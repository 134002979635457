import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AnalyticServiceService } from 'src/app/shared/service/analitycService/analitycService.service';

@Component({
  selector: 'fp-address-radio-button-v2',
  templateUrl: './address-radio-button-v2.component.html',
  styleUrls: ['./address-radio-button-v2.component.sass']
})
export class AddressRadioButtonV2Component implements OnInit {

  @Input() showMatMenu = true;
  @Input() addressId = '';
  @Input() addressTitle = '';
  @Input() addressText = '';
  @Output() editEvent = new EventEmitter<string>();
  @Output() deleteEvent = new EventEmitter<string>();

  @Output() selectRadioOption = new EventEmitter();

  @Input() selectedRadio = false;

  constructor(private serviceAnalytic: AnalyticServiceService) { }

  ngOnInit() {}

  deleteAddressItem() {
    this.deleteEvent.emit(this.addressId);
  }

  editAddressItem() {
    this.editEvent.emit(this.addressId);
  }

  public selectOption() {
    if (!this.selectedRadio) {
      this.selectRadioOption.emit();
    }
    // this.serviceAnalytic.registerEventAnalytics(this.serviceAnalytic.detectModuleTag('elegir_direccion'), 'Click :: Elegir direccion ', 'elegir_direccion');
  }

  public triggerCustomEvent($event: MouseEvent) {
    $event.stopPropagation();
  }

  
}
