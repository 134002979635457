<div class="static-landing-container-dialog">

  <div class="content">
    <div class="row">
      <div class="col-12 text-right">
        <span class="icon icon-24-close cursor-pointer"
              (click)="iconEvent()"></span>
      </div>
    </div>
  </div>

  <div class="static-landing-content">
    <ng-content></ng-content>
  </div>

</div>
