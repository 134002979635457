import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapService } from './services/google-map.service';
import { GoogleMapPlacesService } from './services/google-map-places.service';
import { GoogleMapGeocoderService } from './services/google-map-geocoder.service';
import { CoverageLocationImplementService } from './services/coverage-location-implement.service';
import { GoogleMapConfigImplementService } from './services/google-map-config-implement.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GoogleMapsModule
  ],
  providers: [
    GoogleMapService,
    GoogleMapPlacesService,
    GoogleMapGeocoderService,
    CoverageLocationImplementService,
    GoogleMapConfigImplementService,
  ]
})
export class CustomAgmCoreModule { }
