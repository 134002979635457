import { FormControl } from '@angular/forms';
import { ADRESS_ERROR_MESSAGES } from 'src/app/shared/parameters/global-error-message';
import { getErrorbyFormControl } from 'src/app/shared/helpers/error-controls-helpers';

export class AddressNicknameControl extends FormControl {

  constructor() {
    super('');
    this.settingLastNameValidators();
  }

  private settingLastNameValidators() {
    this.setValidators([
    ]);
  }

  public get addressError(): string {
    return getErrorbyFormControl(this, ADRESS_ERROR_MESSAGES);
  }
}
