import { Component, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'fp-basic-skeleton',
  templateUrl: './basic-skeleton.component.html',
  styleUrls: ['./basic-skeleton.component.sass']
})
export class BasicSkeletonComponent implements AfterViewInit {

  @Input() height = 200;
  @ViewChild('skeleton', { static: true }) skeleton: ElementRef<HTMLElement>;

  ngAfterViewInit() {
    const { skeleton, height } = this;
    const settingStyles = `height: ${height}px; background-size: 10% ${height}px`;
    skeleton.nativeElement.setAttribute('style', settingStyles);
  }
}
