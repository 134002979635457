import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fp-user-data-match-modal',
  templateUrl: './user-data-match-modal.component.html',
  styleUrls: ['./user-data-match-modal.component.sass']
})
export class UserDataMatchModalComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<UserDataMatchModalComponent>,
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.matDialogRef.close();
  }
}
