import { Injectable } from '@angular/core';
import { IPurchaseNumber } from '@models/purchase-number/purchase-number.model';
import { IReqPurchaseNumberUpdatePagoWeb } from '@models/purchase-number/req-purchase-number-update-pago-web.model';
import { PurchaseNumberClientService } from 'src/app/shared/service/purchase-number/purchase-number-client.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseNumberImplementService {

  constructor(
    private purchaseNumberClient: PurchaseNumberClientService,
  ) { }

  public getPurchaseNumber(params: IPurchaseNumber) {
    return this.purchaseNumberClient.getPurchaseNumber$(params);
  }

  public updatePurchaseNumberStatus(params: IReqPurchaseNumberUpdatePagoWeb) {
    return this.purchaseNumberClient.updatePurchaseNumberStatus$(params);
  }

}
