export class ConfigEmbedImage {
  name: string;
  url: string;

  constructor(image) {
    this.name = image.fields.title;
    this.url = image.fields.file.url;
  }
}

export interface IConfigEmbedCatalog {
  fecha: string;
  issuu: string;
  titulo: string;
  configId: string;
}

export class ConfigEmbedCatalog {
  public fecha: string;
  public issuu: string;
  public titulo: string;
  public configId: string;

  constructor(configEmbedCatalog: IConfigEmbedCatalog) {
    this.fecha = configEmbedCatalog.fecha;
    this.issuu = configEmbedCatalog.issuu;
    this.titulo = configEmbedCatalog.titulo;
    this.configId = configEmbedCatalog.configId;
  }
}

export class EmbedCatalogItem {
  configId: string;
  image: string;
  config: Object;

  constructor(data) {
    const staticLandingItem = data.fields;
    this.configId = staticLandingItem.configId;
    this.image = new ConfigEmbedImage(staticLandingItem.image).url;
    this.config = staticLandingItem.config;
  }
}
export class EmbedCatalog {
  title: string;
  slug: string;
  items: Array<EmbedCatalogItem>;

  constructor(data) {
    const embedCatalog = data[0].fields;
    this.title = embedCatalog.title;
    this.slug = embedCatalog.slug;
    this.items = embedCatalog.items.map((item) => new EmbedCatalogItem(item));
  }
}


export const EmbedBannerDesktop = {
  'width': 152,
  'type': 5,
  'loop': false,
  'autoplay': false,
  'autoplayTime': 5000,
  'infinite': false,
  'autoHeight': true,
  'slidesConfigByBreakpoints': {
    'xxs': {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    'sm': {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    'md': {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    'lg': {
      slidesPerView: 1,
      spaceBetween: 14,
    },
    'xl': {
      slidesPerView: 1,
      spaceBetween: 14,
    },
    'xxl': {
      slidesPerView: 1,
      spaceBetween: 14,
    },
  },
};

export const EmbedBannerResponsive = {
  'width': 152,
  'type': 5,
  'loop': false,
  'autoplay': false,
  'autoplayTime': 5000,
  'infinite': false,
  'autoHeight': true,
  'slidesConfigByBreakpoints': {
    'xxs': {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    'sm': {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    'md': {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    'lg': {
      slidesPerView: 1,
      spaceBetween: 14,
    },
    'xl': {
      slidesPerView: 1,
      spaceBetween: 14,
    },
    'xxl': {
      slidesPerView: 1,
      spaceBetween: 14,
    },
  },
};
