export enum IModalStatus {
  ONLY_ADDRESS = 'only_address',
  ONLY_GEO = 'only_geo',
  NO_ONE = 'no_one',
  IS_PENDING = 'is_pending_to_geolocation',
  OK = 'ok',
  EMPTY = 'there_is_no_drugstore',
  LOADING = 'loading',
  DEFAULT = '',
}
