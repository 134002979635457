import { Injectable } from '@angular/core';
import { AppStoreService } from '@stores/app/app-store.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PricePurseService {
    private readonly PRICE_PURSE = 'pricePurse';
    private valueStatus: boolean;
    private _pricePurseStatusSubject = new BehaviorSubject<boolean>(this.getFormStorage());

    public pricePurseStatus$ = this._pricePurseStatusSubject.asObservable();

    constructor(private appStore: AppStoreService) {
        this.pricePurseStatus$.subscribe(status => {
            this.valueStatus = status;
            localStorage.setItem(this.PRICE_PURSE, JSON.stringify(status));
        });
    }
    public getFormStorage(): boolean {
        const stringFromStorage = this.appStore.getStorageItem(this.PRICE_PURSE);
        let jsonFormatOfForm = false;
        if (stringFromStorage) {
            jsonFormatOfForm = JSON.parse(stringFromStorage) as boolean;
        }
        return jsonFormatOfForm;
    }
    public get pricePurseStatus() {
        return this.valueStatus;
    }

    public setpricePurseStatus(status: boolean) {
        this._pricePurseStatusSubject.next(status);
    }
}
