import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ProductImplementService } from '../../service/product-implement.service';
import { EProductStatus } from '../../models/product-status.enum';
import { ProductAlertsService } from '../../service/product-alerts.service';
import { ProductPresentation } from '../../models/product-presentation.model';
import { BUSINESS_PATHS } from 'src/app/shared/parameters/business.parameter';
import { ProductSalesforceService } from '../../service/product-salesforce.service';
import { TaggingService } from 'src/app/atm-services/tagging.service';
import { ProductSellersShoppingCartBase } from '../../models/product-shopping-cart/product-seller-shopping-cart-base.model';
import { IProductItemSellersList, ISellerProduct } from '../../models/products-seller-list.model';

@Component({
  selector: 'fp-product-seller-cart',
  templateUrl: './product-seller-cart.component.html',
  styleUrls: ['./product-seller-cart.component.sass']
})
export class ProductSellerCartComponent implements OnInit, OnChanges {
  public eProductStatus = EProductStatus;
  public productStatus: number = EProductStatus.available;
  public productStatusSeller: number = EProductStatus.available;
  public productPresentation: ProductPresentation;
  public listProductsSeller: any;

  public productPath = BUSINESS_PATHS.PRODUCT;

  public initialPresentation: string;
  public initialPrecio: number;

  @Input() productSeller: ISellerProduct;
  // ISellerProduct implementar

  constructor(
    private _productImplement: ProductImplementService,
    private _productAlerts: ProductAlertsService,
    private _productSalesforce: ProductSalesforceService
  ) {
  }
    ngOnInit(): void {
      this.listProductsSeller = this.productSeller;
    }

  ngOnChanges() {


  }

  setProductPresentation(presentation: ProductPresentation) {
    this.initialPrecio = this.productPresentation.price;
    this.initialPresentation = this.productPresentation.presentation;
    // this.productSeller.products.presentationIdSelected = presentation.id;
    this.productPresentation = presentation;
    // this.productStatus = getUnavailablePresentationStatus(this.productSeller.products, this.productPresentation.fractionated);
    this.productStatus = EProductStatus.available;
    this.setQuantity(this.productStatus === this.eProductStatus.available ? 1 : 0, true);
    TaggingService.cartPresentation();
    this.initialPrecio = this.productPresentation.price;
    this.initialPresentation = this.productPresentation.presentation;
  }

  listPresentation(data) {
    const listProdustSellerPresentations = [];
    let list = [];
    this.productPresentation = {
        description: data.noFractionatedText,
        fractionated: false,
        id: data.presentationId,
        maxUnitSale: data.maxUnitSale,
        price: data.price,
        stock: data.stock,
        presentation: data.presentation,
        quantityLimit: data.quantityLimit,
        image: data.imageList[0].url,
        offerPrice: data.offerPrice,
        regularPrice: data.regularPrice
    };
    listProdustSellerPresentations.push(this.productPresentation);
    list = listProdustSellerPresentations;
    return list;
  }

  setQuantity(quantity: number, changePresentation = false) {
    if (this.listProductsSeller.quantitySelected < quantity) {
      TaggingService.addToCart(this.listProductsSeller, this.productPresentation);
    } else {
      const quantityTotalRemove = changePresentation ?  this.productSeller.quantitySelected : 0;
      TaggingService.removeFromCart(this.listProductsSeller, this.initialPrecio, this.initialPresentation, quantityTotalRemove);
    }
    this.listProductsSeller.quantitySelected = quantity;
    this._productImplement.updateProduct(
      this.listProductsSeller.id,
      this.listProductsSeller.quantitySelected,
      this.listProductsSeller.presentationIdSelected,
      this.listProductsSeller.sellerId,
    );
    this._productSalesforce.updateProduct(
      this.listProductsSeller.id,
      this.listProductsSeller.id,
      this.listProductsSeller.quantitySelected,
      this.productPresentation.price * this.listProductsSeller.quantitySelected
    );
    TaggingService.cartQuantity();
  }


  deleteProduct(id) {
    this._productImplement.deleteProduct(id);
    this._productAlerts.deleteProductAlert();
    this._productSalesforce.deleteProduct(id);
    TaggingService.cartEliminar();
    // const itemPresentation = this.productSeller.products.presentations.find( e => e.id === this.productSeller.products.presentationIdSelected);
    // TaggingService.removeFromCart(this.productSeller, itemPresentation.price, itemPresentation.presentation, this.productSeller.products.quantity, true);
  }

  nameProduct() {
    TaggingService.cartName();
  }

}
