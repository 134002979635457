import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BREAKPOINTS } from "@parameters/global";
import { ModalSkuComponent } from "../../../cart/components/modal-sku/modal-sku.component";
import { NotificationSkuComponent } from "src/app/business/cart/components/notification-sku/notification-sku.component";

@Injectable({
  providedIn: "root"
})
export class ModalSkuVisibleService {
  constructor(private matDialog: MatDialog) {}

  public openModalCheckout(type) {
		const isResposive = window.innerWidth <= BREAKPOINTS.lg;
		let classResposive = isResposive ? 'sku-modal-responsive' : 'sku-modal';
		let idResponsive = isResposive ? 'skuModalResponsive' : 'skuModal';

	return	this.matDialog.open(ModalSkuComponent, {
			id: idResponsive,
			width: "380px",
			hasBackdrop: true,
			panelClass: classResposive,
			disableClose: true,
			data: type
		});
	}

  public openModalCart(type?) {
		this.matDialog.open(NotificationSkuComponent, {
			id: 'skuAlert',
			width: "545px",
			position: { top: 'top' },
			hasBackdrop: false,
			panelClass: 'sku-alert',
			data: type
		});
	}
}
