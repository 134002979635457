import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Filter} from '../../../../shared/models';

@Component({
  selector: 'fp-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.sass']
})
export class ItemListComponent {

  @Input() list: Array<Filter>;
  @Input() itemsInView: number = Infinity;
  @Output() selection = new EventEmitter();

  public selectAll: Filter;
  public radioSelected: Filter;

  @Input('selectAll')
  set defaultSelection(select) {
    this.selectAll = select;
    this.radioSelected = select;
  }

}

