import { environment } from "src/environments/environment";

const getfullEndPoint = (termination) => {
	return `${environment.api_request}/${termination}`;
};

const getfullMAPIEndPoint = (termination) => {
	return `${environment.api_gateway}/${termination}`;
};

const getApprovalEndPoint = (termination) => {
	return `${environment.api_approval}/${termination}`;
};

export const END_POINTS_BUSSINESS = {
	POST_AUTH: getfullEndPoint("oauth/token"),
	SEARCH_CLIENTS: getfullEndPoint("search-client"),
	SET_FAVORITE_ADDRESS: getfullEndPoint("search-client"),
	POST_REGISTER: getfullEndPoint("save-client"),
	PUT_UPDATE_CLIENT: getfullEndPoint("update-client"),
	GET_ADDRESS: getfullEndPoint("search-addresses"),
	PATCH_SAVE_ADDRESS_FAVORITE: getfullEndPoint("save-favorite-address"),
	POST_REGISTER_ADDRESS: getfullEndPoint("save-address"),
	PUT_UPDATE_ADDRESS: getfullEndPoint("update-address"),
	DELETE_ADDRESS: getfullEndPoint("delete-address"),
	POST_NEAR_STORE: getfullEndPoint("nearstore"),
	GET_ZONE: getfullEndPoint("coverage/servicestype"),
	GET_STORE: getfullEndPoint("store"),
	GET_BACKUP: getfullEndPoint("object-id"),
	GET_SERVICES_TYPES: getfullEndPoint("service-types"),
	GET_STOCK_BACKUP: getfullMAPIEndPoint("verify-stock-backup"),
	GET_SCHEDULES: getfullEndPoint("schedules"),
	POST_DRUGSTORE_STOCK: getfullMAPIEndPoint("drugstoresStock"),
	PUT_CHANGE_FIRT_BUY: getfullEndPoint("change-first-buy"),
	RECOVER_PENDING_ITEMS: getfullMAPIEndPoint("recover-pending-items"),
	APPROVALS_DETAIL: getApprovalEndPoint("approval")
};
