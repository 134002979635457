<div class="address-list-modal" id="core-address-list-modal">
  <div class="button-modal-close" (click)="closeModal()">
    <span class="icon icon-24-close"></span>
  </div>

  <fp-section-container-title
    [iconSize]="25"
    [svgName]="'address'"
    [title]="'Mis direcciones'"
  >
  </fp-section-container-title>
  <fp-sub-section-container>
    <div class="content">
      <div class="mt-3">
        <fp-link-buttom
          [id]="'lb--add-address'"
          [buttonText]="'Agregar nueva dirección'"
          (onclick)="openNewAddressModal()"
        ></fp-link-buttom>
      </div>
      <form [formGroup]="addressItemsForm.form" class="form-group">
        <div class="scroll-address-radio-button mt-3">
          <fp-address-radio-button
            *ngFor="let aItem of addressItemList; let indexOfelement = index"
            [showMatMenu]="true"
            [addressTitle]="aItem.name"
            [addressText]="aItem.street"
            [addressId]="aItem.id"
            [selectedRadio]="aItem.id === selectedAddress.id"
            (deleteEvent)="deleteAddressItem(aItem.id)"
            (editEvent)="editAddressItem(aItem.id)"
            (selectRadioOption)="selectRadioAddress(aItem.id)"
            (click)="tagRadio(aItem.name)"
          >
            <fp-radio-control
              formControlName="addressItem"
              [radioOptionValue]="aItem.id"
              [name]="'addressListName'"
            >
            </fp-radio-control>
          </fp-address-radio-button>
        </div>
        <div class="conent-buttons-modal my-4">
          <button
            type="button"
            id="btn--cancel-choose-address"
            class="button-address btn btn-secondary font-weight-bold mb-3 mb-md-0"
            (click)="closeModal()"
          >
            Cancelar
          </button>
          <button
            id="btn--choose-address"
            type="button"
            class="button-address btn ml-lg-3 btn-primary font-weight-bold cursor-pointer"
            [ngClass]="{'btn-disabled': isDisabledSubmitButton}"
            (click)="onSubmit()"
            [disabled]="isDisabledSubmitButton"
          >
            Elegir dirección
          </button>
        </div>
      </form>
    </div>
  </fp-sub-section-container>
</div>
