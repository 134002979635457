import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'fp-snackbar-alert',
  templateUrl: './snackbar-alert.component.html',
  styleUrls: ['./snackbar-alert.component.sass']
})
export class SnackbarAlertComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
    private _matSnackBarRef: MatSnackBarRef<SnackbarAlertComponent>) {
  }

  dismiss() {
    this._matSnackBarRef.dismiss();
  }
}
