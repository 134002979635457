import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  FilteredProductsFilteredList, FilteredProductsFilterSection
} from '../../../core/modules/filtered-products/model/filtered-products-filter-section.model';
import { FilteredProductsStoreService } from '../../../core/modules/filtered-products/store/filtered-products-store.service';
import { ProductCard } from '../../../core/modules/product-card/models/product-card/product-card.model';
import { OrderBy } from '../../../shared/models';
import { FilteredProductsPagination } from '../../../shared/models/filtered-products/filtered-products-pagination.model';
import { listCategorieSelected, SearchLandingFilterSection, SearchLandingFilterSectionToFilterSection, SearchLandingResponse } from '../../../shared/models/search-landing/search-landing-response.model';
import { ActiveOrderFirebaseService } from '../../order/order-services/active-order-firebase.service';
import { SearchLandingRequestService } from '../service/search-landing-request.service';

@Injectable()
export class SearchLandingStoreService {
  private searchLandingResponseSubject: BehaviorSubject<Array<FilteredProductsFilterSection>> = new BehaviorSubject(null);
  public query: string;
  public apiIndex: string;
  public apiIdAlgolia: string;
  public apiKeyId: string;
  public filterList : listCategorieSelected;
  public facet: Array<string>;
  public facetName : Array<string>;
  public maxHits: number;
  public valuesPerFacet: number;
  public selectCategories : Array<string>;
  public replicas: OrderBy[] = [];
  private notificationSubject = new Subject<any>();

  resetHitsOnResponsive = new BehaviorSubject(false);

  constructor(
    private _searchLandingRequest: SearchLandingRequestService,
    private _filteredProductsStore: FilteredProductsStoreService,
    private _ordersFirebaseClientService: ActiveOrderFirebaseService
  ) {
  }

  initSearchLandingStore() {
    this._filteredProductsStore.initFilteredProductsStore();
    this.searchLandingResponseSubject = new BehaviorSubject(null);
  }

  destroySearchLandingStore() {
    this.searchLandingResponseSubject.unsubscribe();
    this._filteredProductsStore.destroyFilteredProductsStore();
  }

  public set rowsRequest(rows: number) {
    this._filteredProductsStore.rowsFilteredStore$ = rows;
  }
  /**
   * Filtered Store*/
  public get filteredProductsStore$(): Observable<Array<ProductCard>> {
    return this._filteredProductsStore.productListFilteredStore$;
  }

  public get paginationStore$(): FilteredProductsPagination {
    return this._filteredProductsStore.paginationFilterStore$;
  }

  public set filterRequest(filteredList: FilteredProductsFilteredList) {
    this._filteredProductsStore.filtersFilteredStore$ = filteredList;
  }

  public set orderByRequest(orderBy: OrderBy) {
    this._filteredProductsStore.setOrderByFilteredStore$(orderBy.order, orderBy.sort);
  }

  public set paginationRequest(pagination: number) {
    this._filteredProductsStore.paginationFilteredStore$ = pagination;
  }

  public set rankingRequest(ranking: number) {
    this._filteredProductsStore.rankingFilteredStore$ = ranking;
  }

  public get filterListCategories() {
    return this.filterList;
  }

  public set filterListCategories(list: listCategorieSelected) {
    this.filterList = list;
  }

  /**
   * Categories Request**/
  public get searchLandingResponse$() {
    this.initSearchLandingStore();
    return this.searchLandingResponseSubject.asObservable();
  }

  private searchLandingResponse(query: string) {
    this._searchLandingRequest.searchLandingRequest$(query)
      .subscribe((searchLandingResponse: SearchLandingResponse) => {
        if (searchLandingResponse.productsId.length > 0) {
          this.filtersSearchLandingStore = searchLandingResponse.filter;
        } else {
          this._filteredProductsStore.emptyProductList();
        }
      });
  }

  set productsIdSearchStore$(productsId: Array<string>) {
    this._filteredProductsStore.productsIdFilteredStore$ = productsId;
  }

  set queryStore$(query: string) {
    this._filteredProductsStore.resetFilteredProductsStore();
    this.query = query;
    this.initSettingsFirebase(query);
  }

  public initSettingsFirebase(query){
    this._ordersFirebaseClientService.getAlgoliaSettings().subscribe((settings)=>{
      const length = settings.ALGOLIA_API_MAX_HITS;
      const maxValuesPerFacet = settings.ALGOLIA_API_MAX_FACETS;
      this.apiMaxHits$ = length;
      this.maxValuesPerFacet$ = maxValuesPerFacet;
      let facets = [];
      let facetsName = [];
      this.replicas = [];
      settings.ALGOLIA_API_FACETS.filter((facet)=>{
        facets.push(facet.value);
        facetsName.push({value: facet.value, name: facet.name});
      });
      settings.ALGOLIA_API_REPLICAS.filter((replica, index)=>{
        this.replicas.push({id: index, name: replica.name, order: "", sort: "", value: replica.value})
      });
      this.facets$ = facets;
      this.facetsName$ = facetsName;
      this.algoliaReplicas$ = this.replicas;
      this.apiIndexes$ = settings.ALGOLIA_API_INDEX;
      this.apiId$ = settings.ALGOLIA_API_ID;
      this.apiKey$ = settings.ALGOLIA_API_KEY;
      this.notify();
      this.filtersSearchLandingStore = [];
    });
  }

  get queryStore() {
    return this.query;
  }
  /**
   * funcion para obtener los facets del firebase
   */
  get facets() {
    return this.facet;
  }

  set facets$(facets:Array<string>) {
    this.facet = facets;
  }

  /**
   * funcion para obtener los facets del firebase
   */
   get facetsName() {
    return this.facetName;
  }

  set facetsName$(facets:Array<string>) {
    this.facetName = facets;
  }

  get apiMaxHits() {
    return this.maxHits;
  }

  set apiMaxHits$(hits:number) {
    this.maxHits = hits;
  }

  get maxValuesPerFacet() {
    return this.valuesPerFacet;
  }

  set maxValuesPerFacet$(hits:number) {
    this.valuesPerFacet = hits;
  }

  get selectedCategories() {
    return this.selectCategories;
  }

  set selectedCategories$(categories) {
    this.selectCategories = categories;
  }

  get apiIndexes() {
    return this.apiIndex;
  }

  set apiIndexes$(categories) {
    this.apiIndex = categories;
  }

  get apiId() {
    return this.apiIdAlgolia;
  }

  set apiId$(id) {
    this.apiIdAlgolia = id;
  }


  /**
   * funcion para obtener las replicas del ordenamiento del firebase
   */
   get algoliaReplicas() {
    return this.replicas;
  }

  set algoliaReplicas$(replica) {
    this.replicas = replica;
  }

  get apiKey() {
    return this.apiKeyId;
  }

  set apiKey$(api) {
    this.apiKeyId = api;
  }

  notify() {
    this.notificationSubject.next();
  }

  getNotification() {
    return this.notificationSubject.asObservable();
  }

  private set filtersSearchLandingStore(searchLandingFilterSections: Array<SearchLandingFilterSection>) {
    const filterSectionToFilteredProducts = searchLandingFilterSections.map(filter => new SearchLandingFilterSectionToFilterSection(filter));
    this.searchLandingResponseSubject.next(filterSectionToFilteredProducts);
  }


}
