<div class="col-lg-12 card processing-order" *ngIf="loading else statusOrder">
  <div class="loading loading-circle"></div>
  <div class="label-2 title">
    Estamos procesando tu pedido.
  </div>
  <div class="label-1 subtitle">
    En unos segundos podrás ver el detalle aquí. No olvides que puedes seguir navegando en nuestra web.
  </div>
</div>

<ng-template #statusOrder>
  <div class="processing-order pt-4">

    <div>
      <span class="icon {{titleIcon}}"></span>
      <div class="label-2 title mt-2"
        [ngClass]="[
          !subtitle ? 'pb-3' : ''
        ]">
        {{ title }}
      </div>
      <div class="label-1 mb-3"
        *ngIf="subtitle"
        [ngClass]="[
          !datetimeMessage ? 'pb-3' : ''
        ]">
        {{ subtitle }}
      </div>
      <div class="pb-4 heading-3" *ngIf="datetimeMessage">
        {{ datetimeMessage }}
      </div>
    </div>

  </div>
</ng-template>

