import { EventEmitter, Injectable, Output } from "@angular/core";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { BehaviorSubject, from, Observable, of, Subject } from "rxjs";
import { map, switchMap } from "rxjs/operators";

export interface IBasicLocation {
  lat: number;
  lng: number;
}

export const GEO_STATUS_TYPES = {
  PENDING_ASK: "prompt",
  DENIED: "denied",
  ACCEPTED: "granted",
};

export interface IGeolocationPermission {
  coords?: GeolocationCoordinates;
  status: "ok" | "nok";
  error?: GeolocationPositionError;
}

@Injectable()
export class GeolocationService {
  private _navigator: Navigator = navigator;

  private geolocationWatcher: number;
  private locationSubject = new BehaviorSubject<IBasicLocation>(
    {} as IBasicLocation
  );
  private locationErrorSubject = new Subject<GeolocationPositionError>();
  public geolocation: IBasicLocation = {} as IBasicLocation;

  public isAcceptedGeolocation = false;

  public location$ = this.locationSubject.asObservable();
  public locationError$ = this.locationErrorSubject.asObservable();
  @Output() setIdDrugStore: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.positionSuccessCallback = this.positionSuccessCallback.bind(this);
    this.positionErrorCallback = this.positionErrorCallback.bind(this);
    this.locationSubject
      .asObservable()
      .subscribe((location) => (this.geolocation = location));
  }

  public get existNavigatorGeolocation() {
    return this._navigator.geolocation;
  }

  public getIsPendingToVerify(status: PermissionStatus) {
    return (
      status.state === GEO_STATUS_TYPES.PENDING_ASK ||
      status.state === GEO_STATUS_TYPES.DENIED
    );
  }

  public loadGeolocation() {
    const geolocationOptions: PositionOptions = {
      timeout: 10000,
      enableHighAccuracy: true,
    };
    this._navigator.geolocation.getCurrentPosition(
      this.positionSuccessCallback,
      this.positionErrorCallback,
      geolocationOptions
    );
  }

  public enableWatchGeolocation() {
    this.geolocationWatcher = this._navigator.geolocation.watchPosition(
      this.positionSuccessCallback,
      this.positionErrorCallback
    );
  }

  public disableWatchGeolocation() {
    this._navigator.geolocation.clearWatch(this.geolocationWatcher);
  }

  private positionSuccessCallback(position: GeolocationPosition) {
    const currentLocation: IBasicLocation = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    this.locationSubject.next(currentLocation);
    this.isAcceptedGeolocation = true;
  }

  private positionErrorCallback(positionError: GeolocationPositionError) {
    this.locationErrorSubject.next(positionError);
    this.isAcceptedGeolocation = false;
  }

  public getGeolocationStatus() {
    if (this._navigator.permissions) {
      return this.getGeolocationStatusSTANDAR();
    } else {
      return this.getGeolocationStatusIOS();
    }
  }

  public getGeolocationStatusSTANDAR() {
    return from(this._navigator.permissions.query({ name: "geolocation" }));
  }

  public getGeolocationStatusIOS() {
    const permissions = {
      state: GEO_STATUS_TYPES.ACCEPTED,
    } as PermissionStatus;
    return of(permissions);
  }

  public isPendingToAskGeolocation$() {
    if (this._navigator.permissions) {
      return this.getGeolocationStatusSTANDAR().pipe(
        map((permissionStatus) => {
          return permissionStatus.state === GEO_STATUS_TYPES.PENDING_ASK;
        })
      )
    } else {
      return of(false);
    }
  }

  public isAcceptedGeolocation$() {
    if (this._navigator.permissions) {
      return this.getGeolocationStatusSTANDAR().pipe(
        switchMap((permissionStatus) => {
          const isAcceptedGeolocation =
            permissionStatus.state === GEO_STATUS_TYPES.ACCEPTED;
          if (isAcceptedGeolocation) {
            return new Observable<IBasicLocation>((observer) => {
              this._navigator.geolocation.getCurrentPosition((position) => {
                const formattedPosition = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                } as IBasicLocation;
                observer.next(formattedPosition);
              });
            });
          } else {
            return of(false);
          }
        })
      );
    } else {
      return of(false);
    }
  }

  public getGeolocationPermissions$() {
    const geolocationOptions: PositionOptions = {
      timeout: 60000,
      enableHighAccuracy: true,
    };
    return new Observable<IGeolocationPermission>((observer) => {
      this._navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          observer.next({
            status: "ok",
            coords: position.coords,
          });
        },
        (positionError: GeolocationPositionError) => {
          observer.next({
            status: "nok",
            error: positionError,
          });
        },
        geolocationOptions
      );
    });
  }
}
