<div class="custom-select">
  <button
    class="custom-select-selected"
    (click)="toggleOptions()"
    [class.selected-active]="showOptions"
    [class.selected-error]="isError"
    [class.selected-disabled]="isDisabled"
    [class.selected-option]="selectedOption"
  >
    {{ selectedOption?.label }}
    <fp-icon iconName="select-arrow-down"></fp-icon>
  </button>
  <div class="custom-select-options" *ngIf="showOptions">
    <button
      class="custom-select-option"
      *ngFor="let option of options"
      (click)="selectOption(option)"
    >
      {{ option.label }}
    </button>
  </div>
</div>
