import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TYPE_NOTIFICATION_ACTION } from "./types-notification.enum";
const TEXT_DESCRIPTION_NOTIFICATION = {
	skuNoVisible: "Se retiró del carrito productos no disponibles para venta unitaria.",
	duplicateProducts: "Producto(s) eliminado(s) por exceso de unidades, vuelve a agregarlo(s)."
};
@Component({
	selector: "fp-notification-sku",
	templateUrl: "./notification-sku.component.html",
	styleUrls: ["./notification-sku.component.scss"]
})
export class NotificationSkuComponent implements OnInit {
	public description = "";

	constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, private dialogRef: MatDialogRef<NotificationSkuComponent>) {}

	ngOnInit() {
		this.description = TEXT_DESCRIPTION_NOTIFICATION[this.dialogData];
		this.closeDialgo();
	}

	closeDialgo() {
		setTimeout(() => {
			this.dialogRef.close();
		}, 8000);
	}
}
