<div class="forgot-password-modal px-4 py-4">
    <div class="header-modal mx-2">
      <span class="icon icon-24-close icon-close" (click)="closeModalConfirmation()"></span>
    </div>

    <div>
      <div class="mx-2">
      <div class="my-3">
        <div class="icon icon-48-send-airplane"></div>
      </div>
      <div class="title">
        <div class="label-3 mb-2">
          ¡Genial! Sigue los pasos que enviamos a
        </div>
        <p class="heading-3 mb-2" #currentEmail>{{email}}</p>
      </div>
      <div class="mb-4">
        <fp-link-buttom
          [buttonText]="'Cambiar este correo'"
          [id]="'changeEmail'"
          (onclick)="changeEmail()">
        </fp-link-buttom>
      </div>
      <p>
        En ese correo te indicaremos qué hacer para restablecer tu contraseña.
      </p>
      <div class="mb-3 mt-4 w-100">
        <a class="w-100 btn btn-primary"
          href="https://{{urlExternal}}"
          target="_blank"
          id="goToEmail"
          (click)="goToEmail()">
          Ir a mi correo
      </a>
      </div>
    </div>
    </div>

</div>
