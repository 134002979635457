import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GenericContentfulService } from 'src/app/shared/service/generic';
import { CONTENTFUL_QUERIES } from 'src/app/shared/parameters/querys';
import { CONTENTFUL_PATH } from 'src/app/shared/parameters/endpoints';
import { CONTENFUL_CUSTOM_KEYS } from 'src/app/shared/parameters/contenful';
import { Entry } from 'contentful';
import { IGenericCheckoutDashboard, IGenericImageBannerCheckout } from 'src/app/shared/models/contentful/contentful-generic-configuration.model';

@Injectable({ providedIn: 'root'})
export class CardBannerContentfulCheckoutService {
  private _paymentMehtodIcons: any;
  constructor(
    private generictContenful: GenericContentfulService
  ) { }

  set paymentMehtodIcons( value: any[]){
    this._paymentMehtodIcons = value.map((obj) => {
      return {
        ...obj.fields,
        icon: obj.fields.image.fields.file.url
      };
    });
  }
  get paymentMehtodIcons(){
    return this._paymentMehtodIcons;
  }

  public getInkfarmaConfigurationBannerCheckout$() {
    const { dashboardShowcases } = CONTENTFUL_PATH.contentTypeIds;
    const { SECTION_CHECKOUT_DASHBOARD } = CONTENFUL_CUSTOM_KEYS.inkafarmaConfiguration;
    const QUERY = CONTENTFUL_QUERIES.prototype.getEntryBySection(SECTION_CHECKOUT_DASHBOARD);
    return this.generictContenful.getEntries<any>(dashboardShowcases, QUERY)
      .pipe(map(entries => {
        const configuration = entries.length ? entries[0] : {} as Entry<IGenericCheckoutDashboard<Entry<IGenericImageBannerCheckout>>>;
        this.paymentMehtodIcons = configuration.fields?.iconslist;
        return configuration;
      }));
  }
}
