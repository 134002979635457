<div class="content-modal px-4 py-4">
  <div class="content-massage">
      <div class="mx-2 my-3 icon-alert">
          <span class="icon icon-48-user-colored"></span>
      </div>
    <div class="warning-message paragraph-2 mx-2 mt-3 mb-2">
      Algunos de los datos ingresados coinciden con más de una cuenta registrada. Para poder terminar con tu registro <span class="label-black-2">llámanos al (511) 314 2020</span>.
    </div>

    <div class="warning-message paragraph-2 mx-2 mt-3 mb-2">
      Recuerda que también puedes comprar sin registrarte.
    </div>
    <div class="mt-4 mx-2 mb-2">
      <fp-link
        [linkRouter] ="'/'">
        <fp-button-primary
          (click)="closeModal()"
          buttonDisplay="block">
            Ok
        </fp-button-primary>
      </fp-link>
    </div>
  </div>
</div>

