import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ProductMiniCartRequest } from 'src/app/shared/models/mini-cart/product-mini-cart-request.model';
import { ShoppingCartRequestService } from 'src/app/shared/service/shopping-cart/shopping-cart-request.service';
import { ShoppingListStoreService } from 'src/app/shared/stores/shopping-list/shopping-list-store.service';
import { UserInfoStoreService } from 'src/app/shared/stores/user/user-info-store.service';
import { ShoppingCartListValidationsService } from '../../service/shopping-cart-list-validations.service';

@Component({
  selector: 'fp-product-error-modal',
  templateUrl: './product-error-modal.component.html',
  styleUrls: ['./product-error-modal.component.sass']
})
export class ProductErrorModalComponent implements OnInit, OnDestroy {
  public shoppingListStoreSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ProductErrorModalComponent>,
    private shoppingCartListValidations: ShoppingCartListValidationsService,
    private _shoppingCartRequest: ShoppingCartRequestService,
    private _shoppingListStore: ShoppingListStoreService,
    private _userInfoStore: UserInfoStoreService,
  ) {
  }

  ngOnInit(): void {
    this.shoppingListStoreSubscription = this._shoppingListStore.shoppingList$
      .subscribe(() => {
        const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList();
        this._shoppingCartRequest.shoppingProductListRequest = shoppingCartListLocal;
        this._shoppingListStore.setHasSellerIssue(true);
      });
  }

  ngOnDestroy() {
    this.shoppingListStoreSubscription.unsubscribe();
  }

  public finishPurchase() {
    if (!this._userInfoStore.isLogged) {
      this.closeModal();
      this.shoppingCartListValidations.goToCart();
    } else {
      this.closeModal();
      this.shoppingCartListValidations.finishPurchase();
    }
  }

  public replaceShoppingCartList() {
    this.closeModal();
    this.shoppingCartListValidations.updateCart();
  }

  public closeModal() {
    this.dialogRef.close();
  }
}
