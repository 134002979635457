<span class="tag-container" *ngIf="text" [ngClass]="tagColor">
  <div class="tag-text">
    {{ text | uppercase }}
  </div>
  <span
    *ngIf="showRemoveIcon && !isValidatingCupon"
    class="icon tag-icon"
    [ngClass]="iconClose"
    (click)="removeIconEvent()"
  >
  </span>
  <span
    *ngIf="showRemoveIcon && isValidatingCupon"
    class="icon tag-icon"
    [ngClass]="iconClose"
  >
  </span>
</span>
