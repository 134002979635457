import { Iitemspack } from "@models/product-default/product-default.interface";
import { EProductPresentation } from "../../../core/modules/product-card/models/product-presentation.enum";
import { EProductStatus } from "../../../core/modules/product-card/models/product-status.enum";
import { Product } from "../../../core/modules/product-card/models/product.model";
import { ProductAdditionalInformation } from "./product-detail-aditional-information.model";
import { ProductDetailImage, ProductImage } from "./product-detail-image.model";
import { ProductDetailPresentations } from "./product-detail-presentation.model";
import { ProductVariant } from "./product-detail-variants.model";
import { IProductDetail, IProductSeoAttributes } from "./product-detail.interface";

export class ProductDetail extends Product {
	public limitOfferTime: string;
	public shortDescription: string;
	public longDescription: string;
	public prescription: string;
	public prescriptionCode: number;
	public images: ProductImage;
	public variants: ProductVariant[];
	public additionalInformation: ProductAdditionalInformation[];
	public tagImageUrl: string;
	public secondaryTagImageUrl: string;
	public alertTagText: string;
	public sellerId: string;
	public sellerName: string;
	public isFromSeller: boolean;
	public sapCode: string;
	public canonicalUrl: string;
	public seoTitle: string;
	public seoMetaDescription: string;
	public seoMetaKeywords: string;
	public screen: boolean;
	public itemsPack: Array<Iitemspack>;
	public productPack: boolean;
	public complementary: string[];
	public categoryList: any;
	public brand: string;
	public category: string;
	public isGeneric: boolean;
	public skuGenericSuggest: string;
	//Order Taker
	unitMeasure: string;
	compositionContent: string;
	skuMifarma: string;
	public imagesBig: ProductImage;

	constructor(iProductDetail: IProductDetail) {
		super();
		this.id = iProductDetail.id || "";
		this.name = iProductDetail.name || "";
		this.screen = this.getScreen();
		this.images = this.screen
			? new ProductDetailImage(iProductDetail.imageList, "L").productImage
			: new ProductDetailImage(iProductDetail.imageList, "X").productImage;
		this.favorite = iProductDetail.favorite || false;
		this.fractionalMode = iProductDetail.fractionalMode || false;
		this.defaultPresentation = iProductDetail.isUnitPresentationDefault
			? iProductDetail.fractionatedPresentationId || EProductPresentation.unit
			: iProductDetail.presentationId || EProductPresentation.pack;
		this.statusId = this.getProductStatusId(iProductDetail);
		this.presentations = new ProductDetailPresentations(iProductDetail).productPresentations || [];
		this.limitOfferTime = iProductDetail.limitOfferTime || "";
		this.prescription = iProductDetail.prescription.toUpperCase() || "";
		this.shortDescription = iProductDetail.shortDescription || "";
		this.longDescription = iProductDetail.longDescription || "";
		this.additionalInformation =
			iProductDetail.details && iProductDetail.details.length
				? iProductDetail.details.map((detail) => new ProductAdditionalInformation(detail))
				: [];
		this.variants =
			iProductDetail.skuVariants && iProductDetail.skuVariants.length
				? iProductDetail.skuVariants.map((variant) => new ProductVariant(variant))
				: [];
		this.tagImageUrl = iProductDetail.tagImageUrl || "";
		this.secondaryTagImageUrl = iProductDetail.secondaryTagImageUrl || "";
		this.alertTagText = iProductDetail.alertTagText || "";
		this.sellerId = iProductDetail.sellerId || "";
		this.sellerName = iProductDetail.sellerName || "";
		this.isFromSeller = iProductDetail.isFromSeller || false;
		this.sapCode = iProductDetail.sapCode || "";

		this.unitMeasure = iProductDetail.unitMeasure || "";
		this.skuMifarma = iProductDetail.skuMifarma || "";
		this.compositionContent = iProductDetail.details.find((details) => details.key === "COMPOSITION")?.content;
		this.itemsPack = iProductDetail.itemsPack || [];
		this.productPack = iProductDetail.productPack || false;
		this.categoryList = iProductDetail.categoryList || [];

		this.stock = iProductDetail.stock || 0;
		this.stockRet = iProductDetail.stockRet || 0;
		this.fractionalRetStock = iProductDetail.fractionalRetStock || 0;
		this.fractionalStock = iProductDetail.fractionalStock || 0;

		this.brand = iProductDetail.brand || "";
		this.category = iProductDetail.categoryList?.[0]?.keyword || "";
		this.prescriptionCode = iProductDetail.prescriptionCode;

		this.isGeneric = iProductDetail.isGeneric;

		this.skuGenericSuggest = iProductDetail.skuGenericSuggest ? iProductDetail.skuGenericSuggest : "";
		this.imagesBig = new ProductDetailImage(iProductDetail.imageList, "L").productImage;

    this.productAliviamed = iProductDetail?.productAliviamed || null;
	}

	getProductStatusId(iProductDetail: IProductDetail) {
		let result = 0;
		if (iProductDetail.maxUnitSale >= 0 || iProductDetail.maxUnitSaleFractionated > 0) {
			result = iProductDetail.productStatusId;
		} else {
			result = EProductStatus.unavailable;
		}
		return result;
	}

	getScreen(): boolean {
		const screen = JSON.parse(localStorage.getItem("SCREEN"));
		return screen;
	}
}

export class ProductSeoAtrtibutes {
	public canonicalUrl: string;
	public seoTitle: string;
	public seoMetaDescription: string;
	public seoMetaKeywords: string;
	public seoRichSnippet: string;

	constructor(iProductSeoAttributes: IProductSeoAttributes) {
		this.canonicalUrl = iProductSeoAttributes.canonicalUrl || "";
		this.seoTitle = iProductSeoAttributes.seoTitle || "";
		this.seoMetaDescription = iProductSeoAttributes.seoMetaDescription || "";
		this.seoMetaKeywords = iProductSeoAttributes.seoMetaKeywords || "";
		this.seoRichSnippet = iProductSeoAttributes.seoRichSnippet || "";
	}
}
