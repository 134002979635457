<form class="controlled-form-step-two" [formGroup]="_recipeControlledFormService.form">
	<div class="row m-0 mb-4">
		<div class="input-form col-lg-6 col-sm-12 pb-4 px-0 input-form-left">
			<fp-input-control [inputTitle]="'Tipo de código del Colegio Médico'" [errorMessage]="" [marginBottomZero]="true">
				<!-- <fp-select
					(optionSelected)="setCMPIType($event)"
					[optionList]="CMPITypeOptions"
					[selectValueControl]="documentType"
					selectPlaceholder="CMP"
					selectFormControlName="CMPIType"
				>
				</fp-select> -->

				<fp-select-control [innerClass]="'simple-arrow'" [options]="CMPITypeOptions" formControlName="CMPIType"> </fp-select-control>
			</fp-input-control>
		</div>
		<div class="input-form col-lg-6 col-sm-12 pb-4 px-0 input-form-right">
			<fp-input-control [inputTitle]="'Código del Colegio Médico'" [errorMessage]="" [marginBottomZero]="true">
				<input
					id="ctrl--names"
					#inputControl
					class="w-100 input"
					placeholder="Ingresa el código"
					maxlength="50"
					formControlName="CMPI"
					appOnlyNumbers
					type="tel"
				/>
			</fp-input-control>
		</div>
		<div class="input-form col-lg-6 col-sm-12 pb-4 px-0 input-form-left">
			<fp-input-control [inputTitle]="'Nombres y apellidos del médico'" [errorMessage]="" [marginBottomZero]="true">
				<input
					id="ctrl--names"
					#inputControl
					class="w-100 input"
					placeholder="Ingresa los nombres"
					maxlength="100"
					formControlName="names"
					appOnlyLettersDoctor
					type="text"
				/>
			</fp-input-control>
		</div>

		<div class="col-12 d-flex gap-16 pb-4 px-0">
			<div class="felx-1">
				<p class="label-form label--1 mb-4 label-recipe">Receta médica:</p>
				<ng-container *ngIf="isDesktop; else isResponsive">
					<div class="fp-input-file" [ngClass]="{ 'disabled-input-file': disableInputfile }">
						<input
							type="file"
							(change)="onFileSelected($event)"
							[disabled]="disableInputfile"
							name="myImage"
							accept=".png, .jpeg, .jpg, .pdf"
							#fileInput
						/>

						<span>
							<fp-icon iconSize="20" [iconName]="getIconBrand('upload')"></fp-icon>
							<p class="fp-input-file-text m-0 ml-2">Adjuntar archivo</p>
						</span>
					</div>
				</ng-container>
				<ng-template #isResponsive>
					<div
						(click)="openModalFileInput('camera')"
						class="fp-input-file"
						[ngClass]="{ 'disabled-input-file': disableInputfile }"
					>
						<input type="button" [disabled]="disableInputfile" #fileInput2 />

						<span>
							<fp-icon iconSize="20" [iconName]="getIconBrand('upload')"></fp-icon>
							<p class="fp-input-file-text m-0 ml-2">Adjuntar archivo</p>
						</span>
					</div>
				</ng-template>
				<p class="label--1 mt-2 label-legend">{{ INFO_CONTROLLED.legend }}</p>
			</div>
		</div>
		<div class="col-12 d-flex gap-16 pb-4 px-0 flex-column recipe-content">
			<ng-container *ngIf="filesSelected">
				<p class="label-form label--1 m-0">Receta adjuntada:</p>
				<div class="recipe-list">
					<div *ngFor="let item of filesSelected; index as i" class="button-chip-icon">
						<fp-icon iconSize="16" [iconName]="'paper'"></fp-icon>
						<span>{{ item?.name }}</span>
						<fp-icon class="btn-close" (click)="removeRecipe(i)" [iconName]="'close-outline-gray'"> </fp-icon>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</form>

<!-- <ng-container>
	<input type="file" accept="image/*" capture="environment" (change)="handleFileInput($event)" #cameraInput style="display:none;" />
	<input type="file" accept="image/png, image/gif, image/jpeg" (change)="handleFileInput($event)" #photoLibraryInput style="display:none;" />
	<input type="file" (change)="handleFileInput($event)" #fileInput style="display:none;" />
</ng-container> -->
