import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';

export interface IContentfulProduct {
  nombre: string;
  sku: string;
}

export class ContentfulProduct {
  name: string;
  id: string;

  constructor(iContentfulProducts: IContentfulProduct) {
    this.name = iContentfulProducts.nombre || '';
    this.id = iContentfulProducts.sku || '';
  }

}

export const CContentfulProduct = {
  id: CONTENTFUL_PATH.contentTypeIds.product
};
