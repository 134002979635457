import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarService } from './snackbar.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarToastComponent } from './snackbar-toast/snackbar-toast.component';
import { SnackbarAlertComponent } from './snackbar-alert/snackbar-alert.component';
import { SnackbarAlertService } from './snackbar-alert.service';
import { SnackbarToastService } from './snackbar-toast.service';
import { MultipleSnackbarsComponent } from './multiple-snackbars/multiple-snackbars.component';
import { SnackbarAlertInComingComponent } from './snackbar-alert-incoming/snackbar-alert-incoming.component';
import { SnackbarAlertInComingService } from './snackbar-alert-incoming.service';
import { IconsModule } from '../../generic/icons/icons.module';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    IconsModule,
  ],
  declarations: [
    SnackbarToastComponent,
    SnackbarAlertComponent,
    MultipleSnackbarsComponent,
    SnackbarAlertInComingComponent,
  ],
  providers: [
    SnackbarService,
    SnackbarAlertService,
    SnackbarAlertInComingService,
    SnackbarToastService,
  ],
  entryComponents: [
    SnackbarToastComponent,
    SnackbarAlertComponent,
    SnackbarAlertInComingComponent,
    MultipleSnackbarsComponent
  ]
})
export class SnackbarModule {
}
