import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EBrandCode } from "@parameters/access/brand.parameter";
import { Observable } from "rxjs";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { OrderTakerValidator } from "src/app/shared/validators/order-taker.validator";
import { environment } from "../../../../../environments/environment";
import { UserTokenStoreService } from '@stores/user/user-token-store.service';

@Injectable({
  providedIn: "root",
})
export class QueryParamsInterceptorService implements HttpInterceptor {
  private allowedUrlToResendRefreshToken = [
    environment.api_gateway,
    "https://nwrs3lmu0i.execute-api.us-east-1.amazonaws.com/CI01"
  ];
  private allowedUrlToResendOrderTaker = [
    environment.api_request,
    "https://nwrs3lmu0i.execute-api.us-east-1.amazonaws.com/CI01",
  ];
  private allowedUrlDeliveryInfoOrderTaker = ['/deliveryinfo']

  constructor(private _appStoreService: AppStoreService, private _userTokenStoreService: UserTokenStoreService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = request.url;
    const valid = this.allowedUrlToResendRefreshToken.find((allowedUrl) =>
      url.startsWith(allowedUrl)
    );
    const validOrderTaker = this.allowedUrlToResendOrderTaker.find(
      (allowedUrl) => url.startsWith(allowedUrl)
    );
    if (valid || (validOrderTaker && OrderTakerValidator.isOrderTakerEnv())) {
      // Se agregan 4 queryParams para los endpoints consumidos que pasan las validaciones
      const brand = OrderTakerValidator.isOrderTakerEnv()
        ? this._appStoreService.brandAtention
        : EBrandCode[environment.brand];
      const saleChannel = OrderTakerValidator.isOrderTakerEnv()
        ? "CALL"
        : "WEB";
      const saleChannelType = OrderTakerValidator.isOrderTakerEnv()
        ? "CALL"
        : "DIGITAL";
      const currentUser = this._userTokenStoreService.currentUser;
      const clientIdTomador = this._appStoreService.getStorageItem("clientIdTomador");

      const isUrlDeliveryInfo = this.allowedUrlDeliveryInfoOrderTaker.includes(url)

      const userCallCenterId = isUrlDeliveryInfo ? currentUser?.id : (clientIdTomador ? clientIdTomador : currentUser?.id);

      request = request.clone({
        setParams: {
          companyCode: brand,
          saleChannel,
          saleChannelType,
          sourceDevice: null,
          userCallCenterId
        },
      });
    }
    return next.handle(request);
  }
}
