<span class="tag-container" *ngIf="text" [ngClass]="tagColor">
  <div class="tag-text">
    <span>
      {{ text | uppercase }}
    </span>
  </div>
  <!-- <span *ngIf="showRemoveIcon && !isValidatingCupon" class="icon tag-icon" [ngClass]="iconClose"
    (click)="removeIconEvent()">
  </span>
  <span *ngIf="showRemoveIcon && isValidatingCupon" class="icon tag-icon" [ngClass]="iconClose">
  </span> -->
  <span *ngIf="showRemoveIcon && !isValidatingCupon" class="x" (click)="removeIconEvent()"></span>
  <span *ngIf="showRemoveIcon && isValidatingCupon" class="x"></span>
</span>
