import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EmailControl } from 'src/app/shared/controls/email-control';
import { NewPasswordControl } from 'src/app/shared/controls/new-password-control';
import { map } from 'rxjs/operators';

@Injectable()
export class NewPasswordFormService {
  public form: FormGroup;

  private _emailAddressControl = new EmailControl();
  private _newPasswordControl = new NewPasswordControl();
  private _confirmPasswordControl = new NewPasswordControl();

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      emailAddress: this._emailAddressControl,
      newPassword: this._newPasswordControl,
      confirmPassword: this._confirmPasswordControl,
    });
  }

  public get emailAddressControl() {
    return this.form.get('emailAddress') as EmailControl;
  }

  public get newPasswordControl() {
    return this.form.get('newPassword') as NewPasswordControl;
  }

  public get confirmPassword() {
    return this.form.get('confirmPassword') as NewPasswordControl;
  }

  public getIsValid8Digit$() {
    return this.newPasswordControl.valueChanges.pipe(
      map((value) => {
        return (value as string).length >= 8;
      })
    );
  }

  public getIsValidCapitalLetter$() {
    const regExp = /(?:.*[A-Z])/;
    return this.newPasswordControl.valueChanges.pipe(
      map((value) => {
        return (value as string).match(regExp) ? true : false;
      })
    );
  }

  public getIsValidLowerCase$() {
    const regExp = /(?:.*[a-z])/;
    return this.newPasswordControl.valueChanges.pipe(
      map((value) => {
        return (value as string).match(regExp) ? true : false;
      })
    );
  }

  public getIsValidNumber$() {
    const regExp = /(?:.*[0-9])/;
    return this.newPasswordControl.valueChanges.pipe(
      map((value) => {
        return (value as string).match(regExp) ? true : false;
      })
    );
  }

  public getIsSameConfirmPassword$() {
    return this.confirmPassword.valueChanges
      .pipe(map(value => {
        return (value as string) === this.newPasswordControl.value;
      }));
  }

  public getIsSameNewPassword$() {
    return this.newPasswordControl.valueChanges
      .pipe(map(value => {
        return (value as string) === this.confirmPassword.value;
      }));
  }

  public isChange$() {
    return this.newPasswordControl.valueChanges.pipe(
      map((value) => {
        return (value as string).length >= 1;
      })
    );
  }
}
