import { ProductSellersShoppingCartBase } from "src/app/core/modules/product-card/models/product-shopping-cart/product-seller-shopping-cart-base.model";
import { ProductShoppingCartBase } from "../../../core/modules/product-card/models/product-shopping-cart/product-shopping-cart-base.model";
import { ProductShoppingCart } from "../../../core/modules/product-card/models/product-shopping-cart/product-shopping-cart.model";
import { IPaymentMethod } from "../cart-confirmation/cart-confirmation.interface";
import { PurchaseSummary } from "./shopping-cart-precalculation.model";
import {
  IPrecalculation,
  IShoppingCartResponse,
  ISubTotal,
} from "./shopping-cart-response.interfase";
import { CART_CODE } from "src/app/business/checkout/parameters/finish-purchase-code.constant";

export class ShoppingCartResponse {
  public code: string;
  public id: number;
  public message: string;
  public shoppingCartAlerts: ShoppingCartAlerts = {} as ShoppingCartAlerts;
  public minimumPurchase: number;
  public minimumPurchaseFlag: boolean;
  public minimumPurchaseMessage: string;
  public purchaseSummary: PurchaseSummary;
  public listSubtotal: Array<ISubTotal>;
  public paymentMethod?: IPaymentMethod;
  public shoppingList: Array<ProductShoppingCart>;
  public productItemSellersList: Array<ProductSellersShoppingCartBase>;
  public warningFlag: boolean;
  public warningMessage: string;
  public defaultDelivery: boolean;
  public orderType: string;
  public mixedOrder: boolean;
  public precalculation: IPrecalculation;

  constructor(iShoppingCartResponse: IShoppingCartResponse) {
    this.code = iShoppingCartResponse.code || "";
    this.id = iShoppingCartResponse.id || -1;
    this.message = iShoppingCartResponse.message || "";
    this.minimumPurchase = iShoppingCartResponse.minimumPurchase || -1;
    this.minimumPurchaseFlag =
      iShoppingCartResponse.minimumPurchaseFlag || false;
    this.minimumPurchaseMessage =
      iShoppingCartResponse.minimumPurchaseMessage || "";
    this.purchaseSummary = new PurchaseSummary(
      iShoppingCartResponse.precalculation
    );
    this.listSubtotal = iShoppingCartResponse.listSubtotal || [];
    this.paymentMethod = iShoppingCartResponse.paymentMethod || null;
    const shoppingList = Array.isArray(iShoppingCartResponse.productItemList)
      ? iShoppingCartResponse.productItemList
      : [];
    this.shoppingList =
      shoppingList.map(
        (productBase) => new ProductShoppingCartBase(productBase).product
      ) || [];
    const itemsSellerList = Array.isArray(
      iShoppingCartResponse.productItemSellersList
    )
      ? iShoppingCartResponse.productItemSellersList
      : [];
    this.productItemSellersList =
      itemsSellerList.map(
        (product) => new ProductSellersShoppingCartBase(product)
      ) || [];
    this.warningFlag = iShoppingCartResponse.warningFlag;
    this.warningMessage = iShoppingCartResponse.warningMessage;
    this.precalculation = iShoppingCartResponse.precalculation;
    this.mixedOrder = iShoppingCartResponse.mixedOrder;

    if (iShoppingCartResponse.code !== CART_CODE.SUCCESSFULL_CODE) {
      this.shoppingCartAlerts = {
        code: iShoppingCartResponse.code,
        message: iShoppingCartResponse.message,
      };
    }
    this.defaultDelivery = this.getDefaultDelivery(iShoppingCartResponse);
    this.orderType = iShoppingCartResponse.orderType;
  }

  public getDefaultDelivery(iShoppingCartResponse: IShoppingCartResponse) {
    const keys = Object.keys(iShoppingCartResponse);
    return keys.includes("defaultDelivery")
      ? iShoppingCartResponse.defaultDelivery
      : true;
  }
}

export class ShoppingCartAlerts {
  code: string;
  message: string;
}
