import { IProductShoppingCartBase } from './product-shopping-cart-base.inteface';
import { ProductShoppingCart } from './product-shopping-cart.model';


export class ProductShoppingCartBase {
  public lam: string | number | boolean;
  public product: ProductShoppingCart;

  constructor(iProductShoppingCartBase: IProductShoppingCartBase) {
    this.lam = iProductShoppingCartBase.lam || '';
    this.product = new ProductShoppingCart(iProductShoppingCartBase.product) || {} as ProductShoppingCart;
  }
}
