import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fp-checkout-processing-coupon-modal',
  templateUrl: './checkout-processing-coupon-modal.component.html',
  styleUrls: ['./checkout-processing-coupon-modal.component.sass']
})
export class CheckoutProcessingCouponModalComponent {
  public descriptionCoupon = 'Calculando el mejor descuento...';
  public description  = 'Espera unos segundos por favor';

  constructor() { }
 

}
