import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { RecipeControlledFormService } from "../components/cart-controlled-form-modal/form/recipe-controlled-form.service";
import { UserControlledFormService } from "../components/cart-controlled-form-modal/form/user-controlled-form.service";

@Injectable({
	providedIn: "root"
})
export class CartControlledStoreService {
	private recipeSubject = new BehaviorSubject<any>(null);
	public recipe$ = this.recipeSubject.asObservable();

	private doctorSubject = new BehaviorSubject<any>(null);
	public doctor$ = this.doctorSubject.asObservable();

	private userSubject = new BehaviorSubject<any>(null);
	public user$ = this.userSubject.asObservable();

	private isLoggedSubject = new BehaviorSubject<any>(false);
	public isLogged$ = this.isLoggedSubject.asObservable();

	private whereItCameFromSubject = new BehaviorSubject<any>("");
	public whereItCameFrom$ = this.whereItCameFromSubject.asObservable();

	private isRecipeExistSubject = new BehaviorSubject<any>(false);
	public isRecipeExistSubject$ = this.isRecipeExistSubject.asObservable();

	constructor(
		private _recipeControlledFormService: RecipeControlledFormService,
		private _userControlledFormService: UserControlledFormService
	) {}
	get getRecipeFile() {
		return this.recipeSubject.value ? this.recipeSubject.value : this.getStorageRecipeFiles();
	}
	public setRecipefile(value: any) {
		if (value) this.setStorageRecipeFiles(value);
		this.recipeSubject.next(value);
	}

	get getUserData() {
		return this.userSubject.value ? this.userSubject.value : JSON.parse(localStorage.getItem("user-recipe-data"));
	}
	public setUserData(value: any) {
		localStorage.setItem("user-recipe-data", JSON.stringify(value));
		this.userSubject.next(value);
	}

	get getDoctorData() {
		return this.doctorSubject.value ? this.doctorSubject.value : JSON.parse(localStorage.getItem("doctor-recipe-data"));
	}
	public setDoctorData(value: any) {
		localStorage.setItem("doctor-recipe-data", JSON.stringify(value));
		this.doctorSubject.next(value);
	}

	get getIsLogged() {
		return this.isLoggedSubject.value;
	}
	public setIsLogged(value: any) {
		this.isLoggedSubject.next(value);
	}

	get getIsRecipeExist() {
		return this.isRecipeExistSubject.value;
	}
	public setIsRecipeExist(value: any) {
		this.isRecipeExistSubject.next(value);
	}

	get getWhereItCameFromSubject() {
		return this.whereItCameFromSubject.value;
	}
	public setWhereItCameFromSubject(value: any) {
		this.whereItCameFromSubject.next(value);
	}

	setStorageRecipeFiles(value: any): void {
		const fileReaders: Promise<string>[] = [];

		value.forEach((file) => {
			fileReaders.push(
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = () => {
						resolve(reader.result as string);
					};
					reader.onerror = reject;
					reader.readAsDataURL(file);
				})
			);
		});

		Promise.all(fileReaders)
			.then((base64Files) => {
				localStorage.setItem("recipe-files", JSON.stringify(base64Files));
			})
			.catch((error) => {
				console.error("Error reading files", error);
			});
	}

	getStorageRecipeFiles() {
		const base64Files = localStorage.getItem("recipe-files");
		if (base64Files) {
			const filesArray = JSON.parse(base64Files) as string[];
			return filesArray.map((base64File, index) => {
				const [mimePart, dataPart] = base64File.split(",");

				const byteString = atob(base64File.split(",")[1]);
				const mimeString = base64File.split(",")[0].split(":")[1].split(";")[0];
				const mimeTypeMatch = mimePart.match(/:(.*?);/);
				const mimeType = mimeTypeMatch[1];

				const ab = new ArrayBuffer(byteString.length);
				const ia = new Uint8Array(ab);
				for (let i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				}

				const blob = new Blob([ia], { type: mimeString });
				const extension = this.getExtensionFromMimeType(mimeType);

				return new File([blob], `filename_${index}.${extension}`, { type: mimeString });
			});
		} else {
			return null;
		}
	}

	getExtensionFromMimeType = (mimeType: string): string => {
		switch (mimeType) {
			case "image/jpeg":
				return "jpg";
			case "image/png":
				return "png";
			case "image/gif":
				return "gif";
			case "application/pdf":
				return "pdf";
			case "text/plain":
				return "txt";
			case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				return "docx";
			case "application/msword":
				return "doc";
			case "image/heic":
				return "heic";
			case "image/heif":
				return "heif";
			// Agrega otros casos según sea necesario
			default:
				throw new Error(`Unsupported MIME type: ${mimeType}`);
		}
	};
	resetData() {
		localStorage.removeItem("doctor-recipe-data");
		localStorage.removeItem("user-recipe-data");
		localStorage.removeItem("hasControlledProducts");
		localStorage.removeItem("recipe-files");
		this.setRecipefile(null);
		this.setUserData(null);
		this.setDoctorData(null);
		this._recipeControlledFormService.form.reset();
		// this._userControlledFormService.resetForm();
	}
}
