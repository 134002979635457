const genders = [
	{ id: "", value: -1, label: "Selecciona tu género" },
	{ id: "MASCULINO", value: 1, label: "Masculino" },
	{ id: "FEMENINO", value: 2, label: "Femenino" }
];

export class NewUser {
	nombres: string;
	apePaterno: string;
	apeMaterno: string;
	direccion: null;
	sexo: string;
	fecnac: any;
	provincia: null;
	dni: string;
	celular: string;
	correo: string;
	comprobante = null;
	id_proveedor: number;
	id_empresa = 2;
	checkTerminos: boolean;
	checkDatos: boolean;
	checkNovedades: boolean;

	constructor(dataUser: DataUser) {
		this.nombres = dataUser.name;
		this.apePaterno = dataUser.fatherLastName;
		this.apeMaterno = dataUser.motherLastName;

		let selectedGender = genders.find((g) => g.value === dataUser.gender);

		// inkaclubAffiliation.sexo = selectedGender.id;

		this.sexo = selectedGender.id;
		if (dataUser.birthDate) {
			this.fecnac = dataUser.birthDate.replaceAll("-", "");
		}
		this.dni = dataUser.identityDocumentNumber ? dataUser.identityDocumentNumber : dataUser.dni;
		this.celular = dataUser.phone;
		this.correo = dataUser.email;
		this.checkTerminos = dataUser.termConditions;
		this.checkDatos = dataUser.termConditions ? dataUser.termConditions : false;
		this.checkNovedades = dataUser.termConditionsInkaClub;
		this.comprobante = dataUser.voucher ? `${dataUser.voucher.voucherCode}-${dataUser.voucher.voucherNumber}` : null;
		this.id_proveedor = dataUser.idProvider ? dataUser.idProvider : 48;
	}
}

export interface DataUser {
	name: string;
	fatherLastName: string;
	motherLastName: string;
	birthDate: any;
	identityDocumentType: string;
	identityDocumentNumber: string;
	dni?: string;
	gender: number;
	email: string;
	phone: string;
	termConditions: boolean;
	termConditionsInkaClub: boolean;
	voucher?: {
		voucherCode: string;
		voucherNumber: string;
	};
	idProvider?: number;
}

export class SearchUSer {
	ipPosId = "0.0.0.1";
	accountNumber: string;
	constructor(data) {
		this.accountNumber = data;
	}
}

export class SearchBenefitsUSer {
	dni: string;
	constructor(data) {
		this.dni = data;
	}
}
export class CouponUSer {
	identificationDocument: string;
	limitAvailableStatus: number;
	companyCode: string;
	constructor(cUser: CUser) {
		this.identificationDocument = cUser.identificationDocument;
		this.limitAvailableStatus = cUser.limitAvailableStatus;
		this.companyCode = cUser.companyCode;
	}
}
export interface CUser {
	identificationDocument: string;
	limitAvailableStatus: number;
	companyCode: string;
}

export class CouponUSerDet {
	identificationDocument: string;
	id: string;
	couponTypeCode: number;
	parentCampaignCode: string;
	idTracking: string;
	companyCode: string;
	constructor(cUserDet: CUserDet) {
		this.identificationDocument = cUserDet.identificationDocument;
		this.id = cUserDet.id;
		this.couponTypeCode = cUserDet.couponTypeCode;
		this.parentCampaignCode = cUserDet.parentCampaignCode;
		this.idTracking = cUserDet.idTracking;
		this.companyCode = cUserDet.companyCode;
	}
}

export class CouponEnableDet {
	identificationDocument: string;
	id: string;
	couponTypeCode: number;
	parentCampaignCode: string;
	idTracking: string;
	companyCode: string;
	constructor(cUserDet: CUserDet) {
		this.identificationDocument = cUserDet.identificationDocument;
		this.id = cUserDet.id;
		this.couponTypeCode = cUserDet.couponTypeCode;
		this.parentCampaignCode = cUserDet.parentCampaignCode;
		this.idTracking = cUserDet.idTracking;
		this.companyCode = cUserDet.companyCode;
	}
}

export interface CUserDet {
	identificationDocument: string;
	id: string;
	couponTypeCode: number;
	parentCampaignCode: string;
	idTracking: string;
	companyCode: string;
}
export class ApiToken {
	grant_type: string;
	refresh_token: string;
	constructor(aToken: AToken) {
		this.grant_type = aToken.grant_type;
		this.refresh_token = aToken.refresh_token;
	}
}

export interface AToken {
	grant_type: string;
	refresh_token: string;
}

export class KeyToken {
	access_token: string;
	expires_in: number;
	id_token: string;
	project_id: string;
	refresh_token: string;
	token_type: string;
	user_id: string;

	constructor(kToken: KToken) {
		this.access_token = kToken.access_token;
		this.expires_in = kToken.expires_in;
		this.id_token = kToken.id_token;
		this.project_id = kToken.project_id;
		this.refresh_token = kToken.refresh_token;
		this.token_type = kToken.token_type;
		this.user_id = kToken.user_id;
	}
}

export interface KToken {
	access_token: string;
	expires_in: number;
	id_token: string;
	project_id: string;
	refresh_token: string;
	token_type: string;
	user_id: string;
}

export interface IKeyTokenParams {
	key: string;
}

export class User {
	identificationDocument: string;
	typeIdentificationDocument: string;
	name: string;
	cellphone: string;
	email: string;
	showBicanalModal?: boolean;
	savingTime?: number;
	totalSavings?: string;
}
