import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "fp-product-price",
	templateUrl: "./product-price.component.html",
	styleUrls: ["./product-price.component.sass"]
})
export class ProductPriceComponent implements OnInit {
	@Input() public price: number;
	@Input() public icon: boolean;
	@Input() public fontSize = "";

	constructor() {}

	ngOnInit() {}
}
