import { IProductImage } from './product-image.interface';

export class ProductImage {
  public url: string;

  constructor(iProductImage: IProductImage) {
    this.url = iProductImage.url;
  }
}

export class ProductImageBySize {
  public image: string;

  constructor(productImages: Array<IProductImage>, size: 'M' | 'X' | 'L') {
    this.image = productImages ? this.getImage(productImages, size) : '';
  }

  getImage(productImages: Array<IProductImage>, size: 'M' | 'X' | 'L') {
    let imageBySize = '';
    productImages.forEach(image => {
      if (image.url.includes(size + '.')) {
        imageBySize = image.url;
      }
    });
    return imageBySize;
  }
}
