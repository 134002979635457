import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CookieService {
  constructor() {}

  public setCookie(key: string, value: any) {
    document.cookie = `${key}=${JSON.stringify(value)}`;
  }
  public getCookie<T>(key: string) {
    let name = `${key}=`;
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return JSON.parse(c.substring(name.length, c.length)) as T;
      }
    }
    return null;
  }

  removecookie(key: string) {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }
}
