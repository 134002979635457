import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointBehavior } from 'src/app/shared/behaviors';
import { Subscription } from 'rxjs';
import { BREAKPOINTS } from 'src/app/shared/parameters/global';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { CookiesPolicyService } from '../../cookies/cookies-policy.service';

@Injectable({
  providedIn: 'root'
})
export class AuthClientService implements OnDestroy {

  public isBigScreenSubscription: Subscription;
  public isBigScreen: boolean;

  constructor(
    private auth: AngularFireAuth,
    private _breakpointBehavior: BreakpointBehavior,
    private cookiesService: CookiesPolicyService
  ) {
    this.isBigScreenSubscription = _breakpointBehavior
      .mediaBreakpointUpObserver(BREAKPOINTS.lg)
      .subscribe(value => this.isBigScreen = value.matches);
  }

  get currentUserObservable() {
    return this.auth.authState;
  }

  authPersistence() {
    return this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  }

  gmailLogin(email: string, password: string) {
    return this.emailLogin(email, password);
  }

  googleLogin(): Promise<any> {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.socialSignIn(provider);
  }

  facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.socialSignIn(provider);
  }

  twitterLogin() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.socialSignIn(provider);
  }

  appleLogin() {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    return this.socialSignIn(provider);
  }

  private socialSignIn(provider): Promise<any> {
    this.cookiesService.resetLocalStorage();
    this.cookiesService.resetDrusgToreAndProcessType();
    return this.auth.signInWithPopup(provider);
  }

  redirectResult() {
    return this.auth.getRedirectResult();
  }

  anonymousLogin() {
    return this.auth.signInAnonymously();
  }


  emailSignUp(email: string, password: string) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  emailLogin(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }


  resetPassword(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }

  signOut() {
    return this.auth.signOut();
  }

  ngOnDestroy() {
    this.isBigScreenSubscription.unsubscribe();
  }

}
