<div class="policy">
  <div class="policy__content">
    <div class="d-flex align-items-center justify-content-between">
      <h2 class="m-0">{{dialogData.title}}</h2>
      <div class="policy__content-close">
        <span class="icon icon-24-close icon-close cursor-pointer" (click)="close()"> </span>
      </div>
    </div>
    <p>
      {{dialogData.description}}
    </p>
  </div>

  <div class="policy__actions">
    <fp-button-primary buttonDisplay="inline-block" (click)="accept()"> Aceptar </fp-button-primary>
    <fp-button-ghost buttonDisplay="inline-block" [routerLink]="[dialogData.link]"
      routerLinkActive="router-link-active">
      Ver política de cookies
    </fp-button-ghost>
  </div>
</div>
