import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ClientManagerModalComponent } from "../components/client-manager-modal/client-manager-modal.component";
import {
  CLIENT_MANAGER_MODAL_CONFIG,
  TYPE_CLIENT_MANAGER,
} from "../paramaters/client-manager.const";

@Injectable()
export class ClientManagerModalService {
  private clientManangerModalRef: MatDialogRef<ClientManagerModalComponent>;
  constructor(private matDialog: MatDialog) {}

  public openClientManagerModal(type: TYPE_CLIENT_MANAGER) {
    this.clientManangerModalRef = this.matDialog.open(
      ClientManagerModalComponent,
      { ...CLIENT_MANAGER_MODAL_CONFIG, data: type }
    );
  }
  public closeRegisterClientModal() {
    this.clientManangerModalRef.close();
  }
  get getClientManangerModalRef(){
    return this.clientManangerModalRef
  }
}
