import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'fp-snackbar-toast',
  templateUrl: './snackbar-toast.component.html',
  styleUrls: ['./snackbar-toast.component.sass']
})
export class SnackbarToastComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public message: any) {
  }

}
