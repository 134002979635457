import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, Inject } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { Subscription, Observable, timer } from "rxjs";
import * as moment from "moment";
import { take } from "rxjs/operators";
import { COMPANY_ID } from "src/app/shared/parameters/global";
import { RegisterImplementService } from "../../register-services/register-implement.service";
import { ValidateTokenResponse, UserOTPRequestParams } from "src/app/shared/models/register/register.model";
import { SnackbarAlertService } from "../../../snackbar/snackbar-alert.service";
import { NewRegisterStoreService } from "src/app/shared/stores/user/new-register-store.service";
import { RegisterModalsManagerHelperService } from '../../register-services/register-modals-manager-helper.service';
import { SixDigitCodeComponent } from "../../../../modules/form-controls/code-six-control/six-digit-code-controlcomponent";

export const LIMIT_MAX_TIME = 3; // tiempo en minutos

@Component({
  selector: "fp-update-email-modal",
  templateUrl: "./update-email-modal.component.html",
  styleUrls: ["./update-email-modal.component.sass"]
})
export class UpdateEmailModalComponent implements OnInit, OnDestroy {
  @ViewChild(SixDigitCodeComponent) child: SixDigitCodeComponent;

  checkVerify = false;

  verificationCode: string;
  buttonDisabled = true;
  isDisabledInputs = false;
  public buttonSendDisabled = true;

  private searchDate: moment.Moment = moment();

  searchEndDate: moment.Moment;
  remainingTime: number;
  minutes: number;
  seconds: number;
  everySecond: Observable<number> = timer(0, 1000);

  encryptedPhoneNumber: string;
  phoneNumber: string;

  public form: FormGroup;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<UpdateEmailModalComponent>,
    public dialogRefAll: MatDialog,
    public formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    private registerImplement: RegisterImplementService,
    private newRegisterStore: NewRegisterStoreService,
    private _snackbarAlertService: SnackbarAlertService,
    private registerModalsManagerHelper: RegisterModalsManagerHelperService,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    this.form = this.formBuilder.group({
      code: new FormControl()
    });
    const { phone } = this.newRegisterStore;
    this.encryptedPhoneNumber = phone.substring(0, 2) + "****" + phone.substring(6, 9);
    this.phoneNumber = phone;
    this.searchEndDate = this.searchDate.add(LIMIT_MAX_TIME, "minutes");
  }

  private get otpCodeControl() {
    return this.form.get("code") as FormControl;
  }

  ngOnInit() {
    console.log(this.data)
    this.sendCode();
    this.subscribeOtpCodeChanging();
    this.subscribeCountdown();
  }

  private subscribeOtpCodeChanging() {
    const formsValues = this.otpCodeControl.valueChanges.subscribe((value) => {
      const otpCode = typeof value === "string" ? value : "";
      if ((otpCode as string).length === 6) {
        /**
         * Cuando el usuario ingrese los 6 dígitos se lanzará automáticamente la validación
         */
        this.sendVerificationCode();
      }
    });
    this.subscriptions.push(formsValues);
  }

  private subscribeCountdown() {
    // cuenta regresiva de 3 minutos
    const countDown = this.everySecond.subscribe(() => {
      const currentTime: moment.Moment = moment();
      this.remainingTime = this.searchEndDate.diff(currentTime);
      this.remainingTime = this.remainingTime / 1000;
      if (this.remainingTime <= 0) {
        this.buttonSendDisabled = false;
      } else {
        this.minutes = Math.floor(this.remainingTime / 60);
        this.seconds = Math.floor(this.remainingTime - this.minutes * 60);
      }
      this.ref.markForCheck();
    });
    this.subscriptions.push(countDown);
  }

  private sendCode() {
    this.registerImplement.sendOtp(this.phoneNumber, "", COMPANY_ID).pipe(take(1)).subscribe();
  }

  public sendNewCode() {
    if (this.buttonSendDisabled) {
      return;
    }
    this.buttonSendDisabled = true;
    this.searchDate = moment();
    this.searchEndDate = this.searchDate.add(LIMIT_MAX_TIME, "minutes");
    this.sendCode();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public closeThisModal() {
    this.dialogRef.close();
  }

  private sendVerificationCode() {
    this.buttonSendDisabled = true;
    this.checkVerify = false;
    const request = new UserOTPRequestParams();
    request.otp = this.form.controls["code"].value;
    request.phoneNumber = this.phoneNumber;
    this.registerImplement
      .tokenValidator(request)
      .pipe(take(1))
      .subscribe((response: ValidateTokenResponse) => {
        const SUCCESS_CODE = "1";
        this.checkVerify = response.code === SUCCESS_CODE;
        if (this.checkVerify) {
          setTimeout(() => {
            if (sessionStorage.getItem('isCheckoutRegister')) {
              const isCheckoutRegister = JSON.parse(sessionStorage.getItem('isCheckoutRegister'));
              if (isCheckoutRegister.value) {
                sessionStorage.setItem('checkVerify', JSON.stringify({ value: this.checkVerify }));
                this.registerModalsManagerHelper.openCheckoutWelcomeRegisterModal();
              }
            }
            this.dialogRef.close(true);
          }, 700);
        } else {
          this._snackbarAlertService.snackbarAlertError("El código que ingresaste es incorrecto. Intenta nuevamente.");
        }
      });
  }

  errorCodeExpired() {
    this._snackbarAlertService.snackbarAlertInfo(
      "El código que ingresaste ha expirado. Dale click a “Reenviar código” para enviarte uno nuevo."
    );
    this.otpCodeControl.setValue("");
  }

  send() {
    this.child.send();
  }

  ss(e) {
    this.buttonDisabled = e;
    return e;
  }
}
