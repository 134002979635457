import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonsModule } from "../../generic/buttons/buttons.module";
import { IconsModule } from "../../generic/icons/icons.module";
import { MoleculesModule } from "../../molecules/molecules.module";
import { RadioModule } from "../form-controls-order-taker/radio/radio.module";
import { AddressDrugstoreDispatchComponent } from "./views/address-drugstore-dispatch/address-drugstore-dispatch.component";
import { AlertDispatchComponent } from './components/alert-dispatch/alert-dispatch.component';
import { ErrorDrugstoreComponent } from './components/error-drugstore/error-drugstore.component';
import { FormControlsModule } from "../form-controls/form-controls.module";

@NgModule({
  declarations: [AddressDrugstoreDispatchComponent, AlertDispatchComponent, ErrorDrugstoreComponent],
  imports: [
    CommonModule,
    MoleculesModule,
    RadioModule,
    ReactiveFormsModule,
    IconsModule,
    ButtonsModule,
    FormControlsModule
  ],
  exports: [AddressDrugstoreDispatchComponent],
  providers: [],
})
export class AddressDrugstoreDispatchModule {}
