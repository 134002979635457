import { Component, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TEXT_MESSAGES } from "./generic-modal-error-texts.constants";

@Component({
	selector: "fp-generic-modal-error",
	templateUrl: "./generic-modal-error.component.html",
	styleUrls: ["./generic-modal-error.component.sass"]
})
export class GenericModalErrorComponent {
	@Input() messageTitleModal: string = TEXT_MESSAGES["MAX_SIZE_FILE_ERROR"].title;
	@Input() messageSubtitleModal: string = TEXT_MESSAGES["MAX_SIZE_FILE_ERROR"].description;
	constructor(public dialogRef: MatDialogRef<GenericModalErrorComponent>) {}

	closeModal() {
		this.dialogRef.close();
	}
}
