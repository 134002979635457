import { AuthImplementService } from 'src/app/shared/implements/auth/auth-implement.service';
import { Injectable } from '@angular/core';
import { UpdateAnonymousClientService } from 'src/app/shared/service/update-anonymous/update-anonymous-client.service';
import { IUpdateAnonymousRequestParams } from 'src/app/shared/models/update-anonymous/update-anonymous.interface';
import firebase from 'firebase/compat/app';
import { take } from 'rxjs/operators';

@Injectable()
export class UpdateAnonymousImplementService {
  private tempFirebaseUser: firebase.User;

  constructor(private updateAnonymousClient: UpdateAnonymousClientService, private authImplement: AuthImplementService) {
    this.authImplement.currentUserObservable.subscribe((user) => {
      this.tempFirebaseUser = user;
    });
  }

  public updateAnonymousInformation$(params: IUpdateAnonymousRequestParams) {
      return this.updateAnonymousClient.updateAnonymousInformation$(params);
  }

  doRegisterCookies() {
    const userAcceptCookies = localStorage.getItem("user_accept_cookies");
    const anonymousParams = {
      flagCookiePolicies: !!userAcceptCookies,
    } as IUpdateAnonymousRequestParams;

    return this.updateAnonymousClient.updateAnonymousInformation$(anonymousParams).pipe(take(1));
  }
}
