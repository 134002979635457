import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Filter} from '../../../../shared/models';

@Component({
  selector: 'fp-radio-list',
  templateUrl: './radio-list.component.html',
  styleUrls: ['./radio-list.component.sass']
})
export class RadioListComponent {

  @Input() list: Array<Filter> = null;
  @Input() itemsInView: number = this.list ? this.list.length : Infinity;
  @Output() selection = new EventEmitter();

  public selectAll: Filter;
  public radioSelected: Filter;

  @Input('selectAll')
  set defaultSelection(select: Filter) {
    this.selectAll = select;
    this.radioSelected = select;
  }

  radioFilter() {
    if (this.radioSelected.row) {
      this.radioSelected.row.forEach(filter => filter.checked = false);
      this.checkboxFilter();
    } else {
      this.selection.emit(this.radioSelected);
    }
  }

  checkboxFilter() {
    const result = {...this.radioSelected};
    result.row = this.radioSelected.row.filter(filter => filter.checked);
    this.selection.emit(result);
  }

}
