<div class="row">
  <div
    class="col d-flex flex-column justify-content-center align-items-center gap-2"
  >
    <fp-product-image
      class="w-100"
      [image]="image"
      [imageName]="name"
      imageSize="48px"
      [productStatus]="productStatus"
      tagSize="small"
    >
    </fp-product-image>

    <fp-chip *ngIf="guaranteed && isOrderTaker" status="blue" [text]="guaranteed"></fp-chip>
  </div>

  <div class="col-9 d-flex flex-column">
    <fp-product-information
      [name]="name"
      [description]="description"
      [prescription]="prescription"
      [productStatus]="productStatus"
      [prescriptionCode]="prescriptionCode"
    >
    </fp-product-information>
    <fp-product-stock
    *ngIf="isOrderTaker"
      [stockPrincipal]="stockPrincipal"
      [stockBackup]="stockBackup"
    ></fp-product-stock>
  </div>
</div>
