export enum EnumTagEvent {
	ATM_ECOMMERCE = "atm.ecommerce"
}
export enum EnumTagEventName {
	//#region Widget
	UI_WIDGET_CLICK_CLOSE_CANALES = "ui_widget_click_close_canales",
	UI_WIDGET_CLICK_ALIVIAMED = "ui_widget_click_aliviamed",
	UI_WIDGET_CLICK_WHATSAPP = "ui_widget_click_whatsapp",
	UI_WIDGET_CLICK_CALLCENTER = "ui_widget_click_callcenter",
	UI_WIDGET_VIEW_CLICK_OPEN = "ui_widget_view_click_open",
	UI_WIDGET_VIEW_CLICK_CLOSE_TXT = "ui_widget_view_click_close_txt"
	//#endregion
}
export enum EnumTagEventCategory {
	ECOMMERCE = "Ecommerce",
	UI_WIDGET = "UI :: Widget"
}
export enum EnumTagEventAction {
	CLICK = "Click"
}

export enum EnumActionPaymentGateway{
	EPAGO_OPEN = "EPAGO_OPEN",
	EPAGO_CLOSE = "EPAGO_CLOSE",
	EPAGO_SUCCESS = "EPAGO_SUCCESS",
	EPAGO_ERROR = "EPAGO_ERROR",
	NIUBIZ_OPEN = "NIUBIZ_OPEN",
	NIUBIZ_CLOSE = "NIUBIZ_CLOSE",
	NIUBIZ_SUCCESS = "NIUBIZ_SUCCESS",
	NIUBIZ_ERROR = "NIUBIZ_ERROR",
}

export const EnumEventCategoryEpago = "Form :: Checkout :: Epago"
export const EnumEventCategoryNiubiz = "Form :: Checkout :: Niubiz"

export const TagEventNamePaymentGateway = {
	EPAGO_OPEN: "ui_form_checkout_finalizar_compra_intento_epago",
	EPAGO_CLOSE: "ui_form_checkout_finalizar_close_modal_epago",
	EPAGO_SUCCESS: "ui_form_checkout_finalizar_success_epago",
	EPAGO_ERROR: "ui_error_checkout_epago",
	NIUBIZ_OPEN: "ui_form_checkout_finalizar_compra_intento_niubiz",
	NIUBIZ_CLOSE: "ui_form_checkout_finalizar_close_modal_niubiz",
	NIUBIZ_SUCCESS: "ui_form_checkout_finalizar_success_niubiz",
	NIUBIZ_ERROR: "ui_error_checkout_niubiz"
}

export const TagEventActionPaymentGateway = {
	EPAGO_OPEN: "open_modal_epago",
	EPAGO_CLOSE: "close_modal_epago",
	EPAGO_SUCCESS: "purchase_success_epago",
	EPAGO_ERROR: "error_modal_epago",
	NIUBIZ_OPEN: "open_modal_niubiz",
	NIUBIZ_CLOSE: "close_modal_niubiz",
	NIUBIZ_SUCCESS: "purchase_success_niubiz",
	NIUBIZ_ERROR: "error_modal_niubiz"
}

export const TagEventLabelPaymentGateway = {
	EPAGO_OPEN: "finalizar_compra :: open_modal_epago",
	EPAGO_CLOSE: "finalizar_compra :: close_modal_epago",
	EPAGO_SUCCESS: "finalizar_compra :: purchase_success_epago",
	EPAGO_ERROR: "finalizar_compra :: error_epago",
	NIUBIZ_OPEN: "finalizar_compra :: open_modal_niubiz",
	NIUBIZ_CLOSE: "finalizar_compra :: close_modal_niubiz",
	NIUBIZ_SUCCESS: "finalizar_compra :: purchase_success_niubiz",
	NIUBIZ_ERROR: "finalizar_compra :: error_niubiz"
}
