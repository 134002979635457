import {Injectable} from '@angular/core';
import {StaticLandingImplementService} from '../../../services/static-landing-implement.service';

@Injectable()
export class PrivacyPolicyImplementService {
  constructor(
    private _staticLandingImplement: StaticLandingImplementService
  ) {
  }

  getPrivacyPolicy() {
    return this._staticLandingImplement.getPrivacyPolicy();
  }

}
