import { Injectable } from '@angular/core';
import { ProductImplementService } from './product-implement.service';
import { IProductDefault } from '@models/product-default/product-default.interface';
import { ProductDefault } from '@models/product-default/product-default.model';
import { getUnavailablePresentationStatus } from './product-status.helper';
import { EProductStatus } from '../models/product-status.enum';
import { ProductPresentation } from '../models/product-presentation.model';
import { GenericValidator } from 'src/app/shared/validators/generic-validator';
import { ICardsAssociated } from 'src/app/shared/models/contentful/business-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalProductCardService {
  constructor(
    private _productImplementService: ProductImplementService,
  ) {
  }

  public paymentMethodCard: ICardsAssociated;
  public paymentMethodCardType: string;
  public paymentMethodName: string;

  getProduct(iProduct: IProductDefault) {
    const productDefault = new ProductDefault(iProduct);

    const productInShoppingCart = this._productImplementService.getProductShoppingCart(iProduct.id);
    const presentationId = productInShoppingCart ? productInShoppingCart.presentationIdSelected : productDefault.defaultPresentation;
    const presentation = productDefault.presentations
        .find(presentations => presentations.id === presentationId) ||
      productDefault.presentations
        .find(presentations => presentations.id === productDefault.defaultPresentation);

    const quantity = productInShoppingCart ? productInShoppingCart.quantity : 1;
    const statusId = getUnavailablePresentationStatus(productDefault, presentation.fractionated) === EProductStatus.unavailablePresentation ? EProductStatus.unavailable : productDefault.statusId;

    if (productInShoppingCart && presentation.id !== presentationId) {
      this._productImplementService.updateProduct(
        productDefault.id, 1, presentation.id, productDefault.sellerId,
      );
    }
    const sellerName = productDefault.isFromSeller ? productDefault.sellerName : 'Inkafarma';
    const calcPrice = this.calculatePrice(presentation);
    const calcPriceOfListPrevious = this.calculatePriceOfListPrevious(presentation);
    const calcPriceWithPaymentMethod = this.calculatePriceWithPaymentMethod(presentation);


    return {
      id: productDefault.id,
      name: productDefault.name,
      favorite: productDefault.favorite,
      slug: productDefault.slug,
      limitOfferTime: productDefault.limitOfferTime,
      fractionated: presentation.id,
      image: presentation.image,
      quantityLimit: presentation.quantityLimit,
      presentation: presentation.presentation,
      description: presentation.description,
      price: calcPrice,
      isProductInCart: !!productInShoppingCart,
      quantity: quantity,
      statusId: statusId,
      sellerId: productDefault.sellerId,
      sellerName: sellerName,
      priceOfListPrevious: calcPriceOfListPrevious,
      priceWithPaymentMethod: calcPriceWithPaymentMethod,
      iconPaymentMethod: this.paymentMethodCard.image,
      prescription: productDefault.prescription,
    };
  }

  private calculatePrice(product: ProductPresentation) {
    const value: number = product.crossOutPL ? product.regularPrice : product.price;
    return value;
  }

  private calculatePriceOfListPrevious(product: ProductPresentation) {
    let value: number = null;
    if (product.crossOutPL) {
      value = product.price;
      if (GenericValidator.roundToTwoDecimals(product.price) === GenericValidator.roundToTwoDecimals(product.regularPrice)) {
        value = null;
      } else if (product.regularPrice === 0) {
        value = null;
      }
    }
    return value;
  }

  private calculatePriceWithPaymentMethod(product: ProductPresentation) {
    const value: number = (product.offerPrice > 0) ? product.offerPrice : null;
    return value;
  }

}
