import { Injectable } from '@angular/core';

import { CoverageLocationClientService } from 'src/app/shared/service/coverage-location/coverage-location-client.service';
import { IPosition } from 'src/app/shared/models/coverage-location/coverage-location.model';

@Injectable()
export class CoverageLocationImplementService {

  constructor(
    private coverageLocationClient: CoverageLocationClientService,
  ) { }

  public getValidatedCoverage$(position: IPosition) {
    return this.coverageLocationClient.getValidatedCoverage$(position);
  }
}
