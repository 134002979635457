import {CONTENTFUL_PATH} from '../../../shared/parameters/endpoints';
import { Asset, Entry } from 'contentful';
import { ContentfulAsset, ContentfulEntry } from 'src/app/shared/models/contentful/contentful.model';
import {ContentfulProductList, IContentfulProductList} from '../../../core/contentful/models/contentful-product-list.model';

export enum ESuperDiscountSumary {
  detailSuperDiscount = 'detalle_super_descuento', 
  default = ''
}

export interface IWorldItem {
  title: string;
  sumary: string;
  image: Asset;
  content: string;
  item: Entry<IContentfulProductList>;
}

export class WorldItem {
  title: string;
  sumary: string;
  image: string;
  content: string;
  item: ContentfulProductList;

  constructor(worldItem: IWorldItem) {
  this.title = worldItem.title || '';
  this.sumary = worldItem.sumary || '';
  this.image =  new ContentfulAsset(worldItem.image).asset || '';
  this.content = worldItem.content || '';
  if (worldItem.item) {this.item = new ContentfulProductList(new ContentfulEntry(worldItem.item).content); }
  }
}

export interface IWorldList {
  title: string;
  image: Asset;
  imageDesktop: Asset;
  sumary: string;
  content: string;
  items: Array<Entry<IWorldItem>>;
}

export class WorldList {
  title: string;
  image: string;
  imageDesktop:string;
  sumary: string;
  content: string;
  items:  Array<WorldItem>;
  constructor(worldList: IWorldList) {
  this.title = worldList.title || '';
  this.image =  new ContentfulAsset(worldList.image).asset || '';
  this.imageDesktop =  new ContentfulAsset(worldList.imageDesktop).asset || '';
  this.sumary = worldList.sumary || '';
  this.content = worldList.content || '';
  this.items = worldList.items
    .map(item => new WorldItem(new ContentfulEntry(item).content));
  }
}

export const CWorldList = {
  id: CONTENTFUL_PATH.contentTypeIds.worldList,
  sliderProductConfig: {
    'type': 5,
    'loop': false,
    'autoplay': false,
    'autoplayTime': 5000,
    'infinite': false,
    'autoHeight': true,
    'slidesConfigByBreakpoints': {
      'xxs': {
        slidesPerView: 1.26,
        spaceBetween: 16
      },
      'sm': {
        slidesPerView: 1.8,
        spaceBetween: 16
      },
      'md': {
        slidesPerView: 2.4,
        spaceBetween: 16
      },
      'lg': {
        slidesPerView: 3,
        spaceBetween: 14
      },
      'xl': {
        slidesPerView: 4,
        spaceBetween: 14
      },
      'xxl': {
        slidesPerView: 5,
        spaceBetween: 14
      },
    }
  },
  sliderStageConfig: {
    'type': 4.5,
    'loop': false,
    'autoplay': false,
    'autoplayTime': 5000,
    'infinite': false,
    'autoHeight': true,
    'pagination': { el: '.swiper-pagination-stage', clickable: true },
    'slidesConfigByBreakpoints': {
      'xxs': {
        slidesPerView: 1.8,
        spaceBetween: 14
      },
      'sm': {
        slidesPerView: 1.8,
        spaceBetween: 14
      },
      'md': {
        slidesPerView: 2,
        spaceBetween: 24
      },
      'lg': {
        slidesPerView: 2.5,
        spaceBetween: 24
      },
      'xl': {
        slidesPerView: 3.5,
        spaceBetween: 37
      },
      'xxl': {
        slidesPerView: 4,
        spaceBetween: 37
      },
    }
  }
};
