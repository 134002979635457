<div class="content-modal">
	<div class="content-modal__header">
		<div class="actions">
			<fp-button-icon class="btn-close" (click)="onClose('close')" [buttonIcon]="'close-outline-gray'"> </fp-button-icon>
		</div>
		<h2 class="title m-0">
			Tu carrito tiene uno o más <br />
			<strong [ngClass]="{'background-secondary':isMifaBrand}"> productos que requieren receta médica. </strong>
		</h2>
		<span class="subtitle tex-body-x mt-4">A continuación, registraremos la siguiente información:</span>
	</div>
	<div class="content-modal__contain">
		<div class="content-controlled-info">
			<div class="text-center card-controlled-info">
				<fp-icon iconSize="64" [iconName]="getIconBrand('user-card')"></fp-icon>
				<span>Ingresar <strong [ngClass]="{'background-secondary':isMifaBrand}">datos del paciente</strong> al cual va dirigido la receta.</span>
			</div>
			<div class="text-center card-controlled-info">
				<fp-icon iconSize="64" [iconName]="getIconBrand('upload-file')"></fp-icon>
				<span>
					Adjuntar una imagen de la <strong [ngClass]="{'background-secondary':isMifaBrand}">receta médica</strong> donde se visualice el membrete, sello y firma del médico,
					número de colegiado y la vigencia de la receta.
				</span>
			</div>
		</div>
		<div class="disclaimer-content">
			<fp-disclaimer-generic></fp-disclaimer-generic>
		</div>

		<mat-dialog-actions align="end" class="mb-0 pb-0">
			<div class="actions-container d-flex align-end">
				<fp-button-secondary [buttonClass]="'button-weight-normal'" class="btn-cancel w-100" [buttonStyle]="{height: '48px', 'font-weight': '600', padding: '16px'}" buttonDisplay="block" (click)="onClose('back')">Volver al carrito</fp-button-secondary>
				<fp-button-primary [buttonClass]="'button-weight-normal'" [buttonStyle]="{height: '48px', 'font-weight': '600', padding: '16px'}" class="ml-2 btn-next-form" buttonDisplay="block" (click)="nextAction()">Continuar</fp-button-primary>
			</div>
		</mat-dialog-actions>
	</div>
</div>
