import { EProductPresentation } from "../product-presentation.enum";
import { EProductStatus } from "../product-status.enum";
import { Product } from "../product.model";
import { ProductShoppingCartPresentations } from "./product-shopping-cart-presentation.model";
import { IProductShoppingCart } from "./product-shopping-cart.interface";

export class ProductShoppingCart extends Product {
	public quantity: number;
	public presentationIdSelected: number;
	public subTotal: number;
	public subTotalOffer: number;
	public subTotalAllPaymentMethod: number;
	public subTotalWithPaymentMethod: number;
	public regularPrice: number;
	public sellerId: string;
	public sellerName: string;
	public isFromSeller: boolean;
	public sapCode: string;
	public brand: string;
	public category: string;
	public categoryList: any;
	public skuVariants = [];
	public productPack: boolean;
	public prescription: string;
	public prescriptionCode: number;
	public guaranteed: string;
	public stockBackup: number = null;

	public fractionatedPrice: number;
	public fractionatedPriceAllPaymentMethod: number;
	public fractionatedPriceWithpaymentMethod: number;
	public price: number;
	public priceAllPaymentMethod: number;
	public priceWithpaymentMethod: number;

	constructor(iProductShoppingCart: IProductShoppingCart) {
		super();
		this.id = iProductShoppingCart.id || "";
		this.name = iProductShoppingCart.name || "";
		this.favorite = iProductShoppingCart.favorite || false;
		this.fractionalMode = iProductShoppingCart.fractionalMode || false;
		this.defaultPresentation = iProductShoppingCart.isUnitPresentationDefault
			? iProductShoppingCart.fractionatedPresentationId || EProductPresentation.unit
			: iProductShoppingCart.presentationId || EProductPresentation.pack;
		this.statusId = iProductShoppingCart.productStatusId || EProductStatus.unavailable;
		this.slug = iProductShoppingCart.slug || "";
		this.presentations = new ProductShoppingCartPresentations(iProductShoppingCart).productPresentations || [];

		this.quantity = iProductShoppingCart.quantitySelected || 0;
		this.presentationIdSelected = iProductShoppingCart.presentationIdSelected || this.defaultPresentation;
		this.subTotal = iProductShoppingCart.subTotal || 0;
		this.subTotalOffer = iProductShoppingCart.subTotalOffer || 0;
		this.regularPrice = iProductShoppingCart.regularPrice || 0;
		this.subTotalAllPaymentMethod = iProductShoppingCart.subTotalAllPaymentMethod || 0;
		this.subTotalWithPaymentMethod = iProductShoppingCart.subTotalWithPaymentMethod || 0;
		this.sellerId = iProductShoppingCart.sellerId || "";
		this.sellerName = iProductShoppingCart.sellerName || "";
		this.sapCode = iProductShoppingCart.sapCode || "";
		this.isFromSeller = iProductShoppingCart.isFromSeller || false;
		this.brand = iProductShoppingCart.brand || "";
		this.category = iProductShoppingCart.categoryList?.[0]?.keyword || "";
		this.categoryList = iProductShoppingCart.categoryList || "";
		this.skuVariants = iProductShoppingCart.skuVariants || [];
		this.productPack = iProductShoppingCart.productPack || false;
		this.prescription = iProductShoppingCart.prescription || "";
		this.prescriptionCode = iProductShoppingCart.prescriptionCode || 0;

		this.fractionalStock = iProductShoppingCart.fractionalStock || null;
		this.fractionalRetStock = iProductShoppingCart.fractionalRetStock || null;

    this.guaranteed = iProductShoppingCart.guaranteed || "";
    this.stock = iProductShoppingCart.stock || 0;
    this.stockRet = iProductShoppingCart.stockRet || 0;
    this.fractionatedPrice = iProductShoppingCart.fractionatedPrice || 0;
    this.fractionatedPriceAllPaymentMethod = iProductShoppingCart.fractionatedPriceAllPaymentMethod || 0;
    this.fractionatedPriceWithpaymentMethod = iProductShoppingCart.fractionatedPriceWithpaymentMethod || 0;
    this.price = iProductShoppingCart.price || 0;
    this.priceAllPaymentMethod = iProductShoppingCart.priceAllPaymentMethod || 0;
    this.priceWithpaymentMethod = iProductShoppingCart.priceWithpaymentMethod || 0;

    this.productAliviamed = iProductShoppingCart?.productAliviamed || null;
  }
}
