import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Client } from "src/app/shared/models/client/client.model";
import { END_POINTS_BUSSINESS } from "src/app/shared/parameters/generic/endpoints";
import { EHttpHeaders } from "src/app/shared/parameters/generic/http-header.parameter";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { ClientStoreService } from "src/app/shared/stores/client/client-store.service";
import { TokenStoreService } from "src/app/shared/stores/user/token-store.service";
import { ClientManagerUpdateRequest } from "../paramaters/client-manager.const";

@Injectable()
export class ClientManagerRequestService {
  constructor(
    private _httpClient: HttpClient,
    private _tokenStoreService: TokenStoreService,
    private _clientStoreService: ClientStoreService,
    private _appStore: AppStoreService
  ) {}

  postRegisterClient$(body: Client): Observable<any> {
    const headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });
    return this._httpClient
      .post<any>(`${END_POINTS_BUSSINESS.POST_REGISTER}`, body, {
        headers,
      })
      .pipe(
        map((r) => {
          if (r.id) {
            body.codClient = r.id;
            body.isFirstBuyIKF = true;
            body.isFirstBuyMF = true;
            this._clientStoreService.setClient$(body);
          }
          return r;
        })
      );
  }

  putUpdate(body: ClientManagerUpdateRequest) {
    const headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });
    return this._httpClient
      .put<any>(`${END_POINTS_BUSSINESS.PUT_UPDATE_CLIENT}`, body, {
        headers,
      })
      .pipe(
        map((r) => {
          if (r.id) {
            const client: Client = {
              ...body,
              codClient: r.id,
            };
          }
          return r;
        })
      );
  }
  putUpdateFirstBuy() {
    const headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });
    const brand = this._appStore.brandAtention;
    const { codClient } = this._clientStoreService.client;
    return this._httpClient.patch<any>(
      `${END_POINTS_BUSSINESS.PUT_CHANGE_FIRT_BUY}/${brand}/${codClient}`,
      {},
      {
        headers,
      }
    );
  }
}
