import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { IFormatedAddressItem } from "../../models/address-item-store.interface";
import { NewAddressManagerService } from "src/app/core/modules/address-manager/address-services/new-address-manager/new-address-manager.service";
import { CheckoutAddressItemsFormService } from "src/app/core/modules/address-manager/address-forms/checkout-address-items-form.service";
import { AddressManagerPersistanceService } from "../../address-services/address-manager-persistance.service";
import { SnackbarToastService } from "../../../snackbar/snackbar-toast.service";
import { BreakpointBehavior } from "src/app/shared/behaviors";
import { BREAKPOINTS } from "src/app/shared/parameters/global";
import { TOAST_DELETE_MESSAGE } from "../../parameters/address-list.constant";
import { AddressListStoreService } from "../../address-stores/address-list-store.service";
import { AddressImplementService } from "../../address-services/address-implement.service";
import { finalize, take, tap } from "rxjs/operators";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { GoogleTagManagerService } from "src/app/shared/analytics/google-tag-manager.service";
import { PATHS_TO_RELOAD } from "../../parameters/special-behaviors.parameter";
import { Router } from "@angular/router";
import { getRouterPath } from "src/app/shared/helpers/router-functions.helper";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { CouponsStoreService } from "src/app/business/checkout/checkout-stores/coupons-store.service";
import { ShoppingCartImplementService } from "src/app/shared/service/shopping-cart/shopping-cart-implement.service";
import { ShoppingCartResponse } from "src/app/shared/models/shopping-cart/shopping-cart-response.model";
import { ShoppingCartRequestService } from "src/app/shared/service/shopping-cart/shopping-cart-request.service";
import { AnalyticServiceService } from "src/app/shared/service/analitycService/analitycService.service";
import { EBrand } from "@parameters/access/brand.parameter";
import { environment } from "@environments/environment";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { AppStoreService } from "@stores/app/app-store.service";
import { FLOW_SEARCH_ADDRESS_TYPE } from "../../parameters/modal.constant";
import { RET3_RAD_STORAGE_KEY } from "src/app/business/checkout/parameters/global-config";
import { CoachmarkService } from "src/app/shared/service/coachmark/coachmark.service";
import { LS_ADDRESS_SELECTED, LS_ID_FAVORITE_ADDRESS } from "src/app/shared/service/local-storage/constants/address.constant";

@Component({
	selector: "fp-address-list-modal-v2",
	templateUrl: "./address-list-modal-v2.component.html",
	styleUrls: ["./address-list-modal-v2.component.sass"]
})
export class AddressListModalV2Component implements OnInit, OnDestroy {
	public isMifaBrand = EBrand[environment.brand] == EBrand.mifarma;
	@Input() listModalTitle;
	public selectedAddress = {} as AddressItem;
	public addressItemList: IFormatedAddressItem[] = [];
	private subscriptions: Subscription[] = [];

	private toastConfigLargeFormDeleteDirection: MatSnackBarConfig = {
		horizontalPosition: "left",
		verticalPosition: "bottom"
	};
	private toastConfigSmallFormDeleteDirection: MatSnackBarConfig = {
		verticalPosition: "top"
	};

	public isDisabledSubmitButton = false;

	constructor(
		private dialogRef: MatDialogRef<AddressListModalV2Component>,
		private newAddressManager: NewAddressManagerService,
		private shoppingCartRequest: ShoppingCartRequestService,
		public addressItemsForm: CheckoutAddressItemsFormService,
		private addressManagerPersistanceService: AddressManagerPersistanceService,
		private snackbarToastService: SnackbarToastService,
		private _breakpointBehavior: BreakpointBehavior,
		private addressListStore: AddressListStoreService,
		private couponsStore: CouponsStoreService,
		private addressImplement: AddressImplementService,
		private shoppingCartImplement: ShoppingCartImplementService,
		public gtm: GoogleTagManagerService,
		private router: Router,
		private serviceAnalytic: AnalyticServiceService,
		private retValidateCartService: RetValidateCartService,
		private appStoreService: AppStoreService,
		private coachMarkService: CoachmarkService
	) {}

	ngOnInit() {
		this.coachMarkService.activeProductCoachmark.next(false);

		this.addressItemsForm.addressItemControl.valueChanges.subscribe((value) => {
			if (value) {
				this.selectedAddress = this.addressListStore.addressList.find((address) => address.id === value);
			}
		});

		const formatedSubscription = this.addressListStore.formatedAddressList$.subscribe((formatedAddressList) => {
			if (formatedAddressList.length === 0) {
				this.closeModal();
				this.openNewAddressModal();
			}
			this.addressItemList = formatedAddressList;
		});
		const selectedSubscription = this.addressListStore.selectedAddress$.subscribe((selectedAddress) => {
			this.addressItemsForm.addressItemControl.setValue(selectedAddress.id);
			this.selectedAddress = selectedAddress;
		});

		this.subscriptions.push(formatedSubscription, selectedSubscription);
		this.serviceAnalytic.registerEventAnalytics(
			this.detectModuleTag("despacho_a_domicilio"),
			"Click :: seleccionar despacho a domicilio",
			""
		);
	}

	closeModal(): void {
		setTimeout(() => {
			this.dialogRef.close();
		}, 250);
		this.serviceAnalytic.registerEventAnalytics(this.detectModuleTag("x_o_cancelar"), "Click :: Cerrar modal direcciones", "");
	}

	openNewAddressModal(): void {
		this.newAddressManager.showNewAddressModal(FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS);
		this.addressManagerPersistanceService.setAddressToUpdate(null);
		this.serviceAnalytic.registerEventAnalytics(
			this.detectModuleTag("agregar_nueva_direccion"),
			"Click :: Agregar nueva direccion",
			""
		);
		if (this.isMifaBrand) {
			this.gtm.addAddressButton();
		}
	}

	onSubmit() {
		if (this.addressItemsForm.form.valid) {
			this.isDisabledSubmitButton = true;
			const addressId = this.addressItemsForm.addressItemControl.value;
			this.serviceAnalytic.registerEventAnalytics(this.detectModuleTag("elegir_direccion"), "Boton :: elegir direccion", "");
			this.retValidateCartService.needCoverageValidation.next(true);
			return this.addressImplement
				.updatedSelectedAddress$(addressId)
				.pipe(
					tap((response: any) => {
						localStorage.removeItem(LS_ID_FAVORITE_ADDRESS);
						const selectedAddressSubscription = this.addressListStore.selectedAddress$.subscribe((selectedAddress) => {
							localStorage.setItem(LS_ID_FAVORITE_ADDRESS, selectedAddress.id);
							this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "true");
							this.retValidateCartService.storeProcessType(RET3_RAD_STORAGE_KEY);
              this.addressListStore.saveAddressStorage(selectedAddress);
							setTimeout(() => {
								window.location.reload();
							}, 1500);
						});
						this.subscriptions.push(selectedAddressSubscription);
					}),
					finalize(() => {
						this.isDisabledSubmitButton = false;
					})
				)
				.subscribe();
		}
	}

	isCurrentCovered(coverageResponse: any) {
		return (
			coverageResponse.current?.latitude !== 0 &&
			coverageResponse.current?.longitude !== 0 &&
			coverageResponse.current?.enabled &&
			coverageResponse.current?.inCoverage
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
		this.addressManagerPersistanceService.setAddressToUpdate(null);
		this.retValidateCartService.needCoverageValidation.next(false);
	}

	private isBigScreen() {
		return this._breakpointBehavior.isMatchedMediaBreakpointUp(BREAKPOINTS.lg);
	}

	public deleteAddressItem(addressId: string) {
		if (this.router.url === "/checkout") {
			this.couponsStore.setCoupons([]);
			const cartRequest = this.shoppingCartRequest.shoppingCartRequest;
			cartRequest.discountsList = [];
			this.shoppingCartRequest.updateShoppingCartRequest = cartRequest;
			this.shoppingCartImplement
				.getShoppingCart()
				.pipe(take(1))
				.subscribe((shoppingCartResponse: ShoppingCartResponse) => {
					this.deleteAdrres(addressId);
				});
		} else {
			this.deleteAdrres(addressId);
		}
	}
  deleteAdrres(addressId: string) {
		this.addressImplement
			.deleteAddress$(addressId)
			.pipe(take(1))
			.subscribe(() => {
				if (this.selectedAddress.id === addressId) {
					this.addressImplement.loadAddressListAndSelectDefaultAddress2$().subscribe((addressList) => {
						this.addressListStore.setAddressList(addressList);
            this.addressListStore.saveAddressListStorage(addressList);
            this.addressListStore.saveAddressStorage(this.addressListStore.getFavoriteAddress(addressList));
						this.validateIfNeedReload();
					});
				} else {
					this.addressImplement.getAddresses$().subscribe((addressList) => {
						this.addressListStore.setAddressList(addressList);
            this.addressListStore.saveAddressListStorage(addressList);
            this.addressListStore.saveAddressStorage(this.addressListStore.getFavoriteAddress(addressList));
						this.validateIfNeedReload();
					});
				}
				const toastConfig = this.isBigScreen()
					? this.toastConfigLargeFormDeleteDirection
					: this.toastConfigSmallFormDeleteDirection;
				this.snackbarToastService.snackBarToast(TOAST_DELETE_MESSAGE, toastConfig);
			});
	}
	public editAddressItem(addressId: string) {
		const addressItem = this.addressListStore.addressList.find((address) => address.id === addressId);

		this.addressManagerPersistanceService.setAddressToUpdate(addressItem);
		this.newAddressManager.showNewAddressModal(FLOW_SEARCH_ADDRESS_TYPE.EDIT_ADDRESS);

		if (this.isMifaBrand) {
			this.gtm.changeAddressButton();
		}
	}

	public selectRadioAddress(addressId: number) {
		this.addressItemsForm.addressItemControl.setValue(addressId);
	}

	private validateIfNeedReload() {
		const isSubpathToReload = PATHS_TO_RELOAD.includes(getRouterPath(this.router.url));
		const isMainpath = this.router.url === "/";
		const pathLista = this.router.url.startsWith("/lista");
		const pathCategoria = this.router.url.startsWith("/categoria");
		if (isSubpathToReload || isMainpath) {
			window.location.reload();
		} else if (pathLista || pathCategoria) {
			window.location.reload();
		}
	}
	tagRadio(direccion) {
		TaggingService.checkoutRadioAddress(direccion);
	}

	detectModuleTag(value) {
		let detectedUrl = "";
		if (this.router.url === "/carrito") {
			detectedUrl = "ui_cart_" + value;
		} else if (this.router.url.startsWith("/buscador")) {
			detectedUrl = "search_" + value;
		} else if (this.router.url.startsWith("/producto")) {
			detectedUrl = "ui_product_detail_" + value;
		} else if (this.router.url.startsWith("/categoria")) {
			detectedUrl = "ui_category_" + value;
		} else if (this.router.url.startsWith("/lista")) {
			detectedUrl = "ui_lista_" + value;
		}
		return detectedUrl;
	}

	public getLocalStoragefavoriteId() {
		const localFavoriteId = localStorage.getItem(LS_ID_FAVORITE_ADDRESS);
		return localFavoriteId !== "undefined" ? parseInt(localFavoriteId) : "-1";
	}
}
