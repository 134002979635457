import {Injectable} from '@angular/core';
import {CustomerServiceService} from '../../../../../shared/service/customer-service/customer-service.service';
import {FrequentlyAskedQuestion} from '../models/frequently-asked-question-list.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StaticLandingImplementService} from '../../../services/static-landing-implement.service';

@Injectable()
export class CustomerServiceImplementService {
  constructor(
    private _customerService: CustomerServiceService,
    private _staticLandingImplement: StaticLandingImplementService
  ) {
  }

  getFrequentlyAskedQuestionList() {
    return this._staticLandingImplement.getFrequentlyAskedQuestions();
  }

  getCustomerServiceCallCenter() {
    return this._staticLandingImplement.getCallCenter();
  }

  getFrequentlyAskedQuestion(ids: Array<string>): Observable<Array<FrequentlyAskedQuestion>> {
    return this._customerService.getFrequentlyAskedQuestion(ids)
      .pipe(map(res => res.map(item => new FrequentlyAskedQuestion(item.content))));
  }

  getFrequentlyAskedQuestionBySlug(slug: string): Observable<any> {
    return this._customerService.getFrequentlyAskedQuestionBySlug(slug)
      .pipe(
        map(res => res[0] ? new FrequentlyAskedQuestion(res[0].fields) : null)
      );
  }
}
