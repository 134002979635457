import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "fp-content-find-client-initial",
	templateUrl: "./content-find-client-initial.component.html",
	styleUrls: ["./content-find-client-initial.component.scss"]
})
export class ContentFindClientInitialComponent {
	@Output() register = new EventEmitter();
	@Input() disabled = false;

	onRegister() {
		this.register.emit();
	}
}
