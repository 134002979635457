import { BehaviorSubject } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class MiniCartCloseService {

  private _closeMiniCartSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public closeMiniCart() {
    this._closeMiniCartSubject.next(true);
  }

  public get closeMiniCart$() {
    return this._closeMiniCartSubject.asObservable();
  }

}
