<div class="hexagon"
  [ngClass]="'bg-' + hexagonColor"
  [style.width]="hexagonSize - (hexagonSize * .4) + 'px'"
  [style.height]="hexagonSize + 'px'"
  [style.border-top-right-radius]="(hexagonSize  * .1)+ 'px ' +  (hexagonSize * .05) + 'px'"
  [style.border-bottom-right-radius]="(hexagonSize  * .1)+ 'px ' +  (hexagonSize * .05) + 'px'"
  [style.border-top-left-radius]="(hexagonSize  * .1)+ 'px ' +  (hexagonSize * .05) + 'px'"
  [style.border-bottom-left-radius]="(hexagonSize  * .1)+ 'px ' +  (hexagonSize * .05) + 'px'">
  <div class="hexagon-content"
    [style.width]="(hexagonSize * 1.1) + 'px'"
    [style.left]="'-'+(hexagonSize * .25) + 'px'"
    [class.display-center]="hexagonPosition === 'center'">
    <ng-content></ng-content>
  </div>
</div>
