import { MatDialogConfig } from '@angular/material/dialog';

export const CHOOSE_A_STORE_MODAL_CONFIG: MatDialogConfig = {
  width: '1066px',
  minHeight: '503px',
  panelClass: 'custom-choose-a-store-overlay',
  disableClose: true,
};

export const CHOOSE_A_STORE_MODAL_V2_CONFIG: MatDialogConfig = {
  width: '874px',
  minHeight: '485px',
  panelClass: 'custom-choose-a-store-overlay',
  disableClose: true,
};

export const NEARBY_BOTICA_MODAL_CONFIG: MatDialogConfig = {
  width: '1000px',
  height: '690px',
  panelClass: 'custom-choose-a-store-overlay',
  disableClose: true,
};

export const CHOOSE_A_STORE_RESPONSIVE_MODAL_CONFIG: MatDialogConfig = {
  width: '100vw',
  minWidth: '100vw',
  height: '100%',
  panelClass: 'custom-choose-a-store-responsive-overlay',
  disableClose: true,
};

export const CHOOSE_A_STORE_RESPONSIVE_MODAL_V2_CONFIG: MatDialogConfig = {
  width: '100vw',
  minWidth: '100vw',
  height: '100%',
  panelClass: 'custom-choose-a-store-responsive-overlay',
  disableClose: true,
};

export const WHO_PICK_UP_MODAL_CONFIG: MatDialogConfig = {
  width: '651px',
  maxWidth: 'calc( 100vw - 32px )',
  height: 'fit-content',
  panelClass: 'custom-who-pick-up-modal-overlay',
  disableClose: true,
};

