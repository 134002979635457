import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CloseEmailVerificationModalService {
  private subjectEmitVerificationClose = new Subject<boolean>();
  constructor(
  ) { }
  public emitVerificationState(status: boolean) {
    this.subjectEmitVerificationClose.next(status);
  }

  public get emitedVerificationClose$() {
    return this.subjectEmitVerificationClose.asObservable();
  }
}
