import {Component, Input, OnDestroy} from '@angular/core';
import {BreakpointBehavior} from '../../../../shared/behaviors';
import {BREAKPOINTS} from '../../../../shared/parameters/global';
import {Subscription} from 'rxjs';

@Component({
  selector: 'fp-banner-title',
  templateUrl: './banner-title.component.html',
  styleUrls: ['./banner-title.component.sass']
})
export class BannerTitleComponent implements OnDestroy {

  private isBigScreen: boolean;
  private background: string;
  private backgroundMobile: string;

  @Input() title: string;

  @Input('background')
  set background$(background: string) {
    this.background = background;
    this.updateBackground();
  }
  @Input('backgroundMobile')
  set backgroundMobile$(backgroundMobile: string) {
    this.backgroundMobile = backgroundMobile;
    this.updateBackground();
  }

  public banner: string;

  private subscriptionBreakpoint: Subscription;

  constructor(
    private _breakpointBehavior: BreakpointBehavior,
  ) {
    this.subscriptionBreakpoint = this._breakpointBehavior
      .mediaBreakpointUpObserver(BREAKPOINTS.lg)
      .subscribe(value => {
        this.isBigScreen = value.matches;
        this.updateBackground();
      });
  }

  ngOnDestroy(): void {
    this.subscriptionBreakpoint.unsubscribe();
  }

  updateBackground() {
    this.banner = this.isBigScreen ? this.background : this.backgroundMobile;
    console.log(this.banner);
  }

}
