import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../modal-components/forgot-password/forgot-password.component';

@Injectable()
export class ForgotPasswordModalService {

  constructor(
    private dialog: MatDialog
  ) { }

  public showForgotPasswordModal(): void {
    this.dialog.open(ForgotPasswordComponent, {
      width: '340px',
      disableClose: true,
    });
  }
}
