import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { GenericService } from '../../../shared/service/generic/generic.service';


@Injectable({
  providedIn: 'root'
})
export class BenefitsService {

  userSubject = new Subject<any[]>();
  messageSubject = new Subject<any>();
  url = `${environment.api_gateway_05}`;

  constructor(public http: HttpClient, private genericService: GenericService) {
  }

  points(request): Observable<any> {
    const urlb = this.url + '/benefits';
    let headers = new HttpHeaders();
    headers = headers
      .set('source', 'MIFA')
      .set('brand', 'web');

    return this.genericService.genericPostWithoutSecurityHeaders(urlb, request, null, headers);
  }
}
