export class FilteredProductsRequest {
  departmentsFilter: Array<number>;
  categoriesFilter: Array<number>;
  subcategoriesFilter: Array<number>;
  brandsFilter: Array<number>;
  ranking: number;
  page: number;
  rows: number;
  order: string;
  sort: string;
  productsFilter: Array<string>;

  constructor() {
    this.departmentsFilter = [];
    this.categoriesFilter = [];
    this.subcategoriesFilter = [];
    this.brandsFilter = [];
    this.ranking = null;
    this.page = 0;
    this.rows = 8;
    this.order = null;
    this.sort = "";
    this.productsFilter = [];
  }
}
