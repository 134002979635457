<ng-container [ngSwitch]="type">

  <ng-container *ngSwitchCase="'sold-out'">
    <span class="sold-out-label text-white bg-dark text-tag-mini">
      AGOTADO
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'low-price'">
    <span class="low-price-label text-white bg-danger text-tag">
      ¡PRECIO BAJO!
    </span>
  </ng-container>

</ng-container>
