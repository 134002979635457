import {CONTENTFUL_PATH} from '../../../shared/parameters/endpoints';
import {Asset} from 'contentful';
import {ContentfulAsset} from '../../../shared/models/contentful/contentful.model';

export interface IContentfulTitleSection {
  title: string;
  iconTitle: Asset;
  subtitle: string;
  iconSubtitle: Asset;
}

export class ContentfulTitleSection {
  title: string;
  iconTitle: string;
  subtitle: string;
  iconSubtitle: string;

  constructor(iContentfulTitleSection: IContentfulTitleSection) {
    this.title = iContentfulTitleSection.title;
    if (iContentfulTitleSection.iconTitle) {
      this.iconTitle = new ContentfulAsset(iContentfulTitleSection.iconTitle).asset;
    }
    this.subtitle = iContentfulTitleSection.subtitle;
    if (iContentfulTitleSection.iconSubtitle) {
      this.iconSubtitle = new ContentfulAsset(iContentfulTitleSection.iconSubtitle).asset;
    }
  }
}

export const CContentfulTitleSection = {
  id: CONTENTFUL_PATH.contentTypeIds.titleSection,
};
