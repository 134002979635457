import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PAYMENT_METHODS_ICON, PAYMENT_METHOD_CODE } from '@parameters/checkout/payment-method.parameters';
import { CardBannerContentfulCheckoutService } from '@service/card-banner-contentful-checkout/card-banner-contentful-checkout.service';
import { TaggingService } from 'src/app/atm-services/tagging.service';

@Component({
  selector: 'fp-select-payment-radio-button',
  templateUrl: './select-payment-radio-button.component.html',
  styleUrls: ['./select-payment-radio-button.component.sass']
})
export class SelectPaymentRadioButtonComponent {

  @Input() paymentMethodTitle = '';
  @Input() paymentMethod: number;
  @Input() showTitleBlack = false;
  @Input() showDetails = false;
  @Input() selected = false;

  @Input() isDisabled = true;
  @Input() svgName = '';
  @Input() iconSize = 32;

  @Output() selectRadioOption = new EventEmitter();

  constructor(
    private cardBannerContentful:CardBannerContentfulCheckoutService
  ) { }

  public selectOption() {
    if (!this.selected) {
      TaggingService.checkoutPaymentMethod(this.paymentMethodTitle);
      this.selectRadioOption.emit();
    }
  }
  public get paymentMethodIcon() {
    return this.cardBannerContentful.paymentMehtodIcons?.find(r => r.sumary === PAYMENT_METHODS_ICON[this.paymentMethod as PAYMENT_METHOD_CODE])?.icon;
  }
}
