import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { Subscription } from "rxjs";
import { IFormatedAddressItem } from "../../models/address-item-store.interface";

import { NewAddressManagerService } from "src/app/core/modules/address-manager/address-services/new-address-manager/new-address-manager.service";
import { CheckoutAddressItemsFormService } from "src/app/core/modules/address-manager/address-forms/checkout-address-items-form.service";
import { AddressManagerPersistanceService } from "../../address-services/address-manager-persistance.service";
import { SnackbarToastService } from "../../../snackbar/snackbar-toast.service";
import { BreakpointBehavior } from "src/app/shared/behaviors";
import { BREAKPOINTS, DISPATCH_TYPE } from "src/app/shared/parameters/global";
import { TOAST_DELETE_MESSAGE } from "../../parameters/address-list.constant";
import { AddressListStoreService } from "../../address-stores/address-list-store.service";
import { AddressImplementService } from "../../address-services/address-implement.service";
import { take } from "rxjs/operators";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { PATHS_TO_RELOAD } from "../../parameters/special-behaviors.parameter";
import { Router } from "@angular/router";
import { getRouterPath } from "src/app/shared/helpers/router-functions.helper";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { AnalyticServiceService } from "src/app/shared/service/analitycService/analitycService.service";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { ShowModalBoticaService } from "src/app/shared/service/showModalServices/showModalService.service";
import { StoresForPickUpStoreService } from "../../../pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service";
import { ProductDetail } from "src/app/business/product-detail/models/product-detail-model";
import { NearestDrugstore } from "src/app/shared/models/nearest-drugstores/nearest-drugstores.model";
import { AddressListModalComponent } from "../address-list-modal/address-list-modal.component";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { RET3_RAD_STORAGE_KEY, RET_SHORTNAME } from "src/app/business/checkout/parameters/global-config";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { ShoppingListStoreService } from "@stores/shopping-list/shopping-list-store.service";
import { ProductIsNotDeliveredComponent } from "src/app/shared/components/product-is-not-delivered/product-is-not-delivered.component";
import { LS_ADDRESS_SELECTED, LS_CURRENT_DRUGSTORE, LS_RAD_OR_RET } from "src/app/shared/service/local-storage/constants/address.constant";
import { AddressListManagerService } from "../../address-services/address-list-manager/address-list-manager.service";
@Component({
	selector: "fp-change-ret-or-rad-modal-desktop",
	templateUrl: "./change-ret-or-rad-modal-desktop.html",
	styleUrls: ["./change-ret-or-rad-modal-desktop.sass"]
})
export class changeRetOrRadModalDesktopComponent implements OnInit, OnDestroy {
	@Input() listModalTitle;
	@Input() ret: boolean = true;
	@Input() rad: boolean = true;
	@Input() selected: boolean;
	@Input() product: ProductDetail;
	hoverScreen: any;
	public selectRad = true;
	public selectRet = false;
	public checkRad = true;
	public checkRet = false;
	public selectedAddress: AddressItem = {} as AddressItem;
	private subscriptions: Subscription[] = [];
	private subscription: Subscription;
	public selectDrugstore: NearestDrugstore;
	public drugstoreName: string;
	public selectDrug = false;
	public selecAddress = false;
	public addressItemList: IFormatedAddressItem[] = [];

	private toastConfigLargeFormDeleteDirection: MatSnackBarConfig = {
		horizontalPosition: "left",
		verticalPosition: "bottom"
	};
	private toastConfigSmallFormDeleteDirection: MatSnackBarConfig = {
		verticalPosition: "top"
	};

	public isDisabledSubmitButton = false;

	private endRoute = "";

	get isDisabled() {
		return this.checkRad ? this._addressListStore.currentSelectedAddress?.street == null : !this.selectDrugstore?.name;
	}

	constructor(
		private dialogRef: MatDialogRef<changeRetOrRadModalDesktopComponent>,
		private newAddressManager: NewAddressManagerService,
		public addressItemsForm: CheckoutAddressItemsFormService,
		private addressManagerPersistanceService: AddressManagerPersistanceService,
		private snackbarToastService: SnackbarToastService,
		private _breakpointBehavior: BreakpointBehavior,
		private addressImplement: AddressImplementService,
		private router: Router,
		private serviceAnalytic: AnalyticServiceService,
		private _addressListStore: AddressListStoreService,
		private showModalBoticaService: ShowModalBoticaService,
		private storesForPickUpStore: StoresForPickUpStoreService,
		private appStoreService: AppStoreService,
		private retValidateCartService: RetValidateCartService,
		private dialog: MatDialog,
		private _shoppingListStore: ShoppingListStoreService // private _addressListModalComponent: AddressListModalComponent,
	) {}

	ngOnInit() {
		this.selectedDrugStore();
		this.getSelectedDrugStore();

		this.settingSelectedAddress();

		TaggingService.RetShowDeliveryTypeDialog(this.retValidateCartService.isRADProcess);

		this._breakpointBehavior.mediaBreakpointDownObserver(700).subscribe((value) => {
			this.hoverScreen = value.matches;
		});

		if (this.retValidateCartService.isRADProcess) {
			this.disableType(false);
		} else {
			this.disableType(true);
		}
	}

	disableType(status: boolean) {
		this.checkRet = status;
		this.checkRad = !this.checkRet;
	}

	closeModal() {
		TaggingService.RetCloseDeliveryTypeDialog();
		this.dialogRef.close();
	}

	openNewAddressModal(): void {
		this.newAddressManager.showNewAddressModal();
		this.addressManagerPersistanceService.setAddressToUpdate(null);
		this.serviceAnalytic.registerEventAnalytics(
			this.detectModuleTag("agregar_nueva_direccion"),
			"Click :: Agregar nueva direccion",
			""
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
		this.addressManagerPersistanceService.setAddressToUpdate(null);
	}

	private isBigScreen() {
		return this._breakpointBehavior.isMatchedMediaBreakpointUp(BREAKPOINTS.lg);
	}

	public deleteAddressItem(addressId: string) {
		this.addressImplement
			.deleteAddress$(addressId)
			.pipe(take(1))
			.subscribe(() => {
				if (this.selectedAddress.id === addressId) {
					this.addressImplement.loadAddressListAndSelectDefaultAddress2$().subscribe((addressList) => {
						this._addressListStore.setAddressList(addressList);
						this.validateIfNeedReload();
					});
				} else {
					this.addressImplement.getAddresses$().subscribe((addressList) => {
						this._addressListStore.setAddressList(addressList);
						this.validateIfNeedReload();
					});
				}
				const toastConfig = this.isBigScreen()
					? this.toastConfigLargeFormDeleteDirection
					: this.toastConfigSmallFormDeleteDirection;
				this.snackbarToastService.snackBarToast(TOAST_DELETE_MESSAGE, toastConfig);
			});
	}

	public editAddressItem(addressId: string) {
		const addressItem = this._addressListStore.addressList.find((address) => address.id === addressId);
		this.addressManagerPersistanceService.setAddressToUpdate(addressItem);
		this.newAddressManager.showNewAddressModal();
	}

	public selectRadioAddress(addressId: number) {
		this.addressItemsForm.addressItemControl.setValue(addressId);
	}

	private validateIfNeedReload() {
		const isSubpathToReload = PATHS_TO_RELOAD.includes(getRouterPath(this.router.url));
		const isMainpath = this.router.url === "/";
		if (isSubpathToReload || isMainpath) {
			window.location.reload();
		}
	}
	tagRadio(direccion) {
		TaggingService.checkoutRadioAddress(direccion);
	}

	detectModuleTag(value) {
		let detectedUrl = "";
		if (this.router.url === "/carrito") {
			detectedUrl = "ui_cart_" + value;
		} else if (this.router.url.startsWith("/buscador")) {
			detectedUrl = "search_" + value;
		} else if (this.router.url.startsWith("/producto")) {
			detectedUrl = "ui_product_detail_" + value;
		} else if (this.router.url.startsWith("/categoria")) {
			detectedUrl = "ui_category_" + value;
		} else if (this.router.url.startsWith("/lista")) {
			detectedUrl = "ui_lista_" + value;
		}
		return detectedUrl;
	}

	selectSection(value) {
		const isRad = value === "rad";

		TaggingService.RetClickDeliveryTypeCheckbox(isRad);
		TaggingService.RetClickDeliveryTypeDialog(isRad);

		if (isRad) {
			this.disableType(false);
		} else {
			this.canChangeToRET(
				true,
				() => {
					this.storesForPickUpStore.setSelectedDrugstore(this.selectDrugstore);
					this.disableType(true);
				},
				true
			);
		}
	}

	selectRET() {
		this.canChangeToRET(true, () => {
			this.showModalBoticaService.showModalDesktopService(this.product, null, null, false);
		});
	}

	settingSelectedAddress() {
		const currentAddreses = this._addressListStore.currentSelectedAddress;
		if (currentAddreses?.street) {
			this.selectedAddress = currentAddreses;
			this.selecAddress = true;
		}
	}

	selectedDrugStore() {
		this.subscription = this.storesForPickUpStore.setDrugstore.subscribe((drugstore) => {
			this.selectDrug = true;
			this.setDrugstore = drugstore;
		});
		this.subscriptions.push(this.subscription);
	}

	get cartHaveSeller() {
		return this._shoppingListStore.getLocalStorageShoppingCartList()?.filter((product: ProductDetail) => product.sellerId);
	}

	set setDrugstore(drugstore: NearestDrugstore) {
		if (drugstore?.name) {
			this.selectDrugstore = drugstore;
			this.drugstoreName = this.appStoreService.capitalizeString(this.selectDrugstore?.name);
		}
	}

	saveChange() {
		this.canChangeToRET(this.checkRet, () => {
			TaggingService.RetSaveDeliveryTypeDialog(this.checkRad);
			if (this.checkRad) {
				this.storesForPickUpStore.resetSelectedDrugstore();
				this.retValidateCartService.storeProcessType(RET3_RAD_STORAGE_KEY);
				this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "true");
			} else {
				this.retValidateCartService.storeProcessType(RET_SHORTNAME);
				this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "false");
				this.selectDrugstoreSaved();
			}
			if(this.selectedAddress?.name) this._addressListStore.saveAddressStorage(this.selectedAddress);
			if(this.selectDrugstore?.name) this.appStoreService.setStorageItem(LS_CURRENT_DRUGSTORE, JSON.stringify(this.selectDrugstore));
			this.closeModal();
			window.location.reload();
		});
	}

	canChangeToRET(isRET, callback, showDialog?) {
		if (isRET && this.cartHaveSeller?.length) {
			if (showDialog) this.dialog.open(ProductIsNotDeliveredComponent, { width: "450px", disableClose: true });
			return;
		}
		callback();
	}

	selectDrugstoreSaved() {
		this.selectDrugstore = JSON.parse(this.appStoreService.getStorageItem(LS_CURRENT_DRUGSTORE));
		this.appStoreService.setStorageItem(LS_RAD_OR_RET, DISPATCH_TYPE.PICKUP_IN_STORE);
		this.storesForPickUpStore.setSelectedDrugstore(this.selectDrugstore);
		this.storesForPickUpStore.setDrugstore.emit(this.selectDrugstore);
	}

	getSelectedDrugStore() {
		const drugstore = JSON.parse(this.appStoreService.getStorageItem(LS_CURRENT_DRUGSTORE));
		const selectAdress = JSON.parse(this.appStoreService.getStorageItem(LS_ADDRESS_SELECTED));

		if (!selectAdress) {
			this.selectDrug = true;
			this.setDrugstore = drugstore;
			this.disableType(true);
		} else {
			this.selecAddress = false;
			this.selectDrug = false;
			this.setDrugstore = drugstore;
			this.disableType(false);
		}
	}

  public showAddressListModal(): void {
    this.retValidateCartService.openModalAddressList();
  }
}
