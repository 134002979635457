<fp-static-landing-header-skeleton
  *ngIf="loader">
</fp-static-landing-header-skeleton>

<div class="content landing-header-content" *ngIf="!loader">
  <fp-image
    class="position-left"
    [imageSrc]="headerImage"
    [imageAlt]="headerTitle">
  </fp-image>
  <div class="ml-3 heading-3">{{headerTitle}}</div>
</div>

<div class="content" *ngIf="content">
  <p class="paragraph--1 pt-4 m-0">{{ content }}</p>
</div>
