export class AddressRequest {
  public apartment: string;
  public city: string; // "Lima"
  public country: string; // "Perú"
  public district: string; // "La Molina"
  public id: string;
  public latitude: number; // -12.072613038655128
  public longitude: number; // -76.94529452392577
  public name: string; // "Mi casa"
  public notes: string; // ""
  public number: string; // "649"
  public state: string; // ""
  public street: string; // "Avenida  Flora Tristan"
}
