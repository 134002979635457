import { Component, Inject, Input, OnInit } from "@angular/core";
import { BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { ContentfulProductList } from "../../../contentful/models/contentful-product-list.model";
import { DOCUMENT } from "@angular/common";
import { TaggingService } from 'src/app/atm-services/tagging.service';
@Component({
  selector: "fp-carousel-title-brain",
  templateUrl: "./carousel-title-brain.component.html",
  styleUrls: ["./carousel-title-brain.component.sass"],
})
export class CarouselTitleBrainComponent implements OnInit {
  @Input() list: ContentfulProductList;
  @Input() showMoreResponsive: boolean = true;
  @Input() showMoreText: string = 'Ver más productos';
  @Input() gaEvent: string;
  @Input() type: string = 'home'
  public productListPath = BUSINESS_PATHS.LIST;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
  }

  goToUrl() {
    if (this.gaEvent) {
      TaggingService.showMoreBrain(this.list.productList, this.gaEvent, this.type)
      this.document.location.href = `${this.list.slug}`;
    } else {
      this.document.location.href = `${this.list.slug}`;
    }
  }
}
