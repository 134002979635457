import { Component, Input, OnInit } from "@angular/core";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { PRODUCT_PRESCRIPTION } from "src/app/business/product-detail/parameters/product-detail.parameters";
import { EProductStatus } from "../../models/product-status.enum";

@Component({
  selector: "fp-product-information",
  templateUrl: "./product-information.component.html",
  styleUrls: ["./product-information.component.sass"],
})
export class ProductInformationComponent implements OnInit {
  public eProductStatus = EProductStatus;

  @Input() productStatus: number = this.eProductStatus.available;
  @Input() name = "";
  @Input() description = "";
  @Input() prescription = "";
  @Input() guaranteed = "";
  productPrescriptionEnum = PRODUCT_PRESCRIPTION;
  @Input() prescriptionCode = 0;

  flagOrderTaker = OrderTakerValidator.isOrderTakerEnv();
  constructor() {}

  ngOnInit() {}
}
