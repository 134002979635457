import { ISellerProductShoppingBase } from './product-shopping-cart-base.inteface';
import { ISellersProductShoppingCart } from './product-shopping-cart.interface';

export class ProductSellersShoppingCartBase {
  public sellerId: string;
  public sellerName: string;
  public deliveryCost: number;
  public products: ISellersProductShoppingCart[];

  constructor(iProductSellerShoppingCartBase: ISellerProductShoppingBase) {
      this.sellerId = iProductSellerShoppingCartBase.sellerId;
      this.sellerName = iProductSellerShoppingCartBase.sellerName;
      this.deliveryCost = iProductSellerShoppingCartBase.deliveryCost;
      this.products = iProductSellerShoppingCartBase.products;
    }
  }
