import { isObject } from "../../helpers/objects-equal";
import { ProductShoppingCartRequest } from "../shopping-cart/product-shopping-cart-request.model";

// request
export interface INearestDrugstoresRequest {
  latitude: number;
  longitude: number;
  userId?: string;
  shoppingCart: ProductShoppingCartRequest[];
  companyCode?: string;
}

// response
export interface INearestDrugstoreResponse {
  address: string;
  distance: number;
  id: number | string;
  drugstoreWareHouseId: number;
  latitude: number;
  longitude: number;
  localCode: string;
  name: string;
  defaultDrugstore: boolean;
  localOpeningHours: string;
  isActiveThird?: boolean;
}

export class NearestDrugstore {
  public address: string;
  public distance: number;
  public id: number | string;
  public latitude: number;
  public longitude: number;
  public localCode: string;
  public name: string;
  public isLoading: boolean;
  public defaultDrugstore: boolean;
  public localOpeningHours: string;
  public drugstoreWareHouseId: number;
  public isActiveThird: boolean

  constructor(data: INearestDrugstoreResponse) {
    const current = isObject(data) ? data : ({} as INearestDrugstoreResponse);
    this.address = current.address || "";
    this.distance = current.distance || 0;
    this.id = current.id || 0;
    this.drugstoreWareHouseId = current.drugstoreWareHouseId || 0;
    this.latitude = current.latitude || 0;
    this.longitude = current.longitude || 0;
    this.localCode = current.localCode || "";
    this.name = current.name || "";
    this.defaultDrugstore = current.defaultDrugstore || false;
    this.localOpeningHours = current.localOpeningHours || "";
    this.isActiveThird = current.isActiveThird || false
  }
}
