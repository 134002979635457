<div class="card-service" [ngClass]="{ disabled: isDisabled }">
  <div class="card-service__content" [ngClass]="{ 'without-switch': !switch }">
    <div class="name-status-container">
      <div class="section-name">
      <span style="color:#2F3A51; font-size: 10px; font-weight: 400;">Reparto a Domicilio</span>
      <span class="card-service__name">
        {{ name }}
      </span>
      </div>
      <ng-container *ngIf="!isLoading; else loaderSkeleton">
        <fp-chip
          class="card-service__status"
          [text]="!isDisabled ? 'Disponible' : 'No Disponible'"
          status="blue"
          [disabled]="isDisabled"
        ></fp-chip>
      </ng-container>
      <ng-template #loaderSkeleton>
        <fp-basic-skeleton
          class="basic-skeleton card-service__status"
          [height]="18"
        ></fp-basic-skeleton>
      </ng-template>
    </div>
    <!-- <ng-container *ngIf="!isLoadingDelivery; else loaderDeliverySkeleton">
      <span class="card-service__description">{{ description }}</span>
    </ng-container>
    <ng-template #loaderDeliverySkeleton>
      <fp-basic-skeleton
        class="basic-skeleton"
        [height]="36"
      ></fp-basic-skeleton>
    </ng-template> -->
  </div>
  <fp-switch
    class="card-service__switch"
    *ngIf="switch"
    inputId="ctrl--switch-service"
    [checkedValue]="valueCheck"
    [switchDisabled]="isDisabled"
    (selectedItem)="onChange()"
  >
  </fp-switch>
</div>
