import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Entry } from 'contentful';
import { ContentfulEntry } from '../../../shared/models/contentful/contentful.model';
import { IContentfulShowCase } from './contentful-showcase.model';
import { Banner } from 'src/app/business/savings-wallet/models/loginTypeItems.model';
import { IContentfulSlider } from './contentful-slider.model';
import { IContentfulBanner } from './contentful-banner.model';
import { IContentfulBannerKit } from './contentful-banner-kit.model';
import { IContentfulProductList } from './contentful-product-list.model';
import { IContentfulBannerList } from './contentful-banner-list.model';
import { IContentfulBannerProductList } from './contentful-banner-product-list.model';
import { IContentfulHeaderProductList } from './contentful-header-product-list.model';


export enum EContentfulDashboardShowcasesSection {
  searchLanding = 'Landing de Busqueda',
  categoriesLanding = 'Landing de Categorias',
  cartLanding = 'Landing del Carrito',
  productDetailLanding = 'Landing de la Ficha de Producto',
  productListLanding = 'Landing de Lista de Producto'
}

export interface IContentfulDashboardShowcases {
  title: string;
  section: EContentfulDashboardShowcasesSection;
  showcaseList: Array<Entry<IContentfulShowCase>>;
  componentes: Array<Entry<IContentfulSlider | IContentfulBanner |
                IContentfulBannerKit | IContentfulBannerList | IContentfulProductList | IContentfulBannerProductList | IContentfulHeaderProductList | any>>;
}

export class ContentfulDashboardShowcases {
  title: string;
  section: EContentfulDashboardShowcasesSection;
  showcaseList: Array<ContentfulEntry<IContentfulShowCase>> = [];
  componentes: Array<Entry<IContentfulSlider | IContentfulBanner |
                IContentfulBannerKit | IContentfulBannerList | IContentfulProductList | IContentfulBannerProductList | IContentfulHeaderProductList | any>>;


  constructor(iContentfulDashboardShowcases: IContentfulDashboardShowcases) {
    this.title = iContentfulDashboardShowcases.title || '';
    if (iContentfulDashboardShowcases.section) {
      this.section = iContentfulDashboardShowcases.section;
    }
    if (iContentfulDashboardShowcases.showcaseList) {
      this.showcaseList = iContentfulDashboardShowcases.showcaseList.map(showcaseEntry => new ContentfulEntry(showcaseEntry));
    }

    if (iContentfulDashboardShowcases.componentes) {
      this.componentes = iContentfulDashboardShowcases.componentes;
    }
  }
}

export const CContentfulDashboardShowcases = {
  id: CONTENTFUL_PATH.contentTypeIds.dashboardShowcases
};
