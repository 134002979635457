export const objectsEqual = (object1, object2) => {
  return typeof object1 === 'object' && Object.keys(object1).length > 0
    ? Object.keys(object1).length === Object.keys(object2).length
    && Object.keys(object1).every(p => objectsEqual(object1[p], object2[p]))
    : object1 === object2;
};

export const arraysEqual = (array1, array2) => {
  const type = Object.prototype.toString.call(array1);
  if (type !== Object.prototype.toString.call(array2)) {
    return false;
  }
  return array1.length === array2.length && array1.every((object, index) => objectsEqual(object, array2[index]));
};

export const isObject = (element: Object) => {
  return typeof element === 'object' && element !== null && Object.prototype.toString.call(element) === '[object Object]';
};

export const isArray = (element: Object) => {
  return typeof element === 'object' && element !== null && Object.prototype.toString.call(element) === '[object Array]';
};


export const objectHasElements = (element: Object) => {
  return element && Object.entries(element).length > 0 && isObject(element);
};

