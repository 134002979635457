<fp-static-landing-container-dialog
  (closeContainer)="closeDialog()">
  <ng-container *ngIf="termsOfService; else infoTemp">
    <fp-static-landing-header
      [loader]="loader"
      [headerTitle]="termsOfService?.title"
      [headerImage]="termsOfService?.image"
      [content]="termsOfService?.content">
    </fp-static-landing-header>
    <br>
    <fp-static-landing-expansion-list
      [loader]="loader"
      class="terms-of-service-content"
      [expansionList]="termsOfService?.items">
    </fp-static-landing-expansion-list>
  </ng-container>
  <ng-template #infoTemp>
    <div class="card informative-item-card content">
      <fp-static-landing-frequently-asked-question-skeleton
        *ngIf="loader">
      </fp-static-landing-frequently-asked-question-skeleton>
      <div class="row"
           *ngIf="!loader">
        <div class="col-12 informative-item">
          <h4 class="heading-2">{{informativeItem?.title}}</h4>
        </div>
        <div class="col-12">
          <p class="paragraph--1 m-0"
             [innerHTML]="informativeItem?.body | mdToHtml"></p>
        </div>

      </div>
    </div>
  </ng-template>
</fp-static-landing-container-dialog>
