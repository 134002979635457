import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Breadcrumbs } from "../../../../shared/models";

@Component({
  selector: "fp-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.sass"],
})
export class BreadcrumbsComponent {
  public breadcrumbs: Array<Breadcrumbs>;
  @Input() showBase = true;
  @Input() breadcrumbRoot = "";

  @Input("breadcrumbs")
  set setBreadCrumbs(breadcrumbs: Array<Breadcrumbs>) {
    if (breadcrumbs?.length > 0) {
      let breadcrumbPath = this.breadcrumbRoot;
      this.breadcrumbs = breadcrumbs.map(({ ...item }) => {
        item.keyword = `${breadcrumbPath}/${item.keyword}`;
        breadcrumbPath = item.keyword;
        return item;
      });
    }
  }

  constructor(private router: Router) {}

  onClick(breadcrumb: Breadcrumbs) {
    if (breadcrumb.queryParams) {
      this.router.navigate([breadcrumb.keyword], {
        queryParams: breadcrumb.queryParams,
      });
    } else {
      this.router.navigate([breadcrumb.keyword]);
    }
  }
}
