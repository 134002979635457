import { Injectable } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { AddressNicknameControl } from "../address-controls/address-nickname-control";
import { DirectionControl } from "../address-controls/direction-control";
import { OtherDirectionControl } from "../address-controls/other-direction-control";
import { ReferenceControl } from "../address-controls/reference-control";
import { NewAddressStoreService } from "../address-stores/new-address-store.service";
import { INITIAL_NEW_ADDRESS_FORM_STORE } from "../models/new-address-form.interface";

@Injectable({
  providedIn: "root",
})
export class NewAddressFormService {
  private direction = new DirectionControl();
  private reference = new ReferenceControl();

  private _numberControl = new FormControl("");
  private addressNickname = new AddressNicknameControl();
  private otherDirection = new OtherDirectionControl();
  private isValidAddressNickname = false;

  public form: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private newAddressStore: NewAddressStoreService
  ) {
    this.form = this.formBuilder.group({
      direction: this.direction,
      reference: this.reference,
      number: this._numberControl,
      addressNickname: this.addressNickname,
      otherDirection: this.otherDirection,
    });
    this.validateDefaultValue();
    this.validateNewAddressSaveButton();
  }

  public validateDefaultValue() {
    if (this.newAddressStore.getFormStorage().addressNickname) {
      this.addressNicknameControl.setValue(
        this.newAddressStore.getFormStorage().addressNickname
      );
    } else {
      this.addressNicknameControl.setValue(1);
    }
  }

  public enableInitialConfigs() {
    this.loadInitialFormValues();
    this.settingControlValues();
  }

  public disableInitialConfigs() {
    this.unsubscribeObservers();
  }

  public get directionControl() {
    return this.form.get("direction") as DirectionControl;
  }

  public get referenceControl() {
    return this.form.get("reference") as ReferenceControl;
  }
  public get numberControl() {
    return this.form.get("number") as FormControl;
  }
  public get addressNicknameControl() {
    return this.form.get("addressNickname") as AddressNicknameControl;
  }

  public get otherDirectionControl() {
    return this.form.get("otherDirection") as OtherDirectionControl;
  }

  private loadInitialFormValues() {
    this.newAddressStore.form$.pipe(take(1)).subscribe((form) => {
      this.form.setValue({
        direction: form.direction,
        number: null,
        reference: form.reference,
        addressNickname: form.addressNickname,
        otherDirection: form.otherDirection,
      });
    });
  }

  // this method updates the store every time the value of some control changes
  private settingControlValues() {
    const directionSubscription = this.direction.valueChanges.subscribe(
      (value) => this.newAddressStore.setDirectionValue(value as string)
    );
    const referenceSubscription = this.reference.valueChanges.subscribe(
      (value) => this.newAddressStore.setReferenceValue(value as string)
    );
    const addressNicknameSubscription =
      this.addressNickname.valueChanges.subscribe((value) =>
        this.newAddressStore.setAddressNicknameValue(value as string)
      );
    const otherDirectionSubscription =
      this.otherDirection.valueChanges.subscribe((value) =>
        this.newAddressStore.setOtherDirectionValue(value as string)
      );
    this.subscriptions.push(
      directionSubscription,
      referenceSubscription,
      addressNicknameSubscription,
      otherDirectionSubscription
    );
  }

  public resetFormControls() {
    this.form.setValue(INITIAL_NEW_ADDRESS_FORM_STORE);
  }

  public unsubscribeObservers() {
    // this.subscriptions.map(subscription => subscription.unsubscribe());
  }

  public validateAddressNickname() {
    const value = this.addressNicknameControl.value;
    this.isValidAddressNickname = value !== 3;
    if (value === 3) {
      this.otherDirectionControl.setValidators([Validators.required]);
    } else {
      this.otherDirectionControl.clearValidators();
    }
    this.otherDirectionControl.updateValueAndValidity();
  }

  private validateNewAddressSaveButton() {
    const addressNicknameSubscription =
      this.addressNicknameControl.valueChanges.subscribe((value) => {
        this.otherDirectionControl.setValue("");
        this.isValidAddressNickname = value !== 3;
        if (value === 3) {
          this.otherDirectionControl.setValidators([Validators.required]);
        } else {
          this.otherDirectionControl.clearValidators();
        }
        this.otherDirectionControl.updateValueAndValidity();
      });
    this.subscriptions.push(addressNicknameSubscription);
  }

  public get isValidForm() {
    const isValid =
      this.directionControl.valid && this.otherDirectionControl.valid;
    return isValid;
  }
}
