import { Injectable } from "@angular/core";
import { PickUpServicesModule } from "./pick-up-services.module";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ChooseAStoreModalComponent } from "../pick-up-modals/choose-a-store-modal/choose-a-store-modal.component";
import {
  CHOOSE_A_STORE_MODAL_CONFIG,
  WHO_PICK_UP_MODAL_CONFIG,
  CHOOSE_A_STORE_RESPONSIVE_MODAL_CONFIG,
  NEARBY_BOTICA_MODAL_CONFIG,
  CHOOSE_A_STORE_MODAL_V2_CONFIG,
  CHOOSE_A_STORE_RESPONSIVE_MODAL_V2_CONFIG,
} from "../parameters/modals-config.parameter";
import { WhoPickUpModalComponent } from "../pick-up-modals/who-pick-up-modal/who-pick-up-modal.component";
import { ModalsManagerHelperService } from "./modals-manager-helper.service";
import { ChooseAStoreResponsiveModalComponent } from "../pick-up-modals/choose-a-store-responsive-modal/choose-a-store-responsive-modal.component";
import { NearbyBoticaModalComponent } from "../pick-up-modals/nearby-botica-modal/nearby-botica-modal.component";
import { NearbyBoticaResponsiveModalComponent } from "../pick-up-modals/nearby-botica-responsive-modal/nearby-botica-responsive-modal.component";
import { ProductSellerCantAddedDialogComponent } from "src/app/shared/components/product-seller-cant-added-dialog/product-seller-cant-added-dialog.component";
import { ProductDetail } from "src/app/business/product-detail/models/product-detail-model";
import { ProductCard } from "../../product-card/models/product-card/product-card.model";
import { ChooseAStoreModalV2Component } from "../pick-up-modals/choose-a-store-modal-v2/choose-a-store-modal-v2.component";
import { ChooseAStoreResponsiveModalV2Component } from "../pick-up-modals/choose-a-store-responsive-modal-v2/choose-a-store-responsive-modal-v2.component";

@Injectable()
export class ModalsManagerService {
  private chooseAStoreModalRef: MatDialogRef<ChooseAStoreModalComponent>;
  private nearbyBoticaModalRef: MatDialogRef<NearbyBoticaModalComponent>;
  private nearbyBoticaResponsiveModalRef: MatDialogRef<NearbyBoticaResponsiveModalComponent>;
  private chooseAStoreResponsiveModalRef: MatDialogRef<ChooseAStoreResponsiveModalComponent>;
  private retProductCantAdded: MatDialogRef<ProductSellerCantAddedDialogComponent>;
  private whoPickUpModalRef: MatDialogRef<WhoPickUpModalComponent>;

  private chooseAStoreModalV2Ref: MatDialogRef<ChooseAStoreModalV2Component>;
  private chooseAStoreResponsiveModalV2Ref: MatDialogRef<ChooseAStoreResponsiveModalV2Component>;

  constructor(
    private dialog: MatDialog,
    private modalsManagerHelper: ModalsManagerHelperService
  ) {
    this.modalsManagerHelper.action$.subscribe((action) => {
      const _this = this;
      const { modalManagerAction } = action;
      _this[modalManagerAction]();
    });
  }

  public openChooseAStoreModal() {
    this.chooseAStoreModalRef = this.dialog.open(
      ChooseAStoreModalComponent,
      CHOOSE_A_STORE_MODAL_CONFIG
    );
  }
  public closeChooseAStoreModal() {
    this.chooseAStoreModalRef.close();
  }

  public openChooseAStoreModalV2() {
    this.chooseAStoreModalV2Ref = this.dialog.open(
      ChooseAStoreModalV2Component,
      CHOOSE_A_STORE_MODAL_V2_CONFIG
    );
  }
  public closeChooseAStoreModalV2() {
    this.chooseAStoreModalV2Ref.close();
  }

  public openChooseAStoreResponsiveModal() {
    this.chooseAStoreResponsiveModalRef = this.dialog.open(
      ChooseAStoreResponsiveModalComponent,
      CHOOSE_A_STORE_RESPONSIVE_MODAL_CONFIG
    );
  }
  public closeChooseAStoreResponsiveModal() {
    this.chooseAStoreResponsiveModalRef.close();
  }

  public openChooseAStoreResponsiveModalV2() {
    this.chooseAStoreResponsiveModalV2Ref = this.dialog.open(
      ChooseAStoreResponsiveModalV2Component,
      CHOOSE_A_STORE_RESPONSIVE_MODAL_V2_CONFIG
    );
  }
  public closeChooseAStoreResponsiveModalV2() {
    this.chooseAStoreResponsiveModalV2Ref.close();
  }

  public openNearbyBoticaModal(product, loadDrugstores?:boolean, currentDrugstore?:any, desktop?, prd?: boolean, loadPageAfterClose: boolean = true) {
    this.nearbyBoticaModalRef = this.dialog.open(NearbyBoticaModalComponent, {
      width: "650px",
      maxHeight: "484px",
      panelClass: "custom-choose-a-store-overlay",
      disableClose: true,
      data: {
        product,
        loadDrugstores,
        currentDrugstore,
        desktop,
        prd,
        loadPageAfterClose
      },
    });
  }

  public closeNearbyBoticaModal() {
    this.nearbyBoticaModalRef.close();
  }

  public openNearbyBoticaResponsiveModal(product, modalResponsive?, change?, prd?) {
    this.nearbyBoticaResponsiveModalRef = this.dialog.open(
      NearbyBoticaResponsiveModalComponent,
      {
        width: "100vw",
        minWidth: "100vw",
        height: "100%",
        panelClass: "custom-choose-a-store-responsive-overlay",
        disableClose: true,
        data: {
          product,
          modalResponsive,
          change,
          prd
        }
      }
    );
  }

  public closeNearbyBoticaResponsiveModal() {
    this.nearbyBoticaResponsiveModalRef.close();
  }

  public openWhoPickUpModal() {
    this.whoPickUpModalRef = this.dialog.open(
      WhoPickUpModalComponent,
      WHO_PICK_UP_MODAL_CONFIG
    );
  }

  public closeWhoPickUpModal() {
    this.whoPickUpModalRef.close();
  }

  openRETProductCantAdded(product: ProductDetail|ProductCard) {
    this.retProductCantAdded = this.dialog.open(ProductSellerCantAddedDialogComponent, {
      width: "450px",
      data: {
        product
      },
      panelClass: "modal-in-footer",
      disableClose: true
    });
  }

  closeRETProductCantAddedDialog() {
    this.retProductCantAdded?.close();
  }
}
