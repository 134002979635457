import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalsManagerHelperService } from './modals-manager-helper.service';
import { ModalsManagerService } from './modals-manager.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatDialogModule,
  ],
  providers: [
    ModalsManagerHelperService,
    ModalsManagerService,
  ]
})
export class PickUpServicesModule { }
