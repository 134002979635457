export enum EBrand {
  inkafarma = "inkafarma",
  mifarma = "mifarma",
}

export type TBrand = "IKF" | "MF";

export const EBrandCode = {
  [EBrand.inkafarma]: "IKF",
  [EBrand.mifarma]: "MF",
};
export const EBrandAsset = {
  [EBrand.inkafarma]: "inkafarma",
  [EBrand.mifarma]: "mifarma",
};
export const EBrandName = {
  [EBrand.inkafarma]: "Inkafarma",
  [EBrand.mifarma]: "Mifarma",
};
export const EBrandAffiliationName = {
  [EBrand.inkafarma]: "Inkaclub",
  [EBrand.mifarma]: "Monedero del ahorro",
};
