import { Component, OnInit, Input } from "@angular/core";
import { BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";

@Component({
  selector: "fp-empty-elements",
  templateUrl: "./empty-elements.component.html",
  styleUrls: ["./empty-elements.component.sass"],
})
export class EmptyElementsComponent implements OnInit {
  @Input() title: string;
  @Input() showLink = false;
  @Input() subtitle: string;
  @Input() image: string;

  constructor() {}

  ngOnInit() {}

  goToTracking() {
    window.location.href = BUSINESS_PATHS.TRACKING;
  }
}
