import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalCase'
})
export class CapitalCasePipe implements PipeTransform {

  transform(value: string | undefined | null): string {
    if (typeof value !== 'string') {
      return '';
    }
    const ABECEDARIO_EN_MINUSCULAS = 'áéíóúabcdefghijklmnñopqrstuvwxyz';
    const ABECEDARIO_EN_MAYUSCULAS = 'ÁÉÍÓÚABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
    const valueArr = value.split('');
    const firstLetter = valueArr[0];
    const minusculasArr = ABECEDARIO_EN_MINUSCULAS.split('');
    const mayusculasArr = ABECEDARIO_EN_MAYUSCULAS.split('');
    if (minusculasArr.includes(firstLetter)) {
      const index = ABECEDARIO_EN_MINUSCULAS.indexOf(firstLetter);
      valueArr[0] = mayusculasArr[index];
      value = valueArr.join('');
    }
    return value;
  }

}
