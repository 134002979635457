import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { OrderTakerValidator } from "@validators/order-taker.validator";

@Component({
  selector: "fp-section-container-title-login",
  templateUrl: "./section-container-title-login.component.html",
  styleUrls: ["./section-container-title-login.component.sass"],
})
export class SectionContainerTitleLoginComponent implements OnInit {
  public isInkaBrand = OrderTakerValidator.isOrderTakerEnv()
    ? EBrand[EBrandAsset[environment.brand]] == EBrand.inkafarma
    : EBrand[environment.brand] == EBrand.inkafarma;
  airplaneSrc: string = "/assets/images/icons/airplane.svg";
  userSrc: string = "/assets/images/icons/user.svg";
  srcImage: string = this.userSrc;
  @Input() title = "";
  @Input() iconName = "";
  @Input() withAnonymous: boolean = false;
  @Input() set iconUser(value: boolean) {
    this.srcImage = value ? this.userSrc : this.airplaneSrc;
  }
  @Output() onclick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  closeLogin() {
    this.onclick.emit();
  }
}
