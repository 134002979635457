import {Injectable} from '@angular/core';
import {StaticLandingDialogImplementService} from '../../../services/static-landing-dialog-implement.service';
import { ModalCyberWowDialogComponent } from '../../cyberwow-register/components/modal-cyber/modal-cyberwow.component';
import {TermsOfServiceDialogComponent} from '../terms-of-service-dialog/terms-of-service-dialog.component';

@Injectable()
export class TermsOfServiceDialogImplementService {
  constructor(
    private _staticLandingDialogImplement: StaticLandingDialogImplementService
  ) {
  }

  openTermsOfServiceDialog() {
    this._staticLandingDialogImplement
      .openStaticLandingDialog(TermsOfServiceDialogComponent);
  }


  openCyberWowServiceDialog() {
    this._staticLandingDialogImplement
      .openStaticMessageDialog(ModalCyberWowDialogComponent);
  }

}
