import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class AnonymousModalValidationService {

  private mainCartModalStateSubject = new Subject<boolean>();
  public mainCartModalState$ = this.mainCartModalStateSubject.asObservable();

  constructor() { }

  hideCardLoginModal() {
    this.mainCartModalStateSubject.next(false);
  }

  showCardLoginModal() {
    this.mainCartModalStateSubject.next(true);
  }
}
