<div class="content-modal">
  <div class="content-modal__header">
    <div class="d-flex align-items-center">
      <fp-icon iconSize="40" iconName="store"></fp-icon>
      <h3 class="title"><b>Selecciona una botica*</b></h3>
    </div>
    <div class="actions">
      <fp-button-icon
        class="btn-close"
        (click)="onCancel()"
        [buttonIcon]="'close-outline-gray'"
      >
      </fp-button-icon>
    </div>
  </div>
  <div class="content-modal__contain">
    <mat-divider class="mb-5"></mat-divider>
    <div class="row">
      <div class="col-lg-6 modal-form" [formGroup]="form">
        <div class="space-search-fake"></div>
        <section class="search-content">
          <app-input-search
            formControlName="searchText"
            labelClass="w-100"
            placeholder="Ingresa tu búsqueda"
            (changeValue)="addressIsChanging()"
            (onBlur)="searchBlur()"
            (onFocus)="searchFocus()"
          ></app-input-search>
          <div class="search-autocomplete-list card">
            <ng-container *ngIf="showCurrentLocationItem && isFocused">
              <fp-search-autocomplete-item
                [title]="''"
                [icon]="'info'"
                [isJustLabel]="true"
                [subTitle]="
                  'Si no encuentras tu dirección, prueba buscando primero tu provincia'
                "
                [applyDots]="false"
              >
              </fp-search-autocomplete-item>
            </ng-container>
            <ng-container *ngIf="showSearchAutocompleteList">
              <fp-search-autocomplete-item
                *ngIf="searchAutocompleteList.length === 0"
                [title]="''"
                [icon]="'info'"
                [isJustLabel]="true"
                [subTitle]="
                  'Si no encuentras tu dirección, prueba buscando primero tu provincia'
                "
                [applyDots]="false"
              >
              </fp-search-autocomplete-item>
              <fp-search-autocomplete-item
                *ngFor="let item of searchAutocompleteList"
                [icon]="item.icon"
                [title]="item.title"
                [subTitle]="item.subTitle"
                [searchItem]="item.searchItem"
                (selectItem)="locateAddressOnTheHereMap($event)"
              >
              </fp-search-autocomplete-item>
            </ng-container>
          </div>
        </section>
        <span> Solo te mostramos las boticas con el stock completo.</span>
        <div class="drugstores-scroll">
          <ng-container *ngIf="loader; else finishedLoader">
            <div class="d-flex justify-content-center">
              <app-fp-loader></app-fp-loader>
            </div>
          </ng-container>
          <ng-template #finishedLoader>
            <section
              *ngIf="listDrugstore?.length > 0; else zeroResults"
              class="drugstores-container"
            >
              <div *ngFor="let store of listDrugstore" class="option-drugstore">
                <fp-radio-drugstore
                  [data]="store"
                  (selectedDrugstore)="onSelectedDrugstore($event)"
                >
                  <app-radio
                    [radioValue]="store.id"
                    id="drugstore"
                    formControlName="idDrugstore"
                  >
                  </app-radio>
                </fp-radio-drugstore>
              </div>
            </section>
            <ng-template #zeroResults>
              <section class="zero-results">
                <fp-icon iconSize="48" iconName="heart"></fp-icon>
                <span
                  >No hay boticas con stock por aquí, realiza otra
                  búsqueda.</span
                >
              </section>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="google-map-container">
          <div class="here-map" #hereMap></div>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <fp-button class="btn-cancel" (click)="onCancel()"> Cancelar </fp-button>
    <fp-button-primary
      buttonDisplay="block"
      (click)="onSave()"
      [buttonDisabled]="form.invalid"
    >
      Aceptar
    </fp-button-primary>
  </mat-dialog-actions>
</div>
