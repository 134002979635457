import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class MainLoaderService {
  private isLoadedSubject = new BehaviorSubject<boolean>(true);
  public isLoaded$ = this.isLoadedSubject.asObservable();

  private isLoadedResponsiveSubject = new BehaviorSubject<boolean>(true);
  public isLoadedResponsive$ = this.isLoadedResponsiveSubject.asObservable();

  checkoutNeedReload = new BehaviorSubject<boolean>(false);
  public authLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  public set isLoaded(status: boolean) {
    this.isLoadedSubject.next(status);
  }

  public set isAuthLoaded(status: boolean) {
    this.authLoaded$.next(true);
  }
}
