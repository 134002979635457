<ng-container *ngIf="offerPrice">
  <div class="grid-price" [class.text-right]="direction === 'right'"
    [class.text-left]="direction === 'left'">
    <div class="text-left flex-grow-1">
      <p class="mb-0 mt-1 label-black{{fontSize}}">
        {{offerPrice | currency: 'S/ '}}
      </p>
    </div>
    <div class="text-left flex-grow-1 container-icons-card"><ng-container
        *ngFor="let image of _globalProductCardService.paymentMethodCard.imageArray;first as isFirst">
        <img   class="icon-card" [src]="image" alt="icono tarjeta inkafarma" />
      </ng-container></div>
  </div>

</ng-container>
