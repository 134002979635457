<div class="position-relative display-center w-100">

  <fp-image
    [class.card-disabled]="productStatus === eProductStatus.unavailable && isSeller"
    [imageSrc]="image"
    [imageAlt]="imageName"
    imageClass="img-fluid mh-100"
    [boxSize]="imageSize"
    class="w-100">
  </fp-image>
  <fp-tag-sold-out
    class="position-center"
    [tagSize]="tagSize"
    [isProductPack]="isProductPack"
    *ngIf="productStatus === eProductStatus.unavailable && isSeller">
  </fp-tag-sold-out>

</div>
