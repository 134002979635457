<ng-container *ngIf="selectAll">
  <div class="filter-item mb-1 mt-1">
    <mat-checkbox disableRipple class="w-100"
                  [(ngModel)]="selectAll.checked"
                  (change)="updateCheckboxAll()"
                  [disabled]="selectAll.checked">{{selectAll.name}}</mat-checkbox>
  </div>
</ng-container>


<ng-container *ngFor="let item of list; let i = index">

  <ng-container *ngIf="i < itemsInView">

    <div class="filter-item mb-1 mt-1">
      <mat-checkbox disableRipple class="w-100" [(ngModel)]="item.checked" (change)="updateCheckboxResult()">{{item.name}}</mat-checkbox>
    </div>

  </ng-container>
</ng-container>
