import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { interval } from "rxjs";
import { take } from "rxjs/operators";
import { ErrorStockModalService } from "src/app/core/modules/error-stock-product/services/error-stock-modal.service";
import { ISubTotal } from "src/app/shared/models/shopping-cart/shopping-cart-response.interfase";
import { TaggingService } from "../../../../atm-services/tagging.service";
import { PurchaseSummary } from "../../../../shared/models/shopping-cart/shopping-cart-precalculation.model";
import { BLOCKED_PURCHASE_ERROR_MESSAGE } from "../../../../shared/parameters/global-error-message";
import { ShoppingListStoreService } from "../../../../shared/stores/shopping-list/shopping-list-store.service";
import { SnackbarAlertService } from "../../../modules/snackbar/snackbar-alert.service";
import { EBrand } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { Router } from "@angular/router";

@Component({
  selector: "fp-purchase-summary-collapse",
  templateUrl: "./purchase-summary-collapse.component.html",
  styleUrls: ["./purchase-summary-collapse.component.sass"],
})
export class PurchaseSummaryCollapseComponent implements AfterViewInit {
  public brand = EBrand;
  public isTomadorBrand = OrderTakerValidator.isOrderTakerEnv();

  @Input() summary: PurchaseSummary;
  @Input() summaryCard: Array<ISubTotal>;
  @Input() disabledCards: boolean;
  @Output() buyNow = new EventEmitter();
  @Output() isCollapse = new EventEmitter();
  public isExpanded: Boolean = false;

  @ViewChild("matExpansionPanel", { static: true })
  matExpansionPanel: ElementRef;
  isAliviamedConsulta: boolean = false;

  private _document: Document = document;

  constructor(
    private shoppingListStore: ShoppingListStoreService,
    private snackbarAlert: SnackbarAlertService,
    private errorStockModal: ErrorStockModalService,
    private _appStore: AppStoreService,
    private router: Router,
  ) {

    this.isAliviamed()
  }

  ngAfterViewInit(): void {
    interval(1000)
      .pipe(take(1))
      .subscribe(() => {
        this.collapse();
        console.log(this.brand)
      });
  }

  isAliviamed() {
    if(this.shoppingListStore?.shoppingList?.length > 0 && this.shoppingListStore?.shoppingList[0]?.productAliviamed?.isMedicalConsultation){
      this.isAliviamedConsulta = true;
    }
  }

  buyNowEvent() {
    const { isValidShoppingList } = this.shoppingListStore;
    if (isValidShoppingList) {
      TaggingService.checkoutView();
      this.buyNow.emit();
    } else {
      if (OrderTakerValidator.isOrderTakerEnv())
        this.errorStockModal.openErrorStockModal();
      else
        this.snackbarAlert.snackbarAlertError(BLOCKED_PURCHASE_ERROR_MESSAGE);
    }
  }

  afterExpand() {
    this.isExpanded = true;
    this.collapse();
  }

  afterCollapse() {
    this.isExpanded = false;
    this.collapse();
  }

  collapse() {
    const { _document } = this;
    const matExpansionPanelContentHeight =
      this.matExpansionPanel.nativeElement.offsetHeight;
    const clientHeight = _document.scrollingElement.clientHeight;
    const scrollHeight = _document.scrollingElement.scrollHeight;
    const scrollTop = _document.scrollingElement.scrollTop;
    const isScrollBottom =
      clientHeight + scrollTop >= scrollHeight - matExpansionPanelContentHeight;

    const collapse = {
      isScrollBottom: isScrollBottom,
      collapsedHeight: matExpansionPanelContentHeight,
    };
    this.isCollapse.emit(collapse);
  }

  openWhatsappModal(){
    this.router.navigate(['/checkout']);
  }
}
