import { Injectable } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AddressItemControl } from "src/app/core/modules/address-manager/address-controls/address-item-control";

@Injectable({
  providedIn: "root",
})
export class StepperOfficeFormService {
  public formFirstStep: FormGroup;
  public formTwoStep: FormGroup;

  private _addressItem = new AddressItemControl();

  constructor(private _fb: FormBuilder) {
    this.formFirstStep = this._fb.group({
      addressItem: this._addressItem,
    });
    this.formTwoStep = this._fb.group({
      idDispatch: new FormControl("", Validators.required),
    });
  }

  public get addressItemControl() {
    return this.formFirstStep.get("addressItem") as AddressItemControl;
  }
}
