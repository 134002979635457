import { Injectable } from "@angular/core";
import { EBrand } from "@parameters/access/brand.parameter";
import { ClientStoreService } from "@stores/client/client-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { BehaviorSubject } from "rxjs";
import { ProductSellersShoppingCartBase } from "src/app/core/modules/product-card/models/product-shopping-cart/product-seller-shopping-cart-base.model";
import { ProductShoppingCart } from "../../../core/modules/product-card/models/product-shopping-cart/product-shopping-cart.model";
import {
	FromProductToProductShoppingCartRequest,
	ProductShoppingCartRequest
} from "../../models/shopping-cart/product-shopping-cart-request.model";
import { ShoppingCartRequest } from "../../models/shopping-cart/shopping-cart-request.model";
import { AppStoreService } from "../../stores/app/app-store.service";
import { PAYMENT_METHOD_CODE } from "@parameters/checkout/payment-method.parameters";
import { CheckoutAppAgoraPayStoreService } from "./../../../business/checkout/checkout-stores/checkout-app-agora-pay-store.service";
import { APP_AGORA_PAYMENT_CARD_ID, NUMBER_THREE } from "src/app/business/checkout/parameters/constants";
import { PaymentGatewayEnum } from "src/app/business/checkout/enum/payment-gateway.enum";
import { Router } from "@angular/router";

@Injectable()
export class ShoppingCartRequestService {
	private readonly CHECKOUT_PAYMENT_METHOD_STORE_KEY = "CPMS";

	private _shoppingCartRequestSubject: BehaviorSubject<ShoppingCartRequest> = new BehaviorSubject({} as ShoppingCartRequest);
	public shoppingCartRequest: ShoppingCartRequest = {} as ShoppingCartRequest;

	private selectedProduct = new BehaviorSubject(null);

	get getSelectedProduct() {
		return this.selectedProduct.getValue();
	}

	set setSelectedProduct(product: any) {
		this.selectedProduct.next(product);
	}

	get getPaymentMethod() {
		return JSON.parse(this.appStore.getStorageItem(this.CHECKOUT_PAYMENT_METHOD_STORE_KEY))?.paymentMethod;
	}

	get paymentGatewayWeb() {
			return this.appStore.getStorageItem("paymentGatewayWeb");
		}

	private fullFillmentCenterCode: string;
	constructor(
		private clientStore: ClientStoreService,
		private appStore: AppStoreService,
		private appAgoraPay: CheckoutAppAgoraPayStoreService,
		private router: Router
	) {
		this.shoppingCartRequest$.subscribe(
			(shoppingCartRequest: ShoppingCartRequest) => (this.shoppingCartRequest = shoppingCartRequest)
		);
	}

	public productCartToShoppingCartRequest(shoppingList: Array<ProductShoppingCart>) {
		const updateShoppingList: ProductShoppingCartRequest[] = shoppingList.map((productShoppingCart: ProductShoppingCart) => {
			return new FromProductToProductShoppingCartRequest(
				productShoppingCart.id,
				productShoppingCart.quantity,
				productShoppingCart.presentationIdSelected,
				productShoppingCart.sellerId,
				productShoppingCart.sellerName,
        		null,
        		productShoppingCart.productAliviamed
			);
		});
		return updateShoppingList;
	}

	public productCartSellerToShoppingCartRequest(shoppingSellerList: Array<ProductSellersShoppingCartBase>) {
		const updateShoppingList: ProductShoppingCartRequest[] = shoppingSellerList.map(
			(productShoppingCart: ProductSellersShoppingCartBase) =>
				new FromProductToProductShoppingCartRequest(
					productShoppingCart.products[0].id,
					productShoppingCart.products[0].quantityUnits,
					productShoppingCart.products[0].presentationIdSelected,
					productShoppingCart.sellerId,
					productShoppingCart.sellerName,
          			null,
          			productShoppingCart.products[0].productAliviamed
				)
		);
		return updateShoppingList;
	}

	public set shoppingProductListRequest(shoppingProductListRequest: Array<ProductShoppingCartRequest>) {
		this.shoppingCartRequest.products = shoppingProductListRequest;
		if (OrderTakerValidator.isOrderTakerEnv()) {
			if (this.clientStore.client) {
				this.shoppingCartRequest.firstBuyCall =
					this.appStore.brandAtention == EBrand.inkafarma
						? this.clientStore.client.isFirstBuyIKF
						: this.clientStore.client.isFirstBuyMF;
			} else this.shoppingCartRequest.firstBuyCall = false;
		}
		this.updateShoppingCartRequest = new ShoppingCartRequest(this.shoppingCartRequest);
	}

	public set updateShoppingCartRequest(shoppingCartRequest: ShoppingCartRequest) {
		const isAppAgoraPay = this.appAgoraPay.isAppAgoraPaySelected || this.getPaymentMethod === PAYMENT_METHOD_CODE.AGORA_PAY;
		const checkoutUrl = this.router.url.includes("checkout");
		if (checkoutUrl) {
			if (isAppAgoraPay) {
				shoppingCartRequest.paymentMethodId = PAYMENT_METHOD_CODE.ONLINE;
				shoppingCartRequest.paymentGateway = NUMBER_THREE;
				shoppingCartRequest.creditCardId = APP_AGORA_PAYMENT_CARD_ID;
			}
			// if(this.getPaymentMethod === PAYMENT_METHOD_CODE.ONLINE && this.paymentGatewayWeb === PaymentGatewayEnum.EPAGO){
			// shoppingCartRequest.paymentGateway = 4;
			// }
		}

		if (shoppingCartRequest.products.length > 0) {
			shoppingCartRequest.sellerName = shoppingCartRequest.products[0].sellerName;
			shoppingCartRequest.sellerId = shoppingCartRequest.products[0].sellerId;
			if (OrderTakerValidator.isOrderTakerEnv()) {
				if (this.clientStore.client) {
					this.shoppingCartRequest.firstBuyCall =
						this.appStore.brandAtention == EBrand.inkafarma
							? this.clientStore.client.isFirstBuyIKF
							: this.clientStore.client.isFirstBuyMF;
				} else this.shoppingCartRequest.firstBuyCall = false;
			}
		}
		this._shoppingCartRequestSubject.next(shoppingCartRequest);
	}

	public get shoppingCartRequest$() {
		return this._shoppingCartRequestSubject.asObservable();
	}

	public set updatePrescriptionId(prescriptionId: string) {
		this.shoppingCartRequest.prescriptionId = prescriptionId;
		this._shoppingCartRequestSubject.next(this.shoppingCartRequest);
	}
}
