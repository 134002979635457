import {Injectable} from '@angular/core';
import {SearchLandingClientService} from '../../../shared/service/search-landing/search-landing-client.service';
import {SearchLandingRequest} from '../../../shared/models/search-landing/search-landing-request.model';
import { HandleFilterAlgolioRequest, SearchLandingAlgoliaRequest } from '@models/search-landing/search-landing-algolia-request.model';

@Injectable()
export class SearchLandingImplementService {
  constructor(
    private _searchLandingClient: SearchLandingClientService
  ) {
  }

  getSearchLandingFilters(searchLandingRequest: SearchLandingRequest) {
    return this._searchLandingClient.getSearchLandingFilters(searchLandingRequest);
  }

  getSearchLandingAlgolaFilters(searchLandingRequest:SearchLandingAlgoliaRequest, indexName?, algoliaApi?, api_key?) {
    return this._searchLandingClient.getSearchLandingAlgoliaFilter(searchLandingRequest, indexName,algoliaApi, api_key);
  }

  handleSelectedRequestAlgolia(handleFilterAlgolioRequest:HandleFilterAlgolioRequest){
    return this._searchLandingClient.handleFSubmitFilterCategory(handleFilterAlgolioRequest);
  }
}
