export interface IControlErrorMessages {
  [key: string]: string;
}

export const DNI_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu DNI",
  minlength: "El número de DNI está incompleto",
  maxlength: "El número de DNI es demasiado largo",
  validateNumbers: "El DNI es inválido",
  validateDni: "El DNI es inválido",
  invalidDocument: "Este número de documento ya existe",
};

export const CE_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu Carnet de extranjería",
  minlength: "El Carnet de extranjería está incompleto",
  maxlength: "El Carnet de extranjería es demasiado largo",
  validateCharactersAndNumbers: "El Carnet de extranjería es inválido",
  invalidDocument: "Este número de documento ya existe",
};

export const PASSPORT_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu pasaporte",
  minlength: "El pasaporte está incompleto",
  maxlength: "El pasaporte es demasiado largo",
  validateCharactersAndNumbers: "El pasaporte es inválido",
  invalidDocument: "Este número de documento ya existe",
};

export const IDENTITY_DOCUMENT_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu número de documento",
  minlength: "El número de documento está incompleto",
  validateCharactersAndNumbers: "El documento es inválido",
};

export const EMAIL_ADDRESS_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu correo electrónico",
  email: "El correo electrónico es inválido",
};

export const NAME_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tus nombres y apellidos",
  validateCharacters: "El nombre es inválido",
};

export const PHONE_NUMBER_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu celular",
  minlength: "El celular está incompleto",
  validateNumbers: "El celular es inválido",
  numberPhoneValidation: "El número de celular es inválido",
};

export const DELIVERY_TYPE_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Selecciona un tipo de delivery",
};

export const RADIO_BUTTON_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Selecciona una de las opciones",
};

export const RUC_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu RUC",
  minlength: "El número de RUC es invalido",
  legalPerson: "El número de RUC es invalido",
};

export const BUSINESS_NAME_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu razón social",
};

export const LEGAL_ADDRESS_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu domicilio legal",
};

/*AFILIATION WORLD ERROR MESSAGE*/

export const CASH_PAYMENT_ERROR_MESSAGE: IControlErrorMessages = {
  required: "Ingrese el monto",
  amount: "Ingrese un monto valido",
};

export const FIRST_NAME_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tus nombres",
  validateCharacters: "Los nombres son inválidos",
};

export const LAST_NAME_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tus apellidos",
  validateCharacters: "Los apellidos son inválidos",
};

export const BIRTHDAY_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu fecha de nacimiento",
  pattern: "Ingresa una fecha válida",
  validateBeforeCurrentDate: "La fecha es inválida",
};

export const CUSTOM_BIRTHDAY_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu fecha de nacimiento",
  validateFormattedDate: "Complete una fecha válida",
  validateAdult: "Debe ser mayor de edad para registrarse",
  pattern: "Ingresa una fecha válida",
  validateBeforeCurrentDate: "La fecha es inválida",
};

export const ADRESS_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa una dirección válida",
  validateCharacters: "La dirección es inválida",
};

export const CURRENTLY_PREGNANT_MESSAGES = {
  required: "Ingresa el número de semanas",
  validateCharacters: "El número de semanas es inválida",
  pattern: "El número de semanas es inválido",
};

export const QUANTITY_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa una cantidad",
  validateQuantity: "Ingresa una cantidad valida",
};

export const GENDER_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingrese un valor",
  validateGender: "Seleccione un género",
};

export const FATHER_LAST_NAME_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu apellido paterno",
  validateCharacters: "Tu apellido paterno es inválido",
};

export const MOTHER_LAST_NAME_ERROR_MESSAGES: IControlErrorMessages = {
  required: "Ingresa tu apellido materno",
  validateCharacters: "Tu apellido materno es inválido",
};

export const FULLNAME_ERROR_MESSAGES: IControlErrorMessages = {
	required: "Ingresa tus nombres y apellidos",
	validateCharacters: "Tus nombres y apellidos son inválidos"
};

export const DAY_ERROR_MESSAGES = {
  required: "Ingresa un día válido",
  pattern: "Ingresa un día válido",
};
export const MONTH_ERROR_MESSAGES = {
  required: "Ingresa un mes válido",
  pattern: "Ingresa un mes válido",
};
export const YEAR_ERROR_MESSAGES = {
  required: "Ingresa un año válido",
  pattern: "Ingresa un año válido",
  validateBeforeCurrentYear: "Ingresa un año válido",
};

export const BLOCKED_PURCHASE_ERROR_MESSAGE = `No se puede realizar compras con productos en cero o cantidad negativa,
  cambie de dirección para ver productos con stock`;
