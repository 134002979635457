import { Injectable } from "@angular/core";
@Injectable()
export class ScheduleInit {
  'address'?: Array<Address>;
  'elements'?: Array<Schedule>;
}

class Address {
  'schedule'?: SchedulesType;
}

class SchedulesType {
  'day'?: Array<Schedule>;
  'hour'?: Array<Schedule>;
}

@Injectable()
export class Schedule {
  'id': string;
  'value': string;
  'text': string;
  'today': boolean;
  'maxCapacity': string;
}

@Injectable()
export class PaymentCard {
  id: number;
  name: string;
  favorite: boolean;
  enabled?: boolean;
  imageUrl?: string;
  creditCardList?: PaymentCard[];
  descriptionShort?: string;
  descriptionLarge?: string;
  active?: boolean;
}
