<section class="error-stock-container">
  <div class="error-stock__icon w-100" style="display: flex; justify-content: space-between;">
    <span style="width: 32px;">&nbsp;</span>
    <img src="./assets/tomador/face.png" alt="" />
    <img src="./assets/tomador/btn-close.png" width="32" height="32" alt="" style="cursor: pointer" (click)="closeModal()" />
  </div>
  <p class="error-stock__title">Venta de consulta médica</p>
  <span class="error-stock__subTitle mb-4" style="color:#6678A1"
    >La consulta médica Aliviamed no se puede vender junto a otros productos. Finaliza la venta de la consulta  Aliviamed y luego atiende los productos regulares.
    </span
  >

  <br />

  <fp-button-primary  buttonDisplay="block" (click)="closeModal()" class="mb-4 w-100"
    >Entendido</fp-button-primary
  >
</section>
