<div class="content-modal">
  <div class="content-modal__header">
    <div class="d-flex align-items-center">
      <div class="avatar">
        <fp-icon iconSize="25" [iconName]="'map'"></fp-icon>
      </div>
      <div class="title">
        <h3><b>Dirección y despacho</b></h3>
      </div>
    </div>
    <div class="actions">
      <fp-button-icon
        class="btn-close"
        (click)="onClose()"
        [buttonIcon]="'close-outline-gray'"
      >
      </fp-button-icon>
    </div>
  </div>
  <div class="content-modal__contain">
    <mat-divider class="mb-5"></mat-divider>
    <div class="w-100 d-flex justify-content-between mb-3">
      <div [ngSwitch]="stepSelected">
        <div *ngSwitchCase="1">
          <span><b>Dirección para el despacho:</b></span>
        </div>
        <div *ngSwitchCase="2">
          <span
            ><b>Dirección para el despacho: {{ street }}</b></span
          >
        </div>
      </div>
      <fp-stepper
        *ngIf="totalSteps > 1"
        [total]="totalSteps"
        [step]="stepSelected"
      >
      </fp-stepper>
    </div>
    <mat-horizontal-stepper linear #stepper>
      <mat-step>
        <div class="step-container">
          <fp-step-one
            [form]="_stepperForm.formFirstStep"
            [client]="_clientStore.client"
          ></fp-step-one>
        </div>
      </mat-step>
      <mat-step>
        <fp-step-two
          *ngIf="stepSelected === 2"
          [form]="_stepperForm.formTwoStep"
          [client]="_clientStore.client"
          [retOrRad]="this.data?.retOrRad"
        ></fp-step-two>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <mat-dialog-actions align="end">
    <div [ngSwitch]="stepSelected">
      <div class="step-one d-flex" *ngSwitchCase="1">
        <fp-button class="btn-cancel" (click)="onClose()"> Cancelar </fp-button>
        <fp-button-primary
          buttonDisplay="block"
          [buttonDisabled]="_presenter.isLoading"
          (click)="onNext()"
        >
          Siguiente
        </fp-button-primary>
      </div>
      <div class="step-two d-flex" *ngSwitchCase="2">
        <fp-button class="btn-cancel" (click)="onPrevious()"> Atrás </fp-button>
        <fp-button-primary
          [buttonDisabled]="_presenter.isLoading"
          buttonDisplay="block"
          (click)="onSave()"
        >
          Aceptar
        </fp-button-primary>
      </div>
    </div>
  </mat-dialog-actions>
</div>
