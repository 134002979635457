export interface INewAddressFormStore {
  direction: string;
  reference: string;
  mz: string;
  lote: number;
  addressNickname: number;
  otherDirection: string;
}

export const INITIAL_NEW_ADDRESS_FORM_STORE: INewAddressFormStore = {
  direction: "",
  reference: "",
  mz: "",
  lote: null,
  addressNickname: 1,
  otherDirection: "",
};
