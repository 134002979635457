import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
// import {Entry} from 'contentful';
import { Asset } from 'contentful';
import { ContentfulAsset } from '../../../shared/models/contentful/contentful.model';
import { IContentfulProduct } from './contentful-product.model';
import { ProductCard } from '../../modules/product-card/models/product-card/product-card.model';

export interface IContentfulFeaturedProduct {
  title: string;
  iconTitle: Asset;
  description: string;
  template: string;
  list_products: Array<IContentfulProduct>;
  skus: Array<string>;
  imageDesktop: Asset;
  imageResponsive: Asset;
  linkTitle: string;
  linkUrl: string;
  colorBackgroundBanner:string;
  style:string;
}

export enum EContentfulFeaturedProduct {
  template1 = 'Plantilla 1',
  template2 = 'Plantilla 2',
  templateDefault = 'Plantilla 1'
}

export class ContentfulFeaturedProduct {
  title: string;
  iconTitle: string;
  description: string;
  template: string;
  productIdList: Array<string>;
  productList: Array<ProductCard>;
  smallImage: string;
  largeImage: string;
  linkTitle: string;
  linkUrl: string;
  colorBackgroundBanner:string;
  style:string;
  constructor(iContentfulFeaturedProduct: IContentfulFeaturedProduct) {
    this.title = iContentfulFeaturedProduct.title;
    if (iContentfulFeaturedProduct.iconTitle) {
      this.iconTitle = new ContentfulAsset(iContentfulFeaturedProduct.iconTitle).asset;
    }
    this.description = iContentfulFeaturedProduct.description;
    this.template = iContentfulFeaturedProduct.template || EContentfulFeaturedProduct.templateDefault;
    this.productIdList = iContentfulFeaturedProduct.skus;
    if (iContentfulFeaturedProduct.imageDesktop) {
      this.largeImage = new ContentfulAsset(iContentfulFeaturedProduct.imageDesktop).asset;
    }
    if (iContentfulFeaturedProduct.imageResponsive) {
      this.smallImage = new ContentfulAsset(iContentfulFeaturedProduct.imageResponsive).asset;
    }
    this.linkTitle = iContentfulFeaturedProduct.linkTitle;
    this.linkUrl = iContentfulFeaturedProduct.linkUrl;
    this.colorBackgroundBanner = iContentfulFeaturedProduct.colorBackgroundBanner;
    this.style = iContentfulFeaturedProduct.style;
  }
}

export const CContentfulFeaturedProduct = {
  id: CONTENTFUL_PATH.contentTypeIds.featuredProduct,
};
