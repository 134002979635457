import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { Subscription } from "rxjs";
import { AddressDispatchManagerPresenter } from "src/app/core/modules/address-dispatch-manager/presenter/address-dispatch-manager.presenter";
import { AddressDrugstoreDispatchService } from "src/app/core/modules/address-drugstore-dispatch/service/address-drugstore-dispatch.service";
import { FLOW_SEARCH_ADDRESS_TYPE } from "src/app/core/modules/address-manager/parameters/modal.constant";
import { Client } from "src/app/shared/models/client/client.model";

@Component({
	selector: "fp-step-two",
	templateUrl: "./step-two.component.html",
	styleUrls: ["./step-two.component.sass"]
})
export class StepTwoComponent implements OnInit, OnDestroy {
	@Input() form: FormGroup;
	@Input() client: Client;
	@Input() retOrRad: string;
	subs$: Subscription = new Subscription();

	constructor(
		public _presenter: AddressDispatchManagerPresenter,
		private _addressDispatchService: AddressDrugstoreDispatchService,
		private router: Router
	) {}

	ngOnDestroy(): void {
		this.subs$.unsubscribe();
		this._presenter.onExit();
	}

	ngOnInit(): void {
		const checkoutUrl = this.router.url.includes("checkout");
		if (checkoutUrl && OrderTakerValidator.isOrderTakerEnv()) {
			this._presenter.validateFlow(FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS);
		} else {
			this._presenter.validateFlow(FLOW_SEARCH_ADDRESS_TYPE.EDIT_ADDRESS);
		}
		this.subscribeResetData();
	}

	subscribeResetData() {
		const sub = this._addressDispatchService.resetData$.subscribe((r) => {
			if (r) {
				this._presenter.onReload();
			}
		});
		this.subs$.add(sub);
	}

	get idDispatchControl(): FormControl {
		return this.form.controls["idDispatch"] as FormControl;
	}
}
