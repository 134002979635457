import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeClientService } from '../../../shared/service/home/home-client.service';
import { map } from 'rxjs/operators';
import { IProductDefault } from '../../../shared/models/product-default/product-default.interface';
import { ProductDefault } from '../../../shared/models/product-default/product-default.model';
import { ContentfulDashboard } from '../../../core/contentful/models/contentful-dashboard.model';
import { ContentfulProductList } from '../../../core/contentful/models/contentful-product-list.model';


@Injectable({
  providedIn: 'root'
})
export class HomeImplementService {
  @Output() searchProduct: EventEmitter<any> = new EventEmitter();

  constructor(private _HomeClientService: HomeClientService) {
  }

  public getHome(): Observable<ContentfulDashboard> {
    return this._HomeClientService.getDashboardHome()
      .pipe(
        map(dashboard => new ContentfulDashboard(dashboard[0].content))
      );
  }

  public getPredictivo(): Observable<any> {
    return this._HomeClientService.getDashboardPredictivoLandingApp();
  }

  public getContenidosBrainHome(path, sectionID, client?) {
    return this._HomeClientService.getContenidosBrainHome(path, sectionID, client).pipe();
  }

  public getProductsByIds(ids: Array<string>) {
    return this._HomeClientService.getProductsByIds(ids)
      .pipe(
        map((productsDefault: Array<IProductDefault>) => productsDefault.map(productDefault => new ProductDefault(productDefault)))
      );
  }

  public getProductListBySlug(slug: string) {
    return this._HomeClientService.getProductListBySlug(slug)
      .pipe(
        map(productList => productList[0] ? new ContentfulProductList(productList[0].content) : null)
      );
  }

  public getPurchaseIntent(){
    return this._HomeClientService.getPurchaseIntent().pipe();
  }

  public getPurchaseIntentTomador(clientId){
    return this._HomeClientService.getPurchaseIntentTomador(clientId).pipe();
  }

}
