<div class="content" [ngClass]="{'p-28': !unfilled}">
	<div class="title-form" *ngIf="iconName || title">
		<div class="checkout-title-with-icon font-bold">
			<span *ngIf="iconName || svgName">
				<fp-icon [fontName]="iconName" [iconName]="svgName" [iconSize]="iconSize"></fp-icon>
			</span>
			<!-- <span *ngIf="iconName" class="icon icon-24-{{ iconName }}"></span> -->
			<p *ngIf="title" class="heading heading-lg-3 font-VAGRoundedNexxt-SemiBold" [innerHTML]="title"></p>
		</div>
	</div>
</div>
