import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { BREAKPOINTS } from '../../../../../shared/parameters/global';
import { Subscription } from 'rxjs';
import { BreakpointBehavior } from '../../../../../shared/behaviors';

@Component({
  selector: 'fp-product-favorite',
  templateUrl: './product-favorite.component.html',
  styleUrls: ['./product-favorite.component.sass']
})
export class ProductFavoriteComponent implements OnChanges, OnDestroy {
  public loader: boolean;
  public isBigScreen: boolean;
  private _subscriptionBreakpoint: Subscription;
  public hideProductFavorite = true;

  @Input() favorite: boolean;
  @Input() favoriteSize: 'small' | 'large' = 'small';

  @Output() addFavorite = new EventEmitter();
  @Output() removeFavorite = new EventEmitter();

  constructor(
    private _breakpointBehavior: BreakpointBehavior,
  ) {
    this._subscriptionBreakpoint = this._breakpointBehavior.mediaBreakpointUpObserver(BREAKPOINTS.lg)
      .subscribe(value => this.isBigScreen = value.matches);
  }

  ngOnChanges(changes): void {
    if (changes.favorite) {
      this.loader = false;
    }
  }

  ngOnDestroy(): void {
    this._subscriptionBreakpoint.unsubscribe();
  }

  addToFavorite() {
    this.loader = true;
    this.addFavorite.emit();
  }

  removeFromFavorite() {
    this.loader = true;
    this.removeFavorite.emit();
  }

}
