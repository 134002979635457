export interface ITagValue {
  [key: string]: string | number | boolean;
}

export enum ECOLORS {
  BLUE = 'blue',
  DARKBLUE = 'darkblue',
  PURPLE = 'purple',
}

export const COLORS = [ECOLORS.BLUE, ECOLORS.DARKBLUE, ECOLORS.PURPLE];
