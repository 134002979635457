import { Injectable } from "@angular/core";
import { ProductShoppingCartRequest } from "@models/shopping-cart/product-shopping-cart-request.model";
import { ShoppingListStoreService } from "@stores/shopping-list/shopping-list-store.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CartRecoverPendingItemsService } from "src/app/shared/service/cart-recover-pending-items/cart-recover-pending-items.service";
import { BAGS_BRAND } from '@parameters/global';
import { OrderTakerValidator } from './../../../shared/validators/order-taker.validator';

@Injectable()
export class RecovePendingItemsImplementsService {
  constructor(
    private _shoppingListStore: ShoppingListStoreService,
    private _cartRecoverPendingItems: CartRecoverPendingItemsService
  ) {}

  updateShoppingListStoreWithPendingItems(
    idClient: string
  ): Observable<ProductShoppingCartRequest[]> {
    return this._cartRecoverPendingItems.getRecoverPendingItems(idClient).pipe(
      map((pendingItems: ProductShoppingCartRequest[]) => {
        let items = Array.isArray(pendingItems) ? pendingItems : [];
        if (items.length > 0) {

          /*
            Se quitan las bolsas del cart
          */
          if (OrderTakerValidator.isOrderTakerEnv()) {
            const bagsExcludes = [...BAGS_BRAND.inkafarma,...BAGS_BRAND.mifarma];
            items = items.filter(r=>!bagsExcludes.includes(r.productId));
          }

          this._shoppingListStore.updateShoppingList(items);
        }

        return items;
      })
    );
  }
}
