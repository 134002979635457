import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { WhatsappSaleModalComponent } from "../Component/whatsapp-sale.component";
import { Product } from "../../product-card/models/product.model";

@Injectable({
  providedIn: "root",
})
export class WhatsappModalService {
  constructor(private dialog: MatDialog) {}

  showModalConsultation(productId: string) {
    setTimeout(() => {
      this.dialog.open(WhatsappSaleModalComponent, {
        disableClose: true,
        panelClass: "custom-wsp-x-modal-overlay",
        data: {
          productId: productId
        }
      });
    }, 100);
  }
  closeModal() {
    this.dialog.closeAll();
  }
}
