<div class="purchase-summary-list text-dark">

  <div class="row subtotal"
       *ngIf="summary?.subtotal">
    <div class="col label--1">Subtotal</div>
    <div class="col-4 text-right label--1">{{summary.subtotal || 0 | currency: 'S/ '}}</div>
  </div>

  <div class="row delivery-cost"
       *ngIf="summary?.deliveryCost">
    <div class="col label--1">Costo de envío</div>
    <div class="col-4 text-right label--1">{{summary.deliveryCost || 0 | currency: 'S/ '}}</div>
  </div>

  <div class="row tax-ICBPER"
       *ngIf="summary?.bagTax">
    <div class="col label--1">Impuesto ICBPER</div>
    <div class="col-4 text-right label--1">{{summary.bagTax || 0 | currency: 'S/ '}}</div>
  </div>

  <ng-container *fpBrandAccess="[brand.mifarma]">
    <div class="row text-danger discount"
        *ngIf="summary.discountedAmountCanonical?.discountAmountAdditionals">
      <div class="col label--1">Descuentos Mifarma</div>
      <div class="col-4 text-right label--1">{{summary.discountedAmountCanonical.discountAmountAdditionals || 0 | currency: '- S/ '}}</div>
    </div>
  </ng-container>

  <ng-container *fpBrandAccess="[brand.inkafarma]">
    <div class="row text-danger discount"
         *ngIf="summary?.discount">
      <div class="col label--1">Descuentos adicionales</div>
      <div class="col-4 text-right label--1">{{summary.discount || 0 | currency: '- S/ '}}</div>
    </div> 
  </ng-container>

  <div class="row round"
       *ngIf="summary?.totalRound">
    <div class="col label--1">Redondeo</div>
    <div class="col-4 text-right label--1">{{summary.totalRound || 0 | currency: 'S/ '}}</div>
  </div>

</div>
