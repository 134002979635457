import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ERROR_MONTO_TOTAL, GENERIC_ERROR_TITLE } from "../../../parameters/modal-error-messages.constant";

@Component({
  selector: "fp-handle-error-change-price-modal",
  templateUrl: "./handle-error-change-price-modal.component.html",
  styleUrls: ["./handle-error-change-price-modal.component.sass"],
})
export class HandleErrorChangePriceModalComponent implements OnInit {
  public title = GENERIC_ERROR_TITLE;
  public subtitle = ERROR_MONTO_TOTAL;
  constructor(
    public _matDialogRef: MatDialogRef<HandleErrorChangePriceModalComponent>
  ) {}

  ngOnInit() {
  }

  closeModal() {
    this._matDialogRef.close();
  }

}
