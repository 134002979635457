import { Component, OnInit, Input, Output, EventEmitter, ContentChild, ElementRef, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { IStoreToChoose } from '../../interfaces/choose-a-store.interface';
import { environment } from '@environments/environment';
import { EBrand } from '@parameters/access/brand.parameter';

export enum EStoreForRetBorders {
  DEFAULT = 'default',
}

@Component({
  selector: 'fp-store-for-ret-radio-button',
  templateUrl: './store-for-ret-radio-button.component.html',
  styleUrls: ['./store-for-ret-radio-button.component.sass']
})
export class StoreForRetRadioButtonComponent implements OnInit, AfterContentInit {

  @ContentChild('inputRadioButton', { static: false }) inputRadioButton: ElementRef<HTMLDivElement>;
  public checkout: boolean;

  @Input() storeToChoose: IStoreToChoose = {} as IStoreToChoose;
  @Input() selected = false;
  @Input() isResponsive = false;
  @Input() customBorder = EStoreForRetBorders.DEFAULT;

  public StoreForRetBorders = EStoreForRetBorders;

  @Output() selectRadioOption = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit() {
    this.validateChackout();
  }

  ngAfterContentInit() {
  }

  validateChackout() {
    if (this.router.url === '/checkout') {
      this.checkout = true;
    } else {
      this.checkout = false;
    }
  }

  public selectOption() {
    if (!this.selected) {
      this.selectRadioOption.emit();
    }
  }
  
  get getIconName(){
    return environment.brand === EBrand.mifarma ? 'time-purple': 'blue-clock'
  }
}
