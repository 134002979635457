import { Injectable } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { GoogleMapGeocoderService } from './google-map-geocoder.service';
import { GoogleMapService } from './google-map.service';
import { PREDICTIVE_MATCHES_LENGHT } from '../parameters/global.parameter';
import { GeolocationService, GEO_STATUS_TYPES } from 'src/app/shared/geolocation/geolocation.service';
import { CoverageLocationImplementService } from './coverage-location-implement.service';
import { AddressItem } from 'src/app/shared/models/address/address.model';
import { IPosition, Position } from 'src/app/shared/models/coverage-location/coverage-location.model';

export interface IGoogleFormattedAddress {
  completedFormattedAddressName: string;
  formattedAddressName: string;
  formattedBasicAddress: string;
  addressLocality: string;
  location: google.maps.LatLng;
  geometry: google.maps.places.PlaceGeometry;
  streetNumber: string;
  country: string;
  department: string;
  province: string;
  district: string;
}

export interface ICustomPlaceResultInformation {
  basicAddress: string;
  name: string;
}

@Injectable()
export class GoogleMapPlacesService {

  public placesService: google.maps.places.PlacesService;

  constructor(
    private googleMap: GoogleMapService,
    private googleMapGeocoderService: GoogleMapGeocoderService,
    private geolocationService: GeolocationService,
    private coverageLocationImplement: CoverageLocationImplementService,
  ) { }

  public loadPlacesServices() {
    this.placesService = new google.maps.places.PlacesService(this.googleMap.map);
  }

  private get PERU_BOUNDS() {
    const { sw_peru, ne_peru } = this.googleMap.PERU_BOUNDS;
    const swPERU = new google.maps.LatLng(sw_peru.lat, sw_peru.lng);
    const nePERU = new google.maps.LatLng(ne_peru.lat, ne_peru.lng);
    return new google.maps.LatLngBounds(swPERU, nePERU);
  }

  public textSearch$(query: string) {
    this.loadPlacesServices();
    return this.getMatchedPlaces$(this.placesService, PREDICTIVE_MATCHES_LENGHT, this.PERU_BOUNDS, query);
  }

  public textSearch2$(query: string, element: HTMLDivElement) {
    const placesService = new google.maps.places.PlacesService(element);
    const peruBounds = this.googleMap.getStaticPeruBounds();
    return this.getMatchedPlaces$(placesService, PREDICTIVE_MATCHES_LENGHT + 1, peruBounds, query);
  }

  public getGeocodeResult$(lat: number, lng: number) {
    const lag_lng = new google.maps.LatLng(lat, lng);
    return this.googleMapGeocoderService.getGeocodeReverse(lag_lng)
      .pipe(map(results => {
        return results.length > 0 ? this.getFormattedSimpleGeocoderResult(results) : {} as IGoogleFormattedAddress;
      }));
  }

  private getMatchedPlaces$(
    placesService: google.maps.places.PlacesService,
    numberOfMatches: number,
    bounds: google.maps.LatLngBounds,
    query: string,
  ) {
    const request = { query, fields: ['geometry', 'name', 'formatted_address'] } as google.maps.places.FindPlaceFromQueryRequest;
    return new Observable<google.maps.places.PlaceResult[]>((observer) => {
      placesService.findPlaceFromQuery(request, (places, status) => {
        places.splice(numberOfMatches);
        const statusResults = {
          'INVALID_REQUEST': [],
          'NOT_FOUND': [],
          'OK': places,
          'OVER_QUERY_LIMIT': [],
          'REQUEST_DENIED': [],
          'UNKNOWN_ERROR': [],
          'ZERO_RESULTS': [],
        };
        observer.next(statusResults[status]);
      });
    });
  }

  public getDetailedAddresses$(query: string) {
    return this.textSearch$(query)
      .pipe(switchMap(placeResults => {
        if (placeResults.length > 0) {
          const { getGeocodeReverse } = this.googleMapGeocoderService;
          const addressGeocodeReverses = placeResults.map(result => getGeocodeReverse(result.geometry.location));
          return combineLatest(addressGeocodeReverses)
            .pipe(map((resultsOfGeocoderResults) => {
              const formatedAddresses = resultsOfGeocoderResults.map((geocoderResults, index) => {
                const placeResult = placeResults[index];
                const customPlaceResult = this.getCustomPlaceResultInformation(placeResult);
                return this.getFormattedGeocoderResults(geocoderResults, placeResult.geometry.location, customPlaceResult);
              });
              return formatedAddresses.filter(address => address.formattedAddressName);
            }));
        } else {
          return of([] as IGoogleFormattedAddress[]);
        }
      }));
  }

  private getCustomPlaceResultInformation(placeResult: google.maps.places.PlaceResult) {
    const formattedAddressArray = placeResult.formatted_address.split(',');
    const basicAddress = formattedAddressArray.length > 0 ? formattedAddressArray[0] : '';
    const name = placeResult.name;
    return {
      basicAddress, name,
    } as ICustomPlaceResultInformation;
  }

  public getDetailedAddress$(location: google.maps.LatLng) {
    const { getGeocodeReverse } = this.googleMapGeocoderService;
    return getGeocodeReverse(location)
      .pipe(map(geocoderResults => this.getFormattedGeocoderResults(geocoderResults, location)));
  }

  private getFormattedGeocoderResults(geocoderResults: google.maps.GeocoderResult[], location: google.maps.LatLng, customPlaceResult?: ICustomPlaceResultInformation) {
    const {
      getGeocodeReverseCountry, getGeocodeReverseDepartment,
      getGeocodeReverseProvince, getGeocodeReverseDistrict,
      getGeocodeReverseStreetAddress, getGeocodeReverseStreetNumber
    } = this.googleMapGeocoderService;
    const country = getGeocodeReverseCountry(geocoderResults);
    const department = getGeocodeReverseDepartment(geocoderResults);
    const province = getGeocodeReverseProvince(geocoderResults);
    const district = getGeocodeReverseDistrict(geocoderResults);
    const geocoderResultAddress = getGeocodeReverseStreetAddress(geocoderResults);
    const addressName = geocoderResultAddress.formatted_address ? geocoderResultAddress.formatted_address.split(',')[0] : '';
    const streetNumber = geocoderResultAddress.address_components ? getGeocodeReverseStreetNumber(geocoderResultAddress.address_components) : '';
    return {
      location,
      completedFormattedAddressName: `${customPlaceResult ? customPlaceResult.basicAddress : addressName}${district ? ', ' + district : ''}${province ? ', ' + province : ''}`,
      formattedAddressName: `${customPlaceResult ? customPlaceResult.name : addressName}`,
      formattedBasicAddress: customPlaceResult ? customPlaceResult.basicAddress : addressName,
      addressLocality: `${customPlaceResult ? customPlaceResult.basicAddress : addressName}${district ? ', ' + district : ''}${province ? ', ' + province : ''}`,
      streetNumber,
      country,
      department,
      province,
      district,
    } as IGoogleFormattedAddress;
  }

  private getFormattedSimpleGeocoderResult(geocoderResults: google.maps.GeocoderResult[]) {
    const {
      getGeocodeReverseCountry, getGeocodeReverseDepartment,
      getGeocodeReverseProvince, getGeocodeReverseDistrict,
      getGeocodeReverseStreetAddress, getGeocodeReverseStreetNumber
    } = this.googleMapGeocoderService;
    const country = getGeocodeReverseCountry(geocoderResults);
    const department = getGeocodeReverseDepartment(geocoderResults);
    const province = getGeocodeReverseProvince(geocoderResults);
    const district = getGeocodeReverseDistrict(geocoderResults);
    const geocoderResultAddress = getGeocodeReverseStreetAddress(geocoderResults);
    const addressName = geocoderResultAddress.formatted_address ? geocoderResultAddress.formatted_address.split(',')[0] : '';
    const streetNumber = geocoderResultAddress.address_components ? getGeocodeReverseStreetNumber(geocoderResultAddress.address_components) : '';
    return {
      completedFormattedAddressName: `${addressName}${district ? ', ' + district : ''}${province ? ', ' + province : ''}`,
      formattedAddressName: addressName,
      streetNumber,
      country,
      department,
      province,
      district,
    } as IGoogleFormattedAddress;
  }

  public loadGoogleMapConfigToUpdate$(element: HTMLDivElement, addressToUpdate?: AddressItem) {
    return this.googleMap.loadGoogleMapConfig$(element, addressToUpdate)
      .pipe(switchMap(() => {
        const position = { latitude: addressToUpdate.latitude, longitude: addressToUpdate.longitude } as IPosition;
        const validatedCoverage$ = this.coverageLocationImplement.getValidatedCoverage$(position);
        const formattedGeolocationAddress$ = this.getFormattedGeolocationAddress$();
        return combineLatest([validatedCoverage$, formattedGeolocationAddress$])
          .pipe(map(([validatedCoverage, formattedGeolocationAddress]) => {
            return {
              validatedCoverage, formattedGeolocationAddress,
            };
          }));
        // return this.coverageLocationImplement.getValidatedCoverage$(position);
      }));
  }

  public getFormattedGeolocationAddress$() {
    return this.geolocationService.getGeolocationStatus()
      .pipe(switchMap(status => {
        if (status.state === GEO_STATUS_TYPES.ACCEPTED) {
          return this.getGeolocationPosition$()
            .pipe(switchMap((position:any) => {
              const { latitude, longitude } = position.coords;
              const lat_lng = new google.maps.LatLng(latitude, longitude);
              return this.getDetailedAddress$(lat_lng);
            }));
        } else {
          return of({} as IGoogleFormattedAddress);
        }
      }));
  }

  private getGeolocationPosition$() {
    const position$ = new Observable<GeolocationPosition>(observer => {
      this.geolocationService.existNavigatorGeolocation
        .getCurrentPosition((position:any) => {
          observer.next(position);
        });
    });
    return position$;
  }
}
