import { Component, Input } from "@angular/core";
import { EProductStatus } from "src/app/core/modules/product-card/models/product-status.enum";
import { GlobalProductCardService } from "../../service/global-product-card.service";

@Component({
	selector: "fp-product-cart-price",
	templateUrl: "./product-cart-price.component.html",
	styleUrls: ["./product-cart-price.component.sass"]
})
export class ProductCartPriceComponent {
	public eProductStatus = EProductStatus;

	@Input() subTotal: number;
	@Input() subTotalOffer: number;
	@Input() productStatus: number = this.eProductStatus.available;
	@Input() regularPrice: number;
	@Input() crossOutPL = false;

	constructor(public _globalProductCardService: GlobalProductCardService) {}

	trackByImage(index: number, urlImage: string): string {
		return urlImage;
	}
}
