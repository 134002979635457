import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fp-button-email-password',
  templateUrl: './button-email-password.component.html',
  styleUrls: ['./button-email-password.component.sass']
})
export class ButtonEmailPasswordComponent implements OnInit {
  @Input() text: string;
  @Input() id: '' = "";
  @Output() onclick = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  openEmailPassoword() {
    this.onclick.emit();
  }

}
