import { FormControl, Validators } from '@angular/forms';

export class PosPaymentTypeControl extends FormControl {

  constructor() {
    super('');
    this.settingPosPaymentTypeValidators();
  }

  private settingPosPaymentTypeValidators() {
    this.setValidators([
        Validators.required,
    ]);
  }

}
