import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AddressItemControl } from 'src/app/core/modules/address-manager/address-controls/address-item-control';

@Injectable()
export class CheckoutAddressItemsFormService {

  public form: FormGroup;

  private _addressItem = new AddressItemControl();

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      addressItem: this._addressItem
    });
  }

  public get addressItemControl() {
    return this.form.get('addressItem') as AddressItemControl;
  }
}
