<div *ngIf="!show" class="input-mask">
  <input
    class="input-basic placeholder-dark-gray"
    [value]="mask"
    readonly/>

  <img
    class="icon"
    src="assets/icons/pencil-outline.svg"
    (click)="toggle()"/>
</div>

<div *ngIf="show">
  <ng-content></ng-content>

  <img
    class="icon"
    src="assets/icons/close-outline.svg"
    (click)="toggle()"/>
</div>