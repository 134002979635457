import { Category } from './category.model';
import { IDeparment } from './deparment.interface';

export class Department {

  public departmentId: number;
  public departmentName: string;
  public departmentKeyword: string;
  public categories: Category[];

  constructor(department: IDeparment) {
    this.departmentId = department.id || -1;
    this.departmentName = department.name || '';
    this.departmentKeyword = department.keyword || '';
    this.categories = department.categories.length > 0 ? department.categories.map(cat => new Category(cat)) : [];
  }
}
