import { NearestDrugstore } from './../../../../shared/models/nearest-drugstores/nearest-drugstores.model';
import { Injectable } from "@angular/core";

import { NearestDrugstoresClientService } from "src/app/shared/service/nearest-drugstores/nearest-drugstores-client.service";
import { INearestDrugstoresRequest } from "src/app/shared/models/nearest-drugstores/nearest-drugstores.model";
import { of } from "rxjs";

@Injectable()
export class NearestDrugstoresImplementService {
  constructor(
    private nearestDrugstoresClient: NearestDrugstoresClientService
  ) {}

  public getDrugstores$(params: INearestDrugstoresRequest) {
    return this.nearestDrugstoresClient.getDrugstores$(params);
  }

  public getSelectedDrugstores$(params: INearestDrugstoresRequest) {
    return this.nearestDrugstoresClient.getSelectedDrugstores$(params);
  }
}
