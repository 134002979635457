import { Directive, forwardRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[fpValueComponents]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValueComponentsDirective),
      multi: true
    }
  ]
})
export class ValueComponentsDirective implements ControlValueAccessor {

  private _lastValue: any;
  private onChange = (_: any) => {};
  private onTouched = () => {};

  constructor(){}

  writeValue(value: any) {
    this.lastValue = value ? value : '';
  }

  registerOnChange(fn: (value:any) => void ) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void ) {
    this.onTouched = fn;
  }

  set lastValue(val:any) {
      this._lastValue = val;
      this.onChange(this._lastValue);
      this.onTouched();
  }
  
  get lastValue() {
    return this._lastValue;
  }

  

  @HostListener('valueChanged', ['$event.detail'])
  _listenForValueChange(value: any) {
    if( JSON.stringify(value) !== JSON.stringify(this.lastValue) ){
      this.lastValue = value;
    }
  }

}
