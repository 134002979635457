import { CampaignCustomer, ICampaignCustomer } from './campaign-customer.model';

export interface ICampaignCustomerResponse {
  isRegisteredInInkaclub: boolean;
  isRegisteredAsCustomer: boolean;
  isRegisteredInCampaign: boolean;
  arePromotionsActive: boolean;
  customerInfo: ICampaignCustomer;
}

export class CampaignCustomerResponse {
  inkaClubUser: boolean;
  customerUser: boolean;
  campaignUser: boolean;
  activeBenefits: boolean;
  customer: CampaignCustomer;

  constructor(iCampaignCustomerResponse: ICampaignCustomerResponse) {
    this.inkaClubUser = iCampaignCustomerResponse.isRegisteredInInkaclub || false;
    this.customerUser = iCampaignCustomerResponse.isRegisteredAsCustomer || false;
    this.campaignUser = iCampaignCustomerResponse.isRegisteredInCampaign || false;
    this.activeBenefits = iCampaignCustomerResponse.arePromotionsActive || false;

    const emptyCustomer = !iCampaignCustomerResponse.customerInfo || iCampaignCustomerResponse.customerInfo &&
      Object.keys(iCampaignCustomerResponse.customerInfo).length === 0 &&
      iCampaignCustomerResponse.customerInfo.constructor === Object;
    if (!emptyCustomer) {
      this.customer = new CampaignCustomer(iCampaignCustomerResponse.customerInfo);
    }
  }

}


