<div
  class="d-flex align-items-center breadcrumbs-container"
  *ngIf="breadcrumbs"
>
  <a *ngIf="showBase" routerLink="/" class="breadcrumb label-black--1"
    >Inicio</a
  >
  <ng-container
    *ngFor="let breadcrumb of breadcrumbs; last as isLast; first as isFirst"
  >
    <span
      class="mr-1 ml-1 icon icon-16-chevron-right"
      *ngIf="(!showBase && !isFirst) || showBase"
    ></span>
    <a
      *ngIf="!isLast"
      class="breadcrumb link label-black--1"
      (click)="onClick(breadcrumb)"
      >{{ breadcrumb.name }}</a
    >
    <span *ngIf="isLast" class="breadcrumb last label-black--1">
      {{ breadcrumb.name }}
    </span>
  </ng-container>
</div>
