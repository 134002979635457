<div class="content-error-modal">
	<div class="content-error-modal__header d-flex justify-content-between align-items-center">
		<h3 class="mb-0">
			<b>Adjuntar archivo</b>
		</h3>
		<fp-button-icon [iconClass]="'mr-0'" class="btn-close" (click)="onClose(false)" [buttonIcon]="'close-outline-gray'">
		</fp-button-icon>
	</div>
	<mat-divider></mat-divider>
	<div class="options-container">
		<!-- <div (click)="selectOption(item.value)" class="options-container__item" *ngFor="let item of uploadFileOptions">
      <fp-icon iconSize="" [iconName]="item.icon"></fp-icon> 
      <span>{{item.label}}</span>
    </div> -->

		<div class="options-container__item">
			<input type="file" accept="image/*" capture="environment" (change)="handleFileInput($event)" #cameraInput />
			<fp-icon iconSize="" [iconName]="'camera'"></fp-icon>
			<span>Cámara</span>
		</div>

		<div class="options-container__item">
			<input type="file" accept="image/png, image/gif, image/jpeg" (change)="handleFileInput($event)" #photoLibraryInput />
			<fp-icon iconSize="" [iconName]="'image'"></fp-icon>
			<span>Biblioteca de fotos</span>
		</div>

		<div class="options-container__item">
			<input type="file" (change)="handleFileInput($event)" accept=".png, .jpeg, .jpg, .pdf" #fileInput />
			<fp-icon iconSize="" [iconName]="'file'"></fp-icon>
			<span>Seleccionar archivo</span>
		</div>
	</div>
</div>
