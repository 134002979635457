import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "fp-tag-seller-brand",
	templateUrl: "./tag-seller-brand.component.html",
	styleUrls: ["./tag-seller-brand.component.sass"]
})
export class TagSellerBrandComponent implements OnInit {
	@Input() iconName = "";
	@Input() text = "";
	@Input() innerClass = "";
	@Input() customClass = "";
	@Input() iconSize = "16";
	@Input() shortTag = false;
	@Input() type = "";

	constructor() {}

	ngOnInit() {}

	get textTag() {
		return this.capitalizeFirstLetter(this.text);
	}

	capitalizeFirstLetter(string) {
		let lowerCaseText = string.toLowerCase();
		let formattedText = lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1);
		return formattedText;
	}
}
