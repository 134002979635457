<div class="input-searcher input-search-for-ret-custom">
  <div>
    <label class="input-searcher-icon" (click)="clickLupa()">
      <fp-icon iconSize="16" iconName="search-modal"></fp-icon>
    </label>
  </div>
  <input type="search"
    #retAddressSearcherInput
    [id]="inputId || 'ctrl--search-drugstore'"
    class="input-searcher-control"
    placeholder="Buscar una botica por dirección"
    autocomplete="none"
    [value]="value"
    (input)="changeValue($event)"
    [disabled]="isDisabled"
    (focus)="focusEvent.emit()"
    (blur)="blurEvent.emit()"
    (keyup)="enter($event)"
    (keypress)="onKeypressEvent($event)"
    [class]="customClass"
    >
</div>

