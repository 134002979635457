import { Component, Input, ContentChild, ElementRef, AfterContentInit, OnChanges, Renderer2 } from '@angular/core';

const ERROR_BORDER_COLOR = 'border-color: #E74848';
@Component({
  selector: 'fp-input-control-v2',
  templateUrl: './input-control-v2.component.html',
  styleUrls: ['./input-control-v2.component.sass']
})
export class InputControlV2Component implements OnChanges, AfterContentInit {

  @ContentChild('inputControlV2') inputControl: ElementRef<HTMLInputElement>;

  @Input() inputTitle = '';
  @Input() errorMessage = '';
  @Input() marginWithError = false;
  @Input() marginBottomZero = false;
  @Input() isMandatory = false;

  @Input() errorMsgJoinToInput = false;

  private isInputControlReady = false;

  constructor(
    private renderer: Renderer2,
  ) { }

  ngOnChanges() {
    if (this.isInputControlReady) {
      this.updateErrorStatus();
    }
  }

  ngAfterContentInit(): void {
    this.isInputControlReady = true;
    this.updateErrorStatus();
  }

  private updateErrorStatus() {
    if (this.inputControl) {
      const input = this.inputControl.nativeElement;
      if (this.errorMessage) {
        this.renderer.setAttribute(input, 'style', ERROR_BORDER_COLOR);
      } else {
        this.renderer.removeAttribute(input, 'style');
      }
    }
  }
}
