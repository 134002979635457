import { isObject } from "@helpers/objects-equal";

export interface ISignatureTransaction{
  identifier: string;
  signature: string;
}
export class SignatureTransactionResponse {
  public identifier: string;
  public signature: string;
  constructor(signatureTransaction:ISignatureTransaction){
    const currentValue = isObject(signatureTransaction) ? signatureTransaction : {} as ISignatureTransaction;
    this.identifier = currentValue.identifier || '';
    this.signature = currentValue.signature || '';
  }
}