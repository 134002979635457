import { Product } from '../models/product.model';
import { EProductStatus } from '../models/product-status.enum';
import { ProductShoppingCart } from '../models/product-shopping-cart/product-shopping-cart.model';
import { CoverageLocationResponse } from '@models/coverage-location/coverage-location.model';

export function getUnavailablePresentationStatus(product: Product, fractionatedSelected: boolean) {
  const noFractionatedPresentation = product.presentations.find(presentation => !presentation.fractionated);
  const unavailablePresentation = (noFractionatedPresentation.quantityLimit === 0 && !fractionatedSelected && product.statusId === EProductStatus.available) || false;
  return unavailablePresentation ? EProductStatus.unavailablePresentation : product.statusId;
}

export function getStockStatus(product: ProductShoppingCart, fractionatedSelected: boolean, beforeProduct:any) {
  const noFractionatedPresentation = product.presentations.find(presentation => !presentation.fractionated);
  const unavailablePresentation = (noFractionatedPresentation.stock < beforeProduct.quantity && noFractionatedPresentation.stock > 0 && !fractionatedSelected && product.statusId === EProductStatus.available) || false;
  return unavailablePresentation ? EProductStatus.newStock : product.statusId;
}

export function productRADHaveStock(product: Product, isRad: boolean, coverages?: CoverageLocationResponse): boolean {
  const stockFractionated = isRad ? 'fractionalStock' : 'fractionalRetStock';
  const stock = isRad ? 'stock' : 'stockRet';
  const favoriteCoverage = coverages?.favorite;
  const currentCoverage = coverages?.current;
  const isFavoriteEnabled = favoriteCoverage?.enabled && favoriteCoverage?.inCoverage;
  const isCurrentEnabled = currentCoverage?.enabled && currentCoverage?.inCoverage;
  let coverage = false;
  if(isFavoriteEnabled || favoriteCoverage?.latitude !== 0 && favoriteCoverage?.longitude !== 0) {
    coverage = isFavoriteEnabled;
  }else if(currentCoverage?.latitude !== 0 && currentCoverage?.longitude !== 0){
    coverage = isCurrentEnabled;
  }else{
    coverage = true;
  }
  const hasStock = product[stock] > 0 || product[stockFractionated] > 0;
  return coverage && hasStock;
}

export function productRETHaveStock(product: Product, isRad: boolean): boolean {
  const stockFractionated = isRad ? 'fractionalStock' : 'fractionalRetStock';
  const stock = isRad ? 'stock' : 'stockRet';
  return product[stock] > 0 || product[stockFractionated] > 0;
}

function getFractionatedStock(product: Product, isRad: boolean) {
  const stockFractionated = isRad ? 'fractionalStock' : 'fractionalRetStock'
  return product[stockFractionated] > 0 ? product[stockFractionated] : 0
}

function getOriginalStock(product: Product, isRad: boolean) {
  const stock = isRad ? 'stock' : 'stockRet'
  return product[stock] > 0 ? product[stock] : 0
}
