<div class="proccessing-payment-modal px-4 py-4">
    <div class="mx-2 my-3 icon-alert">
      <div class="loading loading-circle"></div>
    </div> 
    <div class="label-black-1 mx-2 my-3">
      {{ descriptionCoupon}}
    </div>
    <div class="warning-message text mx-2 my-3">
      {{ description}}
    </div>
  </div>
  
  