import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "fp-geolocalization-dialog",
  templateUrl: "./geolocalization-dialog.component.html",
  styleUrls: ["./geolocalization-dialog.component.sass"],
})
export class GeolocalizationDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<GeolocalizationDialogComponent>,
  ) {}

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close();
  }

  activateGeolocalization(){
  }

}
