import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fp-static-landing-header',
  templateUrl: './static-landing-header.component.html',
  styleUrls: ['./static-landing-header.component.sass']
})
export class StaticLandingHeaderComponent implements OnInit {

  @Input() loader = true;
  @Input() headerImage: string;
  @Input() headerTitle: string;
  @Input() content: string;

  constructor() {
  }

  ngOnInit() {
  }

}
