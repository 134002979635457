import {Component, Input, OnInit, OnChanges} from '@angular/core';
import { GlobalProductCardService } from 'src/app/core/modules/product-card/service/global-product-card.service';
import { ISubTotal } from 'src/app/shared/models/shopping-cart/shopping-cart-response.interfase';
import {PurchaseSummary} from '../../../../shared/models/shopping-cart/shopping-cart-precalculation.model';

@Component({
  selector: 'fp-purchase-summary-total',
  templateUrl: './purchase-summary-total.component.html',
  styleUrls: ['./purchase-summary-total.component.sass']
})
export class PurchaseSummaryTotalComponent implements OnInit, OnChanges {

  public total: number;
  public totalOh: number;

  @Input() summary: PurchaseSummary;
  @Input() summaryCard: Array<ISubTotal>;
  constructor(
    public _globalProductCardService: GlobalProductCardService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.updateTotal();
  }

  updateTotal() {
    const summaryCardTotal = (this.summaryCard || [])
      .find(data => this._globalProductCardService.paymentMethodCardType === data.paymentMethodCardType) || {};
    this.total = this.summary.total;
    this.totalOh = summaryCardTotal.totalWithPaymentMethod;
  }

}
