import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MoleculesModule } from "@molecules/molecules.module";
import { UpdateAnonymousImplementService } from "src/app/business/checkout/checkout-services/update-anonymous-implement.service";
import { ButtonsModule } from "src/app/core/generic/buttons/buttons.module";
import { ChipModule } from "src/app/core/generic/chip/chip.module";
import { IconsModule } from "src/app/core/generic/icons/icons.module";
import { InputsModule } from "src/app/core/generic/inputs/inputs.module";
import { StepperModule } from "src/app/core/generic/stepper/stepper.module";
import { AddressDispatchManagerPresenter } from "src/app/core/modules/address-dispatch-manager/presenter/address-dispatch-manager.presenter";
import { AddressDrugstoreDispatchModule } from "src/app/core/modules/address-drugstore-dispatch/address-drugstore-dispatch.module";
import { ContainersModule } from "src/app/core/modules/containers/containers.module";
import { RadioModule } from "src/app/core/modules/form-controls-order-taker/radio/radio.module";
import { FormControlsModule } from "src/app/core/modules/form-controls/form-controls.module";
import { SelecteDrugstoreModalService } from "src/app/core/modules/selected-drugstore-manager/services/selected-drugstore-modal.service";
import { BasicSkeletonModule } from "src/app/core/modules/skeletons/basic-skeleton/basic-skeleton.module";
import { SnackbarModule } from "src/app/core/modules/snackbar/snackbar.module";
import { AddressClientService } from "src/app/shared/service/address/address-client.service";
import { ContentMapComponent } from "./components/content-map/content-map.component";
import { SelectOfficeFormComponent } from "./components/select-office-form/select-office-form.component";
import { StepperOfficeFormService } from "./forms/stepper-office-form.service";
import { OfficeModalService } from "./services/office-modal.service";
import { RecovePendingItemsImplementsService } from "./services/recove-pending-items-implements.service";
import { OfficeComponent } from "./views/office/office.component";
import { SelectOfficeComponent } from "./views/select-office/select-office.component";
import { StepOneComponent } from "./views/step-one/step-one.component";
import { StepTwoComponent } from "./views/step-two/step-two.component";

@NgModule({
  declarations: [
    OfficeComponent,
    StepOneComponent,
    StepTwoComponent,
    SelectOfficeComponent,
    SelectOfficeFormComponent,
    ContentMapComponent,
  ],
  imports: [
    CommonModule,
    MoleculesModule,
    FormControlsModule,
    MatDialogModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatStepperModule,
    MatChipsModule,
    StepperModule,
    SnackbarModule,
    RadioModule,
    AddressDrugstoreDispatchModule,
    ButtonsModule,
    IconsModule,
    ContainersModule,
    InputsModule,
    ChipModule,
    BasicSkeletonModule,
  ],
  providers: [
    OfficeModalService,
    SelecteDrugstoreModalService,
    //Forms
    StepperOfficeFormService,
    // //Store
    AddressDispatchManagerPresenter,
    AddressClientService,
    UpdateAnonymousImplementService,
    RecovePendingItemsImplementsService,
  ],
  entryComponents: [OfficeComponent, SelectOfficeComponent],
})
export class OfficeModule {}
