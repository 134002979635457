import { Component } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { MainLoaderService } from "src/app/shared/helpers/main-loader.service";

@Component({
  selector: "fp-main-loader",
  templateUrl: "./main-loader.component.html",
  styleUrls: ["./main-loader.component.sass"],
})
export class MainLoaderComponent {
  public isInkaBrand = OrderTakerValidator.isOrderTakerEnv()
    ? EBrand[EBrandAsset[environment.brand]] == EBrand.inkafarma
    : EBrand[environment.brand] == EBrand.inkafarma;
  public isLoaded = true;

  constructor(private mainLoaderService: MainLoaderService) {
    this.mainLoaderService.isLoaded$.subscribe((isLoaded) => (this.isLoaded = isLoaded));
  }
}
