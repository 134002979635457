import { IProcessingOrderState } from './processing-order.interface';

export const DELIVERED_STATES = [
  'DELIVERED', 'ENTREGADO', 'ENTREGADA',
];
export const CANCELLED_STATES = [
  'CANCELLED', 'RECHAZADO', 'CANCELADO',
];
export const ENDING_STATES = [
  ...DELIVERED_STATES, ...CANCELLED_STATES,
];
export const VALID_STATES = [
  'SCHEDULED',
  'ON_STORE', 'PICKING', 'CHECKOUT', 'PREPARED', 'ASSIGNED',
  'ON_ROUTE', 'ARRIVED', 'READY_FOR_PICKUP',
  ...ENDING_STATES,
];

export const ON_STORE_STATES =  ['ON_STORE', 'PICKING', 'CHECKOUT', 'PREPARED', 'ASSIGNED'];

/**
 * Estado generico cancelado
 */
export const CANCELLED_STATE = {
  states: CANCELLED_STATES,
  templateTitle: '¡Pedido cancelado!',
  templateIcon: 'icon-close-red',
};
export const DELIVERED_STATE = {
  states: DELIVERED_STATES,
  templateTitle: '¡Este pedido ya fue entregado!',
  templateIcon: 'icon-16-check-green',
};
export const CANCELLED_SUBTITLE = 'Este pedido fue cancelado.';

/**
 * Existen 4 estados visualmente para Reparto a Domicilio
 * pero aquí definiremos el detalle que debe aparecer en cada
 * uno de los estados de este proceso
 */
export const STATES_FOR_RAD: IProcessingOrderState[] = [
  {
    states: ['SCHEDULED'],
    templateTitle: '¡Tu pedido ha sido programado!',
    templateIcon: 'icon-24-timer',
  },
  {
    states: ['ON_STORE', 'PICKING', 'CHECKOUT', 'PREPARED', 'ASSIGNED'], // PICKING: Lite, CHECKOUT: DC
    templateTitle: '¡Estamos preparando tu pedido!',
    templateIcon: 'icon-24-prepare',
  },
  {
    states: ['ON_ROUTE', 'ARRIVED'],
    templateTitle: '¡Tu pedido está en camino!',
    templateIcon: 'icon-24-scooter',
  },
];
export const SUBTITLE_RAD = 'Hora estimada de entrega';
export const DATETIME_FORMAT_RAD = '';

/**
 * Existen 4 estados visualmente para Seller
 * pero aquí definiremos el detalle que debe aparecer en cada
 * uno de los estados de este proceso
 */
 export const STATES_FOR_SELLER: IProcessingOrderState[] = [
  {
    states: ['SCHEDULED'],
    templateTitle: '¡Tu pedido de %s ha sido programado!',
    templateIcon: 'icon-24-timer',
  },
  {
    states: ['ON_STORE', 'PICKING', 'CHECKOUT', 'PREPARED', 'ASSIGNED'], // PICKING: Lite, CHECKOUT: DC
    templateTitle: '¡ %s esta preparando tu pedido!',
    templateIcon: 'icon-24-prepare',
  },
  {
    states: ['ON_ROUTE', 'ARRIVED'],
    templateTitle: '¡Tu pedido %s está en camino!',
    templateIcon: 'icon-24-scooter',
  },
];
export const SUBTITLE_SELLER = 'Fecha estimada de entrega';
export const DATETIME_FORMAT_SELLER = '';


/**
 * Existen 4 estados visualmente para Retiro en Tienda
 * pero aquí definiremos el detalle que debe aparecer en cada
 * uno de los estados de este proceso
 */
export const STATES_FOR_RET: IProcessingOrderState[] = [
  {
    states: ['CONFIRMADA'],
    templateTitle: '',
    templateIcon: '',
  },
  {
    states: ['SCHEDULED'],
    templateTitle: '¡Tu pedido ha sido programado!',
    templateIcon: 'icon-24-timer',
  },
  {
    states: ['ON_STORE', 'PICKING'],
    templateTitle: '¡Estamos preparando tu pedido!',
    templateIcon: 'icon-24-prepare',
  },
  {
    states: ['READY_FOR_PICKUP'],
    templateTitle: '¡Tu pedido está listo para recoger!',
    templateIcon: 'icon-24-store',
  },
];
export const SUBTITLE_RET = 'Horario para recojo';
export const DATETIME_FORMAT_RET = '';

