<div class="d-flex justify-content-center">
  <div class="row modal-container">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div role="button" (click)="removeDialog()" class="button-modal-close text-right">
            <span class="icon icon-24-close icon-close"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center">
      <fp-icon iconSize="48" iconName="sad-sweat-face-colored"></fp-icon>
    </div>
    <div class="col-md-12">
      <div class="text-center py-3">
        <span class="heading">Lo sentimos, no fue posible usar el cupón: “{{cuponCode}}”</span>
      </div>
      <ul class="list-color m-0 paragraph--1">
        <li>Ya cuentas con un mejor descuento.</li>
        <li>Verifica la fecha de caducidad.</li>
        <li>Verifica si aplica a tus productos.</li> 
      </ul>
    </div> 
    <div class="col-md-12 text-center mt-3">
      <span class="list-color">Si el problema persiste, comunícate al <b>(01) 612 5000.</b></span>
    </div> 
    <div class="col-md-12 modal-footer mt-3">
      <fp-button-primary buttonDisplay="block" (click)="removeDialog()">
        Entiendo
      </fp-button-primary> 
    </div>
  </div>
</div>