import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fp-tag-default',
  templateUrl: './tag-default.component.html',
  styleUrls: ['./tag-default.component.sass']
})
export class TagDefaultComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
