<div class="pre-register-modal">
    <fp-section-container-title-login [title]="'Cuéntanos sobre ti'"  [iconName]="'close'" (onclick)="closeModal()">
    </fp-section-container-title-login>
    <fp-sub-section-container>
      <div class="container my-4" [formGroup]="registerPreNewUserForm.form">
        <div>
          <p class="font-VAGRoundedNexxt-normal-w-400">
            Con esta información podremos brindarte cupones, descuentos y beneficios personalizados
          </p>
        </div>
        <div class="mt-2">
          <fp-select-control [options]="documentTypes" formControlName="documentType" [color_i]="color_g"
          (change)="selectDocumentType($event.target.value)">
          </fp-select-control>
        </div>
        <div class="mt-4">
          <fp-input-control [marginWithError]="false">
            <input autocomplete="off" type="text" id="dni" class="input" formControlName="documentNumber" #inputControl
              fpDigitOnly placeholder="Número de documento" [maxlength]="documentLength" [minlength]="documentLength">
          </fp-input-control>
        </div>
        <div class="button-content">
          <fp-button-primary id="" buttonDisplay="block" (click)="nextNewRegisterData()"
            [buttonDisabled]="!registerPreNewUserForm.form.valid">
            Siguiente
          </fp-button-primary>
        </div>
      </div>
    </fp-sub-section-container>
  </div>