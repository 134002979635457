<hr class="m-0" />
<div class="content product-mini-cart" [attr.data-product-brand]="product?.brand" [attr.data-product-category]="product?.category">
	<div class="row">
		<div class="col-8">
			<fp-link [linkRouter]="['/' + productPath, product.slug, product.id]" linkId="link--go-to-product-detail">
				<div class="row">
					<div class="col">
						<fp-product-image
							class="w-100"
							imageSize="45px"
							[image]="productPresentation?.image"
							[imageName]="product.name"
							[productStatus]="productStatus"
							tagSize="small"
						>
						</fp-product-image>
					</div>

					<div class="col-9 d-flex flex-column">
						<fp-product-information
							[name]="product.name"
							[description]="productPresentation?.description"
							[productStatus]="productStatus"
						>
						</fp-product-information>
						<p class="mt-2 mb-0 text-tag text-black">CANTIDAD: {{ product.quantity }}</p>
						<div class="mt-2" *ngIf="product.prescriptionCode">
							<fp-tag-seller-brand iconName="paper-background" [text]="product.prescription"> </fp-tag-seller-brand>
						</div>
					</div>
				</div>
			</fp-link>
		</div>

		<div class="col-4">
			<div class="row">
				<div class="col-12">
					<fp-product-cart-price-mifa
						*fpBrandAccess="[brand.mifarma]"
						[subTotal]="product.subTotalAllPaymentMethod"
						[subTotalOffer]="product.subTotalWithPaymentMethod"
						[productStatus]="productStatus"
						[regularPrice]="product.subTotal"
					>
					</fp-product-cart-price-mifa>
					<fp-product-cart-price
						*fpBrandAccess="[brand.inkafarma]"
						[subTotal]="product.subTotalAllPaymentMethod"
						[subTotalOffer]="product.subTotalWithPaymentMethod"
						[productStatus]="productStatus"
						[regularPrice]="product.subTotal"
					>
					</fp-product-cart-price>
				</div>
				<div class="col-12 mt-3 text-right">
					<fp-button-ghost
						id="btn--delete-product"
						buttonSize="small"
						buttonColor="red"
						optionalClass="p-0 m-0"
						(click)="deleteProduct()"
					>
						Eliminar
					</fp-button-ghost>
				</div>
			</div>
		</div>
	</div>
</div>
