import { COMPANY_ID } from 'src/app/shared/parameters/global';

export class CompanyId {
    public companyId: number;
    constructor() {
        this.companyId = COMPANY_ID;
    }
}

export class UserRegisterRequest extends CompanyId {
    public name: string;
    public email: string;
    public dni: string;
    public phone: string;
    public flagDataTreatment?: boolean;
    public flagPrivacyPolicy: boolean;
    public flagTermsAndConditions: boolean;
    public authId: string;
    public origin: number;
    public isAnonymous: boolean;
    public identityDocumentType: string | number;
    public identityDocumentNumber: number | string;
    public paternalLastName: string;
    public maternalLastName: string;
    public sexId: number;
    public id: string;
    public source: string;
    public birthDate: string;
    public campaignId: number;
}

export class EmailPhoneVerificationRequest extends CompanyId {
    public email: string;
    public phone: string;
    public origin: number;
    public source: string;
    public id: string;
}

export class EmailVerificationRequest extends CompanyId {
    public email: string;
    public origin: number;
    public source?: string;
}
