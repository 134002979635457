<section class="content card purchase-summary-static" [ngClass]="scrollClass">
	<div class="row">
		<div class="col mb-2">
			<span class="heading-1">Resumen de compra</span>
		</div>
	</div>

	<hr class="m-0" />

	<div class="row">
		<div class="col mt-3 mb-3">
			<fp-purchase-summary-list [summary]="summary"></fp-purchase-summary-list>
		</div>
	</div>

	<hr class="m-0" />

	<div class="row">
		<div class="col mt-3 mb-3">
			<fp-purchase-summary-total-mifa *fpBrandAccess="[brand.mifarma]" [summary]="summary" [summaryCard]="summaryCard">
			</fp-purchase-summary-total-mifa>
			<fp-purchase-summary-total *fpBrandAccess="[brand.inkafarma]" [summary]="summary" [summaryCard]="summaryCard">
			</fp-purchase-summary-total>
		</div>
	</div>

	<hr class="m-0" />

	<div class="row">
		<div class="col-12 mt-3">
			<button
				*ngIf="_shoppingCartLoader.shoppingCartLoader$ | async; else notLoader"
				id="applyCoupon"
				class="btn btn-primary btn-aplicar btn-spinner"
			>
				<div class="mx-2 my-3 icon-alert loading loading-circle"></div>
			</button>
			<ng-template #notLoader>
				<fp-button-primary
					buttonId="btn--go-to-checkout"
					buttonDisplay="block"
					[class.card-disabled]="disabledCards"
					(click)="buyNowEvent()"
				>
					Ir a comprar
				</fp-button-primary>
			</ng-template>
		</div>
	</div>
</section>

<div class="mt-4" *ngIf="!isTomadorBrand && hasControlledProducts">
	<fp-disclaimer-controlled [innerClass]="'disclaimer-desktop d-flex align-items-center'"></fp-disclaimer-controlled>
</div>
