import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from "@angular/core";

const ERROR_BORDER_COLOR = "border-color: #E74848";

@Component({
  selector: "fp-input-password",
  templateUrl: "./input-password.component.html",
  styleUrls: ["./input-password.component.sass"],
})
export class InputPasswordComponent implements OnChanges, AfterContentInit {
  @ContentChild("typeControl") typeControl: ElementRef<HTMLInputElement>;

  @Input() inputTitle = "";
  @Input() errorMessage = "";
  @Input() marginWithError = true;
  @Input() isVerify = false;

  private isInputControlReady = false;
  public changeIcon = "preview-off";

  constructor(private renderer: Renderer2) {}
  ngOnChanges() {
    if (this.isInputControlReady) {
      this.updateErrorStatus();

      if (this.isVerify && this.typeControl.nativeElement.value) {
        this.changeIcon = "check";
        const type = this.typeControl.nativeElement;
        this.renderer.setAttribute(type, "type", "password");
      } else {
        this.changeIcon = "preview-off";
      }
    }
  }

  ngAfterContentInit(): void {
    this.isInputControlReady = true;
    this.updateErrorStatus();
    const type = this.typeControl.nativeElement;
    this.renderer.setAttribute(type, "type", "password");
  }

  private updateErrorStatus() {
    if (this.typeControl) {
      const input = this.typeControl.nativeElement;
      if (this.errorMessage) {
        this.renderer.setAttribute(input, "style", ERROR_BORDER_COLOR);
      } else {
        this.renderer.removeAttribute(input, "style");
      }
    }
  }

  toggleIcon() {
    if (!this.isVerify) {
      const type = this.typeControl.nativeElement;
      if (this.changeIcon === "preview-on") {
        this.changeIcon = "preview-off";
        this.renderer.setAttribute(type, "type", "password");
      } else {
        this.changeIcon = "preview-on";
        this.renderer.setAttribute(type, "type", "text");
      }
    }
  }
}
