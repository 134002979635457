import { Injectable } from '@angular/core';
import { HeaderClientService } from '../../../../shared/service/header/header-client.service';
import { Observable } from 'rxjs';
import { SuggestSearch } from 'src/app/shared/models/suggestSearch';
import { map } from 'rxjs/operators';
import { ContentfulHeader, ContentfulHeaderMifa } from '../../../contentful/models/contentful-header.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderImplementService {

  constructor(private _HeaderClientService: HeaderClientService) {
  }

  public getHeader() {
    return this._HeaderClientService.getHeader()
      .pipe(
        map(header => new ContentfulHeader(header[0].content))
      );
  }

  public getHeaderMifa() {
    return this._HeaderClientService.getHeaderMifa()
      .pipe(
        map(header => new ContentfulHeaderMifa(header[0].content))
      );
  }

  public getSuggestSearchByKeyWord(keyword: string): Observable<SuggestSearch> {
    return this._HeaderClientService.getSuggestSearchByKeyWord(keyword);
  }

}
