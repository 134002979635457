<hr class="m-0">
<div class="content product-mini-cart">
  <div class="row">
    <div class="col-8">
      <fp-link [linkRouter]="['/' + productPath, product.slug , product.id]"
        linkId="link--go-to-product-detail">
        <div class="row">
          <div class="col">
            <fp-product-image
              class="w-100"
              imageSize="45px"
              [image]="productPresentation?.image"
              [imageName]="product.name"
              [productStatus]="productStatus"
              tagSize="small">
            </fp-product-image>
          </div>

          <div class="col-9 d-flex flex-column">
            <fp-product-information
              [name]="product.name"
              [description]="productPresentation?.description"
              [productStatus]="productStatus">
            </fp-product-information>
            <p class="mt-2 mb-0 text-tag text-black">
              CANTIDAD: {{product.quantity}}
            </p>
          </div>

        </div>
      </fp-link>
    </div>

    <div class="col-4">
      <div class="row">
        <div class="col-12">
          <fp-product-cart-price
            [subTotal]="product.subTotalAllPaymentMethod"
            [subTotalOffer]="product.subTotalWithPaymentMethod"
            [productStatus]="productStatus"
            [regularPrice]="product.subTotal">
          </fp-product-cart-price>
        </div>

        <div class="col-12 mt-3 text-right">
          <fp-button-ghost
            id="btn--delete-product"
            buttonSize="small"
            buttonColor="red"
            buttonDisplay="block"
            (click)="deleteProduct()">
            Eliminar
          </fp-button-ghost>
        </div>
      </div>
    </div>
  </div>
</div>
