import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IconsModule } from "../icons/icons.module";
import { ButtonBlackComponent } from "./views/button-black/button-black.component";
import { ButtonEmailPasswordComponent } from "./views/button-email-password/button-email-password.component";
import { ButtonGhostComponent } from "./views/button-ghost/button-ghost.component";
import { ButtonIconComponent } from "./views/button-icon/button-icon.component";
import { ButtonOutlineComponent } from "./views/button-outline/button-outline.component";
import { ButtonPrimaryComponent } from "./views/button-primary/button-primary.component";
import { ButtonSecondaryComponent } from "./views/button-secondary/button-secondary.component";
import { ButtonComponent } from "./views/button/button.component";
import { CustomRouterLinkComponent } from "./views/custom-router-link/custom-router-link.component";
import { LinkButtonComponent } from "./views/link-button/link-button.component";
import { LinkInlineComponent } from "./views/link-inline/link-inline.component";
import { LinkComponent } from "./views/link/link.component";

const COMPONENTS = [
  ButtonComponent,
  ButtonPrimaryComponent,
  ButtonSecondaryComponent,
  ButtonGhostComponent,
  ButtonOutlineComponent,
  ButtonIconComponent,
  LinkComponent,
  LinkInlineComponent,
  LinkButtonComponent,
  ButtonBlackComponent,
  CustomRouterLinkComponent,
  ButtonEmailPasswordComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [CommonModule, RouterModule, IconsModule],
})
export class ButtonsModule {}
