import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDialogComponent } from 'src/app/core/components/utilities/modal-dialog/modal-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class VideoModalService {
  private videoModalRef: MatDialogRef<ModalDialogComponent>;
  constructor(private matDialog: MatDialog) { }

  openVideoModal(link:string){
    if(link){
      this.videoModalRef = this.matDialog.open(
        ModalDialogComponent,
        {
          data:link,
          width:"60vw",
          height: "40vw",
          panelClass:"dialog-responsive"
         }
      );
    }

  }
  onCloseModal(){
    this.videoModalRef.close();
  }
}
