import { Injectable } from '@angular/core';

@Injectable()
export class NewRegisterStoreService {
  private _id = '';
  private _email = '';
  private _password = '';
  private _phone = '';
  private _emailOnConflict = '';
  private _customToken = '';
  private _firstUserLoggedIn;

  constructor() { }

  public get id() {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get email() {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get password() {
    return this._password;
  }

  public set password(password: string) {
    this._password = password;
  }

  public get phone() {
    return this._phone;
  }

  public set phone(phone: string) {
    this._phone = phone;
  }

  public get emailOnConflict() {
    return this._emailOnConflict;
  }

  public set emailOnConflict(email: string) {
    this._emailOnConflict = email;
  }

  public get customToken() {
    return this._customToken;
  }

  public set customToken(customToken: string) {
    this._customToken = customToken;
  }

  public get firstUserLoggedIn() {
    return this._firstUserLoggedIn;
  }

  public set firstUserLoggedIn(firstUserLoggedIn: any) {
    this._firstUserLoggedIn = firstUserLoggedIn;
  }

}
