import {Component, Inject, OnInit} from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'fp-snackbar-alert-incoming',
  templateUrl: './snackbar-alert-incoming.component.html',
  styleUrls: ['./snackbar-alert-incoming.component.sass']
})
export class SnackbarAlertInComingComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private _matSnackBarRef: MatSnackBarRef<SnackbarAlertInComingComponent>) {
  }

  dismiss() {
    this._matSnackBarRef.dismiss();
  }
}
