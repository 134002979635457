import {IProductImage} from './product-detail.interface';

export class ProductImage {
  image: string;
  imageList: string[];

  constructor(iProductImage: IProductImage) {
    this.image = iProductImage?.url || '';
    this.imageList = iProductImage?.thumbnails || [];
  }
}


export class ProductDetailImage {
  public productImage: ProductImage;

  constructor(productImages: Array<IProductImage>, size: 'M' | 'X' | 'L') {
    this.productImage = productImages && productImages.length ?
      new ProductImage(this.getImages(productImages, size)[0]) : new ProductImage({} as IProductImage);
  }

  getImages(productImages, size) {
    return productImages.filter(image => image.url.includes(size + '.'));
  }
}
