import { PackCardPresentation, ProductCardPresentations, UnitCardPresentation } from '../../../core/modules/product-card/models/product-card/product-card-presentation.model';
import { IProductDefault } from './product-default.interface';
import { IProductCard } from '../../../core/modules/product-card/models/product-card/product-card.interface';
import { ProductQuantityLimit } from '../../../core/modules/product-card/models/product-presentation.model';
import { ProductImageBySize } from '../../../core/modules/product-card/models/product-image.model';
import { EProductPresentation } from '../../../core/modules/product-card/models/product-presentation.enum';

export class PackDefaultPresentation extends PackCardPresentation {
  constructor(iProductDefault: IProductDefault) {
    super({} as IProductCard);
    this.id = iProductDefault.presentationId || EProductPresentation.pack;
    this.image = new ProductImageBySize(iProductDefault.imageList, 'X').image || '';
    this.stock = iProductDefault.stock || 0;
    this.stockRet = iProductDefault.stockRet || 0;
    this.maxUnitSale = iProductDefault.maxUnitSale || 0;
    this.price = iProductDefault.price || 0;
    this.description = iProductDefault.noFractionatedText || '';
    this.presentation = iProductDefault.presentation || '';
    this.quantityLimit = new ProductQuantityLimit(this).quantityLimit || 0;
    this.regularPrice = iProductDefault.priceAllPaymentMethod || 0;
    this.offerPrice = iProductDefault.priceWithpaymentMethod || 0;
    this.crossOutPL = iProductDefault.crossOutPL;
  }
}

export class UnitDefaultPresentation extends UnitCardPresentation {
  constructor(iProductDefault: IProductDefault) {
    super({} as IProductCard);
    this.id = iProductDefault.fractionatedPresentationId || EProductPresentation.unit;
    this.image = new ProductImageBySize(iProductDefault.imageList, 'X').image || '';
    this.stock = iProductDefault.fractionalStock || 0;
    this.stockRet = iProductDefault.fractionalRetStock || 0;
    this.maxUnitSale = iProductDefault.maxUnitSaleFractionated || 0;
    this.price = iProductDefault.fractionatedPrice || 0;
    this.description = iProductDefault.fractionatedText || '';
    this.presentation = iProductDefault.fractionatedForm || '';
    this.quantityLimit = new ProductQuantityLimit(this).quantityLimit || 0;
    this.regularPrice = iProductDefault.fractionatedPriceAllPaymentMethod || 0;
    this.offerPrice = iProductDefault.fractionatedPriceWithpaymentMethod || 0;
    this.crossOutPL = iProductDefault.crossOutPL;
  }
}

export class ProductDefaultPresentations extends ProductCardPresentations {
  constructor(iProductDefault: IProductDefault) {
    super({} as IProductCard);
    this.productPresentations = [new PackDefaultPresentation(iProductDefault)];
    if (iProductDefault.fractionalMode) {
      this.productPresentations = [new UnitDefaultPresentation(iProductDefault), ...this.productPresentations];
    }
  }
}
