import { IPaymentMethod, PaymentMethod } from "@models/delivery-info/delivery-info.model";
import { ISelectItem } from "@models/documents-type/document-type.model";
import { PAYMENT_METHODS_TEXT, PAYMENT_METHOD_CODE } from "@parameters/checkout/payment-method.parameters";

// here are the constant parameters. const allow us declare variables in common
export const MAX_CHARACTERS = 100;
export const RUC_MAX_LENGTH = 11;


// App Agora PAY
export const PAYMENT_METHOD_AGORA_PAY = 'App agora';
export const APP_AGORA_PAYMENT_CARD_ID = 22;
export const NUMBER_THREE = 3;
export const SOURCE_WEB = "WEB";
export const NEW_PAYMENT_METHOD_APP_AGORA_PAY: IPaymentMethod = {
  id: PAYMENT_METHOD_CODE.AGORA_PAY,
  name: PAYMENT_METHODS_TEXT.APP_AGORA_PAY_PAYMENT,
  imageUrl: '',
  favorite: false,
  creditCardList: []
};
export const PAYMENT_METHOD_DEFAULT = {
  id: -1,
  creditCardList: [{ id: -1 }],
} as PaymentMethod;

export const ITEMS: ISelectItem[] = [
  { id: "1", name: "DNI" },
  { id: "2", name: "CE" },
];
export const PAYMENT_METHOD_AGORA_WEB = "AGORAWEB";
export const PAYMENT_METHOD_LANGUAGE = "es_PE";
export const PAYMENT_METHOD_DISPATCH = "HOME_DELIVERY";
export const COUNTRY_CODE = "PER";
export const CURRENCY_CODE = "PEN";
export const BACKGROUND_DEFECT = "#f6f6f6";

export const TIMER_DEFECT_ALGOLIA = 3;



export const JSON_INKA_STRUCTURED_DEFECT =  `{
    "@context": "https://schema.org/",
    "@type": "WebSite",
    "name": "Inkafarma",
    "url": "https://inkafarma.pe",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://inkafarma.pe/buscador?keyword={search_term_string}",
      "query-input": "required name=search_term_string"
      }
  }`;


  export const JSON_MIFA_STRUCTURED_DEFECT =  `{
    "@context": "https://schema.org/",
    "@type": "WebSite",
    "name": "Mifarma",
    "url": "https://www.mifarma.com.pe/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://www.mifarma.com.pe/buscador?keyword={search_term_string}",
    "query-input": "required name=search_term_string"
    }
  }`;
