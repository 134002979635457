import { Injectable, OnDestroy } from "@angular/core";
import { AddressItem } from "@models/address/address.model";
import { INearestDrugstoresRequest } from "@models/nearest-drugstores/nearest-drugstores.model";
import { DeliveryMethod } from "@models/schedule-response.model";
import { IServicesTypes } from "@models/services-type/services-type.interface";
import { StoreBackup } from "@models/store-backup.model";
import { Store } from "@models/store.model";
import { ALERT_STATES, SERVICES_TYPES, SERVICES_TYPES_ENUM } from "@parameters/global";
import { AppStoreService } from "@stores/app/app-store.service";
import { ClientStoreService } from "@stores/client/client-store.service";
import { ShoppingListStoreService } from "@stores/shopping-list/shopping-list-store.service";
import { Subscription, throwError } from "rxjs";
import { catchError, finalize, map, take } from "rxjs/operators";
import { StepperOfficeFormService } from "src/app/business/office/forms/stepper-office-form.service";
import { RecovePendingItemsImplementsService } from "src/app/business/office/services/recove-pending-items-implements.service";
import { SelectDataStoreService } from "src/app/business/office/store/select-data-store.service";
import { FLOW_SEARCH_ADDRESS_TYPE } from "src/app/core/modules/address-manager/parameters/modal.constant";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { DispatchHttpService } from "src/app/shared/service/dispatch/dispatch-http.service";
import { AddressDrugstoreDispatchService } from "../../address-drugstore-dispatch/service/address-drugstore-dispatch.service";
import { AddressManagerPersistanceService } from "../../address-manager/address-services/address-manager-persistance.service";
import { AddressListStoreService } from "../../address-manager/address-stores/address-list-store.service";
import { NearestDrugstoresImplementService } from "../../pick-up-in-store/pick-up-implements/nearest-drugstores-implement.service";
import { SelecteDrugstoreModalService } from "../../selected-drugstore-manager/services/selected-drugstore-modal.service";
import { ShowModalBoticaService } from "src/app/shared/service/showModalServices/showModalService.service";
import { Router } from "@angular/router";
import { OrderTakerValidator } from "@validators/order-taker.validator";

@Injectable()
export class AddressDispatchManagerPresenter implements OnDestroy {
	addressItem: AddressItem;
	store: Store;
	delivery: Store;
	storeBackup: StoreBackup;
	listServicesTypes: IServicesTypes[] = [...SERVICES_TYPES];
	subs$: Subscription = new Subscription();
	countStore = 1;
	isLoading = false;
	isFoundRet = true;
	isCallNearStore = false;
	errorloading = false;
	private lat: number;
	private long: number;
	flowDispatch: number;
	deliveryMethods: Array<DeliveryMethod>;
	isLoadingDelivery: boolean = true;
	nearestQuantity: number;
	isLoadingNearest: boolean;

	constructor(
		private _retValidateCartService: RetValidateCartService,
		private _selecteDrugstoreModal: SelecteDrugstoreModalService,
		private _dispatchHttpService: DispatchHttpService,
		public _selectDataStore: SelectDataStoreService,
		private _addressDispatchService: AddressDrugstoreDispatchService,
		private addressManagerPersistanceService: AddressManagerPersistanceService,
		private addressListStore: AddressListStoreService,
		public addressItemsForm: StepperOfficeFormService,
		private _clientStore: ClientStoreService,
		private _nearestDrugstoresImplement: NearestDrugstoresImplementService,
		private _appStore: AppStoreService,
		private _recovePendingItemsImplement: RecovePendingItemsImplementsService,
		public retValidateCartService: RetValidateCartService,
		private _shoppingListStore: ShoppingListStoreService,
		private showModalBoticaService: ShowModalBoticaService,
		private router: Router
	) {}

	ngOnDestroy(): void {
		this.subs$.unsubscribe();
		this.onExit();
	}

	onExit() {
		this._addressDispatchService.setResetData(false);
		this._addressDispatchService.setSwitch(false);
		this._selectDataStore.clearStates();
		this.listServicesTypes.forEach((r) => (r.id != SERVICES_TYPES_ENUM.RETIRO ? (r.disabled = true) : false));
		this.onClear();
	}

	onOpenModalChangeDrugstore(loadPageAfterClose: boolean = true): void {
		// this._selecteDrugstoreModal.openSelectedDrugstoreModal();
		const product = this._retValidateCartService.currentProduct;
		this.showModalBoticaService.showModalDesktopService(product, true, null, false, null, loadPageAfterClose);
	}

	onInitial() {
		const sub = this._selectDataStore.nearbyStore$.subscribe((res) => {
			if (res && res.name) {
				if (res.localType === "DELIVERY_CENTER") {
					this.delivery = res;
				} else {
					this.store = res;
				}
				this.validateServices();
			}
		});
		this.subs$.add(sub);
	}

	private validateStore() {
		if (!this.isFoundRet && !this.isCallNearStore) {
			this.store.address = "No disponible para retiro en tienda";
		}
	}

	private validateServices() {
		if (this._selectDataStore.isReadyToReset) {
			this.listServicesTypes.forEach((r) => (r.disabled = r.id != SERVICES_TYPES_ENUM.RETIRO ? true : false));
		}
	}

	validateFlow(flow: number): void {
		switch (flow) {
			case FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS:
				const value = this.addressItemsForm.addressItemControl.value;
				if (value) {
					const checkoutUrl = this.router.url.includes("checkout");
					this.addressItem = this.addressListStore.addressList.find((address) => address.id === value);
					if (checkoutUrl && OrderTakerValidator.isOrderTakerEnv()) {
						const addressList =
							this.addressListStore.addressList && this.addressListStore.addressList.length > 0
								? this.addressListStore.addressList
								: JSON.parse(localStorage.getItem("OT_CLIENT_DATA")).addresses;
						this.addressItem = addressList.find((address) => address.id === value);
					} else {
						this.addressItem = this.addressListStore.addressList.find((address) => address.id === value);
					}
					if (this.addressItem) {
						const { latitude, longitude } = this.addressItem;
						this.lat = latitude;
						this.long = longitude;
					}
				}
				break;

			default:
				const { latitude, longitude } = this.addressManagerPersistanceService.currentAddressUpdate;
				this.lat = latitude;
				this.long = longitude;

				break;
		}
		this.flowDispatch = flow;
		this.onInitial();
		this.getData(true);
	}

	getData(isInitialCall: boolean = false) {
		this.isLoading = true;
		this.store = null;
		if (this.retValidateCartService.isRetOrRadTomadorProcess) {
			this._dispatchHttpService
				.getZone$(this.lat, this.long)
				.pipe(
					catchError((er) => {
						this.listServicesTypes = [...SERVICES_TYPES];
						this.storeBackup = null;
						this._selectDataStore.clearStates();
						this.errorloading = true;
						this.isLoading = false;
						return throwError(er);
					}),
					finalize(() => {
						this.listServicesTypes = [...SERVICES_TYPES];
					})
				)
				.subscribe((zone) => {
					if (zone && zone.inCoverage) {
						this._dispatchHttpService
							.getData$()
							.pipe(
								catchError((er) => {
									this.listServicesTypes = [...SERVICES_TYPES];
									this.storeBackup = null;
									this._selectDataStore.clearStates();
									this.errorloading = true;
									this.isLoading = false;
									return throwError(er);
								}),
								finalize(() => {
									if (this._selectDataStore.nearbyStore) {
										this.validateServices();
									}
								})
							)
							.subscribe((data) => {
								this.isFoundRet = (data[0] as any).some(
									(service) => service.id === SERVICES_TYPES_ENUM.RETIRO && !service.disabled
								);
								if (data[0]?.length > 0) {
									const servicesTypesAux = [...(data[0] as IServicesTypes[])];
									if (!this.isFoundRet) {
										const serviceRet = servicesTypesAux.find((service) => service.id === SERVICES_TYPES_ENUM.EXPRESS);

										serviceRet.disabled = false;
									}
									this.listServicesTypes = servicesTypesAux;

									this._selectDataStore.servicesTypeState = this.listServicesTypes;
									if (!this.validateOnlyDelivery()) {
										this._selectDataStore.flagState = ALERT_STATES.NOT_FOUND_DELIVERY;
										this.onChangeNearStore(true);
									}
								}
								this.store = data[1];
								this.getSchedules(this.lat, this.long);
								this.storeBackup = data[2];
								this.isLoading = false;
								if (this._addressDispatchService.switch) {
									this._selectDataStore.setNearbyStore(this.store);
									this._selectDataStore.isReadyToReset = true;
								} else {
									this._selectDataStore.setNearbyStore(this.store);
								}
								this._selectDataStore.setZoneBackup(this.storeBackup);
								// TODO: No eliminar comentario de abajo - fix
								// this._selectDataStore.saveZoneBackup(this.storeBackup);
							});
					} else {
						this.storeBackup = null;
						this.isFoundRet = false;
						this._selectDataStore.flagState = ALERT_STATES.NOT_FOUND_COVERAGE;
						this.validateStoreSelectedOrNewStore();
					}
				});
		} else {
			this.store = null;
			this.storeBackup = null;
			this.isFoundRet = false;
			if (!isInitialCall) this._selectDataStore.flagState = ALERT_STATES.NOT_FOUND_COVERAGE;
			this.onChangeNearStore(true);
		}
	}

	onReload() {
		this.onClear();
		this.errorloading = false;
		this._selectDataStore.clearStates();
		this.getData();
	}

	onClear() {
		this.countStore = 1;
		this.isCallNearStore = false;
		this.store = null;
		this.storeBackup = null;
		this.isLoading = false;
		this.isLoadingDelivery = true;
		this.errorloading = false;
		this.deliveryMethods = null;
	}

	onChangeNearStore(isCallNearStore: boolean) {
		this.store = null;
		this.isCallNearStore = isCallNearStore;
		if (!this.isFoundRet && isCallNearStore) {
			this.isLoading = true;
			this.isLoadingDelivery = true;
			this._selectDataStore.setServiceSelected(SERVICES_TYPES_ENUM.RETIRO);
			if (!this._selectDataStore.isReadyToReset) {
				const coordTomador = {
					latitude: this.lat,
					longitude: this.long
				};
				const currentCoords = JSON.parse(this._appStore.getStorageItem("coordTomadorSelected"));
				this._appStore.setStorageItem("coordTomador", JSON.stringify(coordTomador));
				this.isLoadingNearest = true;

				const storedDrugstore = this._selectDataStore.nearbyStore;

				if ((coordTomador && coordTomador?.latitude) || coordTomador?.longitude) {
					this._nearestDrugstoresImplement
						.getSelectedDrugstores$({
							latitude: this.lat,
							longitude: this.long,
							companyCode: this._appStore.companyCode,
							shoppingCart: this._shoppingListStore.getLocalStorageShoppingCartList() || []
						} as INearestDrugstoresRequest)
						.pipe(
							take(1),
							map((drugStore) => {
								this.nearestQuantity = drugStore.length;
								return drugStore && drugStore.length > 0
									? ({
											localCode: drugStore[0].localCode,
											name: drugStore[0].name,
											address: drugStore[0].address,
											drugstoreWareHouseId: drugStore[0].drugstoreWareHouseId,
											latitude: drugStore[0].latitude,
											longitude: drugStore[0].longitude,
											localOpeningHours: drugStore[0].localOpeningHours
									  } as Store)
									: null;
							}),
							catchError((er) => {
								this.errorloading = true;
								this._appStore.removeStorageItem("coordTomador");
								this.isLoadingNearest = false;
								this.isLoadingDelivery = false;
								this.isLoading = false;
								this._addressDispatchService.setSwitch(true);
								this._selectDataStore.setReadyToReset(true);
								return throwError(er);
							}),
							finalize(() => {
								this.isLoadingNearest = false;
								this.isLoadingDelivery = false;
								this.isLoading = false;
								this._addressDispatchService.setSwitch(true);
								this._selectDataStore.setReadyToReset(true);
							})
						)
						.subscribe((res) => {
							if (res) {
								this._selectDataStore.isReadyToReset = true;
								this.store = res as Store;
								this.storeBackup = null;
								this._selectDataStore.setZoneBackup(null);
								this.validateServices();
							}
						});
				} else {
					this._selectDataStore.isReadyToReset = true;
					this.store = storedDrugstore;
					this._selectDataStore.setNearbyStore(storedDrugstore);
					this.storeBackup = null;
					this._selectDataStore.setZoneBackup(null);
					this.validateServices();
					this.isLoadingNearest = false;
					this.isLoadingDelivery = false;
					this.isLoading = false;
					this._addressDispatchService.setSwitch(true);
					this._selectDataStore.setReadyToReset(true);
				}
			}
		}

		if (!this.isFoundRet && !isCallNearStore) {
			this._addressDispatchService.setResetData(true);
		}
	}

	getStore(): Store {
		return this.store;
	}

	doSaveStore() {
		this._selectDataStore.setNearbyStore(this.store);
		this._selectDataStore.saveNearbyStore(this.store);
	}

	onSetValues(): boolean {
		if (
			(this.flowDispatch === FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS &&
				this.addressItem &&
				!this.addressItem.isNew &&
				this.addressListStore.selectedAddress.id == this.addressItem.id &&
				this._selectDataStore.nearbyStore) ||
			(this.flowDispatch === FLOW_SEARCH_ADDRESS_TYPE.EDIT_ADDRESS &&
				this._selectDataStore.callAddress == 1 &&
				this._selectDataStore.nearbyStore)
		) {
			let store = this._selectDataStore.nearbyStore;
			let service = this._selectDataStore.serviceSelected;
			let backup = this._selectDataStore.zoneBackup;
			let servicesType = this._selectDataStore.servicesTypeStore;
			this.isFoundRet = false;
			this._selectDataStore.flagState = this._selectDataStore.flagState;
			if (store) {
				this.store = store;
				this._selectDataStore.setNearbyStore(store);
			}
			if (service) this._selectDataStore.setServiceSelected(service);
			if (backup) {
				this.storeBackup = backup;
				this._selectDataStore.setZoneBackup(backup);
			}
			if (service == SERVICES_TYPES_ENUM.RETIRO) {
				this._selectDataStore.isReadyToReset = true;
				this._addressDispatchService.setSwitch(true);
				this.validateServices();
			}

			if (servicesType && service != SERVICES_TYPES_ENUM.RETIRO) {
				this.listServicesTypes = servicesType as any;
				this.getSchedules(
					this.addressItem ? this.addressItem.latitude : this.lat,
					this.addressItem ? this.addressItem.longitude : this.long
				);
			} else {
				this.isLoading = false;
				this.isLoadingDelivery = false;
			}
			return true;
		}
		return false;
	}

	onSaveAddress() {
		if (this.flowDispatch === FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS) {
			this._clientStore.saveClient$();
			const { client } = this._clientStore;
			this._recovePendingItemsImplement
				.updateShoppingListStoreWithPendingItems(client.codClient)
				.pipe(
					finalize(() => {
						setTimeout(() => {}, 100);
					})
				)
				.subscribe();
		}

		if (!this.isFoundRet && !this.isCallNearStore && this.storeBackup) {
			this.callServiceGetStoreBackup();
			return;
		}

		if (this.storeBackup) {
			this.callServiceGetStoreBackup();
		} else {
			this._appStore.drugstoreStock = this.store ? this.store.drugstoreWareHouseId?.toString() ?? "" : "";
			setTimeout(() => {
				window.location.reload();
			}, 100);
		}
	}

	private callServiceGetStoreBackup() {
		this._dispatchHttpService.zone = this.storeBackup;
		this._dispatchHttpService.getStore$.subscribe((store) => {
			this._appStore.drugstoreStock = store ? store.drugstoreWareHouseId.toString() : "";
			window.location.reload();
		});
	}

  private getSchedules(lat, long) {
    this.isLoadingDelivery = true;
    this._dispatchHttpService
      .getSchedule$(lat, long)
      .pipe(
        catchError((er) => {
          this.errorloading = true;
          return throwError(er);
        }),
        finalize(() => {
          if (this._selectDataStore.flagState == null) {
            this.isLoading = false;
            this.isLoadingDelivery = false;
          }
        })
      )
      .subscribe((response) => {
        this.deliveryMethods = response;
        if (this.deliveryMethods.length > 0) {
          let countDev = 0;
          this.deliveryMethods.forEach((r) => {
            let dev = this.listServicesTypes.find(
              (x) => x.id == r.serviceTypeCode && !x.disabled
            );
            if (
              dev &&
              dev.id != SERVICES_TYPES_ENUM.RETIRO &&
              r.capabilities &&
              r.capabilities[0].hasCapacity
            )
              countDev++;
          });
          if (countDev == 0) {
            this._selectDataStore.flagState = ALERT_STATES.NOT_FOUND_DELIVERY;
            this.onChangeNearStore(true);
          }
        }
      });
  }

	private validateOnlyDelivery(): boolean {
		const deliveries: string[] = [SERVICES_TYPES_ENUM.DELIVERY, SERVICES_TYPES_ENUM.AM_PM, SERVICES_TYPES_ENUM.EXPRESS];
		const dev = this.listServicesTypes.some((s) => deliveries.includes(s.id) && !s.disabled);
		return dev;
	}

	validateStoreSelectedOrNewStore() {
		const drugstoreSelected = this._selectDataStore.nearbyStore;
		if (drugstoreSelected) {
			this.store = {
				address: drugstoreSelected.address,
				companies: drugstoreSelected.companies,
				description: drugstoreSelected.description,
				distance: drugstoreSelected.distance,
				drugstoreWareHouseId: drugstoreSelected.drugstoreWareHouseId,
				endHour: drugstoreSelected.endHour,
				id: drugstoreSelected.id,
				inkaVentaId: drugstoreSelected.inkaVentaId,
				latitude: drugstoreSelected.latitude,
				legacyId: drugstoreSelected.legacyId,
				longitude: drugstoreSelected.longitude,
				localCode: drugstoreSelected.localCode,
				localOpeningHours: drugstoreSelected.localOpeningHours,
				localType: drugstoreSelected.localType,
				name: drugstoreSelected.name,
				schedule: drugstoreSelected.schedule,
				services: drugstoreSelected.services,
				startHour: drugstoreSelected.startHour,
				wmsEnabled: drugstoreSelected.wmsEnabled
			};
			this.isLoading = false;
			this._appStore.removeStorageItem("drugstoreSelected");
			this._appStore.setStorageItem("drugstoreSelected", JSON.stringify(this.store));
			this._selectDataStore.setNearbyStore(this.store as Store);
		} else {
			this.store = null;
			this.onChangeNearStore(true);
		}
	}
}
