/**
 * Este método verifica que el valor a parsear sea un string valido
 * que no arroje errores en tiempo de ejecución
 * @param value este valor es el que viene directo del "localStorage"
 */
export const isParsableJSON = (value: string | number | boolean | object) => {
  if (typeof value !== 'string') {
    return false;
  }
  try {
    JSON.parse(value);
  } catch (error) {
    return false;
  }
  return true;
};
