import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '../../parameters/endpoints';
import { GenericService } from '../generic';
import {
  IUserPickUpRequestParams, UserPickUpResponse, IUserPickUpResponse, IUserPickUpRequestResponse
} from '../../models/pickup-store/pickup-store.model';
import { map } from 'rxjs/operators';

@Injectable()
export class PickUpStoreClientService {

  private readonly PERSON_FOR_STORE_PICKUP_ENDPOINT = ENDPOINTS.PERSON_FOR_STORE_PICKUP;
  private readonly PERSON_FOR_STORE_PICKUP_DEFAULT_ENDPOINT = ENDPOINTS.PERSON_FOR_STORE_PICKUP_DEFAULT;
  private readonly PERSON_FOR_STORE_PICKUP_UPDATE_ENDPOINT = ENDPOINTS.PERSON_FOR_STORE_PICKUP_UPDATE;
  private readonly PEOPLE_FOR_STORE_PICKUP_ENDPOINT = ENDPOINTS.PEOPLE_FOR_STORE_PICKUP;

  constructor(private _GenericService: GenericService) {
  }

  // userToPickUpId not exist into params (return userToPickUpId)
  public registerUser(params: IUserPickUpRequestParams): Observable<IUserPickUpRequestResponse> {
    return this._GenericService.genericPost<any>(this.PERSON_FOR_STORE_PICKUP_ENDPOINT, params);
  }

  // userToPickUpId not exist into params (return nothing)
  public updateUser(id: number, params: IUserPickUpRequestParams) {
    const path = `${this.PERSON_FOR_STORE_PICKUP_UPDATE_ENDPOINT}/${id}`;
    return this._GenericService.genericPut(path, params);
  }

  // return nothing
  public deleteUser(id: number) {
    const path = `${this.PERSON_FOR_STORE_PICKUP_ENDPOINT}/${id}`;
    return this._GenericService.genericDelete(path);
  }

  public getUsers(): Observable<UserPickUpResponse> {
    return this._GenericService.genericGet<IUserPickUpResponse>(this.PEOPLE_FOR_STORE_PICKUP_ENDPOINT)
      .pipe(map(response => {
        const userPickUpResponse = new UserPickUpResponse(response);
        return userPickUpResponse;
      }));
  }

  public setDefaultUser(id: number) {
    const path = `${this.PERSON_FOR_STORE_PICKUP_DEFAULT_ENDPOINT}/${id}`;
    const params = {} as IUserPickUpRequestParams;
    return this._GenericService.genericPut(path, params);
  }

}
