<div
  class="row product-cart align-items-center"
  [class.unavailable-presentation]="
    productStatus === eProductStatus.unavailablePresentation
  "
>
  <div class="col-7">
    <div class="row" (click)="nameProduct()">
      <div class="col d-flex flex-column align-items-center gap-2">
        <fp-link
          [linkRouter]="['/' + productPath, product.slug, product.id]"
          linkId="link--go-to-product-detail"
          ><fp-product-image
            class="w-100"
            [image]="productPresentation?.image"
            [imageName]="product.name"
            imageSize="48px"
            [productStatus]="productStatus"
            tagSize="small"
          >
          </fp-product-image>

          <fp-chip
            *ngIf="product?.guaranteed"
            status="blue"
            [text]="product?.guaranteed"
          ></fp-chip
        ></fp-link>
      </div>

      <div class="col-9 d-flex flex-column">
        <fp-link
          [linkRouter]="['/' + productPath, product.slug, product.id]"
          linkId="link--go-to-product-detail"
        >
          <fp-product-information
            [name]="product.name"
            [prescription]="product.prescription"
            [productStatus]="productStatus"
          >
          </fp-product-information>
        </fp-link>

        <div class="product-description d-flex">
          <span class="text-tag"> {{ productPresentation?.description }}</span>
          <span class="separator">|</span>
          <fp-product-stock
            [stockPrincipal]="stockPrincipal"
            [stockBackup]="stockBackup"
          ></fp-product-stock>
        </div>

        <div class="actions">
          <div class="action">
            <fp-product-presentation
              presentationTemplate="select"
              [presentationList]="product.presentations"
              [presentationSelected]="product.presentationIdSelected"
              [productStatus]="productStatus"

              (updatePresentation)="setProductPresentation($event)"
            >
            </fp-product-presentation>
          </div>
          <div class="action">
            <fp-product-quantity
              [productQuantity]="product.quantity"
              [quantityLimit]="productPresentation?.quantityLimit"
              [productStatus]="productStatus"
              [product]="product"
              [isAlertToast]="true"
              [isSeller]="product.isFromSeller"
              (updateQuantity)="setQuantity($event)"
            >
            </fp-product-quantity>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 d-flex product-prices">
    <ng-container *fpBrandAccess="[brand.inkafarma]">
      <fp-product-cart-price 
        [subTotal]="productPresentation.regularPrice"
        [regularPrice]="productPresentation.price" 
        [subTotalOffer]="productPresentation.offerPrice">
      </fp-product-cart-price>
      <fp-product-cart-price  
        [subTotal]=" product.subTotalAllPaymentMethod ? product.subTotalAllPaymentMethod : 0"
        [subTotalOffer]="product.subTotalWithPaymentMethod ? product.subTotalWithPaymentMethod: 0"
        [productStatus]="productStatus" [regularPrice]="product.subTotal ? product.subTotal : 0">
      </fp-product-cart-price>
    </ng-container>

    <ng-container *fpBrandAccess="[brand.mifarma]">
      <fp-product-cart-price-mifa 
        [subTotal]="productPresentation.regularPrice" 
        [regularPrice]="productPresentation.price"
        [subTotalOffer]="productPresentation.offerPrice">
      </fp-product-cart-price-mifa>
      <fp-product-cart-price-mifa  class="mx-2"
        [subTotal]=" product.subTotalAllPaymentMethod ? product.subTotalAllPaymentMethod : 0"
        [subTotalOffer]="product.subTotalWithPaymentMethod ? product.subTotalWithPaymentMethod: 0"
        [productStatus]="productStatus" [regularPrice]="product.subTotal ? product.subTotal : 0">
      </fp-product-cart-price-mifa>
    </ng-container>
  </div>

  <div class="col-2 text-center">
    <fp-button-ghost
      buttonId="btn--delete-product"
      buttonSize="small"
      buttonColor="red"
      buttonDisplay="block"
      (click)="deleteProduct()"
    >
      <span>Eliminar</span>
    </fp-button-ghost>
  </div>
</div>
