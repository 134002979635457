import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Observable } from 'rxjs';
import { auditTime, map, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppWindowScrollService {

  public scrolling$: Observable<number>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.scrolling$ = fromEvent(window, 'scroll')
      .pipe(
        auditTime(100),
        map(() => {
          return window.scrollY || this.document.documentElement.scrollTop;
        }),
        share()
      );
  }
}
