import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Asset } from 'contentful';
import { ContentfulAsset } from '../../../shared/models/contentful/contentful.model';


export enum EPositionImg{
    right= 'right',
    left= 'left',
}

export interface IContentfulBannerInkaclub {
    title: string;
    subTitle: string;
    description: string;
    button1: string;
    button2: string;
    imageResponsive: Asset;
    imageDesktop: Asset;
    logo: Asset;
    template: string;
    slogan: Asset;
    color: string;
    link: string;
    descriptionLong: string;
    breadcrumb : any;
    breadcrumbMobile:any;
    positionImg: 'right' | 'left',
    imageAltText: Asset;
    imageTitle: Asset;
}

export class ContentfulBannerInkaclub {
    title: string;
    subTitle: string;
    description: string;
    button1: string;
    button2: string;
    smallImage: string;
    largeImage: string;
    logo: string;
    template: string;
    slogan: string;
    color: string;
    link: string;
    descriptionLong: string;
    breadcrumb : any;
    breadcrumbMobile : any;
    positionImg: 'right' | 'left';

    imageAltText: string;
    imageTitle: string;

    constructor(iContentfulBannerInkaclub: IContentfulBannerInkaclub) {
        this.title = iContentfulBannerInkaclub.title;
        this.subTitle = iContentfulBannerInkaclub.subTitle;
        this.description = iContentfulBannerInkaclub.description;
        this.button1 = iContentfulBannerInkaclub.button1 || 'Inscribirme a Inkaclub';
        this.button2 = iContentfulBannerInkaclub.button2 || 'Ya soy miembro';
        this.descriptionLong = iContentfulBannerInkaclub.descriptionLong;
        this.breadcrumb = iContentfulBannerInkaclub.breadcrumb;
        this.breadcrumbMobile = iContentfulBannerInkaclub.breadcrumbMobile;
        this.positionImg = iContentfulBannerInkaclub.positionImg;
        this.color = iContentfulBannerInkaclub.color || "#fff";

        if (iContentfulBannerInkaclub.imageResponsive) {
            this.smallImage = new ContentfulAsset(iContentfulBannerInkaclub.imageResponsive).asset;
        }

        if (iContentfulBannerInkaclub.imageDesktop) {
            this.largeImage = new ContentfulAsset(iContentfulBannerInkaclub.imageDesktop).asset;
        }

        if (iContentfulBannerInkaclub.logo) {
            this.logo = new ContentfulAsset(iContentfulBannerInkaclub.logo).asset;
        }

        this.template = iContentfulBannerInkaclub.template;

        if (iContentfulBannerInkaclub.slogan) {
            this.slogan = new ContentfulAsset(iContentfulBannerInkaclub.slogan).asset;
        }

        if (iContentfulBannerInkaclub.imageAltText) {this.imageAltText = new ContentfulAsset(iContentfulBannerInkaclub.imageAltText).asset;}
        if (iContentfulBannerInkaclub.imageTitle) {this.imageTitle = new ContentfulAsset(iContentfulBannerInkaclub.imageTitle).asset;}

    }
}

export const CContentfulBannerInkaclub = {
    id: CONTENTFUL_PATH.contentTypeIds.bannerInkaclub
};

export enum EContentfulBannerInkaclub {
    template1 = 'template 1',
    template2 = 'template 2',
    template3 = 'template 3',
    templateBreadcrumb = 'template breadcrumb',
}
