import { Injectable } from '@angular/core';
import { CONTENTFUL_PATH } from 'src/app/shared/parameters/endpoints';
import { IMainFooter } from '../models/footer.model';
import { CONTENTFUL_QUERIES } from '../../../../shared/parameters/querys';
import { GenericContentfulService } from '../../../../shared/service/generic';


@Injectable({
  providedIn: 'root'
})
export class FooterClientService {

  constructor(
    private _generictContenfulService: GenericContentfulService
  ) {
  }

  public getFooter() {
    return this._generictContenfulService.getEntriesPromise<IMainFooter>(
      CONTENTFUL_PATH.contentTypeIds.footer,
      CONTENTFUL_QUERIES.prototype.getFirstEntryOfArray());
  }
}
