import { Injectable } from '@angular/core';
import { IAliviamedSessionsRequest, IAliviamedWhatsappRequest } from '@models/aliviamed/aliviamed.model';
import { AliviamedClientService } from 'src/app/shared/service/aliviamed/aliviamed-client.service';

@Injectable({
  providedIn: 'root'
})
export class AliviamedImplementService {

  constructor(
    private aliviamedClient: AliviamedClientService,
  ) { }

  public postSessions$(params: IAliviamedSessionsRequest) {
    return this.aliviamedClient.postSessions$(params);
  }

  public generateWspLink$(params: IAliviamedWhatsappRequest) {
    return this.aliviamedClient.generateWspLink$(params);
  }

  public resendLink$(params: any) {
    return this.aliviamedClient.resendWspLink$(params);
  }

  public setStatusCart$(params: any, idCart:any) {
    return this.aliviamedClient.setStatusCart$(params, idCart);
  }

  public getPatientsData$(params: string) {
    return this.aliviamedClient.getPatientsData$(params);
  }
}
