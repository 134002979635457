import { isObject } from "../../helpers/objects-equal";

// REQUEST AND RESPONSE
export interface IAddressRequestParams {
  apartment: string;
  city: string; // "Lima"
  country: string; // "Perú"
  district: string; // "La Molina"
  id: string;
  latitude: number; // -12.072613038655128
  longitude: number; // -76.94529452392577
  name: string; // "Mi casa"
  notes: string; // ""
  number: string; // "649"
  state: string; // ""
  street: string; // "Avenida  Flora Tristan"
}
export interface IAddressResponse {
  id: string;
}

// DRUGSTORE ADDRESS
export interface IDrugstoreAddress {
  address: string;
  currentCapacity: any;
  description: string;
  id: number;
  inkaVentaId: any;
  latitude: any;
  localCode: any;
  localZones: any;
  longitude: any;
  maxCapacity: any;
  name: string;
  ubigeo: any;
  zones: any;
}

export class DrugstoreAddress {
  public address: string;
  public currentCapacity: any;
  public description: string;
  public id: number;
  public inkaVentaId: any;
  public latitude: any;
  public localCode: any;
  public localZones: any;
  public longitude: any;
  public maxCapacity: any;
  public name: string;
  public ubigeo: any;
  public zones: any;

  constructor(drugstore: IDrugstoreAddress) {
    const currentValue = isObject(drugstore)
      ? drugstore
      : ({} as IDrugstoreAddress);
    this.address = currentValue.address || "";
    this.currentCapacity = currentValue.currentCapacity || "";
    this.description = currentValue.description || "";
    this.id = currentValue.id || -1;
    this.inkaVentaId = currentValue.inkaVentaId || "";
    this.latitude = currentValue.latitude || "";
    this.localCode = currentValue.localCode || "";
    this.localZones = currentValue.localZones || "";
    this.longitude = currentValue.longitude || "";
    this.maxCapacity = currentValue.maxCapacity || "";
    this.name = currentValue.name || "";
    this.ubigeo = currentValue.ubigeo || "";
    this.zones = currentValue.zones || "";
  }
}

// ENABLED SCHEDULE
export interface IEnabledDay {
  id: number;
  value: string;
  today: string;
  text: string;
  sellerId?: string;
}
export interface IEnabledSchedule {
  day: IEnabledDay[];
  hour: string | null;
}

// USER ADDRESS
export interface IAddressItem {
  id: string;
  zoneId: number;
  name: string;
  district: string;
  street: string;
  number: string;
  apartment: string;
  city: string;
  country: string;
  state: string;
  latitude: number;
  longitude: number;
  notes: string;
  favorite: boolean;
  canContinue: boolean;
  programmedZone: boolean;
  viaId: number;
  requireEdition: string;
  otherDirection: string;
  reference: string;
  lote?: string;
  mz?: string;
  drugstoreAddress?: IDrugstoreAddress;
  schedule?: IEnabledSchedule;
  servicesTypes?: IServiceType[];
  idNumber?: number;
}

export interface IServiceType {
  code: string;
  enabled: boolean;
  selected: boolean;
}

export class ServiceType {
  public code: string;
  public enabled: boolean;
  public selected: boolean;

  constructor(serviceType: IServiceType) {
    this.code = serviceType.code;
    this.enabled = serviceType.enabled;
    this.selected = serviceType.selected;
  }
}

export class AddressItem {
  public id: string;
  public zoneId: number;
  public name: string; // "Mi Casa", "Trabajo", etc.
  public district: string; // "Miraflores"
  public street: string; // "Alcanfores"
  public number: string; // 250
  public apartment: string; // apartment #43 (no format validation)
  public city: string; // Lima
  public country: string; // Peru
  public state: string; // Lima
  public latitude: number;
  public longitude: number;
  public notes: string;
  public favorite: boolean;
  public canContinue: boolean;
  public programmedZone: boolean;
  public viaId: number;
  public requireEdition: string;
  public otherDirection: string; // when other option is selected
  public reference: string;
  lote?: string;
  mz?: string;
  public drugstoreAddress?: DrugstoreAddress;
  public schedule?: IEnabledSchedule;
  public servicesTypes?: IServiceType[];
  public idNumber?: number;
  public isNew?: boolean;
  constructor(addressItem: IAddressItem) {
    const currentValue = isObject(addressItem)
      ? addressItem
      : ({} as IAddressItem);
    this.id = currentValue.id || "";
    this.zoneId = currentValue.zoneId || -1;
    this.name = currentValue.name || "";
    this.district = currentValue.district || "";
    this.street = currentValue.street || "";
    this.number = currentValue.number || "";
    this.apartment = currentValue.apartment || "";
    this.city = currentValue.city || "";
    this.country = currentValue.country || "";
    this.state = currentValue.state || "";
    this.latitude = Number(currentValue.latitude) || -1;
    this.longitude = Number(currentValue.longitude) || -1;
    this.notes = currentValue.notes || "";
    this.favorite = currentValue.favorite || false;
    this.canContinue = currentValue.canContinue || false;
    this.programmedZone = currentValue.programmedZone || false;
    this.viaId = currentValue.viaId || -1;
    this.requireEdition = currentValue.requireEdition || "";
    this.otherDirection = currentValue.otherDirection || "";
    this.lote = currentValue.lote || "";
    this.mz = currentValue.mz || "";
    this.reference = currentValue.reference || "";
    this.drugstoreAddress = new DrugstoreAddress(currentValue.drugstoreAddress);
    this.schedule = currentValue.schedule || ({} as IEnabledSchedule);
    this.idNumber = currentValue.idNumber || 0;
    const servicesTypes = Array.isArray(currentValue.servicesTypes)
      ? currentValue.servicesTypes
      : [];
    this.servicesTypes = servicesTypes.map(
      (service) => new ServiceType(service)
    );
    this.isNew = false;
  }
}
