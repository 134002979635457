import { ProductPresentation, ProductPresentations, ProductQuantityLimit } from '../product-presentation.model';
import { IProductShoppingCart } from './product-shopping-cart.interface';
import { ProductImageBySize } from '../product-image.model';
import { EProductPresentation } from '../product-presentation.enum';

class ProductShoppingCartPresentation extends ProductPresentation {
  constructor(iProductShoppingCart: IProductShoppingCart) {
    super();
    this.image = new ProductImageBySize(iProductShoppingCart.imageList, 'M').image;
    this.regularPrice = iProductShoppingCart.regularPrice || 0;
    this.offerPrice = iProductShoppingCart.offerPrice || 0;
  }
}

class UnitShoppingCartPresentation extends ProductShoppingCartPresentation {
  constructor(iProductShoppingCart: IProductShoppingCart) {
    super(iProductShoppingCart);
    this.fractionated = true;
    this.id = iProductShoppingCart.fractionatedPresentationId || EProductPresentation.unit;
    this.stock = iProductShoppingCart.fractionalStock || 0;
    this.maxUnitSale = iProductShoppingCart.maxUnitSaleFractionated || 0;
    this.price = iProductShoppingCart.fractionatedPrice || 0;
    this.description = iProductShoppingCart.fractionatedText || '';
    this.presentation = iProductShoppingCart.fractionatedForm || '';
    this.quantityLimit = new ProductQuantityLimit(this).quantityLimit || 0;
    this.regularPrice = iProductShoppingCart.fractionatedPriceAllPaymentMethod || 0;
    this.offerPrice = iProductShoppingCart.fractionatedPriceWithpaymentMethod || 0;
    this.crossOutPL = iProductShoppingCart.crossOutPL;
  }
}

class PackShoppingCartPresentation extends ProductShoppingCartPresentation {
  constructor(iProductShoppingCart: IProductShoppingCart) {
    super(iProductShoppingCart);
    this.fractionated = false;
    this.id = iProductShoppingCart.presentationId || EProductPresentation.pack;
    this.stock = iProductShoppingCart.stock || 0;
    this.maxUnitSale = iProductShoppingCart.maxUnitSale || 0;
    this.price = iProductShoppingCart.price || 0;
    this.description = iProductShoppingCart.noFractionatedText || '';
    this.presentation = iProductShoppingCart.presentation || '';
    this.quantityLimit = new ProductQuantityLimit(this).quantityLimit || 0;
    this.regularPrice = iProductShoppingCart.priceAllPaymentMethod || 0;
    this.offerPrice = iProductShoppingCart.priceWithpaymentMethod || 0;
    this.crossOutPL = iProductShoppingCart.crossOutPL;
  }
}


export class ProductShoppingCartPresentations extends ProductPresentations {
  constructor(iProductShoppingCart: IProductShoppingCart) {
    super();
    this.productPresentations = [new PackShoppingCartPresentation(iProductShoppingCart)];
    if (iProductShoppingCart.fractionalMode) {
      this.productPresentations = [new UnitShoppingCartPresentation(iProductShoppingCart), ...this.productPresentations];
    }
  }
}

