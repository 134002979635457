import { Injectable } from '@angular/core';
import { IUserRegister } from '../../models/register/register.model';
import { BehaviorSubject } from 'rxjs';
import { AUTH_ORIGIN } from '../../parameters/global';

@Injectable({
  providedIn: 'root'
})
export class RegisterInfoTypeStoreService {

  private _information: IUserRegister;
  private _informationSubject = new BehaviorSubject({} as IUserRegister);
  public information$ = this._informationSubject.asObservable();
  public get information() {
    return this._information;
  }

  public errorCodeType: string;

  public authenticationOrigin = AUTH_ORIGIN.DEFAULT;

  constructor() {
    this.information$.subscribe(info => {
      this._information = info;
    });
  }

  public setInformation(info: IUserRegister) {
    this._informationSubject.next(info);
  }

  public setEmailAndPasswordAuthenticationOrigin() {
    /**
     * Aquí seteamos que el logueo escon GOOGLE, FACEBOOK o APPLE
     */
    this.authenticationOrigin = AUTH_ORIGIN.EMAIL_PASSWORD;
  }

  public setProviderAuthenticationOrigin() {
    /**
     * Aquí seteamos que el proceso de registro viene desde EMAIL y PASSWORD
     */
    this.authenticationOrigin = AUTH_ORIGIN.PROVIDER;
  }

  public setProviderIncompleteUserInfoByCartOrigin() {
    /**
     * Aquí seteamos que el proceso de registro viene desde cualquier pantalla usuario ya logueado
     */
    this.authenticationOrigin = AUTH_ORIGIN.DEFAULT;
  }
}
