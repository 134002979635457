<div
	class="input-control"
	[ngClass]="{
		'margin-with-error': marginWithError,
		'margin-without-error': !marginWithError,
		'margin-bottom-zero': marginBottomZero
	}"
>
	<div class="input-control-title" *ngIf="inputTitle">{{ inputTitle }} {{ isMandatory ? "*" : "" }}</div>
  <ng-content></ng-content>
	<div class="label--2 errorMessage" [ngClass]="{ 'join-to-input': errorMsgJoinToInput }" *ngIf="errorMessage">
		{{ errorMessage }}
	</div>
</div>
