import { Injectable } from '@angular/core';

@Injectable()
export class OnlinePaymentHelperService {

  private _isOnlinePaymentError = false;
  private _otherErrorMessage1 = '';
  private _otherErrorMessage2 = '';

  constructor() { }

  public get isOnlinePaymentError() {
    return this._isOnlinePaymentError;
  }

  public set isOnlinePaymentError(status: boolean) {
    this._isOnlinePaymentError = status;
  }

  public get otherErrorMessage1() {
    return this._otherErrorMessage1;
  }

  public set otherErrorMessage1(message: string) {
    this._otherErrorMessage1 = message;
  }

  public get otherErrorMessage2() {
    return this._otherErrorMessage2;
  }

  public set otherErrorMessage2(message: string) {
    this._otherErrorMessage2 = message;
  }
}
