import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FindClientModalComponent } from "../modal-components/find-client-modal/find-client-modal.component";

@Injectable({
  providedIn: "root",
})
export class FindClientService {
  constructor(private dialog: MatDialog) {}

  showModalFindClient() {
    setTimeout(() => {
      this.dialog.open(FindClientModalComponent, {
        disableClose: true,
        panelClass: "custom-full-x-modal-overlay",
      });
    }, 100);
  }
  closeModal() {
    this.dialog.closeAll();
  }
}
