import { FormControl } from '@angular/forms';
import { ScheduleItem } from 'src/app/shared/models/schedule/schedule.model';
import * as moment from 'moment';

export function getEnableErrorMessages(formControl: FormControl): boolean {
  const value = formControl.invalid && (formControl.touched || formControl.dirty);
  return value;
}

export function getErrorbyFormControl(formControl: FormControl, errorMessageArray: any): string {
  if (getEnableErrorMessages(formControl)) {
    if (formControl.errors) {
      const errorKeys = Object.keys(formControl.errors);
      const currentKey = errorKeys[0];
      return errorMessageArray[currentKey];
    }
  }
  return '';
}

export function resetControlHelper(control: FormControl) {
  control.setValue('');
  control.markAsUntouched();
  control.markAsPristine();
}

export function getCurrentDate() {
  const currentDate = new Date();

  const date = currentDate.getDate();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();

  const dateString = year + '-' + (month + 1) + '-' + date;
  return dateString;
}

export const getFormatedDate = (day?: Date) => {
  const validDay = day ? day : moment();
  return moment(validDay).format('YYYY-MM-DD');
};

export const getThreeDays = (today: string) => {
  const DAYS_IN_MILISECONDS = 1000 * 60 * 60 * 24;
  const firstDate = moment(today, 'YYYY-MM-DD').toDate();
  const firstDateTime = firstDate.getTime();
  const secondDate = new Date(firstDateTime + DAYS_IN_MILISECONDS);
  const thirdDate = new Date(firstDateTime + DAYS_IN_MILISECONDS + DAYS_IN_MILISECONDS);
  return {
    firstDate: getFormatedDate(firstDate),
    secondDate: getFormatedDate(secondDate),
    thirdDate: getFormatedDate(thirdDate),
  };
};

export interface IObjectDay {
  text: string;
  value: string;
  isToday: string;
}

export const getFormatedThreeDays = (today: string) => {
  const threeDays = getThreeDays(today);
  return [
    { text: 'Hoy', value: threeDays.firstDate, isToday: 'Y' } as IObjectDay,
    { text: 'Mañana', value: threeDays.secondDate, isToday: 'N' } as IObjectDay,
    { text: 'Pasado mañana', value: threeDays.thirdDate, isToday: 'N' } as IObjectDay,
  ];
};

export const getFormatedFromSchedule = (scheduleList: ScheduleItem[]) => {
  return scheduleList.map(schedule => {
    return {
      text: schedule.text, value: schedule.value, isToday: schedule.today
    } as IObjectDay;
  });
};

export const getFormatedTwoDays = (day: string) => {
  const DAYS_IN_MILISECONDS = 1000 * 60 * 60 * 24;
  const firstDate = moment(day, 'YYYY-MM-DD').toDate();
  const firstDateTime = firstDate.getTime();
  const secondDate = new Date(firstDateTime + DAYS_IN_MILISECONDS);
  return [
    { text: 'Mañana', value: getFormatedDate(firstDate), isToday: 'N' },
    { text: 'Pasado mañana', value: getFormatedDate(secondDate), isToday: 'N' },
  ];
};

