import { ICategoriesLandingBrands, ICategoriesLandingCategories, ICategoriesLandingResponse, ICategoriesLandingSubcategories } from './categories-landing-response.interface';
import { FilteredProductsFilterSection } from '../../../core/modules/filtered-products/model/filtered-products-filter-section.model';

export class CategoriesLandingFilterSection {
  id: number;
  name: string;
  keyword: string;
  background: string;
  backgroundMobile: string;
  brands?: Array<CategoriesLandingBrands>;
  filter?: Array<CategoriesLandingFilterSection>;
}

export class CategoriesLandingBrands {
  id: number;
  name: string;

  constructor(iCategoriesLandingBrands: ICategoriesLandingBrands) {
    this.id = iCategoriesLandingBrands.id;
    this.name = iCategoriesLandingBrands.name;
  }
}

class CategoriesLandingFilterSectionBySubcategories extends CategoriesLandingFilterSection {

  constructor(iCategoriesLandingCategories: ICategoriesLandingSubcategories) {
    super();
    this.id = iCategoriesLandingCategories.id || 0;
    this.name = iCategoriesLandingCategories.name || '';
    this.keyword = iCategoriesLandingCategories.keyword || '';
    this.background = iCategoriesLandingCategories.url || '';
    this.backgroundMobile = iCategoriesLandingCategories.urlMobile || '';
    this.brands = iCategoriesLandingCategories.brands && iCategoriesLandingCategories.brands.length ?
      iCategoriesLandingCategories.brands.map(brand => new CategoriesLandingBrands(brand)) : [];
  }
}

class CategoriesLandingFilterSectionByCategories extends CategoriesLandingFilterSection {

  constructor(iCategoriesLandingCategories: ICategoriesLandingCategories) {
    super();
    this.id = iCategoriesLandingCategories.id || 0;
    this.name = iCategoriesLandingCategories.name || '';
    this.keyword = iCategoriesLandingCategories.keyword || '';
    this.background = iCategoriesLandingCategories.url || '';
    this.backgroundMobile = iCategoriesLandingCategories.urlMobile || '';
    this.brands = iCategoriesLandingCategories.brands && iCategoriesLandingCategories.brands.length ?
      iCategoriesLandingCategories.brands.map(brand => new CategoriesLandingBrands(brand)) : [];
    this.filter = iCategoriesLandingCategories.subCategories && iCategoriesLandingCategories.subCategories.length ?
      iCategoriesLandingCategories.subCategories.map(subCategories => new CategoriesLandingFilterSectionBySubcategories(subCategories)) : [];
  }
}

export class CategoriesLandingResponse extends CategoriesLandingFilterSection {

  constructor(iCategoriesLandingResponse: ICategoriesLandingResponse) {
    super();
    this.id = iCategoriesLandingResponse.id || 0;
    this.name = iCategoriesLandingResponse.name || '';
    this.keyword = iCategoriesLandingResponse.keyword || '';
    this.background = iCategoriesLandingResponse.url || '';
    this.backgroundMobile = iCategoriesLandingResponse.urlMobile || '';
    this.brands = iCategoriesLandingResponse.brands && iCategoriesLandingResponse.brands.length ?
      iCategoriesLandingResponse.brands.map(brand => new CategoriesLandingBrands(brand)) : [];

    this.filter = iCategoriesLandingResponse.categories && iCategoriesLandingResponse.categories.length ?
      iCategoriesLandingResponse.categories.map(category => new CategoriesLandingFilterSectionByCategories(category)) : [];

  }
}

export class CategoriesLandingFilterSectionToFilterSection extends FilteredProductsFilterSection {
  constructor(categoriesLandingFilterSection: CategoriesLandingFilterSection) {
    super();
    this.id = categoriesLandingFilterSection.id || 0;
    this.name = categoriesLandingFilterSection.name || '';
    if (categoriesLandingFilterSection.brands && categoriesLandingFilterSection.brands.length) {
      this.brands = categoriesLandingFilterSection.brands || [];
    }
    if (categoriesLandingFilterSection.filter && categoriesLandingFilterSection.filter.length) {
      this.filter = categoriesLandingFilterSection.filter.map(filter => new CategoriesLandingFilterSectionToFilterSection(filter)) || [];
    }
  }
}
