import { Asset, Entry, RichTextContent } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Document } from '@contentful/rich-text-types';
export class ContentfulAsset {
  asset: string;

  constructor(asset: Asset) {
    if (!!asset.fields) {
      this.asset = asset.fields.file.url;
    } else if (!asset.fields && asset.sys) {
      this.asset = './assets/images/product/error-image.svg';
    }
  }
}

export class ContentfulRichText {
  richText: any;
  constructor(richText: Document) {
    if (!!richText) {
      this.richText = documentToHtmlString(richText)
    } else {
      this.richText = ''
    }
  }
}

export class ContentfulEntry<T> {
  content: T;
  type: string;
  id: string;
  bannerIndex?: number;
  components: any;

  constructor(entry: Entry<T>) {
    if (entry.fields) { this.content = entry.fields; }
    if (entry.sys.id) { this.id = entry.sys.id; }
    this.type = entry.sys.contentType ? entry.sys.contentType.sys.id : entry.sys['linkType'];
  }
}
