export interface IAnonymousUserFormStore {
  dni: string;
  emailAddress: string;
  name: string;
  phoneNumber: string;
  fatherLastName: string;
  motherLastName: string;
  documentType: string;
  document: string;
  datesAndRegister: boolean;
  flagDataTreatment: boolean;
}

export const INITIAL_ANONYMOUS_USER_FORM_STORE: IAnonymousUserFormStore = {
  dni: "",
  emailAddress: "",
  name: "",
  fatherLastName: "",
  motherLastName: "",
  documentType: "",
  document: "",
  datesAndRegister: false,
  phoneNumber: "",
  flagDataTreatment: false,
};
