import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'fp-payment-card-radio-button',
  templateUrl: './payment-card-radio-button.component.html',
  styleUrls: ['./payment-card-radio-button.component.sass']
})
export class PaymentCardRadioButtonComponent {

  @Input() imageSrc = '';
  @Input() discountText = '';
  @Input() selected = false;

  @Input() isDisabled = true;

  @Output() selectRadioOption = new EventEmitter();

  constructor() { }

  public selectOption() {
    if (!this.selected) {
      this.selectRadioOption.emit();
    }
  }
}
