<div class="who-pick-up-modal" [ngStyle]="{ opacity: isLoadingModal ? '0.5' : '1' }">
	<div class="title">
		<fp-section-container-title svgName="user-green" iconSize="32" title="¿Quién recogerá el pedido?" [unfilled]="true">
		</fp-section-container-title>
		<div class="button-modal-close" (click)="closeModal()">
			<span class="icon icon-24-close"></span>
		</div>
	</div>

	<div class="content">
		<form [formGroup]="whoPickUpForm.form" class="form-group">
			<div class="row">
				<div class="col-12 col-lg-12 col-xl-6 mb-1">
					<div class="label-title">Nombres y apellidos</div>
					<fp-input-control-v2 [errorMessage]="whoPickUpForm.nameControl.nameError" [marginWithError]="true">
						<input
							type="text"
							placeholder="Ingresa los nombres y apellidos"
							id="name"
							class="input"
							maxlength="50"
							formControlName="name"
							#inputControl
							fpCharacterOnly
						/>
					</fp-input-control-v2>
				</div>

				<div class="col-12 col-lg-12 col-xl-6 mb-1">
					<div class="label-title">Correo electrónico</div>
					<fp-input-control-v2 [errorMessage]="whoPickUpForm.emailAddressControl.emailAddressError" [marginWithError]="true">
						<input
							type="text"
							placeholder="Ingresa el correo electrónico"
							id="email"
							class="input"
							maxlength="50"
							formControlName="emailAddress"
							#inputControl
						/>
					</fp-input-control-v2>
				</div>

				<div class="col-12 col-lg-12 col-xl-6 mb-1 documents-type-select">
					<div class="label-title">Tipo de documento</div>
					<div class="documents-type-select-control">
						<select formControlName="typeDoc" id="typeDocument">
							<option [value]="type.value" *ngFor="let type of typesDocs">
								{{ type.text }}
							</option>
						</select>
						<div class="icon-container">
							<div class="icon"></div>
						</div>
					</div>
				</div>

				<div class="col-12 col-lg col-xl-6 mb-1">
					<div class="label-title">Número de documento</div>
					<fp-input-control-v2 [errorMessage]="whoPickUpForm.dniControl.documentError" [marginWithError]="true">
						<input
							type="text"
							placeholder="Ingresa el número de documento"
							id="dni"
							class="input"
							maxlength="12"
							formControlName="dni"
							#inputControl
						/>
					</fp-input-control-v2>
				</div>

				<div class="col-12 col-lg-12 col-xl-6">
					<div class="label-title">Número de celular</div>
					<fp-input-control-v2 [errorMessage]="whoPickUpForm.phoneNumberControl.phoneNumberError" [marginWithError]="true">
						<input
							type="text"
							placeholder="Ingresa el número de celular"
							id="phone"
							class="input"
							maxlength="9"
							formControlName="phoneNumber"
							#inputControl
							fpDigitOnly
						/>
					</fp-input-control-v2>
				</div>

				<div class="row-buttons" [hidden]="!isEdit">
					<div class="mr-2 content-link-eliminar">
						<fp-link-buttom
							class="link-eliminar"
							[id]="'eliminar'"
							[buttonText]="'Eliminar'"
							(onclick)="deleteUser()"
						></fp-link-buttom>
					</div>
					<div class="ml-2 content-confirm">
						<fp-button-primary
							id="btnEdit"
							(click)="onRegistrar()"
              [buttonDisplay]="isDesktopVersion ? '' : 'block'"
							[buttonDisabled]="!whoPickUpForm.form.valid || isLoadingModal"
							>{{ isDesktopVersion ? "Confirmar" : "Guardar" }}</fp-button-primary
						>
					</div>
				</div>

				<div class="btn-container" *ngIf="!isEdit">
					<fp-button-primary
						id="btnConfirm"
            class="btn-confirm"
            [buttonClass]="'w-full'"
						(click)="onRegistrar()"
            [buttonDisplay]="isDesktopVersion ? '' : 'block'"
						[buttonDisabled]="!whoPickUpForm.form.valid || isLoadingModal"
						>Confirmar</fp-button-primary
					>
				</div>
			</div>
		</form>
	</div>
</div>
