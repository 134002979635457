import { Injectable } from '@angular/core';
import { CONTENTFUL_PATH } from '../../parameters/endpoints';
import { GenericContentfulService } from '../generic';
import { CONTENTFUL_QUERIES } from '../../parameters/querys';
import { STATIC_LANDING_SLUG } from 'src/app/business/static-landing/parameters/static-landing';

@Injectable()
export class StaticLandingClientService {
  constructor(
    private _GenericContentfulService: GenericContentfulService
  ) {
  }

  public getStaticLanding(slug: string) {
    return this._GenericContentfulService.getEntries(
      CONTENTFUL_PATH.contentTypeIds.staticLanding,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

  public getStaticLandingPage(slug: string) {
    return this._GenericContentfulService.getEntries(
      CONTENTFUL_PATH.contentTypeIds.staticLandingPage,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

  public getBlog(ids: Array<string>) {
    return this._GenericContentfulService.getEntries(
      CONTENTFUL_PATH.contentTypeIds.shortcut,
      CONTENTFUL_QUERIES.prototype.getEntriesByIds(ids)
    );
  }

  public getBannerListLanding(slug: string) {
    return this._GenericContentfulService.getEntries(
      CONTENTFUL_PATH.contentTypeIds.bannerList,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

  public getDashBoardLanding(section: string) {
    return this._GenericContentfulService.getEntries(
      CONTENTFUL_PATH.contentTypeIds.Dashboard,
      CONTENTFUL_QUERIES.prototype.getEntryBySection(section)
    );
  }

  public getCategoryLanding(keyword: string) {
    return this._GenericContentfulService.getEntries(
      CONTENTFUL_PATH.contentTypeIds.staticLanding,
      {
        ...CONTENTFUL_QUERIES.prototype.getEntryBySlug(STATIC_LANDING_SLUG.category),
        'fields.categoryKeyword': keyword
      }
    );
  }
}
