import { Injectable } from '@angular/core';
import { DiscountCodeClientService } from 'src/app/shared/service/discount-code/discount-code-client.service';
import { IDiscountCodeRequestParams } from 'src/app/shared/models/discount-code/discount-code.model';
import { OrderTakerValidator } from '@validators/order-taker.validator';
import { EBrand, EBrandAsset } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';
import { ClientStoreService } from '@stores/client/client-store.service';

@Injectable()
export class DiscountCodeImplementService {
  public isMifaBrand: boolean = OrderTakerValidator.isOrderTakerEnv()
    ? EBrand[EBrandAsset[environment.brand]] === EBrand.mifarma
    : EBrand[environment.brand] === EBrand.mifarma;
  public isInkaBrand = OrderTakerValidator.isOrderTakerEnv()
    ? EBrand[EBrandAsset[environment.brand]] == EBrand.inkafarma
    : EBrand[environment.brand] == EBrand.inkafarma;

  constructor(
    private discountCodeClient: DiscountCodeClientService,
    private clientStore: ClientStoreService,
  ) { }

  public validateDiscountCode$(params: IDiscountCodeRequestParams) {
    if (this.isInkaBrand) { params.firstBuyCall = this.clientStore.client?.isFirstBuyIKF ?? false }
    if (this.isMifaBrand) {
      params.firstBuyCall = this.clientStore.client?.isFirstBuyMF ?? false
    }

    return this.discountCodeClient.validateDiscountCode$(params);

  }
}
