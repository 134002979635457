<div class="p-4">
  <div class="dialog-close display-flex justify-content-end" (click)="close()">
    <span class="icon icon-24-close icon-close cursor-pointer"> </span>
  </div>

  <div class="notification-discount">
      <img src="../../../../../assets/icons/info.svg" alt="down" />

      <p class="pt-5 pb-4" [innerHTML]="dialogData[0].messageTalon"></p>

      <div class="notification-discount__actions d-flex flex-column text-center">
        <fp-button-primary class="mb-2" buttonClass="px-8 w-50" buttonDisplay="inline-block" (click)="close()"> Cerrar </fp-button-primary>
      </div>
  </div>
</div>
