import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ClientStoreService } from "@stores/client/client-store.service";
import { take } from "rxjs/operators";
import { OfficeModalService } from "src/app/business/office/services/office-modal.service";
import { SelectDataStoreService } from "src/app/business/office/store/select-data-store.service";
import { OfficeComponent } from "src/app/business/office/views/office/office.component";
import { StoreBackup } from "src/app/shared/models/store-backup.model";
import { Store } from "src/app/shared/models/store.model";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { AddressManagerPersistanceService } from "../../../address-manager/address-services/address-manager-persistance.service";
import { NewAddressManagerService } from "../../../address-manager/address-services/new-address-manager/new-address-manager.service";
import { AddressListStoreService } from "../../../address-manager/address-stores/address-list-store.service";
import { AddressItem } from "../../../address-manager/models/delivery-data";
import { FLOW_SEARCH_ADDRESS_TYPE } from "../../../address-manager/parameters/modal.constant";
import { AddressDrugstoreDispatchService } from "../../service/address-drugstore-dispatch.service";
import { DeliveryMethod } from "./../../../../../shared/models/schedule-response.model";
import { StorageClientService } from 'src/app/shared/storage/storage-client.service';
import { ProductOutOfStockDialogComponent } from "src/app/shared/components/product-out-of-stock-dialog/product-out-of-stock-dialog.component";
import { AppStoreService } from "@stores/app/app-store.service";
import { ALERT_STATES } from "@parameters/global";

@Component({
  selector: "fp-address-drugstore-dispatch",
  templateUrl: "./address-drugstore-dispatch.component.html",
  styleUrls: ["./address-drugstore-dispatch.component.sass"],
})
export class AddressDrugstoreDispatchComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() store: Store;
  @Input() nearestResponse: Store;
  @Input() delivery: Store;
  @Input() storeBackup: StoreBackup;
  @Input() listServicesTypes: any[];
  @Input() isLoading: boolean;
  @Input() isError: boolean;
  @Input() deliveryMethods: Array<DeliveryMethod>;
  @Input() isLoadingDelivery: boolean = true;
  @Input() isLoadingNearest: boolean;
  @Input() isRetOrRad: string;
  @Output() openModalChangeDrugstore: EventEmitter<boolean> = new EventEmitter();
  @Output() emitChangeNearStore: EventEmitter<boolean> = new EventEmitter();
  @Output() emitSelectRadioOffice: EventEmitter<number | string> =
  new EventEmitter<number | string>();
  public isCheckRet: boolean = false;
  public isCheckRad: boolean = false;
  onlyAlert = null;
  flagState:number =  null
  states = ALERT_STATES;
  public street: string;
  public retOrRad: boolean;

  constructor(
    public _addressDispatchService: AddressDrugstoreDispatchService,
    public _selectDataStore: SelectDataStoreService,
    private _officeModalService: OfficeModalService,
    private _clientStore: ClientStoreService,
    private _newAddressManager: NewAddressManagerService,
    private _addressListStore: AddressListStoreService,
    private officeModalRef: MatDialogRef<OfficeComponent>,
    public retValidateCartService: RetValidateCartService,
    private _addressManager: AddressManagerPersistanceService,
    private _appStore: AppStoreService,
  ) {}

  ngOnInit(): void {
    this._addressDispatchService.switch$.pipe(take(1)).subscribe((value) => {
      if (
        !this._addressDispatchService.resetData &&
        value &&
        !this._selectDataStore.isReadyToReset &&
        this._selectDataStore.nearbyStore
      ){
        console.log('✅ Call')
        this.onChange(value);
      }
    });

    this.listenFlagStateChange()
    this.retOrRad = this.retValidateCartService.isRetOrRadTomadorProcess;
    this.getAddressSelected();
    this.validateChecked();
  }

  get idDispatchControl(): FormControl {
    return this.form.controls["idDispatch"] as FormControl;
  }

  listenFlagStateChange(){
    this._selectDataStore.flagState$.subscribe((value) => {
      this.flagState = value;
      if(value === this.states.NOT_FOUND_DELIVERY || value === this.states.NOT_FOUND_COVERAGE){
        this.changeRETorRAD(true);
      }
    })
  }

  onChange(e) {
    if (typeof e === "boolean") {
      if (e && !this.isLoadingDelivery && !this.isLoading) {
        this.changeRETorRAD(e)
        this.emitChangeNearStore.emit(e);
      }else if (!this.isLoadingNearest && !this.isLoading){
        this.changeRETorRAD(e)
        this.onReset();
      }
    }
  }

  changeRETorRAD(isRET: boolean){
    this.isCheckRet = isRET;
    this.isCheckRad = !isRET;
    this._appStore.setStorageItem("isRetOrRad", isRET ? "RET" : 'RAD');
  }

  validateChecked(){
    if(this.isRetOrRad === 'RAD' || this.retOrRad){
      this.isCheckRet = false;
      this.isCheckRad = true;
    }else{
      this.isCheckRet = true;
      this.isCheckRad = false;
    }
  }

  onReset() {
    this._selectDataStore.isReadyToReset = false;
    this._addressDispatchService.setResetData(true);
    this._addressDispatchService.setSwitch(false);
  }

  onChangeStoreAuto(value: boolean) {
    this.emitChangeNearStore.emit(value);
  }

  openChangeDrusgStore(){
    if(this.isCheckRet){
      this.openModalChangeDrugstore.emit(false);
    }
  }

  openChangeAddress(){
    if(this.isCheckRad){
      this.onDispatchModal();
      this.officeModalRef.close();
    }
  }

  onDispatchModal() {
    const value = this._clientStore.client;
    if(value){
      this._addressListStore.setAddressList(
        (value?.addresses as Array<AddressItem>)
      );
    }
    if (value?.addresses?.length === 0){
      this._newAddressManager.showNewAddressModal(
        FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS
      );
    } else {
      this._officeModalService.openOfficeModal();
    }
  }

  getAddressSelected(){
    this._addressManager.sendAddressItem$.subscribe(
      (res) => {
        if (res) {
          this.street = `${res ? res.street : ""}`
        }
      }
    );
  }
}
