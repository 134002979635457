import { ProcessingPaymentModalManagerService } from './business/checkout/checkout-services/processing-payment-modal-manager.service';
import { GuardValidatorService } from './shared/stores/app/guard-validator.service';
import { RegistrationValidationService } from './core/modules/register/register-services/registration-validation.service';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {AppStoreService} from './shared/stores/app/app-store.service';
import {CategoryListStoreService} from './core/modules/header/services/category-list-store.service';
import {MenuCategoriesImplementService} from './core/modules/header/services/menu-categories-implement.service';
import {MenuCategoriesClientService} from './shared/service/menu-categories/menu-categories-client.service';
import {GeolocationService} from './shared/geolocation/geolocation.service';
import {ShoppingListStoreService} from './shared/stores/shopping-list/shopping-list-store.service';
import {UserInfoStoreService} from './shared/stores/user/user-info-store.service';
import {AddressListStoreService} from './core/modules/address-manager/address-stores/address-list-store.service';
import {AddressNicknamesStoreService} from './core/modules/address-manager/address-stores/address-nicknames-store.service';
import {DeliveryInfoClientService} from './shared/service/delivery-info/delivery-info-client.service';
import {CoverageLocationClientService} from './shared/service/coverage-location/coverage-location-client.service';
import {NewRegisterStoreService} from './shared/stores/user/new-register-store.service';
import {NewRegisterAccessStoreService} from './shared/stores/user/new-register-access-store.service';
import {RegisterOriginStoreService} from './core/modules/register/register-stores/register-origin-store.service';
import {CheckoutPurchaseConfirmedStoreService} from './business/checkout/checkout-stores/checkout-purchase-confirmed-store.service';
import {GenericService} from './shared/service/generic';
import { GeolocationStoreService } from './business/consulta-stock/stock-stores/geolocation-store.service';

@NgModule()
export class AppFactoryModule {

  static forRoot(): ModuleWithProviders<AppFactoryModule> {

    return {

      ngModule: AppFactoryModule,

      providers: [
        {
          provide: AppStoreService,
          useClass: AppStoreService
        },
        {
          provide: GuardValidatorService,
          useClass: GuardValidatorService
        },
        {
          provide: ShoppingListStoreService,
          useClass: ShoppingListStoreService
        },
        {
          provide: CategoryListStoreService,
          useClass: CategoryListStoreService
        },
        {
          provide: MenuCategoriesImplementService,
          useClass: MenuCategoriesImplementService
        },
        {
          provide: MenuCategoriesClientService,
          useClass: MenuCategoriesClientService
        },
        {
          provide: GenericService,
          useClass: GenericService
        },
        {
          provide: GeolocationService,
          useClass: GeolocationService,
        },
        {
          provide: GeolocationStoreService,
          useClass: GeolocationStoreService,
        },
        {
          provide: UserInfoStoreService,
          useClass: UserInfoStoreService
        },
        {
          provide: AddressListStoreService,
          useClass: AddressListStoreService,
        },
        {
          provide: AddressNicknamesStoreService,
          useClass: AddressNicknamesStoreService,
        },
        {
          provide: DeliveryInfoClientService,
          useClass: DeliveryInfoClientService,
        },
        {
          provide: CoverageLocationClientService,
          useClass: CoverageLocationClientService,
        },
        {
          provide: NewRegisterAccessStoreService,
          useClass: NewRegisterAccessStoreService,
        },
        {
          provide: RegisterOriginStoreService,
          useClass: RegisterOriginStoreService,
        },
        {
          provide: NewRegisterStoreService,
          useClass: NewRegisterStoreService,
        },
        {
          provide: CheckoutPurchaseConfirmedStoreService,
          useClass: CheckoutPurchaseConfirmedStoreService,
        },
        {
          provide: RegistrationValidationService,
          useClass: RegistrationValidationService,
        }
      ]

    };

  }
}
