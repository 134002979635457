import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: "root",
})
export class AddressDrugstoreDispatchService {
  private resetDataSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public resetData$: Observable<boolean> = this.resetDataSubject.asObservable();

  private swicthSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public switch$: Observable<boolean> = this.swicthSubject.asObservable();

  constructor() {}

  public setResetData(value: boolean) {
    this.resetDataSubject.next(value);
  }

  public get resetData() {
    return this.resetDataSubject.value;
  }

  public setSwitch(value: boolean) {
    this.swicthSubject.next(value);
  }

  public get switch() {
    return this.swicthSubject.value;
  }
}
