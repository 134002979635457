import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DOCUMENT_TYPE, DOCUMENT_TYPE_LABEL } from "@parameters/global";
import { AppStoreService } from "@stores/app/app-store.service";
import { Observable } from "rxjs/internal/Observable";
import { map, switchMap } from "rxjs/operators";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { END_POINTS_BUSSINESS } from "src/app/shared/parameters/generic/endpoints";
import { EHttpHeaders } from "src/app/shared/parameters/generic/http-header.parameter";
import { TokenStoreService } from "src/app/shared/stores/user/token-store.service";
import { IClientResponse } from "../interfaces/find-client.interface";
import { AddressResponse, ClientResponse } from "../models/client.model";
import { environment } from "src/environments/environment";
import { ENDPOINTS } from "@parameters/endpoints";
import { ApigeeTokenService } from "src/app/shared/service/apigee/apigee-token.service";

@Injectable()
export class FindClientRequestService {
	constructor(
		private _httpClient: HttpClient,
		private _tokenStoreService: TokenStoreService,
		private _appStore: AppStoreService,
		private _apigeeTokenService: ApigeeTokenService
	) {}

	getClients$(query: string, searchType: string): Observable<Array<ClientResponse>> {
		const headers = new HttpHeaders({
			[EHttpHeaders.accessToken]: this._tokenStoreService.accessToken
		});
		return this._httpClient
			.get<Array<IClientResponse>>(`${END_POINTS_BUSSINESS.SEARCH_CLIENTS}?query=${query}&searchType=${searchType}`, {
				headers
			})
			.pipe(
				map((response: Array<IClientResponse>) => {
					if (response.length === 1 && !response[0].idClient) {
						return [];
					}
					const clients = response.map((client) => {
						const newCLient = new ClientResponse(client, this._appStore.brandAtention);
						return newCLient;
					});
					return clients;
				})
			);
	}

	getApprovalDetail$(query): Observable<any> {
		return this._apigeeTokenService.getTokenFromOauthApproval().pipe(
			switchMap((res: any) =>{
				const newToken = res.access_token;
				const headers = new HttpHeaders({
					"Content-Type": "application/json; charset=utf-8",
					Authorization: `Bearer ${newToken}`
				});
				return this._httpClient.get<any>(`${ENDPOINTS.APIGEE_GET_CUSTOMER_APPROVAL}/${query.document}/detail`, {
					headers
				});
			})
		);
	}

	private mapToAddressItem(list: Array<AddressResponse>): Array<AddressItem> {
		let listMap = new Array<AddressItem>();
		list.map((item) => {
			let address = new AddressItem({
				id: item.idAddress,
				zoneId: 0,
				name: item.ownerName,
				district: item.district,
				street: item.addressDescription,
				number: item.number,
				apartment: "",
				city: "",
				country: "",
				state: "",
				latitude: item.latitude,
				longitude: item.longitude,
				mz: item.mz,
				lote: item.lote,
				notes: "",
				favorite: item.isPrincipal == "0" ? false : true,
				canContinue: true,
				programmedZone: true,
				viaId: 0,
				requireEdition: "",
				otherDirection: "",
				reference: item.referenceAddressDescription
			});
			listMap.push(address);
		});
		return listMap;
	}
}
