import { FormControl, Validators } from '@angular/forms';
import { GENDER_ERROR_MESSAGES } from '../parameters/global-error-message';
import { getErrorbyFormControl } from '../helpers/error-controls-helpers';
import { GenericValidator } from '../validators/generic-validator';

export class GenderControl extends FormControl {

  constructor() {
    super('');
    this.settingGenderValidators();
  }

  private settingGenderValidators() {
    this.setValidators([
      Validators.required,
      GenericValidator.validateGender
    ]);
  }

  public get genderError(): string {
    return getErrorbyFormControl(this, GENDER_ERROR_MESSAGES);
  }
}
