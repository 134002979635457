import { RegisterInfoTypeStoreService } from 'src/app/shared/stores/user/register-info-type-store.service';
import { RegisterResponse, UserRegister } from 'src/app/shared/models/register/register.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ECurrentUserStatus, ICurrentUser } from '../../models/register/register.model';
import { RegisterClientService } from '../../service/user-register/register-client.service';
import { UserInfoStoreService } from '../../stores/user/user-info-store.service';

@Injectable({
  providedIn: 'root'
})
export class UserV2ImplementService {

  constructor(
    private registerClient: RegisterClientService,
    private userInfoStore: UserInfoStoreService,
    private registerInfoTypeStore: RegisterInfoTypeStoreService,
  ) { }

  /**
   * Con este método intentamos evitar que el request hacia el
   * endpoint this.registerClient.getUser$() se realice màs de
   * una vez.
   * Recordar que este método trabaja directamente con
   * currentUser$ del servicio UserInfoStoreService
   */
  public loadCurrentUser(): void {
    const { currentUser } = this.userInfoStore;
    const { VALID_RESPONSE, PENDING, LOADING } = ECurrentUserStatus;
    if (PENDING === currentUser.status) {
      this.userInfoStore.setCurrentUser({
        status: LOADING,
        userResponse: null
      });
      this.registerClient.getUser$()
        .subscribe((userResponse: RegisterResponse) => {
          this.userInfoStore.setCurrentUser({
            status: VALID_RESPONSE,
            userResponse,
          });
          const data = userResponse.data as UserRegister;

          if (data && !data.isAnonymous) {
            this.registerInfoTypeStore.setInformation(data);
          }
        });
    }
  }
}
