import { Injectable } from '@angular/core';
import { CONTENTFUL_PATH, ENDPOINTS } from '../../parameters/endpoints';
import { GenericService, GenericContentfulService } from '../generic';

@Injectable()
export class SuggestionsAndEnquiriesClientService {

  constructor(
    private _genericContentfulService: GenericContentfulService,
    private _genericService: GenericService
  ) {
  }

  public getEmailSubject() {
    return this._genericContentfulService.getEntriesPromise(
      CONTENTFUL_PATH.contentTypeIds.suggestionsAndEnquiriesEmailSubjectList
    );
  }

  public postSuggestionsAndEnquiriesEmail(email) {
    return this._genericService.genericPostWithHeaderCompanyCode(ENDPOINTS.POST_SUGGESTIONS_AND_ENQUIRIES_EMAIL, email);
  }
}
