import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fp-bordered-selected-address',
  templateUrl: './bordered-selected-address.component.html',
  styleUrls: ['./bordered-selected-address.component.sass']
})
export class BorderedSelectedAddressComponent implements OnInit {

  @Input() drugStoreName = '';
  @Input() drugStoreAddress = '';
  @Output() onclick = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  changeAddress() {
    this.onclick.emit();
  }

}
