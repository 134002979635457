import { Injectable } from "@angular/core";
import { SearchLandingAlgoliaResponse } from "@models/search-landing/search-landing-response.model";
import { BehaviorSubject, Subscription } from "rxjs";
import { objectHasElements } from "../../../../shared/helpers/objects-equal";
import { FilteredProductsPagination } from "../../../../shared/models/filtered-products/filtered-products-pagination.model";
import { FilteredProductsResponse } from "../../../../shared/models/filtered-products/filtered-products-response.model";
import { ProductCard } from "../../product-card/models/product-card/product-card.model";
import { FilteredProductsRequestService } from "../implement/filtered-products-request.service";
import { FilteredProductsFilteredList } from "../model/filtered-products-filter-section.model";

@Injectable({
  providedIn: "root",
})
export class FilteredProductsStoreService {
  private productListFilteredStoreSubject: BehaviorSubject<Array<ProductCard>> = new BehaviorSubject(null);
  private filteredProductsRequestSubscription: Subscription;

  private productListFilteredProductsStore: Array<ProductCard> = [];
  public paginationFilteredProductsStore: FilteredProductsPagination = {} as FilteredProductsPagination;
  private filterListAlgolia: BehaviorSubject<SearchLandingAlgoliaResponse> = new BehaviorSubject(null);
  constructor(
    private _filteredProductsRequest: FilteredProductsRequestService,
  ) {}

  initFilteredProductsStore() {
    this.productListFilteredStoreSubject = new BehaviorSubject(null);
    this.resetFilteredProductsStore();

    if (
      !this.filteredProductsRequestSubscription ||
      this.filteredProductsRequestSubscription.closed
    ) {
      this.filteredProductsRequestSubscription =
        this._filteredProductsRequest.filteredProductsResponse$.subscribe(
          (filteredProductsResponse: FilteredProductsResponse) => {
            if (objectHasElements(filteredProductsResponse)) {
              this.paginationFilteredProductsStore.page =
                filteredProductsResponse.page;
              this.paginationFilteredProductsStore.totalPages =
                filteredProductsResponse.totalPages - 1;
              this.paginationFilteredProductsStore.totalRecords =
                filteredProductsResponse.totalRecords;
              this.setProductListFilteredStore$(filteredProductsResponse.row);
            }
          }
        );
    }
  }
  emptyProductList() {
    this.productListFilteredStoreSubject.next([]);
  }

  public set productsIdFilteredStore$(productsId: Array<string>) {
    this._filteredProductsRequest.filteredProductsRequestProductsId =
      productsId;
  }

  public setOrderByFilteredStore$(order: string, sort: string) {
    this.productListFilteredProductsStore = [];
    this._filteredProductsRequest.setFilteredProductsRequestOrderBy(
      order,
      sort
    );
  }

  public set paginationFilteredStore$(page: number) {
    this._filteredProductsRequest.filteredProductsRequestPagination = page;
  }

  public set rankingFilteredStore$(ranking: number) {
    this._filteredProductsRequest.rankingRequest = ranking;
  }

  public set rowsFilteredStore$(rows: number) {
    this._filteredProductsRequest.rowsRequest = rows;
  }

  public get paginationFilterStore$() {
    return this.paginationFilteredProductsStore;
  }

  public set filtersFilteredStore$(filteredList: FilteredProductsFilteredList) {
    this.productListFilteredProductsStore = [];
    this._filteredProductsRequest.setFilteredProductsRequestFilters(
      filteredList.departmentList,
      filteredList.categoryList,
      filteredList.subcategoryList,
      filteredList.brandList
    );
  }

  private setProductListFilteredStore$(productList: Array<ProductCard>) {
    this.productListFilteredProductsStore = [
      ...this.productListFilteredProductsStore,
      ...productList,
    ];
    this.productListFilteredStoreSubject.next(
      this.productListFilteredProductsStore
    );
  }

  public setOneProductUpdateListFilteredStore(product: ProductCard) {
    const indexProduct = this.productListFilteredProductsStore.findIndex(
      (p, i) => p.id === product.id
    );

    if (indexProduct !== -1) {
      this.productListFilteredProductsStore[indexProduct] = product;
      this.productListFilteredStoreSubject.next([
        ...this.productListFilteredProductsStore,
      ]);
    }
  }

  public get productListFilteredStore$() {
    return this.productListFilteredStoreSubject.asObservable();
  }

  resetFilteredProductsStore() {
    this.paginationFilteredProductsStore = {} as FilteredProductsPagination;
    this.productListFilteredProductsStore = [];
    this._filteredProductsRequest.resetFilteredProductsRequest();
  }

  destroyFilteredProductsStore() {
    this.resetFilteredProductsStore();
    this.productListFilteredStoreSubject.unsubscribe();
    this.filteredProductsRequestSubscription.unsubscribe();
    this._filteredProductsRequest.destroyFilteredProductsRequest();
  }

 // List Categories Algolia Service
  public setFilterListAlgolia$(filterList:SearchLandingAlgoliaResponse){
    this.filterListAlgolia.next(filterList);
  }

  public filterListAlgolia$() {
    return this.filterListAlgolia.asObservable();
  }
}
