<section class="fc-success">
  <div class="d-flex justify-content-between align-items-center mb-4 mt-6">
    <p class="fc-success__text">{{ title }}</p>
    <fp-button-outline
      buttonClass="d-flex align-items-center"
      [buttonStyle]="{ height: '40px' }"
      (click)="onRegister()"
    >
      <fp-icon
        iconName="plus-sign-blue"
        iconSize="12"
        innerClass="mr-2"
      ></fp-icon>
      <span class="text-body-bold-3"> Registrar Cliente </span>
    </fp-button-outline>
  </div>
  <div class="fc-success-container-table">
    <table class="fc-table">
      <thead>
        <tr class="fc-table__header">
          <th></th>
          <th>Nombre</th>
          <th>Teléfono</th>
          <th>Documento</th>
          <th class="pr-4">T y C</th>
        </tr>
      </thead>
      <tbody>
        <tr class="fc-table__row" *ngFor="let item of data">
          <td>
            <input
              type="radio"
              [name]="name"
              [value]="item.idClient"
              (change)="onChangeSelected(item)"
            />
          </td>
          <td>{{ item.name }} {{ item.lastName }} {{ item.lastName2 }}</td>
          <td>
            {{ item.phoneList?.length > 0 ? item.phoneList[0] : "" }}
          </td>
          <td>{{ item.identityDocument }} {{ item.identityDocumentNumber }}</td>
          <td class="text-center pr-4">
            <svg width="12" height="12">
              <circle cx="6" cy="6" r="6" [attr.fill]="item.consentsColor" />
            </svg>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
