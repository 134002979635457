import { Injectable } from '@angular/core';
import { GenericService } from '../generic/generic.service';
import { ENDPOINTS } from '../../parameters/endpoints';
import { IDeparment } from '../../models/menu-categories/deparment.interface';
import { map } from 'rxjs/operators';
import { Department } from '../../models/menu-categories/department.model';
import { Observable } from 'rxjs';

@Injectable()
export class MenuCategoriesClientService {

  constructor(
    private genericService: GenericService
  ) { }

  public getAllMenuCategories(): Observable<Department[]> {
    const { GET_MENU_CATEGORIES } = ENDPOINTS;
    return this.genericService.genericGet<IDeparment[]>(GET_MENU_CATEGORIES)
      .pipe(
        map((response: IDeparment[]) => {
          const departments = response?.map(department => new Department(department));
          return departments || [];
        })
      );
  }
}
