<div id="checkout-register-modal"
  class="main-login-modal modal-login-container">
  <fp-section-container-title-login
    [title]="isInkaBrand ? 'Crea una cuenta' : 'Regístrate'"
    [iconName]="'close'"
    (onclick)="closeModal()"
  >
  </fp-section-container-title-login>
  <fp-sub-section-container>
    <div class="content">
      <div class="mb-3" style="
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 80px;
        border-radius: 16px;
        border: 2px solid #DBF4FF;"
      >
        <div style="
          display: flex;
          align-items: center;
          padding: 1.2rem;
          background-color: #DBF4FF;
          height: 80px;
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
          border-top-right-radius: 2.5rem;
          border-bottom-right-radius: 2.5rem;"
        >
          <fp-icon iconSize="32" [iconName]="data.iconName"></fp-icon>
        </div>
        <div style="align-items: center; padding: 1.6rem;">
          <span style="font-size: 13px; line-height: 20px;">
            Regístrate para acceder a tu descuento
            de <span style="color: #007DBA; font-weight: 600;">S/ {{data.discount}}</span>
            por pagar con tu Tarjeta Oh!
          </span>
        </div>
      </div>

      <div class="mt-4" [formGroup]="newPasswordForm.form">
        <div class="input-email">
          <fp-input-control [inputTitle]="'Correo electrónico *'"
            [errorMessage]="newPasswordForm.emailAddressControl.emailAddressError" [marginWithError]="false">
            <input type="text" *ngIf="!checkoutEmailAddress" class="input" maxlength="50" formControlName="emailAddress">
            <input type="text" *ngIf="checkoutEmailAddress" class="input btn-disabled" maxlength="50" formControlName="emailAddress" readonly>
          </fp-input-control>
        </div>
        <div class="password-new mt-4">
          <fp-input-password [inputTitle]="'Contraseña *'"
              [isVerify]="isSame">
            <input class="w-100 input" #typeControl placeholder="Ingresa tu contraseña" formControlName="newPassword">
          </fp-input-password>
        </div>
        <div class="checkList mt-3">
          <fp-checkout-check-list-password
            [verifyCharacter]="isValid8Digit"
            [verifyCapital]="isValidCapitalLetter"
            [verifyLower]="isValidLowerCase"
            [verifyNumber]="isValidNumber"
          >
          </fp-checkout-check-list-password>
        </div>
        <div class="password-confirm mt-4">
          <fp-input-password [inputTitle]="'Confirmar contraseña *'"
            [isVerify]="isSame">
            <input class="w-100 input" #typeControl placeholder="confirma tu contraseña" formControlName="confirmPassword">
          </fp-input-password>
        </div>
        <div class="mt-4 mb-3">
          <fp-button-primary (click)="openEmailVerification()" buttonDisplay="block"
            [buttonDisabled]="!isAllVerified">
            {{ isInkaBrand ? 'Crear cuenta' : 'Siguiente'}}
          </fp-button-primary>
        </div>
        <div class="mt-4 mb-3">
          <div style="display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;"
            >
            <div>
              <span class="checkout--register-anonymous-text">¿Ya tienes cuenta?</span>
            </div>
            <div>
              <span class="checkout--register-anonymous-link" (click)="openLoginModal()">Iniciar sesión</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fp-sub-section-container>
</div>
