import { Component, Input, OnInit, OnChanges, OnDestroy } from "@angular/core";
import { ProductDetail } from "src/app/business/product-detail/models/product-detail-model";
import { AnalyticServiceService } from "src/app/shared/service/analitycService/analitycService.service";
import { CoachmarkService } from "src/app/shared/service/coachmark/coachmark.service";
import { LIST_SERVICES_TYPES_DELIVERY } from "@parameters/global";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { SelectDataStoreService } from "src/app/business/office/store/select-data-store.service";
import { ShowModalBoticaService } from "src/app/shared/service/showModalServices/showModalService.service";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { environment } from "@environments/environment";
import { EBrand } from "@parameters/access/brand.parameter";
import { EProductStatus } from "../../../models/product-status.enum";
import { ProductDetailImplementService } from "src/app/business/product-detail/services/product-detail-implement.service";
import { take } from "rxjs/operators";
import { productRADHaveStock, productRETHaveStock } from "../../../service/product-status.helper";
import { Subscription } from "rxjs";
import { ShoppingListStoreService } from "@stores/shopping-list/shopping-list-store.service";
import { MatDialog } from "@angular/material/dialog";
import { ProductSellerInShoppingcartDialogComponent } from "src/app/shared/components/product-seller-in-shoppingcart-dialog/product-seller-in-shoppingcart-dialog.component";
import { AddressListStoreService } from "src/app/core/modules/address-manager/address-stores/address-list-store.service";
import { CoverageLocationStoreService } from "@stores/coverage-location/coverage-location-store.service";
import { CoverageLocationResponse } from "@models/coverage-location/coverage-location.model";
import { TaggingService } from 'src/app/atm-services/tagging.service';

@Component({
  selector: "fp-product-available-botica",
  templateUrl: "./product-available-botica.component.html",
  styleUrls: ["./product-available-botica.component.sass"]
})
export class ProductAvailableBoticaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() availableProduct: number;
  @Input() product: ProductDetail;
  @Input() style: boolean;
  @Input() availableRet: boolean;
  @Input() availableRad: boolean;
  @Input() currentPresentation: number;

	isRAD = this.retValidateCartService.isRADProcess;
  eProductStatus = EProductStatus;
  isMifaBrand: boolean = EBrand[environment.brand] == EBrand.mifarma;
  isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
  isDelivery = true;
  isRet = true;
  public coverage: CoverageLocationResponse;
  private coverageSubscription: Subscription;

  private subscriptions: Subscription[] = [];

  get isSeller(): boolean {
    return this.product?.isFromSeller
  }

  get checkMarkActive(): boolean {
    return this.isSeller ? false : this.coachMarkService.activeDrugstoreModal.getValue();
  }

  get isUnavailable() {
    return this.product.statusId === this.eProductStatus.unavailable
  }

  constructor(
    private serviceAnalytic: AnalyticServiceService,
    private showModalBoticaService: ShowModalBoticaService,
    private selectDataStoreService: SelectDataStoreService,
    private coachMarkService: CoachmarkService,
    private retValidateCartService: RetValidateCartService,
    private productDetailImplement: ProductDetailImplementService,
    private _shoppingListStore: ShoppingListStoreService,
    private _addressListStore: AddressListStoreService,
    private dialog: MatDialog,
    private coverageLocationStore: CoverageLocationStoreService,
  ) {
    this.coverageSubscription = this.coverageLocationStore.coverageLocationResponse$.subscribe(res => {
      this.coverage = res;
    });
  }

  ngOnInit() { }

  ngOnChanges(): void {
    if (this.product?.id) {
      const { stock, stockRet } = this.product
      this.isDelivery = stock > 0;
      this.isRet = stockRet > 0;
    }

    if (this.isOrderTaker) {
      this.validDelivery();
    }

    if (this.isSeller) {
      this.availableRet = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
    this.coverageSubscription.unsubscribe();
  }

  validDelivery() {
    const { services = [] } = this.selectDataStoreService.nearbyStore || {};

    this.isDelivery = services.some(({ code, enabled }) => {
      const typeDelivery = LIST_SERVICES_TYPES_DELIVERY.includes(code);
      return typeDelivery && enabled;
    });
  }

  get cartHaveSeller() {
    return this._shoppingListStore.getLocalStorageShoppingCartList()?.filter((product: ProductDetail) => product.sellerId);
  }

  showModalBotica(product) {
    TaggingService.RetChangeTypeDeliveryValidate(this.retValidateCartService.isRADProcess)
    this.serviceAnalytic.registerEventAnalytics(this.serviceAnalytic.detectModuleTag("ver_botica"), "click :: Ver botica", product.id);
    product.presentationIdSelected = this.currentPresentation;
    if (this.retValidateCartService.isRADProcess && this.cartHaveSeller.length) {
      this.dialog.open(ProductSellerInShoppingcartDialogComponent,
        {
          width: '450px',
          data: {
            address: this._addressListStore.currentSelectedAddress,
            product: this.product
          },
          disableClose: true
        });
      return
    }
    //
    this.showModalBoticaService.showModalDesktopService(product,null,null,false);
    this.showModalBoticaService.fromProduct.next(true)
    this.retValidateCartService.intentIsFromProduct = this.product.id
  }

  getProductDetail() {
    this.productDetailImplement
      .getProductDetail(this.product.id)
      .pipe(take(1))
      .subscribe(([product, productCard]) => {
        this.product = product;
        //
        this.availableRad = !!productRADHaveStock(this.product, true, this.coverage);
        this.availableRet = !!productRETHaveStock(this.product, false)
      });
  }
}
