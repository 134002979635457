<div class="address-list-modal-v2" id="core-address-list-modal">
  <div class="button-modal-close" (click)="closeModal()">
    <span class="icon icon-24-close"></span>
  </div>

  <fp-section-container-title-v2
    [iconSize]="32"
    [svgName]="isMifaBrand ? 'navigation-orange' : 'navigation-green'"
    [title]="'Mis direcciones'"
  >
  </fp-section-container-title-v2>
  <fp-sub-section-container-v2>
    <!-- <div class="content"> -->
      <div class="d-flex align-items-center justify-content-center p-4 p-md-5">
        <fp-icon iconSize="16" [iconName]="isMifaBrand
            ? 'plus-purple'
            : 'plus-blue'
          "
        ></fp-icon>
        <fp-link-buttom-v2
          [id]="'lb--add-address'"
          [buttonText]="'Agrega dirección'"
          (onclick)="openNewAddressModal()"
        ></fp-link-buttom-v2>
      </div>
      <form [formGroup]="addressItemsForm.form" class="form-group">
        <div class="scroll-address-radio-button">
          <fp-address-radio-button-v2
            *ngFor="let aItem of addressItemList; let indexOfelement = index"
            [showMatMenu]="true"
            [addressTitle]="aItem.name"
            [addressText]="aItem.street"
            [addressId]="aItem.id"
            [selectedRadio]="aItem.id === selectedAddress.id"
            (deleteEvent)="deleteAddressItem(aItem.id)"
            (editEvent)="editAddressItem(aItem.id)"
            (selectRadioOption)="selectRadioAddress(aItem.id)"
            (click)="tagRadio(aItem.name)"
          >
            <fp-radio-control
              formControlName="addressItem"
              [radioOptionValue]="aItem.id"
              [name]="'addressListName'"
            >
            </fp-radio-control>
          </fp-address-radio-button-v2>
        </div>
        <div class="content-buttons-modal my-2 my-md-4">
          <button
            type="button"
            id="btn--cancel-choose-address"
            class="button-address btn btn-secondary font-weight-bold mb-3 mb-md-0"
            (click)="closeModal()"
          >
            Cancelar
          </button>
          <button
            id="btn--choose-address"
            type="button"
            class="button-address btn ml-lg-3 btn-primary font-weight-bold cursor-pointer"
            [ngClass]="{'btn-disabled': isDisabledSubmitButton}"
            (click)="onSubmit()"
            [disabled]="isDisabledSubmitButton"
          >
            Elegir dirección
          </button>
        </div>
      </form>
    <!-- </div> -->
  </fp-sub-section-container-v2>
</div>
