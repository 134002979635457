import { Component, Input, ContentChild, ElementRef, AfterContentInit, OnChanges, Renderer2 } from "@angular/core";

const ERROR_BORDER_COLOR = "border-color: #E74848";
@Component({
	selector: "fp-input-control",
	templateUrl: "./input-control.component.html",
	styleUrls: ["./input-control.component.sass"]
})
export class InputControlComponent implements OnChanges, AfterContentInit {
	@ContentChild("inputControl") inputControl: ElementRef<HTMLInputElement>;

	@Input() inputTitle = "";
	@Input() errorMessage = "";
	@Input() marginWithError = false;
	@Input() marginBottomZero = false;
	@Input() errorMessageClass = "";
	@Input() errorMsgJoinToInput = false;

	private isInputControlReady = false;

	constructor(private renderer: Renderer2) {}

	ngOnChanges() {
		if (this.isInputControlReady) {
			this.updateErrorStatus();
		}
	}

	ngAfterContentInit(): void {
		this.isInputControlReady = true;
		this.updateErrorStatus();
	}

	private updateErrorStatus() {
		if (this.inputControl) {
			const input = this.inputControl.nativeElement;
			if (this.errorMessage) {
				this.renderer.setAttribute(input, "style", ERROR_BORDER_COLOR);
			} else {
				this.renderer.removeAttribute(input, "style");
			}
		}
	}
}
