<div class="modal-header">
	<div style="margin-top: 10px; margin-right: 13px">
		<div class="button-modal-close text-right">
			<fp-icon iconSize="25" *ngIf="bestCuppon" (click)="dialogRef.close()" iconName="close-outline"></fp-icon>
		</div>
	</div>
</div>
<div class="modal-body">
	<div class="container" *ngIf="bestCuppon">
		<br />
		<div class="container text-center">
			<fp-icon iconSize="170" iconName="shop-discount"></fp-icon>
		</div>

		<div class="checkout-title label-black-3 text-center">¡Excelente, ya tienes el mejor precio!</div>
		<div class="container checkout text-center">
			En esta compra estás ahorrando
			<span class="label-black-1"> S/. {{ amountCupon | number : "1.2-2" }} </span>
			<span *ngIf="ohMini == 1" class="label-black-1"
				>con tu tarjeta

				<div class="container-icons-card mr-2">
					<ng-container *ngFor="let image of _globalProductCardService.paymentMethodCard.imageArray; trackBy: trackByImage">
						<img class="icon-card" [src]="image" alt="icono tarjeta inkafarma" />
					</ng-container></div
			></span>
			<span *ngIf="ohMini == 0" class="label-black-1">con tus cupones</span>
		</div>
		<br />
		<div>
			<button id="goToBuyNow" class="btn btn-primary btn-block label-black" (click)="btnClose()">
				<span *ngIf="paymetohd == '1'" class="label-black-1">Continuar al detalle del pedido</span>
				<span *ngIf="paymetohd == '0'" class="label-black-1">Continuar con el pago</span>
			</button>
		</div>
		<br />
	</div>

	<div class="container" *ngIf="!bestCuppon">
		<br />
		<div class="text-center checkout-title label-black-3">
			¡Aplicaremos el mejor descuento sin usar tu cupón
			<span *ngIf="ohMini == '1'">
				pagando con
				<img class="icon-card mr-2" [src]="_globalProductCardService.paymentMethodCard.image" /></span
			>!
		</div>
		<div class="container text-center">
			<fp-icon iconSize="170" iconName="icon-bestofferts"></fp-icon>
		</div>

		<div class="container text-center checkout">Así podrás utilizarlo en tu</div>
		<div class="container text-center checkout">siguiente compra</div>
		<br />

		<div>
			<button id="goToBuyNow" class="btn btn-primary btn-block label-black" (click)="btnClose()">Comprar con el mejor precio</button>
		</div>
		<br />
	</div>
</div>
