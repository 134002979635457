import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { EProductStatus } from 'src/app/core/modules/product-card/models/product-status.enum';
import { EProductPresentationStatus, ProductPresentation } from '../../../models/product-presentation.model';

@Component({
  selector: 'fp-product-presentation-list',
  templateUrl: './product-presentation-list.component.html',
  styleUrls: ['./product-presentation-list.component.sass']
})
export class ProductPresentationListComponent implements OnInit {
  public eProductStatus = EProductStatus;
  public eProductPresentationStatus = EProductPresentationStatus;
  public availablePresentation: ProductPresentation;

   @Input() template: 'select' | 'cards' | 'list' = 'select';
   @Input() presentationList: Array<ProductPresentation> = [] as Array<ProductPresentation>;
   @Input() presentationSelected: number;
   @Input() productStatus: number = this.eProductStatus.available;
   @Output() updatePresentation = new EventEmitter();


   constructor() {
   }

  ngOnInit() {
    this.setAvailablePresentation();
  }

  setAvailablePresentation() {
    if (this.productStatus === this.eProductStatus.unavailablePresentation) {
      this.availablePresentation = this.presentationList
        .find(presentation => presentation.fractionated);
    }
  }

  getProductPresentationDisabled(presentation) {
    return presentation.status !== this.eProductPresentationStatus.available || this.eProductStatus.unavailable === this.productStatus;
  }

  selectPresentation(presentation: ProductPresentation) {
    this.updatePresentation.emit(presentation);
  }
}
