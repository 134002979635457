import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NewPasswordFormService } from '../../register-forms/new-password-form.service';
import { EmailVerificationModalManagerService } from '../../register-services/email-verification-modal-manager.service';
import { Subscription } from 'rxjs';
import { MainRegisterStoreService } from '../../register-stores/main-register-store.service';
import { AuthImplementService } from 'src/app/shared/implements/auth/auth-implement.service';
import { NewRegisterStoreService } from 'src/app/shared/stores/user/new-register-store.service';
import { RegisterOriginStoreService } from '../../register-stores/register-origin-store.service';
import { BUSINESS_PATHS } from 'src/app/shared/parameters/business.parameter';
import { RegisterModalsManagerHelperService } from '../../register-services/register-modals-manager-helper.service';
import { AppStoreService } from 'src/app/shared/stores/app/app-store.service';
import { AnalyticsManager } from 'src/app/analytics';
import { EBrand } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';

@Component({
  selector: 'fp-new-password-modal',
  templateUrl: './new-password-modal.component.html',
  styleUrls: ['./new-password-modal.component.sass'],
})
export class NewPasswordModalComponent implements OnInit, OnDestroy {
  public isInkaBrand = EBrand[environment.brand]==EBrand.inkafarma;
  public checkList = false;
  public isValid8Digit = false;
  public isValidCapitalLetter = false;
  public isValidLowerCase = false;
  public isValidNumber = false;

  public isSame = false;
  public isChange = false;

  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<NewPasswordModalComponent>,
    public newPasswordForm: NewPasswordFormService,
    private emailVerification: EmailVerificationModalManagerService,
    private mainRegisterStore: MainRegisterStoreService,
    public authImplementService: AuthImplementService,
    private newRegisterStore: NewRegisterStoreService,
    public dialogRefAll: MatDialog,
    private registerOriginStore: RegisterOriginStoreService,
    private registerModalsManagerHelper: RegisterModalsManagerHelperService,
    private _appStoreService: AppStoreService
  ) {
    this.newPasswordForm.emailAddressControl.setValue(this.mainRegisterStore.email);
  }

  ngOnInit() {
    const valid8DigitSubscription = this.newPasswordForm.getIsValid8Digit$()
      .subscribe(isValid => {
        this.isValid8Digit = isValid;
      });

    const validCapitalLetterSubscription = this.newPasswordForm
      .getIsValidCapitalLetter$()
      .subscribe((isValid) => {
        this.isValidCapitalLetter = isValid;
      });

    const validLowerCaseSubscription = this.newPasswordForm
      .getIsValidLowerCase$()
      .subscribe((isValid) => {
        this.isValidLowerCase = isValid;
      });

    const validNumberSubscription = this.newPasswordForm
      .getIsValidNumber$()
      .subscribe((isValid) => {
        this.isValidNumber = isValid;
      });

    const sameConfirmPasswordSubscription = this.newPasswordForm
      .getIsSameConfirmPassword$()
      .subscribe((isvalid) => {
        this.isSame = isvalid;
      });

    const sameNewPasswordSubscription = this.newPasswordForm
      .getIsSameNewPassword$()
      .subscribe((isvalid) => {
        this.isSame = isvalid;
      });

    const changeSubscription = this.newPasswordForm
      .isChange$()
      .subscribe((isvalid) => {
        this.isChange = isvalid;
      });

    this.subscriptions.push(
      valid8DigitSubscription,
      validCapitalLetterSubscription,
      validLowerCaseSubscription,
      validNumberSubscription,
      sameConfirmPasswordSubscription,
      sameNewPasswordSubscription,
      changeSubscription
    );

    this.newPasswordForm.newPasswordControl.setValue('');
    this.newPasswordForm.confirmPassword.setValue('');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public get isAllVerified() {
    const verifyArray = [
      this.isValid8Digit,
      this.isValidCapitalLetter,
      this.isValidLowerCase,
      this.isValidNumber,
      this.isSame,
    ];
    return verifyArray.every(verify => verify);
  }

  showCheckList() {
    this.checkList = true;
  }

  hidewCheckList() {
    if (!this.isChange) {
      this.checkList = false;
    }
  }

  closeModal() {
    AnalyticsManager.dispatchEvent({
      eventName: 'ui_register_ingresa_una_contrasenia',
      eventCategory: 'UI :: Register',
      eventAction: 'Ingresa una contraseña',
      eventLabel: 'Link :: Volver a las opciones de registro'
    });
    this.registerModalsManagerHelper.closeNewPasswordModal();

    const methodOpenModal = 'openRegisterModal';
    this.registerModalsManagerHelper[methodOpenModal]();
  }

  openEmailVerification() {
    const email = this.newPasswordForm.emailAddressControl.value;
    const password = this.newPasswordForm.confirmPassword.value;
    this.newRegisterStore.email = email;
    this.newRegisterStore.password = password;
    this.mainRegisterStore.email =
      this.newPasswordForm.emailAddressControl.value;
    this.registerOriginStore.emailVerificationOrigin = BUSINESS_PATHS.HOME;
    this.registerModalsManagerHelper.openEmailVerificationModal();
    this.registerModalsManagerHelper.closeNewPasswordModal();
    AnalyticsManager.dispatchEvent({
      eventName: 'ui_register_ingresa_una_contrasenia',
      eventCategory: 'UI :: Register',
      eventAction: 'Ingresa una contraseña',
      eventLabel: 'Button :: Continuar'
    });
  }
}
