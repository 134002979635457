import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
  CoverageLocationResponse,
  ICoverageLocationResponse,
  IPosition,
} from "../../models/coverage-location/coverage-location.model";
import { ENDPOINTS } from "../../parameters/endpoints";
import { GenericService } from "../generic";

@Injectable()
export class CoverageLocationClientService {
  constructor(private genericService: GenericService) {}

  public getValidatedCoverage$(
    position: IPosition
  ): Observable<CoverageLocationResponse> {
    const params = new HttpParams()
      .set("latitude", String(position?.latitude))
      .set("longitude", String(position?.longitude));
    return this.genericService
      .genericGet<ICoverageLocationResponse>(
        ENDPOINTS.COVERAGE_LOCATION,
        params
      )
      .pipe(
        map((response) => {
          const coverageLocationResponse = new CoverageLocationResponse(
            response
          );
          return coverageLocationResponse;
        })
      );
  }
}
