import { FormControl, Validators } from '@angular/forms';
import { getErrorbyFormControl } from 'src/app/shared/helpers/error-controls-helpers';
import { CASH_PAYMENT_ERROR_MESSAGE } from 'src/app/shared/parameters/error-message-forms';
import { NUMBERS, DOT } from '../parameters/keyboard.constant';

export class CashPaymentControl extends FormControl {

  constructor() {
    super('');
    this.settingCashPaymentValidators();
  }

  private settingCashPaymentValidators() {
    this.setValidators([
        Validators.required,
        this.amountValidator(),
    ]);
  }

  public get cashPaymentError(): string {
    return getErrorbyFormControl(this, CASH_PAYMENT_ERROR_MESSAGE);
  }

  public get canValidate() {
    return this.touched || this.dirty;
  }

  public amountValidator() {
    return () => {
      const { value } = this;
      let amountError = { amount: true };
      if (value) {
        const validCharacteres = [ ...NUMBERS, ...DOT];
        const isValidAmount = String(value).split('').every(char => validCharacteres.includes(char));
        amountError = isValidAmount ? null : { amount: true };
      }
      return amountError;
    };
  }
}
