<fp-static-landing-expansion-list-skeleton
  *ngIf="loader">
</fp-static-landing-expansion-list-skeleton>

<div class="card px-0 px-md-4 pb-5 pb-md-0"
     *ngIf="!loader">

  <mat-accordion displayMode="flat">
    <ng-container *ngFor="let questions of expansionList; let isFirst = first">

      <mat-expansion-panel
        class="shadow-none bg-transparent mat-expansion-static-landing"
        [expanded]="isFirst"
        (opened)="expansionOpenedEvent(questions?.items)">
        <mat-expansion-panel-header
          class="bg-transparent border-top border-light-gray pr-3 pl-3 h-auto">

          <mat-panel-title>
            <div *ngIf='questions?.date' class="date text-tag mr-3">{{questions.day}} {{questions.month}} {{questions.year}}</div>
            <span class="label-black--1 title-header">{{questions.title}}</span>
          </mat-panel-title>

        </mat-expansion-panel-header>

        <div class="expansion-panel-content">

          <div
            *ngIf="questions?.content"
            class="content pt-3 pb-4">
            <p class="paragraph--1 m-0 "
               [innerHTML]="questions?.content | mdToHtml"></p>
          </div>

          <div *ngIf="questions?.urlOnline"  class="row pb-4 ">
            <div class="col-12 col-md-6 catalogue-image" >
              <img class="my-4" src="{{questions?.image}}" alt="">
            </div>
            <div class="col-12 col-md-6 catalogue-content">
              <div>
                <a target="_blank" class="online_button btn btn-primary mb-3" href="{{questions?.urlOnline}}">Ver en linea</a>
                <a class="download_button btn"
                  *ngIf="questions?.urlDownload"
                  href="{{questions?.urlDownload}}">
                  Descargar PDF
                </a>
              </div>
            </div>
          </div>

          <ng-container
            *ngIf="questions?.items">
            <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
          </ng-container>

        </div>

      </mat-expansion-panel>

    </ng-container>
  </mat-accordion>
</div>
