<div class="radio-control-container">
  <label class="radio-control">
    <input type="radio"
      [id]="id"
      class="input-radio"
      [checked]="radioOptionValue === value"
      [value]="radioOptionValue"
      [disabled]="isDisabled"
      (click)="selectCheck($event)"
      [name]="name"
      >
    <span class="check-mark"></span>
  </label>
</div>
