<div class="map-for-ret"
[ngClass]="{'map-for-ret--responsive': isResponsive}">
  <div class="static-map" *ngIf="hideMapForRET">
    <img src="../../../../../../assets/png-images/gray-static-map.png"
      alt="gray-static-map"
      class="static-map-image">
  </div>
  <ng-container *ngIf="!hideMapForRET">
    <div class="map-for-ret"
      [ngClass]="{
        'map-for-ret--responsive': isResponsive,
        'mb-4': !isResponsive
      }"
      #hereMap
    ></div>
  </ng-container>
</div>
