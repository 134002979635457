import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SERVICES_TYPES_ENUM } from "src/app/shared/parameters/global";
import { IDrugstore, Service } from "../../models/drugstore.interface";

@Component({
  selector: "fp-radio-drugstore",
  templateUrl: "./radio-drugstore.component.html",
  styleUrls: ["./radio-drugstore.component.sass"],
})
export class RadioDrugstoreComponent implements OnInit {
  @Input()
  data: IDrugstore = null;
  @Output()
  selectedDrugstore = new EventEmitter<number>();

  serviceRET: Service = null;
  isRET = false;

  constructor() {}

  ngOnInit() {
    this.serviceRET = this.data?.services.find(
      (service) => service.shortName === SERVICES_TYPES_ENUM.RETIRO
    );
  }
}
