import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, Input, NgZone, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { StoresForPickUpStoreService } from '../../pick-up-stores/stores-for-pick-up-store.service';
import { AgmManagerService } from '../../pick-up-services-for-agm/services/agm-manager.service';
import { Subscription, combineLatest, Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { NearestDrugstore } from 'src/app/shared/models/nearest-drugstores/nearest-drugstores.model';
import { HereMapPlacesService } from '../../../here-maps/services/here-map-places.service';
import { HereMapService } from '../../../here-maps/services/here-map.service';
import { debounceTime, defaultIfEmpty, startWith } from "rxjs/operators";
import { TaggingService } from 'src/app/atm-services/tagging.service';

@Component({
  selector: "fp-map-for-ret",
  templateUrl: "./map-for-ret.component.html",
  styleUrls: ["./map-for-ret.component.sass"]
})
export class MapForRetComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit {

  @ViewChild('googleMapForRET', { static: false }) googleMapForRET: ElementRef<HTMLDivElement>;
  @ViewChild('hereMap', { static: false }) hereMap: ElementRef<HTMLDivElement>;

  @Input() isDisabledMapForRET = false;
  @Input() isResponsive = false;

  @Input() hideMapForRET = true;

  private subscriptions: Subscription[] = [];
  private drugstores: NearestDrugstore[];

  // references
  private drugstores$ = this.storesForPickUpStore.drugstores$.pipe(filter((stores) => stores.length > 0)).pipe(defaultIfEmpty([]));
  private selectedTemporalDrugstore$ = this.storesForPickUpStore.selectedTemporalDrugstore$
    .pipe(filter((store) => store && store.id && store.id > 0))
    .pipe(defaultIfEmpty({} as NearestDrugstore));

  isFirstLoad = false;

  constructor(
    private storesForPickUpStore: StoresForPickUpStoreService,
    private agmManager: AgmManagerService,
    private ngZone: NgZone,
    private changeDetectionRef: ChangeDetectorRef,
    private hereMapPlacesService: HereMapPlacesService
  ) {}

  ngOnInit() {
    if (!this.hideMapForRET) {
      this.subscriptions.push(
        combineLatest([this.drugstores$, this.selectedTemporalDrugstore$])
        .pipe(filter(() => this.isFirstLoad))
        .pipe(debounceTime(200))
        .subscribe(([drugstores, selectedDrugstore]) => {
          this.ngZone.run(() => {
            const isUpdatedDrugstores = this.isTheSameDrugstoreList(this.drugstores, drugstores);
            if (!isUpdatedDrugstores) {
              this.loadMarkers(drugstores);
            }
            this.hereMapPlacesService.selectDefaultMarket(selectedDrugstore);
          });
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngAfterContentInit() {
  if (!this.hideMapForRET) {
    combineLatest([this.drugstores$, this.selectedTemporalDrugstore$])
    .pipe(take(1))
    .subscribe(([drugstores, selectedDrugstore]) => {
      if (!this.isFirstLoad) {
        setTimeout(() => {
          const lat = selectedDrugstore.latitude;
          const lng = selectedDrugstore.longitude;

          this.hereMapPlacesService.showMap(this.hereMap, {lat,lng});
          this.storesForPickUpStore.setDrugstores(drugstores);
          this.drugstores = drugstores;
          this.loadMarkers(drugstores);
          this.hereMapPlacesService.selectDefaultMarket(selectedDrugstore);

          this.isFirstLoad = true;

          this.changeDetectionRef.detectChanges();
        }, 1500);
      }
    });
  }
  }

  ngAfterViewInit() {
    this.drugstores$.subscribe(() => {
      console.log('drugstore');
    });

    this.selectedTemporalDrugstore$.subscribe(() => {
      this.tagDrugstoreSelectRadio();
    });
  }

  private loadMarkers(stores: any) {
    this.hereMapPlacesService.removeMarker();
    this.hideMapForRET = false;
    stores.forEach((store) => {
      const lat = store.latitude;
      const lng = store.longitude;
      const position = { lat, lng };
      this.hereMapPlacesService.createMarketHere(position, store);
    });
  }

  private isTheSameDrugstoreList(list1: NearestDrugstore[], list2: NearestDrugstore[]) {
    if (list1.length !== list2.length) {
      return false;
    }
    const list1Ids = list1.map((d) => d.id);
    const list2Ids = list2.map((d) => d.id);
    const orderedList1Ids = list1Ids.sort((a, b) => (a as number) - (b as number));
    const orderedList2Ids = list2Ids.sort((a, b) => (a as number) - (b as number));
    return JSON.stringify(orderedList1Ids) === JSON.stringify(orderedList2Ids);
  }

  tagDrugstoreSelectRadio(){
    TaggingService.tagEventAllRet("ui_drugstore_select", "UI :: Drugstore", "Select", "Seleccionar");
  }
}
