import { Component, Input } from '@angular/core';

@Component({
  selector: 'fp-button-secondary',
  templateUrl: './button-secondary.component.html',
  styleUrls: ["./button-secondary.component.sass"]
})
export class ButtonSecondaryComponent {

  @Input()
  buttonId: string = "";

  @Input()
  buttonClass: string;

  @Input()
  buttonDisplay: 'block' | 'flex' | 'inline-block' = 'inline-block';

  @Input()
  buttonType: 'button' | 'submit' | 'reset' = 'button';

  @Input()
  buttonStyle: { [klass: string]: any; } | null;

  @Input()
  buttonDisabled = false;

}
