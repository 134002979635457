export * from './user/user';
export * from './image';
export * from './product';
export * from './swiper-builder';
export * from './utils';
export * from './checkout';

import { User } from './user/user';
import { ImageBasic, ImageClass } from './image';
import { SwiperBuilder } from './swiper-builder';
import { Breadcrumbs, Filter, OrderBy } from './utils';
import { PaymentCard, Schedule, ScheduleInit } from './checkout';

export const MODELS = [
  User,
  ImageClass,
  ImageBasic,
  SwiperBuilder,
  OrderBy,
  Filter,
  Breadcrumbs,
  ScheduleInit,
  PaymentCard,
  Schedule,
];
