import { STOCK_PATH } from './app-routes.parameter';

export const BUSINESS_KEYS = {
  SEARCH_QUERY_PARAM_KEY: 'keyword',
  DEPARTMENT_PATH_PARAM: 'department',
  CATEGORY_PATH_PARAM: 'category',
  SUBCATEGORY_PATH_PARAM: 'subcategory',
};

export const WORLD_KEYS = {
  WORLD_KEYWORD: 'worldKeyword',
  LIST_KEYWORD: 'lista',
  BLOG_KEYWORD: 'blog',
  BLOG_TAG_ALL: 'todos',
  AFFILIATION: 'afiliacion',
  BENEFITS: 'beneficios',
  AFFILIATION_FORM: 'world-affiliation-form',
  AFFILIATION_MOM_AND_BABY_PREGNANCY_FORM: 'world-mom-and-baby-pregnancy-form',
  AFFILIATION_MOM_AND_BABY_ADDITIONAL_CHILDREN_FORM: 'world-mom-and-baby-additional-children-form',
  AFFILIATION_MOM_AND_BABY_CATEGORIES_FORM: 'world-mom-and-baby-categories-form',
  LIST_PRODUCT_KEYWORD: 'productListKeyword',
  BLOG_TAG_KEYWORD: 'blogTagKeyword',
  BLOG_NAME: 'blogTagName',
  BLOG_DETAIL_KEYWORD: 'blogDetailKeyword',
};

export const BUSINESS_PATHS = {
  LOGIN:'login',
  HOME: '',
  CATEGORY: 'categoria',
  SEARCH: 'buscador',
  CHECKOUT: 'checkout',
  CART: 'carrito',
  CUSTOMER_SERVICE: 'atencion-clientes',
  LIST: 'lista',
  REGISTER: 'nuevo-registro',
  PRE_REGISTER: 'pre-nuevo-registro',
  SAVINGS: 'monedero',
  PRODUCT: 'producto',
  LEGALS: 'legales',
  PRIVACY_POLICY: 'politicas-de-privacidad',
  PERSONAL_DATA_PROCESING: 'tratamiento-de-datos',
  COOKIES_POLICY: 'politica-de-cookies',
  TERMS_OF_SERVICE: 'terminos-y-condiciones',
  COIN_PURSE: 'programa-modenero-del-ahorro',
  INKACLUB: 'programa-inkaclub',
  COVERAGE_ZONES: 'zonas-cobertura',
  NEW_PASSWORD: 'nueva-contrasenia',
  COVERAGE_AREAS: 'zonas-cobertura',
  SUGGESTIONS_AND_ENQUIRIES: 'consultas-sugerencias',
  ORDERS: 'pedidos',
  WORLD: 'mundo',
  DEEPLINK: 'deeplinks',
  TRACKING: 'sigue-tu-pedido',
  NOT_FOUND: '404',
  NEED_HELP: 'atencion-clientes',
  ELECTRONIC_VOUCHER: 'comprobantes-electronicos',
  TERMS_AND_CONDITIONS_SPECIAL: 'terminos-y-condiciones-especiales',
  BLOG: 'blog',
  CATALOGUE: 'catalogo',
  STRATEGIC_PARTNER: 'empresas-intercorp-y-socios-estrategicos',
  TREATMENT_MANAGERS: 'terceros-encargados-de-tratamiento',
  US: 'nosotros',
  TREATMENT_FOR_ADDITIONAL_PURPOSES: 'tratamiento-para-finalidades-adicionales',
  ONE_DOC_THREE: 'servicios',
  SERVICES: 'servicios',
  TERMS_AND_CONDITIONS_OF_ONEDOCTHREE: 'servicios/terminos-y-condiciones-teleayuda',
  CONSULTA_DE_STOCK: STOCK_PATH,
  DATA_USAGE_OF_ONEDOCTHREE: 'servicios/uso-de-datos-teleayuda',
  PRIVACY_OF_ONEDOCTHREE: 'servicios/politica-de-privacidad-teleayuda',
  INFORMATIVE: 'informativo',
  EMBED_CATALOGS: 'encartes',
  CYBERWOW: 'minisitio-icr',
  CYBERWOW_REGISTER: 'cyber-wow',
  EVENTOS_MIFARMA: 'formulario-eventos-mifarma',
  FORMULARIO_EVENTOS_INKAFARMA: 'formulario-eventos-inkafarma',
  CYBERDAYS: 'cyber-inkafarma',
  CAMPANIA: 'farmacia-vecina',
  DYNAMIC_LANDING: 'landing/:slug',
  TARJETA_OH: 'tarjeta-oh',
  CHAT_TIENDA: 'chat/tnda',
  CHAT_PRODS: 'chat/prds',
  CHAT_TIENDAS_OMNI: 'chat/tndas',
  CHAT_PRODS_OMNI: 'chat/prods',
  AGORA_PAY: 'agora-pay',
  AGORA_CLUB: 'agora-club',
  AGORA: 'agora',
  CYBERDAYSMIFA: 'cyber-mifarma',
  ALIVIAMED: "aliviamed",
  PROVIDER_LANDING: 'tienda',
  MYACCOUNT: 'micuenta',
  MYACCOUNT_PROFILE: 'micuenta/miperfil',
  MYACCOUNT_ADDRESSES: 'micuenta/direcciones',
  MYACCOUNT_ORDERS: 'micuenta/pedidos',
  HAIRCODE: 'haircode'
};

export const MONEDERO_PATHS = {
  CUPONES: BUSINESS_PATHS.SAVINGS + '/cupones',
  CUPON_DETALLE: BUSINESS_PATHS.SAVINGS + '/cupon',
  CATEGORY: BUSINESS_PATHS.SAVINGS + '/categoria',
  CATEGORY_COUPONS: BUSINESS_PATHS.SAVINGS + '/categoria/cupones',
}
