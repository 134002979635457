<div class="content-verification-modal px-4 py-4">
  <div class="button-modal-close">
    <span
      class="icon icon-24-close icon-close"
      (click)="closeModal()">
    </span>
  </div>

  <div class="content-massage">
      <div class="mx-2 my-3 icon-alert">
          <span class="icon icon-48-user-colored"></span>
      </div>
    <div class="warning-message paragraph-2 mx-2 mt-3 mb-2 font-VAGRoundedNexxt-size-14">
      El número de celular ingresado, coincide con un correo que ya tenemos registrado:
    </div>
    <div class="label-black-2 mx-2 mt-4 mt-md-0 font-VAGRoundedNexxt-size-14" >
      "{{emailOnConflict}}"
    </div>
    <div class="paragraph-2 mt-4 mx-2 font-VAGRoundedNexxt-size-14">
      ¿Deseas actualizarlo a <br>
      <span class="label-black label-black-md-2">{{currentEmail}}</span>?
    </div>
    <div class="mt-4 mx-2 mb-2">
      <div class="row">
        <div class="col-5">
          <fp-button-secondary
          (click) = "closeModal()"
            buttonDisplay="block">
            Volver
          </fp-button-secondary>
        </div>
        <div class="col-7">
          <fp-button-primary
          (click) = "openUpdateEmailModal()"
          buttonDisplay="block">
            Actualizar
          </fp-button-primary>
        </div>
      </div>
    </div>
  </div>
</div>

