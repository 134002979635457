import { IBrand } from './brand.interface';

export class Brand {

  public brandId: number;
  public brandName: string;
  public brandImage: string;

  constructor(brand: IBrand) {
    this.brandId = brand.id || -1;
    this.brandName = brand.name || '';
    this.brandImage = brand.url || '';
  }
}
