import { Injectable } from '@angular/core';
import { AnalyticsEventCategory, AnalyticsEventName, AnalyticsManager, AnalyticsModel } from 'src/app/analytics';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AnalyticServiceService {

  constructor(
    private router: Router,
  ) {
  }

  detectUrl() {
    let detectedUrl = '';
    if (this.router.url === '/carrito') {
      detectedUrl = AnalyticsEventCategory.CART;
    } else if (this.router.url.startsWith('/buscador')) {
      detectedUrl = AnalyticsEventCategory.SEARCH;
    } else if (this.router.url.startsWith('/producto')) {
      detectedUrl = AnalyticsEventCategory.PRODUCT_DETAIL;
    } else if (this.router.url.startsWith('/categoria')) {
      detectedUrl = AnalyticsEventCategory.CATEGORY;
    } else if (this.router.url === '/') {
      detectedUrl = AnalyticsEventCategory.HOME;
    } else if (this.router.url.startsWith('/lista')) {
      detectedUrl = AnalyticsEventCategory.LISTA;
    }
    return detectedUrl;
  }

  registerEventAnalytics(eventName: string, eventAction: string, eventLabel: string, eventCategory?: string) {
    const analytics: AnalyticsModel = {
      pageName: '',
      prefixEventName: this.detectUrl(),
      prefixEventCategory: this.detectUrl(),
      eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventName: eventName
    };
    AnalyticsManager.dispatchTriggerTag(analytics, false);
  }

  detectModuleTag(value) {
    let detectedUrl = '';
    if (this.router.url === '/carrito') {
      detectedUrl = 'ui_cart_' + value;
    } else if (this.router.url.startsWith('/buscador')) {
      detectedUrl = 'search_' + value;
    } else if (this.router.url.startsWith('/producto')) {
      detectedUrl = 'ui_product_detail_' + value;
    } else if (this.router.url.startsWith('/categoria')) {
      detectedUrl = 'ui_category_' + value;
    } else if (this.router.url === '/') {
      detectedUrl = 'ui_home_' + value;
    } else if (this.router.url.startsWith('/lista')) {
      detectedUrl = 'ui_lista_' + value;
    }
    return detectedUrl;
  }

}
