import {
  Component, OnInit, Input, OnDestroy, ElementRef,
  ViewChildren, QueryList, AfterViewInit, OnChanges, NgZone, ChangeDetectorRef
} from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IStoreToChoose } from '../../interfaces/choose-a-store.interface';

import { StoresForPickUpStoreService } from '../../pick-up-stores/stores-for-pick-up-store.service';
import { StoreToSelectFormService } from '../../pick-up-forms/store-to-select-form.service';
import { NearestDrugstore } from 'src/app/shared/models/nearest-drugstores/nearest-drugstores.model';
import { IModalStatus } from '../../enums/modal-status.enum';
import { Router } from '@angular/router';
import { GeolocationService } from 'src/app/shared/geolocation/geolocation.service';
import { AppStoreService } from '@stores/app/app-store.service';
import { environment } from 'src/environments/environment';
import { EBrand } from '@parameters/access/brand.parameter';
import { MatDialogRef } from '@angular/material/dialog';
import { NearbyBoticaResponsiveModalComponent } from '../../pick-up-modals/nearby-botica-responsive-modal/nearby-botica-responsive-modal.component';
import { TaggingService } from 'src/app/atm-services/tagging.service';

@Component({
  selector: 'fp-drugstore-list-v2',
  templateUrl: './drugstore-list-v2.component.html',
  styleUrls: ['./drugstore-list-v2.component.sass']
})
export class DrugstoreListV2Component implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  isInkaBrand = environment.brand == EBrand.inkafarma;
  @ViewChildren('drugstoreItem') drugstores: QueryList<ElementRef<HTMLDivElement>>;

  @Input() isDisableChooseStore = false;
  @Input() withoutButton = false;
  @Input() isResponsive = false;
  @Input() customClass :boolean=false;
  
  @Input() set status(value: IModalStatus) {
    this.changeDetectionRef.detectChanges();
    this.modalStatus = value;
    if(this.modalStatus === this.ALL_MODAL_STATUS.OK){
      this.tagDrugstorePageView();
    }
  }

  public modalStatus = IModalStatus.DEFAULT;
  public ALL_MODAL_STATUS = IModalStatus;
  public form = this.storeToSelectForm.form;
  public selectedStoreControl = this.storeToSelectForm.selectedDrugstoreControl;
  public stores: IStoreToChoose[] = [];
  public checkout: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    private storesForPickUpStore: StoresForPickUpStoreService,
    private storeToSelectForm: StoreToSelectFormService,
    private appStoreService: AppStoreService,
    private ngZone: NgZone,
    private changeDetectionRef: ChangeDetectorRef,
    private router: Router,
    private geolocationService: GeolocationService,
    private nearbyBoticaModalRef: MatDialogRef<NearbyBoticaResponsiveModalComponent>,
    private taggingService: TaggingService
  ) { }

  ngOnInit() {
    const drugstoresSub = this.storesForPickUpStore.getDrugstoresToChoose$()
      .subscribe(stores => {
        this.stores = stores;
      });
      const clickMarker = this.geolocationService.setIdDrugStore.subscribe(id=>{
        this.storesForPickUpStore.setSelectedTemporalDrugstoreById(id);
      });
    this.subscriptions.push(drugstoresSub);
    this.validateCheckout();
  }

  ngAfterViewInit() {
    this.subscribeStoresAndSelectedStore();
  }

  ngOnChanges() {
    if (this.isDisableChooseStore) {
      this.selectedStoreControl.disable();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public selectRadioOption(id: number) {
    if (id !== this.selectedStoreControl.value && !this.isDisableChooseStore) {
      this.storesForPickUpStore.setSelectedTemporalDrugstoreById(id);
    }
  }

  // for checking and scrolling to the new selected drugstore
  private subscribeStoresAndSelectedStore() {
    const selectedTemporalDrugstore$ = this.storesForPickUpStore.selectedTemporalDrugstore$
      .pipe(filter(store => store && store.id && store.id > 0));
    const drugstores$ = this.storesForPickUpStore.drugstores$
      .pipe(filter(stores => stores.length > 0));
    const combineSub = combineLatest([selectedTemporalDrugstore$, drugstores$])
      .subscribe(([selectedDrugstore, drugstores]) => {
        this.ngZone.run(() => {
          if (selectedDrugstore) { this.selectedStoreControl.setValue(selectedDrugstore.id)}
          else
            if (this.selectedStoreControl.value !== selectedDrugstore.id) {
              setTimeout(() => {
                this.selectedStoreControl.setValue(selectedDrugstore.id);
              });
            }
          this.scrollingToSelectedDrugstore(drugstores, selectedDrugstore);
        });
      });
    this.subscriptions.push(combineSub);
  }

  private scrollingToSelectedDrugstore(drugstores: NearestDrugstore[], selectedDrugstore: NearestDrugstore) {
    const index = drugstores.findIndex(store => store.id === selectedDrugstore.id);
    if (index >= 0) {
      this.changeDetectionRef.detectChanges();
      const selectedRef = this.drugstores.toArray()[index];
      if (selectedRef) {
        this.scrollIntoView(selectedRef);
      }
    }
  }

  private scrollIntoView(ref: ElementRef<HTMLDivElement>) {
    if (ref.nativeElement) {
      setTimeout(() => {
        ref.nativeElement.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
          inline: 'center'
        });
      }, 100);
    }
  }

  validateCheckout() {
    if (this.router.url === '/checkout') {
      this.checkout = true;
    } else {
      this.checkout = false;
    }
  }

  closeModal(){
    this.nearbyBoticaModalRef.close();
  }

  tagDrugstorePageView(){
    TaggingService.tagEventAllRet("ui_drugstore_pageview", "UI :: Drugstore", "Pageview", "drugstore");
  }
}
