import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Entry } from 'contentful';
import { IContentfulBanner } from './contentful-banner.model';
import { ContentfulEntry } from '../../../shared/models/contentful/contentful.model';

export interface IContentfulBannerKit {
  title: string;
  bagOfProducts: 'SinVariacion' | 'ConVariacion';
  bagOfGift: 'SinVariacion' | 'ConVariacion';
  banner: Entry<IContentfulBanner>;
  idCampaign: string;
  tag?: string;
}

export class ContentfulBannerKit {
  title: string;
  productVariation: boolean;
  giftVariation: boolean;
  banner: IContentfulBanner;
  campaignId: string;

  constructor(iContentfulBannerKit: IContentfulBannerKit) {
    this.title = iContentfulBannerKit.title;
    this.productVariation = iContentfulBannerKit.bagOfProducts === 'ConVariacion';
    this.giftVariation = iContentfulBannerKit.bagOfGift === 'ConVariacion';
    this.banner = new ContentfulEntry(iContentfulBannerKit.banner).content;
    this.campaignId = iContentfulBannerKit.idCampaign || '';
  }
}

export const CContentfulBannerKit = {
  id: CONTENTFUL_PATH.contentTypeIds.bannerKit
};
