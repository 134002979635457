import { Injectable } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

@Injectable({
	providedIn: "root"
})
export class RecipeControlledFormService {
	public form: FormGroup;
	private _namesControl = new FormControl("", [Validators.required]);
	private _CMPIControl = new FormControl("", [Validators.required]);
	private _CMPITypeControl = new FormControl("", [Validators.required]);

	constructor(private formBuilder: FormBuilder) {
		this.form = this.formBuilder.group({
			names: this._namesControl,
			CMPI: this._CMPIControl,
			CMPIType: this._CMPITypeControl
		});
	}

	public get namesControl() {
		return this.form.get("names") as FormControl;
	}

	public get CMPIControl() {
		return this.form.get("CMPI") as FormControl;
	}
	public get CMPITypeControl() {
		return this.form.get("CMPIType") as FormControl;
	}
	setDataForm(data: RecipeControlledFormService) {
		this.form.setValue(data);
	}
}
