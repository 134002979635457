export interface ICampaignCustomer {
  id?: number;
  campaignId?: number;
  name: string;
  paternalLastName: string;
  maternalLastName: string;
  dateOfBirth: string;
  identityDocumentType: number;
  identityDocumentNumber: string;
  gender: string;
  email: string;
  phone: string;
  address: string;
  departmentUbigeo: string;
  acceptTermsAndConditions: boolean;
  acceptDataTreatment: boolean;
}

export class CampaignCustomer {
  campaignId: number;
  name: string;
  paternalLastName: string;
  maternalLastName: string;
  birthDate: string;
  identityDocumentType: number;
  identityDocumentNumber: string;
  gender: string;
  email: string;
  phone: string;
  address: string;
  department: string;
  acceptTermsAndConditions: boolean;
  acceptDataTreatment: boolean;

  constructor(iCampaignCustomer: ICampaignCustomer) {
    if (iCampaignCustomer.id || iCampaignCustomer.campaignId) {
      this.campaignId = iCampaignCustomer.id || iCampaignCustomer.campaignId;
    }
    this.name = iCampaignCustomer.name || '';
    this.paternalLastName = iCampaignCustomer.paternalLastName || '';
    this.maternalLastName = iCampaignCustomer.maternalLastName || '';
    this.birthDate = iCampaignCustomer.dateOfBirth || '';
    this.identityDocumentType = iCampaignCustomer.identityDocumentType || 0;
    this.identityDocumentNumber = iCampaignCustomer.identityDocumentNumber || '';
    this.gender = iCampaignCustomer.gender || '';
    this.email = iCampaignCustomer.email || '';
    this.phone = iCampaignCustomer.phone || '';
    this.address = iCampaignCustomer.address || '';
    this.department = iCampaignCustomer.departmentUbigeo || '';
    this.acceptTermsAndConditions = iCampaignCustomer.acceptTermsAndConditions || false;
    this.acceptDataTreatment = iCampaignCustomer.acceptDataTreatment || false;
  }
}
