import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputControlComponent } from './input-control/input-control.component';
import { RadioControlComponent } from './radio-control/radio-control.component';
import { CheckboxControlComponent } from './checkbox-control/checkbox-control.component';
import { LinkButtomComponent } from './link-buttom/link-buttom.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputWithoutTitleControlComponent } from './input-without-title-control/input-without-title-control.component';
import { SixDigitCodeComponent } from './code-six-control/six-digit-code-controlcomponent';
import { SixDigitCodeDirective } from './code-six-control/six-digit-code.directive';
import { SelectControlComponent } from './select-control/select-control.component';
import { InputSearcherComponent } from './input-searcher/input-searcher.component';
import { IconsModule } from '../../generic/icons/icons.module';
import { ObjSelectControlComponent } from './obj-select-control/obj-select-control.component';
import { InputControlV2Component } from './input-control-v2/input-control-v2.component';
import { RadioControlV2Component } from './radio-control-v2/radio-control-v2.component';
import { CheckboxControlV2Component } from './checkbox-control-v2/checkbox-control-v2.component';
import { SelectControlV2Component } from './select-control-v2/select-control.component-v2';
import { LinkButtomV2Component } from './link-buttom-v2/link-buttom-v2.component';

const COMPONENTS = [
  InputControlComponent,
  RadioControlComponent,
  CheckboxControlComponent,
  LinkButtomComponent,
  InputPasswordComponent,
  InputWithoutTitleControlComponent,
  SixDigitCodeComponent,
  SixDigitCodeDirective,
  SelectControlComponent,
  InputSearcherComponent,
  ObjSelectControlComponent,
  
  InputControlV2Component,
  RadioControlV2Component,
  CheckboxControlV2Component,
  LinkButtomV2Component,
  SelectControlV2Component,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    IconsModule
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class FormControlsModule { }
