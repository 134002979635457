  <ng-container>

      <ng-container *ngIf="selectAll">
        <div class="filter-item mb-1 mt-1">
            <p value="selectAll">{{selectAll.name}}</p>
        </div>
      </ng-container>


      <ng-container *ngFor="let item of list; let index = index">
        <ng-container *ngIf="index < itemsInView">

          <div class="filter-item mb-1 mt-1">
            <p>{{item.name}}</p>
          </div>

        </ng-container>
      </ng-container>
  </ng-container>

