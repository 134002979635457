<div class="content-error-modal">
  <fp-section-container-title-login
    [title]="'Validar correo'"
    [iconUser]="false"
    [iconName]="'close'"
    (onclick)="changeEmailEvent()"
  >
  </fp-section-container-title-login>
  <fp-sub-section-container>
    <div class="content">
      <div class="warning-message mx-4-l mt-5">
        Te hemos enviado un código al <b>{{ destinationMail }}</b> para validar
        tu correo
      </div>
      <div class="input-code mb-4 mx-4-l">
        <div class="input-code" [formGroup]="form">
          <fp-six-digit-code
            (someEvent)="goToNewRegister()"
            formControlName="code"
            [isDisabled]="isDisabledInputs"
          >
          </fp-six-digit-code>
        </div>
        <div class="countdown-content-l">
          <div class="d-flex justify-content-between">
            <div class="">
              <fp-link-buttom
                [labelClass]="'label-black--1 link-size'"
                [buttonText]="'Reenviar código'"
                [disabled]="buttonSendDisabled"
                (click)="sendNewCode()"
              ></fp-link-buttom>
            </div>
            <div class="">
              <div
                class="countdown label font-VAGRoundedNexxt-normal-w-400-gray mr-3"
              >
                {{ minutes | number: "2.0-0" }}:{{ seconds | number: "2.0-0" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5 mx-4-l">
        <fp-button-primary
          buttonDisplay="block"
          [buttonDisabled]="buttonDisabled"
          (click)="goToNewRegister()"
          >Enviar
        </fp-button-primary>
      </div>
    </div>
  </fp-sub-section-container>

  <div
    class="alert-success"
    *ngIf="checkVerify"
    [ngClass]="{ class: true }"
    [ngStyle]="{ background: isInkaBrand ? '#FFFFFF' : '#50B83C' }"
  >
    <div class="row">
      <div class="col-12 text-center">
        <img
          *ngIf="!isInkaBrand"
          src="/assets/images/icons/check-white.svg"
          alt="check"
        />
        <img *ngIf="isInkaBrand" src="/assets/icons/check-colored.svg" alt="" />
      </div>
      <div class="col-12 mx-auto">
        <p class="text-center font-VAGRoundedNexxt mt-2">Correo verificado</p>
      </div>
    </div>
  </div>
</div>
