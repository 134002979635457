import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { isArray } from "../../helpers/objects-equal";
import { ENDPOINTS } from "../../parameters/endpoints";
import { GenericHeaderService, GenericService } from "../generic";

import { HttpHeaders } from "@angular/common/http";
import { Client } from "@models/client/client.model";
import { END_POINTS_BUSSINESS } from "@parameters/generic/endpoints";
import { EHttpHeaders } from "@parameters/generic/http-header.parameter";
import { ClientStoreService } from "@stores/client/client-store.service";
import { TokenStoreService } from "@stores/user/token-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import {
  AddressNickname,
  IAddressNickname,
} from "../../models/address/address-nickname.model";
import {
  IAddressRequestParams,
  IAddressResponse,
} from "../../models/address/address.model";

@Injectable()
export class AddressClientService {
  private ADDRESS_NICKNAMES_ENDPOINT = ENDPOINTS.GET_ADDRESS_NICKNAME_LIST;
  private ADDRESS_ENDPOINT = ENDPOINTS.POST_ADD_ADDRESS;

  headers: HttpHeaders;
  isOrderTaker: boolean;

  constructor(
    private genericService: GenericService,
    private clientStoreService: ClientStoreService,
    private _tokenStoreService: TokenStoreService,
    private genericHeaderService:GenericHeaderService
  ) {
    this.headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });

    this.isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
  }

  public getAddressNicknameList$(): Observable<AddressNickname[]> {
    return this.genericService
      .genericGetStaticFile<IAddressNickname[]>(this.ADDRESS_NICKNAMES_ENDPOINT)
      .pipe(
        map((response) => {
          const tempAddressNicknames = isArray(response) ? response : [];
          return tempAddressNicknames.map(
            (addressNickName) => new AddressNickname(addressNickName)
          );
        })
      );
  }

  // return nothing
  public deleteAddress$(addressId: string) {
    let pathToDelete = `${this.ADDRESS_ENDPOINT}/${addressId}`
    let headers: HttpHeaders = this.genericHeaderService.getCommonAuthUserLegacy()

    if(this.isOrderTaker){
      const { codClient }: Client = this.clientStoreService.client;
      pathToDelete = `${END_POINTS_BUSSINESS.DELETE_ADDRESS}/${codClient}/${addressId}`
      headers = this.headers
    }

    return this.genericService.genericDelete(
      pathToDelete,
      headers
    );
  }

  // addressId not exist into params (return addressId)
  public createAddress$(
    params: IAddressRequestParams
  ): Observable<IAddressResponse> {
    return this.genericService.genericPost(ENDPOINTS.POST_ADD_ADDRESS, params);
  }

  // addressId exists into params (return addressId)
  public updateAddress$(
    objRequest: IAddressRequestParams
  ): Observable<IAddressResponse> {

    let objRequestUA = objRequest
    let headers: HttpHeaders = this.genericHeaderService.getCommonAuthUserLegacy()
    if(this.isOrderTaker){
    const { codClient }: Client = this.clientStoreService.client;
    const newParams = this.isOrderTaker
      ? {
          ...objRequest,
          idClient: codClient,
        }
      : null;
      objRequestUA = newParams;
      headers = this.headers

      return this.genericService.genericPut(
        END_POINTS_BUSSINESS.PUT_UPDATE_ADDRESS,
        objRequestUA,
        headers
      );
    }

    return this.genericService.genericPost(
      ENDPOINTS.POST_ADD_ADDRESS,
      objRequestUA,
      null,
      headers
    );
  }
}
