import { Injectable } from '@angular/core';
import { HandleFilterAlgolioRequest, SearchLandingAlgoliaRequest } from '@models/search-landing/search-landing-algolia-request.model';
import { take } from 'rxjs/operators';
import { SearchLandingRequest } from '../../../shared/models/search-landing/search-landing-request.model';
import { SearchLandingImplementService } from './search-landing-implement.service';

@Injectable()
export class SearchLandingRequestService {
  private searchLandingRequest: SearchLandingRequest;
  private searchLandingAlgoliaRequest: SearchLandingAlgoliaRequest;

  constructor(
    private _searchLandingImplement: SearchLandingImplementService,
  ) {
  }

  public searchLandingRequest$(searchLandingRequest: string) {
    this.searchLandingRequest = new SearchLandingRequest(searchLandingRequest);
    return this._searchLandingImplement.getSearchLandingFilters(this.searchLandingRequest).pipe(take(1));
  }


  public searchLangingAlgolia$(paramReq: string, facets:Array<string>,selectedCategories?: Array<any>, length?: number, max?: number,indexName?, algoliAPi?, apiKey?, analyticsTags?: string[], apiIndex?){
    if(indexName === apiIndex + '_query_suggestions'){
      this.searchLandingAlgoliaRequest = { "params": "query="+paramReq, "clickAnalytics": true}
    } else {
      this.searchLandingAlgoliaRequest = new SearchLandingAlgoliaRequest(paramReq, facets,selectedCategories, length, max);
    }

    if(analyticsTags && analyticsTags.length > 0) {
      this.searchLandingAlgoliaRequest.analyticsTags = analyticsTags;
      this.searchLandingAlgoliaRequest.clickAnalytics = true;
    }

    return this._searchLandingImplement.getSearchLandingAlgolaFilters(this.searchLandingAlgoliaRequest, indexName, algoliAPi, apiKey).pipe(take(1));
  }

  public handleSelectedAlgoliaRequest$(request: HandleFilterAlgolioRequest){
    return this._searchLandingImplement.handleSelectedRequestAlgolia(request).pipe(take(1))
  }

}
