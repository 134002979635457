import { FormControl } from '@angular/forms';
import { getErrorbyFormControl } from '../helpers/error-controls-helpers';
import { CUSTOM_BIRTHDAY_ERROR_MESSAGES } from '../parameters/global-error-message';
import { GenericValidator } from '../validators/generic-validator';

export class ControlBornDateControl extends FormControl {

  constructor() {
    super('');
    this.settingLastNameValidators();
  }

  private settingLastNameValidators() {
    this.setValidators([
      GenericValidator.validateFormattedDate,
      GenericValidator.validateBeforeCurrentDate,
      GenericValidator.validateAdult,
      GenericValidator.required
    ]);
  }

  public get birthDateError(): string {
    const error = getErrorbyFormControl(this, CUSTOM_BIRTHDAY_ERROR_MESSAGES);
    return error;
  }
}
