<div class="content-error-modal px-4 py-4">
  <div class="my-3 text-center">
		<img height="56" src="../../../../assets/icons/face_sad.svg" alt="Producto no disponible" />
	</div>
  <div class="warning-message text-center mx-2 my-3" >
    <b class="title mt-4 mb-3">{{title}}</b>
    <p>{{description}}</p>
    <b *ngIf="onlinePaymentHelper.isOnlinePaymentError">{{message}}</b>
  </div>
  <div *ngIf="able" class="warning-buttons mx-2 mt-4 mb-2">
    <a class="btn btn-primary w-100" (click)="refreshCurrentDeliveryServiceType()">Ver horarios disponibles</a>
    <a class="btn btn-gray w-100 mt-2" (click)="changeToTheNextEnabledSchedule()">Cambiar al siguiente horario disponible</a>
  </div>

  <div *ngIf="!able" class="warning-buttons mx-2 mt-4 mb-2">
    <a class="btn btn-primary w-100" (click)="changeDeliveryServiceType()">Cambiar tipo de entrega</a>
  </div>
</div>
