import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'fp-checkout-check-list-password',
  templateUrl: './checkout-check-list-password.component.html',
  styleUrls: ['./checkout-check-list-password.component.sass']
})
export class CheckoutCheckListPasswordComponent implements OnInit {

  @Input() verifyCharacter = false;
  @Input() verifyCapital = false;
  @Input() verifyLower = false;
  @Input() verifyNumber = false;

  constructor() {}

  ngOnInit() {}
}
