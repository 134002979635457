<div class="position-relative" [formGroup]="selectFormGroup">
  <mat-select
    disableRipple
    disableOptionCentering
    panelClass="select-basic"
    [formControlName]="selectFormControlName"
    [class.select-focus]="selectFocus"
    [class.select-active]="selectType === 'active'"
    [placeholder]="selectPlaceholder"
    (selectionChange)="selectionChange()"
    (focus)="focusSelect()"
    (focusout)="focusOutSelect()"
    (closed)="focusOutSelect()">

    <div class="option-container">
      <mat-option
        class="select-basic-option"
        *ngFor="let option of optionList"
        [value]="option">
        {{option}}
      </mat-option>
    </div>

  </mat-select>

  <span class="icon
    icon-16-enlarge{{isFocus ? '-support-two': ''}}
    select-icon position-vertical-center">
  </span>
</div>
