import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-tag-seller',
  templateUrl: './tag-seller.component.html',
  styleUrls: ['./tag-seller.component.sass']
})
export class TagSellerComponent implements OnInit {

  @Input() iconName = '';
  @Input() text = '';
  @Input() customClass = '';
  @Input() iconSize = '20';
  @Input() iconHeight = '20';

  constructor() { }

  ngOnInit() {
  }

}
