import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { AppStoreService } from "@stores/app/app-store.service";
import { AnalyticsEventCategory, AnalyticsEventName, AnalyticsManager, AnalyticsModel } from "src/app/analytics";
import { ProductDetail } from "src/app/business/product-detail/models/product-detail-model";
import { CoachmarkService } from "src/app/shared/service/coachmark/coachmark.service";
import { ShowModalBoticaService } from "src/app/shared/service/showModalServices/showModalService.service";
import { EProductStatus } from "../../../models/product-status.enum";
import { ShoppingCartRequestService } from "src/app/shared/service/shopping-cart/shopping-cart-request.service";
import { ShoppingListStoreService } from "@stores/shopping-list/shopping-list-store.service";
import { ProductSellerInShoppingcartDialogComponent } from "src/app/shared/components/product-seller-in-shoppingcart-dialog/product-seller-in-shoppingcart-dialog.component";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { MatDialog } from "@angular/material/dialog";
import { AddressListStoreService } from "src/app/core/modules/address-manager/address-stores/address-list-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";

@Component({
  selector: "fp-product-available-botica-responsive",
  templateUrl: "./product-available-botica-responsive.component.html",
  styleUrls: ["./product-available-botica-responsive.component.sass"]
})
export class ProductAvailableBoticaResponsiveComponent implements OnInit, OnChanges {
  @Input() availableProduct: number;
  @Input() product: ProductDetail;
  @Input() style: boolean;
  @Input() addressOut: boolean;
  @Input() availableRet: boolean;
  @Input() availableRad: boolean;
  @Input() currentPresentation: number;

  
  isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
	isRAD = this.retValidateCartService.isRADProcess;

  coachMarkConsultBotica = true;
  coachMarkHome = true;
  eProductStatus = EProductStatus;

  get isSeller(): boolean {
    return this.product?.isFromSeller
  }

  get isUnavailable() {
    return this.product.statusId === this.eProductStatus.unavailable
  }

  constructor(
    private _appStore: AppStoreService,
    private _shoppingListStore: ShoppingListStoreService,
    private _addressListStore: AddressListStoreService,
    private showModalBoticaService: ShowModalBoticaService,
    public coachMarkService: CoachmarkService,
    private shoppingListRequest: ShoppingCartRequestService,
    private retValidateCartService: RetValidateCartService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // comment coachmark
    // this.coachMarkService.activeHomeCoachMark.subscribe((status: boolean) => {
    //   this.coachMarkHome = status
    //   if (!status && !this.isSeller && !this.isUnavailable) this.showOrHiddenCoachMarkConsultBotica();
    // })
  }

  ngOnChanges(): void {
    if (this.isSeller) {
      this.availableRet = false;
    }
  }

  get cartHaveSeller() {
    return this._shoppingListStore.getLocalStorageShoppingCartList()?.filter((product: ProductDetail) => product.sellerId);
  }

  showModalBotica(product) {
    product.presentationIdSelected = this.currentPresentation;
    if (this.retValidateCartService.isRADProcess && this.cartHaveSeller.length) {
      this.dialog.open(ProductSellerInShoppingcartDialogComponent,
        {
          width: '450px',
          panelClass: "modal-in-footer",
          disableClose: true,
          data: {
            address: this._addressListStore.currentSelectedAddress,
            product,
            responsive: true
          },
        });
      return
    }
    this.coachMarkService.activeProductCoachmark.next(false);
    this.showModalBoticaService.showModalResponsiveService(product);
    this.shoppingListRequest.setSelectedProduct = product;
    this.registerEventAnalytics(AnalyticsEventName.PRODUCT_DETAIL, "ui_product_detail_ver_botica", "click :: Ver botica", this.product.id);
  }

  registerEventAnalytics(prefixEventName: string, eventName: string, eventAction: string, eventLabel: string) {
    const analytics: AnalyticsModel = {
      pageName: "",
      prefixEventName: prefixEventName,
      prefixEventCategory: AnalyticsEventCategory.PRODUCT_DETAIL,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventName: eventName
    };
    AnalyticsManager.dispatchTriggerTag(analytics, false);
  }

  removeCoachMark() {
    this._appStore.setStorageItem("BVB", "true");
    this.coachMarkConsultBotica = true
    this.showModalBoticaService.activeDrugstoreModal.next(false);
    this.coachMarkService.activeProductCoachmark.next(false);
    this.coachMarkService.activeDrugstoreModal.next(false);
  }

  showOrHiddenCoachMarkConsultBotica() {
    const coachMark = JSON.parse(this._appStore.getStorageItem("BVB"));
    this.coachMarkConsultBotica  = coachMark ? true : false;
    this.coachMarkService.activeProductCoachmark.next(!this.coachMarkConsultBotica);
  }
}
