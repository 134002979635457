<fp-static-landing-container-dialog
  (closeContainer)="closeDialog()">
  <fp-static-landing-header
    [loader]="loader"
    [headerTitle]="treatmentForAdditionalPurposes?.title"
    [headerImage]="treatmentForAdditionalPurposes?.image">
  </fp-static-landing-header>

  <fp-static-landing-content
    class="treatment-for-additional-purposes-content"
    [loader]="loader"
    [content]="treatmentForAdditionalPurposes?.content">
  </fp-static-landing-content>
</fp-static-landing-container-dialog>
