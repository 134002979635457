import { Injectable } from '@angular/core';
import { CampaignCustomerParams, ICampaignCustomerRequest } from '../../models/campaign/campaign-customer-request.model';
import { Observable } from 'rxjs';
import { CampaignDepartment, ICampaignDepartment } from '../../models/campaign/campaign-department.model';
import { CampaignIdentityDocumentType, ICampaignIdentityDocumentType } from '../../models/campaign/campaign-identity-document-type.model';
import { CampaignCustomerResponse, ICampaignCustomerResponse } from '../../models/campaign/campaign-customer-response.model';
import { map } from 'rxjs/operators';
import { CampaignClientService } from './campaign-client.service';
import { CampaignCustomerValuesRequest } from '../../../business/world-landing/models/world-affiliation-mom-and-baby.interface';

@Injectable({
  providedIn: 'root'
})
export class CampaignImplementService {

  constructor(
    private _campaignClient: CampaignClientService
  ) {
  }

  getIdentityDocumentType(): Observable<Array<CampaignIdentityDocumentType>> {
    return this._campaignClient.getIdentityDocumentType()
      .pipe(
        map((iCampaignIdentityDocumentTypeArray: Array<ICampaignIdentityDocumentType>) => {
          return iCampaignIdentityDocumentTypeArray
            .map((iCampaignIdentityDocumentType: ICampaignIdentityDocumentType) => new CampaignIdentityDocumentType(iCampaignIdentityDocumentType));
        })
      );
  }

  getCampaignDepartment(): Observable<Array<CampaignDepartment>> {
    return this._campaignClient.getCampaignDepartment()
      .pipe(
        map((iCampaignDepartmentArray: Array<ICampaignDepartment>) => {
          return iCampaignDepartmentArray
            .map((iCampaignDepartment: ICampaignDepartment) => new CampaignDepartment(iCampaignDepartment));
        })
      );
  }

  getCampaignCustomer(campaignCustomerParams: CampaignCustomerParams): Observable<CampaignCustomerResponse> {
    return this._campaignClient.getCampaignCustomer(campaignCustomerParams)
      .pipe(
        map((iCampaignCustomerResponse: ICampaignCustomerResponse) => new CampaignCustomerResponse(iCampaignCustomerResponse))
      );
  }

  postCampaignCustomer(campaignCustomerRequest: ICampaignCustomerRequest): Observable<{ customerId: number, hoursUntilAccessToPromotions: number }> {
    return this._campaignClient.postCampaignCustomer(campaignCustomerRequest);
  }


  postCampaignCustomerValues(campaignCustomerValuesRequest: CampaignCustomerValuesRequest): Observable<{ id: number }> {
    return this._campaignClient.postCampaignCustomerValues(campaignCustomerValuesRequest);
  }

  postRegisterAffiliated(newRegisterCampaing): Observable<any> {
    return this._campaignClient.postCampaignRegister(newRegisterCampaing);
  }

  searchAffiliated(searchAffiliated): Observable<any> {
    return this._campaignClient.searchCampaignUser(searchAffiliated);
  }
}
