<div class="row product-cart-responsive"
     [class.unavailable-presentation]="productStatus === eProductStatus.unavailablePresentation">

  <div class="col-12 mb-4 text-right">
    <fp-button-ghost
      buttonId="btn--delete-product"
      buttonSize="small"
      buttonColor="red"
      (click)="deleteProduct(productSeller.id)">
      Eliminar
    </fp-button-ghost>
  </div>

  <div class="col-12 mb-3">
    <fp-link [linkRouter]="['/'+productPath, productSeller.slug, productSeller.id]">
      <fp-product-information-cart
        [image]="productSeller.imageList[0].url"
        [name]="productSeller.name"
        [description]="productSeller?.noFractionatedText"
        [productStatus]="productStatus">
      </fp-product-information-cart>
    </fp-link>
  </div>

  <div class="col-12 mb-3">
    <div class="row align-items-center">
      <div class="col-6">
        <fp-product-presentation
          [presentationList]="listPresentation(productSeller)"
          [presentationSelected]="productSeller?.presentationIdSelected"
          [productStatus]="productStatus"
         (updatePresentation)="setProductPresentation($event)">
       </fp-product-presentation>
      </div>

      <div class="col-6">
        <fp-product-quantity
        [productQuantity]="productSeller?.quantitySelected"
        [quantityLimit]="productSeller?.maxUnitSale"
        [productStatus]="productStatus"
        [product]="productSeller"
        [rounded]="true"
        [isSeller]="productSeller.isFromSeller"
        (updateQuantity)="setQuantity($event)">
        </fp-product-quantity>
      </div>

    </div>
  </div>
  <div class="col-12">
    <fp-product-cart-price
      [subTotal]="productSeller?.subTotalAllPaymentMethod"
      [subTotalOffer]="productSeller?.subTotalWithPaymentMethod"
      [productStatus]="productStatus"
      [regularPrice]="productSeller?.subTotal">
    </fp-product-cart-price>
  </div>
</div>
