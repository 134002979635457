import { environment } from '../../../../environments/environment';

const getfullEndPoint = (termination) => {
    return `${environment.api_gateway_07}/${termination}`;
  };


  export const CONSTANTS = {
    COUPON_STATES: {
        1: 'AVAILABLE',
        2: 'USED',
        3: 'EXPIRED'
    },
    COUPON_STATES_SPANISH: {
        1: 'disponible',
        2: 'usado',
        3: 'vencido'
    },
    COMPANY_CODE: 'MF',
    COUPONS_LIMIT: 40,
    GENERIC_ERROR_MESSAGE: 'Disculpa, ha ocurrido algo inesperado. Por favor vuelve a intentar más tarde.',
    ACCESS_TYPE: {
        type: {
            accessByLogin: 'ACCESS_BY_LOGIN',
            accessBySession: 'ACCESS_BY_SESSION'
        }
    }
  };

  export const END_POINTS = {
    GET_INIT_URL: environment.api_gateway,
    POST_COUPONS: getfullEndPoint('coupons'),
    POST_ENABLE_COUPON: getfullEndPoint('enableCoupon'),
    POST_COUPON: getfullEndPoint('coupon'),
    GET_SETTINGS: getfullEndPoint('settings'),
    GET_CAMPAIGNS: getfullEndPoint('campaigns'),
    POST_AUTH_USER: getfullEndPoint('auth'),
    POST_AUDIT_USER: getfullEndPoint('audit'),
    POST_UPDATE_USER: getfullEndPoint('update')
  };

  const noCouponsMessageGroup = ['Para ver tus cupones, debes ser Socio del Monedero del Ahorro.',
' Ingresa a <a href="http://mifarmapromos.com.pe/actualizatusdatos" target="_blank">mifarmapromos.com.pe/actualizatusdatos</a> para afiliarte.'];



