import { Injectable } from "@angular/core";
import { CryptoService } from "../crypto/crypto.service";

@Injectable({
	providedIn: "root"
})
export class SessionStorageService {
	constructor(private cryptoService: CryptoService) {}

	getItem(key: string) {
		try {
			const value = sessionStorage.getItem(key) ?? null;
			if (value) {
				const decrypt = this.cryptoService.decrypt(value);
				return JSON.parse(decrypt);
			} else {
				return null;
			}
		} catch (error) {
			console.error(error);
			sessionStorage.removeItem(key);
			return null;
		}
	}

	setItem(key: string, value: any) {
		try {
			const valueEncrypt = this.cryptoService.encrypt(JSON.stringify(value));
			sessionStorage.setItem(key, valueEncrypt);
		} catch (error) {
			sessionStorage.removeItem(key);
			console.error(error);
		}
	}

	deleteItem(key: string) {
		sessionStorage.removeItem(key);
	}
}
