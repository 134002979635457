import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '@environments/environment';
import { EBrand, EBrandAsset } from '@parameters/access/brand.parameter';
import { OrderTakerValidator } from '@validators/order-taker.validator';

@Component({
  selector: 'fp-selected-item-bordered-card',
  templateUrl: './selected-item-bordered-card.component.html',
  styleUrls: ['./selected-item-bordered-card.component.sass']
})
export class SelectedItemBorderedCardComponent implements OnInit {

  @Input() icon = 'store-for-pick-up';
  @Input() title = 'title';
  @Input() subtitle = 'subtitle';
  @Input() bottomDetail = '';

  @Input() showClickEvent = true;
  @Output() clickEvent = new EventEmitter();

  @Input() hasAlertMessage = false;
  @Input() alertMessage = '';

  public orderTaker = OrderTakerValidator.isOrderTakerEnv();
  public isInkaBrand = OrderTakerValidator.isOrderTakerEnv()
		? EBrand[EBrandAsset[environment.brand]] == EBrand.inkafarma
		: EBrand[environment.brand] == EBrand.inkafarma;
  public clockIcon = this.orderTaker ? 'blue-clock' : this.isInkaBrand ? 'blue-clock' : 'purple-clock';

  constructor() { }

  ngOnInit() {
  }

}
