import { isObject } from '../../helpers/objects-equal';
import { IPosition, Position } from '../coverage-location/coverage-location.model';
import { IAddressItem, AddressItem } from '../address/address.model';
import { NEW_PAYMENT_METHOD_APP_AGORA_PAY } from 'src/app/business/checkout/parameters/constants';
import { validateAppAgoraPayMethod } from 'src/app/business/checkout/checkout-helpers/payment-methods';

export interface IDeliveryInfoRequestParams {
  deliveryTypeId: string; // 1 or 2
  drugstoreId: string; // 18
}

// CREDIT CARD
export interface ICreditCard {
  id: number;
  cardGroup: number;
  name: string; // Efectivo en Soles (S/)
  favorite: boolean;
  descriptionShort: string; // Efectivo en Soles (S/)
  descriptionLarge: string; // Aceptamos efectivo.
  colorDescription: string; // #28a862
  imageUrl: string; // #28a862
  paymentMethodPagoWeb:string;
}
export class CreditCard {

  public id: number;
  public cardGroup: number;
  public name: string;
  public favorite: boolean;
  public descriptionShort: string;
  public descriptionLarge: string;
  public colorDescription: string;
  public cardImage: string;
  public paymentMethodPagoWeb:string;

  constructor(creditCardList: ICreditCard) {
    const currentValue = isObject(creditCardList) ? creditCardList : {} as ICreditCard;
    this.id = currentValue.id || -1;
    this.cardGroup = currentValue.cardGroup || null;
    this.name = currentValue.name || '';
    this.favorite = currentValue.favorite || false;
    this.descriptionShort = currentValue.descriptionShort || '';
    this.descriptionLarge = currentValue.descriptionLarge || '';
    this.colorDescription = currentValue.colorDescription || '';
    this.cardImage = currentValue.imageUrl || '';
    this.paymentMethodPagoWeb = currentValue.paymentMethodPagoWeb || '';
  }
}

// PAYMENT METHOD
export interface IPaymentMethod {
  id: number;
  name: string; // Pago en línea, Pago con P.O.S., Pago en efectivo
  imageUrl: string;
  favorite: boolean;
  creditCardList: ICreditCard[];
}
export class PaymentMethod {

  public id: number;
  public name: string; // Pago en línea, Pago con P.O.S., Pago en efectivo
  public imageUrl: string;
  public favorite: boolean;
  public creditCardList: CreditCard[];

  constructor(paymentMethod: IPaymentMethod) {
    const currentValue = isObject(paymentMethod) ? paymentMethod : {} as IPaymentMethod;
    this.id = currentValue.id || -1;
    this.name = currentValue.name || '';
    this.imageUrl = currentValue.imageUrl || '';
    this.favorite = currentValue.favorite || false;
    const list = this.getCreditCardArray(currentValue.creditCardList);
    this.creditCardList = list.map(e => new CreditCard(e));
  }

  private getCreditCardArray(creditCardList: ICreditCard[] | undefined) {
    return Array.isArray(creditCardList) ? creditCardList : [];
  }
}

// DELIVERY TYPE
export interface IDeliveryType {
  name: string;
  selected: boolean;
  shortName: string;
  segmentGap: number;
  enabled: boolean;
  serviceNew: boolean;
  intervalTime: number;
  serviceCost: number;
}

export class DeliveryType {
  public name: string;
  public selected: boolean;
  public segmentGap: number;
  public enabled: boolean;
  public serviceNew: boolean;
  public intervalTime: number;
  public serviceCost: number;

  constructor(deliveryType: IDeliveryType) {
    const currentValue = isObject(deliveryType) ? deliveryType : {} as IDeliveryType;
    this.segmentGap = currentValue.segmentGap || -1;
    this.name = currentValue.name || '';
    this.selected = currentValue.selected || false;
    this.enabled = currentValue.enabled || false;
    this.serviceNew = currentValue.serviceNew || false;
    this.intervalTime = currentValue.intervalTime || 0;
    this.serviceCost = currentValue.serviceCost || 0;
  }
}

export interface IServiceType {
  brand: string;
  enabledDelivery: boolean;
  enabledPickup: boolean;
}

export class ServiceType {
  public brand: string;
  public enabledDelivery: boolean;
  public enabledPickup: boolean;

  constructor(data: IServiceType) {
    const currentValue = isObject(data) ? data : {} as IServiceType;
    this.brand = currentValue.brand || '';

    const keys = Object.keys(currentValue);

    this.enabledDelivery = keys.includes('enabledDelivery') ? currentValue.enabledDelivery : false;
    this.enabledPickup = keys.includes('enabledPickup') ? currentValue.enabledPickup : false;
  }
}

// ZONE
export interface IZone {
  id: number;
  name: string;
  ubigeo: string;
  zoneLimits: IPosition[];
}

export class Zone {

  public id: number;
  public name: string;
  public ubigeo: string;
  public zoneLimits: Position[];

  constructor(zone: IZone) {
    const currentValue = isObject(zone) ? zone : {} as IZone;
    this.id = currentValue.id || -1;
    this.name = currentValue.name || '';
    this.ubigeo = currentValue.ubigeo || '';
    const zoneLimits = this.getZoneLimits(currentValue.zoneLimits);
    this.zoneLimits = zoneLimits.map(limit => new Position(limit));
  }

  private getZoneLimits(zoneLimits: IPosition[] | undefined) {
    return Array.isArray(zoneLimits) ? zoneLimits : [];
  }
}

// BANNER CHECKOUT
export interface IBannerCheckout {
  checkoutCart: string; // HORARIO
  orden: number;
  imageUrl: string;
}

export class BannerCheckout {

  public checkoutCart: string; // HORARIO
  public orden: number;
  public imageUrl: string;

  constructor(bannerCheckout: IBannerCheckout) {
    const currentValue = isObject(bannerCheckout) ? bannerCheckout : {} as IBannerCheckout;
    this.checkoutCart = currentValue.checkoutCart || ''; // HORARIO
    this.orden = currentValue.orden || -1;
    this.imageUrl = currentValue.imageUrl || '';
  }
}

// RESPONSE
export interface IDeliveryInfoResponse {
  address: IAddressItem[]; // directions saved
  paymentMethods: IPaymentMethod[];
  zones: IZone[]; // covered zones
  bannerCheckout: IBannerCheckout[];
  deliveriesTypes: IDeliveryType[];
  isActiveThird: boolean;
  isFromSeller: boolean;
  serviceTypes: IServiceType[];
  orderType: string;
}

export class DeliveryInfoResponse {

  public address: AddressItem[]; // directions saved
  public paymentMethods: PaymentMethod[];
  public zones: Zone[]; // covered zones
  public bannerCheckout: BannerCheckout[];
  public deliveryTypes: DeliveryType[];
  public isActiveThird: boolean; // RET: recojo por terceros
  public isFromSeller: boolean;
  public serviceTypes: ServiceType;
  public orderType: string;

  constructor(response: IDeliveryInfoResponse) {
    const currentValue = isObject(response) ? response : {} as IDeliveryInfoResponse;

    const addresses = Array.isArray(currentValue.address) ? currentValue.address : [];
    this.address = addresses.map(address => new AddressItem(address)); // directions saved

    const paymentMethods = Array.isArray(currentValue.paymentMethods) ? currentValue.paymentMethods : [];
    /*
    * Verificamos si algún elemento en `paymentMethods` tiene `id == 3` correspondiente a Pago en Linea.
    * Verificar si Pago en Linea tiene en creditCardList un `id == 22`
    */
    // if (validateAppAgoraPayMethod(paymentMethods)) {
    //   // Agregamos el nuevo método de pago a `paymentMethods`.
    //   paymentMethods.push(NEW_PAYMENT_METHOD_APP_AGORA_PAY);
    // }
    this.paymentMethods = paymentMethods.map(paymentMethod => new PaymentMethod(paymentMethod));

    const zones = Array.isArray(currentValue.zones) ? currentValue.zones : [];
    this.zones = zones.map(zone => new Zone(zone)); // covered zones

    const bannersCheckout = Array.isArray(currentValue.bannerCheckout) ? currentValue.bannerCheckout : [];
    this.bannerCheckout = bannersCheckout.map(bannerCheckout => new BannerCheckout(bannerCheckout));

    const deliveryTypes = Array.isArray(currentValue.deliveriesTypes) ? currentValue.deliveriesTypes : [];
    this.deliveryTypes = deliveryTypes.map(type => new DeliveryType(type));

    const keys = Object.keys(currentValue);
    this.isActiveThird = keys.includes('isActiveThird') ? currentValue.isActiveThird : false;

    this.isFromSeller = keys.includes('isFromSeller') ? currentValue.isFromSeller : false;

    const isValidServiceType = currentValue.serviceTypes && Array.isArray(currentValue.serviceTypes) && currentValue.serviceTypes.length > 0;
    const currentServiceType = isValidServiceType ? currentValue.serviceTypes[0] : null;
    this.serviceTypes = new ServiceType(currentServiceType);
    this.orderType = response.orderType;
  }
}
