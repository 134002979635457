import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FLOW_SEARCH_ADDRESS_TYPE } from "src/app/core/modules/address-manager/parameters/modal.constant";
import { OFFICE_MODAL_CONFIG } from "../parameters/office.const";
import { OfficeComponent } from "./../views/office/office.component";

@Injectable()
export class OfficeModalService {
  private officeModalRef: MatDialogRef<OfficeComponent>;
  constructor(private matDialog: MatDialog) {}

  public openOfficeModal(
    option = FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS,
    retOrRad?
  ) {
    this.officeModalRef = this.matDialog.open(OfficeComponent, {
      ...OFFICE_MODAL_CONFIG,
      data: {
        option: option,
        retOrRad: retOrRad
      },
    });
  }
  public closeOfficeModal() {
    this.officeModalRef.close();
  }
}
