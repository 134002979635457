import { Injectable } from "@angular/core";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IProductCard } from "src/app/core/modules/product-card/models/product-card/product-card.interface";
import { ProductCard } from "src/app/core/modules/product-card/models/product-card/product-card.model";
import { environment } from "../../../../environments/environment";
import {
  ProductDetail,
  ProductSeoAtrtibutes,
} from "../../../business/product-detail/models/product-detail-model";
import {
  IProductDetail,
  IProductSeoAttributes,
} from "../../../business/product-detail/models/product-detail.interface";
import { ENDPOINTS } from "../../parameters/endpoints";
import { AppStoreService } from "../../stores/app/app-store.service";
import { OrderTakerValidator } from "../../validators/order-taker.validator";
import { GenericService } from "../generic";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ProductDetailClientService {
  constructor(
    private _GenericService: GenericService,
    private _appStoreService: AppStoreService
  ) {}

  get brand() {
    const brand = OrderTakerValidator.isOrderTakerEnv()
      ? this._appStoreService.brandAtention
      : environment.brand;
    return brand;
  }

  getUrlProductsById(): string {
    let isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
    if (isOrderTaker) {
      const isMifaBrand =
        EBrand[EBrandAsset[environment.brand]] == EBrand.mifarma;
      return isMifaBrand
        ? ENDPOINTS.GET_MF_PRODUCTS_BY_ID
        : ENDPOINTS.GET_PRODUCTS_BY_ID;
    }
    return ENDPOINTS.GET_PRODUCTS_BY_ID;
  }

  getUrlProductsByIdForSEO(): string {
    let isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
    if (isOrderTaker) {
      const isMifaBrand =
        EBrand[EBrandAsset[environment.brand]] == EBrand.mifarma;
      return isMifaBrand
        ? ENDPOINTS.GET_MF_PRODUCTS_BY_ID_FOR_SEO
        : ENDPOINTS.GET_PRODUCTS_BY_ID_FOR_SEO;
    }
    return ENDPOINTS.GET_PRODUCTS_BY_ID_FOR_SEO;
  }
  
  public getProductDetailById(
    id: string
  ): Observable<[ProductDetail, ProductCard]> {
    if (this.brand === EBrand.inkafarma) {
      return this._GenericService
        .genericGetProductDetail(this.getUrlProductsById() + id)
        .pipe(
          map((iProductDetail: IProductDetail | IProductCard) => {
            
            const productCard = iProductDetail
              ? new ProductCard(iProductDetail as IProductCard)
              : null;

            const productDetail = iProductDetail
              ? new ProductDetail(iProductDetail as IProductDetail)
              : null;
            return [productDetail, productCard];
          })
        );
    } else {
      return this._GenericService
        .genericGet(this.getUrlProductsById() + id)
        .pipe(
          map((iProductDetail: IProductDetail | IProductCard) => {
            const productCard = iProductDetail
              ? new ProductCard(iProductDetail as IProductCard)
              : null;

            const productDetail = iProductDetail
              ? new ProductDetail(iProductDetail as IProductDetail)
              : null;

            return [productDetail, productCard];
          })
        );
    }
  }

  public getProductSeoAttributes(id: string): Observable<ProductSeoAtrtibutes> {
    if (this.brand === EBrand.inkafarma) {
      return this._GenericService
        .genericGetProductDetail(
          `${this.getUrlProductsByIdForSEO()}${id}/seoAttributes`
        )
        .pipe(
          map((iProductSeoAttributes: IProductSeoAttributes) =>
            iProductSeoAttributes
              ? new ProductSeoAtrtibutes(iProductSeoAttributes)
              : null
          )
        );
    } else {
      return this._GenericService
        .genericGet(`${this.getUrlProductsByIdForSEO()}${id}/seoAttributes`)
        .pipe(
          map((iProductSeoAttributes: IProductSeoAttributes) =>
            iProductSeoAttributes
              ? new ProductSeoAtrtibutes(iProductSeoAttributes)
              : null
          )
        );
    }
  }
}
