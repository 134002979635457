import { isObject } from '../../helpers/objects-equal';

export interface IPosition {
  latitude: number;
  longitude: number;
}

export class Position {
  public latitude: number;
  public longitude: number;

  constructor(position: IPosition) {
    const currentValue = isObject(position) ? position : {} as IPosition;
    this.latitude = currentValue.latitude || -1;
    this.longitude = currentValue.longitude || -1;
  }
}

// COVERAGE LOCATION ELEMENT
export interface ICoverageLocation {
  latitude: number;
  longitude: number;
  addressName: string;
  addressNumber: string;
  zoneId: number;
  zoneName: string;
  zoneDescription: string;
  drugstoreStockId: string;
  drugstoreWareHouseId: string;
  drugstoreStockName: string;
  inCoverage: boolean;
  isFarFromCurrentLocation: boolean;
  enabled: boolean;
  fulfillmentCenterCode: string;
  userAddressId: string;
  backUpZone: boolean;
}

export class CoverageLocation {
  public latitude: number;
  public longitude: number;
  public addressName: string;
  public addressNumber: string;
  public zoneId: number;
  public zoneName: string;
  public zoneDescription: string;
  public drugstoreStockId: string;
  public drugstoreWareHouseId: string;
  public drugstoreStockName: string;
  public inCoverage: boolean;
  public isFarFromCurrentLocation: boolean;
  public enabled: boolean;
  public fulfillmentCenterCode: string;
  public userAddressId: string;
  public backUpZone: boolean;

  constructor(coverageLocation: ICoverageLocation) {
    const currentValue = isObject(coverageLocation) ? coverageLocation : {} as ICoverageLocation;
    this.latitude = currentValue.latitude || 0;
    this.longitude = currentValue.longitude || 0;
    this.addressName = currentValue.addressName || '';
    this.addressNumber = currentValue.addressNumber || '';
    this.zoneId = currentValue.zoneId || 0;
    this.zoneName = currentValue.zoneName || '';
    this.zoneDescription = currentValue.zoneDescription || '';
    this.drugstoreStockId = currentValue.drugstoreStockId || '';
    this.drugstoreWareHouseId = currentValue.drugstoreWareHouseId || '';
    this.drugstoreStockName = currentValue.drugstoreStockName || '';
    this.inCoverage = currentValue.inCoverage || false;
    this.isFarFromCurrentLocation = currentValue.isFarFromCurrentLocation || false;
    this.enabled = currentValue.enabled || false;
    this.fulfillmentCenterCode = currentValue.fulfillmentCenterCode || '';
    this.userAddressId = currentValue.userAddressId || '';
    this.backUpZone = currentValue.backUpZone || false;
  }
}

// COVERAGE LOCATION RESPONSE
export interface ICoverageLocationResponse {
  current: CoverageLocation;
  favorite: CoverageLocation;
  notCoveraged: boolean;
}

export class CoverageLocationResponse {

  public current: CoverageLocation;
  public favorite: CoverageLocation;
  public notCoveraged: boolean;

  constructor(coverageLocationResponse: ICoverageLocationResponse) {
    const currentValue = isObject(coverageLocationResponse) ? coverageLocationResponse : {} as ICoverageLocationResponse;
    this.current = new CoverageLocation(currentValue.current);
    this.favorite = new CoverageLocation(currentValue.favorite);
    this.notCoveraged = currentValue.notCoveraged
  }
}

