<div class="carousel-title">
  <div class="d-flex align-items-center">
    <h1 *ngIf="list?.headingLevel=='h1'" class="title heading-2 heading-md-3">{{list?.title}}</h1>
    <h2 *ngIf="list?.headingLevel=='h2'" class="title heading-2 heading-md-3">{{list?.title}}</h2>
    <h3 *ngIf="list?.headingLevel=='h3'" class="title heading-2 heading-md-3">{{list?.title}}</h3>
    <h4 *ngIf="list?.headingLevel=='h4'" class="title heading-2 heading-md-3">{{list?.title}}</h4>
    <h5 *ngIf="list?.headingLevel=='h5'" class="title heading-2 heading-md-3">{{list?.title}}</h5>
    <h6 *ngIf="list?.headingLevel=='h6'" class="title heading-2 heading-md-3">{{list?.title}}</h6>
    
    <span *ngIf="list?.slug">

        <div style="cursor: pointer;" class="text-decoration-none link-see-more label-black d-none d-lg-block"  (click)="goToUrl()">
          Ver más productos
        </div>

    </span>
  </div>
</div>
