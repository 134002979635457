import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-tag-low-price',
  templateUrl: './tag-low-price.component.html',
  styleUrls: ['./tag-low-price.component.sass']
})
export class TagLowPriceComponent implements OnInit {

  @Input() tagType: 'card' | 'ficha' = 'card';
  constructor() { }

  ngOnInit() {
  }

}
