import { FormControl, Validators } from '@angular/forms';

export class PaymentMethodControl extends FormControl {

  constructor() {
    super('');
    this.settingPaymentMethodValidators();
  }

  private settingPaymentMethodValidators() {
    this.setValidators([
        Validators.required,
    ]);
  }

}
