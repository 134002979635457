import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fp-product-picker',
  templateUrl: './product-picker.component.html',
  styleUrls: ['./product-picker.component.sass']
})
export class ProductPickerComponent {

  @Input() public pickerDisabled: boolean;
  @Input() public pickerIcon: 'minus' | 'plus' | string = 'minus';
  @Input() public pickerSize: 'small' | 'large' = 'small';
  @Input() public pickerShadow: boolean;
  @Input() public quantity: number;
  @Output() public clicked = new EventEmitter();
  @Input() public buttonPickerId: string;

  clickEvent() {
    this.clicked.emit();
  }

}
