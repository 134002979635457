import { Injectable } from "@angular/core";
import { SnackbarToastService } from "../../snackbar/snackbar-toast.service";
import { PRODUCT_ALERTS } from "../parameters/product-alerts";

@Injectable()
export class ProductAlertsService {
  constructor(private _snackbarToast: SnackbarToastService) {}

  deleteProductAlert() {
    this._snackbarToast.snackBarToast(PRODUCT_ALERTS.DELETE_PRODUCT);
  }
}
