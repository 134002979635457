import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductOutOfStockDialogComponent } from '../product-out-of-stock-dialog/product-out-of-stock-dialog.component';
import { AppStoreService } from '@stores/app/app-store.service';
import { StoresForPickUpStoreService } from 'src/app/core/modules/pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service';
import { RetValidateCartService } from '../../service/cart/ret-validate-cart.service';
import { ShowModalBoticaService } from '../../service/showModalServices/showModalService.service';
import { ShoppingListStoreService } from '@stores/shopping-list/shopping-list-store.service';
import { ProductDetail } from 'src/app/business/product-detail/models/product-detail-model';
import { GeolocationService } from '../../geolocation/geolocation.service';
import { EBrand } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';
import { LS_ADDRESS_SELECTED } from '../../service/local-storage/constants/address.constant';

@Component({
  selector: 'fp-product-seller-in-shoppingcart-dialog',
  templateUrl: './product-seller-in-shoppingcart-dialog.component.html',
  styleUrls: ['./product-seller-in-shoppingcart-dialog.component.sass']
})
export class ProductSellerInShoppingcartDialogComponent implements OnInit {

  isLoading: boolean
  isMifaBrand = environment.brand === EBrand.mifarma;

  get product() {
    return this.dialogData?.product
  }

  get isResponsive() {
    return this.dialogData?.responsive
  }

  constructor(
    private retValidateCartService: RetValidateCartService,
    private storesForPickUpStore: StoresForPickUpStoreService,
    private appStoreService: AppStoreService,
    private shoppingListStore: ShoppingListStoreService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ProductOutOfStockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private showModalBoticaService: ShowModalBoticaService,
    private geolocation: GeolocationService
  ) { }

  get cartWithoutSeller() {
    return this.shoppingListStore.getLocalStorageShoppingCartList()?.filter((product: ProductDetail) => !product.sellerId);
  }

  get product4Shopping() {
    const {
      id: productId,
      presentationIdSelected
    } = this.product

    return {
      productId,
      presentationIdSelected,
      quantity: 1
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  deleteAndClose() {
    this.isLoading = true;
    setTimeout(() => {
      const currentAddreses = null;
      const selectedDrugstore = JSON.parse(this.appStoreService.getStorageItem('drugstoreSelected'));
      if (selectedDrugstore) {
        this.shoppingListStore.setShoppingList(this.cartWithoutSeller);
        const isDelete = true;
        this.retValidateCartService.updateAndValidateCart(currentAddreses, selectedDrugstore, isDelete).subscribe((validateCart: any) => {
          if (validateCart.statusCode === "1") {
            let drugstore = JSON.parse(this.appStoreService.getStorageItem('drugstoreSelected'));
            if (drugstore) {
              this.retValidateCartService.storeProcessType("RET");
              this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, 'false');
              this.appStoreService.setStorageItem('drugstoreSelected', JSON.stringify(drugstore));
              this.storesForPickUpStore.setSelectedDrugstore(drugstore);
              this.storesForPickUpStore.setDrugstore.emit(drugstore);
              this.dialogRef.close();
              window.location.reload();
            }
          }else{
            this.geolocation.loadGeolocation();
            this.shoppingListStore.setShoppingList(this.cartWithoutSeller);
            this.dialogRef.close();
            this.openDialogDrugstore(this.product, this.isResponsive);
          }
        });
      } else {
        this.geolocation.loadGeolocation();
        this.shoppingListStore.setShoppingList(this.cartWithoutSeller)
        this.dialogRef.close();
        this.openDialogDrugstore(this.product, this.isResponsive)
      }
    }, 1500);
  }


  openDialogDrugstore(product, responsive?: boolean) {
    setTimeout(() => {
      if (responsive) this.showModalBoticaService.showModalResponsiveService(product);
      else this.showModalBoticaService.showModalDesktopService(product, null, null, false);
      this.showModalBoticaService.fromProduct.next(true)
      this.retValidateCartService.intentIsFromProduct = product.id
    }, 500);
  }

  selectLocation() {
    if (this.dialogData.address) {
      // this.storesForPickUpStore.resetSelectedDrugstore();
      // this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "true");
      // this.retValidateCartService.storeProcessType(RET3_RAD_STORAGE_KEY);
      this.dialog.closeAll();
      // window.location.reload();
    } else {
      this.openAddressDialog();
    }
  }

  openAddressDialog() {
    this.retValidateCartService.openModalAddressList()
  }
}
