<div class="select-time-content pb-xs-3 pb-md-5"
  [ngClass]="{'clickable': !selected, 'disable': disabled || !service.enabled}"
  (click)="selectOption()">
  <fp-border-container [selected]="selected">
    <div class="p-3 p-md-5">
      <div class="d-flex">
        <div class="radio-control-content">
          <ng-content select="[payment-type-radio]"></ng-content>
        </div>
        <div class="ml-xs-3 ml-md-4 service-header">
          <div class="service-title d-flex align-items-center">
            {{ service.title }}
            <!-- <span *ngIf="service.new" class="new_label">NUEVO</span> -->
            <span class="price" [ngClass]="{'price-color': showTitleBlack}">
              {{ service.price }}
            </span>
          </div>
          <div class="sub-label mt-md-3 mt-xs-2">
            {{ service.subtitle }}
          </div>
        </div>
      </div>
      <div [hidden]="!showDetails">
        <ng-content select="[selects-options]"></ng-content>
        <ng-content select="[express-service]"></ng-content>
      </div>
    </div>
  </fp-border-container>
</div>
