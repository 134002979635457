import { createReducer, createSelector, on } from '@ngrx/store';
import { PlacesSearch, ReverseAddress } from '../../modules/here-maps/interfaces/places-search';
import { setDirection, setHereAddressMarked, setNewAddressModal } from '../actions/app.actions';

export interface NewAddressModal {
  direction: string;
  hereAddressMarked: PlacesSearch<ReverseAddress>;
}

export interface AppState {
  newAddressModal: NewAddressModal;
}

export const initialState: AppState = {
  newAddressModal: {
    direction: '',
    hereAddressMarked: {
      title: '',
      id: '',
      resultType: '',
      address: { label: '', countryCode: '', countryName: '', state: '', county: '', city: '', district: '', street: '', postalCode: '' },
      position: { lat: 0, lng: 0 },
      access: [{ lat: 0, lng: 0 }],
      distance: 0,
      categories: [{ id: '', name: '', primary: true }],
    },
  },
};

const _appReducer = createReducer(
  initialState,
  on(setDirection, (state, props) => ({
    ...state,
    newAddressModal: {
      ...state.newAddressModal,
      direction: props.direction,
    },
  })),
  on(setHereAddressMarked, (state, props) => ({
    ...state,
    newAddressModal: {
      ...state.newAddressModal,
      hereAddressMarked: props.hereAddressMarked,
    },
  })),
  on(setNewAddressModal, (state, props) => ({
    ...state,
    newAddressModal: props.newAddressModal,
  }))
);

export function appReducer(state: AppState, action) {
  return _appReducer(state, action);
}

export const selectNewAddressModal = (state: AppState) => state.newAddressModal;

export const selectNewAddressModalDirection = createSelector(
  selectNewAddressModal,
  (state: NewAddressModal) => state.direction
);
