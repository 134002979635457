import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrandAccessDirective } from "./access/brand-access.directive";
import { EllipsisDirective } from "./ellipsis/ellipsis.directive";
import { NoSpaceDirective } from "./no-space/no-space.directive";
import { OptimizeImageDirective } from "./optimize-image/optimize-image.directive";
import { ValidateFormDirective } from "./validate-form/validate-form.directive";
import { ValueComponentsDirective } from "./value-components/value-components.directive";

const DIRECTIVES = [
	OptimizeImageDirective,
	ValidateFormDirective,
	EllipsisDirective,
	BrandAccessDirective,
	ValueComponentsDirective,
	NoSpaceDirective
];

@NgModule({
	declarations: [...DIRECTIVES],
	exports: [...DIRECTIVES],
	imports: [CommonModule]
})
export class DirectivesModule {}
