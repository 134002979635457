import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgmManagerService } from './services/agm-manager.service';
import { CustomAgmCoreModule } from '../../custom-agm-core/custom-agm-core.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomAgmCoreModule
  ],
  providers: [
    AgmManagerService,
  ]
})
export class PickUpServicesForAgmModule { }
