import { IProductAliviamed } from '@models/product-default/product-default.interface';
import { ProductPresentation } from './product-presentation.model';

export class Product {
	public id: string;
	public name: string;
	public favorite: boolean;
	public fractionalMode: boolean;
	public defaultPresentation: number;
	public statusId: number;
	public slug: string;
	public stock: number;
	public stockRet: number;
	public fractionalStock: number;
	public fractionalRetStock: number;
	public presentations: Array<ProductPresentation>;
  public productAliviamed?: IProductAliviamed;
}



