<div class="row m-0" [ngClass]="{'picker-rounded': rounded}">
  <div class="col-4">
    <fp-product-picker
      (clicked)="subtractPicker()"
      [pickerDisabled]="productQuantity === 0 ? true:false"
      pickerSize="small"
      [quantity]="productQuantity"
      pickerIcon="{{iconMinus}}">
    </fp-product-picker>
  </div>

  <div class="col display-center">
    <span class="quantity-text label"
          [class.text-dark-gray]="productStatus !== eProductStatus.available && isSeller">
			{{productQuantity}}
		</span>
  </div>

  <div class="col-4 display-horizontal-end">
    <fp-product-picker
      (clicked)="addPicker()"
      [pickerDisabled]="!addPickerEnable"
      pickerSize="small"
      pickerIcon="plus"
      buttonPickerId="btn--increment-quantity">
    </fp-product-picker>
  </div>
  <div class="col text-error" *ngIf="productStatus === 24">
    Modifica la cantidad o elimínalo
  </div>
</div>
