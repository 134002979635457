import { Injectable } from '@angular/core';
import { FromProductToShoppingCartSalesforceRequest } from 'src/app/shared/models/shopping-cart-salesforce/shopping-cart-salesforce-request.model';
import { ShoppingListSalesforceStoreService } from 'src/app/shared/stores/shopping-list-salesforce/shopping-list-salesforce-store.service';
import { SalesforceCollectService } from 'src/app/shared/salesforce/salesforce-collect.service';

@Injectable({
  providedIn: 'root'
})
export class ProductSalesforceService {

  constructor(
    private shoppingListSalesforceStoreService: ShoppingListSalesforceStoreService,
    private salesforceCollect: SalesforceCollectService
  ) {
  }

  public addProduct(item: string, unique_id: string, quantity: number, price: number) {
    const productRequest = new FromProductToShoppingCartSalesforceRequest(item, unique_id, quantity, price);
    const staticShoppingListSalesforce = [
      ...this.shoppingListSalesforceStoreService.staticShoppingListSalesforce,
      productRequest
    ];
    this.shoppingListSalesforceStoreService.setShoppingListSalesforce(staticShoppingListSalesforce);
    this.salesforceCollect.trackCart(productRequest);
  }

  public deleteProduct(productId: string) {
    const updatedProductList = this.shoppingListSalesforceStoreService.staticShoppingListSalesforce
      .filter(product => product.unique_id !== productId);
    this.shoppingListSalesforceStoreService.setShoppingListSalesforce(updatedProductList);
  }

  public updateProduct(item: string, unique_id: string, quantity: number, price: number) {
    const productRequest = new FromProductToShoppingCartSalesforceRequest(item, unique_id, quantity, price);
    const updatedProductItem = this.shoppingListSalesforceStoreService.staticShoppingListSalesforce
      .map(shoppingItem => {
        if (shoppingItem.unique_id === productRequest.unique_id) {
          shoppingItem.price = productRequest.price;
          shoppingItem.quantity = productRequest.quantity;
        }
        return shoppingItem;
      });
    this.shoppingListSalesforceStoreService.setShoppingListSalesforce(updatedProductItem);
  }

}
