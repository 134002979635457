import { Banner } from 'src/app/business/savings-wallet/models/loginTypeItems.model';
import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { ContentfulEntry } from '@models/contentful/contentful.model';
import { IContentfulBanner } from './contentful-banner.model';
import { Entry } from 'contentful';


export enum EContentfulShowcasePosition {
  top = 'top',
  bottom = 'bottom',
}

export enum EContenfulShowcaseApplication {
  WEB = 'WEB',
  APP = 'APP',
}
export interface IContentfulShowCase {
  title: string;
  keyword: string;
  position: EContentfulShowcasePosition;
  searchNotFound: boolean;
  application?: EContenfulShowcaseApplication;
  banners?: Entry<IContentfulBanner>;
  link: string;
}

export class ContentfulShowCase {
  title: string;
  keyword: string;
  application?: EContenfulShowcaseApplication;
  position: EContentfulShowcasePosition;
  searchNotFound: boolean;
  banners?: ContentfulEntry<IContentfulBanner>;
  link: string;
  constructor(iContentfulShowCase: IContentfulShowCase) {
    this.title = iContentfulShowCase.title || '';
    this.keyword = iContentfulShowCase.keyword || '';
    if (iContentfulShowCase.position) { this.position = iContentfulShowCase.position; }
    this.searchNotFound = iContentfulShowCase.searchNotFound || false;
    this.link = iContentfulShowCase.link || '';
    if (iContentfulShowCase.application) { this.application = iContentfulShowCase.application; }
    if (iContentfulShowCase.banners) { this.banners = new ContentfulEntry(iContentfulShowCase.banners); }
  }
}


export const CContentfulShowCase = {
  id: CONTENTFUL_PATH.contentTypeIds.showcase
};
