import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrand } from "@parameters/access/brand.parameter";

@Component({
	selector: "fp-disclaimer-generic",
	templateUrl: "./disclaimer-generic.component.html",
	styleUrls: ["./disclaimer-generic.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class DisclaimerGenericComponent {
	@Input() title: string;
	@Input() type: string = "";
	text: string = "Tu carrito tiene uno o más productos controlados y requiere receta médica.";
	isMifarma = "";
	constructor() {
		this.isMifarma = environment.brand;
		this.text = JSON.parse(localStorage.getItem("controllated-sale")).onBoardingRecipeAlert;
	}

	get getIconBrand() {
		return this.isMifarma === "inkafarma" ? "recipe-ikf" : "recipe-mf";
	}
}
