<div class="stores-for-ret"
  [ngClass]="{
    'stores-for-ret--disabled': isDisableChooseStore,
    'stores-for-ret--desktop': !isResponsive,
    'stores-for-ret--empty': withoutButton && modalStatus === ALL_MODAL_STATUS.EMPTY,
		'custom-height': customClass
  }"
  [ngStyle]="{'maxHeight': (withoutButton && modalStatus !== ALL_MODAL_STATUS.OK) ? '' : '340px'}"
  [formGroup]="form">
  <div *ngIf="modalStatus === ALL_MODAL_STATUS.LOADING">
    <div class="row">
      <div class="offset-1 col-10">
        <ng-container *ngFor="let item of [0, 1]" class="store-address">
          <div class="row mt-5">
            <div class="col-8">
              <fp-basic-skeleton height="20"></fp-basic-skeleton>
            </div>
            <div class="offset-2 col-2">
              <fp-basic-skeleton height="25"></fp-basic-skeleton>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-9">
              <fp-basic-skeleton height="20"></fp-basic-skeleton>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-7">
              <fp-basic-skeleton height="25"></fp-basic-skeleton>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="store-for-ret"
    *ngFor="let store of stores; let index = index"
    [ngClass]="{'mb-3': isResponsive}"
    [hidden]="!(modalStatus === ALL_MODAL_STATUS.OK)"
    #drugstoreItem>
    <fp-store-for-ret-radio-button-v2
      (selectRadioOption)="selectRadioOption(store.id)"
      [storeToChoose]="store"
      class="store-for-ret"
      [selected]="selectedStoreControl.value === store.id"
      [isResponsive]="isResponsive">
      <div input-radio-button #inputRadioButton>
        <fp-radio-control
          [radioOptionValue]="store.id"
          formControlName="selectedDrugstore"
          [name]="'store-for-ret'"
          [id]="'ctrl--store-' +index">
        </fp-radio-control>
      </div>
    </fp-store-for-ret-radio-button-v2>
  </div>

  <div class="there-is-no-address"
    *ngIf="modalStatus === ALL_MODAL_STATUS.IS_PENDING">
    <div class="custom-icon mb-4">
      <fp-icon iconName="world-marker" [iconSize]="48"></fp-icon>
    </div>
    <div class="custom-message heading-2">
      Para mostrarte las boticas cerca permítenos saber tu ubicación.
    </div>
  </div>
  <div class="there-is-no-address"
    *ngIf="modalStatus === ALL_MODAL_STATUS.NO_ONE">
    <div class="custom-icon mb-4">
      <fp-icon iconName="hand-pointing-up" [iconSize]="48"></fp-icon>
    </div>
    <div class="custom-message heading-2">
      Busca tu botica favorita por dirección, distrito o provincia.
    </div>
  </div>
  <div class="row " *ngIf="modalStatus === ALL_MODAL_STATUS.EMPTY">
    <div class="col">
      <div class="drugstores-list__empty">
        <img src="../../../../../../assets/images/icons/sad.png" alt="sad">
        <p>
          Lo sentimos, no encontramos boticas cercanas
          <span>Intenta cambiando la dirección </span>
        </p>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 mt-3" [ngClass]="{'col-lg-6': withoutButton}">
          <fp-button-primary buttonDisplay="block" (click)="closeModal()">
            Entendido
          </fp-button-primary>
        </div>
      </div>
    </div>
  </div>
</div>
