import { Injectable } from "@angular/core";
export class ShapeClass {
  originX: number;
  originY: number;
  height: number;
  width: number;
}

@Injectable()
export class OrderBy {
  id: number;
  name: string;
  sort: string;
  order: string;
  value?: string;
}

@Injectable()
export class Filter {
  id: number;
  name: string;
  main?: boolean;
  row?: Array<Filter>;
  keyword?: string;
  value?: { min: number; max: number };
  checked?: boolean;
}

@Injectable()
export class Breadcrumbs {
  id: number;
  name: string;
  keyword: string;
  queryParams?: any;
}

export class Keyword {
  "keyword": string;
}
