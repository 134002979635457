import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fp-ghost-element',
  templateUrl: './ghost-element.component.html',
  styleUrls: ['./ghost-element.component.sass']
})
export class GhostElementComponent implements OnInit {

  constructor() { }

  @Input() width: number;
  @Input() height: number;

  ngOnInit() {
  }

}
