import { InputComponent } from '../input/input.component';
import { Component, Input, ContentChild, AfterContentInit } from '@angular/core';

@Component({
  selector: 'fp-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.sass']
})
export class InputMaskComponent implements AfterContentInit {
  @ContentChild(InputComponent, { static: false }) input: InputComponent;
  @Input() mask: string;
  @Input() value: string;
  show = false;

  ngAfterContentInit(): void {
    this.setValue();
  }

  toggle() {
    const show = !this.show;
    show ?  this.resetValue() : this.setValue();
    this.show = show;
  }

  setValue() {
    this.input.inputFormGroup.get(this.input.inputFormControlName).setValue(this.value);
  }

  resetValue() {
    this.input.inputFormGroup.get(this.input.inputFormControlName).setValue(null);
  }
}
