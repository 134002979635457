<div class="main-login-modal modal-login-container">
  <fp-section-container-title-login
    [title]="'Iniciar sesión'"
    [iconName]="'close'"
    (onclick)="closeModal()"
  >
  </fp-section-container-title-login>

  <fp-sub-section-container>
    <div class="content" [formGroup]="mainLoginForm.form">
      <div class="mb-4 mt-3 font-VAGRoundedNexxt-SemiBold">
        Ingresa con tu correo
      </div>
      <fp-input-without-title-control
        [errorMessage]="mainLoginForm.emailControl.emailAddressError"
      >
        <input
          id="email"
          formControlName="email"
          class="w-100 input label"
          #inputControl
          placeholder="Correo electrónico"
          maxlength="50"
          autocomplete="off"
        />
      </fp-input-without-title-control>
      <fp-input-password>
        <input
          id="password"
          formControlName="password"
          class="w-100 input label"
          #typeControl
          placeholder="Contraseña"
          (keyup.enter)="simpleLogin()"
        />
      </fp-input-password>

      <div class="mb-5 mt-4">
        <fp-link-buttom
          [buttonText]="'¿Olvidaste tu contraseña?'"
          [id]="'forgotPassword'"
          (onclick)="forgotPassword()"
        >
        </fp-link-buttom>
      </div>

      <div class="mb-4 mt-3">
        <fp-button-primary
          id="btnLoginEmailMifarma"
          buttonId="btnLoginEmailMifarma"
          buttonDisplay="block"
          (click)="simpleLogin()"
          [buttonDisabled]="!mainLoginForm.form.valid || isLoggingIn"
        >
          Ingresar
        </fp-button-primary>
      </div>

      <div class="text-center mb-3">
        <fp-link-buttom
          [buttonText]="'Crear cuenta'"
          [linkSizeClass]="'link-size'"
          [id]="'register'"
          (onclick)="registerModal()"
        >
        </fp-link-buttom>
      </div>
    </div>
  </fp-sub-section-container>
</div>
