import { Asset } from 'contentful';
import { CONTENTFUL_PATH } from 'src/app/shared/parameters/endpoints';
import { ContentfulAsset } from '../../../shared/models/contentful/contentful.model';

export interface IContentfulBannerList {
  description: string;
  icon: Asset;
  image: Asset;
  links: any;
  template: string;
  title: string;
}

export class ContentfulBannerList {
  description: string;
  icon: string;
  image: string;
  links: String;
  template: string;
  title: string;

  constructor(iContentfulBannerList: IContentfulBannerList) {
    this.description = iContentfulBannerList.description;
    this.icon = new ContentfulAsset(iContentfulBannerList.icon).asset;
    this.image = new ContentfulAsset(iContentfulBannerList.image).asset;
    this.links = iContentfulBannerList.links;
    this.template = iContentfulBannerList.template;
    this.title = iContentfulBannerList.title;
  }
}

export class ContentfulLinks {
  asset: string;

  constructor(asset: Asset) {
    if (!!asset.fields) {
      this.asset = asset.fields.file.url;
    } else if (!asset.fields && asset.sys) {
      this.asset = './assets/images/product/error-image.svg';
    }
  }
}

export const CContentfulCallToAction = {
  id: CONTENTFUL_PATH.contentTypeIds.callToAction,
};
