import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "@environments/environment";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { NewRegisterStoreService } from "@stores/user/new-register-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { AuthImplementService } from "src/app/shared/implements/auth/auth-implement.service";
import { RegisterResponse } from "src/app/shared/models/register/register.model";
import { EmailVerificationRequest } from "src/app/shared/models/register/user-register-request.model";
import {
  AUTH_ORIGIN,
  AUTH_PROVIDER,
  COMPANY_ID,
  USER_REGISTRATION_ERROR_CODE,
} from "src/app/shared/parameters/global";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { RegisterInfoTypeStoreService } from "src/app/shared/stores/user/register-info-type-store.service";
import { UserInfoStoreService } from "src/app/shared/stores/user/user-info-store.service";
import { MainLoginFormService } from "../../../login/login-forms/main-login-form.service";
import { ForgotPasswordModalService } from "../../../login/login-services/forgot-password-modal-service.service";
import { LoginModalsManagerHelperService } from "../../../login/login-services/login-modals-manager-helper.service";
import { LoginModalsManagerService } from "../../../login/login-services/login-modals-manager.service";
import { SnackbarAlertService } from "../../../snackbar/snackbar-alert.service";
import { RegisterFormService } from "../../register-forms/modal-register-form.service";
import { MainRegisterModalManagerService } from "../../register-services/main-register-modal-manager.service";
import { RegisterImplementService } from "../../register-services/register-implement.service";
import { RegisterModalsManagerHelperService } from "../../register-services/register-modals-manager-helper.service";
import { RegisterModalsMaganerService } from "../../register-services/register-modals-manager.service";
import { RegistrationValidationService } from "../../register-services/registration-validation.service";
import { MainRegisterStoreService } from "../../register-stores/main-register-store.service";
import {
  NAVIGATION_URL,
  USER_SOURCE_LOGIN,
} from "./../../../../../shared/parameters/global";

@Component({
  selector: "fp-main-register-modal",
  templateUrl: "./main-register-modal.component.html",
  styleUrls: ["./main-register-modal.component.sass"],
})
export class MainRegisterModalComponent implements OnInit {
  orderTaker = OrderTakerValidator.isOrderTakerEnv();
  public isMifaBrand: boolean = this.orderTaker
    ? EBrand[EBrandAsset[environment.brand]] == EBrand.mifarma
    : EBrand[environment.brand] == EBrand.mifarma;
  public showEmailAndPassword = false;
  public showEmailAndRegister = false;
  private message =
    "El correo o contraseña que ingresaste es inválido. Intenta nuevamente o recupera tu contraseña.";

  public isLoggingIn = false;
  public registrationErrorCode = USER_REGISTRATION_ERROR_CODE;

  constructor(
    public _matDialogRef: MatDialogRef<MainRegisterModalComponent>,
    public authImplementService: AuthImplementService,
    public mainLoginForm: MainLoginFormService,
    public userInfoStore: UserInfoStoreService,
    private mainRegisterStore: MainRegisterStoreService,
    public forgotPasswordModalService: ForgotPasswordModalService,
    private snackBarAlert: SnackbarAlertService,
    private _appStoreService: AppStoreService,
    private newRegisterStore: NewRegisterStoreService,
    private registrationValidation: RegistrationValidationService,
    public registerModalsMaganer: RegisterModalsMaganerService,
    private registerModalsManagerHelper: RegisterModalsManagerHelperService,
    private loginModalsManagerHelper: LoginModalsManagerHelperService,
    private loginModals: MainRegisterModalManagerService,
    private registerInfoTypeStore: RegisterInfoTypeStoreService,
    public registerForm: RegisterFormService,
    private registerImplement: RegisterImplementService,
    private loginModalsManagerService: LoginModalsManagerService
  ) {}

  ngOnInit() {
    this.isLoggingIn = false;
    this._appStoreService.sourceLogin = USER_SOURCE_LOGIN.MAIN_LOGIN;
    this._appStoreService.sourceNavigationLogin =
      this._appStoreService.getStorageItem(NAVIGATION_URL);
  }

  ngOnDestroy() {}

  simpleLogin() {
    this.isLoggingIn = true;
    const email = this.mainLoginForm.emailControl.value;
    const password = this.mainLoginForm.passwordControl.value;
    this.authImplementService
      .gmailLogin(email, password)
      .then(() => {
        this.registerInfoTypeStore.setEmailAndPasswordAuthenticationOrigin();
        this.validateUserRegister();
        TaggingService.loginOK("simple login");
        this.closeModal();
      })
      .catch((error) => {
        this.isLoggingIn = false;
        this.snackBarAlert.snackbarAlertError(this.message);
      });
  }

  facebookLogin() {
    TaggingService.loginModal("facebook");
    this.authImplementService.facebookLogin().then((response) => {
      // response apply only for desktop
      if (response) {
        this.registerInfoTypeStore.setProviderAuthenticationOrigin();
        this.newRegisterStore.firstUserLoggedIn = response;
        this.settingStorageAuthProvider(response);
        this.validateUserRegister();
        TaggingService.loginOK("facebook");
        this.closeModal();
      }
    });
  }

  googleLogin() {
    TaggingService.loginModal('google');
    this.authImplementService.googleLogin()
      .then(response => {
        // response apply only for desktop
        if (response) {
          this.registerInfoTypeStore.setProviderAuthenticationOrigin();
          this.newRegisterStore.firstUserLoggedIn = response;
          this.settingStorageAuthProvider(response);
          this.validateUserRegister();
          TaggingService.loginOK('google');
          this.closeModal();
        }
      });
  }

  appleLogin() {
    TaggingService.loginModal("apple");
    this.authImplementService.appleLogin().then((response) => {
      // response apply only for desktop
      if (response) {
        this.registerInfoTypeStore.setProviderAuthenticationOrigin();
        this.newRegisterStore.firstUserLoggedIn = response;
        this.settingStorageAuthProvider(response);
        this.validateUserRegister();
        TaggingService.loginOK("apple");
        this.closeModal();
      }
    });
  }

  settingStorageAuthProvider(response) {
    this._appStoreService.setStorageItem(
      AUTH_PROVIDER,
      response.credential.providerId
    );
  }

  validateUserRegister() {
    this.loginModalsManagerHelper.closeMainLoginModal();
    const subscribe = this.registrationValidation
      .validateUserRegisterOnDB()
      .subscribe((path) => {
        !path ? (path = window.location.pathname) : path;
        if (typeof path === "string" && path.length) {
          this.isLoggingIn = false;
          this._appStoreService.removeSourceLogin();
          this.registrationValidation.handleNavigationMainLoin(path);
        }
        subscribe.unsubscribe();
      });
  }

  openEmailWithPassword() {
    TaggingService.registerModal("crea una cuenta con tu correo");
    this.mainRegisterStore.email = this.registerForm.emailControl.value;
    const validateEmailRequest = new EmailVerificationRequest();
    validateEmailRequest.email = this.registerForm.emailControl.value;
    validateEmailRequest.companyId = COMPANY_ID;
    validateEmailRequest.origin = AUTH_ORIGIN.EMAIL_PASSWORD;
    validateEmailRequest.source = "WEB";
    this.registerInfoTypeStore.setEmailAndPasswordAuthenticationOrigin();
    this.registerImplement
      .saveOrUpdateUser(validateEmailRequest)
      .subscribe((response: RegisterResponse) => {
        if (response.code === this.registrationErrorCode.ERROR_EMAIL_USED) {
          this.loginModalsManagerService.openMainLoginModal();
          this.openAlert();
          this._matDialogRef.close();
        } else {
          TaggingService.registerOK("simple register");
          this.showEmailAndPassword = true;
          this.registerModalsManagerHelper.openNewPasswordModal();
          this.closeModal();
        }
      });
  }

  openRegisterEmail() {
    this.showEmailAndPassword = true;
    TaggingService.loginModal("ingresa con correo electrónico");
  }

  closeModal() {
    this._matDialogRef.close();
    TaggingService.loginModalCerrar();
  }

  logIn() {
    this._matDialogRef.close();
    TaggingService.loginModalCerrar();
    this.registerModalsMaganer.openRegisterModal();
  }

  forgotPassword() {
    TaggingService.loginModal("¿olvidaste tu contraseña?");
    this._matDialogRef.close();
    this.loginModalsManagerHelper.openForgotPasswordModal();
  }

  registerModal() {
    TaggingService.loginModal("regístrate");
    this.showEmailAndRegister = true;
  }

  openAlert() {
    this.snackBarAlert
      .snackbarAlertInfo(`El correo que ingresaste ya se encuentra registrado.
    Inicia sesión o da click en "Olvidaste tu contraseña" para recuperar tu cuenta`);
  }

  openAlertError() {
    this.snackBarAlert.snackBarAlert(
      `El correo que ingresaste es invalido. Por favor
    verifique que este escrito correctamente.`,
      "error"
    );
  }
}
