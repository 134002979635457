// here are the enum parameters. enums allow us to define a set of named constants

export enum VERSIONS {
  DESKTOP = 'desktop',
  RESPONSIVE = 'responsive',
}


export enum STATUS_ORDER {
  SCHEDULED = 'SCHEDULED',
  ON_STORE = 'ON_STORE',
  CONFIRMED = 'CONFIRMED',
  PICKING = 'PICKING',
  PREPARED = 'PREPARED',
  ASSIGNED = 'ASSIGNED',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  READY_FOR_BILLING = 'READY_FOR_BILLING',
  ON_ROUTE = 'ON_ROUTE',
  DELIVERED = 'DELIVERED',
  CONFIRMADA = 'CONFIRMADA',
  ENTREGADO = 'ENTREGADO',
  ENTREGADA = 'ENTREGADA',
  CANCELLED = 'CANCELLED',
  CANCELADO = 'CANCELADO',
  ARRIVED = 'ARRIVED',
  RECHAZADO = 'RECHAZADO',
  RECHAZADA = 'RECHAZADA',
  CHECKOUT = 'CHECKOUT'
}

export enum STATE_ORDER {
  PROGRAMADO = 'PROGRAMADO',
  EN_PREPARACION = 'EN PREPARACIÓN',
  LISTO_PARA_RECOGER = 'LISTO PARA RECOGER',
  CAMINO = 'EN CAMINO',
  CONFIRMADA = 'CONFIRMADA',
  ENTREGADO = 'ENTREGADO',
  CANCELADO = 'CANCELADO',
  RECHAZADO = 'RECHAZADO',
  RECHAZADA = 'RECHAZADA',
}

export enum DELIVERY_SERVICES {
  TEMPORARY = 2,
  INKATRACKER = 3,
  INKATRACKER_LITE = 4,
}

export enum DELIVERY_TYPE {
  RAD = 'RAD',
  RET = 'RET',
}

export enum DELIVERY_TYPE_DESCRIPTION {
  RAD = 'Reparto a domicilio',
  RET = 'Retiro en tienda, ',
}

export enum PAYMENT_METHOD_TYPE {
  ONLINE = 'ONLINE_PAYMENT',
  CASH = 'CASH',
  CARD = 'CARD',
  PAGO_LINEA = 'Pago en línea',
  POS = 'Pago con P.O.S.',
  EFECTIVO = 'Pago en efectivo',
  APP_AGORA = 'App Agora'
}

export enum PAYMENT_METHOD_TYPE_DESCRIPTION {
  ONLINE = 'Pago en línea',
  CASH = 'Pago en efectivo',
  CARD = 'Pago con POS contra entrega',
  APP_AGORA = 'Pago con App Agora'
}

export enum USERS_RESPONSE {
  SUCCESS = '1',
  INFORMATION_INCOMPLETE = '12',
}
export enum COMPANY {
  INKAFARMA = 'IKF'
}
