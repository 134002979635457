import { Injectable } from "@angular/core";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { FilteredProductsRequest } from "../../../../shared/models/filtered-products/filtered-products-request.model";
import { FilteredProductsResponse } from "../../../../shared/models/filtered-products/filtered-products-response.model";
import { FilteredProductsStoreService } from "../store/filtered-products-store.service";
import { FilteredProductsImplementService } from "./filtered-products-implement.service";

@Injectable({
  providedIn: "root",
})
export class FilteredProductsRequestService {

  private productsFilter$: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
  private filteredProductsResponseSubject: BehaviorSubject<FilteredProductsResponse> = new BehaviorSubject(null);
  private filteredProductsRequest: FilteredProductsRequest = new FilteredProductsRequest();

  constructor(
    private _filteredProductsSearchImplement: FilteredProductsImplementService
  ) {}

  private initFilteredProductsRequest() {
    if (
      this.filteredProductsResponseSubject &&
      this.filteredProductsResponseSubject.closed
    ) {
      this.filteredProductsResponseSubject = new BehaviorSubject(null);
    }
  }

  destroyFilteredProductsRequest() {
    this.filteredProductsResponseSubject.unsubscribe();
  }

  public resetFilteredProductsRequest() {
    this.filteredProductsRequest = new FilteredProductsRequest();

    if (OrderTakerValidator.isOrderTakerEnv()) {
      this.filteredProductsRequest.rows = 6;
    }
  }

  public get filteredProductsResponse$(): Observable<FilteredProductsResponse> {
    this.initFilteredProductsRequest();
    return this.filteredProductsResponseSubject.asObservable();
  }

  public get rankingRequest$(): Observable<FilteredProductsResponse> {
    this.initFilteredProductsRequest();
    return this.filteredProductsResponseSubject.asObservable();
  }

  public set rankingRequest(ranking: number) {
    this.filteredProductsRequest.ranking = ranking;
  }

  public set rowsRequest(rows: number) {
    this.filteredProductsRequest.rows = rows;
  }

  public set filteredProductsRequestPagination(page: number) {
    this.filteredProductsRequest.page = page;
    this.getFilteredProductsSearch();
  }

  public setFilteredProductsRequestOrderBy(order: string, sort: string) {
    this.filteredProductsRequest.order = order;
    this.filteredProductsRequest.sort = sort;
    this.filteredProductsRequest.page = 0;
    this.getFilteredProductsSearch();
  }

  public set filteredProductsRequestProductsId(productsId: Array<string>) {
    this.filteredProductsRequest.productsFilter = productsId;
    this.productsFilter$.next(productsId);
    this.getFilteredProductsSearch();
  }

  public get filteredProductsRequestProductsId() {
    return this.productsFilter$.value;
  }

  public filteredProductsRequestProductsId$() {
    return this.productsFilter$.asObservable();
  }

  public setFilteredProductsRequestFilters(
    department: number[],
    categories?: number[],
    subcategories?: number[],
    brands?: number[]
  ) {
    this.filteredProductsRequest.departmentsFilter = department || [];
    this.filteredProductsRequest.categoriesFilter = categories || [];
    this.filteredProductsRequest.subcategoriesFilter = subcategories || [];
    this.filteredProductsRequest.brandsFilter = brands || [];
    this.filteredProductsRequest.page = 0;

    if(this.filteredProductsRequest.productsFilter.length > 0 ){
      this.getFilteredProductsSearch();
    }

    if(this.filteredProductsRequest.departmentsFilter.length > 0){
      this.filteredProductsRequest.sort = "ranking";
      this.filteredProductsRequest.order = "ASC"
      this.getFilteredProductsSearch();
      }
  }

  private getFilteredProductsSearch() {
    this._filteredProductsSearchImplement
      .getFilteredProducts(this.filteredProductsRequest)
      .pipe(take(1))
      .subscribe((filteredProductsResponse: FilteredProductsResponse) => {
        this.filteredProductsResponseSubject.next(filteredProductsResponse);
      });
  }
}
