<div class="store-for-ret"
  (click)="selectOption()"
  [ngClass]="{
    'store-for-ret--responsive': isResponsive,
    'default-border': isResponsive && customBorder === StoreForRetBorders.DEFAULT,
    'selected-store':selected
  }">
  <div class="store-radio-button" *ngIf="inputRadioButton">
    <ng-content select="[input-radio-button]"></ng-content>
  </div>
  <div class="store-for-ret-content">
    <div class="row">
      <div class="col-7 col-xs-8">
        <div class="store-for-ret-title label" [ngClass]="{'label-black': selected }">
          {{ storeToChoose.title }}
        </div>
      </div>
      <div class="col-5 col-xs-4 d-flex justify-content-end">
        <div class="tag-distance">
          {{ storeToChoose.distance }}
          <fp-icon iconSize="6" iconName="white-fill-gps"></fp-icon>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-11 col-md-10">
        <div class="store-for-ret-address mt-2 mb-3 label--2">
          {{ storeToChoose.address }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0">
        <span class="store-for-ret-schedule label-black--3">
          <fp-icon class="mt-x me-1" iconSize="16" [iconName]="getIconName"></fp-icon>
          <span class="ml-1">{{ storeToChoose.schedule }}</span>
        </span>
      </div>
    </div>
  </div>
</div>
