export const DELAY_FOR_PREDICTIVE = 2000;
export const MINIMUN_CHARACTERS_FOR_PREDICTIVE = 10;
export const SUGGESTED_MESSAGE_FOR_PREDICTIVE = 'Solo te mostramos las boticas con el stock completo de tu pedido.';
export const MESSAGE_FOR_REQUIRED_GEOLOCATION = 'Para mostrarte las boticas cerca permítenos saber tu ubicación.';
export const MESSAGE_FOR_DIRECT_SEARCH = 'Busca tu botica favorita por dirección, distrito o provincia.';
export const DEFAULT_ZOOM_FOR_MAP = 14;
export const THERE_IS_NO_STORE_WITH_STOCK = 'No hay boticas con stock por aquí, realiza otra búsqueda.';

export const FIRST_OPTION_ID = -1;
export const LAST_OPTION_ID = -2000;

// key for configuration object
export const PICK_UP_IN_STORE_CONFIG_KEY = 'pickUpInStoreConfiguration';
