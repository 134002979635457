import { FormControl, Validators } from '@angular/forms';
import { CONFIG_USER_NOT_REGISTERED } from '../parameters/enums';
import { VALID_DOCUMENT_TYPE, VALID_GENDERS, VALID_SEXES } from '../parameters/global';
import * as moment from 'moment';

const validCharacters = /^[A-Za-zÀ-ÿ\u00f1\u00d1 ]+$/;
const validNumbers = /^[0-9]*$/;
const VALID_CHARACTERS_AND_NUMBERS = /^[A-Za-z0-9]*$/;
const VALID_QUANTITY = /^[1-9]{1}[0-9]*$/;
const VALID_FORMATTED_DATE = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

export class GenericValidator extends Validators {
  static validateCharacters(
    control: FormControl
  ): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const matches = validCharacters.test(control.value);
      return matches ? null : { validateCharacters: matches };
    } else {
      return null;
    }
  }

  static validateValueDocumentType(
    control: FormControl
  ): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const matches = VALID_DOCUMENT_TYPE.includes(control.value);
      return matches ? null : { validateValueDocumentType: matches };
    } else {
      return null;
    }
  }

  static validateNumbers(control: FormControl): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const matches = validNumbers.test(control.value);
      return matches ? null : { validateNumbers: matches };
    } else {
      return null;
    }
  }

  static validateQuantity(
    control: FormControl
  ): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const matches = VALID_QUANTITY.test(control.value);
      return matches ? null : { validateQuantity: matches };
    } else {
      return null;
    }
  }

  static numberPhoneValidation(
    control: FormControl
  ): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const firstNumber = control.value.substr(0, 1);
      if (
        +firstNumber !==
        CONFIG_USER_NOT_REGISTERED.FIRST_RIGHT_NUMBER_NUMBER_PHONE
      ) {
        return { numberPhoneValidation: false };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static validateGender(
    control: FormControl
  ): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const matches = VALID_GENDERS.includes(control.value);
      return matches ? null : { validateGender: matches };
    } else {
      return null;
    }
  }

  static validateSex(control: FormControl): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const matches = VALID_SEXES.includes(control.value);
      return matches ? null : { validateSex: matches };
    } else {
      return null;
    }
  }

  static validateCharactersAndNumbers(control: FormControl): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const matches = VALID_CHARACTERS_AND_NUMBERS.test(control.value);
      return matches ? null : { validateCharactersAndNumbers: matches };
    } else {
      return null;
    }
  }

  static validateDni(control: FormControl): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const matches = validNumbers.test(control.value);
      return matches ? null : { validateDni: matches };
    } else {
      return null;
    }
  }

  static validateBeforeCurrentYear(
    control: FormControl
  ): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const currentYear = moment().year();
      const controlYear = moment(control.value, 'YYYY').year();
      const matches = currentYear >= controlYear;
      return matches ? null : { 'validateBeforeCurrentYear': matches };
    } else {
      return null;
    }
  }

  static validateBeforeCurrentDate(
    control: FormControl
  ): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const currentDate = moment();
      const controlDate = moment(control.value, 'YYYY-MM-DD');
      const matches = controlDate.isBefore(currentDate);
      return matches ? null : { 'validateBeforeCurrentDate': matches };
    } else {
      return null;
    }
  }

  static validateFormattedDate(control: FormControl): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      const matches = VALID_FORMATTED_DATE.test(control.value);
      return matches ? null : { 'validateFormattedDate': matches };
    } else {
      return null;
    }
  }

  static validateAdult(control: FormControl): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0 && VALID_FORMATTED_DATE.test(control.value)) {
      const currentDate = moment();
      const controlDate = moment(control.value);
      const isAdult = moment.duration(currentDate.diff(controlDate)).years() >= 18;
      return isAdult ? null : { 'validateAdult': true };
    } else {
      return null;
    }
  }

  static roundToTwoDecimals(num: number) {
    return +(Math.round(Number(num + 'e+2')) + 'e-2');
  }
}
