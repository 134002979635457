import {Component, Input} from '@angular/core';

@Component({
  selector: 'fp-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent {
  @Input()
  buttonId = 'default';

  @Input()
  buttonClass: string;

  @Input()
  buttonStyle: { [klass: string]: any; } | null;

  @Input()
  buttonType: 'button' | 'submit' | 'reset' = 'button';

  @Input()
  buttonDisabled = false;

  
}
