import { Component, OnInit, Input, OnChanges, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'fp-custom-router-link',
  templateUrl: './custom-router-link.component.html',
  styleUrls: ['./custom-router-link.component.sass']
})
export class CustomRouterLinkComponent implements OnInit, OnChanges {
  @Input()
  link: string;
  @Input()
  linkTarget: '_blank' | '_parent' | '_self' | '_top' = '_self';

  public linkRouter: string;
  public queryParams: string;
  public href: string;

  public isRouterLink: boolean;
  public readonly DEEPLINK = 'deeplinks';

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.href = document.location.href;
  }

  ngOnInit() { }

  ngOnChanges() {
    // this.isRouterLink = this.link.includes(this.href);
    if (this.link) {
      this.settingRouterLink();
      this.settingQueryParams();
    }
  }

  settingQueryParams() {
    const isDeeplink = this.link.includes(this.DEEPLINK);
    if (isDeeplink) {
      const newValue = this.link.split(this.DEEPLINK + '?');
      this.queryParams = JSON.parse('{"' + decodeURI(newValue[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
    }
  }

  settingRouterLink() {
    this.isRouterLink = true;

    const isDeeplink = this.link.includes(this.DEEPLINK);
    if (this.link.includes(this.href)) {
      if (isDeeplink) {
        this.linkRouter = this.DEEPLINK;
        this.isRouterLink = false;
      } else {
        this.linkRouter = this.link.split(this.href)[1];
      }
    } else {
      this.isRouterLink = false;
    }
  }

}
