export interface IGLatLng {
  lat: number;
  lng: number;
}

export interface IGBounds {
  sw_peru: IGLatLng;
  ne_peru: IGLatLng;
}

export interface IGoogleMapConfig {
  peru_bounds: IGBounds;
  default_center: IGLatLng;
}

export class GLatLng {

  public lat: number;
  public lng: number;

  constructor(latlng: IGLatLng) {
    this.lat = latlng.lat || 0;
    this.lng = latlng.lng || 0;
  }
}

export class GBounds {

  public sw_peru: GLatLng;
  public ne_peru: GLatLng;

  constructor(bounds: IGBounds) {
    this.sw_peru = bounds.sw_peru ? new GLatLng(bounds.sw_peru) : {} as GLatLng;
    this.ne_peru = bounds.ne_peru ? new GLatLng(bounds.ne_peru) : {} as GLatLng;
  }
}

export class GoogleMapConfig {

  public peru_bounds: GBounds;
  public default_center: GLatLng;

  constructor(mapConfig: IGoogleMapConfig) {
    this.peru_bounds = mapConfig.peru_bounds ? new GBounds(mapConfig.peru_bounds) : {} as GBounds;
    this.default_center = mapConfig.default_center ? new GLatLng(mapConfig.default_center) : {} as GLatLng;
  }
}
