import { CdkStepper } from "@angular/cdk/stepper";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "@environments/environment";
import { Subscription } from "rxjs";
import { UserControlledFormService } from "./form/user-controlled-form.service";
import { UserInfoStoreService } from "@stores/user/user-info-store.service";
import { RecipeControlledFormService } from "./form/recipe-controlled-form.service";
import { CartControlledStoreService } from "../../services/cart-controlled-store.service";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { BreakpointBehavior } from "src/app/shared/behaviors";
import { BREAKPOINTS } from "@parameters/global";
import { EBrand } from "@parameters/access/brand.parameter";

@Component({
	selector: "fp-cart-controlled-form-modal",
	templateUrl: "./cart-controlled-form-modal.component.html",
	styleUrls: ["./cart-controlled-form-modal.component.sass"],
	providers: [UserControlledFormService]
})
export class CartControlledFormModalComponent implements OnInit, OnDestroy {
	@ViewChild("stepper", { static: true }) stepper: CdkStepper;
	public documentTypeOptions: Array<string> = ["1", "2", "3"];
	documentType = "1";
	totalSteps = 2;
	stepSelected = 1;
	public isMifaBrand = environment.brand === EBrand.mifarma;

	formActive: FormGroup;
	loader: boolean = true;
	street: string;
	subscriptions: Subscription[] = [];
	recipe = null;
	public isCheckedSwitch = true;
	showInfoControlledProducts = false;
	public isDesktop = false;

	constructor(
		public _userControlledFormService: UserControlledFormService,
		public _recipeControlledFormService: RecipeControlledFormService,
		public dialogRef: MatDialogRef<CartControlledFormModalComponent>,
		public userInforStore: UserInfoStoreService,
		private _cartControlledStoreservice: CartControlledStoreService,
		private breakpointBehavior: BreakpointBehavior
	) {}

	ngOnDestroy(): void {}

	ngOnInit() {
		this._cartControlledStoreservice.recipe$.subscribe((recipe) => {
			this.recipe = recipe;
		});

		const breakpointSub = this.breakpointBehavior
			.mediaBreakpointUpObserver(BREAKPOINTS.xxl)
			.subscribe((value) => (this.isDesktop = value.matches));
	}

	onPrevious() {
		this.stepper.previous();
		this.stepSelected = 1;
		this._recipeControlledFormService.form.reset();
		TaggingService.backModalControlledStepTwo();
	}

	onNext() {
		TaggingService.nextModalControlledStepOne();
		this._cartControlledStoreservice.setUserData(this._userControlledFormService.form.value);		
		this.stepper.next();
		this.stepSelected = this.stepper.selectedIndex + 1;
	}

	onClose() {
		this.stepSelected == 1 ? TaggingService.closeModalControlledStepOne() : TaggingService.closeModalControlledStepTwo();
		this.dialogRef.close();
	}

	getIconBrand(iconName) {
		const brand = environment.brand === EBrand.inkafarma ? "ikf" : "mf";
		return `${iconName}-${brand}`;
	}

	clickSwitch($event: any) {
		this._userControlledFormService.form.reset();
	}

	get isFormUserValid() {
		return !this._userControlledFormService.form.valid;
	}
	get isFormRecipeValid() {
		return !this._recipeControlledFormService.form.valid || !this.recipe;
	}

	onSave() {
		this._cartControlledStoreservice.recipe$.subscribe((recipe) => {
			TaggingService.nextModalControlledStepTwo();
			this.dialogRef.close(true);
		});
	}

	hideInfo() {
		this.showInfoControlledProducts = false;
	}

	showTooltip() {
		this.showInfoControlledProducts = true;
		TaggingService.formTooltipShow(this.stepSelected);
	}

	get titleForm() {
		return this.isDesktop ? "Información de la receta médica" : "Información de la receta";
	}
}
