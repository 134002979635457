import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

/**
 * the modalManagerAction value should be the exactly name of the RegisterModalsMaganerService method
 */
export interface IActionModal {
  modalManagerAction: string;
  data?: any;
}

// open/close simple methods
const OPEN_REGISTER_MODAL_ACTION: IActionModal = {
  modalManagerAction: "openRegisterModal",
};
const CLOSE_REGISTER_MODAL_ACTION: IActionModal = {
  modalManagerAction: "closeRegisterModal",
};
const OPEN_REGISTER_AFILIACION_MODAL_ACTION: IActionModal = {
  modalManagerAction: "openRegisterAfiliacionModal",
};
const CLOSE_REGISTER_AFILIACION_MODAL_ACTION: IActionModal = {
  modalManagerAction: "closeRegisterAfiliacionModal",
};
const OPEN_NEW_PASSWORD_MODAL_ACTION: IActionModal = {
  modalManagerAction: "openNewPasswordModal",
};
const CLOSE_NEW_PASSWORD_MODAL_ACTION: IActionModal = {
  modalManagerAction: "closeNewPasswordModal",
};
const CLOSE_EMAIL_VERIF_MODAL_ACTION: IActionModal = {
  modalManagerAction: "closeEmailVerificationModal",
};
const OPEN_PHONE_VERIF_MODAL_ACTION: IActionModal = {
  modalManagerAction: "openPhoneVerificationModal",
};
const CLOSE_PHONE_VERIF_MODAL_ACTION: IActionModal = {
  modalManagerAction: "closePhoneVerificationModal",
};
const OPEN_UPDATE_EMAIL_MODAL_ACTION: IActionModal = {
  modalManagerAction: "openUpdateEmailModal",
};
const CLOSE_UPDATE_EMAIL_MODAL_ACTION: IActionModal = {
  modalManagerAction: "closeUpdateEmailModal",
};
const OPEN_PRE_REGISTER_MODAL_ACTION: IActionModal = {
  modalManagerAction: "openPreRegisterModal",
};
const CLOSE_PRE_REGISTER_MODAL_ACTION: IActionModal = {
  modalManagerAction: "closePreRegisterModal",
};
const CLOSE_CHECKOUT_REGISTER_MODAL_ACTION: IActionModal = {
  modalManagerAction: "closeCheckoutRegisterModal",
};
const CLOSE_CHECKOUT_WELCOME_REGISTER_MODAL_ACTION: IActionModal = {
  modalManagerAction: "closeCheckoutWelcomeRegisterModal",
};

@Injectable()
export class RegisterModalsManagerHelperService {
  private actionSubject = new Subject<IActionModal>();
  public action$ = this.actionSubject.asObservable();

  constructor() {}

  public openRegisterModal() {
    this.actionSubject.next(OPEN_REGISTER_MODAL_ACTION);
  }

  public closeRegisterModal() {
    this.actionSubject.next(CLOSE_REGISTER_MODAL_ACTION);
  }

  public openRegisterAfiliacionModal() {
    this.actionSubject.next(OPEN_REGISTER_AFILIACION_MODAL_ACTION);
  }

  public closeRegisterAfiliacionModal() {
    this.actionSubject.next(CLOSE_REGISTER_AFILIACION_MODAL_ACTION);
  }

  public openNewPasswordModal() {
    this.actionSubject.next(OPEN_NEW_PASSWORD_MODAL_ACTION);
  }

  public closeNewPasswordModal() {
    this.actionSubject.next(CLOSE_NEW_PASSWORD_MODAL_ACTION);
  }

  public openEmailVerificationModal(data?: any) {
    const action: IActionModal = { modalManagerAction: 'openEmailVerificationModal', data };
    this.actionSubject.next(action);
  }

  public closeEmailVerificationModal() {
    this.actionSubject.next(CLOSE_EMAIL_VERIF_MODAL_ACTION);
  }

  public openPhoneVerificationModal() {
    this.actionSubject.next(OPEN_PHONE_VERIF_MODAL_ACTION);
  }

  public closePhoneVerificationModal() {
    this.actionSubject.next(CLOSE_PHONE_VERIF_MODAL_ACTION);
  }

  public openUpdateEmailModal() {
    this.actionSubject.next(OPEN_UPDATE_EMAIL_MODAL_ACTION);
  }

  public closeUpdateEmailModal() {
    this.actionSubject.next(CLOSE_UPDATE_EMAIL_MODAL_ACTION);
  }

  public openPreRegisterModal() {
    this.actionSubject.next(OPEN_PRE_REGISTER_MODAL_ACTION);
  }

  public closePreRegisterModal() {
    this.actionSubject.next(CLOSE_PRE_REGISTER_MODAL_ACTION);
  }

  public openCheckoutRegisterModal(data: any) {
    const action: IActionModal = { modalManagerAction: 'openCheckoutRegisterModal', data };
    this.actionSubject.next(action);
  }

  public closeCheckoutRegisterModal() {
    this.actionSubject.next(CLOSE_CHECKOUT_REGISTER_MODAL_ACTION);
  }

  public openCheckoutWelcomeRegisterModal(data?: any) {
    const action: IActionModal = { modalManagerAction: 'openCheckoutWelcomeRegisterModal', data };
    this.actionSubject.next(action);
  }

  public closeCheckoutWelcomeRegisterModal() {
    this.actionSubject.next(CLOSE_CHECKOUT_WELCOME_REGISTER_MODAL_ACTION);
  }
}
