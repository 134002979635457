import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FilteredProductsRequest } from "../../models/filtered-products/filtered-products-request.model";
import { IFilteredProductsResponse } from "../../models/filtered-products/filtered-products-response.interface";
import { FilteredProductsResponse } from "../../models/filtered-products/filtered-products-response.model";
import { ENDPOINTS } from "../../parameters/endpoints";
import { GenericHeaderService, GenericService } from "../generic";
import { HttpParams } from "@angular/common/http";
@Injectable()
export class FilteredProductClientService {
  constructor(
    private _genericService: GenericService,
    private _appStoreService: AppStoreService,
    private _genericHeaderService: GenericHeaderService
  ) {}

  getUrlFilteredProducts(): string {
    let isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
    if (isOrderTaker) {
      const isMifaBrand =
        EBrand[EBrandAsset[environment.brand]] == EBrand.mifarma;
      return isMifaBrand
        ? ENDPOINTS.GET_FILTERED_PRODUCTS_MF
        : ENDPOINTS.GET_FILTERED_PRODUCTS;
    }

    return ENDPOINTS.GET_FILTERED_PRODUCTS;
  }

  getFilteredProducts(
    body: FilteredProductsRequest
  ): Observable<FilteredProductsResponse> {
    const headers = this._genericHeaderService.getCommonAuthV2();

    return this._genericService
      .genericPost(this.getUrlFilteredProducts(), body, null, headers)
      .pipe(
        map(
          (iFilteredProductsResponse: IFilteredProductsResponse) =>
          new FilteredProductsResponse(
              iFilteredProductsResponse || ({} as IFilteredProductsResponse)
            )
        )
        );
      }
    }

