<div class="display-center radio-address-nickname"
  [ngClass]="{'selected': selected}" (click)="selectOption()">
  <div class="radio-address-nickname-container">
    <ng-content></ng-content>
  </div>
  <div class="display-vertical-center">
    <div class="icon">
      <fp-icon iconSize="20" [iconName]="iconName"></fp-icon>
    </div>
    <div class="options-direction-title label"
      [class.label-black]="selected">{{name}}</div>
  </div>
</div>
