export enum EConsents {
  ALL = "all",
  SOME = "some",
  NONE = "none",
}

export const CClientTermColour = {
  [EConsents.ALL]: "#36B37E",
  [EConsents.SOME]: "#FFAB00",
  [EConsents.NONE]: "#CBD2E1",
};
