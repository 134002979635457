import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { isObject } from 'src/app/shared/helpers/objects-equal';
import { IOrderFirebase } from 'src/app/shared/models/orders-firebase/order-firebase.model';
import {
  CANCELLED_STATE, CANCELLED_STATES, CANCELLED_SUBTITLE, DELIVERED_STATE, ENDING_STATES,
  STATES_FOR_SELLER, SUBTITLE_SELLER,
  STATES_FOR_RAD, STATES_FOR_RET, SUBTITLE_RAD,
  SUBTITLE_RET, ON_STORE_STATES
} from './processing-order.parameters';
import * as moment from 'moment';
import { IProcessingOrderState } from './processing-order.interface';
import { OrderTakerValidator } from '@validators/order-taker.validator';

@Component({
  selector: 'fp-processing-order',
  templateUrl: './processing-order.component.html',
  styleUrls: ['./processing-order.component.sass']
})
export class ProcessingOrderComponent implements OnInit, OnChanges {

  @Input() order: IOrderFirebase;
  public loading = true;

  isOrderTaker = OrderTakerValidator.isOrderTakerEnv()

  public title = '';
  public titleIcon = '';
  public subtitle = '';
  public datetimeMessage = '';

  constructor() { }

  ngOnInit() {
    this.updateOrderStatusDetail();
  }

  ngOnChanges() {
    this.updateOrderStatusDetail();
  }

  private updateOrderStatusDetail() {
    if (isObject(this.order)) {
      this.loading = false;
      if (ENDING_STATES.includes(this.order.orderStatus?.statusName)) { // Entregados y Cancelados
        this.updateEndingOrderStatusDetail();
      } else { // Todos los estados previos
        this.updateProcessingOrderStatusDetail();
      }
    }
  }

  private updateProcessingOrderStatusDetail() {
    const statusName = this.order?.orderStatus?.statusName;
    if (this.order.deliveryType === 'RET') {
      const orderState = statusName ? statusName : 'ON_STORE';
      let processingOrderState = STATES_FOR_RET.find(n => n.states.includes(orderState));
      processingOrderState = processingOrderState ? processingOrderState : {} as IProcessingOrderState;
      this.title = processingOrderState.templateTitle;
      this.subtitle = SUBTITLE_RET;
      this.titleIcon = processingOrderState.templateIcon;
      this.datetimeMessage = orderState === 'READY_FOR_PICKUP'
        ? `Tienes ${this.order.daysToPickUp}` : `Desde el ${ this.order.messageToPickup  }`;
    } else {
      if (this.order.deliveryType === 'SELLER') {
        const orderState = statusName ? statusName : 'ON_STORE';
        let processingOrderState = STATES_FOR_SELLER.find(n => n.states.includes(orderState));
        processingOrderState = processingOrderState ? processingOrderState : {} as IProcessingOrderState;
        this.title = processingOrderState.templateTitle.replace('%s', this.order.sellerName);
        this.subtitle = SUBTITLE_SELLER;
        this.titleIcon = processingOrderState.templateIcon;

        if (this.order.maxDeliveryTime && this.order.scheduled) {
          moment.locale('es');
          const maxDate = new Date(this.order.maxDeliveryTime);
          const month = maxDate.getMonth();
          const mothNames = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
          const monthName = mothNames[month];
          const maxDay = moment(this.order.maxDeliveryTime).format('D');
          this.datetimeMessage = `${maxDay} de ${monthName}`;
        } else {
          this.datetimeMessage = '';
        }
      } else { // RAD, EXP, AM_PM, PROG
        const orderState = statusName ? statusName : 'ON_STORE';
        let processingOrderState = STATES_FOR_RAD.find(n => n.states.includes(orderState));
        processingOrderState = processingOrderState ? processingOrderState : {} as IProcessingOrderState;
        this.title = processingOrderState.templateTitle;
        this.subtitle = SUBTITLE_RAD;
        this.titleIcon = processingOrderState.templateIcon;

        if (this.order.maxDeliveryTime && this.order.scheduled) {
          moment.locale('es');
          const maxDate = new Date(this.order.maxDeliveryTime);
          const month = maxDate.getMonth();
          const mothNames = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
          const monthName = mothNames[month];
          const maxDay = moment(this.order.maxDeliveryTime).format('D');
          const startHour = moment(this.order.scheduled.startDate).format('hh:mm a');
          const endHour = moment(this.order.scheduled.endDate).format('hh:mm a');
          this.datetimeMessage = `${maxDay} de ${monthName} entre ${startHour} y ${endHour}`;
        } else {
          this.datetimeMessage = '';
        }
      }
    }
  }

  private updateEndingOrderStatusDetail() {
    const statusName= this.order.orderStatus?.statusName;
    this.datetimeMessage = '';
    if (CANCELLED_STATES.includes(statusName)) {
      this.title = CANCELLED_STATE.templateTitle;
      this.subtitle = !this.order.cancelMessageId.includes('CU') ? CANCELLED_SUBTITLE : this.order.cancelMessageNote;
      this.titleIcon = CANCELLED_STATE.templateIcon;
    } else { // entregados
      this.title = this.order.deliveryType === 'SELLER' ? `¡Tu pedido de ${this.order.sellerName} ya fue entregado!` : DELIVERED_STATE.templateTitle;
      this.subtitle = '';
      this.titleIcon = DELIVERED_STATE.templateIcon;
    }
  }
}
