import { CContentfulInkaclubSorteos } from './contentful-inkaclub-sorteos.model';
import { CContentfulBannerInkaclub } from './contentful-banner-inkaclub.model';
import {ContentfulAsset, ContentfulEntry} from '../../../shared/models/contentful/contentful.model';
import { Asset, Entry } from 'contentful';
import { CContentfulSlider, IContentfulSlider } from './contentful-slider.model';
import { CContentfulBanner, IContentfulBanner } from './contentful-banner.model';
import { CContentfulBannerList, IContentfulBannerList } from './contentful-banner-list.model';
import { CContentfulFeaturedProduct, IContentfulFeaturedProduct } from './contentful-featured-product.model';
import { CContentfulTitleSection, IContentfulTitleSection } from './contentful-title-section.model';
import { CContentfulBannerCountDown } from './contentful-banner-count-down.model';
import { CContentfulBannerKit, IContentfulBannerKit } from './contentful-banner-kit.model';
import { CContentfulHeaderProductList, IContentfulHeaderProductList } from './contentful-header-product-list.model';
import { CContentfulProductList, IContentfulProductList } from './contentful-product-list.model';
import { CHomeBannerProductList, IContentfulBannerProductList } from './contentful-banner-product-list.model';
import { CContentfulShowCase } from './contentful-showcase.model';
import { CContentfulShortcutList } from './contentful-shortcut-list.model';
import { CWorldList } from '../../../business/world-landing/models/world-list.model';
import { CContentfulTextoLibre } from './contentful-texto-libre.model';
import { CContentfulBlogListPreview } from './contentful-blog-list-preview.model';
import { BACKGROUND_DEFECT } from 'src/app/business/checkout/parameters/constants';
import { CContentfulShortcut } from './contentful-shortcut.model';
import { CContentfulCallToAction } from '../../../business/savings-wallet/models/call-to-action-list.model';


export enum EContentfulDashboardSection {
  home = 'Home',
  world = 'Mundo',
  inkaclub = 'Inkaclub',
  cyberWow = 'CyberWow',
  dynamicLanding = 'landing-page',
  landing = 'mifarma-landing',
  landingApp = 'Monedero',
  cuponero = 'Cuponero',
  cyberwow = 'cyberwow-registro',
  eventos_mifara = 'eventos-mifarma',
  misiones = 'Misiones',
  predictivo = 'Predictivo',
  default = ''
}

export interface IContentfulDashboard {
  title: string;
  section: EContentfulDashboardSection;
  pageTitle: string;
  metaDescription: string;
  link: string;
  metaKeywords: string;
  structuredData: string;
  background: string;
  slug:string;
  componentes: Array<Entry<IContentfulSlider |
    IContentfulBanner | IContentfulBannerKit | IContentfulBannerList |
    IContentfulProductList | IContentfulBannerProductList | IContentfulHeaderProductList |
    IContentfulFeaturedProduct | IContentfulTitleSection | any>>;
  idProveedor: number;
  fondo: string | Asset;
  inscription: string;
  principalPicture: any;
  primaryColor: string;
  supportColorOne: string;
  supportColorTwo: string;
  backgroundColor: string;
  buttonText:string;
  stylesCss:string;
}

export class ContentfulDashboard {
  title: string;
  section: EContentfulDashboardSection;
  pageTitle: string;
  metaDescription: string;
  link: string;
  metaKeywords: string;
  structuredData: string;
  canonicalLink: string;
  principalPicture: any;
  background: any;
  slug:string;
  componentList: Array<ContentfulEntry<IContentfulSlider | IContentfulBanner | IContentfulBannerKit |
    IContentfulBannerList | IContentfulProductList | IContentfulBannerProductList |
    IContentfulHeaderProductList | IContentfulFeaturedProduct | IContentfulTitleSection | any>>;
  idProveedor: number;
  fondo: string | Asset;
  inscription: any;
  primaryColor: string;
  supportColorOne: string;
  supportColorTwo: string;
  backgroundColor: string;
  buttonText:string;
  stylesCss:string;
  constructor(iContentfulDashboard: IContentfulDashboard) {
    this.title = iContentfulDashboard.title || '';
    this.section = iContentfulDashboard.section || EContentfulDashboardSection.default;
    this.componentList = iContentfulDashboard.componentes ?
      iContentfulDashboard.componentes.map(component => new ContentfulEntry(component)) : [];
    this.pageTitle = iContentfulDashboard.pageTitle;
    this.metaDescription = iContentfulDashboard.metaDescription;
    this.link = iContentfulDashboard?.link || '/';
    this.metaKeywords = iContentfulDashboard.metaKeywords;
    this.structuredData = iContentfulDashboard.structuredData;
    this.canonicalLink = iContentfulDashboard.link;
    this.slug = iContentfulDashboard.slug;
    this.background = iContentfulDashboard.background || BACKGROUND_DEFECT;
    this.backgroundColor = iContentfulDashboard.backgroundColor || BACKGROUND_DEFECT;
    this.principalPicture = iContentfulDashboard.principalPicture;
    this.primaryColor = iContentfulDashboard.primaryColor;
    this.supportColorOne = iContentfulDashboard.supportColorOne;
    this.buttonText = iContentfulDashboard.buttonText;
    if(iContentfulDashboard.idProveedor){this.idProveedor=iContentfulDashboard.idProveedor;}
    if(iContentfulDashboard.stylesCss){this.stylesCss=iContentfulDashboard.stylesCss;}
    if(iContentfulDashboard.fondo){this.fondo=iContentfulDashboard.fondo;}
    if(iContentfulDashboard.inscription){this.inscription=iContentfulDashboard.inscription;}

  }
}

export const CContentfulDashboard = {
  mainSlider: CContentfulSlider.id,
  banner: CContentfulBanner.id,
  bannerList: CContentfulBannerList.id,
  bannerCountDown: CContentfulBannerCountDown.id,
  bannerKit: CContentfulBannerKit.id,
  bannerProductList: CHomeBannerProductList.id,
  headerProductList: CContentfulHeaderProductList.id,
  productList: CContentfulProductList.id,
  shortcutList: CContentfulShortcutList.id,
  shortcut: CContentfulShortcut.id,
  showcase: CContentfulShowCase.id,
  blogLandingPreview: CContentfulBlogListPreview.id,
  worldList: CWorldList.id,
  bannerInkaclub: CContentfulBannerInkaclub.id,
  inkaclubSorteos: CContentfulInkaclubSorteos.id,
  textoLibre: CContentfulTextoLibre.id,
  featuredProduct: CContentfulFeaturedProduct.id,
  titleSection: CContentfulTitleSection.id,
  callToAction: CContentfulCallToAction.id,
};

export interface IDashboardPredictive {
  content: Content;
  id: string;
  type: string;
}

export interface Content {
  title: string;
  section: string;
  componentes: Componente[];
}

export interface Componente {
  metadata: Metadata;
  sys: Sys2;
  fields: Fields3;
}

export interface Fields3 {
  title: string;
  items: Item[];
}

export interface Item {
  metadata: Metadata;
  sys: Sys2;
  fields: Fields2;
}

export interface Fields2 {
  title: string;
  sumary?: string;
  description?: string;
  imgeDesktop?: ImgeDesktop;
  imageResponsive?: ImgeDesktop;
  link?: string;
}

export interface ImgeDesktop {
  metadata: Metadata;
  sys: Sys3;
  fields: Fields;
}

export interface Fields {
  title: string;
  file: File;
}

export interface File {
  url: string;
  details: Details;
  fileName: string;
  contentType: string;
}

export interface Details {
  size: number;
  image: Image;
}

export interface Image {
  width: number;
  height: number;
}

export interface Sys3 {
  space: Space;
  id: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  environment: Space;
  revision: number;
  locale: string;
}

export interface Sys2 {
  space: Space;
  id: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  environment: Space;
  revision: number;
  contentType: Space;
  locale: string;
}

export interface Space {
  sys: Sys;
}

export interface Sys {
  type: string;
  linkType: string;
  id: string;
}

export interface Metadata {
  tags: any[];
}
