<div class="row product-cart align-items-center"
     [class.unavailable-presentation]="productStatus === eProductStatus.unavailablePresentation">
  <div class="col-4">
    <fp-link [linkRouter]="['/'+productPath, productSeller?.slug, productSeller?.id]"
      linkId="link--go-to-product-detail">
      <fp-product-information-cart
        [image]="productSeller?.imageList[0].url"
        [name]="productSeller?.name"
        [description]="productSeller?.noFractionatedText"
        [productStatus]="productStatus"
        (click)="nameProduct()">
      </fp-product-information-cart>
    </fp-link>
  </div>

  <div class="col-2">
    <fp-product-presentation
      [presentationList]="listPresentation(productSeller)"
      [presentationSelected]="productSeller?.presentationIdSelected"
      [productStatus]="productStatus"
      (updatePresentation)="setProductPresentation($event)">
    </fp-product-presentation>
  </div>

  <div class="col-2">
    <fp-product-quantity
      [productQuantity]="productSeller?.quantitySelected"
      [quantityLimit]="productSeller?.maxUnitSale"
      [productStatus]="productStatus"
      [product]="productSeller"
      [rounded]="true"
      [isSeller]="productSeller.isFromSeller"
      (updateQuantity)="setQuantity($event)">
    </fp-product-quantity>
  </div>

  <div class="col-2">
    <fp-product-cart-price
      [subTotal]="productSeller?.subTotalAllPaymentMethod"
      [subTotalOffer]="productSeller?.subTotalWithPaymentMethod"
      [productStatus]="productStatus"
      [regularPrice]="productSeller?.subTotal">
    </fp-product-cart-price>
  </div>

  <div class="col-2 text-center">
    <fp-button-ghost
      buttonId="btn--delete-product"
      buttonSize="small"
      buttonColor="red"
      buttonDisplay="block"
      (click)="deleteProduct(productSeller.id)">
      Eliminar
    </fp-button-ghost>
  </div>

</div>
