<div class="cart-login-modal cart-modal-scroll">
	<fp-section-container-title-login [title]="'Iniciar sesion'" [iconName]="'close'" (onclick)="closeModal()">
	</fp-section-container-title-login>

	<fp-sub-section-container>
		<div class="content mt-4">
			<fp-disclaimer *ngIf="pursePrice > 0" [type]="'in'" [discount]="pursePrice" [flkButtom]="false"></fp-disclaimer>
			<ng-container *ngIf="pursePrice <= 0">
				<div *ngIf="src">
					<img [src]="src" />
				</div>

				<div class="text-center mb-4 mt-4">
					<span class="text-black font-VAGRoundedNexxt-normal-w-400">{{ textModalLogin }}</span>
				</div>
			</ng-container>
			<!-- <ng-container *ngIf="!isOrderTaker && hasControlledProducts">
        <fp-disclaimer-login></fp-disclaimer-login>
      </ng-container> -->
			<div class="mb-3">
				<fp-button-brand brandId="btn--login-facebook" brandIcon="facebook" brandWidth="full" (onclick)="facebookLogin()">
					Ingresa con Facebook
				</fp-button-brand>
			</div>

			<div class="mb-3">
				<fp-button-brand brandId="btn--login-google" brandIcon="google" brandWidth="full" (onclick)="googleLogin()">
					Ingresa con Google
				</fp-button-brand>
			</div>

			<div class="mb-3">
				<fp-button-brand brandId="btn--login-apple" brandIcon="apple" brandWidth="full" (onclick)="appleLogin()">
					Ingresa con Apple
				</fp-button-brand>
			</div>

			<div class="mb-3" *ngIf="!showEmailAndPassword">
				<fp-button-outline
					buttonClass="btn--login-email-password"
					buttonDisplay="block"
					[buttonStyle]="{ 'border-radius': isMifaBrand ? '12px' : '32px' }"
					(click)="openEmailWithPassword()"
				>
					Ingresa con correo electrónico
				</fp-button-outline>
			</div>

			<div
				class="login-with-email pt-2"
				[ngClass]="{
					show: showEmailAndPassword,
					showWithError: false
				}"
			>
				<div class="text mb-2 font-VAGRoundedNexxt-normal-w-400">Ingresa con tu correo y contraseña</div>

				<div [formGroup]="cartLoginForm.form">
					<div class="mb-2">
						<fp-input-without-title-control
							[errorMessage]="cartLoginForm.emailControl.emailAddressError"
							[marginWithError]="true"
						>
							<input id="" formControlName="email" class="w-100 input label" placeholder="Correo electrónico" />
						</fp-input-without-title-control>
					</div>

					<div class="mb-3">
						<fp-input-password>
							<input id="" formControlName="password" class="w-100 input-password" #typeControl placeholder="Contraseña" />
						</fp-input-password>
					</div>

					<div class="mb-4">
						<fp-button-primary
							id="btnLogin"
							buttonDisplay="block"
							(click)="simpleLogin()"
							[buttonDisabled]="!cartLoginForm.form.valid || isLoggingIn"
						>
							Ingresa a tu cuenta
						</fp-button-primary>
					</div>
				</div>
			</div>

			<div class="text-center mb-3">
				<fp-link-buttom [buttonText]="'¿Olvidaste tu contraseña?'" (onclick)="forgotPassword()"></fp-link-buttom>
			</div>

			<div class="d-flex align-items-center justify-content-center pt-3">
				<p>
					<span class="mr-3 text-black font-VAGRoundedNexxt-normal-w-400">¿Aún no tienes cuenta?</span>
					<fp-link-buttom
						[buttonText]="'Regístrate'"
						[linkSizeClass]="'link-size'"
						[id]="'register'"
						(onclick)="openRegister()"
					></fp-link-buttom>
				</p>
			</div>
		</div>
	</fp-sub-section-container>

	<fp-sub-section-container>
		<div class="content text-center my-4 pt-2">
			<!-- <fp-link-buttom (click)="anonymousWarning()" [buttonText]="'Continuar sin iniciar sesión'"></fp-link-buttom> -->
			<strong>
				<!-- <fp-vita-link-href [buttonText]="'Continúa sin iniciar sesión'" (click)="anonymousWarning()"></fp-vita-link-href> -->
				<fp-link-buttom (click)="anonymousWarning()" [buttonText]="'Continuar sin iniciar sesión'"></fp-link-buttom>
			</strong>
		</div>
	</fp-sub-section-container>
</div>
