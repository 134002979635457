import { ProductShoppingCart } from '../models/product-shopping-cart/product-shopping-cart.model';

export function getProductPresentationSelected(product: ProductShoppingCart) {
  let selectedPresentation = product.presentations
    .find(presentation => presentation.id === product.presentationIdSelected);
  if (!selectedPresentation) {
    selectedPresentation = product.presentations
      .find(presentation => presentation.id === product.defaultPresentation);
  }
  return selectedPresentation;
}
