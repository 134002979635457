  <fp-address-drugstore-dispatch
  [form]="form"
  [store]="_presenter.store"
  [nearestResponse]="_presenter.nearestQuantity"
  [isLoadingNearest]="_presenter.isLoadingNearest"
  [delivery]="_presenter.delivery"
  [storeBackup]="_presenter.storeBackup"
  [listServicesTypes]="_presenter.listServicesTypes"
  [isLoading]="_presenter.isLoading"
  [isError]="_presenter.errorloading"
  [deliveryMethods]="_presenter.deliveryMethods"
  [isLoadingDelivery]="_presenter.isLoadingDelivery"
  [isRetOrRad]="retOrRad"
  (openModalChangeDrugstore)="_presenter.onOpenModalChangeDrugstore($event)"
  (emitChangeNearStore)="_presenter.onChangeNearStore($event)"
></fp-address-drugstore-dispatch>
