<ng-container>

  <input
    (click)="inputDatepicker.open()"
    [class.input-error]="inputError"
    [class.input-focus]="inputFocus"
    (dateChange)="inputChangeDate($event)"
    [id]="inputId"
    [matDatepicker]="inputDatepicker"
    [name]="inputName"
    [min]="minDate" [max]="maxDate"
    [placeholder]="inputPlaceholder"
    [readOnly]="inputDatePickerReadonly"
    [type]="inputType"
    [formControl]="inputDatePickerValue"
    class="input-basic label {{inputClass}}"/>
  <label
    *ngIf="inputError"
    [htmlFor]="inputId"
    class="label--2 text-danger mx-0 mb-0 mt-2">
    {{ inputErrorMessage}}
  </label>

  <mat-datepicker-toggle matSuffix [for]="inputDatepicker" id="mat-test">
    <mat-icon matDatepickerToggleIcon [svgIcon]="'calendar-picker'" [style.width]="'16px'" ></mat-icon>
  </mat-datepicker-toggle>

  <mat-datepicker [touchUi]="!isDesktop" #inputDatepicker
                  [startView]="inputDatePickerStartView"
                  [disabled]="isDisabled"></mat-datepicker>

</ng-container>
