import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CreditCard } from '@models/delivery-info/delivery-info.model';
import { IRequestPayOnlineMapi } from '@models/pay-online/request-pay-online-mapi.model';

@Injectable({
  providedIn: 'root',
})
export class CheckoutAppAgoraPayStoreService {
  private _isAppAgoraPayAvailable: boolean;
  private _isAppAgoraPayAvailableSubject = new BehaviorSubject<boolean>(false);
  public isAppAgoraPayAvailable$ = this._isAppAgoraPayAvailableSubject.asObservable();

  private _isAppAgoraPaySelected: boolean;
  private _isAppAgoraPaySelectedSubject = new BehaviorSubject<boolean>(false);
  public isAppAgoraPaySelected$ = this._isAppAgoraPaySelectedSubject.asObservable();

  private _agoraCreditCard: CreditCard;
  private _agoraCreditCardSubject = new BehaviorSubject<CreditCard>(null);
  public agoraCreditCard$ = this._agoraCreditCardSubject.asObservable();

  private _requestPayOnlineMapi: IRequestPayOnlineMapi;
  private _requestPayOnlineMapiSubject = new BehaviorSubject<IRequestPayOnlineMapi>(null);
  public  requestPayOnlineMapi$ = this._requestPayOnlineMapiSubject.asObservable();

  constructor(

  ) {
    this.isAppAgoraPaySelected$.subscribe( isAppAgoraPaySelected => this._isAppAgoraPaySelected = isAppAgoraPaySelected);
    this.isAppAgoraPayAvailable$.subscribe( isAppAgoraPayAvailable => this._isAppAgoraPayAvailable = isAppAgoraPayAvailable);
    this.agoraCreditCard$.subscribe( agoraCreditCard => this._agoraCreditCard = agoraCreditCard);
    this.requestPayOnlineMapi$.subscribe( requestPayOnlineMapi => this._requestPayOnlineMapi = requestPayOnlineMapi);
  }
  // Getters y Setters para saber si es tipo de compra con App Agora Pay
  public get isAppAgoraPaySelected(): boolean {
    return this._isAppAgoraPaySelected;
  }

  public setIsAppAgoraPaySelected(state: boolean) {
    this._isAppAgoraPaySelectedSubject.next(state);
  }

  public get isAppAgoraPayAvailable(): boolean {
    return this._isAppAgoraPayAvailable;
  }

  public setIsAppAgoraPayAvailable(state: boolean) {
    this._isAppAgoraPayAvailableSubject.next(state);
  }

  public get agoraCreditCard(): CreditCard {
    return this._agoraCreditCard;
  }

  public setAgoraCreditCard(creditCard: CreditCard) {
    this._agoraCreditCardSubject.next(creditCard);
  }

  public get requestPayOnlineMapi(): IRequestPayOnlineMapi {
    return this._requestPayOnlineMapi;
  }

  public setRequestPayOnlineMapi(requestPayOnlineMapi: IRequestPayOnlineMapi) {
    this._requestPayOnlineMapiSubject.next(requestPayOnlineMapi);
  }

}
