<div class="dialog-close">
  <span class="icon icon-24-close icon-close cursor-pointer" (click)="close()"> </span>
</div>
<div class="out-of-stock">
  <div class="out-of-stock__icon">
		<img *ngIf="isMifaBrand" src="../../../../assets/icons/cart-mifa.svg" alt="Carrito Fuera de stock" />
		<img *ngIf="!isMifaBrand" src="../../../../assets/icons/cart-inka.svg" alt="Carrito Fuera de stock" />
  </div>
  <h2>Lamentablemente tienes uno o más productos que no son despachados por Inkafarma.</h2>
  <ng-container>
    <p>Para continuar, debemos retirar el producto</p>
    <div class="out-of-stock__actions d-flex flex-column">
      <fp-button-primary class="mb-2" [buttonDisabled]="isLoading" buttonDisplay="inline-block" (click)="deleteAndClose()"> 
        {{isLoading ? 'Cargando' : 'Si, eliminar'}}
      </fp-button-primary>
      <fp-button-ghost buttonDisplay="inline-block" (click)="selectLocation()"> Continuar en despacho a domicilio
      </fp-button-ghost>
    </div>
  </ng-container>
</div>