import { IProductCard } from "../../../core/modules/product-card/models/product-card/product-card.interface";
import { ProductCard } from "../../../core/modules/product-card/models/product-card/product-card.model";
import { EProductPresentation } from "../../../core/modules/product-card/models/product-presentation.enum";
import { EProductStatus } from "../../../core/modules/product-card/models/product-status.enum";
import { ProductDefaultPresentations } from "./product-default-presentation.model";
import { IProductDefault } from "./product-default.interface";

export class ProductDefault extends ProductCard {
  constructor(iProductDefault: IProductDefault) {
    super({} as IProductCard);

    this.id = iProductDefault.id || "";
    this.name = iProductDefault.name || "";
    this.brand = iProductDefault.brand || "";
    this.favorite = iProductDefault.favorite || false;
    this.fractionalMode = iProductDefault.fractionalMode || false;
    this.defaultPresentation = iProductDefault.isUnitPresentationDefault
      ? iProductDefault.fractionatedPresentationId || EProductPresentation.unit
      : iProductDefault.presentationId || EProductPresentation.pack;
    this.statusId = this.getProductStatusId(iProductDefault);
    this.presentations =
      new ProductDefaultPresentations(iProductDefault).productPresentations ||
      [];
    this.slug = iProductDefault.slug || "";
    if (iProductDefault.limitOfferTime) {
      this.limitOfferTime = iProductDefault.limitOfferTime;
    }

    this.category = iProductDefault.categoryList?.[0]?.keyword || '';

    this.alertTagText = iProductDefault.alertTagText || "";
    this.tagImageUrl = iProductDefault.tagImageUrl || "";
    this.sellerId = iProductDefault.sellerId || "";
    this.sellerName = iProductDefault.sellerName || "";
    this.isFromSeller = iProductDefault.isFromSeller || false;
    this.prescription = iProductDefault.prescription || "";
    this.prescriptionCode = iProductDefault.prescriptionCode || 0;

    this.sapCode = iProductDefault.sapCode || "";
    this.ranking = iProductDefault.ranking || 0;
    this.productPack = iProductDefault.productPack;
    this.complementary = iProductDefault.complementary || [];
    this.saleChannels = iProductDefault.saleChannels || [];

    this.stock = iProductDefault.stock || 0;
    this.stockRet = iProductDefault.stockRet || 0;

    this.fractionalRetStock = iProductDefault.fractionalRetStock || 0;
    this.fractionalStock = iProductDefault.fractionalStock || 0;

    this.guaranteed = iProductDefault.guaranteed || "";
    this.segment = iProductDefault.segment || "";
    this.unitMeasure = iProductDefault.unitMeasure || "";
    this.compositionContent = iProductDefault.details.find(
      (details) => details.key === "COMPOSITION"
    )?.content;
    this.isGeneric = iProductDefault.isGeneric || false;
    this.productAliviamed = iProductDefault?.productAliviamed || null;
  }

  getProductStatusId(iProductDetail: IProductDefault) {
    let result = 0;
    if (
      iProductDetail.maxUnitSale >= 0 ||
      iProductDetail.maxUnitSaleFractionated > 0
    ) {
      result = iProductDetail.productStatusId;
    } else {
      result = EProductStatus.unavailable;
    }
    return result;
  }
}
