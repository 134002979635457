import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyLetters]'
})
export class OnlyLettersDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', ' '];
    const letterRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]$/;

    if (allowedKeys.indexOf(event.key) !== -1) {
      return; // Allow navigation keys and other control keys
    } else if (!letterRegex.test(event.key)) {
      event.preventDefault(); // Prevent non-letter, non-space, non-tilde keys
    }
  }
}
