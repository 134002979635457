import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  PlacesSearch,
  ReverseAddress,
} from '../../modules/here-maps/interfaces/places-search';
import { AppState, NewAddressModal } from '../reducer/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class AppStoreSubscription {
  private subject$ = new Subject();
  private subjectNewAddressModalDirection$ = new Subject<string>();
  private subjectNewAddressModalHereAddressMarked$ = new Subject<
    PlacesSearch<ReverseAddress>
  >();
  private subjectNewAddressModal$ = new Subject<NewAddressModal>();

  constructor(private readonly store: Store<{ appState: AppState }>) {
    this.store
      .select((state) => state.appState.newAddressModal)
      .subscribe(this.subjectNewAddressModal$);
  }

  getStoreSelect() {
    this.store.select((state) => state).subscribe(this.subject$);
    return this.subject$;
  }

  get selectNewAddressModalDirection$() {
    // this.store.select('appState', 'newAddressModal', 'direction').subscribe(this.subjectNewAddressModalDirection$)
    this.store
      .select((state) => state.appState.newAddressModal.direction)
      .subscribe(this.subjectNewAddressModalDirection$);
    return this.subjectNewAddressModalDirection$.asObservable();
  }

  get selectNewAddressModalHereAddressMarked$() {
    // this.store.select('appState', 'newAddressModal', 'hereAddressMarked').subscribe(this.subjectNewAddressModalHereAddressMarked$)
    this.store
      .select((state) => state.appState.newAddressModal.hereAddressMarked)
      .subscribe(this.subjectNewAddressModalHereAddressMarked$);
    return this.subjectNewAddressModalHereAddressMarked$.asObservable();
  }

  get selectNewAddressModal$() {
    return this.subjectNewAddressModal$;
  }
}
