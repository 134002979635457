import { Component, Input, OnChanges } from "@angular/core";
import { ProductBackup } from "@models/stock/stock-backup.response";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { StockRequestService } from "src/app/shared/service/stock/stock-request.service";
import { ProductPresentation } from "../../models/product-presentation.model";
import { ProductSellersShoppingCartBase } from "../../models/product-shopping-cart/product-seller-shopping-cart-base.model";
import { ProductShoppingCart } from "../../models/product-shopping-cart/product-shopping-cart.model";
import { EProductStatus } from "../../models/product-status.enum";
import { ProductAlertsService } from "../../service/product-alerts.service";
import { ProductImplementService } from "../../service/product-implement.service";
import { getProductPresentationSelected } from "../../service/product-presentation.helper";
import { ProductSalesforceService } from "../../service/product-salesforce.service";
import { getUnavailablePresentationStatus } from "../../service/product-status.helper";
import { EBrand } from "@parameters/access/brand.parameter";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { AlgoliaInstanceService } from "../../../custom-instantsearch/algolia-instance.service";

@Component({
  selector: "fp-product-cart",
  templateUrl: "./product-cart.component.html",
  styleUrls: ["./product-cart.component.sass"],
})
export class ProductCartComponent implements OnChanges {
  public brand = EBrand;
  public eProductStatus = EProductStatus;
  public productStatus: number = EProductStatus.available;
  public productStatusSeller: number = EProductStatus.available;
  public productPresentation: ProductPresentation;

  public productPath = BUSINESS_PATHS.PRODUCT;

  public initialPresentation: string;
  public initialPrecio: number;

  public stockBackup: number = null;

  @Input() product: ProductShoppingCart;
  @Input() productSeller: ProductSellersShoppingCartBase;
  @Input() stockBackupList: ProductBackup[] = [];
  flagBackup = this._stockRequest.isZoneBackup();
  constructor(
    private _productImplement: ProductImplementService,
    private _productAlerts: ProductAlertsService,
    private _productSalesforce: ProductSalesforceService,
    private _stockRequest: StockRequestService,
    private algoliaInstanceService: AlgoliaInstanceService,
    private retValidateCartService: RetValidateCartService
  ) {}

  ngOnChanges() {
    this.productPresentation = getProductPresentationSelected(this.product);
    this.productStatus = getUnavailablePresentationStatus(
      this.product,
      this.productPresentation.fractionated
    );
    this.getStockBackup();
  }

  getStockBackup() {
    if (this.stockBackupList?.length > 0) {
      const stockBackupProduct = this.stockBackupList.find(
        (p) => p.id === this.product.id
      );

      if (stockBackupProduct) {
        this.stockBackup =
          stockBackupProduct.stock > 0 ? stockBackupProduct.stock : null;
      }
    }
  }

  setProductPresentation(presentation: ProductPresentation) {
    this.initialPrecio = this.productPresentation.price;
    this.initialPresentation = this.productPresentation.presentation;
    this.product.presentationIdSelected = presentation.id;
    this.productPresentation = presentation;
    this.productStatus = getUnavailablePresentationStatus(
      this.product,
      this.productPresentation.fractionated
    );
    this.setQuantity(!this.productNeedNewPresentation() ? 1 : 0, true);
    TaggingService.cartPresentation();
    this.initialPrecio = this.productPresentation.price;
    this.initialPresentation = this.productPresentation.presentation;

    this.retValidateCartService.processValidateAddToCart.next(true);
  }

  productNeedNewPresentation() {
    return this.productStatus === this.eProductStatus.unavailable
    || this.productStatus === this.eProductStatus.unavailablePresentation
    || this.productStatus === this.eProductStatus.newStock
  }

  setQuantity(quantity: number, changePresentation = false) {

    if (this.product.quantity < quantity) {
      TaggingService.productClick(this.product);
      TaggingService.detail(this.product);
      TaggingService.addToCart(this.product, this.productPresentation);
      // TaggingService.addToCartSDK(this.product,  this.productPresentation,quantity)
    } else {
      const quantityTotalRemove = changePresentation
        ? this.product.quantity
        : 0;
      if (
        this.initialPrecio === undefined &&
        this.initialPresentation === undefined
      ) {
        const itemPresentation = this.product.presentations.find(
          (e) => e.id === this.product.presentationIdSelected
        );
        this.initialPrecio = itemPresentation.price;
        this.initialPresentation = itemPresentation.presentation;
      }
      TaggingService.removeFromCart(this.product, this.initialPrecio, this.initialPresentation, quantityTotalRemove);
      TaggingService.removeFromCartSDK(this.product, this.initialPrecio,  this.product.quantity)
    }

    this.product.quantity = quantity;
    this._productImplement.updateProduct(
      this.product.id,
      this.product.quantity,
      this.product.presentationIdSelected,
      this.product.sellerId,
      this.product.sellerName,
      this.product.productPack
    );
    this._productSalesforce.updateProduct(
      this.product.id,
      this.product.id,
      this.product.quantity,
      this.productPresentation.price * this.product.quantity
    );
    TaggingService.updateCartSDK(this.product, this.productPresentation.price,this.product.quantity)

    TaggingService.cartQuantity();

    this.retValidateCartService.processValidateAddToCart.next(true);

    this.callEventAnalyticsAlgolia();
  }

  callEventAnalyticsAlgolia() {
    this.algoliaInstanceService.addConvertedObjectID(this.product, this.productPresentation, this.product.quantity, false);
	}

  deleteProduct() {
    this._productImplement.deleteProduct(this.product.id);
    this._productAlerts.deleteProductAlert();
    this._productSalesforce.deleteProduct(this.product.id);
    TaggingService.cartEliminar();
    const itemPresentation = this.product.presentations.find( e => e.id === this.product.presentationIdSelected);
    TaggingService.removeFromCart(this.product, itemPresentation.price, itemPresentation.presentation, this.product.quantity, true);
    TaggingService.removeFromCartSDK(this.product, itemPresentation.price,  this.product.quantity)
    this.algoliaInstanceService.removeConvertedObjectID(this.product.id);

    this.retValidateCartService.processValidateAddToCart.next(true);
  }

  nameProduct() {
    TaggingService.cartName();
  }
}
