<label class="input-search-container" [ngClass]="labelClass">
  <app-input
    labelClass="input-search-label"
    behavior="search"
    [innerClass]="'input-search ' + innerClass"
    [error]="error"
    [id]="id"
    [name]="name"
    [maxLength]="maxLength"
    [(ngModel)]="inputValue"
    (ngModelChange)="changeInputValue()"
    [placeholder]="placeholder"
    (onBlur)="onBlur.emit()"
    (onFocus)="onFocus.emit()"
  >
  </app-input>

  <fp-icon
    innerClass="input-search-icon"
    iconName="zoom-outline"
    iconSize="12"
  ></fp-icon>
</label>
