<label class="input-label" [ngClass]="labelClass">
  <input
    class="input-basic"
    [style.padding-right]="buttonClear ? '48px' : ''"
    [style.padding-left]="isSearch ? '48px' : ''"
    [class.input-error]="error"
    [ngClass]="innerClass"
    [id]="id"
    [name]="name"
    [type]="behavior"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [readonly]="readOnly"
    [value]="value"
    [attr.maxlength]="maxLength"
    [(ngModel)]="value"
    (ngModelChange)="onInputChange($event)"
    (blur)="onBlur.emit()"
    (focus)="emitOnFocus()"
    autocomplete="off"
  />

  <fp-icon
    *ngIf="isSearch"
    class="input-search-icon"
    iconName="search-input"
  ></fp-icon>

  <fp-icon
    *ngIf="buttonClear && value"
    class="input-close-icon"
    (click)="clearValue()"
    iconName="close-input"
  >
  </fp-icon>
</label>
