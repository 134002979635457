<mat-dialog-content class="mat-dialog-contnet">
  <button mat-button class="close" (click)="removeDialog()"></button>
  <div class="modal-error">
    <div class="alert">
      <img src="../../../../../assets/images/coin-purse/alert.svg" alt="" />
    </div>
    <div class="title">No puedes canjear tus cupones</div>
    <div class="info-error-coupons">
      <div class="title-info-error">
        No tienes productos en tu carrito que apliquen a los siguientes cupones:
      </div>
      <ul *ngFor="let noredimidos of CuponesNoRedimidos">
        <li>{{ noredimidos.productName }}</li>
      </ul>
    </div>
    <div class="button-back-checkout">
      <button mat-button (click)="removeDialog()">
        Aceptar y regresar al checkout
      </button>
    </div>
  </div>
</mat-dialog-content>
