<div class="purchase-summary-total">
	<div class="row total" *ngIf="total && total > 0">
		<div class="col label-black label-black-lg--1 label-black-xl label-black-xxl--1">Total a pagar</div>
		<div class="col-4 text-right label-black label-black-lg--1 label-black-xl label-black-xxl--1 currency">
			{{ total | currency : "S/ " }}
		</div>
	</div>
	<div class="row" *ngIf="totalOh && totalOh > 0">
		<div class="col label-black label-black-lg--1 label-black-xl label-black-xxl--1">
			{{ _globalProductCardService.paymentMethodCard.textTotal }}
		</div>
		<div
			class="col-5 text-right d-flex align-items-center justify-content-end label-black label-black-lg--1 label-black-xl label-black-xxl--1"
		>
			<div class="container-icons-card mr-2">
				<ng-container *ngFor="let image of _globalProductCardService.paymentMethodCard.imageArray; trackBy: trackByImage">
					<img class="icon-card" [src]="image" alt="icono tarjeta inkafarma" />
				</ng-container>
			</div>
			<span class="total-oh currency">
				{{ totalOh | currency : "S/ " }}
			</span>
		</div>
	</div>
</div>
