<div class="purchase-summary-sub-total">
	<div class="row total" *ngIf="subtotal">
		<div class="col heading">Subtotal</div>
		<div class="col-4 text-right heading">
			{{ subtotal | currency : "S/ " }}
		</div>
	</div>
	<div class="row total-oh" *ngIf="subtotalOh">
		<div class="col heading">
			{{ _globalProductCardService.paymentMethodCard.textTotal }}
		</div>
		<div class="col-5 text-right heading d-flex align-items-center justify-content-end">
			<div class="container-icons-card mr-2">
				<ng-container *ngFor="let image of _globalProductCardService.paymentMethodCard.imageArray; trackBy: trackByImage">
					<img class="icon-card" [src]="image" alt="icono tarjeta inkafarma" />
				</ng-container>
			</div>
			<span class="currency text-right">
				{{ subtotalOh | currency : "S/ " }}
			</span>
		</div>
	</div>
</div>
<div class="row" *ngIf="discount">
	<div class="col mt-3">
		<div class="row text-danger">
			<div class="col label">Descuentos {{ brand == "Inkafarma" ? "adicionales" : "mifarma" }}</div>
			<div class="col-4 text-right label">
				{{ discount || 0 | currency : "- S/ " }}
			</div>
		</div>
	</div>
</div>
<hr class="mr-0" />
