<div class="row">
  <div class="col">
    <ngb-pagination class="d-flex justify-content-center pagination-list"
                    pageSize="1"
                    [(page)]="page"
                    [collectionSize]="totalPages"
                    [maxSize]="previewPages"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="false"
                    (pageChange)="setPageNumber()">
    </ngb-pagination>
  </div>
</div>
