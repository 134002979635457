import { Directive, ElementRef, HostListener, Input, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validators, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[fpValidateForm][formControlName], [fpValidateForm][formControl], [fpValidateForm][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ValidateFormDirective),
      multi: true
    }
  ]

})
export class ValidateFormDirective {

  @Input() regExpresionValue: string;
  @Input() maxLength: string;
  @Input() maxValue: string;
  @Input() minValue: string;

  constructor(
    private _ElementRef: ElementRef,
  ) {
  }

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
  private specialKeysToNumber: Array<string> = ['e', 'E', '+', '-', 'Dead', '.'];
  private _validatorToMin: ValidatorFn;
  private _validatorToMax: ValidatorFn;

  @HostListener('keypress', ['$event'])
  onkeypress(event: KeyboardEvent) {

    const regExpresion = new RegExp(this.regExpresionValue, 'gi');
    const currentValue: string = this._ElementRef.nativeElement.value;
    const nextValue: string = currentValue.concat(event.key);

    if (this._ElementRef.nativeElement.type === 'number' && this.specialKeysToNumber.indexOf(event.key) !== -1) {
      event.preventDefault();
    }

    if (this.regExpresionValue) {
      if (nextValue && !regExpresion.test(String(nextValue))) {
        event.preventDefault();
      }
    }

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (this.maxValue) {
      if (nextValue.length > this.maxValue.length) {
        event.preventDefault();
      }
    }

    if (this.maxLength) {
      if (nextValue.length > +this.maxLength) {
        event.preventDefault();
      }
    }
  }

  public validate(control: AbstractControl): { [key: string]: any } {

    this._validatorToMin = Validators.min(parseInt(this.minValue, 10));
    this._validatorToMax = Validators.max(parseInt(this.maxValue, 10));

    const controlMin = this._validatorToMin(control);
    const controlMax = this._validatorToMax(control);

    if (this.minValue || this.maxValue) {
      return controlMin || controlMax ? [controlMin, controlMax] : null;
    }
    return null;
  }
}
