import { MatDialogConfig } from "@angular/material/dialog";
import { tr } from "date-fns/locale";

export const ERROR_APPAGORA_MODAL_CONFIG: MatDialogConfig = {
  width: "420px",
  disableClose: false,
  panelClass: "custom-error-modal",
};

export const ERROR_GENERIC_MODAL_CONFIG: MatDialogConfig = {
  width: "360px",
  disableClose: true,
  panelClass: "custom-error-modal",
};
