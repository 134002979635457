import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductDetail } from 'src/app/business/product-detail/models/product-detail-model';
import { AddressListModalComponent } from '../../modal-components/address-list-modal/address-list-modal.component';
import { changeRetOrRadModalDesktopComponent } from '../../modal-components/change-ret-or-rad-modal-desktop/change-ret-or-rad-modal-desktop';
import { ChangeRetOrRadModalComponent } from '../../modal-components/change-ret-or-rad-modal/change-ret-or-rad-modal.component';
import { AddressListModalV2Component } from '../../modal-components/address-list-modal-v2/address-list-modal-v2.component';

@Injectable({
  providedIn: 'root'
})
export class AddressListManagerService {
  constructor(private dialog: MatDialog) {}

  public showChangeRetOrRad(product,cambiar?): void {
    const prod = product as ProductDetail;
    this.dialog.open(ChangeRetOrRadModalComponent, {
      width: '100%',
      height: '50%',
      disableClose: false,
      panelClass: 'custom-full-x-modal-change',
      data: {
        product: prod,
        change: cambiar
      }
    });
  }

  public showAddressListModal(): void {
    this.dialog.open(AddressListModalComponent, {
      width: "816px",
      disableClose: true,
      panelClass: "custom-full-x-modal-overlay",
    });
  }

  public showAddressListModalV2(): void {
    this.dialog.open(AddressListModalV2Component, {
      width: "650px",
      disableClose: true,
      panelClass: "custom-full-x-modal-overlay",
    });
  }

  public showRetOrRadDesktopModal(): void {
    this.dialog.open(changeRetOrRadModalDesktopComponent, {
      width: '680px',
      disableClose: true,
      panelClass: 'custom-full-x-modal-overlay',
      data: {} as ProductDetail,
    });
  }

  public closeAddressListModal(): void {
    this.dialog.closeAll();
  }
}
