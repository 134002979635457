import { Component, Input, OnInit } from '@angular/core';
import { PricePurseService } from '@stores/price-purse/price-purse-store.store';
import { UserInfoStoreService } from '@stores/user/user-info-store.service';
import { OrderTakerValidator } from '@validators/order-taker.validator';

@Component({
  selector: 'fp-product-regular-price-mifa',
  templateUrl: './product-regular-price-mifa.component.html',
  styleUrls: ['./product-regular-price-mifa.component.sass']
})
export class ProductRegularPriceMifaComponent implements OnInit {
  public orderTaker = OrderTakerValidator.isOrderTakerEnv();
  @Input() regularPrice: number;
  @Input() fontSize = '--3';
  @Input() fontSizeText = '--1';
  @Input() flkText: boolean = false;
  @Input() flkTextStrike: boolean = false;
  @Input() place: string = '';
  isPursePrice: boolean = false;
  constructor(
    private _pricePurseService: PricePurseService,
    public _userInfoStore: UserInfoStoreService) {
  }

  ngOnInit() {
    if (this.orderTaker) {
      this._pricePurseService.pricePurseStatus$.subscribe(data => {
        this.isPursePrice = data
      })
    }
  }

}
