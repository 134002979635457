import { createAction, props } from '@ngrx/store';
import {
  PlacesSearch,
  ReverseAddress,
} from '../../modules/here-maps/interfaces/places-search';
import { NewAddressModal } from '../reducer/app.reducer';

export const setDirection = createAction(
  '[MODAL ADDRESS] Set direction',
  props<{ direction: string }>()
);
export const setHereAddressMarked = createAction(
  '[MODAL ADDRESS] Set hereAddressMarked',
  props<{ hereAddressMarked: PlacesSearch<ReverseAddress> }>()
);
export const setNewAddressModal = createAction(
  '[MODAL ADDRESS] Set newAddresModal',
  props<{ newAddressModal: NewAddressModal }>()
);
