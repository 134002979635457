import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  IAnonymousUserFormStore,
  INITIAL_ANONYMOUS_USER_FORM_STORE,
} from '../models/anonymous-user-form-store.interface';
import { AppStoreService } from 'src/app/shared/stores/app/app-store.service';

@Injectable()
export class CheckoutAnonymousUserFormStoreService {

  private readonly CHECKOUT_ANONYMOUS_USER_KEY = 'CAUFS';
  private form: IAnonymousUserFormStore;
  private formSubject = new BehaviorSubject<IAnonymousUserFormStore>(this.getFormStorage());

  private subscriptions: Subscription[] = [];

  public form$ = this.formSubject.asObservable();

  constructor(
    private appStore: AppStoreService,
  ) {
    // this subscribe will be activated for each 'this.formSubject.next'
    const formSubscription = this.form$
      .subscribe(form => {
        this.form = form;
        this.setFormStorage();
      });
    this.subscriptions.push(formSubscription);
  }

  public setDniValue(value: string) {
    this.setOneFormValue(value, 'dni');
  }

  public setEmailValue(value: string) {
    this.setOneFormValue(value, 'emailAddress');
  }

  public setNameValue(value: string) {
    this.setOneFormValue(value, 'name');
  }

  public setPhoneValue(value: string) {
    this.setOneFormValue(value, 'phoneNumber');
  }

  public setFatherLastNameValue(value: string) {
    this.setOneFormValue(value, 'fatherLastName');
  }

  public setMotherLastNameValue(value: string) {
    this.setOneFormValue(value, 'motherLastName');
  }

  public setDocumentTypeValue(value: string) {
    this.setOneFormValue(value, 'documentType');
  }

  public setDocumentValue(value: string) {
    this.setOneFormValue(value, 'document');
  }

  public setOneFormValue(value: string, key: string) {
    const { form } = this;
    form[key] = value;
    this.formSubject.next(form);
  }

  public setFormStorage(): void {
    const { form, CHECKOUT_ANONYMOUS_USER_KEY } = this;
    const stringFormatOfForm = JSON.stringify(form);
    this.appStore.setStorageItem(CHECKOUT_ANONYMOUS_USER_KEY, stringFormatOfForm);
  }

  public getFormStorage(): IAnonymousUserFormStore {
    const { CHECKOUT_ANONYMOUS_USER_KEY } = this;
    const stringFromStorage = this.appStore.getStorageItem(CHECKOUT_ANONYMOUS_USER_KEY);
    let jsonFormatOfForm = INITIAL_ANONYMOUS_USER_FORM_STORE;
    if (stringFromStorage) {
      jsonFormatOfForm = JSON.parse(this.appStore.getStorageItem(CHECKOUT_ANONYMOUS_USER_KEY)) as IAnonymousUserFormStore;
      jsonFormatOfForm = this.validateFullKeys(jsonFormatOfForm) ? jsonFormatOfForm : INITIAL_ANONYMOUS_USER_FORM_STORE;
    }
    return jsonFormatOfForm;
  }

  public unsubscribeObservers() {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

  private validateFullKeys(jsonFormatOfForm: IAnonymousUserFormStore) {
    const allKeys = [
      'emailAddress', 'name', 'phoneNumber', 'fatherLastName',
      'motherLastName', 'documentType', 'document', 'datesAndRegister',
    ];
    const currentKeys = Object.keys(jsonFormatOfForm);
    const isValid01 = allKeys.every(key => {
      return currentKeys.includes(key);
    });
    const isValid02 = currentKeys.every(key => {
      return allKeys.includes(key);
    });
    return isValid01 && isValid02;
  }
}
