import { Component, Input } from "@angular/core";

@Component({
	selector: "fp-button-icon[buttonIcon]",
	templateUrl: "./button-icon.component.html"
})
export class ButtonIconComponent {
	@Input()
	buttonIcon: string;

	@Input()
	buttonClass: string = "p-3";

	@Input()
	iconSize: string | number = 16;

	@Input()
	buttonDisplay: "block" | "inline-block" = "inline-block";

	@Input()
	buttonType: "button" | "submit" | "reset" = "button";

	@Input()
	buttonDisabled = false;

	@Input()
	iconClass: string = "mr-2";

	@Input()
	styleButton: any;

	@Input() iconClassContainer = "mr-2";
}
