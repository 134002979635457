import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailVerificationModalComponent } from '../modal-components/email-verification-modal/email-verification-modal.component';
import { Observable } from 'rxjs';

@Injectable()
export class EmailVerificationModalManagerService {

  constructor(
    private dialog: MatDialog,
  ) { }

  public showEmailVerification(): Observable<any> {
    const dialogRef = this.dialog
      .open(EmailVerificationModalComponent, {
        width: '344px',
        panelClass: 'custom-full-all-modal-overlay',
      });
    return dialogRef.afterClosed();
  }
}
