import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fp-loader',
  templateUrl: './fp-loader.component.html',
  styleUrls: ['./fp-loader.component.scss'],
})
export class FpLoaderComponent implements OnInit {
  @Input() message = '';
  @Input() width = 32;
  @Input() height = 32;
  @Input() innerClass: string;

  constructor() {}

  ngOnInit(): void {}

  getStyles() {
    return {
      'width.px': this.width ? this.width : '',
      'height.px': this.height ? this.height : '',
    };
  }
}
