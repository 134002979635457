<div class="side-nav-content" *ngIf="!isLoadingRespModal">

  <!-- header del sidenav -->
  <div class="sidenav-header d-flex">
    <div class="controls-back-icon d-flex justify-content-center align-items-center"
      (click)="backButtonEvent()">
      <span class="icon icon-24-arrow-left"></span>
    </div>
    <div class="controls-title d-flex align-items-center">
      <span class="heading-1"> {{ title }} </span>
    </div>
    <div class="controls-close-icon d-flex justify-content-center align-items-center"
      (click)="closeButtonEvent()">
      <fp-icon iconName="close-outline" [iconSize]="24"></fp-icon>
    </div>
  </div>

  <!-- body del sidenav -->
  <div class="sidenav-body">
    <div class="input-search-container">
      <div class="content">
        <fp-input-search-for-ret
          (searchDone)="setModalStatus($event)"
          [isResponsive]="true"
          [isDisabledInputSearch]="isDisableChooseStore">
        </fp-input-search-for-ret>
      </div>
    </div>
    <div class="sidenav-body-content">
      <div class="sidenav-body-content-slide"
        [ngClass]="{ 'isMoved' : isSlide }">
        <div class="drugstore-list-container">
          <div class="content">
            <div class="input-search-message paragraph--3 text-dark-gray text-center pl-3 pr-3">
              {{ config.suggestedMessageForPredictiveAddresses }}
            </div>
            <div class="drugstore-list-content">
              <fp-drugstore-list
                [isResponsive]="true"
                [status]="modalStatus"
                [isDisableChooseStore]="isDisableChooseStore">
              </fp-drugstore-list>
            </div>
          </div>
        </div>
        <div class="map-for-ret-container">
          <div class="selected-drugstore-for-map">
            <fp-store-for-ret-radio-button
              [storeToChoose]="temporalSelectedDrugstore"
              class="store-for-ret"
              [selected]="true"
              [isResponsive]="true"
              [customBorder]="''">
            </fp-store-for-ret-radio-button>
          </div>
          <fp-map-for-ret
            [isResponsive]="true"
            [isDisabledMapForRET]="isDisableChooseStore">
          </fp-map-for-ret>
        </div>
      </div>
    </div>
  </div>

  <!-- footer del sidenav -->
  <div class="sidenav-footer">
    <div class="content sidenav-footer-content">
      <div class="row" [hidden]="modalStatus === 'no_one' || modalStatus === ALL_MODAL_STATUS.IS_PENDING  || modalStatus === ALL_MODAL_STATUS.EMPTY  || modalStatus === ALL_MODAL_STATUS.LOADING">
        <div class="col-4 label-black--1 text-secondary d-flex justify-content-center align-items-center"
          (click)="switchSlide()">
          {{ isSlide ? 'Ver lista' : 'Ver mapa' }}
        </div>
        <div class="col-8">
          <fp-button-primary
            buttonDisplay="block"
            [buttonDisabled]="isDisableChooseStore || drugstores.length === 0"
            (click)="saveChanges()">
            Elegir botica
          </fp-button-primary>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="side-nav-keleton content my-5" *ngIf="isLoadingRespModal">
  <div class="row">
    <div class="col-12">
      <fp-basic-skeleton height="50"></fp-basic-skeleton>
    </div>
    <div class="offset-1 col-10 mt-2">
      <fp-basic-skeleton height="20"></fp-basic-skeleton>
    </div>
  </div>
  <ng-container *ngFor="let item of [0, 1]" class="store-address">
    <div class="row mt-5">
      <div class="col-8">
        <fp-basic-skeleton height="20"></fp-basic-skeleton>
      </div>
      <div class="offset-2 col-2">
        <fp-basic-skeleton height="25"></fp-basic-skeleton>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-9">
        <fp-basic-skeleton height="20"></fp-basic-skeleton>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-7">
        <fp-basic-skeleton height="25"></fp-basic-skeleton>
      </div>
    </div>
  </ng-container>
</div>
