import { Component, Input, OnInit } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';

export interface IEnabledDays {
  value: string;
  [key: string]: string | number | boolean;
}

@Component({
  selector: 'fp-input-date-picker',
  templateUrl: './input-date-picker.component.html',
  styleUrls: ['./input-date-picker.component.sass', '../input/input.component.sass']
})
export class InputDatePickerComponent extends InputComponent implements OnInit {

  @Input() inputDatePickerReadonly: boolean;
  @Input() inputDatePickerStartView: 'month' | 'year' | 'multi-year';
  @Input() inputDatePickerFormat = 'DD MM YYYY';
  @Input() inputDatePickerValue: moment.Moment;
  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;
  @Input() isDesktop = true;

  @Input() scheduleDays: IEnabledDays[] = []; // optional, usar en caso se tenga una lista estricta de dias disponibles y bloqueados

  constructor(public override _formBuilder: FormBuilder) {
    super(_formBuilder);
    this.getfilteredRemovingDates = this.getfilteredRemovingDates.bind(this);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.inputDefaultDatePicker();
  }

  inputChangeDate(datePicker) {
    if (datePicker.value.isValid()) {
      this.inputFormGroup.controls[this.inputFormControlName].setValue(datePicker.value);
    }
  }

  inputDefaultDatePicker() {
    if (this.inputFormControl && this.inputFormControl.value) {
      this.inputDatePickerValue = moment(this.inputFormControl.value, this.inputDatePickerFormat);
    }
  }

  public getfilteredRemovingDates(date: moment.Moment) {
    if (this.scheduleDays.length > 1) {
      const formattedDate = date?.format('YYYY-MM-DD');
      return this.scheduleDays.find(day => day.value === formattedDate);
    }
    return true;
  }
}
