import { Injectable } from "@angular/core";
import { EBrand } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { Observable, of } from "rxjs";
import {
  EContentfulDashboardSection,
  IContentfulDashboard,
} from "../../../core/contentful/models/contentful-dashboard.model";
import { IContentfulProductList } from "../../../core/contentful/models/contentful-product-list.model";
import { IProductDefault } from "../../models/product-default/product-default.interface";
import { CONTENTFUL_PATH, ENDPOINTS } from "../../parameters/endpoints";
import { CONTENTFUL_QUERIES } from "../../parameters/querys";
import { GenericContentfulService, GenericService } from "../generic";
import { UserClientService } from "../user/user-client.service";
import { concatMap, take } from "rxjs/operators";
import { UserInfoStoreService } from "@stores/user/user-info-store.service";
import { IPurchaseIntent } from "@models/purchase-intent/purchase-intent.model";
import { HttpHeaders } from "@angular/common/http";
import { AuthImplementService } from "@implements/auth/auth-implement.service";

@Injectable({
  providedIn: "root",
})
export class HomeClientService {
  pathExtra = "&whitelabel=&email=";
  pathExtra2 = "&u="

  constructor(
    private _GenericService: GenericService,
    private _GenericContentfulService: GenericContentfulService,
    private _appStoreService: AppStoreService,
    private _userClientService: UserClientService,
    private _userStoreService: UserInfoStoreService,
    private authImplementService: AuthImplementService
  ) { }

  getUrlProductsByIds(): string {
    return this._appStoreService.brandAtention === EBrand.mifarma
      ? ENDPOINTS.GET_MF_PRODUCTS_BY_IDS
      : ENDPOINTS.GET_PRODUCTS_BY_IDS;
  }

  public getDashboardHome() {
    return this._GenericContentfulService.getEntriesPromise<IContentfulDashboard>(
      CONTENTFUL_PATH.contentTypeIds.Dashboard,
      CONTENTFUL_QUERIES.prototype.getEntryBySection(
        EContentfulDashboardSection.home
      )
    );
  }

  public getProductsByIds(
    ids: Array<string>
  ): Observable<Array<IProductDefault>> {
    const idsArray = ids.toString();
    return this._GenericService.genericGet(
      this.getUrlProductsByIds() + idsArray
    );
  }

  // BRAINDW REFACTOR
  loadUserRegister() {
    const userData = this._userStoreService.lastUserData

    if (userData) {
      return of(userData)
    }

    return this._userClientService.getUser$().pipe();
  }

  public getContenidosBrainHome(path, sectionID, client) {
    if (client) {
      const { drugstoreStock } = this._appStoreService;
      let url = ENDPOINTS.GET_LIST_CONTENIDOS + (drugstoreStock ? drugstoreStock : '') + this.pathExtra + (client?.data?.email || '') + this.pathExtra2 + path + `&sectionID=${sectionID}`

      return this._GenericService.genericGetWithoutHeader(url).pipe();
    }

    return this.loadUserRegister().pipe(
      concatMap((client: any) => {
        const { drugstoreStock } = this._appStoreService;
        let url = ENDPOINTS.GET_LIST_CONTENIDOS + (drugstoreStock ? drugstoreStock : '') + this.pathExtra + (client?.data?.email || '') + this.pathExtra2 + path + `&sectionID=${sectionID}`

        return this._GenericService.genericGetWithoutHeader(url)
      })
    );
  }
  // BRAINDW REFACTOR

  public getProductListBySlug(slug: string) {
    return this._GenericContentfulService.getEntriesPromise<IContentfulProductList>(
      CONTENTFUL_PATH.contentTypeIds.productList,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

  public getDashboardHomeLanding<T>() {
    return this._GenericContentfulService.getEntriesPromise<T>(
      CONTENTFUL_PATH.contentTypeIds.Dashboard,
      CONTENTFUL_QUERIES.prototype.getEntryBySection(
        EContentfulDashboardSection.landingApp
      )
    );
  }

  public getDashboardHomeLandingApp<T>() {
    return this._GenericContentfulService.getEntriesPromise<T>(
      CONTENTFUL_PATH.contentTypeIds.Dashboard,
      CONTENTFUL_QUERIES.prototype.getEntryBySection(
        EContentfulDashboardSection.landingApp
      )
    );
  }

  public getDashboardCuponero<T>() {
    return this._GenericContentfulService.getEntriesPromise<T>(
      CONTENTFUL_PATH.contentTypeIds.Dashboard,
      CONTENTFUL_QUERIES.prototype.getEntryBySection(
        EContentfulDashboardSection.cuponero
      )
    );
  }

  public getDashboardHomeCyberwow<T>() {
    return this._GenericContentfulService.getEntriesPromise<T>(
      CONTENTFUL_PATH.contentTypeIds.Dashboard,
      CONTENTFUL_QUERIES.prototype.getEntryBySection(
        EContentfulDashboardSection.cyberwow
      )
    );
  }

  public getDashboardMifarma<T>() {
    return this._GenericContentfulService.getEntriesPromise<T>(
      CONTENTFUL_PATH.contentTypeIds.Dashboard,
      CONTENTFUL_QUERIES.prototype.getEntryBySection(
        EContentfulDashboardSection.eventos_mifara
      )
    );
  }

  public getDashboardMisionesLandingApp<T>() {
    return this._GenericContentfulService.getEntriesPromise<T>(
      CONTENTFUL_PATH.contentTypeIds.Dashboard,
      CONTENTFUL_QUERIES.prototype.getEntryBySection(EContentfulDashboardSection.misiones));
  }

  public getDashboardPredictivoLandingApp<T>() {
    return this._GenericContentfulService.getEntriesPromise<T>(
      CONTENTFUL_PATH.contentTypeIds.Dashboard,
      CONTENTFUL_QUERIES.prototype.getEntryBySection(EContentfulDashboardSection.predictivo));
  }

  public getPurchaseIntent(): Observable<IPurchaseIntent> {
    let userId = this.authImplementService.currentUID;
    let companyCode = this._appStoreService.companyCode;
    let saleChannel = 'WEB';
    let saleChannelType = 'DIGITAL';
    const paramsArr = [
      userId ? `userId=${userId}` : '',
      companyCode ? `companyCode=${companyCode}` : '',
      saleChannel ? `saleChannel=${saleChannel}` : '',
      saleChannelType ? `saleChannelType=${saleChannelType}` : '',
    ];
    const queryParams = paramsArr.filter(v => v !== '').join('&');
    const endpoint = `${ENDPOINTS.APIGEE_PATH_PURCHASE_INTENT}${queryParams ? '?' + queryParams : ''}`;
    return this._GenericService.genericGet<IPurchaseIntent>(endpoint);
  }

  public getPurchaseIntentTomador(clientId): Observable<IPurchaseIntent> {
    let userId = clientId;
    let companyCode = this._appStoreService.companyCode;
    let saleChannel = 'WEB';
    let saleChannelType = 'DIGITAL';
    const paramsArr = [
      userId ? `userId=${userId}` : '',
      companyCode ? `companyCode=${companyCode}` : '',
      saleChannel ? `saleChannel=${saleChannel}` : '',
      saleChannelType ? `saleChannelType=${saleChannelType}` : '',
    ];
    const queryParams = paramsArr.filter(v => v !== '').join('&');
    const endpoint = `${ENDPOINTS.APIGEE_PATH_PURCHASE_INTENT_TOMADOR}${queryParams ? '?' + queryParams : ''}`;
    return this._GenericService.genericGet<IPurchaseIntent>(endpoint);
  }
}
