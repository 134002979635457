import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Client } from "../../models/client/client.model";
import { StorageClientService } from "../../storage/storage-client.service";

@Injectable({
  providedIn: "root",
})
export class ClientStoreService implements OnInit {
  private _clientSubject: BehaviorSubject<Client> = new BehaviorSubject(null);
  key = "OT_CLIENT_DATA";

  constructor(private _storageClientService: StorageClientService) {}

  ngOnInit(): void {
    const dataClient = JSON.parse(
      this._storageClientService.getStorageItem(this.key)
    ) as Client;

    if (dataClient) {
      this.setClient$(dataClient);
    }
  }

  public get client(): Client {
    return (
      this._clientSubject.value ??
      (JSON.parse(
        this._storageClientService.getStorageItem(this.key)
      ) as Client)
    );
  }

  get addresses() {
    return this.client ? this.client.addresses : [];
  }

  public get client$(): Observable<Client> {
    return this._clientSubject.asObservable();
  }

  public setClient$(client: Client) {
    this._clientSubject.next(client);
  }

  public saveClient$() {
    this._storageClientService.setStorageItem(
      this.key,
      JSON.stringify(this.client)
    );
  }

  public clearStore() {
    this._storageClientService.removeStorageItem(this.key);
  }
}
