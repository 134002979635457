import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Asset, RichTextContent } from 'contentful';
import { ContentfulAsset, ContentfulRichText } from '../../../shared/models/contentful/contentful.model';

export interface IContentfulShortcut {
  title: string;
  image: Asset;
  description: string;
  link: string;
  color: string;
  headingLevel: string;
  descripcionSeo: any;
}

export class ContentfulShortcut {
  title: string;
  image: string;
  description: string;
  link: string;
  color: string;
  headingLevel: string;
  descripcionSeo: any;

  constructor(iContentfulShortcut: IContentfulShortcut) {
    this.title = iContentfulShortcut.title || '';
    if (iContentfulShortcut.image) { this.image = new ContentfulAsset(iContentfulShortcut.image).asset; }
    if (iContentfulShortcut.description) { this.description = iContentfulShortcut.description; }
    if (iContentfulShortcut.link) { this.link = iContentfulShortcut.link; }
    if (iContentfulShortcut.color) { this.color = iContentfulShortcut.color; }
    this.headingLevel = iContentfulShortcut.headingLevel || 'h1';
    if (iContentfulShortcut.descripcionSeo) { this.descripcionSeo = new ContentfulRichText(iContentfulShortcut.descripcionSeo) }
  }

}

export const CContentfulShortcut = {
  id: CONTENTFUL_PATH.contentTypeIds.shortcut
};
