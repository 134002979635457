import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-image[imageSrc][imageAlt]',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.sass']
})
export class ImageComponent implements OnInit {

  @Input() imageSrc: string;
  @Input() imageAlt: string;
  @Input() imageClass: string;

  @Input() boxClass: string;
  @Input() boxSize: string;
  @Input() boxSpacing = true;

  constructor() {
  }

  ngOnInit() {
  }

}
