import { NgModule, ModuleWithProviders } from '@angular/core';
import { StoresForPickUpStoreService } from './pick-up-stores/stores-for-pick-up-store.service';
import { UserGeolocationStoreService } from './pick-up-stores/user-geolocation-store.service';
import { DomDocumentHelperService } from './pick-up-helpers/dom-document-helper.service';
import { PeopleForPickUpStoreService } from './pick-up-stores/people-for-pick-up-store.service';

@NgModule()
export class PickUpInStoreFactoryModule {

  static forChild(): ModuleWithProviders<PickUpInStoreFactoryModule> {
    return {
      ngModule: PickUpInStoreFactoryModule,
      providers: [
        {
          provide: StoresForPickUpStoreService,
          useClass: StoresForPickUpStoreService,
        },
        {
          provide: UserGeolocationStoreService,
          useClass: UserGeolocationStoreService,
        },
        {
          provide: DomDocumentHelperService,
          useClass: DomDocumentHelperService,
        },
        {
          provide: PeopleForPickUpStoreService,
          useClass: PeopleForPickUpStoreService,
        }
      ]
    };
  }
}

