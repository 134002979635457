import { Component, Input } from "@angular/core";
import { UserInfoStoreService } from "@stores/user/user-info-store.service";
import { EProductStatus } from "src/app/core/modules/product-card/models/product-status.enum";
import { GlobalProductCardService } from "../../service/global-product-card.service";

@Component({
	selector: "fp-product-cart-price-mifa",
	templateUrl: "./product-cart-price-mifa.component.html",
	styleUrls: ["./product-cart-price-mifa.component.sass"]
})
export class ProductCartPriceMifaComponent {
	public eProductStatus = EProductStatus;

	@Input() subTotal: number;
	@Input() subTotalOffer: number;
	@Input() productStatus: number = this.eProductStatus.available;
	@Input() regularPrice: number;
	@Input() crossOutPL = false;

	constructor(public _userInfoStore: UserInfoStoreService, public _globalProductCardService: GlobalProductCardService) {}

	trackByImage(index: number, urlImage: string): string {
		return urlImage;
	}
}
