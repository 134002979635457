export enum AnalyticsPageName {
  INKA_CLUB = 'Inkaclub',
  LANDING_EVENTOS = 'Registro Eventos',
  LANDING_FARMACIA_VECINA = '',
  ENCARTE_EMBEBIDO = 'Encarte Embebido',
  LANDING_MONEDERO = 'Monedero',
  LANDING_REGISTRO = 'Registro Cyberwow',
  POPUP_HOME = 'Popup Home'
}




export enum AnalyticsEventName {
  ENCARTE_EMBEBIDO = 'ui_landing_encarte_embebido',
  LANDING_MONEDERO = 'ui_landing_monedero',
  LANDING_REGISTRO = 'ui_landing_registro_cyberwow',
  POPUP_CLOSE_HOME = 'ui_popup_close_home',
  POPUP_VIEW_HOME = 'ui_popup_view_home',
  PRODUCT_DETAIL = 'ui_product_detail',
  INKA_CLUB = 'ui_landing_inkaclub',
  LANDING_EVENTOS = 'ui_landing_registro_eventos',
  SEARCH = 'search_search',
  CART = 'ui_cart_click',
  LANDING_FARMACIA_VECINA = 'ui_landing_farmacia_vecina',
}


export enum AnalyticsEventCategory {
  INKA_CLUB = 'UI :: Landing',
  LANDING_EVENTOS = 'UI :: Landing',
  CART = 'UI :: Cart',
  SEARCH = 'Search',
  CATEGORY = 'UI :: Category',
  HOME = 'UI :: Home',
  LISTA = 'UI :: Lista',
  LANDING_FARMACIA_VECINA = 'UI :: Landing',
  ENCARTE_EMBEBIDO = 'UI :: Landing',
  LANDING_MONEDERO = 'UI :: Landing',
  LANDING_REGISTRO = 'UI :: Landing',
  PRODUCT_DETAIL = 'UI :: Product detail',
  POPUP_HOME = 'UI :: Popup Home'
}
