import { Component, Input, OnInit } from '@angular/core';
import { EProductStatus } from '../../models/product-status.enum';

@Component({
  selector: 'fp-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.sass']
})
export class ProductImageComponent implements OnInit {
  public eProductStatus = EProductStatus;

  @Input() image: string;
  @Input() imageSize: string;
  @Input() imageName: string;
  @Input() tagSize: 'small' | 'regular' = 'regular';
  @Input() productStatus: number = this.eProductStatus.available;
  @Input() isProductPack: boolean;
  @Input() isSeller: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
