import { Injectable } from '@angular/core';
import { GenericService, GenericContentfulService } from '../generic';
import { Observable, BehaviorSubject } from 'rxjs';
import { ENDPOINTS, CONTENTFUL_PATH } from '../../parameters/endpoints';
import { SuggestSearch } from 'src/app/shared/models/suggestSearch';
import { CONTENTFUL_QUERIES } from '../../parameters/querys';
import { IContentfulHeader, IContentfulHeaderMifa } from '../../../core/contentful/models/contentful-header.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderClientService {

  constructor(
    private _GenericService: GenericService,
    private _GenericContentfulService: GenericContentfulService) {
  }


  public getSuggestSearchByKeyWord(keyword: string): Observable<SuggestSearch> {
    return this._GenericService.genericGet(ENDPOINTS.GET_SUGGESTED_PRODUCTS_BY_KEYWORD + '?text=' + keyword);
  }

  public getHeader() {
    return this._GenericContentfulService.getEntriesPromise<IContentfulHeader>(
      CONTENTFUL_PATH.contentTypeIds.header,
      CONTENTFUL_QUERIES.prototype.getFirstEntryOfArray());
  }

  public getHeaderMifa() {
    return this._GenericContentfulService.getEntriesPromise<IContentfulHeaderMifa>(
      CONTENTFUL_PATH.contentTypeIds.header,
      CONTENTFUL_QUERIES.prototype.getFirstEntryOfArray());
  }

}
