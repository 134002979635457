<div class="main-login-modal modal-login-container">
    <fp-section-container-title-login [title]="'¡Te damos la bienvenida!'" [iconName]="'close'" (onclick)="closeModal()">
    </fp-section-container-title-login>

    <fp-sub-section-container>
      <div class="content modal-login mt-4 mt-4-3">
        <div class="container-icon">
          <img [src]="iconInformation">
        </div>
        <div class="message-puntos">
          <p class="font-VAGRoundedNexxt-normal-w-600"><b>{{userName}},</b> has acumulado <b>{{userPoints}} puntos - dinero</b> con tu Monedero del Ahorro. Para gozar de más beneficios regístrate o inicia sesión</p>
        </div>
        <div class="mb-3">
          <fp-button-brand brandId="btn--login-facebook"
                           brandIcon="facebook"
                           brandWidth="full"
                           (onclick)="facebookLogin()">
            Ingresa con Facebook
          </fp-button-brand>
        </div>
        <div class="mb-3">
          <fp-button-brand brandId="btn--login-google"
                           brandIcon="google"
                           brandWidth="full"
                           (onclick)="googleLogin()">
            Ingresa con Google
          </fp-button-brand>
        </div>
        <div class="mb-3">
          <fp-button-brand brandId="btn--login-apple"
                           brandIcon="apple"
                           brandWidth="full"
                           (onclick)="appleLogin()">
            Ingresa con Apple
          </fp-button-brand>
        </div>
        <div class="mb-2 btn-EmailWithPassword" [ngClass]="{'dn': showEmailAndPassword}">
          <fp-button-outline
          buttonClass="btn--login-email-password"
          buttonDisplay="block"
          [buttonStyle]="{'border-radius': isMifaBrand ? '12px' : '32px'}"
          (click)="openEmailWithPassword()">
          Ingresa con correo electrónico
        </fp-button-outline>
        </div>
      </div>
    </fp-sub-section-container>

    <div class="mt-4 mb-4 login-with-email" [ngClass]="{
        'show': showEmailAndPassword,
        'showWithError': false
      }">
      <fp-sub-section-container [flagBorderTop]="false">
        <div class="content" [formGroup]="mainLoginForm.form">
          <div class="mb-4 text-center font-VAGRoundedNexxt-normal-w-400">ó Ingresa con tu correo y contraseña</div>
          <fp-input-without-title-control [errorMessage]="mainLoginForm.emailControl.emailAddressError">
            <input id="email" autocomplete="off" formControlName="email" class="w-100 input" #inputControl placeholder="Correo electrónico"
              maxlength="50">
          </fp-input-without-title-control>
          <fp-input-password>
            <input id="password" formControlName="password" class="w-100 input" #typeControl placeholder="Contraseña"
              (keyup.enter)="simpleLogin()">
          </fp-input-password>
          <div class="mb-4 mt-4">
            <fp-button id="btnLoginEmailMifarma" buttonId="btnLoginEmailMifarma" buttonClass="btn-block"
              (click)="simpleLogin()" [buttonDisabled]="!mainLoginForm.form.valid || isLoggingIn">
              Ingresa a tu cuenta
            </fp-button>
          </div>
        </div>
      </fp-sub-section-container>
    </div>

    <div class="text-center mb-3">
      <div class="mb-4">
        <fp-link-buttom [buttonText]="'¿Olvidaste tu contraseña?'" [id]="'forgotPassword'" (onclick)="forgotPassword()">
        </fp-link-buttom>
      </div>

      <div class="register">
        <p><span class="mr-3 text-black font-VAGRoundedNexxt-normal-w-400">¿Aún no tienes cuenta?</span>
          <fp-link-buttom [buttonText]="'Regístrate'" [linkSizeClass]="'link-size'" [id]="'register'"
            (onclick)="registerModal()">
          </fp-link-buttom>
        </p>
      </div>
    </div>
  </div>
