import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BreakpointBehavior } from 'src/app/shared/behaviors';
import { BREAKPOINTS } from 'src/app/shared/parameters/global';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fp-no-coverage',
  templateUrl: './no-coverage.component.html',
  styleUrls: ['./no-coverage.component.sass']
})
export class NoCoverageComponent implements OnInit {

  @Output() searchAgainEvent = new EventEmitter();
  public isDesktop = true;

  constructor(
    private breakpointBehavior: BreakpointBehavior,
  ) {
    this.breakpointBehavior
      .mediaBreakpointUpObserver(BREAKPOINTS.lg)
      .pipe(take(1))
      .subscribe(value => this.isDesktop = value.matches);
  }

  ngOnInit() {
  }

  public searchAgain() {
    this.searchAgainEvent.emit();
  }
}
