<ng-container *ngIf="offerPrice">
  <div class="row" [class.text-right]="direction === 'right'"
    [class.text-left]="direction === 'left'">
    <div [ngClass]="{'col-6': place === 'categories','col-5': place !== 'categories'}" class="text-left">
      <p class="mb-0 mt-1 label-black-lg{{fontSize}} label-black-xl label-black-xxl{{fontSize}} label-black-xs{{fontSize}}">
        {{offerPrice | currency: 'S/ '}}
      </p>
    </div>
    <div class="text-left container-icons-card col"><ng-container
        *ngFor="let image of _globalProductCardService.paymentMethodCard.imageArray;first as isFirst">
        <img   class="icon-card" [src]="image" alt="icono tarjeta inkafarma" />
      </ng-container></div>
  </div>

</ng-container>
