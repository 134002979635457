<div class="find-client-modal">
	<fp-section-container-title [title]="modalTitle" svgName="user-colored" [iconSize]="32"> </fp-section-container-title>
	<div class="icon icon-14-close icon-close" (click)="closeModal()"></div>

	<fp-sub-section-container>
		<section [formGroup]="form" class="form-find-client">
			<div class="fc-content field row">
				<span class="field__label col-12">Canal de Venta </span>
				<div class="col-4 pb-4">
					<app-select
						placeholder="Canal de venta"
						[optionList]="optionsFpObjSelect"
						[showClearValueForButton]="true"
						[formControlName]="enumFormFindClient.SALECHANNEL"
						[value]="salesChannelControl.value"
						(clearValueForButton)="clearValueSalesChannel()"
					>
						<ng-template let-saleChannel>{{ saleChannel?.description }}</ng-template>
					</app-select>
				</div>
				<span class="field__label col-12">Busca por documento y por teléfono </span>
				<div class="col-4">
					<fp-custom-select [options]="searchTypes" [formControlName]="enumFormFindClient.SEARCHTYPE"> </fp-custom-select>
				</div>
				<div class="col-8">
					<fp-custom-input
						[formControlName]="enumFormFindClient.CELLPHONE"
						[buttonClear]="true"
						[isSearch]="true"
						labelClass="w-100"
						placeholder="Ingresa tu búsqueda"
						[disabled]="form.invalid"
						(changeValue)="changeValueInput($event)"
						(keyup.enter)="onSubmitForm()"
						fpDigitsOnly
					></fp-custom-input>
				</div>
				<div *ngIf="loader" class="col-12 loader">
					<app-fp-loader></app-fp-loader>
				</div>
				<div class="col-12" *ngIf="stateContent === stateContentModalFindClient.INITIAL_FIND && !loader">
					<fp-content-find-client-initial
						(register)="onRegisterModal()"
						[disabled]="form.invalid"
					></fp-content-find-client-initial>
				</div>
				<div class="col-12" *ngIf="stateContent === stateContentModalFindClient.SUCCESS_FIND && !loader">
					<fp-content-find-client-success
						[data]="dataClient"
						name="find-client"
						[disabledRegistre]="form.invalid"
						(changeSelected)="changeValueRadio($event)"
						(register)="onRegisterModal()"
					></fp-content-find-client-success>
				</div>
				<div class="col-12" *ngIf="stateContent === stateContentModalFindClient.NOT_FOUND && !loader">
					<fp-content-unfind-client
						[cellphone]="notFoundTextSearch"
						(register)="onRegisterModal()"
						[disabled]="form.invalid"
					></fp-content-unfind-client>
				</div>
			</div>
		</section>

		<section
			class="fc-content row botoneras d-flex justify-content-end"
			*ngIf="stateContent === stateContentModalFindClient.SUCCESS_FIND && !loader"
		>
			<div class="mr-3">
				<fp-button-ghost [buttonDisabled]="form.invalid" (click)="onEditModal()">Editar cliente</fp-button-ghost>
			</div>
			<div>
				<fp-button-primary [buttonDisabled]="form.invalid" buttonClass="w-100" (click)="onDispatchModal()"
					>Seleccionar cliente</fp-button-primary
				>
			</div>
		</section>
	</fp-sub-section-container>
</div>
