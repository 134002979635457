import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-product-card-information',
  templateUrl: './product-card-information.component.html',
  styleUrls: ['./product-card-information.component.sass']
})
export class ProductCardInformationComponent implements OnInit {
  @Input() name = '';
  @Input() description = '';
  @Input() customClass = '';
  @Input() fontSize: string = '';

  constructor() {
  }

  ngOnInit() {
  }

}
