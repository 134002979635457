import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CoverageLocationResponse } from '../../models/coverage-location/coverage-location.model';

@Injectable({
  providedIn: 'root'
})
export class CoverageLocationStoreService {

  private _coverageLocationResponse: CoverageLocationResponse;
  private _coverageLocationResponseSubject = new BehaviorSubject<CoverageLocationResponse>({} as CoverageLocationResponse);
  public coverageLocationResponse$ = this._coverageLocationResponseSubject.asObservable();
  public get coverageLocationResponse() {
    return this._coverageLocationResponse;
  }

  get isCovered() {
    return this.coverageLocationResponse.notCoveraged;
  }

  constructor() {
    this.coverageLocationResponse$.subscribe(response => this._coverageLocationResponse = response);
  }

  public setCoverageLocationResponse(response: CoverageLocationResponse) {
    this._coverageLocationResponseSubject.next(response);
  }
}
