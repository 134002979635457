import { ContentfulEntry } from './../../../shared/models/contentful/contentful.model';
import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Entry } from 'contentful';
import { ContentfulShortcut, IContentfulShortcut } from './contentful-shortcut.model';
import { BACKGROUND_DEFECT } from 'src/app/business/checkout/parameters/constants';

export interface IContentfulTextoLibre {
    contenido: string;
    background :string;
}

export class ContentfulTextoLibre {
    contenido: string;
    background :string;

    constructor(iContentfulTextoLibre: IContentfulTextoLibre) {
        this.contenido = iContentfulTextoLibre.contenido;
        this.background = iContentfulTextoLibre.background || BACKGROUND_DEFECT;
    }
}

export const CContentfulTextoLibre = {
    id: CONTENTFUL_PATH.contentTypeIds.textoLibre
};

