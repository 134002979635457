import { DOCUMENT_TYPE } from "@parameters/global";
import { isObject } from "../../helpers/objects-equal";

export interface ISelectItem {
  id: string;
  name: string;
}

export class SelectItem {
  public id: string;
  public name: string;

  constructor(data: ISelectItem) {
    const current = isObject(data) ? data : ({} as ISelectItem);
    this.id = current.id || DOCUMENT_TYPE.DNI;
    this.name = current.name || "";
  }
}
