import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "fp-textarea",
  templateUrl: "./textarea.component.html",
  styleUrls: ["./textarea.component.sass"],
})
export class TextareaComponent implements OnInit, AfterContentInit {
  @Input() textareaClass = "";
  @Input() textareaPlaceholder = "";
  @Input() textareaResize: boolean;
  @Input() textareaFocus: boolean;
  @Input() textareaName = "textarea";
  @Input() textareaId: string | number = "textarea";
  @Input() textareaWidth: string;
  @Input() textareaHeight: string;

  @Input() textareaForm: FormGroup = new FormGroup({});
  @Input() textareaFormControlName = "textarea";
  @Input() textareaValidations: boolean;
  @Input() textareaDisabled: boolean;
  @Input() textareaRequired: boolean;
  @Input() textareaMaxLength: number;
  @Input() counter: boolean;
  @Output() textareaValue = new EventEmitter();

  lengthText = 0;

  public value: string;

  constructor(private _formBuilder: FormBuilder) {}
  ngAfterContentInit(): void {
    this.textareaForm
      .get(this.textareaFormControlName)
      .valueChanges.subscribe((text: string) => {
        this.lengthText = text.length;
      });
  }

  ngOnInit(): void {
    const controlValue = this.setControlValues();
    const controlValidations = this.setControlValidations();

    const control = this._formBuilder.control(controlValue, controlValidations);

    this.textareaForm.addControl(this.textareaFormControlName, control);
  }

  valueChanges() {
    this.textareaValue.emit(this.value);
  }

  setControlValidations() {
    let controlValidation = [];
    if (this.textareaValidations) {
      if (this.textareaRequired) {
        controlValidation = [...controlValidation, Validators.required];
      }

      if (!!this.textareaMaxLength) {
        controlValidation = [
          ...controlValidation,
          Validators.maxLength(this.textareaMaxLength),
        ];
      }
    }
    return controlValidation;
  }

  setControlValues() {
    let controlValue: any = "";
    if (this.textareaDisabled) {
      controlValue = { value: "", disabled: this.textareaDisabled };
    }

    return controlValue;
  }
}
