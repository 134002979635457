import { Injectable } from "@angular/core";
import { SnackbarAlertService } from "src/app/core/modules/snackbar/snackbar-alert.service";
import { UserRegister } from "../models/register/register.model";
import { ESnackbarMessageCodes } from "../parameters/snackbar-message-codes.parameter";
import { UserInfoStoreService } from "../stores/user/user-info-store.service";
import { AppStoreService } from "./../stores/app/app-store.service";

const NAME_TO_REPLACE = "$NAME$";

const INKACLUB_LINK = `<a href="http://inkafarma.pe/inkaclub" class="label-black text-secondary">inkafarma.pe/inkaclub</a>`;

// Mensajes de registro o actualización exitosos en INKAFARMA y/o INKACLUB

const TEXT01 = `¡Bienvenido ${NAME_TO_REPLACE}! Tu registro a Inkafarma.pe se realizó correctamente`;
const TEXT02 = `¡Bienvenido ${NAME_TO_REPLACE}! Tu registro a Inkafarma.pe y actualización de datos se realizaron correctamente.`;
const TEXT03 = `¡Bienvenido ${NAME_TO_REPLACE}! Te has registrado correctamente a Inkafarma.pe`;
const TEXT04 = `¡Tus datos se han actualizado correctamente!`;
const TEXT05 = `Se han actualizado tus datos y te has inscrito a Inkaclub correctamente.`;

// Mensajes de registro o actualización no exitosos por PROBLEMAS en INKACLUB

const TEXT_ERROR01 = `Tu inscripción no se pudo completar. Inscríbete ingresando a ${INKACLUB_LINK}`;
const TEXT_ERROR02 = `No pudimos actualizar tus datos. Por favor, actualízalos ingresando a ${INKACLUB_LINK}`;

@Injectable()
export class LoginRegisterSnackbarsHelperService {
  private readonly FINAL_REGISTER_CODE_KEY = "FRCK";
  private DELAY_TO_SHOW_SECOND_SNACKBAR = 4000;

  private user = {} as UserRegister;

  constructor(
    private snackbarAlert: SnackbarAlertService,
    private appStore: AppStoreService,
    public userInfoStore: UserInfoStoreService
  ) {}

  private get fullName() {
    const { name, maternalLastName, paternalLastName } = this.user;
    return `${name} ${paternalLastName} ${maternalLastName}`;
  }

  // mensajes exitosos

  public showSnackbarSuccessInkafarmaInkaclub01() {
    const currentText = TEXT01.replace(NAME_TO_REPLACE, this.fullName);
    this.snackbarAlert.snackbarAlertSuccess(currentText);
  }

  public showSnackbarSuccessInkafarmaInkaclub02() {
    const currentText = TEXT02.replace(NAME_TO_REPLACE, this.fullName);
    this.snackbarAlert.snackbarAlertSuccess(currentText);
  }

  public showSnackbarSuccessInkafarmaInkaclub03() {
    this.snackbarAlert.snackbarAlertSuccess(TEXT05);
  }

  public showSnackbarSuccessInkafarma01() {
    const currentText = TEXT03.replace(NAME_TO_REPLACE, this.fullName);
    this.snackbarAlert.snackbarAlertSuccess(currentText);
  }

  public showSnackbarSuccessInkafarma02() {
    this.snackbarAlert.snackbarAlertSuccess(TEXT04);
  }

  // mensajes de advertencia o error

  public showSnackbarWarningInkaclub01() {
    this.snackbarAlert.snackbarAlertInfo(TEXT_ERROR01);
  }

  public showSnackbarWarningInkaclub02() {
    this.snackbarAlert.snackbarAlertInfo(TEXT_ERROR02);
  }

  // métodos para trabajar el codigo de respuesta de registro a inkafarma e inkaclub

  public setSnackbarMessageCode(code: ESnackbarMessageCodes) {
    const { FINAL_REGISTER_CODE_KEY } = this;
    this.appStore.setStorageItem(FINAL_REGISTER_CODE_KEY, `${code}`);
  }

  public triggerSnackbarByRegisterCode() {
    this.user = this.userInfoStore.getUser() as any;
    const { FINAL_REGISTER_CODE_KEY } = this;
    const code = this.appStore.getStorageItem(FINAL_REGISTER_CODE_KEY);
    if (code) {
      /**
       * Los nombres que están en el atributo @snackbarMethods
       * son los nombres de los métodos dentro de este servicio
       * que se encargaran de lanzar los snackbars
       */
      const SnackbarMethodsByCode = [
        {
          code: ESnackbarMessageCodes.INKAFARMA_REGISTER_OK,
          snackbarMethods: "showSnackbarSuccessInkafarma01",
        },
        {
          code: ESnackbarMessageCodes.INKAFARMA_REGISTER_OK_INKACLUB_ERROR_OK,
          snackbarMethods:
            "showSnackbarSuccessInkafarma01,showSnackbarWarningInkaclub01",
        },
        {
          code: ESnackbarMessageCodes.INKAFARMA_REGISTER_OK_INKACLUB_REGISTER_OK,
          snackbarMethods: "showSnackbarSuccessInkafarmaInkaclub01",
        },
        {
          code: ESnackbarMessageCodes.INKAFARMA_REGISTER_OK_INKACLUB_UPDATED_OK,
          snackbarMethods: "showSnackbarSuccessInkafarmaInkaclub02",
        },
        {
          code: ESnackbarMessageCodes.INKAFARMA_UPDATED_OK,
          snackbarMethods: "showSnackbarSuccessInkafarma02",
        },
        {
          code: ESnackbarMessageCodes.INKAFARMA_UPDATED_OK_INKACLUB_ERROR_OK,
          snackbarMethods:
            "showSnackbarSuccessInkafarma02,showSnackbarWarningInkaclub02",
        },
        {
          code: ESnackbarMessageCodes.INKAFARMA_UPDATED_OK_INKACLUB_REGISTER_OK,
          snackbarMethods: "showSnackbarSuccessInkafarmaInkaclub03",
        },
        {
          code: ESnackbarMessageCodes.INKAFARMA_UPDATED_OK_INKACLUB_UPDATED_OK,
          snackbarMethods: "showSnackbarSuccessInkafarma02",
        },
      ];
      const matchedMethods = SnackbarMethodsByCode.find(
        (o) => `${o.code}` === code
      );
      if (matchedMethods) {
        // aquí obtenemos los métodos de los snackbars a lanzar
        const methods = matchedMethods.snackbarMethods.split(",");
        this[methods[0]]();
        if (methods.length > 1) {
          setTimeout(() => {
            this[methods[1]]();
          }, this.DELAY_TO_SHOW_SECOND_SNACKBAR);
        }
      }
      setTimeout(() => {
        this.appStore.removeStorageItem(FINAL_REGISTER_CODE_KEY);
      }, 500);
    }
  }
}
