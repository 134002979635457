import { environment } from 'src/environments/environment';

export const GOOGLE_MAP_KEY = environment.firebase.apiKey;
export const PREDICTIVE_MATCHES_LENGHT = 3;






