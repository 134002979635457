<div class="row justify-content-center m-0">
  <div class="col-12" *ngIf="!productInShoppingCart">
    <fp-button-primary buttonDisplay="block"
      [buttonDisabled]="productStatus === eProductStatus.unavailable && product.isFromSeller"
      [buttonStyle]="buttonStyle"
      (click)="addProductToShoppingCart()">
      Agregar al carrito
    </fp-button-primary>
  </div>
  <div class="p-0 col-12" *ngIf="productInShoppingCart">
    <fp-product-quantity [quantityLimit]="quantityLimit" [productQuantity]="quantity" [product]="product" [isSeller]="product.isFromSeller"
      [rounded]="true"
      (updateQuantity)="updateQuantity($event)" (deleted)="doDeleted($event)">
    </fp-product-quantity>
  </div>
</div>
