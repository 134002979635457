import { FormControl, Validators } from '@angular/forms';
import { GenericValidator } from 'src/app/shared/validators/generic-validator';
import { NAME_ERROR_MESSAGES } from '../../../shared/parameters/error-message-forms';
import { getErrorbyFormControl } from 'src/app/shared/helpers/error-controls-helpers';

export class NameControl extends FormControl {

  constructor() {
    super('');
    this.settingNameValidators();
  }

  private settingNameValidators() {
    this.setValidators([
      Validators.required,
      GenericValidator.validateCharacters
    ]);
  }

  public get nameError(): string {
    return getErrorbyFormControl(this, NAME_ERROR_MESSAGES);
  }

}
