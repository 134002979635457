import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericService } from '../generic';
import { ENDPOINTS } from '../../parameters/endpoints';
import { IScheduleRequestParams, IScheduleResponse, ScheduleItem, ScheduleResponseForRET } from '../../models/schedule/schedule.model';
import { isArray } from '../../helpers/objects-equal';

@Injectable()
export class ScheduleClientService {

  private readonly SCHEDULE_ENDPOINT = ENDPOINTS.GET_DELIVERY_INFO_SCHEDULE;

  constructor(
    private genericService: GenericService,
  ) { }

  public getScheduleDelivery$(params: IScheduleRequestParams): Observable<ScheduleItem[]> {
    const httpParams = new HttpParams()
      .set('day', String(params.day))
      .set('isToday', String(params.isToday))
      .set('userAddressId', String(params.userAddressId))
      .set('drugstoreId', String(params.drugstoreId))
      .set('deliveryTypeId', String(params.deliveryTypeId))
      .set('isOldVersion', String('N'));
    return this.genericService.genericGet<IScheduleResponse>(this.SCHEDULE_ENDPOINT, httpParams)
      .pipe(map(response => {
        const scheduleItems = isArray(response.schedules) ? response.schedules : [];
        return scheduleItems.map(item => new ScheduleItem(item));
      }));
  }

  public getScheduleForRET$(params: IScheduleRequestParams): Observable<ScheduleResponseForRET> {
    const httpParams = new HttpParams()
      .set('drugstoreId', String(params.drugstoreId))
      .set('deliveryTypeId', String(params.deliveryTypeId))
      .set('isOldVersion', String('N'));
    return this.genericService.genericGet<IScheduleResponse>(this.SCHEDULE_ENDPOINT, httpParams)
      .pipe(map(response => {
        const customResponse = new ScheduleResponseForRET(response);
        return customResponse;
      }));
  }

}
