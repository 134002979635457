import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * the modalManagerAction value should be the exactly name of the RegisterModalsMaganerService method
 */
export interface IActionModal {
  modalManagerAction: string;
  data?: any;
}

// open/close simple methods
const OPEN_ANONYMOUS_WARNING_MODAL_ACTION: IActionModal = { modalManagerAction: 'openAnonymousWarningModal' };
const CLOSE_ANONYMOUS_WARNING_MODAL_ACTION: IActionModal = { modalManagerAction: 'closeAnonymousWarningModal' };
const OPEN_CART_LOGIN_MODAL_ACTION: IActionModal = { modalManagerAction: 'openCartLoginModal',data: {} };
const CLOSE_CART_LOGIN_MODAL_ACTION: IActionModal = { modalManagerAction: 'closeCartLoginModal' };
const OPEN_FORGOT_PASS_MODAL_ACTION: IActionModal = { modalManagerAction: 'openForgotPasswordModal' };
const CLOSE_FORGOT_PASS_MODAL_ACTION: IActionModal = { modalManagerAction: 'closeForgotPasswordModal' };
const OPEN_FORGOT_PASS_CONFIRM_MODAL_ACTION: IActionModal = { modalManagerAction: 'openForgotPasswordConfirmationModal' };
const CLOSE_FORGOT_PASS_CONFIRM_MODAL_ACTION: IActionModal = { modalManagerAction: 'closeForgotPasswordConfirmationModal' };
const OPEN_RESP_FORGOT_PASS_CONFIRM_MODAL_ACTION: IActionModal = { modalManagerAction: 'openResponsiveForgotPasswordConfirmationModal' };
const CLOSE_RESP_FORGOT_PASS_CONFIRM_MODAL_ACTION: IActionModal = { modalManagerAction: 'closeResponsiveForgotPasswordConfirmationModal' };
const OPEN_MAIN_MODAL_MODAL_ACTION: IActionModal = { modalManagerAction: 'openMainLoginModal' };
const CLOSE_MAIN_MODAL_MODAL_ACTION: IActionModal = { modalManagerAction: 'closeMainLoginModal' };
const OPEN_MAIN_MODAL_AFILIACION_MODAL_ACTION: IActionModal = { modalManagerAction: 'openMainLoginAfiliacionModal' };
const OPEN_RESP_MAIN_MODAL_MODAL_ACTION: IActionModal = { modalManagerAction: 'openResponsiveMainLoginModal' };
const CLOSE_RESP_MAIN_MODAL_MODAL_ACTION: IActionModal = { modalManagerAction: 'closeResponsiveMainLoginModal' };
const CLOSE_MAIN_MODAL_AFILIACION_MODAL_ACTION: IActionModal = { modalManagerAction: 'closeMainLoginAfiliacionModal' };
@Injectable()
export class LoginModalsManagerHelperService {

  private actionSubject = new Subject<IActionModal>();
  public action$ = this.actionSubject.asObservable();

  constructor() { }

  public openAnonymousWarningModal() {
    this.actionSubject.next(OPEN_ANONYMOUS_WARNING_MODAL_ACTION);
  }

  public closeAnonymousWarningModal() {
    this.actionSubject.next(CLOSE_ANONYMOUS_WARNING_MODAL_ACTION);
  }

  public openCartLoginModal(data?: any) { 
    const action: IActionModal = { modalManagerAction: 'openCartLoginModal', data };
    this.actionSubject.next(action);
  }

  public closeCartLoginModal() {
    this.actionSubject.next(CLOSE_CART_LOGIN_MODAL_ACTION);
  }
  public openForgotPasswordModal() {
    this.actionSubject.next(OPEN_FORGOT_PASS_MODAL_ACTION);
  }

  public closeForgotPasswordModal() {
    this.actionSubject.next(CLOSE_FORGOT_PASS_MODAL_ACTION);
  }
  public openForgotPasswordConfirmationModal() {
    this.actionSubject.next(OPEN_FORGOT_PASS_CONFIRM_MODAL_ACTION);
  }

  public closeForgotPasswordConfirmationModal() {
    this.actionSubject.next(CLOSE_FORGOT_PASS_CONFIRM_MODAL_ACTION);
  }
  public openResponsiveForgotPasswordConfirmationModal() {
    this.actionSubject.next(OPEN_RESP_FORGOT_PASS_CONFIRM_MODAL_ACTION);
  }

  public closeResponsiveForgotPasswordConfirmationModal() {
    this.actionSubject.next(CLOSE_RESP_FORGOT_PASS_CONFIRM_MODAL_ACTION);
  }
  public openMainLoginModal() {
    this.actionSubject.next(OPEN_MAIN_MODAL_MODAL_ACTION);
  }
  public closeMainLoginModal() {
    this.actionSubject.next(CLOSE_MAIN_MODAL_MODAL_ACTION);
  }
  public openMainLoginAfiliacionModal() {
    this.actionSubject.next(OPEN_MAIN_MODAL_AFILIACION_MODAL_ACTION);
  }
  public openResponsiveMainLoginModal() {
    this.actionSubject.next(OPEN_RESP_MAIN_MODAL_MODAL_ACTION);
  }
  public closeResponsiveMainLoginModal() {
    this.actionSubject.next(CLOSE_RESP_MAIN_MODAL_MODAL_ACTION);
  }
  public closeMainLoginAfiliacionModal() {
    this.actionSubject.next(CLOSE_MAIN_MODAL_AFILIACION_MODAL_ACTION);
  }
}
