import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { ProductCard } from "src/app/core/modules/product-card/models/product-card/product-card.model";
import { EProductPresentation } from "src/app/core/modules/product-card/models/product-presentation.enum";
import { ProductShoppingCart } from "src/app/core/modules/product-card/models/product-shopping-cart/product-shopping-cart.model";
import { isParsableJSON } from "src/app/shared/helpers/json-parse.helper";
import { ProductShoppingCartRequest } from "../../models/shopping-cart/product-shopping-cart-request.model";
import { AppStoreService } from "../app/app-store.service";
import { LOCALSTORAGE_OLD_SHOPPING_LIST, LOCALSTORAGE_SHOPPING_CART_LIST } from "./constants/shopping-list.constant";

@Injectable()
export class ShoppingListStoreService {
	public shoppingList: ProductShoppingCartRequest[];
	private shoppingListSubject = new BehaviorSubject<ProductShoppingCartRequest[]>([]);
	private productShoppingCartList: ProductShoppingCart[];
	private productShoppingCartSubject = new BehaviorSubject<ProductShoppingCart[]>([]);
	private shoppingListStoreShoppingCartList: ProductShoppingCartRequest[];
	private _loadCardEndpoint = true;
	private hasSellerIssue: Boolean;
	private hasSellerIssueSubject = new BehaviorSubject<Boolean>(false);
	private isInActiveOrderSeller: Boolean;
	private isInActiveOrderSellerSubject = new BehaviorSubject<Boolean>(false);
	private productIssue: ProductCard;
	private productIssueSubject = new BehaviorSubject<ProductCard>(null);
	private productIssueList: ProductCard[];
	private productIssueListSubject = new BehaviorSubject<ProductCard[]>([]);
	public hasSellerIssue$ = this.hasSellerIssueSubject.asObservable();
	public isInActiveOrderSeller$ = this.isInActiveOrderSellerSubject.asObservable();
	public productIssue$ = this.productIssueSubject.asObservable();
	public productIssueList$ = this.productIssueListSubject.asObservable();
	private cartCode: string;
	private statusCode: string;
	private enabledButtonConfirmSubject = new BehaviorSubject<boolean>(false);
	private hasControlledProductsSubject = new BehaviorSubject<Boolean>(false);
	public hasControlledProducts$ = this.hasControlledProductsSubject.asObservable();

	constructor(private _appStore: AppStoreService) {
		const shoppingList = this.getShoppingList();
		this.updateShoppingList(shoppingList);
		this.shoppingList$.subscribe((_shoppingList) => (this.shoppingList = _shoppingList));
		this.hasSellerIssue$.subscribe((_hasSellerIssue) => (this.hasSellerIssue = _hasSellerIssue));
		this.isInActiveOrderSeller$.subscribe((_isInActiveOrderSeller) => (this.isInActiveOrderSeller = _isInActiveOrderSeller));
		this.productIssue$.subscribe((_productIssue) => (this.productIssue = _productIssue));
		this.productIssueList$.subscribe((_productIssueList) => (this.productIssueList = _productIssueList));
		this.loadEmptyShoppingList();
	}

	private loadEmptyShoppingList() {
		const oldShoppingList = this._appStore.getStorageItem(LOCALSTORAGE_OLD_SHOPPING_LIST);
		if (oldShoppingList) {
			// exits!
			const isParsable = isParsableJSON(oldShoppingList);
			if (!isParsable) {
				this._appStore.setStorageItem(LOCALSTORAGE_OLD_SHOPPING_LIST, JSON.stringify([]));
			}
		} else {
			// not exist!
			this._appStore.setStorageItem(LOCALSTORAGE_OLD_SHOPPING_LIST, JSON.stringify([]));
		}
	}

	private get shoppingListStore$() {
		return this._appStore.storage$.pipe(
			map((event) => event.storageArea[LOCALSTORAGE_SHOPPING_CART_LIST]),
			filter((value) => value !== JSON.stringify(this.shoppingList)),
			map((value) => this.stringToProductType(value))
		);
	}

	public set shoppingListStore(list: string) {
		this._appStore.setStorageItem(LOCALSTORAGE_SHOPPING_CART_LIST, list);
	}

	public get shoppingListStore(): string {
		return this._appStore.getStorageItem(LOCALSTORAGE_SHOPPING_CART_LIST);
	}

	public removeShoppingListStore(): void {
		this._appStore.removeStorageItem(LOCALSTORAGE_SHOPPING_CART_LIST);
	}

	public getLocalStorageShoppingCartList() {
		return (this.shoppingListStoreShoppingCartList = JSON.parse(localStorage.getItem(LOCALSTORAGE_SHOPPING_CART_LIST)));
	}

	public get shoppingList$() {
		return this.shoppingListSubject.asObservable();
	}
	public get productShoppingCart$() {
		return this.productShoppingCartSubject.asObservable();
	}
	public get getStaticShoppingList() {
		return this.getLocalStorageShoppingCartList();
	}
	public get staticShoppingList() {
		return this.shoppingList;
	}
	public set productShoppingCart(shoppingList: Array<ProductShoppingCart>) {
		this.productShoppingCartList = shoppingList;
		this.productShoppingCartSubject.next(shoppingList);
	}
	public get getCartCode() {
		return this.cartCode;
	}

	public set getCartCode(code: string) {
		this.cartCode = code;
	}

	public set staticShoppingList(shoppingList: Array<ProductShoppingCartRequest>) {
		this.shoppingList = shoppingList;
	}

	public getHasSellerIssue() {
		return this.hasSellerIssue;
	}

	public setHasSellerIssue(param: Boolean) {
		return this.hasSellerIssueSubject.next(param);
	}

	public getIsInActiveOrderSeller() {
		return this.isInActiveOrderSeller;
	}

	public setIsInActiveOrderSeller(param: Boolean) {
		return this.isInActiveOrderSellerSubject.next(param);
	}

	public getProductIssue() {
		return this.productIssue;
	}

	public setProductIssue(product) {
		return this.productIssueSubject.next(product);
	}

	public getProductIssueList() {
		return this.productIssueList;
	}

	public setProductIssueList(products) {
		return this.productIssueListSubject.next(products);
	}

	public updateShoppingList(shoppingCartRequest: ProductShoppingCartRequest[]) {
		this.shoppingListStore = JSON.stringify(shoppingCartRequest);
		this.shoppingListSubject.next(shoppingCartRequest);
	}

	public setShoppingListSubject(shoppingCartRequest?: ProductShoppingCartRequest[]) {
		const shoppingCartListRequest = shoppingCartRequest ? shoppingCartRequest : [];
		alert('ji2')
		this.shoppingListSubject.next(shoppingCartListRequest);
	}
	public setShoppingList(shoppingCartRequest: ProductShoppingCartRequest[]) {
		if (shoppingCartRequest.length >= 0) {
			this.updateShoppingList(shoppingCartRequest);
		}
	}

	// todo: validar que el string parseado sea de tipo Product
	public getShoppingList() {
		const shoppingListString = this.shoppingListStore;
		return this.stringToProductType(shoppingListString);
	}

	public removeShoppingList(): void {
		this.setShoppingList([]);
		this.removeShoppingListStore();
	}

	private stringToProductType(shoppingListString) {
		/**
		 * Si el arreglo en el localstorage no tiene sellerId, entonces
		 * se le pondrá por defecto DEFAULT_SELLER_ID.
		 * Tener cuidado con cambiar el valor del DEFAULT_SELLER_ID.
		 */
		if (isParsableJSON(shoppingListString) && Array.isArray(JSON.parse(shoppingListString))) {
			const temporalShoppingList = JSON.parse(shoppingListString) as Array<any>;
			return temporalShoppingList.map((p) => {
				return {
					productId: p.productId || "",
					presentationIdSelected: p.presentationIdSelected || EProductPresentation.pack,
					quantity: p.quantity || 0,
					sellerId: p.sellerId || "",
					sellerName: p.sellerName || "",
					productAliviamed: p.productAliviamed || null
				} as ProductShoppingCartRequest;
			});
		}
		return [];
	}

	public get isValidShoppingList() {
		const { shoppingList } = this;
		const allIsValid = shoppingList.every((p) => p.quantity > 0);
		return allIsValid;
	}

	public get getLoadCardEnpoint() {
		return this._loadCardEndpoint;
	}

	public set setLoadCardEndpoint(value: boolean) {
		this._loadCardEndpoint = value;
	}
	public get shoppinListWithoutStock() {
		const { productShoppingCartList } = this;
		return productShoppingCartList.find((p) => p.stock == 0 || p.stock < p.quantity);
	}

	get statusCodeValidateCart() {
		return this.statusCode;
	}

	set statusCodeValidateCart$(status: string) {
		this.statusCode = status;
	}

	public get enabledButtonConfirm$() {
		return this.enabledButtonConfirmSubject.asObservable();
	}

	public setEnabledButtonConfirm(status: boolean) {
		this.enabledButtonConfirmSubject.next(status);
	}

	public setHasControlledProducts(param: Boolean) {
		return this.hasControlledProductsSubject.next(param);
	}
}
