import { Injectable } from '@angular/core';

@Injectable()
export class RegisterOriginStoreService {

  private _emailVerificationOrigin: string;
  private _accessCheckoutByRegister: boolean;

  constructor() {
  }

  public get emailVerificationOrigin() {
    return this._emailVerificationOrigin;
  }

  public set emailVerificationOrigin(emailVerificationOrigin: string) {
    this._emailVerificationOrigin = emailVerificationOrigin;
  }

  public set accessCheckoutByRegister(accessCheckoutByRegister: boolean) {
    this._accessCheckoutByRegister = accessCheckoutByRegister;
  }

  public get accessCheckoutByRegister() {
    return this._accessCheckoutByRegister;
  }



}
