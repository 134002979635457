import { FormControl } from "@angular/forms";
import { GenericValidator } from "src/app/shared/validators/generic-validator";
import { getErrorbyFormControl } from "../helpers/error-controls-helpers";
import { MOTHER_LAST_NAME_ERROR_MESSAGES } from "../parameters/global-error-message";

export class MotherLastNameOrderTakerControl extends FormControl {
  constructor() {
    super("");
    this.settingFirstNameValidators();
  }

  private settingFirstNameValidators() {
    this.setValidators([GenericValidator.validateCharacters]);
  }

  public get nameError(): string {
    return getErrorbyFormControl(this, MOTHER_LAST_NAME_ERROR_MESSAGES);
  }
}
