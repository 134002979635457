import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { CartControlledStoreService } from "src/app/business/cart/services/cart-controlled-store.service";
import { GenericModalErrorComponent } from "src/app/shared/components/generic-modal-error/generic-modal-error.component";

@Component({
	selector: "fp-cart-controlled-form-file-modal",
	templateUrl: "./cart-controlled-form-file-modal.component.html",
	styleUrls: ["./cart-controlled-form-file-modal.component.sass"]
})
export class CartControlledFormFileModalComponent implements OnInit {
	@ViewChild("cameraInput") cameraInput: ElementRef;
	@ViewChild("photoLibraryInput") photoLibraryInput: ElementRef;
	@ViewChild("fileInput") fileInput: ElementRef;

	uploadFileOptions = [
		{ id: 1, icon: "camera", label: "Cámara", value: "camera" },
		{ id: 2, icon: "image", label: "Biblioteca de fotos", value: "photoLibrary" },
		{ id: 3, icon: "file", label: "Seleccionar archivo", value: "file" }
	];
	public dialogRef2: MatDialogRef<GenericModalErrorComponent>;
	MAX_SIZE;
	MAX_FILES;
	INFO_CONTROLLED;
	fileSelected;
	filesSelected = [];
	constructor(
		public dialogRef: MatDialogRef<CartControlledFormFileModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private matDialog: MatDialog,
		private _cartControlledStoreservice: CartControlledStoreService
	) {}

	ngOnInit() {
		this.INFO_CONTROLLED = JSON.parse(localStorage.getItem("controllated-sale"));
		this.MAX_SIZE = (this.INFO_CONTROLLED.maxFileSizeAllowed / 1000) * 1024 * 1024;
		this.MAX_FILES = this.INFO_CONTROLLED.maxFileCountAllowed;
	}

	onClose(value) {
		this.dialogRef.close(value);
	}
	selectOption(value) {
		this.onClose(value);
	}
	handleFileInput(event: Event) {
		// this.handleFileRecipe(event)

		const file = (event.target as HTMLInputElement).files[0];
		if (file) {
			if (file.size > this.MAX_SIZE) {
				this.dialogRef2 = this.matDialog.open(GenericModalErrorComponent, {
					width: "380px",
					disableClose: false,
					panelClass: "custom-error-modal"
				});
				this.dialogRef.afterClosed().subscribe(() => {});
			} else {
				this.fileSelected = file;
				this.filesSelected.push(file);
				this._cartControlledStoreservice.setRecipefile(this.filesSelected);
				this.fileInput.nativeElement.value = "";
				this.cameraInput.nativeElement.value = "";
				this.photoLibraryInput.nativeElement.value = "";
				this.dialogRef.close();

			}
		}
	}
}
