<section class="unfind-cliente-container">
  <div class="unfind-cliente__icon">
    <fp-icon iconName="face_bad" iconSize="48"></fp-icon>
  </div>
  <p class="unfind-cliente__title">Ocurrió un problema con el sistema</p>
  <span class="unfind-cliente__subTitle mb-5"
    >Para visualizar los métodos de despacho actualiza los servicios de
    despacho</span
  >
  <fp-button-outline buttonDisplay="flex" (click)="onClick()">
    <span>Actualiza los servicios</span>
  </fp-button-outline>
</section>
