import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {
  ProductDetail,
  ProductSeoAtrtibutes,
} from "src/app/business/product-detail/models/product-detail-model";
import { ProductCard } from "src/app/core/modules/product-card/models/product-card/product-card.model";
import { ProductDetailClientService } from "src/app/shared/service/product-detail/product-detail-client.service";

@Injectable({
  providedIn: "root",
})
export class ProductDetailImplementService {
  private _productDetailLoaderSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(true);

  constructor(private productDetailClient: ProductDetailClientService) {}

  getProductDetail(
    productId: string
  ): Observable<[ProductDetail, ProductCard]> {
    return this.productDetailClient.getProductDetailById(productId);
  }

  getSeoAttributes(productId: string): Observable<ProductSeoAtrtibutes> {
    return this.productDetailClient.getProductSeoAttributes(productId);
  }


  public set productDetailLoader(loader: boolean) {
    this._productDetailLoaderSubject.next(loader);
  }

  public get productDetailLoader$() {
    return this._productDetailLoaderSubject.asObservable();
  }
}
