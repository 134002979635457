import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SERVICES_TYPES_ENUM } from "@parameters/global";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { AddressListStoreService } from "src/app/core/modules/address-manager/address-stores/address-list-store.service";
import { StoresForPickUpStoreService } from "src/app/core/modules/pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service";
import { SelectDataStoreService } from "../../office/store/select-data-store.service";
import { CheckoutTypeOfDeliveryFormStoreService } from "../checkout-stores/checkout-type-of-delivery-form-store.service";
import { DeliveryTypeControl } from "../controls/delivery-type-control";
import { RAD_SHORTNAME, RET_SHORTNAME } from "../parameters/global-config";
import { CheckoutPaymentMethodFormService } from "./checkout-payment-method-form.service";

@Injectable()
export class CheckoutTypeOfDeliveryFormService {
  public form: FormGroup;
  private _deliveryType = new DeliveryTypeControl();

  private subscriptions: Subscription[] = [];

  private selectedAddressHasCoverage = false;

  constructor(
    private formBuilder: FormBuilder,
    private typeOfDeliveryFormStoreService: CheckoutTypeOfDeliveryFormStoreService,
    private addressListStore: AddressListStoreService,
    private storesForRET: StoresForPickUpStoreService,
    private _selectDataStore: SelectDataStoreService
  ) {
    this.form = this.formBuilder.group({
      deliveryType: this._deliveryType,
    });
    this.loadInitialFormValues();
    this.settingControlValues();
    this.addressListStore.selectedAddressHasCoverage$.subscribe(
      (status) => (this.selectedAddressHasCoverage = status)
    );
  }

  public get deliveyTypeControl() {
    return this.form.get("deliveryType") as DeliveryTypeControl;
  }

  private loadInitialFormValues() {
    this.typeOfDeliveryFormStoreService.form$
      .pipe(take(1))
      .subscribe((form) => {
        this.form.setValue({
          deliveryType: form.deliveryType, // 0: RAD, 1: RET
        });
      });
  }

  private settingControlValues() {
    const deliveryTypeSubscription =
      this.deliveyTypeControl.valueChanges.subscribe((value) => {
        if (OrderTakerValidator.isOrderTakerEnv()) {
          const RET_ID = 1;
          this._selectDataStore.saveServiceSelected(
            value == RET_ID ? SERVICES_TYPES_ENUM.RETIRO : null
          );
        }
        this.typeOfDeliveryFormStoreService.setDeliveyTypeValue(
          value as number
        );
      });
    this.subscriptions.push(deliveryTypeSubscription);
  }

  public upsubscribeObservers() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public get isValidTypeOfDeliveryForm() {
    // 0: RAD, 1: RET
    const selectedAddress = this.addressListStore.selectedAddress;
    const selectedDrugstore = this.storesForRET.selectedDrugstore;
    const deliveryType = this.deliveyTypeControl.value as number;
    if (deliveryType === 0) {
      return {
        deliveryType: RAD_SHORTNAME,
        status: !!selectedAddress.id && this.selectedAddressHasCoverage,
        errorMessage: !this.selectedAddressHasCoverage
          ? "Ingrese una dirección con cobertura"
          : "Por favor ingrese una dirección.",
      };
    } else {
      return {
        deliveryType: RET_SHORTNAME,
        status: !!selectedDrugstore.id,
        errorMessage: "Por favor seleccione una botica",
      };
    }
  }

  public markAsTouched() {
    this.deliveyTypeControl.markAsTouched();
  }
}
