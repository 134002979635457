<div class="address-list-modal" id="core-address-list-modal">
  <span class="happy-face-img">
    <img src="./assets/images/main/happy-face.svg" alt="happy-face" class="">
  </span>
  <div class="button-modal-close" (click)="closeModal()">
    <span class="icon icon-24-close"></span>
  </div>

  <div class="title">
    ¿Cómo entregaremos tu pedido?
  </div>
  <div class="content">
    <div class="mt-3 sub-title">
      Selecciona un método de entrega para ofrecerte una mejor experiencia
    </div>
    <div class="scroll-address-radio-button mt-3">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div *ngIf="rad">
              <fp-border-container [selected]="checkRad">
                <div class="radio-control-container position-radio" (click)="selectSection('rad')">
                  <label class="radio-control">
                    <input type="radio" class="input-radio" [checked]="checkRad">
                    <span class="check-mark"></span>
                  </label>
                  <div class="main-header location-selected-container">
                    <fp-icon iconSize="20" iconName="icon-camion"></fp-icon>
                    <div class="content-titles">
                      <div class="text-change" (click)="showAddressListModal()">
                        Cambiar
                      </div>
                      <div class="location label-black">
                        Despacho a domicilio
                      </div>
                      <div class="location-content">
                        <div class="label--1 text-inf text-despacho" *ngIf="selecAddress"> {{selectedAddress.street}}
                        </div>
                        <div class="label--1 text-inf text-sin-despacho" *ngIf="!selecAddress"> Ingresa tu dirección
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fp-border-container>
            </div>
          </div>
          <div class="col-6">
            <div *ngIf="ret">
              <fp-border-container [selected]="checkRet">
                <div class="radio-control-container position-radio" (click)="selectSection('ret')">
                  <label class="radio-control">
                    <input type="radio" class="input-radio" [checked]="checkRet">
                    <span class="check-mark"></span>
                  </label>
                  <div class="main-header location-selected-container">
                    <fp-icon iconSize="20" iconName="icon-ret"></fp-icon>
                    <div class="content-titles">
                      <div class="text-change" (click)="selectRET()">
                        Cambiar
                      </div>
                      <div class="location label-black">
                        Retiro en tienda
                      </div>
                      <div class="location-content">
                        <div class="label--1 text-inf text-despacho" *ngIf="selectDrugstore">{{ drugstoreName }}
                        </div>
                        <div class="label--1 text-inf text-sin-despacho" *ngIf="!selectDrugstore">Elige una botica</div>
                      </div>
                    </div>
                  </div>
                </div>
              </fp-border-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="conent-buttons-modal position-button-ret-rad my-4">
      <fp-button-primary id="btn--choose-address" class="d-inline-block" buttonClass="w-auto" buttonDisplay="block"
        [buttonDisabled]="isDisabled" (click)="saveChange()">
        Guardar
      </fp-button-primary>
    </div>
  </div>
</div>
