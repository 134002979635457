import { SwiperConfig } from 'ngx-swiper-wrapper';
import { GLOBAL } from '../parameters/global';
import { Injectable } from "@angular/core";

@Injectable()
export class SwiperBuilder {

  constructor() {
    this.swiperConfig = new SwiperConfig();
  }

  private swiperConfig: SwiperConfig;

  public settingBreakpoints(param: object, inverse: boolean = false) {
    this.swiperConfig.breakpointsInverse = inverse;
    this.swiperConfig.breakpoints = param;
    return this;
  }

  public settingLoop(param: boolean) {
    this.swiperConfig.loop = param;
    return this;
  }

  public settingDirection(param: string){
    this.swiperConfig.direction = param;
    return this;
  }

  public settingSpaceBetween(param: number) {
    this.swiperConfig.spaceBetween = param;
    return this;
  }

  public settingSlidesPerGroup(param: number) {
    this.swiperConfig.slidesPerGroup = param;
    return this;
  }

  public settingNavigation(param: boolean | any = GLOBAL.SWIPER.navigation) {
    this.swiperConfig.navigation = param;
    return this;
  }

  public settingCenteredSlides(param: boolean) {
    this.swiperConfig.centeredSlides = param;
    return this;
  }

  public settingWatchSlidesVisibility(param: boolean) {
    this.swiperConfig.watchSlidesVisibility = param;
    return this;
  }

  public settingFreeMode(param: boolean) {
    this.swiperConfig.freeMode = param;
    return this;
  }

  public settingLazy(param: boolean | any) {
    this.swiperConfig.lazy = param;
    return this;
  }

  public settingPagination(param: boolean | any = GLOBAL.SWIPER.pagination) {
    this.swiperConfig.pagination = param;
    return this;
  }

  public settingScrollbar(param: boolean | any) {
    this.swiperConfig.scrollbar = param;
    return this;
  }

  public settingOn(param: any) {
    this.swiperConfig.on = param;
    return this;
  }

  public settingSlidesOffsetAfter(param: number) {
    this.swiperConfig.slidesOffsetAfter = param;
    return this;
  }


  public settingSlidesOffsetBefore(param: number) {
    this.swiperConfig.slidesOffsetBefore = param;
    return this;
  }

  public settingAutoplay(param: boolean, value: boolean | any) {
    this.swiperConfig.autoplay = param ? value : param;
    return this;
  }

  public settingSlidesPerView(param: number | 'auto') {
    this.swiperConfig.slidesPerView = param;
    return this;
  }

  public settingAutoHeight(param: boolean) {
    this.swiperConfig.autoHeight = param;
    return this;
  }

  public settingWidth(param: number) {
    this.swiperConfig.width = param;
    return this;
  }

  public build() {
    return this.swiperConfig;
  }

}
