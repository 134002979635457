<div class="select-payment-content"
  [ngClass]="{'clickable': !selected}"
  [ngStyle]="{'opacity': isDisabled ? '0.4' : '1'}"
  (click)="selectOption()">
  <fp-border-container [selected]="selected">
    <div class="p-3 p-md-5">
      <div class="d-flex align-items-center"
        [ngClass]="{'mb-3 mb-md-5': selected}"
      >
        <div class="radio-control-content">
          <ng-content select="[payment-type-radio]"></ng-content>
        </div>
        <div class="ml-xs-2 ml-md-4">
          <div class="label payment-title d-flex flex align-items-center"
            [ngClass]="{'selected': showTitleBlack}">
            <img [src]="paymentMethodIcon" class="d-desktop mr-3">
            {{ paymentMethodTitle }}
          </div>
        </div>
      </div>
      <div [hidden]="!showDetails">
        <ng-content select="[payment-card]"></ng-content>
        <ng-content select="[checkbox-terms]"></ng-content>
        <ng-content select="[banner-card]"></ng-content>
      </div>
    </div>
  </fp-border-container>
</div>
