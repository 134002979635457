import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fp-radio-address-nickname-v2',
  templateUrl: './radio-address-nickname-v2.component.html',
  styleUrls: ['./radio-address-nickname-v2.component.sass']
})
export class RadioAddressNicknameV2Component {

  @Input() iconName: string;
  @Input() name: string;
  @Input() selected: boolean;

  @Output() selectRadioOption = new EventEmitter();

  constructor() { }

  public selectOption() {
    this.selectRadioOption.emit();
  }
}
