import { HostListener, Directive } from '@angular/core';
import { IWindowScrollTopHostListener } from './window-scroll-top-host-listener.interface';
import { GlobalHeader } from '../../../../core/modules/header/parameters/header';

@Directive()
export class WindowScrollTopHostListener implements IWindowScrollTopHostListener {

  private _document: Document = document;
  private _window: Window = window;
  public scrollCounter = GlobalHeader.SCROLL_START;

  constructor() {
  }

  public get currentDocument(): Document {
    return this._document;
  }

  public get currentWindow(): Window {
    return this._window;
  }

  @HostListener('window:scroll')
  listenToEventScrolling() {
    const { currentDocument, currentWindow } = this;
    const documentElement = currentDocument.documentElement;
    const documentScrollTop = documentElement.scrollTop;
    const windowPageYOffset = currentWindow.pageYOffset;
    const scrollOffset = windowPageYOffset || documentScrollTop;
    const active = scrollOffset > this.scrollCounter && scrollOffset > GlobalHeader.MIN_SCROLL;
    this.scrollCounter = scrollOffset <= GlobalHeader.SCROLL_START ? GlobalHeader.SCROLL_START : scrollOffset;
    this.triggerScrollTopEvent(active);
  }

  public triggerScrollTopEvent(active: boolean) {
  }
}
