<div class="row">
  <div class="col-12">
    <fp-basic-skeleton
      height="22">
    </fp-basic-skeleton>
  </div>
  <div class="col-12 mt-3">
    <fp-basic-skeleton
      height="18">
    </fp-basic-skeleton>
  </div>
  <div class="col-12 mt-1">
    <fp-basic-skeleton
      height="18">
    </fp-basic-skeleton>
  </div>
  <div class="col-12 mt-1">
    <fp-basic-skeleton
      height="18">
    </fp-basic-skeleton>
  </div>
  <div class="col-12 mt-1">
    <fp-basic-skeleton
      height="18">
    </fp-basic-skeleton>
  </div>
  <div class="col-5 mt-1">
    <fp-basic-skeleton
      height="18">
    </fp-basic-skeleton>
  </div>
</div>
