import { Injectable, NgZone } from '@angular/core';
import { ProductImplementService } from '../../../core/modules/product-card/service/product-implement.service';
import { BUSINESS_PATHS } from '../../parameters/business.parameter';
import { Router } from '@angular/router';
import { IProductDefault } from '../../models/product-default/product-default.interface';
import { GlobalProductCardService } from '../../../core/modules/product-card/service/global-product-card.service';
import { ShoppingListStoreService } from '../../stores/shopping-list/shopping-list-store.service';
import { ShoppingCartListValidationsService } from 'src/app/core/modules/product-card/service/shopping-cart-list-validations.service';
import { ProductModalsManagerService } from '../../../core/modules/product-card/service/product-modals-manager.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalProductImplementService {
  constructor(
    private _productImplementService: ProductImplementService,
    private _globalProductCardService: GlobalProductCardService,
    private _router: Router,
    private _ngZone: NgZone,
    private productModalsManager: ProductModalsManagerService,
    private _shoppingListStore: ShoppingListStoreService,
    private shoppingCartListValidations: ShoppingCartListValidationsService,
  ) {

    window['getProductFromCart$'] = (productId: string) => {
      return this._productImplementService.getProductShoppingCart$(productId);
    };

    window['isProductInCart'] = (productId: string) => {
      return this._productImplementService.isProductInShoppingCart(productId);
    };

    window['addProductToCart'] = (
      id: string, quantity: number, presentation: number, sellerId: string, sellerName: string
    ) => {
      sellerName === 'Inkafarma' ? sellerName = '' : sellerName;
      this._productImplementService.addProduct(id, quantity, presentation, sellerId, sellerName);
    };

    window['removeProductFromCart'] = (productId: string) => {
      this._productImplementService.deleteProduct(productId);
    };

    window['updateProductFromCart'] = (
      id: string, quantity: number, presentation: number, sellerId = ''
    ) => {
      this._productImplementService.updateProduct(id, quantity, presentation, sellerId);
    };

    window['setFavoriteProduct'] = (productId: string) => {
      return this._productImplementService.addToFavoriteProduct(productId);
    };

    window['removeFavoriteProduct'] = (productId: string) => {
      return this._productImplementService.removeFromFavoriteProduct(productId);
    };

    window['openProductDetail'] = (productSlug: string, productId: string) => {
      const productPath = BUSINESS_PATHS.PRODUCT;
      this._ngZone.run(() => {
        this._router.navigate(['/', productPath, productSlug, productId]);
      });
    };

    window['getProduct'] = (iProduct: IProductDefault) => {
      return this._globalProductCardService.getProduct(iProduct);
    };

  }

  public isValidSellerIdBrain(sellerId: string) {
    const { staticShoppingList } = this._shoppingListStore;
    const shoppingListLength = staticShoppingList.length;
    if (shoppingListLength === 0) {
      return true;
    }
    const currentSellerId = staticShoppingList[0].sellerId;
    const isValidShoppingList = staticShoppingList.every(p => {
      return p.sellerId === currentSellerId;
    });
    const isValidSellerId = currentSellerId === sellerId;
    return isValidSellerId;
  }

}
