import { Injectable } from '@angular/core';
import { RegisterClientService } from 'src/app/shared/service/user-register/register-client.service';

@Injectable()
export class RegisterClientImplement {

  constructor(private registerClientService: RegisterClientService) { }

  public getUser() {
    return this.registerClientService.getUser$();
  }

}
