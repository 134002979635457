export const REJECTED_CARD_ERROR_MESSAGE = 'Lo sentimos, tu pago fue rechazado.';
export const REJECTED_CARD_ERROR_MESSAGE_SUB = 'Por favor verifica los datos de tu tarjeta.';
export const DONT_MATCH_CARD_ERROR_MESSAGE = 'La tarjeta ingresada no coincide con el medio de pago que seleccionaste. Selecciona el medio de pago correcto.';
export const DISABLED_SCHEDULE_ERROR_MESSAGE = 'El horario que seleccionaste ya no se encuentra disponible. Escoge otro horario para recibir tu pedido.';
export const DISABLED_SCHEDULE_RANGE_ERROR_MESSAGE = 'El rango de horario que seleccionaste ya no se encuentra disponible. Elige el siguiente rango de horario para recibir tu pedido.';
export const DISABLED_SERVICE_ERROR_MESSAGE = 'El servicio %d ya no se encuentra disponible. Elige otro tipo de entrega para recibir tu pedido.';
export const DISABLED_STOCK_ERROR_MESSAGE = 'Hubo un problema con el stock de tus productos. Cambia o elimina los productos que no están disponibles.';
export const DISABLED_COVERAGE_ERROR_MESSAGE = 'Por el momento, la zona seleccionada no se encuentra disponible. Por favor, vuelve a intentar más tarde.';
export const YOUR_CARD_WAS_NOT_CHARGED_MESSAGE = 'No se realizó el cargo a tu tarjeta.';

export const RET_UNAVAILABLE_DRUGSTORE_ERROR_MESSAGE = 'La botica seleccionada ya no se encuentra disponible para recojo en botica. Por favor, selecciona otra botica o cambia el método de despacho.';
export const RET_SCHEDULE_ERROR_MESSAGE = 'El horario de recojo para esta botica ha cambiado.';
export const GENERIC_ERROR_APP_AGORA_PAY_TITLE = 'No se pudo completar el proceso de compra';
export const GENERIC_ERROR_APP_AGORA_PAY_SUBTITLE = 'Por favor, inténtalo nuevamente.';

export const GENERIC_ERROR_TITLE = 'No se pudo procesar el pago';
export const GENERIC_ERROR_SUBTITLE = ' Por favor, inténtalo nuevamente. Si se ha generado un cobro, te será devuelto muy pronto';
export const ERROR_MONTO_TOTAL = 'El monto total a pagar cambió durante la transacción. Por favor, revisa el nuevo monto total y vuelve a intentarlo.';
