import { OrderTakerValidator } from "@validators/order-taker.validator";
import { EProductPresentation } from "../../../core/modules/product-card/models/product-presentation.enum";
import { isArray, isObject } from "../../helpers/objects-equal";
import { imageSize, S3 } from "../../parameters/global";

export interface IOrderStatus {
  statusName: string; // ON_STORE
  statusDate: string; // timeStamp
}
export class OrderStatus {
  public statusName: string;
  public statusDate: string;

  constructor(orderStatus: IOrderStatus) {
    const currentValue = isObject(orderStatus)
      ? orderStatus
      : ({} as IOrderStatus);
    this.statusName = currentValue.statusName || "";
    this.statusDate = currentValue.statusDate || "";
  }
}
export interface IScheduled {
  startDate: number;
  endDate: number;
}

export interface IReceipt {
  companyId?: string;
  companyName?: string;
  type?: string;
}

export class Scheduled {
  public startDate: number;
  public endDate: number;

  constructor(schedule: IScheduled) {
    const currentValue = isObject(schedule) ? schedule : ({} as IScheduled);
    this.startDate = currentValue.startDate || -1;
    this.endDate = currentValue.endDate || -1;
  }
}

export interface IDrugstore {
  name: string; // RAUL FERRERO
  address: string; // solo para RET (AV. RAUL FERRERO 234, LA MOLINA)
  description: string; // RAUL FERRERO
}

export class Drugstore {
  public name: string;
  public address: string;
  public description: string;

  constructor(drugstore: IDrugstore) {
    const currentValue = isObject(drugstore) ? drugstore : ({} as IDrugstore);
    this.name = currentValue.name || "";
    this.address = currentValue.address || "";
    this.description = currentValue.description || "";
  }
}

export interface IOrderItems {
  brand: string; // MEGA LIFE CONSUMO
  fractionated: string; // Y
  presentationId: number;
  presentationDescription: string;
  fractionatedText: string; // solo del getHistory
  nofractionatedText: string; // solo del getHistory
  name: string; // NAT-B Cápsula Blanda\n(1 Cápsula Blanda)
  image: string;
  quantityPresentation: number; // 2
  shortDescription: string; // Multivitamínico.
  sku: string; // '428243' si el sku = '024031' es el delivery
  totalPrice: number; // 3.2
  totalPriceList?: number;
  totalPriceAllPaymentMethod?: number;
  totalPriceWithpaymentMethod?: number;
  unitPrecie: number; // 1.6
  sellerId: string;
  sellerName: string;
  isFromSeller: boolean;
  hasPrescription: string;
}

export class OrderItems {
  public brand: string;
  public fractionated: string;
  public presentationId: number;
  public fractionatedText: string;
  public nofractionatedText: string;
  public name: string;
  public presentationDescription: string;
  public quantityPresentation: number;
  public shortDescription: string;
  public sku: string;
  public image: string;
  public totalPrice: number;
  public totalPriceList?: number; // 3 precios
  public totalPriceAllPaymentMethod?: number; // 3 precios
  public totalPriceWithpaymentMethod?: number; // 3 precios
  public unitPrecie: number;
  public sellerId: string;
  public sellerName: string;
  public isFromSeller: boolean;
  public hasPrescription: string;
  constructor(orderItems: IOrderItems) {
    const currentValue = isObject(orderItems)
      ? orderItems
      : ({} as IOrderItems);
    this.brand = currentValue.brand || "";
    this.fractionated = currentValue.fractionated || "";
    this.presentationId =
      currentValue.presentationId ||
      (currentValue.fractionated === "Y"
        ? EProductPresentation.unit
        : EProductPresentation.pack);
    this.fractionatedText = currentValue.fractionatedText || "";
    this.nofractionatedText = currentValue.nofractionatedText || "";
    this.presentationDescription = currentValue.presentationDescription || "";
    this.name = currentValue.name || "";
    this.quantityPresentation = currentValue.quantityPresentation || -1;
    this.shortDescription = currentValue.shortDescription || "";
    this.sku = currentValue.sku || "";
    this.totalPrice = currentValue.totalPrice || -1;
    this.totalPriceList = currentValue.totalPriceList || 0;
    this.totalPriceAllPaymentMethod =
      currentValue.totalPriceAllPaymentMethod || 0;
    this.totalPriceWithpaymentMethod =
      currentValue.totalPriceWithpaymentMethod || 0;
    this.sellerId = currentValue.sellerId || "";
    this.sellerName = currentValue.sellerName || "";
    this.isFromSeller = currentValue.isFromSeller || false;
    if (this.isFromSeller) {
      this.image = currentValue.sku
        ? S3.url + this.sku + "M.jpg"
        : "imagen default";
    } else {
      this.image = currentValue.sku
        ? S3.url + this.sku + imageSize.medium
        : "imagen default";
    }

    this.hasPrescription = orderItems.hasPrescription
  }
}

export interface IItemsUpdated {
  name: string; // NAT-B Cápsula Blanda\n(1 Cápsula Blanda)
  presentationDescription: string;
  image: string;
  quantity: number; // 2
  quantityPresentation: number;
  sku: string; // '428243' si el sku = '024031' es el delivery
  brand?: string;
  totalPrice: number; // 3.2
  totalPriceList?: number;
  totalPriceAllPaymentMethod?: number;
  totalPriceWithpaymentMethod?: number;
}
export class ItemsUpdated {
  public name: string;
  public presentationDescription: string;
  public image: string;
  public quantity: number;
  public quantityPresentation: number;
  public sku: string;
  public totalPrice: number;
  public totalPriceList?: number;
  public totalPriceAllPaymentMethod?: number;
  public totalPriceWithpaymentMethod?: number;

  constructor(itemsUpdated: IItemsUpdated) {
    const currentValue = isObject(itemsUpdated)
      ? itemsUpdated
      : ({} as IItemsUpdated);
    this.name = currentValue.name || "";
    this.presentationDescription = currentValue.presentationDescription || "";
    this.sku = currentValue.sku || "";
    this.image = currentValue.sku
      ? S3.url + this.sku + imageSize.medium
      : "imagen default";
    this.quantity = currentValue.quantity || -1;
    this.quantityPresentation = currentValue.quantityPresentation || -1;
    this.totalPrice = currentValue.totalPrice || -1;
    this.totalPriceList = currentValue.totalPriceList || 0;
    this.totalPriceAllPaymentMethod =
      currentValue.totalPriceAllPaymentMethod || 0;
    this.totalPriceWithpaymentMethod =
      currentValue.totalPriceWithpaymentMethod || 0;
  }
}

export interface IPaymentMethod {
  type: string; // CASH, CARD
  provider: string; // "Visa débito/crédito , SI ES CASH to existe
  agora: boolean; // true (si es agora) , false (no es agora)
  changeAmount?: number,
  note?: string; // METHOD_PAYMENT
  paidAmount?: number;
}
export class PaymentMethod {
  public type: string;
  public provider: string;
  public agora: boolean;
  public changeAmount: number;
  public note: string;
  public paidAmount: number;

  constructor(paymentMethod: IPaymentMethod) {
    const currentValue = isObject(paymentMethod)
      ? paymentMethod
      : ({} as IPaymentMethod);
    this.type = currentValue.type || "";
    this.provider = currentValue.provider || "";
    this.agora = currentValue.agora || false;
    this.changeAmount = currentValue.changeAmount || 0;
    this.note = currentValue.note || "";
    this.paidAmount = currentValue.paidAmount || 0;
  }
}

export interface IPersonToPickup {
  documentNumber: string;
  documentType: number | string;
  email: string;
  fullName: string;
  phone: string;
  userId: string;
}

export class PersonToPickup {
  public documentNumber: string;
  public documentType: number | string;
  public email: string;
  public fullName: string;
  public phone: string;
  public userId: string;
  constructor(data: IPersonToPickup) {
    const current = isObject(data) ? data : ({} as IPersonToPickup);
    this.documentNumber = current.documentNumber || "";
    this.documentType = current.documentType || -1;
    this.email = current.email || "";
    this.fullName = current.fullName || "";
    this.phone = current.phone || "";
    this.userId = current.userId || "";
  }
}

export interface Itimes {
  eta: number;
  etap: number;
  trc: number;
}
export class Times {
  public eta: number;
  public etap: number;
  public trc: number;
  constructor(response: Itimes) {
    const currentValue = isObject(response) ? response : ({} as Itimes);
    this.eta = currentValue.eta;
    this.etap = currentValue.etap || -1;
    this.trc = currentValue.trc || -1;
  }
}

export interface Iclient {
  userId: string;
  isAnonymous: string;
}
export class Client {
  public userId: string;
  public isAnonymous: string;
  constructor(client: Iclient) {
    const currentValue = isObject(client) ? client : ({} as Iclient);
    this.userId = currentValue.userId;
    this.isAnonymous = currentValue.isAnonymous;
  }
}

export interface IAddress {
  city?: string;
  district: string;
  latitude: number;
  longitude: number;
  name: string;
  notes: string;
  number: string;
  street: string;
  zoneEta: number;
}

export class Address {
  city: string;
  district: string;
  latitude: number;
  longitude: number;
  name: string;
  notes: string;
  number: string;
  street: string;
  zoneEta: number;

  constructor(address: IAddress) {
    this.city = address.city || "";
    this.district = address.district || "";
    this.latitude = address.latitude || -1;
    this.longitude = address.longitude || -1;
    this.name = address.name || "";
    this.notes = address.notes || "";
    this.number = address.number || "";
    this.street = address.street || "";
    this.zoneEta = address.zoneEta || -1;
  }
}

export interface IMotorized {
  alias: string;
  id: string;
  lastName: string;
  name: string;
}

export class Motorized {
  alias: string;
  id: string;
  lastName: string;
  name: string;

  constructor(motorized: IMotorized) {
    this.alias = motorized.alias || "";
    this.id = motorized.id || "";
    this.lastName = motorized.lastName || "";
    this.name = motorized.name || "";
  }
}

export interface IOrderFirebase {
  orderExternalId: number; // 2013560769
  dateCreated: number;
  startHour: string; // 08:00
  endHour: string; // 23:30
  deliveryService: number; // 2
  deliveryType: string; // RAD o RET (en inkatracker no existe)
  companyCode: string; // IKF, MF
  companyName: string; // INKAFARMA, MIFARMA
  messageToPickup: string; // 15 de noviembre a las 09:30 a.m. (en inkatracker no existe)
  orderDateConfirm?: string; // 15 de noviembre a las 09:30 a.m. (en inkatracker no existe)
  daysToPickUp: string; // hasta el 13 de noviembre para recogerlo (en inkatracker no existe)
  discountApplied: number; // 5
  discountAppliedEdited: number; // 7
  subtotal: number; // 16.5
  subTotalWithNoSpecificPaymentMethod?: number;
  subtotalEdited: number;
  subTotalWithNoSpecificPaymentMethodEdited?: number;
  totalCost: number; // 16.5
  totalCostEdited?: number;
  totalWithNoSpecificPaymentMethod?: number;
  totalWithNoSpecificPaymentMethodEdited?: number;
  totalWithPaymentMethod?: number;
  totalWithPaymentMethodEdited?: number;
  orderStatus: IOrderStatus;
  scheduled: IScheduled;
  drugstore: IDrugstore;
  orderItems: IOrderItems[];
  itemsUpdated: IItemsUpdated[];
  orderEdited: boolean;
  deliveryCost: number;
  paymentMethod: IPaymentMethod;
  personToPickup: IPersonToPickup;
  note: string;
  maxDeliveryTime: number;
  client: Iclient;
  time: Times;
  cancelMessageId: string;
  cancelMessageNote: string;
  address: IAddress;
  motorizedId?: string;
  motorized?: IMotorized;
  shoppingCartStatus?: string;
  pickupAddress?: string;
  sellerId?: string;
  sellerName?: string;
  receipt?: IReceipt;
  orderItemsPack?: IOrderItems[];
}

export class OrderFirebase {
  public orderExternalId: number;
  public dateCreated: number;
  public startHour: string;
  public endHour: string;
  public deliveryService: number;
  public deliveryType: string;
  public companyCode: string;
  public companyName: string;
  public messageToPickup: string;
  public daysToPickUp: string;
  public discountApplied: number;
  public discountAppliedEdited: number;
  public subtotal: number;
  public subTotalWithNoSpecificPaymentMethod?: number;
  public subtotalEdited: number;
  public subTotalWithNoSpecificPaymentMethodEdited?: number;
  public totalCost: number;
  public totalCostEdited?: number;
  public totalWithNoSpecificPaymentMethod?: number;
  public totalWithNoSpecificPaymentMethodEdited?: number;
  public totalWithPaymentMethod?: number;
  public totalWithPaymentMethodEdited?: number;
  public orderStatus: OrderStatus;
  public scheduled: Scheduled;
  public drugstore: Drugstore;
  public orderItems: OrderItems[];
  public itemsUpdated: ItemsUpdated[];
  public orderEdited: boolean;
  public deliveryCost: number;
  public paymentMethod: PaymentMethod;
  public personToPickup: PersonToPickup;
  public note: string;
  public maxDeliveryTime: number;
  public client: Client;
  public time: Times;
  public cancelMessageId: string;
  public cancelMessageNote: string;
  public address: Address;
  public motorizedId?: string;
  public motorized?: Motorized;
  public shoppingCartStatus?: string;
  public pickupAddress?: string;
  public sellerId?: string;
  public sellerName?: string;
  public receipt?: IReceipt;
  public orderItemsPack?: IOrderItems[];

  constructor(response: IOrderFirebase) {
    const currentValue = isObject(response) ? response : ({} as IOrderFirebase);
    this.orderExternalId = currentValue.orderExternalId || -1;
    this.dateCreated = currentValue.dateCreated || -1;
    this.startHour = currentValue.startHour || "";
    this.endHour = currentValue.endHour || "";
    this.deliveryService = currentValue.deliveryService || -1;
    this.deliveryType = currentValue.deliveryType || "";
    this.companyCode = currentValue.companyCode || "";
    this.companyName = currentValue.companyName || "";
    this.messageToPickup = OrderTakerValidator.isOrderTakerEnv() ? currentValue.orderDateConfirm : currentValue.messageToPickup || "" ;
    this.daysToPickUp = currentValue.daysToPickUp || "";
    this.discountApplied = currentValue.discountApplied || -1;
    this.discountAppliedEdited = currentValue.discountAppliedEdited || -1;
    this.subtotal = currentValue.subtotal;
    this.subTotalWithNoSpecificPaymentMethod =
      currentValue.subTotalWithNoSpecificPaymentMethod;
    this.subtotalEdited = currentValue.subtotalEdited;
    this.subTotalWithNoSpecificPaymentMethodEdited =
      currentValue.subTotalWithNoSpecificPaymentMethodEdited;
    this.totalCost = currentValue.totalCost;
    this.totalCostEdited = currentValue.totalCostEdited;
    this.totalWithNoSpecificPaymentMethod =
      currentValue.totalWithNoSpecificPaymentMethod;
    this.totalWithNoSpecificPaymentMethodEdited =
      currentValue.totalWithNoSpecificPaymentMethodEdited;
    this.totalWithPaymentMethod = currentValue.totalWithPaymentMethod;
    this.totalWithPaymentMethodEdited =
      currentValue.totalWithPaymentMethodEdited;
    this.orderStatus = new OrderStatus(currentValue.orderStatus);
    this.scheduled = new Scheduled(currentValue.scheduled);
    this.drugstore = new Drugstore(currentValue.drugstore);
    const itemsUpdated = isArray(currentValue.itemsUpdated)
      ? currentValue.itemsUpdated
      : [];
    this.itemsUpdated = itemsUpdated.map(
      (orderItem) => new ItemsUpdated(orderItem)
    );
    this.orderEdited = currentValue.orderEdited;
    this.deliveryCost = currentValue.deliveryCost || -1;
    this.paymentMethod = new PaymentMethod(currentValue.paymentMethod);
    this.personToPickup = new PersonToPickup(currentValue.personToPickup);
    this.note = currentValue.note || "";
    this.maxDeliveryTime = currentValue.maxDeliveryTime;
    this.client = new Client(currentValue.client);
    this.time = new Times(currentValue.time);
    this.cancelMessageId = currentValue.cancelMessageId || "";
    this.cancelMessageNote = currentValue.cancelMessageNote || "";
    this.address = currentValue.address
      ? new Address(currentValue.address)
      : null;
    this.motorizedId = currentValue.motorizedId || null;
    this.motorized = currentValue.motorized
      ? new Motorized(currentValue.motorized)
      : null;
    this.shoppingCartStatus = currentValue.shoppingCartStatus || "";
    this.pickupAddress = currentValue.pickupAddress || "";
    this.sellerId = currentValue.sellerId || "";
    this.sellerName = currentValue.sellerName || "";
    const orderItems = isArray(currentValue.orderItems)
      ? currentValue.orderItems
      : [];
    this.receipt = currentValue.receipt;
    const orderItemsPack = Array.isArray(currentValue.orderItemsPack)
      ? currentValue.orderItemsPack
      : [];
    this.orderItems = orderItems.map((orderItem) => {
      orderItem.sellerId = this.sellerId;
      orderItem.sellerName = this.sellerName;
      orderItem.isFromSeller = this.deliveryType === "SELLER" ? true : false;
      return new OrderItems(orderItem);
    });
    this.orderItemsPack = orderItemsPack.map((orderItemsPack) => {
      orderItemsPack.sellerId = this.sellerId;
      orderItemsPack.sellerName = this.sellerName;
      orderItemsPack.isFromSeller =
        this.deliveryType === "SELLER" ? true : false;
      return new OrderItems(orderItemsPack);
    });
  }
}

export interface IOrder {
  orders: IOrderFirebase[];
}

export class Order {
  public orders: OrderFirebase[];

  constructor(iOrder: IOrder) {
    const currentValue = isObject(iOrder) ? iOrder : ({} as Order);
    const items = isArray(currentValue.orders) ? currentValue.orders : [];

    this.orders = items.map((item) => new OrderFirebase(item));
  }
}
