import { Injectable } from '@angular/core';
import { AuthImplementService } from '../implements/auth/auth-implement.service';
import {
  ProductShoppingCartSalesforceRequest
} from "@models/shopping-cart-salesforce/shopping-cart-salesforce-request.model";

@Injectable({
  providedIn: 'root'
})
export class SalesforceCollectService {
  private window: Window = window;
  public etmc = this.window['_etmc'] || [];

  constructor(
    private auth: AuthImplementService
  ) {

    this.auth.authStateObservable.subscribe((currentUser)=>{
      this.etmc = this.window['_etmc'] || [];

       if (currentUser) {
         this.setUserInfo(currentUser.email);
        }
    })
 
  }

  setUserInfo(email: string) {
    if (email) {
      this.etmc.push(['setUserInfo', { email }]);
      this.etmc.push(['trackPageView']);
    }
  }

  trackItem(productId: string): void {
    this.etmc.push(['trackPageView', { 'item': productId }]);
  }

  trackCategory(category: string): void {
    this.etmc.push(['trackPageView', { 'category': category }]);
  }

  trackSearch(query: string): void {
    this.etmc.push(['trackPageView', { 'search': query.replace(/\s/g, '_') }]);
  }

  trackCart(product: ProductShoppingCartSalesforceRequest): void {
    this.setUserInfo(this.auth.currentUser.email);
    if (product) {
      this.etmc.push(['trackCart', { 'cart': [product] }]);
    } else {
      this.etmc.push(['trackCart', { 'clear_cart': true }]);
    }
  }

  trackConversion(products: ProductShoppingCartSalesforceRequest[] = []): void {
    this.etmc.push(['trackConversion', {
      'cart': products
    }]);
    this.etmc.push(['trackCart', { 'clear_cart': true }]);
  }

}
