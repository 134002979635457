import { Component, Input, OnInit } from '@angular/core';
import { EProductStatus } from '../../models/product-status.enum';

@Component({
  selector: 'fp-product-card-price-mifa',
  templateUrl: './product-card-price-mifa.component.html',
  styleUrls: ['./product-card-price-mifa.component.sass']
})
export class ProductCardPriceMifaComponent implements OnInit {
  public eProductStatus = EProductStatus;

  @Input() regularPrice: number;
  @Input() price: number;
  @Input() offerPrice: number;
  @Input() productStatus: number = this.eProductStatus.available;
  @Input() fontSize: string = '-1'
  @Input() fontSizeRegular: string = '--1'
  @Input() fontSizeOffer: string = '-1'
  @Input() fontSizeText: string = '--1'
  @Input() crossOutPL = false;
  @Input() place: string = '';

  constructor() {
  }

  ngOnInit() { }

}
