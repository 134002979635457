import { HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { HandleSelectedAlgoliaResponse } from '@models/search-landing/handle-filter-selected-response.model';
import { HandleFilterAlgolioRequest, SearchLandingAlgoliaRequest } from '@models/search-landing/search-landing-algolia-request.model';
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SearchLandingRequest } from "../../models/search-landing/search-landing-request.model";
import { ISearchLandingResponse } from "../../models/search-landing/search-landing-response.interface";
import { SearchLandingAlgoliaResponse, SearchLandingResponse } from '../../models/search-landing/search-landing-response.model';
import { ENDPOINTS } from "../../parameters/endpoints";
import { GenericService } from "../generic";


@Injectable()
export class SearchLandingClientService {

  public endPointAlgolia = ENDPOINTS.GET_LIST_FILTER_ALGOLIA;
  public headers = new HttpHeaders();
  public setHeaders = this.headers
      .set('X-Algolia-API-Key', environment.algolia_filter_categories.X_Algolia_API_Key)
      .set('X-Algolia-Application-Id', environment.algolia_filter_categories.X_Algolia_Application_Id)
      .set('content-type', 'application/json');
  constructor(
    private _genericService: GenericService,
    private _appStoreService: AppStoreService
  ) {}

  getUrlLandingFilters(): string {
    let isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
    if (isOrderTaker) {
      const isMifaBrand =
        EBrand[EBrandAsset[environment.brand]] == EBrand.mifarma;
      return isMifaBrand
        ? ENDPOINTS.GET_SEARCH_LANDING_FILTERS_MF
        : ENDPOINTS.GET_SEARCH_LANDING_FILTERS;
    }
    return ENDPOINTS.GET_SEARCH_LANDING_FILTERS;
  }

  getSearchLandingFilters(
    searchLandingRequest: SearchLandingRequest
  ): Observable<SearchLandingResponse> {
    return this._genericService
      .genericPost(this.getUrlLandingFilters(), searchLandingRequest)
      .pipe(
        map(
          (iSearchLandingResponse: ISearchLandingResponse) =>
            new SearchLandingResponse(iSearchLandingResponse)
        )
      );
  }

   getSearchLandingAlgoliaFilter(searchLandingRequest: SearchLandingAlgoliaRequest, indexName?, api_algolia?:string, api_key?): Observable<SearchLandingAlgoliaResponse>{
    let setHeaders = this.headers
    .set('X-Algolia-API-Key', api_key)
    .set('X-Algolia-Application-Id', api_algolia)
    .set('content-type', 'application/json');
    return this._genericService.genericPostWithoutSecurityHeaders(`https://${api_algolia}-dsn.algolia.net/1/indexes/${indexName}/query`, searchLandingRequest, null, setHeaders);
  }

  handleFSubmitFilterCategory(filterSelectedRequest:HandleFilterAlgolioRequest): Observable<HandleSelectedAlgoliaResponse>{
    return this._genericService.genericPostWithoutSecurityHeaders(this.endPointAlgolia+`${'query'}`, filterSelectedRequest, null, this.setHeaders);
  }
}
