import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import { UserInfoStoreService } from "@stores/user/user-info-store.service";
import { IUserRegister, RegisterResponse, UserRegister } from "@models/register/register.model";
import { UserControlledFormService } from "../../form/user-controlled-form.service";
import { DOCUMENT_TYPES } from "src/app/business/myprofile/profile/constants/commons.constant";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { UserV2ImplementService } from "@implements/user/user-v2-implement.service";
import { GENDERS } from "@parameters/global";
import { RegisterClientService } from "@service/user-register/register-client.service";

@Component({
	selector: "fp-cart-controlled-form-step-one",
	templateUrl: "./cart-controlled-form-step-one.component.html",
	styleUrls: ["./cart-controlled-form-step-one.component.scss"]
})
export class CartControlledFormStepOneComponent implements OnInit, OnDestroy {
	public documentTypeOptions: Array<string> = ["Selecciona un género", "DNI", "Carnet de extranjería"];
	public documentTypes: string[] = ["DNI", "CE", "Pasaporte"];

	public genderOptions: string[] = GENDERS;
	minDate = moment();

	public isCheckedSwitch = false;
	documentTypeValidator = "DNI";
	public genders = GENDERS;
	constructor(
		public userInforStore: UserInfoStoreService,
		public _userControlledFormService: UserControlledFormService,
		private userV2Implement: UserV2ImplementService,
		private registerClient: RegisterClientService
	) {}

	ngOnDestroy(): void {}

	ngOnInit() {
		TaggingService.showModalControlledStepOne();
		this.setInfoUserLogged();
		this._userControlledFormService.documentTypeControl.valueChanges.subscribe((res) => {
			this.documentTypeValidator = res;
			this._userControlledFormService.documentNumberControl.setValue("");
			this._userControlledFormService.documentNumberControl.updateValueAndValidity();
		});
	}

	onSubmit() {}

	clickSwitch($event: any) {
		this._userControlledFormService.isForThirdControl.setValue(this.isCheckedSwitch);

		if (this.isCheckedSwitch) {
			TaggingService.forThirdModalControlledStepOne();
			this._userControlledFormService.resetForm();
		} else {
			TaggingService.forMeModalControlledStepOne();
			this.setInfoUserLogged();
		}
	}

	get getStatusForm() {
		return true;
	}

	setDocumentType(documentTypeOption: string) {
		// this._userControlledFormService.documentTypeControl.setValue(2);
	}
	setGender(documentTypeOption: string) {
		this._userControlledFormService.genderControl.setValue(1);
	}
	get isForThird() {
		return this.isCheckedSwitch ? "Sí" : "No";
	}
	get isFormValid() {
		return !this._userControlledFormService.form.valid;
	}
	setInfoUserLogged() {

		this.userInforStore.currentUser$.subscribe((current) => {
			if (current.userResponse.data) {
				const { name, lastName, dni, email, sexId, birthDate, identityDocumentType, phone } = current.userResponse
					.data as IUserRegister;
				const fullName = name && lastName ? `${name} ${lastName}` : name ? name : lastName ? lastName : "";
				this._userControlledFormService.setDataForm({
					names: fullName,
					phoneNumber: phone ? phone : "",
					documentType: identityDocumentType
						? identityDocumentType === 1
							? "DNI"
							: identityDocumentType === 2
							? "CE"
							: "PASAPORTE"
						: "DNI",
					documentNumber: dni ? dni : "",
					email: email ? email : "",
					bornDate: birthDate ? birthDate : "",
					gender: sexId != null ? (sexId == 1 ? "Masculino" : "Femenino") : "Selecciona tu género",
					isForThird: this.isCheckedSwitch
				});
			} else {
				this.registerClient.getUser$().subscribe((userResponse: RegisterResponse) => {
					const { name, lastName, dni, email, sexId, birthDate, identityDocumentType, phone } =
						userResponse.data as IUserRegister;
					const fullName = name && lastName ? `${name} ${lastName}` : name ? name : lastName ? lastName : "";

					this._userControlledFormService.setDataForm({
						names: fullName,
						phoneNumber: phone ? phone : "",
						documentType: identityDocumentType
							? identityDocumentType === 1
								? "DNI"
								: identityDocumentType === 2
								? "CE"
								: "PASAPORTE"
							: "DNI",
						documentNumber: dni ? dni : "",
						email: email ? email : "",
						bornDate: birthDate ? birthDate : "",
						gender: sexId != null ? (sexId == 1 ? "Masculino" : "Femenino") : "Selecciona tu género",
						isForThird: this.isCheckedSwitch
					});
				});
			}
		});
	}

	get isUserExist() {
		return this.userInforStore.isLogged;
	}
}
