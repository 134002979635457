<div class="select-time-content"
  [ngClass]="{'clickable': !selected, 'disable': disabled || !service.enabled}"
  (click)="selectOption()">
  <fp-border-container [selected]="selected">
    <div class="py-4 px-3">
      <div class="d-flex">
        <div class="radio-control-content">
          <ng-content select="[payment-type-radio]"></ng-content>
        </div>
        <div class="ml-3 service-header">
          <div class="label d-flex"
            [ngClass]="{'label-black': showTitleBlack}">
            {{ service.title }}
            <span *ngIf="service.new" class="new_label">NUEVO</span>
            <span class="price" [ngClass]="{'price-color': showTitleBlack}">
              {{ service.price }}
            </span>
          </div>
          <div class="sub-label">
            {{ service.subtitle }}
          </div>
        </div>
      </div>
      <div [hidden]="!showDetails">
        <ng-content select="[selects-options]"></ng-content>
        <ng-content select="[express-service]"></ng-content>
      </div>
    </div>
  </fp-border-container>
</div>
