<div class="app-main-loader" *ngIf="isLoaded">
  <div class="loader-icon-container">
    <img
      [src]="
        './assets/icons/[[brandAsset]]/logo/isotipo-color.svg' | brandAsset
      "
      alt="isotipo loading"
    />
  </div>
  <div class="loader-message mt-0 label-black-3">Cargando...</div>
</div>
