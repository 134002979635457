import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fp-pagination-list',
  templateUrl: './pagination-list.component.html',
  styleUrls: ['./pagination-list.component.sass']
})
export class PaginationListComponent {
  public page = 1;
  @Input() totalPages = 1;
  @Input() previewPages = 4;

  @Output() pageNumber = new EventEmitter();

  @Input('page')
  set setPage(page) {
    this.page = page + 1;
  }

  setPageNumber() {
    this.pageNumber.emit(this.page - 1);
  }

}
