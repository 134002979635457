import { Component, OnDestroy, OnInit } from '@angular/core';
import { RegisterPreNewUserFormService } from 'src/app/core/modules/register/register-forms/register-pre-new-user-form.service';
import { NewRegisterAccessStoreService } from 'src/app/shared/stores/user/new-register-access-store.service';
import { RegisterOriginStoreService } from 'src/app/core/modules/register/register-stores/register-origin-store.service';
import { Router } from '@angular/router';
import { BUSINESS_PATHS } from 'src/app/shared/parameters/business.parameter';
import { UserInfoStoreService } from 'src/app/shared/stores/user/user-info-store.service';
import { AnalyticsManager } from 'src/app/analytics';
import { RegisterModalsManagerHelperService } from '../../register-services/register-modals-manager-helper.service';

@Component({
  selector: 'fp-pre-register-modal',
  templateUrl: './pre-register-modal.component.html',
  styleUrls: ['./pre-register-modal.component.sass']
})
export class PreRegisterModalComponent implements OnInit, OnDestroy {
  documentLength: number = 8;
  
  constructor(
    private registerModalsManagerHelper: RegisterModalsManagerHelperService,
    public registerPreNewUserForm: RegisterPreNewUserFormService,
    public newRegisterAccessStore: NewRegisterAccessStoreService,
    private registerOriginStore: RegisterOriginStoreService,
    private router: Router,
    private userInfoStore: UserInfoStoreService
  ) { }

  
  public documentTypes = ['Tipo de documento', 'DNI', 'Carnet de extranjería'];
  public color_g = '';

  ngOnDestroy() {
    this.closeModal();
  }

  ngOnInit() {
    if (this.userInfoStore.getUser()) {
      this.color_g = 'none';
      if (this.userInfoStore.getUser().identityDocumentType === 1) {
        this.registerPreNewUserForm.documentTypeControl.setValue('DNI');
      } else if (this.userInfoStore.getUser().identityDocumentType === 2) {
        this.registerPreNewUserForm.documentTypeControl.setValue('Carnet de extranjería');
      } else {
        this.registerPreNewUserForm.documentTypeControl.setValue('Tipo de documento');
      }
      this.registerPreNewUserForm.documentNumberControl.setValue(this.userInfoStore.getUser().identityDocumentNumber);
    } else if (sessionStorage.getItem('documentTypeAfiliacion') && sessionStorage.getItem('documentNumberAfiliacion')) {
      this.color_g = 'none';
      this.registerPreNewUserForm.documentTypeControl.setValue(sessionStorage.getItem('documentTypeAfiliacion'));
      this.registerPreNewUserForm.documentNumberControl.setValue(sessionStorage.getItem('documentNumberAfiliacion'));
      sessionStorage.removeItem('documentTypeAfiliacion');
      sessionStorage.removeItem('documentNumberAfiliacion');
    }
    const type = this.registerPreNewUserForm.documentTypeControl.value;
    this.selectDocumentType(type);
  }


  selectDocumentType(value: string) {
    this.documentLength = value === 'DNI' ? 8 : 9;
  }

  nextNewRegisterData() {
    sessionStorage.setItem('documentType', this.registerPreNewUserForm.documentTypeControl.value);
    sessionStorage.setItem('documentNumber', this.registerPreNewUserForm.documentNumberControl.value);
    this.newRegisterAccessStore.isValidToAccess = true;
    this.registerOriginStore.emailVerificationOrigin = BUSINESS_PATHS.REGISTER;
    AnalyticsManager.dispatchEvent({
      eventName: 'ui_register_cuentanos_sobre_ti',
      eventCategory: 'UI :: Register',
      eventAction: 'Cuéntanos sobre tí',
      eventLabel: 'Button :: Siguiente'
    });
    this.router.navigate([BUSINESS_PATHS.REGISTER]);
    this.closeModal();
  }

  closeModal() {
    this.registerModalsManagerHelper.closePreRegisterModal();
  }

}
