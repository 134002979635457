import { Component, Input } from '@angular/core';

@Component({
	selector: "fp-button-primary",
	templateUrl: "./button-primary.component.html",
	styleUrls: ["./button-primary.component.scss"]
})
export class ButtonPrimaryComponent {
	@Input()
	buttonId: string = "";

	@Input()
	buttonClass: string;

	@Input()
	buttonSize: "small" | "large" | "regular" = "regular";

	@Input()
	buttonDisplay: "block" | "flex" | "inline-block" = "inline-block";

	@Input()
	buttonType: "button" | "submit" | "reset" = "button";

	@Input()
	buttonStyle: { [klass: string]: any } | null;

	@Input()
	buttonDisabled = false;
}
