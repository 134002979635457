<div class="row">
  <div class="col-lg-6">
    <p class="label-form">Provincia</p>
    <fp-select [optionList]="provinces" selectPlaceholder="Provincia">
    </fp-select>
  </div>
  <div class="col-lg-6">
    <p class="label-form">Distrito</p>
    <fp-select [optionList]="districts" selectPlaceholder="Distrito">
    </fp-select>
  </div>
  <div class="col mt-4">
    <p class="label-form">Botica de Despacho</p>
    <fp-select [optionList]="offices" selectPlaceholder="Botica de Despacho">
    </fp-select>
  </div>
</div>
