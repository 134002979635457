import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Coupon } from '../../../../business/savings-wallet/models/coupon.model';

@Component({
  selector: 'app-modal-error-coupons',
  templateUrl: './modal-error-coupons.component.html',
  styleUrls: ['./modal-error-coupons.component.scss']
})
export class ModalErrorCouponsComponent implements OnInit {

  public CuponesNoRedimidos: Coupon[];
  constructor(@Inject(MAT_DIALOG_DATA) public data:any, public dialogRef: MatDialogRef<ModalErrorCouponsComponent>,) { }

  ngOnInit() {
    this.CuponesNoRedimidos = this.data.cupones;
  }

  public removeDialog() {
    this.dialogRef.close();
  }

}
