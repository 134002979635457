<div class="dialog-close">
  <span class="icon icon-24-close icon-close cursor-pointer" (click)="close()"> </span>
</div>
<div class="out-of-stock">
  <div class="out-of-stock__icon">
		<img *ngIf="isMifaBrand" src="../../../../assets/icons/cart-mifa.svg" alt="Carrito Fuera de stock" />
		<img *ngIf="!isMifaBrand" src="../../../../assets/icons/cart-inka.svg" alt="Carrito Fuera de stock" />
  </div>
  <h2>Actualmente, el "{{productName}}" solo esta disponible en despacho a domicilio </h2>
  <ng-container>
    <p>Realiza tu pedido en despacho a domicilio</p>
    <div class="out-of-stock__actions d-flex flex-column">
      <fp-button-primary [buttonDisabled]="loading" class="mb-2" buttonDisplay="inline-block"
        (click)="changeLocation()">
        {{loading ? 'Cargando' : 'Cambiar a despacho a domicilio'}}
      </fp-button-primary>
    </div>
  </ng-container>
</div>