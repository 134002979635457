import { Component, Input, OnInit } from '@angular/core';
import { EProductStatus } from '../../models/product-status.enum';
import { environment } from '@environments/environment';
import { EBrand } from '@parameters/access/brand.parameter';
import { AppStoreService } from '@stores/app/app-store.service';
import { OrderTakerValidator } from '@validators/order-taker.validator';

@Component({
	selector: "fp-product-card-price",
	templateUrl: "./product-card-price.component.html",
	styleUrls: ["./product-card-price.component.sass"]
})
export class ProductCardPriceComponent implements OnInit {
	public eProductStatus = EProductStatus;
	// public isMifaBrand: boolean = EBrand[environment.brand] == EBrand.mifarma;
	public isTomadorBrand = OrderTakerValidator.isOrderTakerEnv();

	@Input() regularPrice: number;
	@Input() price: number;
	@Input() offerPrice: number;
	@Input() productStatus: number = this.eProductStatus.available;
	@Input() customStyle: { [klass: string]: any } | null;
	@Input() fontSize: string = "-1";
	@Input() fontSizeRegular: string = "--1";
	@Input() fontSizeOffer: string = "-1";
	@Input() crossOutPL = false;
	@Input() suggested = false;
	@Input() direction: "right" | "left" = "right"; // left or right

	constructor(private _appStore: AppStoreService) {}

	ngOnInit() {}

	get isMifaBrand() {
		if (this.isTomadorBrand) {
			return this._appStore.brandAtention == "MF";
		} else {
			return EBrand[environment.brand] == EBrand.mifarma;
		}
	}
}
