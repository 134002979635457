import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SnackbarAlertService } from "src/app/core/modules/snackbar/snackbar-alert.service";
import { HTTP_ERROR_RESPONSE_STATUS } from "../parameters/global";
import { UserTokenStoreService } from "../stores/user/user-token-store.service";
@Injectable({
  providedIn: "root",
})
export class OrderTakerInterceptorService implements HttpInterceptor {
  constructor(
    private _snackbarAlert: SnackbarAlertService,
    private _userTokenStoreService: UserTokenStoreService
  ) {}
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        this.handleError(err);
        return throwError(err);
      })
    );
  }
  private handleError(err: HttpErrorResponse) {
    if (OrderTakerValidator.isOrderTakerEnv()) {
      switch (err.status) {
        case HTTP_ERROR_RESPONSE_STATUS.INTENAL_ERROR:
          this._snackbarAlert.snackbarAlertError(
            "¡Ocurrió un error! Intente de nuevo."
          );
          break;
        case HTTP_ERROR_RESPONSE_STATUS.UNAUTHORIZED:
          this._snackbarAlert.snackbarAlertError(
            "Se venció la sesión. Ingrese nuevamente sus credenciales."
          );
          this._userTokenStoreService.logout();
          break;
        default:
          break;
      }
    }
  }
}
