import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { CheckoutAddressItemsFormService } from "src/app/core/modules/address-manager/address-forms/checkout-address-items-form.service";
import { AddressListStoreService } from "src/app/core/modules/address-manager/address-stores/address-list-store.service";
import { HereMapPlacesService } from "src/app/core/modules/here-maps/services/here-map-places.service";
import { AddressItem } from "src/app/shared/models/address/address.model";

@Component({
  selector: "fp-select-office",
  templateUrl: "./select-office.component.html",
  styleUrls: ["./select-office.component.sass"],
})
export class SelectOfficeComponent implements OnInit, AfterViewInit {
  /* prettier-ignore */
  @ViewChild("hereMap", { static: false }) hereMapRef: ElementRef<HTMLDivElement>;

  form: FormGroup;
  addressItem: AddressItem;
  constructor(
    private hereMapPlacesService: HereMapPlacesService,
    private officeModalRef: MatDialogRef<SelectOfficeComponent>,
    private addressListStore: AddressListStoreService,
    public addressItemsForm: CheckoutAddressItemsFormService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      searchText: new FormControl(""),
      idDrugstore: new FormControl("", Validators.required),
    });

    this.hereMapPlacesService.setMarketImg(
      "assets/images/map/icon-marker-inka.png"
    );
    this.addressItem = this.addressListStore.addressList.find(
      (address) => address.id === this.addressItemsForm.addressItemControl.value
    );
  }

  ngAfterViewInit(): void {
    let location = {
      lat: -14.063477273706589,
      lng: -75.73029514257036,
    };
    this.hereMapPlacesService.showMap(this.hereMapRef, location);
    this.hereMapPlacesService.addMarker(location);
  }
  onCancel() {
    this.officeModalRef.close();
  }
  onSave() {
    this.officeModalRef.close();
  }
}
