import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { HandleErrorChangePriceModalComponent } from '../checkout-components/custom-components/handle-error-change-price-modal/handle-error-change-price-modal.component';

@Injectable()
export class HandleErrorChangePriceModalManagerService {
  reloadCheckout = new BehaviorSubject(false)
  resetCoupons = new BehaviorSubject(false)

  constructor(
    private dialog: MatDialog,
    private readonly strategy: ScrollStrategyOptions,
  ) { }

  public showErrorChangePrice(isDesktop): void {
    const isDesk = !isDesktop ? { bottom: "50px" } : {};
    const maxW = !isDesktop ? "450px" : "340px"
    const dialogRef = this.dialog.open(HandleErrorChangePriceModalComponent, {
      panelClass: "fp-styles-handle-error-30",
      disableClose: true,
      maxWidth: maxW,
      width: "100%",
      position: isDesk,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.resetCoupons.next(true);
    })
  }
}
