import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserPickUp } from 'src/app/shared/models/pickup-store/pickup-store.model';

@Injectable()
export class PickUpPersistanceService {

  private _UserPickUpToUpdate: UserPickUp;
  private sendUserPickUpSubject = new BehaviorSubject<UserPickUp | null>(null);
  public sendAddressItem$ = this.sendUserPickUpSubject.asObservable();

  constructor() {
    this.sendAddressItem$.subscribe(address => this._UserPickUpToUpdate = address);
  }

  public get userPickUpToUpdate() {
    return this._UserPickUpToUpdate;
  }

  public setUserPickUpToUpdate(addressItem: UserPickUp) {
    this.sendUserPickUpSubject.next(addressItem);
  }

}
