import { Directive, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
	selector: "[carneExtranjeriaDirective]"
})
export class CarnetExtranjeriaDirective {
	@Input() documentType: "DNI" | "CE" = "DNI"; // Tipo de documento: DNI o CE

	constructor(private ngControl: NgControl) {}

	@HostListener("input", ["$event"])
	onInputChange(event: KeyboardEvent): void {
		const input = event.target as HTMLInputElement;
		let inputValue = input.value;

		if (this.documentType === "DNI") {
			// Solo números y 8 dígitos
			inputValue = inputValue.replace(/[^0-9]/g, "");
			inputValue = inputValue.substring(0, 8);
		} else if (this.documentType === "CE" || this.documentType === "Pasaporte") {
			// Alfanumérico y entre 9 y 12 caracteres
			inputValue = inputValue.replace(/[^a-zA-Z0-9]/g, "");
			if (inputValue.length > 12) {
				inputValue = inputValue.substring(0, 12);
			}
		}

		this.ngControl.control?.setValue(inputValue);
	}
}
