<button [id]="brandId"
        class="vita--button-brand"
        [class.vita--button-brand-full]="brandWidth"
        (click)="clickEvent($event)">

  <img *ngIf="brandIcon!=='brand'" src="../../../../assets/illustration/mini-illustration/{{brandIcon}}.svg"
    class="vita--button-brand-icon" alt="{{brandIcon}} icon"/>

  <span class="vita--button-brand-content">
    <ng-content></ng-content>
  </span>

</button>
