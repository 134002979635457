import { AfterContentInit, Component, ContentChild, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { Subscription, fromEvent, interval } from "rxjs";
import { take } from "rxjs/operators";
import { AppWindowScrollService } from "src/app/shared/behaviors/scroll/app-window-scroll.service";

@Component({
	selector: "fp-lazy-wrapper",
	templateUrl: "./lazy-wrapper.component.html",
	styleUrls: ["./lazy-wrapper.component.sass"]
})
export class LazyWrapperComponent implements OnInit, AfterContentInit, OnDestroy, OnChanges {
	@ContentChild("fpImg") imgRef: ElementRef<HTMLImageElement>;

	private _document: Document = document;
	private _window: Window = window;
	private isReadyImgRef = false;

	private LOADING_IMAGE = "../../../../../assets/images/product/load-image.svg";
	private ERROR_IMAGE_IKF = "../../../../../assets/images/product/error-image-ikf.svg";
	private ERROR_IMAGE_MF = "../../../../../assets/images/product/error-image.svg";
	private isAlreadyLoadedImage = false;
	@Input() lazy = "";

	private currectImageSubscription: Subscription;
	private errorImageSubscription: Subscription;

	private subscriptions: Subscription[] = [];

	constructor(private renderer: Renderer2, private appWindowScroll: AppWindowScrollService, private _appStore: AppStoreService) { }

	ngOnInit() {
		this.isAlreadyLoadedImage = false;
	}

	ngAfterContentInit() {
		this.isReadyImgRef = true;
		this.setImageSrc(this.LOADING_IMAGE);
		this.settingImageErrorEvent();
		this.settingListenerOfEventScrolling();
		this.loadCorrectImage();
		this.loadInitialImage();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['lazy']?.previousValue !== this.lazy && this.imgRef?.nativeElement) {
			this.loadInitialImage();
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.errorImageSubscription.unsubscribe();
	}

	private loadInitialImage() {
		const intervalSub = interval(1000)
			.pipe(take(4))
			.subscribe(() => {
				if (this.isValidBoundingClientRect() && this.shouldLoadCorrectImage()) {
					this.loadCorrectImage();
					intervalSub.unsubscribe();
				}
			});
	}

	private settingListenerOfEventScrolling() {
		this.currectImageSubscription = this.appWindowScroll.scrolling$.subscribe(() => {
			if (this.isReadyImgRef && this.shouldLoadCorrectImage() && !this.isAlreadyLoadedImage) {
				this.loadCorrectImage();
			}
		});
	}

	private settingImageErrorEvent() {
		this.errorImageSubscription = fromEvent(this.imgRef.nativeElement, "error").subscribe(() => {
			if (this.isReadyImgRef) {
				let urlImageError = environment.brand == EBrand.inkafarma ? this.ERROR_IMAGE_IKF : this.ERROR_IMAGE_MF;

				if (OrderTakerValidator.isOrderTakerEnv()) {
					urlImageError =
						EBrand[EBrandAsset[this._appStore.brandAtention]] == EBrand.inkafarma ? this.ERROR_IMAGE_IKF : this.ERROR_IMAGE_MF;
				}

				this.setImageSrc(urlImageError);
			}
		});
	}

	private shouldLoadCorrectImage() {
		const { _document, _window } = this;
		const documentElement = _document.documentElement;
		const documentScrollTop = documentElement.scrollTop;
		// const windowScrollY = _window.scrollY;
		// const bottomScroll = windowScrollY || documentScrollTop;
		const bottomScroll = documentScrollTop;
		const windowHeight = _window.innerHeight;
		const rect = this.imgRef.nativeElement.getBoundingClientRect();
		return bottomScroll + windowHeight > rect.top - 200;
	}

	private isValidBoundingClientRect() {
		const rect = this.imgRef.nativeElement.getBoundingClientRect();
		const { width, height, left, top, right, bottom } = rect;
		const validations = [!!width, !!height, !!left, !!right, !!top, !!bottom];
		return validations.some((validation) => validation);
	}

	private loadCorrectImage() {
		const { _document, _window } = this;
		if (!this.lazy) {
			this.imgRef.nativeElement.dispatchEvent(new Event("error"));
			return;
		}

		this.setImageSrc(this.lazy);
		this.isAlreadyLoadedImage = true;
		this.currectImageSubscription.unsubscribe();
	}

	private setImageSrc(src: string) {
		this.renderer.setAttribute(this.imgRef.nativeElement, "src", src);
	}
}
