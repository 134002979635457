<div class="content-modal">
	<div class="content-modal__header">
		<div class="d-flex align-items-center">
			<div class="avatar mb-0 pb-0">
				<fp-icon iconSize="32" [iconName]="getIconBrand('recipe')"></fp-icon>
			</div>
			<div class="title">
				<h3 class="mb-0">
					<b>{{ titleForm }}</b>
				</h3>
			</div>
		</div>
		<div class="actions">
			<fp-icon class="btn-close" (click)="onClose()" [iconName]="'close-outline-gray'"> </fp-icon>
		</div>
	</div>
	<div class="content-modal__contain">
		<mat-divider class="mat-divider-form" ></mat-divider>
		<div class="contain-steps d-flex justify-content-between mt-4 mb-4">
			<span (mouseleave)="hideInfo()" class="contain-steps__step d-flex align-items-center">
				<p class="form-title-patient mr-3 mb-0">Completa los datos del paciente</p>
				<fp-icon (mouseenter)="showTooltip()" iconSize="16" [iconName]="'info'"></fp-icon>
				<div [ngClass]="{ 'mifa-tooltip': isMifaBrand }" class="tooltip-controlled-products" *ngIf="showInfoControlledProducts">
					<p class="m-0">Todos los campos son obligatorios.</p>
				</div>
			</span>
			<div class="contain-steps__stepper">
				<fp-stepper *ngIf="totalSteps > 1" [total]="totalSteps" [step]="stepSelected" [type]="'simplify'"> </fp-stepper>
			</div>
		</div>
		<mat-horizontal-stepper linear #stepper>
			<mat-step>
				<div class="step-container">
					<fp-cart-controlled-form-step-one></fp-cart-controlled-form-step-one>
				</div>
			</mat-step>
			<mat-step>
				<fp-cart-controlled-form-step-two *ngIf="stepSelected === 2"></fp-cart-controlled-form-step-two>
			</mat-step>
		</mat-horizontal-stepper>
		<mat-dialog-actions align="end">
			<div class="actions-container w-100" [ngSwitch]="stepSelected">
				<div class="step-one w-100 justify-content-end d-flex" *ngSwitchCase="1">
					<fp-button-secondary
						[buttonStyle]="{ height: '48px', 'font-weight': '600', padding: '16px' }"
						class="btn-back-form mr-2"
						(click)="onClose()"
						buttonDisplay="block"
						[buttonClass]="'button-weight-normal'"
					>
						Cancelar
					</fp-button-secondary>
					<fp-button-primary
						class="btn-back-next"
						[buttonStyle]="{ height: '48px', 'font-weight': '600', padding: '16px' }"
						buttonDisplay="block"
						[buttonDisabled]="isFormUserValid"
						(click)="onNext()"
						[buttonClass]="'button-weight-normal'"
					>
						Continuar
					</fp-button-primary>
				</div>
				<div class="step-two w-100 justify-content-end d-flex" *ngSwitchCase="2">
					<fp-button-secondary
						[buttonStyle]="{ height: '48px', 'font-weight': '600', padding: '16px' }"
						class="btn-back-form mr-2"
						(click)="onPrevious()"
						buttonDisplay="block"
						[buttonClass]="'button-weight-normal'"
					>
						Regresar
					</fp-button-secondary>
					<fp-button-primary
						class="btn-back-next"
						[buttonStyle]="{ height: '48px', 'font-weight': '600', padding: '16px' }"
						buttonDisplay="block"
						[buttonDisabled]="isFormRecipeValid"
						(click)="onSave()"
						[buttonClass]="'button-weight-normal'"
					>
						Cargar receta
					</fp-button-primary>
				</div>
			</div>
		</mat-dialog-actions>
	</div>
</div>
