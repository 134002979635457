export enum PaymentGatewayEnum {
  EPAGO = "EPAGO",
  NIUBIZ = "NIUBIZ"
}

export const PAYMENT_GATEWAY = [
  PaymentGatewayEnum.EPAGO,
  PaymentGatewayEnum.NIUBIZ
]

export enum PaymentGatewayResponseEnum {
  CREATED = "CREATED",
  REJECT = "REJECT",
  ERROR = "ERROR",
  DENIED = "DENIED",
  AUTHORIZED =  "AUTHORIZED",
  CLOSED = "CLOSED",
  CANCELED = "CANCELED",
}

export const PAYMENT_GATEWAY_RESPONSE = [
  PaymentGatewayResponseEnum.ERROR as string,
  PaymentGatewayResponseEnum.DENIED as string,
  PaymentGatewayResponseEnum.AUTHORIZED as string,
  PaymentGatewayResponseEnum.CLOSED as string,
  PaymentGatewayResponseEnum.REJECT as string,
  PaymentGatewayResponseEnum.CREATED as string,
  PaymentGatewayResponseEnum.CANCELED as string
]
