import { Injectable } from "@angular/core";
import { EBrand } from "@parameters/access/brand.parameter";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { Asset, ContentfulClientApi, createClient, Entry } from "contentful";
import * as marked from "marked";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ContentfulEntry } from "../../models/contentful/contentful.model";
import { AppStoreService } from "../../stores/app/app-store.service";

@Injectable({
  providedIn: "root",
})
export class GenericContentfulService {
  private renderer = new marked.Renderer();

  private cdaClient: ContentfulClientApi;

  constructor(private _appStore: AppStoreService) {
    this.renderer.link = (href: string, title: string, text: string) => {
      return `<a target="_blank" class="link-contentful" href="${href}">${text}</a>`;
    };

    if (OrderTakerValidator.isOrderTakerEnv()) {
      const currentBrand = this._appStore.brandAtention;

      const contenfullConfig =
        currentBrand === EBrand.inkafarma
          ? environment.CONTENTFUL_CONFIG
          : environment.CONTENTFUL_CONFIG_MIFA;

      this.cdaClient = createClient({
        space: contenfullConfig.space,
        accessToken: contenfullConfig.accessToken,
        environment: contenfullConfig.environment,
      });
    } else {
      this.cdaClient = createClient({
        space: environment.CONTENTFUL_CONFIG.space,
        accessToken: environment.CONTENTFUL_CONFIG.accessToken,
        environment: environment.CONTENTFUL_CONFIG.environment,
      });
    }
  }

  private get configClient() {
    return this.cdaClient;
  }

  getEntries<T>(contentType?: String, query?: object) {
    return from(
      this.configClient
        .getEntries<T>(
          Object.assign(
            {
              content_type: contentType,
            },
            query
          )
        )
        .then((res) => res.items)
    );
  }

  getEntry<T>(contentId: any): Observable<T> {
    const promise = this.configClient.getEntry<T>(contentId);
    return from(promise).pipe(map((entry) => entry.fields));
  }

  getEntryPromise(contentId: any) {
    return this.configClient.getEntry(contentId);
  }

  getAsset(id: any): Observable<Asset> {
    return from(this.configClient.getAsset(id));
  }

  // TODO: MODIFY PARENT
  getEntriesPromise<T>(
    contentType?: string,
    query?: object
  ): Observable<Array<ContentfulEntry<T>>> {
    const promise = this.configClient.getEntries(
      Object.assign(
        {
          content_type: contentType,
        },
        query
      )
    );
    return from(promise).pipe(
      map((entry) =>
        entry.items.length
          ? entry.items.map((item: Entry<T>) => {
              return new ContentfulEntry<T>(item);
            })
          : []
      )
    );
  }

  async getEntriesP(
    contentType?: string,
    query?: object
  ): Promise<Entry<any>[]> {
    return this.configClient
      .getEntries(
        Object.assign(
          {
            content_type: contentType,
          },
          query
        )
      )
      .then((res) => res.items);
  }

  // convert markdown string to
  markdownToHtml(md: string) {
    return marked(md, { renderer: this.renderer });
  }

  // console logs a response for debugging
  logContent(contentId: any) {
    this.configClient
      .getEntry(contentId)
      .then((entry) => console.log(entry.fields));
  }
}
