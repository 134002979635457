import {Component, Input, OnInit} from '@angular/core';
import {PurchaseSummary} from '../../../../shared/models/shopping-cart/shopping-cart-precalculation.model';
import { EBrand } from '@parameters/access/brand.parameter';

@Component({
  selector: 'fp-purchase-summary-list',
  templateUrl: './purchase-summary-list.component.html',
  styleUrls: ['./purchase-summary-list.component.sass']
})
export class PurchaseSummaryListComponent implements OnInit {
  public brand = EBrand;

  @Input() summary: PurchaseSummary;

  constructor() {
  }

  ngOnInit() {}

}
