import { SnackbarService } from './snackbar.service';
import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { BREAKPOINTS } from '../../../shared/parameters/global';
import { BreakpointBehavior } from '../../../shared/behaviors/breakpoints/breakpoint.behavior';

@Injectable()
export class SnackbarToastService {

  private toastDefaultConfig: MatSnackBarConfig = { duration: 3000 };
  private toastConfigSmall: MatSnackBarConfig = { ...this.toastDefaultConfig, verticalPosition: 'top' };
  private toastConfigLarge: MatSnackBarConfig = { ...this.toastDefaultConfig, verticalPosition: 'bottom' };

  constructor(private _snackbarService: SnackbarService,
    public _breakpointBehavior: BreakpointBehavior) {
  }

  private isBigScreen() {
    return this._breakpointBehavior.isMatchedMediaBreakpointUp(BREAKPOINTS.lg);
  }

  public toast(config: MatSnackBarConfig) {
    return this._snackbarService.snackBarToast(config);
  }

  public snackBarToast(message: string, additionalConfig: MatSnackBarConfig = {}) {
    const toastConfig = this.isBigScreen() ? this.toastConfigLarge : this.toastConfigSmall;
    const config = { data: message, ...toastConfig, ...additionalConfig };
    return this.toast(config);
  }

}
