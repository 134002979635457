<div class="payment-card-content"
  (click)="selectOption()"
  [ngStyle]="{'opacity': isDisabled ? '0.4' : '1'}">
  <fp-border-container [selected]="selected">
    <div class="card-container">
      <div class="card-section">
        <div class="radio-control-content">
          <ng-content></ng-content>
        </div>
        <div class="type-payment">
          <fp-image [imageSrc]="imageSrc"
                    [imageAlt]="imageSrc"
                    imageClass="img-fluid"
                    [boxSpacing]="false"
                    boxSize="46px">
          </fp-image>
        </div>
      </div>
      <div class="text-payment label-black--3 mt-2 text-center" *ngIf="discountText">
        {{ discountText }}
      </div>
    </div>
  </fp-border-container>
</div>
