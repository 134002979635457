import { Injectable } from '@angular/core';
import { GenericService } from '../generic';
import { IUpdateAnonymousRequestParams } from '../../models/update-anonymous/update-anonymous.interface';
import { ENDPOINTS } from '../../parameters/endpoints';
import { IUserRegister } from 'src/app/shared/models/register/register.model';

@Injectable()
export class UpdateAnonymousClientService {

  constructor(
    private genericService: GenericService,
  ) {
  }

  public updateAnonymousInformation$(params: IUpdateAnonymousRequestParams) {
    return this.genericService.genericPost<IUserRegister>(ENDPOINTS.UPDATE_ANONYMOUS, params);
  }
}
