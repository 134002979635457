import { environment } from "./../../../../environments/environment";
import { Injectable } from "@angular/core";
import { GenericService } from "../generic";
import { Observable } from "rxjs";
import { ENDPOINTS } from "../../parameters/endpoints";
import { HttpHeaders } from "@angular/common/http";
import { AppStoreService } from "@stores/app/app-store.service";
import { EBrand } from "@parameters/access/brand.parameter";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { CustomToken } from "../../models/custom-token";
import { tap } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class ApigeeTokenService {
	constructor(private genericService: GenericService, private _appStore: AppStoreService) {}

	public getTokenFromOauth(): Observable<any> {
		const header = new HttpHeaders({
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: `Basic ${OrderTakerValidator.isOrderTakerEnv() ? environment.apigeeTomador.token : environment.apigee.token}`
		});
		const body = new URLSearchParams();
		body.set("grant_type", "client_credentials");
		const url = OrderTakerValidator.isOrderTakerEnv() ? ENDPOINTS.APIGEE_POST_OAUTH_TOKEN_TOMADOR : ENDPOINTS.APIGEE_POST_OAUTH_TOKEN;
		return this.genericService.genericPostApigee(url, body.toString(), null, header).pipe();
	}

	public getTokenFromOauthApproval(): Observable<any> {
		const header = new HttpHeaders({
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: `Basic ${environment.apigee.token_approval}`
		});
		const body = new URLSearchParams();
		body.set("grant_type", "client_credentials");
		const url = ENDPOINTS.APIGEE_POST_OAUTH_TOKEN;
		return this.genericService.genericPostApigee(url, body.toString(), null, header).pipe();
	}

	get atentionOtherBrandFromInka() {
		const currentBrand = this._appStore.brandAtention;
		return OrderTakerValidator.isOrderTakerEnv() ? currentBrand === EBrand.inkafarma : true;
	}

	public getTokenFromControlled(): Observable<any> {
		const header = new HttpHeaders({
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: `Basic ${environment.apigee.tokenControlled}`
		});
		const body = new URLSearchParams();
		body.set("grant_type", "client_credentials");
		const url = ENDPOINTS.APIGEE_POST_OAUTH_TOKEN;
		return this.genericService.genericPostApigee(url, body.toString(), null, header).pipe();
	}
	public getTokenFromOauthParam(token: string): Observable<any> {
		const header = new HttpHeaders({
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: `Basic ${token}`
		});
		const body = new URLSearchParams();
		body.set("grant_type", "client_credentials");
		const url = ENDPOINTS.APIGEE_POST_OAUTH_TOKEN;
		return this.genericService.genericPostApigee(url, body.toString(), null, header).pipe();
	}
}
