import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "fp-skeleton-for-pick-up-modal",
	templateUrl: "./skeleton-for-pick-up-modal.component.html",
	styleUrls: ["./skeleton-for-pick-up-modal.component.sass"]
})
export class SkeletonForPickUpModalComponent implements OnInit {
	@Input() type: string = "medium";
	constructor() {}

	ngOnInit() {}
}
