import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProductShoppingCartRequest } from "@models/shopping-cart/product-shopping-cart-request.model";
import { END_POINTS_BUSSINESS } from "@parameters/generic/endpoints";
import { EHttpHeaders } from "@parameters/generic/http-header.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { TokenStoreService } from "@stores/user/token-store.service";
import { Observable } from "rxjs";
import { GenericService } from "../generic";

@Injectable({
  providedIn: "root",
})
export class CartRecoverPendingItemsService {
  private RECOVER_PENDING_ITEMS_ENDPOINT =
    END_POINTS_BUSSINESS.RECOVER_PENDING_ITEMS;
  private headers: HttpHeaders;

  constructor(
    private genericService: GenericService,
    private _tokenStoreService: TokenStoreService,
    private _appStoreService: AppStoreService
  ) {
    this.headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });
  }

  getRecoverPendingItems(
    idClient: string
  ): Observable<ProductShoppingCartRequest[]> {
    const endpoint = `${this.RECOVER_PENDING_ITEMS_ENDPOINT}/${idClient}`;

    const { drugstoreStock } = this._appStoreService;
    const httpHeaders = new HttpHeaders({
      "drugstore-stock": drugstoreStock,
      androidversion: "100000",
    });

    return this.genericService.genericGet(endpoint, null, httpHeaders);
  }
}
