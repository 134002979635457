import { Component, OnInit } from "@angular/core";
import { WhoPickUpFormService } from "../../pick-up-forms/who-pick-up-form.service";
import { ModalsManagerHelperService } from "../../pick-up-services/modals-manager-helper.service";
import { PickUpImplementService } from "../../pick-up-implements/pick-up-implement.service";
import { PickUpRequestService } from "../../pick-up-services/pick-up-request.service";
import { UserPickUp } from "src/app/shared/models/pickup-store/pickup-store.model";
import { take, switchMap } from "rxjs/operators";
import { PickUpPersistanceService } from "../../pick-up-services/pick-up-persistance.service";
import { WhoPickUpFormHelperService } from "../../pick-up-forms/who-pick-up-form-helper.service";
import { PeopleForPickUpStoreService } from "../../pick-up-stores/people-for-pick-up-store.service";
import { Observable, Subject, Subscription } from "rxjs";
import { Validators } from "@angular/forms";
import { CONFIG_USER_NOT_REGISTERED } from "@parameters/enums";
import { BreakpointObserver } from "@angular/cdk/layout";
import { BREAKPOINTS } from "@parameters/global";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { SnackbarAlertInComingService } from "../../../snackbar/snackbar-alert-incoming.service";

@Component({
	selector: "fp-who-pick-up-modal",
	templateUrl: "./who-pick-up-modal.component.html",
	styleUrls: ["./who-pick-up-modal.component.sass"]
})
export class WhoPickUpModalComponent implements OnInit {
	private subscriptions: Subscription[] = [];

	public typesDocs = [
		{ value: "1", text: "DNI" },
		{ value: "2", text: "C.E." },
		{ value: "3", text: "Pasaporte" }
	];
	public isEdit = false;
	public userToUpdate: UserPickUp = this.pickUpPersistanceService.userPickUpToUpdate;

	public isLoadingModal = false;
	public isDesktopVersion = false;

	// subjects to cancel request
	private retCancelSubject = new Subject();

	private toastConfigSmallFormDeleteDirection: MatSnackBarConfig = {
		duration: 3000,
		horizontalPosition: "center",
		verticalPosition: "top"
	};

	constructor(
		public whoPickUpForm: WhoPickUpFormService,
		public whoPickUpFormHelper: WhoPickUpFormHelperService,
		public pickUpPersistanceService: PickUpPersistanceService,
		public pickUpRequestService: PickUpRequestService,
		public pickUpImplementService: PickUpImplementService,
		private modalsManagerHelper: ModalsManagerHelperService,
		private peopleForPickUpStore: PeopleForPickUpStoreService,
		public breakpointObserver: BreakpointObserver,
		private _snackbarAlertInComingService: SnackbarAlertInComingService
	) {
		this.isEdit = false;
	}

	ngOnInit() {
		this.whoPickUpForm.resetInvoiceForm();
		this.whoPickUpFormHelper.resetFormToCreateUser();
		if (this.userToUpdate) {
			this.isEdit = true;
			this.whoPickUpFormHelper.setFormValuesToUpdate(this.userToUpdate);
		}
		localStorage.removeItem("SCREEN");
		const breakpointSubscription = this.breakpoint$.subscribe((breakPointState) => (this.isDesktopVersion = breakPointState.matches));
		this.subscriptions.push(breakpointSubscription);
	}

	private get breakpoint$() {
		return this.breakpointObserver.observe([`(min-width: ${BREAKPOINTS.sm}px)`]);
	}

	public closeModal() {
		this.modalsManagerHelper.closeWhoPickUpModal();
	}

	public onRegistrar() {
		this.isLoadingModal = true;
		this.pickUpRequestService.requestParams.fullName = this.whoPickUpForm.nameControl.value;
		this.pickUpRequestService.requestParams.email = this.whoPickUpForm.emailAddressControl.value;
		this.pickUpRequestService.requestParams.identityDocumenType = this.whoPickUpForm.typeDocControl.value;
		this.pickUpRequestService.requestParams.identityDocumentNumber = this.whoPickUpForm.dniControl.value;
		this.pickUpRequestService.requestParams.phone = this.whoPickUpForm.phoneNumberControl.value;
		const action$ = this.isEdit
			? this.pickUpImplementService.updateUser$(this.userToUpdate.id, this.pickUpRequestService.requestParams)
			: this.pickUpImplementService.registerUser$(this.pickUpRequestService.requestParams);

		console.log(this.isEdit);
		this.loadPeopleAfterCRUDActionByAction(action$);
	}

	public deleteUser() {
		this.isLoadingModal = true;
		const action$ = this.pickUpImplementService.deleteUser$(this.userToUpdate.id);
		this.loadPeopleAfterCRUDActionByAction(action$);
		action$.subscribe({
			next: () =>
				this._snackbarAlertInComingService.snackbarAlertSuccess(
					"Eliminado correctamente.",
					this.toastConfigSmallFormDeleteDirection
				)
		});
	}

	public loadPeopleAfterCRUDActionByAction(action$: Observable<any>) {
		action$
			.pipe(switchMap(() => this.pickUpImplementService.getPeopleForStorePickup$()))
			.pipe(take(1))
			.subscribe(
				(people) => {
					this.peopleForPickUpStore.setPeople(people);
					setTimeout(() => {
						this.isLoadingModal = false;
						this.closeModal();
					}, 100);
				},
				() => {
					this.isLoadingModal = false;
				}
			);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.retCancelSubject.next();
	}
}
