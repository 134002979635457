<div *ngIf="isUserExist" class="container-third-form d-flex justify-content-between align-items-center">
	<div class="isforthird-text">¿La receta médica es para un tercero?</div>
	<div class="d-flex align-items-center">
		<span>{{ isForThird }}</span>
		<label class="switch m-0 ml-3">
			<input type="checkbox" [id]="'inputId'" name="category-switch" [(ngModel)]="isCheckedSwitch" (change)="clickSwitch($event)" />
			<span class="slider round"></span>
		</label>
	</div>
</div>
<form class="form-container-patient" [formGroup]="_userControlledFormService.form">
	<div class="row m-0">
		<div class="input-form col-lg-6 col-sm-12 pb-4 px-0 input-form-left">
			<fp-input-control
				[inputTitle]="'Nombres y apellidos'"
				[errorMessage]="_userControlledFormService.namesControl.fullnameError"
				[marginBottomZero]="true"
				[errorMessageClass]="'top-70'"
			>
				<input
					id="ctrl--names"
					#inputControl
					class="w-100 input"
					placeholder="Ingresa nombres y apellidos"
					maxlength="50"
					formControlName="names"
					appOnlyLetters
				/>
			</fp-input-control>
		</div>
		<div class="input-form col-lg-6 col-sm-12 pb-4 px-0 input-form-right">
			<fp-input-control
				[inputTitle]="'Número de contacto'"
				[errorMessage]="_userControlledFormService.phoneNumberControl.phoneNumberError"
				[marginBottomZero]="true"
				[errorMessageClass]="'top-70'"
			>
				<input
					id="ctrl--names"
					#inputControl
					class="w-100 input"
					placeholder="Ingresa el número de contacto"
					maxlength="50"
					formControlName="phoneNumber"
					appNumericOnly
					[maxDigits]="9"
				/>
			</fp-input-control>
		</div>
		<div class="input-form col-lg-6 col-sm-12 pb-4 px-0 input-form-left">
			<fp-input-control
				[inputTitle]="'Tipo de documento'"
				[errorMessage]="_userControlledFormService.documentTypeControl.errors"
				[marginBottomZero]="true"
				[errorMessageClass]="'top-70'"
			>
				<fp-select-control [innerClass]="'simple-arrow'" [options]="documentTypes" formControlName="documentType">
				</fp-select-control>
			</fp-input-control>
		</div>
		<div class="input-form col-lg-6 col-sm-12 pb-4 px-0 input-form-right">
			<fp-input-control
				[inputTitle]="'Número de documento'"
				[errorMessage]="_userControlledFormService.documentNumberControl.ducumentError"
				[marginBottomZero]="true"
				[errorMessageClass]="'top-70'"
			>
				<input
					id="ctrl--names"
					#inputControl
					class="w-100 input"
					placeholder="Ingresa número de documento"
					maxlength="50"
					formControlName="documentNumber"
					carneExtranjeriaDirective
					[documentType]="documentTypeValidator"
				/>
			</fp-input-control>
		</div>

		<div class="input-form col-lg-6 col-sm-12 pb-4 px-0 input-form-left">
			<fp-input-control
				[inputTitle]="'Correo electrónico'"
				[errorMessage]="_userControlledFormService.emailControl.emailAddressError"
				[marginBottomZero]="true"
				[errorMessageClass]="'top-70'"
			>
				<input
					id="ctrl--names"
					#inputControl
					class="w-100 input"
					placeholder="Ingresa correo electrónico"
					maxlength="50"
					formControlName="email"
					fpCharacterOnly
				/>
			</fp-input-control>
		</div>
		<div class="input-form col-lg-6 col-sm-12 pb-4 px-0 input-form-right">
			<fp-input-control
				[inputTitle]="'Fecha de nacimiento'"
				[errorMessage]="_userControlledFormService.bornDateControl.birthDateError"
				[marginBottomZero]="true"
				[errorMessageClass]="'top-70'"
			>
				<input class="input mb-0" type="date" formControlName="bornDate" placeholder="DD/MM/AAAA" />
				<!-- <input matInput [matDatepicker]="picker" placeholder="Fecha de nacimiento" formControlName="bornDate" />
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker> -->
			</fp-input-control>
		</div>

		<div class="input-form col-lg-6 col-sm-12 pb-4 px-0 input-form-left">
			<fp-input-control
				[inputTitle]="'Género'"
				[errorMessage]="_userControlledFormService.genderControl.genderError"
				[marginBottomZero]="true"
				[errorMessageClass]="'top-70'"
			>
				<fp-select-control [innerClass]="'simple-arrow'" [options]="genderOptions" formControlName="gender"> </fp-select-control>
			</fp-input-control>
		</div>
	</div>
</form>
