export class FrequentlyAskedQuestion {
  title: string;
  slug: string;
  body: string;
  type: string;

  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  canonicalUrl: string;
  structuredData: string;


  constructor(data) {
    this.title = data.title;
    this.slug = data.slug;
    this.body = data.body;
    if (data.type) { this.type = data.type; }
    this.metaTitle = data.metaTitle;
    this.metaDescription = data.metaDescription;
    this.metaKeywords = data.metaKeywords;
    if (data.canonicalUrl) {
      this.canonicalUrl = data.canonicalUrl
    }
    if (data.structuredData) {
      this.structuredData = data.structuredData
    }
  }
}
