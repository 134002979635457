export class PositionControl {

  private controlDiv: HTMLDivElement;
  public controlUI: HTMLDivElement;

  constructor(controlDiv: HTMLDivElement) {
    this.controlDiv = controlDiv;
    this.createControl();
  }

  private createControl() {
    this.controlUI = document.createElement('div');
    this.controlUI.style.width = '40px';
    this.controlUI.style.height = '40px';
    this.controlUI.style.cursor = 'pointer';
    this.controlUI.style.marginRight = '10px';
    this.controlUI.style.marginBottom = '5px';
    this.controlUI.style.display = 'flex';
    this.controlUI.style.justifyContent = 'center';
    this.controlUI.style.alignItems = 'center';
    this.controlUI.style.boxShadow = '0 2px 4px rgba(0,0,0,.3)';
    this.controlUI.style.backgroundColor = '#fff';
    this.controlUI.style.borderRadius = '3px';
    this.controlUI.title = 'Click to change to the marker position';
    this.controlDiv.appendChild(this.controlUI);

    const controlText = document.createElement('div');
    controlText.style.backgroundImage = 'url(../../../../../../assets/images/position-marker.png)';
    controlText.style.width = '18px';
    controlText.style.height = '18px';
    controlText.style.backgroundPosition = 'center';
    controlText.style.backgroundSize = 'cover';
    this.controlUI.appendChild(controlText);
  }

}

