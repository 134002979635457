import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export interface IEnabledDays {
  value: string;
  [key: string]: string | number | boolean;
}

const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

 
@Component({
  selector: 'fp-input-date-picker-accessor',
  templateUrl: './input-date-picker-accessor.component.html',
  styleUrls: ['./input-date-picker-accessor.component.sass', '../input/input.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatePickerAccessorComponent),
      multi: true,
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}
  ],
})
export class InputDatePickerAccessorComponent extends InputComponent implements ControlValueAccessor  {

  @Input() inputDatePickerReadonly: boolean;
  @Input() inputDatePickerStartView: 'month' | 'year' | 'multi-year';
  @Input() inputDatePickerFormat = 'DD MM YYYY';
  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;
  @Input() isDesktop = true;



  public inputDatePickerValue = new FormControl();
  public isDisabled = false;

  private _onChange = (_:any)=>{};
  private _onTouched = () => undefined;


  @Input() scheduleDays: IEnabledDays[] = []; // optional, usar en caso se tenga una lista estricta de dias disponibles y bloqueados

  writeValue(value: moment.Moment): void {
    if(value){
      this.inputDatePickerValue.setValue(moment(value, this.inputDatePickerFormat));
    }else{
      this.inputDatePickerValue.setValue(null)
    }
  }

  inputChangeDate(datePicker:any){
    this._onTouched();
    if (datePicker.value.isValid()) {
      this._onChange( moment(datePicker.value).format(MY_DATE_FORMATS.parse.dateInput)  )
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if(isDisabled){
      this.inputDatePickerValue.disable();
      this.isDisabled = true;
    }else{
      this.inputDatePickerValue.enable();
      this.isDisabled = false;
    }
  }



}
