import { BreakpointObserver } from '@angular/cdk/layout';
import { Directive, Injectable } from '@angular/core';
@Directive()
@Injectable()
export class BreakpointBehavior {

  constructor(public _breakpointObserver: BreakpointObserver) {
  }

  public hoverObserver() {
    return this._breakpointObserver.observe([
      `(pointer: fine) and (hover: hover)`,
      `(pointer: coarse) and (hover: hover)`
    ]);
  }

  public touchObserver() {
    return this._breakpointObserver.observe([
      `(pointer: fine) and (hover: none)`,
      `(pointer: coarse) and (hover: none)`
    ]);
  }

  public touchAndMediaBreakpointDownObserver(breakpointMax: number) {
    return this._breakpointObserver.observe([
      `(pointer: fine) and (hover: none)`,
      `(pointer: coarse) and (hover: none)`,
      `(max-width: ${breakpointMax}px)`
    ]);
  }

  public touchAndMediaBreakpointUpObserver(breakpointMin: number) {
    return this._breakpointObserver.observe([
      `(pointer: fine) and (hover: none)`,
      `(pointer: coarse) and (hover: none)`,
      `(min-width: ${breakpointMin}px)`
    ]);
  }

  public hoverAndMediaBreakpointUpObserver(breakpointMin: number) {
    return this._breakpointObserver.observe([
      `(pointer: fine) and (hover: hover)`,
      `(pointer: coarse) and (hover: hover)`,
      `(min-width: ${breakpointMin}px)`
    ]);
  }

  public hoverAndMediaBreakpointDownObserver(breakpointMax: number) {
    return this._breakpointObserver.observe([
      `(pointer: fine) and (hover: hover)`,
      `(pointer: coarse) and (hover: hover)`,
      `(max-width: ${breakpointMax}px)`
    ]);
  }

  public mediaBreakpointDownObserver(breakpointMax: number) {
    return this._breakpointObserver.observe([
      `(max-width: ${breakpointMax}px)`
    ]);
  }

  public mediaBreakpointUpObserver(breakpointMax: number) {
    return this._breakpointObserver.observe([
      `(min-width: ${breakpointMax}px)`
    ]);
  }


  public mediaBreakpointBetweenObserver(breakpointMin: number, breakpointMax: number) {
    return this._breakpointObserver.observe([
      `(max-width: ${breakpointMax - 0.5}px) and (min-width: ${breakpointMin}px)`
    ]);
  }

  public isMatchedMediaBreakpointUp(breakpointMin: number) {
    return this._breakpointObserver.isMatched(`(min-width: ${breakpointMin}px)`);
  }

  public isMatchedMediaBreakpointDown(breakpointMax: number) {
    return this._breakpointObserver.isMatched(`(max-width: ${breakpointMax}px)`);
  }

}
