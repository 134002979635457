import { ApigeeStoreService } from "./../../apigee/apigee-store.service";
import { RegistrationValidationService } from "./../../../../core/modules/register/register-services/registration-validation.service";
import { GuardValidatorService } from "./../../../stores/app/guard-validator.service";
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { EMPTY, Observable, from, of } from "rxjs";
import { switchMap, catchError, delay, map } from "rxjs/operators";
import { ENDPOINTS } from "../../../parameters/endpoints";
import { environment } from "../../../../../environments/environment";
import { ShoppingListStoreService } from "src/app/shared/stores/shopping-list/shopping-list-store.service";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { APIGEE_INTERCEPTOR_PATHS, DISPATCH_TYPE, NAVIGATION_URL } from "src/app/shared/parameters/global";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { HTTP_ERROR_RESPONSE_STATUS } from "./../../../parameters/global";
import firebase from "firebase/compat/app";
import { AuthImplementService } from "@implements/auth/auth-implement.service";
import { AuthGuard } from "@guards/app/auth-guard.service";
import { NewRegisterStoreService } from "@stores/user/new-register-store.service";
import { EBrand, EBrandCode } from "@parameters/access/brand.parameter";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { StoresForPickUpStoreService } from "src/app/core/modules/pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service";
import { AddressListStoreService } from "src/app/core/modules/address-manager/address-stores/address-list-store.service";
import { RetValidateCartService } from "../../cart/ret-validate-cart.service";
import { SelectDataStoreService } from "src/app/business/office/store/select-data-store.service";
import { ApigeeTokenService } from "../../apigee/apigee-token.service";
import { CoverageLocationStoreService } from "@stores/coverage-location/coverage-location-store.service";
import { UserTokenStoreService } from "@stores/user/user-token-store.service";

@Injectable({
	providedIn: "root"
})
export class TokenInterceptorService implements HttpInterceptor {
	private token: string;
	private isGuardEndpoint: Boolean = false;
	private allowedUrlToResendRefreshToken = [
		environment.api_gateway,
		environment.api_gateway_05,
		"https://nwrs3lmu0i.execute-api.us-east-1.amazonaws.com/CI01",
		environment.api_gateway_missions
	];
	private endpointsDontRequireToken = [
		ENDPOINTS.USER_SERVICE,
		ENDPOINTS.SALESFORCE_PERSONALITATION_INKA,
		ENDPOINTS.SALESFORCE_PERSONALITATION_MIFA
	];

	constructor(
		private _authImplementService: AuthImplementService,
		private shoppingListStore: ShoppingListStoreService,
		private newRegisterStore: NewRegisterStoreService,
		private _appStoreService: AppStoreService,
		private guardValidatorService: GuardValidatorService,
		private registrationValidationService: RegistrationValidationService,
		private apigeeStoreService: ApigeeStoreService,
		private authGuard: AuthGuard,
		private _selectDataStoreService: SelectDataStoreService,
		private storesForPickUpStore: StoresForPickUpStoreService,
		private _addressListStore: AddressListStoreService,
		private apigeeTokenService: ApigeeTokenService,
		private retCartService: RetValidateCartService,
		private coverageLocationStore: CoverageLocationStoreService,
		private _userTokenStoreService: UserTokenStoreService
	) {
		this.guardValidatorService.isGuardEndpoint$.subscribe((value) => {
			this.isGuardEndpoint = value;
		});
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const url = request.url;
		const valid = this.allowedUrlToResendRefreshToken.find((allowedurl) => url.startsWith(allowedurl));
		const drugstore_stock_ret = JSON.parse(this._appStoreService.getStorageItem("drugstoreWareHouseId"));
		let anonymousBody;
		let RET3CustomHeaders = {};
		let isRad = this.retCartService.isRADProcess;

		let drugstoreStock = this._appStoreService.drugstoreStock?.toString() ?? "";
		let drugstoreRetStock = this._appStoreService?.drugstoreRetStock
			? this._appStoreService?.drugstoreRetStock?.toString()
			: drugstore_stock_ret;
		const drugstore = this.storesForPickUpStore.selectedDrugstore;
		const coverageResponse = this.coverageLocationStore.coverageLocationResponse;
		const currentAddreses = this._addressListStore.currentSelectedAddressFromStore;
		// const intensionRet = this._appStoreService.getStorageItem("intensionRet");
		if (/validate-cart/.test(request.url) && this.retCartService.isRADProcess) {
			if (
				isRad &&
				!this.retCartService.needValidateCoverage &&
				coverageResponse.favorite?.userAddressId === currentAddreses?.id &&
				!this.coverageLocationStore.isCovered
			) {
				this.retCartService.showOutCoverageDialog(drugstore, currentAddreses);
				return EMPTY;
			}

			if (isRad && this.retCartService.needValidateCoverage && this.isCurrentCovered(coverageResponse)) {
				this.retCartService.showOutCoverageDialog(drugstore, currentAddreses);
				return EMPTY;
			}
		}

		let drugstoreCode = this._addressListStore.currentSelectedAddressFromStore?.drugstoreAddress?.localCode ?? "";
		if (OrderTakerValidator.isOrderTakerEnv()) {
			if (this._selectDataStoreService.zoneBackup) {
				drugstoreCode = this._selectDataStoreService.zoneBackup.fulfillmentCenterCode;
			} else {
				const wharehouseId = this._selectDataStoreService.nearbyStore?.drugstoreWareHouseId;
				if (wharehouseId) {
					drugstoreStock = wharehouseId.toString();
					drugstoreRetStock = wharehouseId.toString();
					drugstoreCode = this._selectDataStoreService.nearbyStore?.localCode;
				}
			}
		}

		RET3CustomHeaders = this.getRET3CustomHeaders(request.url, drugstoreCode, drugstoreStock, drugstoreRetStock);

		const isValidOauth =
			(url.includes(environment.apigee.url) || url.includes(environment.apigeeTomador?.url)) &&
			url !== ENDPOINTS.APIGEE_POST_OAUTH_TOKEN &&
			url !== ENDPOINTS.APIGEE_POST_OAUTH_TOKEN_TOMADOR &&
			!url.includes(ENDPOINTS.APIGEE_GET_CUSTOMER_APPROVAL) &&
			!url.includes(ENDPOINTS.APIGEE_GET_CONTROLLED_PRODUCTS);
		const controlled = request.url.includes(ENDPOINTS.APIGEE_GET_CONTROLLED_PRODUCTS);
		// if (controlled) {
		// 	// request = this.newApigeeClone(request, RET3CustomHeaders);
		// 	// return next.handle(request).pipe(
		// 	// 	catchError((error) => {
		// 	// 		if (error.status === 401) {
		// 	// 			return this.apigeeTokenService.getTokenFromControlled().pipe(
		// 	// 				switchMap((apigeeNewToken) => {
		// 	// 					this.apigeeStoreService.oAuthToken.next(apigeeNewToken);
		// 	// 					// Include the new token in the request headers
		// 	// 					return next
		// 	// 						.handle(this.newApigeeClone(request, RET3CustomHeaders, apigeeNewToken.access_token))
		// 	// 						.pipe(delay(2000));
		// 	// 				})
		// 	// 			);
		// 	// 		}
		// 	// 	})
		// 	// );

		// 	return this.apigeeTokenService.getTokenFromControlled().pipe(
		// 		switchMap((token: any) => {
		// 			if (token.access_token) {
		// 				let cloned = request;

		// 				cloned = request.clone({
		// 					setHeaders: {
		// 						Authorization: `Bearer ${token.access_token}`
		// 					}
		// 				});
		// 				return next.handle(cloned);
		// 			} else {
		// 				return next.handle(request);
		// 			}
		// 		})
		// 	);
		// }
		if (isValidOauth) {
			request = this.newApigeeClone(request, RET3CustomHeaders);
			return next.handle(request).pipe(
				catchError((error) => {
					if (error.status === 401) {
						//token apigee
						return this.apigeeTokenService.getTokenFromOauth().pipe(
							switchMap((apigeeNewToken) => {
								this.apigeeStoreService.oAuthToken.next(apigeeNewToken);
								return next.handle(this.newApigeeClone(request, RET3CustomHeaders)).pipe(delay(2000));
							})
						);
					}
				})
			);
		}
		if (isValidOauth) {
			request = this.newApigeeClone(request, RET3CustomHeaders);
			return next.handle(request).pipe(
				catchError((error) => {
					if (error.status === 401) {
						//token apigee
						return this.apigeeTokenService.getTokenFromOauth().pipe(
							switchMap((apigeeNewToken) => {
								this.apigeeStoreService.oAuthToken.next(apigeeNewToken);
								return next.handle(this.newApigeeClone(request, RET3CustomHeaders)).pipe(delay(2000));
							})
						);
					}
				})
			);
		}

		const requestIsFromAnonymous = url.includes(ENDPOINTS.UPDATE_ANONYMOUS);
		if (requestIsFromAnonymous) {
			anonymousBody = request.body;
			const userAcceptCookies = localStorage.getItem("user_accept_cookies");
			anonymousBody.flagCookiePolicies = !!userAcceptCookies;
		}

		const requestIsFromDeliveryInfo = url.includes(ENDPOINTS.DELIVERY_INFO);

		if (valid) {
			return from(firebase.auth().currentUser.getIdToken()).pipe(
				switchMap((token) => {
					this.token = token;
					const dontRequiresToken = this.endpointsDontRequireToken.filter((endpoints) => url.indexOf(endpoints) !== -1).length;

					if (!dontRequiresToken) {
						request = request.clone({
							setHeaders: {
								"x-access-token": `${this.token}`,
								...RET3CustomHeaders
							},
							params: requestIsFromDeliveryInfo ? this.getDeliveryInfoParams(request) : request.params,
							...(requestIsFromAnonymous && anonymousBody)
						});
					}
					TaggingService.getData(request.url);
					return next.handle(request);
				}),
				catchError((error, caught) => {
					this.handleAuthError(error, url);
					return of(error);
				}) as any,
				map((event: HttpEvent<any>) => {
					if (event instanceof HttpResponse && event.url.includes(ENDPOINTS.COVERAGE_LOCATION)) {
						if (this.retCartService.isRADProcess) {
							event.body.notCoveraged = this.isCovered(event);
						}
					}
					return event;
				})
			);
		}

		return next.handle(request);
	}

	getRET3CustomHeaders(
		url: string,
		drugstoreCode: string | number,
		drugstoreStock: string | number,
		drugstoreRetStock: string | number
	) {
		const RET3CustomHeaders = {};
		if (APIGEE_INTERCEPTOR_PATHS.warehouse.test(url)) {
			RET3CustomHeaders["drugstore-stock"] = String(drugstoreStock);
			drugstoreCode ? (RET3CustomHeaders["drugstore-code"] = String(drugstoreCode)) : null;

			if (OrderTakerValidator.isOrderTakerEnv()) {
				if (drugstoreRetStock !== "true" && drugstoreRetStock) {
					RET3CustomHeaders["drugstoreRet-stock"] = String(drugstoreRetStock);
				}
			} else {
				RET3CustomHeaders["drugstoreRet-stock"] = String(drugstoreRetStock);
			}
		}

		if (APIGEE_INTERCEPTOR_PATHS.filtered.test(url)) {
			RET3CustomHeaders["drugstore-stock"] = String(drugstoreStock);

			if (OrderTakerValidator.isOrderTakerEnv()) {
				if (drugstoreRetStock !== "true" && drugstoreRetStock) {
					RET3CustomHeaders["drugstoreRet-stock"] = String(drugstoreRetStock);
				}
			} else {
				if (drugstoreRetStock !== "true") {
					RET3CustomHeaders["drugstoreRet-stock"] = String(drugstoreRetStock);
				}
			}
		}

		return RET3CustomHeaders;
	}

	isCovered(coverageResponse: any) {
		const location = coverageResponse.body?.favorite ? coverageResponse.body?.favorite : coverageResponse.body?.current;
		const inCoverage = location.enabled && location.inCoverage;
		const notCoords = location.latitude === 0 && location.longitude === 0;
		if (notCoords) {
			return true;
		} else {
			return inCoverage;
		}
	}

	isCurrentCovered(coverageResponse: any) {
		return !(
			coverageResponse.body.current?.latitude !== 0 &&
			coverageResponse.body.current?.longitude !== 0 &&
			coverageResponse.body.current?.enabled &&
			coverageResponse.body.current?.inCoverage
		);
	}

	getDeliveryInfoParams(request) {
		const { drugstoreSelected } = this._appStoreService;
		const { isRADProcess } = this.retCartService;
		const drugstoreParsed = JSON.parse(drugstoreSelected);
		const localCode = drugstoreParsed?.localCode;
		return isRADProcess
			? request.params
			: localCode
			? request.params.set("deliveryTypeId", String("RET")).set("drugstoreId", String(localCode)).set("isOldVersion", "N")
			: request.params;
	}

	private newApigeeClone(request: HttpRequest<any>, RET3CustomHeaders: any, accessToken?: string): HttpRequest<any> {
		const originalBody = request.body;
		const superDiscount = request.url.includes(ENDPOINTS.SUPERDISCOUNT);
		if (
			request.url.includes(ENDPOINTS.NEAREST_SELECTED_DRUGSTORES) ||
			request.url.includes(ENDPOINTS.NEAREST_SELECTED_DRUGSTORES_TOMADOR) ||
			request.url.includes(ENDPOINTS.NEAREST_DRUGSTORES_TOMADOR)
		) {
			if (OrderTakerValidator.isOrderTakerEnv()) {
				const currentUser = this._userTokenStoreService.currentUser;
				const clientIdTomador = this._appStoreService.getStorageItem("clientIdTomador");
				const userCallCenterId = clientIdTomador ? clientIdTomador : currentUser?.id;
				originalBody.userId = userCallCenterId;
			} else originalBody.userId = this._authImplementService.currentUID;
		}

		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${
					accessToken
						? accessToken
						: superDiscount
						? this.apigeeStoreService.superDiscounToken?.access_token
						: this.apigeeStoreService.currentToken?.access_token
				}`,
				...RET3CustomHeaders
			},
			body: originalBody
		});
	}

	private handleAuthError(err: HttpErrorResponse, url: string): Observable<any> {
		if (url === ENDPOINTS.USER_SERVICE || url === ENDPOINTS.UPDATE_USER_LEGACY) {
			/*
      Si por algun error se cae getuser o updateuserlegacy en el flujo de login responsive
      la web no permita el acceso por la validacion agregada en el auth-guard
      se retirará el redirect status del local storage
      */
			this._appStoreService.removeRedirectLoginStatus();
		}

		switch (err.status) {
			case HTTP_ERROR_RESPONSE_STATUS.INTENAL_ERROR:
				this.handleInternalError(err, url);
				break;
			case HTTP_ERROR_RESPONSE_STATUS.UPDATE_USER_INFO:
				const currentUser = this.newRegisterStore.firstUserLoggedIn
					? this.newRegisterStore.firstUserLoggedIn.user
					: this._authImplementService.currentUser;
				if (currentUser.isAnonymous) {
					const anonymousObservable = from(this._authImplementService.anonymousLogin());
					return anonymousObservable.pipe(
						switchMap((authResponse: firebase.auth.UserCredential) => {
							this._authImplementService.userAuthState = authResponse.user;
							this.handleUpdateUserInfoError(url);
							return this.authGuard.getValidatedUserResponse(authResponse.user);
						})
					);
				}
				break;
			default:
				break;
		}
		throw err;
	}

	handleUpdateUserInfoError(url) {
		/* Cuando isGuardEndpoint = true --> endpoint invocado desde un guard
       se maneja esta validacion ya que sino genera bucle por la implementacion del guard al redirijir
    */
		const navigationUrl = this._appStoreService.getStorageItem(NAVIGATION_URL);
		if (
			navigationUrl !== "/" + BUSINESS_PATHS.REGISTER &&
			navigationUrl !== "/" + BUSINESS_PATHS.PRE_REGISTER &&
			!this.isGuardEndpoint
		) {
			this.registrationValidationService.updateUserInfoByIncompleteInfoError();
		}
		this.isGuardEndpoint = false;
	}

	handleInternalError(err, url) {
		const navigationUrl = this._appStoreService.getStorageItem(NAVIGATION_URL);
		const endpoint = OrderTakerValidator.isOrderTakerEnv()
			? EBrandCode[environment.brand] === EBrand.inkafarma
				? ENDPOINTS.POST_CART
				: ENDPOINTS.POST_CART_V2
			: ENDPOINTS.POST_CART;

		if (url === endpoint && navigationUrl !== "/" + BUSINESS_PATHS.CHECKOUT) {
			this.shoppingListStore.setLoadCardEndpoint = true; // validar peticiones cart

			if (OrderTakerValidator.isOrderTakerEnv()) return;
			this.shoppingListStore.removeShoppingList();

			// const errorResponse = new ShoppingCartResponse(err.error);
			// if (errorResponse.code === CART_CODE.ERROR_GET_PRODUCT) {
			//   this.shoppingListStore.removeShoppingList();
			// }
		}
	}
}
