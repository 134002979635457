
<!-- linkRouter &&  -->
<ng-container *ngIf="!linkHref">
  <a class="link"
    #ref01
     [ngClass]="linkClass"
     [routerLink]="linkRouter"
     [routerLinkActive]="linkRouterActive"
     [routerLinkActiveOptions]="linkRouterActiveOptions"
     [target]="linkTarget"
     (click)="activeLinkRoute()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>
<!-- && !linkRouter -->
<ng-container *ngIf="linkHref">
  <a class="link"
    #ref02
     [ngClass]="linkClass"
     [href]="linkHref"
     [target]="linkTarget"
     (click)="activeLinkRoute()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
