import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppStoreService } from "@stores/app/app-store.service";
import { CheckoutAppAgoraPayStoreService } from "../../checkout-stores/checkout-app-agora-pay-store.service";
import { ProcessingPaymentModalManagerService } from "../processing-payment-modal-manager.service";
import { PurchaseNumberImplementService } from "../purchase-number-implement.service";
import { SignatureTransactionImplementService } from "../signature-transaction-implement.service";
import { TransactionPaymentAppAgoramentInfo } from "@models/order-app-agora-pay/order-app-agora-pay";
import { HttpParams } from "@angular/common/http";
import { finalize, debounceTime, distinctUntilChanged, tap, catchError, switchMap } from "rxjs/operators";
import { ISignatureTransaction } from "@models/authentication-app-agora-pay/authentication-app-agora-pay";
import { environment } from "@environments/environment";
import { IRequestPayOnlineMapi } from "@models/pay-online/request-pay-online-mapi.model";
import { getFormattedDateISO8601 } from "@helpers/date-fns-functions.helper";
import { APP_AGORA_PAYMENT_CARD_ID } from "../../parameters/constants";
import { IReqPurchaseNumberUpdatePagoWeb } from "@models/purchase-number/req-purchase-number-update-pago-web.model";
import { BehaviorSubject, Observable, Subject, of, throwError } from "rxjs";
import { PAYMENT_METHOD_CODE } from "@parameters/checkout/payment-method.parameters";
import { TERMS_AND_CONDITION_EPAGO } from "@parameters/enums";

@Injectable({
	providedIn: "root"
})
export class ModalAgoraPayService {

	private responseSuccess: BehaviorSubject<string> = new BehaviorSubject<string>("CREATED");
	public responseSuccess$: Observable<string> = this.responseSuccess.asObservable();

	public onDestroySubs = new Subject<boolean>();

	private bodyTransaction: TransactionPaymentAppAgoramentInfo = null;
	generateTransaction = false;
	constructor(
		public signatureTransactionImplementService: SignatureTransactionImplementService,
		private purchaseNumberService: PurchaseNumberImplementService,
		public checkoutAppAgoraPayStoreService: CheckoutAppAgoraPayStoreService,
		private appStore: AppStoreService
	) {}

	private get getElement(): HTMLElement {
		const el = document.createElement("div");
		el.setAttribute("id", "cart-container");
		el.setAttribute("class", "epago-container");
		el.setAttribute("class", "epago-agora-container");
		return el;
	}
	private get form(){
		return JSON.parse(this.appStore.getStorageItem("CPMS"))
	}
	private get paymentGateway(): number{
		// paymentGateway es 3 para AgoraPay y 4 para Online cuando es EPAGO
		return this.form.paymentMethod ===
		PAYMENT_METHOD_CODE.AGORA_PAY ? PAYMENT_METHOD_CODE.ONLINE : PAYMENT_METHOD_CODE.AGORA_PAY;
	  }
	public openAgoraModal(body: TransactionPaymentAppAgoramentInfo,merchantId, ciaCode) {
		this.responseSuccess.next("CREATED");
		if (body) {
			this.generateTransaction = true;
			const _this = this;

			this.bodyTransaction = body;
			//Generar elemento para el modal
			const element = this.getElement;
			document.body.appendChild(element);
      document.body.style.overflow = "hidden";
			// Seteo de los datos de transacción
			const transaction = { ...this.bodyTransaction.transaction };
			// Seteo de los datos de pago
			const payment = { ...this.bodyTransaction.payment };

			const params = new HttpParams()

			.append("companyCode", merchantId)
			.append("ciaCode", ciaCode)
        	.append("paymentGateway", this.paymentGateway);

			this.signatureTransactionImplementService
				.getSignatureTransaction(transaction, params)
				.pipe(
					catchError((error) => {
						this.responseSuccess.next("ERROR");
						// this.processingPaymentModal.closeModal()
						return throwError(error);
					}),
					debounceTime(400),
					distinctUntilChanged()
				)
				.subscribe((signatureInfo: ISignatureTransaction) => {
					try {
						let statusFilter = 0;
						let status = "REJECT";
						let actionDescription = "";
						// Manejo de los campos de respuesta
						const form = new (window as any).Epago.Form({
							// Seteo del ambiente "quality" o "production"
							environment: environment.production ? "production" : "quality",
							// Seteo del modo sin formulario "NOFORM"
							mode: "NOFORM",
							// Seteo de autenticación de seguridad y transacción
							authentication: signatureInfo,
							transaction: transaction,

							// Manejo de la respuesta
							listeners: {
								afterPay: function (response) {
									console.info({ ecommerceCapureResponse: response });
									if (response.success) {
										actionDescription = response.data.result.message;
										if (response.data.result.accepted) {
											statusFilter = 1;
											status = "AUTHORIZED";
											_this.checkoutAppAgoraPayStoreService.setRequestPayOnlineMapi(
												_this.getRequestPayOnlineMapiParams(
													response?.data?.result.processorResult.referenceNumber || "",
													response?.data?.result.processingTimestamp || new Date().toISOString(),
													response?.data?.payment.card.bin || "",
													response?.data?.payment.card.lastPan || "",
													response?.data?.result.processorResult.operationNumber || "",
													response?.data?.result.processorResult.idPagador || ""
												)
											);
										} else {
											status = "DENIED";
											console.info(status);
										}
									} else {
										actionDescription = _this.deleteSpecialCharacters(
											response.data?.message || response.message?.text || ""
										);
										if (response.message?.code === "ERROR.PAYMENT.CHECK-RESPONSE-CANCELED") {
											status = "ERROR";
										} else {
											status = "REJECT";
										}
									}
									_this.updatePurchaseNumberStatus(
										status,
										transaction.order.number,
										statusFilter,
										status,
										actionDescription,
                    merchantId,
                    ciaCode
									);
								}
							}
						});
						// Procesa el pago a través de la librería de pago
						form.perform_pay_app_noform("#cart-container", payment);
					} catch (e) {
						console.error(e);
						_this.responseSuccess.next("ERROR");
					}
				});
		}
	}

	public closeAgoraModal(): void {
		// Busca y elimina el modal layer y el contenedor de autenticación
		const modalSelectors = [
			'div[epago-identifier="modal-layer"]',
			'div[epago-identifier="payment-authentication-main-container"]',
			"#cart-container"
		];

		modalSelectors.forEach((selector) => {
			window.document.querySelectorAll(selector).forEach((element) => element.remove());
		});
    document.body.removeAttribute("style");
		this.generateTransaction = false;
		this.responseSuccess.next("CLOSED");
	}

	getRequestPayOnlineMapiParams(
		referenceNumber: string,
		processingTimestamp: string,
		bin: string,
		lastPan: string,
		operationNumber: string,
		idPagador: string
	): IRequestPayOnlineMapi {
		return {
			shoppingCartId: "",
			idTransactionVisaNet: referenceNumber,
			transactionDateVisaNet: getFormattedDateISO8601(processingTimestamp),
			numPanVisaNet: bin + "******" + lastPan,
			authorizationCodeVisaNet: operationNumber,
			tokenTransactionVisaNet: idPagador
		} as IRequestPayOnlineMapi;
	}

	private updatePurchaseNumberStatus(
		responseSuccess: string,
		purchaseNumber: string,
		statusFilter: number,
		status: string,
		actionDescription: string,
    merchantId,
    ciaCode
	) {
		const params = {
			purchaseNumber: parseInt(purchaseNumber, 10),
			statusFilter: statusFilter,
			status: status,
			actionDescription: actionDescription,
			cardBrandId: APP_AGORA_PAYMENT_CARD_ID,
			cardBrandDescription: this.checkoutAppAgoraPayStoreService?.agoraCreditCard?.descriptionShort,
			cardIssuer: "",
      merchantId,
      ciaCode,
      termsAndCondition: TERMS_AND_CONDITION_EPAGO,
		} as IReqPurchaseNumberUpdatePagoWeb;

		this.purchaseNumberService.updatePurchaseNumberStatus(params).subscribe(
			() => {},
			(error) => {
				responseSuccess = "ERROR";
				console.error("Error al actualizar el estado del purchaseNumber", error);
			}
		);
		this.responseSuccess.next(responseSuccess);
	}
	private deleteSpecialCharacters(message: string) {
		return message.replace(/[^\w\s]/gi, "");
	}
}
