import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Entry } from 'contentful';
import { ContentfulEntry } from '../../../shared/models/contentful/contentful.model';
import { ContentfulProduct, IContentfulProduct } from './contentful-product.model';
import { ProductCard } from '../../modules/product-card/models/product-card/product-card.model';
import { IContentfulBanner } from './contentful-banner.model';
import { IContentfulSlider } from './contentful-slider.model';
import { StoreSubCategory } from '../../modules/header/models/category-list-store.model';
import { ContentfulShortcutList } from './contentful-shortcut-list.model';

export interface IContentfulProductList {
  nombre: string;
  template: EContentfulProductListTemplate;
  banner: Entry<IContentfulSlider | IContentfulBanner>;
  bannerHome: Entry<IContentfulBanner | IContentfulSlider>;
  list_products: Array<Entry<IContentfulProduct>>;
  skus: Array<string>;
  slug: string;
  itemsPerView: number;
  totalRecords?: number;
  rows?: number;
  pages?: number;
  canonicalUrl: string;
  metaDescription: string;
  metaKeywords: string;
  metaTitle: string;
  structuredData: string;
  headingLevel?: string;
  background?: string;
  style?: string;
  dataEstructuradaLista?: Entry<ContentfulShortcutList>
}

export enum EContentfulProductListTemplate {
  template1 = 'Plantilla 1',
  template2 = 'Plantilla 2',
  template3 = 'Plantilla 3',
  templateDefault = 'Plantilla 1'
}

export class ContentfulProductList {
  title: string;
  template: EContentfulProductListTemplate;
  banner: ContentfulEntry<IContentfulSlider | IContentfulBanner>;
  bannerHome: ContentfulEntry<IContentfulSlider | IContentfulBanner>;
  productIdList: Array<string>;
  productList: Array<ProductCard>;
  itemsPerView: number;
  slug: string;
  totalRecords?: number;
  rows?: number;
  pages?: number;
  canonicalUrl: string;
  metaDescription: string;
  metaKeywords: string;
  metaTitle: string;
  structuredData: string;
  headingLevel: string;
  background?: string;
  style?: string;
  dataEstructuradaLista: ContentfulEntry<ContentfulShortcutList>;

  constructor(iContentfulProductList: IContentfulProductList) {
    this.title = iContentfulProductList.nombre;
    this.template = iContentfulProductList.template || EContentfulProductListTemplate.templateDefault;
    this.slug = iContentfulProductList.slug;
    this.itemsPerView = iContentfulProductList.itemsPerView;
    this.totalRecords = iContentfulProductList.skus.length;
    this.rows = 5;
    this.pages = Math.ceil(this.totalRecords / this.rows);
    this.productList = [];
    this.canonicalUrl = iContentfulProductList.canonicalUrl;
    this.metaDescription = iContentfulProductList.metaDescription;
    this.metaKeywords = iContentfulProductList.metaKeywords;
    this.metaTitle = iContentfulProductList.metaTitle;
    this.structuredData = iContentfulProductList.structuredData;
    this.headingLevel = iContentfulProductList.headingLevel || "h1";
    this.background = iContentfulProductList.background;
    this.style = iContentfulProductList.style || "";

    if (iContentfulProductList.banner) {
      this.banner = new ContentfulEntry(iContentfulProductList.banner);
    }
    if (iContentfulProductList.bannerHome) {
      this.bannerHome = new ContentfulEntry(iContentfulProductList.bannerHome);
    }
    if (iContentfulProductList.skus && iContentfulProductList.skus.length) {
      this.productIdList = iContentfulProductList.skus;
    } else {
      this.productIdList = iContentfulProductList.list_products ? iContentfulProductList.list_products
        .map(product => new ContentfulProduct(new ContentfulEntry(product).content).id) : [];
    }

    if (iContentfulProductList.template) { this.template = iContentfulProductList.template; }
    if (iContentfulProductList.metaDescription) { this.metaDescription = iContentfulProductList.metaDescription; }
    if (iContentfulProductList.metaKeywords) { this.metaKeywords = iContentfulProductList.metaKeywords; }
    if (iContentfulProductList.structuredData) { this.structuredData = iContentfulProductList.structuredData; }
    if (iContentfulProductList.metaTitle) { this.metaTitle = iContentfulProductList.metaTitle; }
    if (iContentfulProductList.dataEstructuradaLista) { this.dataEstructuradaLista = new ContentfulEntry(iContentfulProductList.dataEstructuradaLista) }
  }
}

export const CContentfulProductList = {
  id: CONTENTFUL_PATH.contentTypeIds.productList,
  sliderConfig: {
    'type': 5,
    'loop': false,
    'autoplay': false,
    'autoplayTime': 5000,
    'infinite': false,
    'autoHeight': true,
    'slidesConfigByBreakpoints': {
      'xxs': {
        slidesPerView: 1.26,
        spaceBetween: 16
      },
      'sm': {
        slidesPerView: 1.8,
        spaceBetween: 16
      },
      'md': {
        slidesPerView: 2.4,
        spaceBetween: 16
      },
      'lg': {
        slidesPerView: 3,
        spaceBetween: 14
      },
      'xl': {
        slidesPerView: 4,
        spaceBetween: 14
      },
      'xxl': {
        slidesPerView: 5,
        spaceBetween: 14
      },
    },
    'slidesConfigByBreakpointsBanner': {
      'xxs': {
        slidesPerView: 1.26,
        spaceBetween: 16
      },
      'sm': {
        slidesPerView: 1.8,
        spaceBetween: 16
      },
      'md': {
        slidesPerView: 2.4,
        spaceBetween: 16
      },
      'lg': {
        slidesPerView: 2,
        spaceBetween: 14
      },
      'xl': {
        slidesPerView: 3,
        spaceBetween: 14
      },
      'xxl': {
        slidesPerView: 3,
        spaceBetween: 14
      },
    }
  }
};
