<div class="main-register-modal">
    <fp-section-container-title-login [title]="'¡Te damos la bienvenida!'" [iconName]="'close'" (onclick)="closeModal()">
    </fp-section-container-title-login>
    <fp-sub-section-container>
      <div class="content modal-login mt-4 mt-4-3">
        <div class="container-icon">
          <img [src]="iconInformation">
        </div>
        <div class="message-puntos">
          <p class="font-VAGRoundedNexxt-normal-w-600"><b>{{userName}},</b> has acumulado <b>{{userPoints}} puntos - dinero</b> con tu Monedero del Ahorro. Para gozar de más beneficios regístrate o inicia sesión</p>
        </div>
        <div class="mb-3">
          <fp-button-brand brandId="btn--register-facebook"
                           brandIcon="facebook"
                           brandWidth="full"
                           (onclick)="facebookLogin()">
            Regístrate con Facebook
          </fp-button-brand>
        </div>
        <div class="mb-2">
          <fp-button-brand brandId="btn--register-google"
                           brandIcon="google"
                           brandWidth="full"
                           (onclick)="googleLogin()">
            Regístrate con Google
          </fp-button-brand>
        </div>
        <div class="mb-3">
          <fp-button-brand brandId="btn--register-apple"
                           brandIcon="apple"
                           brandWidth="full"
                           (onclick)="appleLogin()">
            Regístrate con Apple
          </fp-button-brand>
        </div>
      </div>
    </fp-sub-section-container>
    <div class="mb-3" [ngClass]="{
      'show': showEmailAndPassword,
      'showWithError': false
    }">
      <fp-sub-section-container [flagBorderTop]="false">
        <div class="content" [formGroup]="registerForm.form">
          <div class="mb-4 text-center text-black font-VAGRoundedNexxt">ó Crea una cuenta con tu correo</div>
          <div class="mb-3 mb-3-2">
            <fp-input-without-title-control [errorMessage]="registerForm.emailControl.emailAddressError"
              [marginWithError]="false">
              <input formControlName="email" autocomplete="off" class="w-100 input" placeholder="Correo electrónico">
            </fp-input-without-title-control>
          </div>
          <div class="mb-2 mb-3-4">
            <fp-button-primary buttonId="btnRegisterEmail" buttonDisplay="block"
              [buttonDisabled]="!registerForm.form.valid" (click)="openEmailWithPassword()">
              Registrarme
            </fp-button-primary>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <div class="label--1 mr-3 text-size-1em text-black font-VAGRoundedNexxt-size-14">Si ya tienes una cuenta</div>
            <fp-link-buttom [buttonText]="'Inicia sesión'" [labelClass]="'label-black--1 link-size'" (click)="closeModal()">
            </fp-link-buttom>
          </div>
        </div>
      </fp-sub-section-container>
    </div>
  </div>
