import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'fp-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.sass']
})
export class SwitchComponent implements OnInit {

  @Input() checkedValue;
  @Input() switchDisabled: boolean;
  @Output() selectedItem = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  chooseItem() {
    this.selectedItem.emit();
  }

}
