import { Directive, Input, ElementRef, HostListener } from '@angular/core';

import { NAVIGATION_KEYBOARD_KEYS, KEYS_FOR_COMBO, NUMBERS, DOT } from '../parameters/keyboard.constant';

@Directive({
  selector: '[fpAmount]'
})
export class AmountDirective {

  private dotCounter = 0;
  private decimalCounter = 0;
  @Input() maxNumberOfDecimals ? = 2;
  @Input() amountMaxLength = 7;
  inputElement: HTMLInputElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const key = event.key;
    const ctrlKey = event.ctrlKey;
    const metaKey = event.metaKey;

    const isNavigationKey = NAVIGATION_KEYBOARD_KEYS.includes(key);
    const isKeyboardCombo = KEYS_FOR_COMBO.includes(key) && (ctrlKey === true || metaKey === true);
    const navigationOrComboKey = [ isNavigationKey, isKeyboardCombo ];
    const isNavigationKeyOrCombo = navigationOrComboKey.some(condition => condition);

    const isNumber = [ ...NUMBERS ].includes(key);
    const isOnlyOneDot = key === '.' && this.dotCounter < 1;

    const validationConditions = [ isNavigationKeyOrCombo, isNumber && this.decimalCounter < 2 , isOnlyOneDot ];
    const existOneValidCondition = validationConditions.some(condition => condition);
    const isKeyboardSpace = key === ' ';

    const valueLength = this.inputElement.value.length;
    const isValidTheNextAction = valueLength >= this.amountMaxLength && !isNavigationKeyOrCombo;
    if (!existOneValidCondition || isKeyboardSpace || isValidTheNextAction) {
      event.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    const value = this.el.nativeElement.value as string;
    const valueArr = value.split('.');
    this.dotCounter = valueArr.length - 1;
    const hasDecimalPart = valueArr.length === 2;
    this.decimalCounter = hasDecimalPart ? valueArr[1].length : 0;
  }

}
