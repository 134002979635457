import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "fp-card-radio-office",
  templateUrl: "./card-radio-office.component.html",
  styleUrls: ["./card-radio-office.component.sass"],
})
export class CardRadioOfficeComponent implements OnInit {
  @Input() title = "";
  @Input() description = "";
  @Output() selectRadioOption = new EventEmitter();
  @Input() selectedRadio = false;
  @Input() isDisabled = false;

  constructor() {}

  ngOnInit(): void {}
  public selectOption() {
    if (!this.selectedRadio) {
      this.selectRadioOption.emit();
    }
  }
  public triggerCustomEvent($event: MouseEvent) {
    $event.stopPropagation();
  }
}
