<ng-container *ngFor="let product of productSeller">
  <div class="col-10 stl-title-seller d-flex align-items-center justify-content-start py-3">
    <h3 class="m-0 heading-1">{{ product.sellerName }} ({{ product.products.length }})
    </h3>
    <br>
  </div>
  <ng-container *ngFor="let prod of product.products">
    <hr class="m-0">
    <div class="content product-mini-cart" [attr.data-product-brand]="prod?.brand"
      [attr.data-product-category]="prod.categoryList?.[0]?.keyword || ''">
      <div class="row">
        <div class="col-8">
          <fp-link [linkRouter]="['/' + productPath, prod.slug, prod.id]" linkId="link--go-to-product-detail">
            <div class="row">
              <div class="col">
                <fp-product-image class="w-100" imageSize="45px" [image]="prod.imageList[0].url" [imageName]="prod.name"
                  [productStatus]="productStatus" tagSize="small">
                </fp-product-image>
              </div>
              <div class="col-9 d-flex flex-column">
                <fp-product-information [name]="prod.name" [description]="prod.noFractionatedText"
                  [productStatus]="productStatus">
                </fp-product-information>
                <p class="mt-2 mb-0 text-tag text-black">
                  CANTIDAD: {{prod.quantitySelected}}
                </p>
              </div>
            </div>
          </fp-link>
        </div>

        <div class="col-4">
          <div class="row">
            <div class="col-12">
              <fp-product-cart-price [subTotal]="prod.subTotalAllPaymentMethod"
                [subTotalOffer]="prod.subTotalWithPaymentMethod" [productStatus]="productStatus"
                [regularPrice]="prod.subTotal">
              </fp-product-cart-price>
            </div>
            <div class="col-12 mt-3 text-right">
              <fp-button-ghost id="btn--delete-product" buttonSize="small" buttonColor="red" optionalClass="p-0 m-0"
                (click)="deleteProduct(prod.id)">
                Eliminar
              </fp-button-ghost>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>