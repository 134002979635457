<div class="new-password-modal">
  <fp-section-container-title-login [title]="isInkaBrand ? 'Crea una cuenta' : 'Regístrate'" [iconName]="'close'" (onclick)="closeModal()">
  </fp-section-container-title-login>
  <fp-sub-section-container>
    <div class="content">
        <div class="mt-4" [formGroup]="newPasswordForm.form">
          <div class="input-email">
            <fp-input-control
              [marginWithError]="false">
              <input type="text" id="" class="input btn-disabled" maxlength="50" formControlName="emailAddress" readonly>
            </fp-input-control>
          </div>
          <div class="password-new mt--4">
            <fp-input-password
                [isVerify]="isSame">
              <input class="w-100 input" #typeControl placeholder="Contraseña" formControlName="newPassword" (focus)="showCheckList()" (blur)="hidewCheckList()">
            </fp-input-password>
          </div>
          <div class="checkList" *ngIf="checkList">
            <fp-check-list-password
              [verifyCharacter]="isValid8Digit"
              [verifyCapital]="isValidCapitalLetter"
              [verifyLower]="isValidLowerCase"
              [verifyNumber]="isValidNumber"
                >
            </fp-check-list-password>
          </div>
          <div class="password-confirm">
            <fp-input-password
              [isVerify]="isSame">
              <input class="w-100 input" #typeControl placeholder="confirma tu contraseña" formControlName="confirmPassword">
            </fp-input-password>
          </div>

        <div class="mt-5 mb-3">
          <fp-button-primary (click)="openEmailVerification()" id="" buttonDisplay="block"
            [buttonDisabled]="!isAllVerified">
            {{ isInkaBrand ? 'Crear cuenta' : 'Siguiente'}}
          </fp-button-primary>
        </div>
        <div class="text-center d-flex justify-content-center align-items-center mt-3 py-4" *ngIf="isInkaBrand">
          <a class="icon-chevron-left" (click)="closeModal()">
            <span class="icon icon-24-chevron-left-blue"></span>
          </a>
          <fp-link-buttom
            (click)="closeModal()"
            [buttonText]="'Volver a las opciones de registro'">
          </fp-link-buttom>
        </div>
      </div>
    </div>


  </fp-sub-section-container>
</div>