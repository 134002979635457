import { Component, OnInit, NgZone, OnDestroy, Inject } from '@angular/core';
import { PickUpConfigResponse } from '../../models/pick-up-config.model';
import { of, Subscription } from 'rxjs';
import { PickUpConfigStoreService } from '../../pick-up-stores/pick-up-config-store.service';
import { UserGeolocationHelperService } from '../../pick-up-helpers/user-geolocation-helper.service';
import { DomDocumentHelperService } from '../../pick-up-helpers/dom-document-helper.service';
import { delay, switchMap, take, tap } from 'rxjs/operators';
import { StoresForPickUpStoreService } from '../../pick-up-stores/stores-for-pick-up-store.service';
import { FirstLoadHelperService } from '../../pick-up-helpers/first-load-helper.service';
import { IModalStatus } from '../../enums/modal-status.enum';
import { NearestDrugstore } from 'src/app/shared/models/nearest-drugstores/nearest-drugstores.model';
import { IStoreToChoose } from '../../interfaces/choose-a-store.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShoppingListStoreService } from 'src/app/shared/stores/shopping-list/shopping-list-store.service';
import { AnalyticServiceService } from 'src/app/shared/service/analitycService/analitycService.service';
import { EBrand, EBrandName } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';
import { SnackbarAlertService } from '../../../snackbar/snackbar-alert.service';
import { ProductSalesforceService } from '../../../product-card/service/product-salesforce.service';
import { AppStoreService } from '@stores/app/app-store.service';
import { ProductImplementService } from '../../../product-card/service/product-implement.service';
import { ProductShoppingCartRequest } from '@models/shopping-cart/product-shopping-cart-request.model';
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { Router } from '@angular/router';
import { ShoppingCartRequestService } from 'src/app/shared/service/shopping-cart/shopping-cart-request.service';
import { CoachmarkService } from 'src/app/shared/service/coachmark/coachmark.service';
import { DeliveryInfoImplementService } from 'src/app/business/checkout/checkout-services/delivery-info-implement.service';
import { LS_ADDRESS_SELECTED } from 'src/app/shared/service/local-storage/constants/address.constant';

@Component({
  selector: 'fp-nearby-botica-responsive-modal',
  templateUrl: './nearby-botica-responsive-modal.component.html',
  styleUrls: ['./nearby-botica-responsive-modal.component.sass']
})
export class NearbyBoticaResponsiveModalComponent implements OnInit, OnDestroy {

  public brand = EBrandName[environment.brand];
  public title = 'Boticas disponibles para retiro';
  public isDisableChooseStore = false;
  public isSlide = false;
  public isLoadingRespModal = true;
  public config: PickUpConfigResponse = {} as PickUpConfigResponse;
  public modalStatus: IModalStatus = IModalStatus.LOADING;
  public ALL_MODAL_STATUS = IModalStatus;
  public drugstores: NearestDrugstore[] = [];
  private subscriptions: Subscription[] = [];
  public temporalSelectedDrugstore = {} as IStoreToChoose;
  public shoppingCartListLocal: any;
  public productInka = [];
  public productSeller = [];
  private productInShoppingCartSubscription: Subscription;
  public productQuantity = 1;
  public productPresentationId: number;
  public productInShoppingCart: boolean;
  public selectedDrugStore: NearestDrugstore;
  public timeout: number;
  public isHome = false;
  public isDetail = false;
  product: any;
  public change: boolean;
  public modalResponsive: boolean;

  constructor(
    public pickUpConfig: PickUpConfigStoreService,
    private userGeolocationHelper: UserGeolocationHelperService,
    private ngZone: NgZone,
    private domDocumentHelper: DomDocumentHelperService,
    private storesForPickUpStore: StoresForPickUpStoreService,
    private firstLoadHelper: FirstLoadHelperService,
    private nearbyBoticaModalRef: MatDialogRef<NearbyBoticaResponsiveModalComponent>,
    private _shoppingListStore: ShoppingListStoreService,
    private serviceAnalytic: AnalyticServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbarAlert: SnackbarAlertService,
    private _productSalesforce: ProductSalesforceService,
    private _appStoreService: AppStoreService,
    private retValidateCartService: RetValidateCartService,
    private _productImplement: ProductImplementService,
    private router: Router,
    private shoppingListRequest: ShoppingCartRequestService,
    private coachMarkService: CoachmarkService,
    private deliveryInfoImplement: DeliveryInfoImplementService
  ) {
    const drugstoresSub = this.storesForPickUpStore.drugstores$.subscribe((drugstores) => this.drugstores = drugstores);
    const temporalSelectedDrugstoreSub = this.storesForPickUpStore.selectedTemporalDrugstore$.subscribe((temporalSelectedDrugstore) => {
      this.temporalSelectedDrugstore = this.storesForPickUpStore.getFormattedNearestDrugstore(temporalSelectedDrugstore);
    });
    this.subscriptions.push(drugstoresSub, temporalSelectedDrugstoreSub);
  }

  ngOnInit() {    
    this.coachMarkService.activeProductCoachmark.next(false);
    this.product = this.data.product;
    this.data?.change ? this.change = true : this.change = false;
    this.data?.modalResponsive ? this.modalResponsive = true : this.modalResponsive = false;
    const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList();

    this.shoppingCartListLocal = shoppingCartListLocal?.length ? shoppingCartListLocal?.length : 0;

    const onlyProductInka = this._shoppingListStore.getLocalStorageShoppingCartList() || [];

    onlyProductInka.filter(v => v.sellerName === '' ? this.productInka.push(v) : []);
    onlyProductInka.filter(v => v.sellerName !== '' ? this.productSeller.push(v) : []);

    this.userGeolocationHelper.validateAndLoadGeolocationAddress$().pipe(take(1)).subscribe();
    const statusModelSub = this.firstLoadHelper.statusModal$.subscribe(state => {
      this.modalStatus = state
    });
    const configSub = this.pickUpConfig.config$.subscribe(config => this.config = config);
    this.getProductShoppingCart();
    this.router.url === '/' ? this.isHome = true : this.isHome = false;
    this.router.url.startsWith('/producto') ? this.isDetail = true : this.isDetail = false;

    this.userGeolocationHelper
    .isPendingToAskGeolocation$()
    .pipe(delay(200))
    .pipe(take(1))
    .subscribe((isPending) => {
      this.userGeolocationHelper
      .getGeolocationPermissions$()
      .subscribe((permission: any) => {
        if (permission.status === "ok") {
          this.firstLoadHelper.getInitialDrugstores$(this.product, this.data.change, this.data?.prd)
          .pipe(switchMap(response => {
            this.modalStatus = response.modalStatus;
            const validationsWithAddress = [IModalStatus.OK, IModalStatus.EMPTY];
            if (validationsWithAddress.includes(response.modalStatus)) {
              this.isLoadingRespModal = false;
              return of(response);
            }
            const validationWithGeolocation = [IModalStatus.IS_PENDING, IModalStatus.LOADING];
            if (validationWithGeolocation.includes(response.modalStatus)) {
              this.isLoadingRespModal = false;
              return this.firstLoadHelper.getDrugstoresOfGeolocationFlow$(this.data?.prd);
            }
            return of(response);
          }))
          .pipe(take(1))
          .subscribe(response => {
            this.ngZone.run(() => {
              const { drugstores, temporalSelectedDrugstore } = response;
              this.modalStatus = response.modalStatus;
              this.storesForPickUpStore.setDrugstores(drugstores);
              this.storesForPickUpStore.setSelectedTemporalDrugstore(temporalSelectedDrugstore);
              this.isLoadingRespModal = false;
            });
          });
        } else {
          this.modalStatus = IModalStatus.NO_ONE;
        }
      });
    });
  }

  ngOnDestroy() {
    this.shoppingListRequest.setSelectedProduct = null;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  get needAddProduct() {
    return this.retValidateCartService.addIntentIsFromProduct
  }

  public backButtonEvent() {
    this.nearbyBoticaModalRef.close();
    this.domDocumentHelper.addScroll();
  }

  public closeButtonEvent() {
    this.nearbyBoticaModalRef.close();
    this.domDocumentHelper.addScroll();
    this.serviceAnalytic.registerEventAnalytics(this.serviceAnalytic.detectModuleTag('boton_entendio'), 'Boton :: entendido', '');
  }

  closeXEvent() {
    this.nearbyBoticaModalRef.close();
    this.domDocumentHelper.addScroll();
    if (this.data?.product) {
      this.coachMarkService.activeProductCoachmark.next(true);
    }
  }

  public switchSlide() {
    if (this.modalStatus !== this.ALL_MODAL_STATUS.LOADING) {
      this.isSlide = !this.isSlide;
      this.serviceAnalytic.registerEventAnalytics(this.serviceAnalytic.detectModuleTag('seccion_ventana_botica'), this.isSlide ? 'Click :: Ver mapa' : 'Click :: Ver Lista', '');
    }
   }

  public setModalStatus(state: IModalStatus) {
    this.modalStatus = state;
  }

  public saveChanges() {
    this._appStoreService.removeStorageItem('drugstoreSelected');
    this._appStoreService.setStorageItem('drugstoreSelected', JSON.stringify(this.storesForPickUpStore.selectedTemporalDrugstore));
    this._appStoreService.setStorageItem(LS_ADDRESS_SELECTED, 'false');
    this.storesForPickUpStore.setSelectedDrugstore(this.storesForPickUpStore.selectedTemporalDrugstore);
    this.selectedDrugStore = JSON.parse(this._appStoreService.getStorageItem('drugstoreSelected'));
    this.storesForPickUpStore.setDrugstore.emit(this.selectedDrugStore);
  }

  getProductShoppingCart() {
    const shoppingLocalList = JSON.parse(this._appStoreService.getStorageItem('shoppingCartList')) || null;
    const productId = this.data?.product?.id ? this.data.product.id : shoppingLocalList?.[0]?.productId;

    if (productId) {
      this.productInShoppingCartSubscription = this._productImplement.getProductShoppingCart$(productId)
        .subscribe((value: ProductShoppingCartRequest) => {
          this.productInShoppingCart = !!value;
          this.productPresentationId = this.productInShoppingCart ? value.presentationIdSelected : this.data.defaultPresentation;
          this.productQuantity = this.productInShoppingCart ? value.quantity : 1;
        });
    }
  }

  selectDrugStore(){
    this.retValidateCartService.storeProcessType('RET');
    this.saveChanges();
    this.getDeliveryInfo().subscribe(resp => {
      this.reloadPage();
    });
    this.nearbyBoticaModalRef.close();
    this.snackbarAlert.snackbarAlertSuccess('Método de entrega actualizado a <b>Retiro en tienda<b>');
  }

  selectedAndAddProduct(){
    clearTimeout(this.timeout);
    this.retValidateCartService.storeProcessType('RET');
    this.saveChanges();
    this.addProductToShoppingCart();
    this.getDeliveryInfo().subscribe(resp => {
      this.reloadPage();
    });
    this.nearbyBoticaModalRef.close();
    this.snackbarAlert.snackbarAlertSuccess("Producto agregado y método de entrega actualizado a <b>Retiro en tienda<b>");
  }

  addProductToShoppingCart() {
    const shoppingCartListLocal = JSON.parse(
      this._appStoreService.getStorageItem("shoppingCartList")
    );

      this._productImplement.addProduct(
        this.data?.product?.id || shoppingCartListLocal[0].productId,
        this.productQuantity || shoppingCartListLocal[0].quantity,
        1,
        this.data?.product?.sellerId || "",
        this.data?.sellerName || ""
      );
      this._productSalesforce.addProduct(
        this.data?.product?.id || shoppingCartListLocal[0].productId,
        this.data?.product?.id || shoppingCartListLocal[0].productId,
        this.productQuantity || 0,
        this.data?.product?.price * this.productQuantity || 0
      );
  }

  reloadPage(){
    this.timeout = setTimeout(()=>{
      window.location.reload();
    },2000);
  }

  getDeliveryInfo() {
    return this.deliveryInfoImplement
    .getDeliveryAddressWithoutParams$()
    .pipe(take(1), tap(((deliveryInfo) => {
    })))
  }

  get getIconName(){
    return environment.brand === EBrand.mifarma ? 'info-purple': 'icon-info'
  }

  get getStoreIconName(){
    return environment.brand === EBrand.mifarma ? 'mf-store': 'store-green'
  }


}
