import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-product-tag',
  templateUrl: './product-tag.component.html',
  styleUrls: ['./product-tag.component.sass']
})
export class ProductTagComponent implements OnInit {

  @Input() type: 'sold-out' | 'free-delivery' | 'low-price';
  @Input() public fontSize = 'text-small';

  constructor() {
  }

  ngOnInit() {
  }

}
