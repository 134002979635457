<fp-border-container [selected]="selectedRadio">
  <div
    class="content-office-radio-button"
    [ngClass]="{ clickable: !selectedRadio, disabled: isDisabled }"
    (click)="selectOption()"
  >
    <div class="py-4 px-3">
      <div class="row">
        <div class="col-9 d-flex">
          <div class="content-radio-buton">
            <ng-content></ng-content>
          </div>
          <div class="ml-3">
            <div class="label-black mb-2">{{ title }}</div>
            <div class="label--1">{{ description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</fp-border-container>
