import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ICON } from '../../parameters/geolocation.parameter';

@Component({
  selector: 'fp-search-autocomplete-item',
  templateUrl: './search-autocomplete-item.component.html',
  styleUrls: ['./search-autocomplete-item.component.sass']
})
export class SearchAutocompleteItemComponent implements OnInit {

  @Input() searchItem: Object = {};
  @Input() icon = ICON;
  @Input() title = '';
  @Input() subTitle = '';

  @Input() subTitleRed = false;

  @Input() applyDots = true;

  @Output() selectItem = new EventEmitter<Object>();
  @Output() subTittleRedClick = new EventEmitter();

  @Input() isJustLabel = false;

  constructor() { }

  ngOnInit() {
  }

  public selectAutocompleteItem() {
    this.selectItem.emit(this.searchItem);
  }

  public subTittleRedEvent() {
    this.subTittleRedClick.emit();
  }

}
