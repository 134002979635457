import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fp-tag-delete',
  templateUrl: './tag-delete.component.html',
  styleUrls: ['./tag-delete.component.sass']
})
export class TagDeleteComponent implements OnInit {

  @Input() tagBackground: 'tertiary' | 'quaternary' | 'quinary' | 'gray' = 'tertiary';
  @Input() tagColor: 'white' | 'black' = 'white';
  @Input() tagIcon = true;
  @Output() tagIconEvent = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  iconClick() {
    this.tagIconEvent.emit();
  }

}
