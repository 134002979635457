import { Pipe, PipeTransform } from '@angular/core';
import { GenericContentfulService } from '../../service/generic';

@Pipe({
  name: 'mdToHtml'
})
export class MdToHtmlPipe implements PipeTransform {
  constructor(private _genericContentfulService: GenericContentfulService) {
  }

  transform(value: string): any {
    return this._genericContentfulService.markdownToHtml(value);
  }
}
