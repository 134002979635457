import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BrandAssetPipe} from './asset-url/brand-asset-url.pipe';

const COMPONENTS = [
  BrandAssetPipe
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule {
}
