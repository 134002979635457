import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HomeImplementService } from 'src/app/business/home/services/home-implement.service';

@Component({
  selector: 'fp-tag-predictive',
  templateUrl: './tag-predictive.component.html',
  styleUrls: ['./tag-predictive.component.sass']
})
export class TagPredictiveComponent implements OnInit {

  @Input() name: string;
  @Input() isHistory: boolean = false;
  @Input() iconName: string;
  @Input() sugeridos: Array<string> = [];

  constructor(private _homeImplementService : HomeImplementService) {}

  ngOnInit() {
  }

  deleteTag(name){
    console.log("------>", name);
  }

  searchProduct(name){
    this._homeImplementService.searchProduct.emit(name);
  }

}
