import { Injectable } from '@angular/core';
import { GenericContentfulService } from '../generic';
import { CONTENTFUL_PATH } from '../../parameters/endpoints';
import { CONTENTFUL_QUERIES } from '../../parameters/querys';

@Injectable()
export class CustomerServiceService {
  constructor(
    private _genericContentfulService: GenericContentfulService,
  ) {
  }

  public getFrequentlyAskedQuestion(ids: Array<string>) {
    return this._genericContentfulService.getEntriesPromise(
      CONTENTFUL_PATH.contentTypeIds.customerServiceQuestionsAndAnswer,
      CONTENTFUL_QUERIES.prototype.getEntriesByIds(ids)
    );
  }


  public getFrequentlyAskedQuestionBySlug(slug: string) {
    return this._genericContentfulService.getEntries(
      CONTENTFUL_PATH.contentTypeIds.customerServiceQuestionsAndAnswer,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

}
