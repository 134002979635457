import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'fp-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class DisclaimerComponent implements OnChanges {
  @Input() title: string;
  @Input() type: string;
  @Input() discount: number = 0;
  @Input() flkButtom: boolean = true;
  @Output() gotToAuth? = new EventEmitter();
  text: string = '  ';
  constructor() { }

  ngOnChanges() { 
    this.getTexto();
  }
  getTexto(): void { 
    let discountFixed = this.discount.toFixed(2);
    if (this.type != 'auth') {
      this.text = `Accede al precio Monedero. <strong>Inicia sesión</strong> o <strong>regístrate</strong> para ahorrar <b class='color-discount'>S/ ${discountFixed }</b> en tu compra.`;
    } else {
      this.text = `<strong>Inicia sesión</strong> o <strong>regístrate</strong> para ahorrar <b class='color-discount'>S/ ${discountFixed}</b> con el precio Monedero del Ahorro.`;
    }
  }
  btnGoToAuth() {
    this.gotToAuth.emit();
  }
}
