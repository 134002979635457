import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Entry } from 'contentful';
import { ContentfulEntry } from '../../../shared/models/contentful/contentful.model';
import { CContentfulBanner, IContentfulBanner } from './contentful-banner.model';
import { CContentfulBannerList, IContentfulBannerList } from './contentful-banner-list.model';
import { CContentfulBannerKit, IContentfulBannerKit } from './contentful-banner-kit.model';
import { BACKGROUND_DEFECT } from 'src/app/business/checkout/parameters/constants';
import { IContentfulBannerCardInfo } from './contentful-banner-card-info.model';

export enum EContentfulSliderTemplate {
  template1 = 'Plantilla 1',
  template2 = 'Plantilla 2',
  templateDefault = 'Plantilla 1'
}

export enum ETemplateSlider{
  template1 = 'template1',
  template2 = 'template2',
  template3 = 'template3',
  templateDefault = 'Plantilla 1',
  template4 = 'Plantilla Carrusel Responsive'
}

export interface IContentfulSlider {
  title: string;
  items: Array<Entry<IContentfulBanner | IContentfulBannerKit | IContentfulBannerList | IContentfulBannerCardInfo>>;
  background:string;
  backgroundColor?:string;
  template: ETemplateSlider;
  headingLevel?:string;
  style?:string;
}

export class ContentfulSlider {
  title: string;
  template: ETemplateSlider;
  sliderList: Array<ContentfulEntry<IContentfulBanner | IContentfulBannerKit | IContentfulBannerList | IContentfulBannerCardInfo>>;
  background?:string;
  backgroundColor?:string;
  headingLevel?:string;
  style?:string;

  constructor(iContentfulSlider: IContentfulSlider) {
    this.title = iContentfulSlider.title || '';
    this.sliderList = iContentfulSlider.items ?
      iContentfulSlider.items.map(component => new ContentfulEntry(component)) : [];
    this.template = iContentfulSlider.template || ETemplateSlider.template1;
    this.background = iContentfulSlider.background || BACKGROUND_DEFECT;
    this.backgroundColor = iContentfulSlider.backgroundColor || BACKGROUND_DEFECT;
    this.headingLevel = iContentfulSlider?.headingLevel || "h1";
    this.style = iContentfulSlider?.style || "";
  }
}


export const CContentfulSlider = {
  id: CONTENTFUL_PATH.contentTypeIds.mainSliderBanner,
  sliderConfig: {
    'infinite': true,
    'autoHeight': true,
    'type': 5,
    'loop': false,
    'autoplay': true,
    'autoplayTime': 5000,
    'itemsPerView': 1,
    'pagination': { el: '.slider-pagination', clickable: true },
  },
  itemsType: {
    banner: CContentfulBanner.id,
    bannerList: CContentfulBannerList.id,
    bannerKit: CContentfulBannerKit.id,
  }
};
export const CContentfulSliderTemplate2 = {
  id: CONTENTFUL_PATH.contentTypeIds.mainSliderBanner,
  sliderConfig: {
    'infinite': true,
    'autoHeight': false,
    'type': 5,
    'loop': false,
    'autoplay': true,
    'autoplayTime': 5000,
    'itemsPerView': 3,
    'pagination': { el: '.slider-pagination', clickable: true },
    'navigation': false
  },
  itemsType: {
    banner: CContentfulBanner.id,
    bannerList: CContentfulBannerList.id,
    bannerKit: CContentfulBannerKit.id,
  }
};
export const CContentfulSliderTemplate3 = {
  id: CONTENTFUL_PATH.contentTypeIds.mainSliderBanner,
  sliderConfig: {
    'infinite': true,
    'autoHeight': true,
    'type': 5,
    'loop': false,
    'autoplay': false,
    'autoplayTime': 5000,
    'pagination': { el: '.slider-pagination', clickable: true },
    'navigation': false,
    'slidesConfigByBreakpoints': {
      'xxs': {
        slidesPerView: 1.26,
        spaceBetween: 16
      },
      'sm': {
        slidesPerView: 1.8,
        spaceBetween: 16
      },
      'md': {
        slidesPerView: 2.4,
        spaceBetween: 16
      },
      'lg': {
        slidesPerView: 3,
        spaceBetween: 14
      },
      'xl': {
        slidesPerView: 4,
        spaceBetween: 14
      },
      'xxl': {
        slidesPerView: 5,
        spaceBetween: 14
      },
    },
    'slidesConfigByBreakpointsBanner': {
      'xxs': {
        slidesPerView: 1.26,
        spaceBetween: 16
      },
      'sm': {
        slidesPerView: 1.8,
        spaceBetween: 16
      },
      'md': {
        slidesPerView: 2.4,
        spaceBetween: 16
      },
      'lg': {
        slidesPerView: 2,
        spaceBetween: 14
      },
      'xl': {
        slidesPerView: 3,
        spaceBetween: 14
      },
      'xxl': {
        slidesPerView: 3,
        spaceBetween: 14
      },
    }
  },
  itemsType: {
    banner: CContentfulBanner.id,
    bannerList: CContentfulBannerList.id,
    bannerKit: CContentfulBannerKit.id,
  }
}
