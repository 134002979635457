import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SERVICES_TYPES_ENUM } from "@parameters/global";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { getFormattedTime } from "src/app/shared/helpers/momentjs-functions.helper";
import { END_POINTS_BUSSINESS } from "src/app/shared/parameters/generic/endpoints";
import { EHttpHeaders } from "src/app/shared/parameters/generic/http-header.parameter";
import { TokenStoreService } from "src/app/shared/stores/user/token-store.service";
import { IDrugstore, IDrugStoreRequest } from "../models/drugstore.interface";

@Injectable({
  providedIn: "root",
})
export class SelectedDrugstoreHttpService {
  constructor(
    private _httpClient: HttpClient,
    private _tokenStoreService: TokenStoreService
  ) {}

  getDrugStoreList$(body: IDrugStoreRequest): Observable<Array<IDrugstore>> {
    const headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });

    body.channel = "CALL";

    return this._httpClient
      .post<Array<IDrugstore>>(
        `${END_POINTS_BUSSINESS.POST_NEAR_STORE}`,
        body,
        {
          headers,
        }
      )
      .pipe(
        map((list) => {
          list.forEach((r) => {
            r.startHour = getFormattedTime(r.startHour);
            r.endHour = getFormattedTime(r.endHour);

            const serviceRET = r.services.find(
              (service) => service.shortName === SERVICES_TYPES_ENUM.RETIRO
            );
            if (serviceRET) {
              serviceRET.startHour = getFormattedTime(serviceRET.startHour);
              serviceRET.endHour = getFormattedTime(serviceRET.endHour);
            }
          });
          return list;
        })
      );
  }
}
