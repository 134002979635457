import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Client } from "@models/client/client.model";
import { throwError } from "rxjs";
import { catchError, finalize, last } from "rxjs/operators";
import { OfficeModalService } from "src/app/business/office/services/office-modal.service";
import { NewAddressManagerService } from "src/app/core/modules/address-manager/address-services/new-address-manager/new-address-manager.service";
import { AddressListStoreService } from "src/app/core/modules/address-manager/address-stores/address-list-store.service";
import { FLOW_SEARCH_ADDRESS_TYPE } from "src/app/core/modules/address-manager/parameters/modal.constant";
import { SnackbarAlertService } from "src/app/core/modules/snackbar/snackbar-alert.service";
import { SelectItem } from "src/app/shared/models/documents-type/document-type.model";
import { CONFIG_USER_NOT_REGISTERED } from "src/app/shared/parameters/enums";
import { DOCUMENT_TYPE, HTTP_ERROR_RESPONSE_STATUS } from "src/app/shared/parameters/global";
import { ClientStoreService } from "src/app/shared/stores/client/client-store.service";
import { ClientManagerFormService } from "../../forms/client-manager-form.service";
import {
	ClientManagerDataForm,
	ClientManagerUpdateRequest,
	DOCUMENT_TYPES,
	DOCUMENT_TYPE_DNI,
	TYPE_CLIENT_MANAGER
} from "../../paramaters/client-manager.const";
import { ClientManagerModalService } from "../../services/client-manager-modal.service";
import { ClientManagerRequestService } from "../../services/client-manager-request.service";
import { FindClientRequestService } from "../../../find-client-manager/services/find-client-request.service";
import { AppStoreService } from "@stores/app/app-store.service";
import { DocumentsImplementService } from "@implements/documents/documents-implement.service";
import { PricePurseService } from "@stores/price-purse/price-purse-store.store";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { environment } from "@environments/environment";

@Component({
	selector: "fp-client-manager-modal",
	templateUrl: "./client-manager-modal.component.html",
	styleUrls: ["./client-manager-modal.component.sass"]
})
export class ClientManagerModalComponent implements OnInit {
	documentTypeList: SelectItem[] = DOCUMENT_TYPES;
	maxLength = CONFIG_USER_NOT_REGISTERED.DNI_LENGTH;
	loader: boolean;
	public documentTypeOptions: Array<string>;
	public documentType: string;
	public disableButtonSearch: boolean;
	public isInEditClientModal: boolean;
  public isInkaBrand = OrderTakerValidator.isOrderTakerEnv()
    ? EBrand[EBrandAsset[environment.brand]] == EBrand.inkafarma
    : EBrand[environment.brand] == EBrand.inkafarma;
	title = "";
	subTitle = "";
	textButton = "";
	searchUser = false;
	tyc;
	dato;
	priv;
	constructor(
		private _clientManagerModalService: ClientManagerModalService,
		private _officeModalService: OfficeModalService,
		public _clientManagerForm: ClientManagerFormService,
		public _clientManagerRequestService: ClientManagerRequestService,
		public _clientStoreService: ClientStoreService,
		private _snackbarAlert: SnackbarAlertService,
		private _newAddressManager: NewAddressManagerService,
		private _addressListStore: AddressListStoreService,
		private _findClientRequestService: FindClientRequestService,
		private _appStore: AppStoreService,
	    private _documentsImplementService:DocumentsImplementService,
    	private _pricePurseService:PricePurseService,
		@Inject(MAT_DIALOG_DATA) public data: TYPE_CLIENT_MANAGER
	) {}

	ngOnInit(): void {
		this.loadSetting();
		// this._clientManagerForm.disableFields();
		this.setDefaultDocumentType();
		this.data === TYPE_CLIENT_MANAGER.UPDATE_CLIENT ? this.loadDataClient() : null;
	}

	loadDataClient() {
		const client: Client = this._clientStoreService.client;

		let approvalBrand = [];
		let hasTYC = false;
		let hasDATO = false;
		let hasPRIV = false;
		if (client.approvals) {
			approvalBrand = client.approvals.filter((app) => app.brand === this._appStore.brandAtention && app.channel === "CALL");
			hasTYC = approvalBrand.some((app) => app.agreement === "TYC");
			hasDATO = approvalBrand.some((app) => app.agreement === "DATO");
			hasPRIV = approvalBrand.some((app) => app.agreement === "PRIV");
		}

		this.searchUser = true;
		if (approvalBrand.length === 0) {
			this.tyc = "cancel-red";
			this.dato = "cancel-red";
			this.disableButtonSearch = false;
		}

		if (hasTYC || hasPRIV) {
			if (hasDATO) {
				this.tyc = "check-green";
				this.dato = "check-green";
				this.disableButtonSearch = this.isInEditClientModal;
			} else {
				this.tyc = "check-green";
				this.dato = "cancel-red";
				this.disableButtonSearch = this.isInEditClientModal;
			}
		} else if (!hasTYC && !hasPRIV) {
			if (hasDATO) {
				this.tyc = "cancel-red";
				this.dato = "check-green";
				this.disableButtonSearch = this.isInEditClientModal;
			} else {
				this.tyc = "cancel-red";
				this.dato = "cancel-red";
				this.disableButtonSearch = false;
			}
		}
		const dataForm: ClientManagerDataForm = {
			identityDocumentType: client.idIdentityDocument,
			document: client.identityDocumentNumber,
			names: client.name,
			paternalLastname: client.lastNamePat,
			maternalLastname: client.lastNameMat,
			emailAddress: client.email,
			phoneNumber: client.phone ?? "",
			phoneNumberOne: client.phone2 ?? "",
			phoneNumberTwo: client.phone3 ?? "",
			termsAndPolitics: hasTYC ? hasTYC : false,
			dataTreatment: hasDATO ? hasDATO : false,
			politicsPrivacy: hasPRIV ? hasPRIV : false
		};
		this.searchUser = true;
		this._clientManagerForm.setDataForm(dataForm);
		this._clientManagerForm.enableFields();
	}

	loadSetting() {
		if (this.data === TYPE_CLIENT_MANAGER.UPDATE_CLIENT) {
			this.title = "Editar cliente";
			this.subTitle = "Evalúa y actualiza los datos del cliente.";
			this.textButton = "Actualizar datos del cliente";
			this.isInEditClientModal = true;
		}

		if (this.data === TYPE_CLIENT_MANAGER.NEW_CLIENT) {
			this.title = "Registra un cliente";
			this.subTitle = "Completa estos datos para crear la cuenta.";
			this.textButton = " Crear cuenta";
			this.isInEditClientModal = false;
		}
	}

	setDefaultDocumentType() {
		if (this._clientStoreService.client) {
			const { idIdentityDocument }: Client = this._clientStoreService.client;
			const documentTypeFound = this.documentTypeList.find((documentType) => documentType.id === idIdentityDocument);
			this.documentType = documentTypeFound ? documentTypeFound.name : null;
			this._clientManagerForm.documentTypeControl.setValue(documentTypeFound ? documentTypeFound.id : null);
			this.configValidationDocument(documentTypeFound ? documentTypeFound.name : DOCUMENT_TYPE_DNI.name);
		} else {
			this.documentType = DOCUMENT_TYPE_DNI.name;
			this.configValidationDocument(DOCUMENT_TYPE_DNI.name);
		}

		this.documentTypeOptions = this.documentTypeList.map((option) => option.name);

		this._clientManagerForm.documentTypeControl.valueChanges.subscribe((value) => {
			this.configValidationDocument(value);
		});
	}

	configValidationDocument(typeDocument: string) {
		switch (typeDocument) {
			case DOCUMENT_TYPE.DNI:
				this._clientManagerForm.documentControl.setDniValidation();
				this.maxLength = CONFIG_USER_NOT_REGISTERED.DNI_LENGTH;
				break;
			case DOCUMENT_TYPE.CE:
				this._clientManagerForm.documentControl.setCEValidation();
				this.maxLength = CONFIG_USER_NOT_REGISTERED.CE_MAX_LENGTH;
				break;
			case DOCUMENT_TYPE.PAS:
				this._clientManagerForm.documentControl.setPassportValidation();
				this.maxLength = CONFIG_USER_NOT_REGISTERED.PASSPORT_MAX_LENGTH;
				break;
			default:
				this._clientManagerForm.documentControl.setDniValidation();
				this.maxLength = CONFIG_USER_NOT_REGISTERED.DNI_LENGTH;
				break;
		}
		this._clientManagerForm.documentControl.setValue("");
	}

	setDocumentType(documentTypeOption: string) {
		const documentType = this.documentTypeList.find((value) => value.name === documentTypeOption);
		this._clientManagerForm.documentTypeControl.setValue(documentType.id);
	}

	onClose(): void {
		this._clientManagerForm.resetForm();
		this._clientManagerModalService.closeRegisterClientModal();
	}

	onSubmit() {
		this.data === TYPE_CLIENT_MANAGER.NEW_CLIENT ? this.onRegister() : this.onUpdateClient();
	}

	onUpdateClient() {
		const client = this._clientStoreService.client;

		const valueForm = this._clientManagerForm.formatValue;
		const updateRequest: ClientManagerUpdateRequest = {
			...valueForm,
			id: client.codClient
		};

		updateRequest.identityDocumentNumber = updateRequest.identityDocumentNumber ? updateRequest.identityDocumentNumber : "00000000";
		valueForm.isAcceptTyC = valueForm.isAcceptTyC ? valueForm.isAcceptTyC : false;
		valueForm.isDataTreatment = valueForm.isDataTreatment ? valueForm.isDataTreatment : false;
		valueForm.isPrivacyPolicy = valueForm.isPrivacyPolicy ? valueForm.isPrivacyPolicy : false;
		this.loader = true;
		this._clientManagerRequestService
			.putUpdate(updateRequest)
			.pipe(
				catchError((err) => {
					return throwError(err);
				}),
				finalize(() => {
					this.loader = false;
				})
			)
			.subscribe((response) => {
				if (response.code == "208") {
					this._clientManagerForm.documentControl.setErrors({
						invalidDocument: true
					});
				}
				if (response.id) {
					const messageAlert = `¡Se actualizo los datos del cliente ${updateRequest.name} con éxito!.`;
					this.checkIsPricePurse(valueForm.idIdentityDocument,valueForm.identityDocumentNumber)
					this._snackbarAlert.snackbarAlertSuccess(messageAlert, null, "Entendido");
					const newDataClient = updateRequest as Client;
					newDataClient.codClient = client.codClient;
					newDataClient.addresses = this._clientStoreService.addresses;
					newDataClient.isAcceptLoyalty = response.acceptLoyalty;
					newDataClient.name = valueForm.name;
					newDataClient.lastNameMat = valueForm.lastNameMat;
					newDataClient.lastNamePat = valueForm.lastNamePat;
					newDataClient.email = valueForm.email;
					newDataClient.phone = valueForm.phone;
					newDataClient.phone2 = valueForm.phone2;
					newDataClient.phone3 = valueForm.phone3;
					newDataClient.idIdentityDocument = valueForm.idIdentityDocument;
					newDataClient.identityDocumentNumber = valueForm.identityDocumentNumber;
					newDataClient.approvals = this._clientStoreService.client.approvals;
					newDataClient.isAcceptTyC = valueForm.isAcceptTyC;
					newDataClient.isDataTreatment = valueForm.isDataTreatment;
					newDataClient.isPrivacyPolicy = valueForm.isPrivacyPolicy;
					newDataClient.isAcceptLoyalty = this._clientStoreService.client.isAcceptLoyalty;
					this._clientStoreService.setClient$(newDataClient);
					this._clientStoreService.saveClient$();
					this.onClose();
				}
			});
	}

	checkIsPricePurse(idIdentityDocument,identityDocumentNumber){
		const parameters = {
			docType:idIdentityDocument,
			docNumber: identityDocumentNumber,
		  };
		  this._documentsImplementService.validationDocumentMonedero$(parameters).subscribe(Data =>{
			const { flagMonedero } = Data
			this._pricePurseService.setpricePurseStatus(flagMonedero)
		  })
	}
	onRegister(): void {
		this.loader = true;
		this._addressListStore.setAddressList([]);

		const valueForm = this._clientManagerForm.client;
		valueForm.identityDocumentNumber = valueForm.identityDocumentNumber ? valueForm.identityDocumentNumber : "00000000";
		valueForm.isAcceptTyC = valueForm.isAcceptTyC ? valueForm.isAcceptTyC : false;
		valueForm.isDataTreatment = valueForm.isDataTreatment ? valueForm.isDataTreatment : false;
		valueForm.isPrivacyPolicy = valueForm.isPrivacyPolicy ? valueForm.isPrivacyPolicy : false;

		this._clientManagerRequestService
			.postRegisterClient$(valueForm)
			.pipe(
				catchError((err) => {
					return throwError(err);
				}),
				finalize(() => {
					this.loader = false;
				})
			)
			.subscribe((response) => {
				if (response.msg) {
					this._clientManagerForm.documentControl.setErrors({
						invalidDocument: true
					});
					// this._clientManagerForm.documentControl.updateValueAndValidity();
				}
				if (response.id) {
					this._newAddressManager.showNewAddressModal(FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS);
					this.onClose();
				}
			});
	}
	searchClient() {
		if(this.disableButtonSearch) return;
		if (this._clientManagerForm.documentControl.invalid) return;
		const document = this._clientManagerForm.documentControl.value;

		this._findClientRequestService.getApprovalDetail$({ document: document }).subscribe(
			(user) => {
				let approvals = !user.approvals ? [] : user.approvals;
				// const approvalBrand = approvals.filter((app) => app.brand === this._appStore.brandAtention && app.channel === "CALL");
				const hasTYC = approvals.some(
					(app) => app.brand === this._appStore.brandAtention && app.channel === "CALL" && app.agreement === "TYC"
				);
				const hasDATO = approvals.some((app) => app.brand === this._appStore.brandAtention && app.agreement === "DATO");
				const hasPRIV = approvals.some(
					(app) => app.brand === this._appStore.brandAtention && app.channel === "CALL" && app.agreement === "PRIV"
				);
				if (approvals.length === 0) {
					this.dato = "cancel-red";
					this.tyc = "cancel-red";
					this.disableButtonSearch = false;
					this.foundUserApproval(user);
					return (this.searchUser = true);
				}
				this._clientManagerForm.termsAndPoliticsControl.setValue(hasTYC);
				this._clientManagerForm.dataTreatmentControl.setValue(hasDATO);
				this._clientManagerForm.politicsPrivacyControl.setValue(hasPRIV);
				this.searchUser = true;
				if (hasTYC || hasPRIV) {
					if (hasDATO) {
						this.tyc = "check-green";
						this.dato = "check-green";
						this.disableButtonSearch = this.isInEditClientModal;
						this.foundUserApproval(user);
					} else {
						this.tyc = "check-green";
						this.dato = "cancel-red";
						this.disableButtonSearch = this.isInEditClientModal;
						this.foundUserApproval(user);
					}
				} else if (!hasTYC && !hasPRIV) {
					if (hasDATO) {
						this.tyc = "cancel-red";
						this.dato = "check-green";
						this.disableButtonSearch = this.isInEditClientModal;
						this.foundUserApproval(user);
					} else {
						this.tyc = "cancel-red";
						this.dato = "cancel-red";
						this.disableButtonSearch = false;
						this.foundUserApproval(user);
					}
				}
			},
			(err) => {
				this.notFoundUserApproval();
			}
		);
	}
	get getStatusForm() {
		const statusNameControl = this._clientManagerForm.namesControl.status;
		if (statusNameControl === "DISABLED") {
			return true;
		} else {
			return this._clientManagerForm.form.invalid;
		}
	}
	foundUserApproval(user) {
		const { name, lastName, phones, emails } = user;
		let lastNames = [];
		let matLastName = "";
		let patLastName = "";
		if (lastName) {
			lastNames = user.lastName.split(" ");
			patLastName = lastNames[0] ? lastNames[0] : "";
			matLastName = lastNames[1] ? lastNames[1] : "";
		}
		this._clientManagerForm.enableFields();
		this._clientManagerForm.namesControl.setValue(name ?? "");
		this._clientManagerForm.paternalLastnameControl.setValue(patLastName);
		this._clientManagerForm.maternalLastnameControl.setValue(matLastName);
		this._clientManagerForm.emailAddressControl.setValue(emails ? emails[0] : "");
		this._clientManagerForm.phoneNumberControl.setValue(phones ? phones[0] : "");
		this._clientManagerForm.phoneNumberOneControl.setValue(phones ? phones[1] : "");
		this._clientManagerForm.phoneNumberTwoControl.setValue(phones ? phones[2] : "");
		this.searchUser = true;
		this._clientManagerForm.validAllFields();
	}
	notFoundUserApproval() {
		this.tyc = "cancel-red";
		this.dato = "cancel-red";
		this.disableButtonSearch = false;
		this.searchUser = true;
		this._clientManagerForm.namesControl.reset();
		this._clientManagerForm.paternalLastnameControl.reset();
		this._clientManagerForm.maternalLastnameControl.reset();
		this._clientManagerForm.emailAddressControl.reset();
		this._clientManagerForm.phoneNumberControl.reset();
		this._clientManagerForm.phoneNumberOneControl.reset();
		this._clientManagerForm.phoneNumberTwoControl.reset();
		return;
	}
}
