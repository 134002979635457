<div class="content address-delivery">
  <fp-border-container>
    <div class="change-address-delivery">
      <div class="row">
        <div class="col-xs-12 col-md-10 address-text">
          <div class="label-black mb-2">{{drugStoreName}}</div>
          <div class="label--1">{{[drugStoreAddress]}}</div>
        </div>
        <div class="col-xs-12 col-md-2 address-button-change">
          <fp-link-buttom [id]="'chooseDelivery'" [buttonText]="'Cambiar'" (onclick)="changeAddress()"></fp-link-buttom>
        </div>
      </div>
    </div>
  </fp-border-container>
</div>
