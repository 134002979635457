import { CdkStepper } from "@angular/cdk/stepper";
import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { environment } from "@environments/environment";
import { Subscription } from "rxjs";
import { CartControlledFormModalComponent } from "../cart-controlled-form-modal/cart-controlled-form-modal.component";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { EBrand } from "@parameters/access/brand.parameter";

@Component({
	selector: "fp-cart-controlled-products-modal",
	templateUrl: "./cart-controlled-products-modal.component.html",
	styleUrls: ["./cart-controlled-products-modal.component.sass"]
})
export class CartControlledProductsModalComponent implements OnInit, OnDestroy {

	public isMifaBrand = environment.brand === EBrand.mifarma;

	@ViewChild("stepper", { static: true }) stepper: CdkStepper;
	@Inject(MAT_DIALOG_DATA) public data: any;

	constructor(public dialogRef: MatDialogRef<CartControlledProductsModalComponent>, private _matDialog: MatDialog) {}

	ngOnDestroy(): void {}

	ngOnInit() {}

	onClose(value) {
		value === "close" ? TaggingService.closeModalControlledInfo() : TaggingService.backToCartModalControlledOnborading();
		this.dialogRef.close(false);
	}

	getIconBrand(iconName) {
		const brand = environment.brand === EBrand.inkafarma ? "ikf" : "mf";
		return `${iconName}-${brand}`;
	}

	nextAction() {
		TaggingService.nextModalControlledInfo()
		this.dialogRef.close(true);
	}
}
