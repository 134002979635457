import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthImplementService } from "@implements/auth/auth-implement.service";
import { ShoppingListStoreService } from "@stores/shopping-list/shopping-list-store.service";
import { BehaviorSubject, Observable } from "rxjs";
import { IUser } from "../../interfaces/user.interface";
import { User } from "../../models/auth/user.model";
import { Role } from "../../parameters/auth/role.parameter";
import { CookieService } from "../../storage/cookie.service";
import { OrderTakerValidator } from "../../validators/order-taker.validator";
import { OrderTakerStore } from "../order-taker/order-taker.store";
import { StorageClientService } from "./../../storage/storage-client.service";
import { TokenStoreService } from "./token-store.service";

@Injectable({
  providedIn: "root",
})
export class UserTokenStoreService {
  private readonly STORAGE_USER = "user";
  private readonly STORAGE_REMEMBER_ME = "remember_me";
  private user: User;
  private userSubject = new BehaviorSubject<User>(null);

  constructor(
    private _tokenStore: TokenStoreService,
    private _router: Router,
    private _storage: StorageClientService,
    private _orderTakerStore: OrderTakerStore,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private _shoppingListStoreService: ShoppingListStoreService,
    private _authImplementService: AuthImplementService
  ) {
    this._tokenStore.decodeToken$.subscribe((iDecodeToken) => {
      if (iDecodeToken) {
        this.decodeUser = this._tokenStore.decodeToken;
      } else {
        this.user = null;
      }
    });
  }

  set decodeUser(iUser: IUser) {
    this.user = new User(iUser);
    this.userSubject.next(this.user);
    this._storage.setStorageItem(this.STORAGE_USER, JSON.stringify(iUser));
    this.cookieService.setCookie(this.STORAGE_USER, this.user.email);
  }

  authenticated(): boolean {
    if (OrderTakerValidator.isOrderTakerEnv) {
      return !!this.currentUser && !!this._tokenStore.accessToken;
    } else return !!this.currentUser;
  }

  hasRole(role: Role): boolean {
    return this.authenticated() && this.user.role === role;
  }

  set accessToken(value) {
    this._tokenStore.accessToken = value;
  }
  set accessTemporalToken(value) {
    this._tokenStore.accessTemporalToken = value;
  }
  get currentUser(): User {
    return JSON.parse(this._storage.getStorageItem("user")) as User;
  }

  get currentUser$(): Observable<User> {
    return this.userSubject.asObservable();
  }

  get rememberUser(): string {
    return this.cookieService.getCookie(this.STORAGE_USER) as string;
  }

  set rememberMe(value: boolean) {
    this.cookieService.setCookie(this.STORAGE_REMEMBER_ME, value);
  }
  get rememberMe(): boolean {
    return this.cookieService.getCookie<boolean>(this.STORAGE_REMEMBER_ME);
  }
  removeRememberMe() {
    this.cookieService.removecookie(this.STORAGE_REMEMBER_ME);
  }

  logout() {
    this._authImplementService.signOutNotRedirect();
    this.user = null;
    this.userSubject.next(null);
    this._shoppingListStoreService.removeShoppingList();
    this._tokenStore.removeAccessToken();
    this._orderTakerStore.reset();
    this._storage.cleanStorage();
    this.removeRememberMe();
    this.dialog.closeAll();
    this._router.navigate(["/login"]);
  }
}
