import { Injectable } from '@angular/core';
import { ShoppingListStoreService } from 'src/app/shared/stores/shopping-list/shopping-list-store.service';
import { ProductShoppingCart } from '../models/product-shopping-cart/product-shopping-cart.model';
import { Router } from '@angular/router';
import { ProductImplementService } from './product-implement.service';
import { ProductSalesforceService } from './product-salesforce.service';
import { TaggingService } from 'src/app/atm-services/tagging.service';
import { ProductManagerSnackbarsHelperService } from 'src/app/shared/helpers/product-manager-snackbars-helper.service';
import { SnackbarAlertService } from 'src/app/core/modules/snackbar/snackbar-alert.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartListValidationsService {
  public shoppingList: Array<ProductShoppingCart>;

  constructor(
    private _shoppingListStore: ShoppingListStoreService,
    private productManagerSnackbarsHelper: ProductManagerSnackbarsHelperService,
    private _snackbarAlertService: SnackbarAlertService,
    private _router: Router,
    private _productImplement: ProductImplementService,
    private _productSalesforce: ProductSalesforceService,
  ) { }

  public isValidSellerId(sellerId: string) {
    const { staticShoppingList } = this._shoppingListStore;
    const shoppingListLength = staticShoppingList.length;
    if (shoppingListLength === 0) {
      return true;
    }
    const currentSellerId = staticShoppingList[0].sellerId;
    const isValidShoppingList = staticShoppingList.every(p => {
      return p.sellerId === currentSellerId;
    });
    const isValidSellerId = currentSellerId === sellerId;
    return isValidSellerId;
  }

  public isShoppingCartEmpty() {
    const { staticShoppingList } = this._shoppingListStore;
    const shoppingListLength = staticShoppingList.length;
    if (shoppingListLength === 0) {
      return true;
    }
    return false;
  }

  public setProductIssue(product) {
    this._shoppingListStore.setProductIssue(product);
  }

  public setProductIssueList(products) {
    if (this._shoppingListStore.getIsInActiveOrderSeller()) {
      products.forEach(element => {
        element.id = element.sku;
      });
    }
    this._shoppingListStore.setProductIssueList(products);
  }

  public setIsInActiveOrderSeller(param: boolean) {
    this._shoppingListStore.setIsInActiveOrderSeller(param);
  }

  public finishPurchase() {
    this.goToCheckout();
  }

  public updateCart() {
    this._shoppingListStore.setShoppingList([]);
    const products = this._shoppingListStore.getProductIssueList();
    products.map( product => {
      const isProductInShoppingCart = this._productImplement.isProductInShoppingCart(product.id);
      if (isProductInShoppingCart) {
        this._productImplement.updateProduct(
          product.id,
          1,
          product.defaultPresentation,
          product.sellerId,
          product.sellerName
        );
      } else {
        this._productImplement.addProduct(
          product.id,
          1,
          product.defaultPresentation,
          product.sellerId,
          product.sellerName
        );
      }
    });

    if (this._shoppingListStore.getIsInActiveOrderSeller()) {
      this._snackbarAlertService.snackbarAlertSuccess('Los productos de este pedido se han agregado al carrito', {}, 'Ok');
    } else {
      this.productManagerSnackbarsHelper.productAddedSuccessfuly();
    }
  }


  goToCart() {
    this._router.navigate(['/carrito']);
  }

  goToCheckout() {
    this._router.navigate(['/checkout']);
  }

}
