<ng-container [ngSwitch]="presentationTemplate">
  <ng-container *ngSwitchCase="'select'">
    <fp-product-presentation-select
      [presentationList]="presentationList"
      [presentationSelected]="presentationSelected"
      [productStatus]="productStatus"
      (updatePresentation)="updatePresentationEvent($event)">
    </fp-product-presentation-select>
  </ng-container>

  <ng-container *ngSwitchCase="'cards'">
    <fp-product-presentation-cards
      [presentationList]="presentationList"
      [presentationSelected]="presentationSelected"
      [productStatus]="productStatus"
      (updatePresentation)="updatePresentationEvent($event)">
    </fp-product-presentation-cards>
  </ng-container>

  <ng-container *ngSwitchCase="'list'">
    <fp-product-presentation-list
      [presentationList]="presentationList"
      [presentationSelected]="presentationSelected"
      [productStatus]="productStatus"
      (updatePresentation)="updatePresentationEvent($event)">
    </fp-product-presentation-list>
  </ng-container>
  <div class="col text-error" *ngIf="productStatus === 23">
    Cambia la presentación
  </div>
</ng-container>
