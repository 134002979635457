
<div class="check-list-password mb-2">
  <div class="check-list-content">
    <div class="check-list-item mb-2">
      <span
        class="icon"
        [ngClass]="{
          'icon-16-check': verifyCharacter,
          'icon-16-check-gray': !verifyCharacter
        }">
      </span>
        Mín 8 caracteres
    </div>
    <div class="check-list-item">
      <span
        class="icon"
        [ngClass]="{
          'icon-16-check': verifyLower,
          'icon-16-check-gray': !verifyLower
        }">
      </span>
      Una minúscula
    </div>
  </div>
  <div class="check-list-content">
    <div class="check-list-item mb-2">
      <span
        class="icon"
        [ngClass]="{
          'icon-16-check': verifyCapital,
          'icon-16-check-gray': !verifyCapital
        }">
      </span>
      Una mayúscula
    </div>
    <div class="check-list-item">
      <span
        class="icon"
        [ngClass]="{
          'icon-16-check': verifyNumber,
          'icon-16-check-gray': !verifyNumber
        }">
      </span>
      Un número
    </div>
  </div>
</div>
