import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { isArray } from 'src/app/shared/helpers/objects-equal';
import { SnackbarAlertComponent } from '../snackbar-alert/snackbar-alert.component';
import { IUnitSnackbarConfig } from '../unit-snackbar-config.interface';

@Component({
  selector: 'fp-multiple-snackbars',
  templateUrl: './multiple-snackbars.component.html',
  styleUrls: ['./multiple-snackbars.component.sass']
})
export class MultipleSnackbarsComponent {

  public snackbarsConfig: IUnitSnackbarConfig[] = [];

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) private data: IUnitSnackbarConfig[],
    private _matSnackBarRef: MatSnackBarRef<SnackbarAlertComponent>
  ) {
    this.snackbarsConfig = isArray(this.data) ? this.data : [];
  }

  dismiss() {
    this._matSnackBarRef.dismiss();
  }
}
