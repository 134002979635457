import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneMask'
})
export class PhoneMaskPipe implements PipeTransform {

  transform(phone: string): string {
    return `${phone.slice(0, 1)}******${phone.slice(7, 9)}`;
  }

}
