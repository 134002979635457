import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumericOnly]'
})
export class NumericOnlyDirective {
  @Input() maxDigits: number = 9;  // Por defecto, 9 dígitos

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    let inputValue = input.value.replace(/[^0-9]/g, '');

    // Limita la entrada al número máximo de dígitos especificado
    if (inputValue.length > this.maxDigits) {
      inputValue = inputValue.substring(0, this.maxDigits);
    }

    this.ngControl.control?.setValue(inputValue);
  }
}