<div class="main-login-modal" id="core-forgot-password-modal">
  <fp-section-container-title-login
    [title]="'Restablecer mi contraseña'"
    [iconName]="'close'"
    (onclick)="closeModal()"
  >
  </fp-section-container-title-login>

  <div class="mt-4">
    <fp-sub-section-container>
      <div class="content" [formGroup]="forgotPasswordForm.form">
        <div class="mt-4">Ingresa tu correo para iniciar el restablecimiento</div>
        <div class="mb-4">
          <fp-input-control
            [errorMessage]="forgotPasswordForm.emailControl.emailAddressError"
            [marginWithError]="true"
            [errorMsgJoinToInput]="true">
            <input type="text"
              id="ctrl--restore-email" 
              class="w-100 input"
              maxlength="50"
              formControlName="email"
              #inputControl 
              placeholder="Correo electrónico">
          </fp-input-control>
        </div>

        <div class="mb-2">
          <fp-button-primary
            buttonId="btn--restore-password"
            buttonDisplay="block"
            (click)="forgotPassword()"
            [buttonDisabled]="!forgotPasswordForm.form.valid || disabledButton">
            Restablecer contraseña
          </fp-button-primary>
        </div>
      </div>
    </fp-sub-section-container>
  </div>

  <div class="text-center mb-3">
    <div class="mb-4">
      <fp-link-buttom
        [buttonText]="'Volver al inicio de sesión'"
        [id]="'link--open-login-modal'"
        (onclick)="closeModal()">
      </fp-link-buttom>
    </div>
  </div>
</div>
