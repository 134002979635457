import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IGeolocationPermission } from 'src/app/shared/geolocation/geolocation.service';
import { IGoogleFormattedAddress } from '../../custom-agm-core/services/google-map-places.service';

export interface IUserGeolocation {
  permissions: IGeolocationPermission;
  geolocationAddress?: IGoogleFormattedAddress;
  name: string;
}

@Injectable()
export class UserGeolocationStoreService {

  private _userGeolocation: IUserGeolocation;
  private _userGeolocationSubject = new BehaviorSubject<IUserGeolocation>({} as IUserGeolocation);
  public userGeolocation$ = this._userGeolocationSubject.asObservable();
  public get userGeolocation(): IUserGeolocation {
    return this._userGeolocation;
  }

  constructor() {
    this.userGeolocation$.subscribe(geo => this._userGeolocation = geo);
  }

  public setUserGeolocation(userGeolocation: IUserGeolocation) {
    this._userGeolocationSubject.next(userGeolocation);
  }
}
