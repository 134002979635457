<div class="content p-28">
  <div class="title-form" *ngIf="iconName || title">
    <div class="checkout-title-with-icon font-bold">
      <div class="icon-section">
        <span *ngIf="iconName || svgName">
          <fp-icon
            [fontName]="iconName"
            [iconName]="svgName"
            [iconSize]="iconSize"
          ></fp-icon>
        </span>
      </div>
      <p
        *ngIf="title"
        class="font-VAGRoundedNexxt-SemiBold"
        [innerHTML]="title"
      ></p>
    </div>
  </div>
</div>
