<div class="store-for-ret" (click)="selectOption()">
  <div class="store-radio-button" *ngIf="inputRadioButton">
    <ng-content select="[input-radio-button]"></ng-content>
  </div>
  <div class="store-for-ret-content">
    <div class="row">
      <div class="col-7 col-xs-8">
        <div class="store-for-ret-title">
          {{ storeToChoose.title }}
        </div>
      </div>
      <div class="col-5 col-xs-4 d-flex justify-content-end">
        <div class="tag-distance">
          <fp-icon iconSize="10" iconName="white-fill-gps"></fp-icon>
          <div>
            {{ storeToChoose.distance }}
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-11 col-md-10">
        <div class="store-for-ret-address mt-3 mb-4">
          {{ storeToChoose.address }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="store-for-ret-schedule">
          <fp-icon iconSize="16"
            [iconName]="getIconName">
          </fp-icon>
          {{ storeToChoose.schedule }}
        </span>
      </div>
    </div>
  </div>
</div>
