import { FormControl, Validators } from '@angular/forms';
import { getEnableErrorMessages } from '@helpers/error-controls-helpers';

export class DiscountCodeControl extends FormControl {

  constructor() {
    super('');
    this.settingValidators();
  }

  private settingValidators() {
    this.setValidators([
      Validators.required,
    ]);
  }

  public get error(): boolean {
    return getEnableErrorMessages(this);
  }

}

