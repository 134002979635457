import { isObject } from 'src/app/shared/helpers/objects-equal';

// this is the interface we use in consulta stock
export interface IProductMatch {
  name: string;
  objectID: string;
  uri: string;
  [key: string]: string;
}

// nuevo response de algolia
export interface IProductMatchDetail {
  fullyHighlighted: boolean;
  matchLevel: string;
  matchedWords: string[];
  value: string;
}
export interface IHighlightProductMatch {
  DESCRIPCION_MAESTRO: IProductMatchDetail;
  NOMBRE_FRIENDLY: IProductMatchDetail;
  PRINCIPIO_ACTIVO_1: IProductMatchDetail;
  PRINCIPIO_ACTIVO_2: IProductMatchDetail;
  PRINCIPIO_ACTIVO_3: IProductMatchDetail;
}
export interface ICustomProductMatch {
  CODIGO_INKAFARMA: string;
  CODIGO_MIFARMA: string;
  objectID: string;
  _highlightResult: IHighlightProductMatch;
}

export class ProductMatchDetail {

  public fullyHighlighted: boolean;
  public matchLevel: string;
  public matchedWords: string[];
  public value: string;

  constructor(data: IProductMatchDetail) {
    const current = isObject(data) ? data : {} as IProductMatchDetail;
    this.fullyHighlighted = current.fullyHighlighted || false;
    this.matchLevel = current.matchLevel || '';
    this.matchedWords = current.matchedWords || [];
    this.value = current.value || '';
  }
}
export class HighlightProductMatch {

  public DESCRIPCION_MAESTRO: ProductMatchDetail;
  public NOMBRE_FRIENDLY: ProductMatchDetail;
  public PRINCIPIO_ACTIVO_1: ProductMatchDetail;
  public PRINCIPIO_ACTIVO_2: ProductMatchDetail;
  public PRINCIPIO_ACTIVO_3: ProductMatchDetail;

  constructor(data: IHighlightProductMatch) {
    const current = isObject(data) ? data : {} as IHighlightProductMatch;
    this.DESCRIPCION_MAESTRO = new ProductMatchDetail(current.DESCRIPCION_MAESTRO);
    this.NOMBRE_FRIENDLY = new ProductMatchDetail(current.NOMBRE_FRIENDLY);
    this.PRINCIPIO_ACTIVO_1 = new ProductMatchDetail(current.PRINCIPIO_ACTIVO_1);
    this.PRINCIPIO_ACTIVO_2 = new ProductMatchDetail(current.PRINCIPIO_ACTIVO_2);
    this.PRINCIPIO_ACTIVO_3 = new ProductMatchDetail(current.PRINCIPIO_ACTIVO_3);
  }
}
export class CustomProductMatch {

  public objectID: string;
  public highlightResult: HighlightProductMatch;

  constructor(data: ICustomProductMatch) {
    const current = isObject(data) ? data : {} as ICustomProductMatch;
    this.objectID = current.CODIGO_MIFARMA || '';
    this.highlightResult = new HighlightProductMatch(current._highlightResult);
  }
}


export interface IAlgoliaObjectData {
  sku?: string,
  queryID?: string,
  price?: number,
  discount?: number,
  quantity?: number
}