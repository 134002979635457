// here are the enum parameters. enums allow us to define a set of named constants
import { PAYMENT_GATEWAY } from 'src/app/business/checkout/enum/payment-gateway.enum';

interface IPaymentGatewayMerchant{
  [key: string]: number
}

export enum VERSIONS {
  DESKTOP = "desktop",
  RESPONSIVE = "responsive",
}

export enum CONFIG_USER_NOT_REGISTERED {
  MIN_LENGHT_DNI = 8,
  MIN_LENGHT_NUMBER_PHONE = 9,
  FIRST_RIGHT_NUMBER_NUMBER_PHONE = 9,
  DNI_LENGTH = 8,
  CE_MIN_LENGTH = 9,
  CE_MAX_LENGTH = 12,
  PASSPORT_MIN_LENGTH = 9,
  PASSPORT_MAX_LENGTH = 12,
  MIN_LENGHT_NUMBER_PHONE_ORDER_TAKER = 6,
}

// export enum DELIVERY_TYPE {
//   NONE = 0,
//   DIRECTION = 1,
//   DRUGSTORE = 2,
// }

export enum DELIVERY_TYPE {
  DIRECTION = 0,
  DRUGSTORE = 1,
}

export enum TYPE_CONTROL {
  EMAIL_NORMAL = "normal",
  EMAIL_LOGIN = "login",
}

export enum COMPANY {
  INKAFARMA = 'IKF',
  MIFARMA = 'MF',
  JORSA = 'JORSA'
}

export enum CIA_CODE {
  INKAFARMA = "94",
  MIFARMA = "99",
  JORSA = "96"
}


export enum PAYMENT_METHOD {
  AGORA = 1,
  EPAGO = 2
}

export const MERCHANT_ID_AGORA:IPaymentGatewayMerchant = {
  [CIA_CODE.INKAFARMA]: 6100,
  [CIA_CODE.MIFARMA]: 6200,
  [CIA_CODE.JORSA]: 6110
}

export const MERCHANT_ID_EPAGO:IPaymentGatewayMerchant = {
  [CIA_CODE.INKAFARMA]: 4078668,
  [CIA_CODE.MIFARMA]: 4078666,
  [CIA_CODE.JORSA]: 4079023
}


export const PAYMENT_GATEWAY_CONFIG_MERCHANT_ID = {
  [PAYMENT_METHOD.AGORA]: MERCHANT_ID_AGORA,
  [PAYMENT_METHOD.EPAGO]: MERCHANT_ID_EPAGO
}

export const TERMS_AND_CONDITION_EPAGO = 1;
