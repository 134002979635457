import { Injectable, OnInit } from "@angular/core";
import { LS_CURRENT_ADDRESS, OT_ADDRESS_SELECTED } from "@service/local-storage/constants/address.constant";
import { add } from "date-fns";
import { BehaviorSubject } from "rxjs";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { StorageClientService } from "src/app/shared/storage/storage-client.service";
import { OrderTakerValidator } from "src/app/shared/validators/order-taker.validator";

@Injectable({
  providedIn: "root",
})
export class AddressManagerPersistanceService implements OnInit {
  private _addressToUpdate: AddressItem;
  private sendAddressItemSubject = new BehaviorSubject<AddressItem | null>(
    JSON.parse(this._storageClientService.getStorageItem(OrderTakerValidator.isOrderTakerEnv() ? OT_ADDRESS_SELECTED : LS_CURRENT_ADDRESS)) || null
  );
  public sendAddressItem$ = this.sendAddressItemSubject.asObservable();
  constructor(private _storageClientService: StorageClientService) {
    this.setAddressToUpdate(this.addressToUpdate);
    this.sendAddressItem$.subscribe(
      (address) => (this._addressToUpdate = address)
    );
  }

  ngOnInit(): void {
    const address = JSON.parse(
      this._storageClientService.getStorageItem(OrderTakerValidator.isOrderTakerEnv() ? OT_ADDRESS_SELECTED : LS_CURRENT_ADDRESS)
    ) as AddressItem;

    if (address) {
      this.setAddressToUpdate(address);
    }
  }

  public get addressToUpdate() {
    const addressJson = this._storageClientService.getStorageItem(OrderTakerValidator.isOrderTakerEnv() ? OT_ADDRESS_SELECTED : LS_CURRENT_ADDRESS);
    let address = {} as AddressItem;
    if (addressJson === null || addressJson === "undefined") {
      this._storageClientService.removeStorageItem(OrderTakerValidator.isOrderTakerEnv() ? OT_ADDRESS_SELECTED : LS_CURRENT_ADDRESS);
    }else{
      address = JSON.parse(
        addressJson
      ) as AddressItem;
    }
    this._addressToUpdate = address;
    if (OrderTakerValidator.isOrderTakerEnv() && this._addressToUpdate?.idNumber > 0){
      return this._addressToUpdate;
    }
    return this.sendAddressItemSubject.value ?? this._addressToUpdate;
  }

  get currentAddressUpdate() {
    return this.sendAddressItemSubject.value ?? this._addressToUpdate;
  }

  public setAddressToUpdate(addressItem: AddressItem) {
    this.sendAddressItemSubject.next(addressItem);
  }
  public saveAddressToUpdate() {
    if (OrderTakerValidator.isOrderTakerEnv()) {
      this._storageClientService.removeStorageItem(OT_ADDRESS_SELECTED);
      this._storageClientService.setStorageItem(
        OT_ADDRESS_SELECTED,
        JSON.stringify(this.addressToUpdate)
      );
    }
    this.sendAddressItemSubject.next(this.addressToUpdate);
  }
  public deleteAddressSelected() {
    this.setAddressToUpdate(null);
    this._storageClientService.removeStorageItem(OT_ADDRESS_SELECTED);
  }
}
