import { ShapeClass } from './utils';
import { Injectable } from "@angular/core";

@Injectable()
export class ImageClass {
  'link': string;
  'image': string;
  'title': string;
  'description'?: string;
  'subTitle'?: string;
  'shapeDesktop'?: ShapeClass;
  'shapeResponsive'?: ShapeClass;
}


@Injectable()
export class ImageBasic {
  url: string;
  name?: string;
}
