<span
  *ngIf="iconName || text"
  class="tag-seller-container d-flex align-items-center"
  [class]="customClass"
>
  <fp-icon *ngIf="iconName" [iconSize]="iconSize" [iconHeight]="iconHeight" [iconName]="iconName">
  </fp-icon>
  <span *ngIf="text" class="tag-seller-text">
    {{ text }}
  </span>
</span>
