import {Component, Input} from '@angular/core';

@Component({
  selector: 'fp-static-landing-content',
  templateUrl: './static-landing-content.component.html',
  styleUrls: ['./static-landing-content.component.sass']
})
export class StaticLandingContentComponent {

  @Input() loader = true;
  @Input() content: string;

  constructor() {
  }

}
