import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MenuModule } from "@molecules/menu/menu.module";
import { ButtonsModule } from "../../generic/buttons/buttons.module";
import { GenericModule } from "../../generic/generic.module";
import { IconsModule } from "../../generic/icons/icons.module";
import { FormControlsModule } from "../form-controls/form-controls.module";
import { LoadersModule } from "../loaders/loaders.module";
import { BasicSkeletonModule } from "../skeletons/basic-skeleton/basic-skeleton.module";
import { TagsCheckoutModule } from "../tags-checkout/tags-checkout.module";
import { AddressRadioButtonComponent } from "./address-radio-button/address-radio-button.component";
import { BorderContainerComponent } from "./border-container/border-container.component";
import { BorderedSelectedAddressComponent } from "./bordered-selected-address/bordered-selected-address.component";
import { EmptyElementsComponent } from "./empty-elements/empty-elements.component";
import { ModalCouponsComponent } from "./modal-coupons/modal-coupons.component";
import { ModalErrorCouponsComponent } from "./modal-error-coupons/modal-error-coupons.component";
import { MomentPipe } from "./momentDate";
import { PaymentCardRadioButtonComponent } from "./payment-card-radio-button/payment-card-radio-button.component";
import { SectionContainerTitleCuponComponent } from "./section-container-title-cupon/section-container-title-cupon.component";
import { SectionContainerTitleLoginComponent } from "./section-container-title-login/section-container-title-login.component";
import { SectionContainerTitleComponent } from "./section-container-title/section-container-title.component";
import { SectionContainerComponent } from "./section-container/section-container.component";
import { SelectPaymentRadioButtonComponent } from "./select-payment-radio-button/select-payment-radio-button.component";
import { SelectTimeTypeRadioButtonComponent } from "./select-time-type-radio-button/select-time-type-radio-button.component";
import { SelectedItemBorderedCardComponent } from "./selected-item-bordered-card/selected-item-bordered-card.component";
import { SubSectionContainerComponent } from "./sub-section-container/sub-section-container.component";
import { ModalInvalidCouponComponent } from "./modal-invalid-coupon/modal-invalid-coupon.component";
import { SectionContainerPointsV2Component } from "./section-container-points-v2/section-container-points-v2.component";
import { SectionContainerTitleV2Component } from "./section-container-title-v2/section-container-title-v2.component";
import { SubSectionContainerV2Component } from "./sub-section-container-v2/sub-section-container-v2.component";
import { AddressRadioButtonV2Component } from "./address-radio-button-v2/address-radio-button-v2.component";
import { SelectTimeTypeRadioButtonV2Component } from "./select-time-type-radio-button-v2/select-time-type-radio-button-v2.component";

const COMPONENTS = [
  BorderContainerComponent,
  SectionContainerComponent,
  SectionContainerTitleComponent,
  SubSectionContainerComponent,
  AddressRadioButtonComponent,
  PaymentCardRadioButtonComponent,
  SelectPaymentRadioButtonComponent,
  SelectTimeTypeRadioButtonComponent,
  SectionContainerTitleLoginComponent,
  EmptyElementsComponent,
  BorderedSelectedAddressComponent,
  SelectedItemBorderedCardComponent,
  SectionContainerTitleCuponComponent,
  ModalCouponsComponent,
  ModalErrorCouponsComponent,
  ModalInvalidCouponComponent,

  SectionContainerPointsV2Component,
  SectionContainerTitleV2Component,
  SubSectionContainerV2Component,
  AddressRadioButtonV2Component,
  SelectTimeTypeRadioButtonV2Component,
];

@NgModule({
  declarations: [...COMPONENTS, MomentPipe],
  imports: [
    CommonModule,
    MatMenuModule,
    GenericModule,
    MatExpansionModule,
    ButtonsModule,
    IconsModule,
    FormControlsModule,
    LoadersModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    TagsCheckoutModule,
    MatProgressBarModule,
    BasicSkeletonModule,
    MenuModule,
  ],
  exports: [...COMPONENTS],
})
export class ContainersModule {}
