<div class="row product-cart align-items-center"
  [ngClass]="{'color-warning': productStatus === 23 || productStatus === 24 }"
  [attr.data-product-brand]="product?.brand " [attr.data-product-category]="product?.category ">
  <div class="col-4">
    <fp-link [linkRouter]="['/'+productPath, product.slug, product.id]" linkId="link--go-to-product-detail">
      <fp-product-information-cart [prescriptionCode]="product?.prescriptionCode" [image]="productPresentation?.image" [name]="product.name" [prescription]="product.prescription"
        [description]="productPresentation?.description" [productStatus]="productStatus" (click)="nameProduct()">
      </fp-product-information-cart>
    </fp-link>
  </div>

  <div class="col-2">
    <fp-product-presentation presentationTemplate="select" [presentationList]="product.presentations"
      [presentationSelected]="product.presentationIdSelected" [productStatus]="productStatus"
      (updatePresentation)="setProductPresentation($event)">
    </fp-product-presentation>
  </div>

  <div class="col-2">
    <fp-product-quantity [productQuantity]="product.quantity" [quantityLimit]="productPresentation?.quantityLimit"
      [rounded]="true"
      [productStatus]="productStatus" [product]="product" [isSeller]="product.isFromSeller"
      (updateQuantity)="setQuantity($event)">
    </fp-product-quantity>
  </div>

  <div class="col-2">
    <fp-product-cart-price-mifa *fpBrandAccess="[brand.mifarma]"
      [subTotal]="product.subTotalAllPaymentMethod ? product.subTotalAllPaymentMethod : 0"
      [subTotalOffer]="product.subTotalWithPaymentMethod ? product.subTotalWithPaymentMethod : 0"
      [productStatus]="productStatus" [regularPrice]="product.subTotal ? product.subTotal : 0">
    </fp-product-cart-price-mifa>
    <fp-product-cart-price *fpBrandAccess="[brand.inkafarma]"
      [subTotal]="product.subTotalAllPaymentMethod ? product.subTotalAllPaymentMethod : 0"
      [subTotalOffer]="product.subTotalWithPaymentMethod ? product.subTotalWithPaymentMethod : 0"
      [productStatus]="productStatus" [regularPrice]="product.subTotal ? product.subTotal : 0">
    </fp-product-cart-price>
  </div>

  <div class="col-2 text-center">
    <fp-button-ghost buttonId="btn--delete-product" buttonSize="small" buttonColor="red" buttonDisplay="block"
      (click)="deleteProduct()">
      Eliminar
    </fp-button-ghost>
  </div>
</div>
