import { isObject } from "../../helpers/objects-equal";

// -- begin - user otp request
export interface IUserOTP {
  otp?: string;
  emailAddress?: string;
  phoneNumber?: string;
  otpEmailEncrypted?: string;
}

export interface IUserOTPRequestParams extends IUserOTP {
  other?: string;
}

export class UserOTPRequestParams {
  otp?: string;
  emailAddress?: string;
  phoneNumber?: string;
  otpEmailEncrypted?: string;
}
// -- end - user otp request

// -- begin - update password request
export interface IUpdatePasswordRequestParams {
  password: string;
  newPassword: string;
  uid: string;
}

// -- end - update password request

export interface IResponse {
  code: string;
  message: string;
  data: string;
}

export class Response {
  code: string;
  message: string;
  data: string;

  constructor(response: IResponse) {
    this.code = response.code || "";
    this.message = response.message || "";
    this.data = response.data || "";
  }
}

export interface IInkaclubRegistrationStatus {
  code: string;
  status: string;
  description: string;
}

// ----- begin - register of user
export interface IUserRegister {
  identityDocumentType: number;
  identityDocumentNumber: string;
  paternalLastName: string;
  maternalLastName: string;
  dni: string;
  phone: string;
  email: string;
  name: string;
  lastName: string;
  birthDate: string;
  sexId: number;
  isAnonymous: boolean;
  flagAuditTerms: boolean;
  flagDataTreatment: boolean;
  flagPrivacyPolicy: boolean;
  flagTermsAndConditions: boolean;
  companyId: string;
  authId: string;
  photo: string;
  photoExtension: string;
  photoUrl: string;
  id: string;
  source: string;
  emailOnConflict: string;
  uids: string;
  uid: string;
  customToken: string;
  authUserId: string;
  encryptedDni: string;
  encryptedEmail: string;
  origin: number;
  inkaclubRegistrationStatus: IInkaclubRegistrationStatus;
}

export interface IUserRegisterRequestParams extends IUserRegister {
  other?: string;
}

export class UserRegister {
  identityDocumentType: number;
  identityDocumentNumber: string;
  paternalLastName: string;
  maternalLastName: string;
  dni: string;
  phone: string;
  email: string;
  name: string;
  lastName: string;
  birthDate: string;
  sexId: number;
  isAnonymous: boolean;
  flagAuditTerms: boolean;
  flagDataTreatment: boolean;
  flagPrivacyPolicy: boolean;
  flagTermsAndConditions: boolean;
  companyId: string;
  authId: string;
  photo: string;
  photoExtension: string;
  photoUrl: string;
  id: string;
  source: string;
  emailOnConflict: string;
  uids: string;
  uid: string;
  customToken: string;
  authUserId: string;
  encryptedDni: string;
  encryptedEmail: string;
  origin: number;
  inkaclubRegistrationStatus: IInkaclubRegistrationStatus;

  constructor(user: IUserRegister) {
    const currentRegisterInfo = isObject(user) ? user : ({} as IUserRegister);
    this.identityDocumentType = currentRegisterInfo.identityDocumentType;
    this.identityDocumentNumber = currentRegisterInfo.identityDocumentNumber;
    this.paternalLastName = currentRegisterInfo.paternalLastName;
    this.maternalLastName = currentRegisterInfo.maternalLastName;

    this.dni = currentRegisterInfo.dni || "";
    this.phone = currentRegisterInfo.phone || "";
    this.email = currentRegisterInfo.email || "";
    this.name = currentRegisterInfo.name || "";
    this.lastName = currentRegisterInfo.lastName || "";
    this.birthDate = currentRegisterInfo.birthDate || "";
    this.sexId = currentRegisterInfo.sexId || 1;
    this.isAnonymous = currentRegisterInfo.isAnonymous || false;
    this.flagAuditTerms = currentRegisterInfo.flagAuditTerms || false;
    this.flagDataTreatment = currentRegisterInfo.flagDataTreatment || false;
    this.flagPrivacyPolicy = currentRegisterInfo.flagPrivacyPolicy || false;
    this.flagTermsAndConditions =
      currentRegisterInfo.flagTermsAndConditions || false;
    this.companyId = currentRegisterInfo.companyId || "";
    this.authId = currentRegisterInfo.authId || "";
    this.photo = currentRegisterInfo.photo || "";
    this.photoExtension = currentRegisterInfo.photoExtension || "";
    this.photoUrl = currentRegisterInfo.photoUrl || "";
    this.id = currentRegisterInfo.id || "";
    this.source = currentRegisterInfo.source || "";
    this.emailOnConflict = currentRegisterInfo.emailOnConflict || "";
    this.uids = currentRegisterInfo.uids || "";
    this.uid = currentRegisterInfo.uid || "";
    this.customToken = currentRegisterInfo.customToken || "";
    this.authUserId = currentRegisterInfo.authUserId || "";
    this.encryptedDni = currentRegisterInfo.encryptedDni || "";
    this.encryptedEmail = currentRegisterInfo.encryptedEmail || "";
    this.inkaclubRegistrationStatus =
      currentRegisterInfo.inkaclubRegistrationStatus ||
      ({} as IInkaclubRegistrationStatus);
  }
}

export interface IRegisterResponse {
  code: string;
  message: string;
  data: IUserRegister | string;
}

export class RegisterResponse {
  code: string;
  message: string;
  data: UserRegister | string;

  constructor(user: IRegisterResponse) {
    const currentRegister = isObject(user) ? user : ({} as IRegisterResponse);
    this.code = currentRegister.code || "";
    this.message = currentRegister.message || "";
    this.data = currentRegister.data ? currentRegister.data : null;
  }
}
// ----- end - register of user

// ----- begin - valdiate token

export interface IValidateToken {
  valid: boolean;
  uid: string;
  email: string;
}

export class ValidateToken {
  valid: boolean;
  uid: string;
  email: string;

  constructor(iValidateToken: IValidateToken) {
    const iValidateTokenConst = isObject(iValidateToken)
      ? iValidateToken
      : ({} as IValidateToken);
    this.valid = iValidateTokenConst.valid || false;
    this.uid = iValidateTokenConst.uid || "";
    this.email = iValidateTokenConst.email || "";
  }
}

export interface IValidateTokenResponse {
  code: string;
  message: string;
  data: IValidateToken | string;
}

export class ValidateTokenResponse {
  code: string;
  message: string;
  data: ValidateToken | string;

  constructor(validToken: IValidateTokenResponse) {
    const validTokenConst = isObject(validToken)
      ? validToken
      : ({} as IValidateTokenResponse);
    this.code = validTokenConst.code || "";
    this.message = validTokenConst.message || "";
    this.data =
      this.code === "1"
        ? new ValidateToken(validTokenConst.data as IValidateToken)
        : (validTokenConst.data as string);
  }
}
// ----- end - valdiate token

export class IUpdateUserEmail {
  email: string;
}

export class UpdateUserEmail {
  email: string;

  constructor(userEmail: IUpdateUserEmail) {
    const currentUpdateUserEmail = isObject(userEmail)
      ? userEmail
      : ({} as IUpdateUserEmail);
    this.email = currentUpdateUserEmail.email || "";
  }
}

export interface IUpdateUserEmailResponse {
  code: string;
  message: string;
  data: UpdateUserEmail | string;
}

export class UpdateUserEmailResponse {
  code: string;
  message: string;
  data: UpdateUserEmail | string;

  constructor(user: IUpdateUserEmailResponse) {
    const currentRegister = isObject(user)
      ? user
      : ({} as IUpdateUserEmailResponse);
    this.code = currentRegister.code || "";
    this.message = currentRegister.message || "";
    this.data =
      this.code === "1"
        ? new UpdateUserEmail(currentRegister.data as IUpdateUserEmail)
        : (currentRegister.data as string);
  }
}

export enum ECurrentUserStatus {
  PENDING,
  LOADING,
  VALID_RESPONSE,
}

export interface ICurrentUser {
  status: ECurrentUserStatus;
  userResponse: IRegisterResponse;
}
