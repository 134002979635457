import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '../../parameters/endpoints';
import { GenericService } from '../generic';
import { ScheduleInit } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class CheckoutClientService {

  constructor(private _GenericService: GenericService) {
  }

  public getScheduleDays(): Observable<ScheduleInit> {
    return this._GenericService.genericGetStaticFile(ENDPOINTS.GET_SCHEDULE_DAY);
  }

  public getScheduleHours(): Observable<ScheduleInit> {
    return this._GenericService.genericGetStaticFile(ENDPOINTS.GET_SCHEDULE_HOUR);
  }
}
