import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "fp-chip",
  templateUrl: "./chip.component.html",
  styleUrls: ["./chip.component.sass"],
})
export class ChipComponent implements OnInit {
  @Input()
  text: string = "";

  @Input()
  status: "success" | "error" | "warning" | "purple" | "blue" = null;

  @Input()
  iconName: string = "";

  @Input()
  iconSize: 8 | 12 | number = 12;

  @Input()
  size: "normal" | "small" = "normal";

  @Input()
  disabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
