import { Injectable } from '@angular/core';
import { RegisterClientService } from 'src/app/shared/service/user-register/register-client.service';
import { RegisterResponse } from 'src/app/shared/models/register/register.model';
import { Observable } from 'rxjs';
import { UserRegisterRequest, EmailPhoneVerificationRequest } from 'src/app/shared/models/register/user-register-request.model';



@Injectable()
export class NewRegisterImplementService {

  constructor(
    private _registerClientService: RegisterClientService
  ) { }

  public getUser(): Observable<RegisterResponse> {
    return this._registerClientService.getUser$();
  }

  public saveOrUpdateUser(request: UserRegisterRequest | EmailPhoneVerificationRequest, userUpdate: boolean = false, isMission: boolean = false, missionCode: string = ''): Observable<RegisterResponse> {
    return this._registerClientService.saveOrUpdateUser$(request, userUpdate, isMission, missionCode);
  }

}

