import { ContentfulEntry } from './../../../shared/models/contentful/contentful.model';
import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Entry } from 'contentful';
import { ContentfulShortcut, IContentfulShortcut } from './contentful-shortcut.model';

export interface IContentfulInkaclubSorteos {
    title: string;
    description: string;
    flag: string;
    items: Array<Entry<IContentfulShortcut>>;
}

export class ContentfulInkaclubSorteos {
    title: string;
    description: string;
    flag: string;
    items: Array<ContentfulShortcut>;

    constructor(iContentfulInkaclubSorteos: IContentfulInkaclubSorteos) {
        this.title = iContentfulInkaclubSorteos.title;
        this.description = iContentfulInkaclubSorteos.description;
        this.flag = iContentfulInkaclubSorteos.flag;
        this.items = iContentfulInkaclubSorteos.items ? iContentfulInkaclubSorteos.items
            .map(item => new ContentfulShortcut(new ContentfulEntry(item).content)) : [];
    }
}

export const CContentfulInkaclubSorteos = {
    id: CONTENTFUL_PATH.contentTypeIds.inkaclubSorteos
};

