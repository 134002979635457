<fp-button
  buttonClass="btn-primary weight-600
  btn-spacer-{{buttonSize}}
  label-black{{buttonSize === 'small' ? '--1': buttonSize === 'large' ? '-1' : ''}}
  btn-{{buttonDisplay}}
  {{buttonDisabled ? 'btn-disabled': ''}}
  {{buttonClass}}"
  [buttonId]="buttonId"
  [buttonType]="buttonType"
  [buttonDisabled]="buttonDisabled"
  [buttonStyle]="buttonStyle"
  [buttonId]="buttonId">
  <ng-content></ng-content>
</fp-button>
