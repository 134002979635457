// tslint:disable-next-line:class-name
export class CONTENTFUL_QUERIES {
	public getEntriesByIds(ids: Array<string>) {
		return {
			"sys.id[in]": ids.toString(),
			select: "fields",
			order: "sys.contentType.sys.id,sys.id",
			include: 10
		};
	}

	public getEntryBySlug(slug: string) {
		return {
			"fields.slug": slug,
			include: 10
		};
	}

	public getEntryBySection(section: string) {
		return {
			"fields.section": section,
			limit: 1,
			include: 10
		};
	}

	public getEntryBySumary(sumary: string) {
		return {
			"fields.sumary": sumary,
			limit: 1,
			include: 10
		};
	}

	public getEntryBySectionDynamicLanding(section: string, slug: string) {
		return {
			"fields.slug": slug,
			"fields.section": section,
			limit: 1,
			include: 10
		};
	}

	public getFirstEntryOfArray() {
		return {
			limit: 1,
			include: 10
		};
	}

	public getEntryArrayByTag(tag: string) {
		return {
			"fields.tag.sys.id[in]": tag,
			include: 10
		};
	}

	public getEntryArrayByAllTag(tags: string, limit?: number) {
		return {
			"fields.tag.sys.id[in]": tags,
			order: "-fields.publicationDate",
			include: 10,
			limit: limit || 9
		};
	}

	/**
	 * this is mainly used to get any "Configuration" content type
	 */
	public queryToGetCheckoutConfigurationByConfigId(configId: string) {
		return {
			"fields.configId": configId,
			include: 10
		};
	}

	public queryToGetConfigurationByConfigId(configId: string) {
		return {
			"fields.configId": configId,
			include: 10
		};
	}
}
