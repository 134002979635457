<div class="modal fade" id="ingresaDNI" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row">
          <div class="col-md-6">
            <h5 class="modal-title" id="exampleModalLabel">Registro exitoso</h5>
          </div>
          <div class="col-md-6">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body text-center">
        <div class="modal-img">
          <img alt="corona" src="//images.ctfassets.net/l9x8e72nkkav/6OLD6Ir1JvoSjuQUkiDYBf/c927e3e23648b1cc0523e354bff38f87/corona.png" />
        </div>
        <div style="margin: 0 auto; text-align: center">
          Gracias por registrar tus datos. Conoce más promociones en
          <a href="https://www.mifarma.com.pe/" target="_blank">Mifarma.com.pe</a>
        </div>
      </div>
    </div>
  </div>
</div>
