
<div class="address-list-modal">
    <div class="text-title">¿Cómo entregaremos tu pedido?</div>
    <fp-sub-section-container>
      <div class="content position-section-change-ret-rad">
        <div class="row">
            <div class="col-12">
                <fp-component-change-ret-rad [product]="data.product" [change]="data.change">
                </fp-component-change-ret-rad>
            </div>
        </div>
      </div>
    </fp-sub-section-container>
  </div>
