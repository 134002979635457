import { Injectable } from '@angular/core';
import { ENDPOINTS } from '../../parameters/endpoints';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GenericService } from '../generic';
import { IRegisterResponse, RegisterResponse, } from '../../models/register/register.model';
import { map } from 'rxjs/operators';
import { AuthImplementService } from '../../implements/auth/auth-implement.service';
import { TaggingService } from 'src/app/atm-services/tagging.service';
import { UserInfoStoreService } from '@stores/user/user-info-store.service';

@Injectable({
  providedIn: 'root',
})
export class UserClientService {
  constructor(
    private _GenericService: GenericService,
    private authImplementService: AuthImplementService,
    // NO DEBERIA ESTAR ACÁ!!!
    private userStoreService: UserInfoStoreService
  ) {
  }

  public getUser$(): Observable<RegisterResponse> {
    const uid = this.authImplementService.currentUID;
    return this._GenericService.genericGetWithoutSecurity<IRegisterResponse>(`${ ENDPOINTS.USER_SERVICE }/${ uid }`)
      .pipe(map(response => {
        const registerResponse = new RegisterResponse(response);
        this.userStoreService.lastUserData = registerResponse;
        TaggingService.saveData(registerResponse);
        return registerResponse;
      }));
  }
}
