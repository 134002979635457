export class ProductShoppingCartSalesforceRequest {
  public item: string;
  public unique_id: string;
  public quantity: number;
  public price: number;
}

export class FromProductToShoppingCartSalesforceRequest extends ProductShoppingCartSalesforceRequest {
  constructor(
    item: string,
    unique_id: string,
    quantity: number,
    price: number
  ) {
    super();
    this.item = item || '';
    this.unique_id = unique_id || '';
    this.quantity = quantity || 0;
    this.price = price || 0;
  }
}
