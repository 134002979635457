import { Injectable } from '@angular/core';
import { MenuCategoriesImplementService } from './menu-categories-implement.service';
import { Department } from 'src/app/shared/models/menu-categories/department.model';
import { StoreDepartment, StoreCategory, StoreSubCategory, StoreBrand } from '../models/category-list-store.model';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class CategoryListStoreService {

  private departmentList: Department[] = [];
  private loadedDataSubject = new Subject<boolean>();

  private selectedDepartmentId: number;

  constructor(
    private menuCategoriesImplement: MenuCategoriesImplementService,
  ) {
  }

  public get isLoadedData$(): Observable<boolean> {
    return this.loadedDataSubject.asObservable();
  }

  public set isLoadedData(status: boolean) {
    this.loadedDataSubject.next(status);
  }

  // to do: this method is activated for 2 modules (category result module and header)
  public loadAllMenuCategories() {
    if (!this.departmentList.length) {
      this.menuCategoriesImplement.getAllMenuCategories()
        .pipe(take(1))
        .subscribe((departments) => {
          this.departmentList = departments;
          this.isLoadedData = true;
        });
    } else {
      this.isLoadedData = true;
    }
  }

  // get array of departments ids
  public getDepartmentList() {
    const currentDepartmentList = this.departmentList.map((department) => {
      return {
        id: department.departmentId,
        name: department.departmentName,
        keyword: department.departmentKeyword || 'nuko',
        href: (department.departmentKeyword || 'nukpo')
      } as StoreDepartment;
    });
    return currentDepartmentList;
  }

  // GET CATEGORY LIST
  public getCategoryList(departmentId: number) {
    this.selectedDepartmentId = departmentId;
    const currentDepartment = this.getCurrentDepartment(departmentId);
    const currentCategoryList = currentDepartment.categories.map((category) => {
      return {
        id: category.categoryId,
        name: category.categoryName,
        keyword: category.categoryKeyword,
        hasSubCategories: category.subCategories.length > 0,
        departmentKeyword: currentDepartment.departmentKeyword

      } as StoreCategory;
    });
    return currentCategoryList;
  }

  // is necessary set the selectedDepartmentId before to work this method (see getCategoryList)

  // GET SUBCATEGORY LIST
  public getSubCategoryListByDepartment(departmentId: number) {
    const map = new Map();
    const subcategoriesByDeparment = [];
    let allSubCategories = [];

    this.selectedDepartmentId = departmentId;
    const currentDepartment = this.getCurrentDepartment(departmentId);
    currentDepartment.categories.forEach((category) => {
      allSubCategories = [...allSubCategories, ...category.subCategories];
    });

    allSubCategories.forEach((subcategory) => {
      if (!map.has(subcategory.subCategoryId)) {
        map.set(subcategory.subCategoryId, true);
        subcategoriesByDeparment.push(subcategory);
      }
    });
    return subcategoriesByDeparment;
  }

  public getSubCategoryListByCategory(categoryId: number) {
    const currentCategory = this.getCurrentCategory(categoryId);
    const currentDepartment = this.getDepartmentByCategory(currentCategory.categoryId);
    const currentSubCategoryList = currentCategory.subCategories.map((subcat) => {
      return {
        id: subcat.subCategoryId,
        name: subcat.subCategoryName,
        keyword: subcat.subCategoryKeyword,
        departmentKeyword: currentDepartment.departmentKeyword,
        categoryKeyword: currentCategory.categoryKeyword
      } as StoreSubCategory;
    });
    return currentSubCategoryList;
  }

  // GET BRAND LIST

  public getBrandListByDepartment(departmentId: number) {
    const map = new Map();
    const brandListByDeparment = [];
    let allBrands = [];

    this.selectedDepartmentId = departmentId;
    const currentDepartment = this.getCurrentDepartment(departmentId);
    currentDepartment.categories.forEach((category) => {
      allBrands = [...allBrands, ...category.brands];
    });

    allBrands.forEach((brand) => {
      if (!map.has(brand.brandId)) {
        map.set(brand.brandId, true);
        brandListByDeparment.push(brand);
      }
    });
    return brandListByDeparment;
  }

  public getBrandListByCategory(categoryId: number) {
    const currentCategory = this.getCurrentCategory(categoryId);
    const currentBrandList = currentCategory.brands.map((brand) => {
      return {
        id: brand.brandId,
        name: brand.brandName,
        src: brand.brandImage,
      } as StoreBrand;
    });
    return currentBrandList;
  }

  public getBrandListBySubcategory(categoryId: number, subcategoryId: number) {
    const currentCategory = this.getCurrentCategory(categoryId);
    const currentSubCategoryList = currentCategory.subCategories
      .filter((subcategory) => subcategory.subCategoryId = subcategoryId)
      .map((subcategory) => subcategory);
    return currentSubCategoryList;
  }

  // ************

  public getDepartmentByCategory(categoryId: number) {
    const currentDepartment = this.departmentList.find((department) => {
      return department.categories.find((category) => category.categoryId === categoryId);
    });
    return currentDepartment;
  }

  // get current element
  public getCurrentDepartment(departmentId: number) {
    const currentDepartment = this.departmentList.find((department) => department.departmentId === departmentId);
    return currentDepartment;
  }

  public getCurrentCategory(categoryId: number) {
    const currentDepartment = this.getCurrentDepartment(this.selectedDepartmentId);
    return currentDepartment.categories.find((category) => category.categoryId === categoryId);
  }

  // get element by keywords
  public getDepartmentByKeyword(departmentKeyword: string) {
    const currentDepartment = this.departmentList.find((department) => department.departmentKeyword === departmentKeyword);
    this.selectedDepartmentId = currentDepartment.departmentId;
    return currentDepartment;
  }

  public getCategoryByKeyword(departmentKeyword: string, categoryKeyword: string) {
    const department = this.getDepartmentByKeyword(departmentKeyword);
    const currentCategory = department.categories.find((category) => category.categoryKeyword === categoryKeyword);
    return currentCategory;
  }

  public getSubCategoryByKeyword(departmentKeyword: string, categoryKeyword: string, subCategoryKeyword: string) {
    const category = this.getCategoryByKeyword(departmentKeyword, categoryKeyword);
    const currentSubCategory = category.subCategories.find((subCategory) => subCategory.subCategoryKeyword === subCategoryKeyword);
    return currentSubCategory;
  }

}
