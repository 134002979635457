<div
  class="display-center mt-0 mb-0 m-auto"
  [class.box-spacing]="boxSpacing"
  [ngClass]="boxClass"
  [style.height]="boxSize"
  [style.max-width]="boxSize">
  <fp-lazy-wrapper [lazy]="imageSrc">
    <img #fpImg
      [alt]="imageAlt"
      [ngClass]="imageClass"/>
  </fp-lazy-wrapper>
</div>
