import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CartControlledFormModalComponent } from "../components/cart-controlled-form-modal/cart-controlled-form-modal.component";
import { CartControlledProductsModalComponent } from "../components/cart-controlled-products-modal/cart-controlled-products-modal.component";

@Injectable({
	providedIn: "root"
})
export class CartcontrolledModalService {
	private cartControlledfromModalRef: MatDialogRef<CartControlledFormModalComponent>;
	private cartControlledModalRef: MatDialogRef<CartControlledProductsModalComponent>;

	constructor(private matDialog: MatDialog) {}
	public openCartControlledFormModal() {
		this.cartControlledfromModalRef = this.matDialog.open(CartControlledFormModalComponent, {
			width: "640px",
			height: "694px",
			disableClose: true,
			panelClass: "custom-full-all-modal-overlay"
		});
		return this.cartControlledfromModalRef;
	}
	public closeCartControlledFormModal() {
		this.cartControlledfromModalRef.close();
	}

	public openCartControlledModalModal() {
		this.cartControlledModalRef = this.matDialog.open(CartControlledProductsModalComponent, {
			width: "640px",
			disableClose: true,
			panelClass: "custom-full-all-modal-overlay"
		});
		return this.cartControlledModalRef;
	}
	public closeCartControlledModalModal() {
		this.cartControlledModalRef.close();
	}
}
