export class AddressRequest {
  idClient: string;
  idAddress?: string;
  addressDescription: string;
  referenceAddressDescription: string;
  latitude: number;
  longitude: number;
  lote?: string;
  mz?: string;
  district?: string;
  number?: string;
  typeAddress?: string;
  addressType?: string;
  ownerName: string;
  isPrincipal?: string;
}
