import { Injectable } from '@angular/core';
import { GoogleMapConfigClientService } from 'src/app/shared/service/google-map/google-map-config-client.service';

@Injectable()
export class GoogleMapConfigImplementService {

  constructor(
    private googleMapConfigClient: GoogleMapConfigClientService,
  ) { }

  public getGoogleMapConfig$() {
    return this.googleMapConfigClient.getGoogleMapConfig$();
  }
}
