<ng-container [ngSwitch]="state">
  <div *ngSwitchCase="states.NOT_FOUND_RET" class="warning-message">
    <fp-icon iconName="alert-light" iconSize="24" fontColor="#FFAB00"></fp-icon>
    <span
      >El servicio de Retiro en tienda no se encuentra disponible para esta
      dirección. <br />
      <span class="warning-message__link-button" (click)="onClick()"
        >Cambiar a la botica más cercana</span
      >
      con retiro en tienda.</span
    >
  </div>
  <div *ngSwitchCase="states.NOT_FOUND_DELIVERY" class="warning-message">
    <fp-icon iconName="alert-light" iconSize="24" fontColor="#FFAB00"></fp-icon>
    <span
      >No contamos con el servicio de delivery para la dirección del cliente.
      Evalúa la botica de despacho para un Retiro en tienda.</span
    >
  </div>
  <div *ngSwitchCase="states.NOT_FOUND_COVERAGE" class="warning-error">
    <fp-icon iconName="alert-error" iconSize="24" fontColor="#FF7452"></fp-icon>
    <span
      >La dirección ingresada no cuenta con cobertura para delivery. Evalúa la
      botica de despacho para Retiro en tienda o cambia de dirección.</span
    >
  </div>
</ng-container>
