<ng-container *ngFor="let snackbarConfig of snackbarsConfig">
  <div class="row align-content-between h-100 mt-3">
    <div class="col-12">
      <div class="row">
        <div class="col mr-3 pr-0 icon-container">
          <ng-container [ngSwitch]="snackbarConfig.type">
            <span *ngSwitchCase="'success'" class="icon icon-24-check"></span>
            <span *ngSwitchCase="'info'" class="icon icon-24-warning"></span>
            <span *ngSwitchCase="'error'" class="icon icon-24-danger"></span>
          </ng-container>
        </div>
        <div class="col pl-0">
          <span class="text text-black" [innerHTML]="snackbarConfig?.message"></span>
        </div>

      </div>
    </div>

    <div class="col-12 d-flex justify-content-end mt-2">
      <span
        (click)="dismiss()"
        class="label-black text-secondary mr-3 cursor-pointer">{{snackbarConfig?.action || 'Ok'}}</span>
    </div>
  </div>
</ng-container>
