import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarToastComponent } from './snackbar-toast/snackbar-toast.component';
import { Injectable } from '@angular/core';
import { SnackbarAlertComponent } from './snackbar-alert/snackbar-alert.component';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { ComponentType } from '@angular/cdk/portal';
import { IUnitSnackbarConfig } from './unit-snackbar-config.interface';
import { SnackbarAlertInComingComponent } from './snackbar-alert-incoming/snackbar-alert-incoming.component';

@Injectable()
export class SnackbarService {

  constructor(private _matSnackBar: MatSnackBar) {
  }

  public get matSnackbar() {
    return this._matSnackBar;
  }

  public snackBarComponent(component: ComponentType<any>, config: MatSnackBarConfig<IUnitSnackbarConfig[]> = {}) {
    const { matSnackbar } = this;
    return matSnackbar.openFromComponent(component, config);
  }

  public snackBarToast(config: MatSnackBarConfig) {
    config.panelClass = `${config.panelClass}-toast-snackbar`;
    return this.snackBarComponent(SnackbarToastComponent, config);
  }

  public snackBarAlert(config: MatSnackBarConfig) {
    config.panelClass = `${config.panelClass}-alert-snackbar`;
    return this.snackBarComponent(SnackbarAlertComponent, config);
  }

  public showSnackbars(config: MatSnackBarConfig<IUnitSnackbarConfig[]>) {
    config.panelClass = `${config.panelClass}-alert-snackbar`;
    return this.snackBarComponent(SnackbarAlertComponent, config);
  }
  public snackBarAlertInComing(config: MatSnackBarConfig) {
    config.panelClass = `${config.panelClass}-snackbar-misiones`;
    return this.snackBarComponent(SnackbarAlertInComingComponent, config);
  }
}
