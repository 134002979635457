import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BREAKPOINTS } from '@parameters/global';
import { Subscription } from 'rxjs';
import { BreakpointBehavior } from 'src/app/shared/behaviors';
import { CheckoutPaymentMethodFormService } from '../../../../checkout-forms/checkout-payment-method-form.service';


@Component({
  selector: 'fp-checkout-error-coupon-modal-inka',
  templateUrl: './checkout-error-coupon-modal.component.html',
  styleUrls: ['./checkout-error-coupon-modal.component.sass']
})
export class CheckoutErrorCouponModalInkaComponent implements OnInit {
  public coupon : string;
  public isBigScreen: boolean;
  public isBigScreenSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<CheckoutErrorCouponModalInkaComponent>,
    public paymentMethodForm: CheckoutPaymentMethodFormService,
    private _breakpointBehavior: BreakpointBehavior,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.isBigScreenSubscription = _breakpointBehavior
      .mediaBreakpointUpObserver(BREAKPOINTS.xl)
      .subscribe(value => this.isBigScreen = value.matches);
  }

  ngOnInit() {
      this.coupon = this.data.coupon;
  }

  closeModal() {
    this.paymentMethodForm.setEnabled.emit(false);

    this.dialogRef.close();
  }

}
