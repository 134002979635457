<div class="content">
  <div class="row">
    <div class="col-12 display-center">
      <fp-hexagon
        hexagonSize="86">
      </fp-hexagon>
    </div>

    <div class="col-10 offset-1 col-md-8 offset-md-2 col-xl-6 offset-xl-3 mt-3">
      <fp-basic-skeleton height="22">
      </fp-basic-skeleton>
    </div>
  </div>
</div>
