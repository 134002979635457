import { FormControl, Validators } from '@angular/forms';

export class AddressItemControl extends FormControl {

  constructor() {
    super();
    this.settingNameValidators();
  }

  private settingNameValidators() {
    this.setValidators([
      Validators.required
    ]);
  }

}
