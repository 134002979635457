import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CheckoutPaymentMethodFormService } from 'src/app/business/checkout/checkout-forms/checkout-payment-method-form.service';

@Component({
  selector: 'fp-tag-filter',
  templateUrl: './tag-filter-selected-desktop.component.html',
  styleUrls: ['./tag-filter-selected-desktop.component.sass']
})
export class TagFilterSelectedDesktopComponent implements OnInit {

  @Input() listSelected: Array<string> = [];

  constructor(private deleteTags:CheckoutPaymentMethodFormService) {
  }

  ngOnInit() {
  }

  deleteTag(category){
    this.deleteTags.deletetag.emit(category);
  }

}
