import { FIRST_OPTION_ID, LAST_OPTION_ID } from './pick-up-config.parameter';
import { UserPickUp } from 'src/app/shared/models/pickup-store/pickup-store.model';

export const FIRST_OPTION = {
  id: FIRST_OPTION_ID,
  default: true,
  email: '',
  fullName: 'Yo',
  identityDocumenType: 0,
  identityDocumentNumber: '',
  value: 'Yo',
} as UserPickUp;

export const LAST_OPTION = {
  id: LAST_OPTION_ID,
  default: false,
  email: '',
  fullName: 'Agregar otra persona',
  identityDocumenType: 0,
  identityDocumentNumber: '',
  value: 'Agregar otra persona',
} as UserPickUp;
