<ng-container [ngSwitch]="display">
  <ng-container *ngIf="offerPrice">
    <div *ngSwitchCase="'inline'" class="d-flex align-items-end" [class.flex-row-reverse]="iconDirection === 'right'"
    [class.justify-content-start]="direction === iconDirection"
    [class.justify-content-end]="direction !== iconDirection">
    <span *ngIf="offerPrice" class="icon icon-14-tarjeta-oh-mini" [class.mr-2]="iconDirection === 'left'"
      [class.ml-2]="iconDirection === 'right'"></span>
    <span class="label-black-1">
      {{offerPrice | currency: 'S/ '}}
    </span>
  </div>
  <div class="offer-price-stl justify-content-end" *ngSwitchCase="'block'">
    <ng-container *ngIf="icon">
      <img class="icon-card mr-1"
        *ngFor="let image of _globalProductCardService.paymentMethodCard.imageArray;first as isFirst" [src]="image" />
    </ng-container>

    <p class="mb-0 mt-1 label-black{{fontSize}}">
      {{offerPrice | currency: 'S/ '}}
    </p>
  </div>
  </ng-container>
</ng-container>
