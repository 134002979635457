<ng-container *ngIf="hasAddress">
    <div class="container location-selected-container-2" [ngStyle]="{'z-index': ( false | async) === false && 1}" [ngClass]="{'location-selected-container': false}">
        <div class="row d-flex align-items-center h-100">
          <div class="col-xs-1 col-sm-1 col-lg-1">
            <fp-icon iconSize="20" iconName="icon-camion" *ngIf="!drugstore"></fp-icon>
            <fp-icon iconSize="20" iconName="icon-ret" *ngIf="drugstore"></fp-icon>
          </div>
        <div class="col-xs-8 col-sm-8 col-lg-8">
            <div class="content-titles">
                <div class="label--1 text-inf text-despacho">
                    {{ drugstore ? 'Retiro en tienda' : 'Despacho a domicilio' }}
                </div>
                <div class="label-black location-content">
                  <div class="location" *ngIf="!drugstore && isAddressSelected()">{{  selectedAddress?.id ? selectedAddress.street + ', ' + selectedAddress.district : 'Ingresa tu dirección' }}</div>
                  <div class="location" *ngIf="drugstore">{{selectDrugstore ? 'Botica ' + drugstoreName : 'Seleccione una botica'}}</div>
                  <div class="location" *ngIf="!drugstore && !isAddressSelected()">Despacho a domicilio</div>
                </div>
            </div>
        </div>
        <div class="col-xs-3 col-sm-3 col-lg-3">
            <div class="button-change" (click)="changeRetRad(true)">Cambiar</div>
        </div>
        </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!hasAddress">
    <div class="main-header location-selected-container-2 d-flex align-items-center">
            <fp-icon iconSize="20" iconName="icon-camion"></fp-icon>
            <div class="content-titles">
              <div class="label--1 text-inf text-despacho">
                  Despacho a domicilio
              </div>
              <div class="label-black location-content text-despacho">
                  Ingresa tu dirección
                  <div class="button-change-not" (click)="changeRetRad()">Cambiar</div>
              </div>
          </div>
    </div>
  </ng-container>


  <!-- <div class="showCoachMark" *ngIf="(coachmarkService.activeHomeCoachMark | async) === true">
    <div class="container-coachmark">
      <div class="style-text-coachmark-address">
        ¡Ahora puedes cambiar el método de entrega en cualquier momento!
        <p class="style-text-coachmark-2-address">Selecciona la sección de direcciones para lograrlo.</p>
      </div>
      <img src="../../../../../assets/images/Arrow_coachmark.svg" alt="Arrow_coachmark"
        class="style_arrow_coachmark-address">
    </div>
    <div class="text-center">
      <button class="button-coachmark" (click)="removeCoachMarkAddress()">Entendido</button>
    </div>
  </div> -->

  <!-- <div class="highlighted__mask" #id_recojo_tercero
  [hidden]="(coachmarkService.activeHomeCoachMark | async) === false"></div> -->
