import { Injectable } from '@angular/core';
import { PickUpConfigResponse } from '../models/pick-up-config.model';
import { BehaviorSubject } from 'rxjs';
import { AppStoreService } from '@stores/app/app-store.service';

@Injectable()
export class PickUpConfigStoreService {

  private _config = {} as PickUpConfigResponse;
  private _configSubject = new BehaviorSubject<PickUpConfigResponse>({} as PickUpConfigResponse);
  public config$ = this._configSubject.asObservable();
  public get config() {
    this.appStore.setStorageItem("paymentGatewayWeb",this._config.paymentGatewayWeb);
    return this._config;
  }
  public set config(configuration: PickUpConfigResponse) {
    this._configSubject.next(configuration);
  }

  constructor(private appStore: AppStoreService) {
    this.config$.subscribe(config => this._config = config);
  }
}
