import {Component, Input} from '@angular/core';

@Component({
  selector: 'fp-button-black',
  templateUrl: './button-black.component.html'
})
export class ButtonBlackComponent {

  @Input()
  buttonSize: 'small' | 'large' | 'regular' = 'regular';

  @Input()
  buttonDisplay: 'block' | 'flex' | 'inline-block' = 'inline-block';

  @Input()
  buttonType: 'button' | 'submit' | 'reset' = 'button';

  @Input()
  buttonDisabled = false;

  @Input()
  buttonId = 'button';

}
