import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrand } from "@parameters/access/brand.parameter";
import { fromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import { IQueryParms } from "../../interfaces/query-params.interface";

@Injectable()
export class AppStoreService {
	private readonly LOCALSTORAGE_USER_INFO = "user_info";
	private STORAGE_NAME_EVENT = "storage";
	private STORAGE_SESSION_TOKEN: string;
	private _drugstoreStock = "";
	private _drugstoreRetStock = "";
	private _drugstoreCode = "";
	private readonly KEY_DRUGS_STORE_STOCK = "drugstore-stock";
	private readonly KEY_DRUGS_STORE_RET_STOCK = "drugstoreRet-stock";
	private readonly USER_SOURCE_LOGIN = "user_source_login";
	private readonly SOURCE_NAVIGATION_LOGIN = "source_navigation_login";
	private readonly REDIRECT_STATUS = "redirect_login_status";
	private readonly LOCALSTORAGE_USER_ORDER_TAKER = "user_data";
	private readonly OT_BRAND_ATENTION = "OT_BRAND_ATENTION";
	private readonly SEARCH_QUERY_PARAMS = "SEARCH_QUERY_PARAMS";
	private readonly CURRENT_DRUGSTORE = "drugstoreSelected";
	private readonly PURCHASE_NUMBER = "purchase_number";

	currentCoords: any;

	constructor() {
		const drugstoreStock = this.getStorageItem(this.KEY_DRUGS_STORE_STOCK);
		if (drugstoreStock) this.drugstoreStock = drugstoreStock;
	}

	public get storage(): Storage {
		return localStorage;
	}

	public get storage$() {
		return fromEvent<StorageEvent>(window, this.STORAGE_NAME_EVENT).pipe(
			filter((event) => event && event.storageArea === this.storage)
		);
	}

	public cleanStorage() {
		const { storage } = this;
		return storage.clear();
	}

	public getStorageItem(key: string) {
		const { storage } = this;
		return storage.getItem(key);
	}

	public setStorageItem(key: string, value: any) {
		const { storage } = this;
		return storage.setItem(key, value);
	}

	public removeStorageItem(key: string) {
		const { storage } = this;
		return storage.removeItem(key);
	}

	// USER TOKEN
	public set userToken(token: string) {
		this.STORAGE_SESSION_TOKEN = token;
	}

	public get userToken(): string {
		return this.STORAGE_SESSION_TOKEN;
	}

	public removeUserToken(): void {
		this.STORAGE_SESSION_TOKEN = null;
	}

	// USER INFORMATION
	public set userInfo(info: string) {
		const { storage, LOCALSTORAGE_USER_INFO } = this;
		storage.setItem(LOCALSTORAGE_USER_INFO, info);
	}

	public get userInfo(): string {
		const { storage, LOCALSTORAGE_USER_INFO } = this;
		return storage.getItem(LOCALSTORAGE_USER_INFO);
	}

	public removeUserInfo(): void {
		const { storage, LOCALSTORAGE_USER_INFO } = this;
		storage.removeItem(LOCALSTORAGE_USER_INFO);
	}

	// drugstore-stock
	public get drugstoreStock() {
		return this._drugstoreStock;
	}

	public get drugstoreStockFromStorage() {
		const { storage, KEY_DRUGS_STORE_STOCK } = this;
		const id = storage.getItem(KEY_DRUGS_STORE_STOCK);
		return JSON.parse(id);
	}

	public set drugstoreStock(id: string) {
		this._drugstoreStock = id;
		const { storage, KEY_DRUGS_STORE_STOCK } = this;

		storage.setItem(KEY_DRUGS_STORE_STOCK, id);
	}

	// drugstoreRet-stock
	public get drugstoreRetStock() {
		return this._drugstoreRetStock;
	}

	public set drugstoreRetStock(id: string) {
		this._drugstoreRetStock = id;
		const { storage, KEY_DRUGS_STORE_RET_STOCK } = this;

		storage.setItem(KEY_DRUGS_STORE_RET_STOCK, id);
	}

	// drugstore-code
	public get drugstoreCode() {
		return this._drugstoreCode;
	}
	public set drugstoreCode(id: string) {
		this._drugstoreCode = id;
	}

	public get drugstoreSelected(): string {
		const { storage, CURRENT_DRUGSTORE } = this;
		return storage.getItem(CURRENT_DRUGSTORE);
	}

	// source login
	public set sourceLogin(info: string) {
		const { storage, USER_SOURCE_LOGIN } = this;
		storage.setItem(USER_SOURCE_LOGIN, info);
	}

	public get sourceLogin(): string {
		const { storage, USER_SOURCE_LOGIN } = this;
		return storage.getItem(USER_SOURCE_LOGIN);
	}

	public removeSourceLogin(): void {
		const { storage, USER_SOURCE_LOGIN } = this;
		storage.removeItem(USER_SOURCE_LOGIN);
	}

	// source login navigation
	public set sourceNavigationLogin(info: string) {
		const { storage, SOURCE_NAVIGATION_LOGIN } = this;
		storage.setItem(SOURCE_NAVIGATION_LOGIN, info);
	}

	public get sourceNavigationLogin(): string {
		const { storage, SOURCE_NAVIGATION_LOGIN } = this;
		return storage.getItem(SOURCE_NAVIGATION_LOGIN);
	}

	public removeSourceNavigationLogin(): void {
		const { storage, SOURCE_NAVIGATION_LOGIN } = this;
		storage.removeItem(SOURCE_NAVIGATION_LOGIN);
	}

	// redirect finish
	public set redirectLoginStatus(info: string) {
		const { storage, REDIRECT_STATUS } = this;
		storage.setItem(REDIRECT_STATUS, info);
	}

	public get redirectLoginStatus(): string {
		const { storage, REDIRECT_STATUS } = this;
		return storage.getItem(REDIRECT_STATUS);
	}

	public removeRedirectLoginStatus(): void {
		const { storage, REDIRECT_STATUS } = this;
		storage.removeItem(REDIRECT_STATUS);
	}

	//ORDER TAKER USER INFORMATION
	public set userData(info: any) {
		const { storage, LOCALSTORAGE_USER_ORDER_TAKER } = this;
		storage.setItem(LOCALSTORAGE_USER_ORDER_TAKER, JSON.stringify(info));
	}

	public get userData(): any {
		const { storage, LOCALSTORAGE_USER_ORDER_TAKER } = this;
		let userData = storage.getItem(LOCALSTORAGE_USER_ORDER_TAKER);
		return JSON.parse(userData);
	}

	public removeUserData(): void {
		const { storage, LOCALSTORAGE_USER_ORDER_TAKER } = this;
		storage.removeItem(LOCALSTORAGE_USER_ORDER_TAKER);
	}

	public set brandAtention(brand: string) {
		const { storage, OT_BRAND_ATENTION } = this;
		storage.setItem(OT_BRAND_ATENTION, brand);
	}

	public get brandAtention(): string {
		const { storage, OT_BRAND_ATENTION } = this;
		let userData = storage.getItem(OT_BRAND_ATENTION);
		return userData as string;
	}

	public removeBrandAtention(): void {
		const { storage, OT_BRAND_ATENTION } = this;
		storage.removeItem(OT_BRAND_ATENTION);
	}

	public set queryParamsSearch(queryParams: IQueryParms) {
		const { storage, SEARCH_QUERY_PARAMS } = this;
		storage.setItem(SEARCH_QUERY_PARAMS, JSON.stringify(queryParams));
	}

	public get queryParamsSearch(): IQueryParms {
		const { storage, SEARCH_QUERY_PARAMS } = this;
		let userData = storage.getItem(SEARCH_QUERY_PARAMS);
		return JSON.parse(userData);
	}

	get companyCode() {
		return environment.brand === EBrand.inkafarma ? "IKF" : "MF";
	}

	public capitalizeString(string) {
		if (typeof string !== "string") {
			return "";
		}
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() || "";
	}

	public set purchaseNumber(puchaseId: string) {
		const { storage, PURCHASE_NUMBER } = this;
		storage.setItem(PURCHASE_NUMBER, puchaseId);
	}

	public get purchaseNumber(): string {
		const { storage, PURCHASE_NUMBER } = this;
		return storage.getItem(PURCHASE_NUMBER);
	}
}
