import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import {EBrand} from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';

@Directive({
    selector: '[fpBrandAccess]'
})
export class BrandAccessDirective implements OnInit {

    private hasAccess: boolean;

    @Input()
    set fpBrandAccess(brandList: Array<EBrand>) {
      const environmentBrand = environment.brand as EBrand;
      const hasAccess = !!brandList.length && brandList.some(brand => brand === environmentBrand);
      if (!hasAccess && !Array.isArray(brandList)) {
        throw new Error('Brand is not allowed');
      }
      this.hasAccess = hasAccess;
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
    }

    ngOnInit() {
        if (this.hasAccess) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

}
