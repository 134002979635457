import { Injectable } from '@angular/core';
import { GenericService } from '../generic';
import { IAliviamedSessionsRequest, IAliviamedSessionsResponse, IAliviamedWhatsappRequest, IAliviamedWhatsappResponse } from '@models/aliviamed/aliviamed.model';
import { ENDPOINTS } from '@parameters/endpoints';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AliviamedClientService {

  constructor(
    public genericService: GenericService,
  ) { }

  public postSessions$(params: IAliviamedSessionsRequest) {
    const query = { query: `query {
      findAllAppointments(type: "${params.type}", value: "${params.value}") {
        session_id
        session_from
        session_to
        session_reservation
        session_status
        doctor_name
        doctor_last_name
        patient_document_number
        patient_full_name consult_id
        consult_folio_number
        consult_url_prescription
        coupon_code
        appointment_token
        consult_company
        external_url
        session_date
        session_hour
        expired_coupon
        expired_prescription}
      }`
    };
    return this.genericService.genericPost<IAliviamedSessionsResponse>(ENDPOINTS.POST_ALIVIAMED_SESSIONS, query);
  }

  public generateWspLink$(params: IAliviamedWhatsappRequest) {
    const query = {
      specialityId: params.specialityId,
      localCode: params.localCode,
      operatorDNI: params.operatorDNI,
      saleChannel: params.saleChannel,
      company: params.company,
      callMode: params.callMode,
      invoiceNumber: params.invoiceNumber,
      cost: params.cost,
      patient: [{
        name: params.name,
        lastName: params.lastName,
        second_lastName: params.second_lastName,
        document_number: params.document_number,
        document_type: params.document_type,
        phone: params.phone,
        email: params.email,
        is_patient: params.is_patient||0,
        document_number_patient: params.document_number_patient,
        document_type_patient: params.document_type_patient,
        contact_phone_patient: params.contact_phone_patient
      }]
    };


    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Authorization', environment.authPasswordAliviaWsp);

    return this.genericService.genericPost<IAliviamedWhatsappResponse>(ENDPOINTS.POST_ALIVIAMED_WSP_GENERATE_LINK, query, null, headers);
  }

  public resendWspLink$(params: any) {

    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Authorization', environment.authPasswordAliviaWsp);

    const query = {
      idShoppingCard: params.idShoppingCart,
      phone: params.phone
    }

    return this.genericService.genericPost<any>(ENDPOINTS.POST_ALIVIAMED_WSP_RESEND_LINK, query, null, headers);
  }

  public setStatusCart$(params: any, idCart: any) {
    return this.genericService.genericPut<any>(ENDPOINTS.POST_ALIVIAMED_SET_STATUS_CART_LINK+'/'+idCart, params);
  }

  public getPatientsData$(document: String) {
    return this.genericService.genericGet<any>(ENDPOINTS.POST_ALIVIAMED_SEARCH_PATIENT+document);
  }
}
