import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ISelectItem, SelectItem } from '../../models/documents-type/document-type.model';
import { ENDPOINTS } from '../../parameters/endpoints';
import { GenericService } from '../../service';
import { environment } from '@environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { KToken, KeyToken } from 'src/app/business/savings-wallet/models/registration-user.model';
import { Observable } from 'rxjs';
import { OrderTakerValidator } from '@validators/order-taker.validator';

@Injectable({
  providedIn: 'root'
})
export class DocumentsImplementService {

  constructor(
    private genericService: GenericService,
  ) { }

  public getDocumentTypes$() {
    return this.genericService.genericGetWithoutSecurity<ISelectItem[]>(ENDPOINTS.USER_DOCUMENT_TYPES_ENDPOINT)
      .pipe(map(response => {
        return Array.isArray(response) ? response.map(item => new SelectItem(item)) : [];
      }));
  }
  public validationDocumentMonedero$({ docType, docNumber }) {
    const url = OrderTakerValidator.isOrderTakerEnv() ?
    `${ENDPOINTS.APIGEE_GET_NOMEDERO_VALIDATION_TOMADOR}?docType=${docType}&docNumber=${docNumber}&source=CALL` :
    `${ENDPOINTS.APIGEE_GET_NOMEDERO_VALIDATION}?docType=${docType}&docNumber=${docNumber}`
    return this.genericService.genericGetWithoutSecurity<any>(url)
      .pipe(map(response => {
        return response
      }));
  }
}
