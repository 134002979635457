import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.sass']
})
export class IconsComponent implements OnInit {

  @Input() iconName: string;
  @Input() iconSize: 12 | 14 | 16 | 24 | 32 | 48 | 56 | number = 16;
  @Input() iconHeight: 14 | 16 | 24 | 32 | 48 | 56 | number;

  @Input() fontName: string;
  @Input() fontColor: string;
  @Input() fontSize: '16px' | '24px' | '32px' | '48px' | string = '16px';
  @Input() fontStyle: 'round' | 'outlined' | 'sharp' | 'two-tone' | '' = 'round';

  @Input() innerClass: string;

  constructor() {
  }

  ngOnInit() {
  }

  get fontStyleClass() {
    return this.fontStyle ? `material-icons-${this.fontStyle}` : 'material-icons';
  }
}
