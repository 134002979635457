import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGeolocationHelperService } from './user-geolocation-helper.service';
import { PickUpServicesForAgmModule } from '../pick-up-services-for-agm/pick-up-services-for-agm.module';
import { FirstLoadHelperService } from './first-load-helper.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    UserGeolocationHelperService,
    PickUpServicesForAgmModule,
    FirstLoadHelperService,
  ]
})
export class PickUpHelpersModule { }
