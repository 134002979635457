import { Injectable } from '@angular/core';
import { WhoPickUpFormService } from './who-pick-up-form.service';
import { UserPickUp } from 'src/app/shared/models/pickup-store/pickup-store.model';

@Injectable()
export class WhoPickUpFormHelperService {
    constructor(
        private whoPickUpForm: WhoPickUpFormService,
    ) { }

    public setFormValuesToUpdate(userPickUpToUpdate: UserPickUp) {
        this.whoPickUpForm.form.setValue({
            typeDoc: userPickUpToUpdate.identityDocumenType,
            dni: userPickUpToUpdate.identityDocumentNumber,
            emailAddress: userPickUpToUpdate.email,
            name: userPickUpToUpdate.fullName,
            phoneNumber: userPickUpToUpdate.phone
        });
    }

    public resetFormToCreateUser() {
        this.whoPickUpForm.form.setValue({
            typeDoc: 1,
            dni: '',
            emailAddress: '',
            name: '',
            phoneNumber: ''
        });
    }
}
