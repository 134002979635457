import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IGeocodeReverseResponse {
  results: google.maps.GeocoderResult[];
  status: google.maps.GeocoderStatus;
}

@Injectable()
export class GoogleMapGeocoderService {

  constructor() { }

  public getGeocodeReverse(location: google.maps.LatLng) {
    const geocoder = new google.maps.Geocoder();
    const geocoderRequest = { location, region: 'PE' } as google.maps.GeocoderRequest;
    return new Observable<google.maps.GeocoderResult[]>(observer => {
      geocoder.geocode(geocoderRequest, (results, status) => {
        if (status === 'OK') {
          observer.next(results);
        }
      });
    });
  }

  public getGeocodeReversePromise(location: google.maps.LatLng, callback: (results: IGeocodeReverseResponse) => void) {
    const geocoder = new google.maps.Geocoder();
    const geocoderRequest = { location, region: 'PE' } as google.maps.GeocoderRequest;
    return geocoder.geocode(geocoderRequest, (results, status) => {
      const response = {
        results, status,
      } as IGeocodeReverseResponse;
      callback(response);
    });
  }

  public getGeocodeReverseCountry(results: google.maps.GeocoderResult[]) {
    const existCountry = results.find(result => result.types.includes('country'));
    return existCountry ? existCountry.formatted_address : '';
  }

  public getGeocodeReverseStreetAddress(results: google.maps.GeocoderResult[]) {
    const selectedAddress = results.find(result => result.types.includes('street_address'));
    return selectedAddress ? selectedAddress : {} as google.maps.GeocoderResult;
  }

  public getGeocodeReverseStreetNumber(addressComponents: google.maps.GeocoderAddressComponent[]) {
    const selectedNumber = addressComponents.find(comp => comp.types.includes('street_number'));
    return selectedNumber ? selectedNumber.long_name : '';
  }

  public getGeocodeReverseDistrict(results: google.maps.GeocoderResult[]) {
    let existDistrict = results.find(result => result.types.includes('administrative_area_level_3'));
    if (!existDistrict) {
      existDistrict = results.find(result => result.types.includes('locality'));
    }
    return existDistrict ? existDistrict.formatted_address.split(',')[0] : '';
  }

  public getGeocodeReverseProvince(results: google.maps.GeocoderResult[]) {
    const existProvince = results.find(result => result.types.includes('administrative_area_level_2'));
    return existProvince ? existProvince.formatted_address.split(',')[0] : '';
  }

  public getGeocodeReverseDepartment(results: google.maps.GeocoderResult[]) {
    const existDepartment = results.find(result => result.types.includes('administrative_area_level_1'));
    return existDepartment ? existDepartment.formatted_address.split(',')[0] : '';
  }
}
