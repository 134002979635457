import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CartLoginModalComponent } from '../modal-components/cart-login-modal/cart-login-modal.component';

@Injectable()
export class CartLoginModalManagerService {

  constructor(
    private dialog: MatDialog,
  ) { }

  public showCartLogin(): void {
    this.dialog.open(CartLoginModalComponent, {
      width: '340px',
      height: 'auto',
    });
  }
}
