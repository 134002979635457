import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { IFormatedAddressItem } from "../models/address-item-store.interface";
import { ADDRESS_NICKNAME_OTHER } from "../parameters/address-list.constant";
import { OrderTakerValidator } from "./../../../../shared/validators/order-taker.validator";
import { StorageClientService } from "src/app/shared/storage/storage-client.service";
import { LS_ADDRESS_SELECTED, LS_CURRENT_ADDRESS, LS_CURRENT_ADDRESS_LIST, LS_CURRENT_DRUGSTORE, LS_ID_FAVORITE_ADDRESS, OT_ADDRESS_SELECTED } from "src/app/shared/service/local-storage/constants/address.constant";

@Injectable({
	providedIn: "root"
})
export class AddressListStoreService {
  private _addressList: AddressItem[];
  private _selectedAddress: AddressItem;
  private _addressListSubject = new BehaviorSubject<AddressItem[]>(
    this.currentSelectedAddressFromStore ? [this.currentSelectedAddressFromStore] : []
  );
  private _selectedAddressSubject = new BehaviorSubject<AddressItem>(this.currentSelectedAddressFromStore ?
    this.currentSelectedAddressFromStore :
    {} as AddressItem
  );
	private subscriptions: Subscription[] = [];
	public addressList$ = this._addressListSubject.asObservable();
	public selectedAddress$ = this._selectedAddressSubject.asObservable();

	get currentSelectedAddress() {
		return this._selectedAddressSubject.getValue();
	}

  get currentSelectedAddressFromStore() {
    return JSON.parse(this.storageClient.getStorageItem(OrderTakerValidator.isOrderTakerEnv() ? OT_ADDRESS_SELECTED : LS_CURRENT_ADDRESS))
  }

  get currentSelectedAddressListFromStore() {
    return JSON.parse(this.storageClient.getStorageItem(LS_CURRENT_ADDRESS_LIST))
  }

	get favoriteAddress() {
		return this.getFavoriteAddress(this._addressListSubject.getValue());
	}

	// for RAD
	private _isLoadingAddressSubject = new BehaviorSubject<boolean>(false);
	public isLoadingAddress$ = this._isLoadingAddressSubject.asObservable();

	private _selectedAddressHasCoverage = new BehaviorSubject<boolean>(true);
	public selectedAddressHasCoverage$ = this._selectedAddressHasCoverage.asObservable();

	constructor(private storageClient: StorageClientService) {
		const addressListSubscription = this.addressList$.subscribe((addresses) => {
			this._addressList = addresses;
		});
		const selectedAddressSubscription = this.selectedAddress$.subscribe((address) => (this._selectedAddress = address));
		this.subscriptions.push(addressListSubscription, selectedAddressSubscription);
	}

	public setIsLoadingAddress(status: boolean) {
		this._isLoadingAddressSubject.next(status);
	}

	public setSelectedAddressHasCoverage(status: boolean) {
		this._selectedAddressHasCoverage.next(status);
	}

	public get addressList() {
		return this._addressList;
	}

	public get selectedAddress() {
		return this._selectedAddress;
	}

	public get formatedAddressList$() {
		return this.addressList$.pipe(
			map((addressList) => {
				const NUMBER_REG_EXP = /[0-9]{2,}/g;
				const formatedAddressList = addressList.map((address) => {
					let _number = address.number ? " " + address.number : "";
					_number = NUMBER_REG_EXP.test(address.street) ? "" : _number;
					const _district = address.district && !OrderTakerValidator.isOrderTakerEnv() ? ", " + address.district : "";
					const _city = address.city ? ", " + address.city : "";
					const _address = `${address.street}${_number}${_district}${_city}`;
					return {
						name:
							address.name !== ADDRESS_NICKNAME_OTHER
								? address.name
								: address.otherDirection
								? address.otherDirection
								: address.name,
						street: _address,
						id: address.id,
						mz: address.mz,
						lote: address.lote,
						latitude: address.latitude,
						longitude: address.longitude
					} as IFormatedAddressItem;
				});
				return formatedAddressList;
			})
		);
	}

  public setSelectedAddress(address: AddressItem) {
    // check it out better
    if(this.storageClient.getStorageItem(LS_ID_FAVORITE_ADDRESS) === "undefined" && address) {
      this.storageClient.setStorageItem(LS_ID_FAVORITE_ADDRESS, address.id.toString())
    }
    const validSelectedAddress = address ? address : ({} as AddressItem);
    this._selectedAddressSubject.next(validSelectedAddress);
  }

  public saveAddressStorage(address: AddressItem) {
    this.storageClient.setStorageItem(OrderTakerValidator.isOrderTakerEnv() ? OT_ADDRESS_SELECTED : LS_CURRENT_ADDRESS, JSON.stringify(address))
  }

	public setAddressList(addressList: AddressItem[]) {
		if (addressList?.length === 1 && !addressList[0].canContinue) {
			this._addressListSubject.next([]);
		} else {
			this._addressListSubject.next(addressList);
			this._addressList = addressList;
      if (addressList.length > 0 && this._selectedAddress?.id === "") {
        const address = this.getFavoriteAddress(addressList) || addressList[0];
        this.setSelectedAddress(address);
        this.saveAddressStorage(this.currentSelectedAddressFromStore?.id === "" && address)
      }
		}
	}

  public saveAddressListStorage(addressList: AddressItem[]) {
    this.storageClient.setStorageItem(LS_CURRENT_ADDRESS_LIST, JSON.stringify(addressList))
  }

	public unsubscribeObservers() {
		this.subscriptions.map((subscription) => subscription.unsubscribe());
	}

	public getFavoriteAddress(addressList: AddressItem[]) {
		return addressList.find((address) => address.favorite);
	}

  checkLocalStorageVariables(): boolean {
    const drugstore = JSON.parse(localStorage.getItem(LS_CURRENT_DRUGSTORE));
    const selectAddress = JSON.parse(localStorage.getItem(LS_ADDRESS_SELECTED));

		return drugstore !== null || selectAddress !== null;
	}
}
