<div class="d-flex">
  <span><b> Direcciones del Sr(a): </b></span>
  <p class="m-0 ml-2">
    {{ client?.name }} {{ client?.lastNamePat }}
    {{ client?.lastNameMat }}
  </p>
</div>
<div class="w-100">
  <div class="d-flex justify-content-center">
    <fp-button-ghost
      class="btn-office-add-address"
      id="lb--add-address"
      (click)="openNewAddressModal()"
    >
      <fp-icon class="mr-2" iconName="add"></fp-icon>
      Agregar nueva dirección
    </fp-button-ghost>
  </div>
  <form [formGroup]="form" class="form-group">
    <div class="scroll-address-radio-button">
      <fp-address-radio-button
        *ngFor="let aItem of addressItemList; let indexOfelement = index"
        [showMatMenu]="true"
        [addressTitle]="aItem.name"
        [addressText]="aItem.street"
        [addressId]="aItem.id"
        [selectedRadio]="aItem.id === selectedAddress.id"
        (deleteEvent)="deleteAddressItem(aItem.id)"
        (editEvent)="editAddressItem(aItem.id)"
        (selectRadioOption)="selectRadioAddress(aItem.id)"
        (click)="tagRadio(aItem)"
      >
        <fp-radio-control
          formControlName="addressItem"
          [radioOptionValue]="aItem.id"
          [name]="'addressListName'"
        >
        </fp-radio-control>
      </fp-address-radio-button>
    </div>
  </form>
</div>
