export enum StateContentModalFindClient {
	INITIAL_FIND = "INITIAL_FIND",
	SUCCESS_FIND = "SUCCESS_FIND",
	NOT_FOUND = "NOT_FOUND"
}

export enum EnumFormFindClient {
	CELLPHONE = "cellphone",
	SEARCHTYPE = "searchType",
	IDDATA = "idData",
	SALECHANNEL = "saleChannel"
}

export enum EnumChannelFindClient {
	CALL = "CALL"
}

export enum EnumAgreementFindClient {
	TYC = "TYC",
	DATO = "DATO",
	PRIV = "PRIV"
}
