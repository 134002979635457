<div class="content-verification-modal px-4 py-4">
  <div class="button-modal-close" >
    <!-- <img src="./assets/myprofile/arrow-down.svg" (click)="closeThisModal()"  /> -->
    <span
      class="icon icon-24-close icon-close"
      (click)="closeThisModal()">
    </span>
  </div>

  <div class="content-massage box-content-modal" style="font-size: 13px; height: 100%; position: relative;">
    <div class="mx-2 my-3 icon-alert modal-title">
      <img src="./assets/myprofile/mobile-chat.svg" />
      <span style="font-size: 16px; font-weight: 600; margin-left: 1rem;">Valida tu número</span>
      <!-- <span class="icon icon-48-phone-colored"></span> -->
    </div>
    <div class="warning-message mx-2 mt-3 mb-2">
      <!-- Para terminar tu registro, ingresa el código que enviamos a tu celular -->
      Te hemos enviado un código al {{encryptedPhoneNumber}} para validar {{data == 'create' ? '' : ' el cambio de'}} tu número de celular.
    </div>
    <!-- <div class="label-black-2 mx-2 mt-4 mt-md-0" >
      {{ phoneNumber }}
    </div> -->
    <!-- <div class="mx-2">
      <fp-link-buttom
        [labelClass]="'label-black--1'"
        [buttonText]="'Cambiar mi número'"
        (click)="closeThisModal()">
      </fp-link-buttom>
    </div> -->

    <div class="input-code mt-4 mb-3 mx-2">
      <div class="input-code" [formGroup]="form">
        <fp-six-digit-code
          formControlName="code"
          [isDisabled] ="isDisabledInputs"
          (disableButton)="ss($event)"
          [modalEmail] = "false"
        ></fp-six-digit-code>
      </div>
      <div class="countdown-content">
        <div class="row">
          <div class="col-7" style="align-items: center;">
            <fp-link-buttom [labelClass]="'label-black--1'"
              [buttonText]="'Reenviar código'"
              [disabled]="buttonSendDisabled"
              (click)="sendNewCode()">
            </fp-link-buttom>
          </div>
          <div class="col-5 text-right">
            <div class="countdown label">
                {{ minutes | number:'2.0-0' }}:{{ seconds | number:'2.0-0' }}
            </div>
          </div>
        </div>

      </div>
      <div class="mt-5 btn-send-code" >
        <fp-button-primary
        [id]="''"
        [buttonDisplay]="'block'"
        (click)="send()"
        [buttonDisabled] = "buttonDisabled"

        >
        <div class="d-flex justify-content-center align-items-center" >
          Enviar
        </div>
      </fp-button-primary>
      </div>
    </div>
  </div>

  <div class="alert-success" *ngIf="checkVerify">
    <span class="icon icon-48-check"></span>
  </div>
</div>

