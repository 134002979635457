import { Asset, Entry } from "contentful";
import {
  ContentfulAsset,
  ContentfulEntry,
} from "../../../shared/models/contentful/contentful.model";
import { IContentfulBanner } from "./contentful-banner.model";
import { IContentfulShortcutList } from "./contentful-shortcut-list.model";
import { IContentfulShortcut } from "./contentful-shortcut.model";

export interface IContentfulHeader {
  title: string;
  mainLogo: Asset;
  categoryShortcutList: Entry<IContentfulShortcutList>;
  // informationShortcutList: Array<Entry<IContentfulShortcutList | IContentfulShortcut>>;
  informationShortcutList: Entry<IContentfulShortcutList>;
  brandShorcutList: Entry<IContentfulShortcutList>;
  bannerCintillo: Entry<IContentfulBanner>;
  headingLevel: string;
  idDeNotificacion: string;
}

export interface IContentfulHeaderMifa {
  title: string;
  mainLogo: Asset;
  categoryShortcutList: Entry<IContentfulShortcutList>;
  informationShortcutList: Array<
    Entry<IContentfulShortcutList | IContentfulShortcut>
  >;
  brandShorcutList: Entry<IContentfulShortcutList>;
  bannerCintillo: Entry<IContentfulBanner>;
  headingLevel: string;
  idDeNotificacion: string;
}

export class ContentfulHeader {
  title: string;
  mainLogo: string;
  categoryShortcutList: ContentfulEntry<IContentfulShortcutList>;
  // informationShortcutList: Array<ContentfulEntry<IContentfulShortcutList | IContentfulShortcut>>;
  informationShortcutList: ContentfulEntry<IContentfulShortcutList>;
  bannerCintillo: ContentfulEntry<IContentfulBanner>;
  brandShorcutList: ContentfulEntry<IContentfulShortcutList>;
  headingLevel: string;
  idDeNotificacion: string;
  constructor(iContentfulHeader: IContentfulHeader) {
    this.title = iContentfulHeader.title || '';
    if (iContentfulHeader.mainLogo) { this.mainLogo = new ContentfulAsset(iContentfulHeader.mainLogo).asset; }
    if (iContentfulHeader.categoryShortcutList) { this.categoryShortcutList = new ContentfulEntry(iContentfulHeader.categoryShortcutList); }
    if (iContentfulHeader.brandShorcutList) { this.brandShorcutList = new ContentfulEntry(iContentfulHeader.brandShorcutList); }
    if (iContentfulHeader.informationShortcutList) { this.informationShortcutList = new ContentfulEntry(iContentfulHeader.informationShortcutList); }
    if (iContentfulHeader.bannerCintillo) { this.bannerCintillo = new ContentfulEntry(iContentfulHeader.bannerCintillo); }
    if (iContentfulHeader.idDeNotificacion) { this.idDeNotificacion = iContentfulHeader.idDeNotificacion; }
    this.headingLevel = iContentfulHeader.headingLevel || "h1";
  }
}

export class ContentfulHeaderMifa {
  title: string;
  mainLogo: string;
  categoryShortcutList: ContentfulEntry<IContentfulShortcutList>;
  informationShortcutList: Array<
    ContentfulEntry<IContentfulShortcutList | IContentfulShortcut>
  >;
  bannerCintillo: ContentfulEntry<IContentfulBanner>;
  brandShorcutList: ContentfulEntry<IContentfulShortcutList>;
  headingLevel: string;
  idDeNotificacion: string;

  constructor(iContentfulHeader: IContentfulHeaderMifa) {
    this.title = iContentfulHeader.title || '';
    this.headingLevel = iContentfulHeader.headingLevel || 'h1';
    if (iContentfulHeader.mainLogo) { this.mainLogo = new ContentfulAsset(iContentfulHeader.mainLogo).asset; }
    if (iContentfulHeader.categoryShortcutList) { this.categoryShortcutList = new ContentfulEntry(iContentfulHeader.categoryShortcutList); }
    if (iContentfulHeader.brandShorcutList) { this.brandShorcutList = new ContentfulEntry(iContentfulHeader.brandShorcutList); }
    if (iContentfulHeader.informationShortcutList) { this.informationShortcutList =  iContentfulHeader.informationShortcutList
      .map(list => new ContentfulEntry(list)); }
    if (iContentfulHeader.idDeNotificacion) { this.idDeNotificacion = iContentfulHeader.idDeNotificacion; }
    if (iContentfulHeader.bannerCintillo) { this.bannerCintillo = new ContentfulEntry(iContentfulHeader.bannerCintillo); }
  }
}
