import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IResponseNotification } from "@models/user/user";

@Component({
	selector: "fp-notification-discount-modal",
	templateUrl: "./notification-discount-modal.component.html",
	styleUrls: ["./notification-discount-modal.component.sass"]
})
export class NotificationDiscountModalComponent implements OnInit {
	constructor(@Inject(MAT_DIALOG_DATA) public dialogData: IResponseNotification,
              private dialogRef: MatDialogRef<NotificationDiscountModalComponent>,
  ) {}

	ngOnInit(): void { }

  close() {
    this.dialogRef.close();
  }
}
