import { Injectable } from "@angular/core";
import { ENDPOINTS } from "src/app/shared/parameters/endpoints";
import { ProductShoppingCart } from "../core/modules/product-card/models/product-shopping-cart/product-shopping-cart.model";
import { EnumTagEventAction, EnumTagEventCategory, EnumTagEventName } from "./constants/tagging.constants";
import { PAYMENT_METHODS_TEXT } from "@parameters/checkout/payment-method.parameters";
import { PaymentGatewayEnum } from "../business/checkout/enum/payment-gateway.enum";

@Injectable({
	providedIn: "root"
})
export class TaggingService {
	public static paymentMethodName = "";
	public static cardMethodName = "";

	constructor() {}

	public static get getClientId() {
		const gaData: Array<any> = (<any>(<any>window)).ga ? (<any>(<any>window)).ga.getAll() : [];
		return gaData.length ? gaData[0].get("clientId") : null;
	}

	public static get getUserData() {
		return (<any>(<any>window)).dataUser;
	}

	public static get getDimensionData() {
		return {
			dimension2: this.getUserData ? this.getUserData.encryptedEmail : "",
			dimension3: this.getUserData ? this.getUserData.encryptedDni : "",
			dimension4: this.getClientId || "",
			dimension13: "",
			dimension14: "",
			dimension17: "web"
		};
	}

	public static fetchData(url_api, callback) {
		const xhttp = new XMLHttpRequest();
		xhttp.open("GET", url_api, true);
		xhttp.onreadystatechange = (event) => {
			if (xhttp.readyState === 4) {
				if (xhttp.status === 200) {
					callback(null, JSON.parse(xhttp.responseText));
				} else {
					const error = new Error("Error " + url_api);
					return callback(error, null);
				}
			}
		};
		xhttp.send();
	}

	public static pushAtributeDataUser(attr: string, value: string | boolean) {
		let dataUserMemory = JSON.parse(localStorage.getItem("dataUserMemory") || "{}");
		dataUserMemory[attr] = value;
		localStorage.setItem("dataUserMemory", JSON.stringify(dataUserMemory));
	}

	public static async saveData(data) {
		const setObjDataUser = (dataUser: any) => {
			const dataUserMemory = JSON.parse(localStorage.getItem("dataUserMemory") || "{}");
			for (let key in dataUserMemory) {
				if (dataUserMemory.hasOwnProperty(key)) {
					dataUser[key] = dataUserMemory[key];
				}
			}
		};

		// Función para derivar la clave desde una contraseña y un salt
		async function getKeyFromPassword(password, salt) {
			const encoder = new TextEncoder();
			const keyMaterial = await window.crypto.subtle.importKey("raw", encoder.encode(password), { name: "PBKDF2" }, false, [
				"deriveKey"
			]);
			return window.crypto.subtle.deriveKey(
				{
					name: "PBKDF2",
					salt: salt,
					iterations: 100000,
					hash: "SHA-256"
				},
				keyMaterial,
				{ name: "AES-GCM", length: 256 },
				true,
				["encrypt", "decrypt"]
			);
		}

		// Función para encriptar datos
		async function encryptData(data, password) {
			const encoder = new TextEncoder();
			const dataArray = encoder.encode(JSON.stringify(data));
			const salt = window.crypto.getRandomValues(new Uint8Array(16));
			const iv = window.crypto.getRandomValues(new Uint8Array(12));

			const key = await getKeyFromPassword(password, salt);

			const encryptedData = await window.crypto.subtle.encrypt({ name: "AES-GCM", iv: iv }, key, dataArray);

			const encryptedArray = new Uint8Array(encryptedData);
			const fullArray = new Uint8Array(salt.length + iv.length + encryptedArray.length);
			fullArray.set(salt);
			fullArray.set(iv, salt.length);
			fullArray.set(encryptedArray, salt.length + iv.length);

			return btoa(String.fromCharCode.apply(null, fullArray));
		}

		if (data && data.data !== null) {
			const encryptedData = await encryptData(data.data, "LFt^gu4Pok$#QW5");
			TaggingService.pushAtributeDataUser("encryptedData", encryptedData);

			(<any>(<any>window)).dataUser = data.data;
			(<any>(<any>window)).dataUser["status"] = true;
			setObjDataUser((<any>(<any>window)).dataUser);
		} else {
			(<any>(<any>window)).dataUser = {
				status: false,
				encryptedEmail: "",
				encryptedDni: ""
			};
		}
	}

	public static getData(url) {
		if (/\/producto\/[0-9]/.test(url)) {
			this.fetchData(url, function (error, data) {
				if (error) {
					return console.error(error);
				}
				if (typeof data === "undefined") {
					(<any>(<any>window)).dataProduct = { status: false };
				} else {
					(<any>(<any>window)).dataProduct = data;
					(<any>(<any>window)).dataProduct["status"] = true;
				}
			});
		}
	}

	public static triggerTag(datos) {
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const gaData: Array<any> = (<any>(<any>window)).ga ? (<any>(<any>window)).ga.getAll() : [];
		const clientId = gaData.length ? gaData[0].get("clientId") : null;
		const user = (<any>(<any>window)).dataUser;
		const shot = {
			event: "atm.event",
			eventName: datos.eventName || "",
			eventCategory: datos.eventCategory || "",
			eventAction: datos.eventAction || "",
			eventLabel: datos.eventLabel || "",
			nonInteraction: datos.nonInteraction || 1,
			firebase_id: user ? user.authId : "",
			dimension2: user ? user.encryptedEmail : "",
			dimension3: user ? user.encryptedDni : "",
			dimension4: clientId || "",
			dimension13: "",
			dimension14: "",
			dimension17: "web"
		};
		dataLayer.push(shot);
	}

	public static sliderArrow(direction) {
		this.triggerTag({
			eventName: "ui_home_slider_direction",
			eventCategory: "UI :: Home",
			eventAction: "Slider direction",
			eventLabel: direction
		});
	}

	public static sliderBanner(title, section) {
		this.triggerTag({
			eventName: "ui_home_slider_banner",
			eventCategory: "UI :: Home",
			eventAction: section,
			eventLabel: title
		});
	}

	public static bannerHomeList(title) {
		this.triggerTag({
			eventName: "ui_home_banner_home_list",
			eventCategory: "UI :: Home",
			eventAction: "Banner Home List",
			eventLabel: title
		});
	}

	public static pageView(url) {
		this.triggerTag({
			eventName: "ui_views_pageview",
			eventCategory: "UI :: Views",
			eventAction: url,
			eventLabel: ""
		});
	}

	public static banner(name, section) {
		this.triggerTag({
			eventName: "ecommerce_banner",
			eventCategory: "UI :: Home",
			eventAction: section,
			eventLabel: name
		});
	}

	public static registerOK(name) {
		this.triggerTag({
			eventName: "ui_login_crear_una_cuenta",
			eventCategory: "UI :: Register",
			eventAction: "Register",
			eventLabel: name
		});
	}

	public static clickFooter(name) {
		this.triggerTag({
			eventName: "ui_footer_click",
			eventCategory: "UI :: Footer",
			eventAction: "Click",
			eventLabel: name
		});
	}

	public static clickFooterOutBound(name) {
		this.triggerTag({
			eventName: "ui_footer_outbound_link",
			eventCategory: "UI :: Footer",
			eventAction: "Outbound Click",
			eventLabel: name
		});
	}

	public static registerModal(type) {
		this.triggerTag({
			eventName: "ui_login_crear_una_cuenta",
			eventCategory: "UI :: Register",
			eventAction: "Crea una cuenta",
			eventLabel: type
		});
	}

	public static registerModalCerrar() {
		this.triggerTag({
			eventName: "ui_login_crear_una_cuenta",
			eventCategory: "UI :: Register",
			eventAction: "Iniciar sesión",
			eventLabel: "cerrar"
		});
	}

	public static loginModalCerrar() {
		this.triggerTag({
			eventName: "ui_login_click",
			eventCategory: "UI :: Login",
			eventAction: "Click",
			eventLabel: "cerrar"
		});
	}

	public static loginOK(name) {
		TaggingService.pushAtributeDataUser("option", name);
		this.triggerTag({
			eventName: "ui_login_click",
			eventCategory: "UI :: Login",
			eventAction: "Login",
			eventLabel: name
		});
	}

	public static loginModal(type) {
		this.triggerTag({
			eventName: "ui_login_click",
			eventCategory: "UI :: Login",
			eventAction: "Click",
			eventLabel: type
		});
	}

	public static headerSearch() {
		const $this = this;
		const input = document.querySelectorAll("fp-header #main-header-search-input");
		if (input.length) {
			input[0].addEventListener("click", function () {
				$this.triggerTag({
					eventName: "search_click",
					eventCategory: "Search",
					eventAction: "Click",
					eventLabel: "search"
				});
			});
		}
	}

	public static headerSearchClick() {
		const $this = this;
		const label = document.querySelectorAll("fp-header .main-header-search-label");
		if (label.length) {
			label[0].addEventListener("click", function () {
				const label2 = (<HTMLScriptElement>(<any>document.querySelectorAll("#main-header-search-input")))[0].value;
				$this.triggerTag({
					eventName: "search_search",
					eventCategory: "Search",
					eventAction: "Search",
					eventLabel: label2
				});
			});
		}
	}

	public static searchSugerencias() {
		const $this = this;
		const time_sugerencia = setInterval(function () {
			if (document.querySelectorAll(".products-filtered-container .product-filtered-text").length) {
				document.querySelectorAll(".products-filtered-container .product-filtered-text").forEach(function (link) {
					link.addEventListener("click", function () {
						$this.triggerTag({
							eventName: "search_click",
							eventCategory: "Search",
							eventAction: "Click",
							eventLabel: this.innerText
						});
						return false;
					});
				});
				clearInterval(time_sugerencia);
			}
		}, 100);
	}

	public static searchPageResult(searchKeyword) {
		this.triggerTag({
			eventName: "search_ecommerce",
			eventCategory: "Search",
			eventAction: "Ecommerce",
			eventLabel: "Impression"
		});
		this.triggerTag({
			eventName: "search_result",
			eventCategory: "Search",
			eventAction: "Results",
			eventLabel: searchKeyword
		});
	}

	public static linkViewDetail(links) {
		const $this = this;
		links.forEach(function (link) {
			link.addEventListener("click", function () {
				$this.triggerTag({
					eventName: "search_ecommerce",
					eventCategory: "Search",
					eventAction: "Ecommerce",
					eventLabel: "Product Click"
				});
			});
		});
	}

	public static btnAddCart(btns) {
		const $this = this;
		btns.forEach(function (btn) {
			btn.addEventListener("click", function () {
				$this.triggerTag({
					eventName: "search_ecommerce",
					eventCategory: "Search",
					eventAction: "Ecommerce",
					eventLabel: "Add To Cart"
				});
			});
		});
	}

	public static searchListenerProduct() {
		const $this = this;
		const time = setInterval(function () {
			// DESKTOP
			const btnsDesk = document.querySelectorAll(".card.product-hover .btn-primary");
			$this.btnAddCart(btnsDesk);

			const linksDesk = document.querySelectorAll(".card.product-hover .link-button");
			$this.linkViewDetail(linksDesk);

			// MOBILE
			const btnsResp = document.querySelectorAll("fp-product-small .btn-primary");
			$this.btnAddCart(btnsResp);

			const linksResp = document.querySelectorAll("fp-product-small a.link");
			$this.linkViewDetail(linksResp);

			clearInterval(time);
		}, 100);
	}

	public static headerLogeado() {
		const $this = this;
		const time = setInterval(function () {
			// MENU DE LOGEADO
			const links = <HTMLScriptElement[]>(<any>document.querySelectorAll(".main-header-option.main-header-history"));
			if (links.length) {
				links.forEach(function (link) {
					link.addEventListener("click", function () {
						const label = link.querySelector("#myOrders") ? link.querySelector("#myOrders").innerHTML : link.innerText.trim();
						$this.triggerTag({
							eventName: "ui_header_click",
							eventCategory: "UI :: Header",
							eventAction: "Click",
							eventLabel: label
						});
					});
				});

				// LOGEADO
				const btn_menu_login = document.querySelectorAll(".main-header-login.main-header-logged");
				if (btn_menu_login.length) {
					btn_menu_login[0].addEventListener("click", function () {
						$this.triggerTag({
							eventName: "ui_header_click",
							eventCategory: "UI :: Header",
							eventAction: "Click",
							eventLabel: "Menú Login"
						});
					});
				}

				clearInterval(time);
			}
		}, 500);
	}

	public static header() {
		const $this = this;
		const logo = document.querySelectorAll("fp-header .main-header-logo a");
		if (logo.length) {
			logo[0].addEventListener("click", function () {
				$this.triggerTag({
					eventName: "ui_header_click",
					eventCategory: "UI :: Header",
					eventAction: "Click",
					eventLabel: "Logo"
				});
			});
		}

		const address = document.querySelectorAll("fp-header .main-header-option.main-header-address");

		if (address.length) {
			address[0].addEventListener("click", function () {
				$this.triggerTag({
					eventName: "ui_header_click",
					eventCategory: "UI :: Header",
					eventAction: "Click",
					eventLabel: "Ingresa tu dirección"
				});
			});
		}

		const time = setInterval(function () {
			// SIN LOGEARSE
			// desktop
			const btn_iniciar_session_desk = document.querySelectorAll(".main-header-login");
			if (btn_iniciar_session_desk.length) {
				btn_iniciar_session_desk[0].addEventListener("click", function () {
					$this.triggerTag({
						eventName: "ui_header_click",
						eventCategory: "UI :: Header",
						eventAction: "Click",
						eventLabel: "Inicia sesión"
					});
				});
			}
			// mobile
			const btn_iniciar_session_resp = document.querySelectorAll("fp-header .main-header-option.main-header-user");
			if (btn_iniciar_session_resp.length) {
				btn_iniciar_session_resp[0].addEventListener("click", function () {
					$this.triggerTag({
						eventName: "ui_header_click",
						eventCategory: "UI :: Header",
						eventAction: "Click",
						eventLabel: "Inicia sesión"
					});
				});
			}
			const cartDesk = document.querySelectorAll(".navigation-header .cart-header");
			if (cartDesk.length) {
				cartDesk[1].addEventListener("click", function () {
					$this.triggerTag({
						eventName: "ui_header_click",
						eventCategory: "UI :: Header",
						eventAction: "Click",
						eventLabel: "Mi carrito"
					});
				});
			}
			// mobile
			const cartResp = document.querySelectorAll("fp-header-responsive .cart-header");
			if (cartResp.length) {
				cartResp[0].addEventListener("click", function () {
					$this.triggerTag({
						eventName: "ui_header_click",
						eventCategory: "UI :: Header",
						eventAction: "Click",
						eventLabel: "Mi carrito"
					});
				});
			}
			// cuando el menu de usuario de despliega en mobile
			document.querySelectorAll("fp-profile-sidenav-responsive fp-sidenav-container .list-option").forEach(function (btn) {
				btn.addEventListener("click", function () {
					$this.triggerTag({
						eventName: "ui_header_click",
						eventCategory: "UI :: Header",
						eventAction: "Click",
						eventLabel: this.innerText
					});
				});
			});
			// cuando el menu de usuario de despliega en desktop
			document.querySelectorAll(".menu-log-out.show .menu-log-out-link").forEach(function (btn) {
				btn.addEventListener("click", function () {
					$this.triggerTag({
						eventName: "ui_header_click",
						eventCategory: "UI :: Header",
						eventAction: "Click",
						eventLabel: this.innerText
					});
				});
			});

			clearInterval(time);
		}, 500);
	}

	public static navigationStatic() {
		/*   const $this = this;
            const time = setInterval(function () {
                if (document.querySelectorAll('.navigation-header fp-custom-router-link').length) {
                    // var dataLayer = (<any><any>window).dataLayer || [];
                    document.querySelectorAll('.navigation-header fp-custom-router-link').forEach(function (link) {
                        link.addEventListener('click', function () {
                            const label = this.innerText;
                            const categoria = window.location.pathname;
                            $this.triggerTag({
                                'eventName': 'ui_header_click',
                                'eventCategory': 'UI :: Header',
                                'eventAction': categoria,
                                'eventLabel': label
                            });

                        });
                    });
                    clearInterval(time);
                }
          }, 100); */
	}

	public static navigationDynamic(type, level, categoria) {
		if (type === "desktop") {
			const seccion = (<HTMLScriptElement[]>(<any>document.querySelectorAll(".category-header-container.category-menu-header")))[0]
				.innerText;
			let subcategoria, subsubcategoria;
			switch (level) {
				case 0:
					this.triggerTag({
						eventName: "ui_header_menu_click",
						eventCategory: "UI :: Header - Menu",
						eventAction: seccion,
						eventLabel: seccion + " > " + categoria
					});
					break;
				case 1:
					subcategoria = categoria;
					categoria = (<HTMLScriptElement[]>(
						(<any>document.querySelectorAll(".department-container .department-option.department-option--hover"))
					))[0].innerText;
					this.triggerTag({
						eventName: "ui_header_menu_click",
						eventCategory: "UI :: Header - Menu",
						eventAction: seccion,
						eventLabel: seccion + " > " + categoria + " > " + subcategoria
					});
					break;
				case 2:
					subsubcategoria = categoria;
					subcategoria = (<HTMLScriptElement[]>(
						(<any>(
							document.querySelectorAll(
								".category-container.category-container--width .category-option.link.category-option--hover"
							)
						))
					))[0].innerText;
					categoria = (<HTMLScriptElement[]>(
						(<any>document.querySelectorAll(".department-container .department-option.department-option--hover"))
					))[0].innerText;
					this.triggerTag({
						eventName: "ui_header_menu_click",
						eventCategory: "UI :: Header - Menu",
						eventAction: seccion,
						eventLabel: seccion + " > " + categoria + " > " + subcategoria + " > " + subsubcategoria
					});

					break;
			}
		}

		if (type === "mobile") {
			const seccion = (<HTMLScriptElement[]>(
				(<any>document.querySelectorAll("fp-categories-of-menu-responsive .list-control-text.heading-1"))
			))[0].innerText;
			let subcategoria, subsubcategoria;

			subcategoria = (<HTMLScriptElement[]>(<any>document.querySelectorAll("fp-sidenav-container  fp-list-control")))[2].innerText;
			subsubcategoria = (<HTMLScriptElement[]>(<any>document.querySelectorAll("fp-sidenav-container  fp-list-control")))[3]
				.innerText;

			// tslint:disable-next-line: max-line-length
			const labelMenu = (<HTMLScriptElement[]>(
				(<any>(
					document.querySelectorAll(
						"fp-header-responsive > fp-categories-of-menu-responsive > fp-sidenav-container:nth-child(1) > mat-sidenav-container > mat-sidenav > div > div.sidenav-content > fp-list-control > div > div.list-control-text.heading-1"
					)
				))
			))[0].innerText;
			switch (level) {
				case 0:
					if (subcategoria === "") {
						this.triggerTag({
							event: "atm.event",
							eventName: "ui_header_menu_menu",
							eventCategory: "UI :: Header - Menu",
							eventAction: "Menú",
							eventLabel: labelMenu + " > " + categoria
						});
					} else {
						this.triggerTag({
							eventName: "ui_header_menu_menu",
							eventCategory: "UI :: Header - Menu",
							eventAction: "Menú",
							eventLabel: labelMenu + " > " + subcategoria + " > " + categoria
						});
					}

					break;
				case 1:
					const descMenu = subsubcategoria.length === 0 ? categoria : subsubcategoria + " > " + categoria;
					this.triggerTag({
						eventName: "ui_header_menu_menu",
						eventCategory: "UI :: Header - Menu",
						eventAction: "Menú",
						eventLabel: labelMenu + " > " + subcategoria + " > " + descMenu
					});
					break;
			}
		}
	}

	public static footer() {
		const $this = this;
		if (document.querySelectorAll("fp-footer").length) {
			const footer = document.querySelectorAll("fp-footer")[0];
			footer.querySelectorAll("a").forEach(function (a) {
				a.addEventListener("click", function () {
					let label = this.innerText || "";

					if (this.classList.contains("icon-24-facebook") || this.classList.contains("icon-24-instagram")) {
						if (this.classList.contains("icon-24-facebook")) {
							label = "Facebook";
						}
						if (this.classList.contains("icon-24-instagram")) {
							label = "Instagram";
						}
						$this.triggerTag({
							eventName: "ui_footer_outbound_link",
							eventCategory: "Outbound Link",
							eventAction: "Click",
							eventLabel: label
						});
					} else {
						$this.triggerTag({
							eventName: "ui_footer_click",
							eventCategory: "UI :: Footer",
							eventAction: "Click",
							eventLabel: label
						});
					}
				});
			});
		}
	}

	public static footerSocial(label: string): void {
		this.triggerTag({
			eventName: "ui_footer_outbound_link",
			eventCategory: "UI :: Footer",
			eventAction: "Outbound Click",
			eventLabel: label
		});
	}

	public static catalogOrder(order) {
		const label = order;
		const action = "Click";
		this.triggerTag({
			eventName: "ui_orden_click",
			eventCategory: "UI :: Orden",
			eventAction: action,
			eventLabel: label
		});
	}

	public static catalogFilter(filtro, titulo) {
		this.triggerTag({
			eventName: "ui_filtro_click",
			eventCategory: "UI :: Filtro",
			eventAction: titulo,
			eventLabel: filtro
		});
	}

	public static cartName() {
		this.triggerTag({
			eventName: "ui_cart_click",
			eventCategory: "UI :: Cart",
			eventAction: "Click",
			eventLabel: "link producto"
		});
		this.triggerTag({
			eventName: "form_checkout_carrito",
			eventCategory: "Form :: Checkout",
			eventAction: "Carrito :: interaction :: link producto",
			eventLabel: "Click"
		});
	}

	public static cartPresentation() {
		this.triggerTag({
			eventName: "ui_cart_click",
			eventCategory: "UI :: Cart",
			eventAction: "Click",
			eventLabel: "presentación"
		});
		this.triggerTag({
			eventName: "form_checkout_carrito",
			eventCategory: "Form :: Checkout",
			eventAction: "Carrito :: interaction :: presentación",
			eventLabel: "Click"
		});
	}

	public static cartQuantity() {
		this.triggerTag({
			eventName: "ui_cart_click",
			eventCategory: "UI :: Cart",
			eventAction: "Click",
			eventLabel: "cantidad"
		});
		this.triggerTag({
			eventName: "form_checkout_carrito",
			eventCategory: "Form :: Checkout",
			eventAction: "Carrito :: interaction :: cantidad",
			eventLabel: "Click"
		});
	}

	public static cartEliminar() {
		this.triggerTag({
			eventName: "ui_cart_click",
			eventCategory: "UI :: Cart",
			eventAction: "Click",
			eventLabel: "eliminar"
		});
		this.triggerTag({
			eventName: "form_checkout_carrito",
			eventCategory: "Form :: Checkout",
			eventAction: "Carrito :: interaction :: eliminar",
			eventLabel: "Click"
		});
	}

	public static productMiniCartEliminar() {
		this.triggerTag({
			eventName: "ui_cart_mini_click",
			eventCategory: "UI :: Cart Mini",
			eventAction: "Click",
			eventLabel: "Eliminar"
		});
	}

	public static productMiniCartbtnGoToCart() {
		this.triggerTag({
			eventName: "ui_cart_mini_click",
			eventCategory: "UI :: Cart Mini",
			eventAction: "Click",
			eventLabel: "Ir a mi carrito"
		});
	}

	public static productMiniCartbtnBuyNow() {
		this.triggerTag({
			eventName: "ui_cart_mini_click",
			eventCategory: "UI :: Cart Mini",
			eventAction: "Click",
			eventLabel: "Comprar ahora"
		});
	}

	public static cartView() {
		this.triggerTag({
			eventName: "form_checkout_continuar",
			eventCategory: "Form :: Checkout",
			eventAction: "Carrito :: Continuar",
			eventLabel: "ok"
		});
	}

	public static checkoutView() {
		this.triggerTag({
			eventName: "form_checkout_continuar",
			eventCategory: "Form :: Checkout",
			eventAction: "Detalle :: Continuar",
			eventLabel: "ok"
		});
	}

	public static purchaseView() {
		this.triggerTag({
			eventName: "form_checkout_continuar",
			eventCategory: "Form :: Checkout",
			eventAction: "Purchase :: Continuar",
			eventLabel: "ok"
		});
	}

	public static clickFinishPurchase(methodPay: string) {
		this.triggerTag({
			eventName: "form_checkout_finalizar_compra_intento_" + methodPay,
			eventCategory: "Form :: Checkout",
			eventAction: "finalizar_compra :: intento_" + methodPay,
			eventLabel: "intento_" + methodPay
		});
	}

	public static checkoutErrorModalAgora(isRetOrRad, serviceType?) {
		const deliveryType = isRetOrRad == 0 ? "RAD" : "RET";
		const deliveryserviceType = `${deliveryType}${serviceType ? `_${serviceType}` : ""}`;
		this.triggerTag({
			eventName: "ui_error_checkout_agora",
			eventCategory: "Form :: Checkout :: Agora",
			eventAction: "error_modal_agora",
			eventLabel: "finalizar_compra :: error_agora"
		});
	}

	public static checkoutOpenModalAgora(isRetOrRad, serviceType?) {
		const deliveryType = isRetOrRad == 0 ? "RAD" : "RET";
		const deliveryserviceType = `${deliveryType}${serviceType ? `_${serviceType}` : ""}`;
		this.triggerTag({
			eventName: "ui_form_checkout_finalizar_compra_agora",
			eventCategory: "Form :: Checkout :: Agora",
			eventAction: "open_modal_agora",
			eventLabel: "finalizar_compra :: open_modal_agora"
		});
	}

	public static checkoutCloseModalAgora(isRetOrRad, serviceType?) {
		const deliveryType = isRetOrRad == 0 ? "RAD" : "RET";
		const deliveryserviceType = `${deliveryType}${serviceType ? `_${serviceType}` : ""}`;
		this.triggerTag({
			eventName: "ui_form_checkout_finalizar_close_agora",
			eventCategory: "Form :: Checkout :: Agora",
			eventAction: "close_modal_agora",
			eventLabel: "finalizar_compra :: close_modal_agora"
		});
	}

	public static purchaseSuccessAgora(isRetOrRad, serviceType?) {
		const deliveryType = isRetOrRad == 0 ? "RAD" : "RET";
		const deliveryserviceType = `${deliveryType}${serviceType ? `_${serviceType}` : ""}`;
		this.triggerTag({
			eventName: "ui_form_checkout_finalizar_success_agora",
			eventCategory: "Form :: Checkout :: Agora",
			eventAction: "purchase_success_agora",
			eventLabel: "finalizar_compra :: success_agora"
		});
	}

	public static checkoutError(errorMessage: string) {
		if (errorMessage !== "") {
			this.triggerTag({
				eventName: "error_checkout",
				eventCategory: "Error",
				eventAction: "Checkout",
				eventLabel: errorMessage
			});
		}
	}

	public static checkoutPaymentMethod(type: string): void {
		this.triggerTag({
			eventName: "ui_checkout_checkbox",
			eventCategory: "UI :: Checkout",
			eventAction: "Checkbox",
			eventLabel: type
		});
		this.triggerTag({
			eventName: "form_checkout_detalle",
			eventCategory: "Form :: Checkout",
			eventAction: "Detalle :: interaction :: ¿Cómo quieres pagar?",
			eventLabel: "Click"
		});
	}

	public static checkoutPaymentMethodRadio(methodName: string, card: string, deliveryType, paymentGateway?): void {
		this.paymentMethodName = methodName;
		this.cardMethodName = card;
		const methods = ["Visa", "Mastercard"];
		const someMehtod = methods.some((palabra) => card.includes(palabra));
		let cardName = someMehtod ? card.split(" ")[0] : card;
		const label = card ? `${methodName}-${cardName}-` : `${methodName}-`;
		let typMethod = "";
		switch (methodName) {
			case PAYMENT_METHODS_TEXT.ONLINE_PAYMENT:
				typMethod = paymentGateway === PaymentGatewayEnum.EPAGO ? "epago" : "online";
				break;
			case PAYMENT_METHODS_TEXT.APP_AGORA_PAY_PAYMENT:
				typMethod = "agora";
				break;
			case PAYMENT_METHODS_TEXT.POS_PAYMENT:
				typMethod = "PosEntrega";
				break;
			case PAYMENT_METHODS_TEXT.CASH_PAYMENT:
				typMethod = "efectivo";
				break;
		}
		this.triggerTag({
			eventName: "ui_checkout_checkbox_" + typMethod,
			eventCategory: "UI :: Checkout",
			eventAction: "Checkbox",
			eventLabel: label + `${deliveryType}`
		});
		this.triggerTag({
			eventName: "form_checkout_detalle",
			eventCategory: "Form :: Checkout",
			eventAction: "Detalle :: interaction :: " + `${cardName ? `${cardName} :: ` : ""}` + `${deliveryType}`,
			eventLabel: "Click"
		});
	}

	public static orderMotivoCancelation(description) {
		this.triggerTag({
			eventName: "ui_order_select",
			eventCategory: "UI :: Order Cancelation",
			eventAction: "select",
			eventLabel: description
		});
	}

	public static orderActions(type) {
		this.triggerTag({
			eventName: "ui_order_click",
			eventCategory: "UI :: Order Actions",
			eventAction: "Click",
			eventLabel: type
		});
	}

	public static orderItem(orderItem) {
		this.triggerTag({
			eventName: "ui_order_click",
			eventCategory: "UI :: Item Order",
			eventAction: "Click",
			eventLabel: orderItem
		});
	}

	public static checkoutSwitchvoucher(isChecked) {
		this.triggerTag({
			eventName: "ui_checkout_click",
			eventCategory: "UI :: Checkout",
			eventAction: "Click",
			eventLabel: "Solicita un comprobante de pago - " + isChecked
		});
		this.triggerTag({
			eventName: "form_checkout_detalle",
			eventCategory: "Form :: Checkout",
			eventAction: "Detalle :: interaction :: Solicita un comprobante de pago",
			eventLabel: "change"
		});
	}

	public static checkoutRadioMeAddressChange(name) {
		this.triggerTag({
			eventName: "ui_checkout_checkbox",
			eventCategory: "UI :: Checkout",
			eventAction: "Click",
			eventLabel: "cambiar dirección: " + name
		});
	}

	public static checkoutSelectDeliveryDay() {
		this.triggerTag({
			eventName: "ui_checkout_select",
			eventCategory: "UI :: Checkout",
			eventAction: "change",
			eventLabel: "elige un horario de entrega - día"
		});
		this.triggerTag({
			eventName: "form_checkout_detalle",
			eventCategory: "Form :: Checkout",
			eventAction: "Detalle :: interaction :: Elige un horario de entrega - día",
			eventLabel: "change"
		});
	}

	public static checkoutSelectDeliveryTime() {
		this.triggerTag({
			eventName: "ui_checkout_select",
			eventCategory: "UI :: Checkout",
			eventAction: "change",
			eventLabel: "elige un horario de entrega - hora"
		});
		this.triggerTag({
			eventName: "form_checkout_detalle",
			eventCategory: "Form :: Checkout",
			eventAction: "Detalle :: interaction :: Elige un horario de entrega - hora",
			eventLabel: "change"
		});
	}

	public static checkoutRadioAddress(direccion) {
		this.triggerTag({
			eventName: "ui_checkout_checkbox",
			eventCategory: "UI :: Checkout",
			eventAction: "Checkbox",
			eventLabel: "¿En dónde recibirás tu pedido? - " + direccion
		});
		this.triggerTag({
			eventName: "ui_checkout_entrega",
			eventCategory: "UI :: Checkout",
			eventAction: "Entrega",
			eventLabel: direccion
		});
		this.triggerTag({
			eventName: "form_checkout_detalle",
			eventCategory: "Form :: Checkout",
			eventAction: "Detalle :: interaction :: ¿En dónde recibirás tu pedido?",
			eventLabel: "Click"
		});
	}

	public static RetClickDeliveryTypeDialog(isRad) {
		this.triggerTag({
			eventName: "ui_modal_delivery_type_click",
			eventCategory: "UI :: Modal",
			eventAction: "Click",
			eventLabel: isRad ? "cambiar_rad" : "cambiar_ret"
		});
	}

	public static RetCloseDeliveryTypeDialog() {
		this.triggerTag({
			eventName: "ui_modal_delivery_type_click",
			eventCategory: "UI :: Modal",
			eventAction: "Click",
			eventLabel: "Cerrar"
		});
	}

	public static RetShowDeliveryTypeDialog(isRad) {
		this.triggerTag({
			eventName: "ui_modal_delivery_type",
			eventCategory: "UI :: Modal",
			eventAction: "Event",
			eventLabel: isRad ? "desde_rad" : "desde_ret"
		});
	}

	public static RetSaveDeliveryTypeDialog(isRad) {
		this.triggerTag({
			eventName: "ui_modal_delivery_type_click_next",
			eventCategory: "UI :: Modal",
			eventAction: "Click",
			eventLabel: isRad ? "cambiar_rad" : "cambiar_ret"
		});
	}

	public static RetClickDeliveryTypeCheckbox(isRad) {
		this.triggerTag({
			eventName: "ui_modal_delivery_type_radio",
			eventCategory: "UI :: Modal",
			eventAction: "Radio",
			eventLabel: isRad ? "cambiar_rad" : "cambiar_ret"
		});
	}

	public static RetDeliveryValidate(isRad) {
		this.triggerTag({
			eventName: "ui_modal_delivery_validate",
			eventCategory: "UI :: Modal",
			eventAction: "Event",
			eventLabel: isRad ? "flujo_rad" : "flujo_ret"
		});
	}

	public static RetAddCartDeliveryValidate(isRad) {
		this.triggerTag({
			eventName: "ui_modal_delivery_validate",
			eventCategory: "UI :: Modal",
			eventAction: "Event",
			eventLabel: isRad ? "agregar_rad" : "agregar_ret"
		});
	}

	public static RetChangeTypeDeliveryValidate(isRad) {
		this.triggerTag({
			eventName: isRad ? "ui_product_click_drugstore_rad" : "ui_product_click_drugstore_ret",
			eventCategory: "UI :: Product",
			eventAction: "Click",
			eventLabel: "Consultar"
		});
	}

	public static RetUiDrugstorePageview() {
		this.triggerTag({
			eventName: "ui_drugstore_pageview",
			eventCategory: "UI :: Drugstore",
			eventAction: "Pageview",
			eventLabel: "drugstore"
		});
	}

	public static RetUiProductClickDrugstore(isRad) {
		this.triggerTag({
			eventName: "ui_modal_delivery_validate_click",
			eventCategory: "UI :: Modal",
			eventAction: "Click",
			eventLabel: isRad ? "cambiar_rad" : "cambiar_ret"
		});
	}

	public static RetCloseDeliveryValidate() {
		this.triggerTag({
			eventName: "ui_modal_delivery_validate_click",
			eventCategory: "UI :: Modal",
			eventAction: "Click",
			eventLabel: "Cerrar"
		});
	}

	public static impressions(productList, page) {
		const gaData: Array<any> = (<any>(<any>window)).ga ? (<any>(<any>window)).ga.getAll() : [];
		const clientId = gaData.length ? gaData[0].get("clientId") : null;
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const imp = [];

		let item_list;
		if (page === "home") {
			item_list = productList.title.toLowerCase();
			productList.productList.forEach(function (prod, idx) {
				const productDefault = prod.presentations?.find((e) => e.id === prod.defaultPresentation);
				imp.push({
					id: prod.id,
					name: prod.name,
					price: productDefault?.price,
					quantity: 1,
					position: idx,
					list: item_list
				});
			});
		}

		if (page == null) {
			if (/\/buscador/.test(window.location.pathname)) {
				item_list = "buscador";
			} else if (/\/categoria\//.test(window.location.pathname)) {
				item_list = "catalogo";
			} else {
				item_list = "-";
			}
			productList.forEach(function (prod, idx) {
				const productDefault = prod.presentations?.find((e) => e.id === prod.defaultPresentation);
				imp.push({
					id: prod.id,
					name: prod.name,
					price: productDefault?.price,
					quantity: 1,
					position: idx,
					list: item_list
				});
			});
		}

		const user = (<any>(<any>window)).dataUser;
		dataLayer.push({
			event: "atm.ecommerce",
			eventName: "view_item_list",
			eventCategory: "Ecommerce",
			eventAction: "Impression",
			eventLabel: item_list,
			firebase_id: user ? user.authId : "",
			dimension2: user ? user.encryptedEmail : "",
			dimension3: user ? user.encryptedDni : "",
			dimension4: clientId || "",
			dimension13: "",
			dimension14: "",
			dimension17: "web",
			firebase: {
				item_list: item_list,
				items: imp
			},
			ecommerce: {
				currencyCode: "PEN",
				impressions: imp
			}
		});
	}

	public static productClick(product) {
		const gaData: Array<any> = (<any>(<any>window)).ga ? (<any>(<any>window)).ga.getAll() : [];
		const clientId = gaData.length ? gaData[0].get("clientId") : null;
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const user = (<any>(<any>window)).dataUser;
		dataLayer.push({
			event: "atm.ecommerce",
			eventName: "select_item",
			eventCategory: "Ecommerce",
			eventAction: "Product Click",
			eventLabel: product.name,
			firebase_id: user ? user.authId : "",
			dimension2: user ? user.encryptedEmail : "",
			dimension3: user ? user.encryptedDni : "",
			dimension4: clientId || "",
			dimension13: product.id || "",
			dimension14: "",
			dimension17: "web",
			firebase: {
				id: product.id,
				name: product.name,
				items: [
					{
						id: product.id,
						name: product.name,
						price: product?.priceList,
						variant: product?.presentations
					}
				]
			},
			ecommerce: {
				click: {
					products: [
						{
							id: product.id,
							name: product.name,
							price: product?.priceList,
							variant: product?.presentations
						}
					]
				}
			}
		});
	}

	public static detail(product) {
		const gaData: Array<any> = (<any>(<any>window)).ga ? (<any>(<any>window)).ga.getAll() : [];
		const clientId = gaData.length ? gaData[0].get("clientId") : null;
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const user = (<any>(<any>window)).dataUser;
		const codCategory = null;

		const productDefault = product.presentations?.find((e) => e.id === product.defaultPresentation);
		dataLayer.push({
			event: "atm.ecommerce",
			eventName: "view_item",
			eventCategory: "Ecommerce",
			eventAction: "Product Detail",
			eventLabel: product.name,
			firebase_id: user ? user.authId : "",
			dimension2: user ? user.encryptedEmail : "",
			dimension3: user ? user.encryptedDni : "",
			dimension4: clientId || "",
			dimension13: product.id || "",
			dimension14: codCategory || "",
			dimension17: "web",
			firebase: {
				id: product.id,
				name: product.name,
				image: product.images ? product.images.image : product.presentations,
				items: [
					{
						id: product.id,
						name: product.name,
						price: productDefault?.price,
						currency: "PEN",
						quantity: 1
					}
				]
			},
			ecommerce: {
				detail: {
					products: [
						{
							id: product.id,
							name: product.name,
							price: productDefault?.price,
							currency: "PEN",
							quantity: 1
						}
					]
				}
			}
		});
	}

	public static addToCartSDK(product, presentation, qnt) {
		const dataLayer = (<any>(<any>window)).dataLayer || [];

		dataLayer.push({
			event: "AddToCart",
			ecommerce: {
				products: {
					_id: product.id,
					price: presentation.price,
					quantity: qnt
				}
			}
		});
	}

	public static removeFromCartSDK(product, price, qnt = 1) {
		const dataLayer = (<any>(<any>window)).dataLayer || [];

		dataLayer.push({
			event: "RemoveFromCart",
			ecommerce: {
				products: {
					_id: product.id,
					price: price,
					quantity: qnt
				}
			}
		});
	}

	public static updateCartSDK(product, price, qnt = 0) {
		const dataLayer = (<any>(<any>window)).dataLayer || [];

		dataLayer.push({
			event: "UpdateLineItem",
			ecommerce: {
				products: {
					_id: product.id,
					price: price,
					quantity: qnt
				}
			}
		});
	}

	public static viewCartSDK(products: ProductShoppingCart[]) {
		const dataLayer = (<any>(<any>window)).dataLayer || [];

		const ids = [];
		const prices = [];
		const quantitys = [];

		products.forEach((item) => {
			ids.push(item.id);
			prices.push(item.subTotal);
			quantitys.push(item.quantity);
		});

		dataLayer.push({
			event: "ViewCart",
			ecommerce: {
				products: {
					_id: ids,
					price: prices,
					quantity: quantitys
				}
			}
		});
	}

	public static purchaseSDK(products: ProductShoppingCart[], id: number) {
		const dataLayer = (<any>(<any>window)).dataLayer || [];

		const ids = [];
		const prices = [];
		const quantitys = [];

		products.forEach((item) => {
			ids.push(item.id);
			prices.push(item.subTotal);
			quantitys.push(item.quantity);
		});

		dataLayer.push({
			event: "Purchase",
			ecommerce: {
				products: {
					orderId: id,
					_id: ids,
					price: prices,
					quantity: quantitys
				}
			}
		});
	}

	public static addToCart(product, presentation) {
		const gaData: Array<any> = (<any>(<any>window)).ga ? (<any>(<any>window)).ga.getAll() : [];
		const clientId = gaData.length ? gaData[0].get("clientId") : null;
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		this.fetchData(ENDPOINTS.GET_PRODUCTS_BY_ID + product.id, function (error, data) {
			if (error) {
				return console.error(error);
			}
			const product_ = data;
			const user = (<any>(<any>window)).dataUser;
			const tmp = [];
			if (product_.categoryList) {
				product_.categoryList.forEach(function (e, i) {
					tmp[i] = e.id;
				});
			}
			const codCategory = tmp.join("/");
			dataLayer.push({
				event: "atm.ecommerce",
				eventName: "add_to_cart",
				eventCategory: "Ecommerce",
				eventAction: "Add To Cart",
				eventLabel: product.name,
				firebase_id: user ? user.authId : "",
				dimension2: user ? user.encryptedEmail : "",
				dimension3: user ? user.encryptedDni : "",
				dimension4: clientId || "",
				dimension13: product.id || "",
				dimension14: codCategory || "",
				dimension17: "web",
				firebase: {
					id: product.id,
					name: product.name,
					price: presentation.price,
					currency: "PEN",
					quantity: 1,
					variant: presentation.description,
					image: product.images ? product.images.image : presentation.image,
					items: [
						{
							id: product.id,
							name: product.name,
							price: presentation.price,
							variant: presentation.description,
							position: 0,
							quantity: 1
						}
					]
				},
				ecommerce: {
					currencyCode: "PEN",
					add: {
						products: [
							{
								id: product.id,
								name: product.name,
								price: presentation.price,
								variant: presentation.description,
								position: 0,
								quantity: 1
							}
						]
					}
				}
			});
		});
	}

	public static removeFromCart(product, initialPrice, initialPresentation, quantityTotal = 0, type = false) {
		const gaData: Array<any> = (<any>(<any>window)).ga ? (<any>(<any>window)).ga.getAll() : [];
		const clientId = gaData.length ? gaData[0].get("clientId") : null;
		const dataLayer = (<any>(<any>window)).dataLayer || [];

		this.fetchData(ENDPOINTS.GET_PRODUCTS_BY_ID + product.id, function (error, data) {
			if (error) {
				return console.error(error);
			}
			const product_ = data;
			let quantity;
			const user = (<any>(<any>window)).dataUser;
			if (type === true) {
				quantity = product.quantity;
			} else {
				quantity = 1;
			}
			if (quantityTotal !== 0) {
				quantity = quantityTotal;
			}
			const tmp = [];
			product_.categoryList.forEach(function (e, i) {
				tmp[i] = e.id;
			});
			const codCategory = tmp.join("/");
			dataLayer.push({
				event: "atm.ecommerce",
				eventName: "remove_from_cart",
				eventCategory: "Ecommerce",
				eventAction: "Remove From Cart",
				eventLabel: product.name,
				firebase_id: user ? user.authId : "",
				dimension2: user ? user.encryptedEmail : "",
				dimension3: user ? user.encryptedDni : "",
				dimension4: clientId || "",
				dimension13: product.id || "",
				dimension14: codCategory || "",
				dimension17: "web",
				firebase: {
					id: product.id,
					name: product.name,
					price: initialPrice,
					variant: initialPresentation,
					position: 0,
					quantity: quantity,
					items: [
						{
							id: product.id,
							name: product.name,
							price: initialPrice,
							variant: initialPresentation,
							position: 0,
							quantity: quantity
						}
					]
				},
				ecommerce: {
					remove: {
						products: [
							{
								id: product.id,
								name: product.name,
								price: initialPrice,
								variant: initialPresentation,
								position: 0,
								quantity: quantity
							}
						]
					}
				}
			});
		});
	}

	public static checkout(productList) {
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const valueFix = productList.map((e) => e.subTotal).reduce((a, b) => a + b);
		const products = productList.map((ele) => {
			let presentation: any;

			if (ele.presentations) {
				presentation = ele.presentations?.find((e) => e.id === ele.presentationIdSelected);
			}
			if (ele.skuVariants && ele.skuVariants.length > 0) {
				let variantVal = "";
				ele.skuVariants.forEach((item) => {
					if (ele.id === item.sku) {
						variantVal = item.description;
					}
				});
				return {
					id: ele.id,
					name: ele.name,
					price: presentation ? presentation.price : ele.subTotal,
					quantity: ele.quantity ? ele.quantity : ele.quantitySelected,
					brand: ele.brand,
					category: this.getCategoryStr(ele.categoryList),
					variant: variantVal
				};
			} else {
				return {
					id: ele.id,
					name: ele.name,
					price: presentation ? presentation.price : ele.subTotal,
					quantity: ele.quantity ? ele.quantity : ele.quantitySelected,
					brand: ele.brand,
					category: this.getCategoryStr(ele.categoryList)
				};
			}
		});
		if (productList.length) {
			const paramsAnalytics = {
				event: "atm.ecommerce",
				eventName: "begin_checkout",
				eventCategory: "Ecommerce",
				eventAction: "Checkout",
				eventLabel: "-",
				firebase: {
					currency: "PEN",
					value: valueFix,
					checkout_step: 1,
					items: products
				},
				ecommerce: {
					checkout: {
						actionField: {
							step: 1
						},
						currencyCode: "PEN",
						products: products
					}
				}
			};
			dataLayer.length = 0;
			dataLayer.push({ ...paramsAnalytics, ...this.getDimensionData });
		}
	}

	public static addShippingInfo(cartConfirmationResponse) {
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const paramsAnalytics = {
			event: "atm.ecommerce",
			eventName: "add_shipping_info",
			eventCategory: "Ecommerce",
			eventAction: "Add Shipping Info",
			eventLabel: cartConfirmationResponse.orderInfo.deliveryTypeDescription,
			firebase: {
				shipping_tier: cartConfirmationResponse.orderInfo.deliveryTypeDescription,
				items: []
			},
			ecommerce: {
				checkout_option: {
					actionField: {
						step: 2,
						option: cartConfirmationResponse.orderInfo.deliveryTypeDescription
					}
				}
			}
		};
		dataLayer.length = 0;
		dataLayer.push({ ...paramsAnalytics, ...this.getDimensionData });
	}

	public static addPaymentInfo() {
		const label = this.cardMethodName ? `${this.paymentMethodName} - ${this.cardMethodName}` : `${this.paymentMethodName}`;
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const paramsAnalytics = {
			event: "atm.ecommerce",
			eventName: "add_payment_info",
			eventCategory: "Ecommerce",
			eventAction: "Add Payment Info",
			eventLabel: label,
			firebase: {
				payment_type: label,
				items: []
			},
			ecommerce: {
				checkout_option: {
					actionField: {
						step: 3,
						option: label
					}
				}
			}
		};
		dataLayer.length = 0;
		dataLayer.push({ ...paramsAnalytics, ...this.getDimensionData });
	}

	public static purchase(ProductList, cartConfirmationResponse) {
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const gaData: Array<any> = (<any>(<any>window)).ga ? (<any>(<any>window)).ga.getAll() : [];
		const clientId = gaData.length ? gaData[0].get("clientId") : null;
		let sellerProducts = [];
		if (ProductList.productItemSellersList) {
			ProductList.productItemSellersList.forEach((item) => {
				sellerProducts = [...sellerProducts, ...item.products];
			});
		}
		const uniqueShoppingList = [...ProductList.shoppingList, ...sellerProducts];
		const products = uniqueShoppingList.map((ele) => {
			return {
				id: ele.id,
				name: ele.name,
				price: ele.quantity ? ele.subTotal / ele.quantity : ele.price,
				quantity: ele.quantity || ele.quantitySelected,
				currency: "PEN",
				brand: ele.brand,
				category: this.getCategoryStr(ele.categoryList)
			};
		});
		let totalCostValue = 0.0;
		let shoppingCartId = 0;
		if (Object.keys(cartConfirmationResponse.orderInfo).length) {
			totalCostValue = cartConfirmationResponse.orderInfo.totalCost;
			shoppingCartId = cartConfirmationResponse.orderInfo.shoppingCartId;
		} else {
			shoppingCartId = cartConfirmationResponse.orders[0].shoppingCartId;
			cartConfirmationResponse.orders.forEach((item) => {
				totalCostValue = totalCostValue + item.totalCost;
			});
		}

		const value = ProductList.shoppingList.reduce((acc, val) => acc + val, 0);
		const user = (<any>(<any>window)).dataUser;
		dataLayer.push({
			event: "atm.ecommerce",
			eventName: "purchase",
			eventCategory: "Ecommerce",
			eventAction: "Purchase",
			eventLabel: shoppingCartId,
			firebase_id: user ? user.authId : "",
			user: {
				email: user ? user.email : ""
			},
			dimension2: user ? user.encryptedEmail : "",
			dimension3: user ? user.encryptedDni : "",
			dimension4: clientId || "",
			dimension13: "",
			dimension14: "",
			dimension17: "web",
			firebase: {
				transactionId: shoppingCartId,
				currency: "PEN",
				value: totalCostValue,
				items: products
			},
			ecommerce: {
				purchase: {
					actionField: {
						id: shoppingCartId,
						revenue: totalCostValue
					},
					products: products,
					productsSkus: products.map((product) => String(product.id))
				}
			}
		});
	}

	public static refund(orderId) {
		const gaData: Array<any> = (<any>(<any>window)).ga ? (<any>(<any>window)).ga.getAll() : [];
		const clientId = gaData.length ? gaData[0].get("clientId") : null;
		const dataLayer = (<any>(<any>window)).dataLayer || [];

		const user = (<any>(<any>window)).dataUser;
		dataLayer.push({
			event: "atm.ecommerce",
			eventName: "refund",
			eventCategory: "Ecommerce",
			eventAction: "Refund",
			eventLabel: "-",
			firebase_id: user ? user.authId : "",
			dimension2: user ? user.encryptedEmail : "",
			dimension3: user ? user.encryptedDni : "",
			dimension4: clientId || "",
			dimension13: "",
			dimension14: "",
			dimension17: "web",
			firebase: {
				transactionId: orderId
			},
			ecommerce: {
				refund: {
					actionField: {
						id: orderId
					}
				}
			}
		});
	}

	private static getCategoryStr(categoryList: Array<any>): string {
		if (!categoryList || !categoryList.length) {
			return "";
		}
		let category = categoryList[0];
		let catString = "";
		do {
			catString = catString + category.name.replaceAll("/", "-") + "/";
			category = category.categories[0];
		} while (category);
		return catString.substr(0, catString.length - 1);
	}

	public static headerTopInterlinks(linkName) {
		this.triggerTag({
			eventName: "ui_header_top_interlinks",
			eventCategory: "UI :: Header - Top",
			eventAction: "Interlinks",
			eventLabel: linkName
		});
	}

	public static ecommercePromotion(productList, datos) {
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const gaData: Array<any> = (<any>(<any>window)).ga ? (<any>(<any>window)).ga.getAll() : [];
		const clientId = gaData.length ? gaData[0].get("clientId") : null;
		const user = (<any>(<any>window)).dataUser;

		if (productList.length) {
			const paramsAnalytics = {
				event: "atm.ecommerce",
				eventName: datos.eventName,
				eventCategory: "Ecommerce",
				eventAction: datos.eventAction || "",
				eventLabel: datos.eventLabel || "",
				nonInteraction: datos.nonInteraction || 1,
				firebase_id: user ? user.authId : "",
				dimension2: user ? user.encryptedEmail : "",
				dimension3: user ? user.encryptedDni : "",
				dimension4: clientId || "",
				dimension13: "",
				dimension14: "",
				dimension17: "web",
				ecommerce: {
					promoView: {
						promotions: productList
					}
				}
			};
			dataLayer.length = 0;
			dataLayer.push({ ...paramsAnalytics, ...this.getDimensionData });
		}
	}

	public static ecommercePromotionClick(productList, datos) {
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const gaData: Array<any> = (<any>(<any>window)).ga ? (<any>(<any>window)).ga.getAll() : [];
		const clientId = gaData.length ? gaData[0].get("clientId") : null;
		const user = (<any>(<any>window)).dataUser;

		if (productList.length) {
			const paramsAnalytics = {
				event: "atm.ecommerce",
				eventName: datos.eventName,
				eventCategory: "Ecommerce",
				eventAction: datos.eventAction || "",
				eventLabel: datos.eventLabel || "",
				nonInteraction: datos.nonInteraction || 1,
				firebase_id: user ? user.authId : "",
				dimension2: user ? user.encryptedEmail : "",
				dimension3: user ? user.encryptedDni : "",
				dimension4: clientId || "",
				dimension13: "",
				dimension14: "",
				dimension17: "web",
				ecommerce: {
					promoClick: {
						promotions: productList
					}
				}
			};
			dataLayer.length = 0;
			dataLayer.push({ ...paramsAnalytics, ...this.getDimensionData });
		}
	}

	public static eventRET_3_0(events) {
		this.triggerTag({
			eventName: "ui_header_top_interlinks",
			eventCategory: "UI :: Header - Top",
			eventAction: "Interlinks",
			eventLabel: ""
		});
	}

	public static productClickBrain(product, sectionID, type = "home") {
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const typeName = type.charAt(0).toUpperCase() + type.slice(1);
		dataLayer.push({
			event: "GA-brain",
			eventName: `ui_${type}_click_product`,
			eventCategory: `UI :: ${typeName}`,
			eventAction: "Click",
			eventLabel: product.name,
			ecommerce: {
				currencyCode: "PEN",
				click: {
					actionField: {
						list: sectionID
					},
					products: [
						{
							id: product.id,
							name: product.name,
							// price: product.presentations[0].regularPrice > 0 ? product.presentations[0].regularPrice : product.presentations[0].price,
							// variant: product.presentations[0].presentation,
							dimension: "Braindw"
						}
					]
				}
			}
		});
	}

	public static productAddToCartBrain(product, sectionID, quantity, type = "home") {
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const typeName = type.charAt(0).toUpperCase() + type.slice(1);
		dataLayer.push({
			event: "GA-brain",
			eventName: `ui_${type}_add_to_cart_brain`,
			eventCategory: `UI :: ${typeName}`,
			eventAction: "Add To Cart",
			eventLabel: product.name,
			ecommerce: {
				currencyCode: "PEN",
				add: {
					actionField: {
						list: sectionID,
						action: "add"
					},
					products: [
						{
							id: product.id,
							name: product.name,
							// price: product.presentations[0].regularPrice > 0 ? product.presentations[0].regularPrice : product.presentations[0].price,
							// variant: product.presentations[0].presentation,
							quantity: quantity,
							dimension: "Braindw"
						}
					]
				}
			}
		});
	}

	public static showCarrouselBrain(products, sectionID, type = "home") {
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const typeName = type.charAt(0).toUpperCase() + type.slice(1);
		dataLayer.push({
			event: "GA-brain",
			eventName: `ui_${type}_show_brain_dw_gondola`,
			eventCategory: `UI :: ${typeName}`,
			eventAction: "Show Brain DW",
			eventLabel: sectionID,
			ecommerce: {
				currencyCode: "PEN",
				impressions: products.map((product, i) => {
					return {
						name: product.name,
						id: product.id,
						// price: product.presentations[0].regularPrice > 0 ? product.presentations[0].regularPrice : product.presentations[0].price,
						// variant: product.presentations[0].presentation,
						list: sectionID,
						position: i + 1,
						dimension: "Braindw"
					};
				})
			}
		});
	}

	public static clickNextBrain(products, sectionID, type = "home") {
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const typeName = type.charAt(0).toUpperCase() + type.slice(1);
		dataLayer.push({
			event: "GA-brain",
			eventName: `ui_${type}_click_arrows`,
			eventCategory: `UI :: ${typeName}`,
			eventAction: "Click",
			eventLabel: sectionID,
			ecommerce: {
				currencyCode: "PEN",
				impressions: products.map((product, i) => {
					return {
						name: product.name,
						id: product.id,
						// price: product.presentations[0].regularPrice > 0 ? product.presentations[0].regularPrice : product.presentations[0].price,
						// variant: product.presentations[0].presentation,
						list: sectionID,
						position: i + 1,
						dimension: "Braindw"
					};
				})
			}
		});
	}

	public static showMoreBrain(products, sectionID, type = "home") {
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		const typeName = type.charAt(0).toUpperCase() + type.slice(1);
		dataLayer.push({
			event: "GA-brain",
			eventName: `ui_${type}_click_show_more`,
			eventCategory: `UI :: ${typeName}`,
			eventAction: "Click",
			eventLabel: sectionID,
			ecommerce: {
				currencyCode: "PEN",
				impressions: products.map((product, i) => {
					return {
						name: product.name,
						id: product.id,
						// price: product.presentations[0].regularPrice > 0 ? product.presentations[0].regularPrice : product.presentations[0].price,
						// variant: product.presentations[0].presentation,
						list: sectionID,
						position: i + 1,
						dimension: "Braindw"
					};
				})
			}
		});
	}

	public static bannerClickBrain(sectionID, type = "home") {
		const typeName = type.charAt(0).toUpperCase() + type.slice(1);
		const dataLayer = (<any>(<any>window)).dataLayer || [];
		dataLayer.push({
			event: "GA-brain",
			eventName: `ui_${type}_click_banner_brain`,
			eventCategory: `UI :: ${typeName}`,
			eventAction: "Click Banner Brain",
			eventLabel: sectionID,
			ecommerce: {
				currencyCode: "PEN",
				click: {
					actionField: {
						list: sectionID
					}
				}
			}
		});
	}

	public static openFilter() {
		this.triggerTag({
			eventName: "ui_algolia_search",
			eventCategory: "UI :: Search",
			eventAction: "Click",
			eventLabel: "filtros"
		});
	}

	public static clearFilter() {
		this.triggerTag({
			eventName: "ui_algolia_search",
			eventCategory: "UI :: Search",
			eventAction: "Click",
			eventLabel: "limpiar"
		});
	}

	public static checkFilterTag(categoriesSelected, facet) {
		this.triggerTag({
			eventName: "ui_algolia_search_filter",
			eventCategory: "UI :: Search",
			eventAction: facet,
			eventLabel: categoriesSelected
		});
	}

	public static viewAllProductsTag(keyboard?) {
		this.triggerTag({
			eventName: "ui_search_click_ver_todos",
			eventCategory: "UI :: Search",
			eventAction: "Click",
			eventLabel: keyboard ? "Keyboard" : "Item"
		});
	}

	public static orderProductsFilter(selected) {
		this.triggerTag({
			eventName: "ui_algolia_search_order",
			eventCategory: "UI :: Search",
			eventAction: "Select",
			eventLabel: selected
		});
	}

	public static searchErrorAlgolia() {
		this.triggerTag({
			eventName: "ui_algolia_search_error",
			eventCategory: "UI :: Search",
			eventAction: "Error",
			eventLabel: "Error"
		});
	}

	public static widgetViewClickOpen() {
		this.triggerTag({
			eventName: EnumTagEventName.UI_WIDGET_VIEW_CLICK_OPEN,
			eventCategory: EnumTagEventCategory.UI_WIDGET,
			eventAction: EnumTagEventAction.CLICK,
			eventLabel: "Open"
		});
	}

	public static widgetClickCloseCanales() {
		this.triggerTag({
			eventName: EnumTagEventName.UI_WIDGET_CLICK_CLOSE_CANALES,
			eventCategory: EnumTagEventCategory.UI_WIDGET,
			eventAction: EnumTagEventAction.CLICK,
			eventLabel: "Close"
		});
	}

	public static widgetViewClickCloseTxt() {
		this.triggerTag({
			eventName: EnumTagEventName.UI_WIDGET_VIEW_CLICK_CLOSE_TXT,
			eventCategory: EnumTagEventCategory.UI_WIDGET,
			eventAction: EnumTagEventAction.CLICK,
			eventLabel: "Close"
		});
	}

	public static widgetClickAliviamed() {
		this.triggerTag({
			eventName: EnumTagEventName.UI_WIDGET_CLICK_ALIVIAMED,
			eventCategory: EnumTagEventCategory.UI_WIDGET,
			eventAction: EnumTagEventAction.CLICK,
			eventLabel: "Aliviamed"
		});
	}

	public static widgetClickWhatsapp() {
		this.triggerTag({
			eventName: EnumTagEventName.UI_WIDGET_CLICK_WHATSAPP,
			eventCategory: EnumTagEventCategory.UI_WIDGET,
			eventAction: EnumTagEventAction.CLICK,
			eventLabel: "Whatsapp"
		});
	}

	public static widgetClickCallCenter() {
		this.triggerTag({
			eventName: EnumTagEventName.UI_WIDGET_CLICK_CALLCENTER,
			eventCategory: EnumTagEventCategory.UI_WIDGET,
			eventAction: EnumTagEventAction.CLICK,
			eventLabel: "Call Center"
		});
	}
	public static brandButton(name) {
		this.triggerTag({
			eventName: "ui_lista_boton_marca",
			eventCategory: "UI :: Lista",
			eventAction: "Click",
			eventLabel: name
		});
	}

	public static tagEventAllRet(eventName, eventCategory, eventAction, eventLabel) {
		this.triggerTag({
			eventName: eventName,
			eventCategory: eventCategory,
			eventAction: eventAction,
			eventLabel: eventLabel
		});
	}

	public static AlgAddToCart(product) {
		this.triggerTag({
			eventName: "add_to_cart",
			eventCategory: "Ecommerce",
			eventAction: "Add To Cart",
			eventLabel: product
		});
	}

	public static AlgAddToCartPredictive(productName) {
		this.triggerTag({
			eventName: "ui_predictive_add_to_cart",
			eventCategory: "UI :: Search",
			eventAction: "Click",
			eventLabel: productName
		});
	}

	public static AlgShowAllProducts() {
		this.triggerTag({
			eventName: "ui_search_click_ver_todos",
			eventCategory: "UI :: Search",
			eventAction: "Click",
			eventLabel: "Item"
		});
	}

	public static AlgShowAllProductsEnter() {
		this.triggerTag({
			eventName: "ui_search_click_ver_todos",
			eventCategory: "UI :: Search",
			eventAction: "Click",
			eventLabel: "Keyboard"
		});
	}

	public static AlgFocusOnFilter() {
		this.triggerTag({
			eventName: "ui_search_focus_bar",
			eventCategory: "UI :: Search",
			eventAction: "Focus",
			eventLabel: "Focus"
		});
	}

	public static AlgProductDetail(product) {
		this.triggerTag({
			eventName: "select_item",
			eventCategory: "Ecommerce",
			eventAction: "Product Click",
			eventLabel: product
		});
	}

	public static AlgProductDetailPredictive(product) {
		this.triggerTag({
			eventName: "ui_predictive_select_item",
			eventCategory: "UI :: Search",
			eventAction: "Click",
			eventLabel: product
		});
	}
	public static errorCartForSkuNoVisible() {
		this.triggerTag({
			eventName: "ui_ecommerce_modal_act_carrito",
			eventCategory: "UI :: Web",
			eventAction: "Show",
			eventLabel: "Servicio de Cart retorna (Code:33)"
		});
	}
	public static errorCartForDuplicateProducts() {
		this.triggerTag({
			eventName: "ui_ecommerce_modal_duplicado_carrito",
			eventCategory: "UI :: Web",
			eventAction: "Show",
			eventLabel: "Servicio de Cart retorna (Code:34)"
		});
	}
	public static errorCartForOnlinePaymentRet() {
		this.triggerTag({
			eventName: "ui_ecommerce_modal_cambio_pago",
			eventCategory: "UI :: Web",
			eventAction: "Show",
			eventLabel: "Servicio de Cart retorna (Code:35)"
		});
	}

	// Productos controlados

	public static showDetailModalcontrolledSale() {
		this.triggerTag({
			eventName: "detail_modal_controlled_sale",
			eventCategory: "select_item",
			eventAction: "Click",
			eventLabel: "mostrar"
		});
	}

	public static showModalControlledOnborading() {
		this.triggerTag({
			eventName: "ui_recipe_onboarding_pageview",
			eventCategory: "UI :: Recipe OnBoarding",
			eventAction: "Pageview",
			eventLabel: "mostrar"
		});
	}

	public static backToCartModalControlledOnborading() {
		this.triggerTag({
			eventName: "ui_recipe_onboarding",
			eventCategory: "UI :: Recipe OnBoarding",
			eventAction: "Click",
			eventLabel: "volver al carrito"
		});
	}

	public static closeModalControlledInfo() {
		this.triggerTag({
			eventName: "ui_recipe_onboarding",
			eventCategory: "UI :: Recipe OnBoarding",
			eventAction: "Click",
			eventLabel: "cerrar"
		});
	}

	public static nextModalControlledInfo() {
		this.triggerTag({
			eventName: "ui_recipe_onboarding",
			eventCategory: "UI :: Recipe OnBoarding",
			eventAction: "Click",
			eventLabel: "continuar"
		});
	}

	public static showModalControlledStepOne() {
		this.triggerTag({
			eventName: "ui_recipe_patient_pageview",
			eventCategory: "UI :: Recipe Patient",
			eventAction: "Pageview",
			eventLabel: "mostrar"
		});
	}

	public static tooltipModalControlledStepOne() {
		this.triggerTag({
			eventName: "ui_recipe_patient",
			eventCategory: "UI :: Recipe Patient",
			eventAction: "Click",
			eventLabel: "tooltip show info"
		});
	}

	public static forThirdModalControlledStepOne() {
		this.triggerTag({
			eventName: "ui_recipe_patient",
			eventCategory: "UI :: Recipe Patient",
			eventAction: "Radio",
			eventLabel: "para tercera persona"
		});
	}

	public static forMeModalControlledStepOne() {
		this.triggerTag({
			eventName: "ui_recipe_patient",
			eventCategory: "UI :: Recipe Patient",
			eventAction: "Radio",
			eventLabel: "para mi"
		});
	}

	public static backModalControlledStepOne() {
		this.triggerTag({
			eventName: "ui_recipe_patient",
			eventCategory: "UI :: Recipe Patient",
			eventAction: "Click",
			eventLabel: "volver"
		});
	}

	public static closeModalControlledStepOne() {
		this.triggerTag({
			eventName: "ui_recipe_patient",
			eventCategory: "UI :: Recipe Patient",
			eventAction: "Click",
			eventLabel: "cerrar"
		});
	}

	public static nextModalControlledStepOne() {
		this.triggerTag({
			eventName: "ui_recipe_patient",
			eventCategory: "UI :: Recipe Patient",
			eventAction: "Click",
			eventLabel: "continuar"
		});
	}

	public static showModalControlledStepTwo() {
		this.triggerTag({
			eventName: "ui_recipe_medic_pageview",
			eventCategory: "UI :: Recipe Medic",
			eventAction: "Pageview",
			eventLabel: "mostrar"
		});
	}

	public static backModalControlledStepTwo() {
		this.triggerTag({
			eventName: "ui_recipe_medic",
			eventCategory: "UI :: Recipe Medic",
			eventAction: "Click",
			eventLabel: "volver"
		});
	}

	public static closeModalControlledStepTwo() {
		this.triggerTag({
			eventName: "ui_recipe_medic",
			eventCategory: "UI :: Recipe Medic",
			eventAction: "Click",
			eventLabel: "cerrar"
		});
	}

	public static showTooltiptModalControlledStepTwo() {
		this.triggerTag({
			eventName: "ui_recipe_medic",
			eventCategory: "UI :: Recipe Medic",
			eventAction: "Click",
			eventLabel: "tooltip show info"
		});
	}

	public static showUploadFileModalControlledStepTwo() {
		this.triggerTag({
			eventName: "ui_recipe_upload_pageview",
			eventCategory: "UI :: Recipe Medic",
			eventAction: "Pageview",
			eventLabel: "mostrar"
		});
	}

	public static closeUploadFileModalControlledStepTwo() {
		this.triggerTag({
			eventName: "ui_recipe_upload",
			eventCategory: "UI :: Recipe Medic",
			eventAction: "Click",
			eventLabel: "cerrar"
		});
	}

	public static camaraUploadFileModalControlledStepTwo() {
		this.triggerTag({
			eventName: "ui_recipe_upload",
			eventCategory: "UI :: Recipe Medic",
			eventAction: "Click",
			eventLabel: "camara"
		});
	}

	public static libraryUploadFileModalControlledStepTwo() {
		this.triggerTag({
			eventName: "ui_recipe_upload",
			eventCategory: "UI :: Recipe Medic",
			eventAction: "Click",
			eventLabel: "biblioteca de fotos"
		});
	}

	public static selectUploadFileModalControlledStepTwo() {
		this.triggerTag({
			eventName: "ui_recipe_upload",
			eventCategory: "UI :: Recipe Medic",
			eventAction: "Click",
			eventLabel: "adjuntar archivo"
		});
	}

	public static nextModalControlledStepTwo() {
		this.triggerTag({
			eventName: "ui_recipe_upload",
			eventCategory: "UI :: Recipe Medic",
			eventAction: "Click",
			eventLabel: "enviar receta"
		});
	}

	public static formTooltipShow(step) {
		this.triggerTag({
			eventName: `ui_recipe_${step === 1 ? "patient" : "medic"}`,
			eventCategory: `UI :: Recipe ${step === 1 ? "Patient" : "Medic"}`,
			eventAction: "Click",
			eventLabel: "tooltip show info"
		});
	}
	public static showModalImageZoom(sku) {
		this.triggerTag({
			eventName: "ui_product_zoom_image	",
			eventCategory: "UI :: ZoomProduct",
			eventAction: "Pageview",
			eventLabel: sku
		});
	}

	public static changeSlideModalImageZoom(sku) {
		this.triggerTag({
			eventName: "ui_product_zoom_image_scroll",
			eventCategory: "UI :: ZoomProduct",
			eventAction: "Scroll",
			eventLabel: sku
		});
	}

	public static clickModalImageZoom(sku) {
		this.triggerTag({
			eventName: "ui_product_zoom_image_click",
			eventCategory: "UI :: ZoomProduct",
			eventAction: "Click",
			eventLabel: sku
		});
	}

	public static zoomSlideModalImageZoom(sku, zoom) {
		this.triggerTag({
			eventName: "ui_product_zoom_image_click",
			eventCategory: "UI :: ZoomProduct",
			eventAction: `Zoom ${zoom ? "Si" : "No"}`,
			eventLabel: sku
		});
	}
}
