import { ProductCard } from '../../core/modules/product-card/models/product-card/product-card.model';

export class ProductList {
  name: string;
  slug: string;
  itemsPerView: number;
  products: Array<ProductCard>;

  constructor(component: any, productCards: Array<ProductCard>) {
    this.name = component.fields.nombre;
    this.slug = component.fields.slug;
    this.itemsPerView = component.fields.itemsPerView;
    this.products = productCards.length ? productCards : [];
  }

}
