import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UpdateEmailModalManagerService } from '../../register-services/update-email-modal-manager.service';
import { Subscription } from 'rxjs';
import { NewRegisterStoreService } from 'src/app/shared/stores/user/new-register-store.service';

@Component({
  selector: 'fp-phone-verification-modal',
  templateUrl: './phone-verification-modal.component.html',
  styleUrls: ['./phone-verification-modal.component.sass']
})
export class PhoneVerificationModalComponent implements OnInit {

  public emailOnConflict: string;
  public currentEmail: string;

  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<PhoneVerificationModalComponent>,
    private updateEmailModal: UpdateEmailModalManagerService,
    private newRegisterStore: NewRegisterStoreService,
  ) {
    this.currentEmail = newRegisterStore.email;
  }

  ngOnInit() {
    const cutEmailOnConflict = this.newRegisterStore.emailOnConflict.split('@');
    this.emailOnConflict = cutEmailOnConflict[0].substring(0, 3) + '***@' + cutEmailOnConflict[1];
  }

  closeModal() {
    this.dialogRef.close();
  }

  openUpdateEmailModal() {
    this.updateEmailModal.showUpdateEmail()
      .subscribe((result: boolean) => {
        if (result) {
          this.dialogRef.close(result);
        }
      });
  }

}
