import { Component, Input } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrandName } from "@parameters/access/brand.parameter";
import { GlobalProductCardService } from "src/app/core/modules/product-card/service/global-product-card.service";

@Component({
  selector: "fp-purchase-summary-sub-total",
  templateUrl: "./purchase-summary-sub-total.component.html",
  styleUrls: ["./purchase-summary-sub-total.component.sass"],
})
export class PurchaseSummarySubTotalComponent {
  @Input() subtotal: number;
  @Input() subtotalOh: number;
  @Input() discount: number;
  brand = EBrandName[environment.brand];
  constructor(
    public _globalProductCardService: GlobalProductCardService
  ) {
  }
}
