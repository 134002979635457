import { Component, OnInit } from "@angular/core";
import { ShoppingListStoreService } from "@stores/shopping-list/shopping-list-store.service";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { ProductShoppingCart } from "../../../product-card/models/product-shopping-cart/product-shopping-cart.model";
import { ProductAlertsService } from "../../../product-card/service/product-alerts.service";
import { ProductImplementService } from "../../../product-card/service/product-implement.service";
import { ProductSalesforceService } from "../../../product-card/service/product-salesforce.service";
import { ErrorStockModalService } from "../../services/error-stock-modal.service";

@Component({
  selector: "fp-error-stock-modal",
  templateUrl: "./error-stock-modal.component.html",
  styleUrls: ["./error-stock-modal.component.sass"],
})
export class ErrorStockModalComponent implements OnInit {
  productWithoutStock: ProductShoppingCart =
    this.shoppingListStore.shoppinListWithoutStock;
  constructor(
    private errorStockModal: ErrorStockModalService,
    private shoppingListStore: ShoppingListStoreService,
    private _productImplement: ProductImplementService,
    private _productAlerts: ProductAlertsService,
    private _productSalesforce: ProductSalesforceService
  ) {}

  ngOnInit(): void {}
  removeProduct() {
    this._productImplement.deleteProduct(this.productWithoutStock.id);
    this._productAlerts.deleteProductAlert();
    this._productSalesforce.deleteProduct(this.productWithoutStock.id);
    TaggingService.cartEliminar();
    const itemPresentation = this.productWithoutStock.presentations.find(
      (e) => e.id === this.productWithoutStock.presentationIdSelected
    );
    TaggingService.removeFromCart(
      this.productWithoutStock,
      itemPresentation.price,
      itemPresentation.presentation,
      this.productWithoutStock.quantity,
      true
    );
    setTimeout(() => {
      this.closeDialog();
    }, 200);
  }
  closeDialog() {
    this.errorStockModal.closeModal();
  }
}
