import { Component, OnInit, Input, ViewChild, NgZone } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { PickUpConfigStoreService } from '../../pick-up-stores/pick-up-config-store.service';
import { UserGeolocationHelperService } from '../../pick-up-helpers/user-geolocation-helper.service';
import { take } from 'rxjs/operators';
import { PickUpConfigResponse } from '../../models/pick-up-config.model';
import { Subscription } from 'rxjs';
import { DomDocumentHelperService } from '../../pick-up-helpers/dom-document-helper.service';

@Component({
  selector: 'fp-choose-a-store-sidenav',
  templateUrl: './choose-a-store-sidenav.component.html',
  styleUrls: ['./choose-a-store-sidenav.component.sass']
})
export class ChooseAStoreSidenavComponent implements OnInit {

  @Input() position: 'start' | 'end' = 'end';
  @ViewChild('sidenavContainer', { static: true }) private sidenavContainer: MatSidenav;

  public title = 'Selecciona una botica';
  public isDisableChooseStore = false;
  public isSlide = false;
  public isLoadingRespModal = true;
  public config: PickUpConfigResponse = {} as PickUpConfigResponse;

  private subscriptions: Subscription[] = [];

  constructor(
    public pickUpConfig: PickUpConfigStoreService,
    private userGeolocationHelper: UserGeolocationHelperService,
    private ngZone: NgZone,
    private domDocumentHelper: DomDocumentHelperService,
  ) { }

  ngOnInit() {
    this.userGeolocationHelper
      .validateAndLoadGeolocationAddress$()
      .pipe(take(1))
      .subscribe(() => {
        this.ngZone.run(() => {
          this.isLoadingRespModal = false;
        });
      });
    const configSub = this.pickUpConfig.config$.subscribe(config => this.config = config);
    this.subscriptions.push(configSub);
  }

  public openSidenav() {
    this.sidenavContainer.open();
  }

  public backButtonEvent() {
    this.sidenavContainer.close();
    this.domDocumentHelper.addScroll();
  }

  public closeButtonEvent() {
    this.sidenavContainer.close();
    this.domDocumentHelper.addScroll();
  }

  public saveChanges() {
  }

  public switchSlide() {
    this.isSlide = !this.isSlide;
  }
}
