import { Injectable } from '@angular/core';

import type { IGeolocationPermission } from 'src/app/shared/geolocation/geolocation.service';

@Injectable()
export class GeolocationStoreService {

  constructor() { }

  public get cordsGeo() {
    const dataStr = sessionStorage.getItem("coordsGeo");
    return JSON.parse(dataStr) as IGeolocationPermission;
  }
  public set cordsGeo(result: IGeolocationPermission) {
    const coords = {
      latitude: result?.coords?.latitude || 0,
      longitude: result?.coords?.longitude || 0,
      status: result?.status
    }
    sessionStorage.setItem("coordsGeo", JSON.stringify(coords));
  }
  public get provinceGeo() {
    const dataStr = sessionStorage.getItem("provinceGeo");
    return dataStr;
  }
  public set provinceGeo(province: string) {
    if(!province) return;

    sessionStorage.setItem("provinceGeo", province);
  }

  public get availableLoc() {
    const data = sessionStorage.getItem("availableLoc");

    return JSON.parse(data);
  }
  public set availableLoc(available: boolean) {
    if(available) sessionStorage.setItem("availableLoc", 'true');
    else sessionStorage.removeItem("availableLoc")
  }
}
