export interface IMotorized {
    device: IDevice;
    drugstore: IDrugstore;
    latitude: number;
    longitude: number;
    setting: ISetting;
    status: IStatus;
    times: ITimes;
    user: IUser;
}

export interface IDevice {
    imei: string;
    phoneMark: string;
    phoneModel: string;
    phoneNumber: string;
}

export interface IDrugstore {
    address: string;
    id: number;
    latitude: number;
    longitude: number;
    name: string;
    radius: number;
}

export interface ISetting {
    arrivedValidated: boolean;
    cancelAlarmTime: number;
    customerDelayTime: number;
    deliveryDistance: number;
    distanceToMarkArrive: number;
    markArriveValidated: boolean;
    routeBufferTime: number;
    statusAlertTime: number;
    trackingDistance: number;
    trackingTime: number;
    travelSpeed: number;
    type: number;
    warningPercentageEta: number;
}

export interface IStatus {
    statusDate: number;
    statusName: string;
}

export interface ITimes {
    etap: number;
}

export interface IUser {
    alias: string;
    dni: string;
    firstName: string;
    lastName: string;
}

export class MotorizedLocation {
    lat: number;
    lng: number;

    constructor(motorized: IMotorized) {
        this.lat = motorized.latitude || null;
        this.lng = motorized.longitude || null;
    }
}
