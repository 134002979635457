<mat-dialog-content class="dialog-content" *ngIf="listado">
  <div class="header">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <span class="d-flex align-items-center heading-1">
        <fp-icon class="mr-4" iconSize="32" iconName="coupon-ticket"></fp-icon>
        {{ coupons?.length > 0 ? 'Canjea tus cupones' : 'Agregar código de descuento' }}
      </span>
      <div class="icon-container cursor-pointer" (click)="closeDialogModal()">
        <fp-icon iconSize="20" [innerClass]="'align-bottom-zero'" iconName="close-outline"></fp-icon>
      </div>
    </div>
  </div>
  <div *ngIf="coupons.length > 0" class="content px-0">
    <div class="coupon_especiales pb-xs-3 pb-md-5 pt-4 pt-md-5">
      <span class="label--1">
        Tienes {{ coupons.length }} cupones especiales por ser parte del <b>Monedero del Ahorro</b> ¡Elige los que
        prefieras y
        ahorra más!
      </span>
    </div>
    <div *ngFor="let coupon of coupons; let i = index" class="py-2">
      <div class="d-flex align-items-center coupon-content">
        <div class="">
          <img height="60px" width="60px" [src]="coupon.pathImage.small" [alt]="coupon.productName" />
        </div>
        <div class="coupon-product d-flex flex-column align-items-start">
          <div class="title_product">{{ coupon.productName }}</div>
          <p class="texto_product mb-0">Válido hasta el {{ coupon.couponValidity.endDate | momentPipe : "D/MM/YY" }}</p>
          <button class="btn-coupon" mat-raised-button (click)="openDialogDetail(coupon)">Ver detalle</button>
        </div>
        <div class="w-100">
          <fp-button-outline [disabled]="isSelected(coupon) || isLoading" buttonClass="btn-coupon-outline px-2 px-sm-3"
            buttonDisplay="block" (click)="addCoupon(coupon)">
            {{ isSelected(coupon) ? "Aplicado" : "Agregar" }}
          </fp-button-outline>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="footer-container d-flex flex-column justify-content-end align-items-stretch">
      <div>
        <form [formGroup]="discountCodeForm.form" class="d-flex flex-row align-items-end codigo_descuento">
          <div class="w-100 block_codigo_descuento">
            <div class="paragraph--1 pb-2">¿Tienes un código de descuento?</div>
            <div class="input_codigo_descuento pr-3 pr-sm-4">
              <fp-input-control [inputTitle]="''" [errorMessage]="''" [marginBottomZero]="true">
                <input type="text" id="nameCupon" class="input" placeholder="Ingresa el código" formControlName="code"
                  [class.isinvalid]="
                    (!discountCodeForm.form.valid && discountCodeForm.form.touched) ||
                    (discountCodeForm.form.valid && isValidCoupon)
                  " />
              </fp-input-control>
            </div>
          </div>
          <div class="btn_agregar_codigo">
            <fp-button-outline *ngIf="!isValidatingCupon" buttonClass="btn-coupon-outline" (click)="applyCupon()"
              id="applyCoupon" [disabled]="!discountCodeForm.form.valid || isValidatingCupon">
              Aplicar
            </fp-button-outline>
            <fp-button-primary *ngIf="isValidatingCupon" id="applyCoupon" buttonDisplay="block">
              <div class="loading loading-circle"></div>
            </fp-button-primary>
          </div>
        </form>
        <small class="small"
          *ngIf="!discountCodeForm.form.valid && discountCodeForm.form.touched && !isValidatingCupon">Ingresar Código
        </small>
        <small class="small" *ngIf="discountCodeForm.form.valid && isValidCoupon"> Cupón inválido </small>
        <div class="d-flex mt-4" *ngIf="cupones.length > 0">
          <div class="p-2 mr-2 container-coupon" *ngFor="let coupon of cupones; let i = index">
            <div class="d-flex justify-content-between align-items-center">
              <span class="coupon">{{ coupon.productName }}</span>
              <fp-icon [innerClass]="'align-bottom-zero'" role="button" iconSize="16" iconName="close-outline"
                (click)="remove(i)"></fp-icon>
            </div>
          </div>
        </div>

        <div *ngIf="coupons.length === 0" class="col-12 px-0">
          <div class="d-flex align-items-center mx-0 mt-4 mt-sm-5 disclaimer-container">
            <fp-icon iconSize="32" iconName="monedero"></fp-icon>
            <div class="paragraph--1">¡Próximamente encontrarás nuevos cupones exclusivos para ti!</div>
          </div>
        </div>
        <div *ngIf="isValidCoupon" class="col-12 pt-lg-4 px-0">
          <div class="d-flex flex-row p-4 disclaimer-container">
            <fp-icon iconSize="32" iconName="chat-alert"></fp-icon>
            <ul class="m-0 paragraph--1">
              <li>Ya cuentas con un mejor descuento.</li>
              <li>Verifica la fecha de caducidad.</li>
              <li>Verifica si aplica a tus productos</li>
              <li>Llama al <strong>(01) 612 5000</strong> si el problema continúa.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-end" style="flex: 1 0 0;">
        <fp-button-primary buttonDisplay="block" (click)="accept()"> Aceptar </fp-button-primary>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-content class="dialog-content" *ngIf="detalle">
	<div class="top-detalle header">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <span class="d-flex align-items-center heading-1">
        <h2>Detalle de cupón</h2>
      </span>
      <div class="icon-container cursor-pointer" (click)="back()">
        <fp-icon iconSize="20" [innerClass]="'align-bottom-zero'" iconName="close-outline"></fp-icon>
      </div>
    </div>
  </div>
	<hr />
	<div class="cargando pb-5" *ngIf="!coupon; else loading">Cargando, Espere Por favor...</div>
	<ng-template #loading>
		<div class="cuerpo-detalle">
			<div class="descto mb-2">{{ coupon.productName }}</div>
			<ul>
				<li>
					Válido del
					{{ coupon.couponValidity.startDate | momentPipe : "D MMMM" }} al
					{{ coupon.couponValidity.endDate | momentPipe : "D MMMM YYYY" }}.
				</li>
				<li>Válido en nuestros locales, Web y App</li>
				<li>Para canjear en la botica, muestra el código.</li>
			</ul>
			<div class="terminos-condiciones">
				<div class="title_terminos_condiciones">Términos y condiciones</div>
				<p>
					{{ coupon.termsAndConditions }}
				</p>
			</div>
		</div>
	</ng-template>
</mat-dialog-content>