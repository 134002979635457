import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyImageComponent } from './lazy-image/lazy-image.component';
import { LazyWrapperComponent } from './lazy-wrapper/lazy-wrapper.component';

@NgModule({
  declarations: [
    LazyImageComponent,
    LazyWrapperComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LazyImageComponent,
    LazyWrapperComponent,
  ]
})
export class LazyImagesModule { }
