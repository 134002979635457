import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { stringFormat } from "@helpers/string-format.helper";
import { DeliveryMethod } from "@models/schedule-response.model";
import { SERVICES_TYPES_ENUM } from "@parameters/global";
import { SERVICES_TYPES } from "./../../../shared/parameters/global";

@Component({
  selector: "fp-card-service",
  templateUrl: "./card-service.component.html",
  styleUrls: ["./card-service.component.sass"],
})
export class CardServiceComponent implements OnChanges {
  @Input()
  serviceCode: string;
  @Input()
  name: string;
  @Input()
  description: string;
  @Input()
  switch: boolean = false;
  @Input()
  isDisabled: boolean = false;
  @Input()
  isLoading: boolean = true;
  @Input()
  isCheckedSwitch: boolean;
  @Input()
  deliveryMethods: Array<DeliveryMethod>;
  @Input()
  isLoadingDelivery: boolean = true;
  @Output()
  change = new EventEmitter<boolean>();

  valueCheck = {
    checked: false,
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["isCheckedSwitch"]) {
      this.updateValueCheck();
    }
    if (changes && changes["deliveryMethods"]) {
      const methods = changes["deliveryMethods"]
        .currentValue as Array<DeliveryMethod>;

      if (
        !this.isDisabled &&
        methods &&
        this.serviceCode !== SERVICES_TYPES_ENUM.RETIRO
      ) {
        let service = methods.find(
          (r) => r.serviceTypeCode === this.serviceCode
        );

        if (service) {
          let caps = service.capabilities ? service.capabilities[0] : null;
          if (caps && caps.hasCapacity) {
            let today = caps?.today === "Y" ? "hoy" : "el dia";
            switch (service.serviceTypeCode) {
              case SERVICES_TYPES_ENUM.DELIVERY:
                this.description =
                  this.description +
                  today + 
                  caps.message + 
                  caps?.schedules[0]?.time
                ;
                break;
              case SERVICES_TYPES_ENUM.AM_PM:
                this.description = 
                  this.description +
                  today +
                  caps.message + 
                  caps?.schedules[0]?.time
                ;
                break;
              case SERVICES_TYPES_ENUM.EXPRESS:
                this.description = 
                  this.description +
                  caps.deliveryTime
                ;
                break;
            }
          } else {
            this.setValues();
          }
        } else {
          this.setValues();
        }
      }
    }
  }

  updateValueCheck() {
    if (this.switch) {
      this.valueCheck.checked = this.isCheckedSwitch;
    }
  }
  setValues() {
    this.isDisabled = true;
    this.description = SERVICES_TYPES.find(
      (l) => l.id == this.serviceCode
    ).textDisabled;
  }
  onChange() {
    this.change.emit(this.valueCheck.checked);
  }
}
