<div class="dialog-close">
	<span class="icon"> </span>
</div>

<div class="out-of-stock">
	<div class="out-of-stock__icon">
		<img height="56" src="../../../../assets/icons/face_sad.svg" alt="Producto no disponible" />
	</div>

	<h2>Carrito de compra actualizado</h2>

	<ng-container>
		{{description}}
	</ng-container>

	<ng-container>
		<div class="col-12 pt-2 mt-4">
			<fp-button-primary class="mb-2" buttonDisplay="block" (click)="action()">
				Entendido
			</fp-button-primary>
		</div>
	</ng-container>
</div>
