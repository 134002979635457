import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { PickUpConfigResponse } from '../../models/pick-up-config.model';
import { of, Subscription } from 'rxjs';
import { PickUpConfigStoreService } from '../../pick-up-stores/pick-up-config-store.service';
import { UserGeolocationHelperService } from '../../pick-up-helpers/user-geolocation-helper.service';
import { DomDocumentHelperService } from '../../pick-up-helpers/dom-document-helper.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ModalsManagerHelperService } from '../../pick-up-services/modals-manager-helper.service';
import { StoresForPickUpStoreService } from '../../pick-up-stores/stores-for-pick-up-store.service';
import { FirstLoadHelperService } from '../../pick-up-helpers/first-load-helper.service';
import { IModalStatus } from '../../enums/modal-status.enum';
import { NearestDrugstore } from 'src/app/shared/models/nearest-drugstores/nearest-drugstores.model';
import { IStoreToChoose } from '../../interfaces/choose-a-store.interface';

@Component({
  selector: 'fp-choose-a-store-responsive-modal',
  templateUrl: './choose-a-store-responsive-modal.component.html',
  styleUrls: ['./choose-a-store-responsive-modal.component.sass']
})
export class ChooseAStoreResponsiveModalComponent implements OnInit, OnDestroy {

  public title = 'Selecciona una botica';
  public isDisableChooseStore = false;
  public isSlide = false;
  public isLoadingRespModal = true;
  public config: PickUpConfigResponse = {} as PickUpConfigResponse;
  public modalStatus: IModalStatus = IModalStatus.LOADING;
  public ALL_MODAL_STATUS = IModalStatus;

  public drugstores: NearestDrugstore[] = [];
  private subscriptions: Subscription[] = [];
  public temporalSelectedDrugstore = {} as IStoreToChoose;

  constructor(
    public pickUpConfig: PickUpConfigStoreService,
    private userGeolocationHelper: UserGeolocationHelperService,
    private ngZone: NgZone,
    private domDocumentHelper: DomDocumentHelperService,
    private modalsManagerHelper: ModalsManagerHelperService,
    private storesForPickUpStore: StoresForPickUpStoreService,
    private firstLoadHelper: FirstLoadHelperService,
  ) {
    const drugstoresSub = this.storesForPickUpStore.drugstores$
      .subscribe((drugstores) => this.drugstores = drugstores);
    const temporalSelectedDrugstoreSub = this.storesForPickUpStore.selectedTemporalDrugstore$
      .subscribe((temporalSelectedDrugstore) => {
        this.temporalSelectedDrugstore = this.storesForPickUpStore.getFormattedNearestDrugstore(temporalSelectedDrugstore);
      });
    this.subscriptions.push(drugstoresSub, temporalSelectedDrugstoreSub);
  }

  ngOnInit() {
    this.userGeolocationHelper
      .validateAndLoadGeolocationAddress$()
      .pipe(take(1))
      .subscribe();
    const statusModelSub = this.firstLoadHelper.statusModal$
      .subscribe(state => this.modalStatus = state);
    const configSub = this.pickUpConfig.config$.subscribe(config => this.config = config);
    this.subscriptions.push(configSub, statusModelSub);

    this.firstLoadHelper.getInitialDrugstores$()
      .pipe(switchMap(response => {
        const validationsWithAddress = [IModalStatus.OK, IModalStatus.EMPTY];
        if (validationsWithAddress.includes(response.modalStatus)) {
          this.isLoadingRespModal = false;
          return of(response);
        }
        const validationWithGeolocation = [IModalStatus.IS_PENDING, IModalStatus.LOADING];
        if (validationWithGeolocation.includes(response.modalStatus)) {
          this.modalStatus = response.modalStatus;
          this.isLoadingRespModal = false;
          return this.firstLoadHelper.getDrugstoresOfGeolocationFlow$();
        }
        return of(response);
      }))
      .pipe(take(1), catchError((err) => {
        this.modalStatus = IModalStatus.EMPTY;
        throw err;
      }))
      .subscribe(response => {
        this.ngZone.run(() => {
          const { drugstores, temporalSelectedDrugstore } = response;
          this.modalStatus = response.modalStatus;
          this.storesForPickUpStore.setDrugstores(drugstores);
          this.storesForPickUpStore.setSelectedTemporalDrugstore(temporalSelectedDrugstore);
          this.isLoadingRespModal = false;
        });
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public backButtonEvent() {
    this.modalsManagerHelper.closeChooseAStoreResponsiveModal();
    this.domDocumentHelper.addScroll();
  }

  public closeButtonEvent() {
    this.modalsManagerHelper.closeChooseAStoreResponsiveModal();
    this.domDocumentHelper.addScroll();
  }

  public saveChanges() {
    const { id } = this.storesForPickUpStore.selectedTemporalDrugstore;
    this.storesForPickUpStore.setSelectedDrugstoreById(id);
    setTimeout(() => {
      this.modalsManagerHelper.closeChooseAStoreResponsiveModal();
      this.domDocumentHelper.addScroll();
    }, 100);
  }

  public switchSlide() {
    this.isSlide = !this.isSlide;
  }

  public setModalStatus(state: IModalStatus) {
    this.modalStatus = state;
  }
}
