import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserDataMatchModalComponent } from '../modal-components/user-data-match-modal/user-data-match-modal.component';

@Injectable()
export class UserDataMatchModalManagerService {

  constructor(
    private dialog: MatDialog,
  ) { }

  public showUserDataMatchModal(): void {
    this.dialog.open(UserDataMatchModalComponent, {
      width: '420px',
      panelClass: 'custom-full-all-modal-overlay',
    });
  }
}
