export const STOCK_PATH = 'consultastock';

export const CONSULTA_DE_STOCK_PATH = 'consultar';
export const CONSULTA_DE_STOCK_FULL_PATH = `${STOCK_PATH}/${CONSULTA_DE_STOCK_PATH}`;

export const RESULTADO_DE_CONSULTA_PATH = 'resultado-de-consulta';
export const RESULTADO_DE_CONSULTA_FULL_PATH = `${STOCK_PATH}/${RESULTADO_DE_CONSULTA_PATH}`;

export const VERIFICACION_DE_IDENTIDAD_PATH = 'verificacion';
export const VERIFICACION_DE_IDENTIDAD_FULL_PATH = `${STOCK_PATH}/${VERIFICACION_DE_IDENTIDAD_PATH}`;

export const AFILIACION_PATH = 'afiliacion';
export const AFILIACION_FULL_PATH = `${STOCK_PATH}/${AFILIACION_PATH}`;



