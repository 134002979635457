import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { UserInfoStoreService } from "@stores/user/user-info-store.service";
import { GlobalProductCardService } from "src/app/core/modules/product-card/service/global-product-card.service";
import { ISubTotal } from "src/app/shared/models/shopping-cart/shopping-cart-response.interfase";
import { PurchaseSummary } from "../../../../shared/models/shopping-cart/shopping-cart-precalculation.model";

@Component({
	selector: "fp-purchase-summary-total-mifa",
	templateUrl: "./purchase-summary-total-mifa.component.html",
	styleUrls: ["./purchase-summary-total-mifa.component.sass"]
})
export class PurchaseSummaryTotalMifaComponent implements OnInit, OnChanges {
	public total: number;
	public totalOh: number;
	public totalMonedero: number;

	@Input() summary: PurchaseSummary;
	@Input() summaryCard: Array<ISubTotal>;
	constructor(public _userInfoStore: UserInfoStoreService, public _globalProductCardService: GlobalProductCardService) {}

	ngOnInit() {}

	ngOnChanges(): void {
		this.updateTotal();
	}

	updateTotal() {
		const summaryCardTotal =
			(this.summaryCard || []).find((data) => this._globalProductCardService.paymentMethodCardType === data.paymentMethodCardType) ||
			{};
		this.total = this.summary.subtotalListPrice;
		this.totalOh = this.summary.subtotalPaymentOh;
		this.totalMonedero = this.summary.subtotalPurse;
	}

	trackByImage(index: number, urlImage: string): string {
		return urlImage;
	}
}
