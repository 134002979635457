<ng-container *ngIf="!isError; else errorLoad">
  <section class="address-drugstore-dispatch__container" [ngClass]="{'disable': isLoading || isLoadingDelivery || isLoadingNearest}" [formGroup]="form">
      <div class="d-flex address-drugstore-dispatch__text-free">
        <div class="radio-control-container" style="margin-right: 25px;">
          <label class="radio-control">
            <input type="radio"
              class="input-radio"
              [disabled]="isLoadingDelivery"
              [checked]="isCheckRet"
              (click)="onChange(true)"
            >
              <span class="check-mark"></span>
            </label>
          </div>
          <span><b class="cursor" (click)="onChange(true)">Retiro en tienda</b> <a class="link_change_drugstore" (click)="openChangeDrusgStore()">Cambiar Botica</a></span>
        </div>
        <div *ngIf="_selectDataStore.isReadyToReset || !this.retValidateCartService.isRetOrRadTomadorProcess">
          <span><b>{{ isLoadingNearest  ? 'Cargando botica...' : store ? store?.name : ''}} </b>{{store ? " - " + store?.address : ''}} </span>
          <fp-alert-dispatch *ngIf="flagState && states.NOT_FOUND_RET" [state]="flagState"></fp-alert-dispatch>
        </div>
        <div *ngIf="this.retValidateCartService.isRetOrRadTomadorProcess">
          <span>Selecciona esta opción para ver la botica para Ret.</span>
        </div>
  </section>
      <br/>
  <section class="address-drugstore-dispatch__container" [ngClass]="{'disable': isLoading || isLoadingDelivery || isLoadingNearest}" [formGroup]="form">
        <div>
          <div class="d-flex address-drugstore-dispatch__text-free">
            <div class="radio-control-container" style="margin-right: 25px;">
              <label class="radio-control">
                <input type="radio"
                [disabled]="isLoadingNearest"
                class="input-radio"
                [checked]="isCheckRad"
                (click)="onChange(false)"
            >
            <span class="check-mark"></span>

          </label>
        </div>
        <span><b class="cursor"  (click)="onChange(false)">Reparto a Domicilio</b> <a class="link_change_address" (click)="openChangeAddress()">Cambiar dirección</a></span>
      </div>
      <fp-alert-dispatch *ngIf="flagState && (flagState === states.NOT_FOUND_DELIVERY || flagState === states.NOT_FOUND_COVERAGE)" [state]="flagState"></fp-alert-dispatch>
      <div class="d-flex" style="margin-top: 15px;">
        <span><b>Stock Principal</b></span>
      </div>
      <div class="radio-office-block mt-4">
        <ng-container *ngIf="listServicesTypes?.length > 0">
          <fp-card-service
            *ngFor="let item of listServicesTypes; let indexOfelement = index"
            [serviceCode]="item.id"
            [name]="item?.name"
            [switch]="item?.switch"
            [isCheckedSwitch]="_addressDispatchService.switch$ | async"
            [isDisabled]="item?.disabled"
            [isLoading]="isLoading"
            [deliveryMethods]="deliveryMethods"
            [isLoadingDelivery]="isLoadingDelivery"
            (change)="onChange($event)">
          </fp-card-service>
        </ng-container>
      </div>
    </div>
    <div *ngIf="this.retValidateCartService.isRetOrRadTomadorProcess">
      <span><b>{{ store?.name }} </b>{{store?.address}}</span>
    </div>
    <div *ngIf="storeBackup">
      <div class="d-flex">
        <span><b>Stock Backup</b></span>
      </div>
      <div class="stores-cards">
        <fp-card-drug-store
          [name]="storeBackup?.zoneName + ' - (BACKUP)'"
          [icon]="'icon-backup'"
          [address]="
            storeBackup?.location?.name +
            ' - ' +
            storeBackup?.location?.province +
            ' - ' +
            storeBackup?.location?.department
          "
          [isDisabled]="_selectDataStore.isReadyToReset"
          [isLoading]="isLoading"
        ></fp-card-drug-store>
      </div>
      <br>
      <div>
        <span>
          <b>{{ storeBackup?.zoneName }} -</b>
          {{ storeBackup?.location?.name }} -
          {{ storeBackup?.location?.province }} -
          {{ storeBackup?.location?.department }}
        </span>
      </div>
    </div>

  </section>
</ng-container>
<ng-template #errorLoad>
  <fp-error-drugstore (evenEmitter)="onReset()"></fp-error-drugstore>
</ng-template>
