<div class="dialog-close">
  <span class="icon icon-24-close icon-close cursor-pointer" (click)="close()"> </span>
</div>
<div class="out-of-stock">
  <div class="out-of-stock__icon">
    <img src="../../../../assets/icons/pin_geo.svg" alt="activate geolocalization">
  </div>

  <h2 class="margin-title">Ayúdanos a mejorar tu experiencia</h2>

  <ng-container>
    <p>No olvides activar tu ubicación para mejorar tu experiencia de compra y recomendarte productos disponibles por tu zona.</p>
    <div class="col-12">
      <fp-button-primary class="mb-8" buttonDisplay="block" (click)="activateGeolocalization()"> Entendido
      </fp-button-primary>
    </div>
  </ng-container>
</div>
