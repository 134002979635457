import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PurchaseSummary } from 'src/app/shared/models/shopping-cart/shopping-cart-precalculation.model';
import { ShoppingCartResponse } from 'src/app/shared/models/shopping-cart/shopping-cart-response.model';

@Injectable()
export class CheckoutPurchaseSummaryStoreService {

  private _purchaseSummary: PurchaseSummary;
  private _purchaseSummarySubject = new Subject<PurchaseSummary>();
  public purchaseSummary$ = this._purchaseSummarySubject.asObservable();
  public isLoadedCart = new BehaviorSubject(false);

  // new order summary after refactor
  private _shoppingCartResponse: ShoppingCartResponse;
  private _shoppingCartResponseSubject = new Subject<ShoppingCartResponse>();
  public shoppingCartResponse$ = this._shoppingCartResponseSubject.asObservable();

  constructor() {
    this.shoppingCartResponse$.subscribe(summary => this._shoppingCartResponse = summary);
  }

  public get purchaseSummary() {
    return this._purchaseSummary;
  }

  public setPurchaseSummary(summary: PurchaseSummary) {
    this._purchaseSummarySubject.next(summary);
  }

  // methods for new order summary after refactor
  public get shoppingCartResponse() {
    return this._shoppingCartResponse;
  }
  public setShoppingCartResponse(response: ShoppingCartResponse) {
    this._shoppingCartResponseSubject.next(response);
  }
}
