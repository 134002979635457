import { Component, Inject, Input, OnInit } from "@angular/core";
import { BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { ContentfulProductList } from "../../../contentful/models/contentful-product-list.model";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "fp-carousel-title",
  templateUrl: "./carousel-title.component.html",
  styleUrls: ["./carousel-title.component.sass"]
})
export class CarouselTitleComponent implements OnInit {
  @Input() list: ContentfulProductList;
  public productListPath = BUSINESS_PATHS.LIST;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {}

  goToUrl() {
    this.document.location.href = `${this.productListPath}/${this.list.slug}`;
  }
}
