import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ClientResponse } from "../../models/client.model";

@Component({
  selector: "fp-content-find-client-success",
  templateUrl: "./content-find-client-success.component.html",
  styleUrls: ["./content-find-client-success.component.scss"],
})
export class ContentFindClientSuccessComponent implements OnChanges {
  @Input() name = "radioButton";
  @Input() data: ClientResponse[];
  @Output() changeSelected = new EventEmitter<ClientResponse>();
  @Output() register = new EventEmitter();

  title = "";

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["data"]) {
      this.onRenameTitle();
    }
  }

  onRenameTitle() {
    if (this.data?.length > 1)
      this.title = `Se encontraron ${this.data.length} resultados`;
    if (this.data?.length === 1) {
      this.title = `Se encontró 1 resultado`;
    }
  }

  onChangeSelected(value: ClientResponse): void {
    this.changeSelected.emit(value);
  }

  onRegister() {
    this.register.emit();
  }
}
