import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IErrorMessageAlert } from '../../../models/error-message-alert.interface';
import { RefreshScheduleHelperService } from '../../../checkout-helpers/refresh-schedule-helper.service';
import { OnlinePaymentHelperService } from '../../../checkout-helpers/online-payment-helper.service';
import { YOUR_CARD_WAS_NOT_CHARGED_MESSAGE, DISABLED_SCHEDULE_ERROR_MESSAGE, GENERIC_ERROR_TITLE } from '../../../parameters/modal-error-messages.constant';
import { IErrorScheduleService } from '../../../models/error-schedule-service.inteface';
import { MainLoaderService } from '@helpers/main-loader.service';

@Component({
  selector: 'fp-checkout-error-schedule-modal',
  templateUrl: './checkout-error-schedule-modal.component.html',
  styleUrls: ['./checkout-error-schedule-modal.component.sass']
})
export class CheckoutErrorScheduleModalComponent implements OnInit {

  errorMessages: IErrorMessageAlert[];
  title = GENERIC_ERROR_TITLE;
  description  = DISABLED_SCHEDULE_ERROR_MESSAGE;
  message = YOUR_CARD_WAS_NOT_CHARGED_MESSAGE;
  public serviceType = 'PROG';
  public able = true;

  constructor(
    public dialogRef: MatDialogRef<CheckoutErrorScheduleModalComponent>,
    private refreshScheduleHelper: RefreshScheduleHelperService,
    public onlinePaymentHelper: OnlinePaymentHelperService,
    private loaderService: MainLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: IErrorScheduleService

  ) { }

  ngOnInit() {
    this.able = this.data.able;
    this.description = this.data.message;
  }

  closeModal() {
    this.dialogRef.close();
  }

  public changeToTheNextEnabledSchedule() {
    // this.refreshScheduleHelper.emitEventForSelectNextSchedule();
    this.loaderService.checkoutNeedReload.next(true)
    this.closeModal();
  }

  public refreshCurrentDeliveryServiceType() {
    // this.refreshScheduleHelper.emitEventForUpdateSchedule();
    this.loaderService.checkoutNeedReload.next(true)
    this.closeModal();
  }

  public changeDeliveryServiceType() {
    this.refreshScheduleHelper.emitDeliveryServiceTypeChanging();
    this.closeModal();
  }
}
