import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Entry } from 'contentful';
import { ContentfulBanner, IContentfulBanner } from './contentful-banner.model';
import { ContentfulProductList, IContentfulProductList } from './contentful-product-list.model';
import { ContentfulEntry } from '../../../shared/models/contentful/contentful.model';


export interface IContentfulHeaderProductList {
  title: string;
  background: string;
  banner: Entry<IContentfulBanner>;
  productList: Entry<IContentfulProductList>;
}

export class ContentfulHeaderProductList {
  title: string;
  backgroundColor: string;
  banner: ContentfulBanner;
  productList: ContentfulProductList;

  constructor(iContentfulHeaderProductList: IContentfulHeaderProductList) {
    this.title = iContentfulHeaderProductList.title;
    this.backgroundColor = iContentfulHeaderProductList.background;
    this.banner = new ContentfulBanner(new ContentfulEntry(iContentfulHeaderProductList.banner).content);
    this.productList = new ContentfulProductList(new ContentfulEntry(iContentfulHeaderProductList.productList).content);
  }
}

export const CContentfulHeaderProductList = {
  id: CONTENTFUL_PATH.contentTypeIds.headerProductList,
  sliderConfig: {
    'type': 5,
    'loop': false,
    'autoplay': false,
    'autoplayTime': 5000,
    'infinite': false,
    'autoHeight': true,
    'slidesConfigByBreakpoints': {
      'xxs': {
        slidesPerView: 1.26,
        spaceBetween: 16
      },
      'sm': {
        slidesPerView: 1.8,
        spaceBetween: 16
      },
      'md': {
        slidesPerView: 2.4,
        spaceBetween: 16
      },
      'lg': {
        slidesPerView: 3,
        spaceBetween: 14
      },
      'xl': {
        slidesPerView: 4,
        spaceBetween: 14
      },
      'xxl': {
        slidesPerView: 5,
        spaceBetween: 14
      },
    }
  }
};
