import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumberKey = event.key >= '0' && event.key <= '9';

    if (allowedKeys.indexOf(event.key) !== -1 || isNumberKey) {
      return; // Allow the key
    } else {
      event.preventDefault(); // Prevent the key
    }
  }

}
