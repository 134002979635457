import { Injectable } from '@angular/core';
import { GenericService } from '../generic';
import { ENDPOINTS } from '../../parameters/endpoints';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CategoriesLandingResponse } from '../../models/categories-landing/categories-landing-response.model';
import { ICategoriesLandingResponse } from '../../models/categories-landing/categories-landing-response.interface';
import { CategoriesLandingRequest } from '../../models/categories-landing/categories-landing-request.model';
import { IProductDefault } from '../../models/product-default/product-default.interface';
import { AppStoreService } from '../../stores/app/app-store.service';
import { ClientStoreService } from '@stores/client/client-store.service';
import { UserClientService } from '../user/user-client.service';

@Injectable()
export class CategoriesLandingClientService {

  pathExtra = "&whitelabel=&email=";
  pathExtra2 = "&u="

  constructor(
    private _genericService: GenericService,
    private genericService: GenericService,
    private _appStoreService: AppStoreService,
    private _userClientService: UserClientService
  ) {
  }

  getCategoriesLandingFilters(categoriesLandingRequest: CategoriesLandingRequest): Observable<CategoriesLandingResponse> {
    const endpoint = `${ENDPOINTS.GET_CATEGORIES_FILTERS}${categoriesLandingRequest.query}`;
    return this._genericService.genericGet(endpoint)
      .pipe(
        map((iCategoriesLandingResponse: ICategoriesLandingResponse) => new CategoriesLandingResponse(iCategoriesLandingResponse || {} as ICategoriesLandingResponse))
      );
  }

  loadUserRegister() {
    return new Promise(resolve => {
      this._userClientService.getUser$()
        .pipe(take(1)).subscribe(data => {
          if (data['data']) {
            resolve(data['data'])
          } else {
            resolve('')
          }
        });
    })
  }

  public async getContenidosBrainHome(path = 'home/1//&guid=', sectionID) {
    const client: any = await this.loadUserRegister();
    const { drugstoreStock } = this._appStoreService;
    let url = ENDPOINTS.GET_LIST_CONTENIDOS + (drugstoreStock ? drugstoreStock : '') + this.pathExtra + (client.email || '') + this.pathExtra2 + path + `&sectionID=${sectionID}`
    const data = await this.genericService.genericGetWithoutHeader(url).toPromise();
    return data;
  }

  public getProductsByIds(ids: Array<string>): Observable<Array<IProductDefault>> {
    const idsArray = ids.toString();
    return this.genericService.genericGet(ENDPOINTS.GET_PRODUCTS_BY_IDS + idsArray);
  }

}
