<div class="select" #select appResized (resized)="resized($event)">
	<mat-select
		appNoSpace
		disableRipple
		disableOptionCentering
		panelClass="select-component"
		[multiple]="multiple"
		[enableNoSpace]="enableNoSpace"
		[disabled]="disabled"
		[placeholder]="placeholder"
		[value]="value"
		(valueChange)="selectionChange($event)"
		(openedChange)="listenToggleSelect($event)"
	>
		<mat-select-trigger class="trigger" *ngIf="customValue">
			<div class="trigger-content">
				<ng-content select="[trigger]"></ng-content>
			</div>
		</mat-select-trigger>

		<div
			class="option-container"
			[style.max-height]="containerMaxHeight"
			[style.width]="optionContainerWidth"
			[style.transform]="multiple ? 'translate(23px)' : ''"
		>
			<mat-option
				class="select-basic-option select-basic-option-clear"
				*ngIf="clearValue"
				[class.select-square-option]="selectOptionSquare"
			>
				{{ clearValue }}
			</mat-option>

			<mat-option class="option-search" disabled="true" *ngIf="enableSearch">
				<div style="display: block">
					<input
						id="input"
						class="search"
						maxlength="20"
						placeholder="Buscar"
						(keyup)="onKey($event)"
						(keyup.space)="addSpace()"
						autocomplete="off"
						#input
					/>
					<app-icon class="icon-search" fontName="search" fontSize="16px"></app-icon>
				</div>
			</mat-option>

			<ng-container *ngIf="enableSearch; else notSearch">
				<mat-option
					*ngFor="let option of optionList"
					class="select-basic-option"
					[class.select-square-option]="selectOptionSquare"
					[value]="option"
				>
					<ng-container *ngIf="!templateRef">{{ option }}</ng-container>
					<ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: option }"> </ng-template>
				</mat-option>
			</ng-container>

			<ng-template #notSearch>
				<mat-option
					*ngFor="let option of optionList"
					class="select-basic-option"
					[class.select-square-option]="selectOptionSquare"
					[value]="option"
				>
					<ng-container *ngIf="!templateRef">{{ option }}</ng-container>
					<ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: option }"> </ng-template>
				</mat-option>
			</ng-template>

			<ng-container *ngIf="showOtionEndOther">
				<mat-option class="select-basic-option" *ngIf="optionList?.length > 3"> </mat-option>

				<mat-option class="select-basic-option option-other" [class.w-100]="optionList?.length < 4" [value]="optionEndOtherValue">
					{{ optionEndOtherLabel }}
				</mat-option>
			</ng-container>
		</div>
	</mat-select>

	<fp-icon
		*ngIf="value && showClearValueForButton"
		(click)="clearValues()"
		class="close-icon"
		fontName="close"
		innerClass="text-gray-5"
		fontSize="16px"
	>
	</fp-icon>

	<fp-icon class="select-icon" fontName="chevron-down" innerClass="text-gray-5" fontSize="14px"> </fp-icon>
</div>
