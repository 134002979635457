<div class="product3Prices">
  <div class="row align-items-end">
    <div class="col-12 mb-5">
      <fp-product-regular-price-mifa
        [regularPrice]="price"
        [flkText]="price > 0 && (regularPrice > 0 || offerPrice > 0) ? true : false"
        [flkTextStrike]="regularPrice > 0 ? true : false"
        fontSize="--1">
      </fp-product-regular-price-mifa>
      <fp-product-price-mifa [price]="regularPrice > 0 && crossOutPL ? regularPrice : 0"
        [icon]="regularPrice > 0 && crossOutPL ? true: false" [place]="place" [fontSize]="fontSizeRegular">
      </fp-product-price-mifa>
      <fp-product-offer-price-mifa [offerPrice]="offerPrice" direction="left" display="block" [icon]="true"
        iconDirection="right" [fontSize]="fontSizeRegular" [place]="place">
      </fp-product-offer-price-mifa>
    </div>
  </div>
</div>
