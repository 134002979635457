<fp-tag-base
  class="d-block cursor-pointer"
  [class.pointer-event-none]="!tagIcon"
  (click)="iconClick()"
  tagHeight="35px"
  tagClass="text-{{tagColor}} bg-{{tagBackground}} text-tag p-2 display-vertical-center">

  <ng-content></ng-content>

  <span *ngIf="tagIcon"
        class="icon icon-16-close-{{tagColor}} ml-3">
  </span>

</fp-tag-base>
