<div class="product3Prices">
	<div class="row align-items-end">
		<div class="col-12" [ngStyle]="customStyle">
			<fp-product-regular-price
				*ngIf="regularPrice > 0"
				[regularPrice]="crossOutPL ? price : regularPrice"
				[fontSize]="fontSizeRegular"
			>
			</fp-product-regular-price>
			<div class="mt-1"></div>
			<fp-product-price
       [price]="regularPrice > 0 && crossOutPL ? regularPrice : price"
       [icon]="isMifaBrand && regularPrice > 0 && crossOutPL ? true: false" [fontSize]="fontSizeRegular">
			</fp-product-price>
			<div class="mt-1"></div>
      <ng-container *ngIf="suggested; else suggestedElse">
          <fp-product-offer-price-predictive
          [offerPrice]="offerPrice"
          [direction]="direction"
          display="block"
          [icon]="true"
          [fontSize]="fontSizeRegular"
          [iconDirection]="direction"
        >
        </fp-product-offer-price-predictive>
      </ng-container>
      <ng-template #suggestedElse>
        <fp-product-offer-price
        [offerPrice]="offerPrice"
        [direction]="direction"
        display="block"
        [icon]="true"
        [fontSize]="fontSizeRegular"
        [iconDirection]="direction"
      >
      </fp-product-offer-price>
      </ng-template>

		</div>
	</div>
</div>

