import { Component, Input } from '@angular/core';

@Component({
  selector: 'fp-link-button',
  templateUrl: './link-button.component.html'
})
export class LinkButtonComponent {
  @Input()
  linkHref: any[] | string;

  @Input()
  innerClass: string = '';

  @Input()
  buttonDisplay: 'block' | 'inline-block' = 'inline-block';
}
