export interface ICampaignDepartment {
  id: number;
  name: string;
}

export class CampaignDepartment {
  id: number;
  name: string;

  constructor(iCampaignDepartment: ICampaignDepartment) {
    this.id = iCampaignDepartment.id;
    this.name = iCampaignDepartment.name;
  }
}
