import { Injectable } from '@angular/core';
import { MenuCategoriesClientService } from 'src/app/shared/service/menu-categories/menu-categories-client.service';

@Injectable()
export class MenuCategoriesImplementService {

  constructor(
    private menuCategoriesClient: MenuCategoriesClientService,
  ) { }

  public getAllMenuCategories() {
    return this.menuCategoriesClient.getAllMenuCategories();
  }
}
