<fp-button
  (click)="clickEvent()"
  id="btn-{{pickerIcon}}-quantity"
  class="d-block"
  [buttonId]="buttonPickerId"
  [ngClass]="'picker-'+ pickerSize"
  [class.pointer-event-none]="pickerDisabled"
  buttonClass="btn-picker {{pickerShadow ? 'shadow' : ''}} p-0 rounded-circle display-center h-100 w-100"
  [buttonDisabled]="pickerDisabled">
  <span *ngIf="pickerIcon !== 'plus'" class="icon icon-24-{{pickerIcon}}{{pickerDisabled ? '-gray' : ''}}"></span>
  <fp-icon iconSize="20" aria-disabled="" iconName="icon-plus" *ngIf="pickerIcon === 'plus'" style="cursor: pointer;"></fp-icon>
  <fp-icon iconSize="20" aria-disabled="" iconName="icon-delete" *ngIf="quantity === 1" style="cursor: pointer;"></fp-icon>
</fp-button>
