import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
	selector: "[appOnlyLettersDoctor]"
})
export class OnlyLettersDoctorDirective {
	constructor(private ngControl: NgControl) {}

	@HostListener("input", ["$event"]) onInputChange(event: Event) {
		const input = event.target as HTMLInputElement;
		const letters = input.value.replace(/[^a-zA-Z\s]/g, ""); // Permite letras y espacios
		this.ngControl.control?.setValue(letters);
	}
}
