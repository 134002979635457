import { FormControl, Validators } from "@angular/forms";
import { GenericValidator } from "src/app/shared/validators/generic-validator";
import { FIRST_NAME_ERROR_MESSAGES, FULLNAME_ERROR_MESSAGES } from "../parameters/global-error-message";
import { getEnableErrorMessages, getErrorbyFormControl } from "../helpers/error-controls-helpers";

export class FirstNameControl extends FormControl {
	constructor() {
		super("");
		this.settingFirstNameValidators();
	}

	private settingFirstNameValidators() {
		this.setValidators([Validators.required, GenericValidator.validateCharacters]);
	}

	public get nameError(): string {
		return getErrorbyFormControl(this, FIRST_NAME_ERROR_MESSAGES);
	}

	public get fullnameError(): string {
		return getErrorbyFormControl(this, FULLNAME_ERROR_MESSAGES);
	}
  
	public get error(): boolean {
		return getEnableErrorMessages(this);
	}
}
