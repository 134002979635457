<div class="card content pt-3 pb-3">
  <div class="row">
    <div class="col-11 mt-2">
      <fp-basic-skeleton height="25">
      </fp-basic-skeleton>
    </div>
    <div class="col-1 mt-2">
      <fp-basic-skeleton height="25">
      </fp-basic-skeleton>
    </div>
    <div class="col-12 mt-2 pl-3 pr-3">
      <fp-basic-skeleton height="18">
      </fp-basic-skeleton>
    </div>
    <div class="col-12 mt-1 pl-3 pr-3">
      <fp-basic-skeleton height="18">
      </fp-basic-skeleton>
    </div>
    <div class="col-12 mt-1 pl-3 pr-3">
      <fp-basic-skeleton height="18">
      </fp-basic-skeleton>
    </div>
    <div class="col-5 mt-1 pl-3 pr-3">
      <fp-basic-skeleton height="18">
      </fp-basic-skeleton>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-11 mt-2">
      <fp-basic-skeleton height="25">
      </fp-basic-skeleton>
    </div>
    <div class="col-1 mt-2">
      <fp-basic-skeleton height="25">
      </fp-basic-skeleton>
    </div>
  </div>


  <div class="row mt-3">
    <div class="col-11 mt-2">
      <fp-basic-skeleton height="25">
      </fp-basic-skeleton>
    </div>
    <div class="col-1 mt-2">
      <fp-basic-skeleton height="25">
      </fp-basic-skeleton>
    </div>
  </div>


  <div class="row mt-3">
    <div class="col-11 mt-2">
      <fp-basic-skeleton height="25">
      </fp-basic-skeleton>
    </div>
    <div class="col-1 mt-2">
      <fp-basic-skeleton height="25">
      </fp-basic-skeleton>
    </div>
  </div>

</div>
