import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { IconsModule } from '../../icons/icons.module';
import { TagSellerBrandComponent } from './tag-seller-brand.component';



@NgModule({
  declarations: [
    TagSellerBrandComponent,
  ],
  imports: [
    CommonModule,
    IconsModule,
  ],
  exports: [
    TagSellerBrandComponent,
  ]
})
export class TagSellerBrandModule { }
