import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Entry } from 'contentful';
import { ContentfulBanner, IContentfulBanner } from './contentful-banner.model';
import { ContentfulProductList, IContentfulProductList } from './contentful-product-list.model';
import { ContentfulEntry } from '../../../shared/models/contentful/contentful.model';

export interface IContentfulBannerProductList {
  title: string;
  titleColor: string;
  banner: Entry<IContentfulBanner>;
  productList: Entry<IContentfulProductList>;
  rows?: number;
}

export class ContentfulBannerProductList {
  title: string;
  titleColor: string;
  banner: ContentfulBanner;
  productList: ContentfulProductList;
  rows?: number;

  constructor(iContentfulBannerProductList: IContentfulBannerProductList) {
    this.title = iContentfulBannerProductList.title;
    this.titleColor = iContentfulBannerProductList.titleColor;
    this.rows = 5;
    this.banner = new ContentfulBanner(new ContentfulEntry(iContentfulBannerProductList.banner).content);
    this.productList = new ContentfulProductList(new ContentfulEntry(iContentfulBannerProductList.productList).content);
  }
}


export const CHomeBannerProductList = {
  id: CONTENTFUL_PATH.contentTypeIds.bannerProductList,
  sliderConfig: {
    'type': 5,
    'loop': false,
    'autoplay': false,
    'autoplayTime': 5000,
    'infinite': false,
    'autoHeight': true,
    'slidesConfigByBreakpoints': {
      'xxs': {
        slidesPerView: 1.26,
        spaceBetween: 16
      },
      'sm': {
        slidesPerView: 1.8,
        spaceBetween: 16
      },
      'md': {
        slidesPerView: 2.4,
        spaceBetween: 16
      },
      'lg': {
        slidesPerView: 1,
        spaceBetween: 14
      },
      'xl': {
        slidesPerView: 2,
        spaceBetween: 14
      },
      'xxl': {
        slidesPerView: 3,
        spaceBetween: 14
      },
    }
  }
};
