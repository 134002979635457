import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ERROR_STOCK_MODAL_CONFIG } from "../parameters/error-stock-modal.constant";
import { ErrorStockModalComponent } from "./../modal-component/error-stock-modal/error-stock-modal.component";

@Injectable()
export class ErrorStockModalService {
  private errorStockModalRef: MatDialogRef<ErrorStockModalComponent>;
  constructor(private matDialog: MatDialog) {}
  public openErrorStockModal() {
    this.errorStockModalRef = this.matDialog.open(
      ErrorStockModalComponent,
      ERROR_STOCK_MODAL_CONFIG
    );
    return this.errorStockModalRef;
  }
  public closeModal() {
    this.errorStockModalRef.close();
  }
}
