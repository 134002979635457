import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MainRegisterAfiliacionModalComponent } from "../modal-components/main-register-afiliacion-modal/main-register-afiliacion-modal.component";
import { MainRegisterModalComponent } from "../modal-components/main-register-modal-component/main-register-modal.component";

import { EmailVerificationModalComponent } from "../modal-components/email-verification-modal/email-verification-modal.component";
import { ItWasWrongModalComponent } from "../modal-components/it-was-wrong-modal/it-was-wrong-modal.component";
import { NewPasswordModalComponent } from "../modal-components/new-password-modal/new-password-modal.component";
import { PhoneVerificationModalComponent } from "../modal-components/phone-verification-modal/phone-verification-modal.component";
import { PreRegisterModalComponent } from "../modal-components/pre-register-modal/pre-register-modal.component";
import { UpdateEmailModalComponent } from "../modal-components/update-email-modal/update-email-modal.component";
import {
  EMAIL_VERIFICATION_MODAL_CONFIG,
  IT_WAS_WRONG_MODAL_CONFIG,
  MAIN_PRE_REGISTER_MODAL_CONFIG,
  MAIN_REGISTER_AFILIACION_MODAL_CONFIG,
  MAIN_REGISTER_MODAL_CONFIG,
  NEW_PASSWORD_MODAL_CONFIG,
  PHONE_VERIFICATION_MODAL_CONFIG,
  UPDATE_EMAIL_MODAL_CONFIG,
  CHECKOUT_REGISTER_MODAL_CONFIG,
  CHECKOUT_WELCOME_REGISTER_MODAL_CONFIG,
} from "../parameters/register-modal-manager.constant";
import { RegisterModalsManagerHelperService } from "./register-modals-manager-helper.service";
import { CheckoutRegisterModalComponent } from "../modal-components/checkout-register-modal/checkout-register-modal.component";
import { CheckoutWelcomeRegisterModalComponent } from "../modal-components/checkout-welcome-register-modal/checkout-welcome-register-modal.component";

@Injectable()
export class RegisterModalsMaganerService {
  private mainRegisterModalRef: MatDialogRef<MainRegisterModalComponent>;
  private mainRegisterAfiliacionModalRef: MatDialogRef<MainRegisterAfiliacionModalComponent>;
  private newPasswordModalRef: MatDialogRef<NewPasswordModalComponent>;
  private emailVerificationModalRef: MatDialogRef<EmailVerificationModalComponent>;
  private phoneVerificationModalRef: MatDialogRef<PhoneVerificationModalComponent>;
  public updateEmailModalRef: MatDialogRef<UpdateEmailModalComponent>;
  private itWasWrongModalRef: MatDialogRef<ItWasWrongModalComponent>;
  private mainPreRegisterModalRef: MatDialogRef<PreRegisterModalComponent>;
  private checkoutRegisterModalRef: MatDialogRef<CheckoutRegisterModalComponent>;
  private checkoutWelcomeRegisterModalRef: MatDialogRef<CheckoutWelcomeRegisterModalComponent>;

  constructor(
    private dialog: MatDialog,
    private registerModalsManagerHelpers: RegisterModalsManagerHelperService
  ) {
    this.registerModalsManagerHelpers.action$.subscribe((action) => {
      const _this = this;
      const { modalManagerAction, data }: { modalManagerAction: string, data?: any } = action;
      _this[modalManagerAction](data);
    });
  }

  public openRegisterModal() {
    this.mainRegisterModalRef = this.dialog.open(
      MainRegisterModalComponent,
      MAIN_REGISTER_MODAL_CONFIG
    );
  }

  public closeRegisterModal() {
    this.mainRegisterModalRef.close();
  }

  public openRegisterAfiliacionModal() {
    this.mainRegisterAfiliacionModalRef = this.dialog.open(
      MainRegisterAfiliacionModalComponent,
      MAIN_REGISTER_AFILIACION_MODAL_CONFIG
    );
  }

  public closeRegisterAfiliacionModal() {
    this.mainRegisterAfiliacionModalRef.close();
  }

  public openNewPasswordModal() {
    this.newPasswordModalRef = this.dialog.open(
      NewPasswordModalComponent,
      NEW_PASSWORD_MODAL_CONFIG
    );
  }

  public closeNewPasswordModal() {
    this.newPasswordModalRef.close();
  }

  public openEmailVerificationModal(data?) {
    this.emailVerificationModalRef = this.dialog.open(
      EmailVerificationModalComponent, {
        ...EMAIL_VERIFICATION_MODAL_CONFIG,
        data: data,
      }
    );
  }

  public closeEmailVerificationModal() {
    this.emailVerificationModalRef.close();
  }

  public openPhoneVerificationModal() {
    this.phoneVerificationModalRef = this.dialog.open(
      PhoneVerificationModalComponent,
      PHONE_VERIFICATION_MODAL_CONFIG
    );
  }

  public closePhoneVerificationModal() {
    this.phoneVerificationModalRef.close();
  }

  public openUpdateEmailModal() {
    this.updateEmailModalRef = this.dialog.open(
      UpdateEmailModalComponent,
      UPDATE_EMAIL_MODAL_CONFIG
    );
  }

  public closeUpdateEmailModal() {
    this.updateEmailModalRef.close();
  }

  public openPreRegisterModal() {
    this.mainPreRegisterModalRef = this.dialog.open(
      PreRegisterModalComponent,
      MAIN_PRE_REGISTER_MODAL_CONFIG
    );
  }

  public closePreRegisterModal() {
    this.mainPreRegisterModalRef.close();
  }

  public openItWasWrongModal() {
    this.itWasWrongModalRef = this.dialog.open(
      ItWasWrongModalComponent,
      IT_WAS_WRONG_MODAL_CONFIG
    );
  }

  public closeItWasWrongModal() {
    this.itWasWrongModalRef.close();
  }

  public openCheckoutRegisterModal(data?) {
    this.checkoutRegisterModalRef = this.dialog.open(
      CheckoutRegisterModalComponent, {
        ...CHECKOUT_REGISTER_MODAL_CONFIG,
        data: data,
      }
    )
  }

  public closeCheckoutRegisterModal() {
    this.checkoutRegisterModalRef.close();
  }

  public openCheckoutWelcomeRegisterModal(data?) {
    this.checkoutWelcomeRegisterModalRef = this.dialog.open(
      CheckoutWelcomeRegisterModalComponent, {
        ...CHECKOUT_WELCOME_REGISTER_MODAL_CONFIG,
        data: data,
      }
    )
  }

  public closeCheckoutWelcomeRegisterModal() {
    this.checkoutWelcomeRegisterModalRef.close();
  }
}
