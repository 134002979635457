import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdateEmailModalComponent } from '../modal-components/update-email-modal/update-email-modal.component';
import { UpdateEmailModal2Component } from '../modal-components/update-email-modal-2/update-email-modal-2.component';
import { Observable } from 'rxjs';

@Injectable()
export class UpdateEmailModalManagerService {
  constructor(
    private dialog: MatDialog,
  ) { }

  public showUpdateEmail(type: string = 'create'): Observable<any> {
    const dialogRef = this.dialog
      .open(UpdateEmailModalComponent, {
        width: '418px',
        panelClass: 'custom-full-all-modal-overlay',
        data:type
      });
    return dialogRef.afterClosed();
  }
}
