import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { LS_TOMADOR_SALE_CHANNEL } from "@parameters/tomador.parameters";
import { ContenfulConfigSalesChannelService } from "@service/contenful/contenful-sales-channel/conteful-config-sales-channel.service";
import { GenericConfigurationSalesChannel } from "@service/contenful/contenful-sales-channel/contenful-sales-channel.interface";
import { LocalStorageService } from "@service/local-storage/local-storage.service";
import { AppStoreService } from "@stores/app/app-store.service";
import { Subscription } from "rxjs/internal/Subscription";
import { OfficeModalService } from "src/app/business/office/services/office-modal.service";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { Client } from "src/app/shared/models/client/client.model";
import { ClientStoreService } from "src/app/shared/stores/client/client-store.service";
import { AddressManagerPersistanceService } from "../../../address-manager/address-services/address-manager-persistance.service";
import { NewAddressManagerService } from "../../../address-manager/address-services/new-address-manager/new-address-manager.service";
import { AddressListStoreService } from "../../../address-manager/address-stores/address-list-store.service";
import { SEARCH_CLIENT_MINIMAL_LENGHT } from "../../../address-manager/parameters/global.parameter";
import { FLOW_SEARCH_ADDRESS_TYPE } from "../../../address-manager/parameters/modal.constant";
import { TYPE_CLIENT_MANAGER } from "../../../client-manager/paramaters/client-manager.const";
import { ClientManagerModalService } from "../../../client-manager/services/client-manager-modal.service";
import { ISelectOption } from "../../../form-controls-find-client/interfaces/forms.interface";
import { ClientResponse } from "../../models/client.model";
import {
	EnumAgreementFindClient,
	EnumChannelFindClient,
	EnumFormFindClient,
	StateContentModalFindClient
} from "../../parameters/find-client.parameter";
import { FindClientRequestService } from "../../services/find-client-request.service";
import { SelectDataStoreService } from "../../../../../business/office/store/select-data-store.service";
import { HomeImplementService } from "src/app/business/home/services/home-implement.service";
import { EResultScheduleTypes } from "src/app/business/checkout/parameters/delivery-time.parameter";
import { LS_ADDRESS_SELECTED } from "@service/local-storage/constants/address.constant";

@Component({
	selector: "fp-find-client-modal",
	templateUrl: "./find-client-modal.component.html",
	styleUrls: ["./find-client-modal.component.sass"]
})
export class FindClientModalComponent implements OnInit {
	modalTitle = "Identificar al cliente";
	form: FormGroup;
	stateContentModalFindClient = StateContentModalFindClient;
	stateContent = StateContentModalFindClient.INITIAL_FIND;
	loader = false;
	textSearch = "";
	notFoundTextSearch = "";
	searchTypes: ISelectOption[] = [];
	optionsFpObjSelect = [];
	enumFormFindClient: typeof EnumFormFindClient = EnumFormFindClient;

	dataClient: Array<ClientResponse> = [];
	clientSelected: ClientResponse = null;
	clientSubscription: Subscription;

	constructor(
		public dialogRef: MatDialogRef<FindClientModalComponent>,
		private _clientManangerModalService: ClientManagerModalService,
		private _findClientRequestService: FindClientRequestService,
		private _officeModalService: OfficeModalService,
		private _clientStore: ClientStoreService,
		private _addressListStore: AddressListStoreService,
		private _addressManager: AddressManagerPersistanceService,
		private _newAddressManager: NewAddressManagerService,
		private _appStore: AppStoreService,
		private contenfulConfigSalesChannel: ContenfulConfigSalesChannelService,
		private localStorageService: LocalStorageService,
    private _selectDataStoreService : SelectDataStoreService,
    private _HomeService: HomeImplementService
	) {}

	ngOnInit() {
		this.form = new FormGroup({
			[EnumFormFindClient.CELLPHONE]: new FormControl(""),
			[EnumFormFindClient.SEARCHTYPE]: new FormControl("TEL"),
			[EnumFormFindClient.IDDATA]: new FormControl(""),
			[EnumFormFindClient.SALECHANNEL]: new FormControl("", Validators.required)
		});
		this.searchTypes = [
			{ label: "Teléfono", value: "TEL" },
			{ label: "Documento", value: "DOC" }
		];
		this.loadConfigContenfulSalesChannel();
		this.saveSaleChannel();
	}

	loadConfigContenfulSalesChannel() {
		this.contenfulConfigSalesChannel.getCloudConfigurationByKey$().subscribe((configSalesChanel: GenericConfigurationSalesChannel) => {
			const notFormat = configSalesChanel?.configuration ?? [];
			this.optionsFpObjSelect = notFormat.filter((field) => field.enable);
		});
	}

	public closeModal() {
		this._clientStore.setClient$(null);
		this._addressListStore.setAddressList(new Array<AddressItem>());
		this._addressManager.setAddressToUpdate(null);
		this._addressListStore.unsubscribeObservers();
		setTimeout(() => {
			this.dialogRef.close();
		}, 250);
	}

	onSubmitForm() {
		const searchValue = this.cellPhoneControl.value;
		const searchType = this.searchTypeControl.value;
		this.form.get(EnumFormFindClient.IDDATA).setValue("");
		if (searchValue?.length >= SEARCH_CLIENT_MINIMAL_LENGHT) {
			this.loader = true;
			this.clientSubscription ? this.clientSubscription.unsubscribe() : null;
			this.clientSubscription = this._findClientRequestService.getClients$(searchValue, searchType).subscribe(
				(response) => {
					if (response.length > 0) {
						this.dataClient = response;
						this.stateContent = StateContentModalFindClient.SUCCESS_FIND;
					} else {
						this.notFoundTextSearch = this.textSearch;
						this.stateContent = StateContentModalFindClient.NOT_FOUND;
					}
					this.loader = false;
				},
				(err) => {
					this.loader = false;
					this.notFoundTextSearch = this.textSearch;
					this.stateContent = StateContentModalFindClient.NOT_FOUND;
				}
			);
		}
	}

	saveSaleChannel() {
		this.salesChannelControl.valueChanges.subscribe((value) => {
			this.localStorageService.setItem(LS_TOMADOR_SALE_CHANNEL, value);
		});
	}

	changeValueRadio(valueSelected: ClientResponse) {
		this.clientSelected = valueSelected;
		this.changeValueIdDataControl(valueSelected.idClient);
	}

	changeValueIdDataControl(value: any) {
		this.idDataControl.setValue(value);
		this.idDataControl.updateValueAndValidity();
	}

	changeValueInput(textSearch: string) {
		this.textSearch = textSearch;
		this.cellPhoneControl.setValue(textSearch);
		this.cellPhoneControl.updateValueAndValidity();
	}

	setClientOnStore() {
		const value = this.clientSelected;

		const approvals = value.approvals ? value.approvals : [];
		// const approvalBrand = approvalsgit add ..filter((app) => app.brand === this._appStore.brandAtention && app.channel === "CALL");
		const hasTYC = approvals.some(
			(app) =>
				app.brand === this._appStore.brandAtention &&
				app.channel === EnumChannelFindClient.CALL &&
				app.agreement === EnumAgreementFindClient.TYC
		);
		const hasDATO = approvals.some(
			(app) => app.brand === this._appStore.brandAtention && app.agreement === EnumAgreementFindClient.DATO
		);
		const hasPRIV = approvals.some(
			(app) =>
				app.brand === this._appStore.brandAtention &&
				app.channel === EnumChannelFindClient.CALL &&
				app.agreement === EnumAgreementFindClient.PRIV
		);

		let client: Client = new Client({
			codClient: value.idClient,
			identityDocumentNumber: value.identityDocumentNumber ?? "",
			name: value.name ?? "",
			lastNamePat: value.lastName ?? "",
			lastNameMat: value.lastName2 ?? "",
			idIdentityDocument: value.idIdentityDocument ? value.idIdentityDocument.toString() : "",
			phone: value.phoneList ? value.phoneList[0] : null,
			phone2: value.phoneList ? value.phoneList[1] : null,
			phone3: value.phoneList ? value.phoneList[2] : null,
			email: value.email ?? "",
			isAcceptTyC: hasTYC ? hasTYC : false,
			isDataTreatment: hasDATO ? hasDATO : false,
			isPrivacyPolicy: hasPRIV ? hasPRIV : false,
			addresses: value.addresses,
			isFirstBuyIKF: value.isFirstBuyIKF,
			isFirstBuyMF: value.isFirstBuyMF,
			approvals: value.approvals,
			isAcceptLoyalty: value.isAcceptLoyalty
		});
		this._addressListStore.setAddressList((value.addresses as Array<AddressItem>));
		this._clientStore.setClient$(client);
	}

	onRegisterModal() {
		this._clientManangerModalService.openClientManagerModal(TYPE_CLIENT_MANAGER.NEW_CLIENT);
	}

	onEditModal() {
		this.setClientOnStore();
		this._clientManangerModalService.openClientManagerModal(TYPE_CLIENT_MANAGER.UPDATE_CLIENT);
		this._clientManangerModalService.getClientManangerModalRef.afterClosed().subscribe((r) => {
			this.dataClient.map((client) => {
				if (client.idClient === this._clientStore.client.codClient) {
					client.name = this._clientStore.client.name;
					client.lastName = this._clientStore.client.lastNamePat;
					client.lastName2 = this._clientStore.client.lastNameMat;
					client.email = this._clientStore.client.email;
					client.identityDocumentNumber = this._clientStore.client.identityDocumentNumber;
				}
			});
		});
	}

	onDispatchModal() {
		const value = this.clientSelected;
    const clientIdTomador = value.idClient;
    this._appStore.setStorageItem("clientIdTomador",clientIdTomador);
    this._HomeService.getPurchaseIntentTomador(clientIdTomador).subscribe(res=>{
      this.loadPurchaseIntent(res);
    });
    const loyalty =
    this._clientStore.client !== null && this._clientStore.client?.isAcceptLoyalty !== undefined
      ? this._clientStore.client?.isAcceptLoyalty
      : value.isAcceptLoyalty;

    this._selectDataStoreService.clearStates();
    this._selectDataStoreService.clearStoresAndZoneBackup();
    this._selectDataStoreService.setNearbyStore(null);

		this.setClientOnStore();
		if (value.approvals.length > 0 && value.approvals) {
			const approvalBrand = value.approvals.filter(
				(app) => app.brand === this._appStore.brandAtention && app.channel === EnumChannelFindClient.CALL
			);
			const hasTYC = approvalBrand.some((app) => app.agreement === EnumAgreementFindClient.TYC);
			const hasDATO = approvalBrand.some((app) => app.agreement === EnumAgreementFindClient.DATO);
			const hasPRIV = approvalBrand.some((app) => app.agreement === EnumAgreementFindClient.PRIV);
			if (hasTYC && hasDATO && hasPRIV && !loyalty) {
				this.onEditModal();
			} else {
				if (value.addresses.length === 0) {
					this._newAddressManager.showNewAddressModal(FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS);
				} else {
					this._officeModalService.openOfficeModal();
				}
			}
		} else {
			if (value.addresses.length === 0) {
				this._newAddressManager.showNewAddressModal(FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS);
			} else {
				this._officeModalService.openOfficeModal();
			}
		}
	}

	clearValueSalesChannel() {
		this.salesChannelControl.setValue("");
		this.salesChannelControl.updateValueAndValidity();
	}

  loadPurchaseIntent(resp){
    if (resp.statusCode === "1") {
      const deliveryType = resp.userDeliveryDto.find(dto => dto.favorite);
      const deliveryTypeCode = deliveryType?.serviceType === EResultScheduleTypes.RET
        ? EResultScheduleTypes.RET
        : EResultScheduleTypes.RAD;
      this._appStore.setStorageItem(LS_ADDRESS_SELECTED, deliveryTypeCode === EResultScheduleTypes.RET ? 'false' : 'true');
      this._appStore.setStorageItem("isRetOrRad", deliveryTypeCode);
    } else {
      this._appStore.setStorageItem(LS_ADDRESS_SELECTED, 'true');
      this._appStore.setStorageItem("isRetOrRad", "RAD");
    }
  }

	get cellPhoneControl(): FormControl {
		return this.form.controls[EnumFormFindClient.CELLPHONE] as FormControl;
	}

	get searchTypeControl(): FormControl {
		return this.form.controls[EnumFormFindClient.SEARCHTYPE] as FormControl;
	}

	get idDataControl(): FormControl {
		return this.form.controls[EnumFormFindClient.IDDATA] as FormControl;
	}

	get salesChannelControl(): FormControl {
		return this.form.controls[EnumFormFindClient.SALECHANNEL] as FormControl;
	}
}
