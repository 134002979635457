import { Injectable } from '@angular/core';
import { ENDPOINTS } from '../../parameters/endpoints';
import { GenericService } from '../generic';
import { IUserRegisterRequestParams, RegisterResponse, IResponse, IUpdatePasswordRequestParams, Response } from '../../models/register/register.model';
import { map } from 'rxjs/operators';

@Injectable()
export class RecoveringPasswordService {
  private USER_SERVICE_ENDPOINT = ENDPOINTS.USER_SERVICE;


  constructor(
    private _genericService: GenericService,
  ) { }

   // Validacion de correo y envio de mail con un link
  public recoveringPassword$(params: IUserRegisterRequestParams) {
    const path = `${this.USER_SERVICE_ENDPOINT}/recover-password`;
    return this._genericService.genericPostWithoutSecurity<IResponse>(path, params)
      .pipe(map( response => {
        const recoveringPasswordResponse = new RegisterResponse(response);
        return recoveringPasswordResponse;
    }));
  }


  // Actualizar password
  public updatePassword$(params: IUpdatePasswordRequestParams) {
    const path = `${this.USER_SERVICE_ENDPOINT}/update-password`;
    return this._genericService.genericPutWithoutSecurity<IResponse>(path, params)
      .pipe(map( response => {
        const recoveringPasswordResponse = new Response(response);
        return recoveringPasswordResponse;
    }));
  }

}
