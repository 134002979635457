import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MainRegisterModalComponent } from '../modal-components/main-register-modal-component/main-register-modal.component';

@Injectable()
export class MainRegisterModalManagerService {

  constructor(
    private dialog: MatDialog,
  ) { }

  public showMainRegister(): void {
    this.dialog.open(MainRegisterModalComponent, {
      width: '340px',
      panelClass: 'custom-full-all-modal-overlay',
    });
  }
}
