<ng-container *ngIf="fontName">
  <span
  aria-hidden="false"[ngClass]="innerClass"
  [style.color]="fontColor"
  [style.font-size]="fontSize"  class="icon icon-24-{{ fontName }}"></span>
</ng-container>

<ng-container *ngIf="iconName">
  <mat-icon
    [svgIcon]="iconName"
    [ngClass]="innerClass"
    [style.color]="fontColor"
    [style.width]="iconSize + 'px'"
    [style.height]="(iconHeight || iconSize) + 'px'"
  ></mat-icon>
</ng-container>
