<div class="custom-modal-whatsapp" [formGroup]="modalForm" style="max-height: 100vh;">
  <div class="custom-modal-header">
    <div class="form-column-title">Generar link de pago para Consulta Médica Aliviamed</div>
    <div class="form-column-button">
      <fp-button-icon class="btn-close" (click)="onClose()" [buttonIcon]="'close-outline-gray'">
      </fp-button-icon>
    </div>
  </div>

  <div class="custom-modal-body">
    <div class="cutomer-form-title">
      Llena los datos necesarios para generar el link de pago.
    </div>
    <div class="cutomer-form-subtitle" style="margin-Bottom: '16px'">
      los datos con * son obligatorios
    </div>
    <div class="container-wsp-flex" >
      <div class="row-flex">
        <div class="form-column">
          <label>Tipo y número de de documento *</label>
          <div class="containerflex">
            <div style="width: 30%;">

              <fp-select (optionSelected)="setDocumentType($event)" [optionList]="documentTypeOptions"
              [selectValueControl]="documentType" selectPlaceholder="Tipo de documento"
              selectFormControlName="idIdentityDocument">
            </fp-select>
          </div>
            <input style="width: 70%;" id="ctrl--document" #inputControl class="input sp-between" placeholder="Escribir el Número"
              [maxlength]="maxLength" formControlName="identityDocumentNumber"
                fpDocumentDirective [ngClass]="{'errorInput': modalForm.get('identityDocumentNumber').hasError('required')}">

          </div>
          <div class="containerflex">
            <div *ngIf="modalForm.get('identityDocumentNumber').hasError('required')" class="errorMessageLateral">Ingresa el número</div>
          </div>
        </div>

        <div class="form-column ">
          <label>Número de celular *</label>
          <input id="ctrl--phone" #inputControl class="w-100 input" placeholder="Escribir el Número" maxlength="11" minlength="9"
            formControlName="phone" fpDigitOnly [ngClass]="{'errorInput': modalForm.get('phone').hasError('required') || modalForm.get('phone').hasError('minlength')}">
            <div *ngIf="modalForm.get('phone').hasError('required')" class="errorMessage">Ingresa celular</div>
            <div *ngIf="!modalForm.get('phone').hasError('required') && modalForm.get('phone').hasError('minlength')" class="errorMessage">Ingrese celular válido</div>

        </div>

      </div>
      <div class="row-flex">
        <div class="form-column">
          <label>Correo electrónico*</label>
          <input id="ctrl--email" #inputControl class="w-100 input" placeholder="Escribe el Correo" maxlength="50"
            formControlName="email"[ngClass]="{'errorInput': modalForm.get('email').hasError('required') || modalForm.get('email').hasError('email')}">
          <div *ngIf="modalForm.get('email').hasError('required')" class="errorMessage">Ingresa correo electrónico</div>
          <div *ngIf="modalForm.get('email').hasError('email')" class="errorMessage">Formato de correo electrónico incorrecto</div>
        </div>
        <div class="form-column">
          <label>Nombres</label>
          <input id="ctrl--names" #inputControl class="w-100 input" placeholder="Escribe el nombre" maxlength="50"
            formControlName="name" fpCharacterOnly />
        </div>

      </div>
      <div class="row-flex">
        <div class="form-column">
          <label>Apellido Paterno</label>
          <input id="ctrl--paternal-lastname" #inputControl class="w-100 input" placeholder="Escribe el Apellido"
            maxlength="20" formControlName="lastNamePat" fpCharacterOnly />
        </div>
        <div class="form-column">
          <label>Apellido Materno</label>
          <input id="ctrl--maternal-lastname" #inputControl class="w-100 input" placeholder="Escribe el Apellido"
            maxlength="20" formControlName="lastNameMat" fpCharacterOnly />
        </div>
      </div>
    </div>
  </div>

  <div class="custom-modal-body">
    <div class="row-flex" *ngIf="!isPatient">
      <div class="purple-button">
        <label class="label-purple" (click)="openIsPatient()">Haz clic aquí, si el cliente está comprando una consulta
          para otra persona.</label>
      </div>
    </div>
    <div class="row-flex"  *ngIf="isPatient">
      <div class="cutomer-form-title">
        Datos del Paciente
      </div>
      <div class="form-column-button">
        <fp-button-icon class="btn-close" (click)="isPatientBefore()" [buttonIcon]="'close-outline-gray'">
        </fp-button-icon>
      </div>
    </div>
    <div class="row-flex" *ngIf="isPatient">

      <div class="form-column">
        <label>Tipo de Documento*</label>
        <fp-select (optionSelected)="setDocumentTypeAgent($event)" [optionList]="documentTypeOptions"
              [selectValueControl]="documentTypePatient" selectPlaceholder="Tipo de documento"
              selectFormControlName="agentType" style="width:125px;">
            </fp-select>
      </div>
      <div class="form-column">
        <label>Número de Documento*</label>
        <input id="ctrl--maternal-lastname2" #inputControl class="w-100 input" style="text-transform: uppercase;" placeholder="Escribe el Número"
          maxlength="20" formControlName="agentDocument" fpDocumentDirective [ngClass]="{'errorInput': modalForm.get('agentDocument').hasError('required')}">
          <div *ngIf="modalForm.get('agentDocument').hasError('required')" class="errorMessage">Ingresa el número</div>
          <div *ngIf="modalForm.get('agentDocument').hasError('pattern')" class="errorMessage">Documento no válido</div>
      </div>
    </div>
  </div>
  <div class="row-flex-button">
    <br/><br/><br /><br/><br />
    <fp-button-secondary class="ml-2" buttonDisplay="flex" (click)="onClose()">
      Cancelar
    </fp-button-secondary>

    <fp-button-primary class="ml-2" buttonDisplay="flex" (click)="onSubmit()" [buttonDisabled]="modalForm.invalid">
      <ng-container *ngIf="!loadingLink">Generar link de pago</ng-container>
      <fp-aliviamed-loader *ngIf="loadingLink">Cargando...</fp-aliviamed-loader>
    </fp-button-primary>
  </div>

</div>