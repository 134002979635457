import { isObject } from '../../helpers/objects-equal';

export interface IAddressNickname {
  id: number;
  name: string;
}

// use this const like default value when necessary
export const DEFAULT_ADDRESS_NICKNAME: IAddressNickname = {
  id: -1,
  name: '',
};

export class AddressNickname {

  public id: number;
  public name: string;

  constructor(addressNickname: IAddressNickname) {
    const currentAddressNickname = isObject(addressNickname) ? addressNickname : {} as IAddressNickname;
    this.id = currentAddressNickname.id || -1;
    this.name = currentAddressNickname.name || '';
  }

}
