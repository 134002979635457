import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { NearestDrugstore } from 'src/app/shared/models/nearest-drugstores/nearest-drugstores.model';
import { StoresForPickUpStoreService } from '../../pick-up-stores/stores-for-pick-up-store.service';
import { UserGeolocationStoreService } from '../../pick-up-stores/user-geolocation-store.service';
import { PickUpConfigStoreService } from '../../pick-up-stores/pick-up-config-store.service';
import { GoogleMapService } from '../../../custom-agm-core/services/google-map.service';
import { INKAFARMA_ACTIVE_MARKER, INKAFARMA_INACTIVE_MARKER, GEOLOCATION_MARKER } from '../../../custom-agm-core/parameters/markers-images.parameter';
import { GeolocationControl } from '../../../custom-agm-core/services/geolocation-custom-control';
import { DEFAULT_ZOOM_FOR_MAP } from '../../parameters/pick-up-config.parameter';
import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle';

export interface DrugstoreMarker {
  drugstore: NearestDrugstore;
  marker: google.maps.Marker;
}

export interface DrugstoreMarkerHere {
  drugstore: NearestDrugstore;
  marker: H.map.Marker;
}

@Injectable()
export class AgmManagerService {

  public map: google.maps.Map;
  private drugstoreMarkers: DrugstoreMarker[] = [];

  constructor(
    private googleMap: GoogleMapService,
    private storesForPickUpStore: StoresForPickUpStoreService,
    private userGeolocationStore: UserGeolocationStoreService,
    private pickUpConfig: PickUpConfigStoreService,
  ) { }

  public loadGoogleMap$(element: HTMLDivElement) {
    return this.googleMap.loadAgmCoreAPI()
      .pipe(switchMap(() => {
        const INITIAL_CONFIG = this.getInitialConfig();
        this.map = new google.maps.Map(element, INITIAL_CONFIG);
        return this.loadGeolocationOptions$(this.map);
      }))
      .pipe(catchError(err => {
        return of(false);
      }));
  }

  public loadGeolocationOptions$(_map: google.maps.Map) {
    return this.userGeolocationStore.userGeolocation$
      .pipe(map(userGeolocation => {
        if (userGeolocation.permissions && userGeolocation.permissions.status === 'ok') {
          this.addGeolocationControl(userGeolocation.geolocationAddress.location, _map);
        }
        return true;
      }));
  }

  private getInitialConfig(center?: google.maps.LatLng) {
    return {
      ...this.googleMap.getInitialConfig(),
      zoom: this.pickUpConfig.config.defaultZoomForMap || DEFAULT_ZOOM_FOR_MAP,
      gestureHandling: 'greedy',
      center: center ? center : new google.maps.LatLng(-12.0884464, -77.0020555),
    } as google.maps.MapOptions;
  }

  public setMapCenter(lat: number, lng: number) {
    const position = new google.maps.LatLng(lat, lng);
    this.map.setCenter(position);
  }

  public createMarker(
    position: google.maps.LatLng,
    drugstore: NearestDrugstore
  ) {
    const markerConfig = this.googleMap.getMarkerConfigForRET(this.map, position);
    const marker = new google.maps.Marker(markerConfig);
    marker.addListener('click', () => {
      this.storesForPickUpStore.setSelectedTemporalDrugstore(drugstore);
    });
    this.drugstoreMarkers.push({ marker, drugstore });
  }

  public removeMarkers() {
    if (this.drugstoreMarkers.length) {
      this.drugstoreMarkers.forEach(e => e.marker.setMap(null));
    }
    this.drugstoreMarkers = new Array();
  }

  public setDefaultMarker(_selectedDrugstore: NearestDrugstore) {
    const drugstoreMarkers = this.drugstoreMarkers.map(e => {
      const icon = e.marker.getIcon();
      if (icon === INKAFARMA_ACTIVE_MARKER) {
        e.marker.setIcon(INKAFARMA_INACTIVE_MARKER);
      }
      if (e.drugstore.id === _selectedDrugstore.id) {
        e.marker.setIcon(INKAFARMA_ACTIVE_MARKER);
        this.setMapCenter(_selectedDrugstore.latitude, _selectedDrugstore.longitude);
      }
      return e;
    });
    this.drugstoreMarkers = drugstoreMarkers;
  }

  private addGeolocationControl(position: google.maps.LatLng, _map: google.maps.Map) {
    const geolocationControlDiv = document.createElement('div');
    const geolocationControl = new GeolocationControl(geolocationControlDiv);
    this.addGeolocationMarkerToMap(position, _map);
    geolocationControl.controlUI.addEventListener('click', () => {
      _map.setOptions({
        center: position,
        zoom: this.pickUpConfig.config.defaultZoomForMap,
      });
    });
    geolocationControlDiv.style.zIndex = '1';
    _map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(geolocationControlDiv);
  }

  private addGeolocationMarkerToMap(position: google.maps.LatLng, _map: google.maps.Map) {
    return new google.maps.Marker({
      map: _map,
      position: position,
      draggable: false,
      icon: GEOLOCATION_MARKER
    });
  }
}
