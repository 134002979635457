import { Injectable } from '@angular/core';
import { AuthImplementService } from '../implements/auth/auth-implement.service';

declare var gtag;
@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  private _window: Window = window;
  public dataLayer = this._window['dataLayer'] = this._window['dataLayer'] || [];
  public productToAnalyticsList = {};

  constructor (
    public auth: AuthImplementService
  ) {}

  objEvent (category, label) {
    return {
      'event_category': category,
      'event_label': label
    };
  }

  user () {
    return this.auth.currentUser;
  }

  providerId (user) {
    if (user) {
      return user.isAnonymous ? 'Anonymous' : user.providerData[0].providerId;
    }
  }

  userId (user) {
    return user ? user.uid : null;
  }

  addToCart (product, quantity) {
    this.dataLayer.push({
      'event': 'addToCart',
      'ecommerce': {
        'currencyCode': 'PEN',
        'add': {                                // 'add' actionFieldObject measures.
            'actionField': {'list': 'adding To Cart '},
            'products': [this.formatProduct(product, quantity)]
        }
      }
    });
  }

  addAddressButton () {
    gtag('event', 'clic add_address_button', this.objEvent('Sales', 'add_address_button'));
    gtag('set', 'add_address_button', this.parameters());
  }

  changeAddressButton () {
    gtag('event', 'clic change_address_button', this.objEvent('Sales', 'change_address_button'));
    gtag('set', 'change_address_button', this.parameters());
  }

  checkout_confirm_button (preConfirmCart) {
    const user = this.user();
    const parameters = {
        userId: this.userId(user),
        addressId: preConfirmCart.orderInfo.address.id,
        discountId: preConfirmCart.orderInfo.discountApplied,
        paymentMethodId: preConfirmCart.orderInfo.paymentMethod.id,
        creditCardId: preConfirmCart.orderInfo.paymentMethod.creditCardId,
        schedule: preConfirmCart.orderInfo.orderDate,
        receiptType: null,
        origin: 'appWeb',
        means: this.providerId(user)
    };
    this.dataLayer.push({
        'event': 'checkout_confirm_button',
        'checkout_confirm_button': {
          parameters: parameters
        }
    });
  }

  checkout = function () {
    this.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
            'checkout': {
                'actionField': {'step': 1},
                'products': this.productToAnalyticsList
            }
        }
    });
  };

  beginCheckout = function (model) {
      const parameters = {
          currency: 'PEN',
          'items': this.formatProductList(model.productItemList),
          'coupon': null
      };
      gtag('event', 'begin_checkout', parameters);
      this.checkout(model);
  };

  continueSendCode () {
    gtag('event', 'clic continue_sms_request', this.objEvent('Login', 'continue_sms_request'));
    gtag('set', 'continue_sms_request', this.parameters());
  }

  viewProfile () {
    gtag('event', 'view profile', this.objEvent('Profile', 'profile'));
    gtag('set', 'view profile', this.parameters());
  }

  formatProductList (productItemList) {
    let productToAnalyticsList = {};
    const items = [];
    productItemList.forEach(product => {
      items.push({
        'id': product.id,
        'name': product.name,
        'price': product.subTotal,
        'quantity': product.quantity
      });
    });
    productToAnalyticsList = items;
    return items;
  }

  purchase (item) {
    this.dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'purchase': {
          'actionField': {
              'id': item.id,                         // Transaction ID. Required for purchases and refunds.
              'affiliation': null,
              'revenue': item.purchaseSummary.total,            // Total transaction value (incl. tax and shipping)
              'tax': null,
              'shipping': item.purchaseSummary.deliveryCost,
              'coupon': item.purchaseSummary.discount
          },
          'products': this.formatProductList(item.shoppingList)
        }
      }
    });
  }

  productDetail (product, q) {
    this.dataLayer.push({
        'event': 'productDetail',
        'ecommerce': {
            'detail': {
                'actionField': {'list': 'productDetail'},    // 'detail' actions have an optional list property.
                'products': [this.formatProduct(product, q)]
            }
        }
    });
  }

  selectPaymentMethod () {
    gtag('event', 'view select_payment_method', this.objEvent('Sales', 'select_payment_method'));
    gtag('set', 'select_payment_method', this.parameters());
  }

  parameters () {
    const user = this.user();
    const providerId = this.providerId(user);
    return {
        userId: this.userId(user),
        origin: 'appWeb',
        means: providerId
    };
  }

  formatProduct (product, quantity: any) {
    return {                        //  adding a product to a shopping cart.
        'name': product.name,
        'id': product.id,
        'price': product.presentations[0].price,
        'brand': null,
        'category': null,
        'variant': null,
        'quantity': quantity,
    };
  }

  sendAlgolia (value:string) {
    this.dataLayer.push({
      event: 'Hits Viewed',
      query: value
    }); 
  }

  sendAlgoliaClickAfterSearch () {
    this.dataLayer.push({
      event: 'gtm.click',
      eventName: "search_click",
      eventCategory: "Search",
      eventAction: "Click",
      eventLabel: "search",
    }); 
  }

}
