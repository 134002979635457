import { Injectable } from "@angular/core";
import { AddressNicknamesStoreService } from "../address-stores/address-nicknames-store.service";
import { NewAddressFormService } from "./new-address-form.service";

import { AddressNickname } from "src/app/shared/models/address/address-nickname.model";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { IGoogleFormattedAddress } from "../../custom-agm-core/services/google-map-places.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";

@Injectable({
  providedIn: "root",
})
export class NewAddressFormHelperService {
  private addressNicknameList: AddressNickname[];

  constructor(
    private newAddressForm: NewAddressFormService,
    private addressNicknamesStore: AddressNicknamesStoreService
  ) {
    this.addressNicknamesStore.addressNicknameList$.subscribe(
      (addressNicknames) => {
        this.addressNicknameList = addressNicknames;
      }
    );
  }

  public setFormValuesToUpdate(addressToUpdate: AddressItem) {
    const NUMBER_REG_EXP = /[0-9]{2,}/g;
    const nicknameId = this.getNicknameIdByName(addressToUpdate.name);
    let _number = addressToUpdate.number ? " " + addressToUpdate.number : "";
    _number = NUMBER_REG_EXP.test(addressToUpdate.street) ? "" : _number;
    const _district = addressToUpdate.district && !OrderTakerValidator.isOrderTakerEnv()
      ? ", " + addressToUpdate.district
      : "";
    const _city = addressToUpdate.city ? ", " + addressToUpdate.city : "";
    const _address = `${addressToUpdate.street}${_number}${_district}${_city}`;
    this.newAddressForm.form.setValue({
      direction: _address,
      addressNickname: nicknameId,
      reference: OrderTakerValidator.isOrderTakerEnv() ?  addressToUpdate.reference: addressToUpdate.notes ,
      number: addressToUpdate.number,
      otherDirection: nicknameId === 3 ? addressToUpdate.name : "",
    });
    if (this.newAddressForm.addressNicknameControl.value !== 3) {
      this.newAddressForm.otherDirectionControl.clearValidators();
      this.newAddressForm.otherDirectionControl.updateValueAndValidity();
    }
  }

  private getNicknameIdByName(nickname: string): number {
    const currentNickname = this.addressNicknameList.find(
      (addressNickname) => addressNickname.name === nickname
    );
    return currentNickname ? currentNickname.id : 3;
  }

  public getGoogleFormattedAddress(addressToUpdate: AddressItem) {
    const location = {
      lat: addressToUpdate.latitude,
      lng: addressToUpdate.longitude,
    };
    return {
      streetNumber: addressToUpdate.number,
      country: addressToUpdate.country,
      district: addressToUpdate.district,
      formattedAddressName: addressToUpdate.street,
      province: addressToUpdate.city,
      location: new google.maps.LatLng(location.lat, location.lng),
    } as IGoogleFormattedAddress;
  }

  public resetFormToCreateAddress() {
    this.newAddressForm.form.setValue({
      direction: "",
      addressNickname: 1,
      reference: "",
      // mz: "",
      number: null,
      // lote: null,
      otherDirection: "",
    });
    this.newAddressForm.otherDirectionControl.clearValidators();
    this.newAddressForm.otherDirectionControl.updateValueAndValidity();
  }
}
