import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Iitemspack } from 'src/app/business/product-detail/models/product-detail.interface';

@Component({
  selector: 'fp-productPack-presentation-responsive',
  templateUrl: './productPack-presentation-responsive.component.html',
  styleUrls: ['./productPack-presentation-responsive.component.sass']
})
export class ProductPackPresentationResponsiveComponent implements OnInit, OnChanges {

  @Input() productNumber: number;
  @Input() itemsPack: Iitemspack[];


  constructor() {
  }


  ngOnInit() {

  }

  ngOnChanges(): void {

  }

}
