<section class="unfind-cliente-container">
	<div class="unfind-cliente__icon">
		<fp-icon iconName="face_sad" iconSize="48"></fp-icon>
	</div>
	<p class="unfind-cliente__title">No se encuentra registrado el Nro {{ cellphone }}</p>
	<span class="unfind-cliente__subTitle mb-5">Realiza una nueva búsqueda o registra un cliente</span>
	<fp-button-primary (click)="onRegister()" [buttonDisabled]="disabled" [buttonClass]="'d-flex align-items-center'">
		<fp-icon iconName="plus-sign-white" iconSize="20" innerClass="mr-2"></fp-icon>
		<span class="text-body-bold-2">Registrar cliente</span>
	</fp-button-primary>
</section>
