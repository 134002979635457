export enum EProductPresentationStatus {
  available = 1,
  unavailable = 2
}

export class ProductPresentations {
  public productPresentations: Array<ProductPresentation>;
}

export class ProductPresentation {
  public id: number;
  public stock: number;
  public stockRet?: number;
  public maxUnitSale: number;
  public price: number;
  public image: string;
  public description: string;
  public presentation: string;
  public regularPrice: number;
  public offerPrice: number;
  public fractionated: boolean;
  public quantityLimit: number;
  public crossOutPL?: boolean;
  public status?: EProductPresentationStatus;
}

export class ProductQuantityLimit {
  public quantityLimit: number;

  constructor(productPresentation: ProductPresentation) {
    this.quantityLimit = (productPresentation.stock >= productPresentation.maxUnitSale ? productPresentation.maxUnitSale : productPresentation.stock) || 0;
  }
}
