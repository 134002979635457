import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BodyTransactionOrder } from '@models/order-app-agora-pay/order-app-agora-pay';
import { SignatureTransactionClientService } from 'src/app/shared/service/signature/signature-transaction-client.service';

@Injectable({
  providedIn: 'root'
})
export class SignatureTransactionImplementService {
  constructor(
    private signatureTransactionClientService: SignatureTransactionClientService,
  ) { }

  public getSignatureTransaction(bodyTransactionOrder: BodyTransactionOrder, params: HttpParams = null,) {
    return this.signatureTransactionClientService.getSignatureTransaction$(bodyTransactionOrder, params);
  }
}
