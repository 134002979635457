import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ECOLORS, ITagValue, COLORS } from '../models/tag-checkout.model';
import { environment } from '@environments/environment';
import { EBrand } from '@parameters/access/brand.parameter';
@Component({
  selector: 'fp-tag-checkout',
  templateUrl: './tag-checkout.component.html',
  styleUrls: ['./tag-checkout.component.sass']
})
export class TagCheckoutComponent implements OnInit {

  @Input() text = 'tag';
  @Input() color: ECOLORS = ECOLORS.BLUE;
  @Input() showRemoveIcon = true;
  @Input() tagValue = {} as ITagValue;
  @Input() isValidatingCupon : boolean;
  @Output() onremoveicon = new EventEmitter<ITagValue>();
  iconClose: string;

  constructor() {}

  ngOnInit() {
  }

  public get tagColor() {
    this.iconClose = environment.brand === EBrand.mifarma ? 'icon-24-close-white' : 'icon-16-close-white'
    return environment.brand === EBrand.mifarma ? 'mifarma' : '';
  }

  public removeIconEvent() {
    this.onremoveicon.emit(this.tagValue);
  }

}
