import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NearestDrugstore } from '@models/nearest-drugstores/nearest-drugstores.model';
import { AppStoreService } from '@stores/app/app-store.service';
import { Subscription } from 'rxjs';
import { ProductDetail } from 'src/app/business/product-detail/models/product-detail-model';
import { AddressListManagerService } from 'src/app/core/modules/address-manager/address-services/address-list-manager/address-list-manager.service';
import { StoresForPickUpStoreService } from 'src/app/core/modules/pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service';
import { AddressItem } from 'src/app/shared/models/address/address.model';
import { environment } from "@environments/environment";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { take } from "rxjs/operators";
import { HeaderImplementService } from "src/app/core/modules/header/services/header-implement.service";
import { CoachmarkService } from 'src/app/shared/service/coachmark/coachmark.service';
import { Router } from '@angular/router';
import { AddressListStoreService } from 'src/app/core/modules/address-manager/address-stores/address-list-store.service';
import { AddressImplementService } from 'src/app/core/modules/address-manager/address-services/address-implement.service';
import { MainLoaderService } from '@helpers/main-loader.service';
import { LS_ADDRESS_SELECTED } from 'src/app/shared/service/local-storage/constants/address.constant';

@Component({
  selector: "fp-location-selected",
  templateUrl: "./location-selected.component.html",
  styleUrls: ["./location-selected.component.sass"],
})
export class LocationSelectedComponent implements OnInit, OnDestroy {
  @Input() selectedAddress: AddressItem = {} as AddressItem;
  @Input() hasAddress: boolean;
  @Input() stylePosition: boolean;
  @Output() openUserAddressList = new EventEmitter;
  @Output() openAddNewAddress = new EventEmitter;
  @Input() product: ProductDetail;
  public selectDrugstore: NearestDrugstore;
  public drugstoreName: string;
  public drugstore = false;
  public isInkaBrand = OrderTakerValidator.isOrderTakerEnv()
  ? EBrand[EBrandAsset[environment.brand]] == EBrand.inkafarma
  : EBrand[environment.brand] == EBrand.inkafarma;
  private subscriptions: Subscription[] = [];
  public bannerHeader: boolean;

  get activeOtherModal(): boolean {
    return !this.coachmarkService.activeDrugstoreModal.getValue();
  }

  get isProductPage() {
    return this.router.url.includes('/producto/')
  }

  constructor(private _addressListManager: AddressListManagerService,
              private addressListStore: AddressListStoreService,
              private addressImplement: AddressImplementService,
              private appStoreService: AppStoreService,
              private mainLoaderService: MainLoaderService,
              private _headerImplementService: HeaderImplementService,
              private storesForPickUpStore: StoresForPickUpStoreService,
              private router: Router,
              public coachmarkService: CoachmarkService) { }

  ngOnInit() {
    this.getSelectedDrugStore();
    this.selectedDrugStore();

    this.addressImplement.loadAddressList().subscribe();

    this.addressListStore.addressList$.subscribe(() => {
      console.log(this.addressListStore.currentSelectedAddress);
    })

    if (this.isInkaBrand) {
      this.getContentfulHeader();
    } else {
      this.getContentfulHeaderMifa();
    }

    const coachMark = JSON.parse(this.appStoreService.getStorageItem("LSX"));
    if (!coachMark) {
      this.coachmarkService.activeHomeCoachMark.next(true)
    } else this.coachmarkService.activeHomeCoachMark.next(false)
  }

  openUserAddressListEvent() {
    this.openUserAddressList.emit();
  }

  openAddNewAddressEvent() {
    this.openAddNewAddress.emit();
  }

  changeRetRad(cambiar){
    this.coachmarkService.activeProductCoachmark.next(false);
    this._addressListManager.showChangeRetOrRad(cambiar ? [] : this.product, cambiar);
  }

  getSelectedDrugStore(){
    const drugstore = JSON.parse(this.appStoreService.getStorageItem('drugstoreSelected'));
    const selectAdress = JSON.parse(this.appStoreService.getStorageItem(LS_ADDRESS_SELECTED));
    const addressSelected = JSON.parse(this.appStoreService.getStorageItem('currentAddress'));
    if(selectAdress === false){
      this.selectDrugstore = drugstore;
      this.drugstoreName = this.appStoreService.capitalizeString(this.selectDrugstore?.name);
      this.drugstore = true;
    }else{
      this.drugstore = false;
      this.selectedAddress = addressSelected;
    }
  }

  public isAddressSelected(): boolean {
    if (this.selectedAddress && Object.keys(this.selectedAddress).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  selectedDrugStore(){
    this.storesForPickUpStore.setDrugstore.subscribe(drugstore => {
      this.selectDrugstore = drugstore;
      this.drugstoreName = this.appStoreService.capitalizeString(this.selectDrugstore?.name);
      this.drugstore = true;
    });
  }


  getContentfulHeader() {
    const subscription = this._headerImplementService
      .getHeader()
      .pipe(take(1))
      .subscribe((header) => {
        this.bannerHeader = header.bannerCintillo ? true : false;
      });
    this.subscriptions.push(subscription);
  }

  getContentfulHeaderMifa() {
    const subscription = this._headerImplementService
      .getHeaderMifa()
      .pipe(take(1))
      .subscribe((header) => {
        this.bannerHeader = header.bannerCintillo ? true : false;
      });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  removeCoachMarkAddress() {
    this.appStoreService.setStorageItem("LSX", "true");
    this.coachmarkService.activeHomeCoachMark.next(false)
  }
}
