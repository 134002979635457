import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { EProductStatus } from '../../models/product-status.enum';
import { ProductImplementService } from '../../service/product-implement.service';
import { ProductAlertsService } from '../../service/product-alerts.service';
import { ProductPresentation } from '../../models/product-presentation.model';
import { BUSINESS_PATHS } from 'src/app/shared/parameters/business.parameter';
import { ProductSalesforceService } from '../../service/product-salesforce.service';
import { TaggingService } from 'src/app/atm-services/tagging.service';
import { ProductSellersShoppingCartBase } from '../../models/product-shopping-cart/product-seller-shopping-cart-base.model';

@Component({
  selector: 'fp-product-seller-mini-cart',
  templateUrl: './seller-mini-cart.component.html',
  styleUrls: ['./seller-mini-cart.component.sass']
})
export class SellerMiniCartComponent implements OnChanges, OnInit {
  public eProductStatus = EProductStatus;
  public productStatus: number = EProductStatus.available;
  public productSellerStatus: number = EProductStatus.available;
  public productPresentation: ProductPresentation;
  public productPath = BUSINESS_PATHS.PRODUCT;

  @Input() productSeller: ProductSellersShoppingCartBase;

  constructor(
    private _productImplement: ProductImplementService,
    private _productAlerts: ProductAlertsService,
    private _productSalesforce: ProductSalesforceService
  ) {
  }
  ngOnInit(): void {

  }

  ngOnChanges() {

  }

  deleteProduct(id) {
    this._productImplement.deleteProduct(id);
    this._productAlerts.deleteProductAlert();
    this._productSalesforce.deleteProduct(id);
    TaggingService.productMiniCartEliminar();
    // const itemPresentation = this.product.presentations.find( e => e.id === this.product.presentationIdSelected);
    // TaggingService.removeFromCart(this.product, itemPresentation.price, itemPresentation.presentation, this.product.quantity);
  }
}
