import { Injectable } from "@angular/core";
import { Entry } from "contentful";
import { map } from "rxjs/operators";
import { CONTENTFUL_QUERIES } from "src/app/shared/parameters/querys";
import { GenericContentfulService } from "src/app/shared/service/generic";

@Injectable({ providedIn: "root" })
export class ContenfulConfigService {
	constructor(private generictContenful: GenericContentfulService) {}

	public getCloudConfigurationByKey$<T>(keyConfiguration: string, contentType: string) {
		const QUERY = CONTENTFUL_QUERIES.prototype.queryToGetConfigurationByConfigId(keyConfiguration);
		return this.generictContenful.getEntries<T>(contentType, QUERY).pipe(
			map((entries) => {
				const configuration = entries.length ? entries[0] : ({} as Entry<T>);
				return configuration;
			})
		);
	}
}
