<div class="input-control margin-error">
  <div class="input-control-title label--1">
    {{ inputTitle }}
  </div>
  <div class="input-password">
    <ng-content></ng-content>
    <a class="buttom-preview" (click)="toggleIcon()">
      <span class="icon icon-16-{{ changeIcon }}"></span>
    </a>
  </div>
  <div class="label--2 errorMessage" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>
