import { Injectable } from '@angular/core';
import { Subject, from, combineLatest, Observable, of} from 'rxjs';
import { GeolocationControl } from './geolocation-custom-control';
import { INKAFARMA_INACTIVE_MARKER, BRAND_INACTIVE_MARKER } from '../parameters/markers-images.parameter';
import { GOOGLE_MAP_STYLES } from '../parameters/google-map-styles.parameter';
import { GBounds, GLatLng, GoogleMapConfig } from '../parameters/google-map-config';
import { IBasicLocation } from 'src/app/shared/geolocation/geolocation.service';
import { PositionControl } from './position-custom-control';
import { BreakpointBehavior } from 'src/app/shared/behaviors';
import { GoogleMapConfigImplementService } from './google-map-config-implement.service';
import { BREAKPOINTS } from 'src/app/shared/parameters/global';
import { take, switchMap, map, catchError } from 'rxjs/operators';
import { GOOGLE_MAP_KEY } from '../parameters/global.parameter';
import { HttpClient } from '@angular/common/http';
import { AddressItem } from 'src/app/shared/models/address/address.model';
import { Loader } from "@googlemaps/js-api-loader";
import { BreakpointState } from '@angular/cdk/layout';

export const ZOOM_BY_DEFAULT = 16;
export const ZOOM_BY_DEFAULT_2 = 12;

export interface IMarkerConfig {
  location: IBasicLocation;
  draggable: boolean;
  icon: string;
  title: string;
}

@Injectable()
export class GoogleMapService {

  public map: google.maps.Map;
  public marker: google.maps.Marker;
  public geolocationMarker: google.maps.Marker;
  public markers = [];
  public latlngbounds: google.maps.LatLngBounds;
  public PERU_BOUNDS: GBounds;
  public DEFAULT_CENTER: GLatLng;

  private isMapLoadedSubject = new Subject();
  public isMapLoaded$ = this.isMapLoadedSubject.asObservable();

  public isDesktopVersion = true;
  private _isSearchInputFocusSubject = new Subject<boolean>();
  public isSearchInputFocus$ = this._isSearchInputFocusSubject.asObservable();

  // tslint:disable-next-line: deprecation
  public markerEvent: (event: google.maps.MapMouseEvent) => void;
  private geolocationControl: GeolocationControl;
  private positionControl: PositionControl;
  private isAlreadyPutGeolocationControl = false;
  private isAlreadyPutPositionControl = false;

  private geolocationPosition: google.maps.LatLng;

  private googleMapLoader = new Loader({
    apiKey: GOOGLE_MAP_KEY,
    version: "weekly",
    libraries: ["places"]
  });
  public apiLoaded: Observable<boolean>;

  constructor(
    private googleMapConfig: GoogleMapConfigImplementService,
    private breakpointBehavior: BreakpointBehavior,
    public httpClient: HttpClient
  ) {
    this.eventForGeolocationControl = this.eventForGeolocationControl.bind(this);
    this.eventForPositionControl = this.eventForPositionControl.bind(this);

    this.apiLoaded = of(this.googleMapLoader.load()).pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public isLoadedAPILoader() {
    return from(this.apiLoaded);
  }

  private catchDeviceVersion$() {
    return this.breakpointBehavior.mediaBreakpointUpObserver(BREAKPOINTS.xl);
  }

  public loadAgmCoreAPI() {
    return from(this.apiLoaded);
  }

  public getInitialConfig() {
    return {
      zoom: ZOOM_BY_DEFAULT,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: true,
      styles: GOOGLE_MAP_STYLES,
    } as google.maps.MapOptions;
  }

  public loadGoogleMap(element: HTMLDivElement) {
    this.googleMapConfig.getGoogleMapConfig$()
      .pipe(take(1))
      .subscribe(config => {
        this.PERU_BOUNDS = config.peru_bounds;
        this.DEFAULT_CENTER = config.default_center;
        this.map = new google.maps.Map(element, this.getInitialConfig());
        this.isMapLoadedSubject.next();
      });
  }

  public getStaticPeruBounds() {
    const swPERU = new google.maps.LatLng(0.293111, -81.487189);
    const nePERU = new google.maps.LatLng(-18.355211, -67.482161);
    return new google.maps.LatLngBounds(swPERU, nePERU);
  }

  private getInitialConfigForCreateAddress() {
    const config = {
      ...this.getInitialConfig(),
      center: new google.maps.LatLng(this.DEFAULT_CENTER.lat, this.DEFAULT_CENTER.lng),
    };
    return config;
  }

  private getInitialConfigForUpdateAddress(center: google.maps.LatLng) {
    const config = {
      ...this.getInitialConfig(),
      center
    };
    return config;
  }

  public loadGoogleMapConfig$(element: HTMLDivElement, addressToUpdate?: AddressItem) {
    return this.googleMapConfig.getGoogleMapConfig$()
      .pipe(switchMap(config => {
        if (this.map) {
          return this.catchDeviceVersion$()
            .pipe(map(state => {
              this.settingMapToCreateOrUpdateAddress(element, config, state, addressToUpdate);
              return true;
            }));
        } else {
          const deviceVersion$ = this.catchDeviceVersion$();
          const loadedMap$ = from(this.apiLoaded);
          return combineLatest([deviceVersion$, loadedMap$])
            .pipe(map(([state]) => {
              this.settingMapToCreateOrUpdateAddress(element, config, state, addressToUpdate);
              return true;
            }));
        }
      }));
  }

  private settingMapToCreateOrUpdateAddress(
    element: HTMLDivElement, config: GoogleMapConfig, state: BreakpointState, addressToUpdate?: AddressItem
  ) {
    this.isDesktopVersion = state.matches;
    this.PERU_BOUNDS = config.peru_bounds;
    this.DEFAULT_CENTER = config.default_center;
    const settings = addressToUpdate
      ? this.getInitialConfigForUpdateAddress(this.getPositionFromAddress(addressToUpdate))
      : this.getInitialConfigForCreateAddress();
    this.map = new google.maps.Map(element, { ...settings });
    this.resizeMap();
    if (!this.isDesktopVersion) {
      this.settingEventsForResponsive();
    }
  }

  private getPositionFromAddress(addressToUpdate: AddressItem) {
    return new google.maps.LatLng(addressToUpdate.latitude, addressToUpdate.longitude);
  }

  public loadCustomControls(
    shouldLoadGeolocationControl: boolean,
    shouldLoadPositionControl: boolean,
    geolocationPosition?: google.maps.LatLng,
  ) {
    if (shouldLoadGeolocationControl) {
      this.addGeolocationControl(geolocationPosition);
    }
    if (shouldLoadPositionControl) {
      this.addPositionControl();
    }
  }

  private settingEventsForResponsive() {
    this.map.addListener('center_changed', () => {
      if (this.marker && this.marker.getMap()) {
        const mapCenter = this.map.getCenter().toJSON();
        this.marker.setPosition(mapCenter);
        this._isSearchInputFocusSubject.next(false);
      }
    });
    this.map.addListener('idle', () => {
      if (this.marker && this.marker.getMap()) {
        const mapCenter = this.map.getCenter();
        // tslint:disable-next-line: deprecation
        const event: google.maps.MapMouseEvent = {} as google.maps.MapMouseEvent;
        event.latLng = mapCenter;
        this.markerEvent(event);
      }
    });
  }

  public resizeMap() {
    // const currentCenter = this.map.getCenter();
    if (this.map) {
      google.maps.event.trigger(this.map, 'resize');
    }
    // this.map.setCenter(currentCenter);
  }

  // tslint:disable-next-line: deprecation
  public putMarker(location: IBasicLocation, putMarkerCallback: (event: google.maps.MapMouseEvent) => void) {
    this.marker = new google.maps.Marker({
      map: this.map,
      position: new google.maps.LatLng(location.lat, location.lng),
      draggable: this.isDesktopVersion,
    });
    this.markerEvent = putMarkerCallback;
    this.marker.addListener('dragend', putMarkerCallback);
  }

  public getMarkerConfigForRET(_map: google.maps.Map, position: google.maps.LatLng) {
    return {
      map: _map,
      position,
      draggable: false,
      icon: INKAFARMA_INACTIVE_MARKER,
    } as google.maps.MarkerOptions;
  }

  public removeMarker() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  }

  public setMapOptions(center: IBasicLocation, zoom?: number) {
    this.map.setOptions({
      center: new google.maps.LatLng(center.lat, center.lng),
      zoom: zoom ? zoom : ZOOM_BY_DEFAULT,
    });
  }

  public addGeolocationControl(position: google.maps.LatLng) {
    if (!this.isAlreadyPutGeolocationControl) {
      this.geolocationPosition = position;
      const geolocationControlDiv = document.createElement('div');
      this.geolocationControl = new GeolocationControl(geolocationControlDiv);
      this.putGeolocationMarker(position);
      this.geolocationControl.controlUI.addEventListener('click', this.eventForGeolocationControl);
      geolocationControlDiv.style.zIndex = '1';
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(geolocationControlDiv);
      this.isAlreadyPutGeolocationControl = true;
    }
  }
  public removeGeolocationControl() {
    if (this.geolocationControl) {
      // this.geolocationControl.controlUI.removeEventListener('click', this.eventForGeolocationControl);
      // this.geolocationControl = null;
    }
  }
  private eventForGeolocationControl() {
    this.map.setOptions({
      center: this.geolocationPosition,
      zoom: ZOOM_BY_DEFAULT,
    });
  }

  private putGeolocationMarker(position: google.maps.LatLng) {
    this.geolocationMarker = new google.maps.Marker({
      map: this.map,
      position,
      draggable: false,
      icon: '../../../../../../assets/images/geolocation-marker.png',
    });
  }

  public addGeolocationMarkerToMap(position: google.maps.LatLng, _map: google.maps.Map) {
    return new google.maps.Marker({
      map: _map,
      position,
      draggable: false,
      icon: '../../../../../../assets/images/geolocation-marker.png',
    });
  }

  public addPositionControl() {
    if (!this.isAlreadyPutPositionControl && this.isDesktopVersion) {
      const positionControlDiv = document.createElement('div');
      this.positionControl = new PositionControl(positionControlDiv);
      this.positionControl.controlUI.addEventListener('click', this.eventForPositionControl);
      positionControlDiv.style.zIndex = '1';
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(positionControlDiv);
      this.isAlreadyPutPositionControl = true;
    }
  }
  public removePositionControl() {
    if (this.positionControl) {
      // this.positionControl.controlUI.removeEventListener('click', this.eventForPositionControl);
      // this.positionControl = null;
    }
  }
  private eventForPositionControl() {
    const markerPosition = this.marker.getPosition();
    this.map.setOptions({
      center: markerPosition,
      zoom: ZOOM_BY_DEFAULT,
    });
  }

  public resetCustomControls() {
    this.isAlreadyPutGeolocationControl = false;
    this.isAlreadyPutPositionControl = false;
  }

  // OLD METHODS

  // tslint:disable-next-line: deprecation
  public putMarkerList(marketList: IMarkerConfig[], putMarkerCallback: (event: google.maps.MapMouseEvent) => void) {
    marketList.forEach((marker, i) => {
      this.markers[i] = this.createMarquer(marker);
      this.markers[i].addListener('dragend', putMarkerCallback);
    });
    this.centerMapByLatlngbounds();
  }

  public centerMapByLatlngbounds() {
    this.latlngbounds = new google.maps.LatLngBounds();
    this.markers.forEach(marker => {
      this.latlngbounds.extend(marker.getPosition());
    });
    this.zoomChangeBoundsListener();
  }

  public zoomChangeBoundsListener() {
    const zoomChangeBoundsListener = google.maps.event.addListener(this.map, 'bounds_changed', function (event) {
      if (this.getZoom() > ZOOM_BY_DEFAULT) {
        this.setZoom(ZOOM_BY_DEFAULT);
      }
      google.maps.event.removeListener(zoomChangeBoundsListener);
    });
    this.map.fitBounds(this.latlngbounds);
  }

  public createMarquer(marker) {
    const market = new google.maps.Marker({
      map: this.map,
      position: new google.maps.LatLng(marker.location.lat, marker.location.lng),
      draggable: marker.draggable,
      icon: marker.icon,
      title: marker.title
    });
    return market;
  }

  public updateMarkerLocation(location, index) {
    this.markers[index].setPosition(new google.maps.LatLng(location.lat, location.lng));
    this.centerMapByLatlngbounds();
  }
}
