import { Injectable } from '@angular/core';
import { GenericContentfulService } from '../generic';
import { CONTENTFUL_PATH } from '../../parameters/endpoints';
import { CONTENTFUL_QUERIES } from '../../parameters/querys';

@Injectable()
export class OneDocThreeClientService {
  constructor(
    private _GenericContentfulService: GenericContentfulService
  ) {
  }

  public getServiceLanding(slug: string) {
    return this._GenericContentfulService.getEntries(
      CONTENTFUL_PATH.contentTypeIds.serviceLanding,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

}
