import { Injectable } from "@angular/core";
import { switchMap, map, delay, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { OrderTakerValidator } from "@validators/order-taker.validator";

import {
  GeolocationService,
  IGeolocationPermission,
} from "src/app/shared/geolocation/geolocation.service";
import {
  UserGeolocationStoreService,
  IUserGeolocation,
} from "../pick-up-stores/user-geolocation-store.service";
import { GoogleMapService } from "../../custom-agm-core/services/google-map.service";
import { GoogleMapPlacesService } from "../../custom-agm-core/services/google-map-places.service";
import { AddressManagerPersistanceService } from "../../address-manager/address-services/address-manager-persistance.service";

@Injectable()
export class UserGeolocationHelperService {
  constructor(
    private geolocation: GeolocationService,
    private googleMap: GoogleMapService,
    private googleMapPlaces: GoogleMapPlacesService,
    private userGeolocationStore: UserGeolocationStoreService,
    private _addressManager: AddressManagerPersistanceService
  ) {}

  public getGeolocationPermissions$() {
    return this.geolocation.getGeolocationPermissions$();
  }

  public isPendingToAskGeolocation$() {
    return this.geolocation.isPendingToAskGeolocation$();
  }

  public getGeolocationStatusSTANDAR() {
    return this.geolocation.getGeolocationStatusSTANDAR();
  }

  public validateAndLoadGeolocationAddress$() {
    return this.geolocation
      .getGeolocationPermissions$()
      .pipe(
        switchMap((_permissions) => {
          if (_permissions.status === "ok") {
            return this.loadGeolocationAddress$(_permissions);
          } else {
            const userGeolocation = {
              permissions: _permissions,
            } as IUserGeolocation;
            this.userGeolocationStore.setUserGeolocation(userGeolocation);
            return of(true);
          }
        })
      )
      .pipe(delay(200));
  }

  public loadGeolocationAddress$(permissions: IGeolocationPermission) {
    return this.googleMap.loadAgmCoreAPI().pipe(
      switchMap(() => {
        const { coords } = permissions;
        const { userGeolocation } = this.userGeolocationStore;
        if (
          !(
            userGeolocation.permissions &&
            userGeolocation.permissions.status === "ok"
          )
        ) {
          return of(true);
        } else {
          return of(true);
        }
      })
    );
  }
}
