<div class="circle_coachmark" *ngIf="!coachMarkConsultBotica && !isUnavailable && !product?.isFromSeller"></div>

<div class="content"  [ngStyle]="{'z-index': !coachMarkConsultBotica && !coachMarkHome ? 12 : 1}">
    <div class="row">
      <ng-container *ngIf="isOrderTaker; else notOrderTaker">
        <div class="row">
          <div class="col-12">
              <div class="content-section-delivery" [ngStyle]="{'z-index': coachMarkConsultBotica && '3'}">
                <div class="heading">
                  <fp-icon  iconSize="24" [iconName]="isRAD ? (availableRad ? 'icon_rad_on' : 'icon_rad_off') : 'icon_rad_on'"></fp-icon>
                  <span class="text--4"> Despacho a domicilio</span>
                </div>
                <div class="text-despacho">
                  <span class="pl-1 text--4" [ngClass]="isRAD ? (availableRad ? '' : 'text-error') : ''">
                    {{isRAD ? (availableRad ? 'Disponible' : 'No disponible') : 'Disponible'}}
                  </span>
                </div>
              </div>
            </div>
      </div>
      <div class="row">
        <div class="col-12">
            <div class="content-section-delivery" [ngStyle]="{'z-index': coachMarkConsultBotica && '3'}">
              <div class="heading">
                <fp-icon  iconSize="24" [iconName]="isRAD ? 'icon_ret_on' : (availableRet ? 'icon_ret_on' : 'icon_ret_off')"></fp-icon>
                <span class="text--4"> Retiro en tienda (Gratis)</span>
              </div>
              <div class="text-desp" [ngClass]="{'text-despacho' : availableRet}">
                <span class="pl-1 text--4" [ngClass]="isRAD ? '' : (availableRet ? '' : 'text-error')">
                  {{isRAD ? 'Disponible' : (availableRet ? 'Disponible' : 'No disponible')}}
                </span>
                <fp-link-buttom *ngIf="!isSeller"
                  [buttonText]="'Consultar y elegir botica'"
                  (click)="showModalBotica(product)">
                </fp-link-buttom>
              </div>
              </div>
          </div>
      </div>
      </ng-container>
      <ng-template #notOrderTaker>
        <div class="row">
            <div class="col-12">
                <div class="content-section-delivery" [ngStyle]="{'z-index': coachMarkConsultBotica && '3'}">
                  <div class="heading">
                    <fp-icon *ngIf="availableRad" iconSize="24" iconName="icon_rad_on"></fp-icon>
                    <fp-icon *ngIf="!availableRad" iconSize="24" iconName="icon_rad_off"></fp-icon>
                    <span class="text--4"> Despacho a domicilio</span>
                  </div>
                  <div class="text-despacho">
                    <span class="pl-1 text--4" [ngClass]="{'text-error': !availableRad}">{{availableRad ? 'Disponible' : 'No disponible'}}</span>
                  </div>
                </div>
              </div>
        </div>
        <div class="row">
          <div class="col-12">
              <div class="content-section-delivery" [ngStyle]="{'z-index': coachMarkConsultBotica && '3'}">
                <div class="heading">
                  <fp-icon *ngIf="availableRet" iconSize="24" iconName="icon-ret"></fp-icon>
                  <fp-icon *ngIf="!availableRet" iconSize="24" iconName="icon_ret_off"></fp-icon>
                  <span class="text--4"> Retiro en tienda (Gratis)</span>
                </div>
                <div class="text-desp" [ngClass]="{'text-despacho' : availableRet}">
                  <!-- <div class="container-coachmark-ver-botica__circle" [hidden]="coachMarkConsultBotica"></div> -->
                  <span class="pl-1 text--4" [ngClass]="{'text-error': !availableRet}">{{availableRet ? 'Disponible' : 'No disponible'}}</span>
                  <fp-link-buttom *ngIf="!isSeller"
                    [buttonText]="'Consultar y elegir botica'"
                    (click)="showModalBotica(product)">
                  </fp-link-buttom>
                </div>
                </div>

                <!-- <div class="container-coachmark-ver-botica"
                     *ngIf="!isUnavailable && !product?.isFromSeller"
                     [hidden]="coachMarkConsultBotica">
                  <div class="style-text-coachmark-1 text-white">
                    ¡Conoce en qué boticas puedes retirar tu pedido!
                    <p class="style-text-coachmark-2">Selecciona el botón señalado para iniciar la consulta</p>
                  </div>
                  <div class="position-arrow">
                    <img src="../../../../../assets/images/arrow_left.svg" alt="arrow_down" class="arrow_down">
                  </div>
                  <button class="button-coachmark" (click)="removeCoachMark()">Entendido</button>
                </div> -->
            </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="highlighted__mask" *ngIf="!isUnavailable" #id_consulta_stock_agotado
        [hidden]="coachMarkConsultBotica"></div>
