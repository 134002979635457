import { Injectable } from '@angular/core';

@Injectable()
export class MainRegisterStoreService {
  private _email = '';
  private _phone = '';

  constructor() { }

  public get email() {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get phone() {
    return this._phone;
  }

  public set phone(phone: string) {
    this._phone = phone;
  }
}
