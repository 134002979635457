<fp-button
	[buttonClass]="'btn-icon label-black  btn-' + buttonDisplay + ' ' + buttonClass"
	[buttonType]="buttonType"
	[buttonDisabled]="buttonDisabled"
	[buttonStyle]="styleButton"
>
	<!-- <span class="d-flex" [class]="iconClass">
		<fp-icon [iconName]="buttonIcon" [iconSize]="iconSize"></fp-icon>
	</span> -->

	<span [class]="iconClassContainer">
		<fp-icon [iconName]="buttonIcon" [iconSize]="iconSize"></fp-icon>
	</span>

	<ng-content></ng-content>
</fp-button>
