<div class="content-modal">
  <div class="content-modal__header">
    <div class="d-flex align-items-center">
      <div class="avatar">
        <fp-icon iconSize="25" [iconName]="'avatar'"></fp-icon>
      </div>
      <div class="title">
        <h3>
          <b>{{ title }}</b>
        </h3>
        <span>{{ subTitle }}</span>
      </div>
    </div>
    <div class="actions">
      <fp-button-icon
        class="btn-close"
        (click)="onClose()"
        [buttonIcon]="'close-outline-gray'"
      >
      </fp-button-icon>
    </div>
  </div>
  <div class="content-modal__contain">
    <mat-divider></mat-divider>
    <p class="subtitle">(*) Campos obligatorios</p>
    <div class="row m-0" [formGroup]="_clientManagerForm.form">
      <div class="col-lg-12 d-flex gap-16 pb-4 px-0">
        <div class="flex-1">
          <p class="label-form label--1">Tipo de documento</p>
          <fp-select
            (optionSelected)="setDocumentType($event)"
            [optionList]="documentTypeOptions"
            [selectValueControl]="documentType"
            selectPlaceholder="Tipo de documento"
            selectFormControlName="identityDocumentType"
          >
          </fp-select>
        </div>
        <div class="flex-1">
          <fp-input-control
            [inputTitle]="'Número de documento'"
            [errorMessage]="_clientManagerForm.documentControl.documentError"
            [marginWithError]="true"
            [marginBottomZero]="true"
          >
            <input
              id="ctrl--document"
              #inputControl
              class="w-100 input"
              placeholder="Ej: 77006600"
              [maxlength]="maxLength"
              formControlName="document"
              fpDocumentDirective
              [typeDocument]="_clientManagerForm.documentTypeControl.value"
            />
          </fp-input-control>
        </div>
        <div class="flex" >
          <fp-button-icon
            buttonClass="fp-button-icon-search not-allowed"
            iconSize="36"
            [buttonIcon]="'search-icon-purple'"
            [buttonDisabled]="disableButtonSearch"
            (click)="searchClient()"
          >
          </fp-button-icon>
        </div>
      </div>
      <div *ngIf="searchUser" class="p-0">
        <div class="col-12 d-flex pb-2">
          <fp-icon [iconName]="tyc" [iconSize]="18"></fp-icon>
          <span class="field__label col-12"
          >Términos y condiciones, Políticas de privacidad
          </span>
        </div>
        <div class="col-12 d-flex pb-4">
          <fp-icon [iconName]="dato" [iconSize]="18"></fp-icon>
          <span class="field__label col-12"
          >Uso y tratamiento de datos
          </span>
        </div>
      </div>
      <div class="col-lg-12 pb-24">
        <fp-input-control
          [inputTitle]="'Nombres *'"
          [errorMessage]="_clientManagerForm.namesControl.nameError"
          [marginBottomZero]="true"
        >
          <input
            id="ctrl--names"
            #inputControl
            class="w-100 input"
            placeholder="Ej: Renato Rodrigo"
            maxlength="50"
            formControlName="names"
            fpCharacterOnly

          />
        </fp-input-control>
      </div>
      <div class="col-lg-12 d-flex gap-16 pb-24">
        <div class="flex-1">
          <fp-input-control
            [inputTitle]="'Apellido paterno *'"
            [errorMessage]="
              _clientManagerForm.paternalLastnameControl.nameError
            "
            [marginBottomZero]="true"
          >
            <input
              id="ctrl--paternal-lastname"
              #inputControl
              class="w-100 input"
              placeholder="Ej: Tizón"
              maxlength="20"
              formControlName="paternalLastname"
              fpCharacterOnly

            />
          </fp-input-control>
        </div>
        <div class="flex-1">
          <fp-input-control
            [inputTitle]="'Apellido materno'"
            [errorMessage]="
              _clientManagerForm.maternalLastnameControl.nameError
            "
            [marginBottomZero]="true"
          >
            <input
              id="ctrl--maternal-lastname"
              #inputControl
              class="w-100 input"
              placeholder="Ej: Espinoza"
              maxlength="20"
              formControlName="maternalLastname"
              fpCharacterOnly

            />
          </fp-input-control>
        </div>
      </div>
      <div class="col-lg-12 pb-24">
        <fp-input-control
          [inputTitle]="'Correo electrónico'"
          [errorMessage]="
            _clientManagerForm.emailAddressControl.emailAddressError
          "
          [marginBottomZero]="true"
        >
          <input
            id="ctrl--email"
            #inputControl
            class="w-100 input"
            placeholder="Ej: renato@correo.com"
            maxlength="50"
            formControlName="emailAddress"

          />
        </fp-input-control>
      </div>
      <div class="col-lg-12 d-flex gap-16 pb-24">
        <div class="flex-1">
          <fp-input-control
            [inputTitle]="'Teléfono principal *'"
            [errorMessage]="
              _clientManagerForm.phoneNumberControl.phoneNumberError
            "
            [marginBottomZero]="true"
          >
            <input
              id="ctrl--phone"
              #inputControl
              class="w-100 input"
              placeholder="Ej: 980 000 000"
              maxlength="9"
              formControlName="phoneNumber"
              fpDigitOnly

            />
          </fp-input-control>
        </div>
        <div class="flex-1">
          <fp-input-control
            [inputTitle]="'Teléfono adicional '"
            [marginBottomZero]="true"
          >
            <input
              id="ctrl--phone-2"
              #inputControl
              class="w-100 input"
              placeholder="Ej: 980 000 000"
              maxlength="9"
              formControlName="phoneNumberOne"
              fpDigitOnly
              [disabled]="searchUser?true:null"
            />
          </fp-input-control>
        </div>
      </div>
      <div class="col-lg-6 pb-24">
        <fp-input-control
          [inputTitle]="'Teléfono adicional '"
          [marginBottomZero]="true"
        >
          <input
            id="ctrl--phone-3"
            #inputControl
            class="w-100 input"
            placeholder="Ej: 980 000 000"
            maxlength="9"
            formControlName="phoneNumberTwo"
            fpDigitOnly

          />
        </fp-input-control>
      </div>
      <div class="col-lg-12 d-flex mb-5" *ngIf="false">
        <fp-checkbox-control
          style="margin-right: 12px"
          formControlName="termsAndPolitics"
        ></fp-checkbox-control>
        <span
          >Cliente acepta los <a href="!#">Términos y Condiciones</a> y las
          <a href="!#">Políticas de Privacidad.</a></span
        >
      </div>
      <div class="col-lg-12 d-flex mb-5" *ngIf="false">
        <fp-checkbox-control
          style="margin-right: 12px"
          formControlName="dataTreatment"
        ></fp-checkbox-control>
        <span *ngIf="!isInkaBrand"
        >Cliente acepta el <a href="!#">uso de mis datos</a> y
        <a href="!#">registro en inkaclub para acumulación.</a></span
        >
        <span *ngIf="isInkaBrand"
          >Cliente acepta el <a href="!#">Tratamiento de mis datos personales</a>
          para finalidades adicionales</span
        >
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <fp-button-primary
      class="ml-2"
      buttonDisplay="flex"
      (click)="onSubmit()"
      [buttonDisabled]="getStatusForm"
    >
      {{ textButton }}
    </fp-button-primary>
  </div>
</div>
