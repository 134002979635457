import { AddressItem } from "@models/address/address.model";
import { IApproval } from "src/app/core/modules/find-client-manager/interfaces/find-client.interface";
import { AddressResponse } from "src/app/core/modules/find-client-manager/models/client.model";

export class Client {
  codClient?: string;
  idIdentityDocument: string;
  identityDocumentNumber?: string;
  name: string;
  lastNamePat: string;
  lastNameMat?: string;
  email?: string;
  phone: string;
  phone2?: string;
  phone3?: string;
  isAcceptTyC?: boolean;
  isDataTreatment?: boolean;
  isPrivacyPolicy?:boolean;
  addresses?: Array<AddressResponse> | Array<AddressItem>;
  isFirstBuyIKF?: boolean;
  isFirstBuyMF?: boolean;
  approvals?:IApproval[];
  isAcceptLoyalty?: boolean;
  constructor(data: Client) {
    this.codClient = data ? data.codClient : null;
    this.idIdentityDocument = data ? data.idIdentityDocument : null;
    this.identityDocumentNumber = data ? data.identityDocumentNumber : null;
    this.name = data ? data.name : null;
    this.lastNamePat = data ? data.lastNamePat : null;
    this.lastNameMat = data ? data.lastNameMat : null;
    this.email = data ? data.email : null;
    this.phone = data ? data.phone : null;
    this.phone2 = data ? data.phone2 : null;
    this.phone3 = data ? data.phone3 : null;
    this.isAcceptTyC = data ? data.isAcceptTyC : false;
    this.isDataTreatment = data ? data.isDataTreatment : false;
    this.isPrivacyPolicy = data ? data.isPrivacyPolicy : false;
    this.addresses = data ? data.addresses : null;
    this.isFirstBuyIKF = data ? data.isFirstBuyIKF : true;
    this.isFirstBuyMF = data ? data.isFirstBuyMF : true;
    this.approvals =data.approvals ? data.approvals : [];
    this.isAcceptLoyalty= data.isAcceptLoyalty ? data.isAcceptLoyalty : false;
  }
}
