import { Injectable } from '@angular/core';
import { DocumentTypeControl } from 'src/app/shared/controls/document-type-control-l';
import { DocumentNumberControl } from 'src/app/shared/controls/document-number-control';
import { identityRevealedValidator } from 'src/app/shared/controls/identity-revealed.directive';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CrossDocumentControl } from 'src/app/shared/controls/cross-document-control';

@Injectable()
export class RegisterPreNewUserFormService {
  public form: FormGroup;

  private _documentTypeControl = new DocumentTypeControl();
  private _documentControl = new CrossDocumentControl();
  private _documentNumberControl = new DocumentNumberControl();

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      documentType: this._documentTypeControl,
      documentNumber: this._documentNumberControl,
    });
  }

  public resetForm() {

  }

  public get documentControl() {
    return this.form.get('document') as CrossDocumentControl;
  }
  
  public get documentTypeControl() {
    return this.form.get('documentType') as DocumentTypeControl;
  }

  public get documentNumberControl() {
    return this.form.get('documentNumber') as DocumentNumberControl;
  }
}
