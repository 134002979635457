import { Injectable } from "@angular/core";
import { GenericService } from "../generic";
import { BehaviorSubject } from "rxjs";
import { APIGEE_TOKEN } from "./types";
import { KToken } from "src/app/business/savings-wallet/models/registration-user.model";

@Injectable({
  providedIn: "root",
})
export class ApigeeStoreService {
  oAuthToken = new BehaviorSubject<APIGEE_TOKEN>({});
  superDiscountAuthToken = new BehaviorSubject<KToken>(null);

  get currentToken() {
    return this.oAuthToken.getValue();
  }

  get superDiscounToken(){
    return this.superDiscountAuthToken.getValue();
  }

  constructor(private genericService: GenericService) {}
}
