import { environment } from '@environments/environment';
import { EBrand } from '@parameters/access/brand.parameter';

import { OrderTakerValidator } from '@validators/order-taker.validator';

import { LS_RAD_OR_RET } from '@service/local-storage/constants/address.constant';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { switchMap } from 'rxjs/operators';

import { ApigeeTokenService } from '@service/apigee/apigee-token.service';
import { from, of } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class CookiesPolicyService {

  private lastCookieData: string = "";

  constructor(private http: HttpClient, private _apigeeTokenService: ApigeeTokenService) { }

  resetLocalStorage() {
    localStorage.removeItem("userid_processed_cookies");
    localStorage.removeItem("user_processed_cookies");
    localStorage.removeItem("user_accept_cookies");
    localStorage.removeItem("isFirstTime");
  }

  resetDrusgToreAndProcessType() {
    localStorage.removeItem(LS_RAD_OR_RET);
    localStorage.removeItem("drugstoreSelected");

    localStorage.setItem(LS_RAD_OR_RET, 'RAD');
  }

  public initializeCookieConsentListeners() {
    // Función común para crear un intervalo y manejar el clic en los botones
    const setupButtonListener = (buttonId: string, callback: () => void) => {
      const buttonInterval = setInterval(() => {
        const button = document.getElementById(buttonId);

        if (button) {
          clearInterval(buttonInterval);
          button.addEventListener('click', () => {
            // Esperar 500 milisegundos para que TrustArc actualice las cookies
            setTimeout(callback, 500);
          });
        }
      }, 1000);
    };

    // Configura los listeners para los botones
    setupButtonListener('truste-consent-button', () => this.handleCookieConsent(document.cookie));
    setupButtonListener('truste-show-consent', () => this.monitorCookieConsentModal());
  }

  private monitorCookieConsentModal() {
    const maxWaitTime = 3 * 60 * 1000; // 3 minutos en milisegundos
    const startTime = Date.now();

    // Intervalo para monitorizar las cookies hasta que el usuario complete la acción en el modal
    const modalCheckInterval = setInterval(() => {
      const cookies = document.cookie;
      const cookieData = this.extractCookieData(cookies);

      if (cookieData.cookiePrivacy && cookieData.trustArcConsentId) {
        clearInterval(modalCheckInterval);
        this.handleCookieConsent(cookies, cookieData);
      } else if (Date.now() - startTime > maxWaitTime) {
        // Limpiar el setInterval cuando el tiempo se haya agotado sin obtener los datos
        clearInterval(modalCheckInterval);
      }
    }, 1000);
  }

  public handleCookieConsent(cookies: string, cookieData?: { cookiePrivacy?: string, trustArcConsentId?: string }) {
    // Si no se pasaron los datos de las cookies, se obtienen de la cadena de cookies
    if (!cookieData) cookieData = this.extractCookieData(cookies);

    const cookieDataString = JSON.stringify(cookieData);
    if(this.lastCookieData === cookieDataString) return;
    this.lastCookieData = cookieDataString;

    const { cookiePrivacy, trustArcConsentId } = cookieData;

    // Termina el flujo si no hay valores válidos
    if (!cookiePrivacy && !trustArcConsentId) return; 

    const dataUser = (<any>window)?.dataUser;
    const userId = dataUser?.id || undefined;

    localStorage.setItem('trust_cookie_privacy', cookiePrivacy);
    localStorage.setItem('trust_consent_id', trustArcConsentId);

    this.sendCookieConsentData(trustArcConsentId, cookiePrivacy, userId);
  }

  private async sendCookieConsentData(uuid: string, policy: string, userId?: number) {

    const brand = environment.brand == EBrand.mifarma ? 'MF' : 'IKF';

    if (OrderTakerValidator.isOrderTakerEnv() || !environment.apigee) return;

    this._apigeeTokenService.getTokenFromOauthParam(environment.apigee.token_cookie).pipe(
      switchMap((res: { access_token: string }) => {
        return from((async () => {
          const req = await fetch("https://api.ipify.org?format=json");
          const json = await req.json();
          const response = { access_token: res.access_token, ipAddress: json.ip }

          return response;
        })());
      }),
      switchMap((res: { access_token: string, ipAddress: string }) => {
        const body = { userId, uuid, policy, ipAddress: res.ipAddress };
        const token = res.access_token;
        const headers = {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
          "app-id": "ZnBzLWJ1cy1tcy1mYXJtYWNpYXNwZXJ1YW5hcy1mcHMtYnVzLW1zLWNvb2tpZQ=="
        };
        const url = `${environment.apigee.url}/cookie/register?channel=${brand}`;
        const opts = { method: 'POST', headers, body: JSON.stringify(body) };

        fetch(url, opts)

        return of();
      })
    ).subscribe()
  }

  private convertCookieString(cookie: string) {
    return cookie.split('; ').reduce((prev, current) => {
      const [name, ...value] = current.split('=');
      prev[name] = value.join('=');
      return prev;
    }, {});
  }

  private extractCookieData(cookieString: string): { cookiePrivacy?: string, trustArcConsentId?: string } {
    // Convierte la cadena de cookies en un objeto y extrae los datos necesarios
    const parsedCookies = this.convertCookieString(cookieString);
    return {
      cookiePrivacy: parsedCookies['cmapi_cookie_privacy'],
      trustArcConsentId: parsedCookies['TAconsentID']
    };
  }

}
