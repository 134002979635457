import { Injectable } from '@angular/core';

@Injectable()

export class NewRegisterAccessStoreService {

  private _isValidToAccess = false;

  constructor() { }

  public get isValidToAccess() {
    return this._isValidToAccess;
  }

  public set isValidToAccess(isValidToAccess: boolean) {
    this._isValidToAccess = isValidToAccess;
  }
}
