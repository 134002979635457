import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'fp-static-landing-container-dialog',
  templateUrl: './static-landing-container-dialog.component.html',
  styleUrls: ['./static-landing-container-dialog.component.sass']
})
export class StaticLandingContainerDialogComponent {

  @Output() closeContainer = new EventEmitter();

  constructor() {
  }

  iconEvent() {
    this.closeContainer.emit();
  }

}
