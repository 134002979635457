<div class="content-modal">
  <div class="content-modal__header">
    <div class="d-flex align-items-center">
      <fp-icon iconSize="40" iconName="store"></fp-icon>
      <h3 class="title"><b>Selecciona una botica</b></h3>
    </div>
    <div class="actions">
      <fp-button-icon
        class="btn-close"
        (click)="onCancel()"
        [buttonIcon]="'close-outline-gray'"
      >
      </fp-button-icon>
    </div>
  </div>
  <div class="content-modal__contain">
    <mat-divider class="mb-5"></mat-divider>
    <div class="row">
      <div class="col-lg-6" [formGroup]="form">
        <fp-select-office-form></fp-select-office-form>
        <div class="info-container mt-4">
          <div class="info-container__title">
            <fp-icon [iconName]="'location'"></fp-icon>
            <span>Dirección de botica:</span>
          </div>
          <span class="info-container__subtitle"
            >{{ addressItem?.street }}, {{ addressItem?.district }} ,
            {{ addressItem?.city }}</span
          >
          <div class="info-container__title mt-5">
            <fp-icon [iconName]="'box'"></fp-icon>
            <span>Despacho disponible:</span>
          </div>
          <div class="d-flex info-container__chips">
            <fp-chip text="RET" status="success"></fp-chip>
            <fp-chip text="Express" status="success"></fp-chip>
            <fp-chip text="AM/PM" status="success"></fp-chip>
            <fp-chip text="Regular" status="error"></fp-chip>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="google-map-container">
          <!-- <div class="google-map" #googleMap>
            </div> -->
          <div class="here-map" #hereMap></div>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <fp-button class="btn-cancel" (click)="onCancel()"> Cancelar </fp-button>
    <fp-button-primary buttonDisplay="block" (click)="onSave()">
      Aceptar
    </fp-button-primary>
  </mat-dialog-actions>
</div>
