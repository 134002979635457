<div class="mb-4 mt-1">
  <label class="">Ingresa el código de 6 dígitos:</label>
</div>
<div class="six-digit">
  <input type="text" (input)="inputEvent($event, 0)" (keydown)="keydownEvent($event, 0)" #inputOne
    class="form-control mr-2 mr-2-3" maxlength="1" fpSixDigit autocomplete="off" (focus)="focusAtInputEmpty(0)"
    [disabled]="isDisabled" />
  <input type="text" (input)="inputEvent($event, 1)" (keydown)="keydownEvent($event, 1)" #inputTwo
    class="form-control mr-2 mr-2-3" maxlength="1" fpSixDigit autocomplete="off" (focus)="focusAtInputEmpty(1)"
    [disabled]="isDisabled" />
  <input type="text" (input)="inputEvent($event, 2)" (keydown)="keydownEvent($event, 2)" #inputThree
    class="form-control mr-2 mr-2-3" maxlength="1" fpSixDigit autocomplete="off" (focus)="focusAtInputEmpty(2)"
    [disabled]="isDisabled" />
  <input type="text" (input)="inputEvent($event, 3)" (keydown)="keydownEvent($event, 3)" #inputFour
    class="form-control mr-2 mr-2-3" maxlength="1" fpSixDigit autocomplete="off" (focus)="focusAtInputEmpty(3)"
    [disabled]="isDisabled" />
  <input type="text" (input)="inputEvent($event, 4)" (keydown)="keydownEvent($event, 4)" #inputFive
    class="form-control mr-2 mr-2-3" maxlength="1" fpSixDigit autocomplete="off" (focus)="focusAtInputEmpty(4)"
    [disabled]="isDisabled" />
  <input type="text" (input)="inputEvent($event, 5)" (keydown)="keydownEvent($event, 5)" (keyup)="keyupEvent($event)"
    #inputSix class="form-control" maxlength="1" fpSixDigit autocomplete="off" (focus)="focusAtInputEmpty(5)"
    [disabled]="isDisabled" />
</div>