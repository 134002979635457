import {Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { TreatmentForAdditionalPurposesImplementService } from '../service/treatment-for-additional-purposes-implement.service';
import { StaticLanding } from '../../../models/static-landing.model';

@Component({
  selector: 'fp-treatment-for-additional-purposes-dialog',
  templateUrl: './treatment-for-additional-purposes-dialog.component.html',
  styleUrls: ['./treatment-for-additional-purposes-dialog.component.sass']
})
export class TreatmentForAdditionalPurposesDialogComponent implements OnInit {

  public treatmentForAdditionalPurposes: StaticLanding;
  public loader = true;

  constructor(
    private _treatmentForAdditionalPurposesImplement: TreatmentForAdditionalPurposesImplementService,
    public _dialogRef: MatDialogRef<TreatmentForAdditionalPurposesDialogComponent>,
  ) {
  }

  ngOnInit() {
    this._treatmentForAdditionalPurposesImplement.getTreatmentForAdditonalPurposes()
      .pipe(take(1))
      .subscribe((treatmentForAdditionalPurposes: StaticLanding) => {
        this.treatmentForAdditionalPurposes = treatmentForAdditionalPurposes;
        this.loader = false;
      });
  }


  closeDialog() {
    this._dialogRef.close();
  }


}
