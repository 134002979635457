import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ProductCard } from "../../models/product-card/product-card.model";
import { EProductStatus } from "../../models/product-status.enum";
import { CoverageLocationStoreService } from '@stores/coverage-location/coverage-location-store.service';
import { RetValidateCartService } from 'src/app/shared/service/cart/ret-validate-cart.service';
import { AddressListStoreService } from '../../../address-manager/address-stores/address-list-store.service';
import { StoresForPickUpStoreService } from '../../../pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service';

@Component({
  selector: "fp-product-add-button",
  templateUrl: "./product-add-button.component.html",
  styleUrls: ["./product-add-button.component.sass"],
})
export class ProductAddButtonComponent implements OnChanges {
  public eProductStatus = EProductStatus;

  @Input() public title: string = "Agregar al carrito";
  @Input() public productInShoppingCart: boolean;
  @Input() public productStatus: number = this.eProductStatus.available;
  @Input() public quantityLimit: number;
  @Input() public quantity: number;
  @Input() public product: ProductCard;
  @Input() public buttonStyle: any;
  @Output() public newQuantity: EventEmitter<number> = new EventEmitter();
  @Output() public addProduct: EventEmitter<number> = new EventEmitter();
  @Output() public deleted: EventEmitter<number> = new EventEmitter();

  enableButton: boolean = true;

  constructor(private coverageLocationStore: CoverageLocationStoreService,
    private retValidateCartService: RetValidateCartService,
    private addressListStore: AddressListStoreService,
    private storesForPickUpStore: StoresForPickUpStoreService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.validateEnableButton();
  }

  validateEnableButton() {
    this.enableButton = this.quantity <= this.quantityLimit;
  }

  updateQuantity(quantity: number) {
    this.newQuantity.emit(quantity);
  }

  addProductToShoppingCart() {
    if (!this.coverageLocationStore.isCovered && this.retValidateCartService.isRADProcess)
		{
			const drugstore = this.storesForPickUpStore.selectedDrugstore;
			const currentAddreses = this.addressListStore.currentSelectedAddressFromStore;
			this.retValidateCartService.showOutCoverageDialog(drugstore, currentAddreses, null, this.product?.id);
			return;
		}

    this.addProduct.emit();
  }

  doDeleted($event) {
    this.deleted.emit($event)
  }
}
