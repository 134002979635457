import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select/select.component';
import { MatSelectModule } from '@angular/material/select';
import { TextareaComponent } from './textarea/textarea.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './input/input.component';
import { InputDatePickerComponent } from './input-date-picker/input-date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../../shared/shared.module';
import { InputMaskComponent } from './input-mask/input-mask.component';
import { InputDatePickerAccessorComponent } from './input-date-picker-accessor/input-date-picker-accessor.component';
import { InputDatePickerV2Component } from './input-date-picker-v2/input-date-picker-v2.component';

@NgModule({
  declarations: [
    SelectComponent,
    TextareaComponent,
    InputComponent,
    InputDatePickerComponent,
    InputDatePickerV2Component,
    InputMaskComponent,
    InputDatePickerAccessorComponent
  ],
  exports: [
    SelectComponent,
    TextareaComponent,
    InputComponent,
    InputDatePickerComponent,
    InputDatePickerV2Component,
    InputMaskComponent,
    InputDatePickerAccessorComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatIconModule,
    SharedModule
  ]
})
export class InputsModule {
}
