import { Component, OnDestroy, OnInit } from "@angular/core";
import { RegisterModalsManagerHelperService } from "../../register-services/register-modals-manager-helper.service";
import { CheckoutAnonymousUserFormStoreService } from "src/app/business/checkout/checkout-stores/checkout-anonymous-user-form-store.service";
import { Subscription } from "rxjs";
import { RegisterNewUserFormService } from "../../register-forms/register-new-user-form.service";
import { TermsOfServiceDialogImplementService } from "src/app/business/static-landing/views/terms-of-service/service/terms-of-service-dialog-implement.service";
import { PrivacyPolicyDialogImplementService } from "src/app/business/static-landing/views/privacy-policy/service/privacy-policy-dialog-implement.service";
import { TreatmentForAdditionalPurposesDialogImplementService } from "src/app/business/static-landing/views/treatment-for-additional-purposes/service/treatment-for-additional-purposes-dialog-implement.service";
import { NewRegisterStoreService } from "@stores/user/new-register-store.service";
import { EmailPhoneVerificationRequest, UserRegisterRequest } from "@models/register/user-register-request.model";
import { AuthImplementService } from "@implements/auth/auth-implement.service";
import { CampaignClientService } from "src/app/shared/service/campaign/campaign-client.service";
import { RegisterInfoTypeStoreService } from "@stores/user/register-info-type-store.service";
import { NewRegisterImplementService } from "src/app/business/new-register/services/new-register-implement.service";
import { take } from "rxjs/operators";
import { RegisterResponse, UserRegister } from "@models/register/register.model";
import { AUTH_ORIGIN, AUTH_PROVIDER, USER_REGISTRATION_ERROR_CODE } from 'src/app/shared/parameters/global';
import { UserDataMatchModalManagerService } from "src/app/business/new-register/services/user-data-match-modal-manager.service";
import { UpdateEmailModalManagerService } from "../../register-services/update-email-modal-manager.service";
import { AppStoreService } from "@stores/app/app-store.service";
import 'firebase/auth';
import firebase from 'firebase/compat/app';
import { UserInfoStoreService } from "@stores/user/user-info-store.service";

@Component({
  selector: "fp-checkout-welcome-register-modal",
  templateUrl: "./checkout-welcome-register-modal.component.html",
  styleUrls: ["./checkout-welcome-register-modal.component.sass"],
})
export class CheckoutWelcomeRegisterModalComponent implements OnInit, OnDestroy {
  public authOrigin: number;
  public userRegisterRequest = new UserRegisterRequest();
  public emailPhoneVerificationRequest = new EmailPhoneVerificationRequest();
  public anonymousForm: any;
  private subscriptions: Subscription[] = [];
  public userUid: string;
  emailUser: string;
  public isEmailEnabled: boolean;
  dataWalletUser = null;
  disableButton: boolean = false;

  constructor(
    private registerModalsManagerHelper: RegisterModalsManagerHelperService,
    private anonymousUserFormStore: CheckoutAnonymousUserFormStoreService,
    public registerNewUserForm: RegisterNewUserFormService,
    private newRegisterStore: NewRegisterStoreService,
    private termsOfServiceDialogImplement: TermsOfServiceDialogImplementService,
    private privacyPolicyDialogImplement: PrivacyPolicyDialogImplementService,
    private treatmentForAdditionalPurposesDialogImplement: TreatmentForAdditionalPurposesDialogImplementService,
    private authImplement: AuthImplementService,
    public campaignClientService: CampaignClientService,
    private registerInfoTypeStore: RegisterInfoTypeStoreService,
    private newRegisterImplement: NewRegisterImplementService,
    private userDataMatchModal: UserDataMatchModalManagerService,
    private updateEmailModal: UpdateEmailModalManagerService,
    private appStoreService: AppStoreService,
    private userInfoStore: UserInfoStoreService,
  ) {}

  ngOnInit() {
    this.authOrigin = this.registerInfoTypeStore.authenticationOrigin;
    const anonymousUserFormStoreSub = this.anonymousUserFormStore.form$.subscribe(form => {
      this.anonymousForm = form;
    });

    if (!this.anonymousForm.emailAddress) {
      this.anonymousForm.emailAddress = this.newRegisterStore.email;
    }
    this.registerNewUserForm.documentTypeControl.setValue(this.anonymousForm.documentType);
    this.registerNewUserForm.documentNumberControl.setValue(this.anonymousForm.document);
    this.registerNewUserForm.phoneNumberControl.setValue(this.anonymousForm.phoneNumber);
    this.subscriptions.push(anonymousUserFormStoreSub);

    const currentUserSub = this.authImplement.currentUserObservable.subscribe((res) => {
      if (res && res.uid) {
        this.isEmailEnabled = true;
        if (res.isAnonymous) {
          this.settingAuthUserDataEmailPassword();
        } else {
          this.userUid = res.uid;
          this.settingAuthUserDataProvedor(res);
        }
        if (!this.dataWalletUser) {
          this.getWalletUser();
        }
      }
    });
    this.subscriptions.push(currentUserSub);

    if (sessionStorage.getItem('checkVerify')) {
      const checkVerify = JSON.parse(sessionStorage.getItem('checkVerify'))
      if (checkVerify.value) {
        this.disableButton = true;
        const userRegisterRequest = JSON.parse(sessionStorage.getItem('userRegisterRequest'));
        this.registerNewUserForm.phoneNumberControl.setValue(userRegisterRequest.phone);
        this.createUserAccount();
      }
    }
  }

  settingAuthUserDataEmailPassword() {
    if (this.newRegisterStore.email) {
      this.emailUser = this.newRegisterStore.email;
      this.isEmailEnabled = false;
    }
  }

  settingAuthUserDataProvedor(authUserData) {
    if (authUserData.phoneNumber) {
      this.registerNewUserForm.phoneNumberControl.setValue(
        authUserData.phoneNumber
      );
    }
    if (authUserData.email) {
      this.emailUser = authUserData.email;
      this.isEmailEnabled = false;
    }
  }

  getWalletUser() {
    const subscription = this.campaignClientService
      .searchPurseUser(sessionStorage.getItem('documentNumber'))
      .subscribe((response) => {
        if (response && response.code === '1' && response.data) {
          this.dataWalletUser = response.data;
        }
      });
    this.subscriptions.push(subscription);
  }

  openTermsAndConditionsDialogEvent() {
    this.termsOfServiceDialogImplement.openTermsOfServiceDialog();
  }

  openPoliticsOfPrivacyDialogEvent() {
    this.privacyPolicyDialogImplement.openPrivacyPolicyDialog();
  }

  openDateAndRegisterDialogEvent() {
    this.treatmentForAdditionalPurposesDialogImplement.openTreatmentFroAdditionalPurposes();
  }

  validateNewRegisterData() {
    this.settingUserRegisterRequest();
    if (!this.isEmailEnabled) {
      this.emailPhoneVerificationEvent();
    }
  }

  emailPhoneVerificationEvent() {
    this.emailPhoneVerificationRequest.email = this.userRegisterRequest.email;
    this.emailPhoneVerificationRequest.phone = this.userRegisterRequest.phone;
    this.emailPhoneVerificationRequest.origin = this.authOrigin;
    this.emailPhoneVerificationRequest.source = 'WEB';
    const subscribeSaveOrUpdate = this.newRegisterImplement
      .saveOrUpdateUser(this.emailPhoneVerificationRequest)
      .pipe(take(1))
      .subscribe((responseRegisterClient: RegisterResponse) => {
        if (responseRegisterClient.code === USER_REGISTRATION_ERROR_CODE.SUCCESS) {
          this.createUserAccount();
        } else {
          this.handleEmailPhoneErrorCodes(responseRegisterClient);
        }
      });
    this.subscriptions.push(subscribeSaveOrUpdate);
  }

  createUserAccount() {
    const { email, password } = this.newRegisterStore;
    const _this = this;
    if (this.authOrigin === AUTH_ORIGIN.EMAIL_PASSWORD) {
      this.authImplement
        .emailAndPasswordSignUp(email, password)
        .then(() => {
          this.appStoreService.setStorageItem(AUTH_PROVIDER, 'password');
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((emailPasswordLoginResponse) => {
              this.userUid = emailPasswordLoginResponse.user.uid;
              this.authOrigin = AUTH_ORIGIN.EMAIL_PASSWORD;
              this.saveOrUpdateUserOnDB();
            });
        })
        .catch((error1) => {
          if (error1.code === 'auth/email-already-in-use') {
            firebase
              .auth()
              .fetchSignInMethodsForEmail(email)
              .then((providers) => {
                const provider = providers[0];

                if (provider.toString() === 'google.com') {
                  const googleProvider = new firebase.auth.GoogleAuthProvider();
                  const emailCredential =
                    firebase.auth.EmailAuthProvider.credential(email, password);
                  googleProvider.setCustomParameters({ login_hint: email });
                  firebase
                    .auth()
                    .signInWithPopup(googleProvider)
                    .then(function (result) {
                      result.user
                        .linkWithCredential(emailCredential)
                        .then(() => {
                          _this.appStoreService.setStorageItem(
                            AUTH_PROVIDER,
                            'password'
                          );
                          firebase
                            .auth()
                            .signInWithEmailAndPassword(email, password)
                            .then((emailPasswordLoginResponse) => {
                              _this.authOrigin = AUTH_ORIGIN.EMAIL_PASSWORD;
                              _this.userUid =
                                emailPasswordLoginResponse.user.uid;
                              _this.saveOrUpdateUserOnDB();
                            });
                        });
                    });
                } else if (provider.toString() === 'facebook.com') {
                  const facebookProvider =
                    new firebase.auth.FacebookAuthProvider();
                  const emailCredential =
                    firebase.auth.EmailAuthProvider.credential(email, password);
                  facebookProvider.setCustomParameters({ login_hint: email });
                  firebase
                    .auth()
                    .signInWithPopup(facebookProvider)
                    .then(function (result) {
                      result.user
                        .linkWithCredential(emailCredential)
                        .then(() => {
                          _this.appStoreService.setStorageItem(
                            AUTH_PROVIDER,
                            'password'
                          );
                          firebase
                            .auth()
                            .signInWithEmailAndPassword(email, password)
                            .then((emailPasswordLoginResponse) => {
                              _this.authOrigin = AUTH_ORIGIN.EMAIL_PASSWORD;
                              _this.userUid =
                                emailPasswordLoginResponse.user.uid;
                              _this.saveOrUpdateUserOnDB();
                            });
                        });
                    });
                } else if (provider.toString() === 'apple.com') {
                  const appleProvider = new firebase.auth.OAuthProvider(
                    'apple.com'
                  );
                  const emailCredential =
                    firebase.auth.EmailAuthProvider.credential(email, password);
                  appleProvider.setCustomParameters({ login_hint: email });
                  firebase
                    .auth()
                    .signInWithPopup(appleProvider)
                    .then(function (result) {
                      result.user
                        .linkWithCredential(emailCredential)
                        .then(() => {
                          _this.appStoreService.setStorageItem(
                            AUTH_PROVIDER,
                            'password'
                          );
                          firebase
                            .auth()
                            .signInWithEmailAndPassword(email, password)
                            .then((emailPasswordLoginResponse) => {
                              _this.authOrigin = AUTH_ORIGIN.EMAIL_PASSWORD;
                              _this.userUid =
                                emailPasswordLoginResponse.user.uid;
                              _this.saveOrUpdateUserOnDB();
                            });
                        });
                    });
                }
              });
          }
        });
    } else {
      this.saveOrUpdateUserOnDB();
    }
  }

  saveOrUpdateUserOnDB() {
    this.userRegisterRequest = {
      ...this.userRegisterRequest,
      ...JSON.parse(sessionStorage.getItem('userRegisterRequest')),
    }
    this.userRegisterRequest.authId = this.userUid;
    this.userRegisterRequest.origin = this.authOrigin;
    this.userRegisterRequest.isAnonymous = false;
    this.userRegisterRequest.source = 'WEB';
    const subscribeSaveOrUpdate = this.newRegisterImplement
      .saveOrUpdateUser(this.userRegisterRequest)
      .pipe(take(1))
      .subscribe((responseRegisterClient: RegisterResponse) => {
        const userData = (responseRegisterClient.data as UserRegister) || {} as UserRegister;
        this.userInfoStore.updateAuthState(userData);
        this.appStoreService.removeStorageItem(AUTH_PROVIDER);
        sessionStorage.removeItem('userRegisterRequest');
        sessionStorage.removeItem('isCheckoutRegister');
        sessionStorage.removeItem('checkVerify');
        window.location.reload();
      });
    this.subscriptions.push(subscribeSaveOrUpdate);
  }

  handleEmailPhoneErrorCodes(responseRegisterClient: RegisterResponse) {
    const {
      ERROR_EMAIL_USED,
      ERROR_PHONE_USED,
      ERROR_NOT_HANDLED,
    } = USER_REGISTRATION_ERROR_CODE;
    const userData = responseRegisterClient.data as UserRegister;
    switch (responseRegisterClient.code) {
      case ERROR_NOT_HANDLED:
        this.userDataMatchModal.showUserDataMatchModal();
        break;
      case ERROR_EMAIL_USED:
        this.userRegisterRequest.id = userData.id;
        this.createUserAccount();
        break;
      case ERROR_PHONE_USED:
        if (userData.email !== userData.emailOnConflict) {
          this.newRegisterStore.id = this.userRegisterRequest.id;
          this.newRegisterStore.emailOnConflict = userData.emailOnConflict;
          this.newRegisterStore.customToken = userData.customToken;
          this.newRegisterStore.email = userData.email;
          this.newRegisterStore.phone = userData.phone;
          sessionStorage.setItem('userRegisterRequest', JSON.stringify(this.userRegisterRequest));
          sessionStorage.setItem('isCheckoutRegister', JSON.stringify({ value: true }));
          this.closeModal();
          this.updateEmailModal.showUpdateEmail();
        } else {
          this.newRegisterStore.id = userData.id;
          this.newRegisterStore.emailOnConflict = userData.emailOnConflict;
          this.newRegisterStore.customToken = userData.customToken;
          this.createUserAccount();
        }
        break;
      default:
        this.createUserAccount();
        break;
    }
  }

  settingUserRegisterRequest() {
    this.userRegisterRequest.identityDocumentNumber = this.registerNewUserForm.documentNumberControl.value;
    this.userRegisterRequest.identityDocumentType = this.registerNewUserForm.documentTypeControl.value === 'DNI' ? 1 : 2;
    this.userRegisterRequest.name = this.capitalize(this.registerNewUserForm.nameControl.value);
    this.userRegisterRequest.paternalLastName = this.capitalize(this.registerNewUserForm.paternalLastNameControl.value);
    this.userRegisterRequest.maternalLastName = this.capitalize(this.registerNewUserForm.maternalLastNameControl.value);
    this.userRegisterRequest.email = this.newRegisterStore.email;
    this.userRegisterRequest.phone = this.registerNewUserForm.phoneNumberControl.value;
    this.userRegisterRequest.flagDataTreatment = this.registerNewUserForm.datesAndRegisterControl.value;
    this.userRegisterRequest.flagPrivacyPolicy = this.registerNewUserForm.termsAndPoliticsControl.value;
    this.userRegisterRequest.flagTermsAndConditions = this.registerNewUserForm.termsAndPoliticsControl.value;
  }

  capitalize(str: string) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  }

  closeModal() {
    this.registerModalsManagerHelper.closeCheckoutWelcomeRegisterModal();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
