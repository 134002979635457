export const GOOGLE_MAP_STYLES: google.maps.MapTypeStyle[] = [
  {
    'featureType': 'administrative.land_parcel',
    'elementType': 'labels',
    'stylers': []
  },
  {
    'featureType': 'landscape.man_made',
    'elementType': 'geometry.fill',
    'stylers': []
  },
  {
    'featureType': 'landscape.natural',
    'elementType': 'geometry.fill',
    'stylers': []
  },
  {
    'featureType': 'poi',
    'elementType': 'labels.text',
    'stylers': []
  },
  {
    'featureType': 'poi.attraction',
    'elementType': 'geometry.fill',
    'stylers': []
  },
  {
    'featureType': 'poi.business',
    'stylers': []
  },
  {
    'featureType': 'poi.business',
    'elementType': 'geometry.fill',
    'stylers': []
  },
  {
    'featureType': 'poi.business',
    'elementType': 'geometry.stroke',
    'stylers': []
  },
  {
    'featureType': 'poi.government',
    'elementType': 'geometry.fill',
    'stylers': []
  },
  {
    'featureType': 'poi.government',
    'elementType': 'geometry.stroke',
    'stylers': []
  },
  {
    'featureType': 'poi.medical',
    'elementType': 'geometry',
    'stylers': []
  },
  {
    'featureType': 'poi.medical',
    'elementType': 'geometry.fill',
    'stylers': []
  },
  {
    'featureType': 'poi.park',
    'elementType': 'geometry.fill',
    'stylers': []
  },
  {
    'featureType': 'poi.park',
    'elementType': 'labels.text',
    'stylers': []
  },
  {
    'featureType': 'poi.place_of_worship',
    'elementType': 'geometry.fill',
    'stylers': []
  },
  {
    'featureType': 'poi.place_of_worship',
    'elementType': 'geometry.stroke',
    'stylers': []
  },
  {
    'featureType': 'poi.school',
    'elementType': 'geometry.fill',
    'stylers': []
  },
  {
    'featureType': 'poi.school',
    'elementType': 'geometry.stroke',
    'stylers': []
  },
  {
    'featureType': 'poi.sports_complex',
    'elementType': 'geometry.fill',
    'stylers': []
  },
  {
    'featureType': 'road',
    'elementType': 'geometry.fill',
    'stylers': []
  },
  {
    'featureType': 'road',
    'elementType': 'geometry.stroke',
    'stylers': []
  },
  {
    'featureType': 'road',
    'elementType': 'labels.text.fill',
    'stylers': []
  },
  {
    'featureType': 'road',
    'elementType': 'labels.text.stroke',
    'stylers': []
  },
  {
    'featureType': 'road.local',
    'elementType': 'labels',
    'stylers': []
  },
  {
    'featureType': 'water',
    'elementType': 'geometry.fill',
    'stylers': []
  }
];
