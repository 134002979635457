import {Injectable} from '@angular/core';
import {ENDPOINTS} from '@parameters/endpoints';
import {AppManagerService} from '../firebase/auth/app-manager.service';
import {environment} from '@environments/environment';
import {EBrand} from '@parameters/access/brand.parameter';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})

export class OrdersFirebaseClientService {
  private readonly ORDERS_FIREBASE = ENDPOINTS.ORDERS_FIREBASE;
  motorizedRef: AngularFireObject<any>;
  settingsRef: AngularFireObject<any>;
  firstApp: firebase.app.App;
  secondApp: firebase.app.App;

  constructor(
    private firebaseDB: AngularFireDatabase,
    private appManager: AppManagerService
  ) {
    this.firstApp = this.appManager.getFirebaseApp();
    if(environment.brand === EBrand.mifarma) {
      this.secondApp = this.appManager.getFirebaseApp('second');
    }
  }

  public getOrdersFirebaseByFilter(nodes: string = null, filters: string = null): AngularFireList<unknown> {
    const firebaseApp = environment.brand === EBrand.mifarma ? this.secondApp : this.firstApp;
    return this.firebaseDB.list(firebase.database(firebaseApp).ref(this.ORDERS_FIREBASE), order => {
      return order.orderByChild(nodes).equalTo(filters);
    });
  }

  public getOrdersFirebaseByFilterLite(nodes: string = null, filters: string = null) {
    const firebaseApp = environment.brand === EBrand.mifarma ? this.secondApp : this.firstApp;
    return this.firebaseDB.list(firebaseApp.database(ENDPOINTS.FIREBASE_URL_INKATRACKER_LITE).ref(this.ORDERS_FIREBASE), order => {
      return order.orderByChild(nodes).equalTo(filters);
    });
  }

  public getMotorized(motorizedUid: string) {
    this.motorizedRef = this.firebaseDB.object(ENDPOINTS.MOTORIZED + '/' + motorizedUid);
    return this.motorizedRef;
  }

  public getSettingsFirebase() {
    this.settingsRef = this.firebaseDB.object('settings');
    return this.settingsRef;
  }

}
