import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { environment } from "../../../../environments/environment";
import { GenericHeaderService } from "./generic-header.service";

@Injectable({
  providedIn: "root"
})
export class GenericService {
  constructor(private http: HttpClient, private _genericHeaderService: GenericHeaderService) {}

  public genericGetWithoutHeader<T>(endpoint: string, params: HttpParams = null) {
    const options = {};
    if (params) {
      options["params"] = params;
    }
    return this.http.get<T>(endpoint, options).pipe(tap((response) => this.log(`Get ` + endpoint)));
  }

  public genericGet<T>(endpoint: string, params: HttpParams = null, headers: HttpHeaders = this._genericHeaderService.getCommonAuth()) {
    const options = { headers: headers };
    if (params) {
      options["params"] = params;
    }
    return this.http.get<T>(endpoint, options).pipe(tap((response) => this.log(`Get ` + endpoint)));
  }

  public genericGetProductDetail<T>(
    endpoint: string,
    params: HttpParams = null,
    headers: HttpHeaders = this._genericHeaderService.getCommonAuthDetailProduct()
  ) {
    const options = { headers: headers };
    if (params) {
      options["params"] = params;
    }
    return this.http.get<T>(endpoint, options).pipe(tap((response) => this.log(`Get ` + endpoint)));
  }

  public genericGetWithoutSecurity<T>(endpoint: string, params: HttpParams = null, headers: HttpHeaders = null) {
    const options = {};
    if (params) {
      options["params"] = params;
    }
    if (headers) {
      options["headers"] = headers;
    }
    return this.http.get<T>(endpoint, options).pipe(tap((response) => this.log(`Get ` + endpoint)));
  }

  public genericGetStaticFile<T>(endpoint: string) {
    return this.http.get<T>(endpoint).pipe(tap((response) => this.log(`Get ` + endpoint)));
  }

  public genericPost<T>(
    endpoint: string,
    body: Object,
    params: HttpParams = null,
    headers: HttpHeaders = this._genericHeaderService.getCommonAuth()
  ) {
    const options = { headers: headers };
    if (params) {
      options["params"] = params;
    }
    return this.http.post<T>(endpoint, body, options).pipe(tap((response) => this.log(`Post ` + endpoint)));
  }

  public genericPostNotification<T>(endpoint: string, body: Object, params: HttpParams = null, headers: HttpHeaders = this._genericHeaderService.getCommonNotificationAuth()) {
    const options = { headers: headers };
    if (params) { options['params'] = params; }
    return this.http.post<T>(endpoint, body, options).pipe(
      tap(response => this.log(`Post ` + endpoint))
    );
  }

  public genericPostApigee<T>(endpoint: string, body: Object, params: HttpParams = null, headers: HttpHeaders = this._genericHeaderService.getCommonAuth()) {
    const options = { headers: headers };
    return this.http.post<T>(endpoint, body, options).pipe(
      tap(response => this.log(`Post ` + endpoint))
    );
  }

  public genericPostCart<T>(endpoint: string, body: Object, params: HttpParams = null, headers: HttpHeaders = this._genericHeaderService.getCommonAuthCart()) {
    const options = { headers: headers };
    if (params) {
      options["params"] = params;
    }
    return this.http.post<T>(endpoint, body, options).pipe(tap((response) => this.log(`Post ` + endpoint)));
  }

  public genericPostWithHeaderCompanyCode<T>(
    endpoint: string,
    body: Object,
    params: HttpParams = null,
    headers: HttpHeaders = this._genericHeaderService.getCommonAuthWithCompanyCode()
  ) {
    const options = { headers: headers };
    if (params) {
      options["params"] = params;
    }
    return this.http.post<T>(endpoint, body, options).pipe(tap((response) => this.log(`Post ` + endpoint)));
  }
  // public genericPostWithoutSecurity<T>(endpoint: string, body: Object, params: HttpParams = null) {
  //   const options = {};
  //   if (params) {
  //     options['params'] = params;
  //   }
  //   return this.http.post<T>(endpoint, body, options).pipe(
  //     tap(response => this.log(`Post ` + endpoint))
  //   );
  // }
  public genericPostWithoutSecurityRegister<T>(
    endpoint: string,
    body: Object,
    params: HttpParams = null,
    headers: HttpHeaders = this._genericHeaderService.getSource()
  ) {
    const options = { headers: headers };
    if (params) {
      options["params"] = params;
    }
    return this.http.post<T>(endpoint, body, options).pipe(tap((response) => this.log(`Post ` + endpoint)));
  }

  public genericPostWithoutSecurity<T>(endpoint: string, body: Object, params: HttpParams = null, headers: HttpHeaders = null) {
    const options = {};
    if (params) {
      options["params"] = params;
    }
    if (headers) {
      options["headers"] = headers;
    }

    return this.http.post<T>(endpoint, body, options).pipe(tap((response) => this.log(`Post ` + endpoint)));
  }

  public genericPut<T>(endpoint: string, body: Object, headers: HttpHeaders = this._genericHeaderService.getCommonAuth()) {
    return this.http
      .put<T>(endpoint, body, {
        headers: headers
      })
      .pipe(tap((response) => this.log(`Put ` + endpoint)));
  }

  public genericPutWithoutSecurity<T>(endpoint: string, body: Object) {
    return this.http.put<T>(endpoint, body, {}).pipe(tap((response) => this.log(`Put ` + endpoint)));
  }

  public genericDelete<T>(endpoint: string, headers: HttpHeaders = this._genericHeaderService.getCommonAuth()) {
    return this.http
      .delete<T>(endpoint, {
        headers: headers
      })
      .pipe(tap((response) => this.log(`genericDelete ` + endpoint)));
  }

  public genericPatch<T>(endpoint: string, body: Object, headers: HttpHeaders = this._genericHeaderService.getCommonAuth()) {
    return this.http
      .patch<T>(endpoint, body, {
        headers: headers
      })
      .pipe(tap((response) => this.log(`genericPatch ` + endpoint)));
  }

  public genericPatchReturnResponse<T>(endpoint: string, body: Object, headers: HttpHeaders = this._genericHeaderService.getCommonAuth()) {
    return this.http
      .patch<T>(endpoint, body, {
        headers: headers
      })
      .pipe(tap((response) => this.log(`genericPatchReturnResponse ` + endpoint)));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  public log(message: string, showLogs = environment.show_logs) {
    if (!showLogs) {
      return true;
    }
  }

  /** Change text with a new parameter*/
  public addParameter(text: string, parameter: string) {
    return text.replace("?", parameter);
  }

  public genericPostWithoutSecurityHeaders<T>(endpoint: string, body: Object, params: HttpParams = null, headers: HttpHeaders = null) {
    const options = {};
    if (params) {
      options["params"] = params;
    }
    if (headers) {
      options["headers"] = headers;
    }

    return this.http.post<T>(endpoint, body, options).pipe(tap((response) => this.log(`Post ` + endpoint)));
  }
}
