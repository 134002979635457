<div class="obj-select-control">
  <div class="select-control-title label--1" *ngIf="label">
    {{ label }}
  </div>
  <div class="select-control-content" *ngIf="options.length > 0">
    <select class="custom-options-container label"
      [ngClass]="{'error-border': errorMessage}"
      (click)="clickEvent.emit()"
      [disabled]="isDisabled"
      (change)="selectOption($event)">
      <option
        *ngFor="let option of options"
        class="custom-option label"
        [value]="option.id"
        [selected]="selectedValue.id === option.id">
        {{ option.value }}
      </option>
    </select>
    <div class="icon-container">
      <div class="icon"></div>
    </div>
  </div>
  <div class="label--2 errorMessage" *ngIf="errorMessage">
    {{errorMessage}}
  </div>
</div>
