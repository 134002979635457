<div class="empty-cart" *ngIf="true">
  <div class="information-text desktop">
    <img
      class="img-empty mt-5 mt-lg-0"
      src="assets/images/main/{{ image }}"
      alt=""
    />
    <div class="heading-2 heading-md-6 title mt-5">
      {{ title }}
    </div>
    <div class="text--1 text-md subtitle px-3">
      {{ subtitle }}
    </div>
    <div class="buttom-content">
      <fp-link linkRouter="/" class="btn-inline-block btn-back-to-home">
        <fp-button-primary buttonDisplay="block">
          Ir a la página principal
        </fp-button-primary>
      </fp-link>
    </div>
    <div *ngIf="false && showLink" class="link">
      <fp-link-buttom
        [buttonText]="'¿No encuentras tu pedido?'"
        [id]="''"
        (onclick)="goToTracking()"
      >
      </fp-link-buttom>
    </div>
  </div>
</div>
