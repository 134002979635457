<div class="content-error-modal">
  <fp-section-container-title-login
    [title]="isInkaBrand ? '' : 'Espera...'"
    [withAnonymous]="true"
    [iconName]="'close'"
    (onclick)="closeModal()"
  >
  </fp-section-container-title-login>

  <fp-sub-section-container [flagBorderTop]="!isInkaBrand">
    <div class="content mt-4">
      <div class="mx-2 my-3 icon-alert">
        <img [src]="src" />
      </div>
      <div class="warning-message text mx-2 my-3">
        <span class="text-black font-VAGRoundedNexxt-normal-w-400"
          >Si continuas sin iniciar sesión no podrás seguir tu pedido en línea,
          ni disfrutar los beneficios que te ofrece {{isInkaBrand?'Inkafarma':'Mifarma'}}
        </span>
      </div>
      <div class="warning-buttons mx-2 mt-4 mb-2">
        <div class="mb-2">
          <fp-button-primary
            buttonDisplay="block"
            [buttonDisabled]="false"
            (click)="continueWithoutLogin()"
          >
            Continuar sin iniciar sesión
          </fp-button-primary>
        </div>
        <div class="mb-4 mt-4 text-center">
          <fp-link-buttom
            [buttonText]="'Iniciar Sesión'"
            (onclick)="closeModal()"
          >
          </fp-link-buttom>
        </div>
      </div>
    </div>
  </fp-sub-section-container>
</div>
