import {Injectable, TemplateRef} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/portal';

@Injectable()
export class StaticLandingDialogImplementService {
  constructor(
    private _matDialog: MatDialog
  ) {
  }

  openStaticLandingDialog(
    componentOrTemplateRef: ComponentType<any> | TemplateRef<any>,
    config?: MatDialogConfig) {
    this._matDialog.open(componentOrTemplateRef,
      {
        width: '634px',
        height: '570px',
        panelClass: 'static-landing-dialog',
        ...config
      });
  }

  openStaticMessageDialog(
    componentOrTemplateRef: ComponentType<any> | TemplateRef<any>,
    config?: MatDialogConfig) {
    this._matDialog.open(componentOrTemplateRef,
      {
        width: '400px',
        height: '350px',
        ...config
      });
  }

}
