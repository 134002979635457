import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IStoreToChoose } from "../interfaces/choose-a-store.interface";
import { Store } from "@models/store.model";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { SelectDataStoreService } from "src/app/business/office/store/select-data-store.service";
import { getPascalCaseFormat } from "src/app/shared/helpers/string-functions.helper";
import { INearestDrugstoreResponse, NearestDrugstore } from "src/app/shared/models/nearest-drugstores/nearest-drugstores.model";

const DRUGSTORES = [] as NearestDrugstore[];

@Injectable()
export class StoresForPickUpStoreService {
  private _drugstores: NearestDrugstore[];
  private _drugstoresSubject = new BehaviorSubject<NearestDrugstore[]>(DRUGSTORES);
  public drugstores$ = this._drugstoresSubject.asObservable();
  public deliveriTyPess: string;
  public get drugstores() {
    return this._drugstores;
  }
  store = this._selectDataStore.nearbyStore;
  nearStore: NearestDrugstore = new NearestDrugstore(
    this.store
      ? {
          address: this.store.address,
          distance: this.store.distance,
          latitude: this.store.latitude,
          longitude: this.store.longitude,
          localCode: this.store.localCode,
          name: this.store.name,
          defaultDrugstore: true,
          localOpeningHours: this.store.localOpeningHours,
          id: this.store.drugstoreWareHouseId,
          drugstoreWareHouseId: this.store.drugstoreWareHouseId
        }
      : ({} as NearestDrugstore)
  );
  @Output() setDrugstore = new EventEmitter;
  /**
   * selected drugstore for checkout
   */
  private _selectedDrugstore: NearestDrugstore;
  private _selectedDrugstoreSubject = new BehaviorSubject<NearestDrugstore>(
    OrderTakerValidator.isOrderTakerEnv() ? this.nearStore : ({} as NearestDrugstore)
  );
  public selectedDrugstore$ = this._selectedDrugstoreSubject.asObservable();
  public get selectedDrugstore() {
    // return this._selectedDrugstore;
    return this._selectedDrugstoreSubject.getValue();
  }

  /**
   * selected drugstore only on the modal of drugstores for pick up
   */
  private _selectedTemporalDrugstore: NearestDrugstore;
  private _selectedTemporalDrugstoreSubject = new BehaviorSubject<NearestDrugstore>({} as NearestDrugstore);
  public selectedTemporalDrugstore$ = this._selectedTemporalDrugstoreSubject.asObservable();
  public get selectedTemporalDrugstore() {
    return this._selectedTemporalDrugstore;
  }

  constructor(private _selectDataStore: SelectDataStoreService) {
    this._drugstoresSubject.next([]);
    this._selectedTemporalDrugstoreSubject.next(null);

    this.drugstores$.subscribe((stores) => (this._drugstores = stores));
    this.selectedDrugstore$.subscribe((selected) => (this._selectedDrugstore = selected));
    this.selectedTemporalDrugstore$.subscribe((selected) => (this._selectedTemporalDrugstore = selected));
  }

  public getDrugstoresToChoose$() {
    return this.drugstores$.pipe(
      map((drugstores) => {
        return drugstores.map((drugstore) => this.getFormattedNearestDrugstore(drugstore));
      })
    );
  }

  get selectedDrugstoreFromStorage(): INearestDrugstoreResponse {
    return JSON.parse(localStorage.getItem("drugstoreSelected"));
  }

  public getSelectedDrugstore$() {
    return this.selectedDrugstore$.pipe(map((selectedDrugstore) => this.getFormattedNearestDrugstore(selectedDrugstore)));
  }

  public setDrugstores(drugstores: NearestDrugstore[]) {
    const formattedDrugstores = drugstores.map((d) => {
      d.address = getPascalCaseFormat(d.address);
      d.name = getPascalCaseFormat(d.name);
      return d;
    });
    this._drugstoresSubject.next(formattedDrugstores);
  }

  public setSelectedDrugstore(drugstore: NearestDrugstore) {
    if (drugstore?.id !== this.selectedDrugstore?.id) {
      if (drugstore?.id > 0) {
        drugstore.address = getPascalCaseFormat(drugstore.address);
        drugstore.name = getPascalCaseFormat(drugstore.name);
      }
      if (OrderTakerValidator.isOrderTakerEnv()) {
        this._selectDataStore.setNearbyStore({
          address: drugstore.address,
          distance: drugstore.distance,
          latitude: drugstore.latitude,
          longitude: drugstore.longitude,
          localCode: drugstore.localCode,
          drugstoreWareHouseId: drugstore.drugstoreWareHouseId,
          id: drugstore.id,
          localOpeningHours: drugstore.localOpeningHours,
          name: drugstore.name
        } as Store);
        this._selectDataStore.saveNearbyStore(this._selectDataStore.nearbyStoreValue);
        this._selectDataStore.saveAll;
      }
      this._selectedDrugstoreSubject.next(drugstore);
    }
  }

  public setSelectedTemporalDrugstore(drugstore: NearestDrugstore) {
    this._selectedTemporalDrugstoreSubject.next(drugstore);
  }
  public setSelectedDrugstoreById(id: number | string) {
    const matchedDrugstore = this.drugstores.find((store) => store.id === id);
    this._selectedDrugstoreSubject.next(matchedDrugstore ? matchedDrugstore : ({} as NearestDrugstore));
  }

  public setSelectedDrugstoreFromStorage(drugstore: NearestDrugstore) {
    if (drugstore?.id !== this.selectedDrugstore?.id) {
      this._selectedDrugstoreSubject.next(drugstore);
    }
  }

  public setSelectedTemporalDrugstoreById(id: number) {
    const matchedDrugstore = this.drugstores.find((store) => store.id === id);
    this._selectedTemporalDrugstoreSubject.next(matchedDrugstore);
  }

  public resetDrugstores() {
    this._drugstoresSubject.next([]);
  }
  public resetSelectedDrugstore() {
    this._selectedDrugstoreSubject.next({} as NearestDrugstore);
  }

  public getFormattedNearestDrugstore(drugstore: NearestDrugstore) {
    return drugstore
      ? ({
          id: drugstore.id,
          title: drugstore.name,
          address: drugstore.address,
          schedule: drugstore.localOpeningHours,
          distance: `A ${drugstore.distance ? drugstore.distance.toFixed(1) : 0.0} k.m.`
        } as IStoreToChoose)
      : ({} as IStoreToChoose);
  }

  /**
   * funcion para obtener el tipo de delivery seleccionado desde el front.
   */
   get deliveryType() {
    return this.deliveriTyPess;
  }

  set deliveryType$(delivery:string) {
    this.deliveriTyPess = delivery;
  }

}
