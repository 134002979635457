import { Component, Inject, OnInit } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrand } from "@parameters/access/brand.parameter";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { AuthImplementService } from "src/app/shared/implements/auth/auth-implement.service";
import { PurchaseSummary } from "src/app/shared/models/shopping-cart/shopping-cart-precalculation.model";
import { BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { AUTH_PROVIDER, USER_SOURCE_LOGIN } from "src/app/shared/parameters/global";
import { USER_INFO_MESSAGES } from "src/app/shared/parameters/user-info-message";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { RegisterInfoTypeStoreService } from "src/app/shared/stores/user/register-info-type-store.service";
import { UserInfoStoreService } from "src/app/shared/stores/user/user-info-store.service";
import { MiniCartCloseService } from "../../../mini-cart/services/mini-cart-close.service";
import { ProductShoppingCart } from "../../../product-card/models/product-shopping-cart/product-shopping-cart.model";
import { RegisterModalsManagerHelperService } from "../../../register/register-services/register-modals-manager-helper.service";
import { RegisterModalsMaganerService } from "../../../register/register-services/register-modals-manager.service";
import { RegistrationValidationService } from "../../../register/register-services/registration-validation.service";
import { SnackbarAlertService } from "../../../snackbar/snackbar-alert.service";
import { CartLoginFormService } from "../../login-forms/cart-login-form.service";
import { MainLoginFormService } from "../../login-forms/main-login-form.service";
import { LoginModalsManagerHelperService } from "../../login-services/login-modals-manager-helper.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OrderTakerValidator } from "@validators/order-taker.validator";
@Component({
	selector: "fp-cart-login-modal",
	templateUrl: "./cart-login-modal.component.html",
	styleUrls: ["./cart-login-modal.component.sass"]
})
export class CartLoginModalComponent implements OnInit {
	public isMifaBrand = EBrand[environment.brand] == EBrand.mifarma;
	public src: string = "";
	public readonly SVG_ICON = "../../../../../../assets/icons/ic-smile.svg";
	public showEmailAndPassword = false;
	private message = "Contraseña inválida. Intenta nuevamente o recupera tu contraseña.";

	public skeletonLoader = true;
	public shoppingList: Array<ProductShoppingCart>;
	public miniCartLoader: boolean;
	public purchaseSummary: PurchaseSummary;
	public pursePrice: number = 0;

	public isLoggingIn = false;
	hasControlledProducts;
	isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
	constructor(
		public cartLoginForm: CartLoginFormService,
		public userInfoStore: UserInfoStoreService,
		public mainLoginForm: MainLoginFormService,
		public registerModalsMaganerService: RegisterModalsMaganerService,
		public authImplementService: AuthImplementService,
		private snackBarAlert: SnackbarAlertService,
		private _appStoreService: AppStoreService,
		private registrationValidation: RegistrationValidationService,
		private _miniCartClose: MiniCartCloseService,
		private loginModalsManagerHelper: LoginModalsManagerHelperService,
		private registerModalsManagerHelper: RegisterModalsManagerHelperService,
		private registerInfoTypeStore: RegisterInfoTypeStoreService,
		@Inject(MAT_DIALOG_DATA) public data: number
	) {
		this.src = this.isMifaBrand ? this.SVG_ICON : "";
	}

	ngOnInit() {
		this.isLoggingIn = false;
		this.pursePrice = this.data;
		this._appStoreService.sourceLogin = USER_SOURCE_LOGIN.CART_LOGIN;
		this._appStoreService.sourceNavigationLogin = BUSINESS_PATHS.CHECKOUT;
		this.hasControlledProducts = JSON.parse(localStorage.getItem("hasControlledProducts"));
	}

	simpleLogin() {
		this.isLoggingIn = true;
		const email = this.cartLoginForm.emailControl.value;
		const password = this.cartLoginForm.passwordControl.value;
		this.authImplementService
			.gmailLogin(email, password)
			.then(() => {
				this.registerInfoTypeStore.setEmailAndPasswordAuthenticationOrigin();
				this.validateUserRegister();
				TaggingService.loginOK("simple login");
			})
			.catch((error) => {
				this.isLoggingIn = false;
				this.snackBarAlert.snackbarAlertError(this.message);
			});
	}

	facebookLogin() {
		TaggingService.loginModal("facebook");
		this.authImplementService.facebookLogin().then((response) => {
			if (response) {
				// response apply only for desktop
				this.registerInfoTypeStore.setProviderAuthenticationOrigin();
				this.settingStorageAuthProvider(response);
				this.validateUserRegister();
				TaggingService.loginOK("facebook");
			}
		});
	}

	googleLogin() {
		TaggingService.loginModal("google");
		this.authImplementService.googleLogin().then((response) => {
			if (response) {
				// response apply only for desktop
				this.registerInfoTypeStore.setProviderAuthenticationOrigin();
				this.settingStorageAuthProvider(response);
				this.validateUserRegister();
				TaggingService.loginOK("google");
			}
		});
	}

	appleLogin() {
		TaggingService.loginModal("apple");
		this.authImplementService.appleLogin().then((response) => {
			// response apply only for desktop
			if (response) {
				this.registerInfoTypeStore.setProviderAuthenticationOrigin();
				this.settingStorageAuthProvider(response);
				this.validateUserRegister();
				TaggingService.loginOK("apple");
			}
		});
	}

	settingStorageAuthProvider(response) {
		this._appStoreService.setStorageItem(AUTH_PROVIDER, response.credential.providerId);
	}

	validateUserRegister() {
		this.loginModalsManagerHelper.closeCartLoginModal();
		this._miniCartClose.closeMiniCart();
		const subscribe = this.registrationValidation.validateUserRegisterOnDB().subscribe((path) => {
			!path ? (path = window.location.pathname) : path;
			if (typeof path === "string" && path.length) {
				this.isLoggingIn = false;
				this._appStoreService.removeSourceLogin();
				this.registrationValidation.handleNavigationCartLogin(path);
			}
			subscribe.unsubscribe();
		});
	}

	// validateUserRegister(addParams: Array<object> = []) {
	//   this.loginModalsManagerHelper.closeCartLoginModal();
	//   this._miniCartClose.closeMiniCart();
	//   const subscribe = this.registrationValidation
	//     .validateUserRegisterOnDB(addParams)
	//     .subscribe((path) => {
	//       !path ? path = window.location.pathname : path;
	//       if (typeof path === 'string' && path.length) {
	//         this.isLoggingIn = false;
	//         this._appStoreService.removeSourceLogin();
	//         this.registrationValidation.handleNavigationCartLogin(path);
	//       }
	//       subscribe.unsubscribe();
	//     });
	// }

	closeModal() {
		this.loginModalsManagerHelper.closeCartLoginModal();
	}

	openEmailWithPassword() {
		this.showEmailAndPassword = true;
		TaggingService.loginModal("ingresa con correo electrónico");
	}

	setShoppingMode() {
		this.userInfoStore.setShoppingMode(USER_INFO_MESSAGES.ANONYMOUS);
	}

	anonymousWarning() {
		TaggingService.loginModal("continúa sin iniciar sesión");
		this.loginModalsManagerHelper.openAnonymousWarningModal();
		this.loginModalsManagerHelper.closeCartLoginModal();
		TaggingService.loginModal("continúa sin iniciar sesión");
	}

	openRegister() {
		TaggingService.loginModal("regístrate");
		this.registerModalsManagerHelper.openRegisterModal();
		this.loginModalsManagerHelper.closeCartLoginModal();
		TaggingService.loginModal("regístrate");
	}

	forgotPassword() {
		TaggingService.loginModal("¿olvidaste tu contraseña?");
		this.loginModalsManagerHelper.closeCartLoginModal();
		this.loginModalsManagerHelper.openForgotPasswordModal();
	}

	get textModalLogin() {
		return environment.brand == EBrand.inkafarma
			? "Inicia sesión o regístrate para una mejor experiencia de compra."
			: "¡Recuerda! para acumular puntos - dinero, debes iniciar sesión o registrarte";
	}
}
