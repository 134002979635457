import { AUTH_ORIGIN, COMPANY_ID, NAVIGATION_URL, USER_REGISTRATION_ERROR_CODE, USER_SOURCE_LOGIN, } from '../../../../../shared/parameters/global';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthImplementService } from 'src/app/shared/implements/auth/auth-implement.service';
import { MainLoginFormService } from '../../login-forms/main-login-form.service';
import { UserInfoStoreService } from 'src/app/shared/stores/user/user-info-store.service';
import { ForgotPasswordModalService } from '../../login-services/forgot-password-modal-service.service';
import { SnackbarAlertService } from '../../../snackbar/snackbar-alert.service';
import { Router } from '@angular/router';
import { AppStoreService } from 'src/app/shared/stores/app/app-store.service';
import { AUTH_PROVIDER } from 'src/app/shared/parameters/global';
import { RegisterOriginStoreService } from '../../../register/register-stores/register-origin-store.service';
import { RegistrationValidationService } from '../../../register/register-services/registration-validation.service';
import { RegisterModalsMaganerService } from '../../../register/register-services/register-modals-manager.service';
import { RegisterModalsManagerHelperService } from '../../../register/register-services/register-modals-manager-helper.service';
import { LoginModalsManagerHelperService } from '../../login-services/login-modals-manager-helper.service';
import { TaggingService } from 'src/app/atm-services/tagging.service';
import { MainRegisterAfiliacionModalComponent } from 'src/app/core/modules/register/modal-components/main-register-afiliacion-modal/main-register-afiliacion-modal.component';
import { RegisterInfoTypeStoreService } from 'src/app/shared/stores/user/register-info-type-store.service';
import { EBrand } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';
import { EmailVerificationRequest } from '@models/register/user-register-request.model';
import { RegisterResponse } from '@models/register/register.model';
import { MainRegisterStoreService } from '../../../register/register-stores/main-register-store.service';
import { RegisterImplementService } from '../../../register/register-services/register-implement.service';

@Component({
  selector: 'fp-main-login-afiliacion-modal',
  templateUrl: './main-login-afiliacion-modal.component.html',
  styleUrls: ['./main-login-afiliacion-modal.component.sass'],
})
export class MainLoginAfiliacionModalComponent implements OnInit, OnDestroy {
  public registrationErrorCode = USER_REGISTRATION_ERROR_CODE;
  public isMifaBrand : boolean = EBrand[environment.brand] == EBrand.mifarma;
  public showEmailAndPassword = false;
  private message =
    'El correo o contraseña que ingresaste es inválido. Intenta nuevamente o recupera tu contraseña.';

  public isLoggingIn = false;

  public iconInformation = 'assets/icons/icon-information.svg';
  public userName: string;
  public userPoints: string;
  registerForm: any;

  constructor(
    public _matDialogRef: MatDialogRef<MainLoginAfiliacionModalComponent>,
    private dialog: MatDialog,
    public authImplementService: AuthImplementService,
    public mainLoginForm: MainLoginFormService,
    public userInfoStore: UserInfoStoreService,
    public forgotPasswordModalService: ForgotPasswordModalService,
    private snackBarAlert: SnackbarAlertService,
    private _router: Router,
    private _ngZone: NgZone,
    private _appStoreService: AppStoreService,
    private registerOriginStore: RegisterOriginStoreService,
    private registrationValidation: RegistrationValidationService,
    public registerModalsMaganer: RegisterModalsMaganerService,
    private registerModalsManagerHelper: RegisterModalsManagerHelperService,
    private loginModalsManagerHelper: LoginModalsManagerHelperService,
    private registerInfoTypeStore: RegisterInfoTypeStoreService,
    private mainRegisterStore: MainRegisterStoreService,
    private registerImplement: RegisterImplementService,
  ) {
  }

  ngOnInit() {
    this.isLoggingIn = false;

    this._appStoreService.sourceLogin = USER_SOURCE_LOGIN.CART_LOGIN;
    this._appStoreService.sourceNavigationLogin = this._appStoreService.getStorageItem(NAVIGATION_URL);
    this.userName = this.userInfoStore.getUser().name;
    this.userPoints = this.userInfoStore.getUser().points;
  }

  ngOnDestroy() {
  }

  simpleLogin() {
    this.isLoggingIn = true;
    const email = this.mainLoginForm.emailControl.value;
    const password = this.mainLoginForm.passwordControl.value;
    this.authImplementService
      .gmailLogin(email, password)
      .then(() => {
        this.registerInfoTypeStore.setEmailAndPasswordAuthenticationOrigin();
        this.validateUserRegister();
        TaggingService.loginOK('simple login');
      })
      .catch(() => {
        this.isLoggingIn = false;
        this.snackBarAlert.snackbarAlertError(this.message);
      });
  }

  facebookLogin() {
    TaggingService.loginModal('facebook');
    this.authImplementService.facebookLogin().then((response) => {
      if (response) {
        this.registerInfoTypeStore.setProviderAuthenticationOrigin();
        this.settingStorageAuthProvider(response);
        this.validateUserRegister();
        TaggingService.loginOK('facebook');
      }
    });
  }

  googleLogin() {
    TaggingService.loginModal('google');
    this.authImplementService.googleLogin().then((response) => {
      if (response) {
        this.registerInfoTypeStore.setProviderAuthenticationOrigin();
        this.settingStorageAuthProvider(response);
        this.validateUserRegister();
        TaggingService.loginOK('google');
      }
    });
  }

  appleLogin() {
    TaggingService.loginModal('apple');
    this.authImplementService.appleLogin().then((response) => {
      if (response) {
        this.registerInfoTypeStore.setProviderAuthenticationOrigin();
        this.settingStorageAuthProvider(response);
        this.validateUserRegister();
        TaggingService.loginOK('apple');
      }
    });
  }

  settingStorageAuthProvider(response) {
    this._appStoreService.setStorageItem(
      AUTH_PROVIDER,
      response.credential.providerId
    );
  }

  validateUserRegister() {
    this.loginModalsManagerHelper.closeMainLoginAfiliacionModal();
    const subscribe = this.registrationValidation
      .validateUserRegisterOnDB()
      .subscribe((path) => {
        !path ? path = window.location.pathname : path;
        if (typeof path === 'string' && path.length) {
          this.isLoggingIn = false;
          this._appStoreService.removeSourceLogin();
          this.registrationValidation.handleNavigationMainLoin(path);
        }
        subscribe.unsubscribe();
      });
  }

  openEmailWithPassword() {
    TaggingService.registerModal('crea una cuenta con tu correo');
    this.mainRegisterStore.email = this.registerForm.emailControl.value;
    const validateEmailRequest = new EmailVerificationRequest();
    validateEmailRequest.email = this.registerForm.emailControl.value;
    validateEmailRequest.companyId = COMPANY_ID;
    validateEmailRequest.origin = AUTH_ORIGIN.EMAIL_PASSWORD;
    validateEmailRequest.source = 'WEB';
    this.registerInfoTypeStore.setEmailAndPasswordAuthenticationOrigin();
    this.registerImplement.saveOrUpdateUser(validateEmailRequest)
      .subscribe((response: RegisterResponse) => {
        if (response.code === this.registrationErrorCode.ERROR_EMAIL_USED) {
          this.loginModalsManagerHelper.openMainLoginModal();
          this.openAlert();
          this.registerModalsManagerHelper.closeRegisterModal();
        } else {
          TaggingService.registerOK('simple register');
          this.showEmailAndPassword = true;
          this.registerModalsManagerHelper.openNewPasswordModal();
          this.registerModalsManagerHelper.closeRegisterModal();
        }
      });
  }

  closeModal() {
    /*this.loginModalsManagerHelper.closeMainLoginModal();
    TaggingService.loginModalCerrar();*/
    this._matDialogRef.close();
  }

  forgotPassword() {
    TaggingService.loginModal('¿olvidaste tu contraseña?');
    this.loginModalsManagerHelper.closeMainLoginModal();
    this.loginModalsManagerHelper.openForgotPasswordModal();
  }

  registerModal() {
    /*TaggingService.loginModal("regístrate");
    this.registerModalsManagerHelper.openRegisterModal();
    this.loginModalsManagerHelper.closeMainLoginModal();*/
    this.closeModal();
    this.dialog.open(MainRegisterAfiliacionModalComponent, {
      width: '344px',
      panelClass: 'custom-full-all-modal-overlay',
      disableClose: true,
    });
  }

  openAlert() {
    this.snackBarAlert
      .snackbarAlertInfo(`El correo que ingresaste ya se encuentra registrado.
    Inicia sesión o da click en "Olvidaste tu contraseña" para recuperar tu cuenta`);
  }
}
