import { Injectable } from "@angular/core";
import { EBrand } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { Observable } from "rxjs";
import { IBlogDetail } from "src/app/business/world-landing/models/world-blog-detail.model";
import { IBlogLanding } from "src/app/business/world-landing/models/world-blog-landing";
import { IWorldTag } from "src/app/business/world-landing/models/world-tag";
import { IContentfulWorld } from "src/app/core/contentful/models/contentful-world.model";
import { IContentfulProductList } from "../../../core/contentful/models/contentful-product-list.model";
import { IProductDefault } from "../../models/product-default/product-default.interface";
import { CONTENTFUL_PATH, ENDPOINTS } from "../../parameters/endpoints";
import { CONTENTFUL_QUERIES } from "../../parameters/querys";
import { GenericContentfulService, GenericService } from "../generic";

@Injectable({
  providedIn: "root",
})
export class WorldClientService {
  constructor(
    private _GenericContentfulService: GenericContentfulService,
    private _GenericService: GenericService,
    private _appStoreService: AppStoreService
  ) {}

  getUrlProductsByIds(): string {
    return this._appStoreService.brandAtention === EBrand.mifarma
      ? ENDPOINTS.GET_MF_PRODUCTS_BY_IDS
      : ENDPOINTS.GET_PRODUCTS_BY_IDS;
  }

  public getWorldDashboardSlug(slug: string) {
    return this._GenericContentfulService.getEntriesPromise<IContentfulWorld>(
      CONTENTFUL_PATH.contentTypeIds.dashboardWorld,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

  public getProductsByIds(
    ids: Array<string>
  ): Observable<Array<IProductDefault>> {
    const idsArray = ids.toString();
    return this._GenericService.genericGet(
      this.getUrlProductsByIds() + idsArray
    );
  }

  public getProductListBySlug(slug: string) {
    return this._GenericContentfulService.getEntriesPromise<IContentfulProductList>(
      CONTENTFUL_PATH.contentTypeIds.productList,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

  public getWorldBlogDetailBySlug(slug: string) {
    return this._GenericContentfulService.getEntriesPromise<IBlogDetail>(
      CONTENTFUL_PATH.contentTypeIds.worldBlogDetail,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

  public getWorldBlogLandingBySlug(slug: string) {
    return this._GenericContentfulService.getEntriesPromise<IBlogLanding>(
      CONTENTFUL_PATH.contentTypeIds.worldBlogLanding,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

  public getBlogListByTag(tag: string) {
    return this._GenericContentfulService.getEntriesPromise<IBlogDetail>(
      CONTENTFUL_PATH.contentTypeIds.worldBlogDetail,
      CONTENTFUL_QUERIES.prototype.getEntryArrayByTag(tag)
    );
  }

  public getBlogListByAllTag(tag: Array<string>, limit: number) {
    return this._GenericContentfulService.getEntriesPromise<IBlogDetail>(
      CONTENTFUL_PATH.contentTypeIds.worldBlogDetail,
      CONTENTFUL_QUERIES.prototype.getEntryArrayByAllTag(tag.join(","), limit)
    );
  }

  public getBlogTagBySlug(slug: string) {
    return this._GenericContentfulService.getEntriesPromise<IWorldTag>(
      CONTENTFUL_PATH.contentTypeIds.tag,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }
}
