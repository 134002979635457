<div class="content-error-modal px-4 py-4">
    <div class="mx-2 my-3 icon-alert text-center">
        <fp-icon iconSize="48" iconHeight="48" iconName="face_sad"></fp-icon>
    </div>
    <div class="warning-message title mx-2 my-3 text-center">
      <strong>{{ messageTitleModal}}</strong>
    </div>
    <div class="warning-message subtitle mx-2 my-3 text-center">
      {{ messageSubtitleModal}}
    </div>
    <div class="warning-buttons mx-2 mt-4 mb-2">
      <fp-button-primary buttonClass="w-100" (click)="closeModal()">Entendido</fp-button-primary>
    </div>
  </div>
  
  