<div class="purchase-summary-total">
	<div class="d-flex justify-content-between mx-0 pt-1 pb-2" *ngIf="total && total > 0">
		<div class="d-flex align-items-center justify-content-end label-black-lg--1 label-black-xl label-black-xxl--1">Total</div>
		<div
			class="d-flex align-items-center justify-content-end label-black label-black-lg--1 label-black-xl label-black-xxl--1 currency"
		>
			{{ total | currency : "S/ " }}
		</div>
	</div>
	<div class="d-flex justify-content-between mx-0 pt-1 pb-2" *ngIf="totalMonedero && totalMonedero > 0">
		<div class="d-flex align-items-center justify-content-end label-black-lg--1 label-black-xl label-black-xxl--1">
			{{ _userInfoStore.isLogged ? "Precio Monedero" : "Exclusivo Monedero" }}
		</div>
		<div class="d-flex align-items-center justify-content-end label-black label-black-lg--1 label-black-xl label-black-xxl--1">
			<fp-icon class="d-inline-flex mr-2" iconSize="20" iconName="card-monedero"></fp-icon>
			<span class="currency">
				{{ totalMonedero | currency : "S/ " }}
			</span>
		</div>
	</div>
	<div class="d-flex justify-content-between mx-0 pt-1 pb-2" *ngIf="totalOh && totalOh > 0">
		<div class="d-flex align-items-center justify-content-end label-black-lg--1 label-black-xl label-black-xxl--1">
			{{ _globalProductCardService.paymentMethodCard.textTotal }}
		</div>
		<div class="d-flex align-items-center justify-content-end label-black label-black-lg--1 label-black-xl label-black-xxl--1">
			<div class="container-icons-card mr-2">
				<ng-container *ngFor="let image of _globalProductCardService.paymentMethodCard.imageArray; trackBy: trackByImage">
					<img class="icon-card" [src]="image" alt="icono tarjeta inkafarma" />
				</ng-container>
			</div>
			<span class="currency">
				{{ totalOh | currency : "S/ " }}
			</span>
		</div>
	</div>
</div>
