<div class="d-flex align-items-center breadcrumbs-container" *ngIf="breadcrumbs">
  <!-- <a *ngIf="showBase" routerLink="/" class="breadcrumb label-black--1">Inicio</a> -->
  <!-- <ng-container *ngFor="let breadcrumb of breadcrumbs; last as isLast; first as isFirst"> -->
  <ng-container *ngIf="breadcrumbs[1]">
    <span class="mr-1 ml-1 icon icon-16-chevron-left-support-two"></span>
    <a class="breadcrumb label-black label-black-lg-1" [routerLink]="breadcrumbs[1].keyword">Volver al blog</a>
    <!-- <a *ngIf="!isLast" class="breadcrumb label-black--1" [routerLink]="breadcrumb.keyword">{{breadcrumb.name}}</a> -->
    <!-- <span *ngIf="isLast" class="breadcrumb last label-black--1">
      {{breadcrumb.name}}
    </span> -->
  </ng-container>
</div>
