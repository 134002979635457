import {Injectable} from '@angular/core';
import {StaticLandingImplementService} from '../../../services/static-landing-implement.service';

@Injectable()
export class TreatmentForAdditionalPurposesImplementService {
  constructor(
    private _staticLandingImplement: StaticLandingImplementService
  ) {
  }

  getTreatmentForAdditonalPurposes() {
    return this._staticLandingImplement.getTreatmentForAdditionalPurposes();
  }
}
