<ng-container [formGroup]="textareaForm">
  <textarea
    class="textarea-basic label"
    [formControlName]="textareaFormControlName"
    [class.textarea-resize]="textareaResize"
    [class.textarea-focus]="textareaFocus"
    [name]="textareaName"
    [id]="textareaId"
    [style.width]="textareaWidth"
    [style.height]="textareaHeight"
    [ngClass]="textareaClass"
    [placeholder]="textareaPlaceholder"
    [maxlength]="'' + textareaMaxLength + ''"
  ></textarea>
  <span *ngIf="counter" class="counter">
    {{ lengthText }}/{{ textareaMaxLength }}
  </span>
</ng-container>
