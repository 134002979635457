<div class="checkout-welcome-register-modal">
  <fp-section-container-title-login [title]="'Te damos la bienvenida'" [iconName]="'close'" (onclick)="closeModal()">
  </fp-section-container-title-login>
  <fp-sub-section-container>
    <div class="content">
      <div class="modal-body">
        <div class="modal-description">
          <span>Completa estos datos finales y empieza a disfrutar del app del ahorro.</span>
        </div>
        <div class="modal-required-field-text">
          <span>(*) Campos obligatorios</span>
        </div>
        <div [formGroup]="registerNewUserForm.form">
          <div class="row">
            <div class="col-md-6 col-12">
              <fp-input-control [inputTitle]="'Tipo de documento'">
                <input autocomplete="off" type="text" id="documentType" class="input btn-disabled"
                  formControlName="documentType" #inputControl readonly>
              </fp-input-control>
            </div>
            <div class="col-md-6 col-12">
              <fp-input-control [inputTitle]="'Número de documento'">
                <input autocomplete="off" type="text" id="documentNumber" class="input btn-disabled"
                  formControlName="documentNumber" #inputControl readonly>
              </fp-input-control>
            </div>
            <div class="col-md-6 col-12">
              <fp-input-control [inputTitle]="'Nombres *'" [errorMessage]="registerNewUserForm.nameControl.nameError">
                <input autocomplete="none"  type="text" id="name" class="input" maxlength="50" formControlName="name"
                  #inputControl fpCharacterOnly placeholder="Nombres">
              </fp-input-control>
            </div>
            <div class="col-md-6 col-12">
              <fp-input-control [inputTitle]="'Apellido paterno *'" [errorMessage]="registerNewUserForm.paternalLastNameControl.lastNameError">
                <input autocomplete="none" type="text" id="paternalLastName" class="input" maxlength="50"
                  formControlName="paternalLastName" #inputControl fpCharacterOnly placeholder="Apellido paterno">
              </fp-input-control>
            </div>
            <div class="col-md-6 col-12">
              <fp-input-control [inputTitle]="'Apellido materno *'" [errorMessage]="registerNewUserForm.maternalLastNameControl.lastNameError">
                <input autocomplete="none" type="text" id="maternalLastName" class="input" maxlength="50"
                  formControlName="maternalLastName" #inputControl fpCharacterOnly placeholder="Apellido materno">
              </fp-input-control>
            </div>
            <div class="col-md-6 col-12">
              <fp-input-control [inputTitle]="'Correo electrónico'">
                <input autocomplete="none" type="text" id="emailAddress" class="input btn-disabled"
                 [value]="anonymousForm.emailAddress" #inputControl readonly>
              </fp-input-control>
            </div>
            <div class="col-md-6 col-12">
              <fp-input-control [inputTitle]="'Celular *'" [errorMessage]="registerNewUserForm.phoneNumberControl.phoneNumberError">
                <input *ngIf="!anonymousForm.phoneNumber" autocomplete="none" type="text" id="phoneNumber" class="input" maxlength="9" formControlName="phoneNumber" #inputControl fpDigitOnly>
                <input *ngIf="anonymousForm.phoneNumber" autocomplete="none" type="text" id="phoneNumber" class="input btn-disabled" maxlength="9" formControlName="phoneNumber" #inputControl readonly>
              </fp-input-control>
            </div>
            <div class="col-12 mb-3">
              <div class="terms-and-dates-content">
                <fp-checkbox-control class="mr-3" formControlName="termsAndPolitics">
                </fp-checkbox-control>
                <div class="text--1 terms-and-dates font-VAGRoundedNexxt-size-12">
                  He leído y acepto los
                  <span (click)="openTermsAndConditionsDialogEvent()" >Términos y Condiciones</span>
                  y las <span (click)="openPoliticsOfPrivacyDialogEvent()"> Políticas de Privacidad</span>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="terms-and-dates-content">
                <fp-checkbox-control class="mr-3" formControlName="datesAndRegister">
                </fp-checkbox-control>
                <div class="text--1 terms-and-dates font-VAGRoundedNexxt-size-12">
                  He leído y acepto el
                  <span  (click)="openDateAndRegisterDialogEvent()">
                    uso de mis datos y registro en el Monedero del ahorro para acumulación de puntos
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="button-container">
                <div class="button-item">
                  <fp-button-ghost buttonDisplay="block" [buttonSize]="'regular'" (click)="closeModal()">
                    Cancelar
                  </fp-button-ghost>
                </div>
                <div class="button-item">
                  <fp-button-primary buttonDisplay="block" (click)="validateNewRegisterData()"
                    [buttonDisabled]="!registerNewUserForm.form.valid || disableButton">
                    Crear mi cuenta
                  </fp-button-primary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fp-sub-section-container>
  <div
    class="alert-success"
    *ngIf="disableButton"
    [ngClass]="{ class: true }"
    [ngStyle]="{ background: isInkaBrand ? '#FFFFFF' : '#50B83C' }"
  >
    <div class="row">
      <div class="col-12 text-center">
        <img
          *ngIf="!isInkaBrand"
          src="/assets/images/icons/check-white.svg"
          alt="check"
        />
        <img *ngIf="isInkaBrand" src="/assets/icons/check-colored.svg" alt="" />
      </div>
      <div class="col-12 mx-auto">
        <p class="text-center font-VAGRoundedNexxt mt-2">CREANDO CUENTA</p>
      </div>
    </div>
  </div>
</div>
