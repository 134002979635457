import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { ButtonsModule } from "../../generic/buttons/buttons.module";
import { ChipModule } from "../../generic/chip/chip.module";
import { IconsModule } from "../../generic/icons/icons.module";
import { ErrorStockModalComponent } from "./modal-component/error-stock-modal/error-stock-modal.component";
import { ErrorStockModalService } from "./services/error-stock-modal.service";

@NgModule({
  declarations: [ErrorStockModalComponent],
  imports: [
    CommonModule,
    ChipModule,
    ButtonsModule,
    MatDialogModule,
    MatDividerModule,
    IconsModule,
  ],
  providers: [ErrorStockModalService],
  entryComponents: [ErrorStockModalComponent],
})
export class ErrorStockModule {}
