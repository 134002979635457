import { Asset, Entry } from 'contentful';
import { TagSoldOutComponent } from 'src/app/core/generic/tags/tag-sold-out/tag-sold-out.component';
import { ContentfulAsset, ContentfulEntry } from '../../../../shared/models/contentful/contentful.model';

export interface IFooterItem {
  title: string;
  link: string;
  label: string;
  image: Asset;
  item: Array<Entry<IFooterItem>>;
  headingLevel: string;
}

export class FooterItem {
  title: string;
  deepLink: string;
  label: string;
  image: string;
  itemList: Array<FooterItem>;
  headingLevel: string;

  constructor(iFooterItem: IFooterItem) {
    this.title = iFooterItem.title;
    this.headingLevel = iFooterItem.headingLevel || 'h3';
    if (iFooterItem.link) {
      this.deepLink = iFooterItem.link;
    }
    if (iFooterItem.label) {
      this.label = iFooterItem.label;
    }
    if (iFooterItem.image) {
      this.image = new ContentfulAsset(iFooterItem.image).asset;
    }
    if (iFooterItem.item) {
      this.itemList = iFooterItem.item.map(item => new FooterItem(new ContentfulEntry(item).content));
    }
  }
}

export interface IMainFooter {
  title: string;
  items: Array<Entry<IFooterItem>>;
  copyright: string;
  cookiesDialog: any;
  headingLevel: string;
}

export class MainFooter {
  title: string;
  copyright: string;
  itemList: Array<FooterItem>;
  cookies: FooterItem;
  headingLevel: string;

  constructor(iMainFooter: IMainFooter) {
    this.title = iMainFooter.title;
    this.headingLevel = iMainFooter.headingLevel || 'h3';
    this.copyright = iMainFooter.copyright;
    this.itemList = iMainFooter.items.map(item => new FooterItem(new ContentfulEntry(item).content));
    this.cookies = iMainFooter.cookiesDialog?.fields;
  }
}
