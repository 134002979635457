<div
  class="text-white bg-dark-new-regular"
  [ngClass]="{ 'bg-dark-new-small': tagSize === 'small' }"
>
  <div clss="text-sold-out">
    AGOTADO

    <!-- {{
      tagSize === "small" || isProductPack
        ? "AGOTADO"
        : "AGOTADO PARA VENTA ONLINE"
    }} -->
  </div>
</div>
