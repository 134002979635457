import { ITokenDetail } from "../../interfaces/token.interface";

export class TokenDetail {
  jti: string;
  clientId: string;
  expirationDate: number;

  constructor(iTokenDetail: ITokenDetail) {
    this.jti = iTokenDetail.jti;
    this.clientId = iTokenDetail.client_id;
    this.expirationDate = iTokenDetail.exp;
  }
}
