import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrandCode } from "@parameters/access/brand.parameter";
import { COMPANY_CODE } from "src/app/shared/parameters/global";
import { HTTP_HEADERS } from "../../parameters/global";
import { AppStoreService } from "../../stores/app/app-store.service";

@Injectable({
  providedIn: "root",
})
export class GenericHeaderService {
  constructor(private _appStoreService: AppStoreService) {}

  public getCommonAuthUserLegacy() {
    let httpHeaders: HttpHeaders;
    httpHeaders = new HttpHeaders({
      AndroidVersion: HTTP_HEADERS.androidVersion,
    });
    return httpHeaders;
  }

  public getCommonAuth() {
    const { drugstoreStock } = this._appStoreService;

    let httpHeaders: HttpHeaders;
    if (drugstoreStock) {
      // RET3: Tomador envia drugstore-stock
      httpHeaders = new HttpHeaders({
        AndroidVersion: HTTP_HEADERS.androidVersion,
        // "drugstore-stock": drugstoreStock,
      });
    } else {
      httpHeaders = new HttpHeaders({
        AndroidVersion: HTTP_HEADERS.androidVersion,
      });
    }
    return httpHeaders;
  }

  public getCommonNotificationAuth() {
    let httpHeaders: HttpHeaders;
    httpHeaders = new HttpHeaders({
      companyCode:  EBrandCode[environment.brand]
    });
    return httpHeaders;
  }

  public getCommonAuthV2() {
    const { drugstoreStock } = this._appStoreService;
    let httpHeaders: HttpHeaders;

    httpHeaders = new HttpHeaders({
      AndroidVersion: HTTP_HEADERS.androidVersion,
      // "drugstore-stock": drugstoreStock ? drugstoreStock : "",
    });

    return httpHeaders;
  }

  public getCommonAuthDetailProduct() {
    const { drugstoreStock, drugstoreCode } = this._appStoreService;
    let httpHeaders: HttpHeaders;
    if (drugstoreStock) {
      httpHeaders = new HttpHeaders({
        AndroidVersion: HTTP_HEADERS.androidVersion,
        // "drugstore-stock": drugstoreStock,
      });
      if (drugstoreCode.length > 0) {
        httpHeaders = httpHeaders.append("drugstore-code", drugstoreCode);
      }
    } else {
      httpHeaders = new HttpHeaders({
        AndroidVersion: HTTP_HEADERS.androidVersion,
      });
    }
    return httpHeaders;
  }

  public getCommonAuthCart() {
    const { drugstoreStock } = this._appStoreService;
    let httpHeaders: HttpHeaders;
    if (drugstoreStock) {
      httpHeaders = new HttpHeaders({
        AndroidVersion: HTTP_HEADERS.androidVersion,
        // "drugstore-stock": drugstoreStock,
        source: HTTP_HEADERS.source,
      });
    } else {
      httpHeaders = new HttpHeaders({
        AndroidVersion: HTTP_HEADERS.androidVersion,
        source: HTTP_HEADERS.source,
      });
    }
    return httpHeaders;
  }

  public getCommonAuthWithCompanyCode() {
    let httpHeaders: HttpHeaders;

    httpHeaders = new HttpHeaders({
      AndroidVersion: HTTP_HEADERS.androidVersion,
      "company-code": COMPANY_CODE,
    });
    return httpHeaders;
  }

  public getSource() {
    let httpHeaders: HttpHeaders;
    httpHeaders = new HttpHeaders({
      source: HTTP_HEADERS.source,
    });
    return httpHeaders;
  }
}
