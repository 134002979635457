import { Component } from '@angular/core';

@Component({
  selector: 'fp-skeleton-for-pick-up-modal-v2',
  templateUrl: './skeleton-for-pick-up-modal-v2.component.html',
  styleUrls: ['./skeleton-for-pick-up-modal-v2.component.sass']
})
export class SkeletonForPickUpModalV2Component {

  constructor() {}
}
