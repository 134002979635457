<fp-static-landing-content-skeleton
  *ngIf="loader">
</fp-static-landing-content-skeleton>


<div class="content static-landing-content pb-4">
  <div class="content p-md-0"
    *ngIf="!loader">
  <div class="gray-line mb-2"></div>
  <p class="paragraph--1 pt-4 m-0"
    [innerHTML]="content | mdToHtml"></p>
  </div>
</div>

