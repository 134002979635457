import { Entry } from 'contentful';
import { IContentfulBannerInkaclub } from 'src/app/core/contentful/models/contentful-banner-inkaclub.model';

import { IContentfulBanner } from 'src/app/core/contentful/models/contentful-banner.model';
import { EContentfulDashboardSection } from 'src/app/core/contentful/models/contentful-dashboard.model';
import { CContentfulShortcutList, ContentfulShortcutList } from 'src/app/core/contentful/models/contentful-shortcut-list.model';

import { ContentfulAsset, ContentfulEntry } from 'src/app/shared/models/contentful/contentful.model';

export class StaticLandingImage {
  name: string;
  url: string;

  constructor(image) {
    this.name = image.fields ? image.fields.title || '' : '';
    this.url = image.fields ? image.fields.file.url || '' : '';
  }
}


export class StaticLandingItem {
  title: string;
  type: string;
  content: string;
  items: Array<string>;
  date: any;
  day: string;
  month: string;
  year: string;

  urlOnline: string;
  image: string;
  urlDownload: string;

  constructor(data) {
    if (data.fields) {
      const staticLandingItem = data.fields;
      this.title = staticLandingItem.title;
      this.type = staticLandingItem.type;
      if (staticLandingItem.content) {
        this.content = staticLandingItem.content;
      }
      if (staticLandingItem.items) {
        this.items = staticLandingItem.items.map(item => item.sys.id);
      }
      if (staticLandingItem.date) {
        this.date = new Date(staticLandingItem.date + ' ');
      }
      if (staticLandingItem.date) {
        this.day = this.date.getDate();
      }
      if (staticLandingItem.date) {
        this.year = this.date.getFullYear();
      }

      const meses = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
      if (staticLandingItem.date) {
        this.month = meses[this.date.getMonth()];
      }

      if(staticLandingItem.urlOnline){this.urlOnline = staticLandingItem.urlOnline;}
      if (staticLandingItem.image) {this.image = new StaticLandingImage(staticLandingItem.image).url;}
      if(staticLandingItem.urlDownload){this.urlDownload = staticLandingItem.urlDownload;}
    }
  }
}

export class StaticLanding {
  title: string;
  slug: string;
  image: string;
  content: string;
  typeLanding:string;
  items: Array<ContentfulEntry<StaticLandingItem | ContentfulShortcutList | any> | StaticLandingItem>;

    // SEO
    categoryKeyword: string;
    canonicalUrl: string;
    metaDescription: string;
    metaKeywords: string;
    metaTitle: string;
    structuredData: string;
    urlRedirect:string;

  description: string;
  searchKeyword: string;
  salesforcePersonalization: any;
  constructor(data) {
    if (data.length > 0 && data[0]) {
      const staticLanding = data[0].fields;

      this.title = staticLanding.title;
      this.slug = staticLanding.slug;
      if (staticLanding.image) {
        this.image = new StaticLandingImage(staticLanding.image).url;
      }
      if (staticLanding.items) {
        this.items = staticLanding.items.map(item => {
          const type = item?.sys?.contentType?.sys?.id;
          if (type === "shortcutList") {
            return new ContentfulEntry(item)
          }else
            return new StaticLandingItem(item)
        });
      }
      if (staticLanding.typeLanding) {
        this.typeLanding = staticLanding.typeLanding;
      }
      if (staticLanding.description) {
        this.content = staticLanding.description; //En contentful se encuentra como description
      }
      if (staticLanding.salesforcePersonalization) {
        this.salesforcePersonalization = staticLanding.salesforcePersonalization;
      }
      //SEO
      if (staticLanding.categoryKeyword) {
        this.categoryKeyword = staticLanding.categoryKeyword;
      }
      if (staticLanding.canonicalUrl) {
        this.canonicalUrl = staticLanding.canonicalUrl;
      }
      if (staticLanding.metaDescription) {
        this.metaDescription = staticLanding.metaDescription;
      }
      if (staticLanding.metaKeywords) {
        this.metaKeywords = staticLanding.metaKeywords;
      }
      if (staticLanding.metaTitle) {
        this.metaTitle = staticLanding.metaTitle;
      }
      if (staticLanding.structuredData) {
        this.structuredData = staticLanding.structuredData;
      }

      if (staticLanding.description) {
        this.description = staticLanding.description;
      }
      if (staticLanding.urlRedirect) {
        this.urlRedirect = staticLanding.urlRedirect;
      }
      if (staticLanding.description) {
        this.searchKeyword = staticLanding.searchKeyword;
      }

    }
  }
}


export class ContentfulDashboard {
  title: string;
  section: EContentfulDashboardSection;
  componentList: Array<ContentfulEntry<IContentfulBanner | IContentfulBannerInkaclub>>;
  pageTitle: string;
  metaDescription: string;
  metaKeywords: string;
  structuredData: string;
  metaTitle: string;
  link: string;

  constructor(data) {
    if ( data && data[0] ) {
      const iContentfulDashboard = data[0].fields ;
      this.title = iContentfulDashboard.title || '';
      this.section = iContentfulDashboard.section || EContentfulDashboardSection.default;
      this.componentList = iContentfulDashboard.componentes ?
        iContentfulDashboard.componentes.map(component => new ContentfulEntry(component)) : [];
      this.pageTitle = iContentfulDashboard.pageTitle;
      this.link = iContentfulDashboard.link;
      this.metaDescription = iContentfulDashboard.metaDescription;
      this.metaKeywords = iContentfulDashboard.metaKeywords;
      this.structuredData = iContentfulDashboard.structuredData;
      this.metaTitle = iContentfulDashboard.metaTitle;
    }
  }
}

export interface IContentfulDashboardCyberWOWRegister {
  background: any;
  inscription: any;
}

export class ContentfulBannerImage {
  imageResponsive: ContentfulAsset;
  imgeDesktop: ContentfulAsset;
  altText: string;
  title: string;

  constructor(entry: Entry<any>) {
    const contentfulEntry = entry ? new ContentfulEntry(entry) : null;
    this.imageResponsive = contentfulEntry ? new ContentfulAsset(contentfulEntry.content.imageResponsive) : null;
    this.imgeDesktop = contentfulEntry ? new ContentfulAsset(contentfulEntry.content.imgeDesktop) : null;
    this.altText = contentfulEntry ? contentfulEntry.content.imageAltText : null;
    this.title = contentfulEntry ? contentfulEntry.content.imageTitle : null;
  }
}

export class ContentfulCyberWOWDashboard extends ContentfulDashboard {
  background: ContentfulAsset;
  inscription: ContentfulBannerImage;
  idProveedor: number;

  constructor(iContentfulDashboard: any) {
    super(iContentfulDashboard);
    if ( iContentfulDashboard && iContentfulDashboard[0] ) {
      const data = iContentfulDashboard[0].fields;
      this.background = new ContentfulAsset(data.fondo);
      this.inscription = new ContentfulBannerImage(data.inscription);
      this.idProveedor = data.idProveedor;
    }
  }

}

export class StaticLandingItemCatalogue {
  title: string;
  type: string;
  date: any;
  day: string;
  month: string;
  year: string;
  image: string;
  urlOnline: string;
  urlDownload: string;
  content: string;

  constructor(data) {
    if (data.fields) {
      const staticLandingItemCatalogue = data.fields;
      this.title = staticLandingItemCatalogue.title;
      this.type = staticLandingItemCatalogue.type;
      if (staticLandingItemCatalogue.content) {
        this.content = staticLandingItemCatalogue.content;
      }
      this.date = new Date(staticLandingItemCatalogue.date + ' ');
      this.day = this.date.getDate();
      this.year = this.date.getFullYear();
      const months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
      this.month = months[this.date.getMonth()];

      this.image = new StaticLandingImage(staticLandingItemCatalogue.image).url;
      this.urlOnline = staticLandingItemCatalogue.urlOnline;
      if (staticLandingItemCatalogue.urlDownload) {
        this.urlDownload = staticLandingItemCatalogue.urlDownload;
      }
    }
  }
}

export class StaticLandingCatalogue {
  title: string;
  slug: string;
  image: string;
  content: string;
  items: Array<StaticLandingItemCatalogue>;

  constructor(data) {
    if (data[0]) {
      const staticLanding = data[0].fields;

      this.title = staticLanding.title;
      this.slug = staticLanding.slug;
      if (staticLanding.image) {
        this.image = new StaticLandingImage(staticLanding.image).url;
      }
      if (staticLanding.items) {
        this.items = staticLanding.items.map(item => new StaticLandingItemCatalogue(item));
      }
      if (staticLanding.description) {
        this.content = staticLanding.description;
      }
    }
  }
}

export class InformationItem {
  title: string;
  image: string;
  content: string;

  constructor(data) {
    if (data) {
      const staticLanding = data.fields;
      this.title = staticLanding.title;
      if (staticLanding.image) {
        this.image = new StaticLandingImage(staticLanding.image).url;
      }
      this.content = staticLanding.content;
    }
  }
}


export class Shortcut {
  title: string;
  image: string;
  description: string;
  link: string;
  color: string;

  constructor(data) {
    this.title = data.title;
    this.image = data.image.fields.file.url;
    this.description = data.description;
    this.link = data.link;
    this.color = data.color;
  }
}

export class ShortcutList {
  title: string;
  description: string;
  shortcutList: Array<Shortcut | any>;
  link: string;

  constructor(data) {
    if (data) {
      const staticLanding = data.fields;
      this.title = staticLanding.title;
      this.description = staticLanding.description;
      this.shortcutList = staticLanding.shortcutList;
      this.link = staticLanding.link;
    }
  }

}


export class StaticLandingPage {
  title: string;
  slug: string;
  imageBanner: string;
  imageLogo: string;
  image: string;
  content: string;
  components: Array<InformationItem>;
  items: Array<ShortcutList>;
  pageTitle: string;
  metaDescription: string;
  link: string;
  metaKeywords: string;
  structuredData: string;
  canonicalLink: string;

  constructor(data) {
    if (data) {
      const staticLanding = data[0].fields;

      this.title = staticLanding.title;
      this.slug = staticLanding.slug;
      if (staticLanding.imageBanner) {
        this.imageBanner = new StaticLandingImage(staticLanding.imageBanner).url;
      }
      if (staticLanding.imageLogo) {
        this.imageLogo = new StaticLandingImage(staticLanding.imageLogo).url;
      }
      if (staticLanding.image) {
        this.image = new StaticLandingImage(staticLanding.image).url;
      }
      if (staticLanding.content) {
        this.content = staticLanding.content;
      }
      if (staticLanding.components) {
        this.components = staticLanding.components.map(item => new InformationItem(item));
      }
      if (staticLanding.items) {
        this.items = staticLanding.items.map(item => new ShortcutList(item));
      }
      this.pageTitle = staticLanding.pageTitle;
      this.metaDescription = staticLanding.metaDescription;
      this.link = staticLanding?.link || '/';
      this.metaKeywords = staticLanding.metaKeywords;
      this.structuredData = staticLanding.structuredData;
      this.canonicalLink = staticLanding.link;
    }
  }
}


export const CContentfulStaticLanding = {
  shortcutList: CContentfulShortcutList.id,
};
