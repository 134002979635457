import { Injectable } from "@angular/core";
import firebase from "firebase/compat/app";
import { from, Observable, Subject } from "rxjs";

@Injectable()
export class AppManagerService {
  public subjectAuthenticatedApp = new Subject<boolean>();

  constructor() {}

  public initializeFirebaseApp(firebaseConfig: Object, name: string) {
    firebase.initializeApp(firebaseConfig, name);
  }

  public getFirebaseApp(name?: string): firebase.app.App {
    return firebase.app(name);
  }

  public signInFirebaseAppWithCustomToken(
    app: firebase.app.App,
    token: string
  ): Observable<any> {
    return from(app.auth().signInWithCustomToken(token));
  }

  public authenticatedFirebaseApp() {
    this.subjectAuthenticatedApp.next(true);
  }

  public authenticatedFirebaseApp$(): Observable<boolean> {
    return this.subjectAuthenticatedApp.asObservable();
  }
}
