<div class="content-error-modal px-4 py-4">
  <div class="my-3 text-center">
		<img height="56" src="../../../../assets/icons/face_sad.svg" alt="Producto no disponible" />
	</div>
  <div class="warning-message text-center mx-2 my-3">
    <b class="title mt-4 mb-3">{{title}}</b>
    <p>{{subtitle}}</p>
  </div>
  <div class="warning-buttons mx-2 mt-4 mb-3">
    <fp-button-primary buttonClass="w-100" (click)="closeModal()">Entendido</fp-button-primary>
  </div>
</div>
