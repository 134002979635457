<div class="product-error-modal content py-4">
  <div
    class=" icon icon-14-close icon-close"
    (click)="closeModal()"></div>
  <div class="my-3 icon-alert">
    <span class="mt-2 icon icon-24-warning"></span>
  </div>
  <div class="warning-message text my-3" >
    <span class="label-black">
      Por el momento, no es posible añadir productos de diferentes vendedores en un solo pedido.
    </span>
    Por favor, finaliza la compra de tu carrito o elimínalos para continuar con la compra de este producto.
  </div>
  <div class="row mt-4 mb-2">
    <div class="col-12 mb-2">
      <fp-button-primary
        id=""
        (click)="finishPurchase()"
        buttonDisplay="block">
        Finalizar compra actual
      </fp-button-primary>
    </div>
    <div class="col-12">
      <fp-button-secondary
        id=""
        (click)="replaceShoppingCartList()"
        buttonDisplay="block">
        Quiero este producto
      </fp-button-secondary>
    </div>
  </div>
</div>