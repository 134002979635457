import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "fp-error-drugstore",
  templateUrl: "./error-drugstore.component.html",
  styleUrls: ["./error-drugstore.component.sass"],
})
export class ErrorDrugstoreComponent implements OnInit {
  @Output()
  evenEmitter = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  onClick() {
    this.evenEmitter.emit();
  }
}
