import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { EBrand } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { RegisterModalsManagerHelperService } from "../../register-services/register-modals-manager-helper.service";
import { NewPasswordFormService } from "../../register-forms/new-password-form.service";
import { Subscription } from "rxjs";
import { CheckoutAnonymousUserFormStoreService } from "src/app/business/checkout/checkout-stores/checkout-anonymous-user-form-store.service";
import { AppStoreService } from "@stores/app/app-store.service";
import { RegisterOriginStoreService } from "../../register-stores/register-origin-store.service";
import { MainRegisterStoreService } from "../../register-stores/main-register-store.service";
import { NewRegisterStoreService } from "@stores/user/new-register-store.service";

@Component({
  selector: "fp-checkout-register-modal",
  templateUrl: "./checkout-register-modal.component.html",
  styleUrls: ["./checkout-register-modal.component.sass"],
})
export class CheckoutRegisterModalComponent implements OnInit, OnDestroy {
  public isInkaBrand = EBrand[environment.brand]==EBrand.inkafarma;

  public isValid8Digit = false;
  public isValidCapitalLetter = false;
  public isValidLowerCase = false;
  public isValidNumber = false;

  public isSame = false;
  public isChange = false;
  public checkoutEmailAddress = '';
  public documentType = '';
  public documentNumber = '';

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private anonymousUserFormStore: CheckoutAnonymousUserFormStoreService,
    private registerModalsManagerHelper: RegisterModalsManagerHelperService,
    public newPasswordForm: NewPasswordFormService,
    private _appStoreService: AppStoreService,
    private newRegisterStore: NewRegisterStoreService,
    private mainRegisterStore: MainRegisterStoreService,
    private registerOriginStore: RegisterOriginStoreService,
  ) {}

  ngOnInit() {
    const anonymousUserFormStoreSubs = this.anonymousUserFormStore.form$.subscribe(form => {
      this.documentType = form.documentType;
      this.documentNumber = form.document;

      if (form.emailAddress) {
        this.checkoutEmailAddress = form.emailAddress;
        this.newPasswordForm.emailAddressControl.setValue(
          form.emailAddress
        );
      } else {
        this.newPasswordForm.emailAddressControl.setValue('');
      }
    });

    const valid8DigitSubscription = this.newPasswordForm.getIsValid8Digit$()
      .subscribe(isValid => {
        this.isValid8Digit = isValid;
      });

    const validCapitalLetterSubscription = this.newPasswordForm
      .getIsValidCapitalLetter$()
      .subscribe((isValid) => {
        this.isValidCapitalLetter = isValid;
      });

    const validLowerCaseSubscription = this.newPasswordForm
      .getIsValidLowerCase$()
      .subscribe((isValid) => {
        this.isValidLowerCase = isValid;
      });

    const validNumberSubscription = this.newPasswordForm
      .getIsValidNumber$()
      .subscribe((isValid) => {
        this.isValidNumber = isValid;
      });

    const sameConfirmPasswordSubscription = this.newPasswordForm
      .getIsSameConfirmPassword$()
      .subscribe((isvalid) => {
        this.isSame = isvalid;
      });

    const sameNewPasswordSubscription = this.newPasswordForm
      .getIsSameNewPassword$()
      .subscribe((isvalid) => {
        this.isSame = isvalid;
      });

    const changeSubscription = this.newPasswordForm
      .isChange$()
      .subscribe((isvalid) => {
        this.isChange = isvalid;
      });

    this.subscriptions.push(
      valid8DigitSubscription,
      validCapitalLetterSubscription,
      validLowerCaseSubscription,
      validNumberSubscription,
      sameConfirmPasswordSubscription,
      sameNewPasswordSubscription,
      changeSubscription,
      anonymousUserFormStoreSubs,
    );

    this.newPasswordForm.newPasswordControl.setValue('');
    this.newPasswordForm.confirmPassword.setValue('');
  }

  public get isAllVerified() {
    const verifyArray = [
      this.isValid8Digit,
      this.isValidCapitalLetter,
      this.isValidLowerCase,
      this.isValidNumber,
      this.isSame,
    ];
    return verifyArray.every(verify => verify) && this.newPasswordForm.emailAddressControl.emailAddressError === '';
  }

  openLoginModal() {
    this._appStoreService.sourceNavigationLogin = BUSINESS_PATHS.CHECKOUT;
    this.registerModalsManagerHelper.closeCheckoutRegisterModal();
    this.registerModalsManagerHelper.openRegisterModal();
  }

  openEmailVerification() {
    const email = this.newPasswordForm.emailAddressControl.value;
    const password = this.newPasswordForm.confirmPassword.value;

    this.newRegisterStore.email = email;
    this.newRegisterStore.password = password;
    this.mainRegisterStore.email = email;
    this.registerOriginStore.emailVerificationOrigin = BUSINESS_PATHS.CHECKOUT;

    this.registerModalsManagerHelper.closeCheckoutRegisterModal();
    this.registerModalsManagerHelper.openEmailVerificationModal({
      checkoutRegister: true,
      documentType: this.documentType,
      documentNumber: this.documentNumber,
    });
  }

  closeModal() {
    this.registerModalsManagerHelper.closeCheckoutRegisterModal();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
