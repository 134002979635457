import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fp-modal-cyberwow',
  templateUrl: './modal-cyberwow.component.html',
  styleUrls: ['./modal-cyberwow.component.sass']
})
export class ModalCyberWowDialogComponent implements OnInit {


  constructor(
    public _dialogRef: MatDialogRef<ModalCyberWowDialogComponent>,
  ) {
  }

  ngOnInit() {

  }

  closeDialog() {
    this._dialogRef.close();
  }

}
