export const DOCUMENT_TYPE_ERROR_MESSAGES = {
  required: "Selecciona un tipo de documento",
};

export const DNI_ERROR_MESSAGES = {
  required: "Ingresa tu DNI",
  minlength: "El número de DNI está incompleto",
  validateNumbers: "El DNI es inválido",
};

export const EMAIL_ADDRESS_ERROR_MESSAGES = {
  required: "Ingresa tu correo electrónico",
  email: "El correo electrónico es inválido",
};

export const EMAIL_LOGIN_ERROR_MESSAGES = {
  required: "Ingresa tu correo electrónico",
  email: "El correo debe contener los caracteres “@” y “.”",
  pattern: "El correo debe contener los caracteres “@” y “.”",
};
export const EMAIL_LOGIN_ERROR_MESSAGES_TOMADOR = {
  required: "Ingresa tu correo electrónico",
  email: "El correo debe contener los caracteres “@” y “.”",
  pattern: "El correo debe contener el dominio @farmaciasperuanas.pe",
};
export const NAME_ERROR_MESSAGES = {
  required: "Ingresa tus nombres y apellidos",
  validateCharacters: "El nombre es inválido",
};

export const PHONE_NUMBER_ERROR_MESSAGES = {
  required: "Ingresa tu celular",
  minlength: "El celular está incompleto",
  validateNumbers: "El celular es inválido",
  numberPhoneValidation: "El número de celular es inválido",
};

export const CONTACT_NUMBER_ERROR_MESSAGES = {
	required: "Ingresa tu número de contacto",
	minlength: "El número de contacto está incompleto",
	validateNumbers: "El número de contacto es inválido",
	numberPhoneValidation: "El número de contacto es inválido"
};

export const PHONE_NUMBER_ORDER_TAKER_ERROR_MESSAGES = {
  required: "Ingresa tu teléfono",
  minlength: "El teléfono está incompleto",
  validateNumbers: "El teléfono es inválido",
  numberPhoneValidation: "El número de teléfono es inválido",
};
export const DELIVERY_TYPE_ERROR_MESSAGES = {
  required: "Selecciona un tipo de delivery",
};

export const RADIO_BUTTON_ERROR_MESSAGES = {
  required: "Selecciona una de las opciones",
};

export const RUC_ERROR_MESSAGES = {
  required: "Ingresa tu RUC",
  minlength: "El número de RUC es invalido",
  maxlength: "El número de RUC es invalido",
  legalPerson: "El número de RUC es invalido",
};

export const BUSINESS_NAME_ERROR_MESSAGES = {
  required: "Ingresa tu razón social",
};

export const LEGAL_ADDRESS_ERROR_MESSAGES = {
  required: "Ingresa tu domicilio legal",
};

export const TEXT_SEARCH_ERROR_MESSAGES = {
  required: "Ingresa tu domicilio legal",
};

export const CASH_PAYMENT_ERROR_MESSAGE = {
  required: "Ingrese el monto",
  amount: "Ingrese un monto valido",
};

export const FIRST_NAME_ERROR_MESSAGES = {
  required: "Ingresa tus nombres",
  validateCharacters: "Los nombres son inválidos",
};

export const LAST_NAME_ERROR_MESSAGES = {
  required: "Ingresa tus apellidos",
  validateCharacters: "El apellidos son inválidos",
};

export const BIRTHDAY_ERROR_MESSAGES = {
  required: "Ingresa una fecha válida (dd/mm/aaaa)",
  validateCharacters: "La fecha es inválida",
};

export const ADRESS_ERROR_MESSAGES = {
  required: "Ingresa una dirección válida",
  validateCharacters: "La dirección es inválida",
};

export const QUANTITY_ERROR_MESSAGES = {
  required: "Ingresa una cantidad",
  validateNumbers: "Ingresa una cantidad valida",
};

export const PASSWORD_LOGIN_ERROR_MESSAGES = {
  required: "Ingresa contraseña",
};
