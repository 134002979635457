import { Injectable } from '@angular/core';
import { GenericService } from '../generic';
import { Observable} from 'rxjs';
import { ENDPOINTS } from '../../parameters/endpoints';
import {CustomToken} from '../../models/custom-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationTokenService {

  constructor(private genericService: GenericService) { }

  public getCustomToken(): Observable<CustomToken> {
    return this.genericService.genericGet(ENDPOINTS.GET_CUSTOM_TOKEN);
  }
}
