import { Injectable } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { getErrorbyFormControl } from "@helpers/error-controls-helpers";
import { CONTACT_NUMBER_ERROR_MESSAGES } from "@parameters/error-message-forms";
import { DOCUMENT_TYPE_DNI } from "src/app/core/modules/client-manager/paramaters/client-manager.const";
import { ControlBornDateControl } from "src/app/shared/controls/born-date-control";
import { DocumentNumberControl } from "src/app/shared/controls/document-number-control";
import { DocumentTypeControl } from "src/app/shared/controls/document-type-control";
import { EmailControl } from "src/app/shared/controls/email-control";
import { FirstNameControl } from "src/app/shared/controls/first-name-control";
import { GenderControl } from "src/app/shared/controls/gender-control";
export class ContactNumberControl extends FormControl{
	constructor() {
		super("");
		this.settingNameValidators();
	}

	private settingNameValidators() {
		this.setValidators([
			Validators.required,
			Validators.minLength(7),
		]);
	}

	public get phoneNumberError(): string {
		return getErrorbyFormControl(this, CONTACT_NUMBER_ERROR_MESSAGES);
	}
}
@Injectable({
	providedIn: "root"
})
export class UserControlledFormService {
	public form: FormGroup;
	private _namesControl = new FirstNameControl();
	private _phoneNumberControl = new ContactNumberControl();
	private _documentTypeControl = new DocumentTypeControl("DNI");
	private _documentNumberControl = new DocumentNumberControl();
	private _emailControl = new EmailControl();
	private _bornDateControl = new ControlBornDateControl();
	private _genderControl = new GenderControl();
	private _isForThird = new FormControl(false);

	constructor(private formBuilder: FormBuilder) {
		this.form = this.formBuilder.group({
			names: this._namesControl,
			phoneNumber: this._phoneNumberControl,
			documentType: this._documentTypeControl,
			documentNumber: this._documentNumberControl,
			email: this._emailControl,
			bornDate: this._bornDateControl,
			gender: this._genderControl,
			isForThird: this._isForThird
		});
	}

	public get namesControl() {
		return this.form.get("names") as FirstNameControl;
	}

	setDataForm(data: any) {
		this.form.setValue(data);
		this.form.updateValueAndValidity()
	}

	public resetForm() {
		this._namesControl.setValue("");
		this._namesControl.markAsUntouched();
		this._namesControl.markAsPristine();
		this._phoneNumberControl.setValue("");
		this._phoneNumberControl.markAsUntouched();
		this._phoneNumberControl.markAsPristine();
		this._documentTypeControl.setValue("DNI");
		this._documentTypeControl.markAsUntouched();
		this._documentTypeControl.markAsPristine();
		this._documentNumberControl.setValue("");
		this._documentNumberControl.markAsUntouched();
		this._documentNumberControl.markAsPristine();
		this._emailControl.setValue("");
		this._emailControl.markAsUntouched();
		this._emailControl.markAsPristine();
		this._bornDateControl.setValue("");
		this._bornDateControl.markAsUntouched();
		this._bornDateControl.markAsPristine();
		this._genderControl.setValue("Selecciona tu género");
		this._genderControl.updateValueAndValidity();
		this._genderControl.markAsUntouched();
		this._genderControl.markAsPristine();
	}

	public get documentControl() {
		return this.form.get("documentNumber") as DocumentNumberControl;
	}

	public get phoneNumberControl() {
		return this.form.get("phoneNumber") as ContactNumberControl;
	}

	public get emailControl() {
		return this.form.get("email") as EmailControl;
	}

	public get documentNumberControl() {
		return this.form.get("documentNumber") as DocumentNumberControl;
	}

	public get documentTypeControl() {
		return this.form.get("documentType") as DocumentTypeControl;
	}

	public get genderControl() {
		return this.form.get("gender") as GenderControl;
	}

	public get bornDateControl() {
		return this.form.get("bornDate") as ControlBornDateControl;
	}

	public get isForThirdControl() {
		return this.form.get("isForThird") as FormControl;
	}
}
