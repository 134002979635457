<div class="presentation-list">
  <ng-container *ngFor="let presentation of presentationList; let index = index">
    <label class="presentation-label"
           [class.checked]="presentationSelected === presentation.id"
           [class.disabled]="productStatus === eProductStatus.unavailable">
           <!-- [class.disabled]="getProductPresentationDisabled(presentation)" -->

      <!-- [disabled]="getProductPresentationDisabled(presentation)" -->
      <input type="radio"
             name="presentation"
             class="presentation-input"
             [value]="presentation.id"
             [disabled]="productStatus === eProductStatus.unavailable"
             [checked]="presentationSelected === presentation.id"
             (change)="selectPresentation(presentation)">
      <span class="presentation-radio-mark"></span>
      <span class="presentation-text label"
            [class.label-black--1]="presentationSelected === presentation.id && !getProductPresentationDisabled(presentation)">
        {{presentation.presentation  | lowercase | capitalCase}}
      </span>
    </label>
  </ng-container>
  <!-- <p *ngIf="productStatus === eProductStatus.unavailablePresentation"
     class="label--2 text-warning mt-2 ml-4">
    Disponible solo en {{availablePresentation?.presentation | lowercase | capitalCase}}
  </p> -->
</div>
