import { ProductOutOfStockDialogComponent } from './components/product-out-of-stock-dialog/product-out-of-stock-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SERVICES } from './service';
import { MODELS } from './models';
import { PIPES } from './pipe';
import { BEHAVIORS } from './behaviors';
import { HELPERS } from './helpers';
import { ButtonsModule } from '../core/generic/buttons/buttons.module';
import { ProductIsNotDeliveredComponent } from './components/product-is-not-delivered/product-is-not-delivered.component';
import { ProductSellerInShoppingcartDialogComponent } from './components/product-seller-in-shoppingcart-dialog/product-seller-in-shoppingcart-dialog.component';
import { ProductSellerCantAddedDialogComponent } from './components/product-seller-cant-added-dialog/product-seller-cant-added-dialog.component';
import { GeolocalizationDialogComponent } from './components/geolocalization-dialog/geolocalization-dialog.component';
import { ProductOutOfCoverageDialogComponent } from './components/product-out-of-coverage/product-out-of-coverage-dialog.component';

@NgModule({
  declarations: [
    ...PIPES,
    ProductOutOfStockDialogComponent,
    ProductOutOfCoverageDialogComponent,
    GeolocalizationDialogComponent,
    ProductIsNotDeliveredComponent,
    ProductSellerInShoppingcartDialogComponent,
    ProductSellerCantAddedDialogComponent
  ],
  providers: [
    ...BEHAVIORS,
    ...SERVICES,
    ...MODELS,
    ...HELPERS,
  ],
  imports: [
    CommonModule,
    ButtonsModule
  ],
  exports: [
    ...PIPES
  ]
})
export class SharedModule {
}
