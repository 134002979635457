<div class="no-coverage">
  <div class="no-coverage-content">
    <div class="content">
      <div class="row">
        <div class="col-6 col-sm-5 offset-sm-1 left">
          <div class="content-description">
            <div class="no-coverage-title">
              No tenemos cobertura para esta ubicación
            </div>
            <div class="no-coverage-text">
              Prueba ingresando otra dirección o moviendo el pin.
            </div>
          </div>
          <div class="content-button" *ngIf="isDesktop" (click)="searchAgain()">
            <fp-button-primary
              buttonDisplay="block">
              Volver a buscar
            </fp-button-primary>
          </div>
        </div>
        <div class="col-6 col-sm-5 image-content">
          <img class="image-no-coverage" src="assets/images/checkout/Oops.svg" alt="">
        </div>
      </div>
      <div class="row" *ngIf="!isDesktop">
        <div class="col" >
          <div class="resp-btn-content" (click)="searchAgain()">
            <fp-button-primary
              buttonDisplay="block">
                Volver a buscar
            </fp-button-primary>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
