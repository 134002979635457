import { Component, Input, OnChanges } from '@angular/core';
import { EProductStatus } from '../../models/product-status.enum';
import { ProductImplementService } from '../../service/product-implement.service';
import { ProductAlertsService } from '../../service/product-alerts.service';
import { ProductPresentation } from '../../models/product-presentation.model';
import { ProductShoppingCart } from '../../models/product-shopping-cart/product-shopping-cart.model';
import { BUSINESS_PATHS } from 'src/app/shared/parameters/business.parameter';
import { getUnavailablePresentationStatus } from '../../service/product-status.helper';
import { getProductPresentationSelected } from '../../service/product-presentation.helper';
import { ProductSalesforceService } from '../../service/product-salesforce.service';
import { TaggingService } from 'src/app/atm-services/tagging.service';
import { ProductSellersShoppingCartBase } from '../../models/product-shopping-cart/product-seller-shopping-cart-base.model';
import { EBrand } from '@parameters/access/brand.parameter';
import { OrderTakerValidator } from '@validators/order-taker.validator';
import { PRODUCT_PRESCRIPTION } from 'src/app/business/product-detail/parameters/product-detail.parameters';

@Component({
  selector: 'fp-product-drawer-mini-cart',
  templateUrl: './product-drawer-mini-cart.component.html',
  styleUrls: ['./product-drawer-mini-cart.component.sass']
})
export class ProductDrawerMiniCartComponent implements OnChanges {
  public isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
  public brand = EBrand;
  public eProductStatus = EProductStatus;
  public productStatus: number = EProductStatus.available;
  public productSellerStatus: number = EProductStatus.available;
  public productPresentation: ProductPresentation;
  public productPath = BUSINESS_PATHS.PRODUCT;
	public productPrescription = PRODUCT_PRESCRIPTION;

  @Input() product: ProductShoppingCart;
  @Input() productSeller: ProductSellersShoppingCartBase;

  constructor(
    private _productImplement: ProductImplementService,
    private _productAlerts: ProductAlertsService,
    private _productSalesforce: ProductSalesforceService
  ) {
  }

  ngOnChanges() {
    this.productPresentation = getProductPresentationSelected(this.product);
    const unavailablePresentation = getUnavailablePresentationStatus(this.product, this.productPresentation.fractionated);
    this.productStatus = unavailablePresentation === EProductStatus.unavailablePresentation ? EProductStatus.unavailable : unavailablePresentation;
  }

  deleteProduct() {
    this._productImplement.deleteProduct(this.product.id);
    this._productAlerts.deleteProductAlert();
    this._productSalesforce.deleteProduct(this.product.id);
    TaggingService.productMiniCartEliminar();
    const itemPresentation = this.product.presentations.find( e => e.id === this.product.presentationIdSelected);
    TaggingService.removeFromCart(this.product, itemPresentation.price, itemPresentation.presentation, this.product.quantity);
    TaggingService.removeFromCartSDK(this.product, itemPresentation.price,  this.product.quantity)


  }
}
