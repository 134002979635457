import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrand } from "@parameters/access/brand.parameter";

@Component({
	selector: "fp-disclaimer-controlled",
	templateUrl: "./disclaimer-controlled.component.html",
	styleUrls: ["./disclaimer-controlled.component.sass"],
	encapsulation: ViewEncapsulation.None
})
export class DisclaimerControlledComponent implements OnChanges {
	@Input() title: string;
	@Input() innerClass: string = "";
	text: string = "Tu carrito tiene uno o más productos que requieren receta médica.";
	isMifarma = "";
	constructor() {
		this.isMifarma = environment.brand;
	}

	ngOnChanges() {}

	get getIconBrand() {
		return this.isMifarma === "inkafarma" ? "recipe-ikf" : "recipe-mf";
	}
}
