<div class="sub-section-container">
	<div class="content">
		<div class="row">
			<div class="col">
				<div *ngIf="flagBorderTop" class="border-top"></div>
			</div>
		</div>
	</div>
	<ng-content></ng-content>
</div>
