import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "@environments/environment";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import * as moment from "moment";
import { Observable, Subscription, timer } from "rxjs";
import { take } from "rxjs/operators";
import { AnalyticsManager } from "src/app/analytics";
import { GoogleTagManagerService } from "src/app/shared/analytics/google-tag-manager.service";
import { UserOTPRequestParams, ValidateToken, ValidateTokenResponse } from "src/app/shared/models/register/register.model";
import { BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { COMPANY_ID } from "src/app/shared/parameters/global";
import { NewRegisterAccessStoreService } from "src/app/shared/stores/user/new-register-access-store.service";
import { NewRegisterStoreService } from "src/app/shared/stores/user/new-register-store.service";
import { SnackbarAlertService } from "../../../snackbar/snackbar-alert.service";
import { CloseEmailVerificationModalService } from "../../register-services/close-email-verification-modal.service";
import { RegisterImplementService } from "../../register-services/register-implement.service";
import { RegisterModalsManagerHelperService } from "../../register-services/register-modals-manager-helper.service";
import { RegisterOriginStoreService } from "../../register-stores/register-origin-store.service";

@Component({
  selector: "fp-email-verification-modal",
  templateUrl: "./email-verification-modal.component.html",
  styleUrls: ["./email-verification-modal.component.sass"]
})
export class EmailVerificationModalComponent implements OnInit, OnDestroy {
  public isInkaBrand = OrderTakerValidator.isOrderTakerEnv()
    ? EBrand[EBrandAsset[environment.brand]] == EBrand.inkafarma
    : EBrand[environment.brand] == EBrand.inkafarma;
  destinationMail = "";

  checkVerify = false;
  verificationCode: string;
  buttonDisabled = true;
  isDisabledInputs = false;

  buttonSendDisabled = true;

  searchDate: moment.Moment = moment();
  timeInMinutes = 3;

  searchEndDate: moment.Moment;
  remainingTime: number;
  minutes: number;
  seconds: number;
  everySecond: Observable<number> = timer(0, 1000);

  public form: FormGroup;
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmailVerificationModalComponent>,
    public dialogRefAll: MatDialog,
    public formBuilder: FormBuilder,
    private _snackbarAlertService: SnackbarAlertService,
    private newRegisterStore: NewRegisterStoreService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private registerImplement: RegisterImplementService,
    private newRegisterAccessStore: NewRegisterAccessStoreService,
    private registerOriginStore: RegisterOriginStoreService,
    private closeEmailVerificationModal: CloseEmailVerificationModalService,
    private registerModalsManagerHelper: RegisterModalsManagerHelperService,
    public gtm: GoogleTagManagerService
  ) {
    this.form = this.formBuilder.group({
      code: new FormControl()
    });

    this.destinationMail = newRegisterStore.email;
    this.searchEndDate = this.searchDate.add(this.timeInMinutes, "minutes");
  }

  ngOnInit() {
    this.sendCode();
    const formsValues = this.form.controls["code"].valueChanges.subscribe((value) => {
      this.verificationCode = value;
      if (this.verificationCode.length === 6) {
        this.buttonDisabled = false;
      } else {
        this.buttonDisabled = true;
      }
    });

    const countDown = this.everySecond.subscribe((seconds) => {
      const currentTime: moment.Moment = moment();
      this.remainingTime = this.searchEndDate.diff(currentTime);
      this.remainingTime = this.remainingTime / 1000;

      if (this.remainingTime <= 0) {
        this.buttonSendDisabled = false;
      } else {
        this.minutes = Math.floor(this.remainingTime / 60);
        this.seconds = Math.floor(this.remainingTime - this.minutes * 60);
      }
      this.ref.markForCheck();
    });

    this.subscriptions.push(formsValues, countDown);
  }

  sendCode() {
    const subscription = this.registerImplement
      .sendOtp("", this.destinationMail, COMPANY_ID)
      .pipe(take(1))
      .subscribe((response) => {
        this.subscriptions.push(subscription);
      });
  }

  sendNewCode() {
    if (this.buttonSendDisabled) {
      return;
    }
    this.buttonSendDisabled = true;
    this.searchDate = moment();
    this.searchEndDate = this.searchDate.add(this.timeInMinutes, "minutes");
    this.sendCode();
    AnalyticsManager.dispatchEvent({
      eventName: "ui_register_codigo_de_verificacion",
      eventCategory: "UI :: Register",
      eventAction: "Código de verificación",
      eventLabel: "Link :: Reenviar código"
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  closeModal() {
    this.registerModalsManagerHelper.closeEmailVerificationModal();
  }

  changeEmailEvent() {
    if (this.registerOriginStore.emailVerificationOrigin === BUSINESS_PATHS.REGISTER) {
      this.closeModal();
    } else {
      this.closeModal();
      this.registerModalsManagerHelper.openRegisterModal();
    }
    AnalyticsManager.dispatchEvent({
      eventName: "ui_register_codigo_de_verificacion",
      eventCategory: "UI :: Register",
      eventAction: "Código de verificación",
      eventLabel: "Link :: Cambiar mi correo"
    });
  }

  goToNewRegister() {
    const request = new UserOTPRequestParams();
    request.otp = this.form.controls["code"].value;
    request.emailAddress = this.destinationMail;

    this.registerImplement.tokenValidator(request).subscribe((response: ValidateTokenResponse) => {
      const responseData = response.data as ValidateToken;
      if (responseData.valid) {
        this.checkVerify = true;
        setTimeout(() => {
          this.newRegisterAccessStore.isValidToAccess = true;
          this.dialogRefAll.closeAll();

            if (this.data && this.data.checkoutRegister) {
              this.showOpenCheckoutWelcomeRegisterModal();
            } else {
              if (
                this.registerOriginStore.emailVerificationOrigin ===
                BUSINESS_PATHS.REGISTER
              ) {
                this.closeEmailVerificationModal.emitVerificationState(true);
              } else {
                /*
                 * Si es mifarma debería mostrar el modal para escoger el tipo de dni
                 */
                if (this.isInkaBrand) {
                  this.router.navigate([BUSINESS_PATHS.REGISTER]);
                } else {
                  this.registerModalsManagerHelper.openPreRegisterModal();
                }
              }
            }
          }, 700);
      } else {
        this._snackbarAlertService.snackbarAlertError("El código que ingresaste es incorrecto. Intenta nuevamente.");
      }
      this.buttonDisabled = false;
    });
  }

  showOpenCheckoutWelcomeRegisterModal() {
    sessionStorage.setItem('documentType', this.data.documentType);
    sessionStorage.setItem('documentNumber', this.data.documentNumber);
    this.registerOriginStore.emailVerificationOrigin = BUSINESS_PATHS.CHECKOUT;
    this.registerModalsManagerHelper.openCheckoutWelcomeRegisterModal();
  }

  errorCodeExpired() {
    this._snackbarAlertService.snackbarAlertInfo(
      "El código que ingresaste ha expirado. Dale click a “Reenviar código” para enviarte uno nuevo."
    );
    this.form.controls["code"].setValue("");
  }
}
