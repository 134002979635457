<section
  class="radio-drugstore"
  (click)="selectedDrugstore.emit(data?.id)"
  *ngIf="serviceRET"
>
  <ng-content></ng-content>
  <div class="radio-drugstore-content">
    <div class="d-flex justify-content-between">
      <span class="radio-drugstore__title">{{ data?.name | titlecase }}</span>
      <fp-chip
        iconName="navigation-blue"
        [iconSize]="8"
        [text]="(data?.distance | number: '1.1-1') + ' k.m.'"
        status="purple"
        size="small"
      ></fp-chip>
    </div>
    <span class="radio-drugstore__address">{{
      data?.address | titlecase
    }}</span>
    <div class="d-flex justify-content-between">
      <fp-chip
        iconName="time-circle"
        [text]="serviceRET?.startHour + ' - ' + serviceRET?.endHour"
        status="purple"
      ></fp-chip>

      <fp-chip text="Retiro en tienda" status="purple"></fp-chip>
    </div>

    <!-- <section class="radio-drugstore-types-atention">
      <fp-chip
        *ngFor="let aItem of data?.services; let indexOfelement = index"
        [text]="aItem?.shortName"
        status="purple"
        [disabled]="!aItem?.enabled"
      ></fp-chip>
    </section> -->
  </div>
</section>
