import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CheckoutErrorCouponModalInkaComponent } from '../checkout-components/custom-components/checkout-error-coupon-modal/checkout-error-coupon-modal-inka/checkout-error-coupon-modal-inka.component';
import { CheckoutErrorScheduleModalComponent } from '../checkout-components/custom-components/checkout-error-schedule-modal/checkout-error-schedule-modal.component';
import { IErrorScheduleService } from '../models/error-schedule-service.inteface';
import { EBrand } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';
import { CheckoutErrorCouponModalMifaComponent } from '../checkout-components/custom-components/checkout-error-coupon-modal/checkout-error-coupon-modal-mifa/checkout-error-coupon-modal-mifa.component';
import { ERROR_GENERIC_MODAL_CONFIG } from '../parameters/modal-config.constant';

@Injectable()
export class ErrorScheduleModalService {

  constructor(
    private dialog: MatDialog,
  ) { }

  public showErrorModal(type: string, message: string, able?: boolean): void {
    this.dialog.open(CheckoutErrorScheduleModalComponent, {
      ...ERROR_GENERIC_MODAL_CONFIG,
      data: { type, able, message } as IErrorScheduleService,
    });
  }

  public showErrorCouponModa(coupon: string) {
    const isMifaBrand = EBrand[environment.brand] == EBrand.mifarma;
    const couponWithIkf = '400px';
    const couponWitMf = '360px';
    const errorModal = isMifaBrand ? CheckoutErrorCouponModalMifaComponent : CheckoutErrorCouponModalInkaComponent;
    this.dialog.open(errorModal, {
      width: isMifaBrand ? couponWitMf : couponWithIkf,
      disableClose: true,
      data: { coupon },
      hasBackdrop: true,
      panelClass: 'panel-modalerror'
    });
  }
}
