import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

interface IObjValue {
	id: number | string;
	value: number | string;
	[key: string]: number | string | Object;
}

@Component({
	selector: "fp-obj-select-control",
	templateUrl: "./obj-select-control.component.html",
	styleUrls: ["./obj-select-control.component.sass"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ObjSelectControlComponent),
			multi: true
		}
	]
})
export class ObjSelectControlComponent implements OnInit {
	@Input() label = "";
	@Input() options: IObjValue[] = [];
	@Input() errorMessage = "";
	@Output() selectedOptionEvent = new EventEmitter<IObjValue>();
	@Output() clickEvent = new EventEmitter<IObjValue>();

	public isDisabled = false;
	public selectedValue = {} as IObjValue;

	onChange = (_: any) => {};
	onTouch = (_: any) => {};

	constructor() {}

	ngOnInit() {}

	writeValue(selectedValue: IObjValue): void {
		if (selectedValue) {
			this.selectedValue = selectedValue;
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	public selectOption(event: Event) {
		const selectElement = event.target as HTMLSelectElement;
		const selectedId = Number(selectElement.value);
		const selectedValue = this.options.find((option) => option.id === selectedId);
		this.selectedValue = selectedValue;
		this.onChange(this.selectedValue);
		this.onTouch(this.selectedValue);
		this.selectedOptionEvent.emit(this.selectedValue);
	}
}
