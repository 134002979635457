import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import { UserControlledFormService } from "../../form/user-controlled-form.service";
import { GenericModalErrorComponent } from "src/app/shared/components/generic-modal-error/generic-modal-error.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { environment } from "@environments/environment";
import { CartControlledStoreService } from "../../../../services/cart-controlled-store.service";
import { RecipeControlledFormService } from "../../form/recipe-controlled-form.service";
import { BreakpointBehavior } from "src/app/shared/behaviors";
import { BREAKPOINTS } from "@parameters/global";
import { Subscription } from "rxjs";
import { CartControlledFormFileModalComponent } from "../cart-controlled-form-file-modal/cart-controlled-form-file-modal.component";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { EBrand } from "@parameters/access/brand.parameter";
// const MAX_SIZE = 5 * 1024 * 1024;
@Component({
	selector: "fp-cart-controlled-form-step-two",
	templateUrl: "./cart-controlled-form-step-two.component.html",
	styleUrls: ["./cart-controlled-form-step-two.component.sass"],
	providers: []
})
export class CartControlledFormStepTwoComponent implements OnInit, OnDestroy {
	@ViewChild("cameraInput") cameraInput: ElementRef;
	@ViewChild("photoLibraryInput") photoLibraryInput: ElementRef;
	@ViewChild("fileInput") fileInput2: ElementRef;

	public dialogRef: MatDialogRef<GenericModalErrorComponent>;
	fileSelected = null;
	public CMPITypeOptions: Array<any> = ["CMP", "RNE", "ODO"];
	documentType = "1";
	disableInputfile = false;
	minDate = moment();

	public isCheckedSwitch = true;
	@ViewChild("fileInput") fileInput: ElementRef;
	isDesktop = false;
	private subscriptions: Subscription[] = [];
	INFO_CONTROLLED;
	MAX_SIZE;
	MAX_FILES;
	filesSelected: any = [];
	constructor(
		public _userControlledForm: UserControlledFormService,
		private matDialog: MatDialog,
		private _cartControlledStoreservice: CartControlledStoreService,
		public _recipeControlledFormService: RecipeControlledFormService,
		private breakpointBehavior: BreakpointBehavior
	) {}

	ngOnInit() {
		this.INFO_CONTROLLED = JSON.parse(localStorage.getItem("controllated-sale"));
		this.MAX_SIZE = (this.INFO_CONTROLLED.maxFileSizeAllowed / 1000) * 1024 * 1024;
		this.MAX_FILES = this.INFO_CONTROLLED.maxFileCountAllowed;
		TaggingService.showModalControlledStepTwo();

		this._recipeControlledFormService.CMPITypeControl.setValue("CMP");
		const behaviorSub = this.breakpointBehavior
			.mediaBreakpointUpObserver(BREAKPOINTS.xl)
			.subscribe((value) => (this.isDesktop = value.matches));

		this._cartControlledStoreservice.recipe$.subscribe((recipes) => {
			if (recipes && recipes.length >= this.MAX_FILES) this.disableInputfile = true;
		});

		this._cartControlledStoreservice.recipe$.subscribe((response) => {
			if (response) this.filesSelected = response;
		});
		this.subscriptions.push(behaviorSub);
	}

	getIconBrand(iconName) {
		if (this.disableInputfile) return "upload-disabled";
		const brand = environment.brand === EBrand.inkafarma ? "ikf" : "mf";
		return `${iconName}-${brand}`;
	}

	get getStatusForm() {
		return true;
	}

	get isForThird() {
		return this.isCheckedSwitch ? "Sí" : "No";
	}
	setCMPIType(documentTypeOption: string) {
		// const documentType = this.documentTypeList.find((value) => value.name === documentTypeOption);
		this._recipeControlledFormService.CMPITypeControl.setValue("1");
	}
	onFileSelected(event: Event): void {
		// this.handleFileRecipe(event)
		TaggingService.showUploadFileModalControlledStepTwo();
		this.fileSelected = null;
		const file = (event.target as HTMLInputElement).files[0];

		if (file) {
			if (file.size > this.MAX_SIZE) {
				this.dialogRef = this.matDialog.open(GenericModalErrorComponent, {
					width: "380px",
					disableClose: false,
					panelClass: "custom-error-modal"
				});
				this.dialogRef.afterClosed().subscribe(() => {});
			} else {
				this.fileSelected = file;
				this.filesSelected.push(file);
				this._cartControlledStoreservice.setRecipefile(this.filesSelected);
				this.fileInput.nativeElement.value = "";

				if (this.filesSelected.length >= this.MAX_FILES) this.disableInputfile = true;
			}
			TaggingService.closeUploadFileModalControlledStepTwo();
		}
	}

	handleFileInput(event: Event) {
		// this.handleFileRecipe(event)

		const file = (event.target as HTMLInputElement).files[0];
		if (file) {
			if (file.size > this.MAX_SIZE) {
				this.dialogRef = this.matDialog.open(GenericModalErrorComponent, {
					width: "380px",
					disableClose: false,
					panelClass: "custom-error-modal"
				});
				this.dialogRef.afterClosed().subscribe(() => {});
			} else {
				this.fileSelected = file;
				this.filesSelected.push(file);
				this._cartControlledStoreservice.setRecipefile(this.filesSelected);
				this.fileInput.nativeElement.value = "";

				if (this.filesSelected.length >= this.MAX_FILES) this.disableInputfile = true;
			}
		}
	}

	handleFileRecipe(event) {
		this.fileSelected = null;
		const file = (event.target as HTMLInputElement).files[0];
		if (file) {
			if (file.size > this.MAX_SIZE) {
				this.dialogRef = this.matDialog.open(GenericModalErrorComponent, {
					width: "380px",
					disableClose: false,
					panelClass: "custom-error-modal"
				});
				this.dialogRef.afterClosed().subscribe(() => {});
			} else {
				this.fileSelected = file;
				this.filesSelected.push(file);
				this._cartControlledStoreservice.setRecipefile(this.filesSelected);
				this.fileInput.nativeElement.value = "";
				if (this.filesSelected.length >= this.MAX_FILES) this.disableInputfile = true;
			}
		}
	}

	removeRecipe(index) {
		this.filesSelected = this.filesSelected.filter((file, i) => i !== index);
		this.disableInputfile = false;

		this._cartControlledStoreservice.setRecipefile(this.filesSelected);
	}

	openModalFileInput() {
		this.matDialog
			.open(CartControlledFormFileModalComponent, {
				width: "360px",
				disableClose: true,
				panelClass: "custom-error-modal"
			})
			.afterClosed()
			.subscribe((response) => {
				if (response) {
					this.triggerFileInput(response);
				}
			});
	}
	triggerFileInput(type: string) {
		if (type === "camera") {
			TaggingService.camaraUploadFileModalControlledStepTwo();
			this.cameraInput.nativeElement.click();
		} else if (type === "photoLibrary") {
			TaggingService.libraryUploadFileModalControlledStepTwo();
			this.photoLibraryInput.nativeElement.click();
		} else if (type === "file") {
			TaggingService.selectUploadFileModalControlledStepTwo();
			this.fileInput2.nativeElement.click();
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}
}
