import { IDiscountCanonical, IDiscountedAmountCanonical, IPurchaseSummary, IDiscountResponse } from './shopping-cart-precalculation.interface';
import { isObject } from '../../helpers/objects-equal';

const DEFAULT_DISCOUNT_AMOUNT_CANONICAL = {} as IDiscountedAmountCanonical;

export class DiscountCanonical {
  public code: string;
  public coupon: string | number;
  public id: number;
  public isEnable: boolean;
  public name: string;
  public parameters: string | number;
  public type: string;

  constructor(iDiscountCanonical: IDiscountCanonical = {} as IDiscountCanonical) {
    this.code = iDiscountCanonical.code || '';
    this.coupon = iDiscountCanonical.coupon || '';
    this.id = iDiscountCanonical.id || 0;
    this.isEnable = iDiscountCanonical.isEnable || false;
    this.name = iDiscountCanonical.name || '';
    this.parameters = iDiscountCanonical.parameters || 0;
    this.type = iDiscountCanonical.type || '';
  }
}

export class DiscountedResponse {
  public code: string;
  public message: string;

  constructor(iDiscountResponse: IDiscountResponse = {} as IDiscountResponse) {
    this.code = iDiscountResponse.code || '';
    this.message = iDiscountResponse.message || '';
  }
}

export class DiscountedAmountCanonical {
  public discountAmount: number;
  public discountCanonical: DiscountCanonical;
  public originalAmount: number;
  public response: DiscountedResponse;
  public discountAmountAdditionals: number;

  constructor(iDiscountedAmountCanonical: IDiscountedAmountCanonical = DEFAULT_DISCOUNT_AMOUNT_CANONICAL) {
    this.discountAmount = iDiscountedAmountCanonical.discountAmount || 0;
    this.discountCanonical = iDiscountedAmountCanonical.discountCanonical ?
      new DiscountCanonical(iDiscountedAmountCanonical.discountCanonical) :
      {} as IDiscountCanonical;
    this.originalAmount = iDiscountedAmountCanonical.originalAmount || 0;
    this.response = iDiscountedAmountCanonical.response ?
      new DiscountedResponse(iDiscountedAmountCanonical.response) :
      {} as IDiscountResponse;
    this.discountAmountAdditionals = iDiscountedAmountCanonical.discountAmountAdditionals || 0;
  }
}
export class PurchaseSummary {
  public deliveryCost: number;
  public discount?: number;
  public subtotal: number;
  public subtotalOh?: number;
  public total: number;
  public totalOh?: number;
  public totalRound?: number;
  public bagTax?: number;
  public totalOnline?: number;
  public ahorro?: number;
  public discountedAmountCanonical?: any;
  public subtotalPurse?: number;
  public subtotalListPrice?: number;
  public subtotalPaymentOh?: number;
  public discountPurse?: number;

  constructor(iPurchaseSummary: IPurchaseSummary = {} as PurchaseSummary) {
    const currentValue = isObject(iPurchaseSummary) ? iPurchaseSummary : {} as IPurchaseSummary;
    this.deliveryCost = currentValue.deliveryCost || null;
    this.discount = new DiscountedAmountCanonical(currentValue.discountedAmountCanonical || DEFAULT_DISCOUNT_AMOUNT_CANONICAL).discountAmount || null;
    this.discountPurse = new DiscountedAmountCanonical(currentValue.discountedAmountCanonical || DEFAULT_DISCOUNT_AMOUNT_CANONICAL).discountAmountAdditionals || null;
    this.discountedAmountCanonical =currentValue.discountedAmountCanonical ;
    this.subtotal = currentValue.subtotal || 0;
    this.subtotalOh = currentValue.subtotalOh || null;
    this.total = currentValue.total || 0;
    this.totalOh = currentValue.totalOh || null;
    this.totalRound = currentValue.round || null;
    this.bagTax = currentValue.taxICBPER || null;
    this.totalOnline = currentValue.totalOnline || 0;
    this.ahorro = currentValue.ahorro || 0;
    this.subtotalPurse = currentValue.subtotalPurse || 0;
    this.subtotalListPrice = currentValue.subtotalListPrice || 0;
    this.subtotalPaymentOh = currentValue.subtotalPaymentOh || 0;
  }
}

