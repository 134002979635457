import { Injectable } from '@angular/core';
import { GenericService } from '../generic';
import { ENDPOINTS } from '../../parameters/endpoints';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IGoogleMapConfig, GoogleMapConfig } from '../../models/google-map/google-map-config';

@Injectable()
export class GoogleMapConfigClientService {

  constructor(
    private _GenericService: GenericService
  ) { }

  public getGoogleMapConfig$(): Observable<GoogleMapConfig> {
    return this._GenericService
      .genericGetStaticFile(ENDPOINTS.GET_GOOGLE_MAP_CONFIGS)
      .pipe(
        map((response: IGoogleMapConfig) => {
          return response ? new GoogleMapConfig(response) : {} as GoogleMapConfig;
        })
      );
  }
}
