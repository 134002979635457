<div class="d-flex flex-wrap filters-landing-tag-container mt-n1 mb-n1 mr-n2 ml-n2">

  <ng-container *ngIf="listSelected">
    <div
      *ngFor="let category of listSelected"
      class="tag-base d-inline-block mt-1 mb-1 mr-2 ml-2 ellipsis">
      <div class="text">
        <span class="ellipsis">{{category}}</span>
        <fp-icon class="icon-style" iconSize="14" iconName="icon-x-close" (click)="deleteTag(category)"></fp-icon>
      </div>
    </div>
  </ng-container>
</div>
