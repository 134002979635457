import { ISubCategory } from './sub-category.interface';

export class SubCategory {

  public subCategoryId: number;
  public subCategoryName: string;
  public subCategoryKeyword: string;

  constructor(subcategory: ISubCategory) {
    this.subCategoryId = subcategory.id || -1;
    this.subCategoryName = subcategory.name || '';
    this.subCategoryKeyword = subcategory.keyword || '';
  }
}
