import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SERVICES_TYPES_ENUM } from "@parameters/global";

import { Observable } from "rxjs";

import { map } from "rxjs/operators";
import { SelectDataStoreService } from "src/app/business/office/store/select-data-store.service";
import { AddressHttpService } from "src/app/core/modules/address-manager/address-services/address-http.service";
import { AddressListStoreService } from "src/app/core/modules/address-manager/address-stores/address-list-store.service";
import { AddressItem } from "../../models/address/address.model";
import { DeliveryInfoResponse, IDeliveryInfoRequestParams, IDeliveryInfoResponse } from "../../models/delivery-info/delivery-info.model";
import { ENDPOINTS } from "../../parameters/endpoints";
import { ClientStoreService } from "../../stores/client/client-store.service";
import { OrderTakerValidator } from "../../validators/order-taker.validator";
import { GenericService } from "../generic";
import { AddressManagerPersistanceService } from "src/app/core/modules/address-manager/address-services/address-manager-persistance.service";
import { LS_CURRENT_ADDRESS, OT_ADDRESS_SELECTED } from "@service/local-storage/constants/address.constant";

@Injectable()
export class DeliveryInfoClientService {
  private readonly DELIVERY_INFO_ENDPOINT = ENDPOINTS.DELIVERY_INFO;
  private isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
  constructor(
    private genericService: GenericService,
    private _selectDataStore: SelectDataStoreService,
    private _addressListStore: AddressListStoreService,
    private addressManager: AddressManagerPersistanceService,
  ) {}

  public getDeliveryInfoWithParams$(params: IDeliveryInfoRequestParams): Observable<DeliveryInfoResponse> {
    const httpParams = new HttpParams()
      .set("deliveryTypeId", String(params.deliveryTypeId))
      .set("drugstoreId", String(params.drugstoreId))
      .set("isOldVersion", String("N"));

    return this.genericService.genericGet<IDeliveryInfoResponse>(this.DELIVERY_INFO_ENDPOINT, httpParams).pipe(
      map((response) => {
        const deliveryInfoResponse = new DeliveryInfoResponse(response);
        if (this.isOrderTaker) {
          //Si es tomador le asigno la dirección del Storage
          let favoriteAddress = deliveryInfoResponse.address.find((address) => address.favorite) || null;
          let {addressToUpdate} = this.addressManager;
          if (favoriteAddress != null) {
            addressToUpdate.idNumber = Number(favoriteAddress.id);
            addressToUpdate.favorite = true;
            addressToUpdate.schedule = favoriteAddress.schedule;
            this.addressManager.setAddressToUpdate(addressToUpdate);
            this.addressManager.saveAddressToUpdate();
            deliveryInfoResponse.address = [addressToUpdate];
          }
        }
        return deliveryInfoResponse;
      })
    );
  }

  public getDeliveryInfo$(): Observable<DeliveryInfoResponse> {
    let httpParams = new HttpParams().set("isOldVersion", String("N"));
    if (OrderTakerValidator.isOrderTakerEnv()) {
      return this.genericService.genericGet<IDeliveryInfoResponse>(this.DELIVERY_INFO_ENDPOINT, httpParams).pipe(
        map((response) => {
          const deliveryInfoResponse = new DeliveryInfoResponse(response);
          if (deliveryInfoResponse.address.length > 0 && !deliveryInfoResponse.address[0].id) {
            deliveryInfoResponse.address = this._addressListStore.addressList;
          }

          //Si es tomador le asigno la dirección del Storage
            let {addressToUpdate} = this.addressManager;
            const addressStorage = JSON.parse(localStorage.getItem(OrderTakerValidator.isOrderTakerEnv() ? OT_ADDRESS_SELECTED : LS_CURRENT_ADDRESS)) as AddressItem;
            const favoriteAddress = deliveryInfoResponse.address.find((address) => address.favorite) || null;
            if (favoriteAddress != null) {
              if (addressToUpdate != null && addressToUpdate?.name?.length > 0) {
                addressToUpdate.idNumber = isNaN(Number(favoriteAddress.id)) ? favoriteAddress.idNumber : Number(favoriteAddress.id);
                addressToUpdate.favorite = true;
                addressToUpdate.schedule = favoriteAddress.schedule;
              }else{
                addressToUpdate = new AddressItem(favoriteAddress);
              }
              this.addressManager.setAddressToUpdate(addressStorage?.idNumber > 0 && addressStorage?.name?.length > 0 ? addressStorage : addressToUpdate);
              this.addressManager.saveAddressToUpdate();
              deliveryInfoResponse.address = [addressToUpdate];
            }
          //
          if (deliveryInfoResponse.deliveryTypes.length > 0) {
            const selectedDeliveryType = deliveryInfoResponse.deliveryTypes.find((d) => d.selected);

            if (!selectedDeliveryType) {
              const listDelivery = deliveryInfoResponse.deliveryTypes.map((d) => d.name);
              deliveryInfoResponse.deliveryTypes.forEach((r) => {
                const deliverySelect =
                  this._selectDataStore.serviceSelected ??
                  (listDelivery.includes(SERVICES_TYPES_ENUM.DELIVERY)
                    ? SERVICES_TYPES_ENUM.DELIVERY
                    : deliveryInfoResponse.deliveryTypes[0].name);
                if (r.name === deliverySelect) r.selected = true;
                else r.selected = false;
              });
            }
          }
          return deliveryInfoResponse;
        })
      );
    }

    return this.genericService.genericGet<IDeliveryInfoResponse>(this.DELIVERY_INFO_ENDPOINT, httpParams).pipe(
      map((response) => {
        const deliveryInfoResponse = new DeliveryInfoResponse(response);
        return deliveryInfoResponse;
      })
    );
  }
}
