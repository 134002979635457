export interface CouponCategoryRelation {
  categoryCode: number;
  priority: number;
}

export class Coupon {
  public id: string;
  public sku: string;
  public skuMiFarma: string;
  public productName: string;
  public productDescription: string;
  public discount: number;
  public discountCalculated: number;
  public discountToShow: string;
  public discountType: string;
  public isNominalDiscount?: boolean;
  public isPackagePromotion?: boolean;
  public couponValidity: {
    startDate: string;
    endDate: string;
    totalDays: number;
  };
  public barCode: string;
  public campaignId: string;
  public couponStatus: number;
  public termsAndConditions: string;
  public savingValue: string;
  public pathImage: {
    small: string;
    medium: string;
    large: string;
  };
  public categoryCode: number;
  public couponActive: string;
  public categoryRelation: CouponCategoryRelation[];
  public priority: number;
  public priorityByDiscountValue: number;
  public familyCategoryCode: number;

  // TODO: Cambiar por propiedad del servicio
  public isFavourite: boolean;
  public isBicanal: boolean;
  public isFlashCoupon: boolean;
  public isContextCoupon: boolean;
  public isMultiHookCoupon: boolean;
  public isWelcomeCoupon: boolean;
  public urlEcommerce: string;
  public omnicanal: string;

  public loadingButton?: boolean;
  public logicCode: number;
}
