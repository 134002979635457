import { DOCUMENT_TYPE, DOCUMENT_TYPE_LABEL } from "@parameters/global";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { CClientTermColour, EConsents } from "../constants/find-client.constant";
import { IApproval, IClientResponse } from "../interfaces/find-client.interface";
import { EnumAgreementFindClient, EnumChannelFindClient } from "../parameters/find-client.parameter";

export class ClientResponse {
	idClient: string;
	identityDocumentNumber: string;
	name: string;
	lastName: string;
	lastName2: string;
	idIdentityDocument: string;
	identityDocument?: string;
	phoneList: Array<string>;
	email?: string;
	addresses: Array<AddressResponse> | Array<AddressItem>;
	isFirstBuyIKF?: boolean;
	isFirstBuyMF?: boolean;
	approvals?: IApproval[];
	consentsColor?: string;
	isAcceptLoyalty?: boolean;
	hasError?: boolean;
	constructor(response: IClientResponse, brand: string) {
		this.idClient = response.idClient;
		this.identityDocumentNumber = response.identityDocumentNumber;
		this.name = response.name;
		this.lastName = response.lastName;
		this.lastName2 = response.lastName2;
		this.idIdentityDocument = response.idIdentityDocument;
		this.identityDocument = this.identityDocumentLabel(response.idIdentityDocument);
		this.phoneList = response.phoneList;
		this.email = response.email;
		this.addresses = response.addresses ? this.mapToAddressItem(response.addresses as Array<AddressResponse>) : [];
		this.isFirstBuyIKF = response.isFirstBuyIKF;
		this.isFirstBuyMF = response.isFirstBuyMF;
		this.approvals = response.approvals == undefined ? [] : response.approvals;
		this.consentsColor =
			response.approvals == undefined ? CClientTermColour[EConsents.NONE] : this.consentColor(response.approvals, brand);
		this.isAcceptLoyalty = response.acceptLoyalty;
	}

	private identityDocumentLabel(idIdentityDocument: string) {
		if (idIdentityDocument === DOCUMENT_TYPE.DNI) return DOCUMENT_TYPE_LABEL[DOCUMENT_TYPE.DNI];
		if (idIdentityDocument === DOCUMENT_TYPE.CE) return DOCUMENT_TYPE_LABEL[DOCUMENT_TYPE.CE];
		if (idIdentityDocument === DOCUMENT_TYPE.PAS) return DOCUMENT_TYPE_LABEL[DOCUMENT_TYPE.PAS];
	}

	private mapToAddressItem(list: Array<AddressResponse>): Array<AddressItem> {
		let listMap = new Array<AddressItem>();
		list.map((item) => {
			let address = new AddressItem({
				id: item.idAddress,
				zoneId: 0,
				name: item.ownerName,
				district: "",
				street: item.addressDescription,
				number: item.number,
				apartment: "",
				city: "",
				country: "",
				state: "",
				latitude: item.latitude,
				longitude: item.longitude,
				mz: item.mz,
				lote: item.lote,
				notes: "",
				favorite: item.isPrincipal == "0" ? false : true,
				canContinue: true,
				programmedZone: true,
				viaId: 0,
				requireEdition: "",
				otherDirection: "",
				reference: item.referenceAddressDescription
			});
			listMap.push(address);
		});
		return listMap;
	}

	private consentColor(approvals: IApproval[], brand: string): string {
		const approvalBrand = approvals.filter((app) => app.brand === brand && app.channel === EnumChannelFindClient.CALL);
		const hasTYC = approvalBrand.some((app) => app.agreement === EnumAgreementFindClient.TYC);
		const hasDATO = approvalBrand.some((app) => app.agreement === EnumAgreementFindClient.DATO);
		const hasPRIV = approvalBrand.some((app) => app.agreement === EnumAgreementFindClient.PRIV);
		if (approvalBrand.length === 0) {
			return CClientTermColour[EConsents.NONE];
		}

		if (hasTYC && hasPRIV && hasDATO) {
			return CClientTermColour[EConsents.ALL];
		} else if (hasTYC || hasPRIV || hasDATO) {
			return CClientTermColour[EConsents.SOME];
		} else {
			return CClientTermColour[EConsents.NONE];
		}
	}
}

export class AddressResponse {
	idAddress: string;
	addressDescription: string;
	referenceAddressDescription: string;
	latitude: number;
	longitude: number;
	addressType: string;
	mz: string;
	lote: string;
	ownerName: string;
	isPrincipal: string;
	number: string;
	district?: string;
}
