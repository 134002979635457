import { SubCategory } from './sub-category.model';
import { Brand } from './brand.model';
import { ICategory } from './category.interface';

export class Category {

  public categoryId: number;
  public categoryName: string;
  public categoryKeyword: string;
  public subCategories: SubCategory[];
  public brands: Brand[];

  constructor(category: ICategory) {
    this.categoryId = category.id || -1;
    this.categoryName = category.name || '';
    this.categoryKeyword = category.keyword || '';
    this.subCategories = category.subCategories.length > 0 ? category.subCategories.map(subcat => new SubCategory(subcat)) : [];
    this.brands = category.brands.length > 0 ? category.brands.map(brand => new Brand(brand)) : [];
  }
}
