import { FormControl, Validators } from '@angular/forms';
import { DNI_ERROR_MESSAGES, IDENTITY_DOCUMENT_ERROR_MESSAGES } from '../parameters/global-error-message';
import { getErrorbyFormControl } from '../helpers/error-controls-helpers';

export class DocumentNumberControl extends FormControl {
  constructor() {
    super('');
    this.settingNameValidators();
  }

  private settingNameValidators() {
    this.setValidators([Validators.required]);
  }

  public get dniError(): string {
    return getErrorbyFormControl(this, DNI_ERROR_MESSAGES);
  }

  public get ducumentError(): string {
    return getErrorbyFormControl(this, IDENTITY_DOCUMENT_ERROR_MESSAGES);
  }
  
}
