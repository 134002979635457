import { Injectable } from '@angular/core';
import { PickUpStoreClientService } from 'src/app/shared/service/pickup-store/pickup-store-client.service';
import { IUserPickUpRequestParams } from 'src/app/shared/models/pickup-store/pickup-store.model';
import { map } from 'rxjs/operators';

@Injectable()
export class PickUpImplementService {

  constructor(
    private pickUpStoreClientService: PickUpStoreClientService,
  ) { }

  public registerUser$(params: IUserPickUpRequestParams) {
    return this.pickUpStoreClientService.registerUser(params)
      .pipe(map(response => {
        return response;
      }));
  }

  public updateUser$(id: number, params: IUserPickUpRequestParams) {
    return this.pickUpStoreClientService.updateUser(id, params);
  }

  public deleteUser$(id: number) {
    return this.pickUpStoreClientService.deleteUser(id);
  }

  public setDefaultUser$(id: number) {
    return this.pickUpStoreClientService.setDefaultUser(id);
  }

  public getPeopleForStorePickup$() {
    return this.pickUpStoreClientService.getUsers()
      .pipe(map(response => {
        const peopleForStorePickup = response.peopleForStorePickup;
        return peopleForStorePickup;
      }));
  }

}
