import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MenuComponent } from './menu.component';

const DECLARATIONS = [MenuComponent, MenuItemComponent];

@NgModule({
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
  imports: [MatMenuModule, CommonModule],
})
export class MenuModule {}
