import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GuardValidatorService {
  private isGuardEndpoint: Boolean;
  private isGuardEndpointSubject = new BehaviorSubject<Boolean>(false);
  public isGuardEndpoint$ = this.isGuardEndpointSubject.asObservable();

  constructor() {
    this.isGuardEndpoint$.subscribe(_isGuardEndpoint => this.isGuardEndpoint = _isGuardEndpoint);
  }

  public getIsGuardEndpoint() {
    return this.isGuardEndpoint;
  }

  public setIsGuardEndpoint(param: Boolean) {
    return this.isGuardEndpointSubject.next(param);
  }
}
