import { FormControl, Validators } from '@angular/forms';
import { CONFIG_USER_NOT_REGISTERED } from '../parameters/enums';
import { getErrorbyFormControl } from '../helpers/error-controls-helpers';
import { PHONE_NUMBER_ORDER_TAKER_ERROR_MESSAGES } from '../parameters/error-message-forms';

export class PhoneNumberOrderTakerControl extends FormControl {
  constructor() {
    super('');
    this.settingNameValidators();
  }

  private settingNameValidators() {
    this.setValidators([
      Validators.required,
      Validators.minLength(CONFIG_USER_NOT_REGISTERED.MIN_LENGHT_NUMBER_PHONE_ORDER_TAKER),
    ]);
  }

  public get phoneNumberError(): string {
    return getErrorbyFormControl(this, PHONE_NUMBER_ORDER_TAKER_ERROR_MESSAGES);
  }
}
