import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@environments/environment";
import { CoverageLocationResponse } from "@models/coverage-location/coverage-location.model";
import { EBrand, EBrandName } from "@parameters/access/brand.parameter";
import { LS_ALGOLIA_REDIRECTS } from "@service/local-storage/algolia/ls-algolia.constans";
import { LocalStorageService } from "@service/local-storage/local-storage.service";
import { CoverageLocationStoreService } from "@stores/coverage-location/coverage-location-store.service";
import { LOCALSTORAGE_SHOPPING_CART_LIST } from "@stores/shopping-list/constants/shopping-list.constant";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { ProductDetail } from "src/app/business/product-detail/models/product-detail-model";
import { PRODUCT_PRESCRIPTION } from "src/app/business/product-detail/parameters/product-detail.parameters";
import { ProductDetailImplementService } from "src/app/business/product-detail/services/product-detail-implement.service";
import { ModalsManagerService } from "src/app/core/modules/pick-up-in-store/pick-up-services/modals-manager.service";
import { BUSINESS_KEYS, BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { AnalyticServiceService } from "src/app/shared/service/analitycService/analitycService.service";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { ShoppingCartRequestService } from "src/app/shared/service/shopping-cart/shopping-cart-request.service";
import { ShowModalBoticaService } from "src/app/shared/service/showModalServices/showModalService.service";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { ShoppingListStoreService } from "src/app/shared/stores/shopping-list/shopping-list-store.service";
import { ProductShoppingCartRequest } from "../../../../../shared/models/shopping-cart/product-shopping-cart-request.model";
import { AlgoliaInstanceService } from "../../../custom-instantsearch/algolia-instance.service";
import { ProductCard } from "../../models/product-card/product-card.model";
import { ProductPresentation } from "../../models/product-presentation.model";
import { EProductStatus } from "../../models/product-status.enum";
import { ProductImplementService } from "../../service/product-implement.service";
import { ProductSalesforceService } from "../../service/product-salesforce.service";
import { productRADHaveStock, productRETHaveStock } from "../../service/product-status.helper";
import { IAlgoliaObjectData } from "../../../custom-instantsearch/interfaces/algolia-searcher.interface";

@Component({
	selector: "fp-product-small",
	templateUrl: "./product-small.component.html",
	styleUrls: ["./product-small.component.sass"]
})
export class ProductSmallComponent implements OnInit, OnDestroy {
	public brand = EBrand;
	public isInkaBrand = EBrand[environment.brand] == EBrand.inkafarma;
	public isMifaBrand = EBrand[environment.brand] == EBrand.mifarma;
	brandC = EBrandName[environment.brand];

	@Input() product: ProductCard;

	public eProductStatus = EProductStatus;
	public productStatus: number = EProductStatus.available;
	public productPresentation: ProductPresentation;
	public productPrescription = PRODUCT_PRESCRIPTION;
	public productPack: boolean;

	public productQuantity = 1;
	public productPresentationId: number;
	public productInShoppingCart: boolean;
	private productInShoppingCartSubscription: Subscription;

	public productPath = BUSINESS_PATHS.PRODUCT;
	isShowSeller = false;
	brandCurrent = "";
	isProductSeller: boolean;
	public coverage: CoverageLocationResponse;
	private coverageSubscription: Subscription;
	companyCodeIkf = "IKF";
	get typeHaveStock() {
		return this.retValidateCartService.isRADProcess ? this.radHaveStock : this.retHaveStock;
	}

	get radHaveStock() {
		return productRADHaveStock(this.product, true, this.coverage);
	}

	get retHaveStock() {
		return productRETHaveStock(this.product, false);
	}

	constructor(
		private _productImplement: ProductImplementService,
		private _productSalesforce: ProductSalesforceService,
		// ---- servicios para snackbar o modal de producto agregado o rechazado (marketplace) -------
		private _modalManagerService: ModalsManagerService,
		private _shoppingListStore: ShoppingListStoreService,
		private _shoppingCartRequest: ShoppingCartRequestService,
		private serviceAnalytic: AnalyticServiceService,
		private showModalBoticaService: ShowModalBoticaService,
		private productDetailImplement: ProductDetailImplementService,
		private _shoppingListStoreService: ShoppingListStoreService,
		private _appStore: AppStoreService, // -----------------------
		private retValidateCartService: RetValidateCartService,
		private algoliaInstanceService: AlgoliaInstanceService,
		private coverageLocationStore: CoverageLocationStoreService,
		private localStorageService: LocalStorageService,
		private router: Router
	) {
		this.coverageSubscription = this.coverageLocationStore.coverageLocationResponse$.subscribe((res) => {
			this.coverage = res;
		});
	}

	ngOnInit() {
		this.loadRequestShoppingCart();
		this.getProductPresentation(this.product.defaultPresentation);
		this.getProductShoppingCart();
		this.getProductStatus();

		this.brandCurrent = this._appStore.brandAtention;
		this.isShowSeller = this.brandCurrent === EBrand.inkafarma;
		this.isProductSeller = this.product.isFromSeller;
	}

	getProductShoppingCart() {
		let productUpdate = true;
		this.productInShoppingCartSubscription = this._productImplement
			.getProductShoppingCart$(this.product.id)
			.subscribe((value: ProductShoppingCartRequest) => {
				this.productInShoppingCart = !!value;
				this.productPresentationId = this.productInShoppingCart ? value.presentationIdSelected : this.product.defaultPresentation;
				this.productQuantity = this.productInShoppingCart ? value.quantity : 1;
				this.productPack = value?.productPack;

				this.getProductPresentation(this.productPresentationId);
				this.getProductStatus();

				if (this.productPresentation.id !== this.productPresentationId && productUpdate) {
					productUpdate = false;
					this.productQuantity = 1;
					this.updateProductToShoppingCart();
				}
				if (this.productInShoppingCart) {
					this.validateProductQuantity(this.product.id);
				}
			});
	}

	getProductStatus() {
		this.productStatus = this.product.statusId;
	}

	getProductPresentation(presentationId: number) {
		this.productPresentation =
			this.product.presentations.find((presentation) => presentation.id === presentationId) ||
			this.product.presentations.find((presentation) => presentation.id === this.product.defaultPresentation);
		this.productPresentationId = this.productPresentation.id;
	}

	updateQuantity(quantity: number) {
		if (quantity > this.productQuantity) {
			TaggingService.productClick(this.product);
			TaggingService.detail(this.product);
			// TaggingService.addToCartSDK(this.product,  this.productPresentation,quantity)
			TaggingService.addToCart(this.product, this.productPresentation);
			this.productQuantity = quantity;
			this.callEventAnalyticsAlgolia();
		} else {
			TaggingService.removeFromCart(this.product, this.product.presentations[0].price, this.product.presentations[0].presentation);
			TaggingService.removeFromCartSDK(this.product, this.product.presentations[0].price, quantity);
			this.productQuantity = quantity;
		}

		this.retValidateCartService.processValidateAddToCart.next(true);
		this.updateProductToShoppingCart();
	}

	addProductToShoppingCart() {
		this._productImplement.addProduct(
			this.product.id,
			this.productQuantity,
			this.productPresentation.id,
			this.product.sellerId,
			this.product.sellerName
		);
		this._productSalesforce.addProduct(
			this.product.id,
			this.product.id,
			this.productQuantity,
			this.product.presentations[0].price * this.productQuantity
		);
		this.retValidateCartService.processValidateAddToCart.next(true);
		TaggingService.productClick(this.product);
		TaggingService.detail(this.product);
		TaggingService.addToCart(this.product, this.productPresentation);
		TaggingService.addToCartSDK(this.product, this.productPresentation, this.productQuantity);

		this.callEventAnalyticsAlgolia();
		// this.algoliaInstanceService.algoliaClickedObjectIDs(this.product.id);
		// this.algoliaInstanceService.addConvertedObjectID(this.product.id);
	}

	updateProductToShoppingCart() {
		this._productImplement.updateProduct(
			this.product.id,
			this.productQuantity,
			this.productPresentation.id,
			this.product.sellerId,
			this.product.sellerName
		);

		TaggingService.updateCartSDK(this.product, this.productPresentation.price, this.productQuantity);
	}

	ngOnDestroy(): void {
		this.productInShoppingCartSubscription.unsubscribe();
		this.coverageSubscription.unsubscribe();
	}

	addToFavorite() {
		this._productImplement.addToFavoriteProduct(this.product.id).subscribe(() => (this.product.favorite = true));
	}

	removeFromFavorite() {
		this._productImplement.removeFromFavoriteProduct(this.product.id).subscribe(() => (this.product.favorite = false));
	}

	showModalBotica(product) {
		this.showModalBoticaService.showModalResponsiveService(product);
		this.serviceAnalytic.registerEventAnalytics(
			this.serviceAnalytic.detectModuleTag("consultar_stock_en_botica"),
			"Boton :: consultar stock en botica",
			product.id
		);
	}

	loadRequestShoppingCart() {
		const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList();
		this._shoppingCartRequest.shoppingProductListRequest = shoppingCartListLocal;
	}

	validateProductQuantity(id: string) {
		this.productDetailImplement
			.getProductDetail(id)
			.pipe(take(1))
			.subscribe((product: [ProductDetail, ProductCard]) => {
				const maxQuantity = product[0].presentations[0].maxUnitSale;
				if (this.productQuantity > maxQuantity && maxQuantity != 0) {
					this.productQuantity = maxQuantity;
					this.updateShoppingCart(id, this.productQuantity);
				}
			});
	}

	updateShoppingCart(id: string, quantity: number) {
		const shoppingLocalList = this._appStore.getStorageItem(LOCALSTORAGE_SHOPPING_CART_LIST);
		const parsedObjectShoppingList = JSON.parse(shoppingLocalList);
		parsedObjectShoppingList.filter((res, i) => {
			if (id === res.productId) {
				parsedObjectShoppingList[i].quantity = quantity;
			}
		});
		this._appStore.setStorageItem("shoppingCartList", JSON.stringify(parsedObjectShoppingList));
	}

	registerEventAnalyticsAlgoliaAndValidateRedirect() {
		//Redirect to detail product
		const isRedirectoToSearchAlgolia = this.validateRedirectByAlgolia();
		if (isRedirectoToSearchAlgolia) {
			this.localStorageService.setItem(LS_ALGOLIA_REDIRECTS, true);
		}

		this.algoliaInstanceService.algoliaClickedObjectIDs(this.product.id);
	}

	callEventAnalyticsAlgolia() {
		const isRedirectoToSearchAlgolia = this.validateRedirectByAlgolia();

		isRedirectoToSearchAlgolia ? this.algoliaInstanceService.convertedAfterSearch([this.product.id]) : null;

		if (isRedirectoToSearchAlgolia) {
			const discount =
				this.productPresentation.regularPrice != 0 ? this.productPresentation.price - this.productPresentation.regularPrice : 0;
			const objectData: IAlgoliaObjectData = {
				price: this.productPresentation.price,
				discount: Number(discount.toFixed(2)),
				quantity: this.productQuantity
			};

			this.algoliaInstanceService.addedToCartObjectIDsAfterSearch([this.product.id], [objectData]);
		}

		this.algoliaInstanceService.addConvertedObjectID(
			this.product,
			this.productPresentation,
			this.productQuantity,
			isRedirectoToSearchAlgolia
		);
	}

	validateRedirectByAlgolia(): boolean {
		const urlSearch = `${BUSINESS_PATHS.SEARCH}?${BUSINESS_KEYS.SEARCH_QUERY_PARAM_KEY}`;
		const isUrlSearch = this.router.url.includes(urlSearch);
		const isUrlDetailProduct = this.router.url.includes(`${BUSINESS_PATHS.PRODUCT}/`);
		const isRedirectoToSearchAlgolia = this.localStorageService.getItem(LS_ALGOLIA_REDIRECTS) as boolean;

		return isUrlSearch || (isUrlDetailProduct && isRedirectoToSearchAlgolia);
	}

	getTextSellerINKAorMIFA() {
		return this._appStore.companyCode === this.companyCodeIkf ? EBrandName[EBrand.inkafarma] : EBrandName[EBrand.mifarma];
	}

	getBrandIcon(isFromSeller) {
		if (isFromSeller) {
			return "shopping-cart";
		} else {
			return environment.brand === EBrand.inkafarma ? "tag-ikf" : "tag-mf";
		}
	}
	get textTagSeller() {
		return this.product.isFromSeller ? this.product.sellerName : this.isMifaBrand ? EBrandName["mifarma"] : EBrandName["inkafarma"];
	}

	get iconBrand() {
		return this.product.isFromSeller ? "shopping-cart" : this.isMifaBrand ? "tag-mf" : "tag-ikf";
	}

	get showPrescription() {
		return (
			this.product.prescription.toUpperCase() === this.productPrescription.PRESCRIPTION.toUpperCase() ||
			this.product.prescriptionCode
		);
	}

	get shortTagBrand() {
		return this.showPrescription || this.product.isGeneric;
	}
	get shortTagPrescription() {
		// return this.product.isGeneric;
		return this.product.isGeneric;
	}
	get iconPrescription() {
		return this.product.prescriptionCode ? "paper-background" : "prescription";
	}
}
