<fp-button
  [buttonId]="buttonId"
  buttonClass="btn-black
  btn-spacer-{{buttonSize}}
  label-black{{buttonSize === 'small' ? '--1': buttonSize === 'large' ? '-1' : ''}}
  btn-{{buttonDisplay}}
  {{buttonDisabled ? 'btn-disabled': ''}}"
  [buttonType]="buttonType"
  [buttonDisabled]="buttonDisabled">
  <ng-content></ng-content>
</fp-button>
