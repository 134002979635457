import {Injectable} from '@angular/core';
import {StaticLandingDialogImplementService} from '../../../services/static-landing-dialog-implement.service';
import { TreatmentForAdditionalPurposesDialogComponent } from '../treatment-for-additional-purposes-dialog/treatment-for-additional-purposes-dialog.component';

@Injectable()
export class TreatmentForAdditionalPurposesDialogImplementService {
  constructor(
    private _staticLandingDialogImplement: StaticLandingDialogImplementService
  ) {
  }

  openTreatmentFroAdditionalPurposes() {
    this._staticLandingDialogImplement
      .openStaticLandingDialog(TreatmentForAdditionalPurposesDialogComponent);
  }

}
