<div class="content-modal px-4 py-4">
  <div class="content-massage">
    <div class="mx-2 my-3 icon-alert">
      <fp-icon iconSize="36" iconName="sad-sweat-face-colored"></fp-icon>
    </div>
    <div class="warning-message label-black-2 mx-2 mt-3 mb-4">
      Oops, algo salió mal
    </div>
    <div class="warning-message paragraph-2 mx-2 mt-3 mb-2">
      Ocurrió un error en nuestro sistema mientras estábamos creando tu cuenta. Por favor, vuelve a intentarlo nuevamente.
    </div>
    <div class="mt-4 mx-2 mb-2">
      <fp-button-primary
        (click)="closeModal()"
        buttonDisplay="block">
        Entendido
      </fp-button-primary>
    </div>
  </div>
</div>

