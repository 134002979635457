import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IResponseNotification, ISkuTalonOneList } from '@models/user/user';
import { EBrandCode } from '@parameters/access/brand.parameter';
import { TokenStoreService } from '@stores/user/token-store.service';
import { OrderTakerValidator } from '@validators/order-taker.validator';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '../../parameters/endpoints';
import { GenericService } from '../generic';

@Injectable()
export class ProductClientService {

  constructor(private _GenericService: GenericService,
              private _tokenStoreService: TokenStoreService) {
  }

  public postRemoveFavorite(id: any): Observable<any> {
    const body = { id: id };
    return this._GenericService.genericPost<any>(
      ENDPOINTS.POST_REMOVE_FAVORITE,
      body
    );
  }

  public postAddFavorite(id: any): Observable<any> {
    const body = { id: id };
    return this._GenericService.genericPost<any>(
      ENDPOINTS.POST_ADD_FAVORITE,
      body
    );
  }

  validateAddToCart(body: any, stock?: string, code?: string) {
    const url = OrderTakerValidator.isOrderTakerEnv() ?
                ENDPOINTS.APIGEE_PATH_VALIDATE_ADD_TO_CART_TOMADOR :
                ENDPOINTS.APIGEE_PATH_VALIDATE_ADD_TO_CART;
    return this._GenericService.genericPost(url, body);
  }
  public callServicesNotification(request:ISkuTalonOneList) : Observable<IResponseNotification[]> {
    const body = request;
    return this._GenericService.genericPostNotification<IResponseNotification[]>(ENDPOINTS.POST_NOTIFICATION, body);
  }

}
