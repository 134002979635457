<div class="main-login-modal modal-login-container" id="core-main-login-modal">
  <fp-section-container-title-login
    [title]="'Iniciar sesion'"
    [iconName]="'close'"
    (onclick)="closeModal()"
  ></fp-section-container-title-login>
  <fp-sub-section-container>
    <div class="content" [formGroup]="registerForm.form" *ngIf="isMifaBrand">
      <div class="divider-text mt-4">
        <span>Ingresa tu correo para continuar</span>
      </div>
      <div class="mb-4">
        <fp-input-control
          [errorMessage]="registerForm.emailControl.emailAddressError"
          [marginWithError]="false"
          [errorMsgJoinToInput]="true"
        >
          <input
            type="text"
            id="ctrl--register-email"
            class="w-100 input"
            formControlName="email"
            #inputControl
            placeholder="Correo electrónico"
          />
        </fp-input-control>
      </div>
      <div class="mb-2">
        <fp-button-primary
          buttonId="btn--register-email"
          buttonDisplay="block"
          [buttonDisabled]="!registerForm.form.valid"
          (click)="openEmailWithPassword()"
        >
          Ingresar
        </fp-button-primary>
      </div>
      <div class="divider-text mt-4">
        <span>Otras opciones para ingresar</span>
      </div>
    </div>
    <div class="content modal-login mt-4">
      <div class="mb-3">
        <fp-button-brand
          brandId="btn--login-facebook"
          brandIcon="facebook"
          brandWidth="full"
          (onclick)="facebookLogin()"
        >
          Ingresa con Facebook
        </fp-button-brand>
      </div>
      <div class="mb-3">
        <fp-button-brand
          brandId="btn--login-google"
          brandIcon="google"
          brandWidth="full"
          (onclick)="googleLogin()"
        >
          Ingresa con Google
        </fp-button-brand>
      </div>
      <div [ngClass]="{ 'mb-3': !showEmailAndPassword }">
        <fp-button-brand
          brandId="btn--login-apple"
          brandIcon="apple"
          brandWidth="full"
          (onclick)="appleLogin()"
        >
          Ingresa con Apple
        </fp-button-brand>
      </div>
      <div *ngIf="!isMifaBrand" [hidden]="showEmailAndRegister">
        <div
          class="mb-4 btn-EmailWithPassword"
          [ngClass]="{ dn: showEmailAndPassword }"
        >
          <fp-button-outline
            buttonClass="btn--login-email-password"
            buttonDisplay="block"
            [buttonStyle]="{ 'border-radius': isMifaBrand ? '12px' : '32px' }"
            (click)="openRegisterEmail()"
          >
            Ingresa con correo electrónico
          </fp-button-outline>
        </div>
        <div class="text-center mb-3" *ngIf="!showEmailAndPassword">
          <div class="mb-4">
            <fp-link-buttom
              [buttonText]="'¿Olvidaste tu contraseña?'"
              [id]="'link--open-forgot-password-modal'"
              (onclick)="forgotPassword()"
            >
            </fp-link-buttom>
          </div>
          <div class="register">
            <p>
              ¿Aún no tienes cuenta?
              <fp-link-buttom
                [buttonText]="'Regístrate'"
                [id]="'link--open-register-modal'"
                (onclick)="registerModal()"
              >
              </fp-link-buttom>
            </p>
          </div>
        </div>
      </div>
    </div>
  </fp-sub-section-container>

  <div
    class="mt-4 mb-4 login-with-email"
    [ngClass]="{
      show: showEmailAndPassword,
      showWithError: false
    }"
    *ngIf="!isMifaBrand && showEmailAndPassword"
  >
    <fp-sub-section-container>
      <div class="content" [formGroup]="mainLoginForm.form">
        <div class="mt-4 mb-2">ó Ingresa con tu correo y contraseña</div>
        <fp-input-without-title-control
          [errorMessage]="mainLoginForm.emailControl.emailAddressError"
        >
          <input
            id="ctrl--login-email"
            formControlName="email"
            class="w-100 input"
            #inputControl
            placeholder="Correo electrónico"
            maxlength="50"
          />
        </fp-input-without-title-control>
        <fp-input-password>
          <input
            id="ctrl--login-password"
            formControlName="password"
            class="w-100 input"
            #typeControl
            placeholder="Contraseña"
            (keyup.enter)="simpleLogin()"
          />
        </fp-input-password>
        <div class="mb-4 mt-4">
          <fp-button-primary
            buttonId="btn--login-email"
            buttonDisplay="block"
            (click)="simpleLogin()"
            [buttonDisabled]="!mainLoginForm.form.valid || isLoggingIn"
          >
            Ingresa a tu cuenta
          </fp-button-primary>
        </div>
      </div>
    </fp-sub-section-container>
  </div>

  <div
    class="mt-4 mb-2"
    [ngClass]="{
      show: showEmailAndRegister,
      showWithError: false
    }"
    *ngIf="!isMifaBrand && showEmailAndRegister"
  >
    <fp-sub-section-container>
      <div class="content" [formGroup]="registerForm.form">
        <div class="mt-4 mb-2">ó Crea una cuenta con tu correo</div>
        <div class="mb-4">
          <fp-input-without-title-control
            [errorMessage]="registerForm.emailControl.emailAddressError"
            [marginWithError]="false"
          >
            <input
              id="ctrl--register-email"
              formControlName="email"
              class="w-100 input"
              placeholder="Correo electrónico"
            />
          </fp-input-without-title-control>
        </div>
        <div class="mb-2">
          <fp-button-primary
            buttonId="btn--register-email"
            buttonDisplay="block"
            [buttonDisabled]="!registerForm.form.valid"
            (click)="openEmailWithPassword()"
          >
            Registrarme
          </fp-button-primary>
        </div>
        <div class="d-flex align-items-center justify-content-center py-3">
          <div class="label--1 mr-3">¿Ya tienes cuenta?</div>
          <fp-link-buttom
            [buttonText]="'Iniciar sesión'"
            [id]="'link--open-login-modal'"
            [labelClass]="'label-black--1'"
            (click)="logIn()"
          >
          </fp-link-buttom>
        </div>
      </div>
    </fp-sub-section-container>
  </div>
</div>
