import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "@environments/environment";
import { RegisterResponse } from "@models/register/register.model";
import { EmailVerificationRequest } from "@models/register/user-register-request.model";
import { EBrand } from "@parameters/access/brand.parameter";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { AuthImplementService } from "src/app/shared/implements/auth/auth-implement.service";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { RegisterInfoTypeStoreService } from "src/app/shared/stores/user/register-info-type-store.service";
import { UserInfoStoreService } from "src/app/shared/stores/user/user-info-store.service";
import { RegisterFormService } from "../../../register/register-forms/modal-register-form.service";
import { RegisterImplementService } from "../../../register/register-services/register-implement.service";
import { RegisterModalsManagerHelperService } from "../../../register/register-services/register-modals-manager-helper.service";
import { RegisterModalsMaganerService } from "../../../register/register-services/register-modals-manager.service";
import { RegistrationValidationService } from "../../../register/register-services/registration-validation.service";
import { MainRegisterStoreService } from "../../../register/register-stores/main-register-store.service";
import { SnackbarAlertService } from "../../../snackbar/snackbar-alert.service";
import { MainLoginFormService } from "../../login-forms/main-login-form.service";
import { ForgotPasswordModalService } from "../../login-services/forgot-password-modal-service.service";
import { LoginModalsManagerHelperService } from "../../login-services/login-modals-manager-helper.service";
import {
  AUTH_ORIGIN,
  AUTH_PROVIDER,
  COMPANY_ID,
  NAVIGATION_URL,
  USER_REGISTRATION_ERROR_CODE,
  USER_SOURCE_LOGIN,
} from "./../../../../../shared/parameters/global";

@Component({
  selector: "fp-main-login-modal",
  templateUrl: "./main-login-modal.component.html",
  styleUrls: ["./main-login-modal.component.sass"],
})
export class MainLoginModalComponent implements OnInit, OnDestroy {
  public isMifaBrand: boolean = EBrand[environment.brand] == EBrand.mifarma;
  public showEmailAndPassword = false;
  private message =
    "El correo o contraseña que ingresaste es inválido. Intenta nuevamente o recupera tu contraseña.";

  public isLoggingIn = false;
  public registrationErrorCode = USER_REGISTRATION_ERROR_CODE;

  constructor(
    public _matDialogRef: MatDialogRef<MainLoginModalComponent>,
    public authImplementService: AuthImplementService,
    public mainLoginForm: MainLoginFormService,
    public userInfoStore: UserInfoStoreService,
    private mainRegisterStore: MainRegisterStoreService,
    public forgotPasswordModalService: ForgotPasswordModalService,
    private snackBarAlert: SnackbarAlertService,
    private _appStoreService: AppStoreService,
    private registrationValidation: RegistrationValidationService,
    public registerModalsMaganer: RegisterModalsMaganerService,
    private registerModalsManagerHelper: RegisterModalsManagerHelperService,
    private loginModalsManagerHelper: LoginModalsManagerHelperService,
    private registerInfoTypeStore: RegisterInfoTypeStoreService,
    public registerForm: RegisterFormService,
    private registerImplement: RegisterImplementService
  ) {}

  ngOnInit() {
    this.isLoggingIn = false;
    this._appStoreService.sourceLogin = USER_SOURCE_LOGIN.MAIN_LOGIN;
    this._appStoreService.sourceNavigationLogin =
      this._appStoreService.getStorageItem(NAVIGATION_URL);
  }

  ngOnDestroy() {}

  simpleLogin() {
    this.isLoggingIn = true;
    const email = this.mainLoginForm.emailControl.value;
    const password = this.mainLoginForm.passwordControl.value;
    this.authImplementService
      .gmailLogin(email, password)
      .then(() => {
        this.registerInfoTypeStore.setEmailAndPasswordAuthenticationOrigin();
        this.validateUserRegister();
        TaggingService.loginOK("simple login");
      })
      .catch((error) => {
        this.isLoggingIn = false;
        this.snackBarAlert.snackbarAlertError(this.message);
      });
  }

  facebookLogin() {
    TaggingService.loginModal("facebook");
    this.authImplementService.facebookLogin().then((response) => {
      // response apply only for desktop
      if (response) {
        this.registerInfoTypeStore.setProviderAuthenticationOrigin();
        this.settingStorageAuthProvider(response);
        this.validateUserRegister();
        TaggingService.loginOK("facebook");
      }
    });
  }

  googleLogin() {
    TaggingService.loginModal("google");
    this.authImplementService.googleLogin().then((response) => {
      // response apply only for desktop
      if (response) {
        this.registerInfoTypeStore.setProviderAuthenticationOrigin();
        this.settingStorageAuthProvider(response);
        this.validateUserRegister();
        TaggingService.loginOK("google");
      }
    });
  }

  appleLogin() {
    TaggingService.loginModal("apple");
    this.authImplementService.appleLogin().then((response) => {
      // response apply only for desktop
      if (response) {
        this.registerInfoTypeStore.setProviderAuthenticationOrigin();
        this.settingStorageAuthProvider(response);
        this.validateUserRegister();
        TaggingService.loginOK("apple");
      }
    });
  }

  settingStorageAuthProvider(response) {
    this._appStoreService.setStorageItem(
      AUTH_PROVIDER,
      response.credential.providerId
    );
  }

  validateUserRegister(addParams: Array<object> = []) {
    this.loginModalsManagerHelper.closeMainLoginModal();
    const subscribe = this.registrationValidation
      .validateUserRegisterOnDB()
      .subscribe((path) => {
        !path ? (path = window.location.pathname) : path;
        if (typeof path === "string" && path.length) {
          this.isLoggingIn = false;
          this._appStoreService.removeSourceLogin();
          this.registrationValidation.handleNavigationMainLoin(path);
        }
        subscribe.unsubscribe();
      });
  }

  openEmailWithPassword() {
    TaggingService.registerModal("crea una cuenta con tu correo");
    this.mainRegisterStore.email = this.registerForm.emailControl.value;
    const validateEmailRequest = new EmailVerificationRequest();
    (validateEmailRequest.email = this.registerForm.emailControl.value),
      (validateEmailRequest.companyId = COMPANY_ID);
    validateEmailRequest.origin = AUTH_ORIGIN.EMAIL_PASSWORD;
    validateEmailRequest.source = "WEB";
    this.registerInfoTypeStore.setEmailAndPasswordAuthenticationOrigin();
    this.registerImplement
      .saveOrUpdateUser(validateEmailRequest)
      .subscribe((response: RegisterResponse) => {
        if (response.code === this.registrationErrorCode.ERROR_EMAIL_USED) {
          this.openAlert();
        } else {
          TaggingService.registerOK("simple register");
          this.showEmailAndPassword = true;
          this.registerModalsManagerHelper.openNewPasswordModal();
          this.closeModal();
        }
      });
  }

  closeModal() {
    this.loginModalsManagerHelper.closeMainLoginModal();
    TaggingService.loginModalCerrar();
  }

  forgotPassword() {
    TaggingService.loginModal("¿olvidaste tu contraseña?");
    this.loginModalsManagerHelper.closeMainLoginModal();
    this.loginModalsManagerHelper.openForgotPasswordModal();
  }

  registerModal() {
    TaggingService.loginModal("regístrate");
    this.registerModalsManagerHelper.openRegisterModal();
    this.loginModalsManagerHelper.closeMainLoginModal();
  }

  openAlert() {
    this.snackBarAlert
      .snackbarAlertInfo(`El correo que ingresaste ya se encuentra registrado.
    Inicia sesión o da click en "Olvidaste tu contraseña" para recuperar tu cuenta`);
  }

  openAlertError() {
    this.snackBarAlert.snackBarAlert(
      `El correo que ingresaste es invalido. Por favor
    verifique que este escrito correctamente.`,
      "error"
    );
  }
}
