<div class="side-nav-content" *ngIf="!isLoadingRespModal">

  <!-- header del sidenav -->
  <div class="sidenav-header d-flex align-items-center">
    <fp-section-container-title-v2
      title="Selecciona una botica"
      [svgName]="brand == 'Inkafarma'
        ? 'store-for-pick-up-green'
        : 'store-for-pick-up-orange'"
      iconSize="32">
    </fp-section-container-title-v2>

    <div class="controls-close-icon d-flex justify-content-center align-items-center"
      (click)="closeButtonEvent()">
      <fp-icon iconName="close-outline" [iconSize]="24"></fp-icon>
    </div>
  </div>

  <fp-sub-section-container-v2>

    <!-- body del sidenav -->
  <div class="sidenav-body">
    <div class="input-search-container">
      <fp-input-search-for-ret
        (searchDone)="setModalStatus($event)"
        [isResponsive]="true"
        [isDisabledInputSearch]="isDisableChooseStore">
      </fp-input-search-for-ret>
    </div>
    <div class="sidenav-body-content">
      <div class="drugstore-list-container">
        <div class="input-search-message paragraph--3 text-dark-gray text-center pl-3 pr-3">
          {{ config.suggestedMessageForPredictiveAddresses }}
        </div>
        <div class="drugstore-list-content">
          <fp-drugstore-list-v2
            [isResponsive]="true"
            [status]="modalStatus"
            [isDisableChooseStore]="isDisableChooseStore">
          </fp-drugstore-list-v2>
        </div>
      </div>
    </div>
  </div>
  </fp-sub-section-container-v2>

  <!-- footer del sidenav -->
  <div class="sidenav-footer container">
    <div class="sidenav-footer-content">
      <div class="row" [hidden]="modalStatus === 'no_one' || modalStatus === ALL_MODAL_STATUS.IS_PENDING  || modalStatus === ALL_MODAL_STATUS.EMPTY  || modalStatus === ALL_MODAL_STATUS.LOADING">
        <div class="col-6 label-black--1 text-secondary d-flex justify-content-center align-items-center"
          (click)="closeButtonEvent()">
          Cancelar
        </div>
        <div class="col-6">
          <fp-button-primary
            buttonDisplay="block"
            [buttonDisabled]="isDisableChooseStore || drugstores.length === 0"
            (click)="saveChanges()">
            Elegir botica
          </fp-button-primary>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="side-nav-keleton content my-5" *ngIf="isLoadingRespModal">
  <div class="row">
    <div class="col-12">
      <fp-basic-skeleton height="50"></fp-basic-skeleton>
    </div>
    <div class="offset-1 col-10 mt-2">
      <fp-basic-skeleton height="20"></fp-basic-skeleton>
    </div>
  </div>
  <ng-container *ngFor="let item of [0, 1]" class="store-address">
    <div class="row mt-5">
      <div class="col-8">
        <fp-basic-skeleton height="20"></fp-basic-skeleton>
      </div>
      <div class="offset-2 col-2">
        <fp-basic-skeleton height="25"></fp-basic-skeleton>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-9">
        <fp-basic-skeleton height="20"></fp-basic-skeleton>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-7">
        <fp-basic-skeleton height="25"></fp-basic-skeleton>
      </div>
    </div>
  </ng-container>
</div>
