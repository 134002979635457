import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { GLOBAL } from '@parameters/global';

@Directive({
  selector: 'img[fpOptimizeImage]'
})

export class OptimizeImageDirective implements OnChanges {
  private supportWebp = false;

  @Input() src: string;
  @Input() errorImage = './assets/images/product/error-image.svg';
  @Input() loadImage = './assets/images/product/load-image.svg';

  @Input() extension: boolean;
  @Input() extensionType = GLOBAL.IMAGE_EXTENSION.webp;

  constructor(
    private _Renderer: Renderer2,
    private _ElementRef: ElementRef) {
    this.setAttributeSRC(this.loadImage);
  }

  ngOnChanges(changes) {
    if (changes.src && changes.src.currentValue && changes.src.currentValue !== this.errorImage) {
      this.applyExtension(changes.src.currentValue);
    }
  }

  applyExtension(defaultImage) {
    if (this.extension && this.supportWebp || this.extension && this.extensionType !== GLOBAL.IMAGE_EXTENSION.webp) {
      const extensionImage = this.changeExtension(defaultImage);

      this.validateImage(extensionImage)
        .then(() => this.setAttributeSRC(extensionImage))
        .catch(() => this.validateDefaultImage(extensionImage, defaultImage));

    } else {
      this.setDefaultImage(defaultImage);
    }
  }

  validateDefaultImage(extensionImage, defaultImage) {
    if (this.extension && extensionImage.indexOf(this.extensionType) !== -1) {
      this.setDefaultImage(defaultImage);
    } else {
      this.setAttributeSRC(this.errorImage, true);
    }
  }

  setDefaultImage(defaultImage) {
    this.validateImage(defaultImage)
      .then(() => this.setAttributeSRC(defaultImage))
      .catch(() => this.setAttributeSRC(this.errorImage, true));
  }

  setAttributeSRC(value, loadAndError?: boolean) {
    this._Renderer.setAttribute(this._ElementRef.nativeElement, 'src', value);
    if (loadAndError) {
      this._Renderer.addClass(this._ElementRef.nativeElement, 'w-100');
    } else {
      this._Renderer.removeClass(this._ElementRef.nativeElement, 'w-100');
    }
  }

  changeExtension(value) {
    const index = value.lastIndexOf('.');
    const imageName = value.slice(0, index);
    return imageName + '.' + this.extensionType;
  }

  validateImage(value) {
    return new Promise<void>((resolve, reject) => {
      const image = new Image();
      image.src = value;
      image.onload = () => resolve();
      image.onerror = () => reject();
    });
  }

}
