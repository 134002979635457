import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { DeliveryInfoResponse, IDeliveryInfoRequestParams } from "src/app/shared/models/delivery-info/delivery-info.model";
import { DeliveryInfoClientService } from "src/app/shared/service/delivery-info/delivery-info-client.service";
import { ShoppingCartLoaderService } from "src/app/shared/service/shopping-cart/shopping-cart-loader.service";

export enum EDeliveryTypes {
  AM_PM = "AM_PM",
  PROGRAMADO = "PROG",
  EXPRESS = "EXP",
}

@Injectable()
export class DeliveryInfoImplementService {
  private deliveryInfo = new BehaviorSubject<DeliveryInfoResponse>(null)

  get currentDeliveryInfoWithoutParams() {
    return this.deliveryInfo.getValue();
  }

  set currentDeliveryInfoWithoutParams(deliveryInfo: DeliveryInfoResponse) {
    this.deliveryInfo.next(deliveryInfo);
  }

  constructor(
    private deliveryInfoClient: DeliveryInfoClientService,
    private _loaderService: ShoppingCartLoaderService
  ) {}

  public getDeliveryInfoForRET$(drugstoreId: string, deliveryTypeId: string) {
    const params = {
      deliveryTypeId,
      drugstoreId,
    } as IDeliveryInfoRequestParams;
    this._loaderService.isLoadedCart.next(false);
    return this.deliveryInfoClient.getDeliveryInfoWithParams$(params).pipe(
      tap((data: any) => {
        this._loaderService.isLoadedCart.next(true);
      })
    );
  }

  public getDeliveryInfoForRAD$(deliveryTypeId?: string, onInit?: boolean) {
    this._loaderService.isLoadedCart.next(false);
    if (deliveryTypeId) {
      const params = {
        deliveryTypeId,
        drugstoreId: "0",
      } as IDeliveryInfoRequestParams;
      return this.deliveryInfoClient.getDeliveryInfoWithParams$(params).pipe(
        tap((data: any) => {
          this._loaderService.isLoadedCart.next(true);
        })
      );
    } else {
      return this.getDeliveryInfoWithoutParams$(onInit).pipe(
        tap((data: any) => {
          this._loaderService.isLoadedCart.next(true);
        })
      );
    }
  }

  public getDeliveryInfoForRETOrderTaker$(drugstoreId: string, deliveryTypeId: string) {
    const params = {
      deliveryTypeId,
      drugstoreId,
    } as IDeliveryInfoRequestParams;
    return this.deliveryInfoClient.getDeliveryInfoWithParams$(params);
  }

  public getDeliveryInfoWithoutParams$(onInit?: boolean) {
    return onInit && this.currentDeliveryInfoWithoutParams ? this.deliveryInfo.pipe() : this.deliveryInfoClient.getDeliveryInfo$();
  }

  public getDeliveryAddressWithoutParams$(onInit?: boolean) {
    return this.getDeliveryInfoWithoutParams$(onInit).pipe(
      map((deliveryInfoResponse) => {
        const addresses = deliveryInfoResponse.address;
        if (addresses.length === 1 && !addresses[0].canContinue) {
          return [];
        } else {
          return addresses;
        }
      })
    );
  }

  public getDeliveryInfoForServiceType$(deliveryType: EDeliveryTypes) {
    const params = {
      deliveryTypeId: deliveryType,
      drugstoreId: "0",
    } as IDeliveryInfoRequestParams;
    this._loaderService.isLoadedCart.next(false);

    return this.deliveryInfoClient.getDeliveryInfoWithParams$(params).pipe(
      tap((data: any) => {
        this._loaderService.isLoadedCart.next(true);
      })
    );
  }
}
