import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigitOnlyDirective } from './digit-only.directive';
import { CharacterOnlyDirective } from './characters-only.directive';
import { AmountDirective } from './amount.directive';

@NgModule({
  declarations: [
    DigitOnlyDirective,
    CharacterOnlyDirective,
    AmountDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DigitOnlyDirective,
    CharacterOnlyDirective,
    AmountDirective,
  ]
})
export class CheckoutDirectivesModule { }
