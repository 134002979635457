import { Injectable } from '@angular/core';
import { GenericService } from '../generic';
import { ENDPOINTS } from '@parameters/endpoints';
import { Observable } from 'rxjs';
import { ISignatureTransaction, SignatureTransactionResponse } from '@models/authentication-app-agora-pay/authentication-app-agora-pay';
import { map } from 'rxjs/operators';
import { BodyTransactionOrder } from '@models/order-app-agora-pay/order-app-agora-pay';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SignatureTransactionClientService {

  constructor(
    private genericService: GenericService,
  ) { }

  public getSignatureTransaction$(body: BodyTransactionOrder,
    params: HttpParams = null,
  ): Observable<ISignatureTransaction> {
    return this.genericService.genericPost<ISignatureTransaction>(ENDPOINTS.POST_SIGNATURE_TRANSACTION, body, params)
      .pipe(
        map((response: ISignatureTransaction) => {
          return new SignatureTransactionResponse(response);
        })
      );
  }
}
