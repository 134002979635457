  <div class="row disclaimer-container p-3 my-3 mx-0">
    <div class="col-xs-2 col-sm-2 col-md-6 col-lg-2 d-flex justify-content-center align-items-center">
      <fp-icon class="d-inline-flex mr-2 mt-1" iconSize="39" iconName="card-monedero"></fp-icon>
    </div>
    <div class="col-xs-10 col-sm-2 col-md-6 col-lg-7" [ngClass]="{'col-lg-7': flkButtom,'col-lg-10': !flkButtom }">
      <div *ngIf="text" [innerHTML]="text"></div>
    </div>
    <div *ngIf="flkButtom" class="col-xs-12 col-sm-2 col-md-6 col-lg-3 mt-xs-3 mt-sm-0 mt-lg-0 d-flex justify-content-center align-items-center">
      <fp-button-outline (click)="btnGoToAuth()" buttonDisplay="block">Ingresar </fp-button-outline>
    </div>
  </div>