import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { EProductStatus } from "src/app/core/modules/product-card/models/product-status.enum";
import { AlgoliaInstanceService } from "../../../custom-instantsearch/algolia-instance.service";
import { SnackbarAlertService } from "../../../snackbar/snackbar-alert.service";
import { SnackbarToastService } from "../../../snackbar/snackbar-toast.service";
import { ProductShoppingCart } from "../../models/product-shopping-cart/product-shopping-cart.model";
import { PRODUCT_ALERTS } from "../../parameters/product-alerts";
import { ProductImplementService } from "../../service/product-implement.service";

@Component({
	selector: "fp-product-quantity",
	templateUrl: "./product-quantity.component.html",
	styleUrls: ["./product-quantity.component.sass"]
})
export class ProductQuantityComponent implements OnChanges, OnInit {
	public eProductStatus = EProductStatus;
	public addPickerEnable: boolean;
	public subtractPickerEnable: boolean;
	public iconMinus = "minus";

	@Input() productQuantity: number;
	@Input() quantityLimit: number;
	@Input() productStatus: number = this.eProductStatus.available;
	@Input() product: ProductShoppingCart | any;
	@Input() isAlertToast: boolean = false;
  @Input() isSeller: boolean;
  @Input() rounded: boolean;
	@Output() updateQuantity = new EventEmitter();
	@Output() deleted = new EventEmitter();

	constructor(
		private _productImplement: ProductImplementService,
		private snackbarAlert: SnackbarAlertService,
		private _snackbarToast: SnackbarToastService,
		private algoliaInstanceService: AlgoliaInstanceService
	) {}

	ngOnInit(): void {
		if (this.productQuantity === 1) {
			this.iconMinus = "";
		}
	}

	ngOnChanges(): void {
		this.pickerSetting();
	}

	addPicker() {
		this.productQuantity++;
		this.iconMinus = "minus";
		this.pickerSetting();
		this.setQuantity();
	}

	subtractPicker() {
		if (this.iconMinus === "") {
			this.deleteProduct();
		} else {
			this.productQuantity--;
			this.pickerSetting();
			this.setQuantity();
      this.algoliaInstanceService.removeUnitConvertedObjectId(this.product.id, this.productQuantity);
			if (this.productQuantity === 1) {
				this.iconMinus = "";
			}
		}
	}

	pickerSetting() {
		this.addPickerEnable = this.productQuantity < this.quantityLimit;
		this.subtractPickerEnable = this.productQuantity > 1;
		if (this.productQuantity === 1) {
			this.iconMinus = "";
		} else {
			this.iconMinus = "minus";
		}
	}

	setQuantity() {
		this.updateQuantity.emit(this.productQuantity);
	}

	deleteProduct() {
		this._productImplement.deleteProduct(this.product?.id ? this.product?.id : this.product?.objectID);

		this.isAlertToast
			? this._snackbarToast.snackBarToast(PRODUCT_ALERTS.DELETE_PRODUCT)
			: this.snackbarAlert.snackbarAlertSuccess("Has eliminado este producto de tu carrito", {}, "Ok");

		TaggingService.productMiniCartEliminar();
		TaggingService.removeFromCart(
			this.product,
			this.product?.presentations[0].price ? this.product?.presentations[0].price : this.product?.priceList,
			this.product?.defaultPresentation ? this.product.defaultPresentation : this.product.presentationIdDefault,
			this.product.quantity ? this.product.quantity : 1
		);
		TaggingService.removeFromCartSDK(
    this.product,
    this.product?.presentations[0].price ? this.product?.presentations[0].price : this.product?.priceList,
    this.product.quantity ? this.product.quantity : 1
    );
		this.algoliaInstanceService.removeConvertedObjectID(this.product.id);

		this.deleted.emit(true);
	}
}
