// RET
export const REPLACEABLE_NAME = '$name$';
export const REPLACEABLE_DNI = '$dni$';
export const REPLACEABLE_LIMIT_DATE = '$limitDay$';

export const OWN_MESSAGES_FOR_RET = [
  `Te enviaremos un mensaje cuando tu pedido esté listo para recoger.`,
  `Una vez que tu pedido esté listo, ${REPLACEABLE_LIMIT_DATE}`,
  `Recuerda que debes llevar tu DNI físico para poder recoger tu pedido.`,
];

// `Solo tú podrás recoger el pedido presentando tu DNI. No podremos entregarlo a un tercero.`,

export const THIRD_MESSAGE_FOR_RET = [
  `Tu pedido será entregado a ${REPLACEABLE_NAME} con DNI ${REPLACEABLE_DNI}.`,
  `Te enviaremos un mensaje a ti y a ${REPLACEABLE_NAME} cuando tu pedido esté listo para recoger.`,
  `Recuerda que la persona autorizada deberá llevar su DNI para poder recoger tu pedido.`,
  `Una vez que tu pedido esté listo, ${REPLACEABLE_LIMIT_DATE}`,
];

export enum EResultScheduleTypes {
  DEFAULT = '',
  NO_COVERAGE_FOR_RAD = 'NO_COVERAGE_FOR_RAD',
  NO_COVERAGE_FOR_SELLER = 'NO_COVERAGE_FOR_SELLER',
  NO_ADDRESS_FOR_RAD = 'NO_ADDRESS_FOR_RAD',
  RAD = 'RAD',
  SELLER = 'SELLER',
  RAD_NOK = 'RAD_NOK',
  NO_DRUGSTORE_FOR_RET = 'NO_DRUGSTORE_FOR_RET',
  RET = 'RET',
  RET_NOK = 'RET_NOK',
}
