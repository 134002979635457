import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhoPickUpFormHelperService } from './who-pick-up-form-helper.service';
import { WhoPickUpFormService } from './who-pick-up-form.service';
import { UserPickUpFormService } from './user-pick-up-form.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    WhoPickUpFormService,
    WhoPickUpFormHelperService,
    UserPickUpFormService,
  ]
})
export class PickUpFormsModule { }
