import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordConfirmationComponent } from '../modal-components/forgot-password-confirmation/forgot-password-confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordConfirmationModalService {

  constructor(
    private dialog: MatDialog,
  ) { }

  public showForgotPasswordConfirmation(): void {
    this.dialog.open(ForgotPasswordConfirmationComponent, {
      width: '432px',
    });
  }

  public showForgotPasswordConfirmationResponsive(): void {
    this.dialog.open(ForgotPasswordConfirmationComponent, {
      panelClass: 'main-login-modal-responsive',
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh'
    });
  }
}
