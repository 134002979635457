import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppStoreService } from '@stores/app/app-store.service';
import { StoresForPickUpStoreService } from 'src/app/core/modules/pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service';
import { RetValidateCartService } from '../../service/cart/ret-validate-cart.service';
import { ProductOutOfStockDialogComponent } from '../product-out-of-stock-dialog/product-out-of-stock-dialog.component';
import { EBrand } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';

@Component({
  selector: 'fp-product-is-not-delivered',
  templateUrl: './product-is-not-delivered.component.html',
  styleUrls: ['./product-is-not-delivered.component.sass']
})
export class ProductIsNotDeliveredComponent implements OnInit {
  isMifaBrand = environment.brand === EBrand.mifarma;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ProductOutOfStockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }
}
