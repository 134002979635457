import { Injectable } from '@angular/core';
import { PickUpFormsModule } from './pick-up-forms.module';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Injectable({
  providedIn: PickUpFormsModule
})
export class StoreToSelectFormService {

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      selectedDrugstore: new FormControl()
    });
  }

  public get selectedDrugstoreControl() {
    return this.form.get('selectedDrugstore') as FormControl;
  }
}
