<div
  [class]="'chip chip__' + size + ' ' + status"
  [ngClass]="{ disabled: disabled }"
>
  <fp-icon
    [iconName]="iconName"
    [iconSize]="iconSize"
    *ngIf="iconName"
  ></fp-icon>
  <span [class]="'text__' + size"> {{ text }} </span>
</div>
