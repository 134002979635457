import { Injectable } from "@angular/core";
import { EBrand } from "@parameters/access/brand.parameter";
import { CONTENTFUL_PATH } from "@parameters/endpoints";
import { AppStoreService } from "@stores/app/app-store.service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CONTENFUL_CUSTOM_KEYS } from "src/app/shared/parameters/contenful";
import { ContenfulConfigService } from "../contenful-config/conteful-config-.service";
import { GenericConfigurationSalesChannel } from "./contenful-sales-channel.interface";

@Injectable({ providedIn: "root" })
export class ContenfulConfigSalesChannelService {
	constructor(private contenfulConfigService: ContenfulConfigService, private appStore: AppStoreService) {}

	public getCloudConfigurationByKey$(): Observable<GenericConfigurationSalesChannel> {
		const currentBrand = this.appStore.brandAtention;

		const keyConfigurationSelected =
			currentBrand === EBrand.inkafarma
				? CONTENFUL_CUSTOM_KEYS.inkafarmaConfiguration.SALES_CHANNEL
				: CONTENFUL_CUSTOM_KEYS.contentfulConfiguration.SALES_CHANNEL;
		const contenfulPath =
			currentBrand === EBrand.inkafarma
				? CONTENTFUL_PATH.contentTypeIds.INKAFARMA_CONFIGURATION_KEY
				: CONTENTFUL_PATH.contentTypeIds.MIFARMA_CONFIGURATION_KEY;

		return this.contenfulConfigService
			.getCloudConfigurationByKey$<GenericConfigurationSalesChannel>(keyConfigurationSelected, contenfulPath)
			.pipe(
				map((entry) => entry.fields),
				catchError((err) => {
					console.error(err);
					return of({} as GenericConfigurationSalesChannel);
				})
			);
	}
}
