import { CONTENTFUL_PATH } from '../../parameters/endpoints';
import { CONTENTFUL_QUERIES } from '../../parameters/querys';

import { GenericContentfulService } from '../generic';
import { Injectable } from '@angular/core';
import { EContentfulDashboardShowcasesSection, IContentfulDashboardShowcases } from '../../../core/contentful/models/contentful-dashboard-showcases.model';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailDashboardClientService {

  private productDetailLandingSection = EContentfulDashboardShowcasesSection.productDetailLanding;

  constructor(
    private _GenericContentfulService: GenericContentfulService
  ) { }
  public getProductDetailDashboard()  {
    return this._GenericContentfulService.getEntriesPromise<IContentfulDashboardShowcases>(
      CONTENTFUL_PATH.contentTypeIds.dashboardShowcases,
      CONTENTFUL_QUERIES.prototype.getEntryBySection(this.productDetailLandingSection));
  }
}
