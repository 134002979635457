import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fp-link-buttom-v2',
  templateUrl: './link-buttom-v2.component.html',
  styleUrls: ['./link-buttom-v2.component.sass'],
})
export class LinkButtomV2Component implements OnInit {
  @Input() buttonText = '';
  @Input() id = '';
  @Input() labelClass = '';
  @Input() disabled = false;
  @Output() onclick = new EventEmitter();
  @Input() linkSizeClass = '';

  constructor() {
  }

  ngOnInit() {
  }

  public buttonClick() {
    if (!this.disabled) {
      this.onclick.emit();
    }
  }

  public get configLabelClass() {
    return this.labelClass ? this.labelClass : 'label-black';
  }

  public get configLinkSizeClass() {
    return this.linkSizeClass === '' ? this.linkSizeClass : 'link-size';
  }
}
