import { USER_SOURCE_LOGIN } from "./../../../../../shared/parameters/global";
import { Component, OnInit, NgZone } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { RegisterFormService } from "../../register-forms/modal-register-form.service";
import { SnackbarAlertService } from "../../../snackbar/snackbar-alert.service";
import { AuthImplementService } from "src/app/shared/implements/auth/auth-implement.service";
import { UserInfoStoreService } from "src/app/shared/stores/user/user-info-store.service";
import { USER_INFO_MESSAGES } from "src/app/shared/parameters/user-info-message";
import { take } from "rxjs/operators";
import { NewPasswordModalManagerService } from "../../register-services/new-password-modal-manager.service";
import { MainRegisterStoreService } from "../../register-stores/main-register-store.service";
import { EmailVerificationRequest } from "src/app/shared/models/register/user-register-request.model";
import {
  RegisterResponse,
  UserRegister,
} from "src/app/shared/models/register/register.model";
import {
  USER_REGISTRATION_ERROR_CODE,
  COMPANY_ID,
  AUTH_ORIGIN,
  AUTH_PROVIDER,
} from "src/app/shared/parameters/global";
import { RegisterImplementService } from "../../register-services/register-implement.service";
import { User } from "src/app/shared/models";
import { NewRegisterAccessStoreService } from "src/app/shared/stores/user/new-register-access-store.service";
import { Router } from "@angular/router";
import { BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { NewRegisterStoreService } from "src/app/shared/stores/user/new-register-store.service";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { RegistrationValidationService } from "../../register-services/registration-validation.service";
import { MiniCartCloseService } from "../../../mini-cart/services/mini-cart-close.service";
import { LoginModalsManagerHelperService } from "../../../login/login-services/login-modals-manager-helper.service";
import { RegisterModalsManagerHelperService } from "../../register-services/register-modals-manager-helper.service";
import { RegisterOriginStoreService } from "../../register-stores/register-origin-store.service";
import { TaggingService } from "src/app/atm-services/tagging.service";
import {MainLoginAfiliacionModalComponent} from "src/app/core/modules/login/modal-components/main-login-afiliacion-modal/main-login-afiliacion-modal.component"
import { RegisterInfoTypeStoreService } from 'src/app/shared/stores/user/register-info-type-store.service';

@Component({
  selector: 'fp-main-register-afiliacion-modal',
  templateUrl: './main-register-afiliacion-modal.component.html',
  styleUrls: ['./main-register-afiliacion-modal.component.sass'],
})
export class MainRegisterAfiliacionModalComponent implements OnInit {
  public showEmailAndPassword: boolean;
  public registrationErrorCode = USER_REGISTRATION_ERROR_CODE;
  public iconInformation = 'assets/icons/icon-information.svg';
  public userName: string;
  public userPoints: string;

  constructor(
    public dialogRef: MatDialogRef<MainRegisterAfiliacionModalComponent>,
    private dialog: MatDialog,
    public registerForm: RegisterFormService,
    private _snackbarAlertService: SnackbarAlertService,
    public authImplementService: AuthImplementService,
    public userInfoStore: UserInfoStoreService,
    private newPasswordModal: NewPasswordModalManagerService,
    private mainRegisterStore: MainRegisterStoreService,
    private registerImplement: RegisterImplementService,
    private newRegisterAccessStore: NewRegisterAccessStoreService,
    private _router: Router,
    private _ngZone: NgZone,
    private _appStoreService: AppStoreService,
    private newRegisterStore: NewRegisterStoreService,
    private registrationValidation: RegistrationValidationService,
    private _miniCartClose: MiniCartCloseService,
    private registerModalsManagerHelper: RegisterModalsManagerHelperService,
    private loginModalsManagerHelper: LoginModalsManagerHelperService,
    private registerOriginStore: RegisterOriginStoreService,
    private registerInfoTypeStore: RegisterInfoTypeStoreService,
  ) {
  }

  ngOnInit() {
    this.userName = this.userInfoStore.getUser().name;
    this.userPoints = this.userInfoStore.getUser().points;
  }

  facebookLogin() {
    TaggingService.registerModal('facebook');
    this.authImplementService.facebookLogin().then((response) => {
      if (response) {
        this.registerInfoTypeStore.setProviderAuthenticationOrigin();
        TaggingService.registerOK('facebook');
        this.newRegisterStore.firstUserLoggedIn = response;
        this.settingStorageAuthProvider(response);

        this.validateUserRegister();
      }
    });
  }

  googleLogin() {
    TaggingService.registerModal('google');
    this.authImplementService.googleLogin().then((response) => {
      if (response) {
        this.registerInfoTypeStore.setProviderAuthenticationOrigin();
        TaggingService.registerOK('google');
        this.newRegisterStore.firstUserLoggedIn = response;
        this.settingStorageAuthProvider(response);
        this.validateUserRegister();
      }
    });
  }

  appleLogin() {
    TaggingService.registerModal('apple');
    this.authImplementService.appleLogin().then((response) => {
      if (response) {
        this.registerInfoTypeStore.setProviderAuthenticationOrigin();
        TaggingService.registerOK('apple');
        this.newRegisterStore.firstUserLoggedIn = response;
        this.settingStorageAuthProvider(response);
        this.validateUserRegister();
      }
    });
  }

  settingStorageAuthProvider(response) {
    this._appStoreService.setStorageItem(
      AUTH_PROVIDER,
      response.credential.providerId
    );
  }

  openEmailVerification() {
    const email =
      this.newRegisterStore.firstUserLoggedIn.additionalUserInfo.profile.email;
    this.newRegisterStore.email = email;
    this.mainRegisterStore.email =
      this.registerOriginStore.emailVerificationOrigin = BUSINESS_PATHS.HOME;
    this.registerModalsManagerHelper.openEmailVerificationModal();
    this.registerModalsManagerHelper.closeNewPasswordModal();
  }

  validateUserRegister() {
    this.registerModalsManagerHelper.closeRegisterAfiliacionModal();
    this._miniCartClose.closeMiniCart();
    const subscribe = this.registrationValidation
      .validateUserRegisterOnDB()
      .subscribe((path) => {
        !path ? path = window.location.pathname : path;
        if (typeof path === 'string' && path.length) {
          this._appStoreService.removeSourceLogin();

          if (
            this._appStoreService.sourceLogin === USER_SOURCE_LOGIN.MAIN_LOGIN
          ) {
            this.registrationValidation.handleNavigationMainLoin(path);
          } else {
            this.registrationValidation.handleNavigationCartLogin(path);
          }
        }
        subscribe.unsubscribe();
      });
  }

  closeModal() {
    this.loginModalsManagerHelper.openMainLoginAfiliacionModal();
    this.dialogRef.close();
  }

  openAlert() {
    this._snackbarAlertService
      .snackbarAlertInfo(`El correo que ingresaste ya se encuentra registrado.
    Inicia sesión o da click en "Olvidaste tu contraseña" para recuperar tu cuenta`);
  }

  openAlertError() {
    this._snackbarAlertService.snackBarAlert(
      `El correo que ingresaste es invalido. Por favor
    verifique que este escrito correctamente.`,
      'error'
    );
  }

  openEmailWithPassword() {
    TaggingService.registerModal('crea una cuenta con tu correo');
    this.mainRegisterStore.email = this.registerForm.emailControl.value;
    const validateEmailRequest = new EmailVerificationRequest();
    (validateEmailRequest.email = this.registerForm.emailControl.value),
      (validateEmailRequest.companyId = COMPANY_ID);
    validateEmailRequest.origin = AUTH_ORIGIN.EMAIL_PASSWORD;
    validateEmailRequest.source = 'WEB';
    this.registerInfoTypeStore.setEmailAndPasswordAuthenticationOrigin();
    this.registerImplement
      .saveOrUpdateUser(validateEmailRequest)
      .subscribe((response: RegisterResponse) => {
        if (response.code === this.registrationErrorCode.ERROR_EMAIL_USED) {
          this.openAlert();
        } else {
          TaggingService.registerOK('simple register');
          this.showEmailAndPassword = true;
          this.registerModalsManagerHelper.openNewPasswordModal();
          this.registerModalsManagerHelper.closeRegisterModal();
        }
      });
  }
}
