<div class="selected-item-bordered-card custom-border p-xs-3 p-md-5">
  <div class="d-flex">
    <div class="card-icon">
      <fp-icon [iconSize]="24" [iconName]="icon">
      </fp-icon>
    </div>
    <div class="card-information d-flex flex-column">
      <div class="card-name d-flex justify-content-between align-items-center">
        <div class="d-flex">
          <span class="card-title"> {{ title }} </span>  
          <span class="alert-message label-black--3 d-none d-lg-inline-block mx-3" *ngIf="alertMessage && hasAlertMessage"> {{ alertMessage }}
          </span>
        </div>
        <div class="card-button label-black--1 label-black-lg text-secondary"
          (click)="clickEvent.emit()" *ngIf="showClickEvent" id="link--change-addres">
          Cambiar
        </div>
      </div> 
      <div class="card-address mt-2">
        {{ subtitle }}
      </div>
      <div class="alert-message label-black--3 d-inline-block d-lg-none mt-2" *ngIf="alertMessage && hasAlertMessage">
        {{ alertMessage }}
      </div>
    </div>  
  </div>
  <div class="d-flex mt-3 custom-ml-38" *ngIf="bottomDetail">
    <div class="card-detail">
      <fp-icon iconSize="16" [innerClass]="'align-bottom-zero'" [iconName]="clockIcon"></fp-icon>
      <div class="custom-ml-4">{{ bottomDetail }}</div>
    </div>
  </div>
</div>
