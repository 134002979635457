import { ProductPresentations, ProductPresentation, ProductQuantityLimit } from '../product-presentation.model';
import { IProductCard } from './product-card.interface';
import { EProductPresentation } from '../product-presentation.enum';

export class PackCardPresentation extends ProductPresentation {
  constructor(iProductCard: IProductCard) {
    super();
    this.fractionated = false;
    this.id = iProductCard.presentationId || EProductPresentation.pack;
    this.image = iProductCard.pathImage || '';
    this.stock = iProductCard.packStock || 0;
    this.maxUnitSale = iProductCard.maxPackUnitSale || 0;
    this.price = iProductCard.packPrice || 0;
    this.description = iProductCard.packDescription || '';
    this.presentation = iProductCard.packPresentation || '';
    this.quantityLimit = new ProductQuantityLimit(this).quantityLimit || 0;
    this.regularPrice = iProductCard.priceAllPaymentMethod || 0;
    this.offerPrice = iProductCard.priceWithpaymentMethod || 0;
    this.crossOutPL = iProductCard.crossOutPL;
  }
}

export class UnitCardPresentation extends ProductPresentation {
  constructor(iProductCard: IProductCard) {
    super();
    this.fractionated = true;
    this.id = iProductCard.fractionatedPresentationId || EProductPresentation.unit;
    this.image = iProductCard.pathImage || '';
    this.stock = iProductCard.fractionalStock || 0;
    this.stockRet = iProductCard.fractionalRetStock || 0;
    this.maxUnitSale = iProductCard.maxfractionalUnitSale || 0;
    this.price = iProductCard.fractionalPrice || 0;
    this.description = iProductCard.fractionalDescription || '';
    this.presentation = iProductCard.fractionalPresentation || '';
    this.quantityLimit = new ProductQuantityLimit(this).quantityLimit || 0;
    this.regularPrice = iProductCard.fractionatedPriceAllPaymentMethod || 0;
    this.offerPrice = iProductCard.fractionatedPriceWithpaymentMethod || 0;
    this.crossOutPL = iProductCard.crossOutPL;
  }
}

export class ProductCardPresentations extends ProductPresentations {
  constructor(iProductCard: IProductCard) {
    super();
    this.productPresentations = [new PackCardPresentation(iProductCard)];
    if (iProductCard.fractionalMode) {
      this.productPresentations = [new UnitCardPresentation(iProductCard), ...this.productPresentations];
    }
  }
}
