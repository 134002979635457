<section class="content-initial">
	<div class="w-100 d-flex flex-column align-items-center justify-content-center">
		<fp-icon iconName="zoom-find" iconSize="48"></fp-icon>
		<h6 class="text-center text-h6 mb-2">Identifica al cliente ingresando el documento ó teléfono, y presiona la tecla “Enter”</h6>
		<p class="text-center text-body-regular-2 mb-5">En caso de ser un cliente nuevo, puedes hacer un nuevo registro</p>
		<fp-button-primary (click)="onRegister()" [buttonClass]="'d-flex align-items-center'" [buttonDisabled]="disabled">
			<fp-icon iconName="plus-sign-white" iconSize="20" innerClass="mr-2"></fp-icon>
			<span class="text-body-bold-2">Registrar cliente</span>
		</fp-button-primary>
	</div>
</section>
