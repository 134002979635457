import { GenericService } from '../generic';
import { JSON_ENDPOINTS } from '../../parameters/endpoints';
import { Injectable } from '@angular/core';

@Injectable()
export class CoverageAreasClientService {

  constructor(
    private _genericService: GenericService,
  ) {
  }

  public getZonesJson() {
    const { COVERAGE_AREAS } = JSON_ENDPOINTS;
    return this._genericService
      .genericGetStaticFile<any>(COVERAGE_AREAS);
  }

}
