import { Injectable } from "@angular/core";
import { AddressManagerPersistanceService } from "src/app/core/modules/address-manager/address-services/address-manager-persistance.service";
import { ClientStoreService } from "src/app/shared/stores/client/client-store.service";

@Injectable({ providedIn: "root" })
export class OrderTakerStore {
  constructor(
    private _clienStore: ClientStoreService,
    private _addressStore: AddressManagerPersistanceService
  ) {}
  public get clientStore$() {
    return this._clienStore.client$;
  }
  public get _addressStore$() {
    return this._addressStore.sendAddressItem$;
  }

  public reset() {
    this._clienStore.clearStore();
    this._addressStore.deleteAddressSelected();
  }
}
