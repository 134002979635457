import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BUSINESS_PATHS } from "@parameters/business.parameter";
import { BREAKPOINTS } from "@parameters/global";
import { BreakpointBehavior } from "src/app/shared/behaviors";
import { ProductOutOfStockDialogComponent } from "src/app/shared/components/product-out-of-stock-dialog/product-out-of-stock-dialog.component";
const TEXT_DESCRIPTION_NOTIFICATION = {
	skuNoVisible: "Se retiró del carrito uno o más productos que no están disponibles para venta unitaria.",
	duplicateProducts:
		"Se retiró del carrito uno o más productos que superan las unidades máximas de venta. Puedes agregarlo nuevamente a tu compra.",
	onlinePaymentRet: "El método de pago no es válido para Retiro en Tienda. Por favor, inténtalo nuevamente."
};
const TEXT_TITLE_NOTIFICATION = {
	skuNoVisible: "Carrito de compra actualizado",
	duplicateProducts: "Carrito de compra actualizado",
	onlinePaymentRet: "Cambiar método de pago"
};
@Component({
	selector: "fp-modal-sku",
	templateUrl: "./modal-sku.component.html",
	styleUrls: ["./modal-sku.component.sass"]
})
export class ModalSkuComponent implements OnInit {
	public description = "";
	public title = "";
	constructor(
		private router: Router,
		private dialogRef: MatDialogRef<ModalSkuComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any
	) {}

	ngOnInit() {
		this.description = TEXT_DESCRIPTION_NOTIFICATION[this.dialogData];
		this.title = this.dialogData ? TEXT_TITLE_NOTIFICATION[this.dialogData] : TEXT_TITLE_NOTIFICATION["duplicateProducts"];
	}

	close() {
		this.dialogRef.close();
		this.router.navigate(["/carrito"]);
	}

	action() {
		this.router.navigate(["/carrito"]);
		this.dialogRef.close();
	}
}
