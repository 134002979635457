import { FilteredProductsFilterSection } from 'src/app/core/modules/filtered-products/model/filtered-products-filter-section.model';
import { ISearchLandingBrands, ISearchLandingCategories, ISearchLandingResponse, ISearchLandingSubcategories } from './search-landing-response.interface';

export class SearchLandingFilterSection {
  id: number;
  name: string;
  brands: Array<SearchLandingBrands>;
  filter?: Array<SearchLandingFilterSection>;
}

export class SearchLandingBrands {
  id: number;
  name: string;

  constructor(iSearchLandingBrands: ISearchLandingBrands) {
    this.id = iSearchLandingBrands.id;
    this.name = iSearchLandingBrands.name;
  }
}

export class SearchLandingAlgoliaResponse {
    hits: Hit[];
    nbHits: number;
    page: number;
    nbPages: number;
    hitsPerPage: number;
    facets: Facets;
    exhaustiveFacetsCount: boolean;
    exhaustiveNbHits: boolean;
    exhaustiveTypo: boolean;
    exhaustive: Exhaustive;
    query: string;
    queryAfterRemoval: string;
    params: string;
    renderingContent: RenderingContent;
    processingTimeMS: number;
    processingTimingsMS: ProcessingTimingsMS;
    serverTimeMS: number;
    queryID?: string
  }

  interface ProcessingTimingsMS {
    afterFetch: AfterFetch;
    fetch: Fetch;
    getIdx: GetIdx;
    request: Request;
    total: number;
  }

  interface Request {
    roundTrip: number;
  }

  interface GetIdx {
    load: Load;
    total: number;
  }

  interface Load {
    gens: number;
    rules: number;
    total: number;
  }

  interface Fetch {
    total: number;
  }

  interface AfterFetch {
    format: Format;
    total: number;
  }

  interface Format {
    highlighting: number;
    total: number;
  }

  interface RenderingContent {
    facetOrdering: FacetOrdering;
  }

  interface FacetOrdering {
    facets: Facets2;
  }

  interface Facets2 {
    order: string[];
  }

  interface Exhaustive {
    facetsCount: boolean;
    nbHits: boolean;
    typo: boolean;
  }

  export interface Facets {
    brand: Brand;
    category: Category;
    subCategory: SubCategory;
  }

  export interface SubCategory {
  }

  export interface Category {
    name: string
  }

  export interface Brand {
  }

  export interface Hit {
    name: string;
    longDescription: string;
    shortDescription: string;
    lab: string;
    brand: string;
    keyword: string;
    uri: string;
    ranking: number;
    channel: string;
    activePrinciples: string;
    department: string[];
    category: string[];
    subCategory: string[];
    sellerName: string;
    presentations: string[];
    variant?: any;
    productType: string;
    laboratory: string;
    objectID: string;
    _highlightResult: HighlightResult;
  }

  interface HighlightResult {
    name: Name;
    brand: Name;
    keyword: Name;
    channel: Channel;
  }

  interface Channel {
    value: string;
    matchLevel: string;
    matchedWords: any[];
  }

  interface Name {
    value: string;
    matchLevel: string;
    fullyHighlighted: boolean;
    matchedWords: string[];
  }

export interface FacetHit {
  value: string;
  highlighted: string;
  count: number;
}

export interface listCategorieSelected {
  categories: Array<string>;
  subCategories: Array<string>;
  brand: Array<string>;
}

export interface algolisListSearach {
  name: string
}


class SearchLandingFilterSectionBySubcategories extends SearchLandingFilterSection {

  constructor(iSearchLandingCategories: ISearchLandingSubcategories) {
    super();
    this.id = iSearchLandingCategories.id;
    this.name = iSearchLandingCategories.name;

    if (iSearchLandingCategories.brands && iSearchLandingCategories.brands.length) {
      this.brands = iSearchLandingCategories.brands.map(brand => new SearchLandingBrands(brand)) || [];
    }
  }
}

class SearchLandingFilterSectionByCategories extends SearchLandingFilterSection {

  constructor(iSearchLandingCategories: ISearchLandingCategories) {
    super();
    this.id = iSearchLandingCategories.id;
    this.name = iSearchLandingCategories.name;

    if (iSearchLandingCategories.brands && iSearchLandingCategories.brands.length) {
      this.brands = iSearchLandingCategories.brands.map(brand => new SearchLandingBrands(brand)) || [];
    }
    if (iSearchLandingCategories.subCategories && iSearchLandingCategories.subCategories.length) {
      this.filter = iSearchLandingCategories.subCategories.map(subCategories => new SearchLandingFilterSectionBySubcategories(subCategories)) || [];
    }
  }
}

class SearchLandingFilterSectionByDepartment extends SearchLandingFilterSection {

  constructor(iSearchLandingCategories: ISearchLandingCategories) {
    super();
    this.id = iSearchLandingCategories.id;
    this.name = iSearchLandingCategories.name;

    if (iSearchLandingCategories.brands && iSearchLandingCategories.brands.length) {
      this.brands = iSearchLandingCategories.brands.map(brand => new SearchLandingBrands(brand)) || [];
    }
    if (iSearchLandingCategories.categories && iSearchLandingCategories.categories.length) {
      this.filter = iSearchLandingCategories.categories.map(categories => new SearchLandingFilterSectionByCategories(categories)) || [];
    }
  }
}

export class SearchLandingResponse {
  filter: Array<SearchLandingFilterSection>;
  productsId: Array<string>;

  constructor(iSearchLandingResponse: ISearchLandingResponse) {
    this.filter = iSearchLandingResponse.categories && iSearchLandingResponse.categories.length ?
      iSearchLandingResponse.categories.map(category => new SearchLandingFilterSectionByDepartment(category)) : [];

    this.productsId = iSearchLandingResponse.productsId;
  }
}

export class SearchLandingFilterSectionToFilterSection extends FilteredProductsFilterSection {
  constructor(searchLandingFilterSection: SearchLandingFilterSection) {
    super();
    this.id = searchLandingFilterSection.id;
    this.name = searchLandingFilterSection.name;
    if (searchLandingFilterSection.brands) {
      this.brands = searchLandingFilterSection.brands || [];
    }
    if (searchLandingFilterSection.filter && searchLandingFilterSection.filter) {
      this.filter = searchLandingFilterSection.filter.map(filter => new SearchLandingFilterSectionToFilterSection(filter)) || [];
    }
  }
}
