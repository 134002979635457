import { MatDialogConfig } from '@angular/material/dialog';

export const MAIN_REGISTER_MODAL_CONFIG: MatDialogConfig = {
  width: '412px',
  panelClass: 'custom-full-all-modal-overlay',
};

export const MAIN_REGISTER_AFILIACION_MODAL_CONFIG: MatDialogConfig = {
  width: '344px',
  panelClass: 'custom-full-all-modal-overlay',
  disableClose: true,
};

export const NEW_PASSWORD_MODAL_CONFIG: MatDialogConfig = {
  width: '340px',
  panelClass: 'custom-full-all-modal-overlay',
};

export const EMAIL_VERIFICATION_MODAL_CONFIG: MatDialogConfig = {
  width: '344px',
  panelClass: 'custom-full-all-modal-overlay',
};

export const PHONE_VERIFICATION_MODAL_CONFIG: MatDialogConfig = {
  width: '418px',
  panelClass: 'custom-full-all-modal-overlay',
};

export const UPDATE_EMAIL_MODAL_CONFIG: MatDialogConfig = {
  width: '418px',
  panelClass: 'custom-full-all-modal-overlay',
};

export const IT_WAS_WRONG_MODAL_CONFIG: MatDialogConfig = {
  width: '340px',
  panelClass: 'custom-full-all-modal-overlay',
};

export const MAIN_PRE_REGISTER_MODAL_CONFIG: MatDialogConfig = {
  width: '412px',
  panelClass: 'custom-full-all-modal-overlay',
};

export const CHECKOUT_REGISTER_MODAL_CONFIG: MatDialogConfig = {
  width: '400px',
  panelClass: 'custom-full-all-modal-overlay',
  disableClose: true,
};

export const CHECKOUT_WELCOME_REGISTER_MODAL_CONFIG: MatDialogConfig = {
  width: '608px',
  panelClass: 'custom-full-all-modal-overlay',
  disableClose: true,
}
