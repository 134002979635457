import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AddressNickname } from 'src/app/shared/models/address/address-nickname.model';

@Injectable()
export class AddressNicknamesStoreService {

  private _addressNicknameList: AddressNickname[];
  private _addressNicknameListSubject = new BehaviorSubject<AddressNickname[]>([]);

  public addressNicknameList$ = this._addressNicknameListSubject.asObservable();

  constructor() {
    this.addressNicknameList$
      .subscribe(list => this._addressNicknameList = list);
  }

  public get addressNicknameList() {
    return this._addressNicknameList;
  }

  public setAddressNicknameList(list: AddressNickname[]) {
    this._addressNicknameListSubject.next(list);
  }

}
