import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fp-tag-sold-out',
  templateUrl: './tag-sold-out.component.html',
  styleUrls: ['./tag-sold-out.component.sass']
})
export class TagSoldOutComponent implements OnInit {

  @Input() tagSize: 'small' | 'regular' = 'regular';
  @Input() isProductPack: boolean;

  public carrito = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.validateUrl();
  }

  validateUrl() {
    if (this.router.url === '/carrito') {
      this.carrito = true;
    } else {
      this.carrito = false;
    }
  }

}
