import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordStoreService {
  private _email = '';

  constructor() { }

  public get email() {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }
}
