<div class="row">
  <div class="col position-relative">
    <button id="btn-select-presentation"
            class="btn btn-block btn-selected-presentation"
            [matMenuTriggerFor]="productPresentationMenu"
            [disabled]="productStatus === eProductStatus.unavailable || presentationList.length <= 1"
            [class.border-warning]="productStatus === eProductStatus.unavailablePresentation"
            [class.border-gray]="productStatus !== eProductStatus.unavailablePresentation">

      <span class="label"
            [class.text-black]="productStatus === eProductStatus.available"
            [class.text-dark-gray]="productStatus !== eProductStatus.available">
        {{selectedPresentation?.presentation | lowercase | capitalCase}}
      </span>

      <fp-icon class="d-inline-flex" iconSize="20" iconHeight="16" iconName="dropdown_arrow"></fp-icon>
    </button>

    <!-- <span *ngIf="productStatus === eProductStatus.unavailablePresentation"
          class="label--2 text-warning label-unavailable-presentation">
    Disponible solo en {{availablePresentation?.presentation | lowercase | capitalCase}}
  </span> -->

  </div>
</div>
<mat-menu class="parameters-mat-button-cart-select"
#productPresentationMenu="matMenu">

<div *ngFor="let presentation of presentationList">
    <span mat-menu-item
          class="label option-content"
          (click)="selectPresentation(presentation)">
      {{presentation.presentation | lowercase | capitalCase}}
    </span>
  </div>

</mat-menu>
