import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductErrorModalComponent } from '../views/product-error-modal/product-error-modal.component';

@Injectable()
export class ProductModalsManagerService {

  constructor(
    private dialog: MatDialog,
  ) { }

  public showProductErrorModal(): void {
    this.dialog.open(ProductErrorModalComponent, {
      width: '340px',
      disableClose: true,
    });
  }
}
