import { FormControl, Validators } from "@angular/forms";
import { getErrorbyFormControl } from "../helpers/error-controls-helpers";
import { PASSWORD_LOGIN_ERROR_MESSAGES } from "../parameters/error-message-forms";

export class PasswordControl extends FormControl {
  constructor() {
    super("");
    this.settingNameValidators();
  }

  private settingNameValidators() {
    this.setValidators([Validators.required]);
  }

  public get passwordError(): string {
    return getErrorbyFormControl(this, PASSWORD_LOGIN_ERROR_MESSAGES);
  }
}
