<div class="purchase-summary-sub-total">
  <div class="d-flex justify-content-between mx-0 pt-1 pb-1" *ngIf="subtotal">
    <div class="d-flex align-items-center justify-content-end">Subtotal</div>
    <div class="d-flex align-items-center justify-content-end text-right label label-xl--1">
      {{ subtotal | currency: "S/ " }}
    </div>
  </div>
  <!-- <div class="row total-oh" *ngIf="subtotalOh">
    <div class="col heading-1">
      Subtotal {{ _globalProductCardService.paymentMethodCard.name }}
    </div>
    <div
      class="col-5 text-right heading-1 d-flex align-items-center justify-content-end"
    >
      <img
        class="icon-card mr-2"
        [src]="_globalProductCardService.paymentMethodCard.image"
        alt="icono tarjeta inkafarma"
      />
      {{ subtotalOh | currency: "S/ " }}
    </div>
  </div> -->
</div>
<div class="row" *ngIf="discount">
  <div class="col">
    <div class="d-flex justify-content-between mx-0 pt-1 pb-2 text-danger">
      <div class="d-flex align-items-center justify-content-end">Descuentos {{brand=='Inkafarma'?'adicionales':'mifarma'}}</div>
      <div class="d-flex align-items-center justify-content-end text-right label--1">
        {{discount || 0 | currency: '- S/ '}}
      </div>
    </div>
  </div>
</div>
<hr class="mr-0">
