import { MatDialogConfig } from '@angular/material/dialog';

export const ANONYMOUS_WARNING_MODAL_CONFIG: MatDialogConfig = {
  width: '340px',
  disableClose: true,
  panelClass: 'custom-full-all-modal-overlay',
};

export const CART_LOGIN_MODAL_CONFIG: MatDialogConfig = {
  width: '340px',
  height: 'auto',
  panelClass: 'custom-full-all-modal-overlay',
};

export const FORGOT_PASSWORD_MODAL_CONFIG: MatDialogConfig = {
  width: '340px',
  disableClose: true,
  panelClass: 'custom-full-all-modal-overlay',
};

export const FORGOT_PASSWORD_CONFIRM_MODAL_CONFIG: MatDialogConfig = {
  width: '432px',
  panelClass: 'custom-full-all-modal-overlay',
};

export const RESPONSIVE_FORGOT_PASSWORD_CONFIRM_MODAL_CONFIG: MatDialogConfig = {
  panelClass: 'main-login-modal-responsive',
  width: '100vw',
  maxWidth: '100vw',
  height: '100vh'
};

export const MAIN_LOGIN_MODAL_CONFIG: MatDialogConfig = {
  width: '412px',
  disableClose: true,
  panelClass: 'custom-full-all-modal-overlay',
};

export const MAIN_LOGIN_AFILIACION_MODAL_CONFIG: MatDialogConfig = {
  width: '344px',
  panelClass: 'custom-full-all-modal-overlay',
  disableClose: true,
};

export const RESPONSIVE_MAIN_LOGIN_MODAL_CONFIG: MatDialogConfig = {
  panelClass: 'custom-full-all-modal-overlay',
};

