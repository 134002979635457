import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CanActivate } from "@angular/router";
import { INearestDrugstoresRequest } from "@models/nearest-drugstores/nearest-drugstores.model";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import firebase from "firebase/compat/app";
import { from, Observable, of } from "rxjs";
import { catchError, switchMap, take } from "rxjs/operators";
import { EResultScheduleTypes } from "src/app/business/checkout/parameters/delivery-time.parameter";
import { HomeImplementService } from "src/app/business/home/services/home-implement.service";
import { AddressListStoreService } from "src/app/core/modules/address-manager/address-stores/address-list-store.service";
import { HereMapPlacesService } from "src/app/core/modules/here-maps/services/here-map-places.service";
import { UserGeolocationHelperService } from "src/app/core/modules/pick-up-in-store/pick-up-helpers/user-geolocation-helper.service";
import { NearestDrugstoresImplementService } from "src/app/core/modules/pick-up-in-store/pick-up-implements/nearest-drugstores-implement.service";
import { GeolocalizationDialogComponent } from "../../components/geolocalization-dialog/geolocalization-dialog.component";
import { GeolocationService } from "../../geolocation/geolocation.service";
import { MainLoaderService } from "../../helpers/main-loader.service";
import { AuthImplementService } from "../../implements/auth/auth-implement.service";
import { DELAY_TO_ALLOW_LOAD_PAGE, REDIRECT_STATUS } from "../../parameters/global";
import { RetValidateCartService } from "../../service/cart/ret-validate-cart.service";
import { CoverageLocationClientService } from "../../service/coverage-location/coverage-location-client.service";
import { AppStoreService } from "../../stores/app/app-store.service";
import { CoverageLocationStoreService } from "../../stores/coverage-location/coverage-location-store.service";
import { UserTokenStoreService } from "../../stores/user/user-token-store.service";
import { GuardValidatorService } from "./../../stores/app/guard-validator.service";
import { ActiveOrderFirebaseService } from "src/app/business/order/order-services/active-order-firebase.service";
import { LS_ID_FAVORITE_ADDRESS, LS_ADDRESS_SELECTED, LS_RAD_OR_RET, LS_CURRENT_ADDRESS, OT_ADDRESS_SELECTED } from "../../service/local-storage/constants/address.constant";

export interface IUserResponse {
	getIdToken: (state: boolean) => Promise<string>;
	[key: string]: any;
}

const DEFAULT_STORE = "1253";

@Injectable({
	providedIn: "root"
})
export class AuthGuard implements CanActivate {
	public checkLocalStoreVariable: boolean = false;
	isFirstTime: boolean;
	constructor(
		private _authImplementService: AuthImplementService,
		private mainLoaderService: MainLoaderService,
		private appStore: AppStoreService,
		private coverageLocationClient: CoverageLocationClientService,
		private coverageLocationStore: CoverageLocationStoreService,
		private guardValidatorService: GuardValidatorService,
		private _userStore: UserTokenStoreService,
		private _nearestDrugstoresImplement: NearestDrugstoresImplementService,
		private _storeProcess: RetValidateCartService,
		private dialog: MatDialog,
		private _HomeService: HomeImplementService,
		private _addressListStore: AddressListStoreService,
		private _userGeolocationHelperService: UserGeolocationHelperService,
		private _geolocationService: GeolocationService,
		private hereMapPlacesService: HereMapPlacesService,
		private retValidate: RetValidateCartService
	) {
		this.catchErrorCallback = this.catchErrorCallback.bind(this);
		this.defaultCallback = this.defaultCallback.bind(this);
	}

	canActivate(): Observable<boolean> {
		return this._authImplementService.currentUserObservable.pipe(
			switchMap((userResponse: firebase.User) => {
				if (userResponse) {
					if (OrderTakerValidator.isOrderTakerEnv) {
						if (this._userStore.authenticated() && location.pathname == "/login") {
							location.href = "/";
						}
					}
					this._authImplementService.userAuthState = userResponse;
					this.mainLoaderService.isAuthLoaded = true;
					if (!this.appStore.redirectLoginStatus || userResponse.isAnonymous) {
						return this.getValidatedUserResponse(userResponse);
					} else if (this.appStore.redirectLoginStatus === REDIRECT_STATUS.COMPLETED) {
						this.appStore.removeRedirectLoginStatus();
						return this.getValidatedUserResponse(userResponse);
					} else {
						// if (OrderTakerValidator.isOrderTakerEnv) {
						//   if (
						//     this._userStore.authenticated() &&
						//     location.pathname == "/login"
						//   ) {
						//     this._router.navigate(["/"]);
						//   } else return of(true);
						// }
						// REDIRECT_STATUS pending wait : Si demora mucho retirar para permitir acceso a la web
						setTimeout(() => {
							this.appStore.removeRedirectLoginStatus();
							return this.getValidatedUserResponse(userResponse);
						}, DELAY_TO_ALLOW_LOAD_PAGE);
					}
				} else {
					const anonymousObservable = from(this._authImplementService.anonymousLogin());
					return anonymousObservable.pipe(
						switchMap((authResponse: firebase.auth.UserCredential) => {
							this._authImplementService.userAuthState = authResponse.user;
							this.mainLoaderService.isAuthLoaded = true;
							return this.getValidatedUserResponse(authResponse.user);
						}),
						catchError(this.catchErrorCallback)
					);
				}
			}),
			catchError(this.catchErrorCallback)
		);
	}

	public async getValidatedUserResponse(user?: firebase.User) {
		try {
			//await this.consultGeolocalization();
			if (!OrderTakerValidator.isOrderTakerEnv()) {
				await this.loadPurchase();
			}
			const token = await from(user.getIdToken(false)).toPromise();
			const coordAddresCurrent = JSON.parse(this.appStore.getStorageItem(OrderTakerValidator.isOrderTakerEnv() ? OT_ADDRESS_SELECTED : LS_CURRENT_ADDRESS));
			const coordsGeo = JSON.parse(this.appStore.getStorageItem("coordsGeo")) || null;
			const coordsUser = coordAddresCurrent && Object.keys(coordAddresCurrent).length > 0 ? coordAddresCurrent : coordsGeo;
			this.appStore.currentCoords =
				// coordsUser ? { latitude: coordsUser?.latitude ?? 0, longitude: coordsUser?.longitude ?? 0 }  :
				{ latitude: 0, longitude: 0 };
			this.appStore.userToken = token;
			this.guardValidatorService.setIsGuardEndpoint(true);

			const coverageLocationResponse = await this.coverageLocationClient
				.getValidatedCoverage$(this.appStore.currentCoords)
				.toPromise();
			this.guardValidatorService.setIsGuardEndpoint(false);
			this.coverageLocationStore.setCoverageLocationResponse(coverageLocationResponse);
			const { favorite } = coverageLocationResponse;
			const { current } = coverageLocationResponse;
			const selectDrugstore = JSON.parse(
				this.appStore.getStorageItem(OrderTakerValidator.isOrderTakerEnv() ? "OT_STORE_SELECTED" : "drugstoreSelected")
			);

			const drStock = this.appStore.getStorageItem("drugstore-stock");
			const drugstore_stock = drStock ? JSON.parse(drStock) : "";
			if (OrderTakerValidator.isOrderTakerEnv()) {
				this.appStore.drugstoreStock = this.appStore.drugstoreStock ? this.appStore.drugstoreStock : favorite.drugstoreStockId;
				const addressId =
					coverageLocationResponse?.favorite?.userAddressId !== "" ? coverageLocationResponse?.favorite?.userAddressId : "-1";
				localStorage.removeItem(LS_ID_FAVORITE_ADDRESS);
				localStorage.setItem(LS_ID_FAVORITE_ADDRESS, addressId);
				return true;
			}
			// RAD
			if (this._storeProcess.isRADProcess && favorite?.inCoverage && favorite?.enabled) {
				await this.handledRad(favorite, selectDrugstore);
				return true;
			} else {
				// RET
				await this.handledRet(selectDrugstore, drugstore_stock, favorite, current);
				return true;
			}
		} catch (error) {
			return false;
		}
	}

	openGeolocalizationDialog() {
		this.dialog.open(GeolocalizationDialogComponent, {
			width: "420px",
			data: {},
			panelClass: "modal-in-footer",
			disableClose: true
		});
	}

	private catchErrorCallback() {
		this.mainLoaderService.isLoaded = true;
		return of(false);
	}

	private defaultCallback() {
		this.appStore.drugstoreStock = DEFAULT_STORE;
		this.appStore.drugstoreRetStock = DEFAULT_STORE;
		this.mainLoaderService.isLoaded = false;
		return of(true);
	}

	callDrugstoreOne(lat, long): Observable<string> {
		return this._nearestDrugstoresImplement
			.getSelectedDrugstores$({
				latitude: lat,
				longitude: long,
				companyCode: this.appStore.companyCode,
				shoppingCart: []
			} as INearestDrugstoresRequest)
			.pipe(
				switchMap((drugstoreOne) => {
					if (drugstoreOne.length > 0) {
						const drugstoreWareHouseId = drugstoreOne[0].drugstoreWareHouseId.toString();
						this.appStore.setStorageItem("drugstoreWareHouseId", drugstoreWareHouseId);
						return of(drugstoreWareHouseId);
					} else {
						return of(null);
					}
				}),
				catchError(this.defaultCallback)
			);
	}

	async handledRad(favorite, selectDrugstore) {
		this.appStore.drugstoreStock = favorite?.drugstoreWareHouseId;
		if (selectDrugstore) {
			this.appStore.drugstoreRetStock = selectDrugstore.drugstoreWareHouseId.toString();
		} else {
			const result = await this.callDrugstoreOne(favorite?.latitude, favorite?.longitude).toPromise();
			this.appStore.drugstoreRetStock = result;
		}
	}
	async handledRet(selectDrugstore, drugstore_stock, favorite, current) {
		if (selectDrugstore) {
			this.appStore.drugstoreRetStock = selectDrugstore?.drugstoreWareHouseId;
			this.appStore.drugstoreStock = drugstore_stock;
		} else {
			if (favorite?.userAddressId !== "" && !favorite?.inCoverage && !favorite?.enabled) {
				const result = await this.callDrugstoreOne(favorite?.latitude, favorite?.longitude).toPromise();
				this.appStore.drugstoreRetStock = result;
				this.appStore.drugstoreStock = favorite.drugstoreStockId;
			}
			if (current?.latitude !== 0 && current?.longitude !== 0) {
				const result = await this.callDrugstoreOne(current?.latitude, current?.longitude).toPromise();
				this.appStore.drugstoreRetStock = result;
				this.appStore.drugstoreStock = current?.drugstoreStockId;
			} else {
				this.appStore.drugstoreStock = current?.drugstoreStockId;
				this.appStore.drugstoreRetStock = current?.drugstoreStockId;
				this.appStore.setStorageItem("drugstoreWareHouseId", current?.drugstoreStockId);
			}
		}
	}

	async loadPurchase(): Promise<void> {
		return new Promise<void>(async (resolve) => {
			const isFirstTimeValue = localStorage.getItem("isFirstTime");
			this.isFirstTime = isFirstTimeValue === null || isFirstTimeValue === "true";
			if (this.isFirstTime) {
				await this.loadPurchaseIntentService();
			} else {
				this.checkLocalStoreVariable = this._addressListStore.checkLocalStorageVariables();
			}
			resolve();
		});
	}

	async loadPurchaseIntentService(): Promise<void> {
		try {
			const res = await this._HomeService.getPurchaseIntent().toPromise();
			if (res.statusCode === "1") {
				const deliveryType = res.userDeliveryDto.find((dto) => dto.favorite);
				if (deliveryType && this.appStore.getStorageItem(LS_RAD_OR_RET)) {
					let deliveryTypeCode: string;
					if (deliveryType.serviceType === EResultScheduleTypes.RET) {
						deliveryTypeCode = EResultScheduleTypes.RET;
					} else {
						deliveryTypeCode = EResultScheduleTypes.RAD;
					}
					this._storeProcess.storeProcessType(deliveryTypeCode);
					if (deliveryTypeCode === EResultScheduleTypes.RET) {
						this.setValueDrugstore(res);
						this.appStore.setStorageItem(LS_ADDRESS_SELECTED, "false");
						this.firtsTime();
					} else {
						this.appStore.setStorageItem(LS_ADDRESS_SELECTED, "true");
						this.setValueDrugstore(res);
						this.firtsTime();
					}
				}
			} else {
				this.appStore.setStorageItem(LS_ADDRESS_SELECTED, "true");
				this.checkLocalStoreVariable = false;
			}
		} catch (error) {
			this.appStore.setStorageItem(LS_ADDRESS_SELECTED, "true");
			this.checkLocalStoreVariable = false;
		}
	}

	setValueDrugstore(res) {
		const deliveryType = res.userDeliveryDto.find((dto) => dto.serviceType === EResultScheduleTypes.RET);
		const selectDrugstore = JSON.parse(this.appStore.getStorageItem("drugstoreSelected"));
		if (deliveryType && !selectDrugstore) {
			const drugstore = {
				address: deliveryType.drugstoreAddress,
				name: deliveryType.drugstoreName,
				defaultDrugstore: true,
				distance: 0,
				id: 0,
				drugstoreWareHouseId: parseInt(deliveryType.drugstoreWarehouseId),
				localCode: deliveryType.drugstoreLocalCode,
				isLoading: null,
				latitude: 0,
				longitude: 0,
				localOpeningHours: deliveryType.drugstoreLocalCode
			};
			this.appStore.removeStorageItem("drugstoreSelected");
			this.appStore.setStorageItem("drugstoreSelected", JSON.stringify(drugstore));
		}
	}

	firtsTime() {
		this.isFirstTime = false;
		localStorage.setItem("isFirstTime", "false");
		this.checkLocalStoreVariable = this._addressListStore.checkLocalStorageVariables();
	}

	async consultGeolocalization(): Promise<{ latitude: number; longitude: number } | string> {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await this._userGeolocationHelperService.getGeolocationStatusSTANDAR().toPromise();
				if (res.state === "denied") {
					this.appStore.removeStorageItem("coordsGeo");
					resolve("Permission denied");
				} else {
					const coordsGeo = JSON.parse(this.appStore.getStorageItem("coordsGeo"));
					if (coordsGeo === null) {
						this.hereMapPlacesService
							.getFormattedGeolocationAddress$()
							.pipe(take(1))
							.subscribe((data) => {
								if (data !== null) {
									const coords = {
										latitude: data?.position?.lat || 0,
										longitude: data?.position?.lng || 0,
										status: "ok"
									};
									this.appStore.setStorageItem("coordsGeo", JSON.stringify(coords));
									resolve({ latitude: data?.position?.lat, longitude: data?.position?.lng });
								} else {
									resolve({ latitude: 0, longitude: 0 });
								}
							});
					} else {
						resolve({ latitude: 0, longitude: 0 });
					}
				}
			} catch (error) {
				reject(error);
			}
		});
	}
}
