import { FormControl, Validators } from "@angular/forms";
import { DOCUMENT_TYPE } from "@parameters/global";
import { getErrorbyFormControl } from "../helpers/error-controls-helpers";
import { CONFIG_USER_NOT_REGISTERED } from "../parameters/enums";
import {
  CE_ERROR_MESSAGES,
  DNI_ERROR_MESSAGES,
  IControlErrorMessages,
  PASSPORT_ERROR_MESSAGES,
} from "../parameters/global-error-message";
import { GenericValidator } from "../validators/generic-validator";
import { DocumentTypeControl } from "./document-type-control";

export interface IDocumentObjectType {
  validValues: any[];
  validationType: string;
}

export const DOCUMENT_OBJECT_TYPES: IDocumentObjectType[] = [
  {
    validValues: ["DNI", "dni", DOCUMENT_TYPE.DNI],
    validationType: "setDniValidation",
  },
  {
    validValues: ["CE", "C.E.", "Carnet de extranjería", DOCUMENT_TYPE.CE],
    validationType: "setCEValidation",
  },
  {
    validValues: ["Pasaporte", "pasaporte", DOCUMENT_TYPE.PAS],
    validationType: "setPassportValidation",
  },
];

export class CrossDocumentOrderTakerControl extends FormControl {
  private documentTypeControl: DocumentTypeControl;
  private errorMessages: IControlErrorMessages;

  constructor() {
    super("");
  }

  /**
   * Es obligatorio setear el documentTypeControl
   */
  public setDocumentTypeControl(control: DocumentTypeControl) {
    this.documentTypeControl = control;
    this.documentTypeControl.valueChanges.subscribe((value) => {
      const matchedObject = DOCUMENT_OBJECT_TYPES.find((object) => {
        return object.validValues.includes(String(value));
      });
      if (matchedObject) {
        this.removeValidatorsAndUpdate();
        this[matchedObject.validationType]();
      }
    });
  }

  public get documentError() {
    return !this.errorMessages
      ? ""
      : getErrorbyFormControl(this, this.errorMessages);
  }

  // setting validators for DNI
  public setDniValidation() {
    this.setValidators([
      GenericValidator.validateDni,
      // Validators.required,
      Validators.minLength(CONFIG_USER_NOT_REGISTERED.DNI_LENGTH),
      Validators.maxLength(CONFIG_USER_NOT_REGISTERED.DNI_LENGTH),
    ]);
    this.errorMessages = DNI_ERROR_MESSAGES;
    this.updateValueAndValidity();
  }

  // setting validators for CE
  public setCEValidation() {
    this.setValidators([
      GenericValidator.validateCharactersAndNumbers,
      Validators.required,
      Validators.maxLength(CONFIG_USER_NOT_REGISTERED.CE_MAX_LENGTH),
    ]);
    this.errorMessages = CE_ERROR_MESSAGES;
    this.updateValueAndValidity();
  }

  // setting validators for Passport
  public setPassportValidation() {
    this.setValidators([
      GenericValidator.validateCharactersAndNumbers,
      Validators.required,
      Validators.maxLength(CONFIG_USER_NOT_REGISTERED.PASSPORT_MAX_LENGTH),
    ]);
    this.errorMessages = PASSPORT_ERROR_MESSAGES;
    this.updateValueAndValidity();
  }

  public removeValidatorsAndUpdate() {
    this.clearValidators();
    this.updateValueAndValidity();
  }
}
