import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fp-button-brand[brandIcon]',
  templateUrl: './button-brand.component.html',
  styleUrls: ['./button-brand.component.sass']
})

export class ButtonBrandComponent {

  @Input() brandId: string = 'button-brand';
  @Input() brandIcon: string = 'brand';
  @Input() brandType: 'default' | 'icon' = 'default';
  @Input() brandDisabled: string;
  @Input() brandLoading: string;
  @Output() onclick = new EventEmitter();
  @Input() brandWidth: 'default' | 'full' = 'default';

  constructor() {
  }

  public clickEvent(event) {
    this.onclick.emit(event);
  }
}
