import { Injectable } from '@angular/core';
import { GenericContentfulService, GenericService } from '../generic';
import { CONTENTFUL_PATH, ENDPOINTS } from '../../parameters/endpoints';
import { CONTENTFUL_QUERIES } from '../../parameters/querys';
import { EContentfulDashboardShowcasesSection, IContentfulDashboardShowcases } from '../../../core/contentful/models/contentful-dashboard-showcases.model';
import { Observable } from 'rxjs';
import { IProductDefault } from '../../models/product-default/product-default.interface';
import { AppStoreService } from '../../stores/app/app-store.service';
import { ClientStoreService } from '@stores/client/client-store.service';
import { UserClientService } from '../user/user-client.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchLandingDashboardClientService {

  private searchLandingSection = EContentfulDashboardShowcasesSection.searchLanding;
  private searchCategory = 'search';
  pathExtra = "&whitelabel=&email=";
  pathExtra2 = "&u="


  constructor(
    private _GenericContentfulService: GenericContentfulService,
    private genericService: GenericService,
    private _appStoreService: AppStoreService,
    private _userClientService: UserClientService
  ) {
  }

  public getSearchDashboard() {
    return this._GenericContentfulService.getEntriesPromise<IContentfulDashboardShowcases>(
      CONTENTFUL_PATH.contentTypeIds.dashboardShowcases,
      CONTENTFUL_QUERIES.prototype.getEntryBySection(this.searchLandingSection));
  }

  public getSearchKeyword(keyword: string) {
    return this._GenericContentfulService.getEntries(
      CONTENTFUL_PATH.contentTypeIds.staticLanding,
      {
        ...CONTENTFUL_QUERIES.prototype.getEntryBySlug(this.searchCategory),
        'fields.searchKeyword': keyword
      }
    );
  }

  loadUserRegister() {
    return new Promise(resolve => {
      this._userClientService.getUser$()
        .pipe(take(1)).subscribe(data => {
          if (data['data']) {
            resolve(data['data'])
          } else {
            resolve('')
          }
        });
    })
  }

  public async getContenidosBrainHome(path = 'home/1//&guid=', sectionID) {
    const client: any = await this.loadUserRegister();
    const { drugstoreStock } = this._appStoreService;
    let url = ENDPOINTS.GET_LIST_CONTENIDOS + (drugstoreStock ? drugstoreStock : '') + this.pathExtra + (client.email || '') + this.pathExtra2 + path + `&sectionID=${sectionID}`
    const data = await this.genericService.genericGetWithoutHeader(url).toPromise();
    return data;
  }

  public getProductsByIds(ids: Array<string>): Observable<Array<IProductDefault>> {
    const idsArray = ids.toString();
    return this.genericService.genericGet(ENDPOINTS.GET_PRODUCTS_BY_IDS + idsArray);
  }
}
