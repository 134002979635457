import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { WhatsappInCartAliviamedComponent } from "../Component/whatsapp-sale-in-cart-aliviamed.component";

@Injectable({
  providedIn: "root",
})
export class WhatsappInCartAliviamedModalService {
  constructor(private dialog: MatDialog) {}

  showModalAliviamedInCart() {
    setTimeout(() => {
      this.dialog.open(WhatsappInCartAliviamedComponent, {
        width: "400px",
        disableClose: true,
        panelClass: "custom-full-all-modal-overlay",
      });
    }, 100);
  }
  closeModal() {
    this.dialog.closeAll();
  }
}
