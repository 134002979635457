import { MatDialogConfig } from "@angular/material/dialog";

export const OFFICE_MODAL_CONFIG: MatDialogConfig = {
  width: "816px",
  maxWidth:"816px",
  height: "auto",
  disableClose: true,
  panelClass:[ "custom-full-all-modal-overlay","office-modal"],
};

export const SELECT_OFFICE_MODAL_CONFIG: MatDialogConfig = {
  width: "1024px",
  height: "auto",
  disableClose: true,
  panelClass: "custom-full-all-modal-overlay",
};
