import { Injectable } from '@angular/core';
import { User } from '../../models';
import { USER_INFO_MESSAGES } from 'src/app/shared/parameters/user-info-message';
import { BehaviorSubject, Observable } from 'rxjs';
import { ECurrentUserStatus, ICurrentUser, RegisterResponse, UserRegister } from '../../models/register/register.model';

const DEFAULT_CURRENT_USER = {
  status: ECurrentUserStatus.PENDING,
  userResponse: null,
} as ICurrentUser;

@Injectable()
export class UserInfoStoreService {
  public user: User;
  public userInfo$ = new BehaviorSubject<User>(null);
  private authStateSubject = new BehaviorSubject<UserRegister>({} as UserRegister);

  public shoppingMode: string = USER_INFO_MESSAGES.ANONYMOUS;

  private _currentUser: ICurrentUser;
  private _currentUserSubject = new BehaviorSubject<ICurrentUser>(DEFAULT_CURRENT_USER);


  // Implementación para corregir el bug de performance
  // multiples requests al servicio de users
  private lastUserDataResponse: BehaviorSubject<RegisterResponse> = new BehaviorSubject(null);
  private lastRequestTime: number;

  set lastUserData(response: RegisterResponse) {
    this.lastUserDataResponse.next(response)
  }

  get lastUserData(): RegisterResponse {
    return this.lastUserDataResponse.getValue();
  }

  /**
   * la información del usuario siempre debe venir en el formato: UserRegister.
   * Para validar si es un usuario anónimo o logueado, debemos usar
   * este observable, y según su estado sabremos que hacer
   */
  public currentUser$ = this._currentUserSubject.asObservable();
  public get currentUser() {
    return this._currentUser;
  }

  constructor() {
    this.currentUser$.subscribe(user => this._currentUser = user);
  }

  /**
   * la información se debe setear una sola vez
   */
  public setCurrentUser(currentUser: ICurrentUser) {
    this._currentUserSubject.next(currentUser);
  }

  public get isLogged() {
    return !!this.user;
  }

  public getUser() {
    return this.user;
  }

  public setUser(param: User) {
    this.userInfo$.next(param);
    return (this.user = param);
  }

  public getShoppingMode() {
    return this.shoppingMode;
  }

  public setShoppingMode(param) {
    return (this.shoppingMode = param);
  }

  authStateObservable(): Observable<UserRegister> {
    return this.authStateSubject.asObservable();
  }

  getAuthState(){
    return this.authStateSubject.getValue();
  }

  updateAuthState(userRegister: UserRegister) {
    this.authStateSubject.next(userRegister);
  }
}
