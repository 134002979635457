
export * from './generic';

export * from './checkout/checkout-client.service';
export * from './product/product-client.service';
export * from './header/header-client.service';
export * from './footer/footer-client.service';
export * from './firebase/auth/auth-client.service';
export * from './customer-service/customer-service.service';
export * from './static-landing/static-landing-client.service';
export * from './coverage-areas/coverage-areas-client.service';
export * from './suggestions-and-enquiries/suggestions-and-enquiries-client.service';


import { GENERIC_SERVICES } from './generic';

import { ShoppingCartImplementService } from './shopping-cart/shopping-cart-implement.service';
import { CheckoutClientService } from './checkout/checkout-client.service';
import { HomeClientService } from './home/home-client.service';
import { ProductClientService } from './product/product-client.service';
import { HeaderClientService } from './header/header-client.service';
import { FooterClientService } from './footer/footer-client.service';
import { MenuCategoriesClientService } from './menu-categories/menu-categories-client.service';
import { AuthClientService } from './firebase/auth/auth-client.service';
import { CartClientService } from './cart/cart-client.service';
import { GoogleMapConfigClientService } from './google-map/google-map-config-client.service';
import { ShoppingCartRequestService } from './shopping-cart/shopping-cart-request.service';
import { ShoppingCartLoaderService } from './shopping-cart/shopping-cart-loader.service';
import { RecoveringPasswordService } from './recovering-password/recovering-password.service';
import { AddressClientService } from './address/address-client.service';
import { UserAddressClientService } from './user-address/user-address-client.service';
import { ScheduleClientService } from './schedule/schedule-client.service';
import { CartConfirmationClientService } from './cart-confirmation/cart-confirmation-client.service';
import { UpdateAnonymousClientService } from './update-anonymous/update-anonymous-client.service';
import { DiscountCodeClientService } from './discount-code/discount-code-client.service';
import { CustomerServiceService } from './customer-service/customer-service.service';
import { StaticLandingClientService } from './static-landing/static-landing-client.service';
import { CoverageAreasClientService } from './coverage-areas/coverage-areas-client.service';
import { SuggestionsAndEnquiriesClientService } from './suggestions-and-enquiries/suggestions-and-enquiries-client.service';
import { SearchLandingClientService } from './search-landing/search-landing-client.service';
import { FilteredProductClientService } from './filtered-products/filtered-product-client.service';
import { AnonymousRegisterImplementService } from './anonymous-register/anonymous-register-implement.service';
import { CategoriesLandingClientService } from './categories-landing/categories-landing-client.service';
import { WorldClientService } from './world/world-client.service';
import { CategoriesLandingDashboardClientService } from './categories-landing/categories-landing-dashboard-client.service';
import { SearchLandingDashboardClientService } from './search-landing/search-landing-dashboard-client.service';
import { CartDashboardClientService } from './cart/cart-dashboard-client.service';
import { ProductDetailDashboardClientService } from './product-detail/product-detail-dashboard-client.service';
import { OneDocThreeClientService } from './one-doc-three/one-doc-three-client.service';
import { UserClientService } from './user/user-client.service';
import { CampaignImplementService } from './campaign/campaign-implement.service';
import { CampaignClientService } from './campaign/campaign-client.service';
import { InformativeService } from './informative/informative.service';
import { NearestDrugstoresClientService } from './nearest-drugstores/nearest-drugstores-client.service';
import { SeoClientService } from './seo/seo-client.service';
import { BlogService } from './blog/blog.service';
import { AnalyticServiceService } from './analitycService/analitycService.service';
import { ShowModalBoticaService } from './showModalServices/showModalService.service';
import { NewAddressManagerService } from 'src/app/core/modules/address-manager/address-services/new-address-manager/new-address-manager.service';
import { CheckoutAddressItemsFormService } from 'src/app/core/modules/address-manager/address-forms/checkout-address-items-form.service';
import { AddressManagerPersistanceService } from 'src/app/core/modules/address-manager/address-services/address-manager-persistance.service';
import { CouponsStoreService } from 'src/app/business/checkout/checkout-stores/coupons-store.service';
import { VideoModalService } from './videoModalService/video-modal.service';


export const SERVICES = [
  ...GENERIC_SERVICES,
  CheckoutClientService,
  HomeClientService,
  ProductClientService,
  ProductDetailDashboardClientService,
  HeaderClientService,
  FooterClientService,
  MenuCategoriesClientService,
  AuthClientService,
  CartClientService,
  CartDashboardClientService,
  GoogleMapConfigClientService,
  CustomerServiceService,
  StaticLandingClientService,
  CoverageAreasClientService,
  SuggestionsAndEnquiriesClientService,
  SearchLandingClientService,
  SearchLandingDashboardClientService,
  FilteredProductClientService,
  CategoriesLandingClientService,
  CategoriesLandingDashboardClientService,

  WorldClientService,
  OneDocThreeClientService,
  UserClientService,
  CampaignClientService,
  CampaignImplementService,
  ShoppingCartImplementService,
  ShoppingCartRequestService,
  ShoppingCartLoaderService,
  AddressClientService,
  UserAddressClientService,
  ScheduleClientService,
  CartConfirmationClientService,
  UpdateAnonymousClientService,
  DiscountCodeClientService,
  RecoveringPasswordService,
  AnonymousRegisterImplementService,
  InformativeService,
  NearestDrugstoresClientService,
  SeoClientService,
  BlogService,
  AnalyticServiceService,
  ShowModalBoticaService,
  NewAddressManagerService,
  CheckoutAddressItemsFormService,
  AddressManagerPersistanceService,
  CouponsStoreService,
  VideoModalService
];
