import { Injectable } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { CrossDocumentOrderTakerControl } from "src/app/shared/controls/cross-document-order-taker-control";
import { DocumentTypeControl } from "src/app/shared/controls/document-type-control";
import { FatherLastNameControl } from "src/app/shared/controls/father-last-name-control";
import { FirstNameControl } from "src/app/shared/controls/first-name-control";
import { MotherLastNameOrderTakerControl } from "src/app/shared/controls/mother-last-name-order-taker-control";
import { PhoneNumberOrderTakerControl } from "src/app/shared/controls/phone-number-order-taker-control";
import { Client } from "src/app/shared/models/client/client.model";
import {
  ClientManagerBaseRequest,
  ClientManagerDataForm,
  DOCUMENT_TYPE_DNI,
} from "../paramaters/client-manager.const";
import { EmailOrderTakerControl } from "src/app/shared/controls/email-order-taker-control";

@Injectable()
export class ClientManagerFormService {
  public form: FormGroup;

  private _documentControl = new CrossDocumentOrderTakerControl();
  private _documentTypeControl = new DocumentTypeControl(DOCUMENT_TYPE_DNI.id);
  private _namesControl = new FirstNameControl();
  private _paternalLastnameControl = new FatherLastNameControl();
  private _maternalLastnameControl = new MotherLastNameOrderTakerControl();
  private _emailAddressControl = new EmailOrderTakerControl();
  private _phoneNumberControl = new PhoneNumberOrderTakerControl();
  private _phoneNumberOneControl = new FormControl();
  private _phoneNumberTwoControl = new FormControl();
  private _termsAndPoliticsControl = new FormControl(false);
  private _dataTreatmentControl = new FormControl();
  private _politicsPrivacytControl = new FormControl();

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      document: this._documentControl,
      identityDocumentType: this._documentTypeControl,
      names: this._namesControl,
      paternalLastname: this._paternalLastnameControl,
      maternalLastname: this._maternalLastnameControl,
      emailAddress: this._emailAddressControl,
      phoneNumber: this._phoneNumberControl,
      phoneNumberOne: this._phoneNumberOneControl,
      phoneNumberTwo: this._phoneNumberTwoControl,
      termsAndPolitics: this._termsAndPoliticsControl,
      dataTreatment: this._dataTreatmentControl,
      politicsPrivacy:this._politicsPrivacytControl
    });
    this.documentControl.setDocumentTypeControl(this.documentTypeControl);
  }

  setDataForm(data: ClientManagerDataForm) {
    this.form.setValue(data);
  }

  public resetForm() {
    this.documentControl.setValue("");
    this.documentControl.markAsUntouched();
    this.documentControl.markAsPristine();
    this.documentTypeControl.setValue(DOCUMENT_TYPE_DNI.id);
    this.documentTypeControl.markAsUntouched();
    this.documentTypeControl.markAsPristine();
    this.namesControl.setValue("");
    this.namesControl.markAsUntouched();
    this.namesControl.markAsPristine();
    this.paternalLastnameControl.setValue("");
    this.paternalLastnameControl.markAsUntouched();
    this.paternalLastnameControl.markAsPristine();
    this.maternalLastnameControl.setValue("");
    this.maternalLastnameControl.markAsUntouched();
    this.maternalLastnameControl.markAsPristine();
    this.emailAddressControl.setValue("");
    this.emailAddressControl.markAsUntouched();
    this.emailAddressControl.markAsPristine();
    this.phoneNumberControl.setValue("");
    this.phoneNumberControl.markAsUntouched();
    this.phoneNumberControl.markAsPristine();
    this.phoneNumberOneControl.setValue("");
    this.phoneNumberOneControl.markAsUntouched();
    this.phoneNumberOneControl.markAsPristine();
    this.phoneNumberTwoControl.setValue("");
    this.phoneNumberTwoControl.markAsUntouched();
    this.phoneNumberTwoControl.markAsPristine();
    this.termsAndPoliticsControl.setValue("");
    this.termsAndPoliticsControl.markAsUntouched();
    this.termsAndPoliticsControl.markAsPristine();
    this.dataTreatmentControl.setValue("");
    this.dataTreatmentControl.markAsUntouched();
    this.dataTreatmentControl.markAsPristine();
    this.politicsPrivacyControl.setValue("");
    this.politicsPrivacyControl.markAsUntouched();
    this.politicsPrivacyControl.markAsPristine();


  }

  public get documentControl() {
    return this.form.get("document") as CrossDocumentOrderTakerControl;
  }
  public get documentTypeControl() {
    return this.form.get("identityDocumentType") as DocumentTypeControl;
  }
  public get namesControl() {
    return this.form.get("names") as FirstNameControl;
  }
  public get paternalLastnameControl() {
    return this.form.get("paternalLastname") as FatherLastNameControl;
  }
  public get maternalLastnameControl() {
    return this.form.get("maternalLastname") as MotherLastNameOrderTakerControl;
  }
  public get emailAddressControl() {
    return this.form.get("emailAddress") as EmailOrderTakerControl;
  }
  public get phoneNumberControl() {
    return this.form.get("phoneNumber") as PhoneNumberOrderTakerControl;
  }
  public get phoneNumberOneControl() {
    return this.form.get("phoneNumberOne") as FormControl;
  }
  public get phoneNumberTwoControl() {
    return this.form.get("phoneNumberTwo") as FormControl;
  }
  public get termsAndPoliticsControl() {
    return this.form.get("termsAndPolitics") as FormControl;
  }
  public get dataTreatmentControl() {
    return this.form.get("dataTreatment") as FormControl;
  }

  public get politicsPrivacyControl() {
    return this.form.get("politicsPrivacy") as FormControl;
  }

  public validAllFields() {
    this.namesControl.markAsTouched();
    this.paternalLastnameControl.markAsTouched();
    this.phoneNumberControl.markAsTouched();
  }

  public disableFields(){
    this.namesControl.disable()
    this.paternalLastnameControl.disable()
    this.maternalLastnameControl.disable()
    this.emailAddressControl.disable()
    this.phoneNumberControl.disable()
    this.phoneNumberOneControl.disable()
    this.phoneNumberTwoControl.disable()
  }

  public enableFields(){
    this.namesControl.enable()
    this.paternalLastnameControl.enable()
    this.maternalLastnameControl.enable()
    this.emailAddressControl.enable()
    this.phoneNumberControl.enable()
    this.phoneNumberOneControl.enable()
    this.phoneNumberTwoControl.enable()
  }

  public get client() {
    return new Client({
      idIdentityDocument: this.documentTypeControl.value.toString(),
      identityDocumentNumber: this.documentControl.value
        .toString()
        .toUpperCase(),
      name: this.namesControl.value,
      lastNamePat: this.paternalLastnameControl.value,
      lastNameMat: this.maternalLastnameControl.value,
      email: this.emailAddressControl.value,
      phone: this.phoneNumberControl.value,
      phone2: this.phoneNumberOneControl.value,
      phone3: this.phoneNumberTwoControl.value,
      isAcceptTyC: this.termsAndPoliticsControl.value,
      isDataTreatment:this.dataTreatmentControl.value,
      isPrivacyPolicy:this.termsAndPoliticsControl.value,
    });
  }

  public get formatValue(): ClientManagerBaseRequest {
    const formatValue: ClientManagerBaseRequest = {
      idIdentityDocument: <string>this.documentTypeControl.value.toString(),
      identityDocumentNumber: (<string>(
        this.documentControl.value
      )).toUpperCase(),
      name: <string>this.namesControl.value,
      lastNamePat: <string>this.paternalLastnameControl.value,
      lastNameMat: <string>this.maternalLastnameControl.value,
      email: <string>this.emailAddressControl.value,
      phone: <string>this.phoneNumberControl.value,
      phone2: <string>this.phoneNumberOneControl.value,
      phone3: <string>this.phoneNumberTwoControl.value,
      isAcceptTyC: this.termsAndPoliticsControl.value,
      isDataTreatment: this.dataTreatmentControl.value,
      isPrivacyPolicy: this.termsAndPoliticsControl.value,
    };

    return formatValue;
  }
}
