import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { LayoutModule } from "@angular/cdk/layout";
import { MAT_DATE_LOCALE } from "@angular/material/core";

import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { FIREBASE_OPTIONS } from "@angular/fire/compat";
import { getDatabase, provideDatabase } from "@angular/fire/database";
import { MatDialogModule } from "@angular/material/dialog";
import { StoreModule } from "@ngrx/store";
import { ToastrModule } from "ngx-toastr";
import { environment } from "../environments/environment";
import { AppFactoryModule } from "./app-factory.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { IconsModule } from "./core/generic/icons/icons.module";
import { RegisterClientImplementService } from "./core/modules/header/services/register-client-implement.service";
import { appReducer } from "./core/store/reducer/app.reducer";
import { MainLoaderComponent } from "./loaders/main-loader/main-loader.component";
import { GlobalProductModule } from "./shared/implements/product/global-product.module";
import { OrderTakerInterceptorService } from "./shared/interceptor/order-taker-interceptor.service";
import { PipesModule } from "./shared/pipe/pipes.module";
import { AuthClientService } from "./shared/service";
import { AppManagerService } from "./shared/service/firebase/auth/app-manager.service";
import { QueryParamsInterceptorService } from "./shared/service/firebase/auth/query-params-interceptor.service";
import { TokenInterceptorService } from "./shared/service/firebase/auth/token-interceptor.service";
import { GenericContentfulService } from "./shared/service/generic";
import { SharedModule } from "./shared/shared.module";
import { CookiesPolicyComponent } from "./shared/components/cookies-policy/cookies-policy.component";
import { RegisterImplementService } from "./core/modules/register/register-services/register-implement.service";
import { UpdateAnonymousImplementService } from "./business/checkout/checkout-services/update-anonymous-implement.service";
import { ButtonsModule } from "./core/generic/buttons/buttons.module";
import { OfficeModule } from "./business/office/office.module";
import { AuthInterceptor } from "@service/coupons/auth.interceptor";
@NgModule({
  declarations: [
    AppComponent,
    MainLoaderComponent,
    CookiesPolicyComponent
  ],
    imports: [
        BrowserModule,
        LayoutModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        StoreModule.forRoot({appState: appReducer}),
        SharedModule,
        AppFactoryModule.forRoot(),
        ButtonsModule,
        IconsModule,
        MatDialogModule,
        GlobalProductModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        provideDatabase(() => getDatabase()),
        PipesModule,
        ToastrModule.forRoot(),
        OfficeModule
    ],
  providers: [
    AppManagerService,
    { provide: MAT_DATE_LOCALE, useValue: "es" },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    AuthClientService,
    GenericContentfulService,
    RegisterClientImplementService,
    RegisterImplementService,
    UpdateAnonymousImplementService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QueryParamsInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrderTakerInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent, MainLoaderComponent],
})
export class AppModule {}
