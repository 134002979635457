import { SnackbarService } from './snackbar.service';
import { Injectable } from '@angular/core';
import { BREAKPOINTS } from '../../../shared/parameters/global';
import { BreakpointBehavior } from '../../../shared/behaviors';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class SnackbarAlertInComingService {
  private alertDefaultConfig: MatSnackBarConfig = {};
  private alertConfigSmall: MatSnackBarConfig = {
    ...this.alertDefaultConfig,
    verticalPosition: 'top',
    horizontalPosition: 'center'
  };
  private alertConfigLarge: MatSnackBarConfig = {
    ...this.alertDefaultConfig,
    verticalPosition: 'bottom',
    horizontalPosition: 'start'
  };

  constructor(private _snackbarService: SnackbarService,
    public _breakpointBehavior: BreakpointBehavior) {
  }

  private isBigScreen() {
    return this._breakpointBehavior.isMatchedMediaBreakpointUp(BREAKPOINTS.lg);
  }

  public openAlert(config: MatSnackBarConfig) {
    return this._snackbarService.snackBarAlertInComing(config);
  }

  public closeAlert() {
    this._snackbarService.matSnackbar.dismiss();
  }

  public snackBarAlert(message: string,
    type: 'success' | 'info' | 'error' | 'default' = 'default',
    additionalConfig: MatSnackBarConfig = {}, action?: string) {

    const alertConfig = this.isBigScreen() ? this.alertConfigLarge : this.alertConfigSmall;
    const data = { message: message, type: type, action: action };
    const config = { panelClass: type, data: data, ...alertConfig, ...additionalConfig };
    return this.openAlert(config);
  }

  /**
   * Snackbar de color amarillo
   */
  snackbarAlertInfo(message: string, config: MatSnackBarConfig = {}, action?: string) {
    return this.snackBarAlert(message, 'info', config, action);
  }

  /**
   * Snackbar de color verde
   */
  snackbarAlertSuccess(message: string, config: MatSnackBarConfig = {}, action?: string) {
    return this.snackBarAlert(message, 'success', config, action);
  }

  /**
   * Snackbar de color rojo
   */
  snackbarAlertError(message: string, config: MatSnackBarConfig = {}, action?: string) {
    return this.snackBarAlert(message, 'error', config, action);
  }
}
