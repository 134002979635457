import { Injectable } from '@angular/core';
import { INewAddressFormStore, INITIAL_NEW_ADDRESS_FORM_STORE } from '../models/new-address-form.interface';
import { AppStoreService } from 'src/app/shared/stores/app/app-store.service';
import { Subscription, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewAddressStoreService {

  private readonly NEW_ADDRESS_STORE_KEY = 'NAS';
  private form: INewAddressFormStore;
  private formSubject = new BehaviorSubject<INewAddressFormStore>(this.getFormStorage());

  private subscriptions: Subscription[] = [];

  public form$ = this.formSubject.asObservable();

  constructor(
    private appStore: AppStoreService,
  ) {
    // this subscribe will be activated for each 'this.formSubject.next'
    const formSubscription = this.form$
      .subscribe(form => {
        this.form = form;
        this.setFormStorage();
      });
    this.subscriptions.push(formSubscription);
  }

  public setDirectionValue(value: string) {
    this.setOneFormValue(value, 'direction');
  }

  public setReferenceValue(value: string) {
    this.setOneFormValue(value, 'reference');
  }

  public setAddressNicknameValue(value: string) {
    this.setOneFormValue(value, 'addressNickname');
  }

  public setOtherDirectionValue(value: string) {
    this.setOneFormValue(value, 'otherDirection');
  }

  public setOneFormValue(value: string, key: string) {
    const { form } = this;
    form[key] = value;
    this.formSubject.next(form);
  }

  public setFormStorage(): void {
    const { form, NEW_ADDRESS_STORE_KEY } = this;
    const stringFormatOfForm = JSON.stringify(form);
    this.appStore.setStorageItem(NEW_ADDRESS_STORE_KEY, stringFormatOfForm);
  }

  public getFormStorage(): INewAddressFormStore {
    const { NEW_ADDRESS_STORE_KEY } = this;
    const stringFromStorage = this.appStore.getStorageItem(NEW_ADDRESS_STORE_KEY);
    let jsonFormatOfForm = INITIAL_NEW_ADDRESS_FORM_STORE;
    if (stringFromStorage) {
      jsonFormatOfForm = JSON.parse(stringFromStorage) as INewAddressFormStore;
    }
    return jsonFormatOfForm;
  }

  public unsubscribeObservers() {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
