import * as moment from "moment";
import {
  RAD_DAY_FORMAT,
  RAD_TIME_FORMAT,
  RET_DAY_HOUR_FORMAT,
} from "../parameters/momentjs-formats.parameter";

export function getFormattedScheduleForRET(day: string, time: string) {
  moment.locale("es");
  return moment(`${day} ${time}`, "YYYY-MM-DD hh:mm").format(
    RET_DAY_HOUR_FORMAT
  );
}

export function getFormattedDayForRAD(day: string) {
  moment.locale("es");
  return moment(`${day}`, "YYYY-MM-DD").format(RAD_DAY_FORMAT);
}

export function getFormattedTime(time: string) {
  moment.locale("es");
  return moment(`${time}`, "HH:mm:ss").format(RAD_TIME_FORMAT);
}
