import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardModule } from '../../../core/modules/product-card/product-card.module';
import { GlobalProductImplementService } from './global-product-implement.service';


@NgModule({
  providers: [
    GlobalProductImplementService
  ],
  imports: [
    CommonModule,
    ProductCardModule
  ],
})
export class GlobalProductModule {
}
