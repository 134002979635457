import { Injectable } from '@angular/core';
import { GenericService } from '../generic';

import { ENDPOINTS } from '../../parameters/endpoints';

@Injectable()
export class UserAddressClientService {

  private readonly USER_ADDRESS_ENDPOINT = ENDPOINTS.USER_ADDRESS;

  constructor(
    private genericService: GenericService,
  ) { }

  public updateSelectedUserAddress$(addressId: string) {
    const endpointToUpdate = `${this.USER_ADDRESS_ENDPOINT}/${addressId}`;
    return this.genericService.genericPatch(endpointToUpdate, {});
  }
}
