import { Injectable } from '@angular/core';
import { SnackbarAlertService } from 'src/app/core/modules/snackbar/snackbar-alert.service';

@Injectable()
export class ProductManagerSnackbarsHelperService {

  constructor(
    private snackbarAlert: SnackbarAlertService,
  ) {}

  public productAddedSuccessfuly() {
    const p1 = `<b>¡Producto agregado al carrito!</b>`;
    const p2 = `Recuerda que solo puedes seguir comprando productos ofrecidos por el mismo vendedor.`;
    this.snackbarAlert.snackbarAlertSuccess(`${p1} ${p2}`);
  }
}
