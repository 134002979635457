
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { CONTENFUL_CUSTOM_KEYS } from "@parameters/contenful";
import { CONTENTFUL_PATH } from "@parameters/endpoints";
import { ContenfulConfigService } from "@service/contenful/contenful-config/conteful-config-.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { ADDRESSES_PREDICTIVE_DELAY } from "../../address-manager/parameters/global.parameter";

@Injectable({
	providedIn: "root"
})
export class HereMapConfigService {
    TIMER_DEFECT = ADDRESSES_PREDICTIVE_DELAY
	private hereMapConfigSubject = new BehaviorSubject<any>(null);
	hereMapConfig$ = this.hereMapConfigSubject.asObservable();
	constructor(private contenfulConfigService: ContenfulConfigService) { }

	public initializeHereMapConfig(): Observable<any> {
		const keyConfigurationSelected =
			environment.brand === EBrand.inkafarma
				? CONTENFUL_CUSTOM_KEYS.inkafarmaConfiguration.AUTOSUGGEST_CONF_ID
				: CONTENFUL_CUSTOM_KEYS.contentfulConfiguration.AUTOSUGGEST_CONF_ID;

		const contenfulPath =
			environment.brand === EBrand.inkafarma
				? CONTENTFUL_PATH.contentTypeIds.INKAFARMA_CONFIGURATION_KEY
				: CONTENTFUL_PATH.contentTypeIds.MIFARMA_CONFIGURATION_KEY;

		return this.contenfulConfigService.getCloudConfigurationByKey$<any>(keyConfigurationSelected, contenfulPath).pipe(
			map((entry) => {
				this.hereMapConfigSubject.next(entry.fields)
				console.log(entry)
				return entry.fields
			}),
			catchError((err) => {
				console.error(err);
				return of({} as any);
			})
		);
	}
	public getHereMapConfig() {
		return this.hereMapConfig$;
	}

	public setHereMapConfig(config) {
		return this.hereMapConfigSubject.next(config);
	}
	public getDebounceTime(): number {
		const config = this.hereMapConfigSubject.value;
		if (config) {
			const debounceTimeVar = config?.configuration?.debounceTime;
			if (OrderTakerValidator.isOrderTakerEnv()) {
				return debounceTimeVar?.tomador || this.TIMER_DEFECT;
			} else {
				return debounceTimeVar?.web || this.TIMER_DEFECT;
			}
		}
		return this.TIMER_DEFECT; // Valor por defecto si la configuración no está disponible
	}
}
