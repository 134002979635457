import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainFooter } from '../models/footer.model';
import { map } from 'rxjs/operators';
import { FooterClientService } from 'src/app/core/modules/footer/services/footer-client.service';

@Injectable({
  providedIn: 'root'
})
export class FooterImplementService {

  constructor(
    private _FooterClientService: FooterClientService
  ) {
  }

  getFooter(): Observable<MainFooter> {
    return this._FooterClientService.getFooter()
      .pipe(
        map(footerList => new MainFooter(footerList[0].content))
      );
  }
}
