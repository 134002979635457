import { Injectable } from '@angular/core';
import { ProductShoppingCartRequest } from '../../../../shared/models/shopping-cart/product-shopping-cart-request.model';
import { ShoppingListStoreService } from '../../../../shared/stores/shopping-list/shopping-list-store.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductManagerService {
  constructor(private _shoppingListStoreService: ShoppingListStoreService) {
  }

  public getProduct$(productId: string): Observable<ProductShoppingCartRequest> {
    const { _shoppingListStoreService } = this;
    return _shoppingListStoreService.shoppingList$
      .pipe(
        map(shoppingList => shoppingList
          .find(shoppingItem => shoppingItem.productId === productId))
      );
  }

  public getProduct(productId: string): ProductShoppingCartRequest {
    return this._shoppingListStoreService.staticShoppingList.find(value => {
      return value.productId === productId;
    });
  }

  public isProductInShoppingCart(productId: string) {
    return !!this.getProduct(productId);
  }

  public isAliviamedConsultationInShoppingCart(): boolean {
    return !!this._shoppingListStoreService.staticShoppingList.find(value => {
      return value.productAliviamed?.isMedicalConsultation;
    });
  }

  public addProduct(product: ProductShoppingCartRequest): void {
    const { _shoppingListStoreService } = this;
    const getStaticShoppingList = _shoppingListStoreService.getStaticShoppingList ? _shoppingListStoreService.getStaticShoppingList : [];
    let addProductNew = _shoppingListStoreService.staticShoppingList;
    if (getStaticShoppingList.length > 0) {
      addProductNew = this.filterNewProductInLocalStorage(getStaticShoppingList, product, _shoppingListStoreService);
    } else {
      addProductNew = _shoppingListStoreService.staticShoppingList = [
        ...getStaticShoppingList,
        product
      ];
    }
    this._shoppingListStoreService
      .setShoppingList(addProductNew);
  }

  filterNewProductInLocalStorage(getStaticShoppingList, product, _shoppingListStoreService) {
    let addProductNew;
    getStaticShoppingList.filter( resp => {
      if (resp.productId !== product.productId) {
        addProductNew = _shoppingListStoreService.staticShoppingList = [
          ...getStaticShoppingList,
          product
        ];
      } else {
        addProductNew = _shoppingListStoreService.staticShoppingList = [
          ...getStaticShoppingList
        ];
      }
    });
    return addProductNew;
  }

  public deleteProduct(productId: string): void {
    const { _shoppingListStoreService } = this;

    const updatedProductList = _shoppingListStoreService.getStaticShoppingList
      .filter(product => product.productId !== productId);

    this._shoppingListStoreService.setShoppingList(updatedProductList);
  }

  public updateProduct(product: ProductShoppingCartRequest): void {
    const { _shoppingListStoreService } = this;
    const updatedProductItem = _shoppingListStoreService.staticShoppingList
      .find(shoppingItem => shoppingItem.productId === product.productId);

    if (updatedProductItem) {
      updatedProductItem.presentationIdSelected = product.presentationIdSelected;
      updatedProductItem.quantity = product.quantity;

      this._shoppingListStoreService
        .setShoppingList(_shoppingListStoreService.staticShoppingList);
    }
  }

}
