export class FilteredProductsFilterSection {
  id: number;
  name: string;
  brands?: Array<FilteredProductsFilterSection>;
  filter?: Array<FilteredProductsFilterSection>;
}

export class FilteredProductsDefaultFilters {
  defaultDepartment: FilteredProductFilterBackUp;
  defaultCategory: FilteredProductFilterBackUp;
  defaultSubcategory: FilteredProductFilterBackUp;
}

// Default Filtered List
export class FilteredProductsFilteredList {
  departmentList: Array<number>;
  categoryList: Array<number>;
  subcategoryList: Array<number>;
  brandList: Array<number>;

  constructor(department?: Array<number>, category?: Array<number>, subcategory?: Array<number>, brand?: Array<number>) {
    this.departmentList = department || [];
    this.categoryList = category || [];
    this.subcategoryList = subcategory || [];
    this.brandList = brand || [];
  }
}

// Default Filter List Store
export class FilteredProductsFilterListBackUp {
  departmentList: Array<FilteredProductFilterBackUp>;
  categoryList: Array<FilteredProductFilterBackUp>;
  subcategoryList: Array<FilteredProductFilterBackUp>;
  brandList: Array<FilteredProductFilterBackUp>;
}

export class FilteredProductFilterBackUp {
  id: number;
  name: string;
  departments?: Array<number>;
  categories?: Array<number>;
  subcategories?: Array<number>;
  brands?: Array<number>;

  constructor(filteredProductsFilterSection: FilteredProductsFilterSection) {
    this.id = filteredProductsFilterSection.id || -1;
    this.name = filteredProductsFilterSection.name || '';
  }
}

export class FilteredProductsCustomFilter {
  id: number;
  name: string;
  checked: boolean;

  constructor(filteredProductFilterBackUp: FilteredProductFilterBackUp) {
    this.id = filteredProductFilterBackUp.id || -1;
    this.name = filteredProductFilterBackUp.name || '';
    this.checked = false;
  }
}

// Custom Filter List Store
export class FilteredProductsCustomFilterList {
  departmentList: Array<FilteredProductsCustomFilter>;
  categoryList: Array<FilteredProductsCustomFilter>;
  subcategoryList: Array<FilteredProductsCustomFilter>;
  brandList: Array<FilteredProductsCustomFilter>;

  constructor(filteredProductsFilterListBackUp: FilteredProductsFilterListBackUp) {
    this.departmentList = filteredProductsFilterListBackUp.departmentList.map(department => new FilteredProductsCustomFilter(department));
    this.categoryList = filteredProductsFilterListBackUp.categoryList.map(category => new FilteredProductsCustomFilter(category));
    this.subcategoryList = filteredProductsFilterListBackUp.subcategoryList.map(subcategory => new FilteredProductsCustomFilter(subcategory));
    this.brandList = filteredProductsFilterListBackUp.brandList.map(brand => new FilteredProductsCustomFilter(brand));
  }
}
