import { Injectable } from '@angular/core';
import { UserPickUp } from 'src/app/shared/models/pickup-store/pickup-store.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FIRST_OPTION, LAST_OPTION } from '../parameters/pick-up-for-third.parameter';
import { ESectionShowStatus } from '../parameters/ret-config.parameter';

@Injectable()
export class PeopleForPickUpStoreService {

  private _people: UserPickUp[];
  private _peopleSubject = new BehaviorSubject<UserPickUp[]>([] as UserPickUp[]);
  public people$ = this._peopleSubject.asObservable();
  public get people() { return this._people; }

  private _selectedPerson: UserPickUp;
  private _selectedPersonSubject = new BehaviorSubject<UserPickUp>({} as UserPickUp);
  public selectedPerson$ = this._selectedPersonSubject.asObservable();
  public get selectedPerson() { return this._selectedPerson; }

  private _temporalSelectedPerson: UserPickUp;
  private _temporalSelectedPersonSubject = new BehaviorSubject<UserPickUp>({} as UserPickUp);
  public temporalSelectedPerson$ = this._temporalSelectedPersonSubject.asObservable();
  public get temporalSelectedPerson() { return this._temporalSelectedPerson; }

  // this detail is to know whether third pick up is active
  private _shouldHaveSections = ESectionShowStatus.DEFAULT;
  private _shouldHaveSectionsSubject = new BehaviorSubject<ESectionShowStatus>(ESectionShowStatus.DEFAULT);
  public shouldHaveSections$ = this._shouldHaveSectionsSubject.asObservable();
  public get shouldHaveSections() { return this._shouldHaveSections; }

  constructor() {
    this.people$.subscribe((people) => this._people = people);
    this.selectedPerson$.subscribe((selectedPerson) => this._selectedPerson = selectedPerson);
    this.temporalSelectedPerson$.subscribe((selectedPerson) => this._temporalSelectedPerson = selectedPerson);
    this.shouldHaveSections$.subscribe((shouldHaveSections) => this._shouldHaveSections = shouldHaveSections);
  }

  public get peopleForSelect$() {
    return this.people$
      .pipe(map(people => {
        return [FIRST_OPTION, ...people, LAST_OPTION];
      }));
  }

  public setPeople(people: UserPickUp[]) {
    this._peopleSubject.next(people.map(p => {
      return { ...p, value: p.fullName };
    }));
    const selectedPerson = people.find(p => p.default);
    if (selectedPerson) {
      this.setSelectedPerson(selectedPerson);
    } else {
      this.setSelectedPerson(FIRST_OPTION);
    }
  }

  public setSelectedPerson(selectedPerson: UserPickUp) {
    this._selectedPersonSubject.next(selectedPerson);
  }

  public setTemporalSelectedPerson(selectedPerson: UserPickUp) {
    this._temporalSelectedPersonSubject.next(selectedPerson);
  }

  public setIfActiveThird(status: boolean) {
    const shouldHaveSections = status ? ESectionShowStatus.BOTH_DRUGSTORE_AND_THIRD : ESectionShowStatus.ONLY_DRUGSTORE;
    this._shouldHaveSectionsSubject.next(shouldHaveSections);
  }
}
