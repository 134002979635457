import { Component, Input, OnInit } from '@angular/core';
import { OrderTakerValidator } from '@validators/order-taker.validator';

@Component({
  selector: 'fp-section-container-title',
  templateUrl: './section-container-title.component.html',
  styleUrls: ['./section-container-title.component.sass']
})
export class SectionContainerTitleComponent implements OnInit {
  @Input() title = '';
  @Input() iconName = '';
  @Input() svgName = '';
  @Input() iconSize = 16;
  @Input() innerClass;
  @Input() unfilled;
  isOrderTaker = OrderTakerValidator.isOrderTakerEnv()

  constructor() { }

  ngOnInit() {
  }

}
