import {Component, OnInit} from '@angular/core';
import {StaticLanding} from '../../../models/static-landing.model';
import {TermsOfServiceImplementService} from '../service/terms-of-service-implement.service';
import {take} from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { STATIC_LANDING_SLUG } from 'src/app/business/static-landing/parameters/static-landing';
import { environment } from '@environments/environment';
import { EBrand } from '@parameters/access/brand.parameter';
import { CheckoutTypeOfDeliveryFormService } from 'src/app/business/checkout/checkout-forms/checkout-type-of-delivery-form.service';
import { CustomerServiceImplementService } from '../../customer-service/services/customer-service-implement.service';
import { FrequentlyAskedQuestion } from '../../customer-service/models/frequently-asked-question-list.model';

@Component({
  selector: 'fp-terms-of-service-dialog',
  templateUrl: './terms-of-service-dialog.component.html',
  styleUrls: ['./terms-of-service-dialog.component.sass']
})
export class TermsOfServiceDialogComponent implements OnInit {

  public termsOfService: StaticLanding;
  public informativeItem: FrequentlyAskedQuestion;

  public loader = true;


  constructor(
    private _termsOfServiceImplement: TermsOfServiceImplementService,
    public _dialogRef: MatDialogRef<TermsOfServiceDialogComponent>,
    private _customerServiceClient: CustomerServiceImplementService,
    public typeOfDeliveryForm: CheckoutTypeOfDeliveryFormService,
  ) {
  }

  ngOnInit() {
    this.onLoadData()
  }

  closeDialog() {
    this._dialogRef.close();
  }

  onLoadData(){
    const deliveryType = this.typeOfDeliveryForm.deliveyTypeControl.value as number;
    if (environment.brand === EBrand.mifarma && deliveryType === 1) {
      this._customerServiceClient.getFrequentlyAskedQuestionBySlug(STATIC_LANDING_SLUG.termsOfServiceDrugstore)
      .pipe(take(1))
      .subscribe((page) => {
        if (page) {
          this.informativeItem = page;
          setTimeout(() => {
            this.loader = false;
          }, 500);
        }
      });
    }else
      this._termsOfServiceImplement.getTermsOfService()
      .pipe(take(1))
      .subscribe((termsOfService: StaticLanding) => {
        this.termsOfService = termsOfService;
        this.loader = false;
      });
  }
}
