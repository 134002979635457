import { Injectable } from '@angular/core';
import { ENDPOINTS } from '../../parameters/endpoints';
import { Observable } from 'rxjs';
import { GenericService } from '../generic';
import { map } from 'rxjs/operators';
import { DiscountCodeResponse, IDiscountCodeResponse, IDiscountCodeRequestParams } from '../../models/discount-code/discount-code.model';

@Injectable()
export class DiscountCodeClientService {

  private readonly DISCOUNT_CODE_ENDPOINT = ENDPOINTS.DISCOUNT_CODE;

  constructor(
    private genericService: GenericService,
  ) { }

  public validateDiscountCode$(params: IDiscountCodeRequestParams): Observable<DiscountCodeResponse> {
    return this.genericService.genericPostCart<IDiscountCodeResponse>(this.DISCOUNT_CODE_ENDPOINT, params)
      .pipe(map(response => {
        const discountCodeResponse = new DiscountCodeResponse(response);
        return discountCodeResponse;
      }));
  }
}
