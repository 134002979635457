import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SelectedDrugstoreComponent } from "../view/selected-drugstore/selected-drugstore.component";

@Injectable()
export class SelecteDrugstoreModalService {
  private selectedDrugstoreModalRef: MatDialogRef<SelectedDrugstoreComponent>;

  constructor(private matDialog: MatDialog) {}

  public openSelectedDrugstoreModal() {
    this.selectedDrugstoreModalRef = this.matDialog.open(
      SelectedDrugstoreComponent,
      {
        disableClose: true,
      }
    );
  }
  public closeSelectedDrugstoreModal() {
    this.selectedDrugstoreModalRef.close();
  }
}
