import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CarouselTitleComponent } from "./utilities/carousel-title/carousel-title.component";
import { BannerTitleComponent } from "./utilities/banner-title/banner-title.component";
import { PaginationListComponent } from "./utilities/pagination-list/pagination-list.component";
import { CheckboxListComponent } from "./utilities/checkbox-list/checkbox-list.component";
import { BreadcrumbsComponent } from "./utilities/breadcrumbs/breadcrumbs.component";
import { BreadcrumbsOneComponent } from "./utilities/breadcrumbs/breadcrumbs-one/breadcrumbs-one.component";
import { RadioListComponent } from "./utilities/radio-list/radio-list.component";
import { OrderByComponent } from "./utilities/order-by/order-by.component";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../../shared/shared.module";
import { ItemListComponent } from "./utilities/item-list/item-list.component";
import { SwitchListComponent } from "./utilities/switch-list/switch-list.component";
import { SwitchComponent } from "./utilities/switch/switch.component";
import { DrawerModule } from "./drawer/drawer.module";
import { DisclaimerComponent } from "./utilities/disclaimer/disclaimer.component";
import { PurchaseSummarySubTotalMifaComponent } from "./purchase/purchase-summary-sub-total-mifa/purchase-summary-sub-total-mifa.component";
import { PurchaseSummaryTotalMifaComponent } from "./purchase/purchase-summary-total-mifa/purchase-summary-total-mifa.component";
import { CarouselTitleBrainComponent } from "./utilities/carousel-title-brain/carousel-title-brain.component";
import { DirectivesModule } from "src/app/shared/directives/directives.module";
import { GhostElementComponent } from "./utilities/ghost-element/ghost-element.component";
import { PurchaseSummaryStaticComponent } from "./purchase/purchase-summary-static/purchase-summary-static.component";
import { PurchaseSummaryCollapseComponent } from "./purchase/purchase-summary-collapse/purchase-summary-collapse.component";
import { PurchaseSummaryListComponent } from "./purchase/purchase-summary-list/purchase-summary-list.component";
import { PurchaseSummaryTotalComponent } from "./purchase/purchase-summary-total/purchase-summary-total.component";
import { PurchaseSummarySubTotalComponent } from "./purchase/purchase-summary-sub-total/purchase-summary-sub-total.component";
import { ButtonsModule } from "../generic/buttons/buttons.module";
import { InputsModule } from "../generic/inputs/inputs.module";
import { LazyImagesModule } from "../generic/lazy-images/lazy-images.module";
import { LocationSelectedComponent } from "./utilities/location-selected/location-selected.component";
import { IconsModule } from "../generic/icons/icons.module";
import { SnackbarModule } from "../modules/snackbar/snackbar.module";
import { ShowcaseComponent } from "./showcases/showcase/showcase.component";
import { AdaptiveImagePipe } from "../../shared/pipe";
import { ErrorStockModule } from "../modules/error-stock-product/error-stock.module";
import { ModalDialogComponent } from "./utilities/modal-dialog/modal-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { DisclaimerControlledComponent } from "./utilities/disclaimer-controlled/disclaimer-controlled.component";

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatMenuModule,
    NgbModule,
    RouterModule,
    FormsModule,
    SharedModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    ButtonsModule,
    InputsModule,
    LazyImagesModule,
    IconsModule,
    SnackbarModule,
    ErrorStockModule,
    DrawerModule,
    DirectivesModule,
    MatDialogModule
  ],
  declarations: [
    CarouselTitleComponent,
    BannerTitleComponent,
    PaginationListComponent,
    CheckboxListComponent,
    BreadcrumbsComponent,
    BreadcrumbsOneComponent,
    RadioListComponent,
    OrderByComponent,
    ItemListComponent,
    SwitchListComponent,
    SwitchComponent,
    GhostElementComponent,
    PurchaseSummaryStaticComponent,
    PurchaseSummaryCollapseComponent,
    PurchaseSummaryListComponent,
    PurchaseSummaryTotalComponent,
    PurchaseSummaryTotalMifaComponent,
    PurchaseSummarySubTotalComponent,
    PurchaseSummarySubTotalMifaComponent,
    ShowcaseComponent,
    LocationSelectedComponent,
    DisclaimerComponent,
    CarouselTitleBrainComponent,
    ModalDialogComponent,
    DisclaimerControlledComponent
  ],
  exports: [
    CarouselTitleComponent,
    BannerTitleComponent,
    OrderByComponent,
    PaginationListComponent,
    CheckboxListComponent,
    BreadcrumbsComponent,
    BreadcrumbsOneComponent,
    RadioListComponent,
    SwitchComponent,
    GhostElementComponent,
    PurchaseSummaryStaticComponent,
    PurchaseSummaryCollapseComponent,
    PurchaseSummarySubTotalComponent,
    PurchaseSummarySubTotalMifaComponent,
    ShowcaseComponent,
    LocationSelectedComponent,
    PurchaseSummaryTotalComponent,
    PurchaseSummaryTotalMifaComponent,
    DisclaimerComponent,
    CarouselTitleBrainComponent,
    ModalDialogComponent,
    DisclaimerControlledComponent
  ],
  providers: [AdaptiveImagePipe]
})
export class ComponentsModule {}
