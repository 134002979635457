import { MatDialog } from '@angular/material/dialog';
import { UNKNOW_AUTH_PROVIDER } from './../../../../shared/parameters/global';
import { ShoppingListStoreService } from 'src/app/shared/stores/shopping-list/shopping-list-store.service';
import { RegisterOriginStoreService } from './../register-stores/register-origin-store.service';
import { Injectable, NgZone } from '@angular/core';
import { RegisterClientImplementService } from '../../header/services/register-client-implement.service';
import { USER_REGISTRATION_ERROR_CODE, AUTH_PROVIDER, REDIRECT_STATUS, NAVIGATION_URL } from 'src/app/shared/parameters/global';
import { NewRegisterAccessStoreService } from 'src/app/shared/stores/user/new-register-access-store.service';
import { map, switchMap } from 'rxjs/operators';
import { BUSINESS_PATHS } from 'src/app/shared/parameters/business.parameter';
import { UserRegister } from 'src/app/shared/models/register/register.model';
import { User } from 'src/app/shared/models';
import { UserInfoStoreService } from 'src/app/shared/stores/user/user-info-store.service';
import { AuthImplementService } from 'src/app/shared/implements/auth/auth-implement.service';
import { AppStoreService } from 'src/app/shared/stores/app/app-store.service';
import { USER_INFO_MESSAGES } from 'src/app/shared/parameters/user-info-message';
import { Subscription, of } from 'rxjs';
import { UserRegisterRequest } from 'src/app/shared/models/register/user-register-request.model';
import { RegisterInfoTypeStoreService } from 'src/app/shared/stores/user/register-info-type-store.service';
import { Router } from '@angular/router';
import { EBrand } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';
import { CartControlledStoreService } from 'src/app/business/cart/services/cart-controlled-store.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationValidationService {
  public subscriptions: Subscription[] = [];
  public isInkaBrand : boolean = EBrand[environment.brand] == EBrand.inkafarma;

  constructor(
    private registerImplement: RegisterClientImplementService,
    private newRegisterAccessStore: NewRegisterAccessStoreService,
    public userInfoStore: UserInfoStoreService,
    public authImplementService: AuthImplementService,
    private _appStoreService: AppStoreService,
    private registerInfoTypeStore: RegisterInfoTypeStoreService,
    private _router: Router,
    private _ngZone: NgZone,
    private _shoppingListStore: ShoppingListStoreService,
    private dialogRef: MatDialog,
    private _cartControlledStoreService: CartControlledStoreService
  ) { }

  validateUserRegisterOnDB() {
    const shouldPassInkaclubValidation = true;
    const userInfoValidation = true;
    const hasControlledProducts = JSON.parse(localStorage.getItem("hasControlledProducts"));

    return this.registerImplement.getUser(null,shouldPassInkaclubValidation, userInfoValidation)
    .pipe(switchMap(response => {
      const userData = (response.data as UserRegister) || {} as UserRegister;
      const { ERROR_USER_NOT_EXIST, ERROR_USER_UPDATE } = USER_REGISTRATION_ERROR_CODE;
      const CONDITIONS_FOR_REGISTER_REDIRECTION = [
        response.code === ERROR_USER_NOT_EXIST,
        response.code === ERROR_USER_UPDATE,
        userData.isAnonymous
      ];
      if (CONDITIONS_FOR_REGISTER_REDIRECTION.some(condition => condition)) {
        this.newRegisterAccessStore.isValidToAccess = true;
        this.registerInfoTypeStore.setInformation(userData);
        this.userInfoStore.setUser(userData);
        this.registerInfoTypeStore.errorCodeType = response.code;
        this._appStoreService.removeStorageItem("isFirstTime");
        if(this.isInkaBrand){
          return of(BUSINESS_PATHS.REGISTER);
        }else{
          return of(BUSINESS_PATHS.PRE_REGISTER);
        }
      } else {
        const user = new User();
        user.dni = userData.identityDocumentNumber;
        user.name = userData.name;
        user.lastName = userData.lastName;
        this.userInfoStore.setUser(user);
        this.userInfoStore.setShoppingMode(USER_INFO_MESSAGES.LOGUEADO);
        this.userInfoStore.updateAuthState(userData);
        this._appStoreService.removeStorageItem(AUTH_PROVIDER);
        this._appStoreService.removeStorageItem("isFirstTime");

        const uid = this.authImplementService.currentUID;
        /**
         *
         */
        const userRegisterRequest = new UserRegisterRequest();
        userRegisterRequest.authId = uid;
        userRegisterRequest.id = userData.id;
       if(!hasControlledProducts) this._router.navigate(['/']);
        return this.registerImplement.updateUserLegacy(userRegisterRequest)
          .pipe(map(() => {
            return this._appStoreService.sourceNavigationLogin;
          }));
      }
    }));
  }

  settingUserData(userData: UserRegister) {
    const user = new User();
    user.dni = userData.identityDocumentNumber;
    user.name = userData.name;
    user.lastName = userData.paternalLastName;
    this.userInfoStore.setUser(user);
    this.userInfoStore.setShoppingMode(USER_INFO_MESSAGES.LOGUEADO);
    this.userInfoStore.updateAuthState(userData);
  }

  validateUserRegisterMainLogin(response) {
    // only responsive validation
    this.settingStorageAuthProvider(response);
    this.registerInfoTypeStore.setProviderAuthenticationOrigin();
    const subscribe = this.validateUserRegisterOnDB()
      .subscribe((path) => {
        !path ? path = window.location.pathname : path;
        if (typeof path === 'string' && path.length) {
            this._appStoreService.removeSourceLogin();
            this._appStoreService.redirectLoginStatus = REDIRECT_STATUS.COMPLETED;
            this.handleNavigationMainLoin(path);
        }
        subscribe.unsubscribe();
      });
  }

  validateUserRegisterCartLogin(response) {
    // only responsive validation
    this.settingStorageAuthProvider(response);
    this.registerInfoTypeStore.setProviderAuthenticationOrigin();

    const subscribe = this.validateUserRegisterOnDB()
      .subscribe((path) => {
        !path ? path = window.location.pathname : path;
        if (typeof path === 'string' && path.length) {
          this._appStoreService.removeSourceLogin();
          this._appStoreService.redirectLoginStatus = REDIRECT_STATUS.COMPLETED;
          this.handleNavigationCartLogin(path);
        }
        subscribe.unsubscribe();
      });
  }

  handleNavigationMainLoin(path: string) {
    this._ngZone.run(() => {
      switch (path) {
        case BUSINESS_PATHS.REGISTER:
          this._ngZone.run(() => {
            this._router.navigate([path]);
          });
          break;
        case BUSINESS_PATHS.PRE_REGISTER:
          this._ngZone.run(() => {
            this._router.navigate([path]);
          });
          break;
        default:
          window.location.reload();
          break;
      }
    });

  }

  handleNavigationCartLogin(path: string) {
    const redirectToCheckout = this._shoppingListStore.getHasSellerIssue();
    const hasControlledProducts = JSON.parse(localStorage.getItem("hasControlledProducts"));
    if(hasControlledProducts) return this._cartControlledStoreService.setIsLogged(true);
    this._ngZone.run(() => {
      if (path !== BUSINESS_PATHS.REGISTER && redirectToCheckout) {
        this._shoppingListStore.setHasSellerIssue(false);
        window.location.href = BUSINESS_PATHS.CHECKOUT;
      }
      switch (path) {
        case BUSINESS_PATHS.REGISTER:
          this._router.navigate([path]);
          break;
        case BUSINESS_PATHS.PRE_REGISTER:
          this._router.navigate([path]);
          break;
        case BUSINESS_PATHS.CHECKOUT:
          window.location.href = BUSINESS_PATHS.CHECKOUT;
          break;
        default:
          window.location.href = BUSINESS_PATHS.HOME;
          break;
      }
    });
  }

  settingStorageAuthProvider(response) {
    this._appStoreService.setStorageItem(AUTH_PROVIDER, response.credential.providerId);
  }


  updateUserInfoByIncompleteInfoError() {
    const navigationUrl = this._appStoreService.getStorageItem(NAVIGATION_URL);
    if (navigationUrl === ('/' + BUSINESS_PATHS.CHECKOUT)) {
      this.dialogRef.closeAll();
    }
    this.newRegisterAccessStore.isValidToAccess = true;
    this.registerInfoTypeStore.setProviderIncompleteUserInfoByCartOrigin();
    this.registerInfoTypeStore.errorCodeType = USER_REGISTRATION_ERROR_CODE.ERROR_USER_UPDATE;
    this._appStoreService.setStorageItem(AUTH_PROVIDER, UNKNOW_AUTH_PROVIDER);
    this._router.navigate([BUSINESS_PATHS.HOME]);
  }

}
