export enum PAYMENT_METHODS_KEYS {
  PAYMENT_METHOD = 'paymentMethod',
  INLINE = 'inlinePaymentMethod',
  PREVIOUS_INLINE = 'previousInlinePaymentMethod',
  POS = 'posPaymentMethod',
  CASH = 'cashPaymentMethod',
  AMOUNT = 'amount',
  DOCUMENT_TYPE = 'documentType',
  DOCUMENT_NUMBER = 'documentNumber',
  PHONE_NUMBER = 'phoneNumber'
}

export interface IPaymentMethodFormStore {
  [PAYMENT_METHODS_KEYS.PAYMENT_METHOD]: number;
  [PAYMENT_METHODS_KEYS.INLINE]: number;
  [PAYMENT_METHODS_KEYS.PREVIOUS_INLINE]: number;
  [PAYMENT_METHODS_KEYS.POS]: number;
  [PAYMENT_METHODS_KEYS.CASH]: number;
  [PAYMENT_METHODS_KEYS.AMOUNT]: string;
  [PAYMENT_METHODS_KEYS.DOCUMENT_TYPE]: number;
  [PAYMENT_METHODS_KEYS.DOCUMENT_NUMBER]: string;
  [PAYMENT_METHODS_KEYS.PHONE_NUMBER]: string;

  termsAndConditions: boolean;
}

export const INITIAL_PAYMENT_METHOD_VALUES: IPaymentMethodFormStore = {
  paymentMethod: 3,
  inlinePaymentMethod: 1,
  previousInlinePaymentMethod: 1,
  posPaymentMethod: 1,
  cashPaymentMethod: 7,
  amount: '',
  termsAndConditions: false,
  documentType: 0, // 0 : DNI y 1: C.E
  documentNumber: '',
  phoneNumber: ''
};
