import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { LABELS } from "./constants/labels.constant";

@Component({
  selector: "fp-select-control-v2",
  templateUrl: "./select-control.component-v2.html",
  styleUrls: ["./select-control.component-v2.sass"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectControlV2Component),
      multi: true,
    },
  ],
})
export class SelectControlV2Component implements OnInit {
  @Input() label;
  @Input() options: string[] = [];
  @Input() errorMessage = "";
  @Input() color_i = "";
  @Input() disabled = false;
  @Output() selectedOptionEvent = new EventEmitter<string>();
  @Output() clickEvent = new EventEmitter<string>();

  public isDisabled = false;
  public selectedValue = "";
  public color = "color-gray";
  public labels = LABELS;

  onChange = (_: any) => {};
  onTouch = (_: any) => {};

  constructor() {}

  ngOnInit() {
    if (this.color_i === "none") {
      this.color = "";
    }

    this.isDisabled = this.disabled;
  }

  writeValue(selectedValue: any): void {
    if (typeof selectedValue === "string") {
      this.selectedValue = selectedValue;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public selectOption(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedValue = selectElement.value;
    this.onChange(this.selectedValue);
    this.onTouch(this.selectedValue);
    this.selectedOptionEvent.emit(this.selectedValue);
    if (
      this.selectedValue === "Tipo de documento" ||
      this.selectedValue === "Género"
    ) {
      this.color = "color-gray";
    } else {
      this.color = "";
    }
  }
}
