import { Injectable } from '@angular/core';
import { PickUpServicesModule } from './pick-up-services.module';
import { Subject } from 'rxjs';

export interface IActionModal {
  modalManagerAction: string;
}

// open/close simple methods
const OPEN_CHOOSE_A_STORE_MODAL_ACTION: IActionModal = { modalManagerAction: 'openChooseAStoreModal' };
const OPEN_NEARBY_BOTICA_MODAL_ACTION: IActionModal = { modalManagerAction: 'openNearbyBoticaModal' };
const CLOSE_CHOOSE_A_STORE_MODAL_ACTION: IActionModal = { modalManagerAction: 'closeChooseAStoreModal' };
const OPEN_CHOOSE_A_STORE_RESPONSIVE_MODAL_ACTION: IActionModal = { modalManagerAction: 'openChooseAStoreResponsiveModal' };
const OPEN_NEARBY_BOTICA_RESPONSIVE_MODAL_ACTION: IActionModal = { modalManagerAction: 'openNearbyBoticarResponsiveModal' };
const CLOSE_CHOOSE_A_STORE_RESPONSIVE_MODAL_ACTION: IActionModal = { modalManagerAction: 'closeChooseAStoreResponsiveModal' };
const OPEN_WHO_PICK_UP_MODAL_ACTION: IActionModal = { modalManagerAction: 'openWhoPickUpModal' };
const CLOSE_WHO_PICK_UP_MODAL_ACTION: IActionModal = { modalManagerAction: 'closeWhoPickUpModal' };

const OPEN_CHOOSE_A_STORE_MODAL_V2_ACTION: IActionModal = { modalManagerAction: 'openChooseAStoreModalV2' };
const CLOSE_CHOOSE_A_STORE_MODAL_V2_ACTION: IActionModal = { modalManagerAction: 'closeChooseAStoreModalV2' };
const OPEN_CHOOSE_A_STORE_RESPONSIVE_MODAL_V2_ACTION: IActionModal = { modalManagerAction: 'openChooseAStoreResponsiveModalV2' };
const CLOSE_CHOOSE_A_STORE_RESPONSIVE_MODAL_V2_ACTION: IActionModal = { modalManagerAction: 'closeChooseAStoreResponsiveModalV2' };

@Injectable()
export class ModalsManagerHelperService {

  private actionSubject = new Subject<IActionModal>();
  public action$ = this.actionSubject.asObservable();

  constructor() { }

  public openChooseAStoreModal() {
    this.actionSubject.next(OPEN_CHOOSE_A_STORE_MODAL_ACTION);
  }
  public closeChooseAStoreModal() {
    this.actionSubject.next(CLOSE_CHOOSE_A_STORE_MODAL_ACTION);
  }

  public openChooseAStoreModalV2() {
    this.actionSubject.next(OPEN_CHOOSE_A_STORE_MODAL_V2_ACTION);
  }

  public closeChooseAStoreModalV2() {
    this.actionSubject.next(CLOSE_CHOOSE_A_STORE_MODAL_V2_ACTION);
  }

  public openNearbyBoticaModal() {
    this.actionSubject.next(OPEN_NEARBY_BOTICA_MODAL_ACTION);
  }

  public openNearbyBoticarResponsiveModal() {
    this.actionSubject.next(OPEN_NEARBY_BOTICA_RESPONSIVE_MODAL_ACTION);
  }

  public openChooseAStoreResponsiveModal() {
    this.actionSubject.next(OPEN_CHOOSE_A_STORE_RESPONSIVE_MODAL_ACTION);
  }
  public closeChooseAStoreResponsiveModal() {
    this.actionSubject.next(CLOSE_CHOOSE_A_STORE_RESPONSIVE_MODAL_ACTION);
  }

  public openChooseAStoreResponsiveModalV2() {
    this.actionSubject.next(OPEN_CHOOSE_A_STORE_RESPONSIVE_MODAL_V2_ACTION);
  }
  public closeChooseAStoreResponsiveModalV2() {
    this.actionSubject.next(CLOSE_CHOOSE_A_STORE_RESPONSIVE_MODAL_V2_ACTION);
  }

  public openWhoPickUpModal() {
    this.actionSubject.next(OPEN_WHO_PICK_UP_MODAL_ACTION);
  }
  public closeWhoPickUpModal() {
    this.actionSubject.next(CLOSE_WHO_PICK_UP_MODAL_ACTION);
  }
}
