export class AmountToPointRequest {
  subTotal: number;
  deliveryCost: number;
  discountAmount: number;
  totalOnline: number;

  constructor() {
    this.subTotal = 0;
    this.deliveryCost = 0;
    this.discountAmount = 0;
    this.totalOnline = 0;
  }
}
