import {Component, Input} from '@angular/core';
import {Breadcrumbs} from '../../../../../shared/models';

@Component({
  selector: 'fp-breadcrumbs-one',
  templateUrl: './breadcrumbs-one.component.html',
  styleUrls: ['./breadcrumbs-one.component.sass']
})
export class BreadcrumbsOneComponent {

  public breadcrumbs: Array<Breadcrumbs>;
  @Input() showBase = true;
  @Input() breadcrumbRoot = '';

  @Input('breadcrumbs')
  set setBreadCrumbs(breadcrumbs: Array<Breadcrumbs>) {
    if (breadcrumbs.length > 0) {
      let breadcrumbPath = this.breadcrumbRoot;
      this.breadcrumbs = breadcrumbs.map(({...item}) => {
        item.keyword = `${breadcrumbPath}/${item.keyword}`;
        breadcrumbPath = item.keyword;
        return item;
      });
    }
  }

}
