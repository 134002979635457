import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DrugStoreStockRequest } from "@models/drugstore-stock/drugstore-stock.request";
import { DrugStoreStockResponse } from "@models/drugstore-stock/drugstore-stock.response";

import { AppStoreService } from "@stores/app/app-store.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SelectDataStoreService } from "src/app/business/office/store/select-data-store.service";
import { StockBackupRequest } from "../../models/stock/stock-backup.request";
import { ProductBackupResponse } from "../../models/stock/stock-backup.response";
import { END_POINTS_BUSSINESS } from "../../parameters/generic/endpoints";
import { GenericService } from "../generic";

@Injectable({
  providedIn: "root",
})
export class StockRequestService {
  flagCall = 1;

  constructor(
    private genericService: GenericService,
    private _selectDataStore: SelectDataStoreService,
    private _appStore: AppStoreService,
    private _httpClient: HttpClient
  ) {}

  isZoneBackup() {
    return !!this._selectDataStore.zoneBackup;
  }

  postStockBackup$(
    body: StockBackupRequest
  ): Observable<ProductBackupResponse[]> {
    return this.genericService
      .genericPost<any>(
        `${END_POINTS_BUSSINESS.GET_STOCK_BACKUP}?zoneObjetId=${this._selectDataStore.zoneBackup}`,
        body
      )
      .pipe(map((response) => response.products));
  }
  postDrugStoreStockBackup$(
    body: DrugStoreStockRequest
  ): Observable<DrugStoreStockResponse[]> {
    body.localCode = this._selectDataStore.nearbyStore?.localCode;
    body.brand = this._appStore.brandAtention;
    body.drugstoreId = this.getDrugstoreWareHouseId();
    return this._httpClient.post<DrugStoreStockResponse[]>(
      `${END_POINTS_BUSSINESS.POST_DRUGSTORE_STOCK}`,
      body
    );
  }

  getDrugstoreWareHouseId(){
    return this._selectDataStore.nearbyStore?.drugstoreWareHouseId?.toString() ?? this._appStore.drugstoreStock
  }
}
