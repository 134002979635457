import {StaticLandingImplementService} from '../../../services/static-landing-implement.service';
import {Injectable} from '@angular/core';

@Injectable()
export class TermsOfServiceImplementService {
  constructor(
    private _staticLandingImplement: StaticLandingImplementService
  ) {
  }

  getTermsOfService() {
    return this._staticLandingImplement.getTermsOfService();
  }
}
