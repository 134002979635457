import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "fp-content-unfind-client",
	templateUrl: "./content-unfind-client.component.html",
	styleUrls: ["./content-unfind-client.component.sass"]
})
export class ContentUnfindClientComponent implements OnInit {
	@Input() cellphone = "";
	@Input() disabled = false;
	@Output() register = new EventEmitter();
	constructor() {}

	ngOnInit() {}

	onRegister() {
		this.register.emit();
	}
}
