import { Injectable } from '@angular/core';
import { GenericService } from '../generic';
import { ENDPOINTS } from '@parameters/endpoints';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPurchaseNumber } from '@models/purchase-number/purchase-number.model';
import { IReqPurchaseNumberUpdatePagoWeb } from '@models/purchase-number/req-purchase-number-update-pago-web.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseNumberClientService {

  constructor(
    private genericService: GenericService,
  ) { }

  public getPurchaseNumber$(params: IPurchaseNumber): Observable<number> {
    return this.genericService.genericPost<number>(ENDPOINTS.POST_PURCHASE_NUMBER, params)
      .pipe(map((response: number ) => response));
  }

  public updatePurchaseNumberStatus$(params: IReqPurchaseNumberUpdatePagoWeb): Observable<any> {
    return this.genericService.genericPut<any>(ENDPOINTS.PUT_PURCHASE_NUMBER_STATUS, params);
  }

}
