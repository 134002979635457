import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ALERT_STATES } from "@parameters/global";

@Component({
  selector: "fp-alert-dispatch",
  templateUrl: "./alert-dispatch.component.html",
  styleUrls: ["./alert-dispatch.component.sass"],
})
export class AlertDispatchComponent {
  @Input() state: number;
  @Output() eventClick = new EventEmitter();
  states = ALERT_STATES;

  onClick() {
    this.eventClick.emit();
  }
}
