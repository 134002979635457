/**
 * this function argument should be "this.router.url"
 * and return just its main "path"
 */
export function getRouterPath(url: string) {
  const arr = url.split('?');
  if (arr.length > 0) {
    const path = arr[0];
    const pathArr = path.split('/');
    return pathArr.length > 1 ? pathArr[1] : '';
  }
  return '';
}
