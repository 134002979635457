import { Injectable } from '@angular/core';
import { IPaymentMethodFormStore, INITIAL_PAYMENT_METHOD_VALUES, PAYMENT_METHODS_KEYS } from '../models/payment-method-form-store.interface';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppStoreService } from 'src/app/shared/stores/app/app-store.service';

@Injectable()
export class CheckoutPaymentMethodFormStoreService {

  private readonly CHECKOUT_PAYMENT_METHOD_STORE_KEY = 'CPMS';
  private form: IPaymentMethodFormStore = INITIAL_PAYMENT_METHOD_VALUES;
  private formSubject = new BehaviorSubject<IPaymentMethodFormStore>(this.getFormStorage());
  private subscriptions: Subscription[] = [];
  public form$ = this.formSubject.asObservable();

  constructor(
    private appStore: AppStoreService,
  ) {
    const formSubscription = this.form$
      .subscribe(form => {
        this.form = form;
        this.setFormStorage();
      });
    this.subscriptions.push(formSubscription);
  }

  public getForm() {
    return this.form;
  }

  public setPaymentMethod(value: number) {
    this.setOneFormValue(value, PAYMENT_METHODS_KEYS.PAYMENT_METHOD as string);
  }

  public setInlinePayment(value: number) {
    this.setOneFormValue(value, PAYMENT_METHODS_KEYS.INLINE as string);
  }

  public setPreviousInlinePayment(value: number) {
    this.setOneFormValue(value, PAYMENT_METHODS_KEYS.PREVIOUS_INLINE as string);
  }

  public setPosPayment(value: number) {
    this.setOneFormValue(value, PAYMENT_METHODS_KEYS.POS as string);
  }

  public setCashPayment(value: number) {
    this.setOneFormValue(value, PAYMENT_METHODS_KEYS.CASH as string);
  }

  public setAmount(value: string) {
    this.setOneFormValue(value, PAYMENT_METHODS_KEYS.AMOUNT as string);
  }

  public setDocumentType(value: number) {
    this.setOneFormValue(value, PAYMENT_METHODS_KEYS.DOCUMENT_TYPE as string);
  }

  public setDocumentNumber(value: string) {
    this.setOneFormValue(value, PAYMENT_METHODS_KEYS.DOCUMENT_NUMBER as string);
  }

  public setPhoneNumber(value: string) {
    this.setOneFormValue(value, PAYMENT_METHODS_KEYS.PHONE_NUMBER as string);
  }

  public setTermsAndConditions(value: boolean) {
    this.setOneFormValue(value, 'termsAndConditions');
  }

  public setOneFormValue(value: number | boolean | string, key: string) {
    const { form } = this;
    form[key] = value;
    this.formSubject.next(form);
  }

  public setFormStorage(): void {
    const { form, CHECKOUT_PAYMENT_METHOD_STORE_KEY } = this;
    if (!form.previousInlinePaymentMethod) {
      form.previousInlinePaymentMethod = 1;
    }
    const stringFormatOfForm = JSON.stringify(form);
    this.appStore.setStorageItem(CHECKOUT_PAYMENT_METHOD_STORE_KEY, stringFormatOfForm);
  }

  public getFormStorage(): IPaymentMethodFormStore {
    const { CHECKOUT_PAYMENT_METHOD_STORE_KEY } = this;
    const stringFromStorage = this.appStore.getStorageItem(CHECKOUT_PAYMENT_METHOD_STORE_KEY);
    let jsonFormatOfForm = INITIAL_PAYMENT_METHOD_VALUES;
    if (stringFromStorage) {
      jsonFormatOfForm = JSON.parse(stringFromStorage) as IPaymentMethodFormStore;
      jsonFormatOfForm = { ...jsonFormatOfForm, amount: '' };
    }
    return jsonFormatOfForm;
  }

  public get previousInlinePaymentMethod() {
   return this.getFormStorage().previousInlinePaymentMethod;
  }

  public unsubscribeObservers() {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }
}
