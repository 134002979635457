import { NgModule } from "@angular/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { StepperComponent } from "./stepper.component";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [StepperComponent],
  imports: [MatProgressBarModule,CommonModule],
  providers: [],
  exports: [StepperComponent],
})
export class StepperModule {}
