import {Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {StaticLanding} from '../../../models/static-landing.model';
import {PrivacyPolicyImplementService} from '../service/privacy-policy-implement.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fp-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrls: ['./privacy-policy-dialog.component.sass']
})
export class PrivacyPolicyDialogComponent implements OnInit {

  public privacyPolicy: StaticLanding;
  public loader = true;

  constructor(
    private _privacyPolicyImplement: PrivacyPolicyImplementService,
    public _dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>,
  ) {
  }

  ngOnInit() {
    this._privacyPolicyImplement.getPrivacyPolicy()
      .pipe(take(1))
      .subscribe((privacyPolicy: StaticLanding) => {
        this.privacyPolicy = privacyPolicy;
        this.loader = false;
      });
  }

  closeDialog() {
    this._dialogRef.close();
  }

}
