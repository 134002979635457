import { FormControl, Validators } from '@angular/forms';
import { RADIO_BUTTON_ERROR_MESSAGES } from '../../../shared/parameters/error-message-forms';
import { getErrorbyFormControl } from '../../../shared/helpers/error-controls-helpers';

export class DeliveryTypeControl extends FormControl {

  constructor() {
    super();
    this.settingNameValidators();
  }

  private settingNameValidators() {
    this.setValidators([
        Validators.required
    ]);
  }

  public get deliveryTypeError(): string {
    return getErrorbyFormControl(this, RADIO_BUTTON_ERROR_MESSAGES);
  }

}
