import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-sub-section-container-v2',
  templateUrl: './sub-section-container-v2.component.html',
  styleUrls: ['./sub-section-container-v2.component.sass'],
})
export class SubSectionContainerV2Component implements OnInit {
  @Input() flagBorderTop = true;

  constructor() {
  }

  ngOnInit() {
  }
}
