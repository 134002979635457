import { isObject } from "../../helpers/objects-equal";
import { IShoppingDefaultPaymentMethodRequest } from "./product-shopping-cart-request.interface";
import { ProductShoppingCartRequest } from "./product-shopping-cart-request.model";
import {
  ICompanyForRUC,
  IDiscountCode,
  IScheduleForCart,
  IShoppingCartRequest,
} from "./shopping-cart-request.interface";

export class ShoppingCartRequest {
  public addressId?: number;
  public amount?: number;
  public creditCardId?: number;
  public currency?: number;
  public discountId?: number;
  public paymentMethodId?: number;
  public paymentMethodNote?: string | number | null;
  public receiptType?: number;
  public schedule?: IScheduleForCart;
  public schedules?: IScheduleForCart[];
  public company?: ICompanyForRUC;
  public discountsList?: IDiscountCode[];
  public products: Array<ProductShoppingCartRequest>;
  public defaultPaymentMethod?: IShoppingDefaultPaymentMethodRequest;
  public creditCardNumber?: string;
  public idTransactionVisaNet?: string;
  public purchaseNumber?: string;
  public deliveryType?: string;
  public transactionDateVisaNet?: string;
  public drugstoreIdSelected?: string | number;
  public pickupPersonId?: number;
  public prescriptionId?: string;
  public sellerId?: string;
  public sellerName?: string;
  public userCallCenterId?: string;
  public observations?: string;
  public skuTransfer?: string;
  public firstBuyCall?: boolean;
  public noPursePrice?: boolean;
  public paymentGateway?: number;
  constructor(shoppingCartRequest: IShoppingCartRequest) {
    const current = isObject(shoppingCartRequest)
      ? shoppingCartRequest
      : ({} as IShoppingCartRequest);
    this.addressId = current.addressId || -1;
    this.amount = current.amount || 0;
    this.creditCardId = current.creditCardId || -1;
    this.currency = current.currency || -1;
    this.discountId = current.discountId || null;
    this.paymentMethodId = current.paymentMethodId || -1;
    this.products = current.products || [];
    this.defaultPaymentMethod = current.defaultPaymentMethod || {};
    this.drugstoreIdSelected = current.drugstoreIdSelected || null;
    this.sellerId = this.products.length > 0 ? this.products[0].sellerId : "";
    this.sellerName =
      this.products.length > 0 ? this.products[0].sellerName : "";
    this.observations = current.observations || "";
    this.skuTransfer = current.skuTransfer || "";
    this.firstBuyCall = current.firstBuyCall;
    this.noPursePrice = current.noPursePrice;
  }
}
