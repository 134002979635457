import { Component, OnDestroy, OnInit } from "@angular/core";
import { AmountToPointRequest } from "src/app/business/savings-wallet/models/amount-to-point-request";
import { ProductShoppingCart } from "src/app/core/modules/product-card/models/product-shopping-cart/product-shopping-cart.model";
import { PurchaseSummary } from "src/app/shared/models/shopping-cart/shopping-cart-precalculation.model";
import { CampaignClientService } from "src/app/shared/service/campaign/campaign-client.service";
import { UserInfoStoreService } from "src/app/shared/stores/user/user-info-store.service";
import { CheckoutPurchaseSummaryStoreService } from "../../../../business/checkout/checkout-stores/checkout-purchase-summary-store.service";

import firebase from "firebase/compat/app";
import { CouponService } from "../../../../business/savings-wallet/services/couponService";
import { ModalCouponsComponent } from "../modal-coupons/modal-coupons.component";

import { HttpErrorResponse } from "@angular/common/http";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable, Subscription, throwError } from "rxjs";
import { catchError, take } from "rxjs/operators";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { CheckoutOrderPopupComponent } from "src/app/business/checkout/checkout-components/custom-components/checkout-order-popup/checkout-order-popup.component";
import { ProcessingCouponModalManagerService } from "src/app/business/checkout/checkout-services/processing-coupon-modal-manager.service";
import { isArray } from "src/app/shared/helpers/objects-equal";
import { IDiscountCode } from "src/app/shared/models/shopping-cart/shopping-cart-request.interface";
import { ShoppingCartResponse } from "src/app/shared/models/shopping-cart/shopping-cart-response.model";
import { BREAKPOINTS, USER_SOURCE_LOGIN } from "src/app/shared/parameters/global";
import { ShoppingCartImplementService } from "src/app/shared/service/shopping-cart/shopping-cart-implement.service";
import { ShoppingCartRequestService } from "src/app/shared/service/shopping-cart/shopping-cart-request.service";
import { CouponsStoreService } from "../../../../business/checkout/checkout-stores/coupons-store.service";
import { Benefits } from "../../../../business/savings-wallet/models/benefits";
import {
  CouponUSer,
  SearchBenefitsUSer,
} from "../../../../business/savings-wallet/models/registration-user.model";
import { BenefitsService } from "../../../../business/savings-wallet/services/benefits.service";
import { Coupon } from "../../../../business/savings-wallet/views/home/home.model";
import {
  IDiscountedAmountCanonical,
  IRedeemedCoupon,
} from "../../../../shared/models/shopping-cart/shopping-cart-response.interfase";
import { BUSINESS_PATHS } from "../../../../shared/parameters/business.parameter";
import { AppStoreService } from "../../../../shared/stores/app/app-store.service";
import { LoginModalsManagerHelperService } from "../../login/login-services/login-modals-manager-helper.service";
import { RegisterModalsManagerHelperService } from "../../register/register-services/register-modals-manager-helper.service";
import { RegisterModalsMaganerService } from "../../register/register-services/register-modals-manager.service";
import { ModalErrorCouponsComponent } from "../modal-error-coupons/modal-error-coupons.component";
import { HandleErrorChangePriceModalManagerService } from "src/app/business/checkout/checkout-services/handle-error-change-price-modal.service";
import { BreakpointBehavior } from "src/app/shared/behaviors";
import { CART_CODES_ACTION, CART_CODE_ACTION } from "src/app/business/checkout/parameters/finish-purchase-code.constant";
@Component({
  selector: "fp-section-container-points-v2",
  templateUrl: "./section-container-points-v2.component.html",
  styleUrls: [ "./section-container-points-v2.component.scss", "./section-container-points-v2.component.sass"]
})
export class SectionContainerPointsV2Component implements OnInit, OnDestroy {
  public userLogin = false;
  public purchaseSummary = {} as PurchaseSummary;
  public productSummaryList: ProductShoppingCart[] = [];
  public title: string;
  public subtitle: string;
  public points1: string;
  public points2 = "";
  public points3: string;
  public points4: string;
  public points5: string;
  public points6: string;
  public points7: string;
  public deliverypoints: string;
  public deliverypoints2: string;
  public deliverypoints3: string;
  public deliverypoints4: string;
  public deliverypoints5: string;
  public deliverypoints6: string;
  public deliverypoints7: string;
  points = 0;
  private COUPON_STORAGE_KEY: string = "cuponesArr";
  sloginTotal1: string;
  sloginTotal2: string;
  public UserLoginLogout: boolean;
  coupon: Coupon;
  public coupons: Coupon[] = [];
  cupArr: Coupon[] = [];
  asyncObservable: Observable<Coupon[]>;
  public showLoadSection: boolean = true;

  public userBenefits: Benefits = {
    points: 0,
    pointsEquivalentToSoles: 0,
    pointsEarnedMonth: 0,
    pointsDueMonth: 0,
    pointsRedeemedMonth: 0,
    pointsLast12Months: 0,
    amountSaved12Months: 0
  };

  public CuponesNoRedimidos: Coupon[];

  subscriptions: Subscription[] = [];

  discount: IDiscountedAmountCanonical;
  private isShootTag: boolean = false;
  private identityDocumentNumber: string = "";
  public isDesktop = false;

  constructor(
    public userInfoStore: UserInfoStoreService,
    private purchaseSummaryStore: CheckoutPurchaseSummaryStoreService,
    public campaignClientService: CampaignClientService,
    public dialog: MatDialog,
    public dialogRefError: MatDialog,
    private couponService: CouponService,
    public benefitsService: BenefitsService,
    private registerModalsManagerHelper: RegisterModalsManagerHelperService,
    public router: Router,
    private couponsStore: CouponsStoreService,
    private loginModalsManagerHelper: LoginModalsManagerHelperService,
    private _appStoreService: AppStoreService,
    private shoppingCartRequest: ShoppingCartRequestService,
    private shoppingCartImplement: ShoppingCartImplementService,
    private processingCouponModal: ProcessingCouponModalManagerService,
    public registerModalsMaganer: RegisterModalsMaganerService,
    private breakpointBehavior: BreakpointBehavior,
    private handleErrorChangePriceModalManagerService: HandleErrorChangePriceModalManagerService
  ) {

    const breakpointSub = this.breakpointBehavior
      .mediaBreakpointUpObserver(BREAKPOINTS.xxl)
      .subscribe((value) => (this.isDesktop = value.matches));
    this.subscriptions.push(breakpointSub)
  }

  ngOnInit() {
    this.loadCustomerBenefitInformation();
    this.leerLocalStorage();

    this.subscriptions.push(
      this.handleErrorChangePriceModalManagerService.resetCoupons.subscribe((need: boolean) => {
        if (need) {
          this.deletedCoupon(true);
          this.handleErrorChangePriceModalManagerService.reloadCheckout.next(true);
        }
      })
    );
  }

  ngOnDestroy(): void {
      this.subscriptions.map((sbs) => sbs.unsubscribe());
  }

  getUserBenefitsData(identityDocumentNumber: string) {
    const request = new SearchBenefitsUSer(identityDocumentNumber);
    this.benefitsService.points(request).subscribe((bene) => {
      if (bene !== undefined) {
        this.userBenefits.points = bene.points;
        this.userBenefits.amountSaved12Months = bene.amountSaved12Months;
        this.userBenefits.pointsEquivalentToSoles = bene.pointsEquivalentToSoles;
      } else {
        this.userBenefits.points = 0;
        this.userBenefits.amountSaved12Months = 0;
        this.userBenefits.pointsLast12Months = 0;
      }
    });
  }

  removerLocalStorage() {
    localStorage.removeItem(this.COUPON_STORAGE_KEY);
  }

  private obtnerCodigoDecuponesNoRedimidos(redeemedCoupon: IRedeemedCoupon[]): string[] {
    const listadoDeCodigosDeCupones: string[] = [];
    redeemedCoupon
      .filter((re) => re.redeemed === false)
      .forEach((e) => {
        listadoDeCodigosDeCupones.push(e.couponCode);
      });
    return listadoDeCodigosDeCupones;
  }

  obtnerObjetosCuponesDelLocalStoragePorCodigo(ListaCodigoCupones: string[]): Coupon[] {
    const arregloDeCupones: Coupon[] = [];

    ListaCodigoCupones.forEach((e) => {
      const cuponesLocalStorage: Coupon[] = JSON.parse(localStorage.getItem(this.COUPON_STORAGE_KEY));

      cuponesLocalStorage.forEach((c) => {
        if (c.barCode === e) {
          arregloDeCupones.push(c);
        }
      });
    });

    return arregloDeCupones;
  }

  public openCouponSelectionDialog() {
    this.taggButtomPoints()
    const dialogRef = this.dialog.open(ModalCouponsComponent, {
      panelClass: "custom-full-x-modal-overlay",
      width: "418px",
      disableClose: true,
      data: {
        coupons: this.coupons,
        identityDocumentNumber: this.identityDocumentNumber
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data != undefined) {
        this.updateCouponsToRedeem();
      } else {
        const LocalStorageCupones = JSON.parse(localStorage.getItem(this.COUPON_STORAGE_KEY)) || [];
        if (LocalStorageCupones.length != 0) {
          const backupLocalStorege = JSON.parse(localStorage.getItem("BackupCuponesArr"));
          localStorage.setItem(this.COUPON_STORAGE_KEY, JSON.stringify(backupLocalStorege));
        }
        this.leerLocalStorage();
      }
    });
  }
  taggButtomPoints(){
    TaggingService.triggerTag({
      eventCategory:'UI :: Checkout',
      eventAction: 'ui_checkout_canjear_cupon',
      eventLabel: 'Boton :: Canjear'
    })
  }

  convertAmountToPoint(amount: number) {
    const amountToPointRequest = new AmountToPointRequest();
    amountToPointRequest.totalOnline = amount;

    this.campaignClientService.convertAmountToPoint(amountToPointRequest).subscribe((response) => {
        this.points = response.point;
      });
  }

  private updateCouponsToRedeem(needRequest?: boolean) {
    const addedCoupons: Coupon[] = JSON.parse(localStorage.getItem(this.COUPON_STORAGE_KEY)) || [];
    const cartRequest = this.shoppingCartRequest.shoppingCartRequest;
    if (addedCoupons !== null && addedCoupons.length !== 0) {
      const discountArray = [];
      addedCoupons.forEach((coupon) => {
        const discount = {
          couponCode: coupon.barCode,
          couponId: null,
          discountByReferenceId: null
        } as IDiscountCode;
        discountArray.push(discount);
      });
      cartRequest.discountsList = discountArray;
    } else {
      cartRequest.discountsList = [];
    }
    this.shoppingCartRequest.updateShoppingCartRequest = cartRequest;
    if (!needRequest) this.loadProductSummary();
  }

  private updateOrderSummary() {
    const cartRequest = this.shoppingCartRequest.shoppingCartRequest;
    const discountArray = [];
    this.cupArr.forEach((coupon) => {
      const discount = {
        couponCode: coupon.barCode,
        couponId: null,
        discountByReferenceId: null
      } as IDiscountCode;
      discountArray.push(discount);
    });
    cartRequest.discountsList = discountArray;
    this.shoppingCartRequest.updateShoppingCartRequest = cartRequest;
    this.loadProductSummary();
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error.message);
  }

  private loadProductSummary() {
		this.processingCouponModal.openModal();
		this.shoppingCartImplement
			.getShoppingCart()
			.pipe(take(1), catchError(this.handleError))
			.subscribe(
				(shoppingCartResponse: ShoppingCartResponse) => {
					if (CART_CODES_ACTION.includes(shoppingCartResponse.code)) {
						this.processingCouponModal.closeModal();
					} else {
						if (shoppingCartResponse.precalculation.discountedAmountCanonical != null) {
							this.purchaseSummaryStore.setShoppingCartResponse(shoppingCartResponse);
							this.registerEventCheckout(shoppingCartResponse.shoppingList);
						}
						this.validateResponseDiscountedAmountCanonical(shoppingCartResponse.precalculation.discountedAmountCanonical);
						this.processingCouponModal.closeModal();
					}
				},
				(error) => {
					this.validateResponseDiscountedAmountCanonical(null);
					this.processingCouponModal.closeModal();
				}
			);
	}

  private validateResponseDiscountedAmountCanonical(discountedAmountCanonical: IDiscountedAmountCanonical) {
    if (discountedAmountCanonical != null) {
      switch (discountedAmountCanonical.code) {
        case "13":
          const ListaCodigoCupones: string[] = this.obtnerCodigoDecuponesNoRedimidos(discountedAmountCanonical.redeemedCoupon);
          this.CuponesNoRedimidos = this.obtnerObjetosCuponesDelLocalStoragePorCodigo(ListaCodigoCupones);
          const dialogRefError = this.dialog.open(ModalErrorCouponsComponent, {
            panelClass: "modal-class-error",
            disableClose: true,
            data: { cupones: this.CuponesNoRedimidos }
          });
          dialogRefError.afterClosed().subscribe((data) => {
            this.actualizarCuponesLocalStorage();
          });
          break;
        case "15":
          const ohMini = discountedAmountCanonical.bestDiscount.paymentMethodCardType;
          this.showPopUp(false, "", ohMini);
          break;
        case "1":
          this.leerLocalStorage();
          break;
        default:
          const LocalStorageCupones = JSON.parse(localStorage.getItem(this.COUPON_STORAGE_KEY)) || [];
          if (LocalStorageCupones.length != 0) {
            const backupLocalStorege = JSON.parse(localStorage.getItem("BackupCuponesArr"));
            localStorage.setItem(this.COUPON_STORAGE_KEY, JSON.stringify(backupLocalStorege));
          }
          this.leerLocalStorage();
          break;
      }
    } else {
      const LocalStorageCupones = JSON.parse(localStorage.getItem(this.COUPON_STORAGE_KEY)) || [];
      if (LocalStorageCupones.length != 0) {
        const backupLocalStorege = JSON.parse(localStorage.getItem("BackupCuponesArr"));
        localStorage.setItem(this.COUPON_STORAGE_KEY, JSON.stringify(backupLocalStorege));
      }
      this.leerLocalStorage();
    }
    const LocalStorageCupones = JSON.parse(localStorage.getItem(this.COUPON_STORAGE_KEY)) || [];
    const arregloDeCodigodeCupones: string[] = [];
    LocalStorageCupones.forEach((cupon: Coupon) => {
      arregloDeCodigodeCupones.push(cupon.barCode);
    });

    this.couponsStore.setCoupons(arregloDeCodigodeCupones);
  }
  private showPopUp(useCuppon: boolean, amountCupon: string, ohMini: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = "411px";
    dialogConfig.panelClass = "custom-full-all-modal-overlay";

    const modalRef = this.dialog.open(CheckoutOrderPopupComponent, dialogConfig);

    modalRef.componentInstance.bestCuppon = useCuppon;
    modalRef.componentInstance.sectionContainerPointsV2Component = this;
    if (this.shoppingCartRequest.shoppingCartRequest.discountsList) {
      modalRef.componentInstance.ohMini = ohMini;
    } else {
      modalRef.componentInstance.ohMini = ohMini;
    }
  }

  public deletedCoupon(needRequest?: boolean) {
    this.couponsStore.setCoupons([]);
    localStorage.removeItem(this.COUPON_STORAGE_KEY);
    this.cupArr = [];
    this.updateCouponsToRedeem(needRequest);
  }

  private leerLocalStorage() {
    if (!localStorage.getItem(this.COUPON_STORAGE_KEY)) {
      this.cupArr = [];
      return;
    }
    const couponsAdd: Coupon[] = JSON.parse(localStorage.getItem(this.COUPON_STORAGE_KEY));
    this.cupArr = couponsAdd;
  }
  actualizarCuponesLocalStorage() {
    this.leerLocalStorage();
    this.CuponesNoRedimidos.forEach((cupon) => {
      this.removeTag(cupon.barCode);
    });
    this.updateOrderSummary();
  }
  removeTag($event: string, tipo?: number) {
    const codigoCupon = $event;
    this.cupArr.forEach((cuponesTag, index) => {
      if (cuponesTag.barCode == codigoCupon) {
        this.cupArr.splice(index, 1);
      }
    });
    this.couponsStore.removeCoupon($event);
    localStorage.setItem(this.COUPON_STORAGE_KEY, JSON.stringify(this.cupArr));
    if (tipo == 1) this.updateOrderSummary();
  }
  private registerEventCheckout(shoppingList: Array<ProductShoppingCart>) {
    if (!this.isShootTag) {
      const productSummaryList = isArray(shoppingList) ? shoppingList : [];
      TaggingService.checkout(productSummaryList);
      this.isShootTag = true;
    }
  }

  private getCouponsByCustomerID(identityDocumentNumber: string) {
    const request = new CouponUSer({
      identificationDocument: identityDocumentNumber,
      limitAvailableStatus: 40,
      companyCode: "MF"
    });
    this.couponService.getCoupons(request).subscribe((response) => {
        if (response.error == null) {
          this.coupons = response.data.filter((coupons) => coupons.couponStatus !== 2 && coupons.omnicanal !== "N");
        }
      });
  }

  public loadCustomerBenefitInformation() {
    this.purchaseSummaryStore.shoppingCartResponse$.subscribe((response) => {
      this.leerLocalStorage();
      if (response.purchaseSummary) {
        this.convertAmountToPoint(response.purchaseSummary.totalOnline);
        if (response.purchaseSummary.discount != null) {
          this.sloginTotal1 = "y ahorrar";
          this.sloginTotal2 = "S/" + response.purchaseSummary.discount;
        } else if (response.purchaseSummary.discount == null) {
          this.sloginTotal1 = "";
          this.sloginTotal2 = "";
        }

        this.userInfoStore.authStateObservable().subscribe((userData) => {
          if (userData.dni !== undefined) {
            this.UserLoginLogout = true;
            this.title = this.isDesktop ? "Beneficios de tu Monedero del ahorro" : "Beneficios de tu Monedero";
            this.points1 = "¡Desde ahora tus puntos se multiplican!";
            this.points2 = this.points + " puntos";
            this.points3 = "gracias a tu monedero del ahorro, en esta compra acumularás";
            this.points4 = "";
            this.points5 = "";
            this.points6 = this.sloginTotal1;
            this.points7 = this.sloginTotal2;
            this.subtitle = "*Sujeto a modificación en la facturación";
            this.deliverypoints = "delivery-points";
            this.deliverypoints2 = "delivery-points2";
            this.deliverypoints3 = "delivery-points3";
            this.deliverypoints4 = "delivery-nouns";
            this.deliverypoints5 = "";
            this.deliverypoints6 = "";
            this.deliverypoints7 = "";
            this.identityDocumentNumber = userData.dni;
            // this.getCouponsByCustomerID(userData.dni);
            // this.getUserBenefitsData(userData.dni);
          } else if (userData.dni === undefined) {
            this.UserLoginLogout = false;
            this.title = "No te pierdas los beneficios del Monedero del Ahorro";
            this.points1 = "Inicia sesión";
            this.points2 = "o";
            this.points3 = "regístrate";
            this.points4 = "para acumular";
            this.points6 = this.sloginTotal1;
            this.points7 = this.sloginTotal2;

            this.deliverypoints = "delivery-spoints1";
            this.deliverypoints2 = "delivery-spoints2";
            this.deliverypoints3 = "delivery-spoints3";
            this.deliverypoints4 = "delivery-spoints4";
            this.deliverypoints5 = "delivery-spoints5";
            this.deliverypoints6 = "delivery-spoints6";
            this.deliverypoints7 = "";
            this.subtitle = "";
          }
          this.showLoadSection = false;
        });
      }
    });

    this.userInfoStore.authStateObservable().subscribe((userData) => {
      if (userData.dni !== undefined) {
        this.getCouponsByCustomerID(userData.dni);
        this.getUserBenefitsData(userData.dni);
      }
    });
  }

  openLoginModal() {
    this._appStoreService.sourceNavigationLogin = BUSINESS_PATHS.CHECKOUT;
    this.loginModalsManagerHelper.openMainLoginModal();
  }
  openRegisterModal() {
    this._appStoreService.sourceLogin = USER_SOURCE_LOGIN.CHECKOUT;
    this.registerModalsMaganer.openRegisterModal();
  }
}
