import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { CART_CODE } from "src/app/business/checkout/parameters/finish-purchase-code.constant";
import { ErrorStockModalService } from "src/app/core/modules/error-stock-product/services/error-stock-modal.service";
import { ISubTotal } from "src/app/shared/models/shopping-cart/shopping-cart-response.interfase";
import { ShoppingCartLoaderService } from "src/app/shared/service/shopping-cart/shopping-cart-loader.service";
import { StorageClientService } from "src/app/shared/storage/storage-client.service";
import { WindowScrollTopHostListener } from "../../../../shared/behaviors/host-listener/window-scroll-top/window-scroll-top-host-listener.behavior";
import { PurchaseSummary } from "../../../../shared/models/shopping-cart/shopping-cart-precalculation.model";
import { BLOCKED_PURCHASE_ERROR_MESSAGE } from "../../../../shared/parameters/global-error-message";
import { ShoppingListStoreService } from "../../../../shared/stores/shopping-list/shopping-list-store.service";
import { GlobalHeader } from "../../../modules/header/parameters/header";
import { SnackbarAlertService } from "../../../modules/snackbar/snackbar-alert.service";
import { EBrand } from "@parameters/access/brand.parameter";
import { Subscription } from 'rxjs';
import { ShoppingCartRequestService } from "src/app/shared/service/shopping-cart/shopping-cart-request.service";
import { WhatsappModalService } from "src/app/core/modules/whatsapp-sale/Service/whatsapp-modal.service";
import { AppStoreService } from "@stores/app/app-store.service";
import {Router} from "@angular/router"


@Component({
  selector: "fp-purchase-summary-static",
  templateUrl: "./purchase-summary-static.component.html",
  styleUrls: ["./purchase-summary-static.component.sass"],
})
export class PurchaseSummaryStaticComponent extends WindowScrollTopHostListener {
  public brand = EBrand;
  public scrollClass: string;
  public isTomadorBrand = OrderTakerValidator.isOrderTakerEnv();

  @Input() summary: PurchaseSummary;
  @Input() summaryCard: Array<ISubTotal>;
  @Input() disabledCards: boolean;
  @Input() public isAliviamedConsulta: boolean;
  @Input() hasControlledProducts
  @Output() buyNow = new EventEmitter();
  loader = false;

  constructor(
    private shoppingListStore: ShoppingListStoreService,
    private snackbarAlert: SnackbarAlertService,
    private storageClientService: StorageClientService,
    private errorStockModal: ErrorStockModalService,
    public _shoppingCartLoader: ShoppingCartLoaderService,
    private findClientService: WhatsappModalService,
    private _appStore: AppStoreService,
    private router: Router,
  ) {
    super();
  }

  public override triggerScrollTopEvent(active: boolean) {
    this.scrollClass = active ? GlobalHeader.CLASS_TO_HIDE_NAV : "";
  }

  buyNowEvent() {
    if (this.shoppingListStore.getCartCode === "23") {
      this.snackbarAlert.snackbarAlertInfo(
        "Presentaciones no disponibles. <b>Modifica tu pedido para continuar.</b>"
      );
      return
    }
    if (this.shoppingListStore.getCartCode === "24") {
      this.snackbarAlert.snackbarAlertInfo(
        "Alcanzaste el límite de compra para los productos señalados. <b>Modifica las cantidades o elimínalos.</b>"
      );
      return;
    }
    if (this.shoppingListStore.getCartCode === "25") {
      this.snackbarAlert.snackbarAlertInfo(
        "Los productos señalados necesitan ser modificados para continuar con la compra."
      );
      return;
    }
    this.shoppingListStore.getCartCode
    this._shoppingCartLoader.shoppingCartLoader =
    !!this.storageClientService.getStorageItem("OT_CLIENT_DATA");
    TaggingService.checkoutView();
    this.buyNow.emit();
  }

  openWhatsappModal(){
    this.router.navigate(['/checkout']);
  }
}
