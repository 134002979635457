import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, from, interval } from "rxjs";
import { END_POINTS } from "src/app/business/savings-wallet/constants/service.constants";
import firebase from "firebase/compat/app";
import { filter, switchMap, take } from "rxjs/operators";
import { AppManagerService } from "@service/firebase/auth/app-manager.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private appManager: AppManagerService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const urlCpupons = [
			END_POINTS.POST_COUPONS,
			END_POINTS.POST_COUPON,
			END_POINTS.POST_ENABLE_COUPON,
			`${END_POINTS.POST_AUDIT_USER}/couponSearch`,
			END_POINTS.GET_CAMPAIGNS
		];
		const isCoupon = urlCpupons.some((url) => {
      if (url === END_POINTS.POST_COUPON) {
        return request.url === END_POINTS.POST_COUPON
      } else {
        return request.url.includes(url)
      }
    });
		if (isCoupon) {
			const secondApp = this.appManager.getFirebaseApp("second");
			return interval(100).pipe(
				filter(() => !!firebase.auth(secondApp).currentUser),
				take(1),
				switchMap(() => from(firebase.auth(secondApp).currentUser.getIdToken())),
				switchMap((token: string) => {
					const cloneRequest = request.clone({
						headers: request.headers.set("x-access-token", token).set("content-type", "application/json")
					});
					return next.handle(cloneRequest);
				})
			);
		} else {
			return next.handle(request);
		}
	}
}
