import { Injectable } from '@angular/core';
import { ICatSubcatBraTag, CSB_TYPE } from '../models/cat-subcat-bra-tag.model';
import { ECOLORS } from '../models/tag-checkout.model';

export interface ICustomTag {
  text: string;
  color: string;
  showRemoveIcon: boolean;
  tagValue: ICatSubcatBraTag;
}

@Injectable()
export class TagTypesBuildingService {

  constructor() { }

  public getCategoryTags(catSubcatBra: ICatSubcatBraTag[]): ICustomTag[] {
    const tags = catSubcatBra.filter(e => e.type === CSB_TYPE.CATEGORY);
    return this.getFormatedColorTag(tags, ECOLORS.DARKBLUE);
  }

  public getSubcategoryTags(catSubcatBra: ICatSubcatBraTag[]): ICustomTag[] {
    const tags = catSubcatBra.filter(e => e.type === CSB_TYPE.SUBCATEGORY);
    return this.getFormatedColorTag(tags, ECOLORS.BLUE);
  }

  public getBrandTags(catSubcatBra: ICatSubcatBraTag[]): ICustomTag[] {
    const tags = catSubcatBra.filter(e => e.type === CSB_TYPE.BRAND);
    return this.getFormatedColorTag(tags, ECOLORS.PURPLE);
  }

  private getFormatedColorTag(tags: ICatSubcatBraTag[], color: ECOLORS) {
    return tags.map(e => {
      return {
        text: e.text,
        color,
        showRemoveIcon: true,
        tagValue: e,
      };
    });
  }
}
