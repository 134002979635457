import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { EmailControl } from 'src/app/shared/controls/email-control';
import { NameControl } from 'src/app/business/checkout/controls/name-control';
import { PhoneNumberControl } from 'src/app/shared/controls/phone-number-control';
import { resetControlHelper } from 'src/app/shared/helpers/error-controls-helpers';
import { CrossDocumentControl } from 'src/app/shared/controls/cross-document-control';

@Injectable()
export class WhoPickUpFormService {

  public form: FormGroup;

  private _dniControl = new CrossDocumentControl();
  private _emailAddressControl = new EmailControl();
  private _nameControl = new NameControl();
  private _phoneNumberControl = new PhoneNumberControl();

  constructor(
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      typeDoc: new FormControl(),
      dni: this._dniControl,
      emailAddress: this._emailAddressControl,
      name: this._nameControl,
      phoneNumber: this._phoneNumberControl
    });
    this.typeDocControl.setValue(1);
    this.dniControl.setDocumentTypeControl(this.typeDocControl);
  }

  public get dniControl() {
    return this.form.get('dni') as CrossDocumentControl;
  }

  public get emailAddressControl() {
    return this.form.get('emailAddress') as EmailControl;
  }

  public get nameControl() {
    return this.form.get('name') as NameControl;
  }

  public get phoneNumberControl() {
    return this.form.get('phoneNumber') as PhoneNumberControl;
  }

  public get typeDocControl() {
    return this.form.get('typeDoc') as FormControl;
  }

  public resetInvoiceForm() {
    resetControlHelper(this.dniControl);
    resetControlHelper(this.emailAddressControl);
    resetControlHelper(this.nameControl);
    resetControlHelper(this.phoneNumberControl);
  }

}
