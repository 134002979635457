export enum PAYMENT_METHOD_CODE {
  ONLINE = 3,
  POS = 2,
  CASH = 1,
  AGORA_PAY = 4
}
export enum PAYMENT_METHODS_TEXT{
  ONLINE_PAYMENT = 'Pago en línea',
  POS_PAYMENT = 'Pago con P.O.S. contra entrega',
  CASH_PAYMENT = 'Pago en efectivo contra entrega',
  APP_AGORA_PAY_PAYMENT = 'Pago con App agora'
}

export const PAYMENT_METHODS_ICON = {
  [PAYMENT_METHOD_CODE.ONLINE]: 'payment-method-online-icon',
  [PAYMENT_METHOD_CODE.POS]: 'payment-method-pos-icon',
  [PAYMENT_METHOD_CODE.CASH]: 'payment-method-cash-icon',
  [PAYMENT_METHOD_CODE.AGORA_PAY]: 'payment-method-agora-icon'
}
