import { IUser } from "../../interfaces/user.interface";
import { Role } from "../../parameters/auth/role.parameter";

export class User {
  role: Role;
  id: string;
  email: string;
  name: string;
  firstLastName: string;
  secondLastName: string;

  constructor(iUser: IUser) {
    this.role = iUser.authorities[0];
    this.id = iUser.id || "";
    this.email = iUser.email;
    this.name = iUser.name;
    this.firstLastName = iUser.first_last_name;
    this.secondLastName = iUser.second_last_name;
  }
}
