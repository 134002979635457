import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild, } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'fp-six-digit-code',
  templateUrl: 'six-digit-code-control.component.html',
  styleUrls: ['six-digit-code-control.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SixDigitCodeComponent),
      multi: true,
    },
  ],
})
export class SixDigitCodeComponent implements ControlValueAccessor, AfterViewInit {

  @ViewChild('inputOne')
  inputOne: ElementRef<HTMLInputElement>;
  @ViewChild('inputTwo')
  inputTwo: ElementRef<HTMLInputElement>;
  @ViewChild('inputThree')
  inputThree: ElementRef<HTMLInputElement>;
  @ViewChild('inputFour')
  inputFour: ElementRef<HTMLInputElement>;
  @ViewChild('inputFive')
  inputFive: ElementRef<HTMLInputElement>;
  @ViewChild('inputSix')
  inputSix: ElementRef<HTMLInputElement>;

  @Input() isDisabled: boolean;
  @Input() modalEmail = true;

  @Output() someEvent = new EventEmitter();
  @Output() disableButton = new EventEmitter();

  public inputs: HTMLInputElement[] = [];

  value: string;
  onChange = (_: any) => {};
  onTouch = (_: any) => {};

  constructor() {
  }

  writeValue(value: string): void {
    this.value = value ? value : '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit() {
    this.inputs.push(
      this.inputOne.nativeElement,
      this.inputTwo.nativeElement,
      this.inputThree.nativeElement,
      this.inputFour.nativeElement,
      this.inputFive.nativeElement,
      this.inputSix.nativeElement
    );
  }

  public focusAtInputEmpty(index: number) {
    const inputEmptyIndex = this.getNextInputEmpty();
    const element = this.inputs[index];
    if (element.value === '' && inputEmptyIndex < index + 1) {
      this.inputs[inputEmptyIndex].focus();
    }
  }

  private getNextInputEmpty() {
    return this.inputs.findIndex((input) => input.value === '');
  }

  public keydownEvent(event: any, index: number) {
    if (event.key === 'ArrowLeft' && index - 1 >= 0) {
      const currentIndex = index - 1;
      this.inputs[currentIndex].focus();
      setTimeout(() => {
        this.inputs[currentIndex].setSelectionRange(0, 1);
      }, 10);
    }
    if (event.key === 'ArrowRight' && index + 1 <= 5) {
      const currentIndex = index + 1;
      this.inputs[currentIndex].focus();
      setTimeout(() => {
        this.inputs[currentIndex].setSelectionRange(0, 1);
      }, 10);
    }
    if (
      event.key === 'Backspace' &&
      this.inputs[index].value === '' &&
      index - 1 >= 0
    ) {
      const currentIndex = index - 1;
      this.inputs[currentIndex].focus();
      this.inputs[currentIndex].value = '';
    }



  }

  public keyupEvent(event: any) {

    if (
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight' &&
      event.key !== 'Backspace' &&
      event.target.value !== ''
    ) {
      this.someEvent.next();
    }
  }

  public inputEvent(event: any, index: number) {
    if (event.inputType === 'insertText' && index + 1 <= 5) {
      this.inputs[index + 1].focus();
    }

    const state = this.getConcatInputValues().length>=6 ? false : true;
    this.disableButton.emit(state);

    if(this.modalEmail){
      const code = this.getConcatInputValues();
      this.onChange(code);
      this.onTouch(code);
    }
  }

  private getConcatInputValues() {
    return this.inputs.map((input) => input.value).join('');
  }

  public send(){
    const code = this.getConcatInputValues();
    this.onChange(code);
    this.onTouch(code);
  }
}
