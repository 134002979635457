import { FormControl } from "@angular/forms";
import { getEnableErrorMessages } from "@helpers/error-controls-helpers";

export class ReferenceControl extends FormControl {
  constructor() {
    super("");
  }

  public get error(): boolean {
    return getEnableErrorMessages(this);
  }
}
