import { EProductPresentation } from "../product-presentation.enum";
import { EProductStatus } from "../product-status.enum";
import { Product } from "../product.model";
import { ProductCardPresentations } from "./product-card-presentation.model";
import { IProductCard } from "./product-card.interface";

export class ProductCard extends Product {
	public limitOfferTime: string;
	public tagImageUrl: string;
	public secondaryTagImageUrl: string;
	public alertTagText: string;
	public sellerId: string;
	public sellerName: string;
	public isFromSeller: boolean;
	public prescription: string;
	public brand: string;
	public category: string;
	public sapCode: string;
	public ranking: number;
	public productPack: boolean;
	public complementary: string[];
	public saleChannels: string[];
	public isGeneric: boolean;
	public skuGenericSuggest: string;
	public prescriptionCode: number;

	//Order Taker
	unitMeasure: string;
	compositionContent: string;
	segment: string;
	guaranteed: string;
	skuMifarma: string;

	constructor(iProductCard: IProductCard) {
		super();
		this.id = iProductCard.id || "";
		this.name = iProductCard.name || "";
		this.brand = iProductCard.brand || "";
		this.favorite = iProductCard.favorite || false;
		this.fractionalMode = iProductCard.fractionalMode || false;
		this.defaultPresentation = iProductCard.isUnitPresentationDefault
			? iProductCard.fractionatedPresentationId || EProductPresentation.unit
			: iProductCard.presentationId || EProductPresentation.pack;
		this.statusId = iProductCard.productStatusId || EProductStatus.unavailable;
		this.presentations = new ProductCardPresentations(iProductCard).productPresentations || [];
		this.slug = iProductCard.slug || "";
		if (iProductCard.limitOfferTime) {
			this.limitOfferTime = iProductCard.limitOfferTime;
		}
		this.tagImageUrl = iProductCard.tagImageUrl || "";
		this.secondaryTagImageUrl = iProductCard.secondaryTagImageUrl || "";
		this.alertTagText = iProductCard.alertTagText || "";
		this.sellerId = iProductCard.sellerId || "";
		this.sellerName = iProductCard.sellerName || "";
		this.prescription = iProductCard.prescription || "";
		this.isFromSeller = iProductCard.isFromSeller || false;
		this.sapCode = iProductCard.sapCode || "";
		this.ranking = iProductCard.ranking || 0;
		this.productPack = iProductCard.productPack || false;

		this.complementary = iProductCard.complementary || [];
		this.saleChannels = iProductCard.saleChannels || [];

		this.segment = iProductCard.segment || "";
		this.guaranteed = iProductCard.guaranteed || "";
		this.skuMifarma = iProductCard.skuMifarma || "";
		this.unitMeasure = iProductCard.unitMeasure;

		this.fractionalRetStock = iProductCard.fractionalRetStock || 0;
		this.fractionalStock = iProductCard.fractionalStock || 0;

		this.category = iProductCard.categoryList?.[0]?.keyword || "";

		this.compositionContent = iProductCard.details?.find((details) => details.key === "COMPOSITION")?.content;

		this.isGeneric = iProductCard.isGeneric;

		this.skuGenericSuggest = iProductCard.skuGenericSuggest ? iProductCard.skuGenericSuggest : "";

		this.prescriptionCode = iProductCard.prescriptionCode || 0;
	}
}
