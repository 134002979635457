import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fp-check-list-password',
  templateUrl: './check-list-password.component.html',
  styleUrls: ['./check-list-password.component.sass']
})
export class CheckListPasswordComponent implements OnInit {

  @Input() verifyCharacter = false;
  @Input() verifyCapital = false;
  @Input() verifyLower = false;
  @Input() verifyNumber = false;

  @Input() ipuntChange = false;

  constructor() { }

  ngOnInit() {

  }

  public get isAllVerified() {
    const verifyArray = [
      this.verifyCharacter,
      this.verifyCapital,
      this.verifyLower,
      this.verifyNumber
    ];
    return verifyArray.every(verify => verify);
  }

}
