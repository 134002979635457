import { IAddress, IPaymentMethod, IOrderInformationResponse, ISchedule, IAction, IScreen, ICartConfirmationResponse, IReferralCanonical } from './cart-confirmation.interface';
import { isObject } from '../../helpers/objects-equal';

export class Address {
  public address: string;
  public addressMessage: string | null;
  public canContinue: string | null;
  public favourite: boolean;
  public id: number;
  public latitude: number;
  public longitude: number;
  public name: string;
  public pickupAddress: string | null;

  constructor(address: IAddress) {
    const currentValue = isObject(address) ? address : {} as IAddress;
    this.address = currentValue.address || '';
    this.addressMessage = currentValue.addressMessage || null;
    this.canContinue = currentValue.canContinue || null;
    this.favourite = currentValue.favourite || false;
    this.id = currentValue.id || -1;
    this.latitude = currentValue.latitude || -1;
    this.longitude = currentValue.longitude || -1;
    this.name = currentValue.name || '';
    this.pickupAddress = currentValue.pickupAddress || null;
  }
}

export class ReferralCanonical {
  public referralCode: string;
  public referralMessage: string;
  public firstReferralCode: boolean;

  constructor(referral : IReferralCanonical){
    this.referralCode = referral.referralCode;
    this.referralMessage = referral.referralMessage;
    this.firstReferralCode = referral.firstReferralCode;
  }
}

export class PaymentMethod {
  public cardType: string;
  public creditCardId: number | null;
  public id: number;
  public lastNumbers: string | null;
  public name: string | null;
  public type: string;

  constructor(method: IPaymentMethod) {
    this.cardType = method.cardType || '';
    this.creditCardId = method.creditCardId || null;
    this.id = method.id || -1;
    this.lastNumbers = method.lastNumbers || null;
    this.name = method.name || null;
    this.type = method.type || '';
  }
}

export class OrderInformationResponse {
  public address: Address;
  public amount: number | null;
  public deliveryCost: number;
  public deliveryDate: string | null;
  public deliveryServiceId: number;
  public deliveryTime: number;
  public deliveryType: string;
  public deliveryTypeDescription: string;
  public discountApplied: number;
  public orderDate: number;
  public orderDateConfirm: string;
  public orderNote: string | null;
  public orderStatus: boolean | null;
  public paymentMethod: PaymentMethod;
  public pickupAddress: string | null;
  public productList: [];
  public programmedMessage: string;
  public shoppingCardStatusId: number;
  public shoppingCartId: number;
  public shoppingCartStatus: string;
  public totalCost: number;
  public tracking: boolean;

  constructor(orderInfo: IOrderInformationResponse) {
    this.address = new Address(orderInfo.address);
    this.amount = orderInfo.amount || null;
    this.deliveryCost = orderInfo.deliveryCost || -1;
    this.deliveryDate = orderInfo.deliveryDate || null;
    this.deliveryServiceId = orderInfo.deliveryServiceId || -1;
    this.deliveryTime = orderInfo.deliveryTime || -1;
    this.deliveryType = orderInfo.deliveryType || '';
    this.deliveryTypeDescription = orderInfo.deliveryTypeDescription || '';
    this.discountApplied = orderInfo.discountApplied || -1;
    this.orderDate = orderInfo.orderDate || -1;
    this.orderDateConfirm = orderInfo.orderDateConfirm || '';
    this.orderNote = orderInfo.orderNote || null;
    this.orderStatus = orderInfo.orderStatus || null;
    this.paymentMethod = new PaymentMethod(orderInfo.paymentMethod);
    this.pickupAddress = orderInfo.pickupAddress || null;
    this.productList = orderInfo.productList || [];
    this.programmedMessage = orderInfo.programmedMessage || '';
    this.shoppingCardStatusId = orderInfo.shoppingCardStatusId || -1;
    this.shoppingCartId = orderInfo.shoppingCartId || -1;
    this.shoppingCartStatus = orderInfo.shoppingCartStatus || '';
    this.totalCost = orderInfo.totalCost || -1;
    this.tracking = orderInfo.tracking || false;
  }
}

export class Schedule {
  public day: string;
  public endDate: number;
  public hour: string;
  public scheduled: string;
  public startDate: number;

  constructor(schedule: ISchedule) {
    this.day = schedule.day || '';
    this.endDate = schedule.endDate || -1;
    this.hour = schedule.hour || '';
    this.scheduled = schedule.scheduled || '';
    this.startDate = schedule.startDate || -1;
  }
}

export class Action {
  public action: string;
  public label: string;

  constructor(action: IAction) {
    this.action = action.action || '';
    this.label = action.label || '';
  }
}

export class Screen {
  public actions: Action[];
  public deliveryStatusImage: string;
  public deliveryStatusMessageBody: string;
  public deliveryStatusMessageFooter: string;
  public deliveryStatusMessageTitle: string;

  constructor(screen: IScreen) {
    this.actions = Array.isArray(screen.actions) ? screen.actions.map(action => new Action(action)) : [];
    this.deliveryStatusImage = screen.deliveryStatusImage || '';
    this.deliveryStatusMessageBody = screen.deliveryStatusMessageBody || '';
    this.deliveryStatusMessageFooter = screen.deliveryStatusMessageFooter || '';
    this.deliveryStatusMessageTitle = screen.deliveryStatusMessageTitle || '';
  }
}

export class CartConfirmationResponse {
  code: string;
  message: string;
  orderInfo: OrderInformationResponse;
  schedule: Schedule;
  screen: Screen;
  mixedOrder: boolean;
  referralCanonical: ReferralCanonical;
  orders?: OrderInformationResponse[];

  constructor(cartConfirm: ICartConfirmationResponse) {
    this.code = cartConfirm.code || '';
    this.message = cartConfirm.message || '';
    this.mixedOrder = cartConfirm.mixedOrder;
    this.orderInfo = cartConfirm.orderInfo ? new OrderInformationResponse(cartConfirm.orderInfo) : {} as OrderInformationResponse;
    this.schedule = cartConfirm.schedule ? new Schedule(cartConfirm.schedule) : {} as Schedule;
    this.screen = cartConfirm.screen ? new Screen(cartConfirm.screen) : {} as Screen;
    this.referralCanonical = cartConfirm.referralCanonical ? new ReferralCanonical(cartConfirm.referralCanonical) : {} as ReferralCanonical;
    this.orders = cartConfirm.orders;
  }
}
