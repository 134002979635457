<div class="row product-cart-responsive" [class.unavailable-presentation]="
    productStatus === eProductStatus.unavailablePresentation
  " [attr.data-product-brand]="product?.brand" [attr.data-product-category]="product?.category">
  <div class="col-12 mb-4 text-right">
    <fp-button-ghost buttonId="btn--delete-product" buttonSize="small" buttonColor="red" (click)="deleteProduct()">
      Eliminar
    </fp-button-ghost>
  </div>

  <div class="col-12 mb-3">
    <fp-link [linkRouter]="['/' + productPath, product.slug, product.id]">
      <fp-product-information-cart [image]="productPresentation?.image" [name]="product.name"
        [description]="productPresentation?.description" [productStatus]="productStatus"
        [stockPrincipal]="flagBackup ? stockBackup : product?.stock"
        [stockBackup]="flagBackup ? product?.stock : stockBackup" [prescription]="product.prescription">
      </fp-product-information-cart>
    </fp-link>
  </div>

  <div class="col-12 mb-3">
    <div class="row align-items-center">
      <div class="col-6">
        <fp-product-presentation presentationTemplate="select" [presentationList]="product.presentations"
          [presentationSelected]="product.presentationIdSelected" [productStatus]="productStatus"
          (updatePresentation)="setProductPresentation($event)">
        </fp-product-presentation>
      </div>

      <div class="col-6">
        <fp-product-quantity [productQuantity]="product.quantity" [quantityLimit]="productPresentation?.quantityLimit"
          [rounded]="true"
          [productStatus]="productStatus" [product]="product" [isSeller]="product.isFromSeller"
          (updateQuantity)="setQuantity($event)">
        </fp-product-quantity>
      </div>
    </div>
  </div>

  <div class="col-12">
    <fp-product-cart-price *fpBrandAccess="[brand.inkafarma]" [subTotal]="product.subTotalAllPaymentMethod"
      [subTotalOffer]="product.subTotalWithPaymentMethod" [productStatus]="productStatus"
      [regularPrice]="product.subTotal">
    </fp-product-cart-price>
    <fp-product-cart-price-mifa *fpBrandAccess="[brand.mifarma]" [subTotal]="product.subTotalAllPaymentMethod"
      [subTotalOffer]="product.subTotalWithPaymentMethod" [productStatus]="productStatus"
      [regularPrice]="product.subTotal">
    </fp-product-cart-price-mifa>
  </div>
</div>
