import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Filter} from '../../../../shared/models';

@Component({
  selector: 'fp-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.sass']
})
export class CheckboxListComponent {

  @Input() itemsInView = 4;
  @Output() selection = new EventEmitter();

  public selectAll: Filter;
  public list: Array<Filter>;
  public checkboxSelected = Array<Filter>();

  @Input('list')
  set checkedList(list: Array<Filter>) {
    this.list = list;
    this.setSavedCheckbox();
    this.updateCheckboxResult(true);
  }

  @Input('selectAll')
  set defaultSelection(select) {
    this.selectAll = select;
    this.setCheckboxAll();
  }

  setSavedCheckbox() {
    if (this.checkboxSelected.length) {
      this.list.filter(item => this.checkboxSelected.find(filter => filter.id === item.id))
        .forEach(item => item.checked = true);
    }
  }

  setCheckboxAll() {
    if (this.selectAll) {
      this.selectAll.checked = this.checkboxSelected.length === 0;
    }
  }

  updateCheckboxAll() {
    this.list.forEach(filter => filter.checked = false);
    this.updateCheckboxResult();
  }

  updateCheckboxResult(updateOnly = false) {
    this.checkboxSelected = this.list.filter(filter => filter.checked);
    this.setCheckboxAll();
    this.selection.emit({checkboxSelected: this.checkboxSelected, updateOnly: updateOnly});
  }

}
