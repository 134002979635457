import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { ContentfulAsset, ContentfulEntry } from '../../../shared/models/contentful/contentful.model';
import { CContentfulBanner, IContentfulBanner } from './contentful-banner.model';
import { CContentfulBannerKit, IContentfulBannerKit } from './contentful-banner-kit.model';
import { Asset, Entry } from 'contentful';
import { IContentfulBannerCardInfo } from './contentful-banner-card-info.model';
import { style } from '@angular/animations';

export enum EContentfulBannerListTemplate {
  template1 = 'Plantilla 1',
  template2 = 'Plantilla 2',
  template3 = 'Plantilla 3',
  template4 = 'Plantilla 4',
  templateDefault = '',
  templateResponsiveCarousel = 'Plantilla Carrusel Responsive',
  templateCard = 'Plantilla Card',
}

export interface IContentfulBannerList {
  background?: string;
  title: string;
  description: string;
  items: Array<Entry<IContentfulBanner | IContentfulBannerKit | IContentfulBannerCardInfo>>;
  template: EContentfulBannerTemplate;
  slug: string;
  backgroundImage?:Asset;
  style?:string;
}

export enum EContentfulBannerTemplate {
  template1 = 'Plantilla 1',
  template2 = 'Plantilla 2',
  templateCard = 'Plantilla Card',
  templateDefault = 'Plantilla 1',
  templateResponsiveCarousel = 'Plantilla Carrusel Responsive',
}

export class ContentfulBannerList {
  title: string;
  description: string;
  template: EContentfulBannerTemplate;
  bannerList: Array<ContentfulEntry<IContentfulBanner | IContentfulBannerKit | IContentfulBannerCardInfo>>;
  slug: string;
  backgroundImage?:string;
  background?:string;
  style?:string;

  constructor(iContentfulBannerList: IContentfulBannerList) {
    this.title = iContentfulBannerList.title;
    this.description = iContentfulBannerList.description || '';
    this.bannerList = iContentfulBannerList.items ?
      iContentfulBannerList.items.map(banner => new ContentfulEntry(banner)) : [];
    this.background = iContentfulBannerList.background;
    this.template = iContentfulBannerList.template || EContentfulBannerTemplate.templateDefault;
    this.style = iContentfulBannerList?.style || '';
    if(iContentfulBannerList.slug){this.slug = iContentfulBannerList.slug;}
    if(iContentfulBannerList.backgroundImage){this.backgroundImage = new ContentfulAsset(iContentfulBannerList.backgroundImage).asset}
  }
}

export const CContentfulBannerList = {
  id: CONTENTFUL_PATH.contentTypeIds.bannerList,
  sliderConfig: {
    'type': 5,
    'loop': false,
    'autoplay': false,
    'autoplayTime': 5000,
    'itemsPerView': 4,
  },
  itemsType: {
    banner: CContentfulBanner.id,
    bannerKit: CContentfulBannerKit.id,
  }
};

