import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NearestDrugstoresImplementService } from './nearest-drugstores-implement.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    NearestDrugstoresImplementService,
  ]
})
export class PickUpImplementsModule { }
