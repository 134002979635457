import { ProductSmallCategoryComponent } from './views/product-small-category/product-small-category.component';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { IconsModule } from "src/app/core/generic/icons/icons.module";
import { DirectivesModule } from "src/app/shared/directives/directives.module";
import { SharedModule } from "../../../shared/shared.module";
import { ButtonsModule } from "../../generic/buttons/buttons.module";
import { ChipModule } from "../../generic/chip/chip.module";
import { GenericModule } from "../../generic/generic.module";
import { LazyImagesModule } from "../../generic/lazy-images/lazy-images.module";
import { TagSellerModule } from "../../generic/tags/tag-seller/tag-seller.module";
import { TagsModule } from "../../generic/tags/tags.module";
import { FormControlsModule } from "../form-controls/form-controls.module";
import { LoginServicesModule } from "../login/login-services/login-services.module";
import { PickUpComponentsModule } from "../pick-up-in-store/pick-up-components/pick-up-components.module";
import { PickUpInStoreModule } from "../pick-up-in-store/pick-up-in-store.module";
import { PickUpServicesForAgmModule } from "../pick-up-in-store/pick-up-services-for-agm/pick-up-services-for-agm.module";
import { PickUpConfigStoreService } from "../pick-up-in-store/pick-up-stores/pick-up-config-store.service";
import { SnackbarModule } from "../snackbar/snackbar.module";
import { ProductCardInformationComponent } from "./custom-components/product-card-information/product-card-information.component";
import { ProductCardPriceComponent } from "./custom-components/product-card-price/product-card-price.component";
import { ProductCartPriceComponent } from "./custom-components/product-cart-price/product-cart-price.component";
import { ProductInformationCartComponent } from "./custom-components/product-information-cart/product-information-cart.component";
import { ProductInformationComponent } from "./custom-components/product-information/product-information.component";
import { ProductStockComponent } from "./custom-components/product-stock/product-stock.component";
import { ProductAddButtonComponent } from "./generic-components/product-add-button/product-add-button.component";
import { ProductDescriptionComponent } from "./generic-components/product-description/product-description.component";
import { ProductFavoriteComponent } from "./generic-components/product-favorite/product-favorite.component";
import { ProductImageComponent } from "./generic-components/product-image/product-image.component";
import { ProductNameComponent } from "./generic-components/product-name/product-name.component";
import { ProductOfferPriceComponent } from "./generic-components/product-offer-price/product-offer-price.component";
import { ProductPickerComponent } from "./generic-components/product-picker/product-picker.component";
import { ProductAvailableBoticaResponsiveComponent } from "./generic-components/product-presentation/product-available-botica-responsive/product-available-botica-responsive.component";
import { ProductAvailableBoticaComponent } from "./generic-components/product-presentation/product-available-botica/product-available-botica.component";
import { ProductPresentationCardsComponent } from "./generic-components/product-presentation/product-presentation-cards/product-presentation-cards.component";
import { ProductPresentationListComponent } from "./generic-components/product-presentation/product-presentation-list/product-presentation-list.component";
import { ProductPackPresentationResponsiveComponent } from "./generic-components/product-presentation/product-presentation-productPack-responsive/productPack-presentation-responsive.component";
import { ProductPackPresentationComponent } from "./generic-components/product-presentation/product-presentation-productPack/productPack-presentation.component";
import { ProductPresentationSelectComponent } from "./generic-components/product-presentation/product-presentation-select/product-presentation-select.component";
import { ProductPresentationComponent } from "./generic-components/product-presentation/product-presentation.component";
import { ProductPriceComponent } from "./generic-components/product-price/product-price.component";
import { ProductQuantityComponent } from "./generic-components/product-quantity/product-quantity.component";
import { ProductRegularPriceComponent } from "./generic-components/product-regular-price/product-regular-price.component";
import { ProductTagComponent } from "./generic-components/product-tag/product-tag.component";
import { ProductAlertsService } from "./service/product-alerts.service";
import { ProductImplementService } from "./service/product-implement.service";
import { ProductManagerService } from "./service/product-manager.service";
import { ProductModalsManagerService } from "./service/product-modals-manager.service";
import { ShoppingCartListValidationsService } from "./service/shopping-cart-list-validations.service";
import { ProductCartOrderTakerComponent } from "./views/product-cart-order-taker/product-cart-order-taker.component";
import { ProductCartResponsiveComponent } from "./views/product-cart-responsive/product-cart-responsive.component";
import { ProductSellerCartComponent } from "./views/product-cart-seller/product-seller-cart.component";
import { ProductCartComponent } from "./views/product-cart/product-cart.component";
import { ProductErrorModalComponent } from "./views/product-error-modal/product-error-modal.component";
import { ProductLargeComponent } from "./views/product-large/product-large.component";
import { ProductMiniCartComponent } from "./views/product-mini-cart/product-mini-cart.component";
import { ProductSellerCartResponsiveComponent } from "./views/product-seller-cart-responsive/product-seller-cart-responsive.component";
import { ProductSmallComponent } from "./views/product-small/product-small.component";
import { SellerMiniCartComponent } from "./views/seller-mini-cart/seller-mini-cart.component";
import { ProductDrawerMiniCartComponent } from './views/product-drawer-mini-cart/product-drawer-mini-cart.component';
import { ProductCardPriceMifaComponent } from './custom-components/product-card-price-mifa/product-card-price-mifa.component';
import { ProductOfferPriceMifaComponent } from './generic-components/product-offer-price-mifa/product-offer-price-mifa.component';
import { ProductPriceMifaComponent } from './generic-components/product-price-mifa/product-price-mifa.component';
import { ProductRegularPriceMifaComponent } from './generic-components/product-regular-price-mifa/product-regular-price-mifa.component';
import { TagSellerBrandModule } from '../../generic/tags/tag-seller-brand/tag-seller-brand.module';
import { ProductCartPriceMifaComponent } from './custom-components/product-cart-price-mifa/product-cart-price-mifa.component';
import { ProductSmallSearchComponent } from './views/product-small-search/product-small-search.component';
import { ProductOfferPricePredictiveComponent } from './generic-components/product-offer-price-predictive/product-offer-price-predictive.component';

@NgModule({
  providers: [
    ProductImplementService,
    ProductManagerService,
    ProductAlertsService,
    ProductModalsManagerService,
    ShoppingCartListValidationsService,
    PickUpConfigStoreService,
  ],
  declarations: [
    ProductCartComponent,
    ProductPresentationComponent,
    ProductPresentationSelectComponent,
    ProductPresentationCardsComponent,
    ProductPresentationListComponent,
    ProductInformationCartComponent,
    ProductQuantityComponent,
    ProductCartPriceComponent,
    ProductCartPriceMifaComponent,
    ProductCartResponsiveComponent,
    ProductLargeComponent,
    ProductSmallComponent,
    ProductSmallCategoryComponent,
    ProductSmallSearchComponent,
    ProductMiniCartComponent,
    ProductDrawerMiniCartComponent,
    ProductImageComponent,
    ProductInformationComponent,
    ProductNameComponent,
    ProductDescriptionComponent,
    ProductOfferPriceComponent,
    ProductOfferPriceMifaComponent,
    ProductRegularPriceComponent,
    ProductRegularPriceMifaComponent,
    ProductTagComponent,
    ProductCardPriceComponent,
    ProductCardPriceMifaComponent,
    ProductCardInformationComponent,
    ProductPriceComponent,
    ProductPriceMifaComponent,
    ProductFavoriteComponent,
    ProductAddButtonComponent,
    ProductPickerComponent,
    ProductErrorModalComponent,
    SellerMiniCartComponent,
    ProductSellerCartComponent,
    ProductSellerCartResponsiveComponent,
    ProductPresentationSelectComponent,
    ProductPresentationListComponent,
    ProductPresentationCardsComponent,
    ProductAvailableBoticaComponent,
    ProductAvailableBoticaResponsiveComponent,
    ProductPackPresentationComponent,
    ProductPackPresentationResponsiveComponent,
    ProductStockComponent,
    ProductCartOrderTakerComponent,
    ProductOfferPricePredictiveComponent
  ],
  exports: [
    ProductCartComponent,
    ProductCartResponsiveComponent,
    ProductMiniCartComponent,
    ProductDrawerMiniCartComponent,
    ProductLargeComponent,
    ProductSmallComponent,
    ProductSmallCategoryComponent,
    ProductSmallSearchComponent,
    ProductQuantityComponent,
    ProductPickerComponent,
    ProductPresentationComponent,
    ProductPresentationSelectComponent,
    ProductPresentationCardsComponent,
    ProductPresentationListComponent,
    ProductImageComponent,
    ProductFavoriteComponent,
    SellerMiniCartComponent,
    ProductSellerCartComponent,
    ProductSellerCartResponsiveComponent,
    ProductAddButtonComponent,
    ProductAvailableBoticaComponent,
    ProductAvailableBoticaResponsiveComponent,
    ProductPackPresentationComponent,
    ProductPackPresentationResponsiveComponent,
    ProductCartOrderTakerComponent,
    ProductCardInformationComponent,
    ProductCardPriceComponent,
    ProductOfferPricePredictiveComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    SharedModule,
    SnackbarModule,
    ButtonsModule,
    RouterModule,
    GenericModule,
    TagsModule,
    LazyImagesModule,
    IconsModule,
    TagSellerBrandModule,
    TagSellerModule,
    MatDialogModule,
    LoginServicesModule,
    FormControlsModule,
    PickUpComponentsModule,
    PickUpServicesForAgmModule,
    PickUpInStoreModule,
    DirectivesModule,
    ChipModule,
  ],
  entryComponents: [ProductErrorModalComponent],
})
export class ProductCardModule {}
