export function stringFormat(template: string, ...args: any[]) {
	return template.replace(/{(\d+)}/g, function (match, number) {
		return typeof args[number] != "undefined" ? args[number] : match;
	});
}

export function normalizeValue(value: string): string {
	const validValue = typeof value === "string" || typeof value === "number" ? value.toString() : "";
	return validValue.toLowerCase().replace(/\s/g, "");
}
