export enum EHttpHeaders {
  contentType = "Content-Type",
  authorization = "Authorization",
  accessToken = "access-token",
}

export enum EHttpHeaderContentTypes {
  xWwwFormUrlencoded = "application/x-www-form-urlencoded",
  json = "application/json",
}
