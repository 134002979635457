import { IFilteredProductsResponse } from './filtered-products-response.interface';
import { ProductCard } from '../../../core/modules/product-card/models/product-card/product-card.model';
import { ProductDefault } from '../product-default/product-default.model';
import { IProductDefault } from '../product-default/product-default.interface';
import { FilteredProductsPagination } from './filtered-products-pagination.model';

export class FilteredProductsResponse extends FilteredProductsPagination {
  row: Array<ProductCard>;

  constructor(iFilteredProductsResponse: IFilteredProductsResponse) {
    super();
    this.page = iFilteredProductsResponse.page || 0;
    this.totalPages = iFilteredProductsResponse.total || 0;
    this.records = iFilteredProductsResponse.records || 0;
    this.totalRecords = iFilteredProductsResponse.totalRecords || 0;
    this.row = iFilteredProductsResponse.rows && iFilteredProductsResponse.rows.length ?
      iFilteredProductsResponse.rows.map((iProductDefault: IProductDefault) => new ProductDefault(iProductDefault)) : [];
  }
}
