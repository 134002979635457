import { Injectable } from '@angular/core';
import { AnonymousWarningModalComponent } from '../modal-components/anonymous-warning-modal/anonymous-warning-modal.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CartLoginModalComponent } from '../modal-components/cart-login-modal/cart-login-modal.component';
import { ForgotPasswordComponent } from '../modal-components/forgot-password/forgot-password.component';
import { ForgotPasswordConfirmationComponent } from '../modal-components/forgot-password-confirmation/forgot-password-confirmation.component';
import { MainLoginModalComponent } from '../modal-components/main-login-modal/main-login-modal.component';
import { MainLoginAfiliacionModalComponent } from '../modal-components/main-login-afiliacion-modal/main-login-afiliacion-modal.component';
import { LoginModalsManagerHelperService } from './login-modals-manager-helper.service';
import {
  ANONYMOUS_WARNING_MODAL_CONFIG, CART_LOGIN_MODAL_CONFIG, FORGOT_PASSWORD_MODAL_CONFIG,
  FORGOT_PASSWORD_CONFIRM_MODAL_CONFIG, RESPONSIVE_FORGOT_PASSWORD_CONFIRM_MODAL_CONFIG,
  MAIN_LOGIN_MODAL_CONFIG, MAIN_LOGIN_AFILIACION_MODAL_CONFIG, RESPONSIVE_MAIN_LOGIN_MODAL_CONFIG,
} from '../parameters/login-modals-manager.constant';

@Injectable()
export class LoginModalsManagerService {

  private anonymousWarningModalRef: MatDialogRef<AnonymousWarningModalComponent>;
  private cartLoginModalRef: MatDialogRef<CartLoginModalComponent>;
  private forgotPasswordRef: MatDialogRef<ForgotPasswordComponent>;
  private forgotPasswordConfirmationRef: MatDialogRef<ForgotPasswordConfirmationComponent>;
  private mainLoginModalRef: MatDialogRef<MainLoginModalComponent>;
  private mainLoginAfiliacionModalRef: MatDialogRef<MainLoginAfiliacionModalComponent>;
  private respForgotPasswordConfirmationRef: MatDialogRef<ForgotPasswordConfirmationComponent>;

  constructor(
    private matDialog: MatDialog,
    private loginModalsManagerHelper: LoginModalsManagerHelperService
  ) {
    this.loginModalsManagerHelper.action$.subscribe(action => {
      const _this = this;
      const { modalManagerAction,data }: { modalManagerAction: string, data?: any } = action;
      _this[modalManagerAction](data);
    });
  }

  public openAnonymousWarningModal() {
    this.anonymousWarningModalRef = this.matDialog.open(AnonymousWarningModalComponent, ANONYMOUS_WARNING_MODAL_CONFIG);
  }

  public closeAnonymousWarningModal() {
    this.anonymousWarningModalRef.close();
  }
  public openCartLoginModal(option?) {
    this.cartLoginModalRef = this.matDialog.open(CartLoginModalComponent, {
      ...CART_LOGIN_MODAL_CONFIG,data:option
    });
  }

  public closeCartLoginModal() {
    this.cartLoginModalRef.close();
  }
  public openForgotPasswordModal() {
    this.forgotPasswordRef = this.matDialog.open(ForgotPasswordComponent, FORGOT_PASSWORD_MODAL_CONFIG);
  }

  public closeForgotPasswordModal() {
    this.forgotPasswordRef.close();
  }
  public openForgotPasswordConfirmationModal() {
    this.forgotPasswordConfirmationRef = this.matDialog.open(ForgotPasswordConfirmationComponent, FORGOT_PASSWORD_CONFIRM_MODAL_CONFIG);
  }

  public closeForgotPasswordConfirmationModal() {
    this.forgotPasswordConfirmationRef.close();
  }
  public openResponsiveForgotPasswordConfirmationModal() {
    this.respForgotPasswordConfirmationRef = this.matDialog.open(ForgotPasswordConfirmationComponent, RESPONSIVE_FORGOT_PASSWORD_CONFIRM_MODAL_CONFIG);
  }

  public closeResponsiveForgotPasswordConfirmationModal() {
    this.respForgotPasswordConfirmationRef.close();
  }
  public openMainLoginModal() {
    this.mainLoginModalRef = this.matDialog.open(MainLoginModalComponent, MAIN_LOGIN_MODAL_CONFIG);
  }
  public closeMainLoginModal() {
    this.mainLoginModalRef?.close();
  }
  public openMainLoginAfiliacionModal() {
    this.mainLoginAfiliacionModalRef = this.matDialog.open(MainLoginAfiliacionModalComponent, MAIN_LOGIN_AFILIACION_MODAL_CONFIG);
  }
  public closeMainLoginAfiliacionModal() {
    this.mainLoginAfiliacionModalRef.close();
  }
}
