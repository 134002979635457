<ng-container [ngSwitch]="orderByType">


  <ng-container *ngSwitchCase="defaultTypes.desktop">
    <div class="order-by-desktop">
      <span class="mr-3 label-black">Ordenar por</span>
      <fp-select
        class="d-inline-block order-by-select"
        selectPlaceholder="Selecciona una opción"
        selectType="active"
        [optionList]="!isSCategories ? orderListByString : orderListCategoriesByString"
        (optionSelected)="selectedOrder($event)"
        [selectValueControl]="selected?.name">
      </fp-select>
    </div>
  </ng-container>


  <ng-container *ngSwitchCase="defaultTypes.responsive">
    <div class="order-by-responsive">
      <fp-select
        class="d-block order-by-select"
        selectPlaceholder="Ordenar por"
        selectType="active"
        [optionList]="!isSCategories ? orderListByString : orderListCategoriesByString"
        (optionSelected)="selectedOrder($event)"
        [selectValueControl]="selected?.name">
      </fp-select>
    </div>
  </ng-container>

</ng-container>
