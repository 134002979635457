import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CouponService } from "../../../../business/savings-wallet/services/couponService";
import { CouponUSer, CouponUSerDet } from "../../../../business/savings-wallet/models/registration-user.model";
import firebase from "firebase/compat/app";
import * as moment from "moment";
import { IDiscountCodeRequestParams } from '../../../../shared/models/discount-code/discount-code.model';
import { IDiscountCode } from '../../../../shared/models/shopping-cart/shopping-cart-request.interface';
import { isArray } from '../../../../shared/helpers/objects-equal';
import { CheckoutDiscountCodeFormService } from '../../../../business/checkout/checkout-forms/checkout-discount-code-form.service';
import { DiscountCodeImplementService } from '../../../../business/checkout/checkout-services/discount-code-implement.service';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { ShoppingCartRequestService } from '../../../../shared/service/shopping-cart/shopping-cart-request.service';
import { CouponsStoreService } from '../../../../business/checkout/checkout-stores/coupons-store.service';
import { ErrorScheduleModalService } from '../../../../business/checkout/checkout-services/error-schedule-modal.service';
import { ShoppingCartImplementService } from '../../../../shared/service/shopping-cart/shopping-cart-implement.service';
import { CheckoutPurchaseSummaryStoreService } from '../../../../business/checkout/checkout-stores/checkout-purchase-summary-store.service';
import { ShoppingCartResponse } from '../../../../shared/models/shopping-cart/shopping-cart-response.model';
import { ProductShoppingCart } from '../../product-card/models/product-shopping-cart/product-shopping-cart.model';
import { TaggingService } from '../../../../atm-services/tagging.service';
import { Coupon } from '../../../../business/savings-wallet/models/coupon.model';
import { Subject, throwError as observableCartError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { UserInfoStoreService } from 'src/app/shared/stores/user/user-info-store.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from 'src/app/shared/parameters/global';
import { ModalErrorCouponsComponent } from '../modal-error-coupons/modal-error-coupons.component';
import { ModalInvalidCouponComponent } from '../modal-invalid-coupon/modal-invalid-coupon.component';
import { SnackbarAlertInComingService } from '../../snackbar/snackbar-alert-incoming.service';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

moment.locale("es");

interface IDiscountCodeTag {
  code: string;
}

@Component({
  selector: "fp-modal-coupons",
  templateUrl: "./modal-coupons.component.html",
  styleUrls: ["./modal-coupons.component.scss"],
})
export class ModalCouponsComponent implements OnInit, AfterViewInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public coupons: Coupon[] = [];
  public cupones: Coupon[] = [];
  public discountCodeTags: IDiscountCodeTag[] = [];
  coupon: Coupon;
  public discountsList?: IDiscountCode[];
  public couponInput: Coupon;
  token: string = "";
  public isValidatingCupon = false;
  private isShootTag = false;
  public listado: boolean = true;
  public detalle: boolean = false;
  private identityDocumentNumber: string;
  public responseCard = {};
  loading = true;
  public isValidCoupon = false;
  private COUPON_STORAGE_KEY: string = "cuponesArr";
  public isDesktopVersion = false;
  public isLoading: boolean = false;
  private authUserId:string = '';
  private authDni: string = '';
  private toastConfigSmallFormDeleteDirection: MatSnackBarConfig = {
    duration: 3000,
    horizontalPosition: "center",
    verticalPosition: "top"
  };

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalCouponsComponent>,
    private couponService: CouponService,
    public discountCodeForm: CheckoutDiscountCodeFormService,
    private discountCodeImplement: DiscountCodeImplementService,
    private shoppingCartRequest: ShoppingCartRequestService,
    private couponsStore: CouponsStoreService,
    private errorScheduleModalService: ErrorScheduleModalService,
    private shoppingCartImplement: ShoppingCartImplementService,
    private purchaseSummaryStore: CheckoutPurchaseSummaryStoreService,
    private cdr: ChangeDetectorRef,
    private userInfoStore: UserInfoStoreService,
    public breakpointObserver: BreakpointObserver,
    private _snackbarAlertInComingService:SnackbarAlertInComingService,
    @Inject(MAT_DIALOG_DATA) private dialogData
  ) {
    this.dialogRef.backdropClick().subscribe(_ => {
      this.closeDialog();
    })
    const backkupLocalStorage = JSON.parse(localStorage.getItem(this.COUPON_STORAGE_KEY));
    localStorage.setItem('BackupCuponesArr', JSON.stringify(backkupLocalStorage));
    this.coupons = this.dialogData.coupons;
    this.identityDocumentNumber = this.dialogData.identityDocumentNumber;
  }

  ngOnInit() {
    this.discountCodeForm.codeControl.valueChanges.subscribe(value => { this.isValidCoupon = false;});
    this.discountCodeForm.resetInputForm();
    this.breakpoint$.pipe(takeUntil(this._unsubscribeAll)).subscribe(breakPointState => this.isDesktopVersion = breakPointState.matches);
    this.userInfoStore.authStateObservable().subscribe(data =>{
     if(data.authId) this.authUserId = data.authId
     if(data.dni) this.authDni = data.dni
    })
  }

  ngAfterViewInit(): void {
    this.cupones = JSON.parse(localStorage.getItem(this.COUPON_STORAGE_KEY)) || [];
    this.cdr.detectChanges();
  }

  public closeDialogModal(data?) {
    this.dialogRef.close(data);
    this.taggPointsClose()
  }

  public closeDialog(data?) {
    this.dialogRef.close(data);
  }
  accept(){
    this.taggPointsAccept()
    if(this.cupones.length > 0) {
      this.guardarLocalStorage();
      this.closeDialog({FlkAceept:true});
    }else if(this.cupones.length < JSON.parse(localStorage.getItem(this.COUPON_STORAGE_KEY)).length){
      this.guardarLocalStorage();
      this.closeDialog({FlkAceept:true});
    } else {
      this.closeDialog();
    }
  }
  openDialogDetail(coupon: Coupon) {
    const requestDet = new CouponUSerDet({
      identificationDocument: this.identityDocumentNumber,
      id: coupon.barCode,
      couponTypeCode: 1,
      parentCampaignCode: "",
      idTracking: null,
      companyCode: "MF",
    });
    this.couponService
      .getCouponById(requestDet)
        .subscribe((response) => {
          this.coupon = response.data as Coupon;

        });
    this.dialogRef.addPanelClass('modal-details-dialog');
    this.dialogRef.removePanelClass('modal-class-coupon');
    return (this.listado = false), (this.detalle = true);

  }

  back() {
    // this.dialogRef.addPanelClass('modal-class-coupon');
    this.dialogRef.removePanelClass('modal-details-dialog');
    return (this.listado = true), (this.detalle = false);

  }
  addCoupon(coupon: Coupon) {
     this.taggPointsAdd()
     const couponExists = this.cupones.some(c => c.barCode === coupon.barCode);
     if (couponExists) {
       return false;
     }
     if (coupon.couponActive === 'S') {
       this.validationApplyCupon(coupon);
     } else {
       this.enableCoupon(coupon);
     }
  }
  enableCoupon(coupon) {
    this.isValidCoupon = false;
    const request = {
      source: "WEB",
      companyCode: "MF",
      client: {
          userId: this.authUserId,
          firstName: "",
          lastname: "",
          documentNumber: this.authDni,
          documentType: "DNI"
      },
      codCoupon: coupon.barCode,
      campaignId: coupon.campaignId,
    }
    this.couponService.enableCoupon(request).subscribe((response) => {
        if(response.status && response.status.code === '0'){
          this.validationApplyCupon(coupon);
        }
      },error => {
        this.isValidCoupon = true;
      });
  }

  isSelected(coupon: Coupon) {
    return this.cupones.some(c => c.barCode === coupon.barCode);
  }

  remove(i: number) {
    this.cupones.splice(i, 1);
  }

  guardarLocalStorage() {
    localStorage.setItem(this.COUPON_STORAGE_KEY, JSON.stringify(this.cupones))
  }

  removerLocalStorage() {
    localStorage.removeItem(this.COUPON_STORAGE_KEY);
  }

  applyCupon() {
    this.taggPointsApply();
    this.isValidatingCupon = true;
    this.isValidCoupon = false;
    const cuponesLocalStorage = this.cupones;
    const listaDecodigoDeCuponesDentroDeLocalstorage: string[] = [];
    if (cuponesLocalStorage !== null) {
      cuponesLocalStorage.forEach(c => {
        listaDecodigoDeCuponesDentroDeLocalstorage.push(c.barCode);
      })
    }
    this.discountCodeForm.codeControl.setValue(this.discountCodeForm.codeControl.value.trim());
    this.discountCodeForm.codeControl.setValue(this.discountCodeForm.codeControl.value.toUpperCase());
    const currentCupon = this.discountCodeForm.codeControl.value;
    if (!listaDecodigoDeCuponesDentroDeLocalstorage.includes(currentCupon)) {
      const params = { coupon: currentCupon } as IDiscountCodeRequestParams;
      this.discountCodeImplement.validateDiscountCode$(params)
        .pipe(take(1))
        .subscribe(response => {
          if (response.valid) {
            const couponInput = new Coupon();
            couponInput.barCode = currentCupon;
            couponInput.productName = currentCupon;
            this._snackbarAlertInComingService.snackbarAlertSuccess('Código aplicado correctamente.', this.toastConfigSmallFormDeleteDirection);
            this.cupones.push(couponInput);
            this.discountCodeForm.resetInputForm();
          } else {
            this.isValidCoupon = true;
            this.modalError(currentCupon);
          }
          this.isValidatingCupon = false;
        });
    } else {
      this.discountCodeForm.resetInputForm();
      this.isValidatingCupon = false;
    }
  }
  validationApplyCupon(coupon: Coupon) {
    const cuponesLocalStorage = this.cupones;
    this.isValidCoupon = false;
    this.isLoading = true;
    const listaDecodigoDeCuponesDentroDeLocalstorage: string[] = [];
    if (cuponesLocalStorage !== null) {
      cuponesLocalStorage.forEach(c => {
        listaDecodigoDeCuponesDentroDeLocalstorage.push(c.barCode);
      })
    }
    const currentCupon = coupon.barCode;
    if (!listaDecodigoDeCuponesDentroDeLocalstorage.includes(currentCupon)) {
      const params = { coupon: currentCupon } as IDiscountCodeRequestParams;
      this.discountCodeImplement.validateDiscountCode$(params)
        .pipe(take(1))
        .subscribe(response => {
          if (response.valid) {
            this._snackbarAlertInComingService.snackbarAlertSuccess('Código aplicado correctamente.', this.toastConfigSmallFormDeleteDirection);
            this.cupones.push(coupon);
          } else {
            this.isValidCoupon = true;
            this.modalError(currentCupon);
          }
          this.isLoading = false;
        });
    }
  }
  modalError(cuponCode:string){
    if(this.isDesktopVersion) return
    const dialogRefError =  this.dialog.open(ModalInvalidCouponComponent, {
      panelClass: "modal-class-error",
      disableClose: true,
      data: {cuponCode},
    });
    dialogRefError.afterClosed().subscribe((data) => {
      this.isValidCoupon = false;
    });
  }
  handleError(error: HttpErrorResponse) {
    return observableCartError(error.message)
  }

  private get breakpoint$() {
    return this.breakpointObserver.observe([
      `(min-width: ${BREAKPOINTS.xl}px)`
    ]);
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next({});
    this._unsubscribeAll.complete();
  }

  taggPointsAdd(){
    TaggingService.triggerTag({
      eventCategory:'UI :: Checkout',
      eventAction: 'ui_checkout_agregar_cupon',
      eventLabel: 'Modal :: Boton - agregar'
    })
  }
  taggPointsApply(){
    TaggingService.triggerTag({
      eventCategory:'UI :: Checkout',
      eventAction: 'ui_checkout_modal_aplicar_cupon',
      eventLabel: 'Modal :: Boton - aplicar'
    })
  }
  taggPointsAccept(){
    TaggingService.triggerTag({
      eventCategory:'UI :: Checkout',
      eventAction: 'ui_checkout_modal_aceptar_cupon',
      eventLabel: 'Modal :: Boton - aceptar'
    })
  }
  taggPointsClose(){
    TaggingService.triggerTag({
      eventCategory:'UI :: Checkout',
      eventAction: 'ui_checkout_modal_cerrar',
      eventLabel: 'Modal :: Boton - cerrar'
    })
  }
}
