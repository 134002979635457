<div class="purchase-summary-collapse" #matExpansionPanel>
  <div class="row">
    <div class="col" id="lb--collapsed-total-container">
      <mat-accordion>
        <mat-expansion-panel
          class="shadow-none rounded-0"
          hideToggle
          [expanded]="isExpanded"
          (afterExpand)="afterExpand()"
          (afterCollapse)="afterCollapse()"
        >
          <mat-expansion-panel-header
            collapsedHeight="54px"
            expandedHeight="54px"
          >
            <div class="label">Resumen de compra</div>
            <span
              class="icon icon-24-chevron-down"
              [class.rotate-180]="!isExpanded"
            ></span>
          </mat-expansion-panel-header>
          <hr class="m-0" />
          <div class="content pt-3 pb-3">
            <fp-purchase-summary-list [summary]="summary">
            </fp-purchase-summary-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <hr class="m-0" />

  <div class="content">
    <div class="row purchase-summary-collapse-total">
      <div class="col-12">
          <fp-purchase-summary-total-mifa *fpBrandAccess="[brand.mifarma]"
            [summary]="summary"
            [summaryCard]="summaryCard">
          </fp-purchase-summary-total-mifa>
          <fp-purchase-summary-total *fpBrandAccess="[brand.inkafarma]"
            [summary]="summary"
            [summaryCard]="summaryCard">
          </fp-purchase-summary-total>
      </div>
    </div>

    <div class="row purchase-summary-action">
      <div class="col-12">


          <fp-button-primary *ngIf="isAliviamedConsulta"
          buttonId="btn--go-to-checkout"
          buttonDisplay="block"
          [class.card-disabled]="disabledCards"
          (click)="openWhatsappModal()"
          >
          Ir a comprar
          </fp-button-primary>

        <fp-button-primary *ngIf="!isAliviamedConsulta"
          buttonId="btn--go-to-checkout"
          buttonDisplay="block"
          [class.card-disabled]="disabledCards"
          (click)="buyNowEvent()"
        >
          Ir a comprar
        </fp-button-primary>
      </div>
    </div>
  </div>
</div>
