import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { GlobalProductCardService } from "src/app/core/modules/product-card/service/global-product-card.service";
import { CheckoutPaymentMethodFormService } from "../../../checkout-forms/checkout-payment-method-form.service";
import { CheckoutCouponAnonymusComponent } from "../checkout-coupon-anonymus/checkout-coupon-anonymus.component";
import { CheckoutOrderSummaryComponent } from "../checkout-order-summary/checkout-order-summary.component";
import { CheckoutUserDataCheckComponent } from "../checkout-user-data-check/checkout-user-data-check.component";
import { SectionContainerPointsV2Component } from "src/app/core/modules/containers/section-container-points-v2/section-container-points-v2.component";
import { CheckoutUserDataCheckV2Component } from "../checkout-user-data-check-v2/checkout-user-data-check-v2.component";

@Component({
	selector: "fp-checkout-order-popup",
	templateUrl: "./checkout-order-popup.component.html",
	styleUrls: ["./checkout-order-popup.component.sass"]
})
export class CheckoutOrderPopupComponent {
	bestCuppon: boolean = true;
	paymetohd: string = "0";

	ohMini: string = "0";
	amountCupon: number = 0;
	checkoutUserDataCheckComponent: CheckoutUserDataCheckComponent;
	checkoutUserDataCheckV2Component: CheckoutUserDataCheckV2Component;
	checkoutOrderSummaryComponent: CheckoutOrderSummaryComponent;
	checkoutCouponAnonymusComponent: CheckoutCouponAnonymusComponent;
	sectionContainerPointsV2Component: SectionContainerPointsV2Component;
	constructor(
		public paymentMethodForm: CheckoutPaymentMethodFormService,
		public dialogRef: MatDialogRef<CheckoutOrderPopupComponent>,
		public _globalProductCardService: GlobalProductCardService
	) {}

	btnClose() {
		if (!this.bestCuppon) {
			if (this.checkoutCouponAnonymusComponent) {
				// this.checkoutOrderSummaryComponent.deletedCoupon();
				this.checkoutCouponAnonymusComponent.deletedCoupon();
			} else if (this.sectionContainerPointsV2Component) {
				this.sectionContainerPointsV2Component.deletedCoupon();
			} else {
				this.paymentMethodForm.setDeleteCoupon.emit(true);
			}
		} else {
			this.checkoutUserDataCheckV2Component.finishPurchase();
		}
		this.dialogRef.close();
	}
	close() {
		this.dialogRef.close();
	}

	trackByImage(_: number, urlImage: string): string {
		return urlImage;
	}
}
