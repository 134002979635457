<div class="dialog-close">
  <span class="icon icon-24-close icon-close cursor-pointer" (click)="close()"> </span>
</div>
<div class="out-of-stock">
  <div class="out-of-stock__icon">
		<img *ngIf="isMifaBrand" src="../../../../assets/icons/cart-mifa.svg" alt="Carrito Fuera de stock" />
		<img *ngIf="!isMifaBrand" src="../../../../assets/icons/cart-inka.svg" alt="Carrito Fuera de stock" />
  </div>
  <h2>Lamentablemente tienes uno o más productos que no son despachados por Inkafarma.</h2>
  <ng-container>
    <p>Realizar tu pedido solo con despacho a domicilio.</p>
    <div class="out-of-stock__actions d-flex flex-column">
      <fp-button-primary class="mb-2" buttonDisplay="inline-block" (click)="close()"> 
        Entendido
      </fp-button-primary>
    </div>
  </ng-container>
</div>