import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-hexagon[hexagonSize]',
  templateUrl: './hexagon.component.html',
  styleUrls: ['./hexagon.component.sass']
})
export class HexagonComponent implements OnInit {
  @Input() hexagonSize: number;
  @Input() hexagonColor: string;
  @Input() hexagonPosition: 'center';

  constructor() {
  }

  ngOnInit() {
  }

}
