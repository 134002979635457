import { Component, Input } from '@angular/core';

@Component({
  selector: 'fp-link-inline',
  templateUrl: './link-inline.component.html'
})
export class LinkInlineComponent {
  @Input()
  linkHref: any[] | string;
}
