
export const LS_ID_FAVORITE_ADDRESS = 'id_favorite_address';
export const LS_ADDRESS_SELECTED = 'address_selected';
export const LS_CURRENT_ADDRESS = 'currentAddress';
export const LS_CURRENT_ADDRESS_LIST = 'currentAddressList';

export const LS_CURRENT_DRUGSTORE = 'drugstoreSelected';
export const LS_RAD_OR_RET = 'isRetOrRad';

export const OT_ADDRESS_SELECTED = "OT_ADDRESS_SELECTED";
