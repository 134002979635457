import { FormControl, Validators } from '@angular/forms';
import { getEnableErrorMessages, getErrorbyFormControl } from '../helpers/error-controls-helpers';
import { GenericValidator } from '../validators/generic-validator';
import { DOCUMENT_TYPE_ERROR_MESSAGES } from '@parameters/error-message-forms';

export class DocumentTypeControl extends FormControl {
  constructor() {
    super('');
    this.settingNameValidators();
  }

  private settingNameValidators() {
    this.setValidators([
      Validators.required,
      GenericValidator.validateValueDocumentType,
    ]);
  }
  public get documentTypeError(): string {
    return getErrorbyFormControl(this, DOCUMENT_TYPE_ERROR_MESSAGES);
  }
  public get error(): boolean {
    return getEnableErrorMessages(this);
  }
}
