import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailMask'
})
export class EmailMaskPipe implements PipeTransform {

  transform(value: string): string {
    const [ username, domain ] = value.split('@');
    return `${username.slice(0, 3)}********${domain}`;
  }

}
