import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { EBrand } from "@parameters/access/brand.parameter";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { Subscription } from "rxjs";
import { debounceTime, finalize } from "rxjs/operators";
import { SelectDataStoreService } from "src/app/business/office/store/select-data-store.service";
import { IBasicLocation } from "src/app/shared/geolocation/geolocation.service";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { AddressManagerPersistanceService } from "../../../address-manager/address-services/address-manager-persistance.service";
import { ISearchAutocompleteItem } from "../../../address-manager/models/google-map.interface";
import {
  ADDRESSES_PREDICTIVE_DELAY,
  ADDRESS_MINIMAL_LENGHT,
} from "../../../address-manager/parameters/global.parameter";
import { HereMapPlacesService } from "../../../here-maps/services/here-map-places.service";
import {
  IDrugstore,
  IDrugStoreRequest,
} from "../../models/drugstore.interface";
import { DRUG_STORE_CONFIG } from "../../parameters/parameters.const";
import { SelectedDrugstoreHttpService } from "../../services/selected-drugstore-http.service";

@Component({
  selector: "fp-selected-drugstore",
  templateUrl: "./selected-drugstore.component.html",
  styleUrls: ["./selected-drugstore.component.sass"],
})
export class SelectedDrugstoreComponent implements OnInit, AfterViewInit {
  /* prettier-ignore */
  @ViewChild("hereMap", { static: false }) hereMapRef: ElementRef<HTMLDivElement>;

  form: FormGroup;
  listDrugstore: IDrugstore[] = [];

  showCurrentLocationItem = false;
  showSearchAutocompleteList = false;
  searchAutocompleteList: ISearchAutocompleteItem[] = [];
  isFocused = false;

  loader: boolean = false;
  subscriptions: Subscription[] = [];
  drugstoreData: IDrugstore;
  constructor(
    private hereMapPlacesService: HereMapPlacesService,
    private thisModalRef: MatDialogRef<SelectedDrugstoreComponent>,
    private _selectedDrugstoreHttp: SelectedDrugstoreHttpService,
    private addressManager: AddressManagerPersistanceService,
    private _appStore: AppStoreService,
    private _selectDataStore: SelectDataStoreService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      searchText: new FormControl(""),
      idDrugstore: new FormControl("", Validators.required),
    });

    this.hereMapPlacesService.setMarketImg(
      "assets/images/map/icon-marker-inka.png"
    );

    this.loader = true;
    this.loadDataFaker();
    this.subscribeDirectionControlObserver();
  }

  ngAfterViewInit(): void {
    const location =
      this.listDrugstore.length > 0
        ? {
            lat: this.listDrugstore[0].latitude,
            lng: this.listDrugstore[0].longitude,
          }
        : {
            lat: -14.063477273706589,
            lng: -75.73029514257036,
          };

    this.hereMapPlacesService.showMap(this.hereMapRef, location);
    if (this.listDrugstore.length > 0) {
      this.idDrugstoreControl.setValue(this.listDrugstore[0].id);
      this.idDrugstoreControl.updateValueAndValidity();
      this.hereMapPlacesService.addMarker(location);
    }
    this.loader = false;
  }

  private subscribeDirectionControlObserver() {
    const directionControlSubscription = this.searchTextControl.valueChanges
      .pipe(debounceTime(ADDRESSES_PREDICTIVE_DELAY))
      .subscribe((address) => {
        if (this.isFocused && address.length > 0) {
          this.showSearchAutocompleteList = true;
          if (address.length >= ADDRESS_MINIMAL_LENGHT) {
            this.hereMapPlacesService.getAutosuggestService$(address).subscribe(
              (formattedAddresses) => {
                this.searchAutocompleteList = formattedAddresses.items.map(
                  (_address) => {
                    return {
                      icon: "pin",
                      title: _address.title,
                      subTitle: _address.address ? _address.address.label : "",
                      searchItem: _address,
                    } as ISearchAutocompleteItem;
                  }
                );
              },
              (error) => {
                console.error(error);
              }
            );
          }
        }
      });

    const directionControlSubscription2 =
      this.searchTextControl.valueChanges.subscribe((address) => {
        this.showSearchAutocompleteList = false;
        this.searchAutocompleteList = [];
        if (address.length < ADDRESS_MINIMAL_LENGHT) {
          this.showSearchAutocompleteList = false;
        }
        if (address.length === 0) {
          this.showCurrentLocationItem = true;
        } else {
          this.showCurrentLocationItem = false;
        }
      });

    this.subscriptions.push(
      directionControlSubscription,
      directionControlSubscription2
    );
  }

  loadDataFaker(lat?, lng?) {
    this.listDrugstore = [];
    this.loader = true;
    this._selectedDrugstoreHttp
      .getDrugStoreList$({
        position: {
          latitude: lat
            ? lat
            : this.addressManager.currentAddressUpdate.latitude,
          longitude: lng
            ? lng
            : this.addressManager.currentAddressUpdate.longitude,
        },
        proximity: DRUG_STORE_CONFIG.PROXIMITY,
        companyCode:
          this._appStore.brandAtention == EBrand.inkafarma
            ? EBrand.inkafarma
            : EBrand.mifarma,
        page: DRUG_STORE_CONFIG.PAGE,
        maxSixeResult: DRUG_STORE_CONFIG.MAX_SIZE_RESULT,
      } as IDrugStoreRequest)
      .pipe(finalize(() => (this.loader = false)))
      .subscribe((res) => (this.listDrugstore = res));
  }

  onCancel() {
    this.thisModalRef.close();
  }
  onSave() {
    this._selectDataStore.setNearbyStore(this.drugstoreData);
    this._selectDataStore.isReadyToReset = true;
    this.thisModalRef.close();
  }

  changeLocationHereMap(location: { lat: number; lng: number }) {
    // this.hereMapPlacesService.removeMarker(); zsxmnj
    // this.hereMapPlacesService.clearMap();
    // this.hereMapPlacesService.showMap(this.hereMapRef, location);
    this.hereMapPlacesService.addMarker(location);
  }

  onSelectedDrugstore(idDrugstore: string) {
    this.idDrugstoreControl.setValue(idDrugstore);
    this.idDrugstoreControl.updateValueAndValidity();

    this.drugstoreData = this.listDrugstore.find(
      (drugstore) => drugstore.id === idDrugstore
    );
    this.drugstoreData
      ? this.changeLocationHereMap({
          lat: this.drugstoreData.latitude,
          lng: this.drugstoreData.longitude,
        })
      : null;
  }

  public addressIsChanging() {
    this.isFocused = true;
  }

  public searchFocus() {
    this.isFocused = true;
    const address = this.searchTextControl.value as string;
    if (address.length === 0) {
      this.showCurrentLocationItem = true;
    }
    if (address.length >= ADDRESS_MINIMAL_LENGHT) {
      this.showSearchAutocompleteList = true;
    }
  }

  public searchBlur() {
    setTimeout(() => {
      this.isFocused = false;
      this.showCurrentLocationItem = false;
      this.showSearchAutocompleteList = false;
    }, 300);
  }

  public locateAddressOnTheHereMap(hereFormattedAddress: any) {
    const { position } = hereFormattedAddress;
    const _location = {
      lat: position.lat,
      lng: position.lng,
    } as IBasicLocation;

    this.searchTextControl.setValue(hereFormattedAddress.address.label);
    this.loader = true;
    if (OrderTakerValidator.isOrderTakerEnv()) {
      this.loadDataFaker(_location.lat, _location.lng);
      this.changeLocationHereMap({
        lat: _location.lat,
        lng: _location.lng,
      });
    }

    setTimeout(() => {
      this.loader = false;
    }, 2000);

    // this.directionSuccessControl.setValue(true);
    // this.searchAutocompleteList = [];
    // this.hereMapPlacesService.addMarker(_location);
  }

  get searchTextControl() {
    return this.form.controls["searchText"] as FormControl;
  }

  get idDrugstoreControl() {
    return this.form.controls["idDrugstore"] as FormControl;
  }
}
