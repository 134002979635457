import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
@Component({
  selector: 'fp-link',
  templateUrl: './link.component.html'
})
export class LinkComponent implements AfterViewInit {

  @Input()
  linkClass: string;

  @Input()
  linkRouter: any[] | string;

  @Input()
  linkRouterActive: string[] | string = '';

  @Input()
  linkRouterActiveOptions: { exact: boolean } = { exact: false };

  @Output()
  linkActive = new EventEmitter();

  @Input()
  linkHref: string;

  @Input()
  linkTarget: '_blank' | '_self' | '_parent' | '_top' | 'framename' = '_self';

  @Input() linkId = '';
  @ViewChild('ref01') ref01: ElementRef<HTMLLinkElement>;
  @ViewChild('ref02') ref02: ElementRef<HTMLLinkElement>;

  constructor(
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    if (this.linkId) {
      const currentRef = this.ref01
        ? this.ref01 : this.ref02
        ? this.ref02 : null;
      if (currentRef) {
        this.renderer.setAttribute(currentRef.nativeElement, 'id', this.linkId);
      }
    }
  }

  activeLinkRoute() {
    this.linkActive.emit();
  }

}
