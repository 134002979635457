import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "fp-stepper",
	templateUrl: "./stepper.component.html",
	styleUrls: ["./stepper.component.sass"]
})
export class StepperComponent implements OnInit {
	@Input()
	total: number;
	@Input()
	step: number;

	@Input() type: string = 'normal';

	constructor() {}

	ngOnInit(): void {}
	get progressTotal() {
		let progress = (this.step * 100) / this.total;
		return progress;
	}
}
