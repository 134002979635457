<fp-section-container>
	<div class="mt-3">
		<fp-section-container-title-v2 [iconSize]="32" [title]="title" [svgName]="'card-monedero'"> </fp-section-container-title-v2>
	</div>
	<fp-sub-section-container-v2>
		<div class="d-flex flex-column py-xs-3 py-md-5">
			<div *ngIf="showLoadSection" class="" style="padding: 0 28px 0 71px">
				<fp-basic-skeleton [height]="70"></fp-basic-skeleton>
			</div>
			<ng-container *ngIf="!showLoadSection">
				<ng-container *ngIf="UserLoginLogout">
					<fp-disclaimer-high-discount
						*ngIf="!statusHighDiscount && subTotalHighDiscount > 0"
						[section]="'checkout'"
						[discount]="subTotalHighDiscount"
					></fp-disclaimer-high-discount>
					<div class="d-flex flex-row align-items-start container-price p-md-5 p-xs-3 mb-xs-3 mb-md-4">
						<fp-icon class="d-inline-flex" iconSize="32" iconName="coupon-ticket"></fp-icon>
						<div class="d-md-flex justify-content-md-between align-items-center ml-xs-3 ml-sm-5 ml-md-5 ml-lg-4 w-100 gap-16">
							<div class="row w-md-75 m-0 mb-xs-3 w-xs-100" [ngClass]="{ 'w-75': isDesktop, 'w-100': !isDesktop }">
								<p class="col-12 p-0 m-0 label-black-lg--1 label-black-xl label-black-xxl-0 text-weight pb-2">
									<ng-container *ngIf="coupons?.length > 0; else noCoupons">
										Tienes <span class="color-span"> {{ coupons?.length }} cupones especiales</span> para ti,
										¡canjéalos y ahorra!
									</ng-container>
									<ng-template #noCoupons>
										Aún no tienes cupones especiales, ¡Sigue ahorrando con tu
										<span class="color-span">Monedero del Ahorro</span> para obtener más beneficios!
									</ng-template>
								</p>
								<p class="col-12 p-0 m-0 text-regular--1">
									{{ cupArr?.length > 0 ? "" : "Si tienes un código de descuento, también lo puedes agregar" }}
								</p>
								<div class="col-12 d-flex flex-wrap p-0">
									<div class="container-coupon mt-3 mr-2" *ngFor="let item of cupArr; let i = index">
										<div class="d-flex justify-content-between align-items-center">
											<span
												style="
													text-transform: uppercase;
													overflow: hidden;
													white-space: nowrap;
													text-overflow: ellipsis;
													max-width: 80px;
												"
											>
												{{ item.productName }}</span
											>
											<fp-icon
												role="button"
												iconSize="16"
												iconName="close-outline"
												(click)="removeTag(item.barCode, 1)"
											></fp-icon>
										</div>
									</div>
								</div>
							</div>
              <div class="shrink-0">
                <fp-button-outline
				  id="openModalCoupon"
                  buttonClass="btn-coupon-outline"
                  buttonDisplay="block"
                  (click)="openCouponSelectionDialog()"
                >
                  {{ coupons?.length > 0 ? "Ver cupones" : "Agregar código de descuento" }}
                </fp-button-outline>
              </div>
						</div>
					</div>
					<div class="d-flex justify-content-between align-items-center container-price p-md-5 p-xs-3">
						<div class="d-flex flex-row">
							<fp-icon class="d-inline-flex" iconSize="32" iconName="face-smile"></fp-icon>
							<div class="d-flex flex-column ml-xs-3 ml-md-4 ml-5">
								<p class="p-0 m-0 label-black-lg--1 label-black-xl label-black-xxl-0 text-weight pb-2">
									¡Desde ahora tus puntos se multiplican!
								</p>
								<p class="p-0 m-0 text-regular--1">
									Gracias a tu Monedero del Ahorro, en esta compra acumularás
									<span class="label-black-lg--1 label-black-xl label-black-xxl-0 text-weight color-span"
										>{{ points }} puntos.</span
									>
								</p>
							</div>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="!UserLoginLogout">
					<div
						class="d-flex flex-row align-items-start container-price p-md-5 p-xs-3"
						style="border-color: #fff5eb; background: #fffbf7"
					>
						<fp-icon class="d-inline-flex" iconSize="32" iconName="face-smile"></fp-icon>
						<div class="pl-xs-3 d-md-flex justify-content-md-between w-100">
							<div class="d-flex flex-column mb-3">
								<p class="p-0 m-0 label-black-lg--1 label-black-xl label-black-xxl-0 text-weight pb-2">
									¡Ahorra! Y obtén el mejor precio con tu Monedero del Ahorro.
								</p>
								<p class="p-0 m-0 text-regular--1">
									Inicia sesión o regístrate para ahorrar
									<span class="label-black-lg--1 label-black-xl label-black-xxl-0 text-weight color-span">{{
										points | currency : "S/ "
									}}</span>
									y acumular
									<span class="label-black-lg--1 label-black-xl label-black-xxl-0 text-weight color-span"
										>{{ points }} puntos</span
									>
									en esta compra.
								</p>
							</div>
							<fp-button-outline
							    id="openModalCoupon"
								buttonClass="btn-coupon-outline"
								[buttonStyle]="{ 'background-color': '#FFFBF7 ' }"
								buttonDisplay="block"
								(click)="openRegisterModal()"
							>
								Ingresar
							</fp-button-outline>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</fp-sub-section-container-v2>
</fp-section-container>
