import {Directive, ElementRef, Input, OnChanges, Renderer2} from '@angular/core';

@Directive({
  selector: '[fpEllipsis]'
})
export class EllipsisDirective implements OnChanges {

  @Input() ellipsisLines = 1;
  @Input() ellipsisSize = 1;

  constructor(
    private _Renderer: Renderer2,
    private _ElementRef: ElementRef) {
    this.setStyle();
  }

  ngOnChanges(): void {
    this.setStyle();
  }

  setStyle() {
    this._Renderer.setAttribute(this._ElementRef.nativeElement,
      'style',
      `overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: ${this.ellipsisLines};
              -webkit-box-orient: vertical;
              max-height: ${this.ellipsisSize * this.ellipsisLines}rem;
              line-height: ${this.ellipsisSize}rem;`);
  }
}
