<a [id]="buttonId">
  <button
    class="btn"
    [id]="buttonId"
    [type]="buttonType"
    [ngClass]="buttonClass"
    [disabled]="buttonDisabled"
    [ngStyle]="buttonStyle">
    <ng-content></ng-content>
  </button>
</a>
