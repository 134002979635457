import { Injectable } from '@angular/core';
import { RegisterClientService } from 'src/app/shared/service/user-register/register-client.service';
import { RegisterResponse, IUserOTPRequestParams } from 'src/app/shared/models/register/register.model';
import { Observable } from 'rxjs';
import { UserRegisterRequest, EmailPhoneVerificationRequest, EmailVerificationRequest } from 'src/app/shared/models/register/user-register-request.model';

@Injectable()
export class RegisterImplementService {

  constructor(
    private _registerClientService: RegisterClientService
  ) { }

  public saveOrUpdateUser(request: UserRegisterRequest | EmailVerificationRequest | EmailPhoneVerificationRequest): Observable<RegisterResponse> {
    return this._registerClientService.saveOrUpdateUser$(request);
  }

  public tokenValidator(userRegisterRequest: IUserOTPRequestParams) {
    return this._registerClientService.tokenValidator$(userRegisterRequest);
  }

  public getUser() {
    return this._registerClientService.getUser$();
  }

  public sendOtp(phoneNumber: string, emailAddress: string, companyId: number) {
    return this._registerClientService.sendOtp$(phoneNumber, emailAddress, companyId);
  }

  doRegisterCookies(userResponseData) {
    const userAcceptCookies = localStorage.getItem("user_accept_cookies");
    const userUpdateCookies = Object.assign(
      new UserRegisterRequest(),
      userResponseData
    );

    userUpdateCookies.flagCookiePolicies = !!userAcceptCookies;

    return this.saveOrUpdateUser(userUpdateCookies).pipe();
  }
  public updateUser(data: any){
    return this._registerClientService.updateUser$(data);
  }
}
