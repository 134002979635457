import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ENDPOINTS } from '../../parameters/endpoints';
import { CartConfirmationResponse } from '../../models/cart-confirmation/cart-confirmation.model';
import { ICartConfirmationResponse, ICartConfirmationRequestParams } from '../../models/cart-confirmation/cart-confirmation.interface';

import { GenericService } from '../generic';

@Injectable()
export class CartConfirmationClientService {

  constructor(
    private genericService: GenericService,
  ) { }

  public validateCartConfirmation$(params: ICartConfirmationRequestParams): Observable<CartConfirmationResponse> {
    return this.genericService.genericPostCart<ICartConfirmationResponse>(ENDPOINTS.CART_CONFIRMATION, params)
      .pipe(
        map((response: ICartConfirmationResponse) => {
          return new CartConfirmationResponse(response);
        })
      );
  }
}
