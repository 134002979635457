import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fp-select-office-form',
  templateUrl: './select-office-form.component.html',
  styleUrls: ['./select-office-form.component.sass']
})
export class SelectOfficeFormComponent implements OnInit {
  provinces=['Lima','Ica']
  districts = ['Miraflores','Magdalena', 'San Borja','San Luis']
  offices = ['C-45 - Rivera Navarrete','IKF 017 - San Borja', 'IKF 013 - Aviación','IKF 011 - Guardia Civil']
  constructor() { }

  ngOnInit(): void {
  }

}
