<fp-button
  buttonClass="btn-ghost{{buttonColor ? '-red' : ''}}
  btn-spacer-{{buttonSize}}
  label-black{{buttonSize === 'small' ? '--1' : ''}}
  btn-{{buttonDisplay}}
  {{optionalClass}}"
  [buttonType]="buttonType"
  [buttonDisabled]="buttonDisabled"
  [buttonStyle]="buttonDisabled && {'color':'#8496BC'}"
  [buttonId]="buttonId">
  <ng-content></ng-content>
</fp-button>
