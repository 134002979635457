import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DiscountCodeControl } from '../controls/discount-code-control';

@Injectable()
export class CheckoutDiscountCodeFormService {

  public form: FormGroup;

  private _discountCode = new DiscountCodeControl();

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      code: this._discountCode,

    });
  }
  public get resetForm() {
    return this.form.reset('code');
  }

  public get codeControl() {
    return this.form.get('code') as DiscountCodeControl;
  }
  public resetInputForm() {
    this.codeControl.setValue('');
    this.codeControl.markAsUntouched();
    this.codeControl.markAsPristine();
  }
}
