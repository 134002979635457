<div class="content-skeleton">
  <div class="skeleton"
  [style.width.%]="width"
  [style.height.px]="height"
  >
  <div class="product-content-flex">
    <div class="image" ></div>
    <div class="description" ></div>
  </div>
  <div class="botton"></div>
  </div>
</div>
