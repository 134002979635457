import { Injectable } from '@angular/core';
import { IOrder, Order, IOrderFirebase } from 'src/app/shared/models/orders-firebase/order-firebase.model';
import { OrdersFirebaseClientService } from 'src/app/shared/service/orders-firebase/orders-firebase-client.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {ENDPOINTS} from '../../../shared/parameters/endpoints';
import { MotorizedLocation } from 'src/app/shared/models/orders-firebase/motorized.model';
import {COMPANY_CODE} from '@parameters/global';
import {environment} from '@environments/environment';
import {EBrand} from '@parameters/access/brand.parameter';
import { IAlgoliaSettings } from '@models/orders-firebase/settings.model';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class ActiveOrderFirebaseService {

  constructor(
    private _OrdersFirebaseClientService: OrdersFirebaseClientService
  ) {
  }
  iOrders: IOrder;
  orders: Order;
  iords: IOrderFirebase[] = [];

  public getOrderFirebaseList(user: string): Observable<Order> {
    const node = ENDPOINTS.NODE_CLIENT_USER_ID;
    return  this._OrdersFirebaseClientService.getOrdersFirebaseByFilter(node, user).valueChanges().pipe(map (data => {
    const ordersFilterByCompany = environment.brand === EBrand.mifarma ? data.filter(order => order['companyCode'] === COMPANY_CODE) : data;
    return  this.transformers(ordersFilterByCompany.map( item => item as IOrderFirebase));
  }));
}

public getOrderFirebaseListLite(user: string): Observable<Order> {
  const node = ENDPOINTS.NODE_CLIENT_USER_ID;
  return  this._OrdersFirebaseClientService.getOrdersFirebaseByFilterLite(node, user).valueChanges().pipe(map (data => {
      const ordersFilterByCompany = environment.brand === EBrand.mifarma ? data.filter(order => order['companyCode'] === COMPANY_CODE): data;
      return  this.transformers(ordersFilterByCompany.map( item => item as IOrderFirebase));
    }));
  }

  transformers(iords: IOrderFirebase[]): Order {
    let order: Order;
    let Iorder: IOrder;
    Iorder = {
      orders: iords
    };

    order = new Order(Iorder);
    return order;
  }

  public getMotorizedLocation(motorizedUid: string): Observable<MotorizedLocation> {
    return this._OrdersFirebaseClientService.getMotorized(motorizedUid)
            .valueChanges()
            .pipe(
              map ((data) => new MotorizedLocation(data)));
  }

  public getAlgoliaSettings(): Observable<IAlgoliaSettings> {
    return this._OrdersFirebaseClientService.getSettingsFirebase()
            .valueChanges()
            .pipe();
  }
}
