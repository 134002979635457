import { isObject } from 'src/app/shared/helpers/objects-equal';

export interface IScheduleRequestParams {
  day?: string; // 2019-11-06 current date
  isToday?: string; // Y
  userAddressId: string; // 235208

  // pick up at store
  deliveryTypeId: string; // AM_PM , EXP, RET, REG
  drugstoreId: string; // 15
  zoneId?: number;
}

// SCHEDULE
export interface IScheduleItem {
  id: number;
  currentCapacity: string;
  day: string;
  dayToPickmessage: string;
  hourToPickMessage: string;
  hoursLocalmessage: string;
  alternativeMessage: string;
  maxCapacity: string;
  schedules: string;
  text: string;
  today: string;
  value: string;
  endValue: string;
  sellerId: string;
  sellerName: string;
}

export class ScheduleItem {
  public id: number;
  public currentCapacity: string;
  public day: string;
  public dayToPickmessage: string;
  public hourToPickMessage: string;
  public hoursLocalmessage: string;
  public alternativeMessage: string;
  public maxCapacity: string;
  public schedules: string;
  public text: string;
  public today: string;
  public value: string;
  public endValue: string;
  public sellerId: string;
  public sellerName: string;

  constructor(schedule: IScheduleItem) {
    const currentValue = isObject(schedule) ? schedule : {} as IScheduleItem;
    this.id = currentValue.id || -1;
    this.currentCapacity = currentValue.currentCapacity || '';
    this.day = currentValue.day || '';
    this.dayToPickmessage = currentValue.dayToPickmessage || '';
    this.hourToPickMessage = currentValue.hourToPickMessage || '';
    this.hoursLocalmessage = currentValue.hoursLocalmessage || '';
    this.alternativeMessage = currentValue.alternativeMessage || '';
    this.maxCapacity = currentValue.maxCapacity || '';
    this.schedules = currentValue.schedules || '';
    this.text = currentValue.text || '';
    this.today = currentValue.today || '';
    this.value = currentValue.value || '';
    this.endValue = currentValue.endValue || '';
    this.sellerId = currentValue.sellerId || '';
    this.sellerName = currentValue.sellerName || '';

  }
}

// SCHEDULE RESPONSE
export interface IScheduleResponse {
  schedules?: IScheduleItem[];
  service?: string;
  message?: string;
  code?: string;
  title?: string;
}

export class ScheduleResponseForRET {
  status: 'PENDING' | 'OK' | 'NOK';
  elements: ScheduleItem[];

  constructor(response: IScheduleResponse) {
    this.status = response === null ? 'PENDING'
      : (isObject(response) && Array.isArray(response.schedules)) ? 'OK' : 'NOK';
    this.elements = response === null ? []
      : (isObject(response) && Array.isArray(response.schedules)) ? response.schedules.map(element => new ScheduleItem(element)) : [];
  }
}

