<div class="favorite position-relative cursor-pointer" *ngIf="!hideProductFavorite"
     [style.width]="(favoriteSize === 'small' && isBigScreen ? 24 : 32) + 'px'"
     [class.card-disabled]="loader">
  <span
    *ngIf="!favorite"
    class="icon favorite-default"
    [class.icon-24-favorite-default]="favoriteSize === 'small' && isBigScreen"
    [class.icon-32-favorite-default-desktop]="favoriteSize === 'large' && isBigScreen"
    [class.icon-32-favorite-default-mobile]="favoriteSize === 'large' || !isBigScreen">
  </span>
  <span
    *ngIf="!favorite"
    (click)="addToFavorite()"
    class="icon favorite-hover"
    [class.icon-24-favorite-hover]="favoriteSize === 'small' && isBigScreen"
    [class.icon-32-favorite-hover-desktop]="favoriteSize === 'large' && isBigScreen"
    [class.icon-32-favorite-hover-mobile]="favoriteSize === 'large' || !isBigScreen">
  </span>
  <span
    *ngIf="favorite"
    (click)="removeFromFavorite()"
    class="icon"
    [class.icon-24-favorite-active]="favoriteSize === 'small' && isBigScreen"
    [class.icon-32-favorite-active-desktop]="favoriteSize === 'large' && isBigScreen"
    [class.icon-32-favorite-active-mobile]="favoriteSize === 'large' || !isBigScreen">
  </span>
</div>
