import { Injectable } from '@angular/core';

import { GenericService } from '../generic';
import { ENDPOINTS } from '../../parameters/endpoints';
import { INearestDrugstoresRequest, INearestDrugstoreResponse, NearestDrugstore } from '../../models/nearest-drugstores/nearest-drugstores.model';
import { map } from 'rxjs/operators';
import { OrderTakerValidator } from '@validators/order-taker.validator';

@Injectable()
export class NearestDrugstoresClientService {

  constructor(
    private genericService: GenericService,
  ) { }

  public getDrugstores$(params: INearestDrugstoresRequest) {
    const url = OrderTakerValidator.isOrderTakerEnv() ?
    ENDPOINTS.NEAREST_DRUGSTORES_TOMADOR :
    ENDPOINTS.NEAREST_DRUGSTORES;
    return this.genericService.genericPost<INearestDrugstoreResponse[]>(url, params)
      .pipe(map(response => {
        const _response = Array.isArray(response) ? response : [];
        const stores = _response.map(e => new NearestDrugstore(e));
        return stores;
      }));
  }

  public getSelectedDrugstores$(params: INearestDrugstoresRequest) {
    const url = OrderTakerValidator.isOrderTakerEnv() ?
    ENDPOINTS.NEAREST_SELECTED_DRUGSTORES_TOMADOR :
    ENDPOINTS.NEAREST_SELECTED_DRUGSTORES;
    return this.genericService.genericPost<INearestDrugstoreResponse[]>(url, params)
      .pipe(map(response => {
        const _response = Array.isArray(response) ? response : [];
        const stores = _response.map(e => new NearestDrugstore(e));
        return stores;
      }));
  }

  public getStaticDrugstores$(params: INearestDrugstoresRequest) {
    return this.genericService.genericGetStaticFile<INearestDrugstoreResponse[]>(ENDPOINTS.STATIC_NEAREST_DRUGSTORES)
      .pipe(map(response => {
        const _response = Array.isArray(response) ? response : [];
        const stores = _response.map(e => new NearestDrugstore(e));
        return stores;
      }));
  }
}
