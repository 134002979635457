import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {StaticLandingItem} from '../../models/static-landing.model';

@Component({
  selector: 'fp-static-landing-expansion-list',
  templateUrl: './static-landing-expansion-list.component.html',
  styleUrls: ['./static-landing-expansion-list.component.sass']
})
export class StaticLandingExpansionListComponent {

  @Input() loader = true;
  @Input() expansionList: Array<StaticLandingItem>;
  @Output() expansionOpened = new EventEmitter();

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  constructor() {
  }

  expansionOpenedEvent(items: Array<string>) {
    if (items) {
      this.expansionOpened.emit(items);
    }
  }

}
