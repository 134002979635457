import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ShoppingListStoreService } from "../../stores/shopping-list/shopping-list-store.service";
import { ShoppingCartRequestService } from "../shopping-cart/shopping-cart-request.service";
import { ModalsManagerService } from "src/app/core/modules/pick-up-in-store/pick-up-services/modals-manager.service";

@Injectable({
  providedIn: "root"
})
export class ShowModalBoticaService {
  activeCircleCoachmark = new BehaviorSubject(true);
  activeDrugstoreModal = new BehaviorSubject(false);
  fromProduct = new BehaviorSubject(false);

  constructor(
    private _shoppingListStore: ShoppingListStoreService,
    private _modalManagerService: ModalsManagerService,
    private _shoppingCartRequest: ShoppingCartRequestService
  ) {}

  get isFromProduct() {
    return this.fromProduct.getValue()
  }

  showModalResponsiveService(product, modalResponsive?, change?, prd?) {
    const intencionProducts = [{"productId": prd,"presentationIdSelected": 1,"quantity": 1,"sellerId": "","sellerName": ""}];
    const onlyProductInkafarma = [];
    const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList() || [];
    const shoppingCartList = prd ? shoppingCartListLocal.concat(intencionProducts) : shoppingCartListLocal;
    shoppingCartList.filter((prod) => {
      if (prod.sellerName === "") {
        onlyProductInkafarma.push({
          productId: prod.productId,
          presentationIdSelected: prod.presentationIdSelected,
          quantity: prod.quantity,
          sellerId: "",
          sellerName: "",
          productPack: prod.productPack
        });
      }
    });
    const request = onlyProductInkafarma.length > 0 ? this.removeEmptyProductId(onlyProductInkafarma) : onlyProductInkafarma;
    this._shoppingCartRequest.shoppingProductListRequest = request;
    this._modalManagerService.openNearbyBoticaResponsiveModal(product,modalResponsive, change, prd);
  }

  showModalDesktopService(product, loadDrugstores?: boolean, currentDrugstore?: any, desktop?, prd?: boolean, loadPageAfterClose: boolean = true) {
    const intencionProducts = [{"productId": prd,"presentationIdSelected": 1,"quantity": 1,"sellerId": "","sellerName": ""}];
    const onlyProductInkafarma = [];
    const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList() || [];
    const shoppingCartList = prd ? shoppingCartListLocal.concat(intencionProducts) : shoppingCartListLocal;
    shoppingCartList.filter((prod) => {
      if (prod.sellerName === "") {
        onlyProductInkafarma.push({
          productId: prod.productId,
          presentationIdSelected: prod.presentationIdSelected,
          quantity: prod.quantity,
          sellerId: "",
          sellerName: "",
          productPack: prod.productPack
        });
      }
    });
    const request = onlyProductInkafarma.length > 0 ? this.removeEmptyProductId(onlyProductInkafarma) : onlyProductInkafarma;
    this._shoppingCartRequest.shoppingProductListRequest = request;
    this._modalManagerService.openNearbyBoticaModal(product, loadDrugstores, currentDrugstore, desktop, prd, loadPageAfterClose);
  }

  removeEmptyProductId(array: any[]){
    return array.filter(item => item.productId !== '' && item.productId !== null);
  }
}
