import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Entry } from 'contentful';
import { ContentfulEntry } from '../../../shared/models/contentful/contentful.model';
import { IBlogDetail } from '../../../business/world-landing/models/world-blog-detail.model';


export enum EContentfulBlogListPreviewTemplate {
  template1 = 'Plantilla 1',
  template2 = 'Plantilla 2',
  template3 = 'Plantilla 3',
  template4 = 'Plantilla 4',
  template5 = 'Plantilla 5',
  templateDefault = ''
}

export interface IContentfulBlogListPreview {
  title: string;
  template: EContentfulBlogListPreviewTemplate;
  blogList: Array<Entry<IBlogDetail>>;
}

export class ContentfulBlogListPreview { //blogLandingPreview
  title: string;
  template: EContentfulBlogListPreviewTemplate;
  blogList: Array<ContentfulEntry<IBlogDetail>>;


  constructor(iContentfulBlogListPreview: IContentfulBlogListPreview) {
    this.title = iContentfulBlogListPreview.title || 'blog preview';
    this.template = iContentfulBlogListPreview.template || EContentfulBlogListPreviewTemplate.templateDefault;
    this.blogList = iContentfulBlogListPreview.blogList ?
      iContentfulBlogListPreview.blogList.map(blog => new ContentfulEntry(blog)) : [];
  }
}

export const CContentfulBlogListPreview = {
  id: CONTENTFUL_PATH.contentTypeIds.blogListPreview,
};



export const CContentfulBlogListPreviewTemplateConfig = {
  [EContentfulBlogListPreviewTemplate.template3]: {
    sliderConfig: {
      'type': 3,
      'loop': false,
      'autoplay': false,
      'autoplayTime': 5000,
      'infinite': false,
      'autoHeight': true,
      'slidesConfigByBreakpoints': {
        'xxs': {
          slidesPerView: 1,
          spaceBetween: 10
        },
        'sm': {
          slidesPerView: 1,
          spaceBetween: 10
        },
        'md': {
          slidesPerView: 2,
          spaceBetween: 14
        },
        'lg': {
          slidesPerView: 2,
          spaceBetween: 30
        },
        'xl': {
          slidesPerView: 3,
          spaceBetween: 47
        },
        'xxl': {
          slidesPerView: 3,
          spaceBetween: 47
        },
      }
    }
  },
  [EContentfulBlogListPreviewTemplate.template4]: {
    sliderConfig: {
      'type': 3,
      'loop': false,
      'autoplay': false,
      'autoplayTime': 5000,
      'infinite': false,
      'autoHeight': true,
      'slidesConfigByBreakpoints': {
        'xxs': {
          slidesPerView: 1,
          spaceBetween: 10
        },
        'sm': {
          slidesPerView: 1,
          spaceBetween: 10
        },
        'md': {
          slidesPerView: 3,
          spaceBetween: 14
        },
        'lg': {
          slidesPerView: 3,
          spaceBetween: 14
        },
        'xl': {
          slidesPerView: 3,
          spaceBetween: 14
        },
        'xxl': {
          slidesPerView: 3,
          spaceBetween: 14
        },
      }
    }
  }
};
