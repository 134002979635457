import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { EBrand, EBrandCode } from "@parameters/access/brand.parameter";
import { environment } from "@environments/environment";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { AppStoreService } from "@stores/app/app-store.service";

@Injectable()
export class SeoClientService {
  private readonly defaultMetaInka = {
    title: OrderTakerValidator.isOrderTakerEnv()
      ? "Toma de pedido | Inkafarma"
      : "Inkafarma: Más salud al mejor precio",
    keywords:
      "botica, inkafarma, inkafarma delivery, inkafarma teléfono, inkafarma delivery telefono, inkafarma precios, farmacia inkafarma, farmacia, minoxidil inkafarma, botica inkafarma delivery, botica inkafarma, inkafarma productos precios, inkafarma peru, inkafarma pe",
    description:
      "Compra online en Inkafarma y encuentra productos para cuidado personal, cuidado para tu bebé, belleza, farmacia y más a los mejores precios.",
  };

  private readonly defaultMetaMifa = {
    title: OrderTakerValidator.isOrderTakerEnv()
      ? "Toma de pedido | Mifarma"
      : "Mifarma: ¡Ahorros que hacen bien!",
    keywords:
      "mifarma, mifarmaperu, mifarma delivery, mifarma delivery y telefono, cuponero mifarma, cupon mifarma, mifarma online, monedero del ahorro mifarma, mifarma catalogo, mifarma cupones, consulta stock mifarma, consulta stock mifarma, mifarma stock",
    description:
      "Encuentra en Mifarma ofertas en dermocosmética, cuidado personal y mucho más. ¡Afíliate al Monedero del Ahorro Digital y suma Puntos Dinero en cada compra! ",
  };

  private readonly defaultMetaOrder = {
    title: "Toma de pedido",
    keywords:
      "mifarma, mifarmaperu, mifarma delivery, mifarma delivery y telefono, cuponero mifarma, cupon mifarma, mifarma online, monedero del ahorro mifarma, mifarma catalogo, mifarma cupones, consulta stock mifarma, consulta stock mifarma, mifarma stock",
    description:
      "Encuentra en Mifarma ofertas en dermocosmética, cuidado personal y mucho más. ¡Afíliate al Monedero del Ahorro Digital y suma Puntos Dinero en cada compra! ",
  };

  private defaultMeta: {
    title: string;
    keywords: string;
    description: string;
  } = this.getDefaultMeta(environment.brand);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _appStore: AppStoreService
  ) {}

  getDefaultMeta(brand: string): {
    title: string;
    keywords: string;
    description: string;
  } {
    let metaDefault;
    if (OrderTakerValidator.isOrderTakerEnv()) {
      this.setFavicon();

      if (!this._appStore.brandAtention) return this.defaultMetaOrder;
      metaDefault =
        EBrandCode[this._appStore.brandAtention] === EBrand.mifarma
          ? this.defaultMetaMifa
          : this.defaultMetaInka;
    } else {
      metaDefault =
        EBrand[environment.brand] === EBrand.inkafarma
          ? this.defaultMetaInka
          : this.defaultMetaMifa;
    }

    return metaDefault;
  }

  setDescription(description: string = this.defaultMeta.description) {
    this.setMetaContent("description", description);
  }

  setKeywords(keywords: string = this.defaultMeta.keywords) {
    this.setMetaContent("keywords", keywords);
  }

  setFavicon() {
    if (!OrderTakerValidator.isOrderTakerEnv()) return;

    let urlFavicon = "/assets/icons/farmacias/logo/favicon.ico";
    if (this._appStore.brandAtention) {
      urlFavicon =
        EBrandCode[this._appStore.brandAtention] === EBrand.mifarma
          ? "/assets/icons/mifarma/favicon.ico"
          : "/assets/icons/inkafarma/favicon.ico";
    }

    const $element = this.document.head.querySelector(".tomador-logo");
    $element.setAttribute("href", urlFavicon);
  }

  setTitle(
    title: string = this.defaultMeta.title,
    includePrefix: boolean = true
  ) {
    let element = this.document.head.querySelector("title");
    if (!element) {
      element = this.document.createElement("title");
      this.document.head.appendChild(element);
    }
    const nonEmptyTitle = title !== "" ? title : this.defaultMeta.title;
    if (includePrefix) {
      element.innerText = `${title}`;
    } else {
      element.innerText = `${nonEmptyTitle}`;
    }
  }

  private setMetaContent(name: string, content: string) {
    let meta = this.document.head.querySelector('meta[name="' + name + '"]');
    if (!meta) {
      meta = this.document.createElement("meta");
      meta.setAttribute("name", name);
      this.document.head.appendChild(meta);
    }
    meta.setAttribute("content", content);
  }

  setLink(typeLink: string, url = "") {
    let linkUrl = this.document.head.querySelector(
      'link[rel="' + typeLink + '"]'
    );
    if (!linkUrl) {
      linkUrl = this.document.createElement("link");
      linkUrl.setAttribute("rel", typeLink);
      this.document.head.appendChild(linkUrl);
    }
    const urlSEO = url !== "" ? url : this.document.URL;
    linkUrl.setAttribute("href", urlSEO);
  }

  setStructuredData(data: string, type = "application/ld+json") {
    let script = this.document.head.querySelector(
      'script[type="' + type + '"]'
    );
    if (!script) {
      script = this.document.createElement("script");
      script.setAttribute("type", type);
      this.document.head.appendChild(script);
    }
    script.innerHTML = data;
  }

  setRobots(description: string = this.defaultMeta.description) {
    this.setMetaContent("robots", description);
  }

  unsetRobots() {
    let meta = this.document.head.querySelector('meta[name="robots"]');
    meta.remove();
  }
}
