<label class="input-label" [ngClass]="labelClass">
  <input
    class="input-basic"
    [style.padding-right]="buttonClear ? '35px' : ''"
    [class.input-error]="error"
    [ngClass]="innerClass"
    [id]="id"
    [name]="name"
    [type]="behavior"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [(ngModel)]="inputValue"
    [readonly]="readOnly"
    [maxlength]="maxLength"
    (ngModelChange)="changeInputValue()"
    (blur)="onBlur.emit()"
    (focus)="emitOnFocus()"
    autocomplete="off"
  />

  <div class="container--icon--close">
    <fp-icon
      *ngIf="buttonClear && inputValue"
      (click)="clearValue()"
      innerClass="close"
      fontName="close"
      fontSize="16px"
    >
    </fp-icon>
  </div>
</label>
