import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartLoginModalManagerService } from './cart-login-modal-manager.service';
import { MainLoginModalManagerService } from './main-login-modal-manager.service';
import { AnonymousWarningManagerService } from './anonymous-warning-manager.service';
import { AnonymousModalValidationService } from './anonymous-modal-validation.service';
import { ForgotPasswordModalService } from './forgot-password-modal-service.service';
import { ForgotPasswordConfirmationModalService } from './forgot-password-confirmation-modal.service';
import { RegisterClientImplement } from './register-client-implement.service';
import { LoginImplementService } from './login-implement.service';
import { LoginModalsManagerService } from './login-modals-manager.service';
import { LoginModalsManagerHelperService } from './login-modals-manager-helper.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    CartLoginModalManagerService,
    MainLoginModalManagerService,
    AnonymousWarningManagerService,
    AnonymousModalValidationService,
    ForgotPasswordModalService,
    ForgotPasswordConfirmationModalService,
    RegisterClientImplement,
    LoginImplementService,
    LoginModalsManagerService,
    LoginModalsManagerHelperService,
  ],
})
export class LoginServicesModule { }
