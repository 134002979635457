import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { EProductStatus } from 'src/app/core/modules/product-card/models/product-status.enum';
import { ProductPresentation } from '../../../models/product-presentation.model';
import { AppStoreService } from "@stores/app/app-store.service";
import { ShoppingListStoreService } from '@stores/shopping-list/shopping-list-store.service';
@Component({
  selector: 'fp-product-presentation-select',
  templateUrl: './product-presentation-select.component.html',
  styleUrls: ['./product-presentation-select.component.sass']
})
export class ProductPresentationSelectComponent implements OnChanges {
  public eProductStatus = EProductStatus;
  public selectedPresentation: ProductPresentation;
  public availablePresentation: ProductPresentation;

  @Input() presentationList: Array<ProductPresentation> = [] as Array<ProductPresentation>;
  @Input() presentationSelected: number;
  @Input() productStatus: number = this.eProductStatus.available;
  @Input() productId: number;
  @Output() updatePresentation = new EventEmitter();

  constructor(private _appStore: AppStoreService,
    private shoppingListStore: ShoppingListStoreService
  ) {}

  ngOnChanges(): void {
    this.setSelectedPresentation();
    this.setAvailablePresentation();
  }

  setSelectedPresentation() {
    this.selectedPresentation = this.presentationList
      .find(presentation => presentation.id === this.presentationSelected);
  }

  setAvailablePresentation() {
    if (this.productStatus === this.eProductStatus.unavailablePresentation) {
      this.availablePresentation = this.presentationList
        .find(presentation => presentation.fractionated);
    }
  }

  selectPresentation(presentation: ProductPresentation) {
    this.selectedPresentation = presentation;
    this.updatePresentation.emit(presentation);
  }
}
