import { Injectable } from '@angular/core';
import { CartConfirmationResponse } from 'src/app/shared/models/cart-confirmation/cart-confirmation.model';

@Injectable()
export class CheckoutPurchaseConfirmedStoreService {

  private _cartConfirmationResponse: CartConfirmationResponse;

  constructor() { }

  public get cartConfirmationResponse() {
    return this._cartConfirmationResponse;
  }

  public set cartConfirmationResponse(response: CartConfirmationResponse) {
    this._cartConfirmationResponse = response;
  }
}
