import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fp-static-landing-content-skeleton',
  templateUrl: './static-landing-content-skeleton.component.html',
  styleUrls: ['./static-landing-content-skeleton.component.sass']
})
export class StaticLandingContentSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
