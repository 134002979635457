// REQUEST
export interface IDiscountCodeRequestParams {
  coupon: string;
  mixedOrder: boolean;
  companyCode:string;
  userId?:string;
	firstBuyCall?: boolean;
}

// RESPONSE
export interface IDiscountCodeResponse {
  message: string | null;
  valid: boolean;
}

export class DiscountCodeResponse {
  public message: string | null;
  public valid: boolean;

  constructor(response: IDiscountCodeResponse) {
    this.message = response.message || null;
    this.valid = response.valid || false;
  }
}

