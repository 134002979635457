import { ElementRef, Injectable } from "@angular/core";
import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle";
import { Observable } from "rxjs";
import { NearestDrugstore } from "src/app/shared/models/nearest-drugstores/nearest-drugstores.model";
import { PlacesSearch, ReverseAddress } from "../interfaces/places-search";
import { HereMapService } from "./here-map.service";
import { HereMapConfigService } from "./here-map-config.service";

export const POSITION_BY_DEFAULT = "-12.0885,-77.00215";

@Injectable({
  providedIn: "root",
})
export class HereMapPlacesService {
	public map: H.map.Map;
	constructor(private hereMapService: HereMapService,private hereMapConfigService:HereMapConfigService ) {}

	public showMap(hereMapRef?: ElementRef<HTMLDivElement>, location?: any) {
		this.hereMapService.startHereMap(hereMapRef, location);
	}

	clearMap() {
		this.hereMapService.clearMap();
	}

	setMarketImg(imgUrl: string) {
		this.hereMapService.setMarketImg(imgUrl);
	}

	removeMarker() {
		this.hereMapService.removeMarker();
	}

	getGeocodeService$(textSearch: string): Observable<any> {
		return this.hereMapService.getGeocodeService$(textSearch);
	}

	getAutosuggestService$(textSearch: string): Observable<any> {
		return this.hereMapService.getAutosuggestService$(textSearch);
	}

  getReverseGeocodeService$(
    textSearch: string
  ): Observable<PlacesSearch<ReverseAddress>> {
		return this.hereMapService.getReverseGeocodeService$(textSearch);
	}

	addMarker(position) {
		return this.hereMapService.updatePosition(position);
	}

	createMarketHere(locationOfMarker, store) {
		this.hereMapService.startCreatedMarket(locationOfMarker, store);
	}

	selectDefaultMarket(_selectedDrugstore: NearestDrugstore) {
		this.hereMapService.defaultMarket(_selectedDrugstore);
	}

	getFormattedGeolocationAddress$(): Observable<PlacesSearch<ReverseAddress>> {
		return this.hereMapService.getFormattedGeolocationAddress$();
	}

	getDetailedAddress$(id: string): any {
		return this.hereMapService.getDetailedAddress$(id);
	}

	initializeApiKeyHere(): any {
		return this.hereMapService.initializeApiKeyHere();
	}
	initializeHereMapConfig(): any {
		return this.hereMapConfigService.initializeHereMapConfig().subscribe();
	}
}
