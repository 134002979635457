import { FormControl, Validators } from "@angular/forms";
import { getErrorbyFormControl } from "../helpers/error-controls-helpers";
import { EMAIL_LOGIN_ERROR_MESSAGES_TOMADOR } from "../parameters/error-message-forms";

export class EmailOrderTakerControl extends FormControl {
  constructor() {
    super("");
    this.settingNameValidators();
  }

  private settingNameValidators() {
    this.setValidators([
      Validators.email,
      Validators.pattern(/.+@.+\..+/),
    ]);
  }

  public get emailAddressError(): string {
    return getErrorbyFormControl(this, EMAIL_LOGIN_ERROR_MESSAGES_TOMADOR);
  }
}
