// here are the global config parameters
export const DEFAULT_DRUGSTORE_ID = 18;
export const DEFAULT_PICKUP_AT_DRUGSTORE_ID = 2;
export const DEFAULT_FROM_DELIVERY_ID = 1;


export const RAD_SHORTNAME = 'DEL';
export const RET_SHORTNAME = 'RET';

export const RET3_DEFAULT_DRUGSTORE = 1253;
export const RET3_RAD_STORAGE_KEY = 'RAD';
export const RET3_VALIDATION_OK = '1';
