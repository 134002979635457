import { Injectable } from "@angular/core";
import { CryptoService } from "@service/crypto/crypto.service";

@Injectable({
	providedIn: "root"
})
export class LocalStorageService {
	constructor(private cryptoService: CryptoService) {}

	getItem(key: string) {
		try {
			const value = localStorage.getItem(key) ?? null;
			if (value) {
				const decrypt = this.cryptoService.decrypt(value);
				return JSON.parse(decrypt);
			} else {
				return null;
			}
		} catch (error) {
			console.error(error);
			localStorage.removeItem(key);
			return null;
		}
	}

	setItem(key: string, value: any) {
		try {
			const valueEncrypt = this.cryptoService.encrypt(JSON.stringify(value));
			localStorage.setItem(key, valueEncrypt);
		} catch (error) {
			localStorage.removeItem(key);
			console.error(error);
		}
	}

	deleteItem(key: string) {
		if (localStorage.getItem(key)) {
			localStorage.removeItem(key);
		  }
	}
}
