export function getPascalCaseFormat(paragraph: string) {
  if (paragraph === '') {
    return '';
  }
  paragraph = paragraph.toLowerCase();
  const MAYUSCULAS = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZÁÉÍÓÚÀÈÌÒÙÄËÏÖÜ';
  const MAY_ARR = MAYUSCULAS.split('');
  const MINUSCULAS = 'abcdefghijklmnñopqrstuvwxyzáéíóúàèìòùäëïöü';
  const MIN_ARR = MINUSCULAS.split('');
  const wordArr = paragraph.split(' ');
  const newWordArr = wordArr.map(word => {
    if (word === '') {
      return '';
    }
    const firstLetter = word[0];
    const indexToReplace = MIN_ARR.findIndex(w => w === firstLetter);
    if (indexToReplace >= 0) {
      const replacedWord = word.replace(firstLetter, MAY_ARR[indexToReplace]);
      return replacedWord;
    }
    return word;
  });
  return newWordArr.join(' ');
}
