import { Component, Input } from '@angular/core';

@Component({
  selector: 'fp-button-ghost',
  templateUrl: './button-ghost.component.html'
})
export class ButtonGhostComponent {

  @Input()
  buttonSize: 'small' | 'regular' = 'regular';

  @Input()
  buttonColor: 'red';

  @Input()
  buttonDisplay: 'block' | 'flex' | 'inline-block' = 'inline-block';

  @Input()
  buttonType: 'button' | 'submit' | 'reset' = 'button';

  @Input()
  buttonId: string = "";

  @Input()
  buttonStyle: { [klass: string]: any; } | null;

  @Input()
  buttonDisabled = false;

  @Input()
  optionalClass?: string;
}
