import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Asset, Entry } from 'contentful';
import { ContentfulAsset, ContentfulEntry } from '../../../shared/models/contentful/contentful.model';
import { ContentfulShortcut, IContentfulShortcut } from './contentful-shortcut.model';
import { OrderTakerValidator } from './../../../shared/validators/order-taker.validator';

export enum EContentfulShortcutTemplate {
  template1 = 'Plantilla 1',
  template2 = 'Plantilla 2',
  template3 = 'Plantilla 3',
  template4 = 'Plantilla 4',
  template5 = 'Plantilla 5',
  template6 = 'Plantilla 6',
  template7 = 'Plantilla 7',
  template8 = 'Plantilla 8',
  template9 = 'Plantilla 9',
  template10 = 'Plantilla 10',
  template11 = 'Plantilla 11',
  template12 = 'Plantilla 12',
  templateDefault = ''
}

export interface IContentfulShortcutList {
  title: string;
  description?: string;
  template: EContentfulShortcutTemplate;
  shortcutList: Array<Entry<IContentfulShortcut>>;
  link: string;
  image: Asset;
  imagenResponsive: Asset;
  summary: string;
  newTitle?: string;
  numberPhone?: string;
  imageExtra?: Asset;
  imageOpcional2?: Asset;
  imageResponsive: Asset;
  headingLevel: string;
  columnas?: string;
  heightSize?: string;

}

export class ContentfulShortcutList {
  title: string;
  description?: string;
  template: EContentfulShortcutTemplate;
  shortcutList: Array<ContentfulShortcut>;
  link: string;
  image: string;
  imagenResponsive: string;
  summary: string;
  newTitle: string;
  numberPhone: string;
  imageExtra?: string;
  imageOpcional2?: string;
  imageResponsive: string;
  columnas?: string;
  heightSize?: string;


  constructor(iContentfulShortcutList: IContentfulShortcutList) {
    this.title = iContentfulShortcutList.title || '';
    this.description = iContentfulShortcutList.description || '';
    this.template = iContentfulShortcutList.template || EContentfulShortcutTemplate.templateDefault;
    this.shortcutList = iContentfulShortcutList.shortcutList ? iContentfulShortcutList.shortcutList
      .map(shortcut => new ContentfulShortcut(new ContentfulEntry(shortcut).content)) : [];
    if(OrderTakerValidator.isOrderTakerEnv()){
      this.shortcutList = this.shortcutList.filter(r=>r.link != "/monedero")
    }
    if (iContentfulShortcutList.link) { this.link = iContentfulShortcutList.link; }
    if (iContentfulShortcutList.image) { this.image = new ContentfulAsset(iContentfulShortcutList.image).asset; }
    if (iContentfulShortcutList.imagenResponsive) { this.imagenResponsive = new ContentfulAsset(iContentfulShortcutList.imagenResponsive).asset; }
    this.summary = iContentfulShortcutList.summary || '';
    if (iContentfulShortcutList.imageExtra) {
      this.imageExtra = new ContentfulAsset(iContentfulShortcutList.imageExtra).asset;
    }
    if (iContentfulShortcutList.imageResponsive) { this.imageResponsive = new ContentfulAsset(iContentfulShortcutList.imageResponsive).asset; }
    if (iContentfulShortcutList.imageOpcional2) { this.imageOpcional2 = new ContentfulAsset(iContentfulShortcutList.imageOpcional2).asset; }
    if (iContentfulShortcutList.imageResponsive) { this.imageResponsive = new ContentfulAsset(iContentfulShortcutList.imageResponsive).asset; }
    if (iContentfulShortcutList.columnas) { this.columnas = iContentfulShortcutList.columnas}
    this.heightSize = iContentfulShortcutList.heightSize
  }
}

export const CContentfulShortcutList = {
  id: CONTENTFUL_PATH.contentTypeIds.shortcutList,
};

export const CContentfulShortcutTemplateConfig = {
  [EContentfulShortcutTemplate.template2]: {
    sliderConfig: {
      'type': 5,
      'loop': false,
      'autoplay': false,
      'autoplayTime': 5000,
      'infinite': false,
      'autoHeight': true,
      'slidesConfigByBreakpoints': {
        'xxs': {
          slidesPerView: 1.2,
          spaceBetween: 16
        },
        'sm': {
          slidesPerView: 2,
          spaceBetween: 16
        },
        'md': {
          slidesPerView: 3,
          spaceBetween: 16
        },
        'lg': {
          slidesPerView: 3,
          spaceBetween: 14
        },
        'xl': {
          slidesPerView: 4,
          spaceBetween: 14
        },
        'xxl': {
          slidesPerView: 4,
          spaceBetween: 14
        },
      }
    }
  },
  [EContentfulShortcutTemplate.template3]: {
    sliderConfig: {
      'type': 5,
      'loop': false,
      'autoplay': false,
      'autoplayTime': 5000,
      'infinite': false,
      'autoHeight': true,
      'pagination': { el: '.swiper-pagination-sponsor', clickable: true },
      'slidesConfigByBreakpoints': {
        'xxs': {
          slidesPerView: 2,
          spaceBetween: 16
        },
        'sm': {
          slidesPerView: 2,
          spaceBetween: 16
        },
        'md': {
          slidesPerView: 3,
          spaceBetween: 16
        },
        'lg': {
          slidesPerView: 4,
          spaceBetween: 14
        },
        'xl': {
          slidesPerView: 5,
          spaceBetween: 14
        },
        'xxl': {
          slidesPerView: 5,
          spaceBetween: 14
        },
      }
    }
  },
  [EContentfulShortcutTemplate.template12]: {
    sliderConfig: {
      'type': 5,
      'loop': false,
      "navigation": {
        "nextEl": ".brand-list-button-next",
        "prevEl": ".brand-list-button-prev"
      },
      'autoplay': false,
      'autoplayTime': 5000,
      'infinite': false,
      'autoHeight': true,
      'pagination': { el: '.swiper-pagination-sponsor', clickable: true },
      'slidesConfigByBreakpoints': {
        'xxs': {
          slidesPerView: 2,
          spaceBetween: 16
        },
        'sm': {
          slidesPerView: 3,
          spaceBetween: 16
        },
        'md': {
          slidesPerView: 3,
          spaceBetween: 16
        },
        'lg': {
          slidesPerView: 7,
          spaceBetween: 14
        },
        'xl': {
          slidesPerView: 8,
          spaceBetween: 14
        },
        'xxl': {
          slidesPerView: 8,
          spaceBetween: 14
        },
      }
    }
  }
};
