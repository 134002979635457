<mat-dialog-content class="mat-dialog-content">
	<div class="text-center">
		<div class="mb-3 postion-icon">
			<fp-icon class="mb-4" iconName="sad-sweat-face-colored" [iconSize]="48"></fp-icon>
			<div class="label-black-1">No aplicamos el cupón “{{ coupon }}”</div>
		</div>
		<div class="mt-2 mb-2 color-text-error">
			<p>
				Revisa en el resumen del pedido si accediste a un mejor descuento. De ser así, reservaremos tu cupón para tu próxima
				compra.
			</p>
			<p>Si no es el caso, verifica su fecha de caducidad y validez para los productos de tu pedido.</p>
		</div>
		<div class="warning-buttons w-100 mt-6">
			<a class="btn btn-primary w-100 style-button" (click)="closeModal()">Entendido</a>
		</div>
	</div>
	<div mat-button class="close icon icon-14-close icon-close" (click)="closeModal()"></div>
<!-- </mat-dialog-content> -->
