import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { DirectivesModule } from "src/app/shared/directives/directives.module";
import { ComponentsModule } from "../components/components.module";
import { ButtonsModule } from "../generic/buttons/buttons.module";
import { ChipModule } from "../generic/chip/chip.module";
import { IconsModule } from "../generic/icons/icons.module";
import { InputsModule } from "../generic/inputs/inputs.module";
import { ContainersModule } from "../modules/containers/containers.module";
import { FormControlsModule } from "../modules/form-controls/form-controls.module";
import { BasicSkeletonModule } from "../modules/skeletons/basic-skeleton/basic-skeleton.module";
import { CardDrugStoreComponent } from "./card-drug-store/card-drug-store.component";
import { CardRadioOfficeComponent } from "./card-radio-office/card-radio-office.component";
import { CardServiceComponent } from "./card-service/card-service.component";

const DECLARATIONS = [
  CardRadioOfficeComponent,
  CardDrugStoreComponent,
  CardServiceComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
  imports: [
    CommonModule,
    ButtonsModule,
    FormControlsModule,
    MatDialogModule,
    MatDividerModule,
    IconsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatStepperModule,
    ContainersModule,
    InputsModule,
    ChipModule,
    ComponentsModule,
    BasicSkeletonModule,
    DirectivesModule,
  ],
})
export class MoleculesModule {}
