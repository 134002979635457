import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-product-price-mifa',
  templateUrl: './product-price-mifa.component.html',
  styleUrls: ['./product-price-mifa.component.sass']
})
export class ProductPriceMifaComponent implements OnInit {

  @Input() public price: number;
  @Input() public fontSize = '--1';
  @Input() public icon = false;
  @Input() place:string = '';
  constructor() {
  }

  ngOnInit() {
  }

}
