import {Component, Input, forwardRef, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'fp-input-searcher',
  templateUrl: './input-searcher.component.html',
  styleUrls: ['./input-searcher.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSearcherComponent),
      multi: true
    }
  ]
})
export class InputSearcherComponent {

  @ViewChild('retAddressSearcherInput') retAddressSearcherInputRef: ElementRef<HTMLInputElement>;

  @Input() inputId = '';
  @Input() customClass = '';


  @Output() focusEvent = new EventEmitter();
  @Output() blurEvent = new EventEmitter();
  @Output() enterEvent = new EventEmitter();
  @Output() keyPressEvent = new EventEmitter();

  public value = '';
  public isDisabled = false;

  onChange = (_: any) => { };
  onTouch = (_: any) => { };

  constructor(
      private cdRef: ChangeDetectorRef
  ) { }

  // start: those are necessary methods
  writeValue(value: string): void {
    this.value = value;
    this.cdRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  // end: those are necessary methods

  public changeValue(event: any) {
    this.value = (event.target as HTMLInputElement).value;
    this.onChange(this.value);
    this.onTouch(this.value);
  }

  public enter($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.enterEvent.emit();
    }
  }

  public clickLupa() {
    if (this.retAddressSearcherInputRef) {
      setTimeout(() => {
        this.enterEvent.emit();
        this.retAddressSearcherInputRef.nativeElement.focus();
      }, 220);
    }
  }

  public onKeypressEvent(event: any) {
    this.keyPressEvent.emit();
  }
}
