<div class="select-control">
	<div class="select-control-title">{{ label }}</div>
	<div class="select-control-content">
		<select
			class="custom-options-container font-VAGRoundedNexxt-normal-w-400"
			[ngClass]="{'error-border': errorMessage}"
			(click)="clickEvent.emit()"
			[disabled]="isDisabled"
			(change)="selectOption($event)"
		>
			<option
				[value]="option"
				*ngFor="let option of options"
				class="custom-option font-VAGRoundedNexxt-normal-w-400 select-options-l"
				[selected]="selectedValue === option"
				[disabled]="option==labels.select"
			>
				{{ option }}
			</option>
		</select>
		<div class="icon-container">
			<div class="icon"></div>
		</div>
	</div>
	<div class="label--2 errorMessage" *ngIf="errorMessage">{{errorMessage}}</div>
</div>
