import { environment } from "./../../../../environments/environment";
import { Injectable } from "@angular/core";
import { ShoppingListStoreService } from "@stores/shopping-list/shopping-list-store.service";
import { ProductClientService } from "../product/product-client.service";
import { AuthImplementService } from "@implements/auth/auth-implement.service";
import { BehaviorSubject, Subject, of } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { tap } from "rxjs/operators";
import { CartValidate } from "./types";
import { EBrand } from "@parameters/access/brand.parameter";
import { RET3_RAD_STORAGE_KEY, RET3_VALIDATION_OK } from "src/app/business/checkout/parameters/global-config";
import { AddressItem } from "@models/address/address.model";
import { AppStoreService } from "@stores/app/app-store.service";
import { ProductDetail } from "src/app/business/product-detail/models/product-detail-model";
import { ProductOutOfStockDialogComponent } from "../../components/product-out-of-stock-dialog/product-out-of-stock-dialog.component";
import { LS_ADDRESS_SELECTED, LS_RAD_OR_RET } from "../local-storage/constants/address.constant";
import { ProductOutOfCoverageDialogComponent } from "../../components/product-out-of-coverage/product-out-of-coverage-dialog.component";
import { TaggingService } from 'src/app/atm-services/tagging.service';
import { StorageClientService } from "../../storage/storage-client.service";
import { LS_ZONE_BACKUP } from "@service/local-storage/order-taker/order-taker.contanst";
import { StoreBackup } from "@models/store-backup.model";
import { AddressListManagerService } from "src/app/core/modules/address-manager/address-services/address-list-manager/address-list-manager.service";
import { ProductShoppingCartRequest } from "@models/shopping-cart/product-shopping-cart-request.model";

@Injectable({
	providedIn: "root"
})
export class RetValidateCartService {
	processValidateAddToCart: BehaviorSubject<boolean|string> = new BehaviorSubject(false);
	processValidateFromProduct = new BehaviorSubject(false);
	processIsTypeRAD = new BehaviorSubject(false);
	productNeedReloadStatus = new Subject();
	productReload = new Subject();
  needCoverageValidation = new BehaviorSubject(false);
	currentDetailProduct: BehaviorSubject<ProductDetail> = new BehaviorSubject(null);

	private productIdSelected = new BehaviorSubject(null);
	$productIdSelected = this.productIdSelected.asObservable();

	private isFromProductAdd = new BehaviorSubject(null);
	$isFromProductAdd = this.isFromProductAdd.asObservable();
  dialogIsActive = false;
  validateDialog: any;

	get waith4CartResponse() {
		return this.processValidateFromProduct.getValue() === true;
	}

  get needValidateCoverage() {
    return this.needCoverageValidation.getValue() === true;
  }

	get currentProduct() {
		return this.currentDetailProduct.getValue();
	}

	get isRADProcess() {
		return localStorage.getItem(LS_RAD_OR_RET) === "RAD";
	}

  get isRetOrRadTomadorProcess() {
    return localStorage.getItem("isRetOrRad") === 'RAD'
  }

  get addIntentIsFromProduct() {
    return this.isFromProductAdd.getValue();
  }

	set intentIsFromProduct(productId) {
		this.isFromProductAdd.next(productId);
	}

	get currentProductId() {
		return this.productIdSelected.getValue();
	}

	set currentProductId(id) {
		this.productIdSelected.next(id);
	}

	get brand() {
		return EBrand[environment.brand] === "inkafarma" ? "IKF" : "MF";
	}

	get activeDrugstore() {
		return JSON.parse(this.appStoreService.getStorageItem("drugstoreSelected"));
	}

	constructor(
		private _shoppingListStore: ShoppingListStoreService,
		private appStoreService: AppStoreService,
		private productClientService: ProductClientService,
		private dialog: MatDialog,
		public auth: AuthImplementService,
		public storageClientService: StorageClientService,
    private newAddressManager: AddressListManagerService,
	) {}

	fillOnStartApp() {
    if (!localStorage.getItem(LS_RAD_OR_RET)) {
      this.storageClientService.setStorageItem(LS_RAD_OR_RET, RET3_RAD_STORAGE_KEY);
    }
		const status = this.storageClientService.getStorageItem(LS_RAD_OR_RET) || "";
		this.processIsTypeRAD.next(status === RET3_RAD_STORAGE_KEY);
	}

	storeProcessType(type: string) {
		this.processIsTypeRAD.next(type === RET3_RAD_STORAGE_KEY);
		localStorage.setItem(LS_RAD_OR_RET, type);
		localStorage.setItem(LS_ADDRESS_SELECTED, `${type === RET3_RAD_STORAGE_KEY}`);
	}

	storeBackup(): StoreBackup{
		return (JSON.parse(this.storageClientService.getStorageItem(LS_ZONE_BACKUP)) as StoreBackup) ?? null
	}

	updateAndValidateCart(currentAddreses: AddressItem, currentDrugstore?: any, isDelete?: boolean, ischangeRad?: boolean, emitGtag?: boolean, product?) {
    // const intensionRet = this.appStoreService.getStorageItem("intensionRet");
    const processRetOrRad = this.isRADProcess ? 'PROG' : 'RET';
    const servicesType = processRetOrRad;
		const drugstoreStock = localStorage.getItem("drugstore-stock");

		const shoppingCartList = this.getShoppingCartListTomador(product)

		const storeBackup = this.storeBackup()
		const currentLocalCode = storeBackup ? storeBackup.fulfillmentCenterCode : currentDrugstore?.localCode;

		if ((currentAddreses || currentDrugstore)) {
			return this.productClientService
				.validateAddToCart({
					companyCode: this.appStoreService.companyCode,
					serviceType: servicesType,
					drugstoreLocalCode: currentLocalCode,
					drugstoreWareHouseId: this.isRADProcess ? drugstoreStock : currentDrugstore?.drugstoreWareHouseId.toString(),
					drugstoreName: currentDrugstore?.name,
					drugstoreAddress: this.isRADProcess ? currentAddreses?.street : currentDrugstore?.address,
					userId: this.auth.currentUserId,
					shoppingCart: shoppingCartList
				})
				.pipe(
					tap((validationResponse: CartValidate) => {
						this._shoppingListStore.statusCodeValidateCart$ = validationResponse.statusCode;
						let shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList();
						if (
							(validationResponse.statusCode === "13" && !isDelete) ||
							(validationResponse.statusCode === "14" && !isDelete)
						) {
							shoppingCartListLocal = this.removeSellerProducts(shoppingCartListLocal);
							this.appStoreService.removeStorageItem("shoppingCartList");
							this.appStoreService.setStorageItem("shoppingCartList", JSON.stringify(shoppingCartListLocal));
						}
						if (
							((this.isRADProcess && !validationResponse.radHasStock) ||
								(!this.isRADProcess && !validationResponse.retHasStock)) &&
							validationResponse.statusCode !== RET3_VALIDATION_OK &&
							!isDelete &&
							!ischangeRad
						) {
							const productWithoutStock = validationResponse.productsWithoutStock?.find(
								(product: string) => product === this.currentProductId
							);
							if (productWithoutStock) {
								this.productNeedReloadStatus.next(true);
							}

							if (emitGtag) {
								TaggingService.RetAddCartDeliveryValidate(this.isRADProcess);
							}
							this.showValidateDialog(validationResponse, currentDrugstore, currentAddreses,this.currentProduct);
						}
					})
				);
		} else {
			return of({});
		}
	}

	getShoppingCartListTomador(product){
		const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList() as ProductShoppingCartRequest[];
    const intencionProducts = [{"productId": product?.id || 0,"presentationIdSelected": 1,"quantity": 1,"sellerId": "","sellerName": ""}];
    const shoppingCartList = product && !shoppingCartListLocal.find(i => i.productId === product.id)  ? shoppingCartListLocal.concat(intencionProducts) : shoppingCartListLocal;

		return shoppingCartList.filter((p) => !p.productAliviamed?.isMedicalConsultation)
	}

	showValidateDialog(response, drugstore, address,product) {
		this.dialog.open(ProductOutOfStockDialogComponent, {
			width: "450px",
			data: {
				response,
				drugstore,
				address,
				product
			},
			panelClass: "modal-in-footer",
			disableClose: true
		});
	}

	showOutCoverageDialog(drugstore, address, float?: boolean, product?) {
		this.dialog.open(ProductOutOfCoverageDialogComponent, {
			width: "450px",
			data: {
				drugstore,
				address,
        float,
        product
			},
			panelClass: "modal-in-footer",
			disableClose: true
		});
	}
  openModalAddressList(){
    this.newAddressManager.showAddressListModal();

  }
	removeSellerProducts(arr) {
		return arr.filter((product) => product.sellerId === "" && product.sellerName === "");
	}
}
