import { Injectable } from '@angular/core';
import { GenericService } from '../generic';
import { ENDPOINTS } from '../../parameters/endpoints';
import { CampaignCustomerParams, ICampaignCustomerRequest } from '../../models/campaign/campaign-customer-request.model';
import { Observable } from 'rxjs';
import { ICampaignDepartment } from '../../models/campaign/campaign-department.model';
import { ICampaignIdentityDocumentType } from '../../models/campaign/campaign-identity-document-type.model';
import { ICampaignCustomerResponse } from '../../models/campaign/campaign-customer-response.model';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { CampaignCustomerValuesRequest } from '../../../business/world-landing/models/world-affiliation-mom-and-baby.interface';
import { AmountToPointResponse } from 'src/app/business/savings-wallet/models/amount-to-point-response';
import { EBrand } from '@parameters/access/brand.parameter';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CampaignClientService {

  constructor(
    private genericService: GenericService
  ) {
  }

  public getIdentityDocumentType(): Observable<Array<ICampaignIdentityDocumentType>> {
    return this.genericService.genericGet(ENDPOINTS.GET_IDENTITY_DOCUMENT_TYPE)
      .pipe(
        map((iCampaignIdentityDocumentTypeArray: Array<ICampaignIdentityDocumentType>) =>
          iCampaignIdentityDocumentTypeArray && iCampaignIdentityDocumentTypeArray.length ? iCampaignIdentityDocumentTypeArray : []));
  }

  public getCampaignDepartment(): Observable<Array<ICampaignDepartment>> {
    return this.genericService.genericGet(ENDPOINTS.GET_CAMPAIGN_DEPARTMENT)
      .pipe(
        map((iCampaignDepartmentArray: Array<ICampaignDepartment>) =>
          iCampaignDepartmentArray && iCampaignDepartmentArray.length ? iCampaignDepartmentArray : []));
  }

  public getCampaignCustomer(campaignCustomerParams: CampaignCustomerParams): Observable<ICampaignCustomerResponse> {
    const newHttpParams = new HttpParams()
      .set('campaignId', String(campaignCustomerParams.campaignId))
      .set('identityDocumentNumber', String(campaignCustomerParams.identityDocumentNumber))
      .set('identityDocumentType', String(campaignCustomerParams.identityDocumentType));
    return this.genericService.genericGet(ENDPOINTS.GET_CAMPAIGN_CUSTOMER, newHttpParams);
  }

  public postCampaignCustomer(request: ICampaignCustomerRequest): Observable<{ customerId: number, hoursUntilAccessToPromotions: number }> {
    return this.genericService.genericPost(ENDPOINTS.POST_CAMPAIGN_CUSTOMER, request);
  }

  public postCampaignCustomerValues(request: CampaignCustomerValuesRequest): Observable<{ id: number }> {
    return this.genericService.genericPost(ENDPOINTS.POST_CAMPAIGN_CUSTOMER_VALUES, request);
  }
// Methods for saving wallet


  public postCampaignRegister(request: any): Observable<any> {
    const url =  `${environment.api_gateway_04}/addcliente`;
    
    return this.genericService.genericPost(url, request);
  }

  public searchCampaignUser(request): Observable<any> {

    const url =  EBrand[environment.brand] == EBrand.inkafarma
    ? `${environment.api_gateway_04}/buscaAfiliadoInkaFarma`
    : `${environment.api_gateway_04}/buscaAfiliado`;
    return this.genericService.genericPost(url, request);
  }

  public searchPurseUser(dni): Observable<any> {
    return this.genericService.genericGetWithoutSecurity(ENDPOINTS.SEARCH_PURSE+'/'+dni);
  }

  public benefitsUser(request): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers
      .set('source', 'web')
      .set('brand', 'MIFA')
      .set('x-access-token', 'token');
    return this.genericService.genericPostWithoutSecurityHeaders(ENDPOINTS.POST_BENEFITS_USER, request, null, headers);
  }

  public convertAmountToPoint(request): Observable<AmountToPointResponse> {
    let headers = new HttpHeaders();
    headers = headers
      .set('x-access-token', 'token');
    return this.genericService.genericPostWithoutSecurityHeaders(ENDPOINTS.POST_AMOUNT_TO_POINT, request, null, headers);
  }
}
