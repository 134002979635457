import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { environment } from "@environments/environment";
import { EBrand } from "@parameters/access/brand.parameter";
import { CheckoutProcessingCouponModalComponent } from "../checkout-components/custom-components/checkout-processing-coupon-modal/checkout-processing-coupon-modal.component";
import { OrderTakerValidator } from './../../../shared/validators/order-taker.validator';

@Injectable()
export class ProcessingCouponModalManagerService {
  private matDialogInstance: MatDialogRef<CheckoutProcessingCouponModalComponent>;

  constructor(private matDialog: MatDialog) {}

  public openModal() {
    if (environment.brand === EBrand.mifarma || OrderTakerValidator.isOrderTakerEnv()) {
      this.matDialogInstance = this.matDialog.open(
        CheckoutProcessingCouponModalComponent,
        {
          width: "360px",
          disableClose: true,
        }
      );
    }
  }

  public closeModal() {
    if (environment.brand === EBrand.mifarma || OrderTakerValidator.isOrderTakerEnv()) {
      this.matDialogInstance.close();
    }
  }
}
