import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SkeletonForPickUpModalComponent } from './skeleton-for-pick-up-modal/skeleton-for-pick-up-modal.component';
import { BasicSkeletonModule } from '../../skeletons/basic-skeleton/basic-skeleton.module';
import { ContainersModule } from '../../containers/containers.module';
import { FormControlsModule } from '../../form-controls/form-controls.module';
import { StoreForRetRadioButtonComponent } from './store-for-ret-radio-button/store-for-ret-radio-button.component';
import { MapForRetComponent } from './map-for-ret/map-for-ret.component';
import { InputSearchForRetComponent } from './input-search-for-ret/input-search-for-ret.component';
import { PickUpFormsModule } from '../pick-up-forms/pick-up-forms.module';

import { IconsModule } from 'src/app/core/generic/icons/icons.module';
import { PickUpServicesForAgmModule } from '../pick-up-services-for-agm/pick-up-services-for-agm.module';
import { DrugstoreListComponent } from './drugstore-list/drugstore-list.component';
import { PickUpServicesModule } from '../pick-up-services/pick-up-services.module';
import { ButtonsModule } from 'src/app/core/generic/buttons/buttons.module';
import { SkeletonForPickUpModalV2Component } from './skeleton-for-pick-up-modal-v2/skeleton-for-pick-up-modal-v2.component';
import { StoreForRetRadioButtonV2Component } from './store-for-ret-radio-button-v2/store-for-ret-radio-button-v2.component';
import { MapForRetV2Component } from './map-for-ret-v2/map-for-ret-v2.component';
import { DrugstoreListV2Component } from './drugstore-list-v2/drugstore-list-v2.component';

const COMPONENTS = [
  SkeletonForPickUpModalComponent,
  StoreForRetRadioButtonComponent,
  MapForRetComponent,
  InputSearchForRetComponent,
  DrugstoreListComponent,
  
  SkeletonForPickUpModalV2Component,
  StoreForRetRadioButtonV2Component,
  MapForRetV2Component,
  DrugstoreListV2Component,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    BasicSkeletonModule,
    ContainersModule,
    FormControlsModule,
    IconsModule,
    PickUpFormsModule,
    ReactiveFormsModule,
    PickUpServicesForAgmModule,
    PickUpServicesModule,
    ButtonsModule
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class PickUpComponentsModule { }
