import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fp-section-container-title-cupon',
  templateUrl: './section-container-title-cupon.component.html',
  styleUrls: ['./section-container-title-cupon.component.sass']
})
export class SectionContainerTitleCuponComponent implements OnInit {
  @Input() title = '';
  @Input() iconName = '';
  @Input() subTitle = '';
  constructor() { }

  ngOnInit() {
  }

}
