import { FormControl, Validators } from '@angular/forms';

export class OnlinePaymentTypeControl extends FormControl {

  constructor() {
    super('');
    this.settingOnlinePaymentTypeValidators();
  }

  private settingOnlinePaymentTypeValidators() {
    this.setValidators([
        Validators.required,
    ]);
  }

}
