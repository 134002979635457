import { Injectable } from '@angular/core';
import { AppStoreService } from '../app/app-store.service';
import { arraysEqual } from '../../helpers/objects-equal';
import { isParsableJSON } from '../../helpers/json-parse.helper';
import { ProductShoppingCartSalesforceRequest } from '../../models/shopping-cart-salesforce/shopping-cart-salesforce-request.model';
import { SalesforceCollectService } from '../../salesforce/salesforce-collect.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingListSalesforceStoreService {

  private shoppingListSalesforce: ProductShoppingCartSalesforceRequest[];
  private readonly LOCALSTORAGE_SHOPPING_LIST_SALESFORCE = 'shoppingListSalesforce';

  constructor(
    private _appStore: AppStoreService,
    private salesforceCollect: SalesforceCollectService
  ) {
    const shoppingList = this.getShoppingListSalesforce();
    this.shoppingListSalesforce = shoppingList;
    this.shoppingListSalesforceStore = JSON.stringify(shoppingList);
  }

  public get shoppingListSalesforceStore(): string {
    return this._appStore.getStorageItem(
      this.LOCALSTORAGE_SHOPPING_LIST_SALESFORCE
    );
  }

  public set shoppingListSalesforceStore(list: string) {
    this._appStore.setStorageItem(this.LOCALSTORAGE_SHOPPING_LIST_SALESFORCE, list);
  }

  public setShoppingListSalesforce(shoppingCartRequest: ProductShoppingCartSalesforceRequest[]) {    
    const sameShoppingCart = arraysEqual(shoppingCartRequest, this.getShoppingListSalesforce());
    if (!sameShoppingCart) {
      this.updateShoppingListSalesforce(shoppingCartRequest);
    }
  }

  public get staticShoppingListSalesforce() {
    return this.getShoppingListSalesforce();
  }

  public updateShoppingListSalesforce(shoppingCartRequest: ProductShoppingCartSalesforceRequest[]) {
    this.shoppingListSalesforceStore = JSON.stringify(shoppingCartRequest);
    this.shoppingListSalesforce = shoppingCartRequest;
    if (!shoppingCartRequest.length) {
      this.salesforceCollect.trackCart(null);
    }
  }

  private getShoppingListSalesforce() {
    const shoppingListString = this.shoppingListSalesforceStore;
    return isParsableJSON(shoppingListString)
      ? JSON.parse(shoppingListString) as ProductShoppingCartSalesforceRequest[] : [];
  }

}
