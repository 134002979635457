import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DatesAndRegisterControl } from "src/app/shared/controls/dates-and-register-control";
import { DocumentNumberControl } from "src/app/shared/controls/document-number-control";
import { DocumentTypeControl } from "src/app/shared/controls/document-type-control-l";
import { FirstNameControl } from "src/app/shared/controls/first-name-control";
import { LastNameControl } from "src/app/shared/controls/last-name-control";
import { PhoneNumberControl } from "src/app/shared/controls/phone-number-control";
import { TermsAndPoliticsControl } from "src/app/shared/controls/terms-and-politics-control";

@Injectable()
export class RegisterNewUserFormService {
  public form: FormGroup;

  private _nameControl = new FirstNameControl();
  private _paternalLastNameControl = new LastNameControl();
  private _documentTypeControl = new DocumentTypeControl();
  private _documentNumberControl = new DocumentNumberControl();
  private _maternalLastNameControl = new LastNameControl();
  private _phoneNumberControl = new PhoneNumberControl();
  private _termsAndPoliticsControl = new TermsAndPoliticsControl();
  private _datesAndRegisterControl = new DatesAndRegisterControl();

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      name: this._nameControl,
      paternalLastName: this._paternalLastNameControl,
      documentType: this._documentTypeControl,
      documentNumber: this._documentNumberControl,
      maternalLastName: this._maternalLastNameControl,
      phoneNumber: this._phoneNumberControl,
      termsAndPolitics: this._termsAndPoliticsControl,
      datesAndRegister: this._datesAndRegisterControl,
    });
  }

  public resetForm() {
    this.nameControl.setValue("");
    this.paternalLastNameControl.setValue("");
    this.documentTypeControl.setValue("");
    this.documentNumberControl.setValue("");
    this.maternalLastNameControl.setValue("");
    this.phoneNumberControl.setValue("");
    this.termsAndPoliticsControl.setValue(false);
    this.datesAndRegisterControl.setValue(false);
  }

  public get nameControl() {
    return this.form.get("name") as FirstNameControl;
  }

  public get paternalLastNameControl() {
    return this.form.get("paternalLastName") as LastNameControl;
  }

  public get documentTypeControl() {
    return this.form.get("documentType") as DocumentTypeControl;
  }

  public get documentNumberControl() {
    return this.form.get("documentNumber") as DocumentNumberControl;
  }

  public get maternalLastNameControl() {
    return this.form.get("maternalLastName") as LastNameControl;
  }

  // public get emailAddressControl() {
  //   return this.form.get('emailAddress') as EmailControl;
  // }

  public get phoneNumberControl() {
    return this.form.get("phoneNumber") as PhoneNumberControl;
  }

  /*public get birthDateControl() {
    return this.form.get('birthDate') as BirthDateControl;
  }

  public get genderControl() {
    return this.form.get('gender') as SexControl;
  }*/

  public get termsAndPoliticsControl() {
    return this.form.get("termsAndPolitics") as TermsAndPoliticsControl;
  }

  public get datesAndRegisterControl() {
    return this.form.get("datesAndRegister") as DatesAndRegisterControl;
  }
}
