<section class="error-stock-container">
  <div class="error-stock__icon">
    <fp-icon iconName="face_sad" iconSize="48"></fp-icon>
  </div>
  <p class="error-stock__title">Producto agotado</p>
  <span class="error-stock__subTitle mb-4"
    >Para continuar con tu compra, eliminaremos el producto "{{
      productWithoutStock?.name
    }}" ya que se encuentra agotado.</span
  >
  <br />
  <fp-button-primary (click)="removeProduct()" class="mb-4"
    >Eliminar producto</fp-button-primary
  >
  <fp-button-ghost (click)="closeDialog()">Cancelar</fp-button-ghost>
</section>
