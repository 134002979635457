<div class="dialog-close">
	<span class="icon icon-24-close icon-close cursor-pointer" (click)="close()"> </span>
</div>

<div class="out-of-stock">
	<div class="out-of-stock__icon">
		<img *ngIf="isMifaBrand" src="../../../../assets/icons/cart-mifa.svg" alt="Carrito Fuera de stock" />
		<img *ngIf="!isMifaBrand" src="../../../../assets/icons/cart-inka.svg" alt="Carrito Fuera de stock" />
	</div>
	<h2>Dirección actual sin cobertura para Despacho a domicilio</h2>
	<ng-container>
		<p>Debes cambiar de dirección de despacho o elegir Retiro en tienda. <span *ngIf="!isMifaBrand">Los productos no despachados por Inkafarma no cuentan con Retiro en tienda.</span></p>
		<div class="out-of-stock__actions d-flex flex-column">
			<fp-button-primary class="mb-2" buttonDisplay="inline-block" (click)="close()" *ngIf="isFloat">
				Cambiar de dirección
			</fp-button-primary>
			<fp-button-primary class="mb-2" buttonDisplay="inline-block" (click)="changeAddress()" *ngIf="!isFloat">
				Cambiar de dirección
			</fp-button-primary>
			<fp-button-ghost buttonDisplay="inline-block" (click)="selectDrugstore()"> Elegir retiro en tienda </fp-button-ghost>
		</div>
	</ng-container>
</div>
