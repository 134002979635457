<div class="card product" [attr.data-product-brand]="product?.brand" [attr.data-product-category]="product?.category">
	<div *ngIf="product.alertTagText?.length > 0" class="tag-product red px-2 py-1">
		<div class="tag-product-imagen mr-1">
			<fp-icon iconSize="16" iconName="alert-white" class="vertical-align-middle"></fp-icon>
		</div>
		<div class="tag-text text-tag-mini" [innerText]="product.alertTagText"></div>
	</div>
	<fp-link [linkRouter]="['/' + productPath, product.slug, product.id]">
		<div class="row align-items-center">
			<div class="col-6 col-xs-4 col-md-6">
				<fp-product-image
					(click)="sectionID ? addEventBrain() : ''"
					class="w-100"
					[productStatus]="productStatus"
					[image]="productPresentation?.image"
					[imageName]="product.name"
				>
				</fp-product-image>
			</div>

			<div class="col-6 col-xs-8 col-md-6">
				<div class="row">
					<div class="col-12">
						<fp-product-card-information
							[customClass]="'smallCategory'"
							(click)="sectionID ? addEventBrain() : ''"
							[name]="product.name"
							[description]="productPresentation?.description"
						>
						</fp-product-card-information>
					</div>

					<!-- <div class="col-12 mt-2 d-flex flex-wrap tags" *ngIf="isInkaBrand">
						<fp-tag-seller
							(click)="sectionID ? addEventBrain() : ''"
							iconName="marketplace-gray"
							text="{{ product.isFromSeller ? product.sellerName : 'Inkafarma' }}"
						>
						</fp-tag-seller>
						<fp-tag-seller
							*ngIf="product.prescription === productPrescription.PRESCRIPTION"
							(click)="sectionID ? addEventBrain() : ''"
							iconName="prescription-gray"
						>
						</fp-tag-seller>
					</div> -->
					<div class="tags-container col-12 mt-2 d-flex flex-wrap tags">
						<fp-tag-seller-brand
							*ngIf="product.isGeneric"
							(click)="sectionID ? addEventBrain() : ''"
							[iconSize]="'16'"
							[iconName]="'pill'"
							[text]="'Genérico'"
						></fp-tag-seller-brand>
						<fp-tag-seller-brand
							(click)="sectionID ? addEventBrain() : ''"
							*ngIf="showPrescription"
							[iconName]="iconPrescription"
							[text]="product.prescription"
							[shortTag]="shortTagPrescription"
						>
						</fp-tag-seller-brand>
						<fp-tag-seller-brand
							*ngIf="!isMifaBrand"
							(click)="sectionID ? addEventBrain() : ''"
							[iconSize]="'16'"
							[iconName]="iconBrand"
							[text]="textTagSeller"
							[shortTag]="shortTagBrand"
						></fp-tag-seller-brand>
					</div>
					<div class="col-12 mt-2">
						<fp-product-card-price
							*fpBrandAccess="[brand.inkafarma]"
							(click)="sectionID ? addEventBrain() : ''"
							[regularPrice]="productPresentation?.regularPrice"
							[price]="productPresentation?.price"
							[offerPrice]="productPresentation?.offerPrice"
							[productStatus]="productStatus"
							[fontSize]="!isBigScreen ? '--1' : '--1'"
							[fontSizeRegular]="!isBigScreen ? '--1' : '--1'"
							[fontSizeOffer]="!isBigScreen ? '--1' : '--1'"
							[customStyle]="!isBigScreen ? { padding: '0' } : '--1'"
							[crossOutPL]="productPresentation?.crossOutPL"
						>
						</fp-product-card-price>
						<fp-product-card-price-mifa
							*fpBrandAccess="[brand.mifarma]"
							(click)="sectionID ? addEventBrain() : ''"
							[fontSizeText]="!isBigScreen ? '--3' : '--1'"
							[regularPrice]="productPresentation?.regularPrice"
							[price]="productPresentation?.price"
							[offerPrice]="productPresentation?.offerPrice"
							[productStatus]="productStatus"
							[fontSize]="!isBigScreen ? '--2' : '--1'"
							[place]="'categories'"
							[fontSizeRegular]="!isBigScreen ? '--2' : '--1'"
							[fontSizeOffer]="!isBigScreen ? '--2' : '--1'"
							[customStyle]="!isBigScreen ? { padding: '0' } : '--1'"
							[crossOutPL]="productPresentation?.crossOutPL"
						>
						</fp-product-card-price-mifa>
					</div>
					<br />
					<div class="col-12 mt-4">
						<div class="row">
							<ng-container *ngIf="productStatus !== eProductStatus.unavailable">
								<div class="col-2">
									<fp-icon iconSize="24" [iconName]="radHaveStock ? 'icon_rad_on' : 'icon_rad_off'"></fp-icon>
								</div>
								<div class="col-2">
									<fp-icon
										iconSize="24"
										[iconName]="!isProductSeller && retHaveStock ? 'icon_ret_on' : 'icon_ret_off'"
									></fp-icon>
								</div>
							</ng-container>

							<ng-container *ngIf="productStatus === eProductStatus.unavailable">
								<div class="col-2">
									<fp-icon iconSize="24" iconName="icon_rad_off"></fp-icon>
								</div>
								<div class="col-2">
									<fp-icon iconSize="24" iconName="icon_ret_off"></fp-icon>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
				<div class="row only-desktop" (click)="$event.stopPropagation(); $event.preventDefault()">
					<div class="col-12 mt-4 buttons-card">
						<fp-product-add-button
							[style.visibility]="productStatus === 1 || isMifaBrand ? 'visible' : 'hidden'"
							[productInShoppingCart]="!!productInShoppingCart"
							[quantityLimit]="productPresentation?.maxUnitSale"
							[quantity]="productQuantity"
							[productStatus]="productStatus"
							(newQuantity)="updateQuantity($event)"
							[buttonStyle]="{ padding: '15px' }"
							[product]="product"
							(addProduct)="addProductToShoppingCart()"
						>
						</fp-product-add-button>
						<fp-product-add-button
							[style.visibility]="productStatus !== 1 && product.isFromSeller ? 'visible' : 'hidden'"
							[productInShoppingCart]="!!productInShoppingCart"
							[quantityLimit]="productPresentation?.maxUnitSale"
							[quantity]="productQuantity"
							[buttonStyle]="{ padding: '15px' }"
							[productStatus]="productStatus"
							(newQuantity)="updateQuantity($event)"
							[product]="product"
							(addProduct)="addProductToShoppingCart()"
						>
						</fp-product-add-button>
					</div>
					<ng-container *fpBrandAccess="[brand.inkafarma]">
						<button
							class="consult-stock-botica"
							*ngIf="productStatus !== 1 && !product.isFromSeller"
							(click)="showModalBotica(product)"
						>
							Consultar stock en boticas
						</button>
					</ng-container>
				</div>
			</div>
		</div>
	</fp-link>

	<div class="tag-product-image" *ngIf="product.tagImageUrl">
		<fp-lazy-wrapper (click)="sectionID ? addEventBrain() : ''" [lazy]="product?.tagImageUrl">
			<img #fpImg alt="tag" />
		</fp-lazy-wrapper>
	</div>

	<div class="row only-mobile">
		<div class="col-12 mt-4 buttons-card">
			<fp-product-add-button
				[style.display]="productStatus === 1 || isMifaBrand ? 'block' : 'none'"
				[productInShoppingCart]="!!productInShoppingCart"
				[quantityLimit]="productPresentation?.maxUnitSale"
				[quantity]="productQuantity"
				[productStatus]="productStatus"
				(newQuantity)="updateQuantity($event)"
				[buttonStyle]="{ padding: '15px' }"
				[product]="product"
				(addProduct)="addProductToShoppingCart()"
			>
			</fp-product-add-button>
			<fp-product-add-button
				[style.display]="productStatus !== 1 && product.isFromSeller ? 'block' : 'none'"
				[productInShoppingCart]="!!productInShoppingCart"
				[quantityLimit]="productPresentation?.maxUnitSale"
				[quantity]="productQuantity"
				[buttonStyle]="{ padding: '15px' }"
				[productStatus]="productStatus"
				(newQuantity)="updateQuantity($event)"
				[product]="product"
				(addProduct)="addProductToShoppingCart()"
			>
			</fp-product-add-button>
		</div>
		<ng-container *fpBrandAccess="[brand.inkafarma]">
			<button class="consult-stock-botica" *ngIf="productStatus !== 1 && !product.isFromSeller" (click)="showModalBotica(product)">
				Consultar stock en boticas
			</button>
		</ng-container>
	</div>

	<fp-product-favorite
		class="product-favorite"
		[favorite]="product.favorite"
		(addFavorite)="addToFavorite()"
		(removeFavorite)="removeFromFavorite()"
	>
	</fp-product-favorite>
</div>
