import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewPasswordModalComponent } from '../modal-components/new-password-modal/new-password-modal.component';

@Injectable()
export class NewPasswordModalManagerService {

  constructor(
    private dialog: MatDialog,
  ) { }

  public showNewPassword(): void {
    this.dialog.open(NewPasswordModalComponent, {
      width: '340px',
      panelClass: 'custom-full-all-modal-overlay',
    });
  }
}
