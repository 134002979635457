import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { AES as CryptoJsAES, enc as CryptoJsEnc } from "crypto-js";

@Injectable({
	providedIn: "root"
})
export class CryptoService {
	/**
	 * encryption key
	 */
	cryptoKey: string;

	/**
	 * constructor
	 * @param CRYPTO_KEY encryption key
	 */
	constructor() {
		this.cryptoKey = environment.cryptoKey;
	}

	/**
	 * Method to perform encryption
	 * @param value text to encrypt
	 * @returns encryption value
	 */
	encrypt(value: string, cryptoKey = null): string {
		return CryptoJsAES.encrypt(value, cryptoKey ?? this.cryptoKey.trim()).toString();
	}

	/**
	 * Method to perform decrypt
	 * @param value text to encrypt
	 * @returns decrypt value
	 */
	decrypt(value: string, cryptoKey = null): string {
		return CryptoJsAES.decrypt(value, cryptoKey ?? this.cryptoKey.trim()).toString(CryptoJsEnc.Utf8);
	}
}
