<div
  class="choose-a-store-modal"
  *ngIf="!isLoadingModal"
  [ngClass]="{ 'modal--disabled': isDisableChooseStore }"
>
  <div class="button-modal-close" (click)="closeModal()">
    <span class="icon icon-24-close"></span>
  </div>

  <fp-section-container-title
    title="Selecciona una botica {{
      brand == 'Inkafarma' ? 'Inkafarma' : 'Mifarma'
    }}"
  >
  </fp-section-container-title>

  <fp-sub-section-container>
    <div class="content mt-4">
      <div class="row" [ngClass]="{'justify-content-center': modalStatus === ALL_MODAL_STATUS.EMPTY}">
        <div [ngClass]="{'col-12': modalStatus === ALL_MODAL_STATUS.EMPTY, 'col-5': modalStatus !== ALL_MODAL_STATUS.EMPTY}">
          <fp-input-search-for-ret
            (searchDone)="setModalStatus($event)"
            [isDisabledInputSearch]="isDisableChooseStore"
          >
          </fp-input-search-for-ret>
          <div class="row justify-content-center">
            <div [ngClass]="{'col-5': modalStatus === ALL_MODAL_STATUS.EMPTY, 'col': modalStatus !== ALL_MODAL_STATUS.EMPTY}">
              <fp-drugstore-list
                [status]="modalStatus"
                [withoutButton]="true"
                [isDisableChooseStore]="isDisableChooseStore"
              >
              </fp-drugstore-list>
            </div>
          </div>
        </div>
        <div class="col-7" *ngIf="modalStatus !== ALL_MODAL_STATUS.EMPTY">
          <div class="row">
            <div class="col-12 map-container">
              <fp-map-for-ret
                *ngIf="modalStatus !== ALL_MODAL_STATUS.LOADING"
                [hideMapForRET]="
                  !(
                    !isLoadingModal &&
                    modalStatus !== ALL_MODAL_STATUS.IS_PENDING &&
                    modalStatus !== ALL_MODAL_STATUS.NO_ONE
                  )
                "
                [isDisabledMapForRET]="isDisableChooseStore"
              >
              </fp-map-for-ret>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <fp-button-secondary
                buttonDisplay="block"
                (click)="closeModal()"
                [buttonDisabled]="isDisableChooseStore"
              >
                Cancelar
              </fp-button-secondary>
            </div>
            <div class="col-8">
              <fp-button-primary
                buttonDisplay="block"
                [buttonId]="'btn--choose-drugstore'"
                [buttonDisabled]="
                  isDisableChooseStore || drugstores.length === 0
                "
                (click)="saveChanges()"
              >
                Elegir botica
              </fp-button-primary>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fp-sub-section-container>
</div>

<div class="skeleton" *ngIf="isLoadingModal">
  <fp-skeleton-for-pick-up-modal> </fp-skeleton-for-pick-up-modal>
</div>
