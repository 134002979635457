export const VARIANTS_SKU_TYPE = {
    SIZE: 'T',
    FLAVOR: 'S',
    COLOR: 'C',
};

export const PRODUCT_PRESCRIPTION = {
    FREE_SALE: 'VENTA LIBRE',
    PRESCRIPTION: 'PRESENTA RECETA',
    RECETA_RETENIDA: 'RECETA RETENIDA',
    ADJUNTAR_RECETA:'ADJUNTAR RECETA'
  };



