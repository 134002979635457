export * from './generic.service';
export * from './generic-header.service';
export * from './generic-contentful.service';

import { GenericService } from './generic.service';
import { GenericHeaderService } from './generic-header.service';
import { GenericContentfulService } from './generic-contentful.service';

export const GENERIC_SERVICES = [
  GenericHeaderService,
  GenericService,
  GenericContentfulService,
];
