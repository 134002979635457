import { Component, Input, OnInit } from '@angular/core';
import { EProductStatus } from '../../models/product-status.enum';

@Component({
  selector: 'fp-product-name',
  templateUrl: './product-name.component.html',
  styleUrls: ['./product-name.component.sass']
})
export class ProductNameComponent implements OnInit {
  public eProductStatus = EProductStatus;

  @Input() name: string;
  @Input() productStatus: number = this.eProductStatus.available;
  @Input() public fontSize = '';

  constructor() {
  }

  ngOnInit() {
  }

}
