import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MainLoginModalComponent } from '../modal-components/main-login-modal/main-login-modal.component';

@Injectable()
export class MainLoginModalManagerService {

  constructor(
    private dialog: MatDialog,
  ) { }

  public showCartLogin(): void {
    this.dialog.open(MainLoginModalComponent, {
      width: '340px',
      disableClose: true,
      panelClass: 'custom-full-all-modal-overlay',
    });
  }

  public showCartLoginResponsive(): void {
    this.dialog.open(MainLoginModalComponent, {
      panelClass: 'main-login-modal-responsive',
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh'
    });
  }
}
