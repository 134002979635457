import { Injectable } from '@angular/core';
import { GenericContentfulService } from '../generic';
import { CONTENTFUL_PATH } from '../../parameters/endpoints';
import { CONTENTFUL_QUERIES } from '../../parameters/querys';

@Injectable()
export class InformativeService {
  constructor(
    private _genericContentfulService: GenericContentfulService,
  ) {
  }

  public getInformativeItemBySlug(slug: string) {
    return this._genericContentfulService.getEntries(
      CONTENTFUL_PATH.contentTypeIds.informativeItems,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

}
