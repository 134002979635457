<div class="d-flex flex-column">
    <div class="d-flex flex-row align-items-center">
      <ng-container [ngSwitch]="data.type">
        <fp-icon class="d-inline-flex" iconSize="26.67" iconName="check-coupon"></fp-icon>
        <span *ngSwitchCase="'info'">
          <fp-icon class="d-inline-flex" iconSize="26.67" iconName="sp-info"></fp-icon>
        </span>
        <span *ngSwitchCase="'error'" class="icon icon-24-danger"></span>
      </ng-container>
      <div class="d-flex flex-column ml-xs-2 ml-sm-5 ml-md-5 ml-lg-3">
        <span class="text text-snackbar" [innerHTML]="data?.message"></span>
      </div>
    </div> 
</div> 