import { MatDialogConfig } from "@angular/material/dialog";

export class GlobalHeader {
  public static SCROLL_START = 0;
  public static MIN_SCROLL = 100;
  public static CLASS_TO_HIDE_NAV = "scroll-header";
  public static CLASS_TO_HIDE_NAV_WORLD = "scroll-world";
}

export const DEFAULT_ID = -1;

export const CANCEL_MODAL_CONFIG: MatDialogConfig = {
  width: "400px",
  height: "auto",
  disableClose: true,
  panelClass: "custom-full-all-modal-overlay",
};
