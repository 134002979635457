import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.sass']
})
export class ProductDescriptionComponent implements OnInit {

  @Input() description = '';
  @Input() customClass = '';
  constructor() { }

  ngOnInit() {
  }

}
