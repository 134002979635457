import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ElementRef, EventEmitter, Injectable, Output } from "@angular/core";
import { environment } from "@environments/environment";
import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle";
import { Store } from "@ngrx/store";
import { EBrand } from "@parameters/access/brand.parameter";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { setNewAddressModal } from "src/app/core/store/actions/app.actions";
import { AppState } from "src/app/core/store/reducer/app.reducer";
import { NearestDrugstore } from "src/app/shared/models/nearest-drugstores/nearest-drugstores.model";
import { ENDPOINTS } from "src/app/shared/parameters/endpoints";
import {
	BRAND_ACTIVE_MARKER,
	BRAND_INACTIVE_MARKER,
	INKAFARMA_ACTIVE_MARKER,
	INKAFARMA_INACTIVE_MARKER
} from "../../custom-agm-core/parameters/markers-images.parameter";
import { StoresForPickUpStoreService } from "../../pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service";
import { PlacesSearch, ReverseAddress } from "../interfaces/places-search";
import { PlacesSearchAutosuggest } from "../interfaces/places-search-autosuggest";
import { GeolocationService, GEO_STATUS_TYPES } from "./../../../../shared/geolocation/geolocation.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";

const ZOOM_BY_DEFAULT = 17;
const DEFAULT_CENTER = { lat: -12.0884464, lng: -77.0020555 };

@Injectable({
	providedIn: "root"
})
export class HereMapService {
	isInkaBrand = environment.brand == EBrand.inkafarma;
	private platform: any;
	private map: any;
	private marker: any;
	private iconInactive = new H.map.Icon(this.isInkaBrand ? INKAFARMA_INACTIVE_MARKER : BRAND_INACTIVE_MARKER);
	private iconctive = new H.map.Icon(this.isInkaBrand ? INKAFARMA_ACTIVE_MARKER : BRAND_ACTIVE_MARKER);
	private API_KEY;
	private markerImg = "assets/images/map/icon-marker.png";
	private drugstoreMarkers: any[] = [];
	@Output() setDrugStoreHereMap = new EventEmitter();
	constructor(
		private http: HttpClient,
		private readonly store: Store<AppState>,
		private geolocationService: GeolocationService,
		private storesForPickUpStore: StoresForPickUpStoreService
	) {
		// if (OrderTakerValidator.isOrderTakerEnv()) {
		// 	this.API_KEY = environment.here.apikey_tomador;
		// } else {
		// 	this.API_KEY = this.isInkaBrand ? environment.here.apikey_ikf : environment.here.apikey_mf;
		// }
		// this.initializeHereMap();
	}

	initializeApiKeyHere() {
		this.getSecretById().subscribe((response) => {
			// El contenido recibido está codificado en formato Base64. Decodificamos para obtener los datos
			const decodedContent = JSON.parse(atob(response.content));
			// Extraemos el apiKey de los datos decodificados
			this.API_KEY = decodedContent.apiKey;
			// Inicializamos utilizando el apiKey obtenido
			this.initializeHereMap();
		});
	}

	private getInitialConfig(location) {
		return {
			pixelRatio: window.devicePixelRatio,
			zoom: ZOOM_BY_DEFAULT,
			center: location
		};
	}

	initializeHereMap() {
		this.platform = new H.service.Platform({
			apikey: this.API_KEY
		});
	}

	startHereMap(hereMapRef: ElementRef<HTMLDivElement>, location: any) {
		const defaultLayers = this.platform.createDefaultLayers({
			pois: "F"
		});
		// if (!this.map) {
		this.map = new H.Map(hereMapRef.nativeElement, defaultLayers.vector.normal.map, this.getInitialConfig(location));
		const ui = H.ui.UI.createDefault(this.map, defaultLayers, "es-ES");
		ui.getControl("mapsettings").setVisibility(false);

		const markerIcon = new H.map.Icon(this.markerImg);
		this.marker = new H.map.Marker(location, {
			volatility: true,
			icon: markerIcon
		});
		this.marker.draggable = true;
		this.map.addObject(this.marker);
		const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
		this.dragStartMarker(behavior);
		this.dragMarker();
		this.dragEndMarker(behavior);
	}

	startCreatedMarket(location, store) {
		const LocationOfMarker = { ...location };
		const icon = this.iconInactive;
		const marker = new H.map.Marker(LocationOfMarker, { icon: icon });
		marker.addEventListener("tap", (event) => {
			if (event.target instanceof H.map.Marker) {
				this.map.getViewPort().element.style.cursor = "pointer";
			}
			this.geolocationService.setIdDrugStore.emit(store.id);
		});
		this.map.addObject(marker);
		this.drugstoreMarkers.push({ marker, ...store });
	}

	public getMarkerConfigForRETHere(_map, position) {
		return {
			map: _map,
			position,
			draggable: false,
			icon: INKAFARMA_INACTIVE_MARKER
		} as H.map.readonly;
	}

	defaultMarket(_selectedDrugstore: NearestDrugstore) {
		const drugstoreMarkers = this.drugstoreMarkers.map((e) => {
			const icon = e.marker.getIcon();
			const iconInactive = this.iconInactive;
			const iconActive = this.iconctive;
			if (e.id === _selectedDrugstore.id) {
				e.marker.setIcon(iconActive);
				this.setMapCenter(_selectedDrugstore.latitude, _selectedDrugstore.longitude);
			} else {
				e.marker.setIcon(iconInactive);
			}
			return e;
		});
		this.drugstoreMarkers = drugstoreMarkers;
	}

	public setMapCenter(lat: number, lng: number) {
		const position = { lat, lng };
		this.map?.setCenter(position);
		this.map?.setZoom(14);
	}

	clearMap() {
		this.map = null;
	}

	setMarketImg(url: string) {
		this.markerImg = url;
	}

	removeMarker() {
		this.map.removeObjects(this.map.getObjects());
	}

	dragStartMarker(behavior: H.mapevents.Behavior) {
		this.map.addEventListener(
			"dragstart",
			(ev) => {
				const target = ev.target;
				const pointer = ev.currentPointer;
				if (target instanceof H.map.Marker) {
					const targetPosition = this.map.geoToScreen(target.getGeometry());
					target["offset"] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
					behavior.disable();
				}
			},
			false
		);
	}

	dragEndMarker(behavior: H.mapevents.Behavior) {
		this.map.addEventListener(
			"dragend",
			(ev) => {
				const target = ev.target;
				const pointer = ev.currentPointer;
				if (target instanceof H.map.Marker) {
					const geoLocation = this.map.screenToGeo(
						pointer.viewportX - target["offset"].x,
						pointer.viewportY - target["offset"].y
					);
					const geoLocationLikeString = geoLocation.lat.toString().concat(",").concat(geoLocation.lng);
					this.getReverseGeocodeService$(geoLocationLikeString).subscribe((place) => {
						this.store.dispatch(
							setNewAddressModal({
								newAddressModal: {
									direction: place?.address?.label,
									hereAddressMarked: place
								}
							})
						);
					});
					behavior.enable();
				}
			},
			false
		);
	}

	dragMarker() {
		this.map.addEventListener(
			"drag",
			(ev) => {
				const target = ev.target,
					pointer = ev.currentPointer;
				if (target instanceof H.map.Marker) {
					target.setGeometry(
						this.map.screenToGeo(pointer.viewportX - target["offset"].x, pointer.viewportY - target["offset"].y)
					);
				}
			},
			false
		);
	}

	getGeocodeService$(textSearch): Observable<any> {
		const params = new HttpParams().set("q", textSearch).set("in", "countryCode:PER").set("apiKey", this.API_KEY);
		return this.http.get(ENDPOINTS.HERE_MAPS_GEOCODE, { params: params });
	}

	getAutosuggestService$(textSearch): Observable<{ items: PlacesSearchAutosuggest[] }> {
		const params = new HttpParams()
			.set("q", textSearch)
			.set("at", "-9.189967,-75.015152") // Perú
			.set("in", "countryCode:PER")
			.set("limit", "3")
			.set("lang", "es")
			.set("apiKey", this.API_KEY);
		return this.http.get<{ items: PlacesSearchAutosuggest[] }>(ENDPOINTS.HERE_MAPS_AUTOSUGGEST, { params: params });
	}

	getReverseGeocodeService$(positionSearch): Observable<PlacesSearch<ReverseAddress>> {
		const params = new HttpParams().set("at", positionSearch).set("lang", "es-PE").set("types", "address").set("apiKey", this.API_KEY);

		return this.http
			.get<{ items: PlacesSearch<ReverseAddress>[] }>(ENDPOINTS.HERE_MAPS_REVERSE_GEOCODE, { params: params })
			.pipe(map((resp) => resp.items[0]));
	}

	updatePosition(position) {
		this.marker?.setGeometry(position);
		this.map?.setCenter(position);
	}

	getFormattedGeolocationAddress$(): Observable<PlacesSearch<ReverseAddress>> {
		return this.geolocationService.getGeolocationStatus().pipe(
			switchMap((status) => {
				if (status.state === GEO_STATUS_TYPES.ACCEPTED) {
					return this.getGeolocationPosition$().pipe(
						switchMap((position) => {
							const { latitude, longitude } = position.coords;
							const lat_lng = `${latitude},${longitude}`;
							return this.getReverseGeocodeService$(lat_lng);
						})
					);
				} else {
					return of(null);
				}
			})
		);
	}

	private getGeolocationPosition$() {
		const position$ = new Observable<any>((observer) => {
			this.geolocationService.existNavigatorGeolocation.getCurrentPosition((position) => {
				observer.next(position);
			});
		});
		return position$;
	}

	getDetailedAddress$(id: string): any {
		const params = new HttpParams().set("id", id).set("apiKey", this.API_KEY);

		return this.http.get<{ items: PlacesSearch<ReverseAddress>[] }>(ENDPOINTS.HERE_MAPS_LOOKUP, { params: params });
	}

	addMarker(position) {
		this.map.removeObjects(this.map.getObjects());
		const newMarker = new H.map.Marker(position);
		newMarker.draggable = true;
		this.setCenter(position);
		this.map.addObject(newMarker);
	}

	setCenter(position) {
		this.map.setCenter(position);
		this.map.setZoom(17);
	}

	// getGeocodeService(params, result, reject) {
	//   this.searchService.geocode(params, result, reject)
	// }

	// getAutosuggestService(params, result, reject) {
	//   this.searchService.autosuggest(params, result, reject)
	// }

	// getRevgeocodeService(params, result, reject) {
	//   this.searchService.revgeocode(params, result, reject)
	// }

	public getSecretById(): Observable<any> {
		const params = new HttpParams().set("secretId", environment.secretManager.secretId);
		const headers = new HttpHeaders().set("app-key", environment.secretManager.appKey);
		const url = `${ENDPOINTS.GET_SECRET_BY_ID}`;
		return this.http.get<any>(url, { params, headers });
	}
}
