<fp-button
  buttonClass="btn-outline
  btn-border-bold
  btn-spacer-regular
  label-black
  btn-{{buttonDisplay}}
  {{disabled ? 'btn-disabled': ''}}
  {{buttonClass}}"
  buttonId={{id}}
  [buttonType]="buttonType"
  [buttonStyle]="buttonStyle"
  [buttonDisabled]="disabled">
  <ng-content></ng-content>
</fp-button>
