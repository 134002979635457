import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { environment } from "@environments/environment";
import { AppModule } from "./app/app.module";

import { RecaptchaComponent } from "ng-recaptcha";

import { defineCustomElements } from "@farmaciasperuanas/vita/dist/esm/loader.js";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

defineCustomElements(window).catch((err) => console.log(err));

// ToDo: Temporal fix to ng-recaptcha issue https://github.com/DethAriel/ng-recaptcha/issues/123
RecaptchaComponent.prototype.ngOnDestroy = function () {
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
};
