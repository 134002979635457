import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IconsModule } from "../icons/icons.module";
import { ChipComponent } from "./chip.component";

@NgModule({
  declarations: [ChipComponent],
  imports: [CommonModule, IconsModule],
  exports: [ChipComponent],
  providers: [],
})
export class ChipModule {}
