import { ITagValue } from './tag-checkout.model';

export interface ICatSubcatBraTag {
  type: CSB_TYPE; // category, subcategory, brand
  text: string;
  value: ITagValue;
}

export enum CSB_TYPE {
  CATEGORY = 'category',
  SUBCATEGORY = 'subcategory',
  BRAND = 'brand',
}
