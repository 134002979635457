import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Asset } from 'contentful';
import { ContentfulAsset } from '../../../shared/models/contentful/contentful.model';
import { BACKGROUND_DEFECT } from 'src/app/business/checkout/parameters/constants';

export enum E {
  right = 'right',
  left = 'left',
}

export interface SalesforcePersonalization {
  campaña: string[];
}

export interface IContentfulBanner {
  tittle: string;
  title: string;
  template: string;
  button: string;
  imageResponsive: Asset;
  link: string;
  imgeDesktop: Asset;
  linkDesktop: string;
  imageDesktop: Asset;
  description: string;
  imageAltText: string;
  imageTitle: string;
  breadcrumb: any;
  breadcrumbMobile: any;
  background: string;
  subTitle: string;
  color: string;
  tag?: string;
  items?: any;
  videoLink?: string;
  style: string;
  salesforcePersonalization?: SalesforcePersonalization;
}

export class ContentfulBanner {
  tittle: string;
  title: string;
  deepLink: string;
  button: string;
  smallImage: string;
  largeImage: string;
  description: string;
  template: string;
  breadcrumb: any;
  breadcrumbMobile: any;
  background: string;
  color: string;
  subTitle: string;
  link: string;
  linkDesktop: string;
  videoLink?: string;
  tag?: string;
  style?: string;
  salesforcePersonalization?: SalesforcePersonalization;
  constructor(iContentfulBanner: IContentfulBanner) {

    this.tittle = iContentfulBanner?.tittle || 'banner';
    this.title = iContentfulBanner?.title || 'banner';
    this.template = iContentfulBanner?.template || 'Default';
    this.breadcrumb = iContentfulBanner?.breadcrumb;
    this.breadcrumbMobile = iContentfulBanner?.breadcrumbMobile;
    this.button = iContentfulBanner?.button || 'Agregar al carrito';
    this.description = iContentfulBanner?.description || '';
    this.color = iContentfulBanner?.color;
    this.subTitle = iContentfulBanner?.subTitle;
    this.link = iContentfulBanner?.link;
    this.linkDesktop = iContentfulBanner?.linkDesktop;
    this.background = iContentfulBanner?.background || BACKGROUND_DEFECT;
    this.videoLink = iContentfulBanner.videoLink;
    this.tag = iContentfulBanner.tag;
    this.style = iContentfulBanner?.style || '';
    this.salesforcePersonalization = iContentfulBanner?.salesforcePersonalization;
    if (iContentfulBanner && iContentfulBanner.link) { this.deepLink = iContentfulBanner.link; }
    if (iContentfulBanner && iContentfulBanner.imageResponsive) { this.smallImage = new ContentfulAsset(iContentfulBanner.imageResponsive).asset; }
    if (iContentfulBanner && iContentfulBanner.imageDesktop) { this.largeImage = new ContentfulAsset(iContentfulBanner.imageDesktop).asset; }
    if (iContentfulBanner && iContentfulBanner.imgeDesktop) { this.largeImage = new ContentfulAsset(iContentfulBanner.imgeDesktop).asset; }
  }
}

export const CContentfulBanner = {
  id: CONTENTFUL_PATH.contentTypeIds.banner,
  sliderConfig: {
    'type': 5,
    'loop': false,
    'autoplay': false,
    'autoplayTime': 5000,
    'itemsPerView': 4,
  }
};


export const EContentfulBanner = {
  default: 'Default',
  centrado: 'Imagen Centrada',
  hero: 'Hero',
  video: 'Video',
  card_default: 'card_default',
  card_overlay: 'card_overlay'
}
