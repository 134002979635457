import { Injectable } from '@angular/core';
import { IDeliveryTypeFormStore, INITIAL_DELIVERY_TYPE_FORM_STORE } from '../models/type-of-delivery-form-store.interface';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppStoreService } from 'src/app/shared/stores/app/app-store.service';

@Injectable()
export class CheckoutTypeOfDeliveryFormStoreService {

  private readonly CHECKOUT_DELIVERY_TYPE_KEY = 'CDTSS';
  private form: IDeliveryTypeFormStore;
  private formSubject = new BehaviorSubject<IDeliveryTypeFormStore>(this.getFormStorage());
  private subscriptions: Subscription[] = [];
  public form$ = this.formSubject.asObservable();

  constructor(private appStore: AppStoreService) {
    // this subscribe will be activated for each 'this.formSubject.next'
    const formSubscription = this.form$
      .subscribe(form => {
        this.form = form;
        this.setFormStorage();
      });
    this.subscriptions.push(formSubscription);
   }

  public setDeliveyTypeValue(value: number) {
    this.setOneFormValue(value, 'deliveryType');
  }

  public setOneFormValue(value: number, key: string) {
    const { form } = this;
    form[key] = value;
    this.formSubject.next(form);
  }

  public setFormStorage(): void {
    const { form, CHECKOUT_DELIVERY_TYPE_KEY } = this;
    const stringFormatOfForm = JSON.stringify(form);
    this.appStore.setStorageItem(CHECKOUT_DELIVERY_TYPE_KEY, stringFormatOfForm);
  }

  public getFormStorage(): IDeliveryTypeFormStore {
    const { CHECKOUT_DELIVERY_TYPE_KEY } = this;
    const stringFromStorage = this.appStore.getStorageItem(CHECKOUT_DELIVERY_TYPE_KEY);
    let jsonFormatOfForm = INITIAL_DELIVERY_TYPE_FORM_STORE;
    if (stringFromStorage) {
      jsonFormatOfForm = JSON.parse(this.appStore.getStorageItem(CHECKOUT_DELIVERY_TYPE_KEY)) as IDeliveryTypeFormStore;
      const VALID_DELIVERY_TYPES = [ 0, 1 ];
      const { deliveryType } = jsonFormatOfForm;
      jsonFormatOfForm.deliveryType = VALID_DELIVERY_TYPES.includes(deliveryType) ? deliveryType : 0;
    }
    return jsonFormatOfForm;
  }

  public unsubscribeObservers() {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
