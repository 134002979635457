import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickUpGuardsModule } from './pick-up-guards/pick-up-guards.module';
import { PickUpModalsModule } from './pick-up-modals/pick-up-modals.module';
import { PickUpServicesModule } from './pick-up-services/pick-up-services.module';
import { PickUpStoresModule } from './pick-up-stores/pick-up-stores.module';
import { PickUpRequestService } from './pick-up-services/pick-up-request.service';
import { PickUpImplementService } from './pick-up-implements/pick-up-implement.service';
import { PickUpStoreClientService } from 'src/app/shared/service/pickup-store/pickup-store-client.service';
import { PickUpPersistanceService } from './pick-up-services/pick-up-persistance.service';
import { PickUpInStoreFactoryModule } from './pick-up-in-store-factory.module';
import { DeliveryInfoImplementService } from 'src/app/business/checkout/checkout-services/delivery-info-implement.service';

const SERVICES = [
  PickUpStoreClientService,
  DeliveryInfoImplementService
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PickUpGuardsModule,
    PickUpModalsModule,
    PickUpServicesModule,
    PickUpStoresModule,
    PickUpInStoreFactoryModule.forChild(),
  ],
  providers: [
    SERVICES,
    PickUpPersistanceService,
    PickUpImplementService,
    PickUpRequestService,
  ]
})
export class PickUpInStoreModule { }
