<ng-container [formGroup]="inputFormGroup">

  <input
    class="input-basic label {{inputClass}}"
    [formControlName]="inputFormControlName"
    [type]="inputType"
    [class.input-focus]="inputFocus"
    [class.input-error]="getFormError()"
    [placeholder]="inputPlaceholder"
    [name]="inputName"
    [maxlength]="''+inputMaxLength +''"
    [id]="inputId"
    (blur)="inputBlurEvent.emit($event)"
    (focus)="inputFocusEvent.emit($event)"
    [class.disabled]="disabled"
    fpDigitsOnly
    [disableDigitsOnly]="!inputDigitOnlyPipe"/>
  <label
    *ngIf="getFormError()"
    class="label--2 text-danger mx-0 mb-0 mt-2"
    [htmlFor]="inputId">
    {{ inputErrorMessage}}
  </label>

</ng-container>
