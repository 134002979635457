<fp-button
  buttonClass="btn-secondary
  btn-spacer-regular
  label-black
  weight-600
  btn-{{buttonDisplay}} {{buttonClass}}"
  [buttonType]="buttonType"
  [buttonStyle]="buttonStyle"
  [buttonId]="buttonId"
  [buttonDisabled]="buttonDisabled">
  <ng-content></ng-content>
</fp-button>
