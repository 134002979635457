import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TaggingService } from 'src/app/atm-services/tagging.service';

@Component({
  selector: 'fp-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.sass']
})
export class SelectComponent implements OnInit {

  @Input() optionList: Array<string>;
  @Input() selectPlaceholder: string;
  @Input() selectFocus: boolean;
  @Input() selectType: 'active' | 'default' = 'default';

  @Input() selectFormGroup: FormGroup = new FormGroup({});
  @Input() selectFormControlName = 'select';
  @Input() selectValidations: boolean; 
  @Input() selectValueControl = '';

  @Input() selectRequired: boolean;
  public selectFormControl: FormControl;


  private selectDisabledControl: boolean;

  @Input()
  set selectValue(value: string) {
    if (typeof value === 'string' && this.selectFormControl) {
      this.selectFormControl.setValue(value);
      this.selectValueControl = value;
    }
  }

  @Input()
  set selectDisabled(condition: boolean) {
    this.selectDisabledControl = !!condition;
    const action = condition ? 'disable' : 'enable';
    if (this.selectFormControl) {
      this.selectFormControl[action]();
    }
  }

  @Output() optionSelected = new EventEmitter();

  public isFocus: boolean;

  constructor(private _formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    const controlValue = this.setControlValues();
    const controlValidations = this.setControlValidations();
    this.selectFormControl = this._formBuilder.control(
      controlValue,
      controlValidations
    );
    this.selectFormGroup.addControl(this.selectFormControlName, this.selectFormControl);

  }

  get formGroup() {
    return this.selectFormGroup.controls;
  }

  setControlValidations() {
    let controlValidation = [];
    if (this.selectValidations) {

      if (this.selectRequired) {
        controlValidation = [...controlValidation, Validators.required];
      }

    }
    return controlValidation;
  }

  setControlValues() {
    let controlValue: any = this.selectValueControl;
    if (this.selectDisabledControl) {
      controlValue = { value: this.selectValueControl, disabled: this.selectDisabledControl };
    }

    return controlValue;
  }


  focusSelect() {
    this.isFocus = this.selectFocus;
  }

  focusOutSelect() {
    this.isFocus = false;
  }

  selectionChange() {
    this.optionSelected.emit(this.selectFormGroup.value[this.selectFormControlName]);
    TaggingService.catalogOrder(this.selectFormGroup.value[this.selectFormControlName]);
  }
}
