import { Injectable } from '@angular/core';
import { RecoveringPasswordService } from 'src/app/shared/service/recovering-password/recovering-password.service';
import { IUserRegisterRequestParams, IUserOTPRequestParams, IUpdatePasswordRequestParams } from 'src/app/shared/models/register/register.model';
import { RegisterClientService } from 'src/app/shared/service/user-register/register-client.service';

@Injectable()
export class LoginImplementService {

  constructor(
    private _recoveringPassword: RecoveringPasswordService,
    private _registerClientService: RegisterClientService
  ) { }

  public recoveringPassword(params: IUserRegisterRequestParams) {
    return this._recoveringPassword.recoveringPassword$(params);
  }

  public validateToken(params: IUserOTPRequestParams) {
    return this._registerClientService.tokenValidator$(params);
  }

  public updatePassword(params: IUpdatePasswordRequestParams) {
    return this._recoveringPassword.updatePassword$(params);
  }
}
