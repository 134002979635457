import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adaptiveImage'
})
export class AdaptiveImagePipe implements PipeTransform {

  constructor() {
  }

  transform(value: string, args?: any): any {
    if (value) {
      const index = value.lastIndexOf('.');
      const extension = value.slice(index, value.length);
      const imageName = value.slice(0, index);
      return imageName + '_D' + extension;
    }
    return value;
  }

}
