import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppStoreService } from '@stores/app/app-store.service';
import { ShoppingListStoreService } from '@stores/shopping-list/shopping-list-store.service';
import { RET3_RAD_STORAGE_KEY } from 'src/app/business/checkout/parameters/global-config';
import { ProductDetail } from 'src/app/business/product-detail/models/product-detail-model';
import { StoresForPickUpStoreService } from 'src/app/core/modules/pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service';
import { RetValidateCartService } from '../../service/cart/ret-validate-cart.service';
import { ProductOutOfStockDialogComponent } from '../product-out-of-stock-dialog/product-out-of-stock-dialog.component';
import { AddressImplementService } from 'src/app/core/modules/address-manager/address-services/address-implement.service';
import { AddressListStoreService } from 'src/app/core/modules/address-manager/address-stores/address-list-store.service';
import { finalize } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { EBrand } from '@parameters/access/brand.parameter';
import { LS_ADDRESS_SELECTED } from '../../service/local-storage/constants/address.constant';

@Component({
  selector: 'fp-product-seller-cant-added-dialog',
  templateUrl: './product-seller-cant-added-dialog.component.html',
  styleUrls: ['./product-seller-cant-added-dialog.component.sass']
})
export class ProductSellerCantAddedDialogComponent implements OnInit {
  loading: boolean;
  isMifaBrand = environment.brand === EBrand.mifarma;

  constructor(
    private retValidateCartService: RetValidateCartService,
    private storesForPickUpStore: StoresForPickUpStoreService,
    private appStoreService: AppStoreService,
    private shoppingListStore: ShoppingListStoreService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ProductOutOfStockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private addressImplementService: AddressImplementService,
    private addressListStore: AddressListStoreService
  ) { }

  get cartWithoutSeller() {
    return this.shoppingListStore.getLocalStorageShoppingCartList()?.filter((product: ProductDetail) => !product.sellerId);
  }

  get productName() {
    return this.dialogData.product?.name
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  changeLocation() {
    this.loading = true;
    this.addressImplementService.getAddresses$().pipe(finalize(() => { this.loading = false })).subscribe((addressList) => {
      const favorite = this.addressListStore.getFavoriteAddress(addressList);
      if (favorite?.name) {
        this.storesForPickUpStore.setSelectedDrugstoreById(favorite.id);
        this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "true");
        this.retValidateCartService.storeProcessType(RET3_RAD_STORAGE_KEY);
        this.dialog.closeAll();
        window.location.reload();
      } else {
        this.openAddressDialog();
      }
      // this.addressListStore.setAddressList(addressList);
      // this.addressManager.setAddressToUpdate(this.addressListStore.getFavoriteAddress(addressList));
    });
  }

  openAddressDialog() {
    this.retValidateCartService.openModalAddressList()
  }
}
