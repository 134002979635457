<div class="skeleton-for-pick-up-modal">
	<fp-sub-section-container>
		<div class="content mt-5">
			<div *ngIf="type === 'medium'" class="row">
				<div class="col-5">
					<div class="row">
						<div class="col-12">
							<fp-basic-skeleton height="50"></fp-basic-skeleton>
						</div>
						<div class="offset-1 col-10 mt-2">
							<fp-basic-skeleton height="20"></fp-basic-skeleton>
						</div>
					</div>
					<ng-container *ngFor="let item of [0, 1]" class="store-address">
						<div class="row mt-5">
							<div class="col-8">
								<fp-basic-skeleton height="20"></fp-basic-skeleton>
							</div>
							<div class="offset-2 col-2">
								<fp-basic-skeleton height="25"></fp-basic-skeleton>
							</div>
						</div>
						<div class="row mt-1">
							<div class="col-9">
								<fp-basic-skeleton height="20"></fp-basic-skeleton>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col-7">
								<fp-basic-skeleton height="25"></fp-basic-skeleton>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col-7">
								<fp-basic-skeleton height="25"></fp-basic-skeleton>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col-7">
								<fp-basic-skeleton height="25"></fp-basic-skeleton>
							</div>
						</div>
					</ng-container>
				</div>

				<div class="col-7">
					<div class="row map-skeleton">
						<div class="col-12">
							<fp-basic-skeleton height="220"></fp-basic-skeleton>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="type === 'full'" class="row">
				<div class="col-12">
					<div class="row">
						<div class="col-12">
							<fp-basic-skeleton height="50"></fp-basic-skeleton>
						</div>
						<div class="offset-1 col-10 mt-2">
							<fp-basic-skeleton height="20"></fp-basic-skeleton>
						</div>
					</div>
					<ng-container *ngFor="let item of [0, 1]" class="store-address">
						<div class="row mt-5">
							<div class="col-8">
								<fp-basic-skeleton height="20"></fp-basic-skeleton>
							</div>
							<div class="offset-2 col-2">
								<fp-basic-skeleton height="25"></fp-basic-skeleton>
							</div>
						</div>
						<div class="row mt-1">
							<div class="col-9">
								<fp-basic-skeleton height="20"></fp-basic-skeleton>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col-7">
								<fp-basic-skeleton height="25"></fp-basic-skeleton>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col-7">
								<fp-basic-skeleton height="25"></fp-basic-skeleton>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col-7">
								<fp-basic-skeleton height="25"></fp-basic-skeleton>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</fp-sub-section-container>
</div>
