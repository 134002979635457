<label class="radio-container" [ngClass]="innerClass">
  <input
    #inputRadio
    type="radio"
    class="radio"
    [ngClass]="radioClass"
    [id]="id"
    [name]="_radioName"
    [value]="radioValue"
    [disabled]="_radioDisabled"
    [checked]="radioValue === _radioValue"
    (change)="chooseRadio()"
  />

  <span class="radio-mark"></span>

  <span class="radio-content">
    <ng-content></ng-content>
  </span>
</label>
