export const SHOWCASE_SECTION = {
	TOP: "top",
	BOTTOM: "bottom"
};

export const CONTENFUL_CUSTOM_KEYS = {
	inkafarmaConfiguration: {
		CONFIG_ID: "checkout-ret-configuration",
		ALGOLIA_CONF_ID: "algolia-configuration",
		AUTOSUGGEST_CONF_ID: "autosuggest-configuration",
		REGISTER_CONFIG_IF: "register-form-configuration",
		CONFIG_ID_BUSINESS: "business-configuration",
		CONFIG_ID_CHECKOUT: "checkout-configuration",
		SECTION_CHECKOUT_DASHBOARD: "Landing del Checkout",
		NEIGHBOR_PHARMACY: "neighbor-pharmacy",
		CHAT_UNIFIED: "chat-unified",
		SALES_CHANNEL: "sales-channel",
		CONTROLLED_SALE:"controllated-sale"
	},
	contentfulConfiguration: {
		CONFIG_ID_BUSINESS: "business-configuration",
		ALGOLIA_CONF_ID: "algolia-configuration",
		AUTOSUGGEST_CONF_ID: "autosuggest-configuration",
		CONFIG_ID_CHECKOUT: "checkout-configuration",
		SECTION_CHECKOUT_DASHBOARD: "Landing del Checkout",
		CHAT_UNIFIED: "chat-unified",
		SALES_CHANNEL: "sales-channel",
		CONTROLLED_SALE:"controllated-sale"
	}
};
