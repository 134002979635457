import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ShoppingCartLoaderService {
  private _shoppingCartLoaderSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _shoppingCartSkeletonSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isLoadedCart = new BehaviorSubject(false);

  constructor() {
  }

  public set shoppingCartLoader(loader: boolean) {
    this._shoppingCartLoaderSubject.next(loader);
  }

  public get shoppingCartLoader$() {
    return this._shoppingCartLoaderSubject.asObservable();
  }

  public set shoppingCartSkeleton(loader: boolean) {
    this._shoppingCartSkeletonSubject.next(loader);
  }

  public get shoppingCartSkeleton$() {
    return this._shoppingCartSkeletonSubject.asObservable();
  }
}
