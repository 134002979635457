<div class="side-nav-content" [hidden]="modalStatus === ALL_MODAL_STATUS.LOADING">
	<!-- header del sidenav -->
	<div class="sidenav-header d-flex">
		<!-- <div class="controls-back-icon d-flex justify-content-center align-items-center" (click)="backButtonEvent()">
			<span class="icon icon-24-arrow-left"></span>
		</div> -->
		<fp-icon [iconName]="getStoreIconName" iconSize="24px"></fp-icon>
		<div class="controls-title d-flex align-items-center">
			<span class="heading-1"> {{ title }} </span>
		</div>
		<div class="controls-close-icon d-flex justify-content-center align-items-center" (click)="closeXEvent()">
			<fp-icon iconName="close-outline" [iconSize]="24"></fp-icon>
		</div>
	</div>
	<!-- body del sidenav -->
	<div class="sidenav-body">
		<div class="input-search-container">
			<div class="content">
				<fp-input-search-for-ret
					(searchDone)="setModalStatus($event)"
					[product]="product"
					[isResponsive]="true"
					[isDisabledInputSearch]="isDisableChooseStore"
				>
				</fp-input-search-for-ret>
			</div>
		</div>
		<div class="sidenav-body-content">
			<div class="sidenav-body-content-slide" [ngClass]="{ isMoved: isSlide }">
				<div class="drugstore-list-container">
					<div class="content">
						<div class="input-search-message paragraph--3 text-dark-gray text-center pl-3 pr-3">
							{{ config.suggestedMessageForPredictiveAddresses }}
						</div>
						<div class="drugstore-list-content">
							<fp-drugstore-list [isResponsive]="true" [status]="modalStatus" [isDisableChooseStore]="isDisableChooseStore">
							</fp-drugstore-list>
						</div>
					</div>
				</div>
				<!-- <div class="map-for-ret-container">
					<div class="selected-drugstore-for-map">
						<fp-store-for-ret-radio-button
							[storeToChoose]="temporalSelectedDrugstore"
							class="store-for-ret"
							[selected]="true"
							[isResponsive]="true"
							[customBorder]="''"
						>
						</fp-store-for-ret-radio-button>
					</div>
					<fp-map-for-ret
						[isResponsive]="true"
						[isDisabledMapForRET]="isDisableChooseStore"
						[hideMapForRET]="modalStatus == ALL_MODAL_STATUS.IS_PENDING || modalStatus == ALL_MODAL_STATUS.NO_ONE"
					>
					</fp-map-for-ret>
				</div> -->
			</div>
		</div>
	</div>

	<!-- footer del sidenav -->
	<div class="sidenav-footer">
		<div class="content sidenav-footer-content" *ngIf="modalStatus !== 'there_is_no_drugstore'">
			<div class="row" *ngIf="modalStatus === 'ok'">
				<div class="col-12">
					<div
						class="container--info-product-selected"
						*ngIf="shoppingCartListLocal === 0 && productSeller.length === 0 && !isHome && !modalResponsive"
					>
						<div class="disclaimer-container-nearby-modal">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							<p>Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto seleccionado.</p>
						</div>
						<!-- <p class="style-text">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							Te mostramos solo las boticas a las que puedes acercarte para adquirir este producto.
						</p> -->
					</div>
					<div
						class="container--info-product-selected"
						*ngIf="shoppingCartListLocal === 1 && productSeller.length === 0 && (isHome || modalResponsive)"
					>
						<div class="disclaimer-container-nearby-modal">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							<p>Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto seleccionado.</p>
						</div>
						<!-- <p class="style-text">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							Te mostramos solo las boticas que cuentan con el producto que tienes en tu carrito.
						</p> -->
					</div>
					<div
						class="container--info-product-selected"
						*ngIf="shoppingCartListLocal === 1 && productSeller.length === 0 && !isHome && !modalResponsive"
					>
						<div class="disclaimer-container-nearby-modal">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							<p>Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto seleccionado.</p>
						</div>
						<!-- <p class="style-text">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							Te mostramos solo las boticas a las que puedes acercarte para adquirir {{ data.change ? "" : "este y" }} el
							producto que tienes en tu carrito.
						</p> -->
					</div>
					<div
						class="container--info-product-selected"
						*ngIf="shoppingCartListLocal > 1 && productSeller.length === 0 && !modalResponsive"
					>
						<div class="disclaimer-container-nearby-modal">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							<p>Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto seleccionado.</p>
						</div>
						<!-- <p class="style-text">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							Te mostramos solo las boticas a las que puedes acercarte para adquirir {{ isDetail ? "este y" : "" }} los
							<b>{{ shoppingCartListLocal }} productos</b> de tu carrito.
						</p> -->
					</div>
					<div
						class="container--info-product-selected"
						*ngIf="shoppingCartListLocal > 1 && productSeller.length === 0 && modalResponsive"
					>
						<div class="disclaimer-container-nearby-modal">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							<p>Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto seleccionado.</p>
						</div>
						<!-- <p class="style-text">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							Te mostramos solo las boticas a las que puedes acercarte para adquirir
							{{ isDetail && !modalResponsive ? "este y" : "" }} los <b>{{ shoppingCartListLocal }} productos</b> de tu
							carrito.
						</p> -->
					</div>
					<div class="container--info-product-selected" *ngIf="shoppingCartListLocal > 1 && productSeller.length > 0">
						<div class="disclaimer-container-nearby-modal">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							<p>Te mostramos solo las boticas que cuentan con la disponibilidad completa de tu carrito y el producto seleccionado.</p>
						</div>
						<!-- <p class="style-text">
							<fp-icon iconSize="16" [iconName]="getIconName" class="pst-icon"></fp-icon>
							Te mostramos solo las boticas a las que puedes acercarte para adquirir {{ isDetail ? "este y" : "" }} los
							<b
								>{{ productInka.length }} productos de tu carrito vendidos por
								{{ brand == "Inkafarma" ? "Inkafarma" : "Mifarma" }}.</b
							>
						</p> -->
					</div>
					<div
						class="container--info-product-selected-empty"
						*ngIf="shoppingCartListLocal === 0 && productSeller.length === 0 && isHome"
					></div>
					<div
						class="container--info-product-selected-empty"
						*ngIf="shoppingCartListLocal === 0 && productSeller.length === 0 && !isHome && modalResponsive"
					></div>
				</div>
			</div>
			<div
				class="row justify-content-center container-button-position"
				[ngClass]="{ 'position--button-ok': shoppingCartListLocal > 0 && modalStatus !== 'there_is_no_drugstore' }"
				[hidden]="modalStatus === 'no_one' || modalStatus === ALL_MODAL_STATUS.IS_PENDING"
			>
				<!-- <div
					class="col-4 label-black--1 text-support-two d-flex justify-content-center align-items-center"
					(click)="switchSlide()"
				>
					{{ isSlide ? "Ver lista" : "Ver mapa" }}
				</div> -->
				<div class="col-12" *ngIf="productInShoppingCart || data.modalResponsive">
					<fp-button-primary
						buttonDisplay="block"
						[buttonDisabled]="modalStatus === ALL_MODAL_STATUS.LOADING"
						(click)="selectDrugStore()"
					>
						Elegir botica
					</fp-button-primary>
				</div>
				<div class="col-12" *ngIf="!productInShoppingCart && !data.modalResponsive">
					<fp-button-primary
						buttonDisplay="block"
						[buttonDisabled]="modalStatus === ALL_MODAL_STATUS.LOADING"
						(click)="selectedAndAddProduct()"
					>
						Elegir botica y agregar producto
					</fp-button-primary>
				</div>
			</div>
			<br />
		</div>
	</div>
</div>

<div class="side-nav-keleton content my-5" *ngIf="modalStatus === ALL_MODAL_STATUS.LOADING">
	<div class="row">
		<div class="col-12">
			<fp-basic-skeleton height="50"></fp-basic-skeleton>
		</div>
		<div class="offset-1 col-10 mt-2">
			<fp-basic-skeleton height="20"></fp-basic-skeleton>
		</div>
	</div>
	<ng-container *ngFor="let item of [0, 1]" class="store-address">
		<div class="row mt-5">
			<div class="col-8">
				<fp-basic-skeleton height="20"></fp-basic-skeleton>
			</div>
			<div class="offset-2 col-2">
				<fp-basic-skeleton height="25"></fp-basic-skeleton>
			</div>
		</div>
		<div class="row mt-1">
			<div class="col-9">
				<fp-basic-skeleton height="20"></fp-basic-skeleton>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-7">
				<fp-basic-skeleton height="25"></fp-basic-skeleton>
			</div>
		</div>
	</ng-container>
</div>
