import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fp-it-was-wrong-modal',
  templateUrl: './it-was-wrong-modal.component.html',
  styleUrls: ['./it-was-wrong-modal.component.sass']
})
export class ItWasWrongModalComponent {

  constructor(
    public matDialogRef: MatDialogRef<ItWasWrongModalComponent>,
  ) { }

  closeModal() {
    this.matDialogRef.close();
  }
}
