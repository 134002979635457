import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumber'
})
export class CustomNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return (value < 10 && value >= 0 ? '0' : '') + value;
  }

}
