import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnonymousWarningModalComponent } from '../modal-components/anonymous-warning-modal/anonymous-warning-modal.component';

@Injectable()
export class AnonymousWarningManagerService {

  constructor(
    private dialog: MatDialog,
  ) { }

  public showAnonymousWarning(): void {
    this.dialog.open(AnonymousWarningModalComponent, {
      width: '340px',
      disableClose: true,
      hasBackdrop: false,
    });
  }
}
