import { Component, Input, OnInit } from "@angular/core";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { EProductStatus } from "src/app/core/modules/product-card/models/product-status.enum";

@Component({
  selector: "fp-product-information-cart",
  templateUrl: "./product-information-cart.component.html",
  styleUrls: ["./product-information-cart.component.sass"],
})
export class ProductInformationCartComponent implements OnInit {
  public eProductStatus = EProductStatus;

  @Input() productStatus: number = this.eProductStatus.available;
  @Input() image = "";
  @Input() name = "";
  @Input() description = "";
  @Input() prescription = "";
  @Input() guaranteed = "";
  @Input() stockPrincipal = 0;
  @Input() stockBackup = null;
  @Input() prescriptionCode = 0;

  isOrderTaker = OrderTakerValidator.isOrderTakerEnv()

  constructor() {}

  ngOnInit() {
    console.log(this.prescription);
    
  }
}
