import { Component, Input, OnInit } from '@angular/core';
import { GlobalProductCardService } from '../../service/global-product-card.service';

@Component({
  selector: 'fp-product-offer-price-mifa',
  templateUrl: './product-offer-price-mifa.component.html',
  styleUrls: ['./product-offer-price-mifa.component.sass']
})
export class ProductOfferPriceMifaComponent implements OnInit {
  @Input() offerPrice: number;
  @Input() display: 'inline' | 'block' = 'inline';
  @Input() direction: 'right' | 'left' = 'left';
  @Input() iconDirection: 'right' | 'left' = 'left';
  @Input() icon: boolean;
  @Input() fontSize: string = '--1'
  @Input() place:string = '';

  constructor(
    public _globalProductCardService: GlobalProductCardService
  ) {
  }

  ngOnInit() {
  }

}
