import { IServicesTypes } from "@models/services-type/services-type.interface";
import { environment } from './../../../environments/environment.call-qas';

export class GLOBAL {
  public static SWIPER = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: { el: ".swiper-pagination", clickable: true },
    scrollbar: { el: ".swiper-scrollbar", hide: true },
  };
  public static IMAGE_EXTENSION = { webp: "webp", png: "png" };
  public static CLASS_ANIMATION = {
    boundInDown: "animation-bounce-in-down",
    wobble: "animation-wobble",
  };
  public static MINLENGTH_TIMER_SEARCH = 3;
  public static MINLENGTH_SEARCH = 4;
  public static LIMIT_SUGGESTIONS = 10;
  // public static TIME_SEARCH = 600;
}

export class TimeFormat {
  public static extendTime = "DD MMM YYYY HH:MM a";
  public static shortTime = "DD MMM YYYY";
}

export enum BREAKPOINTS {
  xxs = 0,
  xs = 360,
  sm = 480,
  md = 640,
  lg = 768,
  xl = 1024,
  xxl = 1366,
}

export enum HTTP_HEADERS {
  androidVersion = "100000",
  applicationId = "1",
  drugStoreId = "1",
  source = "web",
}

export enum HTTP_ERROR_RESPONSE_STATUS {
  UNAUTHORIZED = 401,
  UPDATE_USER_INFO = 417, // PENDING USER INFO
  INTENAL_ERROR = 500,
}

export class NUMBERS {
  public ZERO: number;
  public ONE: number;
  public TWO: number;
  public THREE: number;
  public FOUR: number;
  constructor() {
    this.ZERO = 0;
    this.ONE = 1;
    this.TWO = 2;
    this.THREE = 3;
    this.FOUR = 4;
  }
}

export enum TIME_REMOVE_PRODUCT_MINICART {
  time = 2000,
}

export enum S3 {
  url = "https://s3-us-west-2.amazonaws.com/inkafarmaproductimages/newimages/",
}

export enum imageSize {
  small = "X.png",
  medium = "M.png",
  large = "L.png",
}

export enum ORDER_STATUS {
  ARRIVED = "ARRIVED",
  ASSIGNED = "ASSIGNED",
  CANCELLED = "CANCELLED",
  DELIVERED = "DELIVERED",
  HAVE_TRANSFER = "HAVE_TRANSFER",
  ON_ROUTE = "ON_ROUTE",
  ON_STORE = "ON_STORE",
  PACKING = "PACKING",
  PICKING = "PICKING",
  PREPARED = "PREPARED",
  READY_FOR_BILLING = "READY_FOR_BILLING",
  READY_FOR_PICKUP = "READY_FOR_PICKUP",
  REJECTED = "REJECTED",
  SCHEDULED = "SCHEDULED",
  TRANSFERRED = "TRANSFERRED",
}

export const NUMBERS_CHARACTERS_PRODUCT_ID = 6;

export const USER_REGISTRATION_ERROR_CODE = {
  SUCCESS: "1",
  ERROR_EMAIL_USED: "2", // email-password
  ERROR_PHONE_USED: "3", // landing
  ERROR_EMAIL_DOES_NOT_EXIST: "4",
  ERROR_PASSWORD_DOES_NOT_MATCH: "5",
  ERROR_UPDATE_PASSWORD: "6",
  ERROR_EMAIL_AND_PHONE_USED: "9",
  ERROR_NOT_HANDLED: "10", // no permitido
  ERROR_USER_NOT_EXIST: "11", // user not exist
  ERROR_USER_UPDATE: "12", // update user info
  ERROR_UPDATE_USER_INFORMATION: "12", // user update information
};

export const USER_REGISTRATION_ERROR_MESSAGE = {
  USER_NOT_EXISTS: "User not exists.", // no permitido
  UPDATE_USER: "Update user information.", // user not exist
};

export const USER_SOURCE_LOGIN = {
  MAIN_LOGIN: "main_login",
  CART_LOGIN: "cart_login",
  INCOMPLETE_USER_INFO: "incomplete_user_info",
  CHECKOUT: "checkout",
};

export const REDIRECT_STATUS = {
  PENDING: "pending",
  COMPLETED: "completado",
};

export const DELAY_TO_ALLOW_LOAD_PAGE = 8000;

export const COMPANY_ID = 10;
export const COMPANY_CODE = "MF";

export enum AUTH_ORIGIN {
  DEFAULT = -1,
  PROVIDER = 1,
  EMAIL_PASSWORD = 2,
  INCOMPLETE_INFO = -1,
}

export enum DELIVERY_SERVICES {
  INKATRACKER_LITE = 4,
  SELLER = 5,
}

export const AUTH_PROVIDER = "authProvider";
export const NAVIGATION_URL = "navigationUrl";

export const UNKNOW_AUTH_PROVIDER = "unknowProvider";

export enum DEVICES {
  DEFAULT = "",
  DESKTOP = "desktop",
  RESPONSIVE = "responsive",
  TABLET = "tablet",
}

export enum GENDER_NEW_REGISTER {
  MASCULINO = 1,
  FEMENINO = 2,
}

// this parameters are exclusive for GENDER
export const SELECT_YOUR_GENDER = "Selecciona tu género";
export const VALID_GENDERS = ["Masculino", "Femenino"];
export const VALID_SEXES = ["Hombre", "Mujer"];
export const VALID_DOCUMENT_TYPE = [
  "DNI",
  "Carnet de extranjería",
  "C.E.",
  "Pasaporte",
];
export const GENDERS_INFO = [
  { text: "Masculino", value: "M" },
  { text: "Femenino", value: "F" },
];
export const GENDERS_INFO_V2 = [
  { text: "Masculino", value: 1 },
  { text: "Femenino", value: 2 },
];
export const GENDERS = [SELECT_YOUR_GENDER, ...VALID_GENDERS];

export enum SERVICES_TYPES_ENUM {
  RETIRO = "RET",
  DELIVERY = "PROG",
  AM_PM = "AM_PM",
  EXPRESS = "EXP",
}

export enum DISPATCH_TYPE{
  PICKUP_IN_STORE = 'RET',
  DELIVERY = 'RAD'
}

export enum ERROR_HTTP_DATA {
  SERVICES,
  STORE,
  STOREBACKUP,
}

export const LIST_SERVICES_TYPES_DELIVERY = [
  SERVICES_TYPES_ENUM.EXPRESS,
  SERVICES_TYPES_ENUM.AM_PM,
  SERVICES_TYPES_ENUM.DELIVERY,
];

export const SERVICES_TYPES: IServicesTypes[] = [
  // {
  //   id: SERVICES_TYPES_ENUM.RETIRO,
  //   name: "Retiro en tienda",
  //   shortName: "RET",
  //   description: "Recoge de la botica más cercana",
  //   textDisabled:
  //     "El servicio de retiro en tienda no se encuentra disponible por el momento",
  //   disabled: false,
  //   switch: true,
  // },
  {
    id: SERVICES_TYPES_ENUM.EXPRESS,
    name: "Entrega Express",
    shortName: "EXP",
    description: "Llegaría en menos de {0} minutos",
    textDisabled:
      "El servicio de delivery Express no se encuentra disponible por el momento",
    disabled: true,
    switch: false,
  },
  {
    id: SERVICES_TYPES_ENUM.AM_PM,
    name: "Entrega AM/PM",
    shortName: "AM/PM",
    description: "Llegaría {0} {1} entre las {2}",
    textDisabled:
      "El servicio de delivery AM/PM no se encuentra disponible por el momento",
    disabled: true,
    switch: false,
  },
  {
    id: SERVICES_TYPES_ENUM.DELIVERY,
    name: "Entrega Regular",
    shortName: "Regular",
    description: "Llegaría {0} {1} entre las {2}",
    textDisabled:
      "El servicio de delivery regular no se encuentra disponible por el momento",
    disabled: true,
    switch: false,
  },
];

export enum DOCUMENT_TYPE {
  DNI = "002",
  PAS = "003",
  CE = "004",
}

export const DOCUMENT_TYPE_LABEL = {
  [DOCUMENT_TYPE.DNI]: "DNI",
  [DOCUMENT_TYPE.CE]: "CE",
  [DOCUMENT_TYPE.PAS]: "PAS",
};

export enum ALERT_STATES {
  NOT_FOUND_RET,
  NOT_FOUND_DELIVERY,
  NOT_FOUND_COVERAGE,
}

export const BAGS_BRAND = {
 inkafarma: ["028984", "028982"],
 mifarma:["028985", "028986"]
}

export const APIGEE_INTERCEPTOR_PATHS = {
  warehouse: /validate-cart|\/product\/|\/products|validateAddToCart|\/cart\b/,
  filtered: /\/filtered-products/,
}
