import { Component, Input } from '@angular/core';
import { EProductStatus } from '../../models/product-status.enum';
import { ProductImplementService } from '../../service/product-implement.service';
import { ProductAlertsService } from '../../service/product-alerts.service';
import { ProductPresentation } from '../../models/product-presentation.model';
import { ProductShoppingCart } from '../../models/product-shopping-cart/product-shopping-cart.model';
import { BUSINESS_PATHS } from 'src/app/shared/parameters/business.parameter';
import { getUnavailablePresentationStatus } from '../../service/product-status.helper';
import { ProductSalesforceService } from '../../service/product-salesforce.service';
import { TaggingService } from 'src/app/atm-services/tagging.service';
import { ISellerProduct } from '../../models/products-seller-list.model';
import { RetValidateCartService } from 'src/app/shared/service/cart/ret-validate-cart.service';

@Component({
  selector: 'fp-product-seller-cart-responsive',
  templateUrl: './product-seller-cart-responsive.component.html',
  styleUrls: ['./product-seller-cart-responsive.component.sass']
})
export class ProductSellerCartResponsiveComponent {
  public eProductStatus = EProductStatus;
  public productStatus: number = EProductStatus.available;
  public productPresentation: ProductPresentation;

  public productPath = BUSINESS_PATHS.PRODUCT;

  public initialPresentation: string;
  public initialPrecio: number;

  @Input() product: ProductShoppingCart;
  @Input() productSeller: ISellerProduct;

  constructor(
    private _productImplement: ProductImplementService,
    private _productAlerts: ProductAlertsService,
    private _productSalesforce: ProductSalesforceService,
    private retValidateCartService: RetValidateCartService
  ) {
  }

  setProductPresentation(presentation: ProductPresentation) {
    this.initialPresentation = this.productPresentation.presentation;
    this.initialPrecio = this.productPresentation.price;
    this.product.presentationIdSelected = presentation.id;
    this.productPresentation = presentation;
    this.productStatus = getUnavailablePresentationStatus(this.product, this.productPresentation.fractionated);
    this.setQuantity(this.productStatus === this.eProductStatus.available ? 1 : 0, true);
    TaggingService.cartPresentation();
    this.initialPrecio = this.productPresentation.price;
    this.initialPresentation = this.productPresentation.presentation;

    this.retValidateCartService.processValidateAddToCart.next(true);
  }

  setQuantity(quantity: number, changePresentation = false) {
    if (this.productSeller.quantitySelected < quantity) {
      TaggingService.addToCart(this.productSeller, this.productPresentation);
    } else {
      const quantityTotalRemove = changePresentation ?  this.productSeller.quantitySelected : 0;
      TaggingService.removeFromCart(this.productSeller, this.initialPrecio, this.initialPresentation, quantityTotalRemove);
    }
    this.productSeller.quantitySelected = quantity;
    this._productImplement.updateProduct(
      this.productSeller.id,
      this.productSeller.quantitySelected,
      this.productSeller.presentationIdSelected,
      this.productSeller.sellerId,
    );
    this._productSalesforce.updateProduct(
      this.productSeller.id,
      this.productSeller.id,
      this.productSeller.quantitySelected,
      this.productPresentation.price * this.productSeller.quantitySelected
    );
    TaggingService.updateCartSDK(this.product, this.productPresentation.price,quantity)

    TaggingService.cartQuantity();

    this.retValidateCartService.processValidateAddToCart.next(true);
  }

  deleteProduct(id) {
    this._productImplement.deleteProduct(id);
    this._productAlerts.deleteProductAlert();
    this._productSalesforce.deleteProduct(id);
    TaggingService.cartEliminar();

    this.retValidateCartService.processValidateAddToCart.next(true);
  }

  listPresentation(data) {
    const listProdustSellerPresentations = [];
    let list = [];
    this.productPresentation = {
        description: data.noFractionatedText,
        fractionated: false,
        id: data.presentationId,
        maxUnitSale: data.maxUnitSale,
        price: data.price,
        stock: data.stock,
        presentation: data.presentation,
        quantityLimit: data.quantityLimit,
        image: data.imageList[0].url,
        offerPrice: data.offerPrice,
        regularPrice: data.regularPrice
    };
    listProdustSellerPresentations.push(this.productPresentation);
    list = listProdustSellerPresentations;
    return list;
  }

}
