import {Injectable} from '@angular/core';
import {StaticLandingDialogImplementService} from '../../../services/static-landing-dialog-implement.service';
import {PrivacyPolicyDialogComponent} from '../privacy-policy-dialog/privacy-policy-dialog.component';

@Injectable()
export class PrivacyPolicyDialogImplementService {
  constructor(
    private _staticLandingDialogImplement: StaticLandingDialogImplementService
  ) {
  }

  openPrivacyPolicyDialog() {
    this._staticLandingDialogImplement
      .openStaticLandingDialog(PrivacyPolicyDialogComponent);
  }

}
