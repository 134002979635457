<mat-radio-group [(ngModel)]="radioSelected" (ngModelChange)="radioFilter()">

  <ng-container *ngIf="selectAll">
    <div class="filter-item mb-1 mt-1">
      <mat-radio-button disableRipple class="w-100" [value]="selectAll">{{selectAll.name}}</mat-radio-button>
    </div>
  </ng-container>


  <ng-container *ngFor="let item of list; let index = index">
    <ng-container *ngIf="index < itemsInView">

      <div class="filter-item mb-1 mt-1">
        <mat-radio-button disableRipple class="w-100" [value]="item">{{item.name}}</mat-radio-button>
        <ng-container *ngIf="item.row?.length">
          <fp-lazy-wrapper [lazy]="'assets/images/main/chevron-gray.svg'">
            <img #fpImg class="mr-2 d-none d-md-flex" height="7" [class.rotate-180]="radioSelected.id === item.id" alt="chevron"/>
          </fp-lazy-wrapper>
        </ng-container>
      </div>

      <ng-container *ngIf="item.row && radioSelected.id === item.id">
        <div class="checkbox-in-radio">
          <fp-checkbox-list [list]="item.row" (selection)="checkboxFilter()"></fp-checkbox-list>
        </div>
      </ng-container>

    </ng-container>
  </ng-container>
</mat-radio-group>
