import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationTokenService } from '../../service/authentication/authentication-token.service';
import { CustomToken } from '../../models/custom-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationTokenImplementService {

  constructor(private _authenticationTokenService: AuthenticationTokenService) { }

  public getCustomToken(): Observable<CustomToken> {
    return this._authenticationTokenService.getCustomToken();
  }
}
