import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  DeliveryMethod,
  ScheduleResponse,
} from "@models/schedule-response.model";
import { IServicesTypes } from "@models/services-type/services-type.interface";
import { SERVICES_TYPES } from "@parameters/global";
import { AppStoreService } from "@stores/app/app-store.service";
import { forkJoin, of } from "rxjs";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { StoreBackup } from "../../models/store-backup.model";
import { Store } from "../../models/store.model";
import { Zone } from "../../models/zone.model";
import { END_POINTS_BUSSINESS } from "../../parameters/generic/endpoints";
import { EHttpHeaders } from "../../parameters/generic/http-header.parameter";
import { TokenStoreService } from "../../stores/user/token-store.service";

@Injectable({
  providedIn: "root",
})
export class DispatchHttpService {
  private zone$: BehaviorSubject<Zone | StoreBackup> = new BehaviorSubject<
    Zone | StoreBackup
  >(null);
  public zoneObs: Observable<Zone | StoreBackup> = this.zone$.asObservable();
  constructor(
    private _httpClient: HttpClient,
    private _tokenStoreService: TokenStoreService,
    private _appStoreService: AppStoreService
  ) {}
  public get zone() {
    return this.zone$.value;
  }
  public set zone(value) {
    this.zone$.next(value);
  }
  getZone$(lat: number, long: number): Observable<Zone> {
    const headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });
    return this._httpClient
      .get<Zone>(
        `${END_POINTS_BUSSINESS.GET_ZONE}?latitude=${lat}&longitude=${long}`,
        {
          headers,
        }
      )
      .pipe(
        map((r) => {
          this.zone = r;
          return r;
        })
      );
  }

  get getServiceTypes$(): Observable<Array<any>> {
    return of(this.zone as Zone).pipe(
      map((res) => {
        let services = [...SERVICES_TYPES];
        if (res.deliveryTypes && res.deliveryTypes.length > 0) {
          services.forEach((r) => {
            let item = res.deliveryTypes.find((i) => i.service == r.id);
            r.disabled = item ? false : true;
          });
        }
        return [...services];
      })
    );
  }

  // get getServiceTypes$(): Observable<Array<ServiceType | any>> {
  //   const headers = new HttpHeaders({
  //     [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
  //   });
  //   return this._httpClient
  //     .get<Array<ServiceType | any>>(
  //       `${END_POINTS_BUSSINESS.GET_SERVICES_TYPES}/${this.zone$.value.zoneId}`,
  //       {
  //         headers,
  //       }
  //     )
  //     .pipe(
  //       map((res) => {
  //         if (res.length > 0) {
  //           const services = SERVICES_TYPES;
  //           services.forEach((r) => {
  //             let item = res.find((i) => i.service == r.id);
  //             r.disabled = item ? false : true;
  //           });
  //           res = [...services];
  //         }
  //         return res;
  //       })
  //     );
  // }
  get getStore$(): Observable<Store | any> {
    const headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });
    return this._httpClient.get<Store>(
      `${END_POINTS_BUSSINESS.GET_STORE}/${
        (this.zone$.value as any).fulfillmentCenterCode
      }`,
      {
        headers,
      }
    );
  }
  get getStoreBackup$(): Observable<StoreBackup | any> {
    const headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });
    const zone = this.zone$.value as Zone;
    return zone.zoneBackupDTO &&
      zone.zoneBackupDTO.preferableLocalBackupToShow == "Y"
      ? this._httpClient.get<StoreBackup>(
          `${END_POINTS_BUSSINESS.GET_BACKUP}/${
            zone.zoneBackupDTO?.zoneId ?? ""
          }`,
          {
            headers,
          }
        )
      : of(null);
  }

  getData$(): Observable<[Array<IServicesTypes>, Store, StoreBackup]> {
    return forkJoin([
      this.getServiceTypes$,
      this.getStore$,
      this.getStoreBackup$,
    ]);
  }

  getSchedule$(lat, long): Observable<Array<DeliveryMethod>> {
    const headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });
    const params = new HttpParams()
      .append("channel", "CALL")
      .append("cia", this._appStoreService.brandAtention)
      .append("cb", 3763410)
      .append("latitude", lat)
      .append("longitude", long);
    // .append("localCode", localCode);
    return this._httpClient
      .get<Array<ScheduleResponse>>(`${END_POINTS_BUSSINESS.GET_SCHEDULES}`, {
        headers,
        params,
      })
      .pipe(
        map((r = []) => {
          const { deliveryMethods = [] } = r[0];
          return deliveryMethods;
        })
      );
  }
}
