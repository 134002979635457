import { Injectable } from '@angular/core';
import { TaggingService } from 'src/app/atm-services/tagging.service';
import { AnalyticsModel } from './model/analytics.model';


@Injectable()
export class AnalyticsManager {

  static dispatchTriggerTag(anayticsModel: AnalyticsModel, transformText = true) {

    const eventName = transformText ? this.parseEventName(anayticsModel.eventAction) : anayticsModel.eventName;
    const eventAction = anayticsModel.eventAction;
    const eventLabel = anayticsModel.eventLabel;

    let eventCategory = anayticsModel.prefixEventCategory;
    if (anayticsModel.pageName) {
      eventCategory = eventCategory + ' / ' + anayticsModel.pageName;
    }

    const prefixEventName = anayticsModel.prefixEventName + '_' + eventName;

    TaggingService.triggerTag({
      eventName: prefixEventName,
      eventCategory,
      eventAction,
      eventLabel,
    });
  }

  static dispatchTriggerTagEcommerce(banner: any, anayticsModel: any) {

    TaggingService.ecommercePromotion(
     banner, anayticsModel
    );
  }

  static ecommercePromotionClick(banner: any, anayticsModel: any) {

    TaggingService.ecommercePromotionClick(
     banner, anayticsModel
    );
  }
  static parseEventName(name: string): string {
    return name
      .toLowerCase()
      .replace(/[á]/g, 'a')
      .replace(/[é]/g, 'e')
      .replace(/[í]/g, 'i')
      .replace(/[ó]/g, 'o')
      .replace(/[ú]/g, 'u')
      .replace(/[ñ]/g, 'n')
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(/ +/g, '_');
  }

  static dispatchEvent({
    eventName,
    eventCategory,
    eventAction,
    eventLabel,
  }: {
    eventName: string;
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
  }) {
    TaggingService.triggerTag({
      eventName,
      eventCategory,
      eventAction,
      eventLabel,
    });
  }
}
