import { Component, OnInit, OnDestroy } from '@angular/core';
import { AnonymousModalValidationService } from '../../login-services/anonymous-modal-validation.service';
import { ForgotPasswordFormService } from '../../login-forms/forgot-password-form.service';
import { SnackbarAlertService } from '../../../snackbar/snackbar-alert.service';
import { ForgotPasswordConfirmationModalService } from '../../login-services/forgot-password-confirmation-modal.service';
import { LoginImplementService } from '../../login-services/login-implement.service';
import { IUserRegisterRequestParams, RegisterResponse } from 'src/app/shared/models/register/register.model';
import { COMPANY_ID, USER_REGISTRATION_ERROR_CODE } from 'src/app/shared/parameters/global';
import { ForgotPasswordStoreService } from '../../login-stores/forgot-password-store.service';
import { LoginModalsManagerHelperService } from '../../login-services/login-modals-manager-helper.service';
import { RegisterModalsMaganerService } from '../../../register/register-services/register-modals-manager.service';

@Component({
  selector: 'fp-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  private message = 'El correo que ingresaste no está registrado. Ingresa un nuevo correo o crea una cuenta.';
  public disabledButton = false;


  constructor(
    private anonymousModalValidation: AnonymousModalValidationService,
    public forgotPasswordForm: ForgotPasswordFormService,
    public forgotPasswordConfirmationForm: ForgotPasswordConfirmationModalService,
    private snackBarAlert: SnackbarAlertService,
    private loginImplement: LoginImplementService,
    private forgotPasswordStore: ForgotPasswordStoreService,
    private loginModalsManagerHelper: LoginModalsManagerHelperService,
    private registerModalsManagerHelper: RegisterModalsMaganerService,
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.forgotPasswordForm.emailControl.setValue('');
  }

  forgotPassword() {
    this.disabledButton = true;
    const emailValue = this.forgotPasswordForm.emailControl.value;
    const userRecover = {
      email: emailValue,
      companyId: COMPANY_ID.toString()
    } as IUserRegisterRequestParams;

    this.loginImplement.recoveringPassword(userRecover)
      .subscribe((response: RegisterResponse) => {
        if (response.code === USER_REGISTRATION_ERROR_CODE.ERROR_EMAIL_DOES_NOT_EXIST) {
          this.snackBarAlert.snackbarAlertInfo(this.message);
        } else {
          this.forgotPasswordStore.email = emailValue;
          this.loginModalsManagerHelper.openForgotPasswordConfirmationModal();
          this.loginModalsManagerHelper.closeForgotPasswordModal();
        }
        this.disabledButton = false;
      });

  }

  closeModal() {
    this.loginModalsManagerHelper.closeForgotPasswordModal();
    this.registerModalsManagerHelper.openRegisterModal();
  }

  openLogin() {
    this.closeModal();
    this.anonymousModalValidation.showCardLoginModal();
  }

}
