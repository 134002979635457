import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrand } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { Entry } from "contentful";
import { map } from "rxjs/operators";
import { IConfiguration } from "src/app/shared/models/contentful/business-configuration.model";
import { IGenericConfiguration } from "src/app/shared/models/contentful/contentful-generic-configuration.model";
import { CONTENFUL_CUSTOM_KEYS } from "src/app/shared/parameters/contenful";
import { CONTENTFUL_PATH } from "src/app/shared/parameters/endpoints";
import { CONTENTFUL_QUERIES } from "src/app/shared/parameters/querys";
import { GenericContentfulService } from "src/app/shared/service/generic";

@Injectable({ providedIn: "root" })
export class CardAssociatedService {
  constructor(
    private generictContenful: GenericContentfulService,
    private _appStore: AppStoreService
  ) {}

  public getCloudConfigurationForRET$() {
    const contentfulConfigurationKey =
      environment.brand === EBrand.inkafarma
        ? CONTENTFUL_PATH.contentTypeIds.INKAFARMA_CONFIGURATION_KEY
        : CONTENTFUL_PATH.contentTypeIds.contentfulConfigurationKey;
    const { CONFIG_ID_BUSINESS } =
      environment.brand === EBrand.inkafarma
        ? CONTENFUL_CUSTOM_KEYS.inkafarmaConfiguration
        : CONTENFUL_CUSTOM_KEYS.contentfulConfiguration;
    const QUERY =
      CONTENTFUL_QUERIES.prototype.queryToGetCheckoutConfigurationByConfigId(
        CONFIG_ID_BUSINESS
      );
    return this.generictContenful
      .getEntries<IGenericConfiguration<IConfiguration>>(
        contentfulConfigurationKey,
        QUERY
      )
      .pipe(
        map((entries) => {
          const configuration = entries.length
            ? entries[0]
            : ({} as Entry<IGenericConfiguration<IConfiguration>>);
          return configuration;
        })
      );
  }
}
