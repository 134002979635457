<div *ngIf="!isInkaBrand; else inkaHeader" class="content bg-white">
  <div class="title">
    <img [src]="srcImage" class="icon-user" />
    <div class="label-title">{{ title }}</div>
    <span
      class="icon icon-14-{{ iconName }} icon-close"
      (click)="closeLogin()"
    ></span>
  </div>
</div>

<ng-template #inkaHeader>
  <div class="content bg-white">
    <div class="title">
      <img [src]="withAnonymous ? '' : srcImage" class="icon-user" />
      <div class="label-title">{{ title }}</div>
      <span
        class="icon icon-14-{{ iconName }} icon-close"
        (click)="closeLogin()"
      ></span>
    </div>
  </div>
</ng-template>
