<div class="carousel-title">

  <div class="d-flex align-items-center"
    [ngClass]="{'justify-content-xs-between': !showMoreResponsive, 'justify-content-xl-start': !showMoreResponsive}">
    <h1 *ngIf="list?.headingLevel=='h1'" class="title heading-2 heading-md-3">{{list?.title}}</h1>
    <h2 *ngIf="list?.headingLevel=='h2'" class="title heading-2 heading-md-3">{{list?.title}}</h2>
    <h3 *ngIf="list?.headingLevel=='h3'" class="title heading-2 heading-md-3">{{list?.title}}</h3>
    <h4 *ngIf="list?.headingLevel=='h4'" class="title heading-2 heading-md-3">{{list?.title}}</h4>
    <h5 *ngIf="list?.headingLevel=='h5'" class="title heading-2 heading-md-3">{{list?.title}}</h5>
    <h6 *ngIf="list?.headingLevel=='h6'" class="title heading-2 heading-md-3">{{list?.title}}</h6>

    <span *ngIf="list?.slug">

      <div style="cursor: pointer;" class="text-decoration-none link-see-more label-black d-none d-lg-block"
        [ngClass]="{'d-none':showMoreResponsive}" (click)="goToUrl()">
        {{showMoreText}}
      </div>

    </span>
  </div>
</div>
