import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "@environments/environment";
import { EBrandAsset } from "@parameters/access/brand.parameter";
import { AppStoreService } from "@stores/app/app-store.service";
import { OrderTakerValidator } from "@validators/order-taker.validator";

/**
 * value: string => asset url
 * the url must have the string "[[brandAsset]]",
 * the name of the brand directory is going to be replaced here.
 */
/**
 *
 */

@Pipe({ name: "brandAsset" })
export class BrandAssetPipe implements PipeTransform {
  constructor(private _appStoreService: AppStoreService) {}
  transform(value: string): string {
    const brand =
      EBrandAsset[
        OrderTakerValidator.isOrderTakerEnv()
          ? this._appStoreService.brandAtention
          : environment.brand
      ];
    return value.replace("[[brandAsset]]", brand ?? "farmacias");
  }
}
