import { Injectable } from '@angular/core';
import { RegisterClientService } from 'src/app/shared/service/user-register/register-client.service';
import { UserRegisterRequest } from 'src/app/shared/models/register/user-register-request.model';

@Injectable()
export class RegisterClientImplementService {

  constructor(private registerClientService: RegisterClientService) { }

  public getUser(email = null, shouldPassInkaclubValidation = false, userInfoValidation = false) {
    return this.registerClientService.getUser$(email, shouldPassInkaclubValidation, userInfoValidation);
  }

  public updateUserLegacy(params: UserRegisterRequest) {
    return this.registerClientService.updateUserLegacy$(params);
  }

}
