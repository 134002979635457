import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";

import { AddressListStoreService } from "../address-stores/address-list-store.service";

import { ClientStoreService } from "@stores/client/client-store.service";
import { AddressItem, IAddressRequestParams } from "src/app/shared/models/address/address.model";
import { IPosition } from "src/app/shared/models/coverage-location/coverage-location.model";
import { IDeliveryInfoRequestParams } from "src/app/shared/models/delivery-info/delivery-info.model";
import { AddressClientService } from "src/app/shared/service/address/address-client.service";
import { DeliveryInfoClientService } from "src/app/shared/service/delivery-info/delivery-info-client.service";
import { UserAddressClientService } from "src/app/shared/service/user-address/user-address-client.service";
import { AppStoreService } from "src/app/shared/stores/app/app-store.service";
import { CoverageLocationStoreService } from "src/app/shared/stores/coverage-location/coverage-location-store.service";
import { OrderTakerValidator } from "src/app/shared/validators/order-taker.validator";
import { CoverageLocationImplementService } from "../../custom-agm-core/services/coverage-location-implement.service";
import { AddressManagerPersistanceService } from "./address-manager-persistance.service";
import { ShoppingCartRequestService } from "src/app/shared/service/shopping-cart/shopping-cart-request.service";
import { MatDialog } from '@angular/material/dialog';
import { ProductOutOfCoverageDialogComponent } from 'src/app/shared/components/product-out-of-coverage/product-out-of-coverage-dialog.component';

@Injectable({
  providedIn: "root"
})
export class AddressImplementService {
  constructor(
    private addressClient: AddressClientService,
    private userAddressClient: UserAddressClientService,
    private deliveryInfoClient: DeliveryInfoClientService,
    private addressListStore: AddressListStoreService,
    private appStore: AppStoreService,
    private coverageLocationImplement: CoverageLocationImplementService,
    private coverageLocationStore: CoverageLocationStoreService,
    private addressManager: AddressManagerPersistanceService,
    private _clientStore: ClientStoreService,
    private dialog: MatDialog,
  ) {}

  public getAddressNicknameList$() {
    return this.addressClient.getAddressNicknameList$();
  }

  public updatedSelectedAddress$(addressId: string) {
    return this.userAddressClient.updateSelectedUserAddress$(addressId).pipe(
      switchMap(() => {
        return this.getAddresses$().pipe(
          map((addressList) => {
            this.addressListStore.setAddressList(addressList);
            this.addressListStore.saveAddressListStorage(addressList);
            this.addressListStore.setSelectedAddress(addressList.find((address) => address.id === addressId) || addressList[0]);
            this.addressListStore.saveAddressStorage(addressList.find((address) => address.id === addressId) || addressList[0]);
            this.addressManager.setAddressToUpdate(this.addressListStore.getFavoriteAddress(addressList));
            return true;
          })
        );
      })
    );
  }

  public getAddresses$(): Observable<AddressItem[]> {
    return this.coverageLocationImplement.getValidatedCoverage$(this.appStore?.currentCoords ? this.appStore?.currentCoords : { latitude: 0, longitude: 0 }).pipe(
      switchMap((coverageLocationResponse) => {
        // RET3: Revisar esta integración para ser modificada para tomador y ecommerce

        this.coverageLocationStore.setCoverageLocationResponse(coverageLocationResponse);
        const { favorite, current } = coverageLocationResponse;
        if (OrderTakerValidator.isOrderTakerEnv()) {
          this.appStore.drugstoreStock = this.appStore.drugstoreStock ? this.appStore.drugstoreStock : favorite.drugstoreStockId;
          this.appStore.drugstoreCode = favorite ? favorite.fulfillmentCenterCode : "";
        } else {
          this.appStore.drugstoreStock = favorite?.drugstoreStockId || current.drugstoreStockId;
          this.appStore.drugstoreCode = favorite ? favorite.fulfillmentCenterCode : "";
        }
        return this.deliveryInfoClient.getDeliveryInfo$().pipe(
          map((deliveryInfoResponse) => {
            let addresses = [];
            if (OrderTakerValidator.isOrderTakerEnv()) {
              addresses = (this._clientStore?.addresses as AddressItem[]) ?? [];
            } else {
              addresses = deliveryInfoResponse.address;
            }
            return addresses;
          })
        );
      })
    );
  }

  public getCurrentAddresses$() {
    return this.deliveryInfoClient.getDeliveryInfo$().pipe(
      map((deliveryInfoResponse) => {
        const addresses = deliveryInfoResponse.address;
        if (addresses.length === 1 && !addresses[0].canContinue) {
          return [];
        } else {
          return addresses;
        }
      })
    );
  }

  public getAddressesWithParams$() {
    const params = {} as IDeliveryInfoRequestParams;
    return this.deliveryInfoClient.getDeliveryInfoWithParams$(params).pipe(
      map((response) => {
        const addresses = response.address;
        return addresses;
      })
    );
  }

  public createAddress$(params: IAddressRequestParams) {
    return this.addressClient.createAddress$(params).pipe(
      switchMap(() => {
        return this.getAddresses$().pipe(
          map((addressList) => {
            this.addressListStore.setAddressList(addressList);
            if (!OrderTakerValidator.isOrderTakerEnv()) {
              const favoriteAddress = this.addressListStore.getFavoriteAddress(addressList);
              this.addressManager.setAddressToUpdate(favoriteAddress);
              this.addressListStore.saveAddressListStorage(addressList);
              if (addressList.length === 1) {
                this.addressListStore.setSelectedAddress(favoriteAddress);
                this.addressListStore.saveAddressStorage(favoriteAddress);
              }
            }
            return true;
          })
        );
      })
    );
  }

  public updateAddress$(params: IAddressRequestParams) {
    return this.addressClient.updateAddress$(params).pipe(
      switchMap(() => {
        return this.getAddresses$().pipe(
          map((addressList) => {
            this.addressListStore.setAddressList(addressList);
            return true;
          })
        );
      })
    );
  }

  public deleteAddress$(addressId: string) {
    return this.addressClient.deleteAddress$(addressId);
  }

  public loadAddressListAndSelectDefaultAddress() {
    this.getAddresses$()
      .pipe(take(1))
      .subscribe((addressList) => {
        if (addressList.length === 1 && !addressList[0].canContinue) {
          this.addressListStore.setAddressList([]);
        } else {
          if (addressList.length > 0) {
            const selectedAddress = this.addressListStore.getFavoriteAddress(addressList);
            if (selectedAddress) {
              this.addressListStore.setAddressList(addressList);
            } else {
              const selectedAddressByDefault = addressList[0];
              this.updatedSelectedAddress$(selectedAddressByDefault.id)
                .pipe(take(1))
                .subscribe(() => {
                  this.loadAddressList();
                });
            }
          } else {
            this.addressListStore.setAddressList(addressList);
          }
        }
      });
  }

  public loadAddressListAndSelectDefaultAddress2$(): Observable<AddressItem[]> {
    return this.getAddresses$().pipe(
      switchMap((addressList) => {
        if (addressList.length === 1 && !addressList[0].canContinue) {
          return of([] as AddressItem[]);
        } else {
          if (addressList.length > 0) {
            const selectedAddress = this.addressListStore.getFavoriteAddress(addressList);
            if (selectedAddress) {
              return of(addressList);
            } else {
              const selectedAddressByDefault = addressList[0];
              return this.userAddressClient.updateSelectedUserAddress$(selectedAddressByDefault.id).pipe(
                switchMap(() => {
                  return this.getAddresses$();
                })
              );
            }
          } else {
            return of(addressList);
          }
        }
      })
    );
  }

  public loadAddressList() {
    return this.getAddresses$().pipe(
      take(1),
      tap((addressList) => {
        this.addressListStore.setAddressList(addressList);
        this.addressListStore.setSelectedAddress(this.addressListStore.getFavoriteAddress(addressList) || addressList[0]);
        if (!OrderTakerValidator.isOrderTakerEnv()) {
          this.addressListStore.saveAddressListStorage(addressList ?? []);
          this.addressListStore.saveAddressStorage(this.addressListStore.getFavoriteAddress(addressList) || addressList[0]);
        }
      })
    );
  }
}
