import { Injectable } from "@angular/core";
import firebase from "firebase/compat/app";
import { AppStoreService } from "../../stores/app/app-store.service";

import { AuthClientService } from "../../service/firebase/auth/auth-client.service";
import { BehaviorSubject } from "rxjs";
import { LocalStorageService } from "@service/local-storage/local-storage.service";
import { ALGOLIA_AUTHENTICATED_USER_TOKEN } from "src/app/core/modules/custom-instantsearch/constants/algolia-keys.constant";

@Injectable({
	providedIn: "root"
})
export class AuthImplementService {
	private authState: firebase.User;

	private authStateSubject: BehaviorSubject<firebase.User | null> = new BehaviorSubject<firebase.User | null>(null);


	constructor(private _authClientService: AuthClientService, private _appStoreService: AppStoreService,private localStorageService:LocalStorageService) {}

	sessionToken(auth) {
		auth.getIdToken(false).then((token) => (this._appStoreService.userToken = token));
	}

	get userAuthState(): any {
		return this.authState;
	}

	set userAuthState(authState) {
		this.authStateSubject.next(authState);
		this.authState = authState;
	}

	get authStateObservable(): BehaviorSubject<firebase.User | null> {
		return this.authStateSubject;
	}

	get authenticated(): boolean {
		return this.authState !== null;
	}

	get currentUser(): any {
		return this.authenticated ? this.authState : null;
	}

	get currentUserObservable() {
		return this._authClientService.currentUserObservable;
	}

	get currentUserId(): string {
		return this.authenticated ? this.authState.uid : "";
	}

	get currentUserAnonymous(): boolean {
		return this.authenticated ? this.authState.isAnonymous : false;
	}

	get currentUserDisplayName(): string {
		if (!this.authState) {
			return "Guest";
		} else if (this.currentUserAnonymous) {
			return "Anonymous";
		} else {
			return this.authState["displayName"] || "User without a Name";
		}
	}

	get currentUID(): any {
		return firebase.auth().currentUser?.uid;
	}

	userManage(promise: Promise<any>) {
		promise.catch((error) => console.log(error));
	}

	gmailLogin(email: string, password: string) {
		return this._authClientService.gmailLogin(email, password);
	}

	googleLogin() {
		return this._authClientService.googleLogin().catch((error) => {
			if (error.code === "auth/account-exists-with-different-credential") {
				// never happen
				// Ask user that there's an account with the same email and if want to link with that account.
				const googleCred = error.credential;
				return firebase
					.auth()
					.fetchSignInMethodsForEmail(error.email)
					.then((providers) => {
						if (providers[0].toString() === "facebook.com") {
							const facebookProvider = new firebase.auth.FacebookAuthProvider();
							facebookProvider.setCustomParameters({ login_hint: error.email });
							return firebase
								.auth()
								.signInWithPopup(facebookProvider)
								.then(function (result) {
									return result.user.linkWithCredential(googleCred);
								});
						}

						if (providers[0].toString() === "apple.com") {
							const appleProvider = new firebase.auth.OAuthProvider("apple.com");
							appleProvider.setCustomParameters({ login_hint: error.email });
							return firebase
								.auth()
								.signInWithPopup(appleProvider)
								.then(function (result) {
									return result.user.linkWithCredential(googleCred);
								});
						}
					});
			}
		});
	}

	facebookLogin() {
		return this._authClientService.facebookLogin().catch((error) => {
			if (error.code === "auth/account-exists-with-different-credential") {
				// Ask user that there's an account with the same email and if want to link it.
				const facebookCred = error.credential;
				return firebase
					.auth()
					.fetchSignInMethodsForEmail(error.email)
					.then((providers) => {
						if (providers[0].toString() === "google.com") {
							const googleProvider = new firebase.auth.GoogleAuthProvider();
							googleProvider.setCustomParameters({ login_hint: error.email });
							return firebase
								.auth()
								.signInWithPopup(googleProvider)
								.then(function (result) {
									return result.user.linkWithCredential(facebookCred);
								});
						}

						if (providers[0].toString() === "apple.com") {
							const appleProvider = new firebase.auth.OAuthProvider("apple.com");
							appleProvider.setCustomParameters({ login_hint: error.email });
							return firebase
								.auth()
								.signInWithPopup(appleProvider)
								.then(function (result) {
									return result.user.linkWithCredential(facebookCred);
								});
						}
					});
			}
		});
	}

	appleLogin() {
		return this._authClientService.appleLogin().catch((error) => {
			if (error.code === "auth/account-exists-with-different-credential") {
				// Ask user that there's an account with the same email and if want to link it.
				const apppleCred = error.credential;
				return firebase
					.auth()
					.fetchSignInMethodsForEmail(error.email)
					.then((providers) => {
						if (providers[0].toString() === "google.com") {
							const googleProvider = new firebase.auth.GoogleAuthProvider();
							googleProvider.setCustomParameters({ login_hint: error.email });
							return firebase
								.auth()
								.signInWithPopup(googleProvider)
								.then(function (result) {
									return result.user.linkWithCredential(apppleCred);
								});
						}

						if (providers[0].toString() === "facebook.com") {
							const facebookProvider = new firebase.auth.FacebookAuthProvider();
							facebookProvider.setCustomParameters({ login_hint: error.email });
							return firebase
								.auth()
								.signInWithPopup(facebookProvider)
								.then(function (result) {
									return result.user.linkWithCredential(apppleCred);
								});
						}
					});
			}
		});
	}

	emailAndPasswordSignUp(email: string, password: string) {
		return firebase.auth().createUserWithEmailAndPassword(email, password);
	}

	emailPasswordLogin(email: string, password: string) {
		return firebase.auth().signInWithEmailAndPassword(email, password);
	}

	twitterLogin() {
		return this._authClientService.twitterLogin();
	}

	anonymousLogin() {
		const promise = this._authClientService.anonymousLogin();
		this.userManage(promise);
		return promise;
	}

	emailSignUp(email: string, password: string) {
		const promise = this._authClientService.emailSignUp(email, password);
		this.userManage(promise);
		return promise;
	}

	emailLogin(email: string, password: string) {
		const promise = this._authClientService.emailLogin(email, password);
		this.userManage(promise);
		return promise;
	}

	resetPassword(email: string) {
		const promise = this._authClientService.resetPassword(email);
		this.userManage(promise);
		return promise;
	}

	clearItemsLogout(){
		this.localStorageService.deleteItem(ALGOLIA_AUTHENTICATED_USER_TOKEN);
	}

	signOut() {
		this.clearItemsLogout();
		this._authClientService.signOut().then(() => {
			window.location.reload();
		});
	}

	signOutRedirectHome() {
		this.clearItemsLogout();
		this._authClientService.signOut().then(() => {
			window.location.href = "/";
		});
	}

	async signOutNotRedirect() {
		this.clearItemsLogout();
		await this._authClientService.signOut().catch((err) => console.log(err));
		const authReponse = await this.anonymousLogin();
		this.userAuthState = authReponse.user;
	}

	redirectResult() {
		return this._authClientService.redirectResult();
	}
}
