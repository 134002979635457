import { isObject } from 'src/app/shared/helpers/objects-equal';

// REQUEST AND RESPONSE
export class UserPickUpRequest {
    fullName: string;
    email: string;
    identityDocumenType: number;
    identityDocumentNumber: string;
    phone: string;
}

export class IUserPickUpRequestParams {
    fullName: string;
    email: string;
    identityDocumenType: number;
    identityDocumentNumber: string;
    phone: string;
}

export interface IUserPickUpRequestResponse {
    id: number;
}

// USER PICKUP
export interface IUserPickUp {
    id: number;
    fullName: string;
    email: string;
    default: boolean;
    identityDocumenType: number;
    identityDocumentNumber: string;
    phone: string;
}

export class UserPickUp {
    public id: number;
    public fullName: string;
    public email: string;
    public default: boolean;
    public identityDocumenType: number;
    public identityDocumentNumber: string;
    public phone: string;
    public value: string;

    constructor(userPickUp: IUserPickUp) {
        const currentValue = isObject(userPickUp) ? userPickUp : {} as IUserPickUp;
        this.id = currentValue.id || 0;
        this.fullName = currentValue.fullName || '';
        this.email = currentValue.email || '';
        this.default = currentValue.default || false;
        this.identityDocumenType = currentValue.identityDocumenType || 1;
        this.identityDocumentNumber = currentValue.identityDocumentNumber || '';
        this.phone = currentValue.phone || '';
    }
}

// RESPONSE

export interface IUserPickUpResponse {
    peopleForStorePickup: IUserPickUp[];
}

export class UserPickUpResponse {
    public peopleForStorePickup: UserPickUp[];

    constructor(response: IUserPickUpResponse) {
        const currentValue = isObject(response) ? response : {} as IUserPickUpResponse;
        const peopleForStorePickup = Array.isArray(currentValue.peopleForStorePickup) ? currentValue.peopleForStorePickup : [];
        this.peopleForStorePickup = peopleForStorePickup.map(user => new UserPickUp(user));
    }
}
