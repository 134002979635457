import { CdkStepper } from "@angular/cdk/stepper";
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MainLoaderService } from "@helpers/main-loader.service";
import { AuthImplementService } from "@implements/auth/auth-implement.service";
import {
  AddressItem,
  IAddressRequestParams,
} from "@models/address/address.model";
import { AppStoreService } from "@stores/app/app-store.service";
import { Subject, throwError } from "rxjs";
import { catchError, finalize, take, takeUntil, tap } from "rxjs/operators";
import { DeliveryInfoImplementService } from "src/app/business/checkout/checkout-services/delivery-info-implement.service";
import { UpdateAnonymousImplementService } from "src/app/business/checkout/checkout-services/update-anonymous-implement.service";
import { DELIVERY_TYPE } from "src/app/business/order/parameters/enums";
import { AddressDispatchManagerPresenter } from "src/app/core/modules/address-dispatch-manager/presenter/address-dispatch-manager.presenter";
import { AddressHttpService } from "src/app/core/modules/address-manager/address-services/address-http.service";
import { AddressManagerPersistanceService } from "src/app/core/modules/address-manager/address-services/address-manager-persistance.service";
import { NewAddressManagerService } from "src/app/core/modules/address-manager/address-services/new-address-manager/new-address-manager.service";
import { FLOW_SEARCH_ADDRESS_TYPE } from "src/app/core/modules/address-manager/parameters/modal.constant";
import { FindClientService } from "src/app/core/modules/find-client-manager/services/find-client.service";
import { HereMapPlacesService } from "src/app/core/modules/here-maps/services/here-map-places.service";
import { AppStoreSubscription } from "src/app/core/store/subscription/app.store.subscription";
import { UpdateAnonymous } from "src/app/shared/models/update-anonymous/update-anonymous.model";
import { AddressClientService } from "src/app/shared/service/address/address-client.service";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { ClientStoreService } from "src/app/shared/stores/client/client-store.service";
import { StepperOfficeFormService } from "../../forms/stepper-office-form.service";
import { SelectDataStoreService } from "../../store/select-data-store.service";
import { LS_ADDRESS_SELECTED } from "@service/local-storage/constants/address.constant";
import { DocumentsImplementService } from "@implements/documents/documents-implement.service";
import { PricePurseService } from "@stores/price-purse/price-purse-store.store";
import { SelectItem } from "@models/documents-type/document-type.model";
import { DOCUMENT_TYPES } from "src/app/business/new-register/constants/form-type-information.constant";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { environment } from "@environments/environment";
import { OrderTakerValidator } from "@validators/order-taker.validator";

@Component({
  selector: "fp-office",
  templateUrl: "./office.component.html",
  styleUrls: ["./office.component.sass"],
  providers: [FindClientService],
})
export class OfficeComponent implements OnInit, AfterViewInit {
  @ViewChild("stepper", { static: true }) stepper: CdkStepper;
    totalSteps = 0;
  stepSelected = 1;
  buttonDisabled = false;
  public street: string;
  addressSelected:AddressItem;
  private readonly unsubscribe$ = new Subject<void>();
  originalCoords: any;

  constructor(
    public _stepperForm: StepperOfficeFormService,
    private officeModalRef: MatDialogRef<OfficeComponent>,
    public _clientStore: ClientStoreService,
    private _findClientService: FindClientService,
    private _addressManager: AddressManagerPersistanceService,
    public _selectDataStore: SelectDataStoreService,
    private _addressHttp: AddressHttpService,
    public _presenter: AddressDispatchManagerPresenter,
    private _addressClientService: AddressClientService,
    private _updateAnonymousImplementService: UpdateAnonymousImplementService,
    private appStoreSubscription: AppStoreSubscription,
    private hereMapPlacesService: HereMapPlacesService,
    private newAddressManager: NewAddressManagerService,
    private authImplementService: AuthImplementService,
    public addressItemsForm: StepperOfficeFormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public retValidateCartService: RetValidateCartService,
    private appStoreService: AppStoreService,
    private deliveryInfoImplement: DeliveryInfoImplementService,
    private loadingService: MainLoaderService,
    private _documentsImplementService:DocumentsImplementService,
    private _pricePurseService:PricePurseService
  ) {}

  ngOnInit(): void {
    this._addressManager.sendAddressItem$.subscribe(
      (res) => {
        if (res) {
          this.addressSelected = res;
          this.addressSelected ? this.buttonDisabled = true : this.buttonDisabled = false;
          // Si dirección no tiene district
          if (this.addressSelected?.district == "") {
            this.getAddressComplete();
          }
          this.street = `${res ? res.street : ""}`
        }
      }
    ); 
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.totalSteps = this.stepper.steps.length;
    }, 100);
  }

  onNext() {
    const {client} = this._clientStore;
    let userId = this.authImplementService.currentUID;
    if (!this.validateFields()) {
      this.newAddressManager.showNewAddressModal(FLOW_SEARCH_ADDRESS_TYPE.EDIT_ADDRESS);
    }else{
      const retOrRad = this.retValidateCartService.isRetOrRadTomadorProcess ? "RAD" : "RET";
      this.appStoreService.setStorageItem("isRetOrRad", this.data?.retOrRad ? this.data?.retOrRad : retOrRad);
      this.stepper.next();
      if (this.stepSelected === 1 && this.addressSelected) {
        this._addressHttp
          .saveAddressFavorite$(
            client?.codClient || userId,
            this.addressSelected.id
          )
          .subscribe(() => {});
      }
      this.stepSelected = this.stepper.selectedIndex + 1;
    }
  }
  onPrevious() {
    this.stepper.previous();
    this.stepSelected = this.stepper.selectedIndex + 1;
  }

  onSave() {
    const {client} = this._clientStore;
      const parameters = {
        docType:client.idIdentityDocument,
        docNumber: client.identityDocumentNumber,
      };
      this._documentsImplementService.validationDocumentMonedero$(parameters).subscribe(Data =>{
        const { flagMonedero } = Data
        this._pricePurseService.setpricePurseStatus(flagMonedero)
      })

    this.originalCoords = JSON.parse(this.appStoreService.getStorageItem('coordTomador'));
    this.appStoreService.setStorageItem('coordTomadorSelected', JSON.stringify(this.originalCoords));
    if(this.retValidateCartService.isRetOrRadTomadorProcess){
      this.retValidateCartService.storeProcessType("RAD");
      this._selectDataStore.saveAll("RAD");
      this.getDeliveryInfo().subscribe(res => {
      });
      this._addressManager.saveAddressToUpdate();
      this.updateAnonymousInformation();

      const currentAddress: AddressItem =
        this._addressManager.currentAddressUpdate;
      const requestAddressEcommerce: IAddressRequestParams = {
        number: currentAddress.number,
        country: "Perú",
        district: currentAddress.district,
        apartment: "",
        street: currentAddress.street,
        city: "",
        latitude: currentAddress.latitude,
        longitude: currentAddress.longitude,
        state: "",
        name: currentAddress.name,
        notes: currentAddress.reference,
        id: null,
      };
      this._presenter.isLoading = true;
      this._presenter.isLoadingDelivery = true;
      this._addressClientService
        .createAddress$(requestAddressEcommerce)
        .pipe(
          take(1),
          catchError((er) => throwError(er)),
          finalize(() => {
            setTimeout(() => {
              this._presenter.isLoading = false;
              this._presenter.isLoadingDelivery = false;
              this._selectDataStore.clearStates();
              this.officeModalRef.close();
              this._findClientService.closeModal();
            }, 100);
            this.createAddress();
          })
        )
        .subscribe(() => {
          let { client } = this._clientStore;
          client.addresses.map((r) => {
            if (r.id == currentAddress.id) r.favorite = true;
            else r.favorite = false;
            return r;
          });
          this._clientStore.setClient$(client);
          this._clientStore.saveClient$();
        });
    }else{
      this.getDeliveryInfo().subscribe(res => {
      });
      this._addressManager.saveAddressToUpdate();
      this.updateAnonymousInformation();
      this.retValidateCartService.storeProcessType("RET");
      this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "false");
      this._presenter.isLoading = false;
      this._presenter.isLoadingDelivery = false;
      this._selectDataStore.saveAll("RET");
      this._clientStore.setClient$(client);
      this._clientStore.saveClient$();
      this._presenter.doSaveStore();
      this.getDeliveryInfoRET().subscribe(res => {
        this.officeModalRef.close();
        this._findClientService.closeModal();
        window.location.reload();
      });
    }
  }

  updateAnonymousInformation() {
    const params = new UpdateAnonymous();
    let userId = this.authImplementService.currentUID;
    const { client } = this._clientStore;
    params.codClientCall = client?.codClient || userId;
    params.email = client?.email || "";
    params.identityDocumentType = client?.idIdentityDocument || "";
    params.identityDocumentNumber = client?.identityDocumentNumber || "";
    params.name = client?.name || "";
    params.paternalLastName = client?.lastNamePat || "";
    params.maternalLastName = client?.lastNameMat || "";
    params.lastName = `${client?.lastNamePat || ""} ${client?.lastNameMat || ""}`;
    params.phone = client?.phone || "";
    this._updateAnonymousImplementService
      .updateAnonymousInformation$(params)
      .pipe(take(1))
      .subscribe();
  }

  createAddress() {
    this._presenter.onSaveAddress();
  }



  getAddressComplete(){
    this.appStoreSubscription.selectNewAddressModal$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((addresHere) => {
      if (addresHere.hereAddressMarked) {
        this.hereMapPlacesService
        .getDetailedAddress$(addresHere.hereAddressMarked.id)
        .subscribe((detailedAddress) => {
          const {address} = detailedAddress;
          //Agregando distrito
          this.addressSelected.district = address.city;
          this._addressManager.setAddressToUpdate(this.addressSelected);
        });
      }
    });
  }

  private validateFields():boolean{
    if(!this.addressSelected.hasOwnProperty('latitude') || this.addressSelected.latitude == null || this.addressSelected.latitude == -1) return false;
    if(!this.addressSelected.hasOwnProperty('longitude') || this.addressSelected.longitude == null || this.addressSelected.longitude == -1) return false;
    if(!this.addressSelected.hasOwnProperty('name') || this.addressSelected.name == null || this.addressSelected.name == "") return false;
    return true;
  }

  onClose() {
    this._selectDataStore.clearStates();
    if (this._addressManager.addressToUpdate) {
      this._addressManager.setAddressToUpdate(
        this._addressManager.addressToUpdate ?? null
      );
    }
    this.officeModalRef.close();
  }

   getDeliveryInfo() {
    return this.deliveryInfoImplement
    .getDeliveryInfoWithoutParams$()
    .pipe(take(1), tap(((deliveryInfo) => {
      this.deliveryInfoImplement.currentDeliveryInfoWithoutParams = deliveryInfo;
      this.loadingService.isLoaded = false
    })))
  }

  getDeliveryInfoRET() {
    const store = this._presenter.getStore();
    return this.deliveryInfoImplement.getDeliveryInfoForRETOrderTaker$(store.localCode, DELIVERY_TYPE.RET).pipe(take(1), tap(((deliveryInfo) => {
      this.deliveryInfoImplement.currentDeliveryInfoWithoutParams = deliveryInfo;
      this.loadingService.isLoaded = false
    })))
  }
}
