import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fp-select-time-type-radio-button',
  templateUrl: './select-time-type-radio-button.component.html',
  styleUrls: ['./select-time-type-radio-button.component.sass']
})
export class SelectTimeTypeRadioButtonComponent {

  @Input() service: any;

  @Input() showTitleBlack = false;
  @Input() showDetails = false;
  @Input() selected = false;
  @Input() disabled = false;

  @Output() selectRadioOption = new EventEmitter();

  constructor() { }

  public selectOption() {
    if (!this.selected) {
      this.selectRadioOption.emit();
    }
  }
}
