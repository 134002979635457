<div class="row">
  <div
    [ngClass]="{'col-5': flkText && place != 'categories', 'col': !flkText, 'col-6': flkText && place === 'categories'}">
    <span [ngClass]="{'text-strike--1': (orderTaker && isPursePrice && flkTextStrike) || (_userInfoStore.isLogged && flkTextStrike), 'color-price': flkText}"
      class="mx-0 mb-1 label label-xl{{fontSize}} label-xs{{fontSize}}">{{regularPrice | currency: 'S/ '}}</span>
  </div>
  <div *ngIf="flkText" class="col">
    <span class="color-price mx-0 mb-1 label label-xl{{fontSizeText}} label-xs{{fontSizeText}}">Precio regular</span>
  </div>
</div>
