import { Injectable } from '@angular/core';
import { AuthImplementService } from '@implements/auth/auth-implement.service';
import { AppStoreService } from '@stores/app/app-store.service';
import { ShoppingListStoreService } from '@stores/shopping-list/shopping-list-store.service';
import { combineLatest, of, Subject } from 'rxjs';
import { take, delay, map, switchMap } from 'rxjs/operators';
import { AddressItem } from 'src/app/shared/models/address/address.model';
import { INearestDrugstoresRequest, NearestDrugstore } from 'src/app/shared/models/nearest-drugstores/nearest-drugstores.model';
import { ShoppingCartRequestService } from 'src/app/shared/service/shopping-cart/shopping-cart-request.service';
import { AddressListStoreService } from '../../address-manager/address-stores/address-list-store.service';
import { IModalStatus } from '../enums/modal-status.enum';
import { ReferenceAddressFormService } from '../pick-up-forms/reference-address-form.service';
import { NearestDrugstoresImplementService } from '../pick-up-implements/nearest-drugstores-implement.service';
import { PickUpConfigStoreService } from '../pick-up-stores/pick-up-config-store.service';
import { StoresForPickUpStoreService } from '../pick-up-stores/stores-for-pick-up-store.service';
import { IUserGeolocation, UserGeolocationStoreService } from '../pick-up-stores/user-geolocation-store.service';
import { UserGeolocationHelperService } from './user-geolocation-helper.service';

export interface IFirstLoadDrugstores {
  modalStatus?: IModalStatus;
  drugstores?: NearestDrugstore[];
  temporalSelectedDrugstore?: NearestDrugstore;
}

@Injectable()
export class FirstLoadHelperService {

  private _statusModalSubject = new Subject<IModalStatus>();
  public statusModal$ = this._statusModalSubject.asObservable();
  product: any;
  change: boolean = false;

  constructor(
    private userGeolocationHelper: UserGeolocationHelperService,
    private addressListStore: AddressListStoreService,
    private userGeolocationStore: UserGeolocationStoreService,
    private nearestDrugstoresImplement: NearestDrugstoresImplementService,
    private shoppingListRequest: ShoppingCartRequestService,
    private referenceAddressForm: ReferenceAddressFormService,
    public pickUpConfig: PickUpConfigStoreService,
    private storesForPickUpStore: StoresForPickUpStoreService,
    private appStore: AppStoreService,
    private authImplementService: AuthImplementService,
    private _shoppingListStore: ShoppingListStoreService,
  ) { }

  public setStatusModal(state: IModalStatus) {
    this._statusModalSubject.next(state);
  }

  public getInitialDrugstores$(product?, changes?, prd?) {
    this.product = product;
    this.change = changes;
    const addresses = this.addressListStore.addressList;
    if (addresses.length) {
      const selectedAddress = addresses.find(address => address.favorite);
      const paramsForAddress = this.getParamsForAddress(selectedAddress, prd);
      return this.nearestDrugstoresImplement.getDrugstores$(paramsForAddress)
        .pipe(delay(200))
        .pipe(map(drugstores => {
          return {
            drugstores,
            modalStatus: drugstores.length ? IModalStatus.OK : IModalStatus.EMPTY,
            temporalSelectedDrugstore: this.getSelectedTemporalDrugstore(drugstores),
          } as IFirstLoadDrugstores;
        }));
    } else {
      return this.userGeolocationHelper.isPendingToAskGeolocation$()
        .pipe(delay(200))
        .pipe(map((isPending) => {
          const drugstores = [];
          return {
            drugstores,
            modalStatus: isPending ? IModalStatus.IS_PENDING : IModalStatus.LOADING,
            temporalSelectedDrugstore: this.getSelectedTemporalDrugstore(drugstores),
          } as IFirstLoadDrugstores;
        }));
    }
  }

  public getDrugstoresOfGeolocationFlow$(prd?) {
    return this.userGeolocationHelper.getGeolocationPermissions$()
      .pipe(switchMap(permission => {
        if (permission.status === 'ok') {
          const lat = permission.coords.latitude;
          const lng = permission.coords.longitude;
          const loadMap$ = this.userGeolocationHelper.loadGeolocationAddress$(permission);
          const paramsForGeo = this.getParamsForGeo(permission.coords, prd);
          const drugstores$ = this.nearestDrugstoresImplement.getDrugstores$(paramsForGeo);
          return combineLatest([drugstores$, loadMap$])
            .pipe(map(([drugstores, loadMap]) => {
              return {
                drugstores,
                modalStatus: drugstores.length ? IModalStatus.OK : IModalStatus.EMPTY,
                temporalSelectedDrugstore: this.getSelectedTemporalDrugstore(drugstores),
              } as IFirstLoadDrugstores;
            }));
        } else {
          const userGeolocation = { permissions: permission } as IUserGeolocation;
          this.userGeolocationStore.setUserGeolocation(userGeolocation);
          const referenceAddress = this.referenceAddressForm.referenceAddressControl.value;
          const MIN_CHARACTERS = this.pickUpConfig.config.minimunCharactersForPredictiveAddresses;
          const modalStatus = (typeof referenceAddress === 'string' && referenceAddress.length >= MIN_CHARACTERS) ? IModalStatus.NO_ONE : IModalStatus.EMPTY;
          return of({
            drugstores: [],
            modalStatus,
            temporalSelectedDrugstore: this.getSelectedTemporalDrugstore([]),
          } as IFirstLoadDrugstores);
        }
      }));
  }

  private getVerifiedSelectedDrugstore(drugstores: NearestDrugstore[]) {
    const { selectedDrugstore } = this.storesForPickUpStore;
    if (selectedDrugstore.id) {
      const lastSelectedDrugstoreId = selectedDrugstore.id;
      const temporalSelectedDrugstore = drugstores.find(d => d.id === lastSelectedDrugstoreId);
      return temporalSelectedDrugstore ? temporalSelectedDrugstore : drugstores[0];
    } else {
      return drugstores[0];
    }
  }

  get product4Shopping() {
    const {
      id: productId,
      presentationIdSelected
    } = this.product

    return {
      productId,
      presentationIdSelected,
      quantity: 1
    }
  }

  productIsInShopping(shoppingCart) {
    const {
      productId
    } = this.product4Shopping;
    return !shoppingCart.find((p: any) => p.productId === productId) ? [...shoppingCart, this.product4Shopping] : shoppingCart
  }

  private getParamsForGeo(coords: GeolocationCoordinates, prd?) {
    const shoppingCart = this.getCurrentCart(prd);
    const productInShopping = this.product?.id ? this.productIsInShopping(shoppingCart) : shoppingCart;
    const shoppingCartParam = this.change ? shoppingCart : productInShopping;
    return {
      companyCode: this.appStore.companyCode,
      latitude: coords.latitude,
      longitude: coords.longitude,
      shoppingCart: shoppingCartParam,
      userId: this.authImplementService.currentUID
    } as INearestDrugstoresRequest;
  }

  private getSelectedTemporalDrugstore(drugstores: NearestDrugstore[]) {
    if (drugstores.length === 0) {
      return {} as NearestDrugstore;
    }
    const foundDefaultDrugstore = drugstores.find(d => d.defaultDrugstore);
    const defaultDrugstore = foundDefaultDrugstore ? foundDefaultDrugstore : drugstores[0];
    return defaultDrugstore;
  }

  private getParamsForAddress(selectedAddress: AddressItem, prd) {
    const shoppingCart = this.getCurrentCart(prd);
    const productInShopping = this.product?.id ? this.productIsInShopping(shoppingCart) : shoppingCart;
    const shoppingCartParam = this.change ? shoppingCart : productInShopping;
    return {
      latitude: selectedAddress.latitude,
      longitude: selectedAddress.longitude,
      shoppingCart:  shoppingCartParam,
      companyCode: this.appStore.companyCode,
      userId: this.authImplementService.currentUID
    } as INearestDrugstoresRequest;
  }

  getCurrentCart(prd) {
    const intencionProducts = [{"productId": prd,"presentationIdSelected": 1,"quantity": 1,"sellerId": "","sellerName": ""}];
    const shoppingCartListLocal = this._shoppingListStore.getLocalStorageShoppingCartList() || [];
    const shoppingCartList = prd ? shoppingCartListLocal.concat(intencionProducts) : shoppingCartListLocal;
    return shoppingCartList;
  }
}
