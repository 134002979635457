import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import "firebase/auth";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { delay, map, switchMap, tap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { GenericService } from "../../../shared/service/generic/generic.service";
import {
  ApiToken,
  KeyToken,
  KToken,
} from "../../savings-wallet/models/registration-user.model";
import { CONSTANTS, END_POINTS } from "../constants/service.constants";
import { Coupon } from "../models/coupon.model";
import { GenericApiResponse } from "../models/generic-response.interface";

@Injectable({
  providedIn: "root",
})
export class CouponService {
  coupons: BehaviorSubject<GenericApiResponse> = new BehaviorSubject(null);
  reloadCoupons: Subject<GenericApiResponse> = new Subject();
  userSubject = new Subject<any[]>();
  messageSubject = new Subject<any>();
  access: KeyToken;

  constructor(public http: HttpClient, private genericService: GenericService) {
    this.couponsChecks$.subscribe(
      (couponchecks) => (this._couponchecks = couponchecks)
    );
  }

  private _couponchecks: string[];
  private _couponchecksSubject = new BehaviorSubject<string[]>([]);
  public couponsChecks$ = this._couponchecksSubject.asObservable();

  public get couponsChecks(): string[] {
    return this._couponchecks;
  }

  /*   getCoupons(requestBody: any, access): Observable<GenericApiResponse<Coupon[]>> {
    const limitAvailableStatus = CONSTANTS.COUPONS_LIMIT;
    const body = { ...requestBody, limitAvailableStatus };
    return this.http.get<GenericApiResponse>('../../assets/mockup/coupons.json');
  }  */

  getCouponM(request): Observable<any> {
    const urlb = `${environment.urlMock}` + "/coupons";
    return this.genericService.genericGet(urlb, request, null);
  }

  getCouponById(request): Observable<GenericApiResponse> {
    /*  return this.http.post<GenericApiResponse>(END_POINTS.POST_COUPON, requestBody)
       .pipe(
         switchMap(res => res.error ? throwError(res) : of(res))
       );  */

    return this.genericService.genericPostWithoutSecurityHeaders(
      END_POINTS.POST_COUPON,
      request,
      null,
      null
    );
  }
  /* getCouponById(request, access): Observable<GenericApiResponse> {
    let headers = new HttpHeaders();
    headers = headers
      .set('x-access-token', access)
      .set('content-type', 'application/json');

    //return this.genericService.genericPostWithoutSecurityHeaders(END_POINTS.POST_COUPON, request, null, headers);
    return this.http.get<GenericApiResponse>('../../assets/mockup/coupons_detalle.json');
  } */

  /* getCouponById(request, access): Observable<GenericApiResponse> {
    let headers = new HttpHeaders();
    headers = headers
      .set('x-access-token', access)
      .set('content-type', 'application/json');

    //return this.genericService.genericPostWithoutSecurityHeaders(END_POINTS.POST_COUPON, request, null, headers);
    return this.http.get<GenericApiResponse>('../../assets/mockup/coupons_detalle.json');
  } */

  getCoupons(request): Observable<any> {
    return this.genericService.genericPostWithoutSecurityHeaders(
      END_POINTS.POST_COUPONS,
      request,
      null,
      null
    );
  }

  getCouponsMock(request, access): Observable<GenericApiResponse<Coupon[]>> {
    return this.http.get<GenericApiResponse>(
      "https://demo7545010.mockable.io/coupons"
    );
  }

  enableCoupon(request): Observable<any> {
    return this.genericService.genericPostWithoutSecurityHeaders(
      END_POINTS.POST_ENABLE_COUPON,
      request,
      null,
      null
    );
  }

  public getApiKey(param): Observable<KeyToken> {
    const editedFirebase = environment.firebase2.apiKey;
    const params = new HttpParams().set("key", String(editedFirebase));

    const refresh_token = `${environment.keyCupon}`;
    const body = new ApiToken({
      grant_type: "refresh_token",
      refresh_token: refresh_token,
    });

    const urlb = `${environment.urlToken}`;
    return this.genericService
      .genericPostWithoutSecurityHeaders<KToken>(urlb, body, params, null)
      .pipe(
        map((response) => {
          const keyToken = new KeyToken(response);
          return keyToken;
        })
      );
  }

  getCouponsDetail(
    requestBody: any
  ): Observable<GenericApiResponse<Coupon[]>> {
    const limitAvailableStatus = CONSTANTS.COUPONS_LIMIT;
    const body = { ...requestBody, limitAvailableStatus };

    if (this.coupons.value) {
      return of(this.coupons.value);
    }
    // return this.http.post<GenericApiResponse>(END_POINTS.POST_COUPONS, body)
    return this.genericService
      .genericPostWithoutSecurityHeaders<GenericApiResponse>(
        END_POINTS.POST_COUPONS,
        body,
        null,
        null
      )
      .pipe(
        switchMap((res) => (res.error ? throwError(res) : of(res))),
        tap((res) => this.coupons.next(res))
      );
  }

  postRedirectEcommerce(requestBody: any) {
    return this.http
      .post<GenericApiResponse>(
        `${END_POINTS.POST_AUDIT_USER}/redirect/ecommerce`,
        requestBody
      )
      .pipe(
        tap((response) => {
          return response;
        })
      );
  }

  removeStoredCouponsCheck() {
    const updatedCoupons = [];
    this._couponchecksSubject.next(updatedCoupons);
  }

  updateFavourite(coupon: Coupon) {
    return of("").pipe(
      delay(1000),
      tap(() => {
        const updatedCoupons = this.coupons.value.data.map((item: Coupon) => {
          if (item.barCode === coupon.barCode) {
            return { ...item, isFavourite: !coupon.isFavourite };
          }
          return item;
        });
        this.coupons.next({ ...this.coupons.value, data: updatedCoupons });
      }),
      tap(() => setTimeout(() => this.reloadCoupons.next(), 500))
    );
  }

  updateRemoveFavourite(requestBody: any): Observable<GenericApiResponse> {
    return this.http
      .delete<GenericApiResponse>(
        `${END_POINTS.POST_COUPON}/favorite/${requestBody.id}`
      )
      .pipe(
        delay(1000),
        tap(() => {
          this.getStateCoupon(false, requestBody.id);
        }),
        tap(() => setTimeout(() => this.reloadCoupons.next(), 500))
      );
  }

  getStateCoupon(state: boolean, id: string) {
    const updatedCoupons = this.coupons.value.data.map((item: Coupon) => {
      if (item.barCode === id) {
        return { ...item, isFavourite: state };
      }
      return item;
    });
    this.coupons.next({ ...this.coupons.value, data: updatedCoupons });
  }

  updateAddFavourite(requestBody: any) {
    return this.http
      .post<GenericApiResponse>(
        `${END_POINTS.POST_COUPON}/favorite`,
        requestBody
      )
      .pipe(
        delay(1000),
        tap(() => {
          this.getStateCoupon(true, requestBody.id);
        }),
        tap(() => setTimeout(() => this.reloadCoupons.next(), 500))
      );
  }

  getCouponsSelected(couponCheck, couponCheckValue) {
    return of("").pipe(
      tap(() => {
        let updatedCoupons = [...this.couponsChecks];

        if (couponCheck) {
          updatedCoupons.push(couponCheckValue);
        } else {
          updatedCoupons = updatedCoupons.filter((item) => {
            return item !== couponCheckValue;
          });
        }
        this._couponchecksSubject.next(updatedCoupons);
      })
    );
  }

  getCouponsSearchTracking(data: any) {
    return (
      this.genericService
        .genericPostWithoutSecurityHeaders(
          `${END_POINTS.POST_AUDIT_USER}/couponSearch`,
          data,
          null,
          null
        )
        // return this.http.post<any>(`${END_POINTS.POST_AUDIT_USER}/couponSearch`, data)
        .pipe(
          tap((response) => {
            return response;
          })
        )
    );
  }

  getCouponsListSearch(searchWord, requestBody: any): Observable<any> {
    return (
      this.genericService
        .genericGetWithoutSecurity(
          `${END_POINTS.GET_CAMPAIGNS}/${requestBody}?searchWord=${searchWord}`,
          null,
          null
        )
        // return this.http.get<any>(`${END_POINTS.GET_CAMPAIGNS}/${requestBody}?searchWord=${searchWord}`)
        .pipe(
          tap((response) => {
            return response;
          })
        )
    );
  }
}
