export enum CART_CODE {
  SUCCESSFULL_CODE = '1',
  STOCK_ERROR_CODE = '2',
  COVERAGE_ERROR_CODE = '3',
  SCHEDULE_ERROR_CODE = '4',
  CONTINUE_ERROR_CODE = '5',
  CART_VERIFICATION_ERROR_CODE = '7',
  RET_UNAVAILABLE_DRUGSTORE_ERROR_CODE = '11',
  RET_SCHEDULE_ERROR_CODE = '10',
  CAPACITY_ERROR_CODE = '8',
  SERVICE_ERROR_CODE = '9',
  HOUR_ERROR_CODE = '12',
  DEFAULT_CODE = '666',
  ERROR_GET_PRODUCT = '22',
  SECURITY_TRANSACTION_CODE = '28',
  ERROR_UPDATE_AMOUNT = '30',
  SKU_VISIBLE_CODE = '33',
  DUPLICATE_PRODUCTS = '34',
  ONLINE_PAYMENT_RET ="35"
}

export const CART_CODES = [
  CART_CODE.SUCCESSFULL_CODE as string,
  CART_CODE.STOCK_ERROR_CODE as string,
  CART_CODE.COVERAGE_ERROR_CODE as string,
  CART_CODE.SCHEDULE_ERROR_CODE as string,
  CART_CODE.CAPACITY_ERROR_CODE as string,
  CART_CODE.SERVICE_ERROR_CODE as string,
  CART_CODE.HOUR_ERROR_CODE as string,
  CART_CODE.CONTINUE_ERROR_CODE as string,
  CART_CODE.CART_VERIFICATION_ERROR_CODE as string,
  CART_CODE.DEFAULT_CODE as string,
  CART_CODE.RET_UNAVAILABLE_DRUGSTORE_ERROR_CODE as string,
  CART_CODE.RET_SCHEDULE_ERROR_CODE as string,
  CART_CODE.ERROR_GET_PRODUCT as string,
  CART_CODE.ERROR_UPDATE_AMOUNT as string,
  CART_CODE.SKU_VISIBLE_CODE as string,
  CART_CODE.DUPLICATE_PRODUCTS as string,
  CART_CODE.ONLINE_PAYMENT_RET as string
];

export const FINISH_CONFIRMATION_MESSAGE_TOMADOR = "El pedido ha sido realizado satisfactoriamente. Le notificaremos al cliente cuando esté programado";

export const FINISH_CONFIRMATION_MESSAGE = 'Tu pedido ha sido realizado satisfactoriamente. Te notificaremos cuando esté <strong>Programado</strong>'

export enum CART_CODE_ACTION {
  SKU_VISIBLE_CODE = '33',
  DUPLICATE_PRODUCTS = '34',
  ONLINE_PAYMENT_RET = '35',
}

export const CART_CODES_ACTION = [
  CART_CODE.SKU_VISIBLE_CODE as string,
  CART_CODE.DUPLICATE_PRODUCTS as string,
  CART_CODE.ONLINE_PAYMENT_RET as string
];