<fp-static-landing-container-dialog
(closeContainer)="closeDialog()">
  <fp-static-landing-header
    [loader]="loader"
    [headerTitle]="privacyPolicy?.title"
    [headerImage]="privacyPolicy?.image">
  </fp-static-landing-header>

  <fp-static-landing-content
    [loader]="loader"
    class="privacy-policy-content"
    [content]="privacyPolicy?.content">
  </fp-static-landing-content>

  <fp-static-landing-expansion-list
    [loader]="loader"
    class="privacy-policy-expansion-list"
    [expansionList]="privacyPolicy?.items">

  </fp-static-landing-expansion-list>
</fp-static-landing-container-dialog>
