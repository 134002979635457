import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "fp-card-drug-store",
  templateUrl: "./card-drug-store.component.html",
  styleUrls: ["./card-drug-store.component.sass"],
})
export class CardDrugStoreComponent implements OnInit {
  @Input()
  notFound: boolean = false;
  @Input()
  name: string;
  @Input()
  address: string;
  @Input()
  icon: string;
  @Input()
  action: boolean = false;
  @Input()
  isDisabled: boolean = false;
  @Input()
  isLoading: boolean = false;
  @Output()
  change = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  onChange() {
    this.change.emit();
  }
}
