import { ProductPresentations, ProductPresentation, ProductQuantityLimit, EProductPresentationStatus } from '../../../core/modules/product-card/models/product-presentation.model';
import {IProductDetail} from './product-detail.interface';
import {EProductPresentation} from '../../../core/modules/product-card/models/product-presentation.enum';
import { RetValidateCartService } from 'src/app/shared/service/cart/ret-validate-cart.service';
import { RET3_RAD_STORAGE_KEY } from '../../checkout/parameters/global-config';
import { LS_RAD_OR_RET } from '@service/local-storage/constants/address.constant';


class UnitProductDetailPresentation extends ProductPresentation {
  constructor(productDetail: IProductDetail, private isRad: boolean) {
    super();
    this.fractionated = true;
    this.id = productDetail.fractionatedPresentationId || EProductPresentation.unit;

    const currentStock = this.isRad ? productDetail.fractionalStock : productDetail.fractionalRetStock
    this.stock = currentStock ? currentStock : 1;

    this.maxUnitSale = productDetail.maxUnitSaleFractionated || 0;
    this.price = productDetail.fractionatedPrice || 0;
    this.description = productDetail.fractionatedText || '';
    this.presentation = productDetail.fractionatedForm || '';
    this.quantityLimit = new ProductQuantityLimit(this).quantityLimit || 0;
    this.regularPrice = productDetail.fractionatedPriceAllPaymentMethod || 0;
    this.offerPrice = productDetail.fractionatedPriceWithpaymentMethod || 0;
    this.crossOutPL = productDetail.crossOutPL;
    this.status = this.stock > 0 ? EProductPresentationStatus.available : EProductPresentationStatus.unavailable;
  }
}

class PackProductDetailPresentation extends ProductPresentation {
  constructor(productDetail: IProductDetail, private isRad: boolean) {
    super();
    this.fractionated = false;
    this.id = productDetail.presentationId || EProductPresentation.pack;

    const currentStock = this.isRad ? productDetail.stock : productDetail.stockRet
    this.stock = currentStock ? currentStock : 1;

    this.maxUnitSale = productDetail.maxUnitSale || 0;
    this.price = productDetail.price || 0;
    this.description = productDetail.noFractionatedText || '';
    this.presentation = productDetail.presentation || '';
    this.quantityLimit = new ProductQuantityLimit(this).quantityLimit || 0;
    this.regularPrice = productDetail.priceAllPaymentMethod || 0;
    this.offerPrice = productDetail.priceWithpaymentMethod || 0;
    this.crossOutPL = productDetail.crossOutPL;
    this.status = this.stock > 0 ? EProductPresentationStatus.available : EProductPresentationStatus.unavailable;
  }
}

export class ProductDetailPresentations extends ProductPresentations {
  isRadProcess: boolean
  constructor(productDetail: IProductDetail) {
    super();

    this.isRadProcess = localStorage.getItem(LS_RAD_OR_RET) === RET3_RAD_STORAGE_KEY

    this.productPresentations = [new PackProductDetailPresentation(productDetail, this.isRadProcess)];
    if (productDetail.fractionalMode) {
      this.productPresentations = [new UnitProductDetailPresentation(productDetail, this.isRadProcess), ...this.productPresentations];
    }
  }
}





