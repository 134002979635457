import { Injectable } from "@angular/core";
@Injectable()
export class User {
  id?: string;
  dni: string;
  phone: string;
  email: string;
  lastName?: string;
  name: string;
  paternalLastName: string;
  maternalLastName: string;
  identityDocumentType: number;
  identityDocumentNumber: string;
  birthDate?: number | string;
  notificationToken?: string;
  photo?: string;
  photoExtension?: string;
  photoUrl?: string;
  roleList?: Array<string>;
  sexId: string | number;
  points?: string;
  origin?: number;

  flagAuditTerms: boolean;
  flagDataTreatment: boolean;
  flagPrivacyPolicy: boolean;
  flagTermsAndConditions: boolean;

  fatherLastName?: string;
  motherLastName?: string;
}

export interface ISkuTalonOneList {
  skuTalonOneList: SkuTalonOneList[];
  channel: string;
  dni: string;
}

export interface SkuTalonOneList {
  price: number;
  sku: string;
  quantity: number;
}

export interface IResponseNotification {
  source: string;
  messageTalon: string;
}

