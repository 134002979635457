import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { AddressRequest } from "src/app/shared/models/address/address-request";
import { END_POINTS_BUSSINESS } from "src/app/shared/parameters/generic/endpoints";
import { EHttpHeaders } from "src/app/shared/parameters/generic/http-header.parameter";
import { TokenStoreService } from "src/app/shared/stores/user/token-store.service";

@Injectable({
  providedIn: "root",
})
export class AddressHttpService {
  constructor(
    private _httpClient: HttpClient,
    private _tokenStoreService: TokenStoreService
  ) {}

  updateAddress$(body: AddressRequest): Observable<any> {
    const headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });
    return this._httpClient.put<any>(
      `${END_POINTS_BUSSINESS.PUT_UPDATE_ADDRESS}`,
      body,
      {
        headers,
      }
    );
  }

  registerAddress$(body: AddressRequest): Observable<any> {
    const headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });
    return this._httpClient.post<any>(
      `${END_POINTS_BUSSINESS.POST_REGISTER_ADDRESS}`,
      body,
      {
        headers,
      }
    );
  }
  saveAddressFavorite$(idClient: string, idAddress: string): Observable<any> {
    const headers = new HttpHeaders({
      [EHttpHeaders.accessToken]: this._tokenStoreService.accessToken,
    });
    return this._httpClient.patch<any>(
      `${END_POINTS_BUSSINESS.PATCH_SAVE_ADDRESS_FAVORITE}/${idClient}/${idAddress}`,
      {},
      {
        headers,
      }
    );
  }
}
