import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class UserPickUpFormService {

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      userPickUp: new FormControl()
    });
  }

  public get userPickUpControl() {
    return this.form.get('userPickUp') as FormControl;
  }

}
