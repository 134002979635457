import { FormControl, Validators } from "@angular/forms";
import { environment } from "@environments/environment";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { GenericValidator } from "src/app/shared/validators/generic-validator";
import {
  getEnableErrorMessages,
  getErrorbyFormControl,
} from "../helpers/error-controls-helpers";
import { FATHER_LAST_NAME_ERROR_MESSAGES } from "../parameters/global-error-message";

export class FatherLastNameControl extends FormControl {
  public isInkaBrand = OrderTakerValidator.isOrderTakerEnv()
    ? EBrand[EBrandAsset[environment.brand]] == EBrand.inkafarma
    : EBrand[environment.brand] == EBrand.inkafarma;
  isOrderTaker = OrderTakerValidator.isOrderTakerEnv();
  constructor() {
    super("");
    this.settingFirstNameValidators();
  }

  private _validationsFatherLastName = [
    Validators.required,
    GenericValidator.validateCharacters,
  ];

  private settingFirstNameValidators() {
   if(this.isOrderTaker)
    this.setValidators([Validators.required,GenericValidator.validateCharacters]);
   else
    this.setValidators(this._validationsFatherLastName);
  }

  public get nameError(): string {
    return getErrorbyFormControl(this, FATHER_LAST_NAME_ERROR_MESSAGES);
  }

  public get error(): boolean {
    return getEnableErrorMessages(this); //this.errors && this.invalid && (this.dirty || this.touched);
  }
}
