import { Component, OnInit } from "@angular/core";
import { WhatsappInCartAliviamedModalService } from "../Service/whatsapp-in-cart-aliviamed-modal.service";

@Component({
  selector: "fp-whastapp-sale-in-cart-aliviamed",
  templateUrl: "./whatsapp-sale-in-cart-aliviamed.component.html",
  styleUrls: ["./whatsapp-sale-confirm.component.sass"],
})
export class WhatsappInCartAliviamedComponent implements OnInit {

  constructor(
    private confirmStockModal: WhatsappInCartAliviamedModalService,
  ) {}

  ngOnInit(): void {
  }

  closeModal() {
    this.confirmStockModal.closeModal();
  }

}
