import {EventEmitter, Injectable, Output} from '@angular/core';
import { PaymentMethodControl } from '../controls/payment-method-control';
import { OnlinePaymentTypeControl } from '../controls/online-payment-type-control';
import { PosPaymentTypeControl } from '../controls/pos-payment-type-control';
import { CashPaymentControl } from '../controls/cash-payment-control';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PAYMENT_METHODS_KEYS } from '../models/payment-method-form-store.interface';
import { CheckoutPaymentMethodFormStoreService } from '../checkout-stores/checkout-payment-method-form-store.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PurchaseSummary } from 'src/app/shared/models/shopping-cart/shopping-cart-precalculation.model';
import { CheckoutPurchaseSummaryStoreService } from '../checkout-stores/checkout-purchase-summary-store.service';
import { DocumentTypeControl } from 'src/app/shared/controls/document-type-control-l';
import { CrossDocumentControl } from 'src/app/shared/controls/cross-document-control';
import { PhoneNumberControl } from 'src/app/shared/controls/phone-number-control';
// import { TermnsAndPoliticsControl } from '../controls/terms-politics-control';

@Injectable()
export class CheckoutPaymentMethodFormService {

  private _paymentMethodControl = new PaymentMethodControl();
  private _inlinePaymentTypeControl = new OnlinePaymentTypeControl();
  private _posPaymentTypeControl = new PosPaymentTypeControl();
  private _cashPaymentTypeControl = new PosPaymentTypeControl();
  private _amountControl = new CashPaymentControl();
  private _documentTypeControl = new DocumentTypeControl();
  private _documentNumberControl = new CrossDocumentControl();
  private _phoneNumberControl = new PhoneNumberControl();

  static DOCUMENT_TYPE_DEFAULT = 1; // DNI: 1 y CE : 2

  public form: FormGroup;

  public creditCardIdForCash = 0;

  private subscriptions: Subscription[] = [];
  public purchaseSummary = {} as PurchaseSummary;
  @Output() setDeleteCoupon: EventEmitter<any> = new EventEmitter();
  @Output() setInfoTarjetaOh: EventEmitter<any> = new EventEmitter();

  @Output() setEnabled: EventEmitter<any> = new EventEmitter();
  @Output() couponEnabled: EventEmitter<any> = new EventEmitter();
  @Output() cleanAllFilter: EventEmitter<any> = new EventEmitter();
  @Output() deletetag: EventEmitter<any> = new EventEmitter();
  @Output() setCategoriesSelected: EventEmitter<any> = new EventEmitter();


  constructor(
    private formBuilder: FormBuilder,
    private paymentMethodFormStore: CheckoutPaymentMethodFormStoreService,
    private purchaseSummaryStore: CheckoutPurchaseSummaryStoreService
  ) {
    this.form = this.formBuilder.group({
      paymentMethod: this._paymentMethodControl,
      [PAYMENT_METHODS_KEYS.INLINE]: this._inlinePaymentTypeControl,
      [PAYMENT_METHODS_KEYS.POS]: this._posPaymentTypeControl,
      [PAYMENT_METHODS_KEYS.CASH]: this._cashPaymentTypeControl,
      [PAYMENT_METHODS_KEYS.AMOUNT]: this._amountControl,
      [PAYMENT_METHODS_KEYS.DOCUMENT_TYPE]: this._documentTypeControl,
      [PAYMENT_METHODS_KEYS.DOCUMENT_NUMBER]: this._documentNumberControl,
      [PAYMENT_METHODS_KEYS.PHONE_NUMBER]: this._phoneNumberControl,

    });
    this.loadInitialFormValues();
    this.settingControlValues();
    const purchaseSummarySubscription = this.purchaseSummaryStore.purchaseSummary$
      .subscribe(summary => this.purchaseSummary = summary);
    this.subscriptions.push(purchaseSummarySubscription);
  }

  public get paymentMethodControl() {
    return this.form.get(PAYMENT_METHODS_KEYS.PAYMENT_METHOD) as PaymentMethodControl;
  }

  public get inlinePaymentTypeControl() {
    return this.form.get(PAYMENT_METHODS_KEYS.INLINE) as OnlinePaymentTypeControl;
  }

  public get posPaymentTypeControl() {
    return this.form.get(PAYMENT_METHODS_KEYS.POS) as PosPaymentTypeControl;
  }

  public get cashPaymentTypeControl() {
    return this.form.get(PAYMENT_METHODS_KEYS.CASH) as PosPaymentTypeControl;
  }

  public get amountControl() {
    return this.form.get(PAYMENT_METHODS_KEYS.AMOUNT) as CashPaymentControl;
  }

  public get documentTypeControl() {
    return this.form.get(PAYMENT_METHODS_KEYS.DOCUMENT_TYPE) as DocumentTypeControl
  }

  public get documentNumberControl() {
    return this.form.get(PAYMENT_METHODS_KEYS.DOCUMENT_NUMBER) as CrossDocumentControl
  }

  public get phoneNumberControl() {
    return this.form.get(PAYMENT_METHODS_KEYS.PHONE_NUMBER) as PhoneNumberControl
  }

  public get previousPaymentCardIdOnline(){
    return this.paymentMethodFormStore.previousInlinePaymentMethod as number || 0
  }
  public set previousPaymentCardIdOnline(value: number){
    this.paymentMethodFormStore.setPreviousInlinePayment(value);
  }
  // public get termsAndConditionsControl() {
  //   return this.form.get('termsAndConditions') as TermnsAndPoliticsControl;
  // }

  private loadInitialFormValues() {
    this.documentNumberControl.setDocumentTypeControl(this.documentTypeControl);
    this.paymentMethodFormStore.form$
      .pipe(take(1))
      .subscribe(form => {
        this.form.setValue({
          [PAYMENT_METHODS_KEYS.PAYMENT_METHOD]: form[PAYMENT_METHODS_KEYS.PAYMENT_METHOD],
          [PAYMENT_METHODS_KEYS.INLINE]: form[PAYMENT_METHODS_KEYS.INLINE],
          [PAYMENT_METHODS_KEYS.POS]: form[PAYMENT_METHODS_KEYS.POS],
          [PAYMENT_METHODS_KEYS.CASH]: form[PAYMENT_METHODS_KEYS.CASH],
          [PAYMENT_METHODS_KEYS.AMOUNT]: form[PAYMENT_METHODS_KEYS.AMOUNT],
          [PAYMENT_METHODS_KEYS.DOCUMENT_TYPE]: form[PAYMENT_METHODS_KEYS.DOCUMENT_TYPE],
          [PAYMENT_METHODS_KEYS.DOCUMENT_NUMBER]: form[PAYMENT_METHODS_KEYS.DOCUMENT_NUMBER],
          [PAYMENT_METHODS_KEYS.PHONE_NUMBER]: form[PAYMENT_METHODS_KEYS.PHONE_NUMBER] ?? ""
          // termsAndConditions: form.termsAndConditions,
        });
      });
  }

  private settingControlValues() {
    const paymentMethodSubscription = this.paymentMethodControl.valueChanges
      .subscribe(value => this.paymentMethodFormStore.setPaymentMethod(value as number));
    const inlinePaymentSubscription = this.inlinePaymentTypeControl.valueChanges
      .subscribe(value => this.paymentMethodFormStore.setInlinePayment(value as number));
    const posPaymentSubscription = this.posPaymentTypeControl.valueChanges
      .subscribe(value => this.paymentMethodFormStore.setPosPayment(value as number));
    const cashPaymentSubscription = this.cashPaymentTypeControl.valueChanges
      .subscribe(value => this.paymentMethodFormStore.setCashPayment(value as number));
    const amountSubscription = this.amountControl.valueChanges
      .subscribe(value => this.paymentMethodFormStore.setAmount(value as string));
    const documentTypeSubscription = this.documentTypeControl.valueChanges
      .subscribe(value => this.paymentMethodFormStore.setDocumentType(value as number));
    const documentNumberSubscription = this.documentNumberControl.valueChanges
      .subscribe(value => this.paymentMethodFormStore.setDocumentNumber(value as string));
    const phoneNumberSubscription = this.phoneNumberControl.valueChanges
      .subscribe(value => this.paymentMethodFormStore.setPhoneNumber(value as string));
    // const termsAndConditionsSubscription = this.termsAndConditionsControl.valueChanges
    //   .subscribe(value => this.paymentMethodFormStore.setTermsAndConditions(value as boolean));
    this.subscriptions.push(
      paymentMethodSubscription,
      inlinePaymentSubscription,
      posPaymentSubscription,
      cashPaymentSubscription,
      amountSubscription,
      documentTypeSubscription,
      documentNumberSubscription,
      phoneNumberSubscription
    );
  }

  public upsubscribeObservers() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public get isValidPaymentMethodForm() {
    const paymentMethodId = this.paymentMethodControl.value as number;
    const inputsAppAgoraPayValid = this.phoneNumberControl.valid && this.documentNumberControl.valid;
    const currentCashErrorMessage = this.getValidatedCashAmount(this.purchaseSummary, this.amountControl.value);
    const paymentMethodArray = [
      // { paymentMethodId: 3, isValid: (this.inlinePaymentTypeControl.valid && this.termsAndConditionsControl.valid) },
      { paymentMethodId: 3, isValid: this.inlinePaymentTypeControl.valid },
      { paymentMethodId: 2, isValid: this.posPaymentTypeControl.valid },
      { paymentMethodId: 1, isValid: this.cashPaymentTypeControl.valid && !currentCashErrorMessage },
    ];
    const selectedPaymentMethod = paymentMethodArray.find(method => method.paymentMethodId === paymentMethodId);
    if ( selectedPaymentMethod ) {
      return selectedPaymentMethod.isValid;
    }
    if( inputsAppAgoraPayValid && paymentMethodId === 4 ){
      return true;
    }
    return false;
  }

  public markAsTouched() {
    this.paymentMethodControl.markAsTouched();
    this.inlinePaymentTypeControl.markAsTouched();
    this.posPaymentTypeControl.markAsTouched();
    this.amountControl.markAsTouched();
    this.phoneNumberControl.markAsTouched();
    this.documentNumberControl.markAsTouched();
    // this.termsAndConditionsControl.markAsTouched();
  }

  public markAsUntouched() {
    this.amountControl.markAsUntouched();
    this.phoneNumberControl.markAsUntouched();
    this.documentNumberControl.markAsUntouched();
  }

  public resetFormAppAgoraPay() {
    this.phoneNumberControl.setValue('');
    this.documentNumberControl.setValue('');
    this.markAsUntouched();
  }

  public getValidatedCashAmount(summary: PurchaseSummary, cashValue: number | string | null) {
    const insufficientAmountErrorMessage = this.getInsufficientCashErrorMessage(summary, cashValue);
    const tempErrorMessage = this.amountControl.cashPaymentError;
    const errorMessage = tempErrorMessage ? tempErrorMessage : insufficientAmountErrorMessage;
    return errorMessage;
  }

  public getValidatedCashAmount2(summary: PurchaseSummary, cashValue: number | string | null) {
    const insufficientAmountErrorMessage = this.getInsufficientCashErrorMessage(summary, cashValue);
    let currentCashErrorMessage = '';
    if (this.amountControl.canValidate) {
      const tempErrorMessage = this.amountControl.cashPaymentError;
      const errorMessage = tempErrorMessage ? tempErrorMessage : insufficientAmountErrorMessage;
      currentCashErrorMessage = errorMessage;
    }
    return currentCashErrorMessage;
  }

  private getInsufficientCashErrorMessage(summary: PurchaseSummary, cashValue: number | string | null) {
    let insufficientAmountErrorMessage = '';
    if (summary.totalOnline) {
      const tempAmount = cashValue;
      const amount = tempAmount ? Number(tempAmount) : 0;
      insufficientAmountErrorMessage = amount < summary.totalOnline ? 'El monto ingresado es insuficiente' : '';
    }
    return insufficientAmountErrorMessage;
  }
}
