import { Component, OnInit, Input, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { Subscription } from "rxjs";
import { IFormatedAddressItem } from "../../models/address-item-store.interface";

import { NewAddressManagerService } from "src/app/core/modules/address-manager/address-services/new-address-manager/new-address-manager.service";
import { CheckoutAddressItemsFormService } from "src/app/core/modules/address-manager/address-forms/checkout-address-items-form.service";
import { AddressManagerPersistanceService } from "../../address-services/address-manager-persistance.service";
import { BreakpointBehavior } from "src/app/shared/behaviors";
import { BREAKPOINTS } from "src/app/shared/parameters/global";
import { AddressListStoreService } from "../../address-stores/address-list-store.service";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { ProductDetail } from "src/app/business/product-detail/models/product-detail-model";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
@Component({
  selector: "fp-change-ret-or-rad-modal",
  templateUrl: "./change-ret-or-rad-modal.component.html",
  styleUrls: ["./change-ret-or-rad-modal.component.sass"]
})
export class ChangeRetOrRadModalComponent implements OnInit, OnDestroy {
  @Input() listModalTitle;
  @Input() product: ProductDetail;
  public selectedAddress = {} as AddressItem;
  public addressItemList: IFormatedAddressItem[] = [];
  private subscriptions: Subscription[] = [];

  private toastConfigLargeFormDeleteDirection: MatSnackBarConfig = { horizontalPosition: "left", verticalPosition: "bottom" };
  private toastConfigSmallFormDeleteDirection: MatSnackBarConfig = { verticalPosition: "top" };

  public isDisabledSubmitButton = false;

  private endRoute = "";

  get isRad() {
    return this.retValidateCartService.isRADProcess;
  }

  constructor(
    private dialogRef: MatDialogRef<ChangeRetOrRadModalComponent>,
    private newAddressManager: NewAddressManagerService,
    public addressItemsForm: CheckoutAddressItemsFormService,
    private addressManagerPersistanceService: AddressManagerPersistanceService,
    private _breakpointBehavior: BreakpointBehavior,
    private addressListStore: AddressListStoreService,
    private retValidateCartService: RetValidateCartService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.addressItemsForm.addressItemControl.valueChanges.subscribe((value) => {
      if (value) {
        this.selectedAddress = this.addressListStore.addressList.find((address) => address.id === value);
      }
    });
    const formatedSubscription = this.addressListStore.formatedAddressList$.subscribe((formatedAddressList) => {
      this.addressItemList = formatedAddressList;
    });
    const selectedSubscription = this.addressListStore.selectedAddress$.subscribe((selectedAddress) => {
      this.addressItemsForm.addressItemControl.setValue(selectedAddress.id);
      this.selectedAddress = selectedAddress;
    });
    this.subscriptions.push(formatedSubscription, selectedSubscription);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openNewAddressModal(): void {
    this.newAddressManager.showNewAddressModal();
    this.addressManagerPersistanceService.setAddressToUpdate(null);
  }

  onSubmit() {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.addressManagerPersistanceService.setAddressToUpdate(null);
  }

  private isBigScreen() {
    return this._breakpointBehavior.isMatchedMediaBreakpointUp(BREAKPOINTS.lg);
  }

  public editAddressItem(addressId: string) {
    const addressItem = this.addressListStore.addressList.find((address) => address.id === addressId);
    this.addressManagerPersistanceService.setAddressToUpdate(addressItem);
    this.newAddressManager.showNewAddressModal();
  }
}
