import { Injectable } from '@angular/core';
import { UserPickUpRequest } from 'src/app/shared/models/pickup-store/pickup-store.model';

@Injectable()
export class PickUpRequestService {

  private _requestParams = this.getNewRequestParams();
  public get requestParams() {
    return this._requestParams;
  }
  public set requestParams(params: UserPickUpRequest) {
    this._requestParams = params;
  }

  constructor() { }

  private getNewRequestParams() {
    return new UserPickUpRequest();
  }

  public resetRequestParams() {
    this._requestParams = this.getNewRequestParams();
  }

}
