import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { NewAddressModalComponent } from "../../modal-components/new-address-modal/new-address-modal.component";
import { FLOW_SEARCH_ADDRESS_TYPE } from "../../parameters/modal.constant";
import { NewAddressModalV2Component } from "../../modal-components/new-address-modal-v2/new-address-modal-v2.component";

@Injectable()
export class NewAddressManagerService {
  constructor(private dialog: MatDialog) {}

  public showNewAddressModal(
    option = FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS
  ): void {

    const config = {
        width: '608px',
        disableClose: true,
        panelClass: "custom-full-x-modal-overlay",
        data: option,
      };

    // if(OrderTakerValidator.isOrderTakerEnv()) config['width'] = '608px'
    setTimeout(() => {
      this.dialog.open(NewAddressModalV2Component, config);
    }, 200);
  }
}
