import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FooterImplementService } from "../../../core/modules/footer/services/footer-implement.service";
import { FooterItem } from "../../../core/modules/footer/models/footer.model";
import { RegisterClientService } from "src/app/shared/service/user-register/register-client.service";
import { RegisterClientImplement } from "../../../core/modules/login/login-services/register-client-implement.service";
import { UserRegister } from "@models/register/register.model";
import { RegisterImplementService } from "../../../core/modules/register/register-services/register-implement.service";
import { UserRegisterRequest } from "@models/register/user-register-request.model";
import { COMPANY_ID } from "@parameters/global";
import { IUpdateAnonymousRequestParams } from "@models/update-anonymous/update-anonymous.interface";
import { take } from "rxjs/operators";
import { UpdateAnonymousImplementService } from "src/app/business/checkout/checkout-services/update-anonymous-implement.service";

@Component({
  selector: "fp-cookies-policy",
  templateUrl: "./cookies-policy.component.html",
  styleUrls: ["./cookies-policy.component.sass"],
})
export class CookiesPolicyComponent implements OnInit {
  userResponseData: UserRegister;
  userAcceptCookies: any;

  get listUsersCookies(): string[] {
    const users = localStorage.getItem("users_accept_cookies");
    return users ? JSON.parse(users) : [];
  }

  constructor(
    public matDialogRef: MatDialogRef<CookiesPolicyComponent>,
    private registerClient: RegisterClientService,
    private registerImplementService: RegisterImplementService,
    private updateAnonymousImplement: UpdateAnonymousImplementService,
    @Inject(MAT_DIALOG_DATA) public dialogData
  ) {}

  ngOnInit(): void {
    this.registerClient.currentUserData$.subscribe((userData) => {
      this.userResponseData = userData?.data as UserRegister;
    });
  }

  userRegisterCookiesAccepted(userId): boolean {
    if (userId) {
      const users = this.listUsersCookies;
      const userExists = this.listUsersCookies.find((u) => u === userId);
      if (userExists == null) {
        users.push(userId);
        localStorage.setItem("users_accept_cookies", JSON.stringify(users));
      }

      return userExists != null;
    }

    return false;
  }

  accept() {
    const nowTimestamp = new Date().getTime().toString();
    localStorage.setItem("user_accept_cookies", "true");
    localStorage.setItem("userid_processed_cookies", this.userResponseData?.id);
    localStorage.setItem("user_processed_cookies", nowTimestamp);

    if (this.userResponseData) {
      if (
        this.userResponseData?.id &&
        this.userResponseData.isAnonymous === false
      ) {
        this.registerImplementService
          .doRegisterCookies(this.userResponseData)
          .subscribe(() => {
            this.userRegisterCookiesAccepted(this.userResponseData?.id);
            this.matDialogRef.close();
          });
      } else {
        this.updateAnonymousImplement.doRegisterCookies().subscribe((userResponseData) => {
          this.userRegisterCookiesAccepted(this.userResponseData?.id);
          this.matDialogRef.close();
        });
      }
    } else {
      this.matDialogRef.close();
    }
  }

  close() {
    this.matDialogRef.close({
      closed: true,
    });
  }
}
