import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { ForgotPasswordStoreService } from '../../login-stores/forgot-password-store.service';
import { LoginModalsManagerHelperService } from '../../login-services/login-modals-manager-helper.service';

@Component({
  selector: 'fp-forgot-password-confirmation',
  templateUrl: './forgot-password-confirmation.component.html',
  styleUrls: ['./forgot-password-confirmation.component.sass']
})
export class ForgotPasswordConfirmationComponent implements OnInit {
  public email: string;
  public urlExternal: string;

  constructor(
    public dialog: MatDialogRef<ForgotPasswordComponent>,
    private forgotPasswordStore: ForgotPasswordStoreService,
    private loginModalsManagerHelper: LoginModalsManagerHelperService,

  ) { }

  ngOnInit() {
    this.email = this.forgotPasswordStore.email;
    const index = this.email.indexOf('@') + 1;
    this.urlExternal = this.email.substr(index);
  }

  closeModalConfirmation() {
    this.loginModalsManagerHelper.closeForgotPasswordConfirmationModal();
  }

  changeEmail() {
    this.closeModalConfirmation();
    this.loginModalsManagerHelper.openForgotPasswordModal();
  }

  goToEmail() {
    this.closeModalConfirmation();
  }
}
