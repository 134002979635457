import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@environments/environment";
import { EBrand, EBrandAsset } from "@parameters/access/brand.parameter";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { PurchaseSummary } from "src/app/shared/models/shopping-cart/shopping-cart-precalculation.model";
import { BUSINESS_PATHS } from "src/app/shared/parameters/business.parameter";
import { UserInfoStoreService } from "src/app/shared/stores/user/user-info-store.service";
import { ProductShoppingCart } from "../../../product-card/models/product-shopping-cart/product-shopping-cart.model";
import { CartLoginFormService } from "../../login-forms/cart-login-form.service";
import { LoginModalsManagerHelperService } from "../../login-services/login-modals-manager-helper.service";
import { CartControlledStoreService } from "src/app/business/cart/services/cart-controlled-store.service";
import { CartcontrolledModalService } from "src/app/business/cart/services/cart-controlled-modals.service";

@Component({
	selector: "fp-anonymous-warning-modal",
	templateUrl: "./anonymous-warning-modal.component.html",
	styleUrls: ["./anonymous-warning-modal.component.sass"]
})
export class AnonymousWarningModalComponent implements OnInit {
	public isInkaBrand = OrderTakerValidator.isOrderTakerEnv()
		? EBrand[EBrandAsset[environment.brand]] == EBrand.inkafarma
		: EBrand[environment.brand] == EBrand.inkafarma;
	public src: string = "";
	public readonly IKF_SVG = "../../../../../../assets/icons/deliver-outline.svg";
	public readonly MF_SVG = "../../../../../../assets/icons/broken-heart.svg";
	@Input() message =
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

	private hasUpdateShoppingCartAlert: boolean;
	public skeletonLoader = true;
	public shoppingList: Array<ProductShoppingCart>;
	public miniCartLoader: boolean;
	public purchaseSummary: PurchaseSummary;

	constructor(
		private router: Router,
		public cartLoginForm: CartLoginFormService,
		public userInfoStore: UserInfoStoreService,
		private loginModalsManagerHelper: LoginModalsManagerHelperService,
		private _cartControlledStoreService: CartControlledStoreService,
		private _cartcontrolledModalService: CartcontrolledModalService

	) {
		this.src = this.isInkaBrand ? this.IKF_SVG : this.MF_SVG;
	}

	ngOnInit() {}

	closeModal() {
		this.loginModalsManagerHelper.openCartLoginModal();
		this.loginModalsManagerHelper.closeAnonymousWarningModal();
	}

	continueWithoutLogin() {
		const hasControlledProducts = JSON.parse(localStorage.getItem("hasControlledProducts"));
		if (hasControlledProducts) {
			this.userInfoStore.setShoppingMode("anonymous");
			this.loginModalsManagerHelper.closeAnonymousWarningModal();
			TaggingService.loginModal("continuar sin iniciar sesión - confirmación");
			this._cartControlledStoreService.setIsLogged(true);
			return this.router.navigate([BUSINESS_PATHS.CART]);
		} else {
			this.router.navigate([BUSINESS_PATHS.CHECKOUT]);
			this.userInfoStore.setShoppingMode("anonymous");
			this.loginModalsManagerHelper.closeAnonymousWarningModal();
			TaggingService.loginModal("continuar sin iniciar sesión - confirmación");
		}
	}
}
