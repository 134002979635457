import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs/internal/Subscription";
import { take } from "rxjs/operators";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { AddressImplementService } from "src/app/core/modules/address-manager/address-services/address-implement.service";
import { AddressManagerPersistanceService } from "src/app/core/modules/address-manager/address-services/address-manager-persistance.service";
import { NewAddressManagerService } from "src/app/core/modules/address-manager/address-services/new-address-manager/new-address-manager.service";
import { AddressListStoreService } from "src/app/core/modules/address-manager/address-stores/address-list-store.service";
import { IFormatedAddressItem } from "src/app/core/modules/address-manager/models/address-item-store.interface";
import { TOAST_DELETE_MESSAGE } from "src/app/core/modules/address-manager/parameters/address-list.constant";
import { FLOW_SEARCH_ADDRESS_TYPE } from "src/app/core/modules/address-manager/parameters/modal.constant";
import { HereMapPlacesService } from "src/app/core/modules/here-maps/services/here-map-places.service";
import { SnackbarToastService } from "src/app/core/modules/snackbar/snackbar-toast.service";
import { setNewAddressModal } from "src/app/core/store/actions/app.actions";
import { AppState } from "src/app/core/store/reducer/app.reducer";
import { BreakpointBehavior } from "src/app/shared/behaviors";
import { AddressItem } from "src/app/shared/models/address/address.model";
import { BREAKPOINTS } from "src/app/shared/parameters/global";
import { OrderTakerValidator } from "src/app/shared/validators/order-taker.validator";
import { StepperOfficeFormService } from "../../forms/stepper-office-form.service";
import { Client } from "./../../../../shared/models/client/client.model";
@Component({
  selector: "fp-step-one",
  templateUrl: "./step-one.component.html",
  styleUrls: ["./step-one.component.sass"]
})
export class StepOneComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() client: Client;

  private subs$: Subscription = new Subscription();

  private toastConfigLargeFormDeleteDirection: MatSnackBarConfig = {
    horizontalPosition: "left",
    verticalPosition: "bottom"
  };
  private toastConfigSmallFormDeleteDirection: MatSnackBarConfig = {
    verticalPosition: "top"
  };
  public selectedAddress = {} as AddressItem;
  public addressItemList: IFormatedAddressItem[] = [];

  constructor(
    private newAddressManager: NewAddressManagerService,
    private addressManagerPersistanceService: AddressManagerPersistanceService,
    private addressListStore: AddressListStoreService,
    private addressImplement: AddressImplementService,
    private _breakpointBehavior: BreakpointBehavior,
    private snackbarToastService: SnackbarToastService,
    public addressItemsForm: StepperOfficeFormService,
    private hereMapPlacesService: HereMapPlacesService,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    if (!this.addressListStore.selectedAddress)
      this.addressListStore.setSelectedAddress(this.addressManagerPersistanceService.addressToUpdate ?? ({} as AddressItem));
    const addressSubs = this.addressItemsForm.addressItemControl.valueChanges.subscribe((value) => {
      if (value) {
        this.selectedAddress = this.addressListStore?.addressList?.find((address) => address.id === value);
        //Asignar dirección en hereservice
        if (OrderTakerValidator.isOrderTakerEnv()) this.setAddresStore();
      } else {
        const { id: idAddressDefault } = this.addressListStore?.addressList?.[0] || {};
        if (idAddressDefault) {
          this.selectRadioAddress(idAddressDefault);
        }
      }
      if (OrderTakerValidator.isOrderTakerEnv() && this.selectedAddress && value) {
        this.addressManagerPersistanceService.setAddressToUpdate(this.selectedAddress);
      }
    });
    const formatedSubscription = this.addressListStore.formatedAddressList$.subscribe((formatedAddressList) => {
      this.addressItemList = formatedAddressList;
    });
    const selectedSubscription = this.addressListStore.selectedAddress$.subscribe((selectedAddress) => {
      this.addressItemsForm?.addressItemControl?.setValue(selectedAddress.id);
      this.selectedAddress = selectedAddress;
    });
    this.subs$.add(addressSubs);
    this.subs$.add(formatedSubscription);
    this.subs$.add(selectedSubscription);
  }
  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  openNewAddressModal(): void {
    if (OrderTakerValidator.isOrderTakerEnv()) {
      this.newAddressManager.showNewAddressModal(FLOW_SEARCH_ADDRESS_TYPE.REGISTER_NEW_ADDRESS);
    } else {
      this.newAddressManager.showNewAddressModal();
    }
  }

  public deleteAddressItem(addressId: string) {
    this.addressImplement
      .deleteAddress$(addressId)
      .pipe(take(1))
      .subscribe(() => {
        const newAddressList = this.addressListStore.addressList.filter((address) => address.id !== addressId);
        this.addressListStore.setAddressList(newAddressList);

        if (this.selectedAddress.id === addressId) {
          this.addressImplement.loadAddressListAndSelectDefaultAddress2$().subscribe((_) => {});
        }

        const toastConfig = this.isBigScreen() ? this.toastConfigLargeFormDeleteDirection : this.toastConfigSmallFormDeleteDirection;
        this.snackbarToastService.snackBarToast(TOAST_DELETE_MESSAGE, toastConfig);
      });
  }

  public editAddressItem(addressId: string) {
    const addressItem = this.addressListStore.addressList.find((address) => address.id === addressId);
    this.addressManagerPersistanceService.setAddressToUpdate(addressItem);
    this.newAddressManager.showNewAddressModal(FLOW_SEARCH_ADDRESS_TYPE.EDIT_ADDRESS);
  }

  public selectRadioAddress(addressId: string) {
    this.addressItemsForm.addressItemControl.setValue(addressId);
    const addressItem = this.addressListStore.addressList.find((address) => {
      return address.id === addressId;
    });
    this.addressManagerPersistanceService.setAddressToUpdate(addressItem);
  }

  setAddresStore(){
    const geoLocationLikeString = this.selectedAddress.latitude.toString()
    .toString()
    .concat(",")
    .concat(this.selectedAddress.longitude.toString());

    this.hereMapPlacesService.getReverseGeocodeService$(geoLocationLikeString).subscribe(
      (place) => {
        this.store.dispatch(
          setNewAddressModal({
            newAddressModal: {
              direction: place?.address?.label,
              hereAddressMarked: place,
            },
          })
        );
      }
    );
  }
  private isBigScreen() {
    return this._breakpointBehavior.isMatchedMediaBreakpointUp(BREAKPOINTS.lg);
  }
  tagRadio(aItem) {
    TaggingService.checkoutRadioAddress(aItem.name);
    if(this.addressItemsForm.addressItemControl.value != aItem.id)
      this.selectRadioAddress(aItem.id);
  }
}
