import { CONTENTFUL_PATH } from '../../../shared/parameters/endpoints';
import { Entry } from 'contentful';
import { IContentfulBanner } from './contentful-banner.model';
import { ContentfulEntry } from '../../../shared/models/contentful/contentful.model';

export interface IContentfulBannerCountDown {
  title: string;
  fechaInicio: string;
  fechaFin: string;
  item: Entry<IContentfulBanner>;
}

export class ContentfulBannerCountDown {
  title: string;
  initTime: string;
  endTime: string;
  banner: IContentfulBanner;

  constructor(iContentfulBannerCountDown: IContentfulBannerCountDown) {
    this.title = iContentfulBannerCountDown.title;
    this.initTime = iContentfulBannerCountDown.fechaInicio;
    this.endTime = iContentfulBannerCountDown.fechaFin;
    this.banner = new ContentfulEntry(iContentfulBannerCountDown.item).content;
  }
}

export const CContentfulBannerCountDown = {
  id: CONTENTFUL_PATH.contentTypeIds.bannerCountDown,
  startDate: 'string',
  endDate: 'string',
};
