import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { EProductStatus } from 'src/app/core/modules/product-card/models/product-status.enum';
import { ProductPresentation } from '../../models/product-presentation.model';
import { EBrand } from '@parameters/access/brand.parameter';
import { RetValidateCartService } from 'src/app/shared/service/cart/ret-validate-cart.service';

@Component({
  selector: 'fp-product-presentation',
  templateUrl: './product-presentation.component.html',
  styleUrls: ['./product-presentation.component.sass']
})
export class ProductPresentationComponent implements OnInit {
  public eProductStatus = EProductStatus;
  public brand = EBrand;

  @Input() presentationTemplate: 'select' | 'cards' | 'list' = 'select';
  @Input() presentationList: Array<ProductPresentation> = [] as Array<ProductPresentation>;
  @Input() presentationSelected: number;
  @Input() productStatus: number = this.eProductStatus.available;
  @Output() updatePresentation = new EventEmitter();

  constructor(private retValidateCartService: RetValidateCartService) {
  }

  ngOnInit(): void {
  }

  updatePresentationEvent(presentation: ProductPresentation) {
    this.updatePresentation.emit(presentation);
  }
}
