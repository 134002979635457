import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RefreshScheduleHelperService {

  private scheduleHelperSubject = new Subject();
  public scheduleHelper$ = this.scheduleHelperSubject.asObservable();

  private nextScheduleHelperSubject = new Subject();
  public nextScheduleHelper$ = this.nextScheduleHelperSubject.asObservable();

  private changeDeliveryServiceTypeSubject = new Subject();
  public changeDeliveryServiceType$ = this.changeDeliveryServiceTypeSubject.asObservable();

  constructor() { }

  public emitEventForUpdateSchedule() {
    this.scheduleHelperSubject.next();
  }

  public emitEventForSelectNextSchedule() {
    this.nextScheduleHelperSubject.next();
  }

  public emitDeliveryServiceTypeChanging() {
    this.changeDeliveryServiceTypeSubject.next();
  }
}
