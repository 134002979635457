import { FormControl, Validators } from '@angular/forms';
import { GenericValidator } from 'src/app/shared/validators/generic-validator';
import { getErrorbyFormControl } from '../helpers/error-controls-helpers';
import { LAST_NAME_ERROR_MESSAGES } from '../parameters/global-error-message';

export class LastNameControl extends FormControl {

  constructor() {
    super('');
    this.settingLastNameValidators();
  }

  private settingLastNameValidators() {
    this.setValidators([
      Validators.required,
      Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\*\s]+$/)
    ]);
  }

  public get lastNameError(): string {
    return getErrorbyFormControl(this, LAST_NAME_ERROR_MESSAGES);
  }
}
