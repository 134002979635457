import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckoutPaymentMethodFormService } from '../../../../checkout-forms/checkout-payment-method-form.service';

@Component({
  selector: 'fp-checkout-error-coupon-modal-mifa',
  templateUrl: './checkout-error-coupon-modal.component.html',
  styleUrls: ['./checkout-error-coupon-modal.component.scss']
})
export class CheckoutErrorCouponModalMifaComponent implements OnInit {
  public coupon : string;

  constructor(
    public dialogRef: MatDialogRef<CheckoutErrorCouponModalMifaComponent>,
    public paymentMethodForm: CheckoutPaymentMethodFormService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
      this.coupon = this.data.coupon;
  }

  closeModal() {
    this.paymentMethodForm.setEnabled.emit(false);

    this.dialogRef.close();
  }

}
