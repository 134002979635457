import { Injectable } from "@angular/core";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import firebase from "firebase/compat/app";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { AuthImplementService } from "src/app/shared/implements/auth/auth-implement.service";
import { RegisterResponse } from "../../models/register/register.model";
import { UpdateAnonymous } from "../../models/update-anonymous/update-anonymous.model";
import { USER_REGISTRATION_ERROR_CODE } from "../../parameters/global";
import { UpdateAnonymousClientService } from "../update-anonymous/update-anonymous-client.service";
import { RegisterClientService } from "../user-register/register-client.service";

@Injectable()
export class AnonymousRegisterImplementService {
  private tempFirebaseUser: firebase.User;

  constructor(
    private updateAnonymousClient: UpdateAnonymousClientService,
    private _registerClientService: RegisterClientService,
    private authImplement: AuthImplementService
  ) {
    this.authImplement.currentUserObservable.subscribe((user) => {
      this.tempFirebaseUser = user;
    });
  }

  public getUserByUid(): Observable<RegisterResponse> {
    return this._registerClientService.getUser$();
  }

  public updateAnonymousInformation() {
    const params = new UpdateAnonymous();
    return this.updateAnonymousClient.updateAnonymousInformation$(params);
  }

  public anonymousRegister(): Promise<boolean> {
    const isOrderTaker = OrderTakerValidator.isOrderTakerEnv();

    if (isOrderTaker) {
      return new Promise((resolve) => resolve(true));
    } else {
      return new Promise((resolve) => {
        this.getUserByUid()
          .pipe(take(1))
          .subscribe((registerResponse: RegisterResponse) => {
            if (
              registerResponse.code ===
                USER_REGISTRATION_ERROR_CODE.ERROR_USER_NOT_EXIST &&
              this.tempFirebaseUser.isAnonymous
            ) {
              // const isNotOrderTaker = !OrderTakerValidator.isOrderTakerEnv();
              // if (isNotOrderTaker) {
              this.updateAnonymousInformation()
                .pipe(take(1))
                .subscribe(() => {
                  resolve(true);
                });
              // } else {
              //   resolve(true);
              // }
            } else {
              resolve(true);
            }
          });
      });
    }
  }
}
