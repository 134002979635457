import { IUpdateAnonymousRequestParams } from "./update-anonymous.interface";

export class UpdateAnonymous {
  email: string;
  flagAuditTerms: boolean;
  flagDataTreatment: boolean;
  flagPrivacyPolicy: boolean;
  flagTermsAndConditions: boolean;
  lastName: string;
  name: string;
  phone: string;
  paternalLastName: string;
  maternalLastName: string;
  identityDocumentType: string;
  identityDocumentNumber: string;
  source: string;
  codClientCall?: string;

  constructor(
    iUpdateAnonymousRequestParams: IUpdateAnonymousRequestParams = {} as IUpdateAnonymousRequestParams
  ) {
    this.name = iUpdateAnonymousRequestParams.name || "";
    this.lastName = iUpdateAnonymousRequestParams.lastName || "";
    this.email = iUpdateAnonymousRequestParams.email || "";
    this.phone = iUpdateAnonymousRequestParams.phone || "";
    this.paternalLastName =
      iUpdateAnonymousRequestParams.paternalLastName || "";
    this.maternalLastName =
      iUpdateAnonymousRequestParams.maternalLastName || "";
    this.identityDocumentNumber =
      iUpdateAnonymousRequestParams.identityDocumentNumber || "";
    this.identityDocumentType =
      iUpdateAnonymousRequestParams.identityDocumentType || null;
    this.source = iUpdateAnonymousRequestParams.source || "";
    if (iUpdateAnonymousRequestParams.flagAuditTerms) {
      this.flagAuditTerms =
        iUpdateAnonymousRequestParams.flagAuditTerms || false;
    }
    if (iUpdateAnonymousRequestParams.flagDataTreatment) {
      this.flagDataTreatment =
        iUpdateAnonymousRequestParams.flagDataTreatment || false;
    }
    if (iUpdateAnonymousRequestParams.flagPrivacyPolicy) {
      this.flagPrivacyPolicy =
        iUpdateAnonymousRequestParams.flagPrivacyPolicy || false;
    }
    if (iUpdateAnonymousRequestParams.flagTermsAndConditions) {
      this.flagTermsAndConditions =
        iUpdateAnonymousRequestParams.flagTermsAndConditions || false;
    }
  }
}
