import { IProductVariant } from './product-detail.interface';

export class ProductVariant {
    public sku: string;
    public type: string;
    public code: string;
    public description: string;
    public slug: string;
    public hasStock: boolean;
    public imageUrl: string;
    constructor(productVariant: IProductVariant) {
        this.sku = productVariant.sku || '';
        this.code = productVariant.code || '';
        this.type = productVariant.type || '';
        this.description = productVariant.description || '';
        this.slug = productVariant.slug || '';
        this.hasStock = productVariant.hasStock || null;
        this.imageUrl = productVariant.imageUrl || '';
    }
}

