export interface ICampaignIdentityDocumentType {
  id: number;
  name: string;
}

export class CampaignIdentityDocumentType {
  id: number;
  name: string;

  constructor(iCampaignIdentityDocumentType: ICampaignIdentityDocumentType) {
    this.id = iCampaignIdentityDocumentType.id;
    this.name = iCampaignIdentityDocumentType.name;
  }
}
