<div class="card-store" [ngClass]="{' disabled': isDisabled }" *ngIf="!notFound">
  <ng-container *ngIf="!isLoading; else loaderSkeleton">
    <div class="card-store__content" [ngClass]="{ 'without-action': action }">
      <h4>
        <b>{{ name }}</b>
      </h4>
      <fp-chip
          class="card-store__status"
          [text]="!isDisabled ? 'Disponible' : 'No Disponible'"
          status="blue"
          [disabled]="isDisabled"
        ></fp-chip>
    </div>
  </ng-container>
  <ng-template #loaderSkeleton>
    <div class="card-store__icon" style="margin-right: 16px">
      <fp-basic-skeleton
        class="basic-skeleton"
        [height]="20"
      ></fp-basic-skeleton>
    </div>
    <div class="card-store__content" [ngClass]="{ 'without-action': action }">
      <fp-basic-skeleton
        class="basic-skeleton"
        [height]="20"
      ></fp-basic-skeleton>
      <fp-basic-skeleton
        class="basic-skeleton"
        [height]="36"
      ></fp-basic-skeleton>
    </div>
  </ng-template>

  <div class="card-store__action" *ngIf="!notFound">
    <u>
      <fp-link-buttom
        *ngIf="action"
        [id]="'lb--change-address'"
        [buttonText]="'Cambiar'"
        (click)="onChange()"
      ></fp-link-buttom>
    </u>
  </div>
</div>
<div
  class="card-store not-found"
  *ngIf="notFound && !isLoading"
  (click)="onChange()"
>
  <div class="card-store__content not-found">
    <fp-icon
      style="margin-right: 10px"
      iconSize="20"
      iconName="store_outline_blue"
    ></fp-icon>
    <span>Seleccionar botica</span>
  </div>
</div>
