import { Injectable } from "@angular/core";
import { ModalSkuVisibleService } from "./modals/modal-sku-visible.service";
import { ModalAgoraPayService } from './modals/modal-agora-pay.service';

@Injectable({
  providedIn: "root"
})
export class ModalsCheckoutFacadeService {
  constructor(
    private modalSkuCheckout: ModalSkuVisibleService,
    private modalAgora:ModalAgoraPayService) {}

  get getModalSkuCheckout() {
    return this.modalSkuCheckout;
  }
  get getModalAgora() {
    return this.modalAgora;
  }
}
