export enum ESnackbarMessageCodes {
  /**
   * Solo se realizó registro en inkafarma y
   * no había ningún dato que actualizar en inkaclub
   */
  INKAFARMA_REGISTER_OK,

  /**
   * Solo se realizó actualización en inkafarma y
   * no había ningún dato que actualizar en inkaclub
   */
  INKAFARMA_UPDATED_OK,

  /**
   * Se realizó registro en inkafarma y
   * se registró exitosamente en inkaclub
   */
  INKAFARMA_REGISTER_OK_INKACLUB_REGISTER_OK,

  /**
   * Se realizó registro en inkafarma y
   * se actualizó exitosamente en inkaclub
   */
  INKAFARMA_REGISTER_OK_INKACLUB_UPDATED_OK,

  /**
   * Se realizó registro en inkafarma y
   * se ocurrió un error en el servicio de inkaclub
   */
  INKAFARMA_REGISTER_OK_INKACLUB_ERROR_OK,

  /**
   * Se realizó actualización en inkafarma y
   * se registró exitosamente en inkaclub
   */
  INKAFARMA_UPDATED_OK_INKACLUB_REGISTER_OK,

  /**
   * Se realizó actualización en inkafarma y
   * se actualizó exitosamente en inkaclub
   */
  INKAFARMA_UPDATED_OK_INKACLUB_UPDATED_OK,

  /**
   * Se realizó actualización en inkafarma y
   * se ocurrió un error en el servicio de inkaclub
   */
  INKAFARMA_UPDATED_OK_INKACLUB_ERROR_OK,
}

