import { Component, Input, OnChanges } from "@angular/core";
import { ProductBackup } from "@models/stock/stock-backup.response";
import { BUSINESS_PATHS } from "@parameters/business.parameter";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { StockRequestService } from "src/app/shared/service/stock/stock-request.service";
import { ProductPresentation } from "../../models/product-presentation.model";
import { ProductSellersShoppingCartBase } from "../../models/product-shopping-cart/product-seller-shopping-cart-base.model";
import { ProductShoppingCart } from "../../models/product-shopping-cart/product-shopping-cart.model";
import { EProductStatus } from "../../models/product-status.enum";
import { ProductAlertsService } from "../../service/product-alerts.service";
import { ProductImplementService } from "../../service/product-implement.service";
import { getProductPresentationSelected } from "../../service/product-presentation.helper";
import { ProductSalesforceService } from "../../service/product-salesforce.service";
import { getUnavailablePresentationStatus } from "../../service/product-status.helper";
import { AppStoreService } from "@stores/app/app-store.service";
import { EBrand } from "@parameters/access/brand.parameter";
@Component({
  selector: "fp-product-cart-order-taker",
  templateUrl: "./product-cart-order-taker.component.html",
  styleUrls: ["./product-cart-order-taker.component.sass"],
})
export class ProductCartOrderTakerComponent implements OnChanges {
  public readonly brand = EBrand;
  public eProductStatus = EProductStatus;
  public productStatus: number = EProductStatus.available;
  public productStatusSeller: number = EProductStatus.available;
  public productPresentation: ProductPresentation;

  public productPath = BUSINESS_PATHS.PRODUCT;

  public initialPresentation: string;
  public initialPrecio: number;

  stockBackup: number = 0;
  stockPrincipal: number = 0;
  aliviamed_presentation;

  @Input() product: ProductShoppingCart;
  @Input() productSeller: ProductSellersShoppingCartBase;
  @Input() stockBackupList: ProductBackup[] = [];
  flagBackup = this._stockRequest.isZoneBackup();

  constructor(
    private _productImplement: ProductImplementService,
    private _productAlerts: ProductAlertsService,
    private _productSalesforce: ProductSalesforceService,
    private _stockRequest: StockRequestService,
  ) {}

  ngOnChanges() {
    this.productPresentation = getProductPresentationSelected(this.product);
    this.productStatus = getUnavailablePresentationStatus(
      this.product,
      this.productPresentation.fractionated
    );
    this.getStockBackup();

    if( this.product.productAliviamed?.isMedicalConsultation){
      this.productPresentation.presentation = "SERVICIO"
    }
  }

  getStockBackup() {
    if(!this.product) return

    let stockAux = 0
    this.product.presentations.forEach((presentation)=>{
      stockAux =+ stockAux + (presentation.stock ?? 0)
    })

    if(this.flagBackup){
      if (this.stockBackupList?.length > 0) {
        const stockBackupProduct = this.stockBackupList.find(
          (p) => p.id === this.product.id
        );

        if (stockBackupProduct) {
          this.stockPrincipal = (stockBackupProduct?.stock ?? 0) + (stockBackupProduct?.fractionalStock ?? 0)
        }
      }

      this.stockBackup = stockAux
    }else{
      this.stockPrincipal = stockAux
    }
  }

  setProductPresentation(presentation: ProductPresentation) {
    this.initialPrecio = this.productPresentation.price;
    this.initialPresentation = this.productPresentation.presentation;
    this.product.presentationIdSelected = presentation.id;
    this.productPresentation = presentation;
    this.productStatus = getUnavailablePresentationStatus(
      this.product,
      this.productPresentation.fractionated
    );
    this.setQuantity(
      this.productStatus === this.eProductStatus.available ? 1 : 0,
      true
    );
    TaggingService.cartPresentation();
    this.initialPrecio = this.productPresentation.price;
    this.initialPresentation = this.productPresentation.presentation;


  }

  setQuantity(quantity: number, changePresentation = false) {
    if (this.product.quantity < quantity) {
      TaggingService.productClick(this.product);
      TaggingService.detail(this.product);
      TaggingService.addToCart(this.product, this.productPresentation);
    } else {
      const quantityTotalRemove = changePresentation
        ? this.product.quantity
        : 0;
      if (
        this.initialPrecio === undefined &&
        this.initialPresentation === undefined
      ) {
        const itemPresentation = this.product.presentations.find(
          (e) => e.id === this.product.presentationIdSelected
        );
        this.initialPrecio = itemPresentation.price;
        this.initialPresentation = itemPresentation.presentation;
      }
      TaggingService.removeFromCart(
        this.product,
        this.initialPrecio,
        this.initialPresentation,
        quantityTotalRemove
      );
    }

    this.product.quantity = quantity;
    this._productImplement.updateProduct(
      this.product.id,
      this.product.quantity,
      this.product.presentationIdSelected,
      this.product.sellerId,
      this.product.sellerName,
      this.product.productPack
    );
    this._productSalesforce.updateProduct(
      this.product.id,
      this.product.id,
      this.product.quantity,
      this.productPresentation.price * this.product.quantity
    );
    TaggingService.cartQuantity();
  }

  deleteProduct() {
    this._productImplement.deleteProduct(this.product.id);
    this._productAlerts.deleteProductAlert();
    this._productSalesforce.deleteProduct(this.product.id);
    TaggingService.cartEliminar();
    const itemPresentation = this.product.presentations.find(
      (e) => e.id === this.product.presentationIdSelected
    );
    TaggingService.removeFromCart(
      this.product,
      itemPresentation.price,
      itemPresentation.presentation,
      this.product.quantity,
      true
    );
  }

  nameProduct() {
    TaggingService.cartName();
  }
}
