import { IProductAdditionalInformation } from './product-detail.interface';
import { ICategoryProduct} from './product-detail.interface';

export class ProductAdditionalInformation {
    public order: number;
    public title: string;
    public content: any;

    constructor(productAdditionalInformation: IProductAdditionalInformation) {
        this.order = productAdditionalInformation.order || -1;
        this.title = productAdditionalInformation.title || '';
        this.content = productAdditionalInformation.content || '';
    }
}

export class CategoryProduct {
    public id: string;
    public keyword: string;
    public name: string;
    public categories: CategoryProduct[];

    constructor(productAdditionalInformation: ICategoryProduct) {
        this.id = productAdditionalInformation.id || '';
        this.keyword = productAdditionalInformation.keyword || '';
        this.name = productAdditionalInformation.name || '';
        this.categories = productAdditionalInformation.categories;
    }
}


