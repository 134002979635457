<div
  class="content-address-radio-buttom mb-3"
  [ngClass]="{ clickable: !selectedRadio }"
  (click)="selectOption()"
>
  <fp-border-container [selected]="selectedRadio">
    <div class="content-row-menu">
      <div class="row">
        <div class="col-9 d-flex">
          <div class="content-radio-buton">
            <ng-content></ng-content>
          </div>
          <div class="ml-3">
            <div class="label-black mb-2">{{ addressTitle | titlecase }}</div>
            <div class="label--1">{{ addressText }}</div>
          </div>
        </div>
        <div
          *ngIf="showMatMenu"
          class="col-3 d-flex align-items-center justify-content-end cursor-pointer"
        >
          <app-menu xPosition="before">
            <ng-container triggerFor>
              <span class="icon icon-24-points-menu cursor-pointer"></span>
            </ng-container>
            <ng-container content>
              <app-menu-item (click)="editAddressItem()">
                <span class="text-label">Editar</span>
              </app-menu-item>
              <app-menu-item (click)="deleteAddressItem()">
                <span class="text-label">Eliminar</span>
              </app-menu-item>
            </ng-container>
          </app-menu>
        </div>
      </div>
    </div>
  </fp-border-container>
</div>
