import { Injectable } from "@angular/core";
import { ENDPOINTS } from "../../parameters/endpoints";
import { GenericHeaderService, GenericService } from "../generic";
import { BehaviorSubject, Observable } from "rxjs";
import { IRegisterResponse, RegisterResponse, IResponse } from "../../models/register/register.model";
import { Response, IUserOTPRequestParams, IValidateTokenResponse, ValidateTokenResponse } from "../../models/register/register.model";
import { map } from "rxjs/operators";
import { AuthImplementService } from "../../implements/auth/auth-implement.service";
import {
  UserRegisterRequest,
  EmailPhoneVerificationRequest,
  EmailVerificationRequest
} from "../../models/register/user-register-request.model";
import { TaggingService } from "src/app/atm-services/tagging.service";
import { HttpParams } from "@angular/common/http";
import { EBrand } from "@parameters/access/brand.parameter";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root"
})
export class RegisterClientService {
  private USER_SERVICE_ENDPOINT = ENDPOINTS.USER_SERVICE;
  private readonly UPDATE_USER_LEGACY_ENDPOINT = ENDPOINTS.UPDATE_USER_LEGACY;
	private readonly POST_UPDATE_USER_V2 = ENDPOINTS.POST_UPDATE_USER_V2;
  currentUserData$ = new BehaviorSubject<any>(null);

  public isMifaBrand: boolean = EBrand[environment.brand] == EBrand.mifarma;

  constructor(
    private _genericService: GenericService,
    private authImplementService: AuthImplementService,
    private _genericHeaderService: GenericHeaderService
  ) {}

  public saveOrUpdateUser$(body:| UserRegisterRequest | EmailPhoneVerificationRequest | EmailVerificationRequest, userUpdate: boolean = false, isMission: boolean = false, missionCode: string = ''): Observable<RegisterResponse> {
    const path = `${this.USER_SERVICE_ENDPOINT}/user-registration`;
    let params = new HttpParams();

    if (userUpdate) {
      params = params.set("userUpdate", "true");
    }

    if (this.isMifaBrand && userUpdate) {
      params = params.set("userUpdate", "true");
    }

    if (isMission) {
      params = params.set('isMission', 'true');
      params = params.set('missionCode', missionCode);
    }

    return this._genericService.genericPostWithoutSecurityRegister<IRegisterResponse>(path, body, params).pipe(
      map((response) => {
        return new RegisterResponse(response);
      })
    );
  }

  public partialUpdateUser$(bodyParams: Map<string, string>): Observable<RegisterResponse> {
    const uid = this.authImplementService.currentUID;
    const path = `${this.USER_SERVICE_ENDPOINT}/${uid}`;
    return this._genericService.genericPatch<IRegisterResponse>(path, bodyParams).pipe(
      map((response) => {
        const partialUpdateUserResponse = new RegisterResponse(response);
        return partialUpdateUserResponse;
      })
    );
  }

  public getUser$(email: string = null, shouldPassInkaclubValidation = false, userInfoValidation = false): Observable<RegisterResponse> {
    const uid = this.authImplementService.currentUID;
    let path = `${this.USER_SERVICE_ENDPOINT}/${uid}`;
    let params = new HttpParams();

    if (EBrand.inkafarma === environment.brand) {
      if (shouldPassInkaclubValidation) {
        params = params.set("inkaclubValidation", true);
      }
      if (userInfoValidation) {
        params = params.set("userInfoValidation", true);
      }
    } else if (EBrand.mifarma === environment.brand) {
      if (email) {
        params = params.set("email", email);
      }
      params = params.set("inkaclubValidation", true);
      params = params.set("userInfoValidation", true);
    }

    return this._genericService.genericGetWithoutSecurity<IRegisterResponse>(path, params).pipe(
      map((response) => {
        const registerResponse = new RegisterResponse(response);
        TaggingService.saveData(registerResponse);

        this.currentUserData$.next(registerResponse);

        return registerResponse;
      })
    );
  }

  public sendOtp$(phoneNumber: string, emailAddress: string, companyId: number) {
    const path = `${this.USER_SERVICE_ENDPOINT}/send-otp?phoneNumber=${phoneNumber}&emailAddress=${emailAddress}&companyId=${companyId}`;

    return this._genericService.genericPostWithoutSecurity<IResponse>(path, null).pipe(
      map((response) => {
        return new Response(response);
      })
    );
  }

  // Validar OTP
  public tokenValidator$(params: IUserOTPRequestParams) {
    const path = `${this.USER_SERVICE_ENDPOINT}/token-validator`;
    return this._genericService.genericPostWithoutSecurity<IValidateTokenResponse>(path, params).pipe(
      map((response) => {
        return new ValidateTokenResponse(response);
      })
    );
  }

  public updateUserLegacy$(params: UserRegisterRequest) {
    const headers = this._genericHeaderService.getCommonAuthUserLegacy();
    return this._genericService.genericPut<IResponse>(this.UPDATE_USER_LEGACY_ENDPOINT, params, headers);
  }

  public updateUser$(params: any) {
		const headers = this._genericHeaderService.getCommonAuthUserLegacy();
		return this._genericService.genericPost<IResponse>(
		  this.POST_UPDATE_USER_V2,
		  params
		);
	}
}
