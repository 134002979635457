import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fp-lazy-image',
  templateUrl: './lazy-image.component.html',
  styleUrls: ['./lazy-image.component.sass']
})
export class LazyImageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
