import { Component, Inject, OnInit } from "@angular/core";
import { ShowModalBoticaService } from "../../service/showModalServices/showModalService.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RetValidateCartService } from "../../service/cart/ret-validate-cart.service";
import { RET3_RAD_STORAGE_KEY } from "src/app/business/checkout/parameters/global-config";
import { StoresForPickUpStoreService } from "src/app/core/modules/pick-up-in-store/pick-up-stores/stores-for-pick-up-store.service";
import { AppStoreService } from "@stores/app/app-store.service";
import { ShoppingListStoreService } from "@stores/shopping-list/shopping-list-store.service";
import { ProductDetail } from "src/app/business/product-detail/models/product-detail-model";
import { ProductIsNotDeliveredComponent } from "../product-is-not-delivered/product-is-not-delivered.component";
import { BreakpointBehavior } from "../../behaviors";
import { BREAKPOINTS } from "@parameters/global";
import { OrderTakerValidator } from "@validators/order-taker.validator";
import { OfficeModalService } from "src/app/business/office/services/office-modal.service";
import { environment } from '@environments/environment';
import { EBrand } from '@parameters/access/brand.parameter';
import { LS_ADDRESS_SELECTED } from "../../service/local-storage/constants/address.constant";

@Component({
	selector: "fp-product-out-of-coverage-dialog",
	templateUrl: "./product-out-of-coverage-dialog.component.html",
	styleUrls: ["./product-out-of-coverage-dialog.component.sass"]
})
export class ProductOutOfCoverageDialogComponent implements OnInit {
	isDesktop: boolean;
  isMifaBrand = environment.brand === EBrand.mifarma;

	get isFromRad() {
		return this.retValidateCartService.isRADProcess;
	}

	get isFloat() {
		return this.dialogData.float;
	}

	get cartHaveSeller() {
		return this._shoppingListStore.getLocalStorageShoppingCartList()?.filter((product: ProductDetail) => product.sellerId);
	}

	get isResponsive() {
		return !this.isDesktop;
	}

	constructor(
		private showModalBoticaService: ShowModalBoticaService,
		private retValidateCartService: RetValidateCartService,
		private storesForPickUpStore: StoresForPickUpStoreService,
		private appStoreService: AppStoreService,
		private dialog: MatDialog,
		private dialogRef: MatDialogRef<ProductOutOfCoverageDialogComponent>,
		private _shoppingListStore: ShoppingListStoreService,
		private breakpointBehavior: BreakpointBehavior,
		private _officeModalService: OfficeModalService,
		@Inject(MAT_DIALOG_DATA) public dialogData: any
	) {}

	ngOnInit(): void {
		const behaviorSub = this.breakpointBehavior
			.mediaBreakpointUpObserver(BREAKPOINTS.lg)
			.subscribe((value) => (this.isDesktop = value.matches));
	}

	selectLocation() {
		if (OrderTakerValidator.isOrderTakerEnv()) {
			this.retValidateCartService.storeProcessType(RET3_RAD_STORAGE_KEY);
			this._officeModalService.openOfficeModal();
		} else {
			if (this.dialogData.address?.id) {
				const currentAddreses = this.dialogData.address;
				const selectedDrugstore = null;
				const ischangeRad = true;
				this.retValidateCartService
					.updateAndValidateCart(currentAddreses, selectedDrugstore, null, ischangeRad, null, this.dialogData.product)
					.subscribe((validateCart: any) => {
						if (validateCart.statusCode === "1") {
							// this.storesForPickUpStore.resetSelectedDrugstore();
							// this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "true");
							// this.retValidateCartService.storeProcessType(RET3_RAD_STORAGE_KEY);
							this.dialog.closeAll();
							window.location.reload();
						} else {
							// this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "false");
							// this.retValidateCartService.storeProcessType("RET");
							this.openAddressDialog();
						}
					});
			} else {
				// this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "false");
				// this.retValidateCartService.storeProcessType("RET");
				this.openAddressDialog();
			}
		}
	}

  selectDrugstore() {
    // this.appStoreService.setStorageItem("intensionRet", true);
    if (OrderTakerValidator.isOrderTakerEnv()) {
      this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "false");
      this.retValidateCartService.storeProcessType("RET");
      this._officeModalService.openOfficeModal();
    } else {
      if (this.isFromRad && this.cartHaveSeller.length) {
        this.dialog.open(ProductIsNotDeliveredComponent, { width: "450px", panelClass: "modal-in-footer", disableClose: true });
        return;
      }
      this.retValidateCartService.intentIsFromProduct = null;
      const currentAddreses = null;
      const selectedDrugstore = JSON.parse(this.appStoreService.getStorageItem("drugstoreSelected"));
      const drugstore = this.dialogData.drugstore;
      if (selectedDrugstore) {
        const isDelete = true;
        this.retValidateCartService
          .updateAndValidateCart(currentAddreses, selectedDrugstore, isDelete, null, null, this.dialogData.product)
          .subscribe((validateCart: any) => {
            if (validateCart.statusCode === "1") {
              if (selectedDrugstore) {
                this.retValidateCartService.storeProcessType("RET");
                this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "false");
                this.appStoreService.setStorageItem("drugstoreSelected", JSON.stringify(selectedDrugstore));
                this.storesForPickUpStore.setSelectedDrugstore(selectedDrugstore);
                this.storesForPickUpStore.setDrugstore.emit(selectedDrugstore);
                // this.appStoreService.setStorageItem("intensionRet", false);
                this.dialogRef.close();
                window.location.reload();
              }
            } else {
              if (this.isResponsive) {
                this.showModalBoticaService.showModalResponsiveService({}, null, null ,this.dialogData.product);
              } else {
              // this.retValidateCartService.storeProcessType("RAD");
              // this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "true");
              this.showModalBoticaService.showModalDesktopService({}, true, drugstore, false, this.dialogData.product);
              }
            }
          });
      } else {
        if (this.isResponsive) {
          this.showModalBoticaService.showModalResponsiveService({});
        } else {
          // this.retValidateCartService.storeProcessType("RAD");
          // this.appStoreService.setStorageItem(LS_ADDRESS_SELECTED, "true");
          this.showModalBoticaService.showModalDesktopService({}, true, drugstore, false);
        }
      }
    }
  }

	close() {
		this.dialogRef.close();
	}

	changeAddress() {
		if (OrderTakerValidator.isOrderTakerEnv()) {
			this.retValidateCartService.storeProcessType(RET3_RAD_STORAGE_KEY);
			this._officeModalService.openOfficeModal();
		} else {
      // this.appStoreService.setStorageItem("intensionRet", false);
			this.openAddressDialog();
		}
	}

	openAddressDialog() {
		this.retValidateCartService.openModalAddressList();
	}

	changeDrugstore() {
		this.retValidateCartService.intentIsFromProduct = null;
		if (this.isResponsive) this.showModalBoticaService.showModalResponsiveService({});
		else this.showModalBoticaService.showModalDesktopService({}, true, this.dialogData.drugstore, false);
	}
}
