import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Coupon } from '../../../../business/savings-wallet/models/coupon.model';

@Component({
  selector: 'app-modal-invalid-coupon',
  templateUrl: './modal-invalid-coupon.component.html',
  styleUrls: ['./modal-invalid-coupon.component.scss']
})
export class ModalInvalidCouponComponent implements OnInit {

  public cuponCode:string = '';
  constructor(@Inject(MAT_DIALOG_DATA) public data:any, public dialogRef: MatDialogRef<ModalInvalidCouponComponent>,) { }

  ngOnInit() {
    this.cuponCode = this.data.cuponCode;
  }

  public removeDialog() {
    this.dialogRef.close();
  }

}
