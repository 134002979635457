import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-sub-section-container',
  templateUrl: './sub-section-container.component.html',
  styleUrls: ['./sub-section-container.component.sass'],
})
export class SubSectionContainerComponent implements OnInit {
  @Input() flagBorderTop = true;

  constructor() {
  }

  ngOnInit() {
  }
}
