import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../icons/icons.module';
import { TagBaseComponent } from './tag-base/tag-base.component';
import { TagSoldOutComponent } from './tag-sold-out/tag-sold-out.component';
import { TagDeleteComponent } from './tag-delete/tag-delete.component';
import { TagLowPriceComponent } from './tag-low-price/tag-low-price.component';
import { TagDefaultComponent } from './tag-default/tag-default.component';
import { TagFilterSelectedDesktopComponent } from './tag-filter-selected-desktop/tag-filter-selected-desktop.component';
import { TagPredictiveComponent } from './tag-predictive/tag-predictive.component';

const COMPONENTS = [
  TagSoldOutComponent,
  TagDeleteComponent,
  TagLowPriceComponent,
  TagDefaultComponent,
  TagFilterSelectedDesktopComponent,
  TagPredictiveComponent
];

@NgModule({
  declarations: [
    TagBaseComponent,
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    IconsModule,
  ]
})
export class TagsModule {
}
