import { Injectable } from "@angular/core";
import { IServicesTypes } from "@models/services-type/services-type.interface";
import { StoreBackup } from "@models/store-backup.model";
import { DISPATCH_TYPE, SERVICES_TYPES_ENUM } from "@parameters/global";
import { LS_ZONE_BACKUP } from "@service/local-storage/order-taker/order-taker.contanst";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { Store } from "src/app/shared/models/store.model";
import { RetValidateCartService } from "src/app/shared/service/cart/ret-validate-cart.service";
import { StorageClientService } from "src/app/shared/storage/storage-client.service";

@Injectable({
  providedIn: "root"
})
export class SelectDataStoreService {
  private readonly SERVICE_SELECTED = "OT_SERVICE_SELECTED";
  private readonly STORE_SELECTED = "OT_STORE_SELECTED";
  private readonly STATE = "OT_STATE";
  private readonly SERVICES_CAP = "OT_SERVICES_CAP";
  public zoneBackups: StoreBackup;
  isReadyToReset = false;
  // flagOnly = 1;
  private _nearbyStoreSubject: BehaviorSubject<Store> = new BehaviorSubject<Store>(null);

  private _storeBackupSubject: BehaviorSubject<StoreBackup> = new BehaviorSubject<StoreBackup>(null);

  private _serviceSelectedSubject: BehaviorSubject<string> = new BehaviorSubject(null);

  private _flagStateSubject: BehaviorSubject<number> = new BehaviorSubject(null);

  private _servicesTypeStateSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  public nearbyStore$: Observable<Store> = this._nearbyStoreSubject.asObservable();
  public serviceSelected$: Observable<string> = this._serviceSelectedSubject.asObservable();
  public flagState$: Observable<number> = this._flagStateSubject.asObservable();
  public servicesTypeState$: Observable<any> = this._servicesTypeStateSubject.asObservable();

  callAddress = 0;
  constructor(private _storageClientService: StorageClientService, private retValidateCartService: RetValidateCartService) {
    const store = JSON.parse(this._storageClientService.getStorageItem(this.STORE_SELECTED)) as Store;

    if (store) {
      this.setNearbyStore(store);
    }
  }

  public get nearbyStore(): Store {
    return JSON.parse(this._storageClientService.getStorageItem(this.STORE_SELECTED)) as Store;
  }
  public get nearbyStoreValue(): Store {
    return this._nearbyStoreSubject.value;
  }
  public setNearbyStore(data: Store) {
    this._nearbyStoreSubject.next(data);
  }

  public saveNearbyStore(data: Store) {
    this._storageClientService.setStorageItem(this.STORE_SELECTED, JSON.stringify(data));
  }
  public get serviceSelected(): string {
    return this._serviceSelectedSubject.value ?? (JSON.parse(this._storageClientService.getStorageItem(this.SERVICE_SELECTED)) as string);
  }

  public setServiceSelected(value: string) {
    this._serviceSelectedSubject.next(value);
  }

  public saveServiceSelected(value: string) {
    this._storageClientService.setStorageItem(this.SERVICE_SELECTED, JSON.stringify(value));
  }

  public get zoneBackup(): StoreBackup {
    return this._storeBackupSubject.value ?? (JSON.parse(this._storageClientService.getStorageItem(LS_ZONE_BACKUP)) as StoreBackup);
  }
  public setZoneBackup(value: StoreBackup) {
    this.zoneBackups = value;
    this._storeBackupSubject.next(value);
  }

  public get readyToReset() {
    return this.isReadyToReset;
  }
  public setReadyToReset(value: boolean) {
    this.isReadyToReset = value;
  }

  public saveZoneBackup(value: StoreBackup) {
    this._storageClientService.setStorageItem(LS_ZONE_BACKUP, JSON.stringify(value));
  }

  public get flagState(): number {
    return this._flagStateSubject.value;
  }

  public set flagState(value: number) {
    this._flagStateSubject.next(value);
  }

  // public getflagState(): number {
  //   return JSON.parse(this._storageClientService.getStorageItem(this.STATE)) as number;
  // }
  // public setflagState(value: number) {
  //   this._storageClientService.setStorageItem(this.STATE, JSON.stringify(value));
  // }

  public get servicesTypeState(): IServicesTypes[] {
    return this._servicesTypeStateSubject.value;
  }

  public set servicesTypeState(value: IServicesTypes[]) {
    this._servicesTypeStateSubject.next(value);
  }

  public get servicesTypeStore(): IServicesTypes[] {
    return JSON.parse(this._storageClientService.getStorageItem(this.SERVICES_CAP)) as IServicesTypes[];
  }
  public saveServicesTypeState(value: IServicesTypes[]) {
    this._storageClientService.setStorageItem(this.SERVICES_CAP, JSON.stringify(value));
  }

  public saveAll(isRetOrRad?) {
    if(isRetOrRad === DISPATCH_TYPE.PICKUP_IN_STORE){
      this.clearStoresAndZoneBackup();
    } else if(isRetOrRad === DISPATCH_TYPE.DELIVERY){
      this.clearStores();
    } else {
      this.clearStoresAndZoneBackup();
    }
    this.retValidateCartService.storeProcessType(isRetOrRad)
    this.saveNearbyStore(this.nearbyStoreValue);
    this.saveServiceSelected(this.serviceSelected);

    // this.setflagState(this.flagState);
    this.saveZoneBackup(this.zoneBackups);
    this.saveServicesTypeState(this.servicesTypeState);
  }

  public clearStates() {
    // this.setNearbyStore(null);
    this.flagState = null;
    this.isReadyToReset = false;
    this.setServiceSelected(null);
    this.setZoneBackup(null);
    this.servicesTypeState = null;
  }

  public clearStoresAndZoneBackup() {
    this._storageClientService.removeStorageItem(LS_ZONE_BACKUP);
    this.clearStores();
  }

  public clearStores() {
    this._storageClientService.removeStorageItem(this.STORE_SELECTED);
    this._storageClientService.removeStorageItem(this.SERVICE_SELECTED);
    // this._storageClientService.removeStorageItem(this.STATE);
    this._storageClientService.removeStorageItem(this.SERVICES_CAP);
    this._storageClientService.removeStorageItem("drugstoreSelected");
  }

  clearStoreSelectFromStorage() {
    this._storageClientService.removeStorageItem(this.STORE_SELECTED);
  }
}
