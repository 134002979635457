import { Injectable } from '@angular/core';
import { GenericContentfulService } from '../generic';
import { CONTENTFUL_PATH } from '../../parameters/endpoints';
import { CONTENTFUL_QUERIES } from '../../parameters/querys';

@Injectable()
export class BlogService {
  constructor(
    private genericContentfulService: GenericContentfulService,
  ) {
  }

  public getBlogItemBySlug(slug: string) {
    return this.genericContentfulService.getEntries(
      CONTENTFUL_PATH.contentTypeIds.worldBlogLanding,
      CONTENTFUL_QUERIES.prototype.getEntryBySlug(slug)
    );
  }

}
