import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CouponsStoreService {

  private _coupons: string[];
  private _couponsSubject = new BehaviorSubject<string[]>([]);
  public coupons$ = this._couponsSubject.asObservable();
  public get coupons(): string[] {
    return this._coupons;
  }

  constructor() {
    this.coupons$.subscribe((coupons) => this._coupons = coupons);
  }

  public setCoupons(coupons: string[]) {
    this._couponsSubject.next(coupons);
  }

  public addCoupon(coupon: string) {
    const updatedCoupons = [...this.coupons];
    updatedCoupons.push(coupon);
    this._couponsSubject.next(updatedCoupons);
  }

  public removeCoupon(coupon: string) {
    const updatedCoupons = [...this.coupons].filter(_coupon => _coupon !== coupon);
    this._couponsSubject.next(updatedCoupons);
  }
}
