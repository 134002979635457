import {Component, EventEmitter, Input, Output} from '@angular/core';
import { Router } from '@angular/router';
import { TaggingService } from 'src/app/atm-services/tagging.service';
import { SearchLandingStoreService } from 'src/app/business/search-landing/store/search-landing-store.service';
import {OrderBy} from '../../../../shared/models';

enum DEVICE_TYPE {
  DESKTOP = 'desktop',
  RESPONSIVE = 'responsive',
}

@Component({
  selector: 'fp-order-by',
  templateUrl: './order-by.component.html',
  styleUrls: ['./order-by.component.sass']
})
export class OrderByComponent {

  @Input() selected: OrderBy;
  public isSCategories: boolean;
  public orderListByString: Array<string>;
  public orderListCategoriesByString: Array<string>;
  public orderListCategories: OrderBy[] = [
    {id: 2, sort: 'name', name: 'Nombre A - Z', order: 'ASC', value: 'ASC'},
    {id: 3, sort: 'name', name: 'Nombre Z - A', order: 'DESC', value: 'DESC'},
    {id: 4, sort: 'price', name: 'Precio (menor a mayor)', order: 'ASC', value: 'ASC'},
    {id: 5, sort: 'price', name: 'Precio (mayor a menor)', order: 'DESC', value: 'DESC'},
    {id: 6, sort: 'ranking', name: 'Relevancia', order: 'ASC',value: 'ASC'},
  ];
  public orderList: OrderBy[] = [];
  public defaultTypes = {desktop: DEVICE_TYPE.DESKTOP, responsive: DEVICE_TYPE.RESPONSIVE};
  @Input() orderByType: DEVICE_TYPE;
  @Output() orderBy = new EventEmitter<OrderBy>();

  constructor(private _searchLandingStore: SearchLandingStoreService, private router: Router) {
    this.router.url.includes('/categoria/') ? this.isSCategories = true : this.isSCategories = false;
    this.orderList = this._searchLandingStore.algoliaReplicas;
    this.orderListByString = this.orderList.map(order => order.name);
    this.orderListCategoriesByString = this.orderListCategories.map(order => order.name);
  }

  selectedOrder(order: CustomEvent) {
    const selectedOrderBy: OrderBy = this.orderList.find(orderBy => orderBy.name === String(order));
    const selectedCategoriesOrderBy: OrderBy = this.orderListCategories.find(orderBy => orderBy.name === String(order));
    this.changeOrder(this.isSCategories ? selectedCategoriesOrderBy : selectedOrderBy);
    TaggingService.orderProductsFilter(this.isSCategories ? selectedCategoriesOrderBy?.value : selectedOrderBy?.value);
  }

  changeOrder(order) {
    if (order !== this.selected) {
      this.selected = order;
      this.orderBy.emit(this.selected);
    }
  }

}
