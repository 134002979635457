<ng-container *ngIf="link">
    <a *ngIf="isRouterLink"
        class="router-link"
        [routerLink]="'/' + linkRouter"
        [queryParams]="queryParams"
        queryParamsHandling="merge"
        >
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </a>
    <a *ngIf="!isRouterLink"
        class="router-link"
        href="{{link}}"
       [target]="linkTarget"
        >
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </a>
    <ng-template #contentTpl><ng-content></ng-content></ng-template>

</ng-container>

