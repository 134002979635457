import { Injectable } from '@angular/core';
import { FilteredProductClientService } from '../../../../shared/service/filtered-products/filtered-product-client.service';
import { FilteredProductsRequest } from '../../../../shared/models/filtered-products/filtered-products-request.model';

@Injectable()
export class FilteredProductsImplementService {
  constructor(
    private _filteredProductClient: FilteredProductClientService
  ) {
  }

  getFilteredProducts(filteredProductsRequest: FilteredProductsRequest) {
    return this._filteredProductClient.getFilteredProducts(filteredProductsRequest);
  }
}
