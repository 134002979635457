import { SearchOptions } from "@algolia/client-search";
import { RequestOptions } from "@algolia/transporter";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LOCALSTORAGE_SHOPPING_CART_LIST } from "@stores/shopping-list/constants/shopping-list.constant";
import algoliasearch from "algoliasearch/lite";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import aa, { InsightsEvent } from "search-insights";
import { LocalStorageService } from "src/app/shared/service/local-storage/local-storage.service";
import { SessionStorageService } from "src/app/shared/service/session-storage/session-storage.service";
import { environment } from "src/environments/environment";
import {
	ALGOLIA_AUTHENTICATED_USER_TOKEN,
	ALGOLIA_QUERY_ID,
	ALGOLIA_USER_TOKEN,
	LOCALSTORAGE_ALGOLIA_CONVERTED_OBJECT_IDS,
	SESSIONSTORAGE_ALGOLIA_CLICKED_FILTERS
} from "./constants/algolia-keys.constant";
import { CustomProductMatch, IAlgoliaObjectData, ICustomProductMatch, IProductMatch } from "./interfaces/algolia-searcher.interface";
import { IGenericConfiguration } from "@models/contentful/contentful-generic-configuration.model";
import { EBrand } from "@parameters/access/brand.parameter";
import { CONTENFUL_CUSTOM_KEYS } from "@parameters/contenful";
import { CONTENTFUL_PATH } from "@parameters/endpoints";
import { CONTENTFUL_QUERIES } from "@parameters/querys";
import { Entry } from "contentful";
import { IPickUpInStoreConfig } from "../pick-up-in-store/interfaces/pick-up-in-store-contenful-config.interface";
import { GenericContentfulService } from "@service/generic";
import { ContenfulConfigService } from "@service/contenful/contenful-config/conteful-config-.service";
import { ProductCard } from "../product-card/models/product-card/product-card.model";
import { ProductPresentation } from "../product-card/models/product-presentation.model";
import { ProductDetail } from "src/app/business/product-detail/models/product-detail-model";
import { ProductShoppingCart } from "../product-card/models/product-shopping-cart/product-shopping-cart.model";
import { TIMER_DEFECT_ALGOLIA } from "src/app/business/checkout/parameters/constants";
import { OrderTakerValidator } from "@validators/order-taker.validator";

const { algolia_for_consulta_stock } = environment;
const client = algoliasearch(algolia_for_consulta_stock.appId, algolia_for_consulta_stock.apiKey);
const index = client.initIndex(algolia_for_consulta_stock.indexName);

export const KEYS_TO_GET_FROM_ALGOLIA = ["objectID", "name", "uri", "CODIGO_INKAFARMA", "CODIGO_MIFARMA"];
export const NUMBER_OF_MATCHES = 5;

export interface REDIRECT_ALGOLIA {
	enter:boolean,
	click_lupa:boolean,
	keyword:string,
	isRedirect:boolean,
	urlRedirect:string
}

@Injectable({
	providedIn: "root"
})
export class AlgoliaInstanceService {
	private indexAlgolia = environment.algolia.indexName;
	private algoliaEnv = environment.algolia;
	private patternUrlInvalidProduct = /^\/producto\//;
	private patternUrlInvalidSearch = /^\/buscador\?keyword=/;
	private busquedaAlgoliaSubject = new BehaviorSubject<REDIRECT_ALGOLIA>({
		enter: false,
		click_lupa: false,
		keyword: '',
		isRedirect: false,
		urlRedirect:''
	});

  private timerSearch = TIMER_DEFECT_ALGOLIA;
	constructor(
		private localStorageService: LocalStorageService,
		private sessionStorageService: SessionStorageService,
		private contenfulConfigService: ContenfulConfigService,
		private router: Router
	) {}

	public setBusquedaEnter() {
		const currentObject = this.busquedaAlgoliaSubject.value;
		const newObject: REDIRECT_ALGOLIA = { ...currentObject, enter: true, click_lupa: false };
		this.busquedaAlgoliaSubject.next(newObject);
	}
	
	public setBusquedaClickLupa() {
		const currentObject = this.busquedaAlgoliaSubject.value;
		const newObject: REDIRECT_ALGOLIA = { ...currentObject, click_lupa: true, enter: false };
		this.busquedaAlgoliaSubject.next(newObject);
	}

	public setBusquedaKeyword(keyword: string) {
		const currentObject = this.busquedaAlgoliaSubject.value;
		const newObject: REDIRECT_ALGOLIA = { ...currentObject, keyword };
		this.busquedaAlgoliaSubject.next(newObject);
	}
	
	public setRedirectStatus(isRedirect: boolean, urlRedirect:string) {
		const currentObject = this.busquedaAlgoliaSubject.value;
		const newObject: REDIRECT_ALGOLIA = { ...currentObject, isRedirect, urlRedirect };
		this.busquedaAlgoliaSubject.next(newObject);
	}

	// Borrar el redirect cuando escriban algo en el input
	public clearRedirect() {
		const newObject: REDIRECT_ALGOLIA = {
		  enter: false,
		  click_lupa: false,
		  keyword: '',
		  isRedirect: false,
		  urlRedirect: ''
		};
	  
		// Obtener el valor actual del BehaviorSubject
		const currentObject = this.busquedaAlgoliaSubject.value;
	  
		// Convertir ambos objetos a cadenas JSON para compararlos
		if (JSON.stringify(currentObject) !== JSON.stringify(newObject)) {
		  this.busquedaAlgoliaSubject.next(newObject);
		}
	}

	public getBusquedaAlgolia(){
		return this.busquedaAlgoliaSubject.asObservable();
	}

  public set timerSearchValue(data:any) {
    const debounceTimeVar = data?.configuration?.debounceTime;
    this.timerSearch = TIMER_DEFECT_ALGOLIA;

    if(OrderTakerValidator.isOrderTakerEnv()){
      this.timerSearch = debounceTimeVar?.tomador || TIMER_DEFECT_ALGOLIA;
    }else{
      this.timerSearch = debounceTimeVar?.web || TIMER_DEFECT_ALGOLIA;
    }
  }
  public get timerSearchValue(): number {
    return this.timerSearch;
  }
	public getIndexSearch$(query: string, requestOptions?: RequestOptions & SearchOptions) {
		return new Observable<ICustomProductMatch[]>((subscriber) => {
			index
				.search<ICustomProductMatch>(query, requestOptions)
				.then((algoliaSearchResponse) => {
					subscriber.next(algoliaSearchResponse.hits);
				})
				.catch((err) => {
					subscriber.error(err);
				});
		});
	}

	public getCurrentIndexSearch$(query: string, requestOptions?: RequestOptions & SearchOptions) {
		return this.getIndexSearch$(query, requestOptions).pipe(
			map((_currentResponse) => {
				const matches = Array.isArray(_currentResponse)
					? _currentResponse.map((c) => new CustomProductMatch(c))
					: ([] as CustomProductMatch[]);

				const formattedMatches = matches.map((match) => {
					return {
						name: match.highlightResult.NOMBRE_FRIENDLY.value,
						objectID: match.objectID,
						uri: ""
					} as IProductMatch;
				});
				return formattedMatches.filter((match) => match.objectID !== "");
			})
		);
	}

	public initAlgoliaAA() {
		aa("init", {
			appId: this.algoliaEnv.appId,
			apiKey: this.algoliaEnv.apiKey,
			useCookie: true, // since v2, this is false by default
			cookieDuration: 60 * 60 * 1000 * 24
		});
	}

	public getConfigurationAlgolia(): Observable<any> {
		const keyConfigurationSelected =
			environment.brand === EBrand.inkafarma
				? CONTENFUL_CUSTOM_KEYS.inkafarmaConfiguration.ALGOLIA_CONF_ID
				: CONTENFUL_CUSTOM_KEYS.contentfulConfiguration.ALGOLIA_CONF_ID;

		const contenfulPath =
			environment.brand === EBrand.inkafarma
				? CONTENTFUL_PATH.contentTypeIds.INKAFARMA_CONFIGURATION_KEY
				: CONTENTFUL_PATH.contentTypeIds.MIFARMA_CONFIGURATION_KEY;

		return this.contenfulConfigService.getCloudConfigurationByKey$<any>(keyConfigurationSelected, contenfulPath).pipe(
			map((entry) => {
        this.timerSearchValue = entry.fields;
        return entry.fields
      }),
			catchError((err) => {
				console.error(err);
				return of({} as any);
			})
		);
	}

	// public algoliaSetUserToken(userAnonimoId:string) {
	// 	aa("setUserToken", userAnonimoId);
	// }

	public setAuthenticateUserToken(userId: string) {
		aa("setAuthenticatedUserToken", userId);
		this.localStorageService.setItem(ALGOLIA_AUTHENTICATED_USER_TOKEN, userId);
	}

	public algoliaGetUserToken() {
		aa("getUserToken", null, (err, userToken) => {
			if (err) {
				console.error(err);
				this.localStorageService.deleteItem(ALGOLIA_AUTHENTICATED_USER_TOKEN);
				this.localStorageService.deleteItem(ALGOLIA_USER_TOKEN);
				return;
			}
			this.localStorageService.setItem(ALGOLIA_USER_TOKEN, userToken);
		});
	}

	public algoliaViewObjectsIDS(hits: string[]) {
		const obj = {
			index: this.indexAlgolia,
			eventName: "Search Hits Viewed",
			objectIDs: hits
		};

		aa("viewedObjectIDs", obj);
	}

	public algoliaClickedObjectIDsAfterSearch( objectIDs: string[], positions: number[]) {
    const queryID = this.localStorageService.getItem(ALGOLIA_QUERY_ID) ?? '';

		const obj = {
			index: this.indexAlgolia,
			eventName: "Product Clicked AfterSearch",
			queryID,
			objectIDs,
			positions
		};

		aa("clickedObjectIDsAfterSearch", obj);
	}

	public getPositionProductLarge(objectID): number {
		const $productList = document.querySelectorAll("fp-filtered-product-list  fp-product-large");
		let index = 0;
		$productList.forEach((n, i) => {
			const $nodeProduct = n.querySelector(`[id="${objectID}"]`);
			if ($nodeProduct) index = i + 1;
		});

		return index;
	}

	public algoliaPreClickedObjectIDsAfterSearch(objectID: string) {
		const position = this.getPositionProductLarge(objectID);

		this.algoliaClickedObjectIDsAfterSearch([objectID], [position]);
	}

	public algoliaClickedObjectIDs(objectID: string) {
		try {
			if (this.validatePathSearchOrProduct()) {
				this.algoliaPreClickedObjectIDsAfterSearch(objectID);
				return;
			}

			const obj = {
				index: this.indexAlgolia,
				eventName: "Product Clicked",
				objectIDs: [objectID]
			};

			aa("clickedObjectIDs", obj);
		} catch (error) {
			console.log("Error algoliaClickedObjectIDs:", error);
		}
	}

	validatePathSearchOrProduct(): boolean {
		const currentPathUrl = this.router.url;
		const isPathSearchOrProduct =
			this.patternUrlInvalidProduct.test(currentPathUrl) || this.patternUrlInvalidSearch.test(currentPathUrl);
		return isPathSearchOrProduct;
	}

	//#region algoliaConvertedObjectIDs
	public algoliaConvertedObjectIDs(total: number) {
		try {
			const objectIDsInShoppingCart = this.getObjectIDsInShoppingCartList();

			if (!objectIDsInShoppingCart.length) return;

      const objectData = this.getObjectDataInShoppingCartList();

			const obj = {
				index: this.indexAlgolia,
				eventName: "Convert Products Checkout",
				objectIDs: objectIDsInShoppingCart,
        value: total,
        currency: 'PEN',
        objectData
			};

			aa("convertedObjectIDs", obj);
		} catch (error) {
			console.log("Error algoliaConvertedObjectIDs:", error);
		}
	}

	getObjectIDsInShoppingCartList(): string[] {
		const shoppingCartList = JSON.parse(localStorage.getItem(LOCALSTORAGE_SHOPPING_CART_LIST)) as Array<any>;
		const objectIDsInShoppingCart = shoppingCartList.map((item) => item.productId);

		return objectIDsInShoppingCart;
	}

  getObjectDataInShoppingCartList() {
    const localStorageObjectIDs =
				(this.localStorageService.getItem(LOCALSTORAGE_ALGOLIA_CONVERTED_OBJECT_IDS) as Array<IAlgoliaObjectData>) ?? [];
		const shoppingCartList = JSON.parse(localStorage.getItem(LOCALSTORAGE_SHOPPING_CART_LIST)) as Array<any>;
    const objectData = [];

    for (let product of shoppingCartList) {
      const objectLocal = localStorageObjectIDs.find((sc) => sc.sku === product.productId);

      objectData.push({
        queryID: objectLocal.queryID || undefined,
        price: objectLocal.price,
        discount: objectLocal.discount,
        quantity: objectLocal.quantity
      });
    }

		return objectData;
	}
	//#endregion

	addConvertedObjectID(product: ProductCard | ProductDetail | ProductShoppingCart, productPresentation: ProductPresentation, quantity: number, isAlgolia: boolean) {
    const queryID = this.localStorageService.getItem(ALGOLIA_QUERY_ID) ?? '';
    const discount = productPresentation.regularPrice != 0 ? productPresentation.price - productPresentation.regularPrice : 0;
    const sessionObjectIDs =
			(this.localStorageService.getItem(LOCALSTORAGE_ALGOLIA_CONVERTED_OBJECT_IDS) as Array<IAlgoliaObjectData>) ?? [];
    const productAddedPreviusly = sessionObjectIDs.find(s => s.sku === product.id);

		const objectID: IAlgoliaObjectData = {
			price: productPresentation.price,
			discount: Number(discount.toFixed(2)),
			quantity: quantity,
			sku: product.id
		};

    if(isAlgolia) objectID['queryID'] = queryID;

		const objectIDs = sessionObjectIDs.filter((sObjectID) => sObjectID.sku !== product.id);

		this.localStorageService.setItem(LOCALSTORAGE_ALGOLIA_CONVERTED_OBJECT_IDS, [...objectIDs, objectID]);
	}

  removeUnitConvertedObjectId(objectId: string, quantity: number) {
    const sessionObjectIDs =
			(this.localStorageService.getItem(LOCALSTORAGE_ALGOLIA_CONVERTED_OBJECT_IDS) as Array<IAlgoliaObjectData>) ?? [];
    const product = sessionObjectIDs.find(s => s.sku === objectId);

    if(!product) return;

    product.quantity = quantity;

    console.log(product)

    const objectIDs = sessionObjectIDs.filter((sObjectID) => sObjectID.sku !== objectId);
		this.localStorageService.setItem(LOCALSTORAGE_ALGOLIA_CONVERTED_OBJECT_IDS, [...objectIDs, product]);
  }

	removeConvertedObjectID(objectID: string) {
		const sessionObjectIDs =
			(this.localStorageService.getItem(LOCALSTORAGE_ALGOLIA_CONVERTED_OBJECT_IDS) as Array<IAlgoliaObjectData>) ?? [];
		const objectIDs = sessionObjectIDs.filter((sObjectID) => sObjectID.sku !== objectID);
		this.localStorageService.setItem(LOCALSTORAGE_ALGOLIA_CONVERTED_OBJECT_IDS, objectIDs);
	}

	public algoliaClickedFilters(filters: string[]) {
		try {
			const obj = {
				index: this.indexAlgolia,
				eventName: "Filters Clicked",
				filters
			};

			aa("clickedFilters", obj);
			this.sessionStorageService.setItem(SESSIONSTORAGE_ALGOLIA_CLICKED_FILTERS, filters);
		} catch (error) {
			console.log("Error algoliaClickedFilters:", error);
		}
	}

	public algoliaConvertedFilters() {
		try {
			const filters = this.sessionStorageService.getItem(SESSIONSTORAGE_ALGOLIA_CLICKED_FILTERS);
			if (!filters) return;

			const obj = {
				index: this.indexAlgolia,
				eventName: "Convert Filters Clicked",
				filters
			};

			aa("convertedFilters", obj);
		} catch (error) {
			console.log("Error algoliaConvertedFilters: ", error);
		}
	}

	public convertedAfterSearch(objectIDs: string[]) {
		try {
			const queryID = this.localStorageService.getItem(ALGOLIA_QUERY_ID) ?? '';

			const obj = {
				index: this.indexAlgolia,
				eventName: "Convert After Search",
				queryID,
				objectIDs: objectIDs
			};

			aa("convertedObjectIDsAfterSearch", obj);
		} catch (error) {
			console.log("Error algoliaConvertedAfterSearch: ", error);
		}
	}

	public addedToCartObjectIDsAfterSearch(objectIDs: string[], objectData: IAlgoliaObjectData[]) {
		try {
			const queryID = this.localStorageService.getItem(ALGOLIA_QUERY_ID)?? '';

			const obj = {
				index: this.indexAlgolia,
				eventName: "Added to Cart - After Search",
				queryID,
				objectIDs,
				objectData,
				value: objectData.reduce((acc, curr) => acc + curr.price * curr.quantity, 0),
				currency: "PEN"
			};

			aa("addedToCartObjectIDsAfterSearch", obj);
		} catch (error) {
			console.log("Error algoliaConvertedAfterSearch: ", error);
		}
	}

	public purchasedObjectIDsAfterSearch(total: number) {
		try {
			const objectIDs = this.getObjectIDsInShoppingCartList();
			const objectData = this.getObjectDataInShoppingCartList();

			const obj = {
				index: this.indexAlgolia,
				eventName: "Purchase ObjectIDs - After Search",
				objectIDs,
				objectData,
				value: total,
				currency: "PEN"
			};

			aa("purchasedObjectIDsAfterSearch", obj);
			this.localStorageService.deleteItem(LOCALSTORAGE_ALGOLIA_CONVERTED_OBJECT_IDS)
		} catch (error) {
			console.log("Error purchasedObjectIDsAfterSearch: ", error);
		}
	}
}
