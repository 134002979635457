
<div class="check-list-password mb-2" *ngIf="!isAllVerified">
    <div class="check-list-content">
      <div class="check-list-item label--3 mb-2" [ngClass]="{'check-verify' :verifyCharacter}">
        <span
          class="icon"
          [ngClass]="{
            'icon-16-check-purple': verifyCharacter,
            'icon-16-check-gray': !verifyCharacter
          }">
        </span>
          Mín 8 caracteres
      </div>
      <div class="check-list-item label--3" [ngClass]="{'check-verify' :verifyCapital}">
        <span
          class="icon"
          [ngClass]="{
            'icon-16-check-purple': verifyCapital,
            'icon-16-check-gray': !verifyCapital
          }">
        </span>
        Una mayúscula
      </div>
    </div>
    <div class="check-list-content">
      <div class="check-list-item label--3 mb-2" [ngClass]="{'check-verify' :verifyLower}">
        <span
          class="icon"
          [ngClass]="{
            'icon-16-check-purple': verifyLower,
            'icon-16-check-gray': !verifyLower
          }">
        </span>
        Una minúscula
      </div>
      <div class="check-list-item label--3" [ngClass]="{'check-verify' :verifyNumber}">
        <span
          class="icon"
          [ngClass]="{
            'icon-16-check-purple': verifyNumber,
            'icon-16-check-gray': !verifyNumber
          }">
        </span>
        Un número
      </div>
    </div>
</div>
