<div class="input-control" [ngClass]="{
  'margin-with-error': marginWithError,
  'margin-without-error': !marginWithError,
  'margin-bottom-zero': marginBottomZero
}">
  <div class="input-control-title label--1">
    {{inputTitle}}
  </div>
  <ng-content></ng-content>
  <div [class]="errorMessageClass" class="label--2 errorMessage"
    [ngClass]="{'join-to-input': errorMsgJoinToInput}"
    *ngIf="errorMessage">
    {{errorMessage}}
  </div>
</div>