import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class DomDocumentHelperService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  public removeScroll() {
    this.document.body.setAttribute('style', 'overflow-y: hidden;');
  }

  public addScroll() {
    this.document.body.setAttribute('style', '');
  }
}
