import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagCheckoutComponent } from './tag-checkout/tag-checkout.component';
import { TagTypesBuildingService } from './services/tag-types-building.service';
import { TagCheckoutV2Component } from './tag-checkout-v2/tag-checkout-v2.component';

const COMPONENTS = [
  TagCheckoutComponent,
  TagCheckoutV2Component
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...COMPONENTS,
  ],
  providers: [
    TagTypesBuildingService,
  ]
})
export class TagsCheckoutModule { }
