import { MatDialogConfig } from "@angular/material/dialog";
import { DOCUMENT_TYPE } from "@parameters/global";

export const CLIENT_MANAGER_MODAL_CONFIG: MatDialogConfig = {
  width: "608px",
  height: "auto",
  disableClose: true,
  panelClass: "custom-full-all-modal-overlay",
};

export enum TYPE_CLIENT_MANAGER {
  NEW_CLIENT = 1,
  UPDATE_CLIENT = 2,
}

export const DOCUMENT_TYPE_DNI = {
  name: "DNI",
  id: DOCUMENT_TYPE.DNI,
};

export const DOCUMENT_TYPE_CE = {
  name: "Carnet de Extranjería",
  id: DOCUMENT_TYPE.CE,
};

export const DOCUMENT_TYPE_PAS = {
  name: "Pasaporte",
  id: DOCUMENT_TYPE.PAS,
};

export const DOCUMENT_TYPES = [
  DOCUMENT_TYPE_DNI,
  DOCUMENT_TYPE_CE,
  DOCUMENT_TYPE_PAS,
];

export interface ClientManagerDataForm {
  document: string;
  identityDocumentType: string;
  names: string;
  paternalLastname: string;
  maternalLastname: string;
  emailAddress: string;
  phoneNumber: string;
  phoneNumberOne?: string;
  phoneNumberTwo?: string;
  termsAndPolitics?: boolean;
  dataTreatment?: boolean;
  politicsPrivacy?:boolean;
}

export interface ClientManagerBaseRequest {
  idIdentityDocument: string;
  identityDocumentNumber: string;
  name: string;
  lastNamePat: string;
  lastNameMat: string;
  email: string;
  phone: string;
  phone2: string;
  phone3: string;
  isAcceptTyC?: boolean;
  isDataTreatment?: boolean;
  isPrivacyPolicy?:boolean;
}

export interface ClientManagerUpdateRequest extends ClientManagerBaseRequest {
  id: string;
}
