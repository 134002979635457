<div
  class="product-information"
  [class.sold-out]="productStatus === eProductStatus.unavailable"
>
  <div class="product-observation d-flex">
    <p class="mb-1">
      <span
        class="text product-name"
        [ngClass]="{ 'product-name-ordertaker': (flagOrderTaker && guaranteed) }"
        >{{ name }}
      </span>
    </p>
    <!-- <div
      class="product-observation__icon"
      *ngIf="
        prescription && prescription === productPrescriptionEnum.PRESCRIPTION
      "
    >
      <div class="product-observation__button">
        <fp-icon iconName="prescription" iconSize="12"></fp-icon>
      </div>
      <span class="product-observation__tooltip">{{
        prescription | titlecase
      }}</span>
    </div> -->
  </div>
  <span class="text-tag product-description">{{ description }}</span>

  <div class="mt-1" *ngIf="prescriptionCode">
    <fp-tag-seller-brand
      iconName="paper-background"
      [text]="prescription"
    >
    </fp-tag-seller-brand>
  </div>
</div>
